import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';
import { CustomPicker } from '../../../components/reusables/colorpicker/CustomColorPicker.react.js';

const { LiveCamWidgetPage } = messages;

const LayoutSettingsLayout = ({ disabled, fields: { backgroundType, infobarBackground } }) => {
  const [color, setColor] = useState('#aabbcc');
  const concurrentCalls = (e) => {
    infobarBackground.onChange(e);
    setColor(e);
  };
  return (
    <div className={`settings-group ${disabled ? 'disabled' : ''}`}>
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.layoutSettings.backgroundSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          disabled={disabled}
          field={backgroundType}
          id="widget-infobar-background-type-transparent"
          label={LiveCamWidgetPage.fields.background.choices.transparent}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `infobar background transparent`);
          }}
          value="transparent"
        />
        <Radio
          disabled={disabled}
          field={backgroundType}
          id="widget-infobar-background-type-color"
          label={LiveCamWidgetPage.fields.background.choices.color}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `infobar background color`);
          }}
          value="color"
        />
      </div>

      <CustomPicker
        color={color}
        disabled={disabled || backgroundType.value !== 'color'}
        field={infobarBackground}
        handleChange={setColor}
        input
        label={LiveCamWidgetPage.fields.background.alternativeLabel}
        labelClasses="active"
        onChange={(e) => concurrentCalls(e)}
      />
    </div>
  );
};

LayoutSettingsLayout.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    backgroundType: PropTypes.object,
    infobarBackground: PropTypes.object,
  }),
};

export default injectIntl(LayoutSettingsLayout);

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { InputText } from '../../components/reusables';
import { closeModalAnimation } from '../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../components/reusables/colorpicker/useClickOutside.js';

import messages from '../../i18n/base-en.js';

import { saveReport, fetchAllSavedReports } from '../../actions/statistics-actions';

const StatisticsSaveReportModal = ({ dispatch, fields, report, values, intl, modalInner }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  const createReport = () => {
    dispatch(saveReport(values.reportName, report)).then(() => {
      dispatch(fetchAllSavedReports());

      dispatch({
        type: 'redux-form/CHANGE',
        form: 'statisticsSaveReport',
        field: 'reportName',
        value: '',
      });

      dispatch(closeModalAnimation());
    });
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="save-report-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <h2 className="subtitle">
        <i className="material-icons link">save</i>
        <FormattedMessage {...messages.statisticsSaveReport} />
      </h2>

      <div className="row">
        <InputText
          className="col-md-12"
          field={fields.reportName}
          id="report-name"
          label={messages.genericTextReportName}
          maxLength={60}
          tabIndex={40}
          type="text"
        />
      </div>

      <button
        className="btn apply"
        disabled={!values.reportName || values.reportName.length <= 0}
        onClick={createReport}
        tabIndex={60}
      >
        {intl.formatMessage(messages.genericTextSave)}
      </button>
    </div>
  );
};

StatisticsSaveReportModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object,
  intl: intlShape.isRequired,
  report: PropTypes.object.isRequired,
  values: PropTypes.object,
};

export default injectIntl(
  reduxForm({
    form: 'statisticsSaveReport',
    initialValues: {
      reportName: '',
    },
    fields: ['reportName'],
  })(StatisticsSaveReportModal)
);

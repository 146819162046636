const socialsList = [
  {
    value: 'Facebook',
    url: 'https://www.facebook.com/',
  },
  {
    value: 'Instagram',
    url: 'https://www.instagram.com/',
  },
  {
    value: 'Snapchat',
    url: 'https://www.snapchat.com/add/',
  },
  {
    value: 'Twitter',
    url: 'https://twitter.com/',
  },
  {
    value: 'Tiktok',
    url: 'https://www.tiktok.com/@',
  },
  {
    value: 'Youtube',
    url: 'https://www.youtube.com/c/',
  },
  {
    value: 'Subreddit',
    url: 'https://www.reddit.com/r/',
  },
  {
    value: 'Telegram',
    url: 'https://www.t.me/',
  },
  {
    value: 'Pinterest',
    url: 'https://www.pinterest.com/',
  },
];

export default socialsList;

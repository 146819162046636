import React from 'react';
import './styles/OfferListingTags.scss';
import './styles/OfferListingChip.scss';
import { OFFER_TAG_TEXT, OFFER_TAG_TYPES } from './data';
import { OfferListingTagIcon } from './OfferListingTagIcon.react';
import Tooltip from '../../components/reusables/Tooltip.react';
import { OfferListingTagTooltipContent } from './OfferListingTagTooltipContent.react';

const styles = {
  [OFFER_TAG_TYPES.NEW]: { color: '#00BD00', background: '#BFEEBF' },
  [OFFER_TAG_TYPES.EXCLUSIVE]: { color: '#E65300', background: '#FFEAE9' },
  [OFFER_TAG_TYPES.TOP_OFFER]: { color: '#115EA3', background: '#EBF3FC' },
  [OFFER_TAG_TYPES.HOT_PICK]: { color: '#A91A90', background: '#FEEFF9' },
};

const OfferListingTag = ({ tag, tooltipPosition }) => {
  const text = OFFER_TAG_TEXT[tag];

  const style = styles[tag];

  const tooltipId = 'tooltip-' + tag;
  const tooltipContent = OfferListingTagTooltipContent({ tag });

  return (
    <Tooltip
      closeIcon
      content={
        <div className="offerListingTooltipContent">
          <p>{tooltipContent}</p>
          <a href="https://support.crakrevenue.com/knowledge-base/browse-search-offers/">Learn More</a>
        </div>
      }
      id={tooltipId}
      isDesktopClickable={false}
      isFixed={false}
      isHoverable
      position={tooltipPosition || 'bottom'}
    >
      <div className="offerListingChip withHover" style={style}>
        <OfferListingTagIcon tag={tag} />
        <span>{text}</span>
      </div>
    </Tooltip>
  );
};

export default OfferListingTag;

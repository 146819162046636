const Whale2 = () => (
  <svg className="whale-2" fill="none" height="87" viewBox="0 0 184 87" width="184" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_305)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M176.34 45C169.95 54.72 158 65.07 136.71 70.92C135.19 71.34 133.62 71.73 132 72.1C131.64 73.55 131.29 75.1 131.01 76.62C130.54 79.13 130.26 81.57 130.41 83.4C130.53 84.87 128.75 83.61 126.42 81.2C124.74 79.47 122.79 77.14 121.05 74.82C119.02 77.48 111.66 85.89 99.1 86.63C99.1 86.63 102.39 79.35 103.87 74.52C98.98 73.99 94.32 72.91 89.9 71.42C88.25 70.87 86.64 70.26 85.06 69.6C79.26 67.19 73.93 64.13 69.11 60.78C50.17 47.6 39.16 29.96 39.16 29.96C6.35998 37.89 0.0699768 15.53 0.0699768 15.53C0.0699768 15.53 8.81998 21.42 14.65 17.67C22.28 12.77 30.25 16.08 31.01 16.41C30.69 16.22 29.17 15.18 29.6 12.87C29.72 12.21 30.01 11.43 30.54 10.54C34.32 4.20003 32.64 0.0300293 32.64 0.0300293C46.29 2.75003 45.1 24.39 45.1 24.39C64.14 39.17 82.42 25.6 117.56 14.48C120.52 13.54 123.6 12.62 126.81 11.73C134.34 9.66003 141.17 8.30003 147.26 7.45003C151.14 6.91003 154.7 6.57003 157.95 6.38003C172.99 5.50003 181.24 7.79003 181.24 7.79003C181.24 7.79003 183.91 14.21 183.22 24.44C183.1 26.16 182.89 28 182.56 29.92C181.83 34.1 180 39.44 176.34 44.99V45Z"
          fill="url(#paint0_linear_792_305)"
        />
        <path
          d="M176.34 45C168.51 47.87 159.92 49.98 150.89 51.33C137.03 53.41 122.16 53.7 107.48 52.2C105.51 52 103.54 51.77 101.58 51.51C99.09 51.17 96.61 50.79 94.15 50.35C90.19 49.65 86.28 48.81 82.43 47.83C60.25 42.23 41.75 29.12 29.6 12.88C29.72 12.22 30.01 11.44 30.54 10.55C34.32 4.20003 32.64 0.0300293 32.64 0.0300293C46.29 2.75003 45.1 24.39 45.1 24.39C65.75 40.41 85.49 23.11 126.81 11.73C161.27 2.23003 181.24 7.79003 181.24 7.79003C181.24 7.79003 184.88 16.56 182.56 29.93C181.83 34.11 180 39.45 176.34 45Z"
          fill="url(#paint1_linear_792_305)"
        />
        <path
          d="M183.22 24.44L146.92 60.72L136.71 70.92C135.19 71.34 133.62 71.73 132 72.1C131.64 73.55 131.29 75.1 131.01 76.62L126.42 81.21C124.74 79.48 122.79 77.15 121.05 74.83C119.02 77.49 111.66 85.9 99.1 86.64C99.1 86.64 102.39 79.36 103.87 74.53C98.98 74 94.32 72.92 89.9 71.43L100.12 61.66L157.95 6.37002C172.99 5.49002 181.24 7.78002 181.24 7.78002C181.24 7.78002 183.91 14.2 183.22 24.43V24.44Z"
          fill="url(#paint2_linear_792_305)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M147.26 7.44994L93.77 60.9099L85.06 69.6099C79.26 67.1999 73.93 64.1399 69.11 60.7899L73.13 56.9499L117.56 14.4699C120.52 13.5299 123.6 12.6099 126.81 11.7199C134.34 9.64994 141.17 8.28994 147.26 7.43994V7.44994Z"
          fill="url(#paint3_linear_792_305)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M176.34 45C168.51 47.87 159.92 49.98 150.89 51.33C137.03 53.41 122.16 53.7 107.48 52.2C105.51 52 103.54 51.77 101.58 51.51C99.09 51.17 96.61 50.79 94.15 50.35C90.19 49.65 86.28 48.81 82.43 47.83C60.25 42.23 41.75 29.12 29.6 12.88C28.65 14.41 67.94 64.3 110.79 63.12C151.61 62 175.4 46.57 176.33 45H176.34Z"
          fill="#4B734E"
          opacity="0.09"
        />
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M45.27 24.18C47.79 26.1 50.56 27.7 53.51 28.79C56.46 29.91 59.61 30.4 62.76 30.46C69.09 30.55 75.31 28.84 81.34 26.88C87.38 24.88 93.32 22.54 99.3 20.31C105.28 18.07 111.29 15.89 117.4 13.98L117.26 14.05L117.33 14L117.4 13.98C122.59 12.48 127.74 10.84 133.02 9.65002C138.29 8.42002 143.61 7.45003 148.98 6.78003C154.35 6.11003 159.76 5.74003 165.17 5.80003C170.58 5.90003 176 6.26002 181.3 7.54002L181.16 8.06003C175.77 7.74003 170.43 8.03002 165.16 8.53002C162.52 8.82002 159.9 9.10002 157.29 9.49002C155.99 9.69002 154.69 9.91003 153.39 10.14C152.09 10.35 150.79 10.57 149.5 10.82C146.91 11.31 144.35 11.88 141.79 12.51C139.23 13.08 136.68 13.72 134.14 14.41C129.08 15.79 124.07 17.52 119 19.04L120.26 18.43L119.71 18.82L119 19.05C116.02 20.02 113.05 21.05 110.07 22.06L101.11 25.18C98.12 26.26 95.08 27.25 92.03 28.26C88.97 29.25 85.9 30.3 82.72 31.13C79.54 31.96 76.3 32.71 72.96 33.22C69.61 33.67 66.16 33.87 62.7 33.52C59.25 33.15 55.84 32.21 52.82 30.65L51.7 30.05L50.63 29.38C49.91 28.95 49.24 28.44 48.57 27.95C47.26 26.92 46.05 25.81 44.94 24.62L45.27 24.2V24.18Z"
            fill="url(#paint4_linear_792_305)"
          />
        </g>
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M0.13999 15.43C2.65999 16.98 5.46999 18.19 8.39999 18.6C9.85999 18.79 11.37 18.76 12.75 18.3C13.45 18.08 14.08 17.74 14.71 17.35C15.35 16.93 16.05 16.62 16.75 16.33C19.61 15.23 22.72 15.07 25.71 15.39C28.71 15.73 31.64 16.48 34.43 17.63L34.33 17.86C31.28 17.52 28.35 17.25 25.52 17.28C24.81 17.29 24.11 17.32 23.43 17.39C22.74 17.43 22.07 17.5 21.41 17.61C20.09 17.85 18.83 18.15 17.67 18.64C17.1 18.89 16.55 19.19 16.02 19.49L15.48 19.8C15.27 19.92 15.05 20.04 14.83 20.13C14.39 20.34 13.93 20.5 13.48 20.64C12.56 20.93 11.62 21.03 10.72 21.01C9.81999 20.99 8.93999 20.9 8.10999 20.7C7.27999 20.49 6.48999 20.22 5.72999 19.91C4.96999 19.59 4.26999 19.18 3.57999 18.77C2.22999 17.9 0.98999 16.91 -0.0100098 15.65L0.12999 15.44L0.13999 15.43Z"
            fill="url(#paint5_linear_792_305)"
          />
        </g>
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M30.97 16.48C30.33 16.08 29.83 15.46 29.59 14.74C29.35 14.02 29.39 13.23 29.56 12.51C29.74 11.78 30.06 11.1 30.43 10.46C30.78 9.82 31.13 9.18 31.42 8.51C32.04 7.19 32.51 5.8 32.74 4.37C32.95 2.94 32.99 1.44 32.55 0.06L32.7 0C33.71 1.28 33.99 2.97 33.96 4.56C33.93 6.17 33.47 7.73 32.84 9.17C32.5 9.88 32.11 10.56 31.69 11.22C31.29 11.75 31.01 12.29 30.74 12.82C30.52 13.36 30.34 13.9 30.36 14.49C30.36 15.08 30.58 15.72 31.02 16.35L30.94 16.48H30.97Z"
            fill="url(#paint6_linear_792_305)"
          />
        </g>
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M99.11 86.53C101.57 85.93 103.82 85.09 105.97 84.13C106.51 83.89 107.03 83.63 107.56 83.36C108.09 83.1 108.61 82.84 109.1 82.52C110.13 81.98 111.1 81.32 112.07 80.68C113.01 79.99 113.99 79.34 114.87 78.56C115.32 78.19 115.82 77.85 116.25 77.44C116.67 77.03 117.13 76.64 117.58 76.25C118.05 75.88 118.45 75.43 118.89 75.01L120.22 73.75C121.1 72.89 121.93 71.97 122.92 71.13L123.08 71.23C122.39 72.26 121.74 73.32 121.02 74.34L119.87 75.81C119.49 76.3 119.11 76.8 118.67 77.24L117.4 78.61C116.98 79.07 116.49 79.46 116.04 79.89C115.15 80.76 114.11 81.47 113.13 82.24C111.05 83.62 108.81 84.76 106.44 85.55C104.08 86.33 101.6 86.82 99.09 86.73V86.54L99.11 86.53Z"
            fill="url(#paint7_linear_792_305)"
          />
        </g>
        <path
          d="M163.62 25.45C167.944 25.45 171.45 21.9444 171.45 17.62C171.45 13.2957 167.944 9.79004 163.62 9.79004C159.296 9.79004 155.79 13.2957 155.79 17.62C155.79 21.9444 159.296 25.45 163.62 25.45Z"
          fill="url(#paint8_radial_792_305)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M168.481 19.3658C169.44 16.694 168.052 13.7505 165.38 12.7912C162.709 11.832 159.765 13.2202 158.806 15.892C157.847 18.5637 159.235 21.5072 161.907 22.4665C164.578 23.4258 167.522 22.0375 168.481 19.3658Z"
          fill="url(#paint9_radial_792_305)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M157.42 21.71C157.96 22.52 161.13 21.36 164.5 19.11C167.87 16.86 170.16 14.38 169.61 13.57C169.07 12.76 165.9 13.92 162.53 16.17C159.16 18.42 156.87 20.9 157.42 21.71Z"
          fill="url(#paint10_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M161.63 18.9001C161.8 19.1501 162.78 18.7901 163.82 18.1001C164.86 17.4001 165.57 16.6401 165.4 16.3801C165.23 16.1201 164.25 16.4901 163.21 17.1801C162.17 17.8801 161.46 18.6401 161.63 18.9001Z"
          fill="url(#paint11_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M160.94 19.36C161.17 19.7 162.51 19.21 163.93 18.26C165.35 17.31 166.32 16.26 166.09 15.92C165.86 15.58 164.52 16.07 163.1 17.02C161.68 17.97 160.71 19.02 160.94 19.36Z"
          fill="url(#paint12_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M154.04 24.07C153.92 23.97 157.92 21.11 162.97 17.68C168.02 14.25 172.22 11.55 172.34 11.65C172.46 11.75 168.46 14.61 163.41 18.04C158.35 21.47 154.16 24.17 154.03 24.07H154.04Z"
          fill="url(#paint13_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M159.34 12.08C158.53 12.62 159.69 15.79 161.94 19.16C164.19 22.53 166.67 24.82 167.48 24.27C168.29 23.73 167.13 20.56 164.88 17.19C162.63 13.82 160.15 11.53 159.34 12.08Z"
          fill="url(#paint14_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M162.15 16.2899C161.9 16.4599 162.26 17.4399 162.95 18.4799C163.65 19.5199 164.41 20.2299 164.67 20.0599C164.92 19.8899 164.56 18.9099 163.87 17.8699C163.17 16.8299 162.41 16.1199 162.15 16.2899Z"
          fill="url(#paint15_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M161.69 15.6099C161.35 15.8399 161.84 17.1799 162.79 18.5999C163.74 20.0199 164.79 20.9899 165.13 20.7599C165.47 20.5299 164.98 19.1899 164.03 17.7699C163.08 16.3499 162.03 15.3799 161.69 15.6099Z"
          fill="url(#paint16_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M156.98 8.70002C157.08 8.58002 159.94 12.58 163.37 17.63C166.8 22.69 169.5 26.88 169.4 27.01C169.3 27.13 166.44 23.13 163.01 18.08C159.58 13.02 156.88 8.83002 156.98 8.70002Z"
          fill="url(#paint17_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M164.61 17.01C164.13 16.29 163.15 16.09 162.43 16.57C161.71 17.05 161.51 18.03 161.99 18.75C162.47 19.47 163.45 19.67 164.17 19.19C164.89 18.71 165.09 17.73 164.61 17.01Z"
          fill="url(#paint18_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M163.3 20.74C164.88 20.74 166.16 19.4596 166.16 17.88C166.16 16.3005 164.88 15.02 163.3 15.02C161.72 15.02 160.44 16.3005 160.44 17.88C160.44 19.4596 161.72 20.74 163.3 20.74Z"
          fill="url(#paint19_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M64.84 55.94C69.1644 55.94 72.67 52.4344 72.67 48.11C72.67 43.7856 69.1644 40.28 64.84 40.28C60.5156 40.28 57.01 43.7856 57.01 48.11C57.01 52.4344 60.5156 55.94 64.84 55.94Z"
          fill="url(#paint20_radial_792_305)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M69.6782 49.8483C70.6374 47.1765 69.2492 44.233 66.5774 43.2738C63.9057 42.3145 60.9621 43.7028 60.0029 46.3745C59.0436 49.0463 60.4319 51.9898 63.1036 52.949C65.7754 53.9083 68.7189 52.5201 69.6782 49.8483Z"
          fill="url(#paint21_radial_792_305)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M68.94 54.31C69.75 53.77 68.59 50.6 66.34 47.23C64.09 43.86 61.61 41.57 60.8 42.12C59.99 42.66 61.15 45.83 63.4 49.2C65.65 52.57 68.13 54.86 68.94 54.31Z"
          fill="url(#paint22_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M66.13 50.11C66.38 49.94 66.02 48.96 65.33 47.92C64.63 46.88 63.87 46.17 63.61 46.34C63.35 46.51 63.72 47.49 64.41 48.53C65.11 49.57 65.87 50.28 66.13 50.11Z"
          fill="url(#paint23_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M66.58 50.79C66.92 50.56 66.43 49.22 65.48 47.8C64.53 46.38 63.48 45.41 63.14 45.64C62.8 45.87 63.29 47.21 64.24 48.63C65.19 50.05 66.24 51.02 66.58 50.79Z"
          fill="url(#paint24_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M71.29 57.7C71.19 57.82 68.33 53.82 64.9 48.77C61.47 43.72 58.77 39.52 58.87 39.4C58.97 39.28 61.83 43.28 65.26 48.33C68.69 53.39 71.39 57.58 71.29 57.71V57.7Z"
          fill="url(#paint25_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M59.31 52.4C59.85 53.21 63.02 52.05 66.39 49.8C69.76 47.55 72.05 45.07 71.5 44.26C70.96 43.45 67.79 44.61 64.42 46.86C61.05 49.11 58.76 51.59 59.31 52.4Z"
          fill="url(#paint26_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M63.52 49.5899C63.69 49.8399 64.67 49.4799 65.71 48.7899C66.75 48.0899 67.46 47.3299 67.29 47.0699C67.12 46.8199 66.14 47.1799 65.1 47.8699C64.06 48.5699 63.35 49.3299 63.52 49.5899Z"
          fill="url(#paint27_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M62.83 50.0399C63.06 50.3799 64.4 49.8899 65.82 48.9399C67.24 47.9899 68.21 46.9399 67.98 46.5999C67.75 46.2599 66.41 46.7499 64.99 47.6999C63.57 48.6499 62.6 49.6999 62.83 50.0399Z"
          fill="url(#paint28_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M55.93 54.75C55.81 54.65 59.81 51.79 64.86 48.36C69.92 44.93 74.11 42.23 74.24 42.33C74.36 42.43 70.36 45.29 65.31 48.72C60.25 52.15 56.06 54.85 55.93 54.75Z"
          fill="url(#paint29_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M64.23 47.1299C63.51 47.6099 63.31 48.5899 63.79 49.3099C64.27 50.0299 65.25 50.2299 65.97 49.7499C66.69 49.2699 66.89 48.2899 66.41 47.5699C65.93 46.8499 64.95 46.6499 64.23 47.1299Z"
          fill="url(#paint30_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M65.1 51.3C66.6795 51.3 67.96 50.0195 67.96 48.44C67.96 46.8604 66.6795 45.58 65.1 45.58C63.5205 45.58 62.24 46.8604 62.24 48.44C62.24 50.0195 63.5205 51.3 65.1 51.3Z"
          fill="url(#paint31_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M31.13 25.46C33.2949 25.46 35.05 23.705 35.05 21.54C35.05 19.375 33.2949 17.62 31.13 17.62C28.965 17.62 27.21 19.375 27.21 21.54C27.21 23.705 28.965 25.46 31.13 25.46Z"
          fill="url(#paint32_radial_792_305)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M33.5511 22.4084C34.0307 21.0725 33.3366 19.6008 32.0007 19.1211C30.6648 18.6415 29.193 19.3356 28.7134 20.6715C28.2338 22.0074 28.9279 23.4791 30.2638 23.9588C31.5997 24.4384 33.0714 23.7443 33.5511 22.4084Z"
          fill="url(#paint33_radial_792_305)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M33.18 24.64C33.59 24.37 33.01 22.78 31.88 21.09C30.75 19.4 29.51 18.26 29.1 18.53C28.69 18.8 29.27 20.39 30.4 22.08C31.53 23.77 32.77 24.91 33.18 24.64Z"
          fill="url(#paint34_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M31.77 22.54C31.9 22.46 31.72 21.96 31.37 21.44C31.02 20.92 30.64 20.56 30.51 20.65C30.38 20.74 30.56 21.23 30.91 21.75C31.26 22.27 31.64 22.63 31.77 22.54Z"
          fill="url(#paint35_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M32 22.8801C32.17 22.7701 31.93 22.0901 31.45 21.3801C30.97 20.6701 30.45 20.1801 30.28 20.3001C30.11 20.4101 30.35 21.0901 30.83 21.8001C31.31 22.5101 31.83 23.0001 32 22.8801Z"
          fill="url(#paint36_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M34.36 26.34C34.31 26.4 32.87 24.4 31.16 21.86C29.45 19.32 28.09 17.22 28.14 17.16C28.19 17.1 29.63 19.1 31.34 21.64C33.06 24.17 34.41 26.28 34.36 26.34Z"
          fill="url(#paint37_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M28.35 23.6801C28.62 24.0901 30.21 23.5101 31.9 22.3801C33.59 21.2501 34.73 20.0101 34.46 19.6001C34.19 19.1901 32.6 19.7701 30.91 20.9001C29.22 22.0301 28.08 23.2701 28.35 23.6801Z"
          fill="url(#paint38_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.46 22.2801C30.54 22.4101 31.04 22.2301 31.56 21.8801C32.08 21.5301 32.44 21.1501 32.35 21.0201C32.27 20.8901 31.77 21.0701 31.25 21.4201C30.73 21.7701 30.37 22.1501 30.46 22.2801Z"
          fill="url(#paint39_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.12 22.5001C30.23 22.6701 30.91 22.4301 31.62 21.9501C32.33 21.4701 32.82 20.9501 32.7 20.7801C32.59 20.6101 31.91 20.8501 31.2 21.3301C30.49 21.8101 30 22.3301 30.12 22.5001Z"
          fill="url(#paint40_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M26.66 24.86C26.6 24.81 28.6 23.37 31.14 21.66C33.67 19.94 35.78 18.59 35.84 18.64C35.9 18.69 33.9 20.13 31.36 21.84C28.83 23.56 26.72 24.91 26.66 24.86Z"
          fill="url(#paint41_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M30.82 21.04C30.46 21.28 30.36 21.77 30.6 22.13C30.84 22.49 31.33 22.59 31.69 22.35C32.05 22.11 32.15 21.62 31.91 21.26C31.67 20.9 31.18 20.8 30.82 21.04Z"
          fill="url(#paint42_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M31.26 23.13C32.0498 23.13 32.69 22.4898 32.69 21.7C32.69 20.9103 32.0498 20.27 31.26 20.27C30.4702 20.27 29.83 20.9103 29.83 21.7C29.83 22.4898 30.4702 23.13 31.26 23.13Z"
          fill="url(#paint43_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M111.78 80.12C113.945 80.12 115.7 78.365 115.7 76.2C115.7 74.0351 113.945 72.28 111.78 72.28C109.615 72.28 107.86 74.0351 107.86 76.2C107.86 78.365 109.615 80.12 111.78 80.12Z"
          fill="url(#paint44_radial_792_305)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M114.209 77.0731C114.688 75.7372 113.994 74.2654 112.658 73.7858C111.322 73.3062 109.851 74.0003 109.371 75.3362C108.891 76.6721 109.586 78.1438 110.921 78.6235C112.257 79.1031 113.729 78.409 114.209 77.0731Z"
          fill="url(#paint45_radial_792_305)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M113.83 79.3101C114.24 79.0401 113.66 77.4501 112.53 75.7601C111.4 74.0701 110.16 72.9301 109.75 73.2001C109.34 73.4701 109.92 75.0601 111.05 76.7501C112.18 78.4401 113.42 79.5801 113.83 79.3101Z"
          fill="url(#paint46_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M112.43 77.2001C112.56 77.1201 112.38 76.6201 112.03 76.1001C111.68 75.5801 111.3 75.2201 111.17 75.3101C111.04 75.4001 111.22 75.8901 111.57 76.4101C111.92 76.9301 112.3 77.2901 112.43 77.2001Z"
          fill="url(#paint47_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M112.66 77.5401C112.83 77.4301 112.59 76.7501 112.11 76.0401C111.63 75.3301 111.11 74.8401 110.94 74.9601C110.77 75.0701 111.01 75.7501 111.49 76.4601C111.97 77.1701 112.49 77.6601 112.66 77.5401Z"
          fill="url(#paint48_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M115.02 81C114.97 81.06 113.53 79.06 111.82 76.52C110.11 73.98 108.75 71.88 108.8 71.82C108.85 71.76 110.29 73.76 112 76.3C113.72 78.83 115.07 80.94 115.02 81Z"
          fill="url(#paint49_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M109.01 78.3501C109.28 78.7601 110.87 78.1801 112.56 77.0501C114.25 75.9201 115.39 74.6801 115.12 74.2701C114.85 73.8601 113.26 74.4401 111.57 75.5701C109.88 76.7001 108.74 77.9401 109.01 78.3501Z"
          fill="url(#paint50_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M111.12 76.9401C111.2 77.0701 111.7 76.8901 112.22 76.5401C112.74 76.1901 113.1 75.8101 113.01 75.6801C112.93 75.5501 112.43 75.7301 111.91 76.0801C111.39 76.4301 111.03 76.8101 111.12 76.9401Z"
          fill="url(#paint51_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M110.78 77.1701C110.89 77.3401 111.57 77.1001 112.28 76.6201C112.99 76.1401 113.48 75.6201 113.36 75.4501C113.25 75.2801 112.57 75.5201 111.86 76.0001C111.15 76.4801 110.66 77.0001 110.78 77.1701Z"
          fill="url(#paint52_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M107.32 79.5301C107.26 79.4801 109.26 78.0401 111.8 76.3301C114.33 74.6101 116.44 73.2601 116.5 73.3101C116.56 73.3601 114.56 74.8001 112.02 76.5101C109.49 78.2301 107.38 79.5801 107.32 79.5301Z"
          fill="url(#paint53_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M111.48 75.71C111.12 75.95 111.02 76.44 111.26 76.8C111.5 77.16 111.99 77.26 112.35 77.02C112.71 76.78 112.81 76.29 112.57 75.93C112.33 75.57 111.84 75.47 111.48 75.71Z"
          fill="url(#paint54_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M111.91 77.7901C112.7 77.7901 113.34 77.1498 113.34 76.3601C113.34 75.5703 112.7 74.9301 111.91 74.9301C111.12 74.9301 110.48 75.5703 110.48 76.3601C110.48 77.1498 111.12 77.7901 111.91 77.7901Z"
          fill="url(#paint55_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M176.54 45.55C178.826 45.55 180.68 43.6965 180.68 41.41C180.68 39.1236 178.826 37.27 176.54 37.27C174.254 37.27 172.4 39.1236 172.4 41.41C172.4 43.6965 174.254 45.55 176.54 45.55Z"
          fill="url(#paint56_radial_792_305)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M178.255 43.5003C179.412 42.5499 179.579 40.8422 178.628 39.686C177.678 38.5298 175.97 38.3629 174.814 39.3133C173.658 40.2637 173.491 41.9715 174.441 43.1277C175.391 44.2839 177.099 44.4507 178.255 43.5003Z"
          fill="url(#paint57_radial_792_305)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M172.62 41.5901C172.65 42.1101 174.4 42.4401 176.53 42.3301C178.66 42.2201 180.38 41.7201 180.35 41.2001C180.32 40.6801 178.57 40.3501 176.44 40.4601C174.3 40.5701 172.59 41.0701 172.62 41.5901Z"
          fill="url(#paint58_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M175.29 41.4602C175.29 41.6202 175.84 41.7202 176.5 41.6902C177.16 41.6602 177.69 41.5002 177.68 41.3402C177.67 41.1802 177.13 41.0802 176.47 41.1102C175.81 41.1402 175.28 41.3002 175.29 41.4602Z"
          fill="url(#paint59_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M174.85 41.4801C174.86 41.7001 175.6 41.8401 176.5 41.7901C177.4 41.7401 178.12 41.5301 178.11 41.3101C178.1 41.0901 177.36 40.9501 176.46 41.0001C175.56 41.0501 174.84 41.2601 174.85 41.4801Z"
          fill="url(#paint60_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M170.45 41.7501C170.42 41.6701 173.01 41.4601 176.24 41.2701C179.47 41.0801 182.1 41.0001 182.13 41.0701C182.16 41.1501 179.57 41.3601 176.35 41.5501C173.13 41.7401 170.49 41.8201 170.46 41.7501H170.45Z"
          fill="url(#paint61_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M176.1 37.74C175.58 37.77 175.25 39.52 175.36 41.65C175.47 43.79 175.97 45.5 176.49 45.47C177.01 45.44 177.34 43.69 177.23 41.56C177.12 39.43 176.62 37.71 176.1 37.74Z"
          fill="url(#paint62_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M176.23 40.4101C176.07 40.4101 175.97 40.9601 176 41.6201C176.03 42.2801 176.19 42.8101 176.35 42.8001C176.51 42.8001 176.61 42.2501 176.58 41.5901C176.55 40.9301 176.39 40.4001 176.23 40.4101Z"
          fill="url(#paint63_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M176.21 39.98C175.99 39.99 175.85 40.73 175.9 41.63C175.95 42.53 176.16 43.25 176.38 43.24C176.6 43.23 176.74 42.49 176.69 41.59C176.64 40.69 176.43 39.97 176.21 39.98Z"
          fill="url(#paint64_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M175.94 35.5701C176.02 35.5401 176.23 38.1301 176.42 41.3501C176.61 44.5701 176.69 47.2101 176.62 47.2401C176.54 47.2701 176.33 44.6801 176.14 41.4501C175.95 38.2301 175.87 35.5901 175.94 35.5601V35.5701Z"
          fill="url(#paint65_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M177.16 41.4001C177.14 40.9401 176.75 40.5901 176.29 40.6101C175.83 40.6301 175.48 41.0201 175.5 41.4801C175.52 41.9401 175.91 42.2901 176.37 42.2701C176.83 42.2501 177.18 41.8601 177.16 41.4001Z"
          fill="url(#paint66_radial_792_305)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M176.32 42.9601C177.154 42.9601 177.83 42.284 177.83 41.4501C177.83 40.6161 177.154 39.9401 176.32 39.9401C175.486 39.9401 174.81 40.6161 174.81 41.4501C174.81 42.284 175.486 42.9601 176.32 42.9601Z"
          fill="url(#paint67_radial_792_305)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_305"
        x1="-0.645554"
        x2="177.633"
        y1="19.0986"
        y2="54.6575"
      >
        <stop stopColor="#141718" />
        <stop offset="0.48" stopColor="#1B2425" />
        <stop offset="1" stopColor="#2B4233" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_792_305"
        x1="114.314"
        x2="98.5076"
        y1="-14.2796"
        y2="54.385"
      >
        <stop offset="0.09" stopColor="#141718" stopOpacity="0.2" />
        <stop offset="0.97" stopColor="#020A0D" stopOpacity="0.9216" />
        <stop offset="1" stopColor="#020A0D" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_305"
        x1="138.344"
        x2="134.903"
        y1="11.6619"
        y2="81.7222"
      >
        <stop stopColor="#020A0D" />
        <stop offset="1" stopColor="#141718" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_305"
        x1="109.32"
        x2="106.644"
        y1="10.9611"
        y2="65.4808"
      >
        <stop stopColor="#020A0D" />
        <stop offset="1" stopColor="#141718" stopOpacity="0" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint4_linear_792_305" x1="47.6" x2="176.72" y1="5.72002" y2="31.47">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_792_305" x1="-0.23001" x2="33.94" y1="14.56" y2="21.37">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint6_linear_792_305" x1="29.81" x2="32.88" y1="7.84" y2="8.45">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint7_linear_792_305" x1="100.93" x2="121.92" y1="76.92" y2="81.1">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.575 17.5888) rotate(56.28) scale(7.83)"
        gradientUnits="userSpaceOnUse"
        id="paint8_radial_792_305"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.656 17.6517) rotate(56.28) scale(5.14)"
        gradientUnits="userSpaceOnUse"
        id="paint9_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(170.33 27.6845) rotate(-123.72) scale(1.7592 7.33)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(170.33 27.6845) rotate(-123.72) scale(0.5448 2.27)"
        gradientUnits="userSpaceOnUse"
        id="paint11_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(170.33 27.6845) rotate(-123.72) scale(0.7416 3.09)"
        gradientUnits="userSpaceOnUse"
        id="paint12_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(14.6583 -106.167) rotate(39.76) scale(0.3008 10.6408)"
        gradientUnits="userSpaceOnUse"
        id="paint13_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.287 18.2445) rotate(-33.72) scale(1.7592 7.33)"
        gradientUnits="userSpaceOnUse"
        id="paint14_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.287 18.2445) rotate(-33.72) scale(0.5448 2.27)"
        gradientUnits="userSpaceOnUse"
        id="paint15_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.287 18.2445) rotate(-33.72) scale(0.7416 3.09)"
        gradientUnits="userSpaceOnUse"
        id="paint16_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(42.0255 163.018) rotate(129.76) scale(0.3008 10.6408)"
        gradientUnits="userSpaceOnUse"
        id="paint17_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.251 17.8413) rotate(56.28) scale(1.57)"
        gradientUnits="userSpaceOnUse"
        id="paint18_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.251 17.8413) rotate(56.28) scale(2.86)"
        gradientUnits="userSpaceOnUse"
        id="paint19_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(65.1293 48.2835) rotate(-33.72) scale(7.83)"
        gradientUnits="userSpaceOnUse"
        id="paint20_radial_792_305"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(64.7604 47.9607) rotate(-33.72) scale(5.14)"
        gradientUnits="userSpaceOnUse"
        id="paint21_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(43.502 62.7112) rotate(146.28) scale(1.7592 7.33)"
        gradientUnits="userSpaceOnUse"
        id="paint22_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(43.502 62.7112) rotate(146.28) scale(0.5448 2.27)"
        gradientUnits="userSpaceOnUse"
        id="paint23_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(43.502 62.7112) rotate(146.28) scale(0.7416 3.09)"
        gradientUnits="userSpaceOnUse"
        id="paint24_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-8.10638 136.113) rotate(-50.24) scale(0.3008 10.6408)"
        gradientUnits="userSpaceOnUse"
        id="paint25_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(74.8894 62.2047) rotate(-123.72) scale(1.7592 7.33)"
        gradientUnits="userSpaceOnUse"
        id="paint26_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(74.8894 62.2047) rotate(-123.72) scale(0.5448 2.27)"
        gradientUnits="userSpaceOnUse"
        id="paint27_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(74.8894 62.2047) rotate(-123.72) scale(0.7416 3.09)"
        gradientUnits="userSpaceOnUse"
        id="paint28_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1307.77 -1097.71) rotate(39.76) scale(0.3008 10.6408)"
        gradientUnits="userSpaceOnUse"
        id="paint29_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(65.3902 48.6022) rotate(-33.72) scale(1.57)"
        gradientUnits="userSpaceOnUse"
        id="paint30_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(65.3902 48.6023) rotate(-33.72) scale(2.86)"
        gradientUnits="userSpaceOnUse"
        id="paint31_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.4146 21.7088) rotate(-33.72) scale(3.92)"
        gradientUnits="userSpaceOnUse"
        id="paint32_radial_792_305"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.0503 21.39) rotate(-33.72) scale(2.57)"
        gradientUnits="userSpaceOnUse"
        id="paint33_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.84834 36.0357) rotate(146.28) scale(0.8808 3.67)"
        gradientUnits="userSpaceOnUse"
        id="paint34_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.84833 36.0357) rotate(146.28) scale(0.2736 1.14)"
        gradientUnits="userSpaceOnUse"
        id="paint35_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9.84836 36.0357) rotate(146.28) scale(0.372 1.55)"
        gradientUnits="userSpaceOnUse"
        id="paint36_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-50.3208 119.374) rotate(-50.24) scale(0.1504 5.3204)"
        gradientUnits="userSpaceOnUse"
        id="paint37_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(41.0334 35.7427) rotate(-123.72) scale(0.8808 3.67)"
        gradientUnits="userSpaceOnUse"
        id="paint38_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(41.0334 35.7427) rotate(-123.72) scale(0.2736 1.14)"
        gradientUnits="userSpaceOnUse"
        id="paint39_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(41.0334 35.7427) rotate(-123.72) scale(0.372 1.55)"
        gradientUnits="userSpaceOnUse"
        id="paint40_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1342.94 -1125.65) rotate(39.76) scale(0.1504 5.3204)"
        gradientUnits="userSpaceOnUse"
        id="paint41_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.5478 21.8724) rotate(-33.72) scale(0.79)"
        gradientUnits="userSpaceOnUse"
        id="paint42_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.5478 21.8724) rotate(-33.72) scale(1.43)"
        gradientUnits="userSpaceOnUse"
        id="paint43_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(112.069 76.3712) rotate(-33.72) scale(3.92 3.92)"
        gradientUnits="userSpaceOnUse"
        id="paint44_radial_792_305"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(111.712 76.048) rotate(-33.72) scale(2.57)"
        gradientUnits="userSpaceOnUse"
        id="paint45_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(90.3043 90.8305) rotate(146.28) scale(0.8808 3.67)"
        gradientUnits="userSpaceOnUse"
        id="paint46_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(90.3043 90.8304) rotate(146.28) scale(0.2736 1.14)"
        gradientUnits="userSpaceOnUse"
        id="paint47_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(90.3043 90.8305) rotate(146.28) scale(0.372 1.55)"
        gradientUnits="userSpaceOnUse"
        id="paint48_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(49.4042 151.193) rotate(-50.24) scale(0.1504 5.3204)"
        gradientUnits="userSpaceOnUse"
        id="paint49_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(121.369 89.928) rotate(-123.72) scale(0.8808 3.67)"
        gradientUnits="userSpaceOnUse"
        id="paint50_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(121.369 89.928) rotate(-123.72) scale(0.2736 1.14)"
        gradientUnits="userSpaceOnUse"
        id="paint51_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(121.369 89.9281) rotate(-123.72) scale(0.372 1.55)"
        gradientUnits="userSpaceOnUse"
        id="paint52_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1260.95 -1069.84) rotate(39.76) scale(0.1504 5.3204)"
        gradientUnits="userSpaceOnUse"
        id="paint53_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(112.202 76.5348) rotate(-33.72) scale(0.789999)"
        gradientUnits="userSpaceOnUse"
        id="paint54_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(112.202 76.5348) rotate(-33.7201) scale(1.43 1.43)"
        gradientUnits="userSpaceOnUse"
        id="paint55_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(176.488 41.1282) rotate(87.12) scale(4.14)"
        gradientUnits="userSpaceOnUse"
        id="paint56_radial_792_305"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(176.515 41.5021) rotate(87.11) scale(2.71)"
        gradientUnits="userSpaceOnUse"
        id="paint57_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(177.274 54.1283) rotate(-92.88) scale(0.928801 3.87001)"
        gradientUnits="userSpaceOnUse"
        id="paint58_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(177.274 54.1283) rotate(-92.88) scale(0.287999 1.2)"
        gradientUnits="userSpaceOnUse"
        id="paint59_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(177.274 54.1283) rotate(-92.88) scale(0.3912 1.63)"
        gradientUnits="userSpaceOnUse"
        id="paint60_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(331.991 486.414) rotate(70.59) scale(0.1592 5.6317)"
        gradientUnits="userSpaceOnUse"
        id="paint61_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(192.008 40.5982) rotate(-2.88001) scale(0.928798 3.87)"
        gradientUnits="userSpaceOnUse"
        id="paint62_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(192.009 40.5982) rotate(-2.87999) scale(0.288001 1.2)"
        gradientUnits="userSpaceOnUse"
        id="paint63_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(192.009 40.5981) rotate(-2.87997) scale(0.391204 1.63)"
        gradientUnits="userSpaceOnUse"
        id="paint64_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-344.22 224.35) rotate(160.59) scale(0.159199 5.63169)"
        gradientUnits="userSpaceOnUse"
        id="paint65_radial_792_305"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(176.27 41.1593) rotate(87.12) scale(0.83 0.830002)"
        gradientUnits="userSpaceOnUse"
        id="paint66_radial_792_305"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(176.27 41.1593) rotate(87.12) scale(1.51 1.51)"
        gradientUnits="userSpaceOnUse"
        id="paint67_radial_792_305"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_792_305">
        <rect fill="white" height="86.73" width="183.33" />
      </clipPath>
    </defs>
  </svg>
);

export default Whale2;

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestPromo = () => ({
  type: ACTIONS.REQUEST_PROMO,
});

const receivePromo = (data) => ({
  type: ACTIONS.RECEIVE_PROMO,
  data,
});

const failedToReceivePromo = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_PROMO,
});

export const fetchPromo = (promo) => (dispatch) => {
  dispatch(requestPromo());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/content/${promo}`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePromo(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceivePromo());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

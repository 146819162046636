import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';
import { CustomPicker } from '../../../components/reusables/colorpicker/CustomColorPicker.react.js';

import { Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { LiveCamWidgetPage } = messages;

const LayoutSettingsLayout = ({ fields: { backgroundColor, backgroundType } }) => {
  const [color, setColor] = useState('#aabbcc');
  const concurrentCalls = (e) => {
    backgroundColor.onChange(e);
    setColor(e);
  };

  return (
    <div className="settings-group">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.layoutSettings.backgroundSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={backgroundType}
          id="widget-background-type-transparent"
          label={LiveCamWidgetPage.fields.background.choices.transparent}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `layout background transparent`);
          }}
          value="transparent"
        />
        <Radio
          field={backgroundType}
          id="widget-background-type-color"
          label={LiveCamWidgetPage.fields.background.choices.color}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `layout background color`);
          }}
          value="color"
        />
      </div>

      <CustomPicker
        color={color}
        disabled={backgroundType.value !== 'color'}
        field={backgroundColor}
        handleChange={setColor}
        input
        label={LiveCamWidgetPage.fields.background.alternativeLabel}
        labelClasses="active"
        onChange={(e) => concurrentCalls(e)}
      />
    </div>
  );
};

LayoutSettingsLayout.propTypes = {
  fields: PropTypes.shape({
    backgroundColor: PropTypes.object,
    backgroundType: PropTypes.object,
  }),
};

export default injectIntl(LayoutSettingsLayout);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../i18n/base-en.js';

import { fetchOfferTemplatesUrl, fetchSurveyCreativeScript } from '../actions/survey-generator-actions';

import SurveyIframe from './survey-generator/SurveyIframe.react';
import SurveyGeneratorTabs from './survey-generator/SurveyGeneratorTabs.react';
import { Info, InputText, VideoModalIcon } from '../components/reusables';

import Tooltip from '../components/reusables/ReactTooltip';

import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { surveyUrlBuilder } from '../utils/helpers';
import { Gtm } from '../utils/gtm';

import config from '../config';
import { openModal } from '../components/Modal/modal-actions/modal-actions.js';
const { SURVEY_TEMPLATES_OFFER_ID } = config;
const { genericTextBackToDashboard, WidgetSection } = messages;

const SurveyGenerator = ({
  animated,
  affId,
  basicConfiguration,
  children,
  colorTemplate,
  creativeCode,
  creativeId,
  creativeIsFetching,
  creativeScript,
  dispatch,
  fields,
  intl,
  location: { pathname },
  questionIsFetching,
  questions,
  rewardOptions,
  templateUrls,
  themes,
  values,
  templateOptions,
  customColors,
  skinConfigurations,
  routes,
}) => {
  const [baseSurveyUrl, setBaseSurveyUrl] = useState('');
  const [fieldsSettings] = useState({});
  const [iframeUrl, setIframeUrl] = useState('');
  const [questionId, setQuestionId] = useState('');

  const fetchData = () => {
    new Promise((fulfill) => {
      const promises = [];

      if (!creativeScript) {
        promises.push(dispatch(fetchSurveyCreativeScript()));
      }

      if (templateUrls.length === 0) {
        promises.push(dispatch(fetchOfferTemplatesUrl(SURVEY_TEMPLATES_OFFER_ID)));
      }

      Promise.all(promises).then(fulfill).catch(fulfill);
    });
  };

  useEffect(() => {
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'survey-machine');
    fetchData();

    if ('path' in routes[2]) {
      browserHistory.push('/survey-machine');
    }

    return () => {
      dispatch(reset('templateOptions'));
    };
  }, []);

  useEffect(() => {
    const formsAreInitialized = !!templateOptions && !!basicConfiguration && !!rewardOptions && !!customColors;

    if (formsAreInitialized && !!baseSurveyUrl) {
      setIframeUrl(
        surveyUrlBuilder(
          baseSurveyUrl,
          {
            basicConfiguration: basicConfiguration,
            customColors: customColors,
            rewardOptions: rewardOptions,
            templateOptions: templateOptions,
          },
          affId,
          routes[routes.length - 1].path === 'reward-options'
        )
      );
    }
  }, [templateOptions, basicConfiguration, rewardOptions, customColors]);

  const getToolTip = () => {
    let tooltip;
    if (!values.display || values.display.length === 0) {
      tooltip = intl.formatMessage(messages.surveyGeneratorPleaseNameYourSurvey);
    }

    return tooltip;
  };

  const generateIframeUrl = (affId, otherParams) =>
    surveyUrlBuilder(
      baseSurveyUrl,
      {
        basicConfiguration,
        customColors,
        rewardOptions,
        templateOptions,
      },
      affId,
      otherParams
    );

  const changeBaseSurveyUrl = (baseUrl) => {
    if (!!basicConfiguration && !!rewardOptions && !!customColors && !!baseUrl) {
      setBaseSurveyUrl(baseUrl);
      setIframeUrl(
        surveyUrlBuilder(
          baseSurveyUrl,
          {
            basicConfiguration,
            customColors,
            rewardOptions,
            templateOptions,
          },
          affId,
          routes[routes.length - 1].path === 'reward-options'
        )
      );
    } else {
      setBaseSurveyUrl(baseUrl);
    }
  };

  const handleChangeQuestionId = (id) => {
    setQuestionId(id);
  };

  const btnIsDisabledClass = fields.display.value.length <= 0 || !colorTemplate ? 'disabled' : '';

  const handleOpenModal = () => {
    if (btnIsDisabledClass === 'disabled') {
      return;
    }

    Gtm.event('survey machine', 'Click', 'generate widget code');
    dispatch(
      openModal({
        name: 'SurveyGenerationModal',
        modalProps: {
          title: values.display,
          generateIframeUrl,
        },
      })
    );
  };

  return (
    <div className="container-fluid app-container" id="survey-generator-page">
      <div className="hidden-sm hidden-xs hidden-md hidden-mobile">
        <div className="row">
          <div className="col-md-9">
            <h1 className="primary-color">
              <FormattedMessage {...messages.surveyGeneratorPageTitle} />
              <Info
                onClick={() => {
                  Gtm.event('survey machine', 'Click', 'survey machine info icon');
                }}
                title={intl.formatMessage(messages.surveyGeneratorMoreInfosSurvey)}
                url="https://support.crakrevenue.com/knowledge-base/crakrevenue-survey-machine/"
              />
              <VideoModalIcon
                className="pointer"
                dispatch={dispatch}
                gtmEvent={() => {
                  Gtm.event('survey machine', 'Click', 'survey machine video icon');
                }}
                videoUrl="https://player.vimeo.com/video/391534250"
              />
            </h1>
          </div>
          <div className="col-md-3">
            <Tooltip
              button
              className={`btn btn-generate ${btnIsDisabledClass}`}
              id="generate-widget-tooltip"
              onClick={handleOpenModal}
              position="left"
              tooltip={getToolTip()}
            >
              {intl.formatMessage(WidgetSection.generateCode.button.text)}
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <InputText
            autoFocus
            className="col-md-4"
            field={fields.display}
            id="surveyDisplayName"
            label={messages.surveyGeneratorSurveyCampaignName}
            tabIndex={1}
            type="text"
          />
          <div className="col-md-4 col-md-offset-4">
            <span
              className="btn z-depth-2"
              id="payout-details-btn"
              onClick={() => {
                Gtm.event('survey machine', 'Click', 'payout details');
                dispatch(
                  openModal({
                    name: 'SurveyPayoutsModal',
                    modalProps: {
                      className: 'survey-payouts-modal',
                    },
                  })
                );
              }}
            >
              <FormattedMessage {...messages.genericTextPayoutDetails} />
              <i className="material-icons">&#xE227;</i>
            </span>
          </div>
        </div>
        <SurveyGeneratorTabs animated={animated} colorTemplate={colorTemplate} dispatch={dispatch} pathname={pathname} />
        <div className="content clearfix z-depth-2">
          {React.cloneElement(children, {
            animated,
            basicConfiguration,
            changeBaseSurveyUrl: changeBaseSurveyUrl,
            colorTemplate,
            dispatch,
            fieldsSettings,
            questions,
            questionIsFetching,
            rewardOptions,
            templateUrls,
            themes,
            questionId,
            handleChangeQuestionId: handleChangeQuestionId,
          })}
        </div>
        <SurveyIframe src={iframeUrl} />
      </div>
      <div className="not-available row visible-sm visible-xs visible-md visible-mobile">
        <div className="col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1 z-depth-2 card">
          <h1>
            <FormattedMessage {...WidgetSection.unavailableFeature.header.text} />
          </h1>
          <p className="visible-mobile hidden-desktop">
            <FormattedMessage {...WidgetSection.unavailableFeature.mobileContent.text} />
          </p>
          <p className="visible-desktop hidden-mobile">
            <FormattedMessage {...WidgetSection.unavailableFeature.desktopContent.text} />
          </p>
          <Link className="btn" onlyActiveOnIndex={false} to="/">
            <FormattedMessage {...genericTextBackToDashboard} />
          </Link>
        </div>
      </div>
    </div>
  );
};

SurveyGenerator.propTypes = {
  affId: PropTypes.string,
  animated: PropTypes.bool,
  basicConfiguration: PropTypes.object,
  children: PropTypes.object.isRequired,
  colorTemplate: PropTypes.number,
  creativeCode: PropTypes.any,
  creativeId: PropTypes.any,
  creativeIsFetching: PropTypes.bool.isRequired,
  creativeScript: PropTypes.any,
  customColors: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  questionIsFetching: PropTypes.bool.isRequired,
  questions: PropTypes.any,
  rewardOptions: PropTypes.object,
  routes: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  templateOptions: PropTypes.object,
  templateUrls: PropTypes.array,
  themes: PropTypes.array,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affId: state.profile.data.affInfos.id,
  animated: state.surveyGenerator.animated,
  basicConfiguration: state.form.basicConfiguration,
  colorTemplate: state.surveyGenerator.colorTemplate,
  creativeCode: state.surveyGenerator.creativeCode,
  creativeId: state.surveyGenerator.creativeId,
  creativeIsFetching: state.surveyGenerator.creativeIsFetching,
  creativeScript: state.surveyGenerator.creativeScript,
  customColors: state.form.customColors,
  questions: state.surveyGenerator.questions,
  questionIsFetching: state.surveyGenerator.questionIsFetching,
  rewardOptions: state.form.rewardOptions,
  skinConfigurations: state.skinConfigurations.data,
  templateOptions: state.form.templateOptions,
  templateUrls: state.surveyGenerator.templateUrls,
  themes: state.surveyGenerator.themes,
}))(
  injectIntl(
    reduxForm({
      form: 'surveyGenerator',
      touchOnChange: false,
      touchOnBlur: false,
      fields: ['display'],
      initialValues: {
        display: '',
      },
    })(SurveyGenerator)
  )
);

import { compose, createStore, applyMiddleware } from 'redux';
import { browserHistory } from 'react-router';
import { thunkMiddleware } from './middlewares/thunkMiddleware';
import rootReducer from './reducers';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const reduxRouterMiddleware = routerMiddleware(browserHistory);
const initialState = typeof window !== 'undefined' ? window.__INITIAL_STATE__ : {};
const middlewares = [thunkMiddleware, reduxRouterMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const configureStore = compose(applyMiddleware())(createStore);

export default configureStore(rootReducer, initialState, composedEnhancers);

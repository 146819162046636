import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getLocalStorageValue } from '../../utils/helpers';

import config from '../../config';
import { dismissNps, updateNps } from '../../actions/nps-actions';

const { NPS } = config;

class HomeSurvey extends Component {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);

    this.state = {
      dismissed: false,
      userValidatedSurvey: false,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage.bind(this), false);

    const self = this;

    if (!!window && Object.prototype.hasOwnProperty.call(window, 'onmessage')) {
      window.onmessage = function (e) {
        if (!!e.data && Object.prototype.hasOwnProperty.call(e.data, 'end') && e.data.end === true) {
          self.dismiss();
        }
      };
    }
  }

  receiveMessage(event) {
    const origin = event.origin || event.originalEvent.origin;
    const url = new URL(NPS.LINK);
    const surveyBaseUrl = `${url.protocol}//${url.hostname}`;
    if (origin !== surveyBaseUrl) {
      return;
    }

    switch (event.data) {
      case 'setTimestampDone':
        this.setState(() => ({ userValidatedSurvey: true }));
        this.updateNps();
        break;

      default:
        return;
    }
  }

  updateNps() {
    const { dispatch, isOverrided } = this.props;

    if (!isOverrided && !getLocalStorageValue('isOverrided')) {
      dispatch(updateNps());
    }
  }

  dismiss() {
    const { userValidatedSurvey, dismissed } = this.state;

    this.setState((state) => ({
      dismissed: !state.dismissed,
    }));

    if (userValidatedSurvey) {
      return;
    }

    if (!dismissed) {
      const { dispatch } = this.props;
      dispatch(dismissNps());
    }
  }

  render() {
    const { affId, mainMenuIsOpen } = this.props;
    const { dismissed } = this.state;

    const classNames = [dismissed ? 'closed' : 'open', mainMenuIsOpen ? undefined : 'extended'];

    return (
      <div className={classNames.join(' ')} id="home-survey">
        <div className="container">
          <div className="close" onClick={this.dismiss}>
            Dismiss
          </div>
          {/* eslint-disable-next-line */}
          <iframe src={`${NPS.LINK}${affId}`} />
        </div>
      </div>
    );
  }
}

HomeSurvey.propTypes = {
  affId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOverrided: PropTypes.bool,
  mainMenuIsOpen: PropTypes.bool.isRequired,
};

export default injectIntl(HomeSurvey);

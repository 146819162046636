import React, { useCallback } from 'react';
import { useAdToolsContext } from '../CreativesAdTools';

const Position = React.memo(({ verticalPosition, horizontalPosition }) => {
  const { postitialState, setPostitialState, gtmEvent } = useAdToolsContext();

  const centerSquare = verticalPosition === 'center' && horizontalPosition === 'center';

  const selected =
    postitialState.verticalPosition === verticalPosition && postitialState.horizontalPosition === horizontalPosition;

  const onClickHandler = centerSquare || selected ? false : true;

  const onClick = useCallback(() => {
    setPostitialState((state) => ({
      ...state,
      horizontalPosition,
      verticalPosition,
    }));
    gtmEvent(`horizontal position - ${horizontalPosition} / vertical position - ${verticalPosition}`);
  }, [verticalPosition, horizontalPosition]);

  return (
    <div
      className={`positionBox ${selected ? 'selected' : ''} ${centerSquare ? 'centerSquare' : ''}`}
      onClick={onClickHandler ? onClick : null}
    >
      <svg viewBox="0 0 100 100">
        <rect width="100" height="100" />
      </svg>
    </div>
  );
});

export default Position;

import OfferLandingPagesCard from '../OfferLandingPagesCard/OfferLandingPagesCard.react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../i18n/base-en';

const LandingPageSelection = ({
  optimizedList,
  checkedId,
  handleCheckId,
  handleSelectLanding,
  isReferrals,
  skinConfigurations,
  modalViewMode,
  searchResult,
  offersList,
}) => (
  <>
    {optimizedList.length > 0 && <span className="desktop-results">Landing Optimizer</span>}
    {optimizedList.map((optimizedLanding) => (
      <OfferLandingPagesCard
        checked={optimizedLanding.id === checkedId}
        handleCheckId={handleCheckId}
        handleSelectLanding={handleSelectLanding}
        isAutoOptimized
        isReferrals={isReferrals}
        key={optimizedLanding.id}
        landingPage={optimizedLanding}
        skinConfigurations={skinConfigurations}
        viewMode={modalViewMode}
      />
    ))}
    {searchResult > 0 && <span className="desktop-results">{searchResult} results</span>}

    {searchResult === 0 && (
      <div className="no-result">
        <span className="primary-color">
          <i className="material-icons warning-change-error-icon">warning</i>
        </span>
        <p>
          <FormattedMessage {...messages.noResultlandingPage} />
        </p>
        <button
          className="waves-effect waves-light btn"
          onClick={() => {
            window.Intercom('showNewMessage');
          }}
        >
          <FormattedMessage {...messages.contactUsBtn} />
        </button>
      </div>
    )}
    {offersList.map(
      (landingPage) =>
        landingPage.id !== 'optimized' && (
          <OfferLandingPagesCard
            checked={landingPage.id === checkedId}
            handleCheckId={handleCheckId}
            handleSelectLanding={handleSelectLanding}
            isReferrals={isReferrals}
            key={landingPage.id}
            landingPage={landingPage}
            skinConfigurations={skinConfigurations}
            viewMode={modalViewMode}
          />
        )
    )}
  </>
);

export default LandingPageSelection;

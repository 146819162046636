import PropTypes from 'prop-types';
import DatingSmartlinkIcon from './DatingSmartlinkIcon';
import { Radio } from '../../components/reusables';
import messages from '../../i18n/base-en';
import CamSmartlinkIcon from './CamSmartlinkIcon';
import MixedSmartlinkIcon from './MixedSmartlinkIcon';
import intercom from '../../utils/intercom';
import { Gtm } from '../../utils/gtm';
import AISmartlinkIcon from './AISmartlinkIcon';
import UpgradedSmartlinkIcon from './UpgradedSmartlinkIcon';
import { useSlider } from './SliderContext.react';
import { useCallback } from 'react';

const SmartlinkChoice = ({ smartLinks, selectedOffer, fields, intl, setSelectedOffer, values, getBackOffers }) => {
  const { nextSlide } = useSlider();
  const handleChangeSmartLinkOfferId = useCallback(
    (e) => {
      nextSlide();
      fields.offer_id.onChange(e.target.value);
      fields.target.onChange(undefined);
      fields.popUnder.onChange(true);
      intercom.trackEvent('Smartlink-Select-Vertical');

      Object.entries(smartLinks).forEach(([key, value]) => {
        if (e.target.value === value?.id?.toString()) {
          Gtm.event('dating smartlink', 'click', `smartlink radio - ${intl.formatMessage(messages[value?.label])}`);
          setSelectedOffer(value?.id);
        }
      });

      if (values.bo) {
        const bo = getBackOffers(e.target.value);
        fields.bo.onChange(bo ? bo : '');
      }

      Gtm.newEvent('smartlink_step1', false, 'click', 'string', fields.offer_id);
    },
    [nextSlide, fields, smartLinks, setSelectedOffer, values.bo, getBackOffers, intl]
  );

  const offerDatingLabel = smartLinks.offerDating.label;

  const smartLinkOrdering = Object.entries(smartLinks).reverse();
  const smartLinkOptions = smartLinkOrdering.filter(
    (smartlink) => smartlink[0] !== 'topLabel' && smartlink[0] !== smartLinks.offerDating.label
  );
  return (
    <div className="smart-link-container">
      <div className="header-container smartlink-choice">
        <div className="header-box">
          <h1>
            <svg fill="none" height="60" viewBox="0 0 61 60" width="61" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M40.2287 41.591L33.8647 47.9549C31.6639 50.1558 29.0122 51.2557 25.9098 51.2547C22.8074 51.2536 20.1557 50.1537 17.9548 47.9549C15.754 45.7562 14.6541 43.1046 14.6551 40C14.6562 36.8954 15.7561 34.2438 17.9548 32.045L24.3188 25.6811L27.5008 28.8631L21.1368 35.227C19.811 36.5529 19.1481 38.1438 19.1481 40C19.1481 41.8562 19.811 43.4471 21.1368 44.773C22.4626 46.0988 24.0536 46.7617 25.9098 46.7617C27.766 46.7617 29.3569 46.0988 30.6828 44.773L37.0467 38.409L40.2287 41.591ZM29.0918 40L25.9098 36.818L38.6377 24.0901L41.8197 27.2721L29.0918 40ZM43.4107 38.409L40.2287 35.227L46.5927 28.8631C47.9185 27.5372 48.5814 25.9463 48.5814 24.0901C48.5814 22.2339 47.9185 20.643 46.5927 19.3171C45.2668 17.9913 43.6758 17.3284 41.8197 17.3284C39.9635 17.3284 38.3726 17.9913 37.0467 19.3171L30.6828 25.6811L27.5008 22.4991L33.8647 16.1351C36.0656 13.9343 38.7178 12.8338 41.8213 12.8338C44.9248 12.8338 47.5759 13.9343 49.7746 16.1351C51.9734 18.336 53.0738 20.9882 53.076 24.0917C53.0781 27.1952 51.9776 29.8463 49.7746 32.045L43.4107 38.409Z"
                fill="#00BD00"
              />
            </svg>
            Create Your Smartlink!
          </h1>
          <span className="upgraded">
            <UpgradedSmartlinkIcon /> Upgraded
          </span>
        </div>
        <div className="smartlink-description smartlink-description-choice">
          <p>Use our powerful tool’s latest version to maximize your earnings with one powerful link.</p>
          <p>Let a perfect combination of artificial & business intelligence work for you!</p>
        </div>
      </div>
      <div className="radio-buttons">
        <label
          className={selectedOffer === smartLinks.offerDating.id ? 'selected radio-box' : 'radio-box'}
          htmlFor={smartLinks.offerDating.id}
        >
          <span className="recommended-tag">
            <svg fill="none" height="13" viewBox="0 0 12 13" width="12" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.77461 5.99843C9.5471 5.48639 9.21638 5.02681 8.80313 4.64843L8.46211 4.33554C8.45053 4.32521 8.43659 4.31788 8.42152 4.31419C8.40645 4.3105 8.3907 4.31057 8.37566 4.31439C8.36062 4.3182 8.34674 4.32566 8.33525 4.33609C8.32377 4.34652 8.31501 4.35961 8.30977 4.37421L8.15742 4.81132C8.0625 5.08554 7.88789 5.36562 7.64062 5.64101C7.62422 5.65859 7.60547 5.66328 7.59258 5.66445C7.57969 5.66562 7.55977 5.66328 7.54219 5.64687C7.52578 5.63281 7.51758 5.61171 7.51875 5.59062C7.56211 4.88515 7.35117 4.08945 6.88945 3.22343C6.50742 2.5039 5.97656 1.94257 5.31328 1.55117L4.8293 1.2664C4.76602 1.2289 4.68516 1.27812 4.68867 1.35195L4.71445 1.91445C4.73203 2.29882 4.6875 2.63867 4.58203 2.92109C4.45312 3.26679 4.26797 3.58789 4.03125 3.87617C3.86651 4.07652 3.6798 4.25773 3.47461 4.4164C2.98044 4.79628 2.57861 5.28304 2.29922 5.84023C2.02052 6.40228 1.87534 7.02108 1.875 7.64843C1.875 8.20156 1.98398 8.7371 2.19961 9.24218C2.40781 9.72847 2.70827 10.1698 3.08438 10.5418C3.46406 10.9168 3.90469 11.2121 4.3957 11.4172C4.9043 11.6305 5.44336 11.7383 6 11.7383C6.55664 11.7383 7.0957 11.6305 7.6043 11.4184C8.0941 11.2145 8.53951 10.9171 8.91562 10.543C9.29531 10.168 9.59297 9.72968 9.80039 9.24335C10.0157 8.73966 10.1261 8.19738 10.125 7.6496C10.125 7.07773 10.0078 6.52226 9.77461 5.99843Z"
                fill="#A91A90"
              />
            </svg>
            Hot pick
          </span>
          <DatingSmartlinkIcon />
          <Radio
            field={fields.offer_id}
            id={smartLinks.offerDating.id}
            label={messages[offerDatingLabel]}
            onClick={(e) => {
              handleChangeSmartLinkOfferId(e);
            }}
            value={smartLinks.offerDating.id}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: smartLinks.offerDating.description,
            }}
          />
          <span className="get-started-btn">Get Started</span>
        </label>
        {smartLinkOptions.map((smartlink) => (
          <label
            className={selectedOffer === smartlink[1].id ? 'selected radio-box' : 'radio-box'}
            htmlFor={smartlink[1].id}
            key={smartlink.id}
          >
            {smartlink[1].label === 'offerCam' && <CamSmartlinkIcon />}
            {smartlink[1].label === 'offerGlobalAdult' && <MixedSmartlinkIcon />}
            {smartlink[1].label === 'offerAI' && <AISmartlinkIcon />}
            <Radio
              field={fields.offer_id}
              id={smartlink[1].id}
              label={messages[smartlink[1].label]}
              onClick={(e) => {
                handleChangeSmartLinkOfferId(e);
              }}
              value={smartlink[1].id}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: smartlink[1].description,
              }}
            />
            <span className="get-started-btn">Get Started</span>
          </label>
        ))}
      </div>
    </div>
  );
};

SmartlinkChoice.propTypes = {
  fields: PropTypes.shape({
    offer_id: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    target: PropTypes.shape({
      onChange: PropTypes.func,
    }),
    popUnder: PropTypes.shape({
      onChange: PropTypes.func,
    }),
    bo: PropTypes.shape({
      onChange: PropTypes.func,
    }),
  }).isRequired,
  getBackOffers: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  selectedOffer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedOffer: PropTypes.func.isRequired,
  smartLinks: PropTypes.shape({
    offerDating: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    // Define other smartLink structures if necessary
  }).isRequired,

  values: PropTypes.shape({
    bo: PropTypes.bool,
  }).isRequired,
};

export default SmartlinkChoice;

const PaymentHistory = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.8048 17.4516C9.35616 17.4514 8.91184 17.3637 8.4968 17.1932L6.78325 16.4932C6.67647 16.45 6.56186 16.4295 6.44673 16.4329C6.3316 16.4364 6.21846 16.4637 6.11445 16.5132L5.50561 16.8044C5.26718 16.9192 5.00354 16.9715 4.73936 16.9565C4.47516 16.9414 4.2191 16.8596 3.99521 16.7185C3.77132 16.5774 3.58694 16.3818 3.45932 16.1501C3.33172 15.9182 3.26505 15.6578 3.26563 15.3932L3.27364 5.58838C3.27364 3.28278 4.65763 1.84998 6.88643 1.84998H12.7264C14.944 1.84998 16.3209 3.28278 16.3209 5.58918L16.3344 15.3908C16.3349 15.6554 16.2682 15.9157 16.1406 16.1476C16.013 16.3793 15.8286 16.575 15.6048 16.7161C15.381 16.8572 15.125 16.9392 14.8608 16.9543C14.5966 16.9695 14.333 16.9173 14.0944 16.8028L13.4912 16.514C13.3874 16.4641 13.2742 16.4364 13.159 16.4328C13.0438 16.4292 12.9292 16.4497 12.8224 16.4932L11.1048 17.194C10.6922 17.3631 10.2507 17.4505 9.8048 17.4516ZM6.47041 15.2324C6.73319 15.2326 6.99344 15.2837 7.23682 15.3828L8.9512 16.0828C9.49768 16.3068 10.1104 16.3068 10.6568 16.0828L12.3744 15.382C12.6365 15.2753 12.918 15.2245 13.2008 15.2329C13.4836 15.2413 13.7616 15.3088 14.0169 15.4308L14.6193 15.7188C14.6745 15.7451 14.7355 15.757 14.7966 15.7534C14.8578 15.7498 14.9169 15.7308 14.9686 15.6981C15.0204 15.6655 15.0631 15.6202 15.0926 15.5666C15.1222 15.513 15.1377 15.4528 15.1377 15.3916L15.124 5.58918C15.124 4.42998 14.7089 3.04918 12.7297 3.04918H6.88965C5.33365 3.04918 4.47685 3.95078 4.47685 5.58838L4.46884 15.394C4.46875 15.4552 4.48417 15.5154 4.51372 15.5691C4.54327 15.6227 4.58593 15.6679 4.63774 15.7005C4.68955 15.7332 4.74884 15.7521 4.80996 15.7556C4.87108 15.759 4.93209 15.7469 4.98725 15.7204L5.59521 15.4292C5.86913 15.2989 6.16873 15.2317 6.47202 15.2324H6.47041Z"
      fill="white"
    />
    <path
      d="M10.3856 11.9585H8.49996C8.34084 11.9585 8.1882 11.8953 8.07572 11.7827C7.96316 11.6703 7.89995 11.5176 7.89995 11.3585C7.89995 11.1994 7.96316 11.0467 8.07572 10.9343C8.1882 10.8217 8.34084 10.7585 8.49996 10.7585H10.3856C10.4956 10.7585 10.6013 10.7147 10.6792 10.6369C10.757 10.5591 10.8008 10.4534 10.8008 10.3433C10.8008 10.2331 10.757 10.1275 10.6792 10.0497C10.6013 9.97187 10.4956 9.92811 10.3856 9.92811H9.22556C8.80332 9.91867 8.40156 9.74435 8.1062 9.44243C7.81088 9.14043 7.64551 8.73483 7.64551 8.31251C7.64551 7.89014 7.81088 7.48456 8.1062 7.1826C8.40156 6.88064 8.80332 6.7063 9.22556 6.6969H11.1112C11.2703 6.6969 11.4229 6.76012 11.5355 6.87264C11.648 6.98516 11.7112 7.13777 11.7112 7.2969C11.7112 7.45604 11.648 7.60865 11.5355 7.72117C11.4229 7.83369 11.2703 7.8969 11.1112 7.8969H9.22556C9.11852 7.9016 9.0174 7.94744 8.94324 8.02491C8.86916 8.10227 8.8278 8.20531 8.8278 8.31251C8.8278 8.41971 8.86916 8.52275 8.94324 8.60011C9.0174 8.67755 9.11852 8.72339 9.22556 8.72811H10.3856C10.814 8.72811 11.2248 8.89827 11.5277 9.20115C11.8306 9.50411 12.0008 9.91491 12.0008 10.3433C12.0008 10.7717 11.8306 11.1825 11.5277 11.4854C11.2248 11.7883 10.814 11.9585 10.3856 11.9585Z"
      fill="white"
    />
    <path
      d="M9.80459 12.809C9.64539 12.809 9.49283 12.7458 9.38027 12.6333C9.26779 12.5208 9.20459 12.3681 9.20459 12.209V11.3562C9.20459 11.1971 9.26779 11.0445 9.38027 10.932C9.49283 10.8194 9.64539 10.7562 9.80459 10.7562C9.96371 10.7562 10.1163 10.8194 10.2288 10.932C10.3413 11.0445 10.4046 11.1971 10.4046 11.3562V12.213C10.4035 12.3715 10.3398 12.523 10.2274 12.6347C10.115 12.7464 9.96299 12.809 9.80459 12.809Z"
      fill="white"
    />
    <path
      d="M9.80459 7.89752C9.64539 7.89752 9.49283 7.83431 9.38027 7.72179C9.26779 7.60926 9.20459 7.45664 9.20459 7.29752V6.43752C9.20459 6.27839 9.26779 6.12579 9.38027 6.01327C9.49283 5.90074 9.64539 5.83752 9.80459 5.83752C9.96371 5.83752 10.1163 5.90074 10.2288 6.01327C10.3413 6.12579 10.4046 6.27839 10.4046 6.43752V7.29752C10.4046 7.45664 10.3413 7.60926 10.2288 7.72179C10.1163 7.83431 9.96371 7.89752 9.80459 7.89752Z"
      fill="white"
    />
  </svg>
);

export default PaymentHistory;

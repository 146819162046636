export default {
  'smartLink.create': 'Create your Smartlink!',
  'smartLink.selectTrafficType': 'Select the type of traffic, add a traffic source and copy/paste the link on your website.',
  'smartLink.selectTrafficSource': 'Select your traffic source',
  'smartLink.advancedSettings': 'Advanced Settings',
  'smartLink.customize': 'Customize your Smartlink',
  'smartLink.extra': 'Extra earnings',
  'smartLink.disabled': 'Disabled when slider is grey',
  'smartLink.selectAdTools': 'Select your AdTool',
  'smartLink.yourLink': 'Your link',
  'smartLink.adTools': 'AdTools',
  'smartLink.getStarted': 'The EASIEST WAY to GET STARTED',
  'smartLink.smartLinks': 'Smartlinks',
  'smartLink.fastAndEasy': "It's FAST and EASY",
  'smartLink.selectYourTraffic': 'Select the type of traffic you have',
  'smartLink.addTrafficSource': 'Add a traffic source <span>(it will come in handy analyzing your stats)</span>',
  'smartLink.copyPaste': 'COPY / PASTE the link on your website',
  'smartLink.weTakeCare': 'We take care of the rest!',
  'smartLink.source': 'Custom Source 1',
  'smartLink.PleaseNote':
    '<span><strong>Please note :</strong> the information you provide below is indispensable to us and is an important indicator in determining whether or not you are a good fit for our network. Please be accurate and honest. Thank you!</span>',
  'smartLink.approvalPending': 'Your application for "{name}" is pending.',
  'smartLink.isRestricted': 'This smartLink is available on request only',
  'smartLink.requestSent': 'Your request has been sent!',
  'smartLink.smartlinkLabel': 'Smartlink',
  'smartLink.promotionMedium': 'Promotion Medium',
  'smartLink.payoutType': 'Payout Type',
};

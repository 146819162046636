import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { guid } from '../../utils/helpers';

// TODO: remove jQuery from project
import $ from 'jquery';

class Options extends Component {
  constructor(props) {
    super(props);

    this.uuid = `dropdown-${guid()}`;
    this.dropdownButton = createRef();
  }

  componentDidMount() {
    $(this.dropdownButton.current).dropdown({
      constrain_width: false,
      belowOrigin: true,
      alignment: 'left',
    });
  }

  render() {
    const { className, menu } = this.props;

    return (
      <div className={className} id="options">
        <div className="dropdown-button" data-activates={this.uuid} ref={this.dropdownButton}>
          <i className="material-icons">more_vert</i>
        </div>
        <ul className="menu z-depth-2" id={this.uuid}>
          {menu.map((item, index) => (
            <li className="item basic-bg-colors" key={`item-${index}`}>
              {item.action ? <div onClick={item.action}>{item.label}</div> : null}
              {item.link ? <a {...item.link}>{item.label}</a> : null}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

Options.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.array.isRequired,
};

export default Options;

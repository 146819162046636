import { getApiQlStrings } from './Offers';

export default (search) => {
  const { filtersString, sortString } = getApiQlStrings(search);

  return `
  Offers(
        sorts:[${sortString}]
        filters:[${filtersString}]
        ${search.limit && search.page ? `pager: {size:${search.limit}, page:${search.page - 1}}` : ``}
        ) {
        Data{
          id
          name
          description
          default_payout
          payout_type
          percent_payout
          approval_status
          featured
          top_countries
          epc_affiliation
          epc_global
          channels {
            id
            name
          }
          countries {
            id
            code
            name
          }
          verticals {
            id
            name
          }
          conversion_types {
            name
          },
          categories {
            id,
            name,
          },
          thumbnail{
            thumbnail
          }
          tags {
            id
            name
          }
        }
        PageInfo{
          length,
          currentPage,
          lastPage,
          pageSize
        }
      }
  `;
};

import React from 'react';
import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { Gtm } from '../../utils/gtm';
import parse from 'react-html-parser';

const JerkmateHeader = () => {
  const { sliderSectionRef } = useJerkmateOffersContext();

  const advantages = [
    {
      description: '$100M+ paid in<br />affiliate commissions',
    },
    {
      description:
        'Official sponsor of<br />the <a href="https://www.youtube.com/@pillowtalkwithryan" target="_blank"><u>Pillow Talk</u></a><br />podcast',
    },
    {
      description: '#1 best-converting<br />adult site (USA)',
    },
    {
      description: 'Machine learning-<br />powered landing<br />page rotation',
    },
  ];

  const scrollToSlider = () => {
    const siteHeaderHeight = document.getElementById('site-header').offsetHeight;
    const target = sliderSectionRef.current;
    window.scroll({ top: target.offsetTop - siteHeaderHeight - 15, behavior: 'smooth' });
    Gtm.event(`jerkmate page`, 'Click', 'access_offers');
  };

  return (
    <div className="header-box">
      <div className="description-box">
        <h1>
          Promote the
          <br />
          <span className="jerkmate-pink">Best-Paying</span> Cam
          <br />
          Offers in the
          <br className="brDesktop" />
          <span> Industry</span>
        </h1>
        <p>
          Earn your biggest payouts yet with the largest and best-converting cam site. <br /> Gear yourself up with our exclusive
          and dedicated support!
        </p>
        <div className="jerkmate-btn" onClick={scrollToSlider}>
          See All Jerkmate Offers
        </div>
      </div>
      <div className="advantages-box">
        {advantages.map((advantage, index) => (
          <div className="advantage-box" key={index}>
            <div className="imgCircle">
              <img alt="Jerkmate" className="jerky-coin-right-top" src={`/img/jerkmate2/jerky-header-icon-${index}.svg`} />
            </div>
            <p>{parse(advantage.description)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JerkmateHeader;

import _ from 'lodash';
import moment from 'moment';

import { stripValuesFromDataObj, detectMobileAndTablet } from '../utils/helpers';
import consts from '../utils/consts';

const {
  ACTIONS,
  MAXIMUM_STATS_ROWS_SELECTED,
  COLUMNS_DATE,
  STATS_COLUMNS_ORDER,
  COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT,
  STATS_REPORTS,
  MOBILE_STATS_REPORTS,
} = consts;
const { DAILY } = detectMobileAndTablet() ? MOBILE_STATS_REPORTS : STATS_REPORTS;

export const initialState = {
  filters: {
    tags: {},
    values: {},
  },
  columnsFilters: [],
  date_start: null,
  date_end: null,
  timezone: '-5',
  hour_offset: 0,
  columns: DAILY.columns,
  groups: DAILY.groups,
  sort: DAILY.sort,
  queries: {
    1: {
      response: {
        data: {
          data: [],
        },
      },
    },
  },
  queriesLevel: 1,
  statisticsSettings: {
    page: 1,
    limit: 25,
  },
  statisticsInfos: {
    currentPage: 1,
    total: 0,
    pageCount: 0,
  },
  reportMainMenu: 'Performance',
  reportSubMenu: 'Daily',
  statisticsReceivedAt: null,
  statisticsIsFetching: false,
  statisticsGraphIsFetching: false,
  selectedRows: [],
  selectedRowsValues: {},
  selectedFilters: {},
  drillDownValues: {
    1: {
      filters: {},
      fields: DAILY.columns,
      groups: DAILY.groups,
    },
  },
  graphData: {},
  graphGroups: ['Stat.date'],
  graphFields: ['Stat.erpc'],
  statisticsSummary: {},
  statisticsSaveReportIsFetching: false,
  statisticsSavedReportsIsFetching: false,
  statisticsDeleteReportIsFetching: false,
  statisticsDrillBreadcrumb: {},
  savedReportsList: [],
  newRequest: true,
};

export const statistics = (state = initialState, action = {}) => {
  const filtersState = { ...state.filters };
  const sortState = { ...state.sort };
  const filtersTagsState = filtersState.tags;
  const filtersValuesState = filtersState.values;
  const settingsState = state.statisticsSettings;
  const drillDownValues = state.drillDownValues;
  const lastDrillDownFilters = drillDownValues[state.queriesLevel].filters;
  const queriesState = state.queries;
  const drillBreadcrumb = state.statisticsDrillBreadcrumb;
  const selectedRowsArray = [];
  const newSort = {};
  const newSelectedRowValues = {};
  const newRequestState = state.newRequest;
  let selectedRowsState = state.selectedRows;
  let selectedRowsValues = state.selectedRowsValues;
  let fields = [];
  let count;
  let queryLevels = {};
  let newFiltersTagsState;
  let newFiltersValuesState;
  let newDrillDownValues;
  let newGroups;
  let newQueries;
  let newTags;
  let newValues;
  let newColumns;
  let report;
  let lastDrillDown = {};
  let groups;
  let filterName = '';
  let reportIndex = -1;

  switch (action.type) {
    case ACTIONS.SET_STATISTICS_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          tags: action.tags,
          values: action.values,
        },
        newRequest: action.newRequest,
      };

    case ACTIONS.REMOVE_STATISTICS_SEARCH_FILTERS_TAG:
      filtersTagsState[action.id].forEach((tag) => {
        if (tag.value === action.value || (typeof tag.value.join === 'function' && tag.value.join(',') === action.value)) {
          filterName = tag.filter;
        }
      });

      if (filtersTagsState[action.id] instanceof Array) {
        newFiltersTagsState = filtersTagsState[action.id].filter(
          (tag) => tag.value !== action.value && action.compare !== 'between'
        );
      }

      if (filtersValuesState[action.id] instanceof Array) {
        newFiltersValuesState = filtersValuesState[action.id].filter(
          (value) => value !== action.value && action.compare !== 'between'
        );
      }

      // enlever le filtre du state drilldown
      if (typeof lastDrillDownFilters[filterName] !== 'undefined') {
        // s'il y a une seule valeur, supprimer le filtre complet
        if (
          lastDrillDownFilters[filterName].values.length === 1 &&
          (lastDrillDownFilters[filterName].values[0] === action.value ||
            lastDrillDownFilters[filterName].values[0] === action.value[0])
        ) {
          delete lastDrillDownFilters[filterName];
        } else if (
          lastDrillDownFilters[filterName].values.indexOf(action.value) >= 0 ||
          _.isEqual(lastDrillDownFilters[filterName].values, action.value)
        ) {
          lastDrillDownFilters[filterName].values = _.without(lastDrillDownFilters[filterName].values, ...action.value);

          if (lastDrillDownFilters[filterName].values.length === 0) {
            delete lastDrillDownFilters[filterName];
          }
        }

        newDrillDownValues = {
          ...drillDownValues,
        };
        newDrillDownValues[state.queriesLevel].filters = lastDrillDownFilters;
      } else {
        newDrillDownValues = {
          ...drillDownValues,
        };
      }

      newTags = { ...filtersTagsState };
      newTags[action.id] = newFiltersTagsState;
      newValues = filtersValuesState;
      newValues[action.id] = newFiltersValuesState;

      return {
        ...state,
        filters: {
          ...filtersState,
          tags: {
            ...newTags,
          },
          values: {
            ...newValues,
          },
        },
        drillDownValues: newDrillDownValues,
        selectedRows: [],
        selectedRowsValues: {},
        newRequest: action.newRequest,
      };

    case ACTIONS.REMOVE_ALL_STATISTICS_FILTERS:
      return {
        ...state,
        filters: {
          ...filtersState,
          tags: {},
          values: {},
        },
      };

    case ACTIONS.RESET_IS_FETCHING:
      return {
        ...state,
        statisticsIsFetching: false,
      };

    case ACTIONS.REQUEST_STATISTICS:
      return {
        ...state,
        statisticsIsFetching: true,
      };

    case ACTIONS.RECEIVE_STATISTICS:
      if (action.data.request.fields instanceof Array) {
        fields = action.data.request.fields;
      } else {
        fields = Object.keys(action.data.request.fields).map((key) => action.data.request.fields[key]);
      }

      queryLevels[action.level] = action.data;
      queryLevels = {
        ...queriesState,
        ...queryLevels,
      };

      lastDrillDown[action.level] = {
        filters: action.data.request.filters,
        groups: action.data.request.groups,
        fields,
      };

      if (newRequestState) {
        selectedRowsState = [];
        selectedRowsValues = {};
      }

      return {
        ...state,
        sort: action.data.request.sort,
        columns: fields,
        queries: queryLevels,
        queriesLevel: action.level,
        statisticsInfos: {
          currentPage: action.data.response.data.page || 1,
          total: action.data.response.data.count || 0,
          pageCount: action.data.response.data.pageCount || 1,
        },
        statisticsSettings: {
          page: +action.data.request.page,
          limit: action.data.request.limit,
        },
        selectedRows: selectedRowsState,
        selectedRowsValues,
        statisticsIsFetching: false,
        statisticsReceivedAt: Date.now(),
        drillDownValues: {
          ...drillDownValues,
          ...lastDrillDown,
        },
        newRequest: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_STATISTICS:
      return {
        ...state,
        statisticsIsFetching: false,
      };

    case ACTIONS.REQUEST_GRAPH_STATISTICS:
      return {
        ...state,
        statisticsGraphIsFetching: true,
      };

    case ACTIONS.RECEIVE_GRAPH_STATISTICS:
      return {
        ...state,
        graphData: {
          ...action.data,
        },
        statisticsGraphIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_GRAPH_STATISTICS:
      return {
        ...state,
        statisticsGraphIsFetching: false,
      };

    case ACTIONS.RECEIVE_ALL_STATISTICS:
      return {
        ...state,
        statisticsSummary: action.data,
        statisticsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALL_STATISTICS:
      return {
        ...state,
        statisticsIsFetching: false,
      };

    case ACTIONS.STATISTICS_ADD_SELECTED_ROW:
      newValues = stripValuesFromDataObj(JSON.parse(action.value));
      selectedRowsState = [...selectedRowsState, action.value];

      selectedRowsValues = { ...selectedRowsValues };
      selectedRowsValues[selectedRowsState.length - 1] = newValues;
      return {
        ...state,
        selectedRows: [...selectedRowsState],
        selectedRowsValues,
      };

    case ACTIONS.STATISTICS_ADD_ALL_SELECTED_ROWS:
      selectedRowsValues = { ...selectedRowsValues };
      if (typeof state.queries[state.queriesLevel].response !== 'undefined') {
        count =
          state.queries[state.queriesLevel].response.data.count > state.queries[state.queriesLevel].response.data.current
            ? state.queries[state.queriesLevel].response.data.current
            : state.queries[state.queriesLevel].response.data.count;
        for (let i = 0; i < count; i++) {
          if (i < MAXIMUM_STATS_ROWS_SELECTED) {
            selectedRowsArray.push(i);
            selectedRowsValues[i] = stripValuesFromDataObj(state.queries[state.queriesLevel].response.data.data[i]);
          } else {
            break;
          }
        }
      }

      return {
        ...state,
        selectedRows: selectedRowsArray,
        selectedRowsValues,
      };

    case ACTIONS.STATISTICS_REMOVE_SELECTED_ROW:
      selectedRowsValues = { ...selectedRowsValues };
      delete selectedRowsValues[action.int];
      Object.keys(selectedRowsValues).forEach((key, index) => {
        newSelectedRowValues[index] = selectedRowsValues[key];
      });
      selectedRowsState = _.without(selectedRowsState, selectedRowsState[action.int]);

      return {
        ...state,
        selectedRows: [...selectedRowsState],
        selectedRowsValues: newSelectedRowValues,
      };

    case ACTIONS.STATISTICS_SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.values,
        selectedRowsValues: {},
      };

    case ACTIONS.STATISTICS_CHANGE_COLUMN_SORT:
      newSort[action.field] = action.way;

      return {
        ...state,
        sort: newSort,
      };

    case ACTIONS.CHANGE_STATISTICS_LIST_PAGE:
      return {
        ...state,
        statisticsSettings: {
          ...settingsState,
          page: +action.page,
        },
      };

    case ACTIONS.CHANGE_STATISTICS_ROWS_PER_PAGE:
      return {
        ...state,
        statisticsSettings: {
          ...settingsState,
          limit: action.limit,
          page: 1,
        },
      };

    case ACTIONS.SET_STATISTICS_COLUMNS:
      newDrillDownValues = drillDownValues;
      newDrillDownValues[state.queriesLevel].fields = action.columns;
      newGroups = state.groups.map((group) => {
        if (action.columns.indexOf(group) !== -1) {
          return group;
        }
      });

      if (action.columns.indexOf('Goal.name') > 0) {
        newGroups.push('Goal.name');
      } else {
        newGroups = _.without(newGroups, 'Goal.name', 'Goal.id');
      }

      action.columns.forEach((column) => {
        if (COLUMNS_DATE.indexOf(column) >= 0 && Object.keys(sortState).indexOf(column) < 0) {
          newSort[column] = 'desc';
        }
      });

      if (Object.keys(newSort).length <= 0 && action.columns.indexOf('Stat.payout') >= 0) {
        newSort['Stat.payout'] = 'desc';
      } else {
        newColumns = action.columns;
        newColumns.sort((a, b) => STATS_COLUMNS_ORDER.indexOf(a) - STATS_COLUMNS_ORDER.indexOf(b));

        newSort[newColumns[0]] =
          COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(newColumns[0]) >= 0 || COLUMNS_DATE.indexOf(newColumns[0]) >= 0
            ? 'desc'
            : 'asc';
      }

      return {
        ...state,
        columns: action.columns,
        groups: [...newGroups],
        sort: {
          ...sortState,
          ...newSort,
        },
        drillDownValues: newDrillDownValues,
      };

    case ACTIONS.SET_STATISTICS_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };

    case ACTIONS.SET_STATISTICS_FILTERS:
      return {
        ...state,
        selectedFilters: action.filters,
      };

    case ACTIONS.SET_STATISTICS_COLUMNS_FILTERS:
      return {
        ...state,
        columnsFilters: action.filters,
      };

    case ACTIONS.SET_STATISTICS_REPORT:
      report = action.report;
      if (report) {
        state.drillDownValues[1].fields.shift();
      }

      if (report && Object.prototype.hasOwnProperty.call(report, 'filters')) {
        delete report.filters.tags['Stat.date'];
      }

      newColumns = [...state.columns];

      if (report !== null) {
        newColumns = [...newColumns, ...report.columns];
      }

      newColumns = _.uniq(newColumns);

      newColumns.sort((a, b) => STATS_COLUMNS_ORDER.indexOf(a) - STATS_COLUMNS_ORDER.indexOf(b));

      return {
        ...state,
        ...report,
        columns: newColumns,
        queriesLevel: 1,
        drillDownValues: {
          1: {
            filters: {},
            groups: report ? report.groups : state.drillDownValues[1].groups,
            fields: report ? newColumns : state.drillDownValues[1].fields,
          },
        },
        selectedRows: [],
        selectedRowsValues: {},
        reportMainMenu: action.mainMenu || state.mainMenu,
        reportSubMenu: action.subMenu || state.subMenu,
        statisticsDrillBreadcrumb: {},
      };

    case ACTIONS.SET_STATISTICS_DATES:
      report = { ...drillDownValues };
      newQueries = { ...queriesState };

      Object.keys(report).forEach((key) => {
        if (key > 1) {
          delete report[key];
          delete newQueries[key];
        }
      });

      return {
        ...state,
        date_start: moment.isMoment(action.startDate) ? action.startDate.format('YYYY-MM-DD') + ' 00:00:00' : action.startDate,
        date_end: moment.isMoment(action.endDate) ? action.endDate.format('YYYY-MM-DD') + ' 23:59:59' : action.endDate,
        timezone: action.timezone,
        groups: [...state.groups],
        queries: newQueries,
        queriesLevel: 1,
        drillDownValues: {
          1: {
            filters: {},
            fields: [...state.columns],
            groups: [...state.groups],
          },
        },
        statisticsDrillBreadcrumb: {},
      };

    case ACTIONS.SET_DRILL_DOWN_VALUES:
      lastDrillDown = drillDownValues[action.level - 1];
      groups = lastDrillDown.groups || {};
      groups = _.uniq([...groups, action.group]);

      drillDownValues[action.level] = {
        ...lastDrillDown,
        filters: action.obj,
        fields: [groups[groups.length - 1]] || [], // make sure the drilldown column is included in the query
        groups,
      };

      drillBreadcrumb[action.level] = {
        field: action.drillField,
        value: action.drillValue,
      };

      newColumns = _.without(state.columns, action.drillField);

      newColumns.forEach((column) => {
        if (COLUMNS_DATE.indexOf(column) >= 0 && Object.keys(sortState).indexOf(column) < 0) {
          newSort[column] = 'desc';
        }
      });

      if (Object.keys(newSort).length <= 0 && newColumns.indexOf('Stat.payout') >= 0) {
        newSort['Stat.payout'] = 'desc';
      } else {
        newColumns.sort((a, b) => STATS_COLUMNS_ORDER.indexOf(a) - STATS_COLUMNS_ORDER.indexOf(b));

        newSort[newColumns[0]] =
          COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(newColumns[0]) >= 0 || COLUMNS_DATE.indexOf(newColumns[0]) >= 0
            ? 'desc'
            : 'asc';
      }

      return {
        ...state,
        groups,
        columns: [...newColumns],
        queriesLevel: action.level,
        drillDownValues: {
          ...drillDownValues,
        },
        statisticsDrillBreadcrumb: {
          ...drillBreadcrumb,
        },
        sort: newSort,
      };

    case ACTIONS.SET_DRILL_DOWN_LEVEL_BACK:
      report = { ...drillDownValues };
      newQueries = { ...queriesState };

      Object.keys(report).forEach((key) => {
        if (key >= action.level + 1) {
          delete report[key];
          delete newQueries[key];
          delete drillBreadcrumb[key];
        }
      });

      return {
        ...state,
        queries: newQueries,
        queriesLevel: action.level,
        groups: [...drillDownValues[action.level].groups],
        drillDownValues: report,
        sort: newQueries[Object.keys(newQueries).pop()].request.sort,
        statisticsDrillBreadcrumb: {
          ...drillBreadcrumb,
        },
      };

    case ACTIONS.RESET_STATISTICS:
      return {
        ...initialState,
        savedReportsList: state.savedReportsList,
      };

    case ACTIONS.REQUEST_SAVE_REPORT:
      return {
        ...state,
        statisticsSaveReportIsFetching: true,
      };

    case ACTIONS.RECEIVE_SAVE_REPORT:
      return {
        ...state,
        statisticsSaveReportIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_SAVE_REPORT:
      return {
        ...state,
        statisticsSaveReportIsFetching: false,
      };

    case ACTIONS.REQUEST_SAVED_REPORTS:
      return {
        ...state,
        statisticsSavedReportsIsFetching: true,
      };

    case ACTIONS.RECEIVE_SAVED_REPORTS:
      return {
        ...state,
        statisticsSavedReportsIsFetching: false,
        savedReportsList: action.savedReportsList,
      };

    case ACTIONS.REQUEST_DELETE_REPORT:
      return {
        ...state,
        statisticsDeleteReportIsFetching: true,
      };

    case ACTIONS.RECEIVE_DELETE_REPORT:
      state.savedReportsList.map((savedReport, index) => {
        if (savedReport.id === action.id) {
          reportIndex = index;
        }
      });

      return {
        ...state,
        statisticsDeleteReportIsFetching: false,
        savedReportsList: [...state.savedReportsList.slice(0, reportIndex), ...state.savedReportsList.slice(reportIndex + 1)],
      };

    case ACTIONS.FAILED_TO_RECEIVE_DELETE_REPORT:
      return {
        ...state,
        statisticsDeleteReportIsFetching: false,
      };

    default:
      return state;
  }
};

import consts from './consts';
import _ from 'lodash';

export const getAffiliateRankClass = (rankName) => {
  if (rankName) {
    if (rankName.toLowerCase().indexOf('elite') >= 0) {
      return 'elite';
    }
    if (rankName.toLowerCase().indexOf('vip') >= 0) {
      return 'vip';
    }
    if (rankName.toLowerCase().indexOf('rising star') >= 0) {
      return 'rising star';
    }
  }
  return 'active';
};

export const isActiveRankFromRankStatusName = (rankName) => {
  if (rankName) {
    return rankName.toLowerCase().indexOf('active') >= 0;
  }
  return true;
};

export const isRisingStarRankFromRankStatusName = (rankName) => {
  if (rankName) {
    return rankName.toLowerCase().indexOf('rising star') >= 0;
  }
  return false;
};

export const isVIPRankFromRankStatusName = (rankName) => {
  if (rankName) {
    return rankName.toLowerCase().indexOf('vip') >= 0;
  }
  return false;
};

export const isEliteRankFromRankStatusName = (rankName) => {
  if (rankName) {
    return rankName.toLowerCase().indexOf('elite') >= 0;
  }
  return false;
};

export const getAffiliateCurrentRankTier = (quaterlyRank) => {
  for (const element of Object.values(consts.DESCENDING_RANKS)) {
    if (quaterlyRank <= element.rank) {
      return element;
    }
  }
  return null;
};

export const findRankTierByRankValue = (affNextBrackEntryName) =>
  _.find(consts.DESCENDING_RANKS, { rank: affNextBrackEntryName });

export const getNextRankFlashing = (dailyRank, quaterlyRank, currentRankTier) => {
  let nextRank = null;
  if (dailyRank < quaterlyRank) {
    for (const element of Object.values(consts.DESCENDING_RANKS)) {
      if (element.rank === null) {
        return null;
      }

      if (dailyRank <= element.rank + 50) {
        if (element.rank === currentRankTier?.rank) {
          return null;
        }
        nextRank = element;
        break;
      }
    }
  }
  return nextRank;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import messages from '../i18n/base-en.js';

import ProfileHeader from './profile/ProfileHeader.react';
import { AlertHeader, LoadingPage, Scroller } from '../components/reusables';

import { fetchPaymentInfo } from '../components/Application/actions/application-actions';
import { fetchAchConfigurations } from '../actions/ach-configurations-actions';

import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingFormIsChanged: false,
      isLoading: true,
    };

    this.changeBillingFormDisplayStatus = this.changeBillingFormDisplayStatus.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { skinConfigurations } = this.props;

    this.skin = new SkinConfigurationsUtils(skinConfigurations);
    this.skin.manageAccess(browserHistory, 'profile');

    const { dispatch } = this.props;
    Promise.all([dispatch(fetchPaymentInfo()), dispatch(fetchAchConfigurations())]);
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  getAffStatusRankName(statusRankName) {
    switch (statusRankName) {
      case 'Active Affiliate':
        return this.skin.getPropertyValue('Header', 'activeUserLabel', 'activeUser') || 'Active Affiliate';
      case 'VIP Affiliate':
        return this.skin.getPropertyValue('Header', 'vipUserLabel', 'vipUser') || 'VIP Affiliate';
      case 'Elite Club':
        return this.skin.getPropertyValue('Header', 'eliteUserLabel', 'eliteUser') || 'Elite Club';
      default:
        return null;
    }
  }

  changeBillingFormDisplayStatus(state) {
    this.setState({
      billingFormIsChanged: state,
    });
  }

  render() {
    const {
      location,
      userInfos,
      userInfosIsFetching,
      companyInfos,
      emailPreferencesForm,
      dispatch,
      salutations,
      isConnected,
      bestTimesToCall,
      paymentHistoryViewMode,
      paymentMethods,
      achConfigurations,
      achConfigurationsIsFetching,
      referrals,
      referralsSettings,
      referralsInfos,
      referralsTimeStamp,
      referralsViewMode,
      minimumPayoutList,
      payableTo,
      paymentHistory,
      minimumPayout,
      profileUserInfosForm,
      profileCompanyInfosForm,
      billingInfosForm,
      settingsForm,
      countries,
      timezones,
      affStatus,
      paymentTerm,
      offers,
      profileCompleted,
      skinConfigurations,
      children,
    } = this.props;
    const { isLoading, billingFormIsChanged } = this.state;
    return (
      <div id="profile">
        {isLoading ? <LoadingPage /> : null}
        <ProfileHeader
          affStatus={affStatus}
          company={companyInfos.company || ''}
          minimumPayout={minimumPayout}
          payableTo={payableTo}
          paymentHistory={paymentHistory}
          paymentTerm={paymentTerm}
          profileCompleted={profileCompleted}
          skinConfigurations={skinConfigurations}
          statusRankName={this.getAffStatusRankName(affStatus.rank_status_name)}
          userInfos={userInfos}
        />
        {billingFormIsChanged === true && (
          <AlertHeader className="container-fluid app-container" iconIsVisible localStorageString="billinginfos-alert">
            <span>
              <FormattedMessage {...messages.profileBillingInfoNotice} />
            </span>
          </AlertHeader>
        )}

        <div className="container-fluid">
          <div className="row app-container navigation-container">
            <Scroller classNames={['tabs', 'text-center']}>
              <ul>
                <li className={location.pathname === '/profile/user-details' ? 'active' : ''}>
                  <Link onlyActiveOnIndex={false} to="/profile/user-details">
                    <FormattedMessage {...messages.profileMenuUserInfos} />
                  </Link>
                </li>
                <li className={location.pathname === '/profile/identity' ? 'active' : ''}>
                  <Link onlyActiveOnIndex={false} to="/profile/identity">
                    <FormattedMessage {...messages.profileMenuIdentity} />
                  </Link>
                </li>
                <li className={location.pathname === '/profile/billing-infos' ? 'active' : ''}>
                  <Link onlyActiveOnIndex={false} to="/profile/billing-infos">
                    <FormattedMessage {...messages.profileMenuBillingInfosTitle} />
                  </Link>
                </li>
                {/* <li className={(location.pathname === '/profile/email-preferences') ? 'active' : ''}><Link to="/profile/email-preferences" onlyActiveOnIndex={false}><FormattedMessage {...messages.profileMenuEmailPreferences} /></Link></li> */}
                {this.skin.hasAccessTo('ProfileNotifications') && (
                  <li className={location.pathname === '/profile/notifications' ? 'active' : ''}>
                    <Link onlyActiveOnIndex={false} to="/profile/notifications">
                      <FormattedMessage {...messages.profileMenuNotifications} />
                    </Link>
                  </li>
                )}
              </ul>
            </Scroller>
          </div>
        </div>

        <div className="profile-children">
          {children
            ? React.cloneElement(children, {
                isConnected,
                userInfos,
                userInfosIsFetching,
                companyInfos,
                emailPreferencesForm,
                dispatch,
                salutations,
                bestTimesToCall,
                paymentHistoryViewMode,
                paymentHistory,
                paymentMethods,
                achConfigurations,
                achConfigurationsIsFetching,
                referrals,
                referralsSettings,
                referralsInfos,
                referralsTimeStamp,
                referralsViewMode,
                minimumPayoutList,
                payableTo,
                profileUserInfosForm,
                profileCompanyInfosForm,
                billingInfosForm,
                settingsForm,
                countries,
                timezones,
                paymentTerm,
                offers,
                profileCompleted,
                changeBillingFormDisplayStatus: this.changeBillingFormDisplayStatus,
              })
            : null}
        </div>
      </div>
    );
  }
}

Profile.defaultProps = {
  minimumPayout: '',
  payableTo: '',
};

Profile.propTypes = {
  achConfigurations: PropTypes.array.isRequired,
  achConfigurationsIsFetching: PropTypes.bool,
  affStatus: PropTypes.object.isRequired,
  bestTimesToCall: PropTypes.array.isRequired,
  billingInfosForm: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  companyInfos: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  emailPreferencesForm: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isConnected: PropTypes.bool,
  location: PropTypes.object.isRequired,
  minimumPayout: PropTypes.any,
  minimumPayoutList: PropTypes.array.isRequired,
  offers: PropTypes.object.isRequired,
  payableTo: PropTypes.any,
  paymentHistory: PropTypes.object.isRequired,
  paymentHistoryViewMode: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  paymentTerm: PropTypes.string.isRequired,
  profileCompanyInfosForm: PropTypes.object.isRequired,
  profileCompleted: PropTypes.object,
  profileUserInfosForm: PropTypes.object.isRequired,
  referrals: PropTypes.array.isRequired,
  referralsInfos: PropTypes.object.isRequired,
  referralsSettings: PropTypes.object.isRequired,
  referralsTimeStamp: PropTypes.number.isRequired,
  referralsViewMode: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  salutations: PropTypes.array,
  settingsForm: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  timezones: PropTypes.array.isRequired,
  userInfos: PropTypes.object.isRequired,
  userInfosIsFetching: PropTypes.bool,
};

export default provideHooks()(
  connect((state) => ({
    achConfigurations: state.achConfigurations.data,
    achConfigurationsIsFetching: state.achConfigurations.isFetching,
    affStatus: state.profile.data.affStatus,
    bestTimesToCall: state.application.lists.bestTimesToCall,
    billingInfosForm: state.form.billingInfos || {},
    companyInfos: state.profile.data.affInfos,
    countries: state.application.lists.countries,
    emailPreferencesForm: state.form.emailPreferences || {},
    isConnected: state.login.isConnected,
    minimumPayout:
      state.profile.data.customProfile.affiliate.minimum_payout !== undefined
        ? state.profile.data.customProfile.affiliate.minimum_payout.amount || ''
        : '',
    minimumPayoutList: state.application.lists.minimumPayouts,
    offers: state.offers,
    payableTo: state.profile.data.customProfile.affiliate.payable_to_whom,
    paymentHistory: state.profile.data.paymentHistory,
    paymentHistoryViewMode: state.application.ui.paymentHistoryViewMode,
    paymentMethods: state.application.lists.paymentMethods,
    paymentTerm: state.profile.data.customProfile.affiliate.payment_term || '',
    profileCompanyInfosForm: state.form.profileCompanyInfos || {},
    profileCompleted: state.profileCompleted,
    profileUserInfosForm: state.form.profileUserInfos || {},
    referrals: state.referrals.referrals,
    referralsInfos: state.referrals.referralsInfos,
    referralsSettings: state.referrals.referralsSettings,
    referralsTimeStamp: state.referrals.referralsTimeStamp,
    referralsViewMode: state.application.ui.referralsViewMode,
    salutations: state.application.lists.salutations,
    settingsForm: state.form.settings || {},
    skinConfigurations: state.skinConfigurations.data,
    timezones: state.application.lists.timezones,
    userInfos: state.profile.data.affUserInfos,
    userInfosIsFetching: state.profile.isFetching,
  }))(injectIntl(Profile))
);

import consts from '../utils/consts';

const {
  ACTIONS: { CONNECTION_SETTINGS },
} = consts;

const initialState = {
  isFetching: false,
};

export const connectionSettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONNECTION_SETTINGS.REQUEST_UPDATE_CONNECTION_SETTINGS:
      return {
        isFetching: true,
      };

    case CONNECTION_SETTINGS.RECEIVE_UPDATE_CONNECTION_SETTINGS:
      return {
        isFetching: false,
      };

    case CONNECTION_SETTINGS.FAILED_TO_UPDATE_CONNECTION_SETTINGS:
      return {
        isFetching: false,
      };

    default:
      return {
        ...state,
      };
  }
};

import PropTypes from 'prop-types';

function AlignCenter({ isActive }) {
  let classes = 'align-icon align-center';

  if (isActive) {
    classes += ' active';
  }

  return (
    <svg className={classes} height="16px" viewBox="0 0 17 16" width="17px">
      <g fill="none" fillRule="evenodd" id="align-center-svg" stroke="none" strokeWidth="1">
        <g id="03_Survey_BasicConfiguration" transform="translate(-1284.000000, -399.000000)">
          <g id="Settings" transform="translate(81.000000, 233.000000)">
            <g id="Custom-Logo-URL" transform="translate(654.000000, 138.000000)">
              <g id="Alignement-logo" transform="translate(518.000000, 28.000000)">
                <g id="Page-1-Copy-6" transform="translate(31.000000, 0.000000)">
                  <g className="darken-color" fill="#888888" id="Group-7">
                    <path
                      d="M8.5,2 C8.224,2 8,1.872 8,1.71428571 L8,0.285714286 C8,0.128 8.224,0 8.5,0 C8.776,0 9,0.128 9,0.285714286 L9,1.71428571 C9,1.872 8.776,2 8.5,2"
                      id="Fill-1"
                    />
                    <path
                      d="M8.5,10 C8.224,10 8,9.8656 8,9.7 L8,7.3 C8,7.1344 8.224,7 8.5,7 C8.776,7 9,7.1344 9,7.3 L9,9.7 C9,9.8656 8.776,10 8.5,10"
                      id="Fill-3"
                    />
                    <path
                      d="M8.5,16 C8.224,16 8,15.872 8,15.7142857 L8,14.2857143 C8,14.128 8.224,14 8.5,14 C8.776,14 9,14.128 9,14.2857143 L9,15.7142857 C9,15.872 8.776,16 8.5,16"
                      id="Fill-5"
                    />
                    <path
                      d="M16.0503182,14 L0.949681818,14 C0.425386364,14 0,13.72475 0,13.3855 L0,9.6145 C0,9.27525 0.425386364,9 0.949681818,9 L16.0499318,9 C16.5746136,9 17,9.27525 17,9.6145 L17,13.38525 C17,13.72475 16.5746136,14 16.0503182,14"
                      id="Fill-7"
                    />
                  </g>
                  <path
                    className="lighten-color"
                    d="M14.0601765,7 L2.93982353,7 C2.42097059,7 2,6.72475 2,6.3855 L2,2.6145 C2,2.27525 2.42097059,2 2.93982353,2 L14.0597941,2 C14.5790294,2 15,2.27525 15,2.6145 L15,6.38525 C15,6.72475 14.5790294,7 14.0601765,7"
                    fill="#D4D4D4"
                    id="Fill-9"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

AlignCenter.propTypes = {
  isActive: PropTypes.bool,
};

export default AlignCenter;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

const PageTitle = (props) => {
  const { intl, offerName, routes } = props;

  const changePageTitle = () => {
    const routesArray = [...routes];
    if (typeof document !== 'undefined') {
      const routesTitles = routesArray.reverse().map((route) => {
        if (route.path === '/offers/:id') {
          return `${offerName}`;
        } else if (route.title) {
          return intl.formatMessage(route.title);
        }
      });

      const routesTitlesFiltered = routesTitles.filter((elem, index, self) => {
        if (elem) {
          return index === self.indexOf(elem);
        }
      });
      document.title = routesTitlesFiltered.join(' - ');
    }
  };

  useEffect(() => {
    changePageTitle();
  }, [routes]);

  return null;
};

PageTitle.propTypes = {
  intl: intlShape.isRequired,
  offerName: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
};

export default connect((state) => ({
  offerName: state.offer.offer.name || '',
}))(injectIntl(PageTitle));

import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Container } from '../../../components/reusables';

const OfferEmailInstructions = (props) => {
  const { approvalStatus, downloadLink, emailInstructions, emailInstructionsFrom, emailInstructionsSubject, showEmailPopup } =
    props;
  const isDisplayable = emailInstructions === '1' || !!downloadLink;
  const canAccess = approvalStatus === 'approved';
  const handleSuppressionList = (e) => {
    e.preventDefault();
    if (canAccess) {
      showEmailPopup();
    }
  };

  return isDisplayable ? (
    <Container
      className="z-depth-2 primary-color-title"
      collapsable
      icon="email"
      id="offer-email-instructions"
      isOpen={canAccess}
      title={<FormattedMessage {...messages.offerEmailInstructions} />}
    >
      {emailInstructions === '1' && (
        <div className="email-instructions">
          {!!emailInstructionsFrom && (
            <div>
              <h4>
                <FormattedMessage {...messages.offerApprovedFromLines} />
              </h4>
              <p className="instructions">{emailInstructionsFrom}</p>
            </div>
          )}
          {!!emailInstructionsSubject && (
            <div>
              <h4>
                <FormattedMessage {...messages.offerApprovedSubjectLines} />
              </h4>
              <p className="instructions">
                {emailInstructionsSubject.split('\n').map((txt, i) => (i === 0 ? txt : [<br key={`br-${i}`} />, txt]))}
              </p>
            </div>
          )}
        </div>
      )}
      {!!downloadLink && (
        <div className="suppression-list">
          <a
            className={`waves-effect waves-light btn ${canAccess ? '' : 'disabled'}`}
            href=""
            onClick={(e) => handleSuppressionList(e)}
          >
            <FormattedMessage {...messages.offerSuppressionList} />
            <i className="material-icons">file_download</i>
          </a>
          <div className="merge-list">
            <span className="primary-color">
              <FormattedMessage {...messages.genericTextImportant} />
            </span>{' '}
            : <FormattedMessage {...messages.offerMergeList} />
          </div>
        </div>
      )}
    </Container>
  ) : null;
};

OfferEmailInstructions.defaultProps = {
  emailInstructions: '0',
};

OfferEmailInstructions.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
  downloadLink: PropTypes.string,
  emailInstructions: PropTypes.string,
  emailInstructionsFrom: PropTypes.string,
  emailInstructionsSubject: PropTypes.string,
  showEmailPopup: PropTypes.func,
};

export default injectIntl(OfferEmailInstructions);

const FansRevenue = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M15.1147 18.2497L5.05352 18.1087C3.35716 18.0852 2.00008 16.6988 2.00008 14.9951V5.26662C1.98838 3.56296 3.34546 2.16478 5.04182 2.14128L15.103 2.00029C16.8344 1.97679 18.25 3.37497 18.25 5.11388V15.1361C18.25 16.875 16.8344 18.2732 15.103 18.2497H15.1147Z"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M8.54594 8.91706C8.32279 9.17064 7.93735 9.20107 7.67363 8.98807C7.34905 8.72434 6.63902 8.15632 6 7.62888C6.16229 8.6432 6.42601 10.6414 6.59845 11.8992C6.65931 12.3455 7.04475 12.6802 7.50119 12.6802H12.9988C13.4553 12.6802 13.8407 12.3455 13.9016 11.8992C14.074 10.6414 14.3377 8.65334 14.5 7.62888C13.8508 8.15632 13.151 8.72434 12.8264 8.98807C12.5626 9.20107 12.1772 9.17064 11.9541 8.91706C11.599 8.51134 10.7166 7.52745 10.25 7C9.79356 7.5173 8.9111 8.51134 8.54594 8.91706Z"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.49121 15H12.9888" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default FansRevenue;

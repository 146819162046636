const Community = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M1.87646 9.69304C1.87646 8.2925 3.0276 7.17444 4.42616 7.17444C5.13692 7.17444 5.78174 7.46232 6.24502 7.92809C7.34992 7.44673 8.63517 7.17444 9.99951 7.17444C11.3638 7.17444 12.649 7.44673 13.7539 7.92808C14.2172 7.46232 14.862 7.17444 15.5728 7.17444C16.9713 7.17444 18.1224 8.2925 18.1224 9.69304C18.1224 10.4528 17.7823 11.1305 17.2495 11.5904C17.3392 11.9326 17.3865 12.2872 17.3865 12.6504C17.3865 14.243 16.4855 15.6269 15.1453 16.5884C13.8039 17.5506 11.9826 18.1264 9.99951 18.1264C8.01637 18.1264 6.19507 17.5506 4.85373 16.5884C3.51343 15.6269 2.61251 14.243 2.61251 12.6504C2.61251 12.2872 2.65981 11.9327 2.74949 11.5904C2.21668 11.1305 1.87646 10.4528 1.87646 9.69304ZM4.42616 8.42446C3.6987 8.42446 3.12646 9.00195 3.12646 9.69304C3.12646 10.1625 3.38816 10.5776 3.78735 10.7982C4.06117 10.9495 4.18093 11.28 4.06765 11.5715C3.93313 11.9179 3.86251 12.279 3.86251 12.6504C3.86251 13.737 4.47531 14.7785 5.58236 15.5727C6.68837 16.3661 8.24806 16.8764 9.99951 16.8764C11.7509 16.8764 13.3106 16.3661 14.4166 15.5727C15.5237 14.7785 16.1365 13.737 16.1365 12.6504C16.1365 12.279 16.0658 11.9178 15.9313 11.5715C15.818 11.28 15.9378 10.9495 16.2116 10.7982C16.6108 10.5776 16.8724 10.1625 16.8724 9.69304C16.8724 9.00195 16.3003 8.42446 15.5728 8.42446C15.0999 8.42446 14.6892 8.67004 14.4618 9.03437C14.2897 9.31004 13.9351 9.40787 13.646 9.25937C12.6318 8.73846 11.373 8.42446 9.99951 8.42446C8.62601 8.42446 7.36717 8.73846 6.35293 9.25945C6.06387 9.40787 5.70924 9.31004 5.53718 9.03437C5.30977 8.67004 4.89902 8.42446 4.42616 8.42446Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.1471 3.125C14.8034 3.125 14.5249 3.40355 14.5249 3.74716C14.5249 4.09077 14.8034 4.36932 15.1471 4.36932C15.4907 4.36932 15.7692 4.09077 15.7692 3.74716C15.7692 3.40355 15.4907 3.125 15.1471 3.125ZM13.2749 3.74716C13.2749 2.71319 14.1131 1.875 15.1471 1.875C16.181 1.875 17.0192 2.71319 17.0192 3.74716C17.0192 4.78113 16.181 5.61932 15.1471 5.61932C14.1131 5.61932 13.2749 4.78113 13.2749 3.74716Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.385 3.29097L13.7489 3.95523C14.0813 4.04861 14.4264 3.85491 14.5198 3.52261C14.6131 3.19031 14.4194 2.84522 14.0871 2.75184L11.4994 2.02469C10.9025 1.85696 10.2922 2.24461 10.1903 2.85618L9.3836 7.69603C9.32685 8.03651 9.55685 8.3585 9.89735 8.41525C10.2379 8.472 10.5599 8.24201 10.6166 7.90153L11.385 3.29097Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.04025 13.1673C7.25243 12.895 7.64515 12.8463 7.91741 13.0584C8.50892 13.5194 9.22701 13.7859 9.99917 13.7859C10.7714 13.7859 11.4895 13.5194 12.081 13.0584C12.3533 12.8463 12.746 12.895 12.9582 13.1673C13.1703 13.4395 13.1217 13.8323 12.8493 14.0444C12.0528 14.6653 11.0676 15.0359 9.99917 15.0359C8.93084 15.0359 7.9457 14.6653 7.14905 14.0444C6.87679 13.8323 6.82807 13.4395 7.04025 13.1673Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.05176 10.8886C7.05176 10.4284 7.42486 10.0553 7.88509 10.0553H7.89163C8.35183 10.0553 8.725 10.4284 8.725 10.8886C8.725 11.3489 8.35183 11.722 7.89163 11.722H7.88509C7.42486 11.722 7.05176 11.3489 7.05176 10.8886Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.2793 10.8886C11.2793 10.4284 11.6524 10.0553 12.1126 10.0553H12.1191C12.5794 10.0553 12.9525 10.4284 12.9525 10.8886C12.9525 11.3489 12.5794 11.722 12.1191 11.722H12.1126C11.6524 11.722 11.2793 11.3489 11.2793 10.8886Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Community;

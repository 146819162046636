import React from 'react';
import { useAdToolsContext } from '../CreativesAdTools';
import { Link } from 'react-router';
const Intro = React.memo(() => {
  const { text } = useAdToolsContext();

  return (
    <div className="introBox">
      <div className="icon">
        <svg viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-2 -4.5)">
            <g transform="translate(2 4.5)">
              <path
                d="M9.905,0a3.91,3.91,0,0,1,4.113,4.061h0v6.878A3.91,3.91,0,0,1,9.905,15H4.113A3.91,3.91,0,0,1,0,10.939H0V4.061A3.91,3.91,0,0,1,4.113,0H9.905Zm8.053,2.379a1.383,1.383,0,0,1,1.373.064A1.43,1.43,0,0,1,20,3.662h0v7.676a1.429,1.429,0,0,1-.669,1.219,1.4,1.4,0,0,1-.743.216,1.384,1.384,0,0,1-.631-.153h0l-1.481-.747a1.623,1.623,0,0,1-.888-1.457h0V4.583a1.618,1.618,0,0,1,.888-1.456h0Z"
                data-name="Tracé 32"
                id="Tracé_32"
              />
            </g>
          </g>
        </svg>
        <div className="iconText">Play</div>
      </div>
      <div className="text">
        {text['intro.title'] ? <div className="title">{text['intro.title']}</div> : null}
        <p>
          {text['intro.paragraph'] ? (
            text['intro.paragraph']
          ) : (
            <>
              This Chat Head features pre-recorded content that will lead to the landing page you have chosen for this offer.{' '}
              <br /> If you want to promote live models, you can get the Live Chat Head from the
              <Link onlyActiveOnIndex to="/live-cam-widget">
                {' '}
                Live Cam Widget page
              </Link>
              .
            </>
          )}
        </p>
      </div>
    </div>
  );
});

export default Intro;

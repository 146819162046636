import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { fetchPromoTools } from '../PromoTools/PromoTools-Actions/PromoTools-Actions';

export const JERKMATE_OFFER_TAG = 'JM Page';
const MAX_COUNTRIES = 5;

const JerkmateOffersContext = createContext(undefined);

const JerkmateOffersProvider = ({ children, dispatch, offers, promoTools }) => {
  const sliderSectionRef = useRef(null);
  const [jerkmatePromotools, setJerkmatePromotools] = useState([]);

  const [promotool, setPromotool] = useState(null);

  useEffect(() => {
    dispatch(fetchPromoTools());
  }, []);

  useEffect(() => {
    setJerkmatePromotools(promoTools.filter((promotool) => promotool.show_jerkmate_page === 'yes'));
  }, [promoTools]);

  const jerkmateOffers = useMemo(() => {
    const taggedOffers = [];

    offers.forEach((offer) => {
      const { tags, countries } = offer;

      const taggedOffer = tags.find(({ name }) => name === JERKMATE_OFFER_TAG);

      if (taggedOffer) {
        const offerData = {
          ...offer,
          countries: countries.slice(0, MAX_COUNTRIES),
        };

        taggedOffers.push(offerData);
      }
    });

    return taggedOffers;
  }, [offers]);

  useEffect(() => {
    const body = document.querySelector('body');

    if (promotool) {
      body.classList.add('hidden-scroll');
    } else {
      body.classList.remove('hidden-scroll');
    }

    return () => {
      body.classList.remove('hidden-scroll');
    };
  }, [promotool]);

  if (!jerkmateOffers?.length) {
    return null;
  }

  return (
    <JerkmateOffersContext.Provider
      value={{
        jerkmateOffers,
        sliderSectionRef,
        jerkmatePromotools,
        promotool,
        setPromotool,
      }}
    >
      {children}
    </JerkmateOffersContext.Provider>
  );
};

export const useJerkmateOffersContext = () => useContext(JerkmateOffersContext);

export default connect((state) => ({
  offers: state.jerkmate.offers,
  promoTools: state.promoTools.promoTools,
}))(JerkmateOffersProvider);

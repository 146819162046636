import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import DropdownOuter from './DropdownOuter';
import DropdownTitle from './DropdownTitle';
import { SELECTABLE_TAB_CSS_NAME } from './values';
import FilterTab from './FilterTab';
import SearchBar from './SearchBar';
import CategoryRightTab from './CategoryRightTab';

const GreyTab = React.memo(({ field, options, label, customLabel, search, selectedValues, offersIsFetching }) => {
  const parentTabRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchFilterRef = useRef(null);
  const scrollableRef = useRef(null);

  const {
    showDropdown,
    setShowDropdown,
    activeFilters,
    searchOptions,
    distpachSearchFormChange,
    activateOuterBox,
    desktopMode,
    outerBoxActive,
  } = useOffersFilterContext();

  const [alignRight, setAlignRight] = useState(false);

  const activeDropdown = showDropdown === label;

  const searchFilterRefValue = searchFilterRef?.current?.value;
  const currentSearchOptions = searchOptions?.[field];

  const currentActiveFilters = useMemo(() => {
    return activeFilters?.[field]?.length ? activeFilters[field] : [];
  }, [activeFilters, field]);

  const selectOptions = useMemo(() => {
    let filteredOptions = searchFilterRefValue ? currentSearchOptions : options;

    filteredOptions = filteredOptions.filter((option) => {
      if (searchFilterRefValue) {
        return option.name.toLowerCase().includes(searchFilterRefValue.toLowerCase());
      }
      return true;
    });

    if (filteredOptions.some((option) => Object.keys(option).includes('code'))) {
      filteredOptions.sort((a, b) => a.name.localeCompare(b.name));
    }

    // Sort selectOptions so that active filters are always at the top
    return filteredOptions.sort((a, b) => {
      const isActiveA = currentActiveFilters.includes(a.id || a.code);
      const isActiveB = currentActiveFilters.includes(b.id || b.code);

      if (isActiveA && !isActiveB) {
        return -1; // a comes first
      }
      if (!isActiveA && isActiveB) {
        return 1; // b comes first
      }
      return 0; // no change in order
    });
  }, [searchFilterRefValue, currentSearchOptions, options, currentActiveFilters]);

  useEffect(() => {
    // SCROLL BACK TO TOP OF DIV
    if (outerBoxActive.subDropdown || showDropdown) {
      scrollableRef.current.scrollTop = 0;
    }
  }, [showDropdown, outerBoxActive]);

  const setOuterBox = (e) => {
    e.stopPropagation();
    activateOuterBox({ field });
  };

  const clearClick = () => {
    if (offersIsFetching) {
      return;
    }

    distpachSearchFormChange({ field, filters: '' });

    if (searchFilterRef.current) {
      searchFilterRef.current.value = '';
    }
  };

  useEffect(() => {
    // keep dropdowns inside of the screen
    const resize = () => {
      const parentTab = parentTabRef.current;
      const dropdown = dropdownRef.current;
      if (!dropdown) {
        return;
      }
      const dropdownWidth = dropdown.offsetWidth;
      const windowWidth = window.innerWidth - 20;

      const parentTabRect = parentTab.getBoundingClientRect();
      const left = parentTabRect.left;

      const total = left + dropdownWidth;

      if (total > windowWidth) {
        setAlignRight(true);
      } else {
        setAlignRight(false);
      }
    };

    window.addEventListener('resize', resize);
    resize();

    return () => window.removeEventListener('resize', resize);
  }, [label]);

  const toggleDropdown = (e) => {
    e.stopPropagation();

    if (activeDropdown && showDropdown) {
      setShowDropdown();
    } else {
      setShowDropdown(label);
    }
  };

  return (
    <div className={`${SELECTABLE_TAB_CSS_NAME} greyTab`} onClick={setOuterBox} ref={parentTabRef}>
      <div className={`trigger ${activeDropdown ? 'active' : ''}`} onClick={desktopMode ? toggleDropdown : null}>
        <div className="text">{label}</div>

        <svg className="arrowDown" viewBox="0 0 7.547 6.289" xmlns="http://www.w3.org/2000/svg">
          <g id="arrow-down" transform="translate(-6 -7)">
            <g id="Arrow---Down-2" transform="translate(6 7)">
              <path d="M3.062,6.057c-.036-.036-.192-.17-.321-.295A13.64,13.64,0,0,1,.211,2.117,2.9,2.9,0,0,1,0,1.376,1.216,1.216,0,0,1,.137.813a1.178,1.178,0,0,1,.569-.5A6.188,6.188,0,0,1,1.375.152,15.08,15.08,0,0,1,3.768,0a17.435,17.435,0,0,1,2.32.134,5.342,5.342,0,0,1,.835.214,1.123,1.123,0,0,1,.624.992v.036a3.068,3.068,0,0,1-.257.831A13.641,13.641,0,0,1,4.8,5.771a3.559,3.559,0,0,1-.348.3,1.122,1.122,0,0,1-.669.214,1.179,1.179,0,0,1-.716-.232" />
            </g>
          </g>
        </svg>

        <div className={`activeCircle ${currentActiveFilters.length ? 'active' : ''}`} />
      </div>

      <CategoryRightTab field={field} />

      <DropdownOuter
        className={`filtersDropdown ${activeDropdown ? 'active' : ''} ${alignRight ? 'alignRight' : ''}`}
        field={field}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        passedRef={dropdownRef}
      >
        <DropdownTitle clearClick={clearClick} field={field} label={customLabel || label} />

        {search ? <SearchBar field={field} options={options} searchFilterRef={searchFilterRef} /> : null}

        <div className="scrollable" ref={scrollableRef}>
          {selectOptions.map((option, i) => (
            <FilterTab
              field={field}
              key={i}
              selectedValues={selectedValues}
              text={option.name}
              value={option.id || option.code || option.name}
            />
          ))}
        </div>
      </DropdownOuter>
    </div>
  );
});

export default GreyTab;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoginErrors from '../components/Login/LoginErrors';
import OverrideForm from './override/OverrideForm.react';

import { fetchOverrideAuth } from '../components/Login/actions/login-actions';

const Override = ({ dispatch, error, location }) => (
  <div className="container-fluid app-container" id="override-page">
    <OverrideForm dispatch={dispatch} location={location} onSubmit={(data) => dispatch(fetchOverrideAuth(data))} />
    <LoginErrors dispatch={dispatch} error={error} showErrorTime={6000} />
  </div>
);

Override.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.any,
  location: PropTypes.object.isRequired,
};

export default connect((state) => ({
  error: state.login.error,
}))(Override);

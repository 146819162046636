export const JerkmateOfferCardFireIcon = () => {
  return (
    <div className="jerkmate-offer-card-fire-icon">
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Fire-Ico">
          <path
            id="Vector"
            d="M3.88945 4.4548C3.8271 5.1198 3.78335 6.29668 4.17601 6.79761C4.17601 6.79761 3.99116 5.5048 5.6482 3.88277C6.31538 3.2298 6.4696 2.34168 6.23663 1.67558C6.10429 1.29824 5.86257 0.986521 5.65257 0.768865C5.53007 0.640896 5.62413 0.429802 5.80241 0.437458C6.88085 0.485583 8.62866 0.785271 9.37132 2.64902C9.69726 3.46715 9.72132 4.31261 9.56601 5.1723C9.46757 5.72136 9.11757 6.94199 9.91601 7.09183C10.4859 7.19902 10.7615 6.74621 10.8851 6.42027C10.9365 6.28465 11.1148 6.25074 11.211 6.35902C12.1735 7.45386 12.2555 8.74339 12.0565 9.85355C11.6715 11.9995 9.4982 13.5614 7.33913 13.5614C4.64195 13.5614 2.49491 12.0181 1.9382 9.22464C1.71398 8.09699 1.82773 5.86574 3.56679 4.29074C3.69585 4.17261 3.90695 4.27761 3.88945 4.4548Z"
            fill="url(#paint0_radial_986_328)"
          />
          <path
            id="Vector_2"
            d="M8.32461 8.46781C7.33039 7.18812 7.77554 5.72797 8.01945 5.14609C8.05226 5.06953 7.96476 4.99734 7.89586 5.04437C7.4682 5.33531 6.59211 6.02 6.18414 6.98359C5.63179 8.28625 5.67117 8.9239 5.9982 9.70265C6.19508 10.1719 5.96648 10.2714 5.85164 10.2889C5.74007 10.3064 5.63726 10.232 5.55523 10.1544C5.31924 9.92781 5.15107 9.63996 5.06961 9.32312C5.05211 9.25531 4.96351 9.23672 4.92304 9.2925C4.61679 9.71578 4.4582 10.395 4.45054 10.8752C4.42648 12.3594 5.65257 13.5625 7.1357 13.5625C9.00492 13.5625 10.3666 11.4953 9.29257 9.76719C8.98086 9.26406 8.68773 8.93484 8.32461 8.46781Z"
            fill="url(#paint1_radial_986_328)"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_986_328"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(6.80492 13.5954) rotate(-179.751) scale(7.72053 12.6678)"
          >
            <stop offset="0.314" stopColor="#FF9800" />
            <stop offset="0.662" stopColor="#FF6D00" />
            <stop offset="0.972" stopColor="#F44336" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_986_328"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(7.23851 5.91263) rotate(90.5787) scale(8.07804 6.07933)"
          >
            <stop offset="0.214" stopColor="#FFF176" />
            <stop offset="0.328" stopColor="#FFF27D" />
            <stop offset="0.487" stopColor="#FFF48F" />
            <stop offset="0.672" stopColor="#FFF7AD" />
            <stop offset="0.793" stopColor="#FFF9C4" />
            <stop offset="0.822" stopColor="#FFF8BD" stopOpacity="0.804" />
            <stop offset="0.863" stopColor="#FFF6AB" stopOpacity="0.529" />
            <stop offset="0.91" stopColor="#FFF38D" stopOpacity="0.209" />
            <stop offset="0.941" stopColor="#FFF176" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

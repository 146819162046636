const ClickGold = () => (
  <svg fill="none" height="83" viewBox="0 0 59 83" width="59" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1439_2798)">
      <path
        d="M58.09 52.84L0 0L10.32 77.83L24.61 66.01L33.21 82.44L48.82 74.27L40.22 57.83L58.08 52.83L58.09 52.84ZM42.08 72.16L35.33 75.69L26.19 58.22L14.1 68.22L6.76 12.9L48.05 50.45L32.94 54.68L42.08 72.16Z"
        fill="#FFEF57"
      />
      <path
        d="M32.94 54.68L42.08 72.16L35.33 75.69L26.18 58.23L14.09 68.23L6.76001 12.9L48.05 50.46L32.94 54.68Z"
        fill="url(#paint0_linear_1439_2798)"
      />
      <path
        d="M32.5701 54.53L41.7401 72.06L37.5301 74.2601L35.4301 75.3601L34.8501 74.2601L26.2601 57.84L14.2801 67.75L10.6901 40.68L7.09009 13.55L32.4401 36.6L47.5401 50.34L32.5701 54.53Z"
        fill="url(#paint1_linear_1439_2798)"
      />
      <path
        d="M32.5699 54.53L41.7399 72.06L37.5299 74.26H34.8499L26.2599 57.84L14.2799 67.75L10.6899 40.68L32.4399 36.6L47.5399 50.34L32.5699 54.53Z"
        fill="url(#paint2_linear_1439_2798)"
        style={{ mixBlendMode: 'color-burn' }}
      />
      <path d="M26.1801 58.23L24.6201 66.01L33.2201 82.44L35.3301 75.69L26.1801 58.23Z" fill="url(#paint3_linear_1439_2798)" />
      <path d="M42.08 72.16L48.83 74.28L33.22 82.44L35.33 75.69L42.08 72.16Z" fill="#FBEA56" />
      <path
        d="M32.9399 54.6801L40.2299 57.8401L48.8299 74.2801L42.0799 72.1601L32.9399 54.6801Z"
        fill="url(#paint4_linear_1439_2798)"
      />
      <path d="M58.0899 52.84L48.0499 50.46L32.9399 54.68L40.2299 57.84L58.0899 52.84Z" fill="url(#paint5_linear_1439_2798)" />
      <path d="M26.1801 58.23L24.6201 66.01L10.3201 77.83L14.0901 68.23L26.1801 58.23Z" fill="url(#paint6_linear_1439_2798)" />
      <path d="M0 0L6.76 12.9L48.05 50.46L58.09 52.84L0 0Z" fill="url(#paint7_linear_1439_2798)" />
      <g opacity="0.75">
        <path
          d="M7.09008 13.55L47.5401 50.34L32.5701 54.53L41.7401 72.06L35.4301 75.36L26.2601 57.84L14.2801 67.75L7.09008 13.55ZM6.08008 11.61L6.35008 13.64L13.5401 67.84L13.7201 69.18L14.7601 68.32L26.0301 59L34.7701 75.7L35.1201 76.36L35.7801 76.01L42.0901 72.71L42.7501 72.36L42.4001 71.7L33.6601 54.99L47.7401 51.05L49.0501 50.68L48.0501 49.77L7.60008 12.99L6.08008 11.61Z"
          fill="#FBEA56"
        />
      </g>
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_1439_2798" x1="6.76001" x2="48.05" y1="44.3" y2="44.3">
        <stop stopColor="#FFEF57" />
        <stop offset="0.3" stopColor="#F9CF2B" />
        <stop offset="0.78" stopColor="#E4A10A" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_1439_2798" x1="7.09009" x2="47.5401" y1="44.45" y2="44.45">
        <stop stopColor="#ED9E00" stopOpacity="0.2" />
        <stop offset="0.97" stopColor="#E38802" stopOpacity="0.98" />
        <stop offset="1" stopColor="#E38702" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint2_linear_1439_2798" x1="27.2199" x2="34.9999" y1="38.51" y2="81.75">
        <stop stopColor="#E6E7E8" />
        <stop offset="1" stopColor="#6D6E71" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint3_linear_1439_2798" x1="24.6201" x2="35.3301" y1="70.33" y2="70.33">
        <stop stopColor="#E38702" />
        <stop offset="1" stopColor="#ED9E00" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_1439_2798"
        x1="42.2799"
        x2="29.8499"
        y1="62.2101"
        y2="82.4001"
      >
        <stop stopColor="#FFEF57" />
        <stop offset="0.3" stopColor="#F9CF2B" />
        <stop offset="0.78" stopColor="#E4A10A" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_1439_2798" x1="46.2499" x2="45.2599" y1="45.59" y2="56.6">
        <stop stopColor="#E38702" />
        <stop offset="1" stopColor="#ED9E00" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint6_linear_1439_2798" x1="20.2801" x2="17.7101" y1="45.45" y2="74.11">
        <stop stopColor="#E38702" />
        <stop offset="1" stopColor="#ED9E00" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint7_linear_1439_2798" x1="23.81" x2="36.58" y1="-2.7" y2="68.26">
        <stop stopColor="#FFEF57" />
        <stop offset="0.3" stopColor="#F9CF2B" />
        <stop offset="0.78" stopColor="#E4A10A" />
      </linearGradient>
      <clipPath id="clip0_1439_2798">
        <rect fill="white" height="82.44" width="58.09" />
      </clipPath>
    </defs>
  </svg>
);

export default ClickGold;

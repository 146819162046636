import { Component } from 'react';
import PropTypes from 'prop-types';

const initScrolling = () => window.crakrevenue.initTabsScrolling();

class Scroller extends Component {
  componentDidMount() {
    initScrolling();
    window.addEventListener('resize', initScrolling);
  }

  componentDidUpdate() {
    initScrolling();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', initScrolling);
  }

  render() {
    const { children, classNames, id } = this.props;

    return (
      <div className="scroller link-tabs" id={id}>
        <div className="row">
          <div className="col-xs-12">
            <nav className={classNames.join(' ')} style={{ width: 'auto' }}>
              <div className="scroller-container">{children}</div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

Scroller.defaultProps = {
  classNames: [],
};

Scroller.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.array,
  id: PropTypes.string,
};

export default Scroller;

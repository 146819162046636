import { useState } from 'react';
import PropTypes from 'prop-types';
import { Gtm } from '../../../utils/gtm';
import { getClassName } from '../helpers';
import { openModal } from '../../Modal/modal-actions/modal-actions';

const PromoToolsCard = ({ promoTool, dispatch, affUserInfos, affiliateManager }) => {
  const [hover, setHover] = useState(false);
  const handleModalClick = () => {
    dispatch(
      openModal({
        name: 'PromoToolModal',
        modalProps: {
          dispatch,
          isLarge: true,
          promoTool,
          affUserInfos,
          affiliateManager,
          className: getClassName(promoTool),
        },
      })
    );
  };

  return (
    <div
      className="promoTool"
      onClick={() => {
        Gtm.event('promotools', 'Click', `${promoTool.title} card`);
        handleModalClick();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="promoTool__image">
        <img alt={promoTool.title} src={`${hover ? promoTool.icon_url_hover : promoTool.icon_url}`} />
      </div>
      <h1 className="promoTool__title">{promoTool.title}</h1>
      <p className="promoTool__description">{promoTool.header_text}</p>
      <button className="promoTool__btn">Choose this tool</button>
    </div>
  );
};

PromoToolsCard.propTypes = {
  affUserInfos: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  promoTool: PropTypes.object.isRequired,
};

export default PromoToolsCard;

export const BANNER_TAB_ALL_CREATIVES = 'ALL_CREATIVES';
export const BANNER_TAB_SELECTED_CREATIVES = 'SELECTED_CREATIVES';
export const BANNER_TAB_TRENDING_BANNERS = 'TRENDING_BANNERS';

export const USE_PROVEN_FLOW = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const useProvenFlow = urlParams.get('useProvenFlow');

  if (useProvenFlow === 'true') {
    return true;
  }

  return process.env.REACT_APP_USE_PROVEN_FLOW === 'true';
};

export const CLEAR_ALL_TEXT = {
  size: 'Reset',
  language: 'Clear All',
  format: 'Clear All',
};

export const CREATIVE_BANNER_TABS = [
  {
    key: BANNER_TAB_TRENDING_BANNERS,
    text: 'Trending',
    title: 'Trending Banners',
  },
  {
    key: BANNER_TAB_ALL_CREATIVES,
    text: 'All',
    title: 'All Banners',
  },
  {
    key: BANNER_TAB_SELECTED_CREATIVES,
    text: 'Selected',
    title: 'Selected Banners',
  },
];

export const TRENDING_BANNER_TABS = [];

export const CREATIVE_BANNER_LANGUAGE_LIST = [
  { key: 'EN', value: 'English' },
  { key: 'FR', value: 'French' },
  { key: 'ES', value: 'Spanish' },
  { key: 'DE', value: 'German' },
  { key: 'IT', value: 'Italian' },
  { key: 'NL', value: 'Dutch' },
];

export const CREATIVE_BANNER_FORMAT_LIST = [
  { key: 'jpg', value: 'jpg' },
  { key: 'gif', value: 'gif' },
];

export const BANNER_FILTERS = ['size', 'language', 'format'];

export const TRENDING_FILTERS = {
  COUNTRY_CODE: 'countryCode',
  DAYS: 'days',
};

export const BANNER_TRENDING_FILTERS = [
  {
    field: TRENDING_FILTERS.COUNTRY_CODE,
  },
  {
    text: 'last',
  },
  {
    field: TRENDING_FILTERS.DAYS,
  },
];

export const isSelectCreativesTabs = (key) => key === BANNER_TAB_SELECTED_CREATIVES;
export const isAllCreativesTab = (key) => key === BANNER_TAB_ALL_CREATIVES;
export const isTrendingCreativesTab = (key) => key === BANNER_TAB_TRENDING_BANNERS;

export const DEFAULT_BANNER_FILTER_STATES = Object.freeze({
  [BANNER_TAB_TRENDING_BANNERS]: {},
  [BANNER_TAB_ALL_CREATIVES]: {},
});

export const createFieldDisplayValue = ({ field, value }) => {
  if (!value) {
    switch (field) {
      case TRENDING_FILTERS.COUNTRY_CODE:
        return 'Country';
      default:
        return field;
    }
  }
  switch (field) {
    case TRENDING_FILTERS.DAYS:
      return `${value}D`;
    default:
      return value || field;
  }
};

export const createFieldTitle = ({ field }) => {
  switch (field) {
    case TRENDING_FILTERS.COUNTRY_CODE:
      return 'Country';
    default:
      return field;
  }
};

export const filterTrendingBannerDuplicateIds = (trendingBanners) => {
  const trending = [];

  trendingBanners.forEach((banner) => {
    const { id, rating } = banner;
    const index = trending.findIndex((existing) => existing.id === id);

    if (index < 0) {
      trending.push(banner);
    } else {
      const { rating: ratingExisting } = trending[index] || {};
      if (rating > ratingExisting) {
        trending[index] = banner;
      }
    }
  });

  return trending;
};

export const createTrendingCountries = (countries) => {
  if (!countries?.length) {
    return [];
  }

  countries = countries.map((country) => {
    try {
      return {
        key: country,
        value: new Intl.DisplayNames(['en'], { type: 'region' }).of(country),
      };
    } catch (e) {
      console.error(`countryCode ${country}, does not exist. Ignore in filters.`);
    }
  });

  countries = countries.filter((country) => country);

  countries.sort((a, b) => a.value.localeCompare(b.value));

  return countries;
};

export const createTrendingDays = (days) => {
  if (!days?.length) {
    return [];
  }

  days = days.map((day) => ({
    key: day,
    value: `${day} Days`,
  }));

  days.sort((a, b) => {
    return a.key - b.key;
  });

  return days;
};

export const createTrendingSizes = (sizes) => {
  if (!sizes?.length) {
    return [];
  }

  sizes = sizes.map((size) => ({
    key: size,
    value: size,
  }));

  sizes.sort((a, b) => a.key.localeCompare(b.key));

  return sizes;
};

export const createTrendingFormats = (formats) => {
  if (!formats?.length) {
    return [];
  }

  formats = formats.map((format) => ({
    key: format,
    value: format,
  }));

  formats.sort((a, b) => a.key.localeCompare(b.key));

  return formats;
};

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import consts from '../../../utils/consts';
import Tooltip from '../../../components/reusables/ReactTooltip';
import { Info } from '../../../components/reusables';

const { COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT } = consts;

const StatisticsTableHead = ({ handleColumnSort, statisticsFieldsAndColumns, statisticsSort }) => (
  <thead>
    <tr>
      <th />
      {statisticsFieldsAndColumns.map((column, index) => (
        <th
          className={[
            Object.prototype.hasOwnProperty.call(statisticsSort, column) ? 'sorted' : undefined,
            COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(column) >= 0 ? 'text-right' : undefined,
            column === 'Stat.paidConversions' && 'noSorting paidConversions',
          ].join(' ')}
          data-field={column}
          key={`stat-th-${index}`}
          onClick={(e) => {
            if (column !== 'Stat.paidConversions') {
              handleColumnSort(e);
            }
          }}
        >
          <FormattedMessage {...messages.statisticsColumns[column]} />
          {/*  Add tooltip here if it is the right column */}
          {column === 'Stat.paidConversions' && (
            <Tooltip
              backgroundColor="white"
              border
              borderColor="black"
              button
              className="statistics-paid-conversion-tooltip"
              closeDelay={400}
              html
              id="statistics-paid-conversion-tooltip"
              position="top"
              textColor="black"
              tooltip="The figures below may not be accurate if the goal set for the promoted offer includes both eligible and non-eligible conversions."
            >
              <Info className="statistics-paid-conversion-tooltip" />
            </Tooltip>
          )}
          {column !== 'Stat.paidConversions' && (
            <i className="material-icons arrowSort">{statisticsSort[column] === 'desc' ? 'arrow_downward' : 'arrow_upward'}</i>
          )}
        </th>
      ))}
    </tr>
  </thead>
);

StatisticsTableHead.propTypes = {
  handleColumnSort: PropTypes.func.isRequired,
  statisticsFieldsAndColumns: PropTypes.any.isRequired,
  statisticsSort: PropTypes.object.isRequired,
};

export default StatisticsTableHead;

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { setActionMessage } from '../../components/Application/actions/application-actions';
import { guid } from '../../utils/helpers';

import Clipboard from 'clipboard';
import CopyDiagonalIcon from './svg/CopyDiagonalIcon.react';

class Copy extends Component {
  constructor(props) {
    super(props);

    this.uuid = `copy-${guid()}`;
  }

  componentDidMount() {
    const { dispatch, intl, onCopy } = this.props;

    if (!!window && typeof window.Clipboard !== 'undefined') {
      this.clipboard = new Clipboard(`#${this.uuid}`);
      this.clipboard
        .on('success', (e) => {
          if (typeof onCopy === 'function') {
            onCopy();
          }

          const text = e.text.length > 170 ? `${e.text.substr(0, 170)}...` : e.text;
          dispatch(
            setActionMessage(
              'success',
              {
                text: intl.formatMessage(messages.genericTextTextCopied, { text }),
              },
              3000
            )
          );
          e.clearSelection();
        })
        .on('error', (e) => {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
        });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { clipboardTarget, disabled } = this.props;

    return disabled !== nextProps.disabled || !_.isEqual(clipboardTarget, nextProps.clipboardTarget);
  }

  componentWillUnmount() {
    if (window.Clipboard !== null && this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    const {
      buttonStyled,
      classNames,
      clipboardTarget,
      disabled,
      btnFlat = true,
      icon = 'link',
      text = <FormattedMessage {...messages.genericTextCopy} />,
      isSmartlink,
      isReferrals,
    } = this.props;

    return (
      <span
        className={[
          'copyBtn',
          btnFlat ? 'btn-flat' : '',
          disabled ? 'disabled' : undefined,
          buttonStyled ? 'copy-btn bg-primary-color' : 'primary-color',
          ...classNames,
        ].join(' ')}
        data-clipboard-target={disabled ? '' : clipboardTarget}
        data-cy={disabled ? '' : clipboardTarget}
        id={this.uuid}
      >
        {isSmartlink ? (
          <svg fill="none" height="14" viewBox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3125 1.75H5.3125C5.19647 1.75 5.08519 1.79609 5.00314 1.87814C4.92109 1.96019 4.875 2.07147 4.875 2.1875V4.375H2.6875C2.57147 4.375 2.46019 4.42109 2.37814 4.50314C2.29609 4.58519 2.25 4.69647 2.25 4.8125V11.8125C2.25 11.9285 2.29609 12.0398 2.37814 12.1219C2.46019 12.2039 2.57147 12.25 2.6875 12.25H9.6875C9.80353 12.25 9.91481 12.2039 9.99686 12.1219C10.0789 12.0398 10.125 11.9285 10.125 11.8125V9.625H12.3125C12.4285 9.625 12.5398 9.57891 12.6219 9.49686C12.7039 9.41481 12.75 9.30353 12.75 9.1875V2.1875C12.75 2.07147 12.7039 1.96019 12.6219 1.87814C12.5398 1.79609 12.4285 1.75 12.3125 1.75ZM9.25 11.375H3.125V5.25H9.25V11.375ZM11.875 8.75H10.125V4.8125C10.125 4.69647 10.0789 4.58519 9.99686 4.50314C9.91481 4.42109 9.80353 4.375 9.6875 4.375H5.75V2.625H11.875V8.75Z"
              fill="white"
            />
          </svg>
        ) : isReferrals ? (
          <CopyDiagonalIcon />
        ) : (
          <i className="material-icons">{icon}</i>
        )}
        {text ? <span className="text">{text}</span> : null}
      </span>
    );
  }
}

Copy.defaultProps = {
  btnFlat: true,
  buttonStyled: false,
  classNames: [],
};

Copy.propTypes = {
  btnFlat: PropTypes.bool,
  buttonStyled: PropTypes.bool,
  classNames: PropTypes.array,
  clipboardTarget: PropTypes.string,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  icon: PropTypes.any,
  intl: intlShape.isRequired,
  onCopy: PropTypes.func,
  text: PropTypes.any,
};

export default injectIntl(Copy);

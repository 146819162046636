import request from '../utils/Req';
import { getCookie } from '../utils/helpers';
import config from '../config';
import consts from '../utils/consts';
import { fetchProfile } from './profile-actions';

const { API_URL } = config;
const { ACTIONS } = consts;

// ACTIONS.AFFILIATE / SET_VERIFIED / SET_PENDING
const receiveJumioUrl = (data) => ({
  type: ACTIONS.AFFILIATE.RECEIVE_JUMIO_URL,
  url: data,
});

const failedToReceiveJumioUrl = (data) => ({
  type: ACTIONS.AFFILIATE.FAILED_TO_RECEIVE_JUMIO_URL,
});

const requestJumioUrl = (data) => ({
  type: ACTIONS.AFFILIATE.REQUEST_JUMIO_URL,
});

export const getJumioUrl = (jumioUrl, affiliateValidationStatusName) => (dispatch) => {
  const windowReference = window.open();
  if (jumioUrl && affiliateValidationStatusName === 'PENDING') {
    windowReference.location = jumioUrl;
  } else {
    return new Promise((fulfill, reject) => {
      dispatch(requestJumioUrl());
      return request
        .post(`${API_URL}/affiliate/identity-verification/initiate`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((err, res) => {
          if (err || res.body.errorMessage) {
            console.error(err);
            console.error(res.body.errorMessage);
            dispatch(failedToReceiveJumioUrl(res.body.data.redirectUrl));
          } else {
            dispatch(receiveJumioUrl(res.body.data?.redirectUrl));
            dispatch(fetchProfile());

            windowReference.location = res.body.data?.redirectUrl;
            fulfill();
          }
        });
    });
  }
};

import PropTypes from 'prop-types';
import messages from '../../i18n/base-en';
import SubdivisionSelect from './SubdivisionSelect.react';
import provincesList from '../../utils/list-options/provinces';
import statesList from '../../utils/list-options/states';
import { InputText } from '../reusables';

const SubdivisionFormField = ({ countryIsoCode2, disabled, provinceField, regionField, stateField }) => {
  switch (countryIsoCode2) {
    case 'CA':
      return (
        <div className="beneficiary-input">
          <SubdivisionSelect
            disabled={disabled}
            id="subdivision-form-field-province"
            label={messages.genericTextProvince.description}
            subdivisionField={provinceField}
            subdivisionList={provincesList}
          />
        </div>
      );
    case 'US':
      return (
        <div className="beneficiary-input">
          <SubdivisionSelect
            disabled={disabled}
            id="subdivision-form-field-state"
            label={messages.genericTextState.description}
            subdivisionField={stateField}
            subdivisionList={statesList}
          />
        </div>
      );
    default:
      return (
        <div className="row">
          <InputText
            className="col-md-12"
            disabled={disabled}
            displayErrorInstantly
            field={regionField}
            id="subdivision-form-field-region"
            label={messages.genericTextRegion}
            tabIndex={160}
            type="text"
          />
        </div>
      );
  }
};

SubdivisionFormField.propTypes = {
  countryIsoCode2: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  provinceField: PropTypes.object.isRequired,
  regionField: PropTypes.object.isRequired,
  stateField: PropTypes.object.isRequired,
};

export default SubdivisionFormField;

import { basicLengthAndCharValidation } from '../../utils/helpers';
import consts from '../../utils/consts';
import { validationErrorMessages } from './validateBeneficiaryForm';

const { COMPANY_NAME, NAME, ZIPCODE, ADDRESS_TEXTS } = consts.REGEXES;

export default (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'Please enter your first name.';
  } else {
    if (!values.first_name.match(NAME) && values.first_name.length >= 2) {
      errors.first_name =
        "Your first name can contain only letters and these characters (', -, ., space) should not be placed at the beginning or the end.";
    } else {
      errors.first_name = basicLengthAndCharValidation(values.first_name, 2, 50, NAME);
    }
  }

  if (!values.last_name) {
    errors.last_name = 'Please enter your last name.';
  } else {
    if (!values.last_name.match(NAME) && values.last_name.length >= 2) {
      errors.last_name =
        "Your last name can contain only letters and these characters (', -, ., space) should not be placed at the beginning or the end.";
    } else {
      errors.last_name = basicLengthAndCharValidation(values.last_name, 2, 50, NAME);
    }
  }

  if (values.company) {
    errors.company = basicLengthAndCharValidation(values.company, 2, 50, COMPANY_NAME);
  }

  if (!values.address) {
    errors.address = 'Please enter your street address.';
  } else {
    if (!values.address.match(ADDRESS_TEXTS)) {
      errors.address = validationErrorMessages.regex.address_texts;
    } else {
      errors.address = basicLengthAndCharValidation(values.address, 2, 60, ADDRESS_TEXTS);
    }
  }

  if (values.address2) {
    if (!values.address2.match(ADDRESS_TEXTS)) {
      errors.address2 = validationErrorMessages.regex.address_texts;
    } else {
      errors.address2 = basicLengthAndCharValidation(values.address2, 1, 200, ADDRESS_TEXTS);
    }
  }

  if (values.address_label) {
    if (!values.address_label.match(ADDRESS_TEXTS)) {
      errors.address_label = validationErrorMessages.regex.address_texts;
    } else {
      errors.address_label = basicLengthAndCharValidation(values.address_label, 3, 200, ADDRESS_TEXTS);
    }
  }

  if (!values.city) {
    errors.city = 'Please enter a city.';
  } else {
    if (values.city && !values.city.match(ADDRESS_TEXTS)) {
      errors.city = validationErrorMessages.regex.address_texts;
    } else {
      errors.city = basicLengthAndCharValidation(values.city, 2, 100, ADDRESS_TEXTS);
    }
  }

  if (!values.country) {
    errors.country = 'Please select the country.';
  } else {
    errors.country = basicLengthAndCharValidation(values.country);
  }

  if (values.country && values.country === 'CA') {
    if (!values.province) {
      errors.province = 'Please select the province you live in.';
    } else {
      errors.province = basicLengthAndCharValidation(values.province);
    }
  }

  if (values.country && values.country === 'US') {
    if (!values.state) {
      errors.state = 'Please select the state you live in.';
    } else {
      errors.state = basicLengthAndCharValidation(values.state);
    }
  }

  if (values.country && values.country !== 'CA' && values.country !== 'US') {
    if (!values.region) {
      errors.region = 'Please enter the region you live in.';
    } else {
      errors.region = basicLengthAndCharValidation(values.region, 2, 100, ADDRESS_TEXTS);
    }
  }

  if (consts.COUNTRIES_WITHOUT_ZIP.includes(values.country)) {
    // NOOP
  } else if (!values.zipcode) {
    errors.zipcode = 'Please enter your zip code.';
  } else {
    if (values.country && !values.zipcode.match(ZIPCODE[values.country])) {
      errors.zipcode = 'Please enter a valid zip code.';
    } else {
      errors.zipcode = basicLengthAndCharValidation(values.zipcode);
    }
  }

  return errors;
};

import { basicLengthAndCharValidation } from '../../utils/helpers';
import consts from '../../utils/consts';

const { ADDRESS_TEXTS, ZIPCODE } = consts.REGEXES;

export const validationErrorMessages = {
  regex: {
    address_texts:
      'Only latin alphabet allowed. Special characters such as: ~ ! ? @ % ^ * + { } | " < > " are not allowed in this field. Please remove them.',
  },
};

export default (values) => {
  const errors = {};

  if (consts.COUNTRIES_WITHOUT_ZIP.includes(values.country)) {
    // NOOP
  } else if (!values.postalCode) {
    errors.postalCode = 'Please enter your zip code.';
  } else {
    if (values.country && !values.postalCode.match(ZIPCODE[values.country])) {
      errors.postalCode = 'Please enter a valid zip code.';
    } else {
      errors.postalCode = basicLengthAndCharValidation(values.postalCode);
    }
  }

  if (values.address_label) {
    if (!values.address_label.match(ADDRESS_TEXTS)) {
      errors.address_label = validationErrorMessages.regex.address_texts;
    } else {
      errors.address_label = basicLengthAndCharValidation(values.address_label, 3, 200, ADDRESS_TEXTS);
    }
  }

  if (!values.address1) {
    errors.address1 = 'Please enter your street address.';
  } else {
    if (!values.address1.match(ADDRESS_TEXTS)) {
      errors.address1 = validationErrorMessages.regex.address_texts;
    } else {
      errors.address1 = basicLengthAndCharValidation(values.address1, 2, 254, ADDRESS_TEXTS);
    }
  }

  if (values.apartment_suite_unit_building) {
    if (!values.apartment_suite_unit_building.match(ADDRESS_TEXTS)) {
      errors.apartment_suite_unit_building = validationErrorMessages.regex.address_texts;
    } else {
      errors.apartment_suite_unit_building = basicLengthAndCharValidation(
        values.apartment_suite_unit_building,
        1,
        254,
        ADDRESS_TEXTS
      );
    }
  }

  if (values.address2) {
    if (!values.address2.match(ADDRESS_TEXTS)) {
      errors.address2 = validationErrorMessages.regex.address_texts;
    } else {
      errors.address2 = basicLengthAndCharValidation(values.address2, 1, 254, ADDRESS_TEXTS);
    }
  }

  if (!values.city) {
    errors.city = 'Please enter a city.';
  } else {
    if (values.city && !values.city.match(ADDRESS_TEXTS)) {
      errors.city = validationErrorMessages.regex.address_texts;
    } else {
      errors.city = basicLengthAndCharValidation(values.city);
    }
  }

  if (values.country && values.country !== 'CA' && values.country !== 'US') {
    if (!values.region) {
      errors.region = 'Please enter the region of the beneficiary.';
    } else {
      errors.region = basicLengthAndCharValidation(values.region, 2, 100, ADDRESS_TEXTS);
    }
  }

  if (values.country && values.country === 'CA') {
    if (!values.province) {
      errors.province = 'Please select the province of the beneficiary.';
    }
  }

  if (values.country && values.country === 'US') {
    if (!values.state) {
      errors.state = 'Please select the state of the beneficiary.';
    }
  }

  return errors;
};

import { createMessagesFromLabels } from '../helpers';

const countryList = [
  {
    value: 'US',
    label: {
      id: 'countryList.us',
      description: 'United States',
      defaultMessage: 'United States',
    },
    name: 'countryList-US',
  },
  {
    value: 'CA',
    label: {
      id: 'countryList.ca',
      description: 'Canada',
      defaultMessage: 'Canada',
    },
    name: 'countryList-CA',
  },
  {
    value: 'AU',
    label: {
      id: 'countryList.au',
      description: 'Australia',
      defaultMessage: 'Australia',
    },
    name: 'countryList-AU',
  },
  {
    value: 'UK',
    label: {
      id: 'countryList.uk',
      description: 'United Kingdom',
      defaultMessage: 'United Kingdom',
    },
    name: 'countryList-GB',
  },
  {
    value: 'GB',
    label: {
      id: 'countryList.gb',
      description: 'United Kingdom',
      defaultMessage: 'United Kingdom',
    },
    name: 'countryList-GB',
  },
  {
    value: 'IE',
    label: {
      id: 'countryList.ie',
      description: 'Ireland',
      defaultMessage: 'Ireland',
    },
    name: 'countryList-IE',
  },
  {
    value: 'NZ',
    label: {
      id: 'countryList.nz',
      description: 'New Zealand',
      defaultMessage: 'New Zealand',
    },
    name: 'countryList-NZ',
  },
  {
    value: 'SG',
    label: {
      id: 'countryList.sg',
      description: 'Singapore',
      defaultMessage: 'Singapore',
    },
    name: 'countryList-SG',
  },
  {
    value: 'FR',
    label: {
      id: 'countryList.fr',
      description: 'France',
      defaultMessage: 'France',
    },
    name: 'countryList-FR',
  },
  {
    value: 'DE',
    label: {
      id: 'countryList.de',
      description: 'Germany',
      defaultMessage: 'Germany',
    },
    name: 'countryList-DE',
  },
  {
    value: 'IT',
    label: {
      id: 'countryList.it',
      description: 'Italy',
      defaultMessage: 'Italy',
    },
    name: 'countryList-IT',
  },
  {
    value: 'ZA',
    label: {
      id: 'countryList.za',
      description: 'South Africa',
      defaultMessage: 'South Africa',
    },
    name: 'countryList-ZA',
  },
  {
    value: 'ES',
    label: {
      id: 'countryList.es',
      description: 'Spain',
      defaultMessage: 'Spain',
    },
    name: 'countryList-ES',
  },
  {
    value: 'AF',
    label: {
      id: 'countryList.af',
      description: 'Afghanistan',
      defaultMessage: 'Afghanistan',
    },
    name: 'countryList-AF',
  },
  {
    value: 'AX',
    label: {
      id: 'countryList.ax',
      description: 'Åland Islands',
      defaultMessage: 'Åland Islands',
    },
    name: 'countryList-AX',
  },
  {
    value: 'AL',
    label: {
      id: 'countryList.al',
      description: 'Albania',
      defaultMessage: 'Albania',
    },
    name: 'countryList-AL',
  },
  {
    value: 'DZ',
    label: {
      id: 'countryList.dz',
      description: 'Algeria',
      defaultMessage: 'Algeria',
    },
    name: 'countryList-DZ',
  },
  {
    value: 'AS',
    label: {
      id: 'countryList.as',
      description: 'American Samoa',
      defaultMessage: 'American Samoa',
    },
    name: 'countryList-AS',
  },
  {
    value: 'AD',
    label: {
      id: 'countryList.ad',
      description: 'Andorra',
      defaultMessage: 'Andorra',
    },
    name: 'countryList-AD',
  },
  {
    value: 'AO',
    label: {
      id: 'countryList.ao',
      description: 'Angola',
      defaultMessage: 'Angola',
    },
    name: 'countryList-AO',
  },
  {
    value: 'AI',
    label: {
      id: 'countryList.ai',
      description: 'Anguilla',
      defaultMessage: 'Anguilla',
    },
    name: 'countryList-AI',
  },
  {
    value: 'AQ',
    label: {
      id: 'countryList.aq',
      description: 'Antarctica',
      defaultMessage: 'Antarctica',
    },
    name: 'countryList-AQ',
  },
  {
    value: 'AG',
    label: {
      id: 'countryList.ag',
      description: 'Antigua and Barbuda',
      defaultMessage: 'Antigua and Barbuda',
    },
    name: 'countryList-AG',
  },
  {
    value: 'AR',
    label: {
      id: 'countryList.ar',
      description: 'Argentina',
      defaultMessage: 'Argentina',
    },
    name: 'countryList-AR',
  },
  {
    value: 'AM',
    label: {
      id: 'countryList.am',
      description: 'Armenia',
      defaultMessage: 'Armenia',
    },
    name: 'countryList-AM',
  },
  {
    value: 'AW',
    label: {
      id: 'countryList.aw',
      description: 'Aruba',
      defaultMessage: 'Aruba',
    },
    name: 'countryList-AW',
  },
  {
    value: 'AT',
    label: {
      id: 'countryList.at',
      description: 'Austria',
      defaultMessage: 'Austria',
    },
    name: 'countryList-AT',
  },
  {
    value: 'AZ',
    label: {
      id: 'countryList.az',
      description: 'Azerbaijan',
      defaultMessage: 'Azerbaijan',
    },
    name: 'countryList-AZ',
  },
  {
    value: 'BS',
    label: {
      id: 'countryList.bs',
      description: 'Bahamas',
      defaultMessage: 'Bahamas',
    },
    name: 'countryList-BS',
  },
  {
    value: 'BH',
    label: {
      id: 'countryList.bh',
      description: 'Bahrain',
      defaultMessage: 'Bahrain',
    },
    name: 'countryList-BH',
  },
  {
    value: 'BD',
    label: {
      id: 'countryList.bd',
      description: 'Bangladesh',
      defaultMessage: 'Bangladesh',
    },
    name: 'countryList-BD',
  },
  {
    value: 'BB',
    label: {
      id: 'countryList.bb',
      description: 'Barbados',
      defaultMessage: 'Barbados',
    },
    name: 'countryList-BB',
  },
  {
    value: 'BY',
    label: {
      id: 'countryList.by',
      description: 'Belarus',
      defaultMessage: 'Belarus',
    },
    name: 'countryList-BY',
  },
  {
    value: 'BE',
    label: {
      id: 'countryList.be',
      description: 'Belgium',
      defaultMessage: 'Belgium',
    },
    name: 'countryList-BE',
  },
  {
    value: 'BZ',
    label: {
      id: 'countryList.bz',
      description: 'Belize',
      defaultMessage: 'Belize',
    },
    name: 'countryList-BZ',
  },
  {
    value: 'BJ',
    label: {
      id: 'countryList.bj',
      description: 'Benin',
      defaultMessage: 'Benin',
    },
    name: 'countryList-BJ',
  },
  {
    value: 'BM',
    label: {
      id: 'countryList.bm',
      description: 'Bermuda',
      defaultMessage: 'Bermuda',
    },
    name: 'countryList-BM',
  },
  {
    value: 'BT',
    label: {
      id: 'countryList.bt',
      description: 'Bhutan',
      defaultMessage: 'Bhutan',
    },
    name: 'countryList-BT',
  },
  {
    value: 'BO',
    label: {
      id: 'countryList.bo',
      description: 'Bolivia',
      defaultMessage: 'Bolivia',
    },
    name: 'countryList-BO',
  },
  {
    value: 'BA',
    label: {
      id: 'countryList.ba',
      description: 'Bosnia and Herzegovina',
      defaultMessage: 'Bosnia and Herzegovina',
    },
    name: 'countryList-BA',
  },
  {
    value: 'BW',
    label: {
      id: 'countryList.bw',
      description: 'Botswana',
      defaultMessage: 'Botswana',
    },
    name: 'countryList-BW',
  },
  {
    value: 'BV',
    label: {
      id: 'countryList.bv',
      description: 'Bouvet Island',
      defaultMessage: 'Bouvet Island',
    },
    name: 'countryList-BV',
  },
  {
    value: 'BR',
    label: {
      id: 'countryList.br',
      description: 'Brazil',
      defaultMessage: 'Brazil',
    },
    name: 'countryList-BR',
  },
  {
    value: 'IO',
    label: {
      id: 'countryList.io',
      description: 'British Indian Ocean Territory',
      defaultMessage: 'British Indian Ocean Territory',
    },
    name: 'countryList-IO',
  },
  {
    value: 'BN',
    label: {
      id: 'countryList.bn',
      description: 'Brunei Darussalam',
      defaultMessage: 'Brunei Darussalam',
    },
    name: 'countryList-BN',
  },
  {
    value: 'BG',
    label: {
      id: 'countryList.bg',
      description: 'Bulgaria',
      defaultMessage: 'Bulgaria',
    },
    name: 'countryList-BG',
  },
  {
    value: 'BF',
    label: {
      id: 'countryList.bf',
      description: 'Burkina Faso',
      defaultMessage: 'Burkina Faso',
    },
    name: 'countryList-BF',
  },
  {
    value: 'BI',
    label: {
      id: 'countryList.bi',
      description: 'Burundi',
      defaultMessage: 'Burundi',
    },
    name: 'countryList-BI',
  },
  {
    value: 'KH',
    label: {
      id: 'countryList.kh',
      description: 'Cambodia',
      defaultMessage: 'Cambodia',
    },
    name: 'countryList-KH',
  },
  {
    value: 'CM',
    label: {
      id: 'countryList.cm',
      description: 'Cameroon',
      defaultMessage: 'Cameroon',
    },
    name: 'countryList-CM',
  },
  {
    value: 'CV',
    label: {
      id: 'countryList.cv',
      description: 'Cape Verde',
      defaultMessage: 'Cape Verde',
    },
    name: 'countryList-CV',
  },
  {
    value: 'KY',
    label: {
      id: 'countryList.ky',
      description: 'Cayman Islands',
      defaultMessage: 'Cayman Islands',
    },
    name: 'countryList-KY',
  },
  {
    value: 'CF',
    label: {
      id: 'countryList.cf',
      description: 'Central African Republic',
      defaultMessage: 'Central African Republic',
    },
    name: 'countryList-CF',
  },
  {
    value: 'TD',
    label: {
      id: 'countryList.td',
      description: 'Chad',
      defaultMessage: 'Chad',
    },
    name: 'countryList-TD',
  },
  {
    value: 'CL',
    label: {
      id: 'countryList.cl',
      description: 'Chile',
      defaultMessage: 'Chile',
    },
    name: 'countryList-CL',
  },
  {
    value: 'CN',
    label: {
      id: 'countryList.cn',
      description: 'China',
      defaultMessage: 'China',
    },
    name: 'countryList-CN',
  },
  {
    value: 'CX',
    label: {
      id: 'countryList.cx',
      description: 'Christmas Island',
      defaultMessage: 'Christmas Island',
    },
    name: 'countryList-CX',
  },
  {
    value: 'CC',
    label: {
      id: 'countryList.cc',
      description: 'Cocos (Keeling) Islands',
      defaultMessage: 'Cocos (Keeling) Islands',
    },
    name: 'countryList-CC',
  },
  {
    value: 'CO',
    label: {
      id: 'countryList.co',
      description: 'Colombia',
      defaultMessage: 'Colombia',
    },
    name: 'countryList-CO',
  },
  {
    value: 'KM',
    label: {
      id: 'countryList.km',
      description: 'Comoros',
      defaultMessage: 'Comoros',
    },
    name: 'countryList-KM',
  },
  {
    value: 'CG',
    label: {
      id: 'countryList.cg',
      description: 'Congo',
      defaultMessage: 'Congo',
    },
    name: 'countryList-CG',
  },
  {
    value: 'CD',
    label: {
      id: 'countryList.cd',
      description: 'Congo, The Democratic Republic of the',
      defaultMessage: 'Congo, The Democratic Republic of the',
    },
    name: 'countryList-CD',
  },
  {
    value: 'CK',
    label: {
      id: 'countryList.ck',
      description: 'Cook Islands',
      defaultMessage: 'Cook Islands',
    },
    name: 'countryList-CK',
  },
  {
    value: 'CR',
    label: {
      id: 'countryList.cr',
      description: 'Costa Rica',
      defaultMessage: 'Costa Rica',
    },
    name: 'countryList-CR',
  },
  {
    value: 'CI',
    label: {
      id: 'countryList.ci',
      description: "Cote D'Ivoire",
      defaultMessage: "Cote D'Ivoire",
    },
    name: 'countryList-CI',
  },
  {
    value: 'HR',
    label: {
      id: 'countryList.hr',
      description: 'Croatia',
      defaultMessage: 'Croatia',
    },
    name: 'countryList-HR',
  },
  {
    value: 'CU',
    label: {
      id: 'countryList.cu',
      description: 'Cuba',
      defaultMessage: 'Cuba',
    },
    name: 'countryList-CU',
  },
  {
    value: 'CY',
    label: {
      id: 'countryList.cy',
      description: 'Cyprus',
      defaultMessage: 'Cyprus',
    },
    name: 'countryList-CY',
  },
  {
    value: 'CZ',
    label: {
      id: 'countryList.cz',
      description: 'Czech Republic',
      defaultMessage: 'Czech Republic',
    },
    name: 'countryList-CZ',
  },
  {
    value: 'DK',
    label: {
      id: 'countryList.dk',
      description: 'Denmark',
      defaultMessage: 'Denmark',
    },
    name: 'countryList-DK',
  },
  {
    value: 'DJ',
    label: {
      id: 'countryList.dj',
      description: 'Djibouti',
      defaultMessage: 'Djibouti',
    },
    name: 'countryList-DJ',
  },
  {
    value: 'DM',
    label: {
      id: 'countryList.dm',
      description: 'Dominica',
      defaultMessage: 'Dominica',
    },
    name: 'countryList-DM',
  },
  {
    value: 'DO',
    label: {
      id: 'countryList.do',
      description: 'Dominican Republic',
      defaultMessage: 'Dominican Republic',
    },
    name: 'countryList-DO',
  },
  {
    value: 'EC',
    label: {
      id: 'countryList.ec',
      description: 'Ecuador',
      defaultMessage: 'Ecuador',
    },
    name: 'countryList-EC',
  },
  {
    value: 'EG',
    label: {
      id: 'countryList.eg',
      description: 'Egypt',
      defaultMessage: 'Egypt',
    },
    name: 'countryList-EG',
  },
  {
    value: 'SV',
    label: {
      id: 'countryList.sv',
      description: 'El Salvador',
      defaultMessage: 'El Salvador',
    },
    name: 'countryList-SV',
  },
  {
    value: 'GQ',
    label: {
      id: 'countryList.gq',
      description: 'Equatorial Guinea',
      defaultMessage: 'Equatorial Guinea',
    },
    name: 'countryList-GQ',
  },
  {
    value: 'ER',
    label: {
      id: 'countryList.er',
      description: 'Eritrea',
      defaultMessage: 'Eritrea',
    },
    name: 'countryList-ER',
  },
  {
    value: 'EE',
    label: {
      id: 'countryList.ee',
      description: 'Estonia',
      defaultMessage: 'Estonia',
    },
    name: 'countryList-EE',
  },
  {
    value: 'ET',
    label: {
      id: 'countryList.et',
      description: 'Ethiopia',
      defaultMessage: 'Ethiopia',
    },
    name: 'countryList-ET',
  },
  {
    value: 'FK',
    label: {
      id: 'countryList.fk',
      description: 'Falkland Islands (Malvinas)',
      defaultMessage: 'Falkland Islands (Malvinas)',
    },
    name: 'countryList-FK',
  },
  {
    value: 'FO',
    label: {
      id: 'countryList.fo',
      description: 'Faroe Islands',
      defaultMessage: 'Faroe Islands',
    },
    name: 'countryList-FO',
  },
  {
    value: 'FJ',
    label: {
      id: 'countryList.fj',
      description: 'Fiji',
      defaultMessage: 'Fiji',
    },
    name: 'countryList-FJ',
  },
  {
    value: 'FI',
    label: {
      id: 'countryList.fi',
      description: 'Finland',
      defaultMessage: 'Finland',
    },
    name: 'countryList-FI',
  },
  {
    value: 'GF',
    label: {
      id: 'countryList.gf',
      description: 'French Guiana',
      defaultMessage: 'French Guiana',
    },
    name: 'countryList-GF',
  },
  {
    value: 'PF',
    label: {
      id: 'countryList.pf',
      description: 'French Polynesia',
      defaultMessage: 'French Polynesia',
    },
    name: 'countryList-PF',
  },
  {
    value: 'TF',
    label: {
      id: 'countryList.tf',
      description: 'French Southern Territories',
      defaultMessage: 'French Southern Territories',
    },
    name: 'countryList-TF',
  },
  {
    value: 'GA',
    label: {
      id: 'countryList.ga',
      description: 'Gabon',
      defaultMessage: 'Gabon',
    },
    name: 'countryList-GA',
  },
  {
    value: 'GM',
    label: {
      id: 'countryList.gm',
      description: 'Gambia',
      defaultMessage: 'Gambia',
    },
    name: 'countryList-GM',
  },
  {
    value: 'GE',
    label: {
      id: 'countryList.ge',
      description: 'Georgia',
      defaultMessage: 'Georgia',
    },
    name: 'countryList-GE',
  },
  {
    value: 'GH',
    label: {
      id: 'countryList.gh',
      description: 'Ghana',
      defaultMessage: 'Ghana',
    },
    name: 'countryList-GH',
  },
  {
    value: 'GI',
    label: {
      id: 'countryList.gi',
      description: 'Gibraltar',
      defaultMessage: 'Gibraltar',
    },
    name: 'countryList-GI',
  },
  {
    value: 'GR',
    label: {
      id: 'countryList.gr',
      description: 'Greece',
      defaultMessage: 'Greece',
    },
    name: 'countryList-GR',
  },
  {
    value: 'GL',
    label: {
      id: 'countryList.gl',
      description: 'Greenland',
      defaultMessage: 'Greenland',
    },
    name: 'countryList-GL',
  },
  {
    value: 'GD',
    label: {
      id: 'countryList.gd',
      description: 'Grenada',
      defaultMessage: 'Grenada',
    },
    name: 'countryList-GD',
  },
  {
    value: 'GP',
    label: {
      id: 'countryList.gp',
      description: 'Guadeloupe',
      defaultMessage: 'Guadeloupe',
    },
    name: 'countryList-GP',
  },
  {
    value: 'GU',
    label: {
      id: 'countryList.gu',
      description: 'Guam',
      defaultMessage: 'Guam',
    },
    name: 'countryList-GU',
  },
  {
    value: 'GT',
    label: {
      id: 'countryList.gt',
      description: 'Guatemala',
      defaultMessage: 'Guatemala',
    },
    name: 'countryList-GT',
  },
  {
    value: 'GG',
    label: {
      id: 'countryList.gg',
      description: 'Guernsey',
      defaultMessage: 'Guernsey',
    },
    name: 'countryList-GG',
  },
  {
    value: 'GN',
    label: {
      id: 'countryList.gn',
      description: 'Guinea',
      defaultMessage: 'Guinea',
    },
    name: 'countryList-GN',
  },
  {
    value: 'GW',
    label: {
      id: 'countryList.gw',
      description: 'Guinea-Bissau',
      defaultMessage: 'Guinea-Bissau',
    },
    name: 'countryList-GW',
  },
  {
    value: 'GY',
    label: {
      id: 'countryList.gy',
      description: 'Guyana',
      defaultMessage: 'Guyana',
    },
    name: 'countryList-GY',
  },
  {
    value: 'HT',
    label: {
      id: 'countryList.ht',
      description: 'Haiti',
      defaultMessage: 'Haiti',
    },
    name: 'countryList-HT',
  },
  {
    value: 'HM',
    label: {
      id: 'countryList.hm',
      description: 'Heard Island and Mcdonald Islands',
      defaultMessage: 'Heard Island and Mcdonald Islands',
    },
    name: 'countryList-HM',
  },
  {
    value: 'VA',
    label: {
      id: 'countryList.va',
      description: 'Holy See (Vatican City State)',
      defaultMessage: 'Holy See (Vatican City State)',
    },
    name: 'countryList-VA',
  },
  {
    value: 'HN',
    label: {
      id: 'countryList.hn',
      description: 'Honduras',
      defaultMessage: 'Honduras',
    },
    name: 'countryList-HN',
  },
  {
    value: 'HK',
    label: {
      id: 'countryList.hk',
      description: 'Hong Kong',
      defaultMessage: 'Hong Kong',
    },
    name: 'countryList-HK',
  },
  {
    value: 'HU',
    label: {
      id: 'countryList.hu',
      description: 'Hungary',
      defaultMessage: 'Hungary',
    },
    name: 'countryList-HU',
  },
  {
    value: 'IS',
    label: {
      id: 'countryList.is',
      description: 'Iceland',
      defaultMessage: 'Iceland',
    },
    name: 'countryList-IS',
  },
  {
    value: 'IN',
    label: {
      id: 'countryList.in',
      description: 'India',
      defaultMessage: 'India',
    },
    name: 'countryList-IN',
  },
  {
    value: 'ID',
    label: {
      id: 'countryList.id',
      description: 'Indonesia',
      defaultMessage: 'Indonesia',
    },
    name: 'countryList-ID',
  },
  {
    value: 'IR',
    label: {
      id: 'countryList.ir',
      description: 'Iran, Islamic Republic Of',
      defaultMessage: 'Iran, Islamic Republic Of',
    },
    name: 'countryList-IR',
  },
  {
    value: 'IQ',
    label: {
      id: 'countryList.iq',
      description: 'Iraq',
      defaultMessage: 'Iraq',
    },
    name: 'countryList-IQ',
  },
  {
    value: 'IM',
    label: {
      id: 'countryList.im',
      description: 'Isle of Man',
      defaultMessage: 'Isle of Man',
    },
    name: 'countryList-IM',
  },
  {
    value: 'IL',
    label: {
      id: 'countryList.il',
      description: 'Israel',
      defaultMessage: 'Israel',
    },
    name: 'countryList-IL',
  },
  {
    value: 'JM',
    label: {
      id: 'countryList.jm',
      description: 'Jamaica',
      defaultMessage: 'Jamaica',
    },
    name: 'countryList-JM',
  },
  {
    value: 'JP',
    label: {
      id: 'countryList.jp',
      description: 'Japan',
      defaultMessage: 'Japan',
    },
    name: 'countryList-JP',
  },
  {
    value: 'JE',
    label: {
      id: 'countryList.je',
      description: 'Jersey',
      defaultMessage: 'Jersey',
    },
    name: 'countryList-JE',
  },
  {
    value: 'JO',
    label: {
      id: 'countryList.jo',
      description: 'Jordan',
      defaultMessage: 'Jordan',
    },
    name: 'countryList-JO',
  },
  {
    value: 'KZ',
    label: {
      id: 'countryList.kz',
      description: 'Kazakhstan',
      defaultMessage: 'Kazakhstan',
    },
    name: 'countryList-KZ',
  },
  {
    value: 'KE',
    label: {
      id: 'countryList.ke',
      description: 'Kenya',
      defaultMessage: 'Kenya',
    },
    name: 'countryList-KE',
  },
  {
    value: 'KI',
    label: {
      id: 'countryList.ki',
      description: 'Kiribati',
      defaultMessage: 'Kiribati',
    },
    name: 'countryList-KI',
  },
  {
    value: 'KP',
    label: {
      id: 'countryList.kp',
      description: "Korea, Democratic People's Republic of",
      defaultMessage: "Korea, Democratic People's Republic of",
    },
    name: 'countryList-KP',
  },
  {
    value: 'KR',
    label: {
      id: 'countryList.kr',
      description: 'Korea, Republic of',
      defaultMessage: 'Korea, Republic of',
    },
    name: 'countryList-KR',
  },
  {
    value: 'KW',
    label: {
      id: 'countryList.kw',
      description: 'Kuwait',
      defaultMessage: 'Kuwait',
    },
    name: 'countryList-KW',
  },
  {
    value: 'KG',
    label: {
      id: 'countryList.kg',
      description: 'Kyrgyzstan',
      defaultMessage: 'Kyrgyzstan',
    },
    name: 'countryList-KG',
  },
  {
    value: 'LA',
    label: {
      id: 'countryList.la',
      description: "Lao People's Democratic Republic",
      defaultMessage: "Lao People's Democratic Republic",
    },
    name: 'countryList-LA',
  },
  {
    value: 'LV',
    label: {
      id: 'countryList.lv',
      description: 'Latvia',
      defaultMessage: 'Latvia',
    },
    name: 'countryList-LV',
  },
  {
    value: 'LB',
    label: {
      id: 'countryList.lb',
      description: 'Lebanon',
      defaultMessage: 'Lebanon',
    },
    name: 'countryList-LB',
  },
  {
    value: 'LS',
    label: {
      id: 'countryList.ls',
      description: 'Lesotho',
      defaultMessage: 'Lesotho',
    },
    name: 'countryList-LS',
  },
  {
    value: 'LR',
    label: {
      id: 'countryList.lr',
      description: 'Liberia',
      defaultMessage: 'Liberia',
    },
    name: 'countryList-LR',
  },
  {
    value: 'LY',
    label: {
      id: 'countryList.ly',
      description: 'Libyan Arab Jamahiriya',
      defaultMessage: 'Libyan Arab Jamahiriya',
    },
    name: 'countryList-LY',
  },
  {
    value: 'LI',
    label: {
      id: 'countryList.li',
      description: 'Liechtenstein',
      defaultMessage: 'Liechtenstein',
    },
    name: 'countryList-LI',
  },
  {
    value: 'LT',
    label: {
      id: 'countryList.lt',
      description: 'Lithuania',
      defaultMessage: 'Lithuania',
    },
    name: 'countryList-LT',
  },
  {
    value: 'LU',
    label: {
      id: 'countryList.lu',
      description: 'Luxembourg',
      defaultMessage: 'Luxembourg',
    },
    name: 'countryList-LU',
  },
  {
    value: 'MO',
    label: {
      id: 'countryList.mo',
      description: 'Macao',
      defaultMessage: 'Macao',
    },
    name: 'countryList-MO',
  },
  {
    value: 'MK',
    label: {
      id: 'countryList.mk',
      description: 'Macedonia, The Former Yugoslav Republic of',
      defaultMessage: 'Macedonia, The Former Yugoslav Republic of',
    },
    name: 'countryList-MK',
  },
  {
    value: 'MG',
    label: {
      id: 'countryList.mg',
      description: 'Madagascar',
      defaultMessage: 'Madagascar',
    },
    name: 'countryList-MG',
  },
  {
    value: 'MW',
    label: {
      id: 'countryList.mw',
      description: 'Malawi',
      defaultMessage: 'Malawi',
    },
    name: 'countryList-MW',
  },
  {
    value: 'MY',
    label: {
      id: 'countryList.my',
      description: 'Malaysia',
      defaultMessage: 'Malaysia',
    },
    name: 'countryList-MY',
  },
  {
    value: 'MV',
    label: {
      id: 'countryList.mv',
      description: 'Maldives',
      defaultMessage: 'Maldives',
    },
    name: 'countryList-MV',
  },
  {
    value: 'ML',
    label: {
      id: 'countryList.ml',
      description: 'Mali',
      defaultMessage: 'Mali',
    },
    name: 'countryList-ML',
  },
  {
    value: 'MT',
    label: {
      id: 'countryList.mt',
      description: 'Malta',
      defaultMessage: 'Malta',
    },
    name: 'countryList-MT',
  },
  {
    value: 'MH',
    label: {
      id: 'countryList.mh',
      description: 'Marshall Islands',
      defaultMessage: 'Marshall Islands',
    },
    name: 'countryList-MH',
  },
  {
    value: 'MQ',
    label: {
      id: 'countryList.mq',
      description: 'Martinique',
      defaultMessage: 'Martinique',
    },
    name: 'countryList-MQ',
  },
  {
    value: 'MR',
    label: {
      id: 'countryList.mr',
      description: 'Mauritania',
      defaultMessage: 'Mauritania',
    },
    name: 'countryList-MR',
  },
  {
    value: 'MU',
    label: {
      id: 'countryList.mu',
      description: 'Mauritius',
      defaultMessage: 'Mauritius',
    },
    name: 'countryList-MU',
  },
  {
    value: 'YT',
    label: {
      id: 'countryList.yt',
      description: 'Mayotte',
      defaultMessage: 'Mayotte',
    },
    name: 'countryList-YT',
  },
  {
    value: 'MX',
    label: {
      id: 'countryList.mx',
      description: 'Mexico',
      defaultMessage: 'Mexico',
    },
    name: 'countryList-MX',
  },
  {
    value: 'FM',
    label: {
      id: 'countryList.fm',
      description: 'Micronesia, Federated States of',
      defaultMessage: 'Micronesia, Federated States of',
    },
    name: 'countryList-FM',
  },
  {
    value: 'MD',
    label: {
      id: 'countryList.md',
      description: 'Moldova, Republic of',
      defaultMessage: 'Moldova, Republic of',
    },
    name: 'countryList-MD',
  },
  {
    value: 'MC',
    label: {
      id: 'countryList.mc',
      description: 'Monaco',
      defaultMessage: 'Monaco',
    },
    name: 'countryList-MC',
  },
  {
    value: 'MN',
    label: {
      id: 'countryList.mn',
      description: 'Mongolia',
      defaultMessage: 'Mongolia',
    },
    name: 'countryList-MN',
  },
  {
    value: 'MS',
    label: {
      id: 'countryList.ms',
      description: 'Montserrat',
      defaultMessage: 'Montserrat',
    },
    name: 'countryList-MS',
  },
  {
    value: 'MA',
    label: {
      id: 'countryList.ma',
      description: 'Morocco',
      defaultMessage: 'Morocco',
    },
    name: 'countryList-MA',
  },
  {
    value: 'MZ',
    label: {
      id: 'countryList.mz',
      description: 'Mozambique',
      defaultMessage: 'Mozambique',
    },
    name: 'countryList-MZ',
  },
  {
    value: 'MM',
    label: {
      id: 'countryList.mm',
      description: 'Myanmar',
      defaultMessage: 'Myanmar',
    },
    name: 'countryList-MM',
  },
  {
    value: 'NA',
    label: {
      id: 'countryList.na',
      description: 'Namibia',
      defaultMessage: 'Namibia',
    },
    name: 'countryList-NA',
  },
  {
    value: 'NR',
    label: {
      id: 'countryList.nr',
      description: 'Nauru',
      defaultMessage: 'Nauru',
    },
    name: 'countryList-NR',
  },
  {
    value: 'NP',
    label: {
      id: 'countryList.np',
      description: 'Nepal',
      defaultMessage: 'Nepal',
    },
    name: 'countryList-NP',
  },
  {
    value: 'NL',
    label: {
      id: 'countryList.nl',
      description: 'Netherlands',
      defaultMessage: 'Netherlands',
    },
    name: 'countryList-NL',
  },
  {
    value: 'AN',
    label: {
      id: 'countryList.an',
      description: 'Netherlands Antilles',
      defaultMessage: 'Netherlands Antilles',
    },
    name: 'countryList-AN',
  },
  {
    value: 'NC',
    label: {
      id: 'countryList.nc',
      description: 'New Caledonia',
      defaultMessage: 'New Caledonia',
    },
    name: 'countryList-NC',
  },
  {
    value: 'NI',
    label: {
      id: 'countryList.ni',
      description: 'Nicaragua',
      defaultMessage: 'Nicaragua',
    },
    name: 'countryList-NI',
  },
  {
    value: 'NE',
    label: {
      id: 'countryList.ne',
      description: 'Niger',
      defaultMessage: 'Niger',
    },
    name: 'countryList-NE',
  },
  {
    value: 'NG',
    label: {
      id: 'countryList.ng',
      description: 'Nigeria',
      defaultMessage: 'Nigeria',
    },
    name: 'countryList-NG',
  },
  {
    value: 'NU',
    label: {
      id: 'countryList.nu',
      description: 'Niue',
      defaultMessage: 'Niue',
    },
    name: 'countryList-NU',
  },
  {
    value: 'NF',
    label: {
      id: 'countryList.nf',
      description: 'Norfolk Island',
      defaultMessage: 'Norfolk Island',
    },
    name: 'countryList-NF',
  },
  {
    value: 'MP',
    label: {
      id: 'countryList.mp',
      description: 'Northern Mariana Islands',
      defaultMessage: 'Northern Mariana Islands',
    },
    name: 'countryList-MP',
  },
  {
    value: 'NO',
    label: {
      id: 'countryList.no',
      description: 'Norway',
      defaultMessage: 'Norway',
    },
    name: 'countryList-NO',
  },
  {
    value: 'OM',
    label: {
      id: 'countryList.om',
      description: 'Oman',
      defaultMessage: 'Oman',
    },
    name: 'countryList-OM',
  },
  {
    value: 'PK',
    label: {
      id: 'countryList.pk',
      description: 'Pakistan',
      defaultMessage: 'Pakistan',
    },
    name: 'countryList-PK',
  },
  {
    value: 'PW',
    label: {
      id: 'countryList.pw',
      description: 'Palau',
      defaultMessage: 'Palau',
    },
    name: 'countryList-PW',
  },
  {
    value: 'PS',
    label: {
      id: 'countryList.ps',
      description: 'Palestinian Territory, Occupied',
      defaultMessage: 'Palestinian Territory, Occupied',
    },
    name: 'countryList-PS',
  },
  {
    value: 'PA',
    label: {
      id: 'countryList.pa',
      description: 'Panama',
      defaultMessage: 'Panama',
    },
    name: 'countryList-PA',
  },
  {
    value: 'PG',
    label: {
      id: 'countryList.pg',
      description: 'Papua New Guinea',
      defaultMessage: 'Papua New Guinea',
    },
    name: 'countryList-PG',
  },
  {
    value: 'PY',
    label: {
      id: 'countryList.py',
      description: 'Paraguay',
      defaultMessage: 'Paraguay',
    },
    name: 'countryList-PY',
  },
  {
    value: 'PE',
    label: {
      id: 'countryList.pe',
      description: 'Peru',
      defaultMessage: 'Peru',
    },
    name: 'countryList-PE',
  },
  {
    value: 'PH',
    label: {
      id: 'countryList.ph',
      description: 'Philippines',
      defaultMessage: 'Philippines',
    },
    name: 'countryList-PH',
  },
  {
    value: 'PN',
    label: {
      id: 'countryList.pn',
      description: 'Pitcairn',
      defaultMessage: 'Pitcairn',
    },
    name: 'countryList-PN',
  },
  {
    value: 'PL',
    label: {
      id: 'countryList.pl',
      description: 'Poland',
      defaultMessage: 'Poland',
    },
    name: 'countryList-PL',
  },
  {
    value: 'PT',
    label: {
      id: 'countryList.pt',
      description: 'Portugal',
      defaultMessage: 'Portugal',
    },
    name: 'countryList-PT',
  },
  {
    value: 'PR',
    label: {
      id: 'countryList.pr',
      description: 'Puerto Rico',
      defaultMessage: 'Puerto Rico',
    },
    name: 'countryList-PR',
  },
  {
    value: 'QA',
    label: {
      id: 'countryList.qa',
      description: 'Qatar',
      defaultMessage: 'Qatar',
    },
    name: 'countryList-QA',
  },
  {
    value: 'RE',
    label: {
      id: 'countryList.re',
      description: 'Reunion',
      defaultMessage: 'Reunion',
    },
    name: 'countryList-RE',
  },
  {
    value: 'RO',
    label: {
      id: 'countryList.ro',
      description: 'Romania',
      defaultMessage: 'Romania',
    },
    name: 'countryList-RO',
  },
  {
    value: 'RU',
    label: {
      id: 'countryList.ru',
      description: 'Russian Federation',
      defaultMessage: 'Russian Federation',
    },
    name: 'countryList-RU',
  },
  {
    value: 'RW',
    label: {
      id: 'countryList.rw',
      description: 'Rwanda',
      defaultMessage: 'Rwanda',
    },
    name: 'countryList-RW',
  },
  {
    value: 'SH',
    label: {
      id: 'countryList.sh',
      description: 'Saint Helena',
      defaultMessage: 'Saint Helena',
    },
    name: 'countryList-SH',
  },
  {
    value: 'KN',
    label: {
      id: 'countryList.kn',
      description: 'Saint Kitts and Nevis',
      defaultMessage: 'Saint Kitts and Nevis',
    },
    name: 'countryList-KN',
  },
  {
    value: 'LC',
    label: {
      id: 'countryList.lc',
      description: 'Saint Lucia',
      defaultMessage: 'Saint Lucia',
    },
    name: 'countryList-LC',
  },
  {
    value: 'PM',
    label: {
      id: 'countryList.pm',
      description: 'Saint Pierre and Miquelon',
      defaultMessage: 'Saint Pierre and Miquelon',
    },
    name: 'countryList-PM',
  },
  {
    value: 'VC',
    label: {
      id: 'countryList.vc',
      description: 'Saint Vincent and the Grenadines',
      defaultMessage: 'Saint Vincent and the Grenadines',
    },
    name: 'countryList-VC',
  },
  {
    value: 'WS',
    label: {
      id: 'countryList.ws',
      description: 'Samoa',
      defaultMessage: 'Samoa',
    },
    name: 'countryList-WS',
  },
  {
    value: 'SM',
    label: {
      id: 'countryList.sm',
      description: 'San Marino',
      defaultMessage: 'San Marino',
    },
    name: 'countryList-SM',
  },
  {
    value: 'ST',
    label: {
      id: 'countryList.st',
      description: 'Sao Tome and Principe',
      defaultMessage: 'Sao Tome and Principe',
    },
    name: 'countryList-ST',
  },
  {
    value: 'SA',
    label: {
      id: 'countryList.sa',
      description: 'Saudi Arabia',
      defaultMessage: 'Saudi Arabia',
    },
    name: 'countryList-SA',
  },
  {
    value: 'SN',
    label: {
      id: 'countryList.sn',
      description: 'Senegal',
      defaultMessage: 'Senegal',
    },
    name: 'countryList-SN',
  },
  {
    value: 'CS',
    label: {
      id: 'countryList.cs',
      description: 'Serbia and Montenegro',
      defaultMessage: 'Serbia and Montenegro',
    },
    name: 'countryList-CS',
  },
  {
    value: 'SC',
    label: {
      id: 'countryList.sc',
      description: 'Seychelles',
      defaultMessage: 'Seychelles',
    },
    name: 'countryList-SC',
  },
  {
    value: 'SL',
    label: {
      id: 'countryList.sl',
      description: 'Sierra Leone',
      defaultMessage: 'Sierra Leone',
    },
    name: 'countryList-SL',
  },
  {
    value: 'SK',
    label: {
      id: 'countryList.sk',
      description: 'Slovakia',
      defaultMessage: 'Slovakia',
    },
    name: 'countryList-SK',
  },
  {
    value: 'SI',
    label: {
      id: 'countryList.si',
      description: 'Slovenia',
      defaultMessage: 'Slovenia',
    },
    name: 'countryList-SI',
  },
  {
    value: 'SB',
    label: {
      id: 'countryList.sb',
      description: 'Solomon Islands',
      defaultMessage: 'Solomon Islands',
    },
    name: 'countryList-SB',
  },
  {
    value: 'SO',
    label: {
      id: 'countryList.so',
      description: 'Somalia',
      defaultMessage: 'Somalia',
    },
    name: 'countryList-SO',
  },
  {
    value: 'GS',
    label: {
      id: 'countryList.gs',
      description: 'South Georgia and the South Sandwich Islands',
      defaultMessage: 'South Georgia and the South Sandwich Islands',
    },
    name: 'countryList-GS',
  },
  {
    value: 'LK',
    label: {
      id: 'countryList.lk',
      description: 'Sri Lanka',
      defaultMessage: 'Sri Lanka',
    },
    name: 'countryList-LK',
  },
  {
    value: 'SD',
    label: {
      id: 'countryList.sd',
      description: 'Sudan',
      defaultMessage: 'Sudan',
    },
    name: 'countryList-SD',
  },
  {
    value: 'SR',
    label: {
      id: 'countryList.sr',
      description: 'Suriname',
      defaultMessage: 'Suriname',
    },
    name: 'countryList-SR',
  },
  {
    value: 'SJ',
    label: {
      id: 'countryList.sj',
      description: 'Svalbard and Jan Mayen',
      defaultMessage: 'Svalbard and Jan Mayen',
    },
    name: 'countryList-SJ',
  },
  {
    value: 'SZ',
    label: {
      id: 'countryList.sz',
      description: 'Swaziland',
      defaultMessage: 'Swaziland',
    },
    name: 'countryList-SZ',
  },
  {
    value: 'SE',
    label: {
      id: 'countryList.se',
      description: 'Sweden',
      defaultMessage: 'Sweden',
    },
    name: 'countryList-SE',
  },
  {
    value: 'CH',
    label: {
      id: 'countryList.ch',
      description: 'Switzerland',
      defaultMessage: 'Switzerland',
    },
    name: 'countryList-CH',
  },
  {
    value: 'SY',
    label: {
      id: 'countryList.sy',
      description: 'Syrian Arab Republic',
      defaultMessage: 'Syrian Arab Republic',
    },
    name: 'countryList-SY',
  },
  {
    value: 'TW',
    label: {
      id: 'countryList.tw',
      description: 'Taiwan, Province of China',
      defaultMessage: 'Taiwan, Province of China',
    },
    name: 'countryList-TW',
  },
  {
    value: 'TJ',
    label: {
      id: 'countryList.tj',
      description: 'Tajikistan',
      defaultMessage: 'Tajikistan',
    },
    name: 'countryList-TJ',
  },
  {
    value: 'TZ',
    label: {
      id: 'countryList.tz',
      description: 'Tanzania, United Republic of',
      defaultMessage: 'Tanzania, United Republic of',
    },
    name: 'countryList-TZ',
  },
  {
    value: 'TH',
    label: {
      id: 'countryList.th',
      description: 'Thailand',
      defaultMessage: 'Thailand',
    },
    name: 'countryList-TH',
  },
  {
    value: 'TL',
    label: {
      id: 'countryList.tl',
      description: 'Timor-Leste',
      defaultMessage: 'Timor-Leste',
    },
    name: 'countryList-TL',
  },
  {
    value: 'TG',
    label: {
      id: 'countryList.tg',
      description: 'Togo',
      defaultMessage: 'Togo',
    },
    name: 'countryList-TG',
  },
  {
    value: 'TK',
    label: {
      id: 'countryList.tk',
      description: 'Tokelau',
      defaultMessage: 'Tokelau',
    },
    name: 'countryList-TK',
  },
  {
    value: 'TO',
    label: {
      id: 'countryList.to',
      description: 'Tonga',
      defaultMessage: 'Tonga',
    },
    name: 'countryList-TO',
  },
  {
    value: 'TT',
    label: {
      id: 'countryList.tt',
      description: 'Trinidad and Tobago',
      defaultMessage: 'Trinidad and Tobago',
    },
    name: 'countryList-TT',
  },
  {
    value: 'TN',
    label: {
      id: 'countryList.tn',
      description: 'Tunisia',
      defaultMessage: 'Tunisia',
    },
    name: 'countryList-TN',
  },
  {
    value: 'TR',
    label: {
      id: 'countryList.tr',
      description: 'Turkey',
      defaultMessage: 'Turkey',
    },
    name: 'countryList-TR',
  },
  {
    value: 'TM',
    label: {
      id: 'countryList.tm',
      description: 'Turkmenistan',
      defaultMessage: 'Turkmenistan',
    },
    name: 'countryList-TM',
  },
  {
    value: 'TC',
    label: {
      id: 'countryList.tc',
      description: 'Turks and Caicos Islands',
      defaultMessage: 'Turks and Caicos Islands',
    },
    name: 'countryList-TC',
  },
  {
    value: 'TV',
    label: {
      id: 'countryList.tv',
      description: 'Tuvalu',
      defaultMessage: 'Tuvalu',
    },
    name: 'countryList-TV',
  },
  {
    value: 'UG',
    label: {
      id: 'countryList.ug',
      description: 'Uganda',
      defaultMessage: 'Uganda',
    },
    name: 'countryList-UG',
  },
  {
    value: 'UA',
    label: {
      id: 'countryList.ua',
      description: 'Ukraine',
      defaultMessage: 'Ukraine',
    },
    name: 'countryList-UA',
  },
  {
    value: 'AE',
    label: {
      id: 'countryList.ae',
      description: 'United Arab Emirates',
      defaultMessage: 'United Arab Emirates',
    },
    name: 'countryList-AE',
  },
  {
    value: 'UM',
    label: {
      id: 'countryList.um',
      description: 'United States Minor Outlying Islands',
      defaultMessage: 'United States Minor Outlying Islands',
    },
    name: 'countryList-UM',
  },
  {
    value: 'UY',
    label: {
      id: 'countryList.uy',
      description: 'Uruguay',
      defaultMessage: 'Uruguay',
    },
    name: 'countryList-UY',
  },
  {
    value: 'UZ',
    label: {
      id: 'countryList.uz',
      description: 'Uzbekistan',
      defaultMessage: 'Uzbekistan',
    },
    name: 'countryList-UZ',
  },
  {
    value: 'VU',
    label: {
      id: 'countryList.vu',
      description: 'Vanuatu',
      defaultMessage: 'Vanuatu',
    },
    name: 'countryList-VU',
  },
  {
    value: 'VE',
    label: {
      id: 'countryList.ve',
      description: 'Venezuela',
      defaultMessage: 'Venezuela',
    },
    name: 'countryList-VE',
  },
  {
    value: 'VN',
    label: {
      id: 'countryList.vn',
      description: 'Viet Nam',
      defaultMessage: 'Viet Nam',
    },
    name: 'countryList-VN',
  },
  {
    value: 'VG',
    label: {
      id: 'countryList.vg',
      description: 'Virgin Islands, British',
      defaultMessage: 'Virgin Islands, British',
    },
    name: 'countryList-VG',
  },
  {
    value: 'VI',
    label: {
      id: 'countryList.vi',
      description: 'Virgin Islands, U.S.',
      defaultMessage: 'Virgin Islands, U.S.',
    },
    name: 'countryList-VI',
  },
  {
    value: 'WF',
    label: {
      id: 'countryList.wf',
      description: 'Wallis and Futuna',
      defaultMessage: 'Wallis and Futuna',
    },
    name: 'countryList-WF',
  },
  {
    value: 'EH',
    label: {
      id: 'countryList.eh',
      description: 'Western Sahara',
      defaultMessage: 'Western Sahara',
    },
    name: 'countryList-EH',
  },
  {
    value: 'YE',
    label: {
      id: 'countryList.ye',
      description: 'Yemen',
      defaultMessage: 'Yemen',
    },
    name: 'countryList-YE',
  },
  {
    value: 'ZM',
    label: {
      id: 'countryList.zm',
      description: 'Zambia',
      defaultMessage: 'Zambia',
    },
    name: 'countryList-ZM',
  },
  {
    value: 'ZW',
    label: {
      id: 'countryList.zw',
      description: 'Zimbabwe',
      defaultMessage: 'Zimbabwe',
    },
    name: 'countryList-ZW',
  },
];

export default countryList;
export const labelMessagesObj = createMessagesFromLabels(countryList);

export const getCountryLabel = (countryCode) => {
  const country = countryList.find((current) => current.value === countryCode);
  if (country) {
    return country.label.id;
  }
  return countryCode;
};

export const getCountryLabelDefaultMessage = (countryCode) => {
  const country = countryList.find((current) => current.value === countryCode);
  if (country) {
    return country.label.defaultMessage;
  }
  return undefined;
};

export const getCountryCode = (countryLabelDefaultMessage) => {
  const country = countryList.find((current) => current.label.defaultMessage === countryLabelDefaultMessage);
  if (country) {
    return country.value;
  }
  return undefined;
};

import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useRef, useState } from 'react';
import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { explodeDescription } from '../../utils/helpers';
import { getConversionTypeMessage } from './helper';
import JerkmateOfferCard from './JerkmateOfferCard';

const slideDirections = {
  NEXT: 'nextDirection',
  PREVIOUS: 'previousDirection',
};

const JerkmateOfferSlider = () => {
  const firstLoad = useRef(true);
  const interval = useRef(null);
  const timeout = useRef(null);
  const timeout2 = useRef(null);
  const timeoutMouseOut = useRef(null);
  const offersLeftRef = useRef([]);
  const indicatorActiveRef = useRef(null);
  const sliderRightRef = useRef(null);

  const { jerkmateOffers, sliderSectionRef } = useJerkmateOffersContext();

  const [sliderInScreen, setSliderInScreen] = useState(false);

  const [slideDirection, setSlideDirection] = useState(slideDirections.NEXT);

  const [active, setActive] = useState({
    activeOffer: 0,
    indicatorHeight: 0,
    indicatorTranslateY: 0,
    previousActiveOffer: null,
    sliding: false,
    pullLeftSlideIndex: null,
  });

  const clearSliderMouseOutTimeout = () => clearTimeout(timeoutMouseOut.current);

  const triggerNext = (jumpToSlide) => {
    const { activeOffer } = active;

    let nextActive;
    let nextItemHeight;
    let lastIndex;
    let nextItem;

    if (slideDirection === slideDirections.NEXT) {
      //NEXT
      lastIndex = activeOffer === offersLeftRef.current.length - 1;

      nextActive = activeOffer + 1;

      if (!offersLeftRef.current?.[nextActive]) {
        nextActive = 0;
      }
    } else {
      //PREVIOUS
      nextActive = activeOffer - 1;

      if (nextActive < 0) {
        nextActive = offersLeftRef.current.length - 1;
      }
    }

    if (!isNaN(jumpToSlide)) {
      nextActive = jumpToSlide;
    }

    nextItem = offersLeftRef.current?.[nextActive];
    nextItemHeight = nextItem.offsetHeight + 45;

    setActive((active) => ({
      ...active,
      activeOffer: nextActive,
      indicatorHeight: nextItemHeight,
      indicatorTranslateY: lastIndex || jumpToSlide === 0 ? 0 : nextItem.offsetTop - 45,
      previousActiveOffer: activeOffer,
      sliding: true,
      jumpTo: null,
    }));

    clearSliderMouseOutTimeout();
  };

  useEffect(() => {
    if (!sliderInScreen) {
      return;
    }

    if (active.sliding) {
      clearInterval(interval.current);
      clearTimeout(timeout2.current);
      timeout2.current = setTimeout(() => {
        setActive((active) => ({
          ...active,
          sliding: false,
        }));
      }, 600);
    } else {
      interval.current = setInterval(() => {
        startSlide();
      }, 5000);
    }

    return () => {
      clearInterval(interval.current);
      clearTimeout(timeout2.current);
      clearSliderMouseOutTimeout();
    };
  }, [active.sliding, sliderInScreen]);

  useEffect(() => {
    const currentItem = offersLeftRef.current[0];
    const currentItemHeight = currentItem.offsetHeight;

    setActive((active) => ({
      ...active,
      indicatorHeight: currentItemHeight,
    }));
  }, []);

  useEffect(() => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setActive((active) => ({
        ...active,
        previousActiveOffer: null,
      }));
    }, 1000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [active.previousActiveOffer]);

  const startSlide = (direction = slideDirections.NEXT) => {
    if (active.sliding) {
      return;
    }

    if (direction !== slideDirection) {
      setSlideDirection(direction);
    } else {
      triggerNext();
    }
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    triggerNext();
  }, [slideDirection]);

  const jumpToSlide = (index) => {
    if (active.sliding || index === active.activeOffer) {
      return;
    }
    triggerNext(index);
  };

  useEffect(() => {
    const removeListener = () => window.removeEventListener('scroll', onScroll);

    const onScroll = () => {
      const { bottom } = sliderSectionRef.current.getBoundingClientRect();
      const { innerHeight } = window;

      const scrolledToMiddleOfSlider = sliderSectionRef.current.offsetHeight / 2 - (bottom - innerHeight) > 0;

      if (scrolledToMiddleOfSlider) {
        setSliderInScreen(true);
        removeListener();
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();
    return () => removeListener();
  }, []);

  const slideMouseEnter = () => {
    clearSliderMouseOutTimeout();
    clearInterval(interval.current);
  };

  const slideMouseLeave = () => {
    timeoutMouseOut.current = setTimeout(() => {
      triggerNext();
    }, 500);
  };

  return (
    <div className="slider" ref={sliderSectionRef}>
      <div className="slider-left">
        <div className="slider-title">
          Earn
          <br className="br1" /> Unrivaled
          <br /> Payout
        </div>
        <div className="offer-outer-box">
          <div className="indicator">
            <span
              className="active-indicator"
              ref={indicatorActiveRef}
              style={{ transform: `translateY(${active.indicatorTranslateY}px)`, height: `${active.indicatorHeight}px` }}
            />
          </div>
          <div className="offers-list">
            {jerkmateOffers.map((offer, i) => (
              <div
                key={i}
                className={`offer-box ${active.activeOffer === i ? 'active' : ''}`}
                ref={(div) => (offersLeftRef.current[i] = div)}
                onClick={() => jumpToSlide(i)}
              >
                <div className="inner">
                  <h2 className="jerkmate-pink">{offer.name}</h2>
                  {explodeDescription(offer.description).customPayout
                    ? ReactHtmlParser(explodeDescription(offer.description).customPayout)
                    : getConversionTypeMessage(offer)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="slider-right" ref={sliderRightRef}>
        <div className="vertical-slides">
          {jerkmateOffers.map((offer, i) => (
            <div
              key={i}
              className={`vertical-slide-box ${slideDirection} ${
                active.activeOffer === i ? 'slide-center' : active.previousActiveOffer === i ? 'slide-out' : ''
              }`}
              style={{ position: active.activeOffer === i ? 'relative' : 'absolute' }}
              onMouseEnter={() => slideMouseEnter()}
              onMouseLeave={() => slideMouseLeave()}
            >
              <div className="slide-mobile-content">
                <h2 className="jerkmate-pink">{offer.name}</h2>
                {explodeDescription(offer.description).customPayout
                  ? ReactHtmlParser(explodeDescription(offer.description).customPayout)
                  : getConversionTypeMessage(offer)}
              </div>
              <div className="vertical-slide">
                <JerkmateOfferCard {...offer} isCarrousel={true} />
              </div>
            </div>
          ))}
        </div>
        <div className="slider-next-prev-mobile">
          <div
            className="slide-button"
            onClick={() => {
              startSlide(slideDirections.PREVIOUS);
            }}
          >
            <svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="Vector"
                d="M5.001 1L1 5M1 5L5.001 9M1 5L14 5"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="slide-button"
            onClick={() => {
              startSlide();
            }}
          >
            <svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="Vector"
                d="M9.999 9L14 5M14 5L9.999 1M14 5L1 5"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  offers: state.jerkmate.offers,
}))(JerkmateOfferSlider);

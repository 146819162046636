import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import Tooltip from './ReactTooltip';
import CheckCircle from './svg/CheckCircle.react';
import ErrorCircle from './svg/ErrorCircle.react';

const SimplifiedInput = (props) => {
  const {
    dontBlurIfPristine,
    field,
    id,
    label,
    labelClasses,
    optional,
    tooltip,
    autoFocus,
    className,
    disabled,
    inputDisabled,
    maxLength,
    tabIndex,
    type,
    placeholder,
    key,
    normalizeBudget,
    canShowPassword,
    inputmode,
    showValidationIcon = true,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState('');
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = () => {
    if (!dontBlurIfPristine || !field.pristine) {
      field.onBlur();
    }
  };

  const renderLabel = (fieldValueExists) => {
    const classNames = [labelClasses, fieldValueExists ? 'active' : undefined];

    return (
      <label className={classNames.join(' ')} htmlFor={id}>
        <FormattedMessage {...label} className="checkbox-label" />
        {optional ? (
          <span className="simplified-optional-label">
            <FormattedMessage {...messages.genericTextOptionalInput} />
          </span>
        ) : null}
        {!!tooltip && (
          <Tooltip button id="password-tooltip" position="right" tooltip={tooltip}>
            <i className="material-icons icon-info">info</i>
          </Tooltip>
        )}
      </label>
    );
  };

  const renderShowPasswordBtn = () => (
    <button
      className={`reveal-password ${showPassword ? 'open' : 'close'}`}
      onClick={() => toggleShowPassword()}
      title="Show password"
      type="button"
    >
      <i className="material-icons icon-open">&#xE8F4;</i>
      <i className="material-icons icon-close">&#xE8F5;</i>
    </button>
  );

  const currencyFormat = () => {
    const formattedString = field.value.toString();
    return `$${formattedString
      .replaceAll('$', '')
      .replaceAll(',', '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  useEffect(() => {
    if (field.value !== '' && field.value !== undefined && field.value !== null) {
      setFormattedNumber(currencyFormat());
    }
  }, [field.value]);

  const passwordType = showPassword ? 'text' : 'password';
  const isPassword = type === 'password';

  const fieldValueExists = !!field.value && field.value.toString().length >= 1;

  const fieldClasses = [
    'simplified-input',
    className,
    !!field && field.error && field.touched ? 'error' : undefined,
    !!field && field.valid && field.touched ? 'valid' : undefined,
  ];

  return (
    <div className={fieldClasses.join(' ')}>
      {label ? renderLabel(fieldValueExists) : null}
      {!isPassword || canShowPassword === false ? null : renderShowPasswordBtn()}
      <input
        autoFocus={autoFocus || false}
        className={fieldValueExists ? 'hasValue' : undefined}
        disabled={disabled || inputDisabled || false}
        id={id}
        inputMode={inputmode}
        key={key}
        maxLength={maxLength}
        name={field.name}
        onBlur={handleBlur}
        onChange={field.onChange}
        onDragStart={field.onDragStart}
        onDrop={field.onDrop}
        onFocus={field.onFocus}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        type={isPassword ? passwordType : type}
        value={normalizeBudget ? formattedNumber : field.value}
      />
      {showValidationIcon ? (
        <>
          {!!field && field.value !== '' && field.touched && field.valid ? (
            <i className="icon-validation" key="valid">
              <CheckCircle />
            </i>
          ) : null}
        </>
      ) : null}
      {!!field && field.touched && field.error
        ? [
            <i className="icon-validation" key="error">
              <ErrorCircle />
            </i>,
            <div className="field-error" key="error div">
              {field.error}
            </div>,
          ]
        : null}
    </div>
  );
};

SimplifiedInput.defaultProps = {
  dontBlurIfPristine: true,
};

SimplifiedInput.propTypes = {
  autoFocus: PropTypes.bool,
  canShowPassword: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dontBlurIfPristine: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  label: PropTypes.object,
  labelClasses: PropTypes.string,
  maxLength: PropTypes.number,
  optional: PropTypes.bool,
  tabIndex: PropTypes.number,
  tooltip: PropTypes.string,
  type: PropTypes.string,
};

export default injectIntl(SimplifiedInput);

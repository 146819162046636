import PropTypes from 'prop-types';
import { IndexLink, Link } from 'react-router';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import Tooltip from '../../components/reusables/ReactTooltip';
import ColorTemplateIcon from '../../components/reusables/svg/ColorTemplate.react';

import { setAnimatedTemplateSurvey } from '../../actions/survey-generator-actions';

const SurveyGeneratorTabs = (props) => {
  const { animated, colorTemplate, dispatch, intl, pathname } = props;

  const getToolTip = (message) => {
    if (!colorTemplate) {
      return `${intl.formatMessage(messages.surveyGeneratorChooseColorTemplateToEnable)} ${message}`;
    }
  };
  const handleClickPage = (e) => {
    if (!colorTemplate) {
      e.preventDefault();
      if (animated) {
        dispatch(setAnimatedTemplateSurvey(false));
      }

      setTimeout(() => {
        dispatch(setAnimatedTemplateSurvey(true));
      }, 50);
    }
  };

  return (
    <ul className="tabs">
      <li className={pathname === '/survey-machine' ? 'selected' : 'disabled'}>
        <IndexLink
          className="color-template"
          onClick={() => {
            Gtm.event('survey machine', 'Click', 'template tab');
          }}
          to="/survey-machine"
        >
          <ColorTemplateIcon />
          <FormattedMessage {...messages.surveyGeneratorTemplate} />
        </IndexLink>
      </li>
      <Tooltip
        className={pathname === '/survey-machine/basic-configuration' ? 'selected' : 'disabled'}
        id="survey-generator-tabs-basic"
        li="true"
        tooltip={getToolTip(intl.formatMessage(messages.surveyGeneratorBasicConfiguration))}
      >
        <Link
          onClick={(e) => {
            handleClickPage(e);
            if (colorTemplate) {
              Gtm.event('survey machine', 'Click', 'basic configuration tab');
            }
          }}
          onlyActiveOnIndex={false}
          to="/survey-machine/basic-configuration"
        >
          <i className="material-icons">settings</i>
          <FormattedMessage {...messages.surveyGeneratorBasicConfiguration} />
        </Link>
      </Tooltip>
      <Tooltip
        className={pathname === '/survey-machine/reward-options' ? 'selected' : 'disabled'}
        id="survey-generator-tabs-reward"
        li="true"
        tooltip={getToolTip(intl.formatMessage(messages.surveyGeneratorRewardOptions))}
      >
        <Link
          onClick={(e) => {
            handleClickPage(e);
            if (colorTemplate) {
              Gtm.event('survey machine', 'Click', 'reward options tab');
            }
          }}
          onlyActiveOnIndex={false}
          to="/survey-machine/reward-options"
        >
          <i className="material-icons">local_offer</i>
          <FormattedMessage {...messages.surveyGeneratorRewardOptions} />
        </Link>
      </Tooltip>
    </ul>
  );
};

SurveyGeneratorTabs.propTypes = {
  animated: PropTypes.bool,
  colorTemplate: PropTypes.number,
  dispatch: PropTypes.func,
  intl: intlShape.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default injectIntl(SurveyGeneratorTabs);

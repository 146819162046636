export default {
  paymentInfosBankInfosTitle: {
    id: 'paymentForm.bankInfosTitle',
    description: 'Bank information',
    defaultMessage: 'Bank information',
  },
  paymentInfosBankName: {
    id: 'paymentForm.bankName',
    description: "Bank's name",
    defaultMessage: "Bank's name",
  },
  paymentInfosBankAddress: {
    id: 'paymentForm.bankAddress',
    description: "Bank's address",
    defaultMessage: "Bank's address",
  },
  paymentInfosBeneficiaryInfosTitle: {
    id: 'paymentForm.beneficiaryInfosTitle',
    description: 'Beneficiary information',
    defaultMessage: 'Beneficiary information',
  },
  paymentInfosIntermediaryBankInfosTitle: {
    id: 'paymentForm.intermediaryInfosTitle',
    description: 'Intermediary Bank information',
    defaultMessage: 'Intermediary Bank information',
  },
  paymentInfosIntermediaryBankStatusLabel: {
    id: 'paymentFormLabel.intermediaryBankStatus',
    description: 'I have an intermediary bank',
    defaultMessage: 'I have an intermediary bank',
  },
  paymentInfosCheckPayableToLabel: {
    id: 'paymentFormLabel.checkPayableTo',
    description: 'Payable to',
    defaultMessage: 'Payable to',
  },
  paymentInfosPaxumEmailLabel: {
    id: 'paymentFormLabel.paxumEmail',
    description: 'Paxum email',
    defaultMessage: 'Paxum email',
  },
  paymentInfosPaypalEmailLabel: {
    id: 'paymentFormLabel.paypalEmail',
    description: 'PayPal email',
    defaultMessage: 'PayPal email',
  },
  paymentInfosPaymentMethodFeesLabel: {
    id: 'paymentFormLabel.paymentMethodFees',
    description: 'Payment method (fees)',
    defaultMessage: 'Payment method (fees)',
  },
  paymentInfosPaymentMethodFeesLabelPaypalDisclaimer: {
    id: 'paymentFormLabel.paymentMethodFeesDisclaimer',
    description:
      'Your payments will be held if your PayPal account is not registered in one of the authorized countries. Please contact support@crakrevenue.com or your affiliate manager with any questions you have in order to avoid this situation.',
    defaultMessage:
      'Your payments will be held if your PayPal account is not registered in one of the authorized countries. Please contact support@crakrevenue.com or your affiliate manager with any questions you have in order to avoid this situation.',
  },
  paymentInfosPaymentMethodAlternativeMethodDisclaimer: {
    id: 'paymentFormLabel.paymentMethodAlternativeMethodDisclaimer',
    description:
      'You have not chosen a standard banking payment method such as Wire. The use of an alternative payment method is at your own risk. We do not offer any guarantee of payment from the moment it is sent to this type of payment processor. For more information, please see our Terms of Service.',
    defaultMessage:
      'You have not chosen a standard banking payment method such as Wire. The use of an alternative payment method is at your own risk. We do not offer any guarantee of payment from the moment it is sent to this type of payment processor. For more information, please see our <a href="https://www.crakrevenue.com/tos/" target="_blank"><span class="text">Terms of Service</span></a>.',
  },
  paymentInfosAchBankAccountCurrencyMessage: {
    id: 'paymentFormLabel.achBankAccountCurrencyMustMatch',
    description: 'Your bank account currency must match.',
    defaultMessage: 'Your bank account currency must match.',
  },
  paymentInfosRussiaUkraineMessage: {
    id: 'paymentFormLabel.RussiaUkraineMessage',
    description:
      'You have selected the countries of Ukraine or Russia as your Beneficiary country or bank country. Be advised that ACH and Wire payments are not available for the Crimea Region. If you are currently in this region, please select another payment method as payments will not be processed at this time via bank transfers for the Crimea Region. </br> Please contact your affiliate manager with any questions you may have in order to avoid this situation.',
    defaultMessage:
      'You have selected the countries of Ukraine or Russia as your Beneficiary country or bank country. Be advised that ACH and Wire payments are not available for the Crimea Region. If you are currently in this region, please select another payment method as payments will not be processed at this time via bank transfers for the Crimea Region. </br> Please contact your affiliate manager with any questions you may have in order to avoid this situation.',
  },
  paymentInfosAchExchangeRate: {
    id: 'paymentFormLabel.achBankAccountExchangeRate',
    description:
      'Your payment will be issued in {currency} from USD. CrakRevenue is not responsible for the currency exchange fees applied to your payment by our service provider.',
    defaultMessage:
      'Your payment will be issued in {currency} from USD. CrakRevenue is not responsible for the currency exchange fees applied to your payment by our service provider.',
  },
  paymentInfosAchBankNotAccepted: {
    id: 'paymentFormLabel.achBankAccountBankNotAccepted',
    description: 'Bank institutions associated with Payoneer will not work for ACH payments.',
    defaultMessage: 'Bank institutions associated with Payoneer will not work for ACH payments.',
  },
  paymentInfosReferenceInformationLabel: {
    id: 'paymentFormLabel.referenceInformation',
    description: 'Reference information',
    defaultMessage: 'Reference information',
  },
  paymentInfosWireABAroutingLabel: {
    id: 'paymentFormLabel.wireABArouting',
    description: 'ABA, Routing or Branch Number',
    defaultMessage: 'ABA, Routing or Branch Number',
  },
  paymentInfosWireIbanLabel: {
    id: 'paymentFormLabel.wireAccountIban',
    description: 'IBAN (International Bank Account Number)',
    defaultMessage: 'IBAN (International Bank Account Number)',
  },
  paymentInfosWireAccountNumberLabel: {
    id: 'paymentFormLabel.wireAccountNumber',
    description: 'Account Number / IBAN',
    defaultMessage: 'Account Number / IBAN',
  },
  paymentInfosWireCanadaAccountNumberLabel: {
    id: 'paymentFormLabel.wireCanadaAccountNumber',
    description: 'Account Number',
    defaultMessage: 'Account Number',
  },
  paymentInfosWireBicSwiftLabel: {
    id: 'paymentFormLabel.wireBicSwift',
    description: 'BIC / SWIFT',
    defaultMessage: 'BIC / SWIFT',
  },
  paymentInfosWireTransitNumberLabel: {
    id: 'paymentFormLabel.wireTransitNumber',
    description: "Bank's Institution and Transit Number",
    defaultMessage: "Bank's Institution and Transit Number",
  },
  paymentInfosBankInformationName: {
    id: 'paymentFormLabel.bankInformationName',
    description: "Bank's name",
    defaultMessage: "Bank's name",
  },
  paymentInfosBankInformationAddress: {
    id: 'paymentFormLabel.bankInformationAddress',
    description: "Bank's address",
    defaultMessage: "Bank's address",
  },
  paymentInfosBankInformationCity: {
    id: 'paymentFormLabel.bankInformationCity',
    description: "Bank's city",
    defaultMessage: "Bank's city",
  },
  paymentInfosBankInformationCountry: {
    id: 'paymentFormLabel.bankInformationCountry',
    description: "Bank's country",
    defaultMessage: "Bank's country",
  },
  paymentInfosBankInformationProvince: {
    id: 'paymentFormLabel.bankInformationProvince',
    description: "Bank's province",
    defaultMessage: "Bank's province",
  },
  paymentInfosBankInformationState: {
    id: 'paymentFormLabel.bankInformationState',
    description: "Bank's state",
    defaultMessage: "Bank's state",
  },
  paymentInfosBankInformationRegion: {
    id: 'paymentFormLabel.bankInformationRegion',
    description: "Bank's region",
    defaultMessage: "Bank's region",
  },
  paymentInfosBankInformationZipcode: {
    id: 'paymentFormLabel.bankInformationZipcode',
    description: "Bank's zip code",
    defaultMessage: "Bank's zip code",
  },
  paymentInfosBankInformationPostalcode: {
    id: 'paymentFormLabel.bankInformationPostalcode',
    description: "Bank's postal code",
    defaultMessage: "Bank's postal code",
  },
  paymentInfosBankInformationZipPostalcode: {
    id: 'paymentFormLabel.bankInformationZipPostalcode',
    description: "Bank's zip/postal code",
    defaultMessage: "Bank's zip/postal code",
  },
  paymentInfosWireIntermediaryBankInformationName: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationName',
    description: "Intermediary bank's name",
    defaultMessage: "Intermediary bank's name",
  },
  paymentInfosWireIntermediaryBankInformationAddress: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationAddress',
    description: "Intermediary bank's address",
    defaultMessage: "Intermediary bank's address",
  },
  paymentInfosWireIntermediaryBankInformationCity: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationCity',
    description: "Intermediary bank's city",
    defaultMessage: "Intermediary bank's city",
  },
  paymentInfosWireIntermediaryBankInformationCountry: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationCountry',
    description: "Intermediary bank's country",
    defaultMessage: "Intermediary bank's country",
  },
  paymentInfosWireIntermediaryBankInformationProvince: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationProvince',
    description: "Intermediary bank's province",
    defaultMessage: "Intermediary bank's province",
  },
  paymentInfosWireIntermediaryBankInformationState: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationState',
    description: "Intermediary bank's state",
    defaultMessage: "Intermediary bank's state",
  },
  paymentInfosWireIntermediaryBankInformationRegion: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationRegion',
    description: "Intermediary bank's region",
    defaultMessage: "Intermediary bank's region",
  },
  paymentInfosWireIntermediaryBankInformationZipcode: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationZipcode',
    description: "Intermediary bank's zip code",
    defaultMessage: "Intermediary bank's zip code",
  },
  paymentInfosWireIntermediaryBankInformationPostalcode: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationPostalcode',
    description: "Intermediary bank's postal code",
    defaultMessage: "Intermediary bank's postal code",
  },
  paymentInfosWireIntermediaryBankInformationZipPostalcode: {
    id: 'paymentFormLabel.wireIntermediaryBankInformationZipPostalcode',
    description: "Intermediary bank's zip/postal code",
    defaultMessage: "Intermediary bank's zip/postal code",
  },
  paymentInfosWireIntermediaryIbanLabel: {
    id: 'paymentFormLabel.wireIntermediaryAccountIban',
    description: "Intermediary bank's IBAN (International Bank Account Number)",
    defaultMessage: "Intermediary bank's IBAN (International Bank Account Number)",
  },
  paymentInfosWireIntermediaryAccountNumberLabel: {
    id: 'paymentFormLabel.wireIntermediaryAccountNumber',
    description: "Intermediary bank's Account Number / IBAN",
    defaultMessage: "Intermediary bank's Account Number / IBAN",
  },
  paymentInfosWireIntermediaryCanadaAccountNumberLabel: {
    id: 'paymentFormLabel.wireIntermediaryCanadaAccountNumber',
    description: "Intermediary bank's Account Number",
    defaultMessage: "Intermediary bank's Account Number",
  },
  paymentInfosWireIntermediaryTransitNumberLabel: {
    id: 'paymentFormLabel.wireIntermediaryTransitNumber',
    description: "Intermediary bank's Institution and Transit Number",
    defaultMessage: "Intermediary bank's Institution and Transit Number",
  },
  paymentInfosWireIntermediaryBicSwiftLabel: {
    id: 'paymentFormLabel.wireIntermediaryBicSwift',
    description: "Intermediary bank's BIC / SWIFT",
    defaultMessage: "Intermediary bank's BIC / SWIFT",
  },
  paymentInfosWireIntermediaryABAroutingLabel: {
    id: 'paymentFormLabel.wireIntermediaryABArouting',
    description: "Intermediary bank's ABA, Routing or Branch Number",
    defaultMessage: "Intermediary bank's ABA, Routing or Branch Number",
  },
  paymentInfosUserFirstName: {
    id: 'paymentFormLabel.firstName',
    description: 'First Name',
    defaultMessage: 'First Name',
  },
  paymentInfosUserLastName: {
    id: 'paymentFormLabel.lastName',
    description: 'Last Name',
    defaultMessage: 'Last Name',
  },
  paymentInfosEPayEWalletNumber: {
    id: 'paymentFormLabel.eWalletNumber',
    description: 'E-Wallet Number',
    defaultMessage: 'E-Wallet Number',
  },
  paymentInfosBitcoinEWalletAdress: {
    id: 'paymentFormLabel.bitcoinEWalletAdress',
    description: 'Wallet Address',
    defaultMessage: 'Wallet Address',
  },
  paymentInfosMassPayEmailLabel: {
    id: 'paymentFormLabel.paymentInfosMassPayEmail',
    description: 'MassPay Email',
    defaultMessage: 'MassPay Email',
  },
  paymentInfosMassPayFirstnameLabel: {
    id: 'paymentFormLabel.paymentInfosMassPayFirstname',
    description: 'MassPay First Name',
    defaultMessage: 'MassPay First Name',
  },
  paymentInfosMassPayLastnameLabel: {
    id: 'paymentFormLabel.paymentInfosMassPayLastname',
    description: 'MassPay Last Name',
    defaultMessage: 'MassPay Last Name',
  },
  paymentInfosMassPayEmailDisclaimer: {
    id: 'paymentFormLabel.paymentInfosMassPayEmailDisclaimer',
    description:
      'If you already have a MassPay account, you must use the same email address. Otherwise, enter the email address you wish to associate with MassPay. An invitation to open an account will be sent to this address with your first payment. If you have any questions, contact our Customer Experience Team or your Affiliate Manager.',
    defaultMessage:
      'If you already have a MassPay account, you must use the same email address. Otherwise, enter the email address you wish to associate with MassPay. An invitation to open an account will be sent to this address with your first payment. If you have any questions, contact our Customer Experience Team or your Affiliate Manager.',
  },
  paymentInfosMassPayFirstnameDisclaimer: {
    id: 'paymentFormLabel.paymentInfosMassPayFirstnameDisclaimer',
    description: 'This must match your MassPay Account. For a Business account, match the representative’s first name.',
    defaultMessage: 'This must match your MassPay Account. For a Business account, match the representative’s first name.',
  },
  paymentInfosMassPayLastnameDisclaimer: {
    id: 'paymentFormLabel.paymentInfosMassPayLastnameDisclaimer',
    description: 'This must match your MassPay Account. For a Business account, match the representative’s last name.',
    defaultMessage: 'This must match your MassPay Account. For a Business account, match the representative’s last name.',
  },
};

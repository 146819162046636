import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { initialFetching } from '../../utils/initialFetching';
import provincesList from '../../utils/list-options/provinces';
import statesList from '../../utils/list-options/states';
import validate from './validateBillingInfos';
import CheckSection from './billing-infos/CheckSection.react';
import PaxumSection from './billing-infos/PaxumSection.react';
import WireSection from './billing-infos/WireSection.react';
import PaypalSection from './billing-infos/PaypalSection.react';
import AchSection from './billing-infos/AchSection.react';
import MassPaySection from './billing-infos/MassPaySection.react';
import EPayServiceSection from './billing-infos/EPayServiceSection.react';
import PaymentMethodSection from './billing-infos/PaymentMethodSection.react';
import MinimumPayoutSection from './billing-infos/MinimumPayoutSection.react';

import BitcoinSection from './billing-infos/BitcoinSection.react';
import { LoadingPage, VideoModalIcon, Info } from '../../components/reusables';

import ExcludedCountriesHelper from '../../utils/ExcludedCountriesHelper';
import { Gtm } from '../../utils/gtm';
import BeneficiarySection from './billing-infos/Beneficiary.react';
import CompleteIdentityService from '../../components/Profile/CompleteIdentity/CompleteIdentityService';
import CompleteIdentityComponent from '../../components/Profile/CompleteIdentity/CompleteIdentityComponent';
import CompleteProfileComponent from '../../components/Profile/CompleteProfile/CompleteProfileComponent';
import CompleteProfileService from '../../components/Profile/CompleteProfile/CompleteProfileService';
import { AffiliateTypeEnum } from '../../enum/AffiliateTypeEnum';
import EpayWalletConverter from '../../utils/EpayWalletConverter';
import { connect } from 'react-redux';
import { useConfirmationOnFields } from '../../components/reusables/useConfirmationOnFields';
import { isKeyValueEqualBetweenObjects } from './billing-infos/billingHelper';
import { getPaymentMethodIds } from './billing-infos/paymentMethodHelper.js';

const BillingInfos = ({
  accountType,
  achConfigurations,
  achConfigurationsIsFetching,
  affInfos,
  beneficiaryInfos,
  changeBillingFormDisplayStatus,
  countries,
  dispatch,
  fields,
  handleSubmit,
  hasToCompleteInformation,
  invalid,
  listValidationStatus,
  minimumPayoutList,
  paymentMethods,
  paymentMethodsV2,
  paymentTerm,
  validationStatusId,
  values,
  route,
  router,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const billingRef = useRef('billingInfos');
  useEffect(() => {
    initialFetching(dispatch);
    setIsLoading(false);

    return () => {
      changeBillingFormDisplayStatus(false);
    };
  }, []);

  const companyBeneficiaryButtonRef = useRef(null);
  const personBeneficiaryButtonRef = useRef(null);

  const paymentMethodId = useMemo(() => parseInt(values.paymentMethod, 10), [values.paymentMethod]);

  const minimumPayoutsOptions = useMemo(() => {
    const tmpList = [];
    minimumPayoutList.forEach((minimumPayout) => {
      if (paymentMethodId === parseInt(minimumPayout.payment_method_id, 10)) {
        tmpList.push(minimumPayout.id.toString());
      }
    });
    return tmpList;
  }, [minimumPayoutList, paymentMethodId]);

  useEffect(() => {
    if (values) {
      if (minimumPayoutsOptions.length && minimumPayoutsOptions.indexOf(String(values.minimumPayouts)) === -1) {
        dispatch({
          type: 'redux-form/CHANGE',
          form: 'billingInfos',
          field: 'minimumPayouts',
          value: null,
        });
      }

      changeBillingFormDisplayStatus(true);
    }
  }, [values, minimumPayoutsOptions, paymentMethodId]);

  const dataLoading = achConfigurationsIsFetching || isLoading;

  const isAffiliateTypeCompany = useMemo(() => accountType?.toUpperCase() === AffiliateTypeEnum.COMPANY, [accountType]);
  const isAffiliateTypeLegacy = useMemo(() => accountType?.toUpperCase() === AffiliateTypeEnum.LEGACY, [accountType]);

  const {
    paymentMethodCheckId,
    paymentMethodWireId,
    paymentMethodPaxumId,
    paymentMethodAchId,
    paymentMethodPaypalId,
    paymentMethodEpayServiceId,
    paymentMethodBitcoinId,
    paymentMethodMasspayId,
  } = useMemo(() => getPaymentMethodIds([...paymentMethods, ...paymentMethodsV2]), [paymentMethods, paymentMethodsV2]);

  let accountPaymentMethods = useMemo(
    () => (isAffiliateTypeLegacy ? paymentMethods?.filter((element) => element.id !== paymentMethodMasspayId) : paymentMethodsV2),
    [isAffiliateTypeLegacy, paymentMethods, paymentMethodsV2, paymentMethodMasspayId]
  );

  if (isAffiliateTypeCompany) {
    accountPaymentMethods = accountPaymentMethods.filter((pm) => pm.id !== paymentMethodBitcoinId);
  }

  const hasToCompleteProfile = CompleteProfileService.hasToCompleteProfile({
    affInfos,
  });

  const hasToCompleteBeneficiary = CompleteProfileService.hasToCompleteBeneficiaryProfile({
    beneficiaryInfos,
  });

  const hasToCompleteIdentity = CompleteIdentityService.hasToCompleteIdentity({
    hasToCompleteProfile,
    validationStatusList: listValidationStatus,
    validationStatusId,
    affiliateType: accountType,
  });

  const [submittedValues, setSubmittedValues] = useState({});

  useConfirmationOnFields({
    router,
    route,
    items: fields,
    alertMessage: messages.profileSettingsUnsavedChecked.description,
    bypassFlag: isKeyValueEqualBetweenObjects(fields, submittedValues),
  });

  const handleBillingSubmit = (data) => {
    const tmp = {};
    for (const [fieldName, field] of Object.entries(fields)) {
      tmp[fieldName] = field.value;
    }
    setSubmittedValues(tmp);
    if (!isAffiliateTypeLegacy) {
      if (isAffiliateTypeCompany) {
        companyBeneficiaryButtonRef.current.click();
      } else {
        personBeneficiaryButtonRef.current.click();
      }
    }
    handleSubmit(data);
  };

  const handleGtmEvent = () => {
    if (fields.paymentMethod.value !== fields.paymentMethod.initialValue) {
      const eventLabel = accountPaymentMethods.filter(({ id }) => fields.paymentMethod.value === id);
      eventLabel.forEach((paymentMethod) =>
        Gtm.event('profile - billing information', 'Click', `payment method - ${paymentMethod.name}`)
      );
    }

    if (fields.minimumPayouts.value !== fields.minimumPayouts.initialValue) {
      minimumPayoutList.forEach((minimumPayout) => {
        if (paymentMethodId === parseInt(minimumPayout.payment_method_id, 10)) {
          if (minimumPayout.id.toString() === fields.minimumPayouts.value) {
            return Gtm.event('profile - billing information', 'Click', `minimum payout - $${minimumPayout.amount}`);
          }
        }
      });
    }

    if (fields.achBeneficiaryCountryCode.value !== fields.achBeneficiaryCountryCode.initialValue) {
      achConfigurations.forEach((achConfig) => {
        if (achConfig.id.toString() === fields.achConfigId.value) {
          return Gtm.event('profile - billing information', 'Click', `ach bank's country - ${achConfig.country_name}`);
        }
      });
    }

    if (fields.achBeneficiaryCountryCode.value !== fields.achBeneficiaryCountryCode.initialValue) {
      countries.forEach((country) => {
        if (country.code === fields.achBeneficiaryCountryCode.value) {
          return Gtm.event('profile - billing information', 'Click', `ach country - ${country.name}`);
        }
      });
    }

    if (fields.achBeneficiaryState.value !== fields.achBeneficiaryState.initialValue) {
      statesList.forEach((state) => {
        if (state.value === fields.achBeneficiaryState.value) {
          return Gtm.event('profile - billing information', 'Click', `ach state - ${state.label.description}`);
        }
      });
    }

    if (fields.achBeneficiaryState.value !== fields.achBeneficiaryState.initialValue) {
      provincesList.forEach((province) => {
        if (province.value === fields.achBeneficiaryState.value) {
          return Gtm.event('profile - billing information', 'Click', `ach province - ${province.label.description}`);
        }
      });
    }

    if (fields.checkCountry.value !== fields.checkCountry.initialValue) {
      countries.forEach((country) => {
        if (country.code === fields.checkCountry.value) {
          return Gtm.event('profile - billing information', 'Click', `check country - ${country.name}`);
        }
      });
    }

    if (fields.checkState.value !== fields.checkState.initialValue) {
      statesList.forEach((state) => {
        if (state.value === fields.checkState.value) {
          return Gtm.event('profile - billing information', 'Click', `check state - ${state.label.description}`);
        }
      });
    }

    if (fields.checkProvince.value !== fields.checkProvince.initialValue) {
      provincesList.forEach((province) => {
        if (province.value === fields.checkProvince.value) {
          return Gtm.event('profile - billing information', 'Click', `check province - ${province.label.description}`);
        }
      });
    }

    if (fields.wireBeneficiaryCountry.value !== fields.wireBeneficiaryCountry.initialValue) {
      countries.forEach((country) => {
        if (country.code === fields.wireBeneficiaryCountry.value) {
          return Gtm.event('profile - billing information', 'Click', `wire country - ${country.name}`);
        }
      });
    }

    if (fields.wireBeneficiaryState.value !== fields.wireBeneficiaryState.initialValue) {
      statesList.forEach((state) => {
        if (state.value === fields.wireBeneficiaryState.value) {
          return Gtm.event('profile - billing information', 'Click', `wire state - ${state.label.description}`);
        }
      });
    }

    if (fields.wireBeneficiaryProvince.value !== fields.wireBeneficiaryProvince.initialValue) {
      provincesList.forEach((province) => {
        if (province.value === fields.wireBeneficiaryProvince.value) {
          return Gtm.event('profile - billing information', 'Click', `wire province - ${province.label.description}`);
        }
      });
    }

    if (fields.wireBankCountry.value !== fields.wireBankCountry.initialValue) {
      countries.forEach((country) => {
        if (country.code === fields.wireIntermediaryBankCountry.value) {
          return Gtm.event('profile - billing information', 'Click', `wire bank's country - ${country.name}`);
        }
      });
    }

    if (fields.wireBankState.value !== fields.wireBankState.initialValue) {
      statesList.forEach((state) => {
        if (state.value === fields.wireBankState.value) {
          return Gtm.event('profile - billing information', 'Click', `wire bank's state - ${state.label.description}`);
        }
      });
    }

    if (fields.wireBankProvince.value !== fields.wireBankProvince.initialValue) {
      provincesList.forEach((province) => {
        if (province.value === fields.wireBankProvince.value) {
          return Gtm.event('profile - billing information', 'Click', `wire bank's province - ${province.label.description}`);
        }
      });
    }

    if (fields.wireIntermediaryBankCountry.value !== fields.wireIntermediaryBankCountry.initialValue) {
      countries.forEach((country) => {
        if (country.code === fields.wireIntermediaryBankCountry.value) {
          return Gtm.event('profile - billing information', 'Click', `wire intermediate bank's country - ${country.name}`);
        }
      });
    }

    if (fields.wireIntermediaryBankState.value !== fields.wireIntermediaryBankState.initialValue) {
      statesList.forEach((state) => {
        if (state.value === fields.wireIntermediaryBankState.value) {
          return Gtm.event(
            'profile - billing information',
            'Click',
            `wire intermediate bank's state - ${state.label.description}`
          );
        }
      });
    }

    if (fields.wireIntermediaryBankProvince.value !== fields.wireIntermediaryBankProvince.initialValue) {
      provincesList.forEach((province) => {
        if (province.value === fields.wireIntermediaryBankProvince.value) {
          return Gtm.event(
            'profile - billing information',
            'Click',
            `wire intermediate bank's province - ${province.label.description}`
          );
        }
      });
    }

    Gtm.event('profile - billing information', 'Click', `Save changes - Billing`);
  };

  return (
    <div className="app-container container-fluid form-container" id="profile-billing-infos">
      {dataLoading ? <LoadingPage /> : null}
      {hasToCompleteProfile ? <CompleteProfileComponent /> : null}

      {hasToCompleteIdentity && (!accountType || !isAffiliateTypeCompany) ? <CompleteIdentityComponent /> : null}

      <div
        className={`user-details-wrapper ${hasToCompleteProfile || hasToCompleteIdentity ? 'locked' : ''}`}
        id="profile-billing"
      >
        {!isAffiliateTypeLegacy && (
          <BeneficiarySection
            companyBeneficiarySubmitRef={companyBeneficiaryButtonRef}
            dispatch={dispatch}
            parentRoute={route}
            personBeneficiarySubmitRef={personBeneficiaryButtonRef}
          />
        )}
        <div className="row">
          <form action="#" className="form-container col-md-12" method="post" onSubmit={handleBillingSubmit} ref={billingRef}>
            <h1 className="billing-infos-title">
              <FormattedMessage {...messages.profileBillingInfosTitle} />
            </h1>
            <div className={`${hasToCompleteBeneficiary ? 'locked transparent no-pointer' : ''}`}>
              <div className="row paymentTerms">
                <div className="col-md-12 paymentTermsDiv">
                  Payment terms: {paymentTerm.toUpperCase()}
                  <Info
                    onClick={() => {
                      Gtm.event('profile - billing information', 'Click', `payment terms - info icon`);
                    }}
                    title=""
                    url="https://support.crakrevenue.com/knowledge-base/minimum-payout-terms/"
                  />
                  <VideoModalIcon
                    className="pointer"
                    dispatch={dispatch}
                    gtmEvent={() => {
                      Gtm.event('profile - billing information', 'Click', `payment terms - video icon`);
                    }}
                    videoUrl="https://player.vimeo.com/video/391535028"
                  />
                </div>
              </div>
              <PaymentMethodSection
                accountPaymentMethods={accountPaymentMethods}
                fields={fields}
                isDisabled={hasToCompleteProfile || hasToCompleteIdentity || hasToCompleteBeneficiary}
                parentRoute={route}
                paymentMethodId={paymentMethodId}
              />
              {paymentMethodId !== 0 && !isNaN(paymentMethodId) && (
                <MinimumPayoutSection
                  hasToCompleteInformation={hasToCompleteInformation}
                  minimumPayoutField={fields.minimumPayouts}
                  minimumPayoutList={minimumPayoutList}
                  paymentMethodId={paymentMethodId}
                />
              )}
              <AchSection
                accountType={accountType}
                achConfigurations={achConfigurations}
                countries={countries}
                dispatch={dispatch}
                fields={_.pick(fields, [
                  'achBeneficiaryBankName',
                  'achBeneficiaryBankAddress',
                  'achAccountNumber',
                  'achBeneficiaryAddress',
                  'achBeneficiaryCity',
                  'achBeneficiaryCountryCode',
                  'achBeneficiaryName',
                  'achBeneficiaryState',
                  'achBeneficiaryZipCode',
                  'achConfigId',
                  'achCountryName',
                  'achTransitNumber',
                ])}
                values={values}
                willRender={paymentMethodId === paymentMethodAchId}
              />
              {accountType === 'Legacy' && (
                <CheckSection
                  countries={countries}
                  dispatch={dispatch}
                  fields={_.pick(fields, [
                    'checkPayableTo',
                    'checkAddress',
                    'checkCity',
                    'checkCountry',
                    'checkProvince',
                    'checkState',
                    'checkRegion',
                    'checkZipcode',
                  ])}
                  values={values}
                  willRender={paymentMethodId === paymentMethodCheckId}
                />
              )}
              <PaxumSection fields={_.pick(fields, ['paxumEmail'])} willRender={paymentMethodId === paymentMethodPaxumId} />
              {paymentMethodId === paymentMethodPaypalId && <PaypalSection fields={_.pick(fields, ['paypalEmail'])} />}
              <WireSection
                accountType={accountType}
                countries={countries}
                fields={_.pick(fields, [
                  'wireBeneficiaryName',
                  'wireBeneficiaryAddress',
                  'wireBeneficiaryCity',
                  'wireBeneficiaryCountry',
                  'wireBeneficiaryProvince',
                  'wireBeneficiaryState',
                  'wireBeneficiaryRegion',
                  'wireBeneficiaryZipcode',
                  'wireBankName',
                  'wireBankAddress',
                  'wireBankCity',
                  'wireBankCountry',
                  'wireBankProvince',
                  'wireBankState',
                  'wireBankRegion',
                  'wireBankZipcode',
                  'wireBankInstitutionNumber',
                  'wireBicSwift',
                  'wireABArouting',
                  'wireAccountNumberOrIban',
                  'wireIntermediaryBankStatus',
                  'wireIntermediaryBankName',
                  'wireIntermediaryBankAddress',
                  'wireIntermediaryBankCity',
                  'wireIntermediaryBankCountry',
                  'wireIntermediaryBankProvince',
                  'wireIntermediaryBankState',
                  'wireIntermediaryBankRegion',
                  'wireIntermediaryBankZipcode',
                  'wireIntermediaryBankInstitutionNumber',
                  'wireIntermediaryBicSwift',
                  'wireIntermediaryABArouting',
                  'wireIntermediaryAccountNumberOrIban',
                  'otherInformation',
                ])}
                values={values}
                willRender={paymentMethodId === paymentMethodWireId}
              />
              <EPayServiceSection
                accountType={accountType}
                fields={_.pick(fields, [
                  'epayserviceBeneficiaryFirstName',
                  'epayserviceBeneficiaryLastName',
                  'epayserviceEwalletNumber',
                ])}
                willRender={paymentMethodId === paymentMethodEpayServiceId}
              />
              {paymentMethodId === paymentMethodBitcoinId && (
                <BitcoinSection bitcoinWalletAddress={fields.bitcoinWalletAddress} />
              )}
              {paymentMethodId === paymentMethodMasspayId ? (
                <MassPaySection
                  masspayEmail={fields.masspayEmail}
                  masspayFirstname={fields.masspayFirstname}
                  masspayLastname={fields.masspayLastname}
                />
              ) : null}

              <div className="row">
                <div className="col-md-12 form-buttons text-right">
                  <button
                    className="waves-effect waves-light btn bg-primary-color"
                    disabled={invalid || hasToCompleteBeneficiary}
                    id="btn-save-billing-infos"
                    onClick={() => {
                      handleGtmEvent();
                    }}
                  >
                    <FormattedMessage {...messages.genericTextSaveChanges} />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

BillingInfos.propTypes = {
  accountType: PropTypes.string.isRequired,
  achConfigurations: PropTypes.array.isRequired,
  achConfigurationsIsFetching: PropTypes.bool,
  affInfos: PropTypes.object.isRequired,
  beneficiaryInfos: PropTypes.object,
  changeBillingFormDisplayStatus: PropTypes.func.isRequired,
  companyInfos: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasToCompleteInformation: PropTypes.bool,
  intl: intlShape.isRequired,
  invalid: PropTypes.bool.isRequired,
  listValidationStatus: PropTypes.array.isRequired,
  minimumPayoutList: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  paymentMethodsV2: PropTypes.array.isRequired,
  paymentTerm: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  validationStatusId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  listValidationStatus: state.application.lists.validationStatus,
  paymentTerm: state.profile.data.customProfile.affiliate.payment_term || ' ',
  hasToCompleteInformation: state.profileCompleted.hasToCompleteInformation,
  accountType: state.profile.data.customProfile.affiliate.affiliate_type?.type,
  paymentMethodsV2: state.application.lists.paymentMethodsV2,
  affInfos: state.profile.data.affInfos,
  validationStatusId: state.profile.data.affValidationStatus?.validation_status_id,
  minimumPayoutList: state.application.lists.minimumPayouts,
  beneficiaryInfos: state.profile.data?.affiliateBeneficiary,
}))(
  injectIntl(
    withRouter(
      reduxForm(
        {
          form: 'billingInfos',
          touchOnBlur: false,
          fields: [
            'paymentMethod',
            'minimumPayouts',
            'achBeneficiaryBankName',
            'achBeneficiaryBankAddress',
            'achAccountNumber',
            'achBeneficiaryAddress',
            'achBeneficiaryCity',
            'achBeneficiaryCountryCode',
            'achBeneficiaryName',
            'achBeneficiaryState',
            'achBeneficiaryZipCode',
            'achConfigId',
            'achCountryName',
            'achTransitNumber',
            'bitcoinWalletAddress',
            'checkPayableTo',
            'checkAddress',
            'checkCity',
            'checkCountry',
            'checkProvince',
            'checkState',
            'checkRegion',
            'checkZipcode',
            'epayserviceBeneficiaryFirstName',
            'epayserviceBeneficiaryLastName',
            'epayserviceEwalletNumber',
            'paxumEmail',
            'paypalEmail',
            'wireBeneficiaryName',
            'wireBeneficiaryAddress',
            'wireBeneficiaryCity',
            'wireBeneficiaryCountry',
            'wireBeneficiaryProvince',
            'wireBeneficiaryState',
            'wireBeneficiaryRegion',
            'wireBeneficiaryZipcode',
            'wireBankName',
            'wireBankAddress',
            'wireBankCity',
            'wireBankCountry',
            'wireBankProvince',
            'wireBankState',
            'wireBankRegion',
            'wireBankZipcode',
            'wireBankInstitutionNumber',
            'wireBicSwift',
            'wireABArouting',
            'wireAccountNumberOrIban',
            'wireIntermediaryBankStatus',
            'wireIntermediaryBankName',
            'wireIntermediaryBankAddress',
            'wireIntermediaryBankCity',
            'wireIntermediaryBankCountry',
            'wireIntermediaryBankProvince',
            'wireIntermediaryBankState',
            'wireIntermediaryBankRegion',
            'wireIntermediaryBankZipcode',
            'wireIntermediaryBankInstitutionNumber',
            'wireIntermediaryBicSwift',
            'wireIntermediaryABArouting',
            'wireIntermediaryAccountNumberOrIban',
            'otherInformation',
            'masspayFirstname',
            'masspayLastname',
            'masspayEmail',
          ],
          validate,
        },
        (state) => ({
          initialValues: {
            paymentMethod: parseInt(state.profile.data.customProfile.affiliate.payment_method_id, 10) || 0,
            minimumPayouts: parseInt(state.profile.data.customProfile.affiliate.minimum_payout_id, 10) || 0,
            achAccountNumber: state.profile.data.customProfile.affiliate.ach_account_number,
            achBeneficiaryBankName: state.profile.data.customProfile.affiliate.ach_beneficiary_bank_name,
            achBeneficiaryBankAddress: state.profile.data.customProfile.affiliate.ach_beneficiary_bank_address,
            achBeneficiaryAddress: state.profile.data.customProfile.affiliate.ach_beneficiary_address,
            achBeneficiaryCity: state.profile.data.customProfile.affiliate.ach_beneficiary_city,
            achBeneficiaryCountryCode: ExcludedCountriesHelper.isFilteredCountry(
              state.profile.data.customProfile.affiliate.ach_beneficiary_country_code,
              state.application.lists.countries,
              ExcludedCountriesHelper.getBillingAchExcludedCountries()
            ),
            achBeneficiaryName: state.profile.data.customProfile.affiliate.ach_beneficiary_name,
            achBeneficiaryState: state.profile.data.customProfile.affiliate.ach_beneficiary_state,
            achBeneficiaryZipCode: state.profile.data.customProfile.affiliate.ach_beneficiary_zipcode,
            achConfigId: state.profile.data.customProfile.affiliate.ach_config_id,
            achCountryName: state.profile.data.customProfile.affiliate.ach_country_name,
            achTransitNumber: state.profile.data.customProfile.affiliate.ach_transit_number,
            bitcoinWalletAddress: state.profile.data.customProfile.affiliate.bitcoin_wallet_address,
            checkPayableTo: state.profile.data.customProfile.affiliate.check_payable_to,
            checkAddress: state.profile.data.customProfile.affiliate.check_address,
            checkCity: state.profile.data.customProfile.affiliate.check_city,
            checkCountry: ExcludedCountriesHelper.isFilteredCountry(
              state.profile.data.customProfile.affiliate.check_country_code,
              state.application.lists.countries,
              ExcludedCountriesHelper.getBillingCheckExcludedCountries()
            ),
            checkProvince: state.profile.data.customProfile.affiliate.check_state,
            checkState: state.profile.data.customProfile.affiliate.check_state,
            checkRegion: state.profile.data.customProfile.affiliate.check_state,
            checkZipcode: state.profile.data.customProfile.affiliate.check_zipcode,
            epayserviceBeneficiaryFirstName: state.profile.data.customProfile.affiliate.epayservice_beneficiary_first_name,
            epayserviceBeneficiaryLastName: state.profile.data.customProfile.affiliate.epayservice_beneficiary_last_name,
            epayserviceEwalletNumber: EpayWalletConverter.convertLegacyWallet(
              state.profile.data.customProfile.affiliate.epayservice_ewallet_number
            ),
            paxumEmail: state.profile.data.customProfile.affiliate.paxum_email,
            paypalEmail: state.profile.data.customProfile.affiliate.paypal_email,
            wireBeneficiaryName: state.profile.data.customProfile.affiliate.wire_beneficiary_name,
            wireBeneficiaryAddress: state.profile.data.customProfile.affiliate.wire_beneficiary_address,
            wireBeneficiaryCity: state.profile.data.customProfile.affiliate.wire_beneficiary_city,
            wireBeneficiaryCountry: ExcludedCountriesHelper.isFilteredCountry(
              state.profile.data.customProfile.affiliate.wire_beneficiary_country_code,
              state.application.lists.countries,
              ExcludedCountriesHelper.getBillingWireExcludedCountries()
            ),
            wireBeneficiaryProvince: state.profile.data.customProfile.affiliate.wire_beneficiary_state,
            wireBeneficiaryState: state.profile.data.customProfile.affiliate.wire_beneficiary_state,
            wireBeneficiaryRegion: state.profile.data.customProfile.affiliate.wire_beneficiary_state,
            wireBeneficiaryZipcode: state.profile.data.customProfile.affiliate.wire_beneficiary_zipcode,
            wireBankName: state.profile.data.customProfile.affiliate.wire_bank_name,
            wireBankAddress: state.profile.data.customProfile.affiliate.wire_bank_address,
            wireBankCity: state.profile.data.customProfile.affiliate.wire_bank_city,
            wireBankCountry: ExcludedCountriesHelper.isFilteredCountry(
              state.profile.data.customProfile.affiliate.wire_bank_country_code,
              state.application.lists.countries,
              ExcludedCountriesHelper.getBillingWireExcludedCountries()
            ),
            wireBankState: state.profile.data.customProfile.affiliate.wire_bank_state,
            wireBankProvince: state.profile.data.customProfile.affiliate.wire_bank_state,
            wireBankRegion: state.profile.data.customProfile.affiliate.wire_bank_state,
            wireBankZipcode: state.profile.data.customProfile.affiliate.wire_bank_zipcode,
            wireBicSwift: state.profile.data.customProfile.affiliate.wire_bank_swift_bic,
            wireABArouting: state.profile.data.customProfile.affiliate.wire_aba_routing_branch_number,
            wireBankInstitutionNumber: state.profile.data.customProfile.affiliate.wire_bank_institution_number,
            wireAccountNumberOrIban: state.profile.data.customProfile.affiliate.wire_bank_account_number,
            wireIntermediaryBankStatus:
              state.profile.data.customProfile.affiliate.wire_intermediary_bank_name !== null &&
              typeof state.profile.data.customProfile.affiliate.wire_intermediary_bank_name !== 'undefined' &&
              state.profile.data.customProfile.affiliate.wire_intermediary_bank_name.length > 0,
            wireIntermediaryBankName: state.profile.data.customProfile.affiliate.wire_intermediary_bank_name,
            wireIntermediaryBankAddress: state.profile.data.customProfile.affiliate.wire_intermediary_bank_address,
            wireIntermediaryBankCity: state.profile.data.customProfile.affiliate.wire_intermediary_bank_city,
            wireIntermediaryBankCountry: ExcludedCountriesHelper.isFilteredCountry(
              state.profile.data.customProfile.affiliate.wire_intermediary_bank_country_code,
              state.application.lists.countries,
              ExcludedCountriesHelper.getBillingWireExcludedCountries()
            ),
            wireIntermediaryBankState: state.profile.data.customProfile.affiliate.wire_intermediary_bank_state,
            wireIntermediaryBankZipcode: state.profile.data.customProfile.affiliate.wire_intermediary_bank_zipcode,
            wireIntermediaryBankInstitutionNumber:
              state.profile.data.customProfile.affiliate.wire_intermediary_bank_institution_number,
            wireIntermediaryBicSwift: state.profile.data.customProfile.affiliate.wire_intermediary_bank_swift_bic,
            wireIntermediaryABArouting: state.profile.data.customProfile.affiliate.wire_intermediary_aba_routing_branch_number,
            wireIntermediaryAccountNumberOrIban: state.profile.data.customProfile.affiliate.wire_intermediary_bank_account_number,
            otherInformation: state.profile.data.customProfile.affiliate.other_information,
            masspayEmail: state.profile.data.customProfile.affiliate.masspay_email,
            masspayFirstname: state.profile.data.customProfile.affiliate.masspay_firstname,
            masspayLastname: state.profile.data.customProfile.affiliate.masspay_lastname,
          },
        })
      )(BillingInfos)
    )
  )
);

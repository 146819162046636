import { setActionMessage } from '../components/Application/actions/application-actions';

export const BILLING_INFOS_SUCCESS = 'Your beneficiary info has succesfully been saved';
export const COMPANY_INFOS_SUCCESS = 'Your company info has succesfully been saved';

export default class ActionMessageHelper {
  static showSuccess(dispatch, text) {
    dispatch(
      setActionMessage('success', {
        text,
      })
    );
  }
}

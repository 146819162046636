export default {
  confirmEmailTitle: {
    id: 'confirmEmail.pleaseCheckEmailTitle',
    description: 'Please check your email !',
    defaultMessage: 'Please check your email !',
  },
  confirmEmailThankYouForRegistering: {
    id: 'confirmEmail.thankYouForRegistering',
    description: 'Thank you for registering as an affiliate with CrakRevenue.com',
    defaultMessage: 'Thank you for registering as an affiliate with {site}',
  },
  confirmEmailOwnerOf: {
    id: 'confirmEmail.confirmOwnerOf',
    description: 'To confirm that you are the owner of',
    defaultMessage: 'To confirm that you are the owner of',
  },
  confirmEmailSentConfirmation: {
    id: 'confirmEmail.sentConfirmation',
    description: 'we sent you a confirmation email. On that email is a link you must click to complete the registration process.',
    defaultMessage:
      'we sent you a confirmation email. On that email is a link you must click to complete the registration process.',
  },
};

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import apiqlFactory from '../utils/apiqlFactory';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const {
  ACTIONS: { ACH_CONFIGURATIONS },
} = consts;

const requestAchConfigurations = (req) => ({
  type: ACH_CONFIGURATIONS.REQUEST,
  request: req,
});
const receiveAchConfigurations = (payload) => ({
  type: ACH_CONFIGURATIONS.RECEIVE,
  payload,
});
const failedToReceiveAchConfigurations = () => ({
  type: ACH_CONFIGURATIONS.FAILED_TO_RECEIVE,
});
export const fetchAchConfigurations = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    const achConfigurationsRequest = request
      .post(`${API_URL}/apiql`)
      .query({
        query: apiqlFactory({
          AchConfigurations: { sorts: { country_name: 'asc' } },
        }),
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body.data;
          dispatch(receiveAchConfigurations(data));
          fulfill();
        } else {
          dispatch(failedToReceiveAchConfigurations());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestAchConfigurations(achConfigurationsRequest));
  });

export default {
  referralsOnlyShowActive: {
    id: 'referrals.onlyShowActive',
    description: 'Only show active',
    defaultMessage: 'Only show active',
  },
  referralsReferredAffiliate: {
    id: 'referrals.referredAffiliate',
    description: 'Referred affiliate',
    defaultMessage: 'Referred affiliate',
  },
  referralsNoCommissions: {
    id: 'referrals.noCommissions',
    description: 'No commissions for this period!',
    defaultMessage: 'No commissions for this period!',
  },
  referralsNoReferrals: {
    id: 'referrals.noReferrals',
    description: 'No referrals yet!',
    defaultMessage: 'No referrals yet!',
  },
  referralsUseOurPromo: {
    id: 'referrals.useOurPromo',
    description:
      'Feature our referral program banners on your website and recruit a bunch of motivated individuals. <br/> <span class="gold">Nothing better than watching the cash flow! </span><br />',
    defaultMessage:
      'Feature our referral program banners on your website and recruit a bunch of motivated individuals. <br/> <span class="gold">Nothing better than watching the cash flow! </span><br />',
  },
  referralsNeedALittle: {
    id: 'referrals.needALittle',
    description: 'Need some help with <br/> your Whale hunt?',
    defaultMessage: 'Need some help <br/> with your Whale hunt?',
  },
  referralsFRtitle: {
    id: 'referrals.referralsFRtitle',
    description: 'FansRevenue Lifetime Referral Commission',
    defaultMessage: 'FansRevenue Lifetime Referral Commission',
  },
  referralsFRDescription: {
    id: 'referrals.referralsFRDescription',
    description:
      'Get a content creator to sign up with your referral link and earn <strong>a {rate}% lifetime referral commission</strong>. You’ll be  <strong>rewarded for each new creator</strong>. You <strong>don’t need to create a FansRevenue account, your tracking link is already functional</strong>.',
    defaultMessage:
      'Get a content creator to sign up with your referral link and earn <strong>a {rate}% lifetime referral commission</strong>. You’ll be  <strong>rewarded for each new creator</strong>. You <strong>don’t need to create a FansRevenue account, your tracking link is already functional</strong>.',
  },
  referralsModalFRtitle: {
    id: 'referrals.referralsModalFRtitle',
    description: 'What is FansRevenue?',
    defaultMessage: 'What is FansRevenue?',
  },
  referralsModalFRDescription: {
    id: 'referrals.referralsModalFRDescription',
    description:
      '<p>Powered by CrakRevenue, <a href="https://fansrevenue.com/" target="_blank" rel="noreferrer">FansRevenue</a> is the <span class="bold">#1 monetization platform</span> for Cam Models, Adult Content Creators and Influencers.</p><p>If you know content creators, get on the train now! Help us <span class="bold">leverage the content creator economy</span>. Make them <span class="bold">unlock new income streams</span> like you do with CrakRevenue. Crown your efforts with a <span class="bold">{rate}% lifetime referral bonus</span> for each content creator recruited. You don\'t need to create a new account, your aff id already lets you refer on the new platform.</p>',
    defaultMessage:
      '<p>Powered by CrakRevenue, <a href="https://fansrevenue.com/" target="_blank" rel="noreferrer">FansRevenue</a> is the <span class="bold">#1 monetization platform</span> for Cam Models, Adult Content Creators and Influencers.</p><p>If you know content creators, get on the train now! Help us <span class="bold">leverage the content creator economy</span>. Make them <span class="bold">unlock new income streams</span> like you do with CrakRevenue. Crown your efforts with a <span class="bold">{rate}% lifetime referral bonus</span> for each content creator recruited. You don\'t need to create a new account, your aff id already lets you refer on the new platform.</p>',
  },
  referralsCRtitle: {
    id: 'referrals.referralsCRtitle',
    description: 'CrakRevenue Lifetime Referral Commission',
    defaultMessage: 'CrakRevenue Lifetime Referral Commission',
  },
  referralsCRDescription: {
    id: 'referrals.referralsCRDescription',
    description:
      'Get some Webmasters, Media Buyers, or all other online marketing professionals to sign up with your referral link and earn a<strong> {rate}% lifetime referral commission. You’ll be rewarded for each new affiliate</strong>.',
    defaultMessage:
      'Get some Webmasters, Media Buyers, or all other online marketing professionals to sign up with your referral link and earn a<strong> {rate}% lifetime referral commission</strong>. You’ll be <strong>rewarded for each new affiliate</strong>.',
  },
  referralsHelpSectiontitle: {
    id: 'referrals.referralsHelpSectiontitle',
    description: 'Need Help with your Referral Link?',
    defaultMessage: 'Need Help with your Referral Link?',
  },
  referralsHelpSectionDescription: {
    id: 'referrals.referralsHelpSectionDescription',
    description: 'If you need any help, we will be pleased to help you!',
    defaultMessage: 'If you need any help, we will be pleased to help you!',
  },
  referralsFRModalCopyLink: {
    id: 'referrals.referralsFRModalCopyLink',
    description: 'Copy your referral link',
    defaultMessage: 'Copy your referral link',
  },
  referralsYourTrackingLink: {
    id: 'referrals.yourTrackingLink',
    description: 'Your referral Link',
    defaultMessage: 'Your referral Link',
  },
  referralsClick: {
    id: 'referrals.click',
    description:
      "Please note, affiliates you refer will only appear in the stats below once they've sent traffic (of at least 1 hit/click).",
    defaultMessage:
      "Please note, affiliates you refer will only appear in the stats below once they've sent traffic (of at least 1 hit/click).",
  },
  referralsLearnMore: {
    id: 'referrals.learnMore',
    description: 'Click here to learn more referrals',
    defaultMessage: 'Click here to learn more referrals',
  },
  referralsCommissions: {
    id: 'referrals.commissions',
    description: 'Referrals Commissions',
    defaultMessage: 'Referrals Commissions',
  },
  referralsReferredAffiliates: {
    id: 'referrals.referedAffiliates',
    description: 'Referred Affiliates',
    defaultMessage: 'Referred Affiliates',
  },
  referralsNewMarketCheckmark: {
    id: 'referrals.referralsNewMarketCheckmark',
    description: 'Help us conquer new markets',
    defaultMessage: 'Help us conquer new markets',
  },
  referralsCCffiliates: {
    id: 'referrals.referralsCCffiliates',
    description: 'Refer affiliates & content creators',
    defaultMessage: 'Refer affiliates & content creators',
  },
  referralsLifetimeCommission: {
    id: 'referrals.referralsLifetimeCommission',
    description: 'Earn a {rate}% lifetime referral commission',
    defaultMessage: 'Earn a {rate}% lifetime referral commission',
  },
  referralsFRPoweredBy: {
    id: 'referrals.referralsFRPoweredBy',
    description: 'Powered by CrakRevenue',
    defaultMessage: 'Powered by CrakRevenue',
  },
  referralsSameAffId: {
    id: 'referrals.referralsSameAffId',
    description: 'Same Affiliate ID Tracking',
    defaultMessage: 'Same Affiliate ID Tracking',
  },
};

import React from 'react';
import './styles/OfferListingBottomDetails.scss';

const OfferListingBottomDetails = ({ verticals, id, className }) => {
  const vertical = verticals?.map((vertical) => vertical.name)?.join(', ');

  return (
    <div className={`offerListingBottomDetails ${className}`}>
      {vertical && <div className="listingVertical">{vertical}</div>}
      {id && <div className="idBox">ID {id}</div>}
    </div>
  );
};

export default OfferListingBottomDetails;

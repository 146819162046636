import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const {
  ACTIONS: { CONNECTION_SETTINGS },
} = consts;

const requestUpdateConnectionSettings = () => ({
  type: CONNECTION_SETTINGS.REQUEST_UPDATE_CONNECTION_SETTINGS,
});
const receiveUpdateConnectionSettings = () => ({
  type: CONNECTION_SETTINGS.RECEIVE_UPDATE_CONNECTION_SETTINGS,
});
const failedToUpdateConnectionSettings = () => ({
  type: CONNECTION_SETTINGS.FAILED_TO_UPDATE_CONNECTION_SETTINGS,
});

export const updateConnectionSettings = (body) => (dispatch) => {
  dispatch(requestUpdateConnectionSettings());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/user/connection-settings`)
      .withCredentials()
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send(body)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUpdateConnectionSettings());
          fulfill();
        } else {
          dispatch(failedToUpdateConnectionSettings());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import React, { useCallback } from 'react';
import { useAdToolsContext } from '../CreativesAdTools';

const Tab = React.memo(({ text, parentText, animation, direction, verticalPosition }) => {
  const { postitialState, setPostitialState, gtmEvent } = useAdToolsContext();

  let selected = false;

  if (postitialState.verticalPosition === verticalPosition) {
    selected = true;
  } else if (direction && animation && postitialState.direction === direction && postitialState.animation === animation) {
    selected = true;
  } else if (!direction && animation && animation === postitialState.animation) {
    selected = true;
  }

  const onClick = useCallback(() => {
    setPostitialState((state) => ({
      ...state,
      animation: animation || state.animation,
      direction: direction || state.direction,
      verticalPosition: verticalPosition || state.verticalPosition,
    }));
    gtmEvent(`${parentText} - ${text}`);
  }, [animation, direction]);

  return (
    <div className={`tab ${selected ? 'selected' : ''}`} onClick={onClick}>
      {text}
    </div>
  );
});

export default Tab;

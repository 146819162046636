const PreRollAds = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M1.79541 6.68098C1.79541 4.65076 3.44123 3.00476 5.47088 3.00476H13.1192C15.1495 3.00476 16.7954 4.65067 16.7954 6.68098V10.8783C16.7954 12.9086 15.1495 14.5544 13.1192 14.5544H5.47088C3.44123 14.5544 1.79541 12.9084 1.79541 10.8783V6.68098ZM5.47088 4.15861C4.07866 4.15861 2.94926 5.28783 2.94926 6.68098V10.8783C2.94926 12.2714 4.07866 13.4006 5.47088 13.4006H13.1192C14.5123 13.4006 15.6416 12.2713 15.6416 10.8783V6.68098C15.6416 5.28792 14.5123 4.15861 13.1192 4.15861H5.47088Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.25977 16.4184C5.25977 16.0997 5.51807 15.8414 5.83669 15.8414H12.7523C13.0709 15.8414 13.3292 16.0997 13.3292 16.4184C13.3292 16.737 13.0709 16.9953 12.7523 16.9953H5.83669C5.51807 16.9953 5.25977 16.737 5.25977 16.4184Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.51846 7.64599C8.43977 8.35252 8.43715 9.11535 8.519 9.91643C8.53661 10.0466 8.58823 10.085 8.608 10.0961C8.63084 10.109 8.71092 10.1403 8.87231 10.077C9.48692 9.82897 10.0485 9.46282 10.4976 9.05674C10.6252 8.93897 10.6501 8.83858 10.6498 8.77797C10.6495 8.71612 10.6225 8.61743 10.499 8.50551L10.4968 8.50358C10.0257 8.07205 9.48138 7.73327 8.86723 7.48187L8.86223 7.47984C8.73646 7.42696 8.65354 7.44693 8.61654 7.46637C8.58823 7.48122 8.53615 7.52059 8.51846 7.64599ZM9.30661 6.41495C8.90769 6.24814 8.46107 6.24471 8.08 6.44486C7.68968 6.64987 7.43546 7.03369 7.37393 7.49959L7.37255 7.51086C7.28306 8.30919 7.28127 9.15966 7.37199 10.0419L7.37344 10.0546C7.42844 10.4935 7.65524 10.8838 8.04192 11.1016C8.42577 11.3177 8.87923 11.3147 9.29738 11.1497L9.30146 11.1481C10.0518 10.8457 10.7296 10.403 11.2737 9.91066L11.2768 9.90782C11.6068 9.60474 11.8059 9.20643 11.8036 8.77182C11.8013 8.33869 11.5993 7.94605 11.2751 7.65167C10.6978 7.12318 10.0368 6.71413 9.30661 6.41495Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default PreRollAds;

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Info = ({ className, stopPropagation, title, url, onClick }) => (
  <a
    className={`${className} info`}
    href={url}
    onClick={(e) => {
      if (onClick) {
        onClick();
      }
      if (stopPropagation) {
        e.stopPropagation();
      }
    }}
    rel="noopener noreferrer"
    target="_blank"
    title={title}
  >
    <i className="material-icons">&#xE88F;</i>
  </a>
);
Info.propTypes = {
  className: PropTypes.string,
  stopPropagation: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default injectIntl(Info);

import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import Tooltip from '../../components/reusables/ReactTooltip';

const LiveCamWidgetTab = ({ icon, isDisabled, isSelected, pathname, title, warning, gtmEvent }) => {
  const handleClickPage = (e) => {
    if (isSelected || isDisabled) {
      e.preventDefault();
    }
    if (gtmEvent && !isDisabled && !isSelected) {
      gtmEvent();
    }
  };

  return (
    <Tooltip
      className={isSelected ? 'selected' : isDisabled ? 'disabled' : undefined}
      ignoreClasses
      li
      tooltip={isSelected ? '' : isDisabled ? warning : ''}
    >
      <Link
        onClick={(e) => {
          handleClickPage(e);
        }}
        onlyActiveOnIndex={false}
        to={pathname}
      >
        <i className="material-icons">{icon}</i>
        <FormattedMessage {...title} />
      </Link>
    </Tooltip>
  );
};

LiveCamWidgetTab.propTypes = {
  icon: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  warning: PropTypes.string.isRequired,
};

export default injectIntl(LiveCamWidgetTab);

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';

import consts from '../../../utils/consts';
import messages from '../../../i18n/base-en.js';

import Tooltip from '../../../components/reusables/ReactTooltip';

import RankAffiliate from '../../../components/reusables/svg/RankAffiliate.react';
import RankElite from '../../../components/reusables/svg/RankElite.react';
import RankVIP from '../../../components/reusables/svg/RankVIP.react';
import RankRisingStar from '../../../components/reusables/svg/RankRisingStar.react';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';
import { findRankTierByRankValue, isRisingStarRankFromRankStatusName } from '../../../utils/rankingHelper';
import RankingIcon from '../../../components/reusables/svg/ranking/UpRankingIcon.react';

const getNumberOfDecimals = (value) => (value < 1000 ? 2 : 0);

const getHomeActiveAffiliateText = (skin) =>
  skin.getPropertyValue('HomeRankingRank', 'activeAffiliate', 'text') || 'Active Affiliate';

const getHomeVIPAffiliateText = (skin) => skin.getPropertyValue('HomeRankingRank', 'VIPAffiliate', 'text') || 'VIP Affiliate';

const getHomeRisingStarAffiliateText = (skin) =>
  skin.getPropertyValue('HomeRankingRank', 'RisingStarAffiliate', 'text') || 'Rising Star Affiliate';

const HomeRankingRank = ({
  affRank,
  affStatus,
  intl,
  isAffRank,
  isRisingStarRank,
  isVIPRank,
  isEliteRank,
  skinConfigurations,
}) => {
  const skin = new SkinConfigurationsUtils(skinConfigurations);
  const homeActiveAffiliateText = getHomeActiveAffiliateText(skin);
  const homeVIPAffiliateText = getHomeVIPAffiliateText(skin);
  const homeRisingStarAffiliateText = getHomeRisingStarAffiliateText(skin);
  const currentAffAmountDecimals = getNumberOfDecimals(affRank.current_aff_amount);
  const nextBracketEntryCashDifferenceDecimals = getNumberOfDecimals(affRank.next_bracket_entry_cash_difference);
  const aboveRankCashDifferenceDecimals = getNumberOfDecimals(affRank.above_rank_cash_difference);

  const nextRankTier = findRankTierByRankValue(affRank.next_bracket_entry_aff_rank);

  return (
    <div className="ranking-inner ranked">
      <div className="payout-title primary-color">
        <FormattedMessage {...messages.homeTotalPayout} />
      </div>
      <div className="payout">
        <FormattedNumber
          currency="USD"
          maximumFractionDigits={currentAffAmountDecimals}
          minimumFractionDigits={currentAffAmountDecimals}
          style="currency"
          value={affRank.current_aff_amount}
        />
      </div>
      <div className="rank-number">
        {affRank.hidden_rank ? `TOP ${consts.DESCENDING_RANKS.HIDDEN_RANK.rank}` : affRank.current_aff_rank || 'N/A'}
        <span className="rank-indicator">
          {affRank.current_aff_rank > affRank.current_aff_yesterday_rank && (
            <span className="inverse-arrow">
              <RankingIcon />
            </span>
          )}
          {affRank.current_aff_rank < affRank.current_aff_yesterday_rank && <RankingIcon />}
          {affRank.current_aff_rank === affRank.current_aff_yesterday_rank && <i className="material-icons equal">remove</i>}
        </span>
      </div>
      <div className="ranks clearfix">
        <Tooltip button className={`rank affiliate ${isAffRank ? 'active' : ''}`} tooltip={homeActiveAffiliateText}>
          <RankAffiliate />
        </Tooltip>
        <Tooltip button className={`rank rising-star ${isRisingStarRank ? 'active' : ''}`} tooltip={homeRisingStarAffiliateText}>
          <RankRisingStar />
        </Tooltip>
        <Tooltip button className={`rank vip ${isVIPRank ? 'active' : ''}`} tooltip={homeVIPAffiliateText}>
          <RankVIP />
        </Tooltip>
        <Tooltip
          button
          className={`rank elite ${isEliteRank ? 'active' : ''}`}
          tooltip={intl.formatMessage(messages.homeEliteClub)}
        >
          <RankElite />
        </Tooltip>
      </div>
      {affRank.next_bracket_entry_cash_difference !== null && nextRankTier ? (
        <div className="until top">
          <div className={isRisingStarRankFromRankStatusName(nextRankTier.name) ? 'position rising-star' : 'position'}>
            <span>Until {nextRankTier.name} </span>
            {affRank.next_bracket_entry_aff_rank !== consts.DESCENDING_RANKS.HIDDEN_RANK.rank && (
              <span className="sub-position">({affRank.next_bracket_entry_aff_rank})</span>
            )}
          </div>
          <div className="amount">
            <FormattedNumber
              currency="USD"
              maximumFractionDigits={nextBracketEntryCashDifferenceDecimals}
              minimumFractionDigits={nextBracketEntryCashDifferenceDecimals}
              style="currency"
              value={affRank.next_bracket_entry_cash_difference}
            />
          </div>
        </div>
      ) : null}
      {affRank.above_rank_cash_difference !== null && affRank.current_aff_rank !== null && (
        <div className="until next">
          <div className={isRisingStarRankFromRankStatusName(nextRankTier.name) ? 'position rising-star' : 'position'}>
            Until Next <span className="sub-position">({affRank.current_aff_rank - 1})</span>
          </div>
          <div className="amount">
            <FormattedNumber
              currency="USD"
              maximumFractionDigits={aboveRankCashDifferenceDecimals}
              minimumFractionDigits={aboveRankCashDifferenceDecimals}
              style="currency"
              value={affRank.above_rank_cash_difference}
            />
          </div>
        </div>
      )}
    </div>
  );
};

HomeRankingRank.propTypes = {
  affRank: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isAffRank: PropTypes.bool,
  isEliteRank: PropTypes.bool,
  isRisingStarRank: PropTypes.bool,
  isVIPRank: PropTypes.bool,
  skinConfigurations: PropTypes.object.isRequired,
};

export default injectIntl(HomeRankingRank);

// Required for Meteor package, the use of window prevents export by Meteor
import jQuery from 'jquery';
import './velocity.min';

let Materialize = {};
(function (window) {
  if (window.Package) {
    Materialize = {};
  } else {
    window.Materialize = {};
  }
})(window);

// Unique ID
Materialize.guid = (function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return function () {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };
})();

Materialize.elementOrParentIsFixed = function (element) {
  var jQueryelement = jQuery(element);
  var jQuerycheckElements = jQueryelement.add(jQueryelement.parents());
  var isFixed = false;
  jQuerycheckElements.each(function () {
    if (jQuery(this).css('position') === 'fixed') {
      isFixed = true;
      return false;
    }
  });
  return isFixed;
};

// Velocity has conflicts when loaded with jQuery, this will check for it
var Vel;
if (jQuery) {
  Vel = jQuery.Velocity;
} else if (jQuery) {
  Vel = jQuery.Velocity;
}

export default Materialize;

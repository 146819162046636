import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import {
  InfobarSettingsBackground,
  InfobarSettingsDisplay,
  InfobarSettingsFont,
  InfobarSettingsPosition,
} from './infobar-settings';

const LiveCamWidgetInfobarSettings = ({
  fields: {
    backgroundType,
    fontColor,
    fontFamily,
    fontSize,
    infobarBackground,
    infobarDisplay,
    infobarIsCustomizable,
    infobarPositionIO,
    infobarPositionTB,
    infoFields,
  },
}) => {
  const disabled = infobarDisplay.value !== 'custom';

  return (
    <div className="settings-groups same-width" id="infobar-settings">
      <InfobarSettingsDisplay disabled={disabled} fields={{ infobarDisplay, infoFields, infobarIsCustomizable }} />

      <InfobarSettingsFont disabled={disabled} fields={{ fontColor, fontFamily, fontSize }} />

      <InfobarSettingsPosition disabled={disabled} fields={{ infobarPositionIO, infobarPositionTB }} />

      <InfobarSettingsBackground disabled={disabled} fields={{ backgroundType, infobarBackground }} />
    </div>
  );
};

LiveCamWidgetInfobarSettings.propTypes = {
  fields: PropTypes.shape({
    backgroundType: PropTypes.object,
    fontColor: PropTypes.object,
    fontFamily: PropTypes.object,
    fontSize: PropTypes.object,
    infobarBackground: PropTypes.object,
    infobarDisplay: PropTypes.object,
    infobarIsCustomizable: PropTypes.object,
    infobarPositionIO: PropTypes.object,
    infobarPositionTB: PropTypes.object,
    infoFields: PropTypes.object,
  }),
};

export default reduxForm({
  form: 'infobarSettingsLC',
  fields: [
    'backgroundType',
    'fontColor',
    'fontFamily',
    'fontSize',
    'infobarBackground',
    'infobarDisplay',
    'infobarIsCustomizable',
    'infobarPositionIO',
    'infobarPositionTB',
    'infoFields',
  ],
  destroyOnUnmount: false,
})(LiveCamWidgetInfobarSettings);

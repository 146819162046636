import { useEffect } from 'react';
import PropTypes from 'prop-types';

import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';

function EPayServiceSection({ fields, willRender, accountType }) {
  useEffect(() => {}, [willRender]);
  const eWalletMask = '9999-9999-9999-9999';

  if (willRender) {
    return (
      <div className="ePay_section">
        {accountType === 'Legacy' && (
          <>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.epayserviceBeneficiaryFirstName}
                id="epayserviceBeneficiaryFirstName"
                label={messages.paymentInfosUserFirstName}
                tabIndex={30}
                type="text"
              />
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.epayserviceBeneficiaryLastName}
                id="epayserviceBeneficiaryLastName"
                label={messages.paymentInfosUserLastName}
                tabIndex={40}
                type="text"
              />
            </div>
          </>
        )}
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.epayserviceEwalletNumber}
            id="epayserviceEwalletNumber"
            label={messages.paymentInfosEPayEWalletNumber}
            mask={eWalletMask}
            tabIndex={50}
            type="text"
          />
        </div>
      </div>
    );
  } else {
    return false;
  }
}

EPayServiceSection.propTypes = {
  accountType: PropTypes.string,
  fields: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default EPayServiceSection;

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import Tooltip from './ReactTooltip';
import CheckCircle from './svg/CheckCircle.react';
import ErrorCircle from './svg/ErrorCircle.react';

const SimplifiedTextarea = (props) => {
  const {
    dontBlurIfPristine,
    field,
    id,
    label,
    labelClasses,
    optional,
    tooltip,
    autoFocus,
    className,
    disabled,
    maxLength,
    tabIndex,
    key,
    placeholder,
    followCharNumber,
  } = props;

  const handleBlur = () => {
    if (!dontBlurIfPristine || !field.pristine) {
      field.onBlur();
    }
  };

  const renderLabel = (fieldValueExists) => {
    const classNames = [labelClasses, fieldValueExists ? 'active' : undefined];

    return (
      <label className={classNames.join(' ')} htmlFor={id}>
        <FormattedMessage {...label} className="checkbox-label" />
        {optional ? (
          <span className="simplified-optional-label">
            <FormattedMessage {...messages.genericTextOptionalInput} />
          </span>
        ) : null}
        {!!tooltip && (
          <Tooltip id="custom-logo-url-1" tooltip={tooltip}>
            <i className="material-icons icon-info">info</i>
          </Tooltip>
        )}
      </label>
    );
  };

  const fieldValueExists = !!field.value && field.value.toString().length >= 1;

  const fieldClasses = [
    'simplified-textarea',
    className,
    !!field && field.error && field.touched ? 'error' : undefined,
    !!field && field.valid && field.touched ? 'valid' : undefined,
  ];

  return (
    <div className={fieldClasses.join(' ')}>
      {label ? renderLabel(fieldValueExists) : null}
      <textarea
        autoFocus={autoFocus || false}
        className={fieldValueExists ? 'hasValue' : undefined}
        disabled={disabled || false}
        id={id}
        key={key}
        maxLength={maxLength}
        name={field.name}
        onBlur={handleBlur}
        onChange={field.onChange}
        onDragStart={field.onDragStart}
        onDrop={field.onDrop}
        onFocus={field.onFocus}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        value={field.value}
      />
      {followCharNumber ? <span className="followCharNumber">{`${field.value.length}/${maxLength}`}</span> : null}
      {!!field && field.touched && field.valid ? (
        <i className="icon-validation" key="valid">
          <CheckCircle />
        </i>
      ) : null}
      {!!field && field.touched && field.error
        ? [
            <i className="icon-validation" key="error">
              <ErrorCircle />
            </i>,
            <div className="field-error" key="error div">
              {field.error}
            </div>,
          ]
        : null}
    </div>
  );
};

SimplifiedTextarea.defaultProps = {
  dontBlurIfPristine: true,
};

SimplifiedTextarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dontBlurIfPristine: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  label: PropTypes.object,
  labelClasses: PropTypes.string,
  maxLength: PropTypes.number,
  optional: PropTypes.bool,
  tabIndex: PropTypes.number,
  tooltip: PropTypes.string,
  type: PropTypes.string,
};

export default injectIntl(SimplifiedTextarea);

import { basicLengthValidation } from '../../utils/helpers';

export default (values) => {
  const errors = {};

  if (!values.company_name) {
    errors.company_name = 'Please enter a company name.';
  }

  if (!values.country) {
    errors.country = 'Please select a country.';
  }

  if (values.country === 'CA' && !values.province) {
    errors.province = 'Please select a province.';
  }
  if (values.country === 'US' && !values.state) {
    errors.state = 'Please select a state.';
  }
  if (values.country === 'CA' && !values.gstTaxId) {
    errors.gstTaxId = 'Please enter the GST / HST Number.';
  }

  if (values.gstTaxId) {
    const gstTaxIdRegexp = new RegExp('^[0-9]{9}$');
    if (!gstTaxIdRegexp.test(values.gstTaxId)) {
      errors.gstTaxId = 'GST / HST number must be 9 digits';
    }
  }

  if (values.pstTaxId) {
    const pstTaxIdRegexp = new RegExp('^([0-9a-zA-Z]){2,20}$');
    if (!pstTaxIdRegexp.test(values.pstTaxId)) {
      errors.pstTaxId = 'PST number must be between 2 to 20 alphanumeric characters.';
    }
  }

  if (!values.registrationNumber) {
    errors.registrationNumber = 'Please enter the registration number.';
  } else if (values.registrationNumber) {
    errors.registrationNumber = basicLengthValidation(values.registrationNumber, 2, 30);
  }

  return errors;
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedNumber } from 'react-intl';

import StatisticsDriller from './StatisticsDriller.react';
import { setDrillDownValues } from '../../../actions/statistics-actions';
import { buildDrillDownObject, transformTableValue } from '../../../utils/helpers';

// TODO: remove jQuery from project
import $ from 'jquery';

import consts from '../../../utils/consts';
const { COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT, DRILL_DOWN_VALUES } = consts;

const initDropDown = () => {
  $('.driller-button').dropdown({
    constrain_width: false,
  });
};

class StatisticsTableRow extends Component {
  constructor(props) {
    super(props);

    this.handleDrillDownClick = this.handleDrillDownClick.bind(this);

    this.drillDownIsSet = false;
  }

  componentDidMount() {
    initDropDown();
  }

  shouldComponentUpdate(nextProps) {
    const { classes, isSelected, rowIndex, statisticsFieldsAndColumns, statisticsRow } = this.props;

    return (
      !_.isEqual(classes !== nextProps.classes) ||
      isSelected !== nextProps.isSelected ||
      rowIndex !== nextProps.rowIndex ||
      !_.isEqual(statisticsFieldsAndColumns, nextProps.statisticsFieldsAndColumns) ||
      !_.isEqual(statisticsRow, nextProps.statisticsRow)
    );
  }

  componentDidUpdate() {
    this.drillDownIsSet = false;
    initDropDown();
  }

  handleDrillDownClick(e) {
    e.preventDefault();
    const { dispatch, statisticsRow, statisticsTableLevel } = this.props;

    const el = e.target;

    if (el.tagName === 'A') {
      const dataFilter = el.getAttribute('data-filter');

      dispatch(
        setDrillDownValues(
          dataFilter
            ? {
                ...buildDrillDownObject(statisticsRow),
                ...JSON.parse(dataFilter),
              }
            : buildDrillDownObject(statisticsRow),
          el.getAttribute('data-group'),
          parseInt(statisticsTableLevel, 10) + 1,
          el.getAttribute('data-field'),
          el.getAttribute('data-value')
        )
      );
    }
  }

  render() {
    const {
      classes,
      handleSelectRow,
      isSelected,
      rowIndex,
      statisticsFieldsAndColumns,
      statisticsDrillDownFilters,
      statisticsDrillDownGroups,
      statisticsRow,
    } = this.props;

    return (
      <tr className={[...classes, isSelected ? 'selected' : undefined].join(' ')}>
        <td>
          <div className="checkbox-field">
            <input
              checked={isSelected}
              className="filled-in"
              data-rowindex={rowIndex}
              id={`statistics-checkbox-${rowIndex}`}
              onChange={handleSelectRow}
              type="checkbox"
              value={JSON.stringify(statisticsRow)}
            />
            <label htmlFor={`statistics-checkbox-${rowIndex}`} />
          </div>
        </td>
        {statisticsFieldsAndColumns.map((column, colIndex) => {
          const columnsObjects = column.split('.');

          const isCurrency = ['Stat.payout', 'Stat.cpa', 'Stat.erpc', 'Stat.cpm'].indexOf(column) !== -1;
          const isPercent = ['Stat.ctr', 'Stat.ltr'].indexOf(column) !== -1;
          const isString =
            [
              'Stat.source',
              'Stat.affiliate_info1',
              'Stat.affiliate_info2',
              'Stat.affiliate_info3',
              'Stat.affiliate_info4',
              'Stat.affiliate_info5',
            ].indexOf(column) !== -1;
          const digits = ['Stat.cpa', 'Stat.erpc', 'Stat.cpm', 'Stat.ctr', 'Stat.ltr'].indexOf(column) !== -1 ? 3 : 2;
          const noFormat = ['Stat.date', 'Stat.year'].indexOf(column) === -1;

          const originalValue =
            Object.prototype.hasOwnProperty.call(statisticsRow, columnsObjects[0]) &&
            Object.prototype.hasOwnProperty.call(statisticsRow[columnsObjects[0]], columnsObjects[1])
              ? statisticsRow[columnsObjects[0]][columnsObjects[1]]
              : '';
          let columnValue = originalValue;
          if ((isCurrency || isPercent) && (columnValue === null || (Array.isArray(columnValue) && columnValue.length === 0))) {
            columnValue = 0;
          }
          columnValue = transformTableValue(column, columnValue);

          if (isString) {
            columnValue = <span>{columnValue}</span>;
          } else if (isCurrency && columnValue !== null && columnValue.length !== 0) {
            columnValue = (
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={digits}
                minimumFractionDigits={digits}
                style="currency"
                value={columnValue}
              />
            );
          } else if (isPercent && columnValue !== null && columnValue.length !== 0) {
            columnValue = (
              <FormattedNumber
                maximumFractionDigits={digits}
                minimumFractionDigits={digits}
                style="percent"
                value={columnValue / 100}
              />
            );
          } else if (
            !isNaN(columnValue) &&
            !isCurrency &&
            !isPercent &&
            noFormat &&
            columnValue !== null &&
            columnValue.length !== 0
          ) {
            columnValue = <FormattedNumber value={columnValue} />;
          }

          const canDrillDown = DRILL_DOWN_VALUES.indexOf(column) >= 0 && !this.drillDownIsSet;
          if (canDrillDown) {
            this.drillDownIsSet = true;
          }

          return (
            <td
              className={[
                canDrillDown ? 'canDrillDown' : undefined,
                COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(column) >= 0 ? 'text-right' : undefined,
              ].join(' ')}
              data-cy={column}
              key={`statistics-td-${rowIndex}-${colIndex}`}
            >
              <div>
                {columnValue}
                {canDrillDown ? (
                  <StatisticsDriller
                    handleClick={this.handleDrillDownClick}
                    id={`drill-${rowIndex}-${colIndex}`}
                    statisticsDrillDownFilters={statisticsDrillDownFilters || {}}
                    statisticsDrillDownGroups={statisticsDrillDownGroups || []}
                    statisticsField={column}
                    statisticsValue={originalValue || ''}
                  />
                ) : null}
              </div>
            </td>
          );
        })}
      </tr>
    );
  }
}

StatisticsTableRow.propTypes = {
  classes: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  handleSelectRow: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired,
  statisticsDrillDownFilters: PropTypes.object.isRequired,
  statisticsDrillDownGroups: PropTypes.array.isRequired,
  statisticsFieldsAndColumns: PropTypes.any.isRequired,
  statisticsRow: PropTypes.object.isRequired,
  statisticsTableLevel: PropTypes.number.isRequired,
};

export default StatisticsTableRow;

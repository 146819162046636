import React from 'react';
import SearchBar from './SearchBar';
import { useOffersFilterContext } from './OfferFilterProvider';

const SearchMain = React.memo(() => {
  const { search, clearSearchFilter } = useOffersFilterContext();

  const { searchString } = search;
  return (
    <div className="searchMainBox">
      <SearchBar />
      {searchString ? (
        <div className="selectableTab clear" onClick={() => clearSearchFilter(searchString)}>
          Clear
        </div>
      ) : null}
    </div>
  );
});

export default SearchMain;

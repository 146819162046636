import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText, LoadingPage } from '../../components/reusables';

import validate from './validatePasswordEmailResetForm';

const PasswordResetEmailForm = (props) => {
  const { fields, handleSubmit, isDisabled } = props;

  return (
    <div id="password-reset-form">
      <div className={isDisabled ? 'greyed-out row' : 'row'}>
        <form action="#" className="col-md-12" method="post" onSubmit={handleSubmit}>
          <div className="row">
            <InputText
              autoFocus
              className="col-md-12"
              disabled={isDisabled}
              field={fields.email}
              id="email"
              label={messages.loginFormLabelEmail}
              tabIndex={10}
              type="email"
            />
          </div>

          <div className="row">
            <div className="col-md-12 form-buttons text-right">
              <button className="waves-effect waves-light btn" disabled={!fields.email.valid}>
                <FormattedMessage {...messages.passwordResetSendLink} />
              </button>
            </div>
          </div>
        </form>
      </div>
      {isDisabled ? (
        <div className="is-loading">
          <LoadingPage fullScreen={false} />
        </div>
      ) : null}
    </div>
  );
};

PasswordResetEmailForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(
  {
    form: 'passwordResetForm',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['email'],
    validate,
  },
  () => ({
    initialValues: {
      email: '',
    },
  })
)(PasswordResetEmailForm);

import $ from 'jquery'
import Materialize from '../../js/vendors/materialize/global'
import '../../js/vendors/materialize/dropdown'
import '../../js/vendors/jquery.easings.1.3'

function validate_field(object) {
    var hasLength = object.attr('length') !== undefined
    var lenAttr = parseInt(object.attr('length'))
    var lenVal = object.val().length

    if (
        lenVal === 0 &&
        typeof object[0].validity !== 'undefined' &&
        object[0].validity.badInput === false
    ) {
        if (object.hasClass('validate')) {
            object.removeClass('valid')
            object.removeClass('invalid')
        }
    } else {
        if (object.hasClass('validate')) {
            // Check for character counter attributes
            if (object.is(':valid') && (!hasLength || lenVal <= lenAttr)) {
                object.removeClass('invalid')
                object.addClass('valid')
            } else {
                object.removeClass('valid')
                object.addClass('invalid')
            }
        }
    }
}

$(function () {
    // Text based inputs
    const input_selector =
        'input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], textarea'
    const text_area_selector = '.materialize-textarea'
    const radio_checkbox_selector = 'input[type=radio], input[type=checkbox]'

    // Function to update labels of text fields
    Materialize.updateTextFields = function () {
        $(input_selector).each(function (index, element) {
            if (
                $(element).val().length > 0 ||
                element.autofocus ||
                $(this).attr('placeholder') !== undefined ||
                (typeof $(element)[0].validity !== 'undefined' &&
                    $(element)[0].validity.badInput === true)
            ) {
                $(this).siblings('label').addClass('active')
            } else {
                $(this).siblings('label').removeClass('active')
            }
        })
    }
    // Add active if input element has been pre-populated on document ready
    Materialize.updateTextFields()

    $(document)
        .on('reset', function (e) {
            // HTML DOM FORM RESET handling
            const formReset = $(e.target)
            if (formReset.is('form')) {
                formReset
                    .find(input_selector)
                    .removeClass('valid')
                    .removeClass('invalid')
                formReset.find(input_selector).each(function () {
                    if ($(this).attr('value') === '') {
                        $(this).siblings('label').removeClass('active')
                    }
                })

                // Reset select
                formReset.find('select.initialized').each(function () {
                    formReset
                        .siblings('input.select-dropdown')
                        .val(formReset.find('option[selected]').text())
                })
            }
        })

        .on('focus', input_selector, function () {
            // Add active when element has focus
            $(this).siblings('label, .prefix').addClass('active')
        })

        .on('change', input_selector, function () {
            const $inputElement = $(this)
            // Add active if form auto complete
            if (
                $inputElement.val().length !== 0 ||
                $inputElement.attr('placeholder') !== undefined
            ) {
                $inputElement.siblings('label').addClass('active')
            }
            validate_field($inputElement)
        })

        .on('blur', input_selector, function () {
            const $inputElement = $(this)
            let selector = '.prefix'

            if (
                $inputElement.val().length === 0 &&
                typeof $inputElement[0].validity !== 'undefined' &&
                $inputElement[0].validity.badInput !== true &&
                $inputElement.attr('placeholder') === undefined
            ) {
                selector += ', label'
            }

            $inputElement.siblings(selector).removeClass('active')

            validate_field($inputElement)
        })

        // Radio and Checkbox focus class
        .on('keyup.radio', radio_checkbox_selector, function (e) {
            // TAB, check if tabbing to radio or checkbox.
            if (e.which === 9) {
                const $this = $(this)
                $this.addClass('tabbed')
                $this.one('blur', function () {
                    $this.removeClass('tabbed')
                })
            }
        })

        .on('keyup keydown autoresize', text_area_selector, function () {
            textareaAutoResize($(this))
        })

    window.validate_field = function (object) {
        const hasLength = object.attr('length') !== undefined
        const lenAttr = parseInt(object.attr('length'))
        const lenVal = object.val().length

        if (
            lenVal === 0 &&
            typeof object[0].validity !== 'undefined' &&
            object[0].validity.badInput === false
        ) {
            if (object.hasClass('validate')) {
                object.removeClass('valid')
                object.removeClass('invalid')
            }
        } else {
            if (object.hasClass('validate')) {
                // Check for character counter attributes
                if (object.is(':valid') && (!hasLength || lenVal <= lenAttr)) {
                    object.removeClass('invalid')
                    object.addClass('valid')
                } else {
                    object.removeClass('valid')
                    object.addClass('invalid')
                }
            }
        }
    }

    // Textarea Auto Resize
    let hiddenDiv = $('.hiddendiv').first()
    if (!hiddenDiv.length) {
        hiddenDiv = $('<div class="hiddendiv common"></div>')
        $('body').append(hiddenDiv)
    }

    function textareaAutoResize($textarea) {
        // Set font properties of hiddenDiv
        const fontFamily = $textarea.css('font-family')
        const fontSize = $textarea.css('font-size')
        const lineHeight = $textarea.css('line-height')

        if (fontSize) {
            hiddenDiv.css('font-size', fontSize)
        }
        if (fontFamily) {
            hiddenDiv.css('font-family', fontFamily)
        }
        if (lineHeight) {
            hiddenDiv.css('line-height', lineHeight)
        }

        if ($textarea.attr('wrap') === 'off') {
            hiddenDiv.css('overflow-wrap', 'normal').css('white-space', 'pre')
        }

        hiddenDiv.text(`${$textarea.val()}\n`)
        hiddenDiv.html(hiddenDiv.html().replace(/\n/g, '<br>'))

        // When textarea is hidden, width goes crazy.
        // Approximate with half of window size
        hiddenDiv.css(
            'width',
            $textarea.is(':visible') ? $textarea.width() : $(window).width() / 2
        )

        $textarea.css('height', hiddenDiv.height())
    }

    $(text_area_selector).each(function () {
        const $textarea = $(this)
        if ($textarea.val().length) {
            textareaAutoResize($textarea)
        }
    })

    /****************
     *  Range Input  *
     ****************/
    const range_type = 'input[type=range]'
    let range_mousedown = false
    let left

    $(range_type).each(function () {
        $(this).after($('<span class="thumb"><span class="value" /></span>'))
    })

    const range_wrapper = '.range-field'
    $(document)
        .on('change', range_type, function () {
            const $self = $(this)
            $self.siblings('.thumb').find('.value').html($self.val())
        })

        .on('input mousedown touchstart', range_type, function (e) {
            const $self = $(this)
            let thumb = $self.siblings('.thumb')
            let width = $self.outerWidth()

            // If thumb indicator does not exist yet, create it
            if (thumb.length <= 0) {
                thumb = $('<span class="thumb"><span class="value" /></span>')
                $self.after(thumb)
            }

            // Set indicator value
            thumb.find('.value').html($self.val())

            range_mousedown = true
            $self.addClass('active')

            if (!thumb.hasClass('active')) {
                thumb.velocity(
                    {
                        height: '30px',
                        width: '30px',
                        top: '-20px',
                        marginLeft: '-15px',
                    },
                    { duration: 300, easing: 'easeOutExpo' }
                )
            }

            if (e.type !== 'input') {
                if (e.pageX === undefined || e.pageX === null) {
                    //mobile
                    left =
                        e.originalEvent.touches[0].pageX - $self.offset().left
                } else {
                    // desktop
                    left = e.pageX - $self.offset().left
                }
                if (left < 0) {
                    left = 0
                } else if (left > width) {
                    left = width
                }
                thumb.addClass('active').css('left', left)
            }

            thumb.find('.value').html($self.val())
        })

        .on('mouseup touchend', range_wrapper, function () {
            range_mousedown = false
            $(this).removeClass('active')
        })

        .on('mousemove touchmove', range_wrapper, function (e) {
            const $self = $(this)
            const thumb = $self.children('.thumb')
            let left

            if (range_mousedown) {
                if (!thumb.hasClass('active')) {
                    thumb.velocity(
                        {
                            height: '30px',
                            width: '30px',
                            top: '-20px',
                            marginLeft: '-15px',
                        },
                        { duration: 300, easing: 'easeOutExpo' }
                    )
                }
                if (e.pageX === undefined || e.pageX === null) {
                    //mobile
                    left =
                        e.originalEvent.touches[0].pageX - $self.offset().left
                } else {
                    // desktop
                    left = e.pageX - $self.offset().left
                }
                const width = $self.outerWidth()

                if (left < 0) {
                    left = 0
                } else if (left > width) {
                    left = width
                }
                thumb.addClass('active').css('left', left)
                thumb.find('.value').html(thumb.siblings(range_type).val())
            }
        })

        .on('mouseout touchleave', range_wrapper, function () {
            if (!range_mousedown) {
                const thumb = $(this).children('.thumb')

                if (thumb.hasClass('active')) {
                    thumb.velocity(
                        {
                            height: '0',
                            width: '0',
                            top: '10px',
                            marginLeft: '-6px',
                        },
                        { duration: 100 }
                    )
                }
                thumb.removeClass('active')
            }
        })

    /**************************
     * Auto complete plugin  *
     *************************/
    $.fn.autocomplete = function (options) {
        // Defaults
        const defaults = {
            data: {},
        }

        options = $.extend(defaults, options)

        return this.each(function () {
            const $input = $(this)
            const data = options.data
            const $inputDiv = $input.closest('.input-field') // Div to append on

            // Check if data isn't empty
            if (!$.isEmptyObject(data)) {
                // Create autocomplete element
                const $autocomplete = $(
                    '<ul class="autocomplete-content dropdown-content" />'
                )

                // Append autocomplete element
                if ($inputDiv.length) {
                    $inputDiv.append($autocomplete) // Set ul in body
                } else {
                    $input.after($autocomplete)
                }

                const highlight = function (string, $el) {
                    const img = $el.find('img')
                    const matchStart = $el
                        .text()
                        .toLowerCase()
                        .indexOf(`${string.toLowerCase()}`)
                    const matchEnd = matchStart + string.length - 1
                    const beforeMatch = $el.text().slice(0, matchStart)
                    const matchText = $el.text().slice(matchStart, matchEnd + 1)
                    const afterMatch = $el.text().slice(matchEnd + 1)
                    $el.html(
                        `<span>${beforeMatch}<span class='highlight'>${matchText}</span>${afterMatch}</span>`
                    )
                    if (img.length) {
                        $el.prepend(img)
                    }
                }

                // Perform search
                $input.on('keyup', function (e) {
                    // Capture Enter
                    if (e.which === 13) {
                        $autocomplete.find('li').first().click()
                        return
                    }

                    const val = $input.val().toLowerCase()
                    $autocomplete.empty()

                    // Check if the input isn't empty
                    if (val !== '') {
                        for (const key in data) {
                            if (
                                data.hasOwnProperty(key) &&
                                key.toLowerCase().indexOf(val) !== -1 &&
                                key.toLowerCase() !== val
                            ) {
                                const autocompleteOption = $('<li />')
                                if (!!data[key]) {
                                    autocompleteOption.append(
                                        `<img src="${data[key]}" class="right circle" alt="autocomplete icon"><span>${key}</span>`
                                    )
                                } else {
                                    autocompleteOption.append(
                                        `<span>${key}</span>`
                                    )
                                }
                                $autocomplete.append(autocompleteOption)

                                highlight(val, autocompleteOption)
                            }
                        }
                    }
                })

                // Set input value
                $autocomplete.on('click', 'li', function () {
                    $input.val($(this).text().trim())
                    $input.trigger('change')
                    $autocomplete.empty()
                })
            }
        })
    }

    /*******************
     *  Select Plugin  *
     ******************/
    $.fn.material_select = function (callback) {
        $(this).each(function () {
            const $select = $(this)

            if ($select.hasClass('browser-default')) {
                return // Continue to next (return false breaks out of entire loop)
            }

            const multiple = !!$select.attr('multiple')
            const lastID = $select.data('select-id') // Tear down structure if Select needs to be rebuilt
            const selectID = $select.attr('id')

            if (lastID) {
                $select.parent().find('span.caret').remove()
                $select.parent().find('input').remove()

                $select.unwrap()
                $(`ul#select-options-${lastID}`).remove()
            }

            // If destroying the select, remove the selelct-id and reset it to it's uninitialized state.
            if (callback === 'destroy') {
                $select.data('select-id', null).removeClass('initialized')
                $(window).off(`click.${selectID}`)
                return
            }

            const uniqueID = Materialize.guid()
            $select.data('select-id', uniqueID)
            const wrapper = $(
                `<div class="select-wrapper" data-cy="select-wrapper-${selectID}"></div>`
            )
            wrapper.addClass($select.attr('class'))
            let options = $(
                `<ul id="select-options-${uniqueID}" class="dropdown-content select-dropdown ${
                    multiple ? 'multiple-select-dropdown' : ''
                }" />`
            )
            const selectChildren = $select.children('option, optgroup')
            const valuesSelected = []
            let optionsHover = false

            const label =
                $select.find('option:selected').html() ||
                $select.find('option:first').html() ||
                ''

            // Function that renders and appends the option taking into
            // account type and possible image icon.
            const appendOptionWithIcon = function (select, option, type) {
                // Add disabled attr if disabled
                const disabledClass = option.is(':disabled') ? 'disabled' : ''
                const optgroupClass =
                    type === 'optgroup-option' ? 'optgroup-option' : ''
                const icon_url = option.data('icon') // add icons
                const classes = option.attr('class')
                const optionID = `select-${select.attr(
                    'id'
                )}-option-${option.val()}`

                if (!!icon_url) {
                    const classString = !!classes ? `class="${classes}"` : ''

                    // Check for multiple type.
                    if (type === 'multiple') {
                        options.append(
                            $(
                                `<li class="${disabledClass}" data-cy="${optionID}"><img alt="" src="${icon_url}" ${classString}><span><input type="checkbox" ${disabledClass}/><label />${option.html()}</span></li>`
                            )
                        )
                    } else {
                        options.append(
                            $(
                                `<li class="${disabledClass} ${optgroupClass}" data-cy="${optionID}"><img alt="" src="${icon_url}" ${classString}><span>${option.html()}</span></li>`
                            )
                        )
                    }
                    return true
                }

                // Check for multiple type.
                if (type === 'multiple') {
                    options.append(
                        $(
                            `<li class="${disabledClass}" data-cy="${optionID}"><span><input type="checkbox" ${disabledClass} /><label />${option.html()}</span></li>`
                        )
                    )
                } else {
                    options.append(
                        $(
                            `<li class="${disabledClass} ${optgroupClass}" data-cy="${optionID}"><span>${option.html()}</span></li>`
                        )
                    )
                }
            }

            /* Create dropdown structure. */
            if (selectChildren.length) {
                selectChildren.each(function () {
                    if ($(this).is('option')) {
                        // Direct descendant option.
                        appendOptionWithIcon(
                            $select,
                            $(this),
                            multiple ? 'multiple' : ''
                        )
                    } else if ($(this).is('optgroup')) {
                        // Optgroup.
                        const selectOptions = $(this).children('option')
                        options.append(
                            $(
                                `<li class="optgroup"><span>${$(this).attr(
                                    'label'
                                )}</span></li>`
                            )
                        )

                        selectOptions.each(function () {
                            appendOptionWithIcon(
                                $select,
                                $(this),
                                'optgroup-option'
                            )
                        })
                    }
                })
            }

            options.find('li:not(.optgroup)').each(function (i) {
                const $li = $(this)
                $li.click(function (e) {
                    e.stopPropagation()
                    // Check if option element is disabled
                    if (!$li.hasClass('disabled')) {
                        let selected = true
                        const thisSelect = $newSelect
                            .closest('.select-field')
                            .find('select')[0]
                        const maxChoices =
                            thisSelect.getAttribute('data-maxselected')

                        if (multiple) {
                            $('input[type="checkbox"]', this).prop(
                                'checked',
                                function (i, v) {
                                    return !v
                                }
                            )
                            selected = toggleEntryFromArray(
                                valuesSelected,
                                $(this).index(),
                                $select
                            )
                            $newSelect.trigger('focus')

                            if (typeof maxChoices === 'string') {
                                const selectedValues = []

                                $(thisSelect)
                                    .find(':selected')
                                    .each(function () {
                                        selectedValues.push($(this).val())
                                    })

                                if (
                                    selectedValues.length >
                                    parseInt(maxChoices, 10)
                                ) {
                                    const toUnselect = valuesSelected.shift()

                                    $select
                                        .find('option')
                                        .eq(toUnselect)
                                        .prop('selected', false)
                                    $newSelect
                                        .closest('.select-field')
                                        .find('li:not(.optgroup)')
                                        .eq(toUnselect)
                                        .find('input')
                                        .prop('checked', false)
                                }
                            }
                        } else {
                            options.find('li').removeClass('active')
                            $li.toggleClass('active')
                            $newSelect.val($li.text())
                        }

                        activateOption(options, $li)
                        $select.find('option').eq(i).prop('selected', selected)
                        // Trigger onchange() event
                        $select.trigger('change')
                        if (typeof callback !== 'undefined') callback()
                        if (!multiple) $newSelect.trigger('close')
                    }

                    e.stopPropagation()
                })
            })

            // Wrap Elements
            $select.wrap(wrapper)
            // Add Select Display Element
            const dropdownIcon = $('<span class="caret">&#9660;</span>')
            if ($select.is(':disabled')) dropdownIcon.addClass('disabled')

            // escape double quotes
            const sanitizedLabelHtml = label.replace(/"/g, '&quot;')

            const $newSelect = $(
                `<input type="text" class="select-dropdown" data-cy="select-inputtext-${selectID}" readonly="true" ${
                    $select.is(':disabled') ? 'disabled' : ''
                } data-activates="select-options-${uniqueID}" value="${sanitizedLabelHtml}" />`
            )
            $select.after($newSelect)
            $newSelect.after(dropdownIcon)

            /**
             * -------- AJOUT PHOENIX --------
             * CAN EXCLUDE SECTION
             * -------- AJOUT PHOENIX --------
             */
            const hasCanExclude = $select
                .closest('.select-field')
                .hasClass('canExclude')
            const hasCanSearch = $select
                .closest('.select-field')
                .hasClass('canSearch')
            if (hasCanExclude || hasCanSearch) {
                const dataCompare = $select.data('compare') || 'include'

                options = $(`<div id="select-options-${uniqueID}" />`).html(
                    options
                )

                if (hasCanExclude) {
                    options.attr(
                        'class',
                        `${options
                            .children('ul')
                            .attr(
                                'class'
                            )} select-dropdown canExclude ${dataCompare}`
                    )
                }
                if (hasCanSearch) {
                    options.attr(
                        'class',
                        `${options
                            .children('ul')
                            .attr(
                                'class'
                            )} select-dropdown canSearch ${dataCompare}`
                    )
                }

                options.children('ul').removeAttr('class').removeAttr('id')

                //options.find('input[type=checkbox]').attr('class', 'filled-in');

                const inputName = `${$select.attr('name')}-compare`
                const $func = $('<div class="func" />')
                if (hasCanExclude) {
                    $func.prepend(
                        $(
                            '<div class="dropdown-title primary-color"><span class="close-dropdown"><i class="material-icons">close</i></span></div>'
                        )
                    )
                    $func.append(
                        $(
                            '<div class="block-buttons"><button class="btn include" data-btn="include">Include</button><button class="btn exclude" data-btn="exclude">Exclude</button></div>'
                        )
                    )
                }
                $func.append(
                    $(
                        `<div class="input-field"><i class="material-icons prefix">&#xE8B6;</i><input type="text" name="${inputName}-search" autofocus class="search-select" value="" placeholder="Search"></div>`
                    )
                )

                options.prepend($func)

                if (
                    hasCanExclude &&
                    $(`input[name=${inputName}]`).length === 0
                ) {
                    $select.after(
                        `<input type="hidden" name="${inputName}" value="${dataCompare}">`
                    )
                }

                if (hasCanExclude) {
                    $func.find('.close-dropdown').on('click', function () {
                        $newSelect.trigger('close')
                    })
                }

                options.on('keyup', 'input[type=text]', function () {
                    const srcString = $(this).val()
                    const regex = new RegExp(srcString, 'i')

                    options.find('li').each(function (index) {
                        const $li = $(this)

                        if (index === 0 || srcString.length < 2) {
                            $li.removeAttr('style')
                            return
                        }
                        $li.css(
                            'display',
                            regex.test($li.text()) ? 'block' : 'none'
                        )
                    })
                })

                options.on('click', '.btn', function (e) {
                    e.preventDefault()

                    const data = $(this).data('btn')

                    options.removeClass('include exclude').addClass(data)

                    $select.attr('data-compare', data)
                    $(`input[name=${inputName}]`).val(data)
                })

                options.find('li').on('click', 'span', function () {
                    const $li = $(this).parent()

                    if ($li.find('input').is(':checked')) {
                        $li.removeClass('selected')
                    } else {
                        $li.addClass('selected')
                    }
                })
            }
            /**
             * -------- FIN AJOUT PHOENIX --------
             * CAN EXCLUDE SECTION
             * -------- FIN AJOUT PHOENIX --------
             */

            $newSelect.after(options)
            // Check if section element is disabled
            if (!$select.is(':disabled')) {
                $newSelect.dropdown({ hover: false, closeOnClick: false })
            }

            // Copy tabindex
            if ($select.attr('tabindex')) {
                $($newSelect[0]).attr('tabindex', $select.attr('tabindex'))
            }

            $select.addClass('initialized')

            $newSelect.on({
                focus: function () {
                    const $self = $(this)

                    $('input.select-dropdown.active').each(() => {
                        const $el = $(this)
                        if (
                            $el
                                .closest('.select-wrapper')
                                .find('select')
                                .attr('id') !==
                            $self
                                .closest('.select-wrapper')
                                .find('select')
                                .attr('id')
                        )
                            $el.trigger('close')
                    })

                    if (
                        $('div.select-dropdown, ul.select-dropdown')
                            .not(options[0])
                            .is(':visible')
                    ) {
                        $('input.select-dropdown.open')
                            .not(options[0])
                            .trigger('close')
                    }

                    if (!options.is(':visible')) {
                        $self.trigger('open', ['focus'])
                        const label = $self.val()
                        activateOption(
                            options,
                            options
                                .find('li')
                                .filter(
                                    () =>
                                        $(this).text().toLowerCase() ===
                                        label.toLowerCase()
                                )[0]
                        )
                    }

                    $self
                        .parent()
                        .find('li input[type=checkbox]:checked')
                        .each(() => $(this).closest('li').addClass('selected'))
                },

                click: function (e) {
                    e.stopPropagation()
                    const $self = $(this)

                    setTimeout(function () {
                        $self.parent().find('input[autofocus]').focus()
                    }, 100)
                },
            })

            options.hover(
                function () {
                    optionsHover = true
                },
                function () {
                    optionsHover = false
                }
            )

            $(window).on({
                [`click.${selectID}`]: function (e) {
                    multiple && (optionsHover || $newSelect.trigger('close'))
                    if (!multiple) $newSelect.trigger('close')
                },
            })

            // Add initial multiple selections.
            if (multiple) {
                $select
                    .find('option:selected:not(:disabled)')
                    .each(function () {
                        const index = $(this).index()

                        toggleEntryFromArray(valuesSelected, index, $select)
                        options
                            .find('li')
                            .eq(index)
                            .find(':checkbox')
                            .prop('checked', true)
                    })
            }
            $newSelect.closest('.select-wrapper').on('click', function (e) {
                e.stopPropagation()
            })

            // Make option as selected and scroll to selected position
            const activateOption = function (collection, newOption) {
                if (newOption) {
                    collection.find('li.selected').removeClass('selected')
                    const option = $(newOption)
                    if (option.find('input').is(':checked')) {
                        option.addClass('selected')
                    }
                    // options.scrollTo(option);
                }
            }

            // Allow user to search by typing
            // this array is cleared after 1 second
            let filterQuery = []
            $newSelect.on('keydown', function (e) {
                // TAB - switch to another input
                if (e.which === 9) {
                    $newSelect.trigger('close')
                    return
                }

                if (!options.is(':visible')) {
                    // ARROW DOWN WHEN SELECT IS CLOSED - open select options
                    if (e.which === 40) {
                        $newSelect.trigger('open')
                        return
                    }

                    // ENTER WHEN SELECT IS CLOSED - submit form
                    if (e.which === 13) {
                        return
                    }
                }
                e.preventDefault()

                // CASE WHEN USER TYPE LETTERS
                const letter = String.fromCharCode(e.which).toLowerCase()
                const nonLetters = [9, 13, 27, 38, 40]
                if (letter && nonLetters.indexOf(e.which) === -1) {
                    filterQuery.push(letter)

                    const string = filterQuery.join('')
                    const newOption = options
                        .find('li')
                        .filter(
                            () =>
                                $(this).text().toLowerCase().indexOf(string) ===
                                0
                        )[0]

                    if (newOption) {
                        activateOption(options, newOption)
                    }
                }

                // ENTER - select option and close when select options are opened
                if (e.which === 13) {
                    const activeOption = options.find(
                        'li.selected:not(.disabled)'
                    )[0]
                    if (activeOption) {
                        $(activeOption).trigger('click')
                        if (!multiple) {
                            $newSelect.trigger('close')
                        }
                    }
                }

                // ARROW DOWN - move to next not disabled option
                let newOption
                if (e.which === 40) {
                    if (options.find('li.selected').length) {
                        newOption = options
                            .find('li.selected')
                            .next('li:not(.disabled)')[0]
                    } else {
                        newOption = options.find('li:not(.disabled)')[0]
                    }
                    activateOption(options, newOption)
                }

                // ESC - close options
                if (e.which === 27) {
                    $newSelect.trigger('close')
                    $newSelect.trigger('blur')
                }

                // ARROW UP - move to previous not disabled option
                if (e.which === 38) {
                    newOption = options
                        .find('li.selected')
                        .prev('li:not(.disabled)')[0]
                    if (newOption) activateOption(options, newOption)
                }

                // Automaticaly clean filter query so user can search again by starting letters
                setTimeout(function () {
                    filterQuery = []
                }, 1000)
            })
        })

        function toggleEntryFromArray(entriesArray, entryIndex, select) {
            const index = entriesArray.indexOf(entryIndex)
            const notAdded = index === -1

            if (notAdded) {
                entriesArray.push(entryIndex)
            } else {
                entriesArray.splice(index, 1)
            }

            select
                .siblings('ul.dropdown-content')
                .find('li')
                .eq(entryIndex)
                .toggleClass('active')

            // use notAdded instead of true (to detect if the option is selected or not)
            select.find('option').eq(entryIndex).prop('selected', notAdded)
            setValueToInput(entriesArray, select)

            return notAdded
        }

        function setValueToInput(entriesArray, select) {
            select
                .siblings('input.select-dropdown')
                .val(
                    entriesArray
                        .map((entry) => select.find('option').eq(entry).text())
                        .join(', ') ||
                        select.find('option:disabled').eq(0).text()
                )
        }
    }
}) // End of $(function() {

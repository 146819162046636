import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Checkbox } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm';

import StatisticsSlowFieldsWarning from '../statistics-modals/StatisticsSlowFieldsWarning';
import { setStatisticsColumns, setStatisticsColumnsFilters, setStatisticsGroups } from '../../../actions/statistics-actions';

import consts from '../../../utils/consts';
import { closeModalAnimation } from '../../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../../components/reusables/colorpicker/useClickOutside.js';
const { COLUMNS_FIELDS, COLUMNS_FIELDS_CATEGORY, COLUMNS_FIELDS_GROUPS } = consts;

const columnsFields = [
  'columnSearch',
  ...COLUMNS_FIELDS_GROUPS.map((column) => column.replace('.', '-')),
  ...COLUMNS_FIELDS.map((column) => column.replace('.', '-')),
];

const slowColumnsFields = [
  'Stat.affiliate_info1',
  'Stat.affiliate_info2',
  'Stat.affiliate_info3',
  'Stat.affiliate_info4',
  'Stat.affiliate_info5',
  'Stat.source',
  'AdCampaign.name',
  'Goal.name',
  'Country.name',
];

const StatisticsAddColumnModal = ({ drillDownValues, fields, id, values, dispatch, modalInner }) => {
  useEffect(() => {
    dispatch({
      type: 'redux-form/CHANGE',
      form: 'statisticsAddColumn',
      field: 'Offer-name',
      value: values['Goal-name'],
    });
  }, [values['Goal-name']]);

  useEffect(() => {
    if (values['Goal-name'] && values['Offer-name']) {
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'statisticsAddColumn',
        field: 'Goal-name',
        value: values['Offer-name'],
      });
    }
  }, [values['Offer-name']]);

  const handleCloseModal = () => {
    const eventLabel = [];

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        if (key === 'Stat-erpc') {
          eventLabel.push('epc');
        } else if (key.includes('Stat-')) {
          eventLabel.push(key.split('Stat-')[1]);
        } else if (key === 'OfferFile-display') {
          eventLabel.push('creative id');
        } else if (key === 'Browser-display_name') {
          eventLabel.push('browser/device');
        } else if (key === 'OfferUrl-name') {
          eventLabel.push('landing page');
        } else {
          eventLabel.push(key);
        }
      }
    }
    Gtm.event('statistics', 'Click', `more data options - ${eventLabel}`);

    let correctValues = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => key.replace('-', '.'));

    let correctGroups = _.intersection(correctValues, COLUMNS_FIELDS_GROUPS);
    const correctCategories = _.intersection(correctValues, COLUMNS_FIELDS_CATEGORY);

    if (correctCategories.length > 0) {
      correctValues.push('Category.name');
      correctGroups.push('Category.name');

      correctGroups = _.pull(correctGroups, ...COLUMNS_FIELDS_CATEGORY);
      correctValues = _.pull(correctValues, ...COLUMNS_FIELDS_CATEGORY);
    }

    if (correctGroups.indexOf('Goal.name') >= 0) {
      correctGroups.push('Goal.id');
    }

    dispatch(setStatisticsColumns(correctValues));
    dispatch(setStatisticsGroups(correctGroups));
    dispatch(setStatisticsColumnsFilters(correctCategories));
    dispatch(closeModalAnimation());
    setTimeout(() => {
      document.getElementById('statistics-sub-reports').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }, 1000);
  };

  useClickOutside(modalInner, handleCloseModal);

  const handleSelectAll = (selectAllFlag = true) => {
    [...COLUMNS_FIELDS, ...COLUMNS_FIELDS_GROUPS].map((column) =>
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'statisticsAddColumn',
        field: column.replace('.', '-'),
        value: selectAllFlag,
      })
    );
  };

  const allSelected = ![...COLUMNS_FIELDS, ...COLUMNS_FIELDS_GROUPS].some((column) => !values[column.replace('.', '-')]);
  const selectedSlowColumns = slowColumnsFields.filter((column) => values[column.replace('.', '-')]);
  const drilledFields = Object.values(drillDownValues).map((value) => value.field);
  let tabIndex = 0;

  return (
    <div className="hasFooter" id={id}>
      <div className="stats-modal-content">
        <div className="modal-container">
          <div className="row modal-filter-title">
            <div className="col-md-12">
              <h1 className="primary-color">
                <FormattedMessage {...messages.genericTextMoreDataOptions} />
              </h1>
            </div>
          </div>
          <div className="row columnsContainer">
            <Checkbox
              className="col-lg-3 col-md-4 col-xs-6"
              field={{
                value: [allSelected],
                onChange: () => handleSelectAll(!allSelected),
              }}
              id="selectAllColumns"
              label={messages.genericTextSelectAll.defaultMessage}
              tabIndex=""
              value
            />
          </div>

          <div className="row modal-filter-title">
            <div className="col-md-12">
              <h2>Measures</h2>
            </div>
          </div>
          <div className="row columnsContainer">
            {COLUMNS_FIELDS.filter((column) => drilledFields.indexOf(column) === -1).map((column) => (
              <Checkbox
                className="addColumnCheckbox col-lg-3 col-md-4 col-xs-6"
                field={fields[column.replace('.', '-')]}
                id={column}
                key={`addColumn-measures-${column}`}
                label={messages.statisticsColumns[column].defaultMessage}
                tabIndex={++tabIndex}
              />
            ))}
          </div>
          <div className="row modal-filter-title">
            <div className="col-md-12">
              <h2>Dimensions</h2>
            </div>
          </div>
          <div className="row columnsContainer">
            {COLUMNS_FIELDS_GROUPS.filter((column) => drilledFields.indexOf(column) === -1).map((column) => (
              <Checkbox
                className="addColumnGroupCheckbox col-lg-3 col-md-4 col-xs-6"
                field={fields[column.replace('.', '-')]}
                id={column}
                key={`addColumn-dimensions-${column}`}
                label={messages.statisticsColumns[column].defaultMessage}
                tabIndex={++tabIndex}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={`modal-footer ${selectedSlowColumns.length ? 'warning' : ''} `}>
        {!!selectedSlowColumns.length && (
          <StatisticsSlowFieldsWarning fieldsMessages={messages.statisticsColumns} slowFields={selectedSlowColumns} />
        )}
        <button
          className="modal-action waves-effect waves-green btn bg-primary-color"
          onClick={handleCloseModal}
          tabIndex={++tabIndex}
        >
          <FormattedMessage {...messages.genericTextApply} />
        </button>
      </div>
    </div>
  );
};

StatisticsAddColumnModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  drillDownValues: PropTypes.object.isRequired,
  fields: PropTypes.object,
  id: PropTypes.string.isRequired,
  statisticsSelectedRows: PropTypes.array.isRequired,
  values: PropTypes.object,
};

export default reduxForm(
  {
    form: 'statisticsAddColumn',
    fields: columnsFields,
  },
  (state) => {
    const values = {};

    state.statistics.columns.forEach((column) => {
      values[column.replace('.', '-')] = true;
    });

    return {
      initialValues: values,
    };
  }
)(StatisticsAddColumnModal);

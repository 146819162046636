import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import AddToolBanner from '../AddToolBanner.react';
import messages from '../../../i18n/base-en.js';

import { Filters } from '../../reusables';

import SelectCustom from '../form/SelectCustom';

const CreativesEmailFilters = ({ fields, handleSubmit, intl, isLoading }) => (
  <>
    <Filters handleSubmit={handleSubmit} id="offer-creatives-html-filters">
      <div className="customSelects">
        <div className="fields">
          <div className="col-xs-12 col-md-4 filterInput ">
            <SelectCustom
              id="language"
              label={messages.genericTextLanguage.description}
              onChange={fields.language.onChange}
              value=""
              touched={fields.language.touched}
              valueDisabled={intl.formatMessage(messages.genericTextAll)}
              showSelectedFieldCheckmark={false}
              options={[
                { value: 'EN', text: 'English' },
                { value: 'FR', text: 'French' },
                { value: 'ES', text: 'Spanish' },
                { value: 'DE', text: 'German' },
                { value: 'IT', text: 'Italian' },
                { value: 'NL', text: 'Dutch' },
              ]}
            />
          </div>
          <div className="col-xs-12 col-md-4 filterInput">
            <SelectCustom
              id="rating"
              label={messages.genericTextRating.description}
              onChange={fields.rating.onChange}
              value=""
              touched={fields.rating.touched}
              valueDisabled={intl.formatMessage(messages.genericTextAll)}
              showSelectedFieldCheckmark={false}
              options={[
                { value: 'G', text: 'G' },
                { value: 'PG', text: 'PG' },
                { value: '13', text: '13+' },
                { value: '18', text: '18+' },
                { value: 'R', text: 'R' },
              ]}
            />
          </div>
        </div>
      </div>
    </Filters>
    {fields.length < 1 && !isLoading && <AddToolBanner />}
  </>
);

CreativesEmailFilters.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  reduxForm(
    {
      form: 'creativesSearch',
      fields: ['size', 'format', 'rating', 'language'],
    },
    (state) => ({
      initialValues: {
        size: state.creativesSizes.length > 0 ? state.creativesSizes[0] : '',
        format: '',
        rating: '',
        language: '',
      },
    })
  )(CreativesEmailFilters)
);

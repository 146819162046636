import consts from '../utils/consts';

const {
  ACTIONS: { ACH_CONFIGURATIONS },
} = consts;

const initialState = {
  isFetching: false,
  request: null,
  data: [],
  receivedAt: null,
};

export const achConfigurations = (state = initialState, action) => {
  switch (action.type) {
    case ACH_CONFIGURATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
        request: action.request,
      };
    case ACH_CONFIGURATIONS.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.payload.CrakRevenue.AchConfigurations?.Data,
        receivedAt: new Date().getTime(),
      };
    case ACH_CONFIGURATIONS.FAILED_TO_RECEIVE:
      return {
        ...state,
        isFetching: false,
        receivedAt: new Date().getTime(),
      };
    default:
      return state;
  }
};

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { CodeInput, Copy } from '../../reusables';
import { Gtm } from '../../../utils/gtm';

const {
  offerPopCode: { msgCopy, msgHeader, msgLoading, msgNoData },
} = messages;

const popCodeCopyTracking = () =>
  Gtm.event(`${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`, 'Click', 'copy pop code');

const CreativesPopCode = ({ creativeCode, dispatch, intl, isLoading, offerFileId }) => (
  <div className="fullWidth">
    {!isLoading ? (
      <div>
        {offerFileId ? (
          <div className="code-input-wrapper">
            <CodeInput codeId="offer-pop-code" text={creativeCode} title={<FormattedMessage {...msgHeader} />} />
            <Copy
              clipboardTarget="#offer-pop-code"
              dispatch={dispatch}
              icon="code"
              onCopy={() => {
                popCodeCopyTracking();
              }}
              text={<FormattedMessage {...msgCopy} />}
            />
          </div>
        ) : (
          <div className="code-input-wrapper">
            <CodeInput text={intl.formatMessage(msgNoData)} title={<FormattedMessage {...msgHeader} />} />
            <Copy clipboardTarget={null} disabled dispatch={() => {}} icon="code" text={<FormattedMessage {...msgCopy} />} />
          </div>
        )}
      </div>
    ) : (
      <div className="code-input-wrapper">
        <CodeInput text={intl.formatMessage(msgLoading)} title={<FormattedMessage {...msgHeader} />} />
        <Copy clipboardTarget={null} disabled dispatch={() => {}} icon="code" text={<FormattedMessage {...msgCopy} />} />
      </div>
    )}
  </div>
);

CreativesPopCode.defaultProps = {
  creativeCode: '',
  dispatch: () => {},
  isLoading: false,
};

CreativesPopCode.propTypes = {
  creativeCode: PropTypes.string,
  dispatch: PropTypes.func,
  intl: intlShape.isRequired,
  isLoading: PropTypes.bool,
  offerFileId: PropTypes.string,
};

export default injectIntl(CreativesPopCode);

import PropTypes from 'prop-types';

import { getFlagUrl } from './reusablesHelpers/helpers';

const CountryList = ({ list }) => (
  <ul className="country-list">
    {list.map((country) => (
      <li
        key={country}
        style={{
          backgroundImage: `url(${getFlagUrl(country)})`,
        }}
        title={country}
      />
    ))}
  </ul>
);

CountryList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default CountryList;

import PropTypes from 'prop-types';

import HomeBlogPost from '../../pages/home/blog/HomeBlogPost.react';
import { v4 as uuidv4 } from 'uuid';

const HomeBlog = ({ latestPosts }) => (
  <div id="home-blog">
    <div className="row">
      {latestPosts.slice(0, 3).map((post, index) => (
        <HomeBlogPost
          categories={post.categories[0].category}
          date={post.date}
          description={post.description}
          key={uuidv4()}
          link={post.link}
          media={post.media}
          showImageMobile={index === 0}
          title={post.title}
        />
      ))}
    </div>
  </div>
);

HomeBlog.defaultProps = {
  latestPosts: [],
};

const category = PropTypes.any.isRequired;

HomeBlog.propTypes = {
  latestPosts: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(category).isRequired,
      date: PropTypes.any.isRequired,
      description: PropTypes.any.isRequired,
      link: PropTypes.any.isRequired,
      media: PropTypes.any.isRequired,
      title: PropTypes.any.isRequired,
    })
  ),
};

export default HomeBlog;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const getElementsToValidate = (props) => Object.keys(props).filter((key) => typeof props[key] === 'boolean');

const getLoadingElements = (props) => getElementsToValidate(props).filter((key) => props[key]);

const getLoadingPercent = (max) => (100 * Math.ceil(Math.random() * ([0, 1].indexOf(max) === -1 ? max - 1 : max))) / max;

const LoadingBar = (props) => {
  const { hideLoadingCursor, showLoadingCursor } = props;
  const loadingElement = getLoadingElements(props).length;

  if (loadingElement === 0) {
    hideLoadingCursor();
    return null;
  }

  showLoadingCursor();
  return (
    <div id="main-loading-bar">
      <div className="bar bg-primary-color" style={{ width: `${getLoadingPercent(loadingElement)}%` }} />
    </div>
  );
};

LoadingBar.propTypes = {
  hideLoadingCursor: PropTypes.func,
  showLoadingCursor: PropTypes.func,
};

export default connect((state) => ({
  achConfigurationsIsFetching: state.achConfigurations.isFetching,
  alertsDismissIsFetching: state.alerts.dismissAlertsIsFetching,
  alertsIsFetching: state.alerts.alertsIsFetching,
  alertsNewIsFetching: state.alerts.newAlertsIsFetching,
  appBestTimeToCallIsFetching: state.application.lists.bestTimeToCallIsFetching,
  appBrowsersIsFetching: state.application.lists.browsersIsFetching,
  appCountriesIsFetching: state.application.lists.countriesIsFetching,
  appGoalsIsFetching: state.application.lists.goalsIsFetching,
  appMinimumPayoutsIsFetching: state.application.lists.minimumPayoutsIsFetching,
  appOffersCategoriesIsFetching: state.application.lists.offersCategoriesIsFetching,
  appOffersIsFetching: state.application.lists.offersIsFetching,
  appOffersUrlIsFetching: state.application.lists.offersUrlIsFetching,
  appPaymentMethodsIsFetching: state.application.lists.paymentMethodsIsFetching,
  appSalutationsIsFetching: state.application.lists.salutationsIsFetching,
  appSearchFieldsIsFetching: state.application.lists.searchFields.isFetching,
  appTimezonesIsFetching: state.application.lists.timezonesIsFetching,
  blogIsFetching: state.blog.isFetching,
  creativeCodeIsFetching: state.creativeCode.isFetching,
  creativesIsFetching: state.creatives.isFetching,
  creativesSizesIsFetching: state.creativesSizes.isFetching,
  goalsIsFetching: state.goals.goalsIsFetching,
  homeDailyStatisticsIsFetching: state.home.dailyStatisticsIsFetching,
  homeFeaturedOffersIsFetching: state.home.featuredOffersIsFetching,
  homeLatestPayoutsIsFetching: state.home.currentPeriodPayoutsIsFetching,
  homeQOTWIsFetching: state.home.QOTWIsFetching,
  jerkmateOffersIsFetching: state.jerkmate.offersIsFetching,
  billingInfosIsFetching: state.billingInfos.isFetching,
  livecamGeneratorCampaignsIsFetching: state.livecamGenerator.campaignsIsFetching,
  loginAuthIsFetching: state.login.isFetchingAuth,
  loginTfaValidationIsFetching: state.login.isFetchingTfaValidation,
  newsIsFetching: state.news.isFetching,
  offerAcceptTermsIsFetching: state.offer.offerAcceptTermsIsFetching,
  offerIsFetching: state.offer.offerIsFetching,
  offerOverlayIsFetching: state.offer.offerOverlay.isFetching,
  offerPopCodeIsFetching: state.offer.offerPopCode.isFetching,
  offerTrackingLinkIsFetching: state.offer.offerTrackingLinkIsFetching,
  offerUnsubscribeLinksIsFetching: state.offer.unsubscribeLinksIsFetching,
  offerUrlIsFetching: state.offer.offerUrlIsFetching,
  offersIsFetching: state.offers.offersIsFetching,
  offersSlidesIsFetching: state.offersSlides.isFetching,
  paymentHistoryIsFetching: state.paymentHistory.isFetchingPaymentsList,
  postbacksIsFetching: state.postbacks.postbacksIsFetching,
  profileCompletedIsFetching: state.profileCompleted.fetchingProfile,
  profileCompletePaymentIsFetching: state.profileCompleted.fetchingPayment,
  profileIsFetching: state.profile.isFetching,
  promoIsFetching: state.promo.isFetching,
  referralCommissionIsFetching: state.referrals.referralCommissionIsFetching,
  referralsIsFetching: state.referrals.referralsIsFetching,
  signupIsFetching: state.signup.isFetching,
  skinConfigurationIsFetching: state.skinConfigurations.isFetching,
  statisticsDeleteReportIsFetching: state.statistics.statisticsDeleteReportIsFetching,
  statisticsGraphIsFetching: state.statistics.statisticsGraphIsFetching,
  statisticsIsFetching: state.statistics.statisticsIsFetching,
  statisticsSavedReportsIsFetching: state.statistics.statisticsSavedReportsIsFetching,
  statisticsSaveReportIsFetching: state.statistics.statisticsSaveReportIsFetching,
  surveyGeneratorCreativeIsFetching: state.surveyGenerator.creativeIsFetching,
  surveyGeneratorQuestionIsFetching: state.surveyGenerator.questionIsFetching,
  surveyGeneratorTemplateUrlIsFetching: state.surveyGenerator.templateUrlIsFetching,
  topMfcOffersIsFetching: state.topoffers.mfc.offersIsFetching,
  topOffersIsFetching: state.topoffers.top.offersIsFetching,
  topRecentOffersIsFetching: state.topoffers.recent.offersIsFetching,
}))(LoadingBar);

const TrackingLinkIcon = () => (
  <svg viewBox="0 0 16 21.33" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="linear-gradient" x1="12.66" x2="3.05" y1="19.87" y2="3.88">
        <stop offset="0" stopColor="#ed4910" />
        <stop offset="1" stopColor="#fec002" />
      </linearGradient>
    </defs>
    <title>Tracking Link</title>
    <path
      d="M9.5,0a21.13,21.13,0,0,1,.74,4.8A3.47,3.47,0,0,1,7,8.52H6.83A3.63,3.63,0,0,1,3.2,4.88V4.44A13.74,13.74,0,0,0,0,13.33a8,8,0,0,0,16,0A17.11,17.11,0,0,0,9.5,0ZM7.71,18.33a3.18,3.18,0,0,1-3.22-3.14h0A3.13,3.13,0,0,1,7.3,12.07a7.71,7.71,0,0,0,4.62-2.58,13.94,13.94,0,0,1,.59,4,4.8,4.8,0,0,1-4.76,4.84Z"
      fill="url(#linear-gradient)"
    />
  </svg>
);

export default TrackingLinkIcon;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import BillingInfos from './BillingInfos.react';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import { fetchPaymentCompleteVerification } from '../../components/Profile/actions/profile-completed-actions';
import { fetchProfile } from '../../actions/profile-actions';
import HandleAPIError from '../../utils/handleAPIError';
import { postChangeBillingInfos, putChangeBillingInfos } from '../../actions/billing-infos-actions';
import { openModal } from '../../components/Modal/modal-actions/modal-actions.js';
import qs from 'qs';

function BillingInfosContainer(props) {
  const { isConnected, dispatch } = props;

  const saveConfirmBilling = () => {
    const urlParam = qs.parse(window.location.search.replace('?', ''));

    dispatch(putChangeBillingInfos({ info: urlParam.confirmBilling })).then(() => {
      dispatch(fetchProfile())
        .then(() => {
          const fetchingPromises = [
            dispatch(fetchPaymentCompleteVerification()),
            dispatch(
              openModal({
                name: 'BillingChangeConfirmModal',
                modalProps: {
                  dispatch: dispatch,
                  isConnected: isConnected,
                  className: 'billing-change-confirm-modal-styled',
                },
              })
            ),
            window.history.replaceState({}, document.title, window.location.pathname),
          ];
          Promise.all(fetchingPromises);
        })
        .catch((res) => {
          new HandleAPIError(res, dispatch).handleAll();
        });
    });
  };

  useEffect(() => {
    const urlParam = qs.parse(window.location.search.replace('?', ''));

    if (urlParam.confirmBilling && isConnected) {
      setTimeout(() => {
        saveConfirmBilling();
      }, 1250); // le temps de rediriger, avoir les param dans l'url et avoir les bonne props. Ã©vite un watch sur l'url. donne aussi au browser le temps de dessiner la page, evite du lag visuel
    }
  }, [isConnected]);

  const handleFormSubmit = (data) => {
    const wireBankStateToSave =
      data.wireBankCountry === 'CA'
        ? data.wireBankProvince
        : data.wireBankCountry === 'US'
        ? data.wireBankState
        : data.wireBankRegion;
    data.wireBankState = wireBankStateToSave;
    data.wireBankProvince = wireBankStateToSave;
    data.wireBankRegion = wireBankStateToSave;

    const body = {
      ...data,
      callbackUrl: window.location.href,
    };
    dispatch(postChangeBillingInfos(body))
      .then(() => {
        dispatch(fetchProfile()).then(() => {
          const fetchingPromises = [
            dispatch(fetchPaymentCompleteVerification()),
            dispatch(
              openModal({
                name: 'BillingChangeModal',
                modalProps: {
                  dispatch: dispatch,
                  className: 'billing-change-modal-styled',
                },
              })
            ),
          ];
          Promise.all(fetchingPromises);
        });
      })
      .catch((res) => {
        new HandleAPIError(res, dispatch).handleAll();
      });
  };

  return (
    <div>
      <BillingInfos {...props} onSubmit={handleFormSubmit} />
    </div>
  );
}

BillingInfosContainer.propTypes = {
  billingInfosForm: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  intl: intlShape.isRequired,
  isConnected: PropTypes.bool,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(BillingInfosContainer));

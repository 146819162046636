import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Gtm } from '../../utils/gtm';

import consts from '../../utils/consts';

import 'materialize-css';
import { Select } from 'react-materialize';
const { TRAFFICTYPE_ZONES } = consts;

const RewardOptions = (props) => {
  const { fields } = props;

  const handleGtmEvent = (e) => {
    for (const [key, value] of Object.entries(TRAFFICTYPE_ZONES)) {
      if (JSON.stringify(value) === e.target.value) {
        return Gtm.event('survey machine', 'Click', `rewards dropdown - ${key.toLowerCase()}`);
      }
    }
  };

  return (
    <div className="container-fluid reward-options">
      <div className="row">
        <div className="col-md-4 config-section">
          <h2>
            <FormattedMessage {...messages.genericTextRewards} />
          </h2>
          <div className="row">
            <Select
              className="col-md-12"
              id="reward-type"
              label={messages.genericTextType.description}
              onChange={(e) => {
                fields.rewardType.onChange(e.target.value);
                handleGtmEvent(e);
              }}
              tabIndex={10}
              value={fields.rewardType.value}
            >
              <option value={JSON.stringify(TRAFFICTYPE_ZONES.CAM)}>Cam (18+)</option>
              <option value={JSON.stringify(TRAFFICTYPE_ZONES.DATING)}>Dating (18+)</option>
              <option value={JSON.stringify(TRAFFICTYPE_ZONES.GAY)}>Gay (18+)</option>
              <option value={JSON.stringify(TRAFFICTYPE_ZONES.MIXED)}>Mixed (18+)</option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

RewardOptions.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'rewardOptions',
    touchOnChange: true,
    touchOnBlur: true,
    fields: ['rewardType'],
    destroyOnUnmount: false,
  })(RewardOptions)
);

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';

const { API_URL } = config;

export const updateSendGrid = (data) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/sendgrid/contact/update-profile`)
      .withCredentials()
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send({
        data,
      })
      .end((err, res) => {
        if (!err) {
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );

const TEMPLATE_OPTIONS = {
  modelIdentity: '',
  primaryColor: '#000000',
  secondaryColor: '#000000',
};
const BASIC_CONFIGURATION = {
  questionTheme: 2,
  questionTopic: 'Generic (18+)',
  questionNumber: '5 Questions',
  displayLogo: 'yes',
  displayIntroPicture: 'yes',
  displayBoxBorder: 'yes',
  displayIntroText: 'yes',
  customTitle: 'Best Survey',
  customLogoUrl: '',
  customLogoUrlAlignement: 'center',
  customBackgroundUrl: '',
};
const REWARD_OPTIONS = {
  rewardType: '{"zones":"10833,10834,10835,10836,12288,12289"}',
  displayComments: 'yes',
};

export const COLOR_TEMPLATES = {
  COLOR_TEMPLATE1: {
    INDEX: 1,
    TEMPLATE_OPTIONS: {
      ...TEMPLATE_OPTIONS,
      primaryColor: '#DB232C',
      secondaryColor: '#ffffff',
    },
    BASIC_CONFIGURATION,
    REWARD_OPTIONS,
    CUSTOM_COLORS: {
      // bg
      generalBackgroundColor: '#000000',
      logoBackgroundColor: '#000000',
      questionBoxBackgroundColor: '#000000',
      rewardsBackgroundColor: '#333333',
      // text (contrast to bg)
      introductionTextColor: '#ffffff',
      questionTextColor: '#ffffff',
      answerTextColor: '#ffffff',
      questionBoxQuestionTextColor: '#ffffff',
      questionBoxAnswerTextColor: '#ffffff',
      rewardsTextColor: '#ffffff',
      // primary color:
      logoColor1: '#D71F27',
      introductionBackgroundColor: '#DB232C',
      questionBoxBorderColor: '#DB232C',
      rewardsButtonBackgroundColor: '#DB232C',
      rewardsCommentsUsernameColor: '#DB232C',
      // secondary color:
      rewardsButtonTextColor: '#ffffff',
      logoColor2: '#ffffff',
    },
    PRIMARYCOLORS_FILEDS: [
      'logoColor1',
      'introductionBackgroundColor',
      'questionBoxBorderColor',
      'rewardsButtonBackgroundColor',
      'rewardsCommentsUsernameColor',
    ],
    SECONDARYCOLORS_FILEDS: [
      'introductionTextColor',
      'questionTextColor',
      'answerTextColor',
      'questionBoxQuestionTextColor',
      'questionBoxAnswerTextColor',
      'rewardsTextColor',
    ],
  },
  COLOR_TEMPLATE2: {
    INDEX: 2,
    TEMPLATE_OPTIONS: {
      ...TEMPLATE_OPTIONS,
      primaryColor: '#DB232C',
    },
    BASIC_CONFIGURATION,
    REWARD_OPTIONS,
    CUSTOM_COLORS: {
      generalBackgroundColor: '#F1F1F1',
      logoColor1: '#DB232C',
      logoColor2: '#000000',
      logoBackgroundColor: '#F1F1F1',
      introductionTextColor: '#000000',
      introductionBackgroundColor: '#DB232C',
      questionTextColor: '#000000',
      answerTextColor: '#000000',
      questionBoxBackgroundColor: '#ffffff',
      questionBoxQuestionTextColor: '#000000',
      questionBoxBorderColor: '#DB232C',
      questionBoxAnswerTextColor: '#000000',
      rewardsBackgroundColor: '#ffffff',
      rewardsButtonTextColor: '#ffffff',
      rewardsTextColor: '#000000',
      rewardsButtonBackgroundColor: '#DB232C',
      rewardsCommentsUsernameColor: '#DB232C',
    },
    PRIMARYCOLORS_FILEDS: [
      'logoColor1',
      'introductionBackgroundColor',
      'questionBoxBorderColor',
      'questionBoxBorderColor',
      'rewardsButtonBackgroundColor',
      'rewardsCommentsUsernameColor',
    ],
    SECONDARYCOLORS_FILEDS: [
      'introductionTextColor',
      'questionTextColor',
      'answerTextColor',
      'questionBoxQuestionTextColor',
      'questionBoxAnswerTextColor',
      'rewardsTextColor',
    ],
  },
  COLOR_TEMPLATE3: {
    INDEX: 3,
    TEMPLATE_OPTIONS: {
      ...TEMPLATE_OPTIONS,
      primaryColor: '#39579A',
    },
    BASIC_CONFIGURATION,
    REWARD_OPTIONS,
    CUSTOM_COLORS: {
      generalBackgroundColor: '#E9EBEE',
      logoColor1: '#ffffff',
      logoColor2: '#ffffff',
      logoBackgroundColor: '#39579A',
      introductionTextColor: '#000000',
      introductionBackgroundColor: '#39579A',
      questionTextColor: '#000000',
      answerTextColor: '#000000',
      questionBoxBackgroundColor: '#ffffff',
      questionBoxQuestionTextColor: '#000000',
      questionBoxBorderColor: '#39579A',
      questionBoxAnswerTextColor: '#000000',
      rewardsBackgroundColor: '#ffffff',
      rewardsButtonTextColor: '#ffffff',
      rewardsTextColor: '#000000',
      rewardsButtonBackgroundColor: '#39579A',
      rewardsCommentsUsernameColor: '#39579A',
    },
    PRIMARYCOLORS_FILEDS: [
      'logoBackgroundColor',
      'introductionBackgroundColor',
      'questionBoxBorderColor',
      'rewardsButtonBackgroundColor',
      'rewardsCommentsUsernameColor',
    ],
    SECONDARYCOLORS_FILEDS: [
      'introductionTextColor',
      'questionTextColor',
      'answerTextColor',
      'questionBoxQuestionTextColor',
      'questionBoxAnswerTextColor',
      'rewardsTextColor',
    ],
  },
  COLOR_TEMPLATE4: {
    INDEX: 4,
    TEMPLATE_OPTIONS: {
      ...TEMPLATE_OPTIONS,
      primaryColor: '#FFFB21',
    },
    BASIC_CONFIGURATION,
    REWARD_OPTIONS,
    CUSTOM_COLORS: {
      generalBackgroundColor: '#FFFB21',
      logoColor1: '#000000',
      logoColor2: '#000000',
      logoBackgroundColor: '#FFFB21',
      introductionTextColor: '#000000',
      introductionBackgroundColor: '#000000',
      questionTextColor: '#000000',
      answerTextColor: '#000000',
      questionBoxBackgroundColor: '#ffffff',
      questionBoxQuestionTextColor: '#000000',
      questionBoxBorderColor: '#000000',
      questionBoxAnswerTextColor: '#000000',
      rewardsBackgroundColor: '#ffffff',
      rewardsButtonTextColor: '#ffffff',
      rewardsTextColor: '#000000',
      rewardsButtonBackgroundColor: '#000000',
      rewardsCommentsUsernameColor: '#000000',
    },
    PRIMARYCOLORS_FILEDS: ['generalBackgroundColor', 'logoBackgroundColor'],
    SECONDARYCOLORS_FILEDS: ['introductionBackgroundColor', 'rewardsButtonBackgroundColor'],
  },
  COLOR_TEMPLATE5: {
    INDEX: 5,
    TEMPLATE_OPTIONS: {
      ...TEMPLATE_OPTIONS,
      primaryColor: '#3FC9FF',
    },
    BASIC_CONFIGURATION: {
      ...BASIC_CONFIGURATION,
    },
    REWARD_OPTIONS,
    CUSTOM_COLORS: {
      generalBackgroundColor: '#ffffff',
      logoColor1: '#000000',
      logoColor2: '#000000',
      logoBackgroundColor: '#FFFFFF',
      introductionTextColor: '#000000',
      introductionBackgroundColor: '#3FC9FF',
      questionTextColor: '#000000',
      answerTextColor: '#000000',
      questionBoxBackgroundColor: '#FFFFFF',
      questionBoxQuestionTextColor: '#000000',
      questionBoxBorderColor: '#3FC9FF',
      questionBoxAnswerTextColor: '#3FC9FF',
      rewardsBackgroundColor: '#FFFFFF',
      rewardsButtonTextColor: '#000000',
      rewardsTextColor: '#000000',
      rewardsButtonBackgroundColor: '#3FC9FF',
      rewardsCommentsUsernameColor: '#3FC9FF',
    },
    PRIMARYCOLORS_FILEDS: [
      'introductionBackgroundColor',
      'questionBoxBorderColor',
      'questionBoxAnswerTextColor',
      'rewardsButtonBackgroundColor',
      'rewardsCommentsUsernameColor',      
      'logoColor2'
    ],
    SECONDARYCOLORS_FILEDS: [
      'introductionTextColor',
      'questionTextColor',
      'answerTextColor',
      'questionBoxQuestionTextColor',
      'rewardsTextColor',
      'logoColor1'
    ],
  },
};

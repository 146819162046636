import moment from 'moment';
import config from '../config';
import messages from '../i18n/base-en.js';
import { Gtm } from './gtm';

const intercomExist = () => typeof window !== 'undefined' && !!window.Intercom;

class Intercom {
  constructor() {
    this.isOverrided = false;
    this.mapping = {
      AVERAGEREVENUE: 'monthly_revenues',
      BUYTRAFFIC: 'traffic_buy',
      CONVERTMAINSTREAM: 'mainstream',
      FORUMS: 'blogs_forums',
      HOWYOUHEAR: 'hear_from',
      INCENTIVETRAFFIC: 'incentive_traffic',
      MARKET: 'markets',
      NICHE: 'niches',
      OFFERSINTERESTED: 'offer_types',
      PAYOUTS: 'payout_types',
      PROMOCODE: 'promo_code',
      PROMOTING: 'own_pages',
      PROMOTINGURL: 'traffic_source',
      PROMOTIONMETHODS: 'promotion_method',
      REFERENCES: 'business_contact',
      REPRESENTCOMPANY: 'own_products',
      SELLBUYTRAFFIC: 'cpm_cpc',
      SELLTRAFFIC: 'traffic_sell',
      TIMEREADY: 'startup_date',
      TRAFFICDEVICE: 'traffic_devices',
    };
  }

  boot(profile, skinName) {
    const adressBR = [
      'affiliates.bluerevenue.staging',
      'affiliates.bluerevenue-stage.com',
      'influencers.bluerevenue.staging',
      'influencers.bluerevenue-stage.com',
      'affiliates.bluerevenue.com',
      'influencers.bluerevenue.com',
    ];
    //Cette condition permet de ne pas monter le module intercom si nous sommes dans le cas de BR (adresses listées si dessus)
    if (!adressBR.find((adress) => adress === window.location.host)) {
      if (intercomExist()) {
        if (this.isOverrided) {
          this.shutdown();
        } else {
          const signupAnswers = {};
          let infos = {
            app_id: config.INTERCOM_APP_ID,
          };
          this.isOverrided = !!profile && profile.isOverrided ? profile.isOverrided : false;

          if (!!profile && !this.isOverrided) {
            Object.keys(profile.signupAnswers).map((signupAnswer) => {
              if (this.mapping[signupAnswer]) {
                signupAnswers[this.mapping[signupAnswer]] = profile.signupAnswers[signupAnswer].answer;
              }
            });

            const {
              affiliateManager,
              affInfos: { company, date_added, id, phone, ref_id, status },
              affRank: { current_aff_rank },
              affStatus: { rank_status_name },
              affUserInfos,
              customProfile: {
                affiliate: { payment_method },
                affiliate_info,
              },
              intercomUserHash,
              paymentHistory: { asReachedMinimumPayout, next },
            } = profile;

            infos = {
              ...infos,
              company: {
                id: id,
                name: `${company} - ${id}`,
                legacy_id: ref_id,
                account_owner: `${affiliateManager.first_name} ${affiliateManager.last_name}`,
                payment_method: payment_method ? payment_method.name : affiliate_info.payment_method,
                payment_status: asReachedMinimumPayout ? 'Pending' : 'Carried Over',
                pending_revenues: next ? next.amount || '' : '',
                status: status,
                plan: rank_status_name,
                phone: phone,
                rank_number: current_aff_rank,
                referred_by: ref_id,
                ...signupAnswers,
              },
              email: affUserInfos.email,
              job_title: affUserInfos.title,
              last_login_skin: skinName,
              name: `${affUserInfos.first_name} ${affUserInfos.last_name}`,
              registration: 'approved',
              signed_up_at: moment(date_added, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
              user_hash: intercomUserHash,
              user_id: affUserInfos.id,
            };
          }
          window.Intercom('boot', infos);
        }
      }
    }
  }

  newMessageErrorReport(intl, intent, errormessage) {
    if (intercomExist()) {
      const values = {
        intent,
        location: window.location.pathname,
        error: errormessage,
      };
      window.Intercom('showNewMessage', intl.formatMessage(messages.genericTextIntercomErrorMessage, values));
    }
  }

  onShow(pageName) {
    if (intercomExist()) {
      window.Intercom('onShow', async () => {
        Gtm.event(`${pageName}`, 'Click', 'intercom');
      });
    }
  }

  shutdown() {
    if (intercomExist()) {
      window.Intercom('shutdown');
    }
  }

  trackEvent(name, params) {
    if (intercomExist() && !this.isOverrided) {
      window.Intercom('trackEvent', name, params);
    }
  }

  update(profile, infos = {}) {
    if (intercomExist() && !!profile) {
      const {
        affInfos: { id },
        affUserInfos,
        intercomUserHash,
        isOverrided,
      } = profile;
      this.isOverrided = isOverrided || false;

      if (isOverrided) {
        this.shutdown();
      } else {
        window.Intercom('update', {
          user_id: affUserInfos.id,
          user_hash: intercomUserHash,
          ...infos,
          company: {
            ...infos.company,
            id: id,
          },
        });
      }
    }
  }
}

export default new Intercom();

import PropTypes from 'prop-types';

const RankStatus = ({ rank }) => {
  switch (rank) {
    case 'active':
      return (
        <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path d="M-4.577-5.577h27.556v27.556H-4.577z" />
            <path className="fill" d="M19.947 3.215L16.98.248l-9.975 9.975-4.051-4.051-2.967 2.964 7.018 7.018.005-.005z" />
          </g>
        </svg>
      );
    case 'elite':
      return (
        <svg viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path
              className="fill"
              d="M18.673 14.493l-.45 1.593a.405.405 0 01-.39.295H5.113a.405.405 0 01-.39-.295l-.45-1.593h14.4zM20.813 6.818l-1.928 6.918H4.068L2.14 6.818a1.27 1.27 0 00.556-.676l4.61 2.067a.408.408 0 00.512-.155l3.236-5.263c.136.05.279.076.423.076.154.003.307-.024.45-.079.785 1.217 2.427 3.766 3.35 5.217.106.17.322.234.505.15l4.475-2.01c.102.281.299.519.556.673z"
            />
            <path d="M18.673 14.493l-.45 1.593a.405.405 0 01-.39.295H5.113a.405.405 0 01-.39-.295l-.45-1.593h14.4zM2.4 5.696a.928.928 0 01-.332.714.923.923 0 11.333-.712v-.002zM12.403 1.563a.932.932 0 01-.79.917.345.345 0 00-.246 0 .923.923 0 111.043-.917h-.007zM22.4 5.696a.921.921 0 11-1.844 0 .923.923 0 011.845 0zM20.813 6.818l-1.928 6.918H4.068L2.14 6.818a1.27 1.27 0 00.556-.676l4.61 2.067a.408.408 0 00.512-.155l3.236-5.263c.136.05.279.076.423.076.154.003.307-.024.45-.079.785 1.217 2.427 3.766 3.35 5.217.106.17.322.234.505.15l4.475-2.01c.102.281.299.519.556.673z" />
            <path d="M-1-3h24.962v24.962H-1z" />
          </g>
        </svg>
      );
    case 'vip':
      return (
        <svg height="16.723" viewBox="0 0 18.759 16.723" width="18.759" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path
              className="fill"
              d="M11.073,11.211,13.617,6h.824l2.545,5.211Zm2.351,11.512-8.58-10.3h8.58Zm1.212,0v-10.3h8.58Zm3.708-11.512L15.8,6h4.12a1.421,1.421,0,0,1,.763.218,1.509,1.509,0,0,1,.545.582l2.181,4.411Zm-13.694,0L6.831,6.8a1.509,1.509,0,0,1,.545-.582A1.421,1.421,0,0,1,8.14,6h4.12L9.715,11.211Z"
              id="vip-aff"
              transform="translate(-4.65 -6)"
            />
          </g>
        </svg>
      );
    case 'rising star':
      return (
        <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path d="M-4-3h27.957v27.957H-4z" />
            <path
              className="fill"
              d="M10.63.993l1.988 6.115a.578.578 0 00.548.398h6.43a.578.578 0 01.338 1.045l-5.202 3.783a.578.578 0 00-.21.646l1.989 6.115a.578.578 0 01-.888.646l-5.202-3.784a.578.578 0 00-.678 0L4.54 19.74a.578.578 0 01-.888-.646l1.988-6.115a.578.578 0 00-.21-.646l-5.2-3.783a.578.578 0 01.337-1.045h6.43c.25 0 .47-.161.548-.398L9.533.993a.578.578 0 011.098 0z"
            />
          </g>
        </svg>
      );
    default:
      return null;
  }
};

RankStatus.propTypes = {
  rank: PropTypes.string,
};

export default RankStatus;

import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

const NewsBox = ({ content }) => (
  <div id="NewsBox">
    <p>
      <b>{content.title}:</b> {reactHtmlParser(content.content)}
    </p>
  </div>
);

NewsBox.propTypes = {
  content: PropTypes.object.isRequired,
};

export default NewsBox;

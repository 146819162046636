import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../i18n/base-en.js';
import intercom from '../../../../utils/intercom';
import { closeModalAnimation } from '../../../../components/Modal/modal-actions/modal-actions';
import useClickOutside from '../../../../components/reusables/colorpicker/useClickOutside';

const BillingChangeErrorModal = ({ dispatch, modalInner, error, intl, intercomError }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="billing-change-error">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div>
        <h2 className="primary-color">
          <i className="material-icons" id="warning-change-error-icon">
            warning
          </i>
        </h2>
        <hr />
        <p>{error || <FormattedMessage {...messages.genericTextErrorContactSupport} />}</p>
        <button
          className="waves-effect waves-light btn"
          id="btn-billing-change-error"
          onClick={() => {
            intercom.newMessageErrorReport(intl, intercomError, error);
          }}
        >
          Talk to support
        </button>
      </div>
    </div>
  );
};

export default injectIntl(BillingChangeErrorModal);

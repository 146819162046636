const RoundArrow = () => (
  <svg fill="none" height="19" viewBox="0 0 16 19" width="16" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_142_13244)">
      <path
        d="M10.7678 17.9009C10.713 17.9566 10.6822 18.0322 10.6822 18.1111C10.6822 18.1899 10.713 18.2655 10.7678 18.3213C10.8225 18.377 10.8968 18.4083 10.9743 18.4083C11.0517 18.4083 11.126 18.377 11.1808 18.3213L15.2641 14.165C15.2913 14.1374 15.3128 14.1047 15.3275 14.0686C15.3422 14.0325 15.3498 13.9939 15.3498 13.9548C15.3498 13.9158 15.3422 13.8771 15.3275 13.841C15.3128 13.805 15.2913 13.7722 15.2641 13.7446L11.1808 9.58839C11.126 9.53264 11.0517 9.50133 10.9743 9.50133C10.8968 9.50133 10.8225 9.53264 10.7678 9.58839C10.713 9.64413 10.6822 9.71974 10.6822 9.79858C10.6822 9.87741 10.713 9.95302 10.7678 10.0088L14.3535 13.658L7.47427 13.658C3.76952 13.658 0.765937 10.6008 0.765937 6.82983C0.765937 6.7658 0.802943 2.81031 0.765936 1.0196C0.765936 1.0196 0.690726 0.945182 0.680508 0.934781C0.62581 0.879106 0.523524 0.847829 0.446169 0.847829C0.368814 0.847829 0.314385 0.934781 0.314385 0.934781L0.182602 1.0196C0.145596 2.81031 0.182603 6.7658 0.182603 6.82983C0.182603 10.9291 3.44694 14.2517 7.47427 14.2517L14.3535 14.2517L10.7678 17.9009Z"
        fill="#3D3D3D"
      />
    </g>
    <defs>
      <clipPath id="clip0_142_13244">
        <rect fill="white" height="19" width="16" />
      </clipPath>
    </defs>
  </svg>
);

export default RoundArrow;

import consts from '../../../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  shouldModalShow: false,
  shouldModalAnimate: false,
  modal: null,
  modalProps: {},
};

export const modal = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.MODAL.OPEN:
      return {
        shouldModalShow: true,
        modal: action.payload.name,
        modalProps: action.payload.modalProps,
        shouldModalAnimate: false,
      };
    case ACTIONS.MODAL.CLOSE:
      return {
        ...state,
        shouldModalShow: false,
      };
    case ACTIONS.MODAL.ANIMATE:
      return {
        ...state,
        shouldModalAnimate: true,
      };
    default:
      return state;
  }
};

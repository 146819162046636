const BorderedPlusIcon = () => (
  <svg viewBox="0 0 41.81 41.81" xmlns="http://www.w3.org/2000/svg">
    <title>Bordered Plus</title>
    <g>
      <path
        d="M22.17,22.17H33.46a1.27,1.27,0,1,0,0-2.53H22.17V8.35a1.27,1.27,0,1,0-2.53,0V19.64H8.35a1.27,1.27,0,1,0,0,2.53H19.64V33.46a1.23,1.23,0,0,0,.37.89,1.26,1.26,0,0,0,2.16-.89Z"
        fill="#9A9A9A"
        fillRule="evenodd"
      />
      <path
        d="M20.9,41.81A20.91,20.91,0,1,1,41.81,20.9,20.93,20.93,0,0,1,20.9,41.81ZM20.9,2A18.91,18.91,0,1,0,39.81,20.9,18.92,18.92,0,0,0,20.9,2Z"
        fill="#9A9A9A"
      />
    </g>
  </svg>
);

export default BorderedPlusIcon;

export const STATUSES = Object.freeze({
  REJECTED: 'rejected',
  UNAPPROVED: 'unapproved',
  PENDING: 'pending',
  APPROVED: 'approved',
});

export const APPROVAL_NOTIFICATION_TITLES = Object.freeze({
  APPROVED: 'Approved for Offer',
  APPLICATION_APPROVED: 'Offer Application Approved',
  DENIED: 'Offer Application Denied',
});

export const BUTTON_TEXT = Object.freeze({
  REQUEST_APPROVAL: 'Request Approval',
  CONTACT_US: 'Contact Us',
  PENDING: 'Pending',
  CREATE_A_NEW_ZONE: 'Create a new zone',
  REACH_OUT: 'Reach Out',
});

export const CHIP_TEXT = Object.freeze({
  [STATUSES.PENDING]: STATUSES.PENDING,
  [STATUSES.REJECTED]: STATUSES.REJECTED,
  [STATUSES.UNAPPROVED]: 'Approval Required',
  [STATUSES.APPROVED]: STATUSES.APPROVED,
});

export const BUTTON_TEXT_BY_STATUS = Object.freeze({
  [STATUSES.UNAPPROVED]: BUTTON_TEXT.REQUEST_APPROVAL,
  [STATUSES.PENDING]: BUTTON_TEXT.PENDING,
  [STATUSES.REJECTED]: BUTTON_TEXT.CONTACT_US,
  [STATUSES.APPROVED]: BUTTON_TEXT.REACH_OUT,
});

export const VAST_OUTLINK = 'https://www.iab.com/guidelines/vast/';

export const PARAGRPAHS_BY_STATUS = Object.freeze({
  [STATUSES.PENDING]: `Your request is currently pending approval. We will inform you of the outcome as soon as possible.`,
  [STATUSES.REJECTED]: `We regret to inform you that your request has been rejected at this time, but please feel free to contact us if you need any assistance or further clarification.`,
  [STATUSES.UNAPPROVED]: `To utilize this tool, kindly be advised that you must request approval and furnish a valid traffic source.`,
  [STATUSES.APPROVED]: `We're pleased to inform you that your request has been approved. You should have received your VAST Tag URL. Please don't hesitate to reach out if you need any assistance or have any further questions.`,
});

export const VAST_PREROLL_PATH = 'vast-pre-roll';

export const filterVastUrlInNotifactions = (alerts) => {
  if (!USE_VAST_PREROLL || !alerts?.length) {
    return alerts;
  }

  alerts = alerts.map((alert) => {
    const { description, title } = alert;

    const createLink = (denied) => {
      let text = 'You have been approved for the Pre-roll promo tool.';

      if (denied) {
        text = 'You have been refused for the Pre-roll promo tool.';
      }

      const link = `<a href="/${VAST_PREROLL_PATH}">${text}</a>`;

      return link;
    };

    if (
      description?.toLowerCase()?.includes('vast pre-roll') &&
      (title === APPROVAL_NOTIFICATION_TITLES.APPROVED || title === APPROVAL_NOTIFICATION_TITLES.APPLICATION_APPROVED)
    ) {
      alert.description = createLink();
    } else if (description?.toLowerCase()?.includes('vast pre-roll') && title === APPROVAL_NOTIFICATION_TITLES.DENIED) {
      alert.description = createLink(true);
    }

    return alert;
  });

  return alerts;
};

export const VAST_PROMO_METHOD_SELECT_DEFAULT_VALUE = 'Pre-rolls';

export const USE_VAST_PREROLL = process.env.REACT_APP_USE_VAST_PREROLL === 'true';

export const VAST_GTM_EVENTS = Object.freeze({
  [STATUSES.REJECTED]: 'Preroll-Rejected-Contact-Us',
  [STATUSES.UNAPPROVED]: 'Preroll-Request-Approval',
  [STATUSES.APPROVED]: 'Preroll-Approved-Reach-out',
});

const getSortString = (options) =>
  Object.prototype.hasOwnProperty.call(options, 'sorts')
    ? `(sorts:[${Object.keys(options.sorts)
        .map((key) => `{sort:"${key}",direction:${options.sorts[key]}}`)
        .join(',')}])`
    : '';

export default (options = {}) => `
    CrakRevenue {
      AchConfigurations ${getSortString(options)} {
        Data {
          id
          country_name
          currency
          transit_number {
            label
            config
            instructions
          }
          account_number {
            label
            config
            instructions
          }
        }
      }
    }
  `;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../../i18n/base-en.js';

import OfferLandingPages from '../OfferLandingPages/OfferLandingPages.react';
import { Container } from '../../../components/reusables';
import ChooseLandingIcon from '../../../components/reusables/svg/ChooseLandingIcon.react';
import Skeleton from '../../reusables/Skeleton/Skeleton';

const OfferTrackingLinkLandingPages = ({
  fields,
  isAutoOptimized,
  offerPreviewUrl,
  offerScreenshots,
  values,
  withFrame,
  isReferrals,
  cancelModalButton,
  isLandingPageModalOpen,
  performerLandingIds,
  trendingPerformers,
  offerCampaignLinkIsFetching,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [viewMode, setViewMode] = useState('desktop');

  const devices = [
    { name: 'desktop', icon: 'desktop_windows' },
    { name: 'mobile', icon: 'phone_iphone' },
  ];

  const getAvailableDevices = () => devices.filter((device) => offerScreenshots[device.name]);

  const handleViewModeChange = (e) => {
    e.preventDefault();

    const newViewMode =
      /* $("span").click() */ e.target.getAttribute('data-viewmode') ||
      /* $("i").click() */ e.target.parentNode.getAttribute('data-viewmode');

    if (viewMode !== newViewMode) {
      setViewMode(newViewMode);
    }
  };

  const availableDevices = getAvailableDevices();
  const isDirectToModel = performerLandingIds?.length > 0;

  const hideSkeleton = !offerCampaignLinkIsFetching && isLoaded ? true : false;

  return (
    <Container
      Svg={withFrame ? ChooseLandingIcon : undefined}
      className={`${withFrame ? 'z-depth-2 black-title' : ''}`}
      id="offer-tracking-link-lp"
      title={withFrame ? <FormattedMessage {...messages.offerChooseLandingPage} /> : undefined}
    >
      <Skeleton className={`${hideSkeleton ? 'hidden' : ''}`} height="630px" width="100%" />
      <div className={!hideSkeleton ? 'hidden' : ''}>
        {!withFrame && (
          <h4>
            <div className="title">
              <div className="ico">
                <ChooseLandingIcon />
              </div>
              {isDirectToModel ? (
                <FormattedMessage {...messages.offerChooseLandingPageOrPerformer} />
              ) : (
                <FormattedMessage {...messages.offerChooseLandingPage} />
              )}
            </div>
            <div className="devices-icons">
              {offerScreenshots
                ? availableDevices.map((device) => (
                    <span
                      className={`pointer device-icon ${device.name}-icon ${viewMode === device.name ? 'primary-color' : ''}`}
                      data-viewmode={device.name}
                      key={device.name}
                      onClick={handleViewModeChange}
                    >
                      <i className="material-icons">{device.icon}</i>
                    </span>
                  ))
                : null}
            </div>
          </h4>
        )}
        <OfferLandingPages
          availableDevices={availableDevices}
          cancelModalButton={cancelModalButton}
          currentUrlId={values.url_id}
          fields={fields}
          handleViewModeChange={handleViewModeChange}
          hasError={hasError}
          isAutoOptimized={isAutoOptimized}
          isDirectToModel={isDirectToModel}
          isLandingPageModalOpen={isLandingPageModalOpen}
          isLoaded={isLoaded}
          isReferrals={isReferrals}
          offerPreviewUrl={offerPreviewUrl}
          offerScreenshots={offerScreenshots}
          performerLandingIds={performerLandingIds}
          setHasError={setHasError}
          setIsLoaded={setIsLoaded}
          trendingPerformers={trendingPerformers}
          values={values}
          viewMode={viewMode}
        />
      </div>
    </Container>
  );
};

OfferTrackingLinkLandingPages.defaultProps = {
  isAutoOptimized: false,
  offerPreviewUrl: '',
  offerScreenshots: {},
  withFrame: false,
};

OfferTrackingLinkLandingPages.propTypes = {
  fields: PropTypes.object.isRequired,
  isAutoOptimized: PropTypes.bool,
  offerPreviewUrl: PropTypes.string,
  offerScreenshots: PropTypes.object,
  offerUrl: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  withFrame: PropTypes.bool,
};

export default connect((state) => ({
  offerCampaignLinkIsFetching: state.offer.offerCampaignLinkIsFetching,
}))(injectIntl(OfferTrackingLinkLandingPages));

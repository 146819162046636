import { connect } from 'react-redux';
import React from 'react';
import './styles/OfferListingNoData.scss';

const OfferListingNoData = ({ initialOffersFetched }) => {
  const text = !initialOffersFetched ? 'Loading' : 'No Data';

  return <div className="offerListingNoData">{text}</div>;
};

export default connect((state) => ({
  initialOffersFetched: state.offers.initialOffersFetched,
}))(OfferListingNoData);

import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { closeModal, animateModal, closeModalAnimation } from '../../../Modal/modal-actions/modal-actions';
import { reduxForm } from 'redux-form';
import messages from '../../../../i18n/base-en.js';
import { Gtm } from '../../../../utils/gtm';
import useClickOutside from '../../../reusables/colorpicker/useClickOutside';

import 'materialize-css';
import OfferModalTabs from './OfferModalTabs.react';
import SearchTool from './SearchTool.react';
import PerformerPageSubHeader from './PerformerPageSubHeader.react';
import LandingPageSelection from './LandingPageSelection.react';
import PerformerPageModelSelection from './PerformerPageModelSelection.react';

const OfferLandingPagesModal = ({
  dispatch,
  offerUrl,
  fields,
  offerScreenshots,
  availableDevices,
  viewMode,
  isReferrals,
  skinConfigurations,
  changeCurrentOffer,
  currentOfferUrl,
  isAutoOptimized,
  isDirectToModel,
  performerLandingIds,
  trendingPerformers,
  modalInner,
  model,
}) => {
  const [offersList, setOffersList] = useState(offerUrl);
  const [searchResult, setSearchResult] = useState();
  const [sortType, setSortType] = useState('epc_highest');
  const [modalViewMode, setModalViewMode] = useState(viewMode);
  const [checkedId, setCheckedId] = useState(currentOfferUrl.id ? currentOfferUrl.id : 'optimized');
  const [selectedLanding, setSelectedLanding] = useState('');
  const [selectedTab, setSelectedTab] = useState('landing');
  const optimizedList = offersList.filter((offer) => offer.id === 'optimized');

  const sortTypeList = {
    newest: 'id',
    oldest: 'id',
    epc_highest: 'epc_affiliation',
    epc_lowest: 'epc_affiliation',
    name_asc: 'name',
    name_desc: 'name',
  };

  const sortBy = (sortType) => {
    const sortProperty = sortTypeList[sortType];
    const sorted = [...offerUrl].sort((a, b) => {
      switch (sortType) {
        case 'name_asc':
          return b[sortProperty] ? a[sortProperty]?.replace(/\s/g, '').localeCompare(b[sortProperty].replace(/\s/g, '')) : -1;
        case 'newest':
        case 'epc_highest':
          if (Number(a[sortProperty]) === Number(b[sortProperty])) {
            return 0;
          } else if (Number(a[sortProperty]) === null) {
            return 1;
          } else if (Number(b[sortProperty]) === null) {
            return -1;
          } else {
            return Number(b[sortProperty]) > Number(a[sortProperty]) ? 1 : -1;
          }
        case 'oldest':
        case 'epc_lowest':
          if (Number(a[sortProperty]) === Number(b[sortProperty])) {
            return 0;
          } else if (Number(a[sortProperty]) === null) {
            return 1;
          } else if (Number(b[sortProperty]) === null) {
            return -1;
          } else {
            return Number(a[sortProperty]) > Number(b[sortProperty]) ? 1 : -1;
          }
        default:
          return a[sortProperty] ? b[sortProperty]?.replace(/\s/g, '').localeCompare(a[sortProperty].replace(/\s/g, '')) : 1;
      }
    });
    setOffersList(sorted);
  };

  useEffect(() => {
    sortBy(sortType);
  }, [sortType]);

  useEffect(() => {
    if (fields.searchString.value.length >= 3) {
      const filteredLandings = offerUrl.filter((landing) =>
        landing.name.toLocaleLowerCase().includes(fields.searchString.value.toLocaleLowerCase())
      );
      setOffersList(filteredLandings);
      setSearchResult(filteredLandings.length);
    } else {
      setSearchResult(isAutoOptimized ? offerUrl.length - 1 : offerUrl.length);

      sortBy(sortType);
    }
  }, [fields.searchString.value, offerUrl]);

  useEffect(() => {
    if (selectedTab === 'performer') {
      setCheckedId(performerLandingIds[0]);
    } else {
      setCheckedId(currentOfferUrl.id ?? 'optimized');
    }
  }, [selectedTab]);

  const handleViewModeChange = (device) => {
    setModalViewMode(device);
  };
  const handleSave = () => {
    if (selectedTab === 'performer') {
      model?.onChange(fields.pname.value);
    } else {
      model?.onChange('');
    }
    Gtm.event('UI Component', 'Click', `available landing page - ${selectedLanding}`);
    changeCurrentOffer(checkedId);
    dispatch(animateModal());
    setTimeout(() => {
      dispatch(closeModal());
    }, 500);
  };

  const handleCheckId = (id) => {
    setCheckedId(id);
  };

  const handleSelectLanding = (name) => {
    setSelectedLanding(name);
  };

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div className="available-landing-modal" id="screenshot-modal">
      <div className="modal-content">
        <div className={`modal-box ${selectedTab === 'performer' && 'performer-modal-box'}`}>
          <div className="modal-header">
            <div className="sub-header">
              <h4 className="primary-color">
                {isDirectToModel ? (
                  <FormattedMessage {...messages.offerAvailablePages} />
                ) : (
                  <FormattedMessage {...messages.offerAvailableLandingPage} />
                )}
              </h4>
              {isDirectToModel ? <OfferModalTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> : null}
              {selectedTab === 'landing' ? (
                <SearchTool
                  availableDevices={availableDevices}
                  handleViewModeChange={handleViewModeChange}
                  modalViewMode={modalViewMode}
                  offerScreenshots={offerScreenshots}
                  searchResult={searchResult}
                  searchString={fields.searchString}
                  setSortType={setSortType}
                  sortType={sortType}
                />
              ) : (
                <PerformerPageSubHeader />
              )}
            </div>
            {selectedTab === 'landing' ? (
              <LandingPageSelection
                checkedId={checkedId}
                handleCheckId={handleCheckId}
                handleSelectLanding={handleSelectLanding}
                isReferrals={isReferrals}
                modalViewMode={modalViewMode}
                offersList={offersList}
                optimizedList={optimizedList}
                searchResult={searchResult}
                skinConfigurations={skinConfigurations}
              />
            ) : (
              <PerformerPageModelSelection
                dispatch={dispatch}
                handleSave={handleSave}
                pname={fields.pname}
                trendingPerformers={trendingPerformers}
              />
            )}
          </div>
        </div>
      </div>
      {selectedTab === 'landing' && (
        <div className="modal-footer">
          <button
            className="waves-effect btn-large btn-flat cancel-button primary-border-color"
            data-cy="landing-page-cancel"
            onClick={closeOpenModal}
          >
            <FormattedMessage {...messages.genericTextCancel} />
          </button>
          <button
            className="waves-effect waves-light btn"
            data-cy="landing-page-save"
            disabled={searchResult === 0}
            onClick={() => handleSave()}
          >
            save
          </button>
        </div>
      )}
    </div>
  );
};
export default reduxForm({
  form: 'OfferModalSearch',
  fields: ['searchString', 'pname'],
})(OfferLandingPagesModal);

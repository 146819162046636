const RightBrokenBar = ({ className }) => (
  <svg className={`broken-bar ${className}`} viewBox="0 0 73 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2568 23.5713L0.757356 11.8716L10.2337 0.5L72.4995 0.5V47.5H20.2337L10.6504 36L20.3836 24.3201L20.7574 23.8716L20.2568 23.5713Z"
      fill="#01262E"
      stroke="#00BD00"
      strokeWidth="3px"
    />
  </svg>
);

export default RightBrokenBar;

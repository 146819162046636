import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

import OfferAdditionalSingleInfo from '../OfferAdditionalSingleInfo/OfferAdditionalSingleInfo.react';
import { Container } from '../../../reusables';
import ExclamationMarkIcon from '../../../reusables/svg/ExclamationMarkIcon.react';

const isDisplayable = (item) => !!item && Array.isArray(item) && item.length > 0;
const hasDisplayableItems = (props) => Object.values(props).some((value) => isDisplayable(value));

const OfferAdditionalInfo = (props) => {
  if (hasDisplayableItems(props)) {
    const { offerTargetAudience, offerTopPromotionalMethods, offerChannels, offerOS, offerVerticals } = props;

    return (
      <Container
        Svg={ExclamationMarkIcon}
        className="z-depth-2 black-title"
        id="offer-additionnal-info"
        title={<FormattedMessage {...messages.offerAdditionalInfo} />}
      >
        <div className="infos">
          {isDisplayable(offerTargetAudience) && (
            <OfferAdditionalSingleInfo labelKey="offerTargetAudience" values={offerTargetAudience} />
          )}
          {isDisplayable(offerTopPromotionalMethods) && (
            <OfferAdditionalSingleInfo labelKey="offerTopPromotionalMethods" values={offerTopPromotionalMethods} />
          )}
          {isDisplayable(offerChannels) && (
            <OfferAdditionalSingleInfo labelKey="offerChannels" values={offerChannels.map((channel) => channel.name)} />
          )}
          {isDisplayable(offerOS) && <OfferAdditionalSingleInfo labelKey="offerOS" values={offerOS.map((os) => os.name)} />}
          {isDisplayable(offerVerticals) && (
            <OfferAdditionalSingleInfo labelKey="offerVerticals" values={offerVerticals.map((vertical) => vertical.name)} />
          )}
        </div>
      </Container>
    );
  }

  return null;
};

OfferAdditionalInfo.propTypes = {
  offerChannels: PropTypes.array,
  offerOS: PropTypes.array,
  offerTargetAudience: PropTypes.array,
  offerTopPromotionalMethods: PropTypes.array,
  offerVerticals: PropTypes.array,
};

export default OfferAdditionalInfo;

import request from '../utils/Req';
import { getCookie } from '../utils/helpers';
import config from '../config';
import consts from '../utils/consts';
import { setActionMessage } from '../components/Application/actions/application-actions';
import { fetchProfile } from '../actions/profile-actions';

const { API_URL } = config;
const { ACTIONS } = consts;

const createCompany = (data) => ({
  type: ACTIONS.AFFILIATE.CREATE_COMPANY,
});

const updateCompany = () => ({
  type: ACTIONS.AFFILIATE.UPDATE_COMPANY,
});

export const postCreateCompany = (data) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/affiliate/company`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        affiliate_id: data.affId,
        name: data.name,
        registration_country_code: data.registration_country_code,
        registration_number: data.registration_number,
        registration_subdivision_code: data.registration_subdivision_code || null,
        tax_id_gst_hst: data.tax_id_gst_hst || null,
        tax_id_pst: data.tax_id_pst || null,
      })
      .end((err, res) => {
        if (err || res.body.errorMessage) {
          console.error(err);
          console.error(res.body.errorMessage);
        } else {
          dispatch(createCompany(res.body));
          dispatch(fetchProfile());
          fulfill();
        }
      })
  );

export const putUpdateCompany = (data) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .put(`${API_URL}/affiliate/company`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        affiliate_id: data.affId,
        name: data.name,
        registration_country_code: data.registration_country_code,
        registration_number: data.registration_number,
        registration_subdivision_code: data.registration_subdivision_code,
        tax_id_gst_hst: data.tax_id_gst_hst,
        tax_id_pst: data.tax_id_pst,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(updateCompany(res.body));
          dispatch(
            setActionMessage('success', {
              text: 'Your company info has succesfully been saved',
            })
          );
          fulfill();
        } else {
          console.log(err);
        }
        dispatch(fetchProfile());
      })
  );

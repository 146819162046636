import {
  createTrendingCountries,
  createTrendingDays,
  createTrendingFormats,
  createTrendingSizes,
} from '../components/reusables/creatives/banners/values';

export const createTrendingBannerData = ({ creatives, trending }) => {
  const banners = [];
  let countries = [];
  let sizes = [];
  let daysArray = [];
  let formats = [];

  trending.forEach((banner) => {
    const { countryCode, fileName, impressionNumber, stars, days } = banner;

    creatives.forEach((hoBanner) => {
      const { url, width, height, format } = hoBanner;

      let bannerName = url?.split('/');
      bannerName = bannerName?.[bannerName.length - 1];

      if (bannerName === fileName) {
        const size = `${width}x${height}`;

        //PUSH MATCHING HO BANNER
        banners.push({
          ...hoBanner,
          rating: stars,
          impressionNumber,
          countryCode,
          size,
          days,
        });

        countries.push(countryCode);
        daysArray.push(days);
        sizes.push(size);
        formats.push(format);
      }
    });
  });

  countries = createTrendingCountries([...new Set(countries)]);
  daysArray = createTrendingDays([...new Set(daysArray)]);
  sizes = createTrendingSizes([...new Set(sizes)]);
  formats = createTrendingFormats([...new Set(formats)]);

  return { banners, countries, sizes, days: daysArray, formats };
};

const Badge5 = () => (
  <svg className="badge-5" fill="none" height="71" viewBox="0 0 69 71" width="69" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_277)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path d="M6.9 5.91L0 51.58L25.81 70.69L56.98 62.94L68.12 18.12L39.91 0L6.9 5.91Z" fill="url(#paint0_linear_792_277)" />
        <g opacity="0.5">
          <path
            d="M39.83 0.4L67.69 18.29L56.67 62.63L25.9 70.28L0.41 51.41L7.24 6.24L39.83 0.4ZM39.91 0L6.9 5.91L0 51.58L25.81 70.69L56.98 62.94L68.12 18.12L39.91 0Z"
            fill="#253829"
          />
        </g>
        <path
          d="M13.47 14.57L10.19 46.42L28.02 59.62L49.55 54.26L57.62 23.38L37.76 10.78L13.47 14.57Z"
          fill="url(#paint1_linear_792_277)"
          opacity="0.45"
        />
        <path d="M11.99 29.76L10.19 46.42L28.02 59.62L49.55 54.26L55.76 30.75L11.99 29.76Z" fill="url(#paint2_linear_792_277)" />
        <g opacity="0.5" style={{ mixBlendModee: 'soft-light' }}>
          <path d="M6.89999 5.91L13.47 14.57L37.76 10.78L39.91 0L6.89999 5.91Z" fill="#253829" />
        </g>
        <path d="M10.19 46.42L0 51.58L25.81 70.69L28.02 59.62L10.19 46.42Z" fill="url(#paint3_linear_792_277)" opacity="0.45" />
        <path
          d="M28.02 59.62L25.81 70.69L56.98 62.94L49.55 54.26L28.02 59.62Z"
          fill="url(#paint4_linear_792_277)"
          opacity="0.45"
        />
        <g opacity="0.5" style={{ mixBlendModee: 'soft-light' }}>
          <path
            d="M37.76 10.78L57.62 23.37L49.55 54.26L28.02 59.61L10.19 46.41L13.47 14.57L37.76 10.78ZM37.84 10.39L37.7 10.41L13.41 14.2L13.12 14.25L13.09 14.54L9.81001 46.38L9.79001 46.6L9.96001 46.73L27.79 59.93L27.93 60.04L28.1 60L49.63 54.65L49.85 54.6L49.91 54.38L57.98 23.49L58.05 23.22L57.82 23.07L37.96 10.48L37.84 10.4V10.39Z"
            fill="#253829"
          />
        </g>
        <path
          d="M11.99 29.76L11.06 38.33L53.76 38.35L55.76 30.75L11.99 29.76Z"
          fill="url(#paint5_linear_792_277)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M43.5991 51.738C52.0486 46.4171 54.5848 35.2539 49.2639 26.8044C43.943 18.3549 32.7798 15.8187 24.3303 21.1396C15.8808 26.4606 13.3446 37.6237 18.6656 46.0732C23.9865 54.5227 35.1496 57.0589 43.5991 51.738Z"
            fill="black"
          />
        </g>
        <path
          d="M43.0133 48.2354C51.4628 42.9144 53.999 31.7513 48.6781 23.3018C43.3571 14.8523 32.194 12.3161 23.7445 17.637C15.295 22.958 12.7588 34.1211 18.0797 42.5706C23.4006 51.0201 34.5638 53.5563 43.0133 48.2354Z"
          fill="#355538"
        />
        <path
          d="M37.07 15.23C27.29 13.19 17.71 19.46 15.67 29.24C13.97 37.4 18.06 45.42 25.15 49.04C24.06 47.54 23.25 45.83 22.76 44.02C19.07 40.48 17.23 35.17 18.35 29.8C20.08 21.5 28.21 16.17 36.52 17.91C42.42 19.14 46.82 23.61 48.24 29.07C49.58 30.37 50.67 31.91 51.45 33.59C51.77 24.94 45.83 17.06 37.07 15.23Z"
          fill="url(#paint6_linear_792_277)"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M22.55 36.9C24.28 28.62 32.39 23.3 40.68 25.03C41.52 25.2 42.32 25.45 43.09 25.75C41.39 23.45 38.87 21.74 35.85 21.11C29.32 19.75 22.92 23.94 21.56 30.47C21.02 33.08 21.36 35.67 22.38 37.92C22.43 37.58 22.48 37.24 22.55 36.9Z"
          fill="url(#paint7_linear_792_277)"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M36.38 18.58C28.45 16.93 20.68 22.01 19.03 29.94C17.38 37.87 22.46 45.64 30.39 47.29C38.32 48.94 46.09 43.86 47.74 35.93C49.39 28 44.31 20.23 36.38 18.58ZM30.69 45.81C23.58 44.33 19.01 37.36 20.5 30.24C21.98 23.13 28.95 18.56 36.07 20.05C43.18 21.53 47.75 28.5 46.26 35.62C44.78 42.73 37.81 47.3 30.69 45.81Z"
          fill="#355538"
        />
        <path
          d="M26.47 23.18C26.58 22.53 34.07 19.21 34.07 19.21C34.07 19.21 26.87 22.16 25.89 21.78C24.91 21.4 22.06 14.24 22.06 14.24C22.06 14.24 24.99 21.38 24.69 22.27C24.39 23.16 17.09 26.24 17.09 26.24C17.09 26.24 24.51 23.43 25.27 23.67C26.02 23.91 29.1 31.21 29.1 31.21C29.1 31.21 26.36 23.83 26.47 23.18Z"
          fill="#436E47"
        />
        <path
          d="M41.93 30.32C41.76 30.3 38.1 30.29 33.42 31.18C30.9 31.66 27.4 31.26 28.37 28.52C28.85 28.36 31.1 30.15 31.18 27.75C30.67 27.91 29.11 27.09 28.53 27.83C28.39 26.94 26.67 26.29 26.21 25.58C25.95 27.04 26.8 27.9 27.78 28.21C23.95 30.62 26.15 35.45 29.59 37.06C29.77 37.33 29.3 37.98 28.58 38.3C30.25 38.84 31.8 37.95 32.65 37.67C33.13 38.06 34.02 38.56 35.16 38.76C34.99 38.42 34.89 37.9 34.92 37.41C36.01 37.27 37.02 37.09 37.37 36.98C40.66 35.97 43.59 34.35 41.93 30.32Z"
          fill="#355538"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_277"
        x1="38.3921"
        x2="23.802"
        y1="7.56545"
        y2="80.7146"
      >
        <stop stopColor="#243626" />
        <stop offset="0.52" stopColor="#191E1B" />
        <stop offset="1" stopColor="#151B1B" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_792_277"
        x1="36.149"
        x2="26.0715"
        y1="15.8977"
        y2="66.4225"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_277"
        x1="33.903"
        x2="27.0745"
        y1="30.0721"
        y2="64.3078"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_277"
        x1="-0.657374"
        x2="27.8315"
        y1="54.8302"
        y2="60.5125"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_792_277"
        x1="27.8315"
        x2="56.3203"
        y1="60.5125"
        y2="66.1948"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_792_277"
        x1="33.611"
        x2="33.2034"
        y1="30.4626"
        y2="38.6408"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_792_277"
        x1="15.7088"
        x2="50.9447"
        y1="29.0638"
        y2="36.0918"
      >
        <stop stopColor="#C1C7C8" />
        <stop offset="1" stopColor="#25373D" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_792_277"
        x1="22.0193"
        x2="41.8291"
        y1="28.0995"
        y2="32.0507"
      >
        <stop stopColor="#C1C7C8" />
        <stop offset="1" stopColor="#25373D" stopOpacity="0.2" />
      </linearGradient>
      <clipPath id="clip0_792_277">
        <rect fill="white" height="70.69" width="68.12" />
      </clipPath>
    </defs>
  </svg>
);

export default Badge5;

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { genericTextPx, LiveCamWidgetPage } = messages;

const LayoutSettingsWidgetSize = ({ fields: { iframeHeight, iframeSizes, iframeWidth } }) => {
  const disabled = iframeSizes.value !== 'custom';

  return (
    <div className="settings-group">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.layoutSettings.widgetSizeSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={iframeSizes}
          id="widget-iframe-dimensions-auto"
          label={LiveCamWidgetPage.fields.genericChoices.auto}
          value="auto"
        />
        <Radio
          field={iframeSizes}
          id="widget-iframe-dimensions-custom"
          label={LiveCamWidgetPage.fields.genericChoices.custom}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `widget size custom`);
          }}
          value="custom"
        />
      </div>

      <div>
        <div className={`with-units${disabled ? ' disabled' : ''}`}>
          <InputText
            disabled={disabled}
            field={iframeWidth}
            id="widget-iframe-width"
            label={LiveCamWidgetPage.layoutSettings.widgetSizeSection.widthField.label}
            labelClasses="active"
            max={9999}
            min={1}
            type="number"
          />
          <span className="unit-label">
            <FormattedMessage {...genericTextPx} />
          </span>
        </div>
      </div>

      <div>
        <div className={`with-units${disabled ? ' disabled' : ''}`}>
          <InputText
            disabled={disabled}
            field={iframeHeight}
            id="widget-iframe-height"
            label={LiveCamWidgetPage.layoutSettings.widgetSizeSection.heightField.label}
            labelClasses="active"
            max={9999}
            min={1}
            type="number"
          />
          <span className="unit-label">
            <FormattedMessage {...genericTextPx} />
          </span>
        </div>
      </div>
    </div>
  );
};

LayoutSettingsWidgetSize.propTypes = {
  fields: PropTypes.shape({
    iframeHeight: PropTypes.object,
    iframeSizes: PropTypes.object,
    iframeWidth: PropTypes.object,
  }),
};

export default injectIntl(LayoutSettingsWidgetSize);

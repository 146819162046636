import consts from '../../../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  offersIsFetching: false,
  offers: [],
};

export const jerkmateReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.JERKMATE_OFFERS.REQUEST_OFFERS:
      return {
        ...state,
        offersIsFetching: true,
      };

    case ACTIONS.JERKMATE_OFFERS.RECEIVE_OFFERS:
      return {
        ...state,
        offers: action.offers,
        offersIsFetching: false,
      };

    case ACTIONS.JERKMATE_OFFERS.FAILED_TO_RECEIVE_OFFERS:
      return {
        ...state,
        offersIsFetching: false,
      };

    default:
      return state;
  }
};

import { arrayShuffle } from '../utils/helpers';

import consts from '../utils/consts';

const {
  TOP_OFFERS_OPTIONS,
  ACTIONS: { TOPOFFERS },
} = consts;

const initialState = {
  top: {
    search: consts.OFFERS_SEARCH_INITALSTATE,
    offersIsFetching: false,
    offersCurrentRequest: null,
    homeOffers: [],
    offers: [],
    offersReceivedAt: null,
    offersInfos: {
      currentPage: 1,
      total: 0,
      pageCount: 0,
      numberPerPage: TOP_OFFERS_OPTIONS.topNumberPerPage,
    },
  },
  recent: {
    search: consts.OFFERS_SEARCH_INITALSTATE,
    offersIsFetching: false,
    offersCurrentRequest: null,
    offers: [],
    offersReceivedAt: null,
    offersInfos: {
      currentPage: 1,
      total: 0,
      pageCount: 0,
      numberPerPage: null,
    },
  },
  mfc: {
    accessToAtLeastOneOffer: false,
    search: consts.OFFERS_SEARCH_INITALSTATE,
    offersIsFetching: false,
    offersCurrentRequest: null,
    offers: [],
    offersReceivedAt: null,
    offersInfos: {
      currentPage: 1,
      total: 0,
      pageCount: 0,
      numberPerPage: TOP_OFFERS_OPTIONS.mfcNumberPerPage,
    },
  },
};

export const topoffers = (state = initialState, action = {}) => {
  const { mfc, recent, top } = state;
  const topOffersInfos = top.offersInfos;
  const mfcOffersInfos = mfc.offersInfos;

  switch (action.type) {
    case TOPOFFERS.TOP.REQUEST_OFFERS:
      if (!!top.offersCurrentRequest && Object.prototype.hasOwnProperty.call(top.offersCurrentRequest, 'abort')) {
        top.offersCurrentRequest.abort();
      }
      return {
        ...state,
        top: {
          ...top,
          offersIsFetching: true,
          offersCurrentRequest: action.request,
        },
      };

    case TOPOFFERS.TOP.RECEIVE_OFFERS:
      return {
        ...state,
        top: {
          ...top,
          search: action.payload.Search,
          offers: action.payload.Offers ? action.payload.Offers.Data : [],
          offersReceivedAt: new Date().getTime(),
          offersIsFetching: false,
          offersCurrentRequest: null,
          homeOffers: action.payload.Offers
            ? state.top.homeOffers.length === 0
              ? arrayShuffle(action.payload.Offers.Data).slice(0, 8)
              : state.top.homeOffers
            : [],
          offersInfos: {
            ...topOffersInfos,
            total: action.payload.Offers ? action.payload.Offers.Data.length : 0,
            pageCount: action.payload.Offers
              ? Math.ceil(action.payload.Offers.Data.length / TOP_OFFERS_OPTIONS.topNumberPerPage)
              : 0,
            currentPage: action.payload.Offers ? action.payload.Offers.PageInfo.currentPage + 1 : 0,
          },
        },
      };

    case TOPOFFERS.TOP.FAILED_TO_RECEIVE_OFFERS:
      return {
        ...state,
        top: {
          ...top,
          offersIsFetching: false,
        },
      };

    case TOPOFFERS.RECENT.REQUEST_OFFERS:
      if (!!recent.offersCurrentRequest && Object.prototype.hasOwnProperty.call(recent.offersCurrentRequest, 'abort')) {
        recent.offersCurrentRequest.abort();
      }
      return {
        ...state,
        recent: {
          ...recent,
          offersIsFetching: true,
          offersCurrentRequest: action.request,
        },
      };

    case TOPOFFERS.RECENT.RECEIVE_OFFERS:
      return {
        ...state,
        recent: {
          ...recent,
          search: action.payload.Search,
          offers: action.payload.Offers ? action.payload.Offers.Data : [],
          offersReceivedAt: new Date().getTime(),
          offersIsFetching: false,
          offersCurrentRequest: null,
          offersInfos: {
            total: action.payload.Offers
              ? action.payload.Offers.PageInfo.length <= 25
                ? action.payload.Offers.PageInfo.length
                : 25
              : 25,
            pageCount: action.payload.Offers
              ? Math.ceil(action.payload.Offers.Data.length / TOP_OFFERS_OPTIONS.recentNumberPerPage)
              : 0,
            currentPage: action.payload.Offers ? action.payload.Offers.PageInfo.currentPage + 1 : 0,
          },
        },
      };

    case TOPOFFERS.RECENT.FAILED_TO_RECEIVE_OFFERS:
      return {
        ...state,
        recent: {
          ...recent,
          offersIsFetching: false,
        },
      };

    case TOPOFFERS.TOP.CHANGE_PAGE:
      return {
        ...state,
        top: {
          ...top,
          offersInfos: {
            ...topOffersInfos,
            currentPage: action.newPage,
          },
        },
      };
    case TOPOFFERS.MFC.CHANGE_PAGE:
      return {
        ...state,
        mfc: {
          ...mfc,
          offersInfos: {
            ...mfcOffersInfos,
            currentPage: action.newPage,
          },
        },
      };

    case TOPOFFERS.MFC.REQUEST_OFFERS:
      if (!!mfc.offersCurrentRequest && Object.prototype.hasOwnProperty.call(mfc.offersCurrentRequest, 'abort')) {
        mfc.offersCurrentRequest.abort();
      }
      return {
        ...state,
        mfc: {
          ...mfc,
          accessToAtLeastOneOffer: false,
          offersIsFetching: true,
          offersCurrentRequest: action.request,
        },
      };

    case TOPOFFERS.MFC.RECEIVE_OFFERS:
      return {
        ...state,
        mfc: {
          ...mfc,
          accessToAtLeastOneOffer: action.payload.Offers
            ? action.payload.Offers.Data.some((offer) => offer.approval_status === 'approved')
            : [],
          search: action.payload.Search,
          offers: action.payload.Offers ? action.payload.Offers.Data : [],
          offersReceivedAt: new Date().getTime(),
          offersIsFetching: false,
          offersCurrentRequest: null,
          offersInfos: {
            ...mfcOffersInfos,
            total: action.payload.Offers.Data.length,
            pageCount: Math.ceil(action.payload.Offers.Data.length / TOP_OFFERS_OPTIONS.mfcNumberPerPage),
            currentPage: action.payload.Offers.PageInfo.currentPage + 1,
          },
        },
      };

    case TOPOFFERS.MFC.FAILED_TO_RECEIVE_OFFERS:
      return {
        ...state,
        mfc: {
          ...mfc,
          offersIsFetching: false,
        },
      };

    default:
      return state;
  }
};

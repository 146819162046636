import { FormattedMessage } from 'react-intl';
import { closeModalAnimation } from '../../../../components/Modal/modal-actions/modal-actions';
import messages from '../../../../i18n/base-en.js';
import { connect } from 'react-redux';
import useClickOutside from '../../../../components/reusables/colorpicker/useClickOutside';

const BillingChangeModal = ({ dispatch, modalInner }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div className="billing-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div>
        <h2 className="primary-color" id="warn-message">
          <FormattedMessage {...messages.genericTextWarning} />
        </h2>
        <hr />
        <p>
          <FormattedMessage {...messages.profileWarningBillingChange} />
        </p>
        <button className="waves-effect waves-light btn" id="btn-billing-change" onClick={closeOpenModal}>
          <FormattedMessage {...messages.genericTextIGotIt} />
        </button>
      </div>
    </div>
  );
};
export default connect(() => ({}))(BillingChangeModal);

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

import { Container } from '../../../reusables';

const isValid = ({ offerConversionFlow }) => !!offerConversionFlow && offerConversionFlow.length > 0;

const getGenericTitleMessage = ({ offerConversionFlow }) =>
  isValid({ offerConversionFlow }) ? { ...messages.offerConversionFlowTitle } : { ...messages.offerDefaultConversionFlowTitle };

const getFlowMessage = ({ offerConversionFlow }) =>
  isValid({ offerConversionFlow })
    ? offerConversionFlow
    : messages.offerDefaultConversionFlow.map((step) => <FormattedMessage key={step.id} {...step} />);

const OfferConversionFlow = ({ offerConversionFlow, offerConversionTitle }) => (
  <Container
    className="z-depth-2 black-title"
    icon="swap_horiz"
    id="offer-conversion-flow"
    title={
      offerConversionTitle ? (
        <span>{offerConversionTitle}</span>
      ) : (
        <FormattedMessage {...getGenericTitleMessage({ offerConversionFlow })} />
      )
    }
  >
    <ol>
      {getFlowMessage({ offerConversionFlow }).map((step, i) => (
        <li key={`step-${i + 1}`}>
          <span className="step-number primary-color">{i + 1}</span>
          {step}
        </li>
      ))}
    </ol>
  </Container>
);

OfferConversionFlow.propTypes = {
  offerConversionFlow: PropTypes.array.isRequired,
  offerConversionTitle: PropTypes.string.isRequired,
};

export default OfferConversionFlow;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { FormattedMessage, injectIntl, intlShape, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../i18n/base-en.js';

import { Info, LineChart, Radio, Scroller } from '../../components/reusables';

import { getCurrentPeriod } from '../../utils/helpers';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { Gtm } from '../../utils/gtm';
import { fetchDailyStatistics } from '../../components/Home/actions/home-actions';
import Skeleton from '../../components/reusables/Skeleton/Skeleton';
import Tooltip from '../../components/reusables/Tooltip.react';

const HomeDailyStatistics = ({
  dailyStatistics,
  dailyStatisticsTotals,
  dailyStatisticsIsFetching,
  fields,
  intl,
  resize,
  values,
  skinConfigurations,
  dispatch,
  paymentTerm,
}) => {
  const [dateRange, setDateRange] = useState('today');
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (!dailyStatisticsIsFetching && isFirstLoad) {
      setIsFirstLoad(false);
    }
  }, [dailyStatisticsIsFetching, isFirstLoad]);

  const thisPeriodTime = getCurrentPeriod(undefined, paymentTerm);
  const timeObj = {
    today: {
      start: moment().startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    },
    yesterday: {
      start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    thisPeriod: {
      start: thisPeriodTime.dateStart,
      end: thisPeriodTime.dateEnd,
    },
  };
  const skin = new SkinConfigurationsUtils(skinConfigurations);
  const graphFields = [`stat.${values.stat}`];
  const graphValues = [];

  let titlesArray = [];
  let firstField = null;

  const stats = ['payout', 'clicks', 'conversions', 'erpc'];
  const changeDateRange = (dateRange) => {
    setDateRange(dateRange);

    dispatch(fetchDailyStatistics(timeObj[dateRange]));
  };

  const getHomeYourDailyStatsText = () =>
    skin.getPropertyValue('HomeDailyStatistic', 'yourDailyStats', 'text') ||
    'No data yet! Choose an offer and start sending traffic to earn money.';

  const getHomeDailyStatsVisibility = () => {
    const SKIN_COMPONENT_NAME = 'Dashboard';
    return {
      homeDailyStatisticsInfoIcon: skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeDailyStatisticsInfoIcon'),
    };
  };

  const IS_VISIBLE = getHomeDailyStatsVisibility();
  if (dailyStatistics.length > 0) {
    if ('hour' in dailyStatistics[0].Stat) {
      titlesArray = ['Stat.hour', `Stat.${values.stat}`];
      firstField = 'hour';
    } else if ('date' in dailyStatistics[0].Stat) {
      titlesArray = ['Stat.date', `Stat.${values.stat}`];
      firstField = 'date';
    }
  }

  dailyStatistics.map((dailyStatistic, index) => {
    graphValues[index] = [dailyStatistic.Stat[firstField], dailyStatistic.Stat[values.stat]];
  });

  graphValues.reverse();
  graphValues.unshift(titlesArray);

  const statisticsGraphData = { titlesArray, graphValues };

  const getMinimumFractionDigit = (statTitle) => {
    switch (statTitle) {
      case 'erpc':
        return 4;
      case 'clicks':
      case 'conversions':
        return 0;
      default:
        return 2;
    }
  };

  return (
    <div id="home-daily-statistics">
      <div className={`row home-daily-statistics-container ${isFirstLoad ? 'first-load' : ''}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xlg-4">
          <div className="container-date link-tabs">
            <Scroller>
              <ul className="br-primary-color">
                {dailyStatisticsIsFetching && isFirstLoad ? (
                  <li>
                    <Skeleton height="20px" width="50px" />
                  </li>
                ) : (
                  <li
                    className={dateRange === 'today' ? 'active' : ''}
                    onClick={() => {
                      changeDateRange('today');
                      Gtm.event('dashboard', 'Click', 'recent statistics - today');
                    }}
                  >
                    <span>Today</span>
                  </li>
                )}
                {dailyStatisticsIsFetching && isFirstLoad ? (
                  <li>
                    <Skeleton height="20px" width="50px" />
                  </li>
                ) : (
                  <li
                    className={dateRange === 'yesterday' ? 'active' : ''}
                    onClick={() => {
                      changeDateRange('yesterday');
                      Gtm.event('dashboard', 'Click', 'recent statistics - yesterday');
                    }}
                  >
                    <span>Yesterday</span>
                  </li>
                )}
                {dailyStatisticsIsFetching && isFirstLoad ? (
                  <li>
                    <Skeleton height="20px" width="50px" />
                  </li>
                ) : (
                  <li
                    className={dateRange === 'thisPeriod' ? 'active' : ''}
                    onClick={() => {
                      changeDateRange('thisPeriod');
                      Gtm.event('dashboard', 'Click', 'recent statistics - this period');
                    }}
                  >
                    <span>This period</span>
                  </li>
                )}
              </ul>
            </Scroller>
          </div>
          {dailyStatisticsIsFetching && isFirstLoad ? (
            <Skeleton className="left-side" height="185px" width="100%" />
          ) : statisticsGraphData.graphValues.length > 1 ? (
            <form className="data-form">
              {typeof dailyStatisticsTotals !== 'undefined' &&
                stats.map((stat, index) => {
                  const minimumFractionDigits = getMinimumFractionDigit(stat);
                  const pcClasse = stat === values.stat ? 'primary-color' : '';
                  return (
                    <div className={`stat-selector clearfix ${stat === values.stat ? 'active' : ''}`} key={`statmenu-${index}`}>
                      <div className={`visible-xlg visible-lg ${pcClasse}`}>
                        <div className="pull-left">
                          <Radio
                            className="filterInput"
                            field={fields.stat}
                            id={stat}
                            label={messages.statisticsColumns[`Stat.${stat}`]}
                            labelClassNames={[pcClasse]}
                            onClick={() => {
                              Gtm.event('dashboard', 'Click', `recent statistics - ${stat === 'erpc' ? 'epc' : stat}`);
                            }}
                            value={stat}
                            withGap
                          />
                          {stat === 'erpc' && IS_VISIBLE.homeDailyStatisticsInfoIcon ? (
                            <Tooltip
                              closeIcon
                              content={
                                <p>
                                  EPC stands for <strong>Earnings Per Click</strong>{' '}
                                  <a
                                    href="https://support.crakrevenue.com/knowledge-base/epc-definition/"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Learn more
                                  </a>
                                </p>
                              }
                              position="right"
                              wrapperClassName="info"
                            >
                              <Info className="more-info" />
                            </Tooltip>
                          ) : null}
                        </div>
                        <div className="pull-right">
                          {(stat === 'erpc' || stat === 'payout') && '$'}
                          <FormattedNumber
                            minimumFractionDigits={minimumFractionDigits}
                            value={dailyStatisticsTotals.Stat[stat]}
                          />
                        </div>
                      </div>
                      <div className="visible-xs visible-sm visible-md">
                        <div className="pull-left">
                          <FormattedMessage {...messages.statisticsColumns[`Stat.${stat}`]} />
                          {stat === 'erpc' && IS_VISIBLE.homeDailyStatisticsInfoIcon ? (
                            <Tooltip
                              closeIcon
                              content={
                                <p>
                                  EPC stands for <strong>Earnings Per Click</strong>{' '}
                                  <a
                                    href="https://support.crakrevenue.com/knowledge-base/epc-definition/"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Learn more
                                  </a>
                                </p>
                              }
                              position="right"
                              wrapperClassName="info"
                            >
                              <Info className="more-info" />
                            </Tooltip>
                          ) : null}
                        </div>
                        <div className="pull-right">
                          {(stat === 'erpc' || stat === 'payout') && '$'}
                          <FormattedNumber
                            minimumFractionDigits={minimumFractionDigits}
                            value={dailyStatisticsTotals.Stat[stat]}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              <Link className="more-stats visible-xs visible-sm visible-md" onlyActiveOnIndex={false} to="statistics">
                More Statistics
              </Link>
            </form>
          ) : (
            <div className="big-icon">
              <i className="material-icons">&#xE922;</i>
            </div>
          )}
        </div>
        {dailyStatisticsIsFetching && isFirstLoad ? (
          <div className="hidden-xs hidden-sm hidden-md col-lg-8 col-xlg-8 chart-container">
            <Skeleton height="250px" width="100%" />
          </div>
        ) : statisticsGraphData.graphValues.length > 1 ? (
          <div className="hidden-xs hidden-sm hidden-md col-lg-8 col-xlg-8 chart-container">
            <div className="title">
              <FormattedMessage
                {...messages.homeRecentStatBy}
                values={{
                  stat: intl.formatMessage(messages.statisticsColumns[`Stat.${values.stat}`]).toLowerCase(),
                  field: firstField,
                }}
              />
              <Link
                className="more-stats hidden-xs hidden-sm"
                onClick={() => {
                  Gtm.event('dashboard', 'Click', `more statistics`);
                }}
                onlyActiveOnIndex={false}
                to="statistics"
              >
                More Statistics
              </Link>
            </div>
            <LineChart graphFields={graphFields} resize={resize} statisticsGraphData={statisticsGraphData} />
          </div>
        ) : (
          <div className="no-stats col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xlg-8">
            <p>{getHomeYourDailyStatsText()}</p>
            <Link className="btn" onlyActiveOnIndex={false} to="offers">
              <FormattedMessage {...messages.homeStartPromoting} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

HomeDailyStatistics.propTypes = {
  dailyStatistics: PropTypes.array,
  dailyStatisticsTotals: PropTypes.object,
  fields: PropTypes.object.isRequired,
  handleNewDateRangeStatistics: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  paymentTerm: PropTypes.string,
  resize: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default connect((state) => {
  const { skinConfigurations } = state;

  return {
    skinConfigurations: skinConfigurations.data,
  };
})(
  injectIntl(
    reduxForm(
      {
        form: 'homeDailyStatistics',
        fields: ['stat'],
      },
      () => ({
        initialValues: {
          stat: 'payout',
        },
      })
    )(HomeDailyStatistics)
  )
);

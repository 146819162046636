import PropTypes from 'prop-types';

import { Gtm } from '../../utils/gtm';

const toggleInfo = (container) => container.classList.toggle('info-active');
const HomePromotionSlide = ({ content, direction, active, next, transition, index }) => {
  let container = null;

  return (
    <div
      className={`promotion-container ${direction} ${transition ? 'transition' : ''} ${
        next === index ? 'next' : active === index ? 'active' : ''
      }`}
      ref={(theContainer) => {
        container = theContainer;
      }}
      style={
        content.image
          ? {
              backgroundImage: `url(${content.image})`,
            }
          : {}
      }
    >
      {content.content ? (
        <>
          <button className="info" onClick={() => toggleInfo(container)} type="button">
            <i className="material-icons">info</i>Info
          </button>

          <button className="close" onClick={() => toggleInfo(container)} type="button">
            <i className="material-icons">close</i>Close
          </button>
        </>
      ) : null}
      {content.cta_link ? (
        <a
          href={content.cta_link}
          onClick={() => {
            Gtm.newEvent('dashboard_carrousel', false, 'click', 'string', `${index}_${content.cta_link}`);
          }}
          rel="noreferrer"
          target={content.cta_out ? '_blank' : '_self'}
        >
          <span className="bottom-content">
            <h2>{content.title}</h2>
            <p>
              {content.subtitle ? <span className="subtitle">{content.subtitle}</span> : null}
              {content.cta ? (
                <span className="cta">
                  {content.cta}
                  <svg
                    height="11px"
                    viewBox="421 43 6 11"
                    width="6px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="M422.258274,53.774235 C421.970375,54.075255 421.503596,54.075255 421.215837,53.774235 C420.928054,53.4734827 420.928054,52.9856915 421.215837,52.6849636 L425.220519,48.4999209 L421.215953,44.3150242 C420.928171,44.0141503 420.928171,43.5264077 421.215953,43.2256555 C421.503736,42.9247815 421.970491,42.9247815 422.25839,43.2256555 L426.784245,47.9554069 C426.928136,48.1058561 427,48.3028276 427,48.4998965 C427,48.6970628 426.927996,48.8941804 426.784128,49.0445566 L422.258274,53.774235 Z"
                      fill="#00B827"
                      fillRule="evenodd"
                      stroke="none"
                    />
                  </svg>
                </span>
              ) : null}
            </p>
          </span>
          <span className="behind-content">
            <p>{content.content}</p>
          </span>
        </a>
      ) : (
        <>
          <span className="bottom-content">
            <h2>{content.title}</h2>
            <p>{content.subtitle ? <span className="subtitle">{content.subtitle}</span> : null}</p>
          </span>
          <span className="behind-content">
            <p>{content.content}</p>
          </span>
        </>
      )}
    </div>
  );
};

HomePromotionSlide.propTypes = {
  content: PropTypes.shape({
    content: PropTypes.string,
    cta: PropTypes.string,
    cta_link: PropTypes.string,
    cta_out: PropTypes.bool,
    image: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default HomePromotionSlide;

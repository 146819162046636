import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Table } from '../../reusables';

const SelectedCreativesTable = ({
  creatives,
  displayLink,
  displayUrl,
  handleSelectCreative,
  hidePreview,
  isSelected,
  showPreview,
}) => {
  const table = {
    head: [
      '',
      { component: <FormattedMessage {...messages.genericTextName} /> },
      {
        component: <FormattedMessage {...messages.genericTextPreview} />,
      },
      { component: <FormattedMessage {...messages.genericTextType} /> },
      { component: <FormattedMessage {...messages.genericTextSize} /> },
      {
        component: <FormattedMessage {...messages.genericTextLanguage} />,
      },
      { component: <FormattedMessage {...messages.genericTextRating} /> },
      { component: <FormattedMessage {...messages.genericTextFormat} /> },
      {
        component: <FormattedMessage {...messages.offerPlacementOptions} />,
      },
      '',
      '',
    ],
    body: creatives.map((creative) => {
      const [, rating, , language] = creative.display.split('_');

      return [
        <div className="checkbox-field" key={creative.id}>
          <input
            checked={isSelected(creative.id)}
            className="filled-in"
            id={`creative-checkbox-${creative.id}`}
            onChange={() => handleSelectCreative(creative, isSelected(creative.id))}
            type="checkbox"
          />
          <label htmlFor={`creative-checkbox-${creative.id}`}>&nbsp;</label>
        </div>,
        <div className="title" key={creative.id}>
          {creative.display}
        </div>,
        <span key={creative.id}>
          {creative.type !== 'file' && (
            <span
              className="show-preview standard-bg-colors"
              onMouseEnter={(e) => showPreview(creative, e)}
              onMouseLeave={hidePreview}
            >
              <i className="material-icons">search</i>
            </span>
          )}
        </span>,
        <div key={creative.id}>{creative.type}</div>,
        <div key={creative.id}>
          {creative.width !== null && creative.height !== null ? `${creative.width}x${creative.height}` : 'none'}
        </div>,
        <div key={creative.id}>{language}</div>,
        <div key={creative.id}>{rating}</div>,
        <div key={creative.id}>{creative.format}</div>,
        <div key={creative.id}>
          {creative.type !== 'file' && (
            <div className="show-link standard-colors" onClick={() => displayLink(creative)}>
              <i className="material-icons">link</i>
              <FormattedMessage {...messages.offerCopyCode} />
            </div>
          )}
        </div>,
        <div key={creative.id}>
          {creative.url && creative.type !== 'file' ? (
            <div className="show-link standard-colors" onClick={() => displayUrl(creative)}>
              <i className="material-icons">code</i>
              <FormattedMessage {...messages.offerCopyUrl} />
            </div>
          ) : null}
        </div>,
        <div key={creative.id}>
          {creative.url ? (
            <a className="show-link standard-colors" download href={creative.url} rel="noreferrer" target="_blank">
              <i className="material-icons">file_download</i>
              <FormattedMessage {...messages.offerDownloadFile} />
            </a>
          ) : null}
        </div>,
      ];
    }),
  };

  return (
    <div id="offer-creatives-table">
      <div className="row">
        <Table animated table={table} />
      </div>
    </div>
  );
};

SelectedCreativesTable.propTypes = {
  creatives: PropTypes.array.isRequired,
  displayLink: PropTypes.func.isRequired,
  displayUrl: PropTypes.func.isRequired,
  handleSelectCreative: PropTypes.func,
  hidePreview: PropTypes.func,
  isSelected: PropTypes.func,
  showPreview: PropTypes.func,
};

export default SelectedCreativesTable;

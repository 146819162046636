import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestReferrals = () => ({ type: ACTIONS.REQUEST_REFERRALS });
const receiveReferrals = (data) => ({ type: ACTIONS.RECEIVE_REFERRALS, data });
const failedToReceiveReferrals = (data) => ({
  type: ACTIONS.FAILED_TO_RECEIVE_REFERRALS,
  data,
});
export const fetchReferrals = (options) => (dispatch) => {
  dispatch(requestReferrals());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/user/referrals`)
      .query({ options: JSON.stringify(options) })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          fulfill();
          dispatch(receiveReferrals(res.body.data));
        } else {
          dispatch(failedToReceiveReferrals());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestReferralCommission = () => ({
  type: ACTIONS.REQUEST_REFERRAL_COMMISSION,
});
const receiveReferralCommission = (data) => ({
  type: ACTIONS.RECEIVE_REFERRAL_COMMISSION,
  data,
});
const failedToReceiveReferralCommission = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_REFERRAL_COMMISSION,
});
export const fetchReferralCommission = () => (dispatch) => {
  dispatch(requestReferralCommission());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/user/referralCommission`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          fulfill();
          dispatch(receiveReferralCommission(res.body.data));
        } else {
          dispatch(failedToReceiveReferralCommission());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const changeReferralsDateFilters = (dates) => ({
  type: ACTIONS.CHANGE_REFERRALS_DATE_FILTERS,
  dates,
});
export const changeReferralsViewMode = (viewMode) => ({
  type: ACTIONS.CHANGE_REFERRALS_VIEW_MODE,
  viewMode,
});

import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../i18n/base-en.js';

import Offline from './Offline.react';

const SimplifiedRegistration = (props) => {
  const {
    children,
    dispatch,
    intl,
    location: { pathname },
    signupUser,
  } = props;

  const splash = pathname.includes('connection');

  return (
    <Offline
      link={{
        url: '/simplified-login',
        text: <FormattedMessage {...messages.loginPageGoBackToLogin} />,
        isExternal: false,
      }}
      simplified
    >
      <div className={`simple-signup__container path${pathname.split('/').join('-')}`} id="simplified-registration-page">
        <div className={splash ? 'simple-signup__container__box__splash' : 'simple-signup__container__box'}>
          {cloneElement(children, {
            dispatch,
            intl,
            signupUser,
          })}
        </div>
      </div>
    </Offline>
  );
};

SimplifiedRegistration.propTypes = {
  children: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  signupUser: PropTypes.any.isRequired,
};

export default injectIntl(
  connect((state) => ({
    signupUser: state.signup,
  }))(SimplifiedRegistration)
);

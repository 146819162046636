import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  isVerified: 'false',
  isFetchingJumio: false,
  jumioUrl: '',
};

export const affiliateValidation = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.AFFILIATE.SET_VERIFIED:
      return {
        ...state,
        isVerified: 'true',
      };
    case ACTIONS.AFFILIATE.SET_PENDING:
      return {
        ...state,
        isVerified: 'pending',
      };
    case ACTIONS.AFFILIATE.SET_URL:
      return {
        ...state,
        url: action.url,
      };
    case ACTIONS.AFFILIATE.REQUEST_JUMIO_URL:
      return {
        ...state,
        isFetchingJumio: true,
      };
    case ACTIONS.AFFILIATE.RECEIVE_JUMIO_URL:
      return {
        ...state,
        isFetchingJumio: false,
        url: action.url,
      };
    case ACTIONS.AFFILIATE.FAILED_TO_RECEIVE_JUMIO_URL:
      return {
        ...state,
        isFetchingJumio: false,
      };

    default:
      return state;
  }
};

import React from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import LargeCta from './LargeCta';
import { MAIN_DROPDOWN_NAME, largeCtaTypes } from './values';

const DropdownOuter = React.memo(({ className = '', passedRef, onClick, field, children }) => {
  const { outerBoxActive } = useOffersFilterContext();

  const { mainDropdownActive, subDropdown } = outerBoxActive;

  let slideClassName = '';

  //MAIN DROPDOWN
  if (field === MAIN_DROPDOWN_NAME) {
    if (mainDropdownActive && subDropdown) {
      slideClassName = 'slideLeft';
    } else if (mainDropdownActive) {
      slideClassName = 'slideCenter';
    }
  }

  //SUB DROPDOWNS
  if (field === subDropdown) {
    slideClassName = 'slideCenter';
  }

  return (
    <div className={`dropdownOuterBox ${className} ${slideClassName}`} ref={passedRef} onClick={onClick}>
      {children}
      <div className="mobileCtaBottom">
        <LargeCta text={'FIND OFFERS'} largeCtaTpe={largeCtaTypes.mobileMenuBottom} />
      </div>
    </div>
  );
});

export default DropdownOuter;

import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import validate from '../validateContactInfos';

import ContactMethodSection from './company-infos/ContactMethodSection.react';

import messages from '../../../i18n/base-en.js';
import { Gtm } from '../../../utils/gtm';
import { useConfirmationOnFields } from '../../../components/reusables/useConfirmationOnFields';
import { withRouter } from 'react-router';
import { useEffect, useRef } from 'react';
import ContactInfo from './svg/ContactInfo.react';
import Arrow from './svg/Arrow.react';
import Warning from './svg/Warning.react';

function ContactInfos({
  fields,
  values,
  bestTimesToCall,
  handleSubmit,
  timezones,
  dispatch,
  invalid,
  parentRoute,
  router,
  isOpen,
  setIsOpen,
}) {
  const firstLoad = useRef(true);

  const handleGtmEvent = () => {
    if (fields.bestTimeToCall.value !== fields.bestTimeToCall.initialValue) {
      const eventLabel = bestTimesToCall.filter(({ id }) => fields.bestTimeToCall.value.includes(id));
      eventLabel.map((bestTimeToCall) =>
        Gtm.event('profile - user details', 'Click', `best time to call you - ${bestTimeToCall.name}`)
      );
    }

    if (fields.timezone.value !== fields.timezone.initialValue) {
      const eventLabel = timezones.filter(({ id }) => fields.timezone.value.includes(id));
      eventLabel.map((timezone) =>
        Gtm.event('profile - user details', 'Click', `time zone - ${timezone.location} ${timezone.gmt}`)
      );
    }
  };

  useEffect(() => {
    if (!firstLoad.current) {
      dispatch(change('profileContactInfos', 'messaging_platform_username', ''));
    }

    if (fields.messaging_platform_name.value) {
      firstLoad.current = false;
    }
  }, [fields.messaging_platform_name.value]);

  useConfirmationOnFields({
    router,
    route: parentRoute,
    items: fields,
    alertMessage: messages.profileSettingsUnsavedChecked.description,
  });

  return (
    <div className="profile-infos">
      <div className="info-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="header-wrapper" />
        <ContactInfo />
        <div className="profile-info">
          <h1>
            <FormattedMessage {...messages.profileMenuContactInformation} />
          </h1>
          <span className="profile-info-span">
            Please share your contact details so we can reach you when needed. Include your time zone and preferred method of
            contact.
          </span>
        </div>
        <div className="icons">
          <Warning className={invalid ? 'invalid' : ''} />
          <Arrow className={isOpen ? 'open' : ''} />
        </div>
      </div>
      <form action="#" className={isOpen ? 'open' : ''} method="post" onSubmit={handleSubmit}>
        <span className="profile-info-span-mobile">
          Please share your contact details so we can reach you when needed. Include your time zone and preferred method of
          contact.
        </span>
        <div className="">
          <ContactMethodSection
            bestTimesToCall={bestTimesToCall}
            dispatch={dispatch}
            fields={fields}
            handleGtmEvent={handleGtmEvent}
            invalid={invalid}
            timezones={timezones}
            values={values}
          />
        </div>
        <div className="form-buttons text-right">
          <button className="waves-effect waves-light btn bg-primary-color" disabled={invalid} id="btn-save-contact-infos">
            <FormattedMessage {...messages.genericTextSaveChanges} />
          </button>
        </div>
      </form>
    </div>
  );
}

ContactInfos.propTypes = {
  bestTimesToCall: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  parentRoute: PropTypes.object.isRequired,
  payableTo: PropTypes.any.isRequired,
  salutations: PropTypes.array,
  timezones: PropTypes.array.isRequired,
  values: PropTypes.any,
};

export default withRouter(
  reduxForm(
    {
      form: 'profileContactInfos',
      touchOnChange: false,
      touchOnBlur: false,
      fields: ['phone', 'bestTimeToCall', 'timezone', 'website', 'messaging_platform_name', 'messaging_platform_username'],
      validate,
    },
    (state) => ({
      initialValues: {
        phone: state.profile.data.affInfos.phone,
        bestTimeToCall: state.profile.data.customProfile.user.best_time_to_call_id,
        timezone: state.profile.data.customProfile.user.time_zone_id,
        website: state.profile.data.customProfile.affiliate.website,
        messaging_platform_name: state.profile.data.customProfile.user.messaging_platform_name,
        messaging_platform_username: state.profile.data.customProfile.user.messaging_platform_username,
      },
    })
  )(ContactInfos)
);

export default {
  'provinceList.AB': 'Alberta',
  'provinceList.BC': 'British Columbia',
  'provinceList.MB': 'Manitoba',
  'provinceList.NB': 'New Brunswick',
  'provinceList.NL': 'Newfoundland',
  'provinceList.NT': 'Northwest Territories',
  'provinceList.NS': 'Nova Scotia',
  'provinceList.NU': 'Nunavut',
  'provinceList.ON': 'Ontario',
  'provinceList.PE': 'Prince Edward Island',
  'provinceList.QC': 'Quebec',
  'provinceList.SK': 'Saskatchewan',
  'provinceList.YT': 'Yukon Territory',
};

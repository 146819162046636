export default {
  'referrals.onlyShowActive': 'Only show active',
  'referrals.referredAffiliate': 'Referred affiliate',
  'referrals.noReferrals':
    'No referrals yet!<br>Learn how to refer affiliates and get all the Referral Program information you need in the <a target="_blank" href="https://support.crakrevenue.com/knowledge-base/crakrevenue-referral-program/">Knowledge Base</a>',
  'referrals.useOurPromo':
    'Feature our Referral Program banners on your site and watch<br /><span class="gold">the $$$ roll in!</span>',
  'referrals.needALittle': 'Need a little help catching some Whales?',
  'referrals.fivePercent': '{rate}% Referral Commissions',
  'referrals.ifSomeoneSignup':
    'If someone signs up under your CrakRevenue Webmaster Referral link, you will receive {rate}% in referral commissions.<br /><span class="gold">{rate}% of whatever your referral earns each day, for as long as he is with CrakRevenue.</span>',
  'referrals.yourTrackingLink': 'Your tracking Link',
  'referrals.click':
    "Please note, affiliates you refer will only appear in the stats below once they've sent traffic (of at least 1 hit/click).",
  'referrals.learnMore': 'Click here to learn more referrals',
  'referrals.commissions': 'Referrals Commissions',
  'referrals.referredAffiliates': 'Referred Affiliates',
};

import { useCallback, useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { countryAlphaCode2ToAlphaCode3, countryAlphaCode3ToAlphaCode2 } from '../../../utils/list-options/countriesIsoCode3';
import AutoSuggestionAddressReact from '../AutoSuggestionAddress.react';
import { getCountryLabelDefaultMessage } from '../../../utils/list-options/countries';
import { buildAddressLabel, getAddressPartsFromAWSPlaceData } from '../../../utils/addressHelper';

function BeneficiaryInfoAutoSuggestionAddress({
  fields,
  fields: { country },
  intl,
  setIsAddressModeSearchTool,
  setIsFetchingAddressData,
  setIsSuggestedAddressComplete,
}) {
  const [suggestionMaxResults, setSuggestionMaxResults] = useState(5);
  const [suggestionInputTimer, setSuggestionInputTimer] = useState(250);
  const [suggestionMinimumInput, setSuggestionMinimumInput] = useState(3);
  const [addressCategoryTypes, setAddressCategoryTypes] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let param = searchParams.get('suggestionMinimumInput');
    if (param) {
      setSuggestionMinimumInput(param);
    }
    param = searchParams.get('suggestionInputTimer');
    if (param) {
      setSuggestionInputTimer(param);
    }
    param = searchParams.get('suggestionMaxResults');
    if (param) {
      setSuggestionMaxResults(param);
    }
  }, []);

  useEffect(() => {
    if (addressCategoryTypes?.length) {
      setIsSuggestedAddressComplete(addressCategoryTypes.find((e) => e === 'AddressType') !== undefined);
    }
    if (addressCategoryTypes === undefined) {
      setIsAddressModeSearchTool(false);
    }
  }, [addressCategoryTypes, setIsSuggestedAddressComplete]);

  const countryIsoCode3 = countryAlphaCode2ToAlphaCode3(country.initialValue === 'UK' ? 'GB' : country.initialValue);
  const suggestionFilters = {};
  if (countryIsoCode3) {
    suggestionFilters.FilterCountries = [countryIsoCode3];
  }

  const suggestionParams = {
    inputTimer: suggestionInputTimer,
    language: 'en',
    maxResults: suggestionMaxResults,
    minimumInput: suggestionMinimumInput,
  };

  const mapPlaceAddressToFields = useCallback(
    (place) => {
      if (place) {
        const { address, city, region, postalCode, unitNumber } = getAddressPartsFromAWSPlaceData(
          place,
          fields.country.initialValue
        );
        fields.address1.onChange(address);
        fields.city.onChange(city);
        fields.postalCode.onChange(postalCode);
        fields.province.onChange(region);
        fields.region.onChange(region);
        fields.state.onChange(region);
        // Reset extended address fields
        fields.address2.onChange(null);
        fields.address3.onChange(null);
        fields.address4.onChange(null);
        fields.address5.onChange(null);
        fields.apartment_suite_unit_building.onChange(unitNumber ? unitNumber : null);
        const displayCountry = getCountryLabelDefaultMessage(countryAlphaCode3ToAlphaCode2(place?.Country));
        const addressLabel = buildAddressLabel({
          address,
          city,
          region,
          postalCode,
          country: displayCountry,
        });
        fields.address_label.onChange(addressLabel);
        fields.autocompleted_address_label.onChange(addressLabel);
        return addressLabel;
      }
    },
    [fields]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {country.value ? (
        <AutoSuggestionAddressReact
          address_label_field={fields.address_label}
          countryFullName={country.value}
          mapPlaceAddressToFields={mapPlaceAddressToFields}
          setAddressCategoryTypes={setAddressCategoryTypes}
          setIsAddressModeSearchTool={setIsAddressModeSearchTool}
          setIsFetchingAddressData={setIsFetchingAddressData}
          suggestionFilters={suggestionFilters}
          suggestionParams={suggestionParams}
        />
      ) : null}
    </>
  );
}

BeneficiaryInfoAutoSuggestionAddress.propTypes = {
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  setIsAddressModeSearchTool: PropTypes.func,
  setIsFetchingAddressData: PropTypes.func,
  setIsSuggestedAddressComplete: PropTypes.func,
};

export default injectIntl(BeneficiaryInfoAutoSuggestionAddress);

const TrafficPayoutBumpsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
    <g clip-path="url(#clip0_327_33141)">
      <circle cx="39.5" cy="38.5" r="38.5" fill="white" />
      <g clip-path="url(#clip1_327_33141)">
        <path
          d="M25.5264 47.2004L27.7345 44.9923L44.4576 28.2693"
          stroke="#00BC00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.1365 34.9034L44.6996 34.8507L44.9582 27.7713L37.8765 28.03L37.8262 26.593L46.4502 26.2793L46.1365 34.9034Z"
          fill="#00BC00"
        />
        <path
          d="M20.2383 19.4492L27.5772 26.7882"
          stroke="#00BC00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 27.8269L20.0527 26.39L27.132 26.6486L26.8734 19.5669L28.3103 19.5166L28.6241 28.1407L20 27.8269Z"
          fill="#00BC00"
        />
        <path d="M41.0093 41.6943H49.0846" stroke="#00BC00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M44.4864 47.5721L43.5068 46.5208L48.6966 41.6974L43.5068 36.8717L44.4864 35.8203L50.8065 41.6974L44.4864 47.5721Z"
          fill="#00BC00"
        />
        <path d="M59.0003 53.9629H35.8647" stroke="#00BC00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M39.8294 48.0859L40.809 49.1397L35.6192 53.963L40.809 58.7864L39.8294 59.8378L33.5093 53.963L39.8294 48.0859Z"
          fill="#00BC00"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_327_33141">
        <rect width="77" height="77" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_327_33141">
        <rect width="77" height="77" fill="white" transform="translate(19 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default TrafficPayoutBumpsIcon;

import createAccountForm from './access/create-account-form';
import confirmEmail from './access/confirm-email';
import tellUsAboutYou from './access/tell-us-about-you';
import accountRegistered from './access/account-registered';
import loginSplash from './access/login-splash';

export default {
  createAccount: {
    id: 'createAccount.createAccount',
    description: 'Create an affiliate account',
    defaultMessage: 'Create an affiliate account',
  },
  confirmEmail: {
    id: 'confirmEmail.confirmEmail',
    description: 'Confirm Email',
    defaultMessage: 'Confirm Email',
  },
  ...createAccountForm,
  ...confirmEmail,
  ...tellUsAboutYou,
  ...accountRegistered,
  ...loginSplash,
};

const Interrogation = () => (
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <title>Interogation</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Mode Isolation" id="Mode_Isolation">
        <circle cx="12.5" cy="12.5" r="12.5" />
        <path d="M9.61,6.29a4.22,4.22,0,0,1,3-1,4.49,4.49,0,0,1,2.91.88,3,3,0,0,1,1.09,2.5,3.41,3.41,0,0,1-.26,1.41,3.25,3.25,0,0,1-.65,1c-.26.27-.59.59-1,.95a10.68,10.68,0,0,0-.93.87,3.34,3.34,0,0,0-.57.88,2.72,2.72,0,0,0-.24,1.17h-1.5a4,4,0,0,1,.24-1.44,3.82,3.82,0,0,1,.6-1,12.15,12.15,0,0,1,.95-.94,7.79,7.79,0,0,0,1.16-1.24,2.4,2.4,0,0,0,.37-1.38,1.79,1.79,0,0,0-.62-1.47,2.6,2.6,0,0,0-1.71-.51,2.49,2.49,0,0,0-1.73.57A2.19,2.19,0,0,0,10,9.15l-1.54,0A3.85,3.85,0,0,1,9.61,6.29Zm3.31,11.4a1.13,1.13,0,0,1,.32.84,1.18,1.18,0,0,1-.32.86,1,1,0,0,1-.79.34,1.11,1.11,0,0,1-.81-.34,1.22,1.22,0,0,1-.32-.86,1.17,1.17,0,0,1,.32-.84,1.1,1.1,0,0,1,.81-.33A1,1,0,0,1,12.92,17.69Z" />
      </g>
    </g>
  </svg>
);

export default Interrogation;

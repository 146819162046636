const LinkIcon = () => (
  <svg fill="none" height="54" viewBox="0 0 54 54" width="54" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32_74)">
      <rect fill="url(#paint0_radial_32_74)" height="54" rx="5" width="54" />
      <g clipPath="url(#clip1_32_74)">
        <g style={{ mixBlendMode: 'color-dodge' }}>
          <path
            d="M-2.96246 66.0859C-0.552483 64.0942 1.39026 62.4803 0.299442 59.2716C-0.898521 55.7478 -4.71022 51.7035 -7.24667 48.9614C-14.1394 41.5065 -22.7289 42.1576 -32.1035 43.3833C-37.5699 44.0988 -43.0942 45.0529 -48.6274 45.4255C-52.7061 45.7006 -55.6518 46.2211 -59.3054 48.2215C-66.8146 52.3337 -68.659 58.8625 -69.4951 66.9546C-70.5227 76.8957 -63.9269 86.4903 -57.995 94.0253C-55.6729 96.9763 -53.3314 99.5791 -49.6058 100.514C-45.715 101.491 -41.7013 102.171 -37.6929 102.269C-28.9541 102.485 -20.3997 101.042 -16.9674 92.1851C-15.3057 87.8988 -15.1845 83.1615 -13.4262 78.9379C-11.2498 73.7132 -7.25194 69.634 -2.9607 66.0841H-2.96246V66.0859Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M-0.117468 65.8649C2.99977 63.1599 3.79006 61.1826 1.51228 57.5898C-0.642563 54.1921 -3.05382 50.8448 -5.60732 47.7272C-8.03964 44.7577 -10.8162 42.0196 -14.5727 40.822C-20.1486 39.0431 -26.7764 40.5487 -32.3839 41.3947C-38.2689 42.2824 -44.4683 43.722 -50.4762 43.5131C-55.1037 43.353 -58.019 44.2842 -62.0372 46.4687C-65.6883 48.4548 -68.6475 50.1433 -69.7521 54.3209C-70.8216 58.3644 -71.2782 62.6778 -72.0615 66.784C-74.0916 77.416 -67.174 86.8712 -60.9097 95.0315C-58.4071 98.2917 -55.5252 101.877 -51.486 103.22C-47.2834 104.616 -42.4328 105.098 -38.0424 105.075C-28.3324 105.025 -19.5321 103.061 -15.737 93.39C-14.0915 89.1968 -13.5049 84.678 -11.9243 80.4778C-9.68518 74.5282 -4.78542 69.9155 -0.117468 65.8649Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M3.00751 66.2277C6.46318 62.7177 5.54307 60.4871 2.94079 56.7702C0.665109 53.5175 -1.72821 50.3395 -4.0794 47.1389C-6.50784 43.834 -9.26465 40.1709 -13.2559 38.648C-19.0697 36.4297 -26.8011 38.4881 -32.636 39.4651C-40.1882 40.729 -47.1154 41.4974 -54.7308 41.374C-58.6009 41.3114 -61.233 43.5593 -64.4446 45.3152C-68.1145 47.3214 -72.2655 48.8843 -72.564 53.5644C-72.8836 58.5852 -72.8555 63.0949 -74.183 68.0305C-75.4718 72.8167 -76.0917 76.3997 -73.679 80.8746C-70.8063 86.2032 -67.4894 91.3822 -63.7949 96.1874C-60.9644 99.8679 -57.6018 104.045 -53.1312 105.833C-48.6167 107.636 -43.0978 108.061 -38.3041 107.91C-27.7282 107.578 -18.7642 105.13 -14.478 94.8279C-12.7308 90.6277 -11.7774 86.1284 -9.86869 81.9908C-6.95034 75.6626 -1.7967 71.1025 3.004 66.2277H3.00751Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M6.65669 67.0438C10.1723 62.5891 7.12205 59.356 4.20176 55.5173C1.98213 52.6009 -0.423642 49.8689 -2.47294 46.8289C-4.97704 43.112 -7.6269 38.753 -11.7466 36.5918C-17.7346 33.4509 -26.4621 36.0541 -32.6768 37.1799C-37.4339 38.043 -42.2191 39.021 -47.0499 39.4699C-50.3899 39.7797 -53.4981 39.0941 -56.7731 39.1689C-60.8787 39.2629 -63 42.5673 -66.347 44.3875C-70.4386 46.6114 -76.0508 48.2627 -75.3221 54.0104C-74.9551 56.9111 -73.9085 59.3281 -73.9805 62.356C-74.049 65.2533 -74.7022 68.0879 -75.6031 70.8303C-77.0571 75.2572 -78.9782 78.6261 -76.4582 82.9503C-73.5186 87.9966 -70.068 92.8654 -66.4699 97.4681C-63.223 101.622 -59.5265 105.894 -54.6167 108.138C-49.6488 110.408 -43.6766 110.919 -38.2961 110.667C-33.0543 110.423 -26.9363 109.658 -22.195 107.254C-17.7311 104.992 -15.0251 100.776 -13.0425 96.3666C-11.1478 92.1555 -9.92907 87.5929 -7.32486 83.7246C-3.22452 77.6324 2.15597 72.7462 6.65669 67.0421V67.0438Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M10.1278 69.2714C15.362 60.3046 3.48625 53.5865 -0.837778 46.8476C-3.59806 42.5469 -6.22481 37.5275 -10.6174 34.614C-16.7792 30.5257 -26.1565 33.5019 -32.7523 34.802C-37.8283 35.801 -42.9624 36.9027 -48.1456 37.1829C-51.751 37.3796 -55.88 35.9768 -59.3255 36.8748C-63.0644 37.8495 -64.4384 42.0091 -67.8365 43.7322C-72.5647 46.1288 -79.0867 47.6116 -78.1695 54.3558C-77.6846 57.9307 -75.1211 60.5796 -75.028 64.2972C-74.9542 67.242 -75.91 70.3383 -76.8166 73.1038C-78.0887 76.9798 -80.8877 82.2899 -78.8917 86.171C-76.604 90.6231 -72.1623 95.0769 -69.0155 98.9476C-60.819 109.03 -51.4593 113.71 -38.301 113.08C-32.7453 112.814 -26.3849 112.055 -21.3826 109.455C-16.6685 107.004 -13.8028 102.65 -11.5029 98.0461C-9.46651 93.9682 -7.93615 89.3073 -4.46427 86.1449C0.980736 81.1847 6.36776 75.7197 10.1313 69.2749L10.1278 69.2714Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M13.3182 71.1624C16.6129 64.659 12.852 58.3473 7.96008 53.7527C5.4675 51.4119 2.77263 50.0552 0.913307 47.1292C-2.17735 42.2647 -4.84936 36.5712 -9.42642 32.8545C-15.9683 27.5425 -25.8929 30.6949 -33.2387 32.2815C-38.2274 33.3596 -43.2653 34.483 -48.3912 34.6519C-52.3034 34.7808 -57.044 32.75 -60.7257 34.4412C-64.7258 36.2769 -64.7364 41.3556 -69.0144 43.207C-74.8984 45.7533 -82.3586 47.0299 -80.0912 55.2123C-79.0674 58.9046 -76.5871 61.8201 -76.705 65.7667C-76.7912 68.6788 -77.7252 71.617 -78.5661 74.381C-80.063 79.3064 -81.8713 84.5959 -80.2424 89.7285C-78.9988 93.6473 -74.0611 97.6183 -71.3979 100.753C-62.5569 111.163 -52.1468 116.033 -38.3664 115.42C-32.4718 115.157 -25.8402 114.411 -20.4979 111.724C-15.4934 109.206 -12.4766 104.681 -9.82045 99.9573C-7.11854 95.1503 -5.25922 91.5555 -0.089356 89.1991C3.58003 87.5271 4.73045 85.2908 6.7903 81.8858C8.95042 78.3154 11.4325 74.8861 13.3199 71.1607L13.3182 71.1624Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M16.2501 72.4834C19.7679 65.5333 16.4189 57.6233 10.6249 52.8468C7.81415 50.5289 4.78877 50.5898 2.74839 47.4546C-0.95595 41.7669 -3.62956 35.1228 -9.11044 30.8053C-16.5402 24.9524 -25.6305 27.6615 -34.0259 29.6681C-39.2113 30.9079 -44.5954 32.2277 -49.9901 31.9895C-53.4605 31.8364 -57.5765 29.7759 -60.8991 31.5669C-65.8154 34.2169 -64.1039 40.708 -69.97 42.5616C-72.9936 43.5162 -76.9565 43.6205 -79.4578 45.7732C-81.915 47.8876 -82.0364 52.0156 -81.6213 55.1055C-81.1217 58.8179 -78.719 61.9687 -78.8122 65.7298C-78.8896 68.8406 -79.8641 71.9496 -80.6767 74.9334C-82.2123 80.5655 -83.1727 85.9368 -81.616 91.6662C-80.3249 96.4167 -76.7455 99.0876 -73.5072 102.673C-64.0177 113.177 -52.6971 118.103 -38.4286 117.537C-32.2336 117.291 -25.3455 116.542 -19.7222 113.753C-14.287 111.058 -11.2036 106.845 -8.01989 101.891C-6.71827 99.8648 -5.44479 97.5087 -3.55744 95.9368C-1.67888 94.3736 0.889183 93.5459 2.99816 92.2922C4.14851 91.6088 5.59788 90.8299 6.59872 89.9378C8.45265 88.2859 9.0243 85.9089 10.0973 83.6954C11.96 79.8509 14.3275 76.2862 16.2536 72.4817H16.2501V72.4834Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M18.9886 73.3592C23.0447 66.0695 20.3219 56.726 13.5376 51.843C10.3874 49.5754 6.90992 50.7631 4.71827 47.5564C0.108081 40.811 -2.77483 33.8742 -9.4166 28.5913C-17.4743 22.1814 -25.7871 24.4021 -35.0075 26.8662C-39.987 28.1965 -45.2146 29.5373 -50.4492 29.066C-54.4649 28.7043 -58.9256 26.1132 -62.3115 29.4468C-64.3748 31.4779 -64.8128 34.8985 -65.7239 37.5104C-66.7195 40.3588 -68.0668 40.9136 -71.0324 41.4005C-74.8669 42.0317 -78.3918 41.5448 -80.682 45.0958C-82.4778 47.8816 -83.3556 51.2117 -83.3503 54.4949C-83.3432 58.2354 -81.3187 61.4769 -81.2905 65.1183C-81.2659 68.4014 -82.3969 71.7628 -83.1427 74.9295C-84.5921 81.0854 -84.9597 86.8501 -83.2729 92.9956C-81.8411 98.2125 -79.1007 100.948 -75.4438 104.765C-65.4425 115.204 -53.0824 120.176 -38.513 119.652C-32.0085 119.417 -24.9234 118.671 -19.0064 115.799C-13.339 113.048 -9.77718 108.839 -6.13441 103.922C-2.67281 99.2507 2.1942 97.3152 6.65664 93.7555C9.82098 91.2306 10.8552 89.6864 12.3503 85.9041C14.1181 81.4332 16.665 77.5362 18.9886 73.3592Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M21.4718 73.8971C26.3056 66.4179 24.3447 55.566 16.4464 50.7399C13.0674 48.6745 9.06465 50.4148 6.75226 47.2576C4.5945 44.3125 3.33288 40.8946 1.07497 37.9791C-2.22316 33.7214 -6.10993 29.735 -10.2866 26.3153C-13.4284 23.7423 -16.8706 21.4005 -21.0333 21.0058C-26.1412 20.5208 -31.3371 22.5757 -36.0936 24.01C-40.9328 25.4686 -46.0794 26.8212 -51.2208 26.0145C-55.2411 25.3852 -59.5619 22.6852 -62.7036 26.6126C-64.7964 29.2273 -64.8614 32.9582 -66.2478 35.912C-67.7185 39.0448 -69.2718 39.4916 -72.5787 39.429C-77.1982 39.3421 -78.69 39.9471 -81.3661 43.711C-83.3341 46.4805 -84.7468 49.6707 -85.2388 53.0365C-85.8415 57.1568 -84.0493 60.6321 -84.0967 64.6029C-84.1354 67.8905 -85.2265 71.2997 -85.845 74.5038C-88.1152 86.2563 -85.9083 98.2539 -77.2263 106.851C-66.8294 117.146 -53.2766 122.127 -38.6221 121.698C-31.8256 121.498 -24.5791 120.695 -18.3782 117.762C-12.5551 115.006 -8.37839 110.677 -4.26846 105.907C-0.631198 101.686 4.66304 99.2692 8.657 95.3662C11.1346 92.9444 13.092 90.6461 14.1867 87.3707C15.8683 82.3324 18.6217 78.3077 21.47 73.8988L21.4718 73.8971Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M23.905 74.3389C29.8414 66.6412 28.5602 54.5284 19.3886 49.6262C15.9389 47.7823 11.2467 49.5984 8.99381 46.408C6.2576 42.5339 3.966 38.9434 0.572537 35.4834C-3.28662 31.5467 -7.24595 27.6378 -11.4583 24.0699C-14.8606 21.1874 -18.6495 18.1797 -23.2924 17.8752C-28.0461 17.5621 -32.7663 19.7383 -37.1052 21.2239C-42.3 23.0001 -47.2891 24.1134 -52.7791 22.7009C-56.9845 21.6188 -60.5484 19.6757 -63.195 24.2126C-64.9084 27.149 -64.984 30.7586 -66.7449 33.6725C-68.9627 37.3413 -71.3702 36.221 -75.0168 36.3462C-81.1429 36.5584 -86.4431 47.6101 -87.4852 52.9193C-88.2462 56.7881 -87.0371 60.2708 -87.1548 64.0596C-87.2568 67.3335 -88.2602 70.6996 -88.747 73.9353C-90.5378 85.815 -87.842 100.198 -78.9515 108.948C-68.4548 119.279 -53.287 124.22 -38.6956 123.893C-31.5133 123.731 -23.8794 122.828 -17.3385 119.716C-11.4109 116.896 -6.98411 112.441 -2.36928 107.936C1.9837 103.686 7.28038 100.683 11.1747 95.9185C13.2818 93.3404 15.1024 90.6458 16.141 87.4867C17.8316 82.3479 20.6346 78.58 23.905 74.3389Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M26.1869 74.5346C33.6692 66.4335 32.435 52.9573 21.8094 48.1231C19.1125 46.8967 16.6724 47.3211 13.9896 46.7958C12.0417 46.4148 12.3634 45.8234 11.2523 44.0125C8.6504 39.7733 3.57492 36.4925 -0.132805 33.243C-4.56836 29.3569 -8.79647 25.2481 -13.232 21.3654C-16.4246 18.5717 -20.1025 15.2335 -24.5204 14.7499C-29.2373 14.2333 -33.8205 16.7382 -38.0117 18.3751C-43.5478 20.5374 -48.5477 21.2749 -54.2842 19.137C-58.5176 17.5575 -61.5414 17.4045 -63.6599 21.7324C-65.1155 24.7071 -65.1437 28.6715 -67.3957 31.2547C-70.5022 34.8173 -73.5629 31.8131 -77.1054 33.1004C-79.7056 34.0449 -82.4886 38.6582 -84.0251 40.7874C-86.242 43.8577 -88.2708 47.1368 -89.475 50.7376C-90.841 54.8221 -90.1607 58.3325 -90.3294 62.4917C-90.4718 66.0247 -91.4282 69.5925 -91.8097 73.1082C-93.1335 85.325 -89.8425 101.616 -80.6953 110.632C-70.0556 121.12 -53.53 126.161 -38.8081 125.935C-31.5931 125.823 -24.0528 124.868 -17.3951 121.982C-10.8569 119.149 -5.67768 114.462 -0.610989 109.613C4.10585 105.097 9.41516 101.706 13.1897 96.2441C15.2519 93.2608 16.8482 90.1487 17.954 86.7149C19.5732 81.6876 22.648 78.3685 26.1869 74.5346Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M28.3207 74.8236C37.4325 66.3422 36.1905 51.8764 24.2558 46.7622C22.5185 46.0174 20.6617 45.5145 18.7839 45.2726C17.9547 45.1665 16.4036 45.4327 15.6781 45.1177C13.9531 44.3677 14.3448 42.3387 12.9412 40.7883C11.1828 38.8445 8.55662 37.7848 6.38715 36.3631C3.81892 34.6804 1.31745 32.8846 -1.11025 31.0052C-5.89537 27.2987 -10.2062 23.1137 -14.6312 19.007C-17.7387 16.1236 -21.3504 12.4032 -25.7508 11.7037C-30.3041 10.9798 -34.7537 13.9641 -38.6236 15.6955C-44.3274 18.2483 -48.8631 18.3423 -54.6935 15.8869C-59.0429 14.0563 -61.7166 14.5818 -63.7578 19.0261C-65.2791 22.3394 -65.4881 27.5876 -69.2034 29.4791C-72.4234 31.1183 -75.5309 28.4699 -78.5787 30.612C-81.5615 32.7088 -83.5008 36.1787 -85.5456 39.0916C-87.9416 42.504 -90.3342 46.0435 -91.801 49.9675C-93.1519 53.5835 -93.1097 56.9576 -93.3574 60.7285C-93.6139 64.6316 -94.5273 68.4825 -94.7944 72.3943C-95.6674 85.2487 -91.6903 102.868 -82.1675 112.32C-71.3817 123.025 -53.8151 128.26 -38.873 128.124C-23.1036 127.981 -9.98662 122.387 1.12949 111.373C6.02001 106.529 11.4463 102.502 15.065 96.5144C17.1027 93.1437 18.4554 89.6165 19.6499 85.8874C21.1799 81.1089 24.7389 78.1525 28.3207 74.8201V74.8236Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M30.452 75.209C35.4641 71.0728 38.3146 64.8121 37.7399 58.2923C37.1741 51.8647 31.8386 47.466 26.1131 45.2111C24.2608 44.4826 22.3276 43.9627 20.3681 43.6133C19.6371 43.4829 17.9078 43.622 17.3331 43.1647C16.523 42.518 17.2013 42.7857 16.8129 41.8608C15.9887 39.9031 16.0168 38.8425 14.1593 37.3108C11.546 35.155 8.42844 34.3135 5.36356 33.053C2.57985 31.9073 -0.0966555 30.0921 -2.53767 28.3796C-7.28438 25.0467 -11.3826 21.0462 -15.4756 16.9761C-18.602 13.8657 -22.4278 9.4097 -27.1147 8.79597C-31.4204 8.23092 -35.3534 11.4317 -38.8559 13.2294C-44.4022 16.0772 -48.9187 15.62 -54.5335 12.993C-60.0992 10.3885 -62.3609 12.6139 -64.2853 17.8019C-65.5295 21.1592 -66.4205 25.5787 -70.3957 26.6445C-72.661 27.2513 -74.7435 25.7821 -76.9156 26.3455C-78.0316 26.6341 -79.3391 27.7276 -80.1949 28.4248C-83.4531 31.0762 -85.4583 34.6525 -87.7552 38.081C-89.8975 41.2801 -92.0995 44.4843 -93.6882 47.9998C-96.8866 55.0742 -97.5369 64.1532 -97.7829 71.8448C-98.0377 79.8441 -96.3822 87.5774 -93.7725 95.116C-91.4721 101.766 -88.6796 108.842 -83.5762 113.9C-72.5485 124.827 -54.2119 130.368 -38.9191 130.311C-22.8215 130.25 -8.45831 124.323 2.91902 113.067C8.14374 107.898 13.6391 102.889 17.1609 96.3608C19.0712 92.8193 20.1801 89.156 21.4191 85.3589C22.9392 80.7047 26.8248 78.1994 30.4503 75.2072H30.452V75.209Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M32.4268 75.8586C37.9822 71.5652 41.5611 64.0065 40.7476 56.9106C39.9992 50.3923 33.9852 46.2867 28.3542 43.9765C26.1704 43.0806 23.932 42.4422 21.6515 41.8438C20.9786 41.6681 19.2832 41.5863 18.8387 40.9879C18.2607 40.2103 19.1163 39.0569 18.8703 38.0409C18.4223 36.1952 16.3105 34.8 14.8329 33.805C9.21072 30.023 2.36221 29.4281 -3.3197 25.9506C-8.43588 22.8193 -12.5032 18.5711 -16.5459 14.2273C-19.443 11.1133 -22.8515 6.93825 -27.3106 6.13107C-31.7011 5.33606 -35.2536 8.93186 -38.6392 10.922C-44.7569 14.5195 -48.7451 12.4076 -55.0015 9.82255C-60.4234 7.58191 -62.2998 10.5306 -64.257 15.2919C-65.8242 19.1069 -67.1647 23.456 -71.9981 23.6612C-74.1889 23.7534 -75.8299 22.7845 -77.9558 23.5708C-79.4316 24.117 -80.7669 25.3852 -81.9036 26.422C-87.9264 31.9175 -93.2973 40.6991 -96.4528 48.1621C-99.4413 55.2284 -100.611 64.0257 -100.629 71.6504C-100.65 80.1502 -98.728 88.4117 -95.7447 96.3443C-93.1796 103.165 -90.1946 110.314 -84.9255 115.536C-73.6232 126.739 -54.6888 132.725 -38.9818 132.722C-22.4966 132.718 -7.02506 126.372 4.6269 114.835C9.87309 109.64 15.1245 103.924 18.6314 97.4194C20.5078 93.9402 21.8712 90.3566 23.0184 86.5938C24.6682 81.1801 28.0766 79.2247 32.4285 75.862V75.8586H32.4268Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M34.6751 76.414C40.2729 71.8777 44.1291 63.7286 43.4542 56.3849C42.7845 49.102 35.7648 45.0666 29.615 42.5375C27.8715 41.8209 21.2226 40.7077 20.5266 38.683C20.0749 37.3698 21.4915 36.4444 20.5319 34.6754C19.5424 32.8491 17.131 31.5949 15.4033 30.553C12.2994 28.6797 8.94422 27.0969 5.54508 25.8323C2.05278 24.5347 -1.82443 24.2477 -5.09528 22.4753C-9.63157 20.0193 -13.0008 15.9804 -16.2453 12.1085C-19.0504 8.76187 -22.0172 5.05347 -26.4375 3.8359C-30.8244 2.62701 -34.1884 5.29004 -37.8441 7.22772C-40.0218 8.38095 -42.4296 9.14802 -44.9289 9.09584C-48.618 9.0193 -51.3212 6.8294 -54.7836 6.09537C-60.2233 4.94215 -62.1285 8.95669 -64.0935 12.9834C-66.7861 18.5042 -68.5401 20.8994 -74.9816 20.2645C-78.5794 19.9097 -81.1367 22.197 -83.6412 24.6356C-96.062 36.7227 -103.145 54.5741 -103.145 71.6881C-103.145 80.559 -101.143 89.1325 -97.8266 97.3425C-94.9811 104.387 -91.7067 111.731 -86.2213 117.168C-74.5756 128.716 -55.1685 135.207 -38.9602 135.207C-22.7519 135.207 -5.57684 128.479 6.42386 116.603C12.1377 110.948 17.312 104.274 21.075 97.205C22.9029 93.7697 24.3546 90.2962 25.5498 86.5999C27.1861 81.54 30.6836 79.6458 34.6733 76.4123H34.6751V76.414Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M37.0731 77.3231C42.3891 72.581 46.7827 63.5402 45.8429 56.3461C44.8995 49.1207 37.6528 44.3056 31.4831 41.5945C28.8005 40.4155 22.5482 39.2748 22.7924 36.0021C22.9505 33.8875 23.7042 33.4336 21.914 31.6338C20.21 29.9192 17.8401 28.7558 15.7566 27.5716C12.4592 25.6987 9.08268 23.9354 5.62011 22.3791C1.91686 20.7149 -1.77585 20.2175 -5.51775 18.8907C-9.81127 17.3691 -12.3972 13.2895 -15.0095 9.82207C-17.7184 6.2259 -21.5745 2.94797 -26.0051 1.68201C-30.3249 0.447354 -33.4467 2.2437 -37.3326 3.89571C-39.4056 4.77736 -41.6402 5.27643 -43.9047 5.07298C-47.2496 4.77214 -49.6914 2.5741 -52.8747 1.9046C-59.3975 0.532563 -61.7305 7.14581 -64.2796 11.6219C-65.4285 13.6373 -66.7197 15.8928 -68.9139 16.9657C-71.7353 18.3464 -73.8767 16.6092 -76.6085 16.9761C-80.0149 17.4335 -83.1507 20.6505 -85.3923 22.9581C-97.8951 35.8368 -105.584 53.7707 -105.584 71.6524C-105.584 80.4863 -103.761 89.1828 -100.349 97.335C-97.1784 104.913 -93.4031 112.855 -87.4846 118.724C-75.3823 130.723 -55.8577 137.618 -38.9436 137.618C-22.0295 137.618 -4.24585 130.596 8.17971 118.296C14.0157 112.52 18.708 105.649 22.8012 98.5871C24.962 94.8588 27.0385 91.1635 28.5265 87.1204C30.2797 82.3574 33.3716 80.6202 37.0696 77.3231H37.0731Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M39.6419 78.661C44.7851 73.5386 49.851 63.0225 47.9093 55.7903C45.9624 48.5389 38.7194 44.0514 32.4287 40.771C30.408 39.7169 27.8513 38.9081 26.1188 37.4036C24.2281 35.7582 25.1084 35.6556 25.5108 33.6344C26.4052 29.1469 19.2606 26.804 16.1258 25.0559C9.65591 21.4468 2.8346 16.18 -4.5367 14.639C-7.22867 14.0772 -8.25134 13.8649 -9.95051 11.6612C-11.1805 10.0662 -12.3209 8.7843 -13.8303 7.40152C-17.306 4.21676 -20.9539 1.13811 -25.5805 -0.216845C-29.882 -1.47614 -33.0958 -0.235977 -37.1268 1.11898C-39.4164 1.88777 -41.9221 2.15389 -44.3013 1.63209C-47.2674 0.981569 -49.7081 -1.15436 -52.7515 -1.41004C-59.2319 -1.95446 -61.7938 5.1995 -64.6053 9.67659C-66.0057 11.9064 -67.5468 13.9467 -70.2651 14.5659C-72.806 15.1434 -75.2221 13.738 -77.6803 14.2667C-81.3739 15.0599 -84.8003 18.7908 -87.2428 21.3946C-100.005 34.9981 -108.1 53.0421 -108.1 71.6601C-108.1 88.6519 -101.193 108.131 -88.8488 120.359C-76.2553 132.835 -56.5733 140.14 -38.956 140.104C-20.7588 140.067 -2.95701 132.821 9.93705 120.058C16.3032 113.756 20.6417 106.242 25.4493 98.801C27.7301 95.2701 30.3219 92.0297 31.9437 88.1283C33.7571 83.766 36.349 81.9415 39.6436 78.6593L39.6419 78.661Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M42.312 80.2713C47.0957 74.7046 52.164 63.8771 50.1782 56.6408C48.1466 49.2375 40.6987 44.2568 34.4247 40.5456C32.6111 39.4726 29.1332 38.2309 27.9364 36.3753C26.5937 34.2954 28.5339 33.4172 28.2914 31.2416C27.8239 27.0574 19.6221 24.7548 16.5238 23.0123C12.7471 20.8889 9.17077 18.4629 5.64365 15.9603C2.82476 13.9604 -0.0907796 11.3779 -3.38416 10.1936C-5.09764 9.57794 -6.65997 9.53794 -8.25394 8.62144C-9.95863 7.64061 -11.4805 6.42151 -13.0218 5.21112C-16.7545 2.27904 -20.4996 -0.618262 -25.1655 -1.90692C-29.5151 -3.10862 -32.9315 -2.2756 -37.1458 -1.20085C-39.4585 -0.611306 -41.9013 -0.466962 -44.2439 -1.00434C-47.3739 -1.72257 -49.9959 -4.17119 -53.3631 -3.90511C-59.377 -3.42861 -61.8883 3.1851 -65.1044 7.24758C-66.8091 9.40055 -68.7686 11.2979 -71.6138 11.6683C-74.38 12.0283 -76.5434 10.7431 -79.2603 11.9292C-83.1231 13.6161 -86.3304 16.9238 -89.1194 19.9654C-102.198 34.2328 -110.616 52.2983 -110.616 71.6925C-110.616 89.6693 -103.274 109.143 -90.2722 122.014C-77.1286 135.026 -57.4403 142.643 -38.9735 142.589C-20.0778 142.534 -1.6988 135.063 11.6821 121.821C18.2478 115.324 22.1158 107.488 27.5339 100.194C29.9943 96.883 33.3861 94.0483 35.2296 90.3284C36.1312 88.5076 36.5547 86.612 37.8236 84.9703C39.1451 83.2591 40.9008 81.9148 42.3102 80.2731H42.312V80.2713Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M45.0106 82.0064C47.1219 79.0255 48.571 75.655 49.8391 72.2549C51.6202 67.4792 53.9879 61.9521 52.1893 56.8877C49.5704 49.5154 42.404 44.2214 36.1598 39.9779C34.7212 39.0005 31.1679 37.3292 30.4811 35.5796C29.6591 33.4804 31.9337 31.423 30.8289 29.049C29.9349 27.1273 27.4828 26.2872 25.6719 25.4924C22.7439 24.2089 19.8246 22.9828 17.0494 21.3863C13.3819 19.2767 10.0411 16.6697 6.97258 13.7757C4.83671 11.7618 2.37064 7.72869 -0.232439 6.39475C-2.48072 5.24343 -4.18624 6.71998 -6.44506 6.32519C-8.27705 6.00518 -10.0809 4.54603 -11.5898 3.5408C-15.6121 0.859014 -19.4745 -2.13234 -24.2398 -3.39497C-28.905 -4.63151 -32.6937 -4.1515 -37.3114 -3.26453C-39.8776 -2.77235 -42.6441 -2.66278 -45.1909 -3.37584C-48.2595 -4.23498 -50.5025 -6.14805 -53.896 -5.65239C-59.4974 -4.83325 -62.0759 0.923363 -65.5572 4.65212C-67.8177 7.07303 -70.2908 8.50435 -73.6229 8.53565C-76.2505 8.56174 -77.9209 8.28869 -80.3765 9.52524C-84.4971 11.6001 -87.8994 15.1949 -90.9451 18.5636C-104.375 33.4195 -113.056 51.5746 -113.056 71.7123C-113.056 90.6274 -105.334 110.203 -91.7145 123.694C-78.0386 137.239 -58.2274 145.131 -38.9643 145.075C-19.4218 145.018 -0.415112 137.287 13.4241 123.584C16.6314 120.409 19.5401 116.95 22.1116 113.25C24.8271 109.342 26.7732 104.913 29.8663 101.269C32.5379 98.1214 36.3407 95.8518 38.6645 92.4292C39.9028 90.6048 40.3086 88.41 41.4748 86.5438C42.4901 84.9212 43.9005 83.5664 45.0071 82.0029L45.0106 82.0064Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M47.7556 83.7058C49.7709 80.0822 51.2397 76.1209 52.575 72.1945C54.158 67.5371 56.1908 62.7943 54.4514 57.928C51.5981 49.9394 44.2101 44.0967 37.8113 38.9276C36.3319 37.7319 33.7527 36.2699 33.5946 34.2545C33.3785 31.4976 35.5096 29.7833 33.2959 27.1308C31.7427 25.2703 28.8701 24.6159 26.6809 23.8588C23.6027 22.7954 20.5667 21.7093 17.7591 20.0333C14.2206 17.9221 11.1951 15.0974 8.71251 11.8393C6.71309 9.21641 5.59919 5.93219 2.52275 4.38145C-0.390285 2.91251 -2.52323 3.962 -5.48019 3.63131C-7.4515 3.41028 -9.49133 2.05621 -11.1604 1.09722C-15.2858 -1.27327 -19.1651 -3.99532 -23.8738 -5.12487C-28.651 -6.27009 -32.836 -5.96377 -37.615 -5.28152C-40.2469 -4.90558 -43.0159 -4.79071 -45.6337 -5.39116C-48.5889 -6.06994 -50.9187 -7.65897 -54.0618 -7.24822C-59.8229 -6.49461 -62.62 -0.972167 -66.6838 2.43041C-71.2273 6.23328 -76.4789 4.51546 -81.444 7.14354C-85.8927 9.49836 -89.5138 13.397 -92.8362 17.0832C-106.685 32.4479 -115.571 50.8601 -115.571 71.6793C-115.571 112.862 -80.8221 147.678 -38.9696 147.561C-18.7663 147.504 0.885264 139.505 15.1922 125.332C18.6095 121.947 21.7158 118.243 24.4285 114.28C27.1061 110.369 28.9597 105.785 32.2452 102.325C35.2988 99.107 39.8212 97.3492 42.5638 93.877C44.7758 91.0783 45.9899 86.8804 47.7556 83.7041V83.7058Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M50.4149 85.3242C51.9463 81.4743 53.3653 77.5741 54.6982 73.6477C56.3227 68.8659 58.4828 64.3135 56.7881 59.3056C53.8605 50.653 46.3178 44.334 40.0359 38.0724C38.1867 36.2292 37.0381 35.3493 37.2963 32.8315C37.5896 29.9606 38.2868 28.0183 35.8685 25.7682C31.4166 21.628 23.8124 22.1809 18.6334 19.004C12.025 14.9507 10.6306 6.88939 4.12218 2.7909C1.52831 1.15811 -0.751191 0.97727 -3.70859 0.91641C-6.29016 0.864244 -8.2957 0.0574118 -10.6138 -1.10067C-14.6882 -3.13687 -18.5359 -5.58171 -23.037 -6.62155C-28.0772 -7.78659 -32.8839 -7.63183 -37.9662 -7.09452C-40.6637 -6.80935 -43.4806 -6.64068 -46.1764 -7.10148C-49.3181 -7.63878 -51.9401 -8.98466 -55.2242 -8.35693C-60.6226 -7.32405 -63.6152 -2.43785 -67.9336 0.39823C-71.1105 2.48312 -73.8431 2.07275 -77.3063 2.84828C-79.368 3.30908 -81.3648 4.34197 -83.1455 5.42701C-87.5746 8.12399 -91.2099 11.9304 -94.6608 15.7315C-108.961 31.4786 -118.011 50.2792 -118.011 71.6967C-118.011 114.139 -82.1147 150.105 -38.9567 149.972C-18.0969 149.907 2.17107 141.67 16.9422 127.046C20.3246 123.699 23.3962 120.046 26.1148 116.151C28.9334 112.113 30.795 107.126 34.2599 103.603C37.6405 100.167 42.5877 98.9881 45.9823 95.6739C48.5481 93.1699 49.1048 88.6245 50.4167 85.3276V85.3242H50.4149Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M52.9968 86.6802C55.1553 77.9912 62.0666 68.2041 58.7137 59.3394C55.5136 50.8749 48.7077 44.5039 43.0839 37.6404C42.3321 36.7233 40.9657 35.4633 40.6759 34.226C40.3387 32.7868 41.1923 31.6087 41.2625 30.126C41.3784 27.6897 40.2754 25.7354 38.3838 24.2388C32.9619 19.9456 25.3587 21.4788 19.7014 17.8382C16.5681 15.823 15.3088 12.7585 13.3311 9.7844C11.3446 6.79816 8.891 3.81887 6.01935 1.61226C0.95049 -2.28065 -4.65933 -1.15994 -10.2112 -3.57365C-14.4282 -5.40612 -18.3625 -7.64406 -22.9273 -8.55768C-28.0681 -9.58442 -33.1581 -9.57746 -38.3622 -9.20157C-41.4007 -8.9823 -44.4936 -8.76825 -47.5286 -9.13718C-50.1878 -9.45912 -52.7064 -10.1587 -55.4094 -9.75323C-61.2493 -8.87963 -64.7129 -4.12008 -69.7677 -1.69245C-72.3899 -0.432521 -75.1351 -0.596104 -77.8663 0.183522C-80.2092 0.853513 -82.4047 2.03339 -84.4403 3.34379C-89.0262 6.29349 -92.8656 10.209 -96.5381 14.1855C-111.29 30.1643 -120.527 49.7628 -120.527 71.6568C-120.527 115.532 -83.239 152.515 -38.9769 152.458C-17.4773 152.43 3.45155 143.889 18.6862 128.792C22.278 125.233 25.5431 121.34 28.4059 117.181C31.1617 113.177 33.0129 107.932 36.4764 104.507C40.2052 100.82 45.822 100.303 49.6597 96.918C52.6174 94.3094 52.1239 90.1972 52.9985 86.6802H52.9968Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M55.5571 87.7065C56.6605 77.9716 65.0063 69.0837 60.807 59.1975C57.0751 50.4103 49.9803 43.9882 45.3277 35.8376C43.7201 33.0204 45.21 31.5579 44.7971 28.5286C44.4229 25.781 42.4164 23.9011 40.1604 22.4578C37.3509 20.6614 34.0337 19.538 30.8008 18.7833C27.6821 18.0564 23.8272 18.3642 21.0511 16.5504C18.2469 14.7192 16.5408 10.9682 14.5888 8.35973C12.5278 5.60517 10.3052 2.91842 7.70307 0.642076C4.98497 -1.73513 1.78368 -3.69149 -1.82347 -4.35927C-4.63118 -4.87923 -7.0699 -4.9314 -9.77746 -5.98523C-13.8959 -7.58684 -17.7578 -9.52408 -22.1381 -10.3605C-27.569 -11.397 -33.1176 -11.6004 -38.6276 -11.3761C-44.4363 -11.1396 -50.7932 -11.9395 -56.4929 -10.9205C-62.1171 -9.91535 -66.0107 -5.9661 -71.1868 -4.00104C-73.666 -3.06024 -76.2857 -3.05676 -78.7807 -2.13336C-81.1526 -1.25516 -83.3946 -0.0500422 -85.5012 1.33594C-90.2926 4.48699 -94.3812 8.57363 -98.3046 12.702C-113.647 28.8451 -122.966 49.3304 -122.966 71.6626C-122.966 116.85 -84.5366 154.926 -38.8982 154.868C-16.7335 154.84 4.83913 146.043 20.5468 130.498C24.1522 126.93 27.4343 123.034 30.3316 118.88C33.2659 114.671 35.1863 109.001 38.7811 105.373C42.643 101.476 48.3604 101.453 52.6527 98.3961C56.5902 95.5911 55.1073 91.6575 55.5571 87.7048V87.7065Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M57.7864 88.2529C57.8461 84.1629 59.8018 79.9842 61.2637 76.1918C62.3689 73.3265 64.7656 69.4367 64.8728 66.2618C64.9431 64.1759 64.0083 61.7734 63.2281 59.9033C59.5154 51.0136 52.2023 44.0132 48.486 35.2592C47.2578 32.3662 48.9956 29.9324 48.0344 27.0341C47.061 24.0993 44.03 22.163 41.4962 20.6895C38.5004 18.9481 35.2058 17.5721 31.9042 16.5213C29.0489 15.6115 24.9513 15.9211 22.4123 14.6251C19.7573 13.2699 17.8104 9.55047 16.0076 7.29065C13.6232 4.30365 11.1457 1.36535 8.25878 -1.16238C5.69165 -3.41002 2.81526 -5.37237 -0.45823 -6.45966C-3.42599 -7.44431 -6.52376 -7.49302 -9.48273 -8.55421C-13.6928 -10.0642 -17.6234 -11.8387 -22.0742 -12.5902C-27.6143 -13.5262 -33.2951 -14.0428 -38.9073 -14.0185C-44.9412 -13.9924 -51.4284 -13.6271 -57.3727 -12.5172C-62.8233 -11.4977 -67.0421 -8.295 -72.1974 -6.60405C-77.7464 -4.78436 -82.5679 -3.58747 -87.4878 -0.156854C-92.1213 3.07196 -96.1732 7.03665 -100.121 11.0379C-116.109 27.2498 -125.482 48.9504 -125.482 71.653C-125.482 118.203 -85.8923 157.274 -38.9213 157.354C-16.158 157.392 6.17835 148.195 22.2822 132.251C26.053 128.517 29.4846 124.445 32.4981 120.089C35.5449 115.684 37.4601 109.698 41.1518 105.874C45.1721 101.711 51.5205 102.311 55.9239 98.9831C59.9547 95.9352 57.7284 92.1741 57.7864 88.2546V88.2529Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M60.3311 87.4232C60.4629 83.4094 62.2334 79.34 63.691 75.6324C66.1401 69.4091 68.2851 66.4774 65.6513 60.1984C63.3973 54.8241 60.1886 49.8308 57.1751 44.8514C55.2429 41.6606 51.519 37.2119 51.1762 33.3634C50.9002 30.27 52.5863 28.2779 50.6962 25.2958C49.0875 22.7574 45.9386 21.0228 43.4367 19.5387C37.5274 16.0365 31.2947 12.5394 24.4888 11.5216C21.1606 11.024 19.3883 8.32732 17.1994 5.83067C14.476 2.72683 11.7719 -0.408338 8.63353 -3.11898C6.03318 -5.36336 3.16734 -7.29108 -0.0395798 -8.57333C-2.99508 -9.75467 -6.14925 -10.0417 -9.13288 -11.103C-18.9523 -14.6001 -28.4272 -16.5069 -38.971 -16.5052C-45.2107 -16.5052 -51.4347 -15.8528 -57.5409 -14.5879C-63.269 -13.4013 -68.1304 -10.7829 -73.6475 -9.0918C-85.081 -5.58605 -93.5695 0.988742 -101.974 9.3225C-118.543 25.7534 -128.074 48.4929 -128.074 71.6673C-128.074 119.563 -87.3279 159.76 -38.971 159.84C-15.5362 159.878 7.45203 150.417 24.0317 134.012C28.0192 130.066 31.6253 125.753 34.7917 121.137C37.8756 116.644 39.7955 110.792 43.2662 106.681C47.0832 102.159 53.4407 102.828 57.9856 99.6455C62.5165 96.472 60.1887 91.8197 60.3328 87.4232H60.3311Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M62.1536 86.0135C62.3276 81.4752 64.3836 76.9125 66.0706 72.7567C68.5045 66.7595 69.239 64.4956 66.8192 58.5123C64.8774 53.7114 62.187 49.2113 59.6372 44.7095C57.8588 41.5727 54.2053 36.6953 54.2071 32.8734C54.2088 29.7783 55.9943 28.1073 53.9066 25.113C51.9314 22.2805 48.155 20.3938 45.3011 18.6133C41.6934 16.3633 38.0821 14.1254 34.5728 11.7258C31.6381 9.71922 30.0547 8.54551 26.5155 8.15776C22.7426 7.74565 20.8623 6.62064 18.2123 3.96372C15.3707 1.11553 12.7032 -1.89438 9.65599 -4.53739C4.04668 -9.40435 -1.84906 -11.098 -8.63927 -13.5671C-18.5382 -17.1665 -28.3316 -18.9157 -38.9685 -18.9157C-51.5526 -18.9157 -62.8046 -15.9701 -74.4572 -11.6492C-85.9149 -7.39949 -95.0493 -0.95889 -103.7 7.61524C-120.729 24.487 -130.513 47.8655 -130.513 71.6665C-130.513 120.879 -88.658 162.17 -38.9668 162.25C-14.8777 162.289 8.71583 152.565 25.7669 135.719C29.7156 131.818 33.3093 127.549 36.4724 123C39.8447 118.15 41.9938 112.703 45.1306 107.778C48.4853 102.511 55.0401 103.132 59.5915 99.5989C64.2887 95.9526 61.9744 90.8961 62.1606 86.0135H62.1536Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M63.4788 84.2473C63.6194 79.734 65.5088 75.2763 67.1521 71.123C69.3543 65.5574 69.8024 63.1102 67.7444 57.4855C65.8867 52.4104 63.2257 47.6483 60.8601 42.7976C59.3521 39.7069 56.447 35.1657 57.3152 31.5777C58.1061 28.3096 59.136 27.1513 56.403 24.412C53.6033 21.6066 49.7543 19.6221 46.4958 17.408C42.6169 14.7731 38.8154 11.9885 35.462 8.71003C32.8504 6.15681 31.5796 5.75157 28.0927 4.99673C24.1734 4.14798 21.4035 3.62968 18.3894 0.956456C15.4455 -1.65416 12.7618 -4.52565 9.70541 -7.01626C4.25355 -11.4583 -1.78709 -13.5819 -8.22844 -16.1404C-17.9827 -20.0137 -28.4875 -21.4016 -38.9817 -21.4016C-63.9826 -21.4016 -87.8551 -11.6096 -105.483 5.85766C-122.978 23.1928 -133.029 47.21 -133.029 71.6673C-133.029 122.233 -90.0362 164.658 -38.9817 164.736C-14.241 164.775 10.0165 154.793 27.5162 137.475C31.6289 133.405 35.3671 128.965 38.6519 124.215C42.1705 119.126 44.4711 113.595 47.5117 108.282C50.5645 102.948 57.28 102.829 61.4085 98.737C65.5369 94.6428 63.3259 89.2338 63.4806 84.2491H63.4788V84.2473Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M64.4551 82.2275C64.5289 77.7722 66.186 73.4456 67.7324 69.3102C69.7955 63.7911 69.8165 60.8464 67.815 55.2404C66.0823 50.3906 63.7469 45.7806 61.7998 41.0177C60.2693 37.2717 58.9337 34.214 60.4256 30.4471C61.6557 27.3425 61.7664 26.6229 58.9794 24.2848C55.4965 21.3628 51.395 19.1482 47.7662 16.3982C43.4117 13.0989 40.1748 9.04867 36.3035 5.3374C34.4531 3.5626 31.8611 2.96984 29.4536 2.19456C26.352 1.19504 22.7022 0.539701 19.887 -1.11168C16.7521 -2.94906 14.1425 -6.08669 11.3238 -8.35169C5.85518 -12.7461 -0.349751 -15.4561 -6.77962 -18.1765C-16.8506 -22.4354 -28.0515 -23.8121 -38.9466 -23.8121C-64.4939 -23.8121 -89.1642 -13.7109 -107.197 4.15362C-125.153 21.9399 -135.468 46.575 -135.468 71.6673C-135.468 123.547 -91.3485 167.067 -38.9466 167.147C-13.5539 167.185 11.3396 156.948 29.3025 139.179C38.1855 130.392 44.3747 120.308 49.8222 109.216C52.5109 103.739 58.4891 102.585 62.327 98.2198C66.4549 93.5247 64.3655 87.7622 64.4568 82.2292H64.4551V82.2275Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M65.1945 80.1289C65.1945 75.2429 66.8657 70.5814 68.4648 66.0119C69.9286 61.8304 69.2749 58.2855 67.9587 54.1145C65.6303 46.7429 59.3604 37.7954 62.5463 30.1629C63.4355 28.0322 63.736 26.9103 62.1562 25.0926C60.9015 23.6489 58.6751 22.6557 57.0883 21.5442C54.781 19.9248 52.5071 18.2515 50.3351 16.4565C47.5076 14.1204 44.8489 11.6053 42.4485 8.83789C40.8511 6.99587 39.5578 4.8164 37.6617 3.27529C35.6742 1.65939 32.9715 0.631405 30.6291 -0.340919C27.227 -1.75157 23.477 -2.81608 20.2208 -4.54157C17.0032 -6.24792 14.3867 -8.91268 11.4801 -11.0782C6.4403 -14.8336 0.614955 -17.721 -5.15943 -20.2066C-15.7294 -24.7551 -27.5084 -26.298 -38.9745 -26.298C-92.9438 -26.298 -137.984 18.2759 -137.984 71.705C-137.984 125.134 -92.8506 169.63 -38.9745 169.708C-12.916 169.746 12.5925 159.235 31.035 141.004C40.2326 131.913 46.7309 121.403 52.254 109.854C54.8091 104.508 59.9754 102.284 63.2738 97.589C67.059 92.2003 65.191 86.1507 65.191 80.1341L65.1945 80.1289Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M65.9491 77.9504C65.8753 73.192 67.16 68.7382 68.5168 64.2078C69.6768 60.3353 69.0687 56.7813 68.0089 52.9332C66.8103 48.5803 65.1037 44.3824 63.877 40.04C62.7258 35.9638 62.7943 33.83 64.1617 29.9697C65.0457 27.4705 64.9543 26.3113 63.0509 24.5065C59.281 20.9316 54.6851 18.2269 50.9205 14.5581C46.829 10.5724 43.8904 5.39982 39.3542 1.92763C37.022 0.141929 34.4912 -1.31483 31.8619 -2.62538C28.7458 -4.1796 25.5296 -5.53019 22.3871 -7.03046C18.7227 -8.77787 15.8263 -10.9987 12.5538 -13.3378C7.46047 -16.9789 1.77839 -20.0595 -3.99156 -22.5239C-14.9304 -27.1936 -27.1364 -28.8592 -38.9874 -28.8592C-94.3054 -28.8592 -140.5 16.8833 -140.5 71.6674C-140.5 126.451 -94.3019 172.194 -38.9874 172.194C-12.2097 172.194 13.8561 161.499 32.7899 142.747C41.9378 133.688 49.2667 122.939 54.3846 111.176C56.8996 105.396 61.8154 101.739 64.6134 96.2303C67.5765 90.3929 66.0458 84.1464 65.9508 77.9504H65.9491Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M66.9313 75.9119C66.8118 71.3108 67.6801 67.0402 68.7205 62.5853C69.5642 58.9757 69.0299 55.4427 68.1792 51.8801C67.202 47.7905 65.7713 43.8191 64.817 39.7243C63.787 35.3024 64.6851 33.4619 65.6061 29.3601C66.1703 26.8465 65.52 25.5523 63.6921 23.8614C60.6163 21.0156 57.5441 18.3732 54.6599 15.3064C50.3239 10.6966 46.8703 5.61545 41.9895 1.54667C39.2512 -0.7356 36.3582 -2.72911 33.2473 -4.48082C26.7214 -8.15472 20.0654 -11.1937 13.8893 -15.4503C8.70971 -19.0199 3.18036 -22.1893 -2.62493 -24.6525C-13.9666 -29.464 -26.6546 -31.2697 -38.9348 -31.2697C-95.6115 -31.2697 -142.94 15.5726 -142.94 71.6674C-142.94 127.762 -95.6115 174.604 -38.9348 174.604C-11.5183 174.604 15.2303 163.663 34.6077 144.455C43.8666 135.277 51.3697 124.396 56.5721 112.487C57.8692 109.518 58.9923 106.672 60.7728 103.939C62.4056 101.431 64.2088 99.0546 65.4163 96.2975C68.2952 89.7238 67.1106 82.8196 66.9313 75.9119Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M68.1452 74.1137C67.9203 66.2024 70.073 58.7903 68.4738 50.9294C67.6303 46.778 66.242 42.7397 65.5479 38.5586C64.9205 34.7726 66.307 32.3002 66.5847 28.716C66.7587 26.461 65.9204 24.9873 64.4232 23.3884C61.3866 20.1417 58.3025 17.3526 55.8053 13.5875C53.5454 10.1807 51.6563 7.60564 48.5968 4.83396C46.8693 3.26978 45.1349 1.71082 43.3529 0.209277C40.6168 -2.09436 37.7506 -4.22054 34.7087 -6.11008C28.1293 -10.1937 21.1826 -13.4908 14.7719 -17.8597C9.88651 -21.1916 4.80083 -24.1999 -0.645105 -26.5453C-12.6037 -31.6955 -25.9927 -33.7555 -38.9775 -33.7555C-97.0007 -33.7555 -145.455 14.2173 -145.455 71.6674C-145.455 129.118 -97.0025 177.09 -38.9775 177.09C-10.8903 177.09 16.4519 165.875 36.3114 146.21C45.9011 136.716 53.6368 125.424 58.9685 113.076C61.5184 107.169 64.9609 101.293 66.894 95.1719C69.0555 88.334 68.3455 81.1534 68.1452 74.112V74.1137Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M70.017 72.6656C69.8641 65.2034 70.7042 57.7515 69.3527 50.3675C68.59 46.199 67.2227 42.1053 66.6884 37.8967C66.2877 34.7421 67.3053 32.0448 67.4072 28.9354C67.5004 26.0521 66.4318 24.273 64.59 22.1322C61.8168 18.9115 59.1138 15.8751 56.917 12.2161C52.033 4.07912 44.3828 -2.18321 36.4849 -7.44385C30.0526 -11.7271 23.1335 -15.1113 16.7961 -19.5546C11.8594 -23.0171 6.71183 -26.1161 1.15125 -28.5038C-11.3636 -33.8775 -25.3283 -36.166 -38.9204 -36.166C-98.3065 -36.166 -147.895 12.9031 -147.895 71.6674C-147.895 130.432 -98.3065 179.501 -38.9204 179.501C-10.1914 179.501 17.8312 168.039 38.1351 147.918C48.0419 138.101 56.026 126.423 61.4899 113.641C64.1507 107.415 67.1014 100.793 68.6603 94.2021C70.3193 87.1919 70.1612 79.8184 70.0135 72.6656H70.017Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
          <path
            d="M72.5 71.6918C72.4947 63.9948 71.9341 56.0631 70.435 48.5018C69.7654 45.129 68.79 41.8085 68.2223 38.4166C67.6371 34.9152 68.4297 31.6155 68.1467 28.1332C67.9112 25.2457 66.6916 23.1689 64.9552 20.8798C62.363 17.4618 60.08 14.0195 57.8112 10.3859C52.9887 2.6576 45.7796 -3.42168 38.3719 -8.64342C31.894 -13.2094 24.9239 -16.9735 18.4459 -21.5708C13.6182 -24.9975 8.60237 -28.0241 3.15601 -30.3984C-9.97043 -36.1193 -24.6575 -38.6519 -38.9474 -38.6519C-99.6886 -38.6519 -150.411 11.5495 -150.411 71.6674C-150.411 131.785 -99.6886 181.987 -38.9474 181.987C21.7938 181.987 72.5386 131.759 72.5 71.6918Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeOpacity="0.45"
            strokeWidth="0.25"
          />
        </g>
      </g>
      <g filter="url(#filter0_d_32_74)">
        <path
          d="M31.4919 33.9405L26.8649 38.5676C25.2647 40.1678 23.3367 40.9675 21.0811 40.9667C18.8254 40.9659 16.8975 40.1662 15.2973 38.5676C13.6971 36.9689 12.8974 35.041 12.8982 32.7838C12.8989 30.5266 13.6986 28.5986 15.2973 27L19.9243 22.373L22.2378 24.6865L17.6108 29.3135C16.6468 30.2775 16.1648 31.4342 16.1648 32.7838C16.1648 34.1333 16.6468 35.2901 17.6108 36.2541C18.5748 37.218 19.7315 37.7 21.0811 37.7C22.4306 37.7 23.5874 37.218 24.5513 36.2541L29.1784 31.627L31.4919 33.9405ZM23.3946 32.7838L21.0811 30.4703L30.3351 21.2162L32.6486 23.5297L23.3946 32.7838ZM33.8054 31.627L31.4919 29.3135L36.1189 24.6865C37.0829 23.7225 37.5649 22.5658 37.5649 21.2162C37.5649 19.8667 37.0829 18.7099 36.1189 17.7459C35.1549 16.782 33.9982 16.3 32.6486 16.3C31.2991 16.3 30.1423 16.782 29.1784 17.7459L24.5513 22.373L22.2378 20.0595L26.8649 15.4324C28.465 13.8322 30.3933 13.0322 32.6498 13.0321C34.9062 13.0322 36.8338 13.8322 38.4324 15.4324C40.0311 17.0326 40.8312 18.9609 40.8327 21.2174C40.8342 23.4738 40.0341 25.4014 38.4324 27L33.8054 31.627Z"
          fill="#F8F8F8"
        />
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="67.9345"
        id="filter0_d_32_74"
        width="67.9345"
        x="-7.10184"
        y="-6.96786"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_32_74" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_32_74" mode="normal" result="shape" />
      </filter>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="rotate(45) scale(76.3675 124.451)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_32_74"
        r="1"
      >
        <stop stopColor="#00FF00" />
        <stop offset="1" stopColor="#00BD00" />
      </radialGradient>
      <clipPath id="clip0_32_74">
        <rect fill="white" height="54" width="54" />
      </clipPath>
      <clipPath id="clip1_32_74">
        <rect fill="white" height="54" transform="translate(0.5)" width="53" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkIcon;

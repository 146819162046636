import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { addPostBackVariables, removePostBackVariable } from '../../../../actions/postbacks-actions.js';

import messages from '../../../../i18n/base-en.js';

import { Checkbox } from '../../../reusables';
import { closeModalAnimation } from '../../../Modal/modal-actions/modal-actions';
import consts from '../../../../utils/consts';
import { connect } from 'react-redux';
import useClickOutside from '../../../reusables/colorpicker/useClickOutside.js';

const { POSTBACKS_VARIABLES } = consts;
const variables = POSTBACKS_VARIABLES.map((postback) => postback.id);

const OfferPostbacksAddVariablesModal = (props) => {
  const { fields, handleSubmit, search, values, dispatch, postbacksVariables, modalInner } = props;
  const regex = new RegExp(search, 'i');

  const handleOnClick = (e) => {
    const strippedValues = Object.values(values)
      .flat()
      .filter((val) => val !== undefined);
    const totalVariables = postbacksVariables.concat(strippedValues);
    dispatch(addPostBackVariables(totalVariables));
    handleSubmit();
  };

  const removeVariable = (name) => {
    dispatch(removePostBackVariable(name));
  };

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <div id="postbacks-add-variables-modal">
      <div className="variables">
        {POSTBACKS_VARIABLES.filter((variable) => regex.test(variable.id)).map((variable, index) => (
          <Checkbox
            className="col-md-3 addFiltersCheckbox"
            field={fields[variable.id]}
            id={`var-${variable.id}`}
            isAdded={postbacksVariables}
            key={index}
            label={variable.defaultMessage}
            onClick={() => removeVariable(variable.variable)}
            value={variable.variable}
          />
        ))}
      </div>
      <div className="actions-section">
        <button
          className="filter-btn waves-effect waves-light btn pull-right"
          onClick={() => {
            handleOnClick();
          }}
        >
          <FormattedMessage {...messages.offerAddVariables} />
        </button>
      </div>
    </div>
  );
};

OfferPostbacksAddVariablesModal.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  search: PropTypes.string,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  postbacksVariables: state.postbacks.postbacksVariables,
  form: state.form.addPostback,
}))(
  injectIntl(
    reduxForm({
      form: 'addPostbackVariables',
      fields: ['filtersSearch', ...variables],
    })(OfferPostbacksAddVariablesModal)
  )
);

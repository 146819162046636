import React, { useEffect, useState } from 'react';

const AnimatedNumber = ({ value, duration = 1000, delay = 0 }) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    let animationFrame;

    const startAnimation = () => {
      const start = 0;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // Ensures progress doesn't exceed 1
        const currentValue = Math.floor(start + progress * (value - start));
        setDisplayValue(currentValue);

        if (progress < 1) {
          animationFrame = requestAnimationFrame(animate);
        }
      };

      animationFrame = requestAnimationFrame(animate);
    };

    const delayTimeout = setTimeout(() => {
      startAnimation();
    }, delay);

    return () => {
      clearTimeout(delayTimeout);
      cancelAnimationFrame(animationFrame);
      setDisplayValue(value);
    };
  }, [value, duration, delay]);

  const formatNumber = (num) => new Intl.NumberFormat('en-US').format(num);

  return <>{formatNumber(displayValue)}</>;
};

export default AnimatedNumber;

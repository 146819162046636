import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';
import { CustomColorPicker } from '../../../components/reusables/colorpicker/CustomColorPicker.react';
import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { genericTextPercentage, LiveCamWidgetPage } = messages;

const ThumbnailsSettingsColorFilter = ({ fields: { colorFilter, colorFilterFlag, colorFilterOpacity } }) => {
  const disabled = colorFilterFlag.value === '0';
  const [color, setColor] = useState('#aabbcc');
  const concurrentCalls = (e) => {
    colorFilter.onChange(e);
    setColor(e);
  };

  return (
    <div className="settings-group" id="color-filter-section">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.thumbnailsSettings.colorFilterSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={colorFilterFlag}
          id="widget-color-filter-flag-on"
          label={LiveCamWidgetPage.fields.genericChoices.on}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `filter on`);
          }}
          value="1"
        />
        <Radio
          field={colorFilterFlag}
          id="widget-color-filter-flag-off"
          label={LiveCamWidgetPage.fields.genericChoices.off}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `filter off`);
          }}
          value="0"
        />
      </div>
      <CustomColorPicker
        color={color}
        disabled={disabled}
        field={colorFilter}
        handleChange={setColor}
        input
        label={LiveCamWidgetPage.fields.color.label}
        labelClasses="active"
        onChange={(e) => concurrentCalls(e)}
      />

      <div>
        <div className={`with-units ${disabled ? 'disabled' : ''}`}>
          <InputText
            disabled={disabled}
            field={colorFilterOpacity}
            id="widget-color-filter-opacity"
            label={LiveCamWidgetPage.fields.colorFilterOpacity.label}
            labelClasses="active"
            max={100}
            min={0}
            type="number"
          />

          <span className="unit-label">
            <FormattedHTMLMessage {...genericTextPercentage} />
          </span>
        </div>
      </div>
    </div>
  );
};

ThumbnailsSettingsColorFilter.propTypes = {
  fields: PropTypes.shape({
    colorFilter: PropTypes.object,
    colorFilterFlag: PropTypes.object,
    colorFilterOpacity: PropTypes.object,
  }),
};

export default injectIntl(ThumbnailsSettingsColorFilter);

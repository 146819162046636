import CustomAudienceTargetingIcon from '../reusables/svg/Jerkmate2/CustomAudienceTargeting';
import CustomCreativesIcon from '../reusables/svg/Jerkmate2/CustomCreatives';
import ExpertTipsIcon from '../reusables/svg/Jerkmate2/ExpertTips';
import TrafficPayoutBumpsIcon from '../reusables/svg/Jerkmate2/TrafficPayoutBumps';
import parse from 'react-html-parser';
import { Gtm } from '../../utils/gtm';
const JerkmateGetInTouch = () => {
  const benefits = [
    {
      icon: <CustomAudienceTargetingIcon />,
      description: 'Custom audience targeting',
    },
    {
      icon: <CustomCreativesIcon />,
      description: 'Custom creatives',
    },
    {
      icon: <ExpertTipsIcon />,
      description: 'Expert tips and recommendations',
    },
    {
      icon: <TrafficPayoutBumpsIcon />,
      description: 'Traffic quality-based payout bumps',
    },
  ];
  const showIntercom = () => {
    window.Intercom('showNewMessage');
    Gtm.event(`jerkmate page`, 'Click', 'need_help');
  };
  return (
    <div className="get-in-touch-box">
      <div className="description-box">
        <h2 className="jerkmate-page-title">Let us help you make the most of every click!</h2>
        <p>
          Our dedicated Affiliate Managers and Customer Experience Team can help optimize your campaigns to ensure the best
          possible performances and payouts.
        </p>
        <div className="button-box">
          <div className="need-help">Need help?</div>
          <div className="jerkmate-btn pink" onClick={() => showIntercom()}>
            Get in touch!
          </div>
        </div>
      </div>
      <div className="benefits-box">
        {benefits.map((benefit, key) => (
          <div className="benefit-box" key={key}>
            {benefit.icon}
            <p>{parse(benefit.description)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JerkmateGetInTouch;

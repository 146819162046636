import { FormattedMessage } from 'react-intl';
import messages from '../../../i18n/base-en.js';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';

const OfferRequestSentModal = ({ dispatch, modalInner }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="offer-requested-modal">
      <div className="modal-content">
        <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
          <i className="material-icons">close</i>
        </span>
      </div>
      <div className="offer-request-body">
        <div className="titleBox OfferRequestSentModal">
          <h2>
            <FormattedMessage {...messages.offerRequestSent} />
          </h2>
          <div className="subline">
            Our team will review your application within the next 2 business days. You will receive a notification of the status
            of your request directly in the platform.
          </div>
        </div>
        <div className="offer-request-questions">
          <div className="buttonBox">
            <button className="waves-effect waves-light btn" onClick={closeOpenModal}>
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferRequestSentModal;

function RankRisingStar() {
  return (
    <svg height="20px" viewBox="0 0 21 20" width="21px">
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g id="rising-star-aff" transform="translate(-1124.000000, -28.000000)">
          <g id="HEADER-Copy-6">
            <g id="Group-10" transform="translate(1118.000000, 20.000000)">
              <g id="Group-12" transform="translate(0.000000, 3.000000)">
                <g id="VIP" transform="translate(2.000000, 2.000000)">
                  <g id="Fichier-36">
                    <g id="Calque_2">
                      <g id="Calque_25">
                        <rect height="27.9571171" id="Rectangle-path" width="27.9571171" x="0" y="0" />
                        <path
                          d="M14.6306306,3.99315315 L16.6183784,10.1077477 C16.6956905,10.3448228 16.9164044,10.5055269 17.1657658,10.5063063 L23.5956757,10.5063063 C23.8452745,10.5073569 24.0659678,10.6686018 24.1428306,10.9060734 C24.2196934,11.1435451 24.1353387,11.4035253 23.9336937,11.5506306 L18.7322523,15.3344144 C18.5299988,15.4816286 18.445485,15.7422978 18.5228829,15.9801802 L20.5106306,22.0947748 C20.5873583,22.3324485 20.5026537,22.5925159 20.3006704,22.7394128 C20.0986872,22.8863097 19.8251735,22.8867647 19.6227027,22.7405405 L14.4212613,18.9567568 C14.2189091,18.8099108 13.9450548,18.8099108 13.7427027,18.9567568 L8.54126126,22.7405405 C8.33879042,22.8867647 8.06527681,22.8863097 7.86329354,22.7394128 C7.66131028,22.5925159 7.57660569,22.3324485 7.65333333,22.0947748 L9.64108108,15.9801802 C9.71847899,15.7422978 9.63396512,15.4816286 9.43171171,15.3344144 L4.23027027,11.5506306 C4.02862531,11.4035253 3.94427056,11.1435451 4.02113336,10.9060734 C4.09799617,10.6686018 4.31868947,10.5073569 4.56828829,10.5063063 L10.9981982,10.5063063 C11.2475596,10.5055269 11.4682735,10.3448228 11.5455856,10.1077477 L13.5333333,3.99315315 C13.6113689,3.75629279 13.832598,3.59625232 14.081982,3.59625232 C14.331366,3.59625232 14.552595,3.75629279 14.6306306,3.99315315 L14.6306306,3.99315315 Z"
                          fill="#FFFFFF"
                          id="Shape"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RankRisingStar;

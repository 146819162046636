import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConfirmEmail from '../ConfirmEmail.react';

const ConfirmEmailContainer = (props) => {
  const {
    location: {
      state: { email },
    },
    skinConfigurations,
  } = props;
  return <ConfirmEmail email={email} skinConfigurations={skinConfigurations} />;
};

ConfirmEmailContainer.propTypes = {
  location: PropTypes.object,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
}))(ConfirmEmailContainer);

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { browserHistory } from 'react-router';

const isMultiCpa = ({ conversionTypes }) => !!conversionTypes && conversionTypes.some((ct) => ct === 'Multi-CPA');

const isPercentPayout = ({ payoutType }) => payoutType === 'cpa_percentage';

const Payout = ({ className, conversionTypes, defaultPayout, payoutType, percentPayout, emptyPayoutText }) => {
  const [payoutLength, setPayoutLength] = useState(0);

  const payoutRef = useRef(null);

  const defaultPayoutNum = parseFloat(defaultPayout);
  const payoutNumber = defaultPayoutNum ? defaultPayout : percentPayout;

  useEffect(() => {
    if (browserHistory.getCurrentLocation().pathname === '/jerkmate') {
      const length = payoutRef?.current?.textContent?.length || 0;
      setPayoutLength(length);
    }
  }, []);

  const emptyText = emptyPayoutText || '';

  return (
    <>
      {!isMultiCpa({
        conversionTypes,
      }) ? (
        <span className={`payout ${className || ''} payoutLength${payoutLength}`} ref={payoutRef}>
          {isPercentPayout({ payoutType, percentPayout }) ? (
            <FormattedNumber minimumFractionDigits={2} style="percent" useGrouping={false} value={payoutNumber / 100} />
          ) : (
            <FormattedNumber currency="USD" minimumFractionDigits={2} style="currency" value={defaultPayout} />
          )}
        </span>
      ) : (
        emptyText
      )}
    </>
  );
};

Payout.propTypes = {
  className: PropTypes.string,
  conversionTypes: PropTypes.array,
  defaultPayout: PropTypes.any,
  payoutType: PropTypes.string,
  percentPayout: PropTypes.any,
  emptyPayoutText: PropTypes.string,
};

export default Payout;

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import { LiveCamWidgetTab } from '.';
import { CodesTypes } from './LiveCamWidgetConsts';
import { v4 as uuidv4 } from 'uuid';

const { LiveCamWidgetPage } = messages;

const LiveCamWidgetTabs = ({ intl, pathname, templateOptions, typeSettings }) => {
  const currentWidgetType = typeSettings && CodesTypes.find((type) => type.id === typeSettings.widgetType.value);
  const offerIsRequired = !typeSettings || !typeSettings.offers || typeSettings.offers.length === 0;
  const templateIsRequired = !templateOptions;
  let warning = offerIsRequired
    ? LiveCamWidgetPage.warnings.selectTypeFirst
    : templateIsRequired
    ? LiveCamWidgetPage.warnings.selectTemplateFirst
    : '';
  const tabs = [
    {
      id: 'typeSettings',
      icon: 'settings',
      isDisabled: false,
      pathname: '/live-cam-widget',
      title: LiveCamWidgetPage.typeSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'type tab');
      },
    },
    {
      id: 'templateSettings',
      icon: 'view_compact',
      isDisabled: offerIsRequired,
      pathname: '/live-cam-widget/template-settings',
      title: LiveCamWidgetPage.templateSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'template tab');
      },
    },
    {
      id: 'layoutSettings',
      icon: 'view_module',
      isDisabled: offerIsRequired || templateIsRequired,
      pathname: '/live-cam-widget/layout-settings',
      title: LiveCamWidgetPage.layoutSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'layout settings tab');
      },
    },
    {
      id: 'thumbnailsSettings',
      icon: 'account_box',
      isDisabled: offerIsRequired || templateIsRequired,
      pathname: '/live-cam-widget/thumbnails-settings',
      title: LiveCamWidgetPage.thumbnailsSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'thumbnails tab');
      },
    },
    {
      id: 'infobarSettings',
      icon: 'call_to_action',
      isDisabled: offerIsRequired || templateIsRequired,
      pathname: '/live-cam-widget/infobar-settings',
      title: LiveCamWidgetPage.infobarSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'infobar settings tab');
      },
    },
    {
      id: 'cssSettings',
      icon: 'code',
      isDisabled: offerIsRequired || templateIsRequired,
      pathname: '/live-cam-widget/custom-css',
      title: LiveCamWidgetPage.customCssSettings.title.text,
      gtmEvent: () => {
        Gtm.event('live cam widget', 'click', 'custom css tab');
      },
    },
  ];

  return (
    <ul className="tabs">
      {tabs.map((tab) => {
        const isBlockedByWidgetType = currentWidgetType && currentWidgetType.blockedTabs.includes(tab.id);
        warning = !isBlockedByWidgetType ? warning : LiveCamWidgetPage.warnings.blockedTab;

        return (
          <LiveCamWidgetTab
            gtmEvent={tab.gtmEvent}
            icon={tab.icon}
            isDisabled={isBlockedByWidgetType || tab.isDisabled}
            isSelected={pathname === tab.pathname}
            key={uuidv4()}
            pathname={tab.pathname}
            title={tab.title}
            warning={
              warning === ''
                ? warning
                : intl.formatMessage(warning, {
                    tab: intl.formatMessage(tab.title),
                  })
            }
          />
        );
      })}
    </ul>
  );
};

LiveCamWidgetTabs.propTypes = {
  intl: intlShape.isRequired,
  pathname: PropTypes.string.isRequired,
  templateOptions: PropTypes.object,
  typeSettings: PropTypes.object,
};

export default injectIntl(LiveCamWidgetTabs);

const ArrowIcon = ({ isVisible }) => (
  <svg
    className={isVisible ? 'arrow' : 'arrow rotate'}
    fill="none"
    height="14"
    viewBox="0 0 15 14"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0677 8.72638L7.57181 3.63935C7.47527 3.55 7.3458 3.5 7.21099 3.5C7.07619 3.5 6.94672 3.55 6.85018 3.63935L1.35221 8.72638C1.30719 8.768 1.27141 8.81776 1.24698 8.87272C1.22255 8.92769 1.20996 8.98674 1.20996 9.0464C1.20996 9.10606 1.22255 9.16512 1.24698 9.22008C1.27141 9.27505 1.30719 9.32481 1.35221 9.36643C1.44452 9.45206 1.56846 9.5 1.69751 9.5C1.82657 9.5 1.9505 9.45206 2.04282 9.36643L7.21099 4.58274L12.3781 9.36643C12.4704 9.45177 12.5941 9.49952 12.7229 9.49952C12.8517 9.49952 12.9754 9.45177 13.0677 9.36643C13.1127 9.32481 13.1485 9.27505 13.1729 9.22008C13.1974 9.16512 13.21 9.10606 13.21 9.0464C13.21 8.98674 13.1974 8.92769 13.1729 8.87272C13.1485 8.81776 13.1127 8.768 13.0677 8.72638Z"
      fill="#3D3D3D"
    />
  </svg>
);

export default ArrowIcon;

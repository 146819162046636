import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { v4 as uuidv4 } from 'uuid';

const { LiveCamWidgetPage, WidgetSection } = messages;

class LiveCamWidgetIFrame extends Component {
  constructor(props) {
    super(props);

    this.handleViewModeChange = this.handleViewModeChange.bind(this);

    this.devices = [
      { name: 'desktop', icon: 'desktop_windows' },
      { name: 'tablet', icon: 'tablet_mac' },
      { name: 'phone', icon: 'phone_iphone' },
    ];
    this.state = {
      viewMode: 'desktop',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { src } = this.props;
    const { viewMode } = this.state;

    return src !== nextProps.src || viewMode !== nextState.viewMode;
  }

  handleViewModeChange(e) {
    e.preventDefault();
    const { src } = this.props;
    const { viewMode } = this.state;

    if (src.length > 0) {
      const newViewMode =
        /* $("span").click() */ e.target.getAttribute('data-viewmode') ||
        /* $("i").click() */ e.target.parentNode.getAttribute('data-viewmode');

      if (viewMode !== newViewMode) {
        this.setState({
          viewMode: newViewMode,
        });
      }
    }
  }

  render() {
    const { src } = this.props;
    const { viewMode } = this.state;

    const iconClasses = src ? 'enabled' : 'disabled';

    return (
      <div className="demoIframe z-depth-2" id="widget-iframe">
        {src ? (
          <>
            <div className="widget-iframe-header">
              <div className="title">
                <FormattedMessage {...WidgetSection.widgetPreview.text} />:
              </div>
              <div className="devices">
                {this.devices.map((device) => (
                  <span
                    className={`device-icon ${device.name}-icon ${viewMode === device.name ? 'br-primary-color' : ''}`}
                    data-viewmode={device.name}
                    key={device.name}
                    onClick={this.handleViewModeChange}
                  >
                    <i className="material-icons">{device.icon}</i>
                  </span>
                ))}
              </div>
            </div>

            <div className="widget-iframe-wrapper">
              <div className={`widget-iframe-container ${iconClasses} ${viewMode}`}>
                {/* eslint-disable-next-line */}
                <iframe id="iframe" src={src} />{' '}
              </div>
            </div>

            <div className="widget-iframe-footer">
              <div>
                <strong>
                  <FormattedMessage {...WidgetSection.widgetPreview.text} />
                </strong>
              </div>
              <div>
                <FormattedMessage {...LiveCamWidgetPage.warnings.screenResolution} />
              </div>
            </div>
          </>
        ) : (
          <div className="widget-iframe-wrapper">
            <div className={`widget-iframe-container ${iconClasses} desktop`}>
              <div className="iframe-placeholder">
                <div className="placeholder-content livecam-generator-iframe-placeholder">
                  <div className="text">
                    <h3>
                      <FormattedHTMLMessage {...LiveCamWidgetPage.widgetPreview.emptyIFrame.header} />
                    </h3>

                    {LiveCamWidgetPage.widgetPreview.emptyIFrame.contents.map((content) => (
                      <p key={uuidv4()}>
                        <FormattedHTMLMessage {...content} />
                      </p>
                    ))}

                    <ul>
                      {LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros.map((pro) => (
                        <li key={uuidv4()}>
                          <FormattedHTMLMessage {...pro} />
                        </li>
                      ))}
                    </ul>

                    {LiveCamWidgetPage.widgetPreview.emptyIFrame.infos.map((info) => (
                      <div className="muted" key={uuidv4()}>
                        <FormattedHTMLMessage {...info} />
                      </div>
                    ))}
                  </div>
                  <div className="video">
                    <div
                      style={{
                        padding: '56.25% 0 0 0',
                        position: 'relative',
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      <iframe
                        allowFullScreen="true"
                        mozallowfullscreen="true"
                        src="https://player.vimeo.com/video/391530959"
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%',
                        }}
                        webkitallowfullscreen="true"
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

LiveCamWidgetIFrame.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LiveCamWidgetIFrame;

import { FormattedHTMLMessage } from 'react-intl';
import { Gtm } from '../../utils/gtm';

const MessageInfo = ({ className, message, restart, onClick, messageWithInternalLink }) => (
  <div className="message-info-box">
    <p className={`${className ? className : ''} `}>
      {message ? <FormattedHTMLMessage {...message} /> : messageWithInternalLink}
      {restart ? (
        <>
          <a
            onClick={() => {
              onClick();
              Gtm.event('profile - identity', 'Click', `Restart validation - Individual`);
            }}
          >
            here
          </a>
          <span>.</span>
        </>
      ) : null}
    </p>
  </div>
);

export default MessageInfo;

export default {
  MainNavigation: {
    items: {
      dashboard: {
        text: {
          id: 'mainNavigation.dashboard',
          description: 'Dashboard',
          defaultMessage: 'Dashboard',
        },
      },
    },
  },
};

import jQuery from 'jquery';
(function (jQuery) {
  window.requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();
  /**
   * Function declarations
   */
  function innerScrollingDetection(event) {
    const scrollTop = this.scrollTop;

    event.preventDefault();

    this.scrollTop = scrollTop + event.deltaY * event.deltaFactor * -1;
  }

  function innerScrollingLeftDetection(event) {
    const scrollLeft = this.scrollLeft;

    event.preventDefault();

    this.scrollLeft = scrollLeft + event.deltaY * event.deltaFactor * -1;
  }
  // Menu scroll
  window.crakrevenue = {
    initMainNavScrolling: function () {
      jQuery('#main-navigation').off('mousewheel').on('mousewheel', 'nav', innerScrollingDetection);
    },
    initTabsScrolling: function () {
      jQuery('.link-tabs').each(function () {
        const jQuerythis = jQuery(this);
        const jQuerynav = jQuerythis.find('nav');

        if (jQuerynav.find('.scroller-container').width() > jQuerythis.width()) {
          jQuerythis.off('mousewheel').on('mousewheel', 'nav', innerScrollingLeftDetection);

          const swipeObj = {
            swipeStatus: function (event, phase, direction, distance) {
              if (direction === 'left' || direction === 'right') {
                const jQueryscrollableNav = jQuery(event.target).closest('.link-tabs nav');
                const scrollPos = jQueryscrollableNav.scrollLeft();
                const pos = direction === 'left' ? distance / 4 : (distance / 4) * -1;

                jQueryscrollableNav.scrollLeft(scrollPos + pos);
              }
            },
            threshold: 20,
            maxTimeThreshold: 5000,
            fingers: 'all',
          };

          if (jQuery.fn.swipe) {
            jQuerythis.find('nav').swipe(swipeObj);
          }
        }
      });
    },
    resizingFnStack: {},
  };

  window.crakrevenue.resizingFnStackCalls = function () {
    const resizingFnStack = window.crakrevenue.resizingFnStack;

    Object.keys(resizingFnStack).forEach(function (key) {
      if (typeof resizingFnStack[key] === 'function') {
        resizingFnStack[key]();
      }
    });
  };

  jQuery('#main-navigation').on('mousewheel', 'nav', innerScrollingDetection);

  let tabNumero = 0;

  /**
   * Règle le bug de TAB pour les select
   */
  jQuery(document)
    .on('keydown', function (e) {
      const keyCode = e.keyCode || e.which;

      if (keyCode === 9 && jQuery(e.target).is('input')) {
        tabNumero = jQuery(e.target).attr('tabIndex');
      }
    })
    .on('keyup', function (e) {
      const keyCode = e.keyCode || e.which;

      if (keyCode === 9 && !jQuery(e.target).is('input')) {
        if (e.shiftKey) {
          tabNumero--;
        } else {
          tabNumero++;
        }

        jQuery('input[tabindex=' + tabNumero + ']').trigger('focus');
      }
    });

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    jQuery('body').addClass('mobile');
  }
})(jQuery);

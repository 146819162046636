export const globalSkinConfigurations = () => `
  Public {
    Skin {
      name
      components {
        name
        parameters {
          key
          property
          value
        }
      }
    }
  }
`;

export const skinConfigurations = () => `
  Skin {
    name
    components {
      name
      parameters {
        key
        property
        value
      }
    }
  }
`;

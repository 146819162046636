const Whale = () => (
  <svg className="whale-1" fill="none" height="88" viewBox="0 0 211 88" width="211" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_747)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M185.21 32.17C184.61 35.2 183.55 38.62 181.74 42.13C179.1 47.27 174.87 52.6 168.15 57.27C161.29 62.02 151.84 66.08 138.83 68.48C139.81 74.11 126.92 82.57 121.69 82.41C120.05 82.36 119.24 81.18 118.91 79.59C118.16 76.1 119.66 70.62 119.66 70.62C115.45 70.82 111.45 70.89 107.63 70.83C96.11 86.05 66.93 80.25 70.64 79.24C73.55 78.44 81.7 71.74 85.08 68.9C83.4 68.62 81.76 68.3 80.19 67.96C78.13 67.52 76.15 67.04 74.25 66.52C66.62 64.43 60.31 61.73 55.01 58.77C43.6 52.41 36.9 44.86 32.11 39.6C30.93 38.3 29.87 37.15 28.89 36.19C27.35 34.7 25.98 33.65 24.63 33.27C24.63 33.27 24.37 33.5 23.89 33.89C23.59 34.13 23.21 34.43 22.75 34.78C18.62 37.92 8.41 44.73 0.0300007 42.38C0.0300007 42.38 7.94 37.83 8.45 35.3C8.79 33.61 11.14 30.36 13.97 28.78C14.26 28.62 14.56 28.47 14.86 28.36C16.01 27.89 17.21 27.73 18.36 28.07C18.36 28.07 14.84 25.19 13.32 15.25C13.32 15.25 24.77 14.91 28.32 27.87C28.32 27.87 35.06 29.21 42.8 29.88C48.11 30.33 68.86 18.1 103.9 12.02C112.38 10.55 121.7 9.44 131.84 8.96C135.2 8.8 138.65 8.71 142.18 8.7C148.22 8.69 154.51 8.89 161.06 9.38C171.85 10.18 178.32 13.72 181.89 16.64C184.62 18.86 185.66 20.72 185.66 20.72C185.66 20.72 186.52 25.54 185.19 32.18L185.21 32.17Z"
          fill="url(#paint0_linear_792_747)"
        />
        <path
          d="M181.91 16.64L139.55 58.97L118.92 79.59C118.17 76.1 119.67 70.62 119.67 70.62C115.46 70.82 111.46 70.89 107.64 70.83C96.12 86.05 66.94 80.25 70.65 79.24C73.56 78.44 81.71 71.74 85.09 68.9C83.41 68.62 81.77 68.3 80.2 67.96L88.54 59.98L142.2 8.7C148.24 8.69 154.53 8.89 161.08 9.38C171.87 10.18 178.34 13.72 181.91 16.64Z"
          fill="url(#paint1_linear_792_747)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M131.85 8.96997L81.61 59.17L74.26 66.52C66.63 64.43 60.32 61.73 55.02 58.77L59.12 54.85L103.92 12.03C112.4 10.56 121.72 9.44997 131.86 8.96997H131.85Z"
          fill="url(#paint2_linear_792_747)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <path
          d="M185.21 32.17C183.96 38.46 165.96 61.76 73.85 43.67C56.61 40.28 36.77 35.45 13.96 28.78C15.37 28 16.89 27.63 18.36 28.07C18.36 28.07 14.84 25.19 13.32 15.25C13.32 15.25 24.77 14.91 28.32 27.87C28.32 27.87 35.06 29.21 42.8 29.88C48.11 30.33 68.86 18.1 103.9 12.02C112.38 10.55 121.7 9.44 131.84 8.96C135.2 8.8 138.65 8.71 142.18 8.7C148.22 8.69 154.51 8.89 161.06 9.38C171.85 10.18 178.32 13.72 181.89 16.64C184.62 18.86 185.66 20.72 185.66 20.72C185.66 20.72 186.52 25.54 185.19 32.18L185.21 32.17Z"
          fill="url(#paint3_linear_792_747)"
        />
        <g opacity="0.09">
          <path
            d="M181.74 42.13C178.52 46.04 173.96 49.23 170.21 51.26C154.43 59.72 135.1 61.54 117.46 61.02C88.85 59.88 58.47 52.27 32.12 39.6C30.94 38.3 29.88 37.15 28.9 36.19C27.36 34.7 25.99 33.65 24.64 33.27C24.64 33.27 24.38 33.5 23.9 33.89C23.6 34.13 23.22 34.43 22.76 34.78C21.41 34.04 20.07 33.29 18.75 32.52L16.27 30.98L15.03 30.21L13.8 29.39L13.98 28.77L14.16 28.15C14.39 28.22 14.63 28.28 14.87 28.34C55.53 39.39 128.41 57.89 167.91 44.71C173.84 42.65 180 39.75 183.55 34.38C184.02 33.64 184.41 32.83 184.59 32.03L185.84 32.28C185.86 35.82 184.18 39.17 181.74 42.11V42.13Z"
            fill="#4B734E"
          />
        </g>
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M42.85 29.57C44.23 29.61 45.69 29.1 47.09 28.66C48.5 28.19 49.89 27.66 51.29 27.12L59.65 23.86C65.25 21.73 70.91 19.75 76.64 17.99C88.1 14.46 99.85 11.84 111.73 10.18C117.68 9.38 123.65 8.83 129.64 8.5C132.63 8.31 135.63 8.33 138.63 8.24C141.63 8.14 144.63 8.14 147.62 8.22C150.62 8.29 153.61 8.41 156.6 8.58C159.58 8.77 162.59 8.92 165.58 9.42C171.51 10.4 177.41 12.52 182.12 16.38L181.72 16.88C176.37 14.13 170.71 12.98 165.04 12.83C163.63 12.77 162.22 12.76 160.8 12.79L156.36 12.94L147.52 13.32C144.58 13.45 141.65 13.65 138.73 13.88C137.27 13.99 135.81 14.12 134.35 14.2C132.89 14.28 131.43 14.38 129.98 14.53C127.07 14.81 124.16 15.06 121.27 15.41C118.37 15.73 115.48 16.08 112.59 16.5C101.04 18.1 89.58 20.23 78.12 22.89C72.39 24.22 66.63 25.58 60.86 27.1C57.94 27.78 55.04 28.56 52.07 29.2C50.58 29.51 49.08 29.83 47.55 30.08C46.78 30.19 46.01 30.29 45.21 30.33C44.42 30.38 43.61 30.41 42.79 30.19L42.84 29.55L42.85 29.57Z"
            fill="url(#paint4_linear_792_747)"
          />
        </g>
        <path
          d="M23.41 28.18C21.76 28.27 20.32 28.55 19.06 28.99C18.75 29.1 18.44 29.21 18.15 29.37C17.86 29.5 17.57 29.6 17.31 29.77C16.76 30.04 16.29 30.45 15.8 30.77C14.86 31.51 14.03 32.39 13.2 33.35L12.01 34.89C11.65 35.46 11.27 36.02 10.87 36.58L10.55 37.05C9.83 37.79 9.12 38.42 8.36 39.04C7.57 39.63 6.79 40.22 5.93 40.74C5.07 41.24 4.17 41.73 3.19 42.07C2.24 42.43 1.14 42.7 0.07 42.53L0 42.22C0.85 41.98 1.63 41.46 2.35 40.91C3.07 40.35 3.77 39.76 4.44 39.13C5.78 37.87 7.04 36.51 8.22 35.11L8.19 35.16L9.37 33.14C9.84 32.52 10.27 31.86 10.79 31.27C11.84 30.11 13.04 29.06 14.43 28.29C15.83 27.55 17.38 27.1 18.95 27.03C20.53 26.95 22.1 27.25 23.55 27.89L23.41 28.18Z"
          fill="url(#paint5_linear_792_747)"
        />
        <g opacity="0.38" style={{ mixBlendMode: 'screen' }}>
          <path
            d="M70.68 79.15C72.6 79.4 74.48 79.64 76.38 79.65C77.32 79.69 78.26 79.68 79.19 79.69C80.13 79.63 81.05 79.65 81.98 79.57C85.68 79.3 89.32 78.67 92.76 77.46C93.19 77.32 93.65 77.23 94.06 77.05L95.32 76.52C96.16 76.15 97.03 75.87 97.83 75.41L100.28 74.15C101.08 73.69 101.83 73.16 102.63 72.68L103.81 71.94C104.22 71.71 104.55 71.37 104.93 71.11L107.18 69.41C108.71 68.29 110 66.89 111.57 65.7L111.72 65.82L109.83 68L108.89 69.09L108.42 69.64L107.89 70.13C106.45 71.41 105.14 72.84 103.52 73.9C100.5 76.3 96.98 78.02 93.35 79.29C92.42 79.55 91.52 79.9 90.57 80.09L87.73 80.66L84.86 80.98C83.9 81.05 82.93 81.05 81.97 81.09C78.12 81.09 74.26 80.54 70.6 79.34L70.67 79.16L70.68 79.15Z"
            fill="url(#paint6_linear_792_747)"
          />
        </g>
        <path
          d="M164.41 33.92C173.169 33.92 180.27 26.8192 180.27 18.06C180.27 9.30078 173.169 2.20001 164.41 2.20001C155.651 2.20001 148.55 9.30078 148.55 18.06C148.55 26.8192 155.651 33.92 164.41 33.92Z"
          fill="url(#paint7_radial_792_747)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M174.222 21.5818C176.165 16.1707 173.353 10.2092 167.942 8.26642C162.531 6.32364 156.57 9.13526 154.627 14.5463C152.684 19.9574 155.496 25.9189 160.907 27.8617C166.318 29.8045 172.279 26.9929 174.222 21.5818Z"
          fill="url(#paint8_radial_792_747)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M151.84 26.36C152.94 28.01 159.36 25.65 166.18 21.1C173 16.55 177.64 11.52 176.54 9.87002C175.44 8.22002 169.02 10.58 162.2 15.13C155.38 19.68 150.74 24.71 151.84 26.36Z"
          fill="url(#paint9_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M160.36 20.67C160.7 21.18 162.69 20.45 164.8 19.04C166.91 17.63 168.35 16.07 168.01 15.56C167.67 15.05 165.68 15.78 163.57 17.19C161.46 18.6 160.02 20.16 160.36 20.67Z"
          fill="url(#paint10_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M158.98 21.6C159.44 22.3 162.15 21.3 165.03 19.38C167.91 17.46 169.87 15.34 169.4 14.64C168.94 13.94 166.23 14.94 163.35 16.86C160.47 18.78 158.51 20.9 158.98 21.6Z"
          fill="url(#paint11_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M144.99 31.14C144.74 30.93 152.85 25.13 163.09 18.18C173.33 11.23 181.84 5.76002 182.08 5.97002C182.33 6.18002 174.22 11.98 163.98 18.93C153.74 25.88 145.23 31.35 144.99 31.14Z"
          fill="url(#paint12_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M155.73 6.85999C154.08 7.95999 156.44 14.38 160.99 21.2C165.54 28.02 170.57 32.66 172.22 31.56C173.87 30.46 171.51 24.04 166.96 17.22C162.41 10.4 157.38 5.75999 155.73 6.85999Z"
          fill="url(#paint13_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M161.42 15.38C160.91 15.72 161.64 17.71 163.05 19.82C164.46 21.93 166.02 23.37 166.53 23.03C167.04 22.69 166.31 20.7 164.9 18.59C163.49 16.48 161.93 15.04 161.42 15.38Z"
          fill="url(#paint14_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M160.49 13.99C159.79 14.45 160.79 17.16 162.71 20.04C164.63 22.92 166.75 24.88 167.45 24.41C168.15 23.95 167.15 21.24 165.23 18.36C163.31 15.48 161.19 13.52 160.49 13.99Z"
          fill="url(#paint15_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M150.95 1.28243e-05C151.16 -0.249987 156.96 7.86001 163.91 18.1C170.86 28.34 176.33 36.85 176.12 37.09C175.91 37.34 170.11 29.23 163.16 18.99C156.21 8.76001 150.75 0.250013 150.95 1.28243e-05Z"
          fill="url(#paint16_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M166.4 16.83C165.42 15.37 163.44 14.97 161.98 15.95C160.52 16.93 160.12 18.91 161.1 20.37C162.08 21.83 164.06 22.23 165.52 21.25C166.98 20.27 167.38 18.29 166.4 16.83Z"
          fill="url(#paint17_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M169.439 19.734C170.064 16.598 168.029 13.5488 164.893 12.9233C161.757 12.2978 158.708 14.3329 158.083 17.4689C157.457 20.6048 159.492 23.6541 162.628 24.2796C165.764 24.9051 168.813 22.8699 169.439 19.734Z"
          fill="url(#paint18_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M67.85 77.71C76.6092 77.71 83.71 70.6092 83.71 61.85C83.71 53.0908 76.6092 45.99 67.85 45.99C59.0908 45.99 51.99 53.0908 51.99 61.85C51.99 70.6092 59.0908 77.71 67.85 77.71Z"
          fill="url(#paint19_radial_792_747)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M77.647 65.3791C79.5898 59.968 76.7782 54.0065 71.3671 52.0637C65.956 50.1209 59.9945 52.9326 58.0517 58.3437C56.109 63.7547 58.9206 69.7162 64.3317 71.659C69.7428 73.6018 75.7042 70.7902 77.647 65.3791Z"
          fill="url(#paint20_radial_792_747)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M76.15 74.43C77.8 73.33 75.44 66.91 70.89 60.09C66.34 53.27 61.31 48.63 59.66 49.73C58.01 50.83 60.37 57.25 64.92 64.07C69.47 70.89 74.5 75.53 76.15 74.43Z"
          fill="url(#paint21_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M70.46 65.9C70.97 65.56 70.24 63.57 68.83 61.46C67.42 59.35 65.86 57.91 65.35 58.25C64.84 58.59 65.57 60.58 66.98 62.69C68.39 64.8 69.95 66.24 70.46 65.9Z"
          fill="url(#paint22_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M71.39 67.29C72.09 66.83 71.09 64.12 69.17 61.24C67.25 58.36 65.13 56.4 64.43 56.87C63.73 57.33 64.73 60.04 66.65 62.92C68.57 65.8 70.69 67.76 71.39 67.29Z"
          fill="url(#paint23_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M80.93 81.28C80.72 81.53 74.92 73.42 67.97 63.18C61.02 52.94 55.55 44.43 55.76 44.19C55.97 43.94 61.77 52.05 68.72 62.29C75.67 72.53 81.14 81.04 80.93 81.28Z"
          fill="url(#paint24_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M56.65 70.54C57.75 72.19 64.17 69.83 70.99 65.28C77.81 60.73 82.45 55.7 81.35 54.05C80.25 52.4 73.83 54.76 67.01 59.31C60.19 63.86 55.55 68.89 56.65 70.54Z"
          fill="url(#paint25_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M65.17 64.85C65.51 65.36 67.5 64.63 69.61 63.22C71.72 61.81 73.16 60.25 72.82 59.74C72.48 59.23 70.49 59.96 68.38 61.37C66.27 62.78 64.83 64.34 65.17 64.85Z"
          fill="url(#paint26_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M63.79 65.77C64.25 66.47 66.96 65.47 69.84 63.55C72.72 61.63 74.68 59.51 74.21 58.81C73.75 58.11 71.04 59.11 68.16 61.03C65.28 62.95 63.32 65.07 63.79 65.77Z"
          fill="url(#paint27_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M49.8 75.31C49.55 75.1 57.66 69.3 67.9 62.35C78.14 55.4 86.65 49.93 86.89 50.14C87.14 50.35 79.03 56.15 68.79 63.1C58.55 70.05 50.04 75.52 49.8 75.31Z"
          fill="url(#paint28_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M66.62 59.86C65.16 60.84 64.76 62.82 65.74 64.28C66.72 65.74 68.7 66.14 70.16 65.16C71.62 64.18 72.02 62.2 71.04 60.74C70.06 59.28 68.08 58.88 66.62 59.86Z"
          fill="url(#paint29_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M74.0678 63.6528C74.6933 60.5168 72.6582 57.4676 69.5222 56.8421C66.3862 56.2166 63.337 58.2517 62.7115 61.3877C62.086 64.5237 64.1211 67.5729 67.2571 68.1984C70.3931 68.8239 73.4423 66.7888 74.0678 63.6528Z"
          fill="url(#paint30_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M14.59 42.63C18.9807 42.63 22.54 39.0707 22.54 34.68C22.54 30.2894 18.9807 26.73 14.59 26.73C10.1993 26.73 6.64 30.2894 6.64 34.68C6.64 39.0707 10.1993 42.63 14.59 42.63Z"
          fill="url(#paint31_radial_792_747)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M19.4937 36.4378C20.466 33.7296 19.0589 30.746 16.3507 29.7737C13.6426 28.8014 10.659 30.2085 9.68666 32.9167C8.71434 35.6248 10.1215 38.6084 12.8296 39.5807C15.5378 40.5531 18.5214 39.1459 19.4937 36.4378Z"
          fill="url(#paint32_radial_792_747)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M18.74 40.98C19.57 40.43 18.39 37.21 16.1 33.79C13.82 30.37 11.3 28.05 10.47 28.6C9.64 29.15 10.82 32.37 13.11 35.79C15.39 39.21 17.91 41.53 18.74 40.98Z"
          fill="url(#paint33_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M15.89 36.71C16.15 36.54 15.78 35.54 15.07 34.48C14.36 33.42 13.58 32.7 13.33 32.87C13.08 33.04 13.44 34.04 14.15 35.1C14.86 36.16 15.64 36.88 15.89 36.71Z"
          fill="url(#paint34_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M16.36 37.4C16.71 37.17 16.21 35.81 15.25 34.37C14.29 32.93 13.22 31.95 12.88 32.18C12.53 32.41 13.03 33.77 13.99 35.21C14.95 36.65 16.02 37.63 16.36 37.4Z"
          fill="url(#paint35_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M21.14 44.41C21.04 44.53 18.13 40.47 14.65 35.34C11.17 30.21 8.43 25.95 8.53 25.82C8.63 25.7 11.54 29.76 15.02 34.89C18.5 40.02 21.24 44.28 21.14 44.41Z"
          fill="url(#paint36_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M8.97 39.03C9.52 39.86 12.74 38.68 16.16 36.39C19.58 34.11 21.9 31.59 21.35 30.76C20.8 29.93 17.58 31.11 14.16 33.4C10.74 35.69 8.42 38.2 8.97 39.03Z"
          fill="url(#paint37_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M13.24 36.18C13.41 36.44 14.41 36.07 15.47 35.36C16.53 34.65 17.25 33.87 17.08 33.62C16.91 33.36 15.91 33.73 14.85 34.44C13.79 35.15 13.07 35.93 13.24 36.18Z"
          fill="url(#paint38_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M12.55 36.6501C12.78 37.0001 14.14 36.5 15.58 35.54C17.02 34.58 18 33.51 17.77 33.17C17.54 32.82 16.18 33.32 14.74 34.28C13.3 35.24 12.32 36.3101 12.55 36.6501Z"
          fill="url(#paint39_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M5.54 41.43C5.42 41.33 9.48 38.42 14.61 34.94C19.74 31.46 24 28.72 24.13 28.82C24.25 28.92 20.19 31.83 15.06 35.31C9.93 38.79 5.67 41.53 5.54 41.43Z"
          fill="url(#paint40_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M13.97 33.6801C13.24 34.1701 13.04 35.1601 13.53 35.8901C14.02 36.6201 15.01 36.8201 15.74 36.3301C16.47 35.8401 16.67 34.8501 16.18 34.1201C15.69 33.3901 14.7 33.1901 13.97 33.6801Z"
          fill="url(#paint41_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M14.85 37.91C16.4516 37.91 17.75 36.6117 17.75 35.01C17.75 33.4084 16.4516 32.11 14.85 32.11C13.2484 32.11 11.95 33.4084 11.95 35.01C11.95 36.6117 13.2484 37.91 14.85 37.91Z"
          fill="url(#paint42_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M93.88 19.09C98.2707 19.09 101.83 15.5307 101.83 11.14C101.83 6.74934 98.2707 3.19 93.88 3.19C89.4893 3.19 85.93 6.74934 85.93 11.14C85.93 15.5307 89.4893 19.09 93.88 19.09Z"
          fill="url(#paint43_radial_792_747)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M98.7919 12.8974C99.7642 10.1892 98.357 7.2056 95.6489 6.23328C92.9407 5.26096 89.9571 6.66812 88.9848 9.37626C88.0125 12.0844 89.4196 15.068 92.1278 16.0403C94.8359 17.0127 97.8195 15.6055 98.7919 12.8974Z"
          fill="url(#paint44_radial_792_747)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M98.04 17.43C98.87 16.88 97.69 13.66 95.4 10.24C93.11 6.81998 90.6 4.49998 89.77 5.04998C88.94 5.59998 90.12 8.81998 92.41 12.24C94.69 15.66 97.21 17.98 98.04 17.43Z"
          fill="url(#paint45_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M95.19 13.16C95.45 12.99 95.08 11.99 94.37 10.93C93.66 9.86996 92.88 9.14996 92.63 9.31996C92.38 9.48996 92.74 10.49 93.45 11.55C94.16 12.61 94.94 13.33 95.19 13.16Z"
          fill="url(#paint46_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M95.65 13.86C96 13.63 95.5 12.27 94.54 10.83C93.58 9.38997 92.51 8.40997 92.17 8.63997C91.82 8.86997 92.32 10.23 93.28 11.67C94.24 13.11 95.31 14.09 95.65 13.86Z"
          fill="url(#paint47_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M100.43 20.87C100.33 20.99 97.42 16.93 93.94 11.8C90.46 6.67 87.72 2.41 87.82 2.28C87.92 2.16 90.83 6.22 94.31 11.35C97.79 16.48 100.53 20.74 100.43 20.87Z"
          fill="url(#paint48_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M88.27 15.49C88.82 16.32 92.04 15.14 95.46 12.85C98.88 10.57 101.2 8.04999 100.65 7.21999C100.1 6.38999 96.88 7.56999 93.46 9.85999C90.04 12.15 87.72 14.66 88.27 15.49Z"
          fill="url(#paint49_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M92.54 12.63C92.71 12.89 93.71 12.52 94.77 11.81C95.83 11.1 96.55 10.32 96.38 10.07C96.21 9.80998 95.21 10.18 94.15 10.89C93.09 11.6 92.37 12.38 92.54 12.63Z"
          fill="url(#paint50_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M91.84 13.1C92.07 13.45 93.43 12.95 94.87 11.99C96.31 11.03 97.29 9.96 97.06 9.62C96.83 9.27 95.47 9.77 94.03 10.73C92.59 11.69 91.61 12.76 91.84 13.1Z"
          fill="url(#paint51_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M84.83 17.88C84.71 17.78 88.77 14.87 93.9 11.39C99.03 7.90999 103.29 5.16999 103.42 5.26999C103.54 5.36999 99.48 8.27999 94.35 11.76C89.22 15.24 84.96 17.98 84.83 17.88Z"
          fill="url(#paint52_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M93.26 10.14C92.53 10.63 92.33 11.62 92.82 12.35C93.31 13.08 94.3 13.28 95.03 12.79C95.76 12.3 95.96 11.31 95.47 10.58C94.98 9.85002 93.99 9.65002 93.26 10.14Z"
          fill="url(#paint53_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M94.15 14.37C95.7516 14.37 97.05 13.0716 97.05 11.47C97.05 9.86838 95.7516 8.57001 94.15 8.57001C92.5484 8.57001 91.25 9.86838 91.25 11.47C91.25 13.0716 92.5484 14.37 94.15 14.37Z"
          fill="url(#paint54_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M133.45 84.3C138.078 84.3 141.83 80.5482 141.83 75.92C141.83 71.2919 138.078 67.54 133.45 67.54C128.822 67.54 125.07 71.2919 125.07 75.92C125.07 80.5482 128.822 84.3 133.45 84.3Z"
          fill="url(#paint55_radial_792_747)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M136.943 80.1572C139.29 78.2283 139.629 74.7624 137.7 72.4159C135.771 70.0693 132.305 69.7307 129.958 71.6595C127.612 73.5884 127.273 77.0543 129.202 79.4009C131.131 81.7474 134.597 82.086 136.943 80.1572Z"
          fill="url(#paint56_radial_792_747)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M125.5 76.28C125.55 77.33 129.1 78 133.43 77.78C137.76 77.56 141.22 76.54 141.17 75.49C141.12 74.44 137.57 73.77 133.24 73.99C128.91 74.21 125.45 75.23 125.5 76.28Z"
          fill="url(#paint57_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M130.91 76.0101C130.93 76.3301 132.03 76.5401 133.37 76.4701C134.71 76.4001 135.78 76.0901 135.77 75.7601C135.76 75.4301 134.65 75.2301 133.31 75.3001C131.97 75.3701 130.9 75.6801 130.91 76.0101Z"
          fill="url(#paint58_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M130.03 76.0501C130.05 76.4901 131.55 76.77 133.38 76.68C135.21 76.59 136.67 76.16 136.65 75.71C136.63 75.27 135.13 74.9901 133.3 75.0801C131.47 75.1701 130.01 75.6001 130.03 76.0501Z"
          fill="url(#paint59_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M121.1 76.59C121.04 76.43 126.29 75.9901 132.82 75.6101C139.35 75.2301 144.69 75.0501 144.75 75.2101C144.81 75.3701 139.56 75.8101 133.03 76.1901C126.5 76.5701 121.16 76.75 121.1 76.59Z"
          fill="url(#paint60_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M132.55 68.49C131.5 68.54 130.83 72.09 131.05 76.42C131.27 80.75 132.29 84.21 133.34 84.16C134.39 84.11 135.06 80.56 134.84 76.23C134.62 71.9 133.6 68.44 132.55 68.49Z"
          fill="url(#paint61_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M132.82 73.89C132.5 73.91 132.29 75.01 132.36 76.35C132.43 77.69 132.74 78.76 133.07 78.75C133.39 78.73 133.6 77.63 133.53 76.29C133.46 74.95 133.15 73.88 132.82 73.89Z"
          fill="url(#paint62_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M132.78 73.01C132.34 73.03 132.06 74.53 132.15 76.36C132.24 78.19 132.67 79.65 133.12 79.63C133.56 79.61 133.84 78.11 133.75 76.28C133.66 74.45 133.23 72.99 132.78 73.01Z"
          fill="url(#paint63_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M132.24 64.08C132.4 64.02 132.84 69.27 133.22 75.8C133.6 82.33 133.78 87.67 133.62 87.73C133.46 87.79 133.02 82.54 132.64 76.01C132.26 69.48 132.08 64.14 132.24 64.08Z"
          fill="url(#paint64_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M134.69 75.9001C134.64 74.9701 133.85 74.2601 132.92 74.3001C131.99 74.3501 131.28 75.1401 131.32 76.0701C131.36 77.0001 132.16 77.7101 133.09 77.6701C134.02 77.6201 134.73 76.8301 134.69 75.9001Z"
          fill="url(#paint65_radial_792_747)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M133.01 79.04C134.7 79.04 136.07 77.67 136.07 75.98C136.07 74.2901 134.7 72.92 133.01 72.92C131.32 72.92 129.95 74.2901 129.95 75.98C129.95 77.67 131.32 79.04 133.01 79.04Z"
          fill="url(#paint66_radial_792_747)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_747"
        x1="4.06431"
        x2="180.205"
        y1="22.1732"
        y2="57.3056"
      >
        <stop stopColor="#141718" />
        <stop offset="0.48" stopColor="#1B2425" />
        <stop offset="1" stopColor="#2B4233" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_792_747"
        x1="127.799"
        x2="124.687"
        y1="13.9168"
        y2="77.3228"
      >
        <stop stopColor="#020A0D" />
        <stop offset="1" stopColor="#141718" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_747"
        x1="94.5098"
        x2="92.0278"
        y1="12.1103"
        y2="62.5899"
      >
        <stop stopColor="#020A0D" />
        <stop offset="1" stopColor="#141718" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_747"
        x1="106.938"
        x2="93.0337"
        y1="-9.55719"
        y2="50.8499"
      >
        <stop offset="0.09" stopColor="#141718" stopOpacity="0.2" />
        <stop offset="0.97" stopColor="#020A0D" stopOpacity="0.9216" />
        <stop offset="1" stopColor="#020A0D" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint4_linear_792_747" x1="46.75" x2="179.8" y1="8.36" y2="34.89">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_792_747" x1="1.24" x2="22.08" y1="32.98" y2="37.13">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint6_linear_792_747" x1="72.55" x2="110.39" y1="68.93" y2="76.48">
        <stop stopColor="#2B4233" />
        <stop offset="0.52" stopColor="#1B2425" />
        <stop offset="1" stopColor="#141718" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(164.383 18.0442) rotate(56.28) scale(15.86)"
        gradientUnits="userSpaceOnUse"
        id="paint7_radial_792_747"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(164.431 18.0858) rotate(56.28) scale(10.41 10.41)"
        gradientUnits="userSpaceOnUse"
        id="paint8_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(169.719 26.2649) rotate(-123.72) scale(3.564 14.85)"
        gradientUnits="userSpaceOnUse"
        id="paint9_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(169.719 26.2649) rotate(-123.72) scale(1.104 4.6)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(169.719 26.2649) rotate(-123.72) scale(1.5048 6.27)"
        gradientUnits="userSpaceOnUse"
        id="paint11_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(42.3724 -82.5977) rotate(39.76) scale(0.6088 21.5363)"
        gradientUnits="userSpaceOnUse"
        id="paint12_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(162.968 19.8713) rotate(-33.72) scale(3.564 14.85)"
        gradientUnits="userSpaceOnUse"
        id="paint13_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(162.968 19.8713) rotate(-33.72) scale(1.104 4.6)"
        gradientUnits="userSpaceOnUse"
        id="paint14_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(162.968 19.8713) rotate(-33.72) scale(1.5048 6.27)"
        gradientUnits="userSpaceOnUse"
        id="paint15_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(67.4863 133.579) rotate(129.76) scale(0.6088 21.5363)"
        gradientUnits="userSpaceOnUse"
        id="paint16_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.729 18.5771) rotate(56.28) scale(3.19 3.19)"
        gradientUnits="userSpaceOnUse"
        id="paint17_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(163.759 18.6084) rotate(56.28) scale(5.79)"
        gradientUnits="userSpaceOnUse"
        id="paint18_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(68.1323 62.0325) rotate(-33.72) scale(15.86)"
        gradientUnits="userSpaceOnUse"
        id="paint19_radial_792_747"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(67.7864 61.7097) rotate(-33.72) scale(10.41)"
        gradientUnits="userSpaceOnUse"
        id="paint20_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.7638 77.7803) rotate(146.28) scale(3.564 14.85)"
        gradientUnits="userSpaceOnUse"
        id="paint21_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.7638 77.7803) rotate(146.28) scale(1.104 4.6)"
        gradientUnits="userSpaceOnUse"
        id="paint22_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(44.7638 77.7803) rotate(146.28) scale(1.5048 6.27)"
        gradientUnits="userSpaceOnUse"
        id="paint23_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(110.734 11.8981) rotate(-50.24) scale(0.6088 21.5363)"
        gradientUnits="userSpaceOnUse"
        id="paint24_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(77.1434 74.2017) rotate(-123.72) scale(3.564 14.85)"
        gradientUnits="userSpaceOnUse"
        id="paint25_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(77.1434 74.2017) rotate(-123.72) scale(1.104 4.6)"
        gradientUnits="userSpaceOnUse"
        id="paint26_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(77.1434 74.2017) rotate(-123.72) scale(1.5048 6.27)"
        gradientUnits="userSpaceOnUse"
        id="paint27_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1274.75 -1058.69) rotate(39.76) scale(0.6088 21.5363)"
        gradientUnits="userSpaceOnUse"
        id="paint28_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(68.6569 62.6924) rotate(-33.72) scale(3.19)"
        gradientUnits="userSpaceOnUse"
        id="paint29_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(68.4235 62.4845) rotate(-33.72) scale(5.79)"
        gradientUnits="userSpaceOnUse"
        id="paint30_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(14.8614 34.8524) rotate(-33.72) scale(7.95)"
        gradientUnits="userSpaceOnUse"
        id="paint31_radial_792_747"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(14.5125 34.5307) rotate(-33.72) scale(5.21)"
        gradientUnits="userSpaceOnUse"
        id="paint32_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-8.37304 50.3904) rotate(146.28) scale(1.7856 7.44)"
        gradientUnits="userSpaceOnUse"
        id="paint33_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-8.37304 50.3904) rotate(146.28) scale(0.552 2.3)"
        gradientUnits="userSpaceOnUse"
        id="paint34_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-8.37305 50.3904) rotate(146.28) scale(0.7536 3.14)"
        gradientUnits="userSpaceOnUse"
        id="paint35_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(45.4305 -1.60275) rotate(-50.24) scale(0.3056 10.8106)"
        gradientUnits="userSpaceOnUse"
        id="paint36_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.4956 47.0873) rotate(-123.72) scale(1.7856 7.44)"
        gradientUnits="userSpaceOnUse"
        id="paint37_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.4956 47.0874) rotate(-123.72) scale(0.552 2.3)"
        gradientUnits="userSpaceOnUse"
        id="paint38_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.4956 47.0874) rotate(-123.72) scale(0.7536 3.14)"
        gradientUnits="userSpaceOnUse"
        id="paint39_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1327.41 -1085.6) rotate(39.76) scale(0.3056 10.8106)"
        gradientUnits="userSpaceOnUse"
        id="paint40_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(15.1333 35.1878) rotate(-33.72) scale(1.6)"
        gradientUnits="userSpaceOnUse"
        id="paint41_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(15.1333 35.1878) rotate(-33.72) scale(2.9)"
        gradientUnits="userSpaceOnUse"
        id="paint42_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(94.1629 11.3083) rotate(-33.72) scale(7.95)"
        gradientUnits="userSpaceOnUse"
        id="paint43_radial_792_747"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(93.8075 10.9717) rotate(-33.72) scale(5.21)"
        gradientUnits="userSpaceOnUse"
        id="paint44_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(70.5029 27.1303) rotate(146.28) scale(1.7856 7.44)"
        gradientUnits="userSpaceOnUse"
        id="paint45_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(70.5029 27.1302) rotate(146.28) scale(0.552001 2.3)"
        gradientUnits="userSpaceOnUse"
        id="paint46_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(70.5029 27.1302) rotate(146.28) scale(0.7536 3.14)"
        gradientUnits="userSpaceOnUse"
        id="paint47_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(135.734 -38.327) rotate(-50.24) scale(0.3056 10.8106)"
        gradientUnits="userSpaceOnUse"
        id="paint48_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(102.709 23.4108) rotate(-123.72) scale(1.7856 7.44)"
        gradientUnits="userSpaceOnUse"
        id="paint49_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(102.709 23.4108) rotate(-123.72) scale(0.552 2.3)"
        gradientUnits="userSpaceOnUse"
        id="paint50_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(102.709 23.4108) rotate(-123.72) scale(0.7536 3.14)"
        gradientUnits="userSpaceOnUse"
        id="paint51_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-1262.38 -1121.06) rotate(39.76) scale(0.3056 10.8106)"
        gradientUnits="userSpaceOnUse"
        id="paint52_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(94.4265 11.6493) rotate(-33.72) scale(1.6)"
        gradientUnits="userSpaceOnUse"
        id="paint53_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(94.4265 11.6493) rotate(-33.72) scale(2.9)"
        gradientUnits="userSpaceOnUse"
        id="paint54_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(133.421 75.6598) rotate(87.12) scale(8.38)"
        gradientUnits="userSpaceOnUse"
        id="paint55_radial_792_747"
        r="1"
      >
        <stop stopColor="#020A0D" />
        <stop offset="0.58" stopColor="#020A0D" stopOpacity="0.3721" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(133.423 75.9868) rotate(87.11) scale(5.5)"
        gradientUnits="userSpaceOnUse"
        id="paint56_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(134.032 87.2022) rotate(-92.88) scale(1.884 7.85)"
        gradientUnits="userSpaceOnUse"
        id="paint57_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.21" stopColor="#2D4331" stopOpacity="0.8649" />
        <stop offset="0.47" stopColor="#233629" stopOpacity="0.5184" />
        <stop offset="0.75" stopColor="#14211B" stopOpacity="0.1444" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(134.032 87.2022) rotate(-92.88) scale(0.583199 2.43)"
        gradientUnits="userSpaceOnUse"
        id="paint58_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(134.032 87.2022) rotate(-92.88) scale(0.794399 3.31)"
        gradientUnits="userSpaceOnUse"
        id="paint59_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(289.153 522.371) rotate(70.59) scale(0.3216 11.3766)"
        gradientUnits="userSpaceOnUse"
        id="paint60_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(147.231 75.406) rotate(-2.88001) scale(1.884 7.85)"
        gradientUnits="userSpaceOnUse"
        id="paint61_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.16" stopColor="#476E4B" stopOpacity="0.9216" />
        <stop offset="0.36" stopColor="#3E6042" stopOpacity="0.6889" />
        <stop offset="0.57" stopColor="#2E4A34" stopOpacity="0.3721" />
        <stop offset="0.8" stopColor="#192B21" stopOpacity="0.1024" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(147.231 75.406) rotate(-2.88) scale(0.583199 2.43)"
        gradientUnits="userSpaceOnUse"
        id="paint62_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(147.231 75.406) rotate(-2.87999) scale(0.794403 3.31)"
        gradientUnits="userSpaceOnUse"
        id="paint63_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-378.623 255.646) rotate(160.59) scale(0.321598 11.3766)"
        gradientUnits="userSpaceOnUse"
        id="paint64_radial_792_747"
        r="1"
      >
        <stop stopColor="#4B734E" />
        <stop offset="0.22" stopColor="#406245" />
        <stop offset="0.57" stopColor="#2B4233" />
        <stop offset="0.69" stopColor="#23372B" stopOpacity="0.6561" />
        <stop offset="0.89" stopColor="#0E1B18" stopOpacity="0.0961" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(132.974 75.7224) rotate(87.12) scale(1.68)"
        gradientUnits="userSpaceOnUse"
        id="paint65_radial_792_747"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBEBEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D4D4" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B3B4" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#84888A" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F5456" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#11181B" stopOpacity="0.01" />
        <stop offset="1" stopColor="#020A0D" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(132.974 75.7224) rotate(87.12) scale(3.06 3.06001)"
        gradientUnits="userSpaceOnUse"
        id="paint66_radial_792_747"
        r="1"
      >
        <stop stopColor="#314834" />
        <stop offset="0.38" stopColor="#344D37" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#39573C" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_792_747">
        <rect fill="white" height="87.73" width="210.16" />
      </clipPath>
    </defs>
  </svg>
);

export default Whale;

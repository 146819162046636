import consts from '../utils/consts';
import CompleteIdentityService from '../components/Profile/CompleteIdentity/CompleteIdentityService';
import CompleteProfileService from '../components/Profile/CompleteProfile/CompleteProfileService';
import CompleteBillingInformationService from '../components/Profile/CompleteBillingInfo/CompleteBillingInformationService';
import CompleteBeneficiaryInfoService from '../components/Profile/CompleteBeneficiaryInfo/CompleteBeneficiaryInfoService';

const { ACTIONS } = consts;

const communicationStatus = ({ globalState }) => {
  const hasToCompleteProfile = CompleteProfileService.hasToCompleteProfile({
    affInfos: globalState.profile.data.affInfos,
  });

  if (hasToCompleteProfile) {
    return 'profile';
  }

  const validationStatusList = globalState.application.lists.validationStatus;
  const validationStatusId = globalState.profile.data.affValidationStatus?.validation_status_id;
  const affiliateType = globalState.profile.data.customProfile?.affiliate?.affiliate_type?.type;

  const hasToCompleteIdentity = CompleteIdentityService.hasToCompleteIdentity({
    hasToCompleteProfile,
    validationStatusList,
    validationStatusId,
    affiliateType,
  });

  if (hasToCompleteIdentity) {
    return 'identity';
  }

  const affiliate = globalState.profile.data.customProfile?.affiliate;

  const hasToCompleteBillingInfo = CompleteBillingInformationService.hasToCompleteBillingInfo(
    affiliate?.minimum_payout.payment_method_id,
    affiliate?.payment_method
  );

  const affBeneficiary = globalState.profile?.data?.affiliateBeneficiary;

  const hasToCompleteBeneficiaryInfo = CompleteBeneficiaryInfoService.hasToCompleteBeneficiaryInfo({
    affBeneficiary: affBeneficiary,
  });

  if (hasToCompleteBillingInfo || hasToCompleteBeneficiaryInfo) {
    return 'payment';
  }
  return null;
};
const initialState = {
  fetchingProfile: false,
  fetchingPayment: false,
  needPaymentMethod: false,
  hasAPaymentMethod: null,
  hasEarnedMoney: false,
  hasToCompleteInformation: null,
  communicationStatus: null,
};

export const profileCompleted = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PAYMENTC_VALIDATION:
      return {
        ...state,
        fetchingPayment: true,
      };

    case ACTIONS.RECEIVE_PAYMENTC_VALIDATION:
      return {
        ...state,
        fetchingPayment: false,
        needPaymentMethod: action.needPaymentMethod,
        hasAPaymentMethod: action.hasAPaymentMethod,
        hasEarnedMoney: action.hasEarnedMoney,
        communicationStatus: communicationStatus({
          globalState: action.globalState,
        }),
      };

    case ACTIONS.FAILED_PAYMENTC_VALIDATION:
      return {
        ...state,
        fetchingPayment: false,
      };

    case ACTIONS.REQUEST_PROFILEC_VALIDATION:
      return {
        ...state,
        fetchingProfile: true,
      };

    case ACTIONS.RECEIVE_PROFILEC_VALIDATION:
      return {
        ...state,
        fetchingProfile: false,
        hasToCompleteInformation: action.hasToCompleteInformation,
        communicationStatus: communicationStatus({
          globalState: action.globalState,
        }),
      };

    case ACTIONS.FAILED_PROFILEC_VALIDATION:
      return {
        ...state,
        fetchingProfile: false,
      };

    default:
      return state;
  }
};

const OffersIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M2.51318 4.97214C2.51562 3.79567 3.40507 2.74082 4.55965 2.54217C4.7964 2.50082 7.5734 2.50649 8.72233 2.5073C9.85909 2.50812 10.828 2.91676 11.6307 3.71783C13.335 5.41889 15.0377 7.12158 16.7379 8.82592C17.7441 9.83375 17.7579 11.3807 16.7558 12.3918C15.3101 13.8512 13.8571 15.3034 12.3985 16.7491C11.3883 17.7504 9.84125 17.7374 8.83259 16.7312C7.11287 15.0163 5.39315 13.3015 3.68074 11.5793C3.01831 10.9129 2.62751 10.1077 2.54075 9.16642C2.47102 8.414 2.51156 5.61673 2.51318 4.97214Z"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M8.25593 6.92934C8.25268 7.6542 7.64702 8.25014 6.91487 8.24852C6.18757 8.2469 5.5819 7.64042 5.58596 6.91799C5.59082 6.16394 6.18757 5.57612 6.94648 5.57936C7.66647 5.58179 8.25917 6.19233 8.25593 6.92934Z"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default OffersIcon;

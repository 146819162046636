import { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const usePlayer = () => {
  const options = {
    playsinline: true,
    preload: 'auto',
    fluid: true,
    fill: true,
    controls: true,
  };
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, options);
    setPlayer(vjsPlayer);

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, []);

  return videoRef;
};

export const PromoVideo = ({ src }) => {
  const videoRef = usePlayer();

  return (
    <video className="video-js vjs-big-play-centered" ref={videoRef}>
      <source src={src} />
    </video>
  );
};

export default PromoVideo;

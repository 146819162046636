const HelpSign = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        d="M36.2529 11.5377C37.1123 10.6786 37.0613 9.25987 36.0538 8.58037C32.8973 6.45127 29.0938 5.20833 25 5.20833C20.9061 5.20833 17.1024 6.45135 13.946 8.58058C12.9386 9.2601 12.8875 10.6788 13.7469 11.5379L17.7015 15.4913C17.7187 15.5085 17.7355 15.5261 17.7518 15.5439L19.0415 16.8336C19.75 17.5422 20.8544 17.6178 21.7798 17.2337C22.7715 16.8221 23.859 16.595 24.9996 16.595C26.1402 16.595 27.2277 16.8222 28.2194 17.2338C29.145 17.618 30.2494 17.5423 30.9579 16.8338L32.2477 15.5439C32.264 15.5261 32.2809 15.5085 32.2982 15.4913L36.2529 11.5377Z"
        fill="white"
      />
      <path
        d="M36.7377 40.9371C37.3523 40.4835 37.385 39.5944 36.845 39.0544L36.8267 39.0363L30.9571 33.1667C30.2486 32.4581 29.1442 32.3825 28.2188 32.7665C27.2273 33.1779 26.1398 33.405 24.9996 33.405C23.8596 33.405 22.7727 33.1781 21.7815 32.7671C20.8563 32.3831 19.7521 32.459 19.0437 33.1673L17.7224 34.4888L17.7042 34.5071L13.7481 38.4633C12.8889 39.3225 12.9402 40.741 13.9476 41.4204C17.1037 43.5492 20.9067 44.7917 25 44.7917C29.394 44.7917 33.4538 43.3598 36.7377 40.9371Z"
        fill="white"
      />
    </g>
    <path
      d="M38.6465 36.0694C39.5056 36.9287 40.9244 36.8777 41.604 35.8704C43.7329 32.714 44.9758 28.9104 44.9758 24.8167C44.9758 20.7226 43.7329 16.919 41.6037 13.7625C40.9242 12.7551 39.5054 12.7041 38.6462 13.5634L34.6929 17.518C34.6756 17.5353 34.6581 17.552 34.6404 17.5683L33.3506 18.858C32.6421 19.5666 32.5665 20.6709 32.9506 21.5965C33.3621 22.5881 33.5894 23.6756 33.5894 24.816C33.5894 25.9567 33.3621 27.0444 32.9504 28.036C32.5662 28.9615 32.6419 30.0658 33.3504 30.7746L34.6404 32.0644C34.6581 32.0806 34.6756 32.0973 34.6929 32.1146L38.6465 36.0694Z"
      fill="white"
    />
    <path
      d="M9.2472 36.5542C9.70064 37.1687 10.5899 37.2017 11.13 36.6617L11.148 36.6433L17.0176 30.7735C17.7262 30.065 17.8018 28.9608 17.4178 28.0354C17.0063 27.0437 16.7792 25.9565 16.7792 24.816C16.7792 23.6763 17.0061 22.5894 17.4172 21.5981C17.801 20.6727 17.7253 19.5687 17.0169 18.8603L15.6956 17.539L15.6771 17.5208L11.721 13.5647C10.8618 12.7055 9.44312 12.7567 8.76372 13.7641C6.63516 16.9203 5.39258 20.7233 5.39258 24.8167C5.39258 29.2106 6.82452 33.2702 9.2472 36.5542Z"
      fill="white"
    />
  </svg>
);

export default HelpSign;

import { InputText, Switch } from '../../components/reusables';
import { Gtm } from '../../utils/gtm';
import messages from '../../i18n/base-en';
import React, { useRef, useEffect } from 'react';

const LinkSettings = ({ hasPopUnder, linkSettingsButtonRef, fields, isOpen, backOffers, setIsOpen, formChangedHandler }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        linkSettingsButtonRef.current &&
        !linkSettingsButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);
  return (
    <div className={`settings-modal ${isOpen ? 'open' : ''}`} ref={modalRef}>
      <div className="modal-content">
        <div className="">
          <InputText
            displayErrorInstantly
            field={fields.source}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="source"
            label={messages.smartLinkSourceLabel}
            placeholder={messages.smartLinkSource.description}
            type="text"
          />
        </div>
        <div className="aff-sub-container">
          <InputText
            displayErrorInstantly
            field={fields.aff_sub}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="aff_sub"
            label={messages.smartLinkAffsubLabel}
            placeholder={messages.smartLinkAffsub.description}
            type="text"
          />
          <InputText
            displayErrorInstantly
            field={fields.aff_sub2}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="aff_sub2"
            label={messages.smartLinkAffsub2Label}
            placeholder={messages.smartLinkAffsub2.description}
            type="text"
          />
          <InputText
            displayErrorInstantly
            field={fields.aff_sub3}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="aff_sub3"
            label={messages.smartLinkAffsub3Label}
            placeholder={messages.smartLinkAffsub3.description}
            type="text"
          />
          <InputText
            displayErrorInstantly
            field={fields.aff_sub4}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="aff_sub4"
            label={messages.smartLinkAffsub4Label}
            placeholder={messages.smartLinkAffsub4.description}
            type="text"
          />
        </div>
        <div className="switchs">
          {hasPopUnder ? (
            <div className="text-center">
              <Switch
                className="extra-switch"
                dataCy="pop-unders"
                field={fields.popUnder}
                label={messages.genericTextPopUnders}
                onChange={() => {
                  Gtm.event('dating smartlink', 'click', `pop-unders ${fields.popUnder.value ? 'off' : 'on'}`);
                }}
                value
              />
            </div>
          ) : null}
          {backOffers && backOffers.length > 0 ? (
            <div className=" text-center">
              <Switch
                className="extra-switch"
                dataCy="back-offers"
                field={fields.bo}
                label={messages.genericTextBackOffers}
                onChange={() => {
                  Gtm.event('dating smartlink', 'click', `back-offers ${fields.bo.value ? 'off' : 'on'}`);
                }}
                value={backOffers ? backOffers : ''}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="settings-modal-footer">
        <button className="cancel-button" onClick={() => setIsOpen(false)} type="button">
          Cancel
        </button>
        <button
          className="apply-button"
          onClick={() => {
            setIsOpen(false);
            formChangedHandler();
            Gtm.newEvent('smartlink_step3', false, 'click', 'string', 'apply_settings');
          }}
          type="button"
        >
          Apply settings
        </button>
      </div>
    </div>
  );
};

export default LinkSettings;

import PropTypes from 'prop-types';
import moment from 'moment';
import { Gtm } from '../../../utils/gtm';

const HomeBlogPost = ({ categories, date, description, link, media, showImageMobile, title }) => (
  <a
    className="post"
    href={link}
    onClick={() => {
      Gtm.event('dashboard', 'Click', `blog posts - ${title}`);
    }}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="row">
      <div className={`col-lg-3 col-md-4 col-sm-3 col-xs-12${showImageMobile ? '' : ' hidden-xs'}`}>
        {media ? (
          <div>
            <img alt={title} height={media.height} src={media.url} width={media.width} />
          </div>
        ) : null}
      </div>
      <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
        <h2>{title}</h2>
        <span>
          {moment(date, 'ddd, DD MMM YYYY HH:mm:ss ZZ').format('MMMM DD, YYYY')} | {categories.join(', ')}
        </span>
        <p>{description}</p>
      </div>
    </div>
  </a>
);

HomeBlogPost.propTypes = {
  categories: PropTypes.array,
  date: PropTypes.any,
  description: PropTypes.any,
  link: PropTypes.any,
  media: PropTypes.any,
  showImageMobile: PropTypes.bool,
  title: PropTypes.any,
};

export default HomeBlogPost;

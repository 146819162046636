import React from 'react';
import { useSlider } from './SliderContext.react';
import './Slider.scss';

const Slider = ({ children }) => {
  const { currentIndex, sliderRef } = useSlider();

  return (
    <div className="slider-container">
      <div className="smartlink-slider-wrapper" ref={sliderRef}>
        {React.Children.map(children, (child, index) => (
          <div className={`smartlink-slider-slide ${index === currentIndex ? 'active' : ''}`} key={index}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;

export default () => `
  MediaPlacements: Tags(filters: [{key: "attribute", value: "Media Placement"}, {key: "name", value: "All", condition: not_eq}]) {
    Data {
      id
      name
      attribute
    }
  }

  Verticals: Tags(filters: [{key: "attribute", value: "Vertical"}]) {
    Data {
      id
      name
      attribute
    }
  }

  Channels: Tags(filters: [{key: "attribute", value: "Channel"}]) {
    Data {
      id
      name
      attribute
    }
  }

  ConversionTypes: Tags(filters: [{key: "attribute", value: "Conversion Type"}]) {
    Data {
      id
      name
      attribute
    }
  }
`;

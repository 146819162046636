import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import consts from '../utils/consts';
import config from '../config';

const {
  ACTIONS: { LIVECAM },
} = consts;
const { API_URL } = config;

export const setAnimatedTemplateLivecam = (animated) => ({
  type: LIVECAM.SET_ANIMATED_TEMPLATE,
  animated,
});

export const setExpireDaysLivecam = (expireDays) => ({
  type: LIVECAM.SET_EXPIRE_DAYS,
  expireDays,
});

const receiveCreatedCreative = (creativeId, creativeCode, creativeName) => ({
  type: LIVECAM.RECEIVE_CREATED_CREATIVE,
  creativeId,
  creativeCode,
  creativeName,
});

export const postCreateCreative = (type, code, display, placeholders, name) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/creative/create`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        type,
        code,
        display,
        placeholders,
      })
      .then((res) => {
        dispatch(receiveCreatedCreative(res.body.creativeId, res.body.creativeCode, name));
        fulfill();
      })
      .catch((err) => {
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );

const fetchRemoteCode = (url) =>
  new Promise((fulfill, reject) =>
    request.get(url).end((err, res) => {
      if (!err) {
        fulfill(res.text);
      } else {
        reject(err);
      }
    })
  );

export const createCreatives = (codes) => (dispatch) =>
  new Promise((fulfill) => {
    if (codes.name !== 'im') {
      dispatch(postCreateCreative(codes.type, codes.code, codes.display, codes.placeholders, codes.name)).then(() => fulfill());
    } else {
      fetchRemoteCode(codes.remote).then((code) =>
        dispatch(postCreateCreative(codes.type, code, codes.display, codes.placeholders, codes.name)).then(() => fulfill())
      );
    }
  });

export const resetCreative = () => {
  return { type: LIVECAM.RESET_CREATIVES };
};

const requestCreatives = () => ({ type: LIVECAM.REQUEST_CREATIVE });
export const fetchCreatedCreative = (id, placeholders, name) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/creative/get-creative-code`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        creativeId: id,
        placeholders,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveCreatedCreative(id, res.body, name));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );

export const fetchMultipleCreatedCreatives = (creatives, placeholders) => (dispatch) => {
  dispatch(requestCreatives());
  return new Promise((fulfill) =>
    Promise.all(creatives.map((creative) => dispatch(fetchCreatedCreative(creative.id, placeholders, creative.name)))).then(() =>
      fulfill()
    )
  );
};

export const requestCampaigns = () => ({ type: LIVECAM.REQUEST_CAMPAIGNS });
export const receiveCampaigns = (campaigns) => ({
  type: LIVECAM.RECEIVE_CAMPAIGNS,
  campaigns,
});
export const failedToReceiveCampaigns = () => ({
  type: LIVECAM.FAILED_TO_RECEIVE_CAMPAIGNS,
});
export const fetchLiveCamOffers = () => (dispatch) => {
  dispatch(requestCampaigns());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/apiql`)
      .query({
        query: `{
            Campaigns {
              id
              name
              genders
              exclusive_widget_types
              payout {
                name
                label
              }
              provider {
                name
                label
              }
              creatives {
                offer_id
                offer_name
                require_approval
                approval_status
              }
            }
          }`,
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .then((res) => {
        const data = res.body.data.Campaigns;
        dispatch(receiveCampaigns(data));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveCampaigns());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const changeLivecamUrls = ({ iframeUrl, widgetUrls }) => ({
  type: LIVECAM.CHANGE_LIVECAM_URL,
  iframeUrl,
  widgetUrls,
});

import { OFFER_TAG_TYPES } from './data';

export const createOfferTags = ({ tags }) => {
  const offerTags = [];

  const tagsToDisplay = {
    [OFFER_TAG_TYPES.NEW]: false,
    [OFFER_TAG_TYPES.EXCLUSIVE]: false,
    [OFFER_TAG_TYPES.TOP_OFFER]: false,
    [OFFER_TAG_TYPES.HOT_PICK]: false,
  };

  const tagsToDisplayKeys = Object.keys(tagsToDisplay);

  tags?.forEach((tag) => {
    const tagName = tag?.name?.toLowerCase()?.replace(/\s/g, '');

    if (tagsToDisplayKeys.includes(tagName)) {
      tagsToDisplay[tagName] = true;
    }
  });

  Object.entries(tagsToDisplay).forEach(([key, value]) => {
    if (value) {
      offerTags.push(key);
    }
  });

  return { offerTags, tagsToDisplay };
};

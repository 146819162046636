const CustomAudienceTargetingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="77" viewBox="0 0 78 77" fill="none">
    <g clip-path="url(#clip0_327_33024)">
      <circle cx="39" cy="38.5" r="38.5" fill="white" />
      <path
        d="M38.8224 51.2571C45.818 51.2571 51.4891 45.5861 51.4891 38.5905C51.4891 31.5949 45.818 25.9238 38.8224 25.9238C31.8268 25.9238 26.1558 31.5949 26.1558 38.5905C26.1558 45.5861 31.8268 51.2571 38.8224 51.2571Z"
        stroke="#00BC00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M38.777 43.8451C36.4867 43.8451 34.5548 42.2203 34.2611 40.0513L33.6845 35.8102C33.3315 33.2047 35.452 30.8955 38.2004 30.8955H39.3509C42.0993 30.8955 44.2225 33.2047 43.8668 35.8102L43.2902 40.0513C42.9965 42.2203 41.0619 43.8451 38.7743 43.8451H38.777Z"
        stroke="#00BC00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.309 43.1465L36.1689 44.4183C36.0719 45.3047 35.4764 46.0538 34.6358 46.3502L30.2734 47.8806"
        stroke="#00BC00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M41.2856 43.1465L41.4258 44.4183C41.5228 45.3047 42.1182 46.0538 42.9589 46.3502L47.3212 47.8806"
        stroke="#00BC00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M38.6938 59.4843C50.274 59.4843 59.6616 50.0967 59.6616 38.5166C59.6616 26.9364 50.274 17.5488 38.6938 17.5488C27.1137 17.5488 17.7261 26.9364 17.7261 38.5166C17.7261 50.0967 27.1137 59.4843 38.6938 59.4843Z"
        stroke="#00BC00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M49.9839 38.5166H64.5" stroke="#00BC00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.3066 27.2261V12.71" stroke="#00BC00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.0161 38.5166H14.5" stroke="#00BC00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.0806 49.8066V64.3228" stroke="#00BC00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_327_33024">
        <rect width="77" height="77" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CustomAudienceTargetingIcon;

const AffiliateResources = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.59736 9.98945H7.62198C9.86276 9.98945 11.6858 8.16639 11.6858 5.92563C11.6858 3.68486 9.86276 1.86255 7.62198 1.86255C5.38121 1.86255 3.55813 3.68486 3.55813 5.92332C3.55044 8.15793 5.36121 9.98252 7.59736 9.98945ZM4.71198 5.92563C4.71198 4.32101 6.01736 3.01639 7.62198 3.01639C9.22661 3.01639 10.532 4.32101 10.532 5.92563C10.532 7.53024 9.22661 8.8356 7.62198 8.8356H7.59967C6.00198 8.83099 4.70659 7.52563 4.71198 5.92563Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.60498 15.3398C1.60498 17.8474 5.03883 18.1374 7.6219 18.1374C9.11039 18.1374 13.6388 18.1374 13.6388 15.3244C13.6388 13.1805 10.8835 11.3698 7.6219 11.3698C4.36037 11.3698 1.60498 13.1874 1.60498 15.3398ZM2.75883 15.3398C2.75883 14.0105 4.83883 12.5236 7.6219 12.5236C10.405 12.5236 12.485 14.0021 12.485 15.3244C12.485 16.4251 10.8489 16.9836 7.6219 16.9836C4.39498 16.9836 2.75883 16.4305 2.75883 15.3398Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      d="M14.7051 10.6996C14.3866 10.6996 14.1282 10.4412 14.1282 10.1227V9.15741H13.1336C12.8151 9.15741 12.5566 8.89895 12.5566 8.58049C12.5566 8.26204 12.8151 8.00358 13.1336 8.00358H14.1282V7.0381C14.1282 6.71964 14.3866 6.46118 14.7051 6.46118C15.0236 6.46118 15.282 6.71964 15.282 7.0381V8.00358H16.2797C16.5982 8.00358 16.8566 8.26204 16.8566 8.58049C16.8566 8.89895 16.5982 9.15741 16.2797 9.15741H15.282V10.1227C15.282 10.4412 15.0236 10.6996 14.7051 10.6996Z"
      fill="white"
    />
  </svg>
);

export default AffiliateResources;

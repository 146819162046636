const EmailTime = () => (
  <svg height="47.291" viewBox="0 0 47.291 47.291" width="47.291" xmlns="http://www.w3.org/2000/svg">
    <g data-name="vuesax/bulk/message-time" id="vuesax_bulk_message-time" transform="translate(-428 -316)">
      <g id="message-time" transform="translate(428 316)">
        <path d="M0,0H47.291V47.291H0Z" fill="#ffd500" id="Vector" opacity="0" />
        <path
          d="M39.409,9.852V21.675a9.86,9.86,0,0,1-2.719,7.153,9.849,9.849,0,0,1-7.133,2.7v4.2A1.972,1.972,0,0,1,26.5,37.38l-8.769-5.852H13.557a7.081,7.081,0,0,0,.236-1.833,7.85,7.85,0,0,0-2.03-5.261,7.772,7.772,0,0,0-5.852-2.621A7.872,7.872,0,0,0,.256,24.2,11.523,11.523,0,0,1,0,21.675V9.852C0,3.941,3.941,0,9.852,0h19.7C35.468,0,39.409,3.941,39.409,9.852Z"
          data-name="Vector"
          fill="#ffd500"
          id="Vector-2"
          opacity="0.4"
          transform="translate(3.941 4.788)"
        />
        <g id="Group" transform="translate(15.271 19.212)">
          <path
            d="M15.271,2.956H1.478A1.489,1.489,0,0,1,0,1.478,1.489,1.489,0,0,1,1.478,0H15.271a1.489,1.489,0,0,1,1.478,1.478A1.489,1.489,0,0,1,15.271,2.956Z"
            data-name="Vector"
            fill="#ffd500"
            id="Vector-3"
          />
        </g>
        <path
          d="M7.882,0a7.875,7.875,0,1,0,7.882,7.882A7.887,7.887,0,0,0,7.882,0Zm1.97,8.374a1.481,1.481,0,0,1-.709,1.261L6.68,11.113a1.508,1.508,0,0,1-.769.217,1.471,1.471,0,0,1-.749-2.739L6.916,7.547V5.419a1.478,1.478,0,0,1,2.956,0V8.374Z"
          data-name="Vector"
          fill="#ffd500"
          id="Vector-4"
          transform="translate(1.97 26.601)"
        />
      </g>
    </g>
  </svg>
);

export default EmailTime;

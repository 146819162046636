import { STATUSES } from './data';

export const OfferListingApprovalStatusIcon = ({ approval_status }) => {
  switch (approval_status) {
    case STATUSES.APPROVED:
      return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon-Light" clip-path="url(#clip0_4_1197)">
            <path
              id="Vector"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.59912 12.8655C7.38705 12.8655 8.16727 12.7103 8.89522 12.4088C9.62318 12.1072 10.2846 11.6653 10.8418 11.1081C11.3989 10.551 11.8409 9.88953 12.1424 9.16158C12.4439 8.43363 12.5991 7.65341 12.5991 6.86548C12.5991 6.07755 12.4439 5.29733 12.1424 4.56938C11.8409 3.84142 11.3989 3.17999 10.8418 2.62284C10.2846 2.06569 9.62318 1.62373 8.89522 1.3222C8.16727 1.02067 7.38705 0.865479 6.59912 0.865479C5.00782 0.865479 3.4817 1.49762 2.35648 2.62284C1.23126 3.74806 0.599121 5.27418 0.599121 6.86548C0.599121 8.45678 1.23126 9.9829 2.35648 11.1081C3.4817 12.2333 5.00782 12.8655 6.59912 12.8655ZM6.44445 9.29215L9.77779 5.29215L8.75379 4.43881L5.88712 7.87815L4.40379 6.39415L3.46112 7.33681L5.46112 9.33681L5.97712 9.85281L6.44445 9.29215Z"
              fill="#0E700E"
            />
          </g>
          <defs>
            <clipPath id="clip0_4_1197">
              <rect width="12" height="12" fill="white" transform="translate(0.599121 0.865479)" />
            </clipPath>
          </defs>
        </svg>
      );
    case STATUSES.PENDING:
      return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon-Dark" clip-path="url(#clip0_4_1316)">
            <path
              id="Vector"
              d="M6.59912 10.8C7.87216 10.8 9.09306 10.2943 9.99323 9.39411C10.8934 8.49394 11.3991 7.27304 11.3991 6C11.3991 4.72696 10.8934 3.50606 9.99323 2.60589C9.09306 1.70571 7.87216 1.2 6.59912 1.2C5.32608 1.2 4.10518 1.70571 3.20501 2.60589C2.30483 3.50606 1.79912 4.72696 1.79912 6C1.79912 7.27304 2.30483 8.49394 3.20501 9.39411C4.10518 10.2943 5.32608 10.8 6.59912 10.8ZM6.59912 0C7.38705 0 8.16727 0.155195 8.89522 0.456723C9.62318 0.758251 10.2846 1.20021 10.8418 1.75736C11.3989 2.31451 11.8409 2.97595 12.1424 3.7039C12.4439 4.43185 12.5991 5.21207 12.5991 6C12.5991 7.5913 11.967 9.11742 10.8418 10.2426C9.71654 11.3679 8.19042 12 6.59912 12C3.28112 12 0.599121 9.3 0.599121 6C0.599121 4.4087 1.23126 2.88258 2.35648 1.75736C3.4817 0.632141 5.00782 0 6.59912 0ZM6.89912 3V6.15L9.59912 7.752L9.14912 8.49L5.99912 6.6V3H6.89912Z"
              fill="#FF8705"
            />
          </g>
          <defs>
            <clipPath id="clip0_4_1316">
              <rect width="12" height="12" fill="white" transform="translate(0.599121)" />
            </clipPath>
          </defs>
        </svg>
      );
    case STATUSES.REJECTED:
      return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon-Ligh">
            <path
              id="Vector"
              d="M6.21436 0C2.90596 0 0.214355 2.6916 0.214355 6C0.214355 9.3084 2.90596 12 6.21436 12C9.52276 12 12.2144 9.3084 12.2144 6C12.2144 2.6916 9.52276 0 6.21436 0ZM8.73856 7.6758L7.89016 8.5242L6.21436 6.8484L4.53856 8.5242L3.69016 7.6758L5.36596 6L3.69016 4.3242L4.53856 3.4758L6.21436 5.1516L7.89016 3.4758L8.73856 4.3242L7.06276 6L8.73856 7.6758Z"
              fill="#B10E1C"
            />
          </g>
        </svg>
      );
    case STATUSES.APPROVAL_REQUIRED:
      return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="mdi:required-circle" clip-path="url(#clip0_4_1532)">
            <path
              id="Vector"
              d="M6.68476 0.461548C3.63861 0.461548 1.1463 2.95386 1.1463 6.00001C1.1463 9.04616 3.63861 11.5385 6.68476 11.5385C9.73091 11.5385 12.2232 9.04616 12.2232 6.00001C12.2232 2.95386 9.73091 0.461548 6.68476 0.461548ZM6.68476 10.4308C4.25337 10.4308 2.25399 8.43139 2.25399 6.00001C2.25399 3.56862 4.25337 1.56924 6.68476 1.56924C9.11614 1.56924 11.1155 3.56862 11.1155 6.00001C11.1155 8.43139 9.11614 10.4308 6.68476 10.4308ZM7.23861 5.04186L8.80599 4.13355L9.35984 5.09724L7.79245 6.00001L9.35984 6.90278L8.80599 7.86647L7.23861 6.95816V8.76924H6.13091V6.95816L4.56353 7.86647L4.00968 6.90278L5.57707 6.00001L4.00968 5.09724L4.56353 4.13355L6.13091 5.04186V3.23078H7.23861V5.04186Z"
              fill="#444444"
            />
          </g>
          <defs>
            <clipPath id="clip0_4_1532">
              <rect width="12" height="12" fill="white" transform="translate(0.32959)" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};

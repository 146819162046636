const Approved = () => (
  <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
    <title>Approved</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Calque 1" id="Calque_1-2">
        <circle className="fill-primary-color" cx="26.5" cy="26.5" r="26.5" />
        <path d="M38.8,15.85a2.2,2.2,0,0,0-3.07.56L23.37,34.16,14.65,27a2.21,2.21,0,0,0-2.8,3.42l10.57,8.67a2.18,2.18,0,0,0,1.4.5,1.51,1.51,0,0,0,.3,0,2.2,2.2,0,0,0,1.51-.92L39.35,18.93A2.21,2.21,0,0,0,38.8,15.85Z" />
      </g>
    </g>
  </svg>
);

export default Approved;

import PropTypes from 'prop-types';
import _ from 'lodash';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import StatisticsTableHead from './statistics-table/StatisticsTableHead.react';
import StatisticsTableRow from './statistics-table/StatisticsTableRow.react';
import StatisticsTableTotals from './statistics-table/StatisticsTableTotals.react';

import { addAllSelectedRows, addSelectedRow, removeSelectedRow, setSelectedRows } from '../../actions/statistics-actions';
import { detectMobileAndTablet, sortStatsColumns } from '../../utils/helpers';

import consts from '../../utils/consts';
import { v4 as uuidv4 } from 'uuid';

const { COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT, MAXIMUM_STATS_ROWS_SELECTED } = consts;

const StatisticsTableContainer = ({
  dispatch,
  drillDownValues,
  handleColumnSort,
  statisticsColumns,
  statisticsQueries,
  statisticsSelectedRows,
  statisticsSort,
  statisticsSummary,
  statisticsTableLevel,
}) => {
  const handleSelectRow = (e) => {
    const value = e.target.value;
    const indexOfValue = statisticsSelectedRows.indexOf(value);

    if (indexOfValue < 0) {
      if (statisticsSelectedRows.length < MAXIMUM_STATS_ROWS_SELECTED) {
        dispatch(addSelectedRow(indexOfValue, value));
      }
    } else {
      dispatch(removeSelectedRow(indexOfValue));
    }
  };

  const handleSelectAllRows = (e) => {
    if (
      !!statisticsQueries[statisticsTableLevel] &&
      !!statisticsQueries[statisticsTableLevel].response &&
      statisticsSelectedRows.length >= MAXIMUM_STATS_ROWS_SELECTED &&
      !e.target.checked
    ) {
      dispatch(setSelectedRows([]));
    } else {
      document.getElementById('statistics-checkbox-all').checked = true;
      dispatch(addAllSelectedRows());
    }
  };

  const currentTableData = statisticsQueries[statisticsTableLevel]
    ? statisticsQueries[statisticsTableLevel].response.data.data || []
    : [];
  const currentTableDataLength = typeof currentTableData !== 'undefined' ? currentTableData.length : 0;

  const statisticsDrillDownFields = [...drillDownValues[statisticsTableLevel].fields].reverse();
  const statisticsDrillDownFilters = drillDownValues[statisticsTableLevel].filters;
  const statisticsDrillDownGroups = drillDownValues[statisticsTableLevel].groups || [];

  const drillDownGroups =
    Object.prototype.hasOwnProperty.call(statisticsDrillDownGroups, 'length') &&
    !!statisticsDrillDownGroups[statisticsDrillDownGroups.length - 1]
      ? statisticsDrillDownGroups[statisticsDrillDownGroups.length - 1]
      : [];
  const statisticsFieldsAndColumns = sortStatsColumns(
    _.uniq([
      drillDownGroups,
      ...statisticsDrillDownFields,
      ...statisticsColumns,
      !detectMobileAndTablet() && 'Stat.paidConversions',
    ])
  );

  const statisticsTableTotals = (
    <StatisticsTableTotals statisticsColumns={statisticsColumns} statisticsSummary={statisticsSummary} />
  );

  return (
    <div className="row statisticsTable">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table bordered highlight">
            <StatisticsTableHead
              handleColumnSort={handleColumnSort}
              handleSelectAllRows={handleSelectAllRows}
              statisticsFieldsAndColumns={statisticsFieldsAndColumns}
              statisticsSort={statisticsSort}
            />
            <CSSTransitionGroup
              className="animationOffersTableRows"
              component="tbody"
              transitionEnterTimeout={currentTableDataLength * 50 + 500}
              transitionLeave={false}
              transitionLeaveTimeout={0}
              transitionName="tablerow"
            >
              {statisticsTableTotals}
              {currentTableData.length > 0 ? (
                currentTableData.map((statisticsRow, rowIndex) => {
                  const indexOfValue = statisticsSelectedRows.indexOf(JSON.stringify(statisticsRow));
                  const isSelected = indexOfValue >= 0;

                  return (
                    <StatisticsTableRow
                      classes={[isSelected ? `selected-${indexOfValue}` : undefined]}
                      dispatch={dispatch}
                      handleSelectRow={handleSelectRow}
                      isSelected={isSelected}
                      key={`tablerow-${rowIndex}`}
                      rowIndex={rowIndex}
                      statisticsDrillDownFilters={statisticsDrillDownFilters}
                      statisticsDrillDownGroups={statisticsDrillDownGroups}
                      statisticsFieldsAndColumns={statisticsFieldsAndColumns}
                      statisticsRow={statisticsRow}
                      statisticsTableLevel={statisticsTableLevel}
                    />
                  );
                })
              ) : (
                <tr className="nodata">
                  <td>
                    <div>No data</div>
                  </td>
                  {statisticsColumns.map((column) => (
                    <td
                      className={COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(column) >= 0 ? 'text-right' : undefined}
                      key={uuidv4()}
                    >
                      <div>N/A</div>
                    </td>
                  ))}
                </tr>
              )}
              {statisticsTableTotals}
            </CSSTransitionGroup>
          </table>
        </div>
      </div>
    </div>
  );
};

StatisticsTableContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  drillDownValues: PropTypes.object.isRequired,
  handleColumnSort: PropTypes.func.isRequired,
  statisticsColumns: PropTypes.array.isRequired,
  statisticsQueries: PropTypes.object.isRequired,
  statisticsSelectedRows: PropTypes.array.isRequired,
  statisticsSort: PropTypes.object.isRequired,
  statisticsSummary: PropTypes.object.isRequired,
  statisticsTableLevel: PropTypes.number.isRequired,
};

export default StatisticsTableContainer;

import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Scroller } from '../../components/reusables';

import { changeReferralsDateFilters } from '../../actions/referrals-actions';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import { v4 as uuidv4 } from 'uuid';

import 'materialize-css';
import { Select } from 'react-materialize';

const ReferralsSearch = ({ className, dispatch, filters, isDisabled }) => {
  const changeDateFilters = (e) => {
    e.preventDefault();
    const dates = e.target.getAttribute('data-dates') || e.target.parentNode.getAttribute('data-dates') || e.target.value;

    if (!isDisabled) {
      dispatch(changeReferralsDateFilters(dates));
    }
  };
  const filterDates = filters['Stat.date'].values;

  const getClassNames = (dateFilter) =>
    [filterDates.indexOf(dateFilter) >= 0 ? 'active' : undefined, isDisabled ? 'disabled' : undefined].join(' ');

  const dateNow = moment().format('YYYY-MM-DD');
  const tabs = [
    {
      dateString: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      messageKey: 'genericTextLast7Days',
      gtm: () => {
        Gtm.event('referrals', 'click', 'last 7 days');
      },
    },
    {
      dateString: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      messageKey: 'genericTextLastMonth',
      gtm: () => {
        Gtm.event('referrals', 'click', 'last month');
      },
    },
    {
      dateString: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      messageKey: 'genericTextLast3Months',
      gtm: () => {
        Gtm.event('referrals', 'click', 'last 3 months');
      },
    },
    {
      dateString: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      messageKey: 'genericTextLastYear',
      gtm: () => {
        Gtm.event('referrals', 'click', 'last year');
      },
    },
    {
      dateString: '2000-01-01',
      messageKey: 'genericTextAllTime',
      gtm: () => {
        Gtm.event('referrals', 'click', 'all time');
      },
    },
  ];

  return (
    <div>
      <div className={`search-component ${className}`} id="search-filters-line">
        <div className="filter-by">
          <FormattedMessage {...messages.genericTextFilterBy} />
        </div>
        <Scroller classNames={['tabs']}>
          <ul>
            {tabs.map((tab) => (
              <li
                className={getClassNames(tab.dateString)}
                data-dates={[tab.dateString, dateNow]}
                key={uuidv4()}
                onClick={(e) => {
                  tab.gtm();
                  changeDateFilters(e);
                }}
              >
                <FormattedMessage {...messages[tab.messageKey]} />
              </li>
            ))}
          </ul>
        </Scroller>
      </div>
      <div className={`search-component ${className}`} id="search-filters-dropdown">
        <Select
          id="date-filter"
          label={messages.genericTextFilterBy.description}
          onChange={changeDateFilters}
          tabIndex={140}
          value="Alltime test"
        >
          {tabs.reverse().map((tab) => (
            <option key={uuidv4()} value={[tab.dateString, dateNow]}>
              {messages[tab.messageKey].defaultMessage}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

ReferralsSearch.defaultProps = {
  isDisabled: false,
};

ReferralsSearch.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default injectIntl(ReferralsSearch);

import { Component } from 'react';
import PropTypes from 'prop-types';
import ellipsisOverflow from 'ellipsis-overflow';

class Ellipsis extends Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);

    this.container = null;
  }

  componentDidMount() {
    this.initial = this.container.innerHTML;
    ellipsisOverflow(this.container);

    window.addEventListener('resize', this.update);
  }

  componentDidUpdate() {
    this.initial = this.container.innerHTML;
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  update() {
    this.container.innerHTML = this.initial;
    ellipsisOverflow(this.container);
  }

  render() {
    const { children, height } = this.props;

    return (
      <div className="ellipsis-parent" style={{ height }}>
        <span
          className="ellipsis-child"
          ref={(el) => {
            this.container = el;
          }}
        >
          {children}
        </span>
      </div>
    );
  }
}

Ellipsis.propTypes = {
  children: PropTypes.object.isRequired,
  height: PropTypes.string.isRequired,
};

export default Ellipsis;

const EmailTick = () => (
  <svg height="47.29" viewBox="0 0 47.29 47.29" width="47.29" xmlns="http://www.w3.org/2000/svg">
    <g data-name="vuesax/bulk/message-tick" id="vuesax_bulk_message-tick" transform="translate(-364 -316)">
      <g id="message-tick" transform="translate(364 316)">
        <path d="M0,0H47.29V47.29H0Z" fill="none" id="Vector" opacity="0" />
        <path
          d="M39.424,9.857V21.685a9.866,9.866,0,0,1-2.72,7.156,9.852,9.852,0,0,1-7.136,2.7v4.2A1.973,1.973,0,0,1,26.512,37.4l-8.772-5.855H13.562a7.085,7.085,0,0,0,.237-1.833,7.855,7.855,0,0,0-2.03-5.264,7.774,7.774,0,0,0-5.854-2.622A7.874,7.874,0,0,0,.256,24.209,11.53,11.53,0,0,1,0,21.685V9.857C0,3.943,3.942,0,9.856,0H29.568C35.481,0,39.424,3.943,39.424,9.857Z"
          data-name="Vector"
          fill="#00bd00"
          id="Vector-2"
          opacity="0.4"
          transform="translate(3.933 4.779)"
        />
        <g id="Group" transform="translate(15.287 19.214)">
          <path
            d="M15.241,2.95H1.475A1.486,1.486,0,0,1,0,1.475,1.486,1.486,0,0,1,1.475,0H15.241a1.486,1.486,0,0,1,1.475,1.475A1.486,1.486,0,0,1,15.241,2.95Z"
            data-name="Vector"
            fill="#00bd00"
            id="Vector-3"
          />
        </g>
        <path
          d="M7.866,0A7.864,7.864,0,0,0,0,7.866a7.711,7.711,0,0,0,1.141,4.051,7.836,7.836,0,0,0,13.452,0,7.711,7.711,0,0,0,1.141-4.051A7.864,7.864,0,0,0,7.866,0Zm3.874,7.217L7.552,11.092a1.5,1.5,0,0,1-1,.393,1.459,1.459,0,0,1-1.042-.433L3.56,9.105A1.474,1.474,0,0,1,5.644,7.021l.944.944L9.735,5.054a1.475,1.475,0,1,1,2.006,2.163Z"
          data-name="Vector"
          fill="#00bd00"
          id="Vector-4"
          transform="translate(1.967 26.641)"
        />
      </g>
    </g>
  </svg>
);

export default EmailTick;

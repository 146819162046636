import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOffer } from '../offer/Offer-Actions/Offer-Actions';
import { useEffect } from 'react';
import { openModal } from '../../components/Modal/modal-actions/modal-actions.js';
import { useCallback } from 'react';
import { BUTTON_TEXT_BY_STATUS, CHIP_TEXT, PARAGRPAHS_BY_STATUS, STATUSES, VAST_GTM_EVENTS, VAST_OUTLINK } from './values.js';
import { Gtm } from '../../utils/gtm.js';

const VastPreRoll = ({ dispatch, offer, offerIsFetching }) => {
  let approvalStatus = '';

  if (!offerIsFetching) {
    if (!offer.id || offer.approval_status === 'rejected') {
      approvalStatus = STATUSES.REJECTED;
    } else if (!offer.approval_status) {
      approvalStatus = STATUSES.UNAPPROVED;
    } else {
      approvalStatus = offer.approval_status;
    }
  }

  const buttonText = BUTTON_TEXT_BY_STATUS[approvalStatus];

  const onClick = useCallback(() => {
    const gtmValue = VAST_GTM_EVENTS[approvalStatus];

    switch (approvalStatus) {
      case STATUSES.APPROVED:
      case STATUSES.REJECTED:
        window.Intercom('showNewMessage');
        Gtm.event(`vast preroll page`, 'Click', 'need_help');
        break;
      case STATUSES.UNAPPROVED:
        dispatch(
          openModal({
            name: 'OfferRequestModal',
            modalProps: {
              dispatch,
              vastPreRoll: true,
            },
          })
        );
        break;
    }
    if (gtmValue) {
      Gtm.event('UI Component', 'Click', gtmValue);
    }
  }, [approvalStatus, dispatch]);

  useEffect(() => {
    dispatch(fetchOffer(process.env.REACT_APP_VAST_PREROLL_OFFER_ID));
  }, []);

  return (
    <div className={`vastPreRoll container-fluid app-container ${offerIsFetching ? 'loading' : ''}`}>
      <div className="topTitle">
        <h1 className="primary-color title">VAST Tag Pre-Roll Ads</h1>
        <div className={`chipTag ${approvalStatus}`}>{CHIP_TEXT[approvalStatus]}</div>
      </div>
      <div className="content">
        <div className="leftContent">
          <p>
            Discover the power of VAST tags, the gold standard for unleashing captivating pre-roll video ads! Elevate your
            campaign's performance while ensuring maximum visibility for your video ads. Our expert team provides an optimal
            rotation with the help of machine learning to help you reach full potential. Please note that a VAST-compatible video
            player is required for this tool. Learn more about the realm of possibilities with VAST 3.0{' '}
            <a href={VAST_OUTLINK} target="_blank">
              here.
            </a>
          </p>
          {PARAGRPAHS_BY_STATUS[approvalStatus] && (
            <p>
              <div className="subText">
                <div className="circle"></div>
                <span>{PARAGRPAHS_BY_STATUS[approvalStatus]}</span>
              </div>
            </p>
          )}
        </div>
        <button className="btn" disabled={approvalStatus === STATUSES.PENDING} onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

VastPreRoll.propTypes = {
  offer: PropTypes.object.isRequired,
  offerIsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
  offer: state.offer.offer,
  offerIsFetching: state.offer.offerIsFetching,
}))(VastPreRoll);

/**
 * @file Video
 * @author Pierre-Luc Gagn�
 * @version 0.0.1
 */

function Video() {
  return (
    <svg
      className="video-svg-icon-path-image"
      height="24px"
      id="Capa_1"
      version="1.1"
      viewBox="0 0 351.465 351.465"
      width="24px"
      x="0px"
      y="0px"
    >
      <g>
        <g id="Camera_4_">
          <g>
            <path
              d="M249.427,68.025H22.675C10.158,68.025,0,78.184,0,90.701v170.064c0,12.517,10.158,22.675,22.675,22.675h226.751     c12.517,0,22.675-10.158,22.675-22.675V90.701C272.102,78.184,261.943,68.025,249.427,68.025z M249.427,238.089     c0,12.517-10.158,22.675-22.675,22.675H45.35c-12.517,0-22.675-10.158-22.675-22.675V113.376     c0-12.517,10.158-22.675,22.675-22.675h181.401c12.517,0,22.675,10.158,22.675,22.675V238.089z M343.166,102.537     c-4.875-1.474-10,0.408-12.789,4.558l-35.6,40.294v68.025l35.6,40.294c2.154,3.22,5.759,5.057,9.501,5.057     c1.088,0,2.199-0.159,3.288-0.476c4.807-1.451,8.299-5.873,8.299-10.861V113.376     C351.465,108.387,347.973,103.988,343.166,102.537z M113.376,226.751l56.688-45.35l-56.688-45.35V226.751z"
              fill="#656565"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Video;

import PropTypes from 'prop-types';
import messages from '../../i18n/base-en';
import { InputText } from '../reusables';
import SubdivisionFormField from './SubdivisionFormField.react';

const BeneficiaryAddressSection = ({
  extendedAddressLines,
  fields: {
    city,
    province,
    state,
    region,
    country,
    postalCode,
    address1,
    address2,
    address3,
    address4,
    address5,
    apartment_suite_unit_building,
  },
  subdivisionDisabled,
}) => (
  <>
    <SubdivisionFormField
      countryIsoCode2={country.initialValue}
      disabled={subdivisionDisabled}
      provinceField={province}
      regionField={region}
      stateField={state}
    />
    <div className="beneficiary-input">
      <InputText
        displayErrorInstantly
        field={address1}
        id="address1-id"
        label={{
          ...messages.genericTextAddress,
          defaultMessage: 'Address Line 1',
        }}
        type="text"
      />
    </div>

    {address2.value || address2.initialValue ? (
      <div className="beneficiary-input">
        <InputText
          displayErrorInstantly
          field={address2}
          id="address2-id"
          label={{
            ...messages.genericTextAddress2,
            defaultMessage: 'Address Line 2',
          }}
          type="text"
        />
      </div>
    ) : null}

    {extendedAddressLines ? (
      <>
        {address3.value || address3.initialValue ? (
          <div className="beneficiary-input" style={{ display: address2.value ? 'block' : 'none' }}>
            <InputText
              displayErrorInstantly
              field={address3}
              id="address3-id"
              label={{
                ...messages.genericTextAddress,
                defaultMessage: 'Address Line 3',
              }}
              type="text"
            />
          </div>
        ) : null}
        {address4.value || address4.initialValue ? (
          <div className="beneficiary-input" style={{ display: address3.value ? 'block' : 'none' }}>
            <InputText
              displayErrorInstantly
              field={address4}
              id="address4-id"
              label={{
                ...messages.genericTextAddress,
                defaultMessage: 'Address Line 4',
              }}
              type="text"
            />
          </div>
        ) : null}
        {address5.value || address5.initialValue ? (
          <div className="beneficiary-input" style={{ display: address4.value ? 'block' : 'none' }}>
            <InputText
              displayErrorInstantly
              field={address5}
              id="address5-id"
              label={{
                ...messages.genericTextAddress,
                defaultMessage: 'Address Line 5',
              }}
              type="text"
            />
          </div>
        ) : null}
      </>
    ) : null}

    <div className="beneficiary-input">
      <InputText
        displayErrorInstantly
        field={apartment_suite_unit_building}
        id="address-apt-suite"
        label={{
          ...messages.genericTextAddressAptSuite,
          defaultMessage: 'Apt, Suite, Unit, Building',
        }}
        type="text"
      />
    </div>

    <div className="beneficiary-input">
      <InputText displayErrorInstantly field={postalCode} id="postalCode-id" label={messages.genericTextZipCode} type="text" />
    </div>
    <div className="beneficiary-input">
      <InputText displayErrorInstantly field={city} id="city-id" label={messages.genericTextCity} labelClasses="" type="text" />
    </div>
  </>
);

BeneficiaryAddressSection.propTypes = {
  extendedAddressLines: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  subdivisionDisabled: PropTypes.bool,
};

export default BeneficiaryAddressSection;

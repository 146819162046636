import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import messages from '../../../i18n/base-en.js';
import PropTypes from 'prop-types';

const OfferDescriptionModal = ({ dispatch, modalInner, description }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <>
      <div className="modal-content">
        <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
          <i className="material-icons">close</i>
        </span>
      </div>
      <h1 className="modal-title">
        <FormattedMessage {...messages.offerDescription} />
      </h1>
      <div className="modal-inner-content">{ReactHtmlParser(description)}</div>
    </>
  );
};

OfferDescriptionModal.propTypes = {
  description: PropTypes.string,
  dispatch: PropTypes.func,
  modalInner: PropTypes.object.isRequired,
};

export default injectIntl(OfferDescriptionModal);

import PropTypes from 'prop-types';

const InputNotice = ({ children, className, dataCyLabel, detailsUrl }) =>
  children ? (
    <div className={`row notice-container ${className}`}>
      <div className="col-sm-12">
        <span className="notice" data-cy={`inputNotice-${dataCyLabel}`}>
          <i className="material-icons notice-icon">info</i>
          {children}
          {detailsUrl ? (
            <a href={detailsUrl} rel="noopener noreferrer" target="_blank">
              (<span className="text">Details</span>)
            </a>
          ) : null}
        </span>
      </div>
    </div>
  ) : null;

InputNotice.defaultProps = {
  detailsUrl: undefined,
  dataCyLabel: '',
};

InputNotice.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dataCyLabel: PropTypes.string,
  detailsUrl: PropTypes.string,
};

export default InputNotice;

import { useEffect } from 'react';
import PropTypes from 'prop-types';

import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';
import { withRouter } from 'react-router';

function PaxumSection({ fields, willRender }) {
  let paxumSection = false;

  useEffect(() => {}, [willRender]);

  if (willRender) {
    paxumSection = (
      <div className="paxum_section">
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.paxumEmail}
            id="paxumEmail"
            label={messages.paymentInfosPaxumEmailLabel}
            tabIndex={30}
            type="email"
          />
        </div>
      </div>
    );
  }

  return paxumSection;
}

PaxumSection.propTypes = {
  fields: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default withRouter(PaxumSection);

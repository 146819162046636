export default {
  loginSplashPreparingAccount: {
    id: 'loginSplash.preparingAccount',
    description: 'Preparing your Account',
    defaultMessage: 'Preparing your Account',
  },
  loginSplashLoading: {
    id: 'loginSplash.loading',
    description: 'Loading',
    defaultMessage: 'Loading',
  },
  loginSplashLogin: {
    id: 'loginSplash.login',
    description: 'Logging in',
    defaultMessage: 'Logging in',
  },
  loginSplashCreatingAccount: {
    id: 'loginSplash.creatingAccount',
    description: 'Creating Account',
    defaultMessage: 'Creating Account',
  },
  loginSplashErrorOccured: {
    id: 'loginSplash.errorOccured',
    description: 'An error has occured, please try again later.',
    defaultMessage: 'An error has occured, please try again later.',
  },
  loginSplashAccountPending: {
    id: 'Your Account is not verified yet. Give us time to review your account',
    description: 'Your Account is not verified yet. Give us time to review your account',
    defaultMessage: 'Your Account is not verified yet. Give us time to review your account',
  },
};

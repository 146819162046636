import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import validate from './validateLoginForm';

import { Checkbox, InputText, SimplifiedInput } from '../reusables';
import GoogleSignup from '../reusables/svg/GoogleSignUp.react';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';

class LoginForm extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);

    this.showError = this.showError.bind(this);
    this.handleGoogleClick = this.handleGoogleClick.bind(this);

    this.state = {
      hideError: true,
    };
  }

  showError() {
    this.setState({ hideError: false });
  }

  handleGoogleClick() {
    const { googleLogin } = this.props;
    googleLogin();
  }

  render() {
    const { fields, googleIsLoading, handleSubmit, isFetchingAuth, skinConfigurations } = this.props;

    const SKIN = new SkinConfigurationsUtils(skinConfigurations);
    const scrollIntoView = (event) => {
      setTimeout(() => {
        const focusedElement = event.target;
        focusedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    };

    fields.email.onFocus = scrollIntoView;
    fields.password.onFocus = scrollIntoView;

    return (
      <div id="login-form">
        <div className="row">
          {SKIN.isItemVisible('CreateAccount', 'GoogleSignup') && (
            <div className="row google-btn-box">
              <div className="col-sm-12  social-login">
                <GoogleSignup
                  disabled={googleIsLoading}
                  label="Log in with Google"
                  onClick={() => {
                    this.handleGoogleClick();
                  }}
                />
                <div className="simple-signup__google-bot-border">
                  <span>OR</span>
                </div>
              </div>
            </div>
          )}
          <form
            action="#"
            className="col-xs-12"
            method="post"
            noValidate
            onSubmit={(e) => {
              this.showError();
              handleSubmit(e);
            }}
          >
            <div className="row simple-signup__container__box">
              <SimplifiedInput
                autofocus
                className="simple-signup__input input-field"
                dataCy="input-text-login-email"
                field={fields.email}
                label={messages.loginFormLabelEmail}
                showValidationIcon={false}
                tabIndex={10}
                type="email"
              />
            </div>
            <div className="row simple-signup__container__box">
              <SimplifiedInput
                autofocus
                canShowPassword={false}
                className="simple-signup__input input-field"
                dataCy="input-text-login-password"
                field={fields.password}
                id="password"
                label={messages.loginFormLabelPassword}
                showValidationIcon={false}
                tabIndex={20}
                type="password"
              />
            </div>

            <div className="row">
              <div className="col-md-12 password-reset">
                <div className="pull-right">
                  <Link data-cy="link-login-password-reset" onlyActiveOnIndex={false} to="/password-reset">
                    <FormattedMessage {...messages.loginPageForgottenPassword} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-buttons text-center">
                <div>
                  <button className="waves-effect waves-light btn" data-cy="button-login-submit" disabled={isFetchingAuth}>
                    <FormattedMessage {...messages.loginPageStartSession} />
                  </button>
                </div>
                <div>
                  <Checkbox
                    className="remember-me"
                    dataCy="checkbox-login-remember-me"
                    field={fields.rememberMe}
                    id="rememberMe"
                    label={messages.loginFormLabelRememberMe.defaultMessage}
                    tabIndex={30}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

LoginForm.defaultProps = {
  googleIsLoading: false,
  isFetchingAuth: false,
};

LoginForm.propTypes = {
  fields: PropTypes.object,
  googleIsLoading: PropTypes.bool,
  googleLogin: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isFetchingAuth: PropTypes.bool,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
}))(
  reduxForm(
    {
      form: 'loginForm',
      touchOnChange: false,
      touchOnBlur: true,
      fields: ['email', 'password', 'rememberMe'],
      validate,
    },
    () => ({
      initialValues: {
        email: '',
        password: '',
        rememberMe: false,
      },
    })
  )(LoginForm)
);

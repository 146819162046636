import PropTypes from 'prop-types';

const LoadingBar = ({ className, style }) => (
  <div className={`loading-bar ${className}`} style={style}>
    <div className="bar-1 active bg-primary-color" />
    <div className="bar-2 active bg-primary-color" />
    <div className="bar-3 active bg-primary-color" />
    <div className="bar-4 active bg-primary-color" />
  </div>
);

LoadingBar.defaultProps = {
  className: '',
  style: {},
};

LoadingBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingBar;

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';
import store from '../../../create-store';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestPaymentCompleteValidation = () => ({
  type: ACTIONS.REQUEST_PAYMENTC_VALIDATION,
});
const receivePaymentCompleteValidation = (data, globalState) => ({
  type: ACTIONS.RECEIVE_PAYMENTC_VALIDATION,
  needPaymentMethod: data.needPaymentMethod,
  hasAPaymentMethod: data.hasAPaymentMethod,
  hasEarnedMoney: data.hasGatteredMoney,
  globalState: globalState,
});
const failedPaymentCompleteValidation = () => ({
  type: ACTIONS.FAILED_PAYMENTC_VALIDATION,
});
export const fetchPaymentCompleteVerification = (code) => (dispatch) => {
  dispatch(requestPaymentCompleteValidation);

  return new Promise((fulfill, reject) =>
    // Get info about payment info
    request
      .get(`${API_URL}/validate/has-to-complete-payment-method`)
      .query({ code })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const globalState = store.getState();
          dispatch(receivePaymentCompleteValidation(res.body, globalState));

          fulfill();
        } else {
          dispatch(failedPaymentCompleteValidation);
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestProfileCompletedValidation = () => ({
  type: ACTIONS.REQUEST_PROFILEC_VALIDATION,
});
const receiveProfileCompletedValidation = (data, globalState) => ({
  type: ACTIONS.RECEIVE_PROFILEC_VALIDATION,
  hasToCompleteInformation: data.hasToCompleteInformation,
  globalState: globalState,
});
const failedProfileCompletedValidation = () => ({
  type: ACTIONS.FAILED_PROFILEC_VALIDATION,
});
export const fetchProfileCompleteVerification = (code) => (dispatch) => {
  dispatch(requestProfileCompletedValidation);
  return new Promise((fulfill, reject) =>
    // get info about profile completed
    request
      .get(`${API_URL}/validate/has-completed-all-information`)
      .query({ code })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const globalState = store.getState();
          dispatch(receiveProfileCompletedValidation(res.body, globalState));
          fulfill();
        } else {
          dispatch(failedProfileCompletedValidation);
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import React from 'react';
import { useCreativesBanners } from '../CreativesBanners';
import {
  BANNER_TAB_ALL_CREATIVES,
  BANNER_TAB_TRENDING_BANNERS,
  CREATIVE_BANNER_TABS,
  USE_PROVEN_FLOW,
  isSelectCreativesTabs,
} from './values';
import { Gtm } from '../../../../utils/gtm';

const Tabs = React.memo(() => {
  const { activeTab, setActiveTab, selectedCreatives, creativesTrending } = useCreativesBanners();

  const selectedCreativesTabCount = selectedCreatives?.length;

  let tabs = CREATIVE_BANNER_TABS;

  if (!USE_PROVEN_FLOW() || !creativesTrending?.banners?.length) {
    tabs = tabs.filter(({ key }) => key !== BANNER_TAB_TRENDING_BANNERS);
  }

  const setTab = (key) => {
    if (isSelectCreativesTabs(key) && !selectedCreativesTabCount) {
      return;
    }

    Gtm.event('offers', 'Click', `banner - ${key}`);

    setActiveTab(key);
  };

  return (
    <div className={`bannerTitleTabs totalTabs${tabs?.length}`}>
      {tabs.map(({ key, text }, index) => (
        <div
          key={index}
          className={`bannerTab ${isSelectCreativesTabs(key) && !selectedCreativesTabCount ? 'disabled' : ''}${
            activeTab === key ? 'active' : ''
          }`}
          onClick={() => setTab(key)}
        >
          {key === BANNER_TAB_ALL_CREATIVES ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.147 23.147">
              <g id="img-grey" transform="translate(-2 -2)">
                <g id="Image" transform="translate(2 2)">
                  <path
                    id="Tracé_1567"
                    data-name="Tracé 1567"
                    d="M16.589,0c3.923,0,6.558,2.752,6.558,6.848V16.3c0,4.1-2.635,6.848-6.559,6.848H6.558C2.635,23.147,0,20.395,0,16.3V6.848C0,2.752,2.635,0,6.558,0Zm1.276,12.21a1.819,1.819,0,0,0-2.457.66c-.249.336-.463.706-.689,1.076a6.625,6.625,0,0,1-2.278,2.58c-1.59.88-2.8.069-3.665-.521a6.394,6.394,0,0,0-.958-.566c-.777-.336-1.477.047-2.515,1.365-.545.689-1.085,1.372-1.632,2.053a.893.893,0,0,0,.192,1.308,4.78,4.78,0,0,0,2.534.666h9.754a5.237,5.237,0,0,0,1.628-.247A4.28,4.28,0,0,0,20.4,18.142a4.362,4.362,0,0,0,.228-3.172,2.611,2.611,0,0,0-.594-.907A12.481,12.481,0,0,0,17.866,12.21ZM7.522,4.629a2.893,2.893,0,1,0,2.892,2.893A2.9,2.9,0,0,0,7.522,4.629Z"
                  />
                </g>
              </g>
            </svg>
          ) : key === BANNER_TAB_TRENDING_BANNERS ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="23.878" height="23.878" viewBox="0 0 23.878 23.878">
              <path
                id="trending-black"
                d="M21.371,14.243a.871.871,0,0,1-.245.033.941.941,0,0,1-.91-.7l-.466-1.725-3.408,5.922a.943.943,0,0,1-1.287.347L11.717,16.2,9.568,19.936A.942.942,0,1,1,7.934,19l2.619-4.549A.946.946,0,0,1,11.84,14.1l3.337,1.919,2.938-5.106-1.732.466A.943.943,0,1,1,15.9,9.556L19.821,8.5a.752.752,0,0,1,.119-.008.94.94,0,0,1,.245-.016,37418.823,37418.823,0,0,0,.351.117.872.872,0,0,1,.187.165.789.789,0,0,1,.084.074.944.944,0,0,1,.17.343l1.057,3.911A.94.94,0,0,1,21.371,14.243ZM19.932,2.5H9.945C5.791,2.5,3,5.167,3,9.137V19.742c0,3.969,2.791,6.635,6.945,6.635h9.987c4.155,0,6.946-2.667,6.946-6.635V9.137C26.878,5.167,24.086,2.5,19.932,2.5Z"
                transform="translate(-3 -2.5)"
                fill-rule="evenodd"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="23.059" height="23.059" viewBox="0 0 23.059 23.059">
              <path
                id="check-circle-grey"
                d="M18.876,11.872l-5.6,5.6a.91.91,0,0,1-1.288,0l-2.8-2.8a.91.91,0,1,1,1.286-1.288l2.16,2.159,4.96-4.961a.911.911,0,1,1,1.288,1.288ZM14.03,2.5a11.53,11.53,0,1,0,11.53,11.53A11.543,11.543,0,0,0,14.03,2.5Z"
                transform="translate(-2.5 -2.5)"
                fill-rule="evenodd"
              />
            </svg>
          )}

          <span>
            {text} {isSelectCreativesTabs(key) ? `(${selectedCreativesTabCount})` : ''}
          </span>
        </div>
      ))}
    </div>
  );
});

export default Tabs;

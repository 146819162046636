const Dating = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.40593 17.5047H3.829C3.6029 17.5048 3.38103 17.4434 3.18714 17.3271C2.99326 17.2108 2.83465 17.0439 2.72829 16.8444C2.62193 16.6448 2.57182 16.4201 2.58333 16.1944C2.59483 15.9685 2.66753 15.7501 2.79362 15.5625C2.96285 15.3086 3.17823 14.9985 3.35361 14.7562C3.37169 14.73 3.38193 14.6991 3.38316 14.6673C3.38438 14.6354 3.37654 14.6038 3.36054 14.5763C3.07753 14.1105 2.81946 13.6301 2.58746 13.1371C1.90933 11.6632 1.72705 10.0094 2.06793 8.42325C2.40882 6.83714 3.2545 5.40419 4.47828 4.33914C5.70206 3.27409 7.23801 2.63428 8.856 2.5156C10.474 2.39691 12.0868 2.80574 13.4529 3.68082C14.819 4.55589 15.8648 5.8501 16.4335 7.36949C17.0022 8.88891 17.0632 10.5516 16.6073 12.1086C16.1515 13.6656 15.2035 15.033 13.9052 16.0058C12.6068 16.9786 11.0282 17.5045 9.40593 17.5047ZM7.69977 16.3508H9.40593C10.3319 16.3504 11.2466 16.1475 12.086 15.7565C12.9253 15.3655 13.6691 14.7957 14.2652 14.0871C15.1905 12.9661 15.7081 11.5643 15.7329 10.111C15.7578 8.65768 15.2886 7.23894 14.4021 6.08704C13.8475 5.38108 13.1497 4.80065 12.3545 4.38403C11.5593 3.9674 10.6848 3.72402 9.78877 3.66995C8.89269 3.61588 7.99531 3.75233 7.15576 4.07031C6.31625 4.38828 5.55364 4.88058 4.91823 5.51472C4.00145 6.43728 3.38856 7.61801 3.16172 8.89868C2.93486 10.1794 3.10489 11.4988 3.649 12.6801C3.71054 12.8301 3.92054 13.2355 4.36208 14.0001C4.48818 14.2191 4.54896 14.4697 4.53724 14.7221C4.52552 14.9745 4.44178 15.2184 4.29593 15.4248C4.129 15.6594 3.919 15.9578 3.75746 16.2032C3.7466 16.2171 3.74012 16.2341 3.73889 16.2518C3.73766 16.2695 3.74172 16.2871 3.75054 16.3025C3.75811 16.3176 3.7699 16.3302 3.78451 16.3388C3.79912 16.3475 3.81591 16.3516 3.83285 16.3508H7.70362H7.69977Z"
      fill="white"
    />
    <path
      d="M9.40215 13.1376C9.30592 13.1375 9.21131 13.1134 9.12677 13.0676C9.04062 13.0199 7.01289 11.9014 6.50366 10.3183C6.39999 10.0434 6.35211 9.75065 6.36279 9.45704C6.37348 9.16342 6.44252 8.87489 6.56589 8.60819C6.68926 8.34159 6.8645 8.10217 7.08137 7.90395C7.29824 7.70574 7.55241 7.55269 7.82908 7.45374C8.349 7.28646 8.91346 7.3267 9.40446 7.56605C9.89346 7.33192 10.4528 7.29125 10.9706 7.4522C11.2478 7.55076 11.5027 7.70361 11.7202 7.90181C11.9377 8.1 12.1135 8.33957 12.2373 8.6065C12.3612 8.87342 12.4305 9.16235 12.4414 9.45642C12.4522 9.7505 12.4044 10.0438 12.3006 10.3191C11.8083 11.8853 9.76677 13.0191 9.68062 13.0668C9.59515 13.1133 9.49938 13.1376 9.40215 13.1376ZM7.60212 9.96527C7.99454 10.7727 8.62131 11.4429 9.40062 11.8883C10.1802 11.4428 10.8075 10.7727 11.2006 9.96527C11.2563 9.83419 11.2855 9.69342 11.2864 9.55096C11.2873 9.40858 11.2599 9.26735 11.2059 9.13558C11.1519 9.00381 11.0723 8.88404 10.9717 8.78327C10.8711 8.6825 10.7515 8.60265 10.6198 8.54835C10.4726 8.50296 10.3169 8.49289 10.1651 8.51881C10.0133 8.54473 9.86969 8.60596 9.746 8.69758C9.64623 8.77273 9.52469 8.81335 9.39985 8.81335C9.27492 8.81335 9.15338 8.77273 9.05369 8.69758C8.93008 8.60535 8.78623 8.54389 8.63415 8.51835C8.482 8.49273 8.326 8.50389 8.17908 8.55065C8.04746 8.60458 7.928 8.68412 7.82746 8.78465C7.72692 8.88527 7.64738 9.00473 7.59348 9.13635C7.53958 9.26789 7.51237 9.40889 7.51346 9.55104C7.51455 9.69327 7.54391 9.83381 7.59982 9.9645L7.60212 9.96527Z"
      fill="white"
    />
  </svg>
);

export default Dating;

const PerformerPageModalIconReact = () => (
  <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32_10229)">
      <mask height="30" id="mask0_32_10229" maskUnits="userSpaceOnUse" width="30" x="0" y="0">
        <path d="M30 0H0V30H30V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_32_10229)">
        <path
          d="M15 22.627C19.9253 22.627 23.918 18.6343 23.918 13.709C23.918 8.78374 19.9253 4.79102 15 4.79102C10.0748 4.79102 6.08203 8.78374 6.08203 13.709C6.08203 18.6343 10.0748 22.627 15 22.627Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 17.667C17.1859 17.667 18.958 15.895 18.958 13.709C18.958 11.5231 17.1859 9.75104 15 9.75104C12.814 9.75104 11.042 11.5231 11.042 13.709C11.042 15.895 12.814 17.667 15 17.667Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0003 15.908C16.2147 15.908 17.1993 14.9235 17.1993 13.709C17.1993 12.4945 16.2147 11.51 15.0003 11.51C13.7858 11.51 12.8013 12.4945 12.8013 13.709C12.8013 14.9235 13.7858 15.908 15.0003 15.908Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9522 22.127L19.1322 25.209H10.8672L12.0472 22.127"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_32_10229">
        <rect fill="white" height="30" width="30" />
      </clipPath>
    </defs>
  </svg>
);

export default PerformerPageModalIconReact;

const ValidatedRound = () => (
  <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <g id="validate_rounded" transform="translate(0.01)">
      <path
        d="M9.99,0a10,10,0,1,0,10,9.991A10,10,0,0,0,9.99,0ZM8.755,15.088h0L4.377,10.709,6.216,8.853l2.539,2.531L14.99,5.149l1.839,1.856Z"
        data-name="validate_rounded"
        fill="#00bd00"
        fillRule="evenodd"
        id="validate_rounded-2"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export default ValidatedRound;

import { SimplifiedInput } from '../../../../../components/reusables';
import socialsList from '../../../../../utils/list-options/socials';
import consts from '../../../../../utils/consts';
import CircleAdd from '../../../../../components/reusables/svg/CircleAdd';
import 'materialize-css';
import { Select } from 'react-materialize';
import CheckCircle from '../../../../reusables/svg/CheckCircle.react';

export const MESSENGER_NAMES = {
  SKYPE: 'Skype',
  TELEGRAM: 'Telegram',
  WHATSAPP: 'WhatsApp',
};

export const MESSENGERS = [MESSENGER_NAMES.SKYPE, MESSENGER_NAMES.TELEGRAM, MESSENGER_NAMES.WHATSAPP];

export const MESSENGER_PLACEHOLDERS = {
  [MESSENGER_NAMES.SKYPE]: 'Skype ID',
  [MESSENGER_NAMES.TELEGRAM]: 'Telegram username',
  [MESSENGER_NAMES.WHATSAPP]: 'WhatsApp phone number',
};

const TellUsAboutYouRoleDropdownInput = (props) => {
  const { fieldInput, fieldSelect, hidden, addField, showAdd, disabled, inputDisabled, inputOptions, tabIndex } = props;
  const { REFERENCES } = consts;

  const getOptions = () => {
    switch (inputOptions) {
      case 'socials':
        return socialsList;
      case 'references':
        return REFERENCES;
      case 'messaging_platform_name':
        return MESSENGERS;
      default:
        return [];
    }
  };

  const getPlaceHolder = () => {
    switch (inputOptions) {
      case 'socials':
        return 'Your username';
      case 'messaging_platform_name':
        return MESSENGER_PLACEHOLDERS[fieldSelect.value] || 'Choose a messenger';
      case 'references':
        return 'Specify';
      default:
        return '';
    }
  };

  const getIcon = (field) => (
    <i className="icon-validation" key="valid">
      {field.value !== '' && (field.touched || field.dirty) && field.valid ? <CheckCircle /> : ''}
    </i>
  );

  return (
    <div className={`row ${hidden ? 'hidden' : ''} custom-add`}>
      <div className={`cl-md-12 ${fieldSelect.name}`}>
        <Select
          icon={getIcon(fieldSelect)}
          onChange={fieldSelect.onChange}
          simplified
          tabIndex={tabIndex}
          value={fieldSelect.value || ''}
          options={{
            classes: '',
            dropdownOptions: {
              onOpenStart: (e) => {
                if (e.closest('#messagingPlatformNameSelect')) {
                  const howDidYouHearAboutUsSelect = document.getElementById('howDidYouHearAboutUsSelect');
                  const cover = howDidYouHearAboutUsSelect.querySelector('.cover');
                  cover.style.display = 'block';
                }
              },
              onCloseEnd: (e) => {
                const howDidYouHearAboutUsSelect = document.getElementById('howDidYouHearAboutUsSelect');
                const cover = howDidYouHearAboutUsSelect.querySelector('.cover');
                cover.style.display = 'none';
              },
            },
          }}
        >
          <option disabled={disabled} value="">
            Choose
          </option>
          {getOptions().map((option, i) => (
            <option key={`${fieldSelect.name}-${i}`} value={option.value ? option.value : option}>
              {option.value ? option.value : option}
            </option>
          ))}
        </Select>
      </div>
      <SimplifiedInput
        autofocus
        className="simple-signup__input"
        field={fieldInput}
        id="tellUsMore-simplified_role"
        key={`input-${fieldInput.name}`}
        placeholder={getPlaceHolder(fieldInput === 'messaging_platform_name')}
        tabIndex={tabIndex}
        type="text"
        inputDisabled={inputDisabled}
      />
      {showAdd ? (
        <div className="custom-svg" onClick={() => addField()}>
          <CircleAdd onClick={() => addField()} />
        </div>
      ) : null}
    </div>
  );
};

export default TellUsAboutYouRoleDropdownInput;

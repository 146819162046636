import { Link } from 'react-router';

const HasToEarnRevenues = () => (
  <div className="complete-profile-component">
    <h2>
      Before you can complete the identity validation process, <br />
      you need to have generated a minimum of $25 with us.
    </h2>
    <Link className="btn" onlyActiveOnIndex={false} to="/offers#seeAll">
      SELECT AN OFFER
    </Link>
  </div>
);

export default HasToEarnRevenues;

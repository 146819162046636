import PropTypes from 'prop-types';

const getClassName = ({ tagClassNames, tagCompare }) =>
  [...tagClassNames, 'search-tag', !!tagCompare && tagCompare === 'exclude' ? 'exclude' : undefined].join(' ');

const getTags = (values) => {
  if (values) {
    if (values instanceof Array) {
      return values;
    } else if (values instanceof Object) {
      const tags = [];
      Object.keys(values).forEach((key) => tags.push(...values[key]));
      return tags;
    } else if (typeof values === 'string') {
      return values.split(' ');
    }
  }
  return [];
};

const SearchTags = ({ handleRemoveTag, tagClassNames, values }) => (
  <div className="clearfix">
    {getTags(values)
      .filter((tag) => tag !== '' || typeof tag === 'object')
      .map((tag, index) => (
        <div
          className={getClassName({
            tagClassNames,
            tagCompare: tag.compare,
          })}
          key={`search-tag-${index}`}
          onClick={(e) => handleRemoveTag(tag.id, tag.value, tag.compare, e)}
        >
          <span className="tag-text">{typeof tag === 'object' ? `${tag.category}: ${tag.text}` : tag}</span>
          <i className="material-icons">close</i>
        </div>
      ))}
  </div>
);

SearchTags.defaultProps = {
  tagClassNames: [],
};

SearchTags.propTypes = {
  handleRemoveTag: PropTypes.func.isRequired,
  tagClassNames: PropTypes.array,
  values: PropTypes.any,
};

export default SearchTags;

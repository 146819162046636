const ChooseLandingIcon = () => (
  <svg viewBox="0 0 20.42 20" xmlns="http://www.w3.org/2000/svg">
    <title>ChooseLanding</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Calque 1" id="Calque_1-2">
        <path
          className="fill-primary-color"
          d="M16,11v7H2V4H7A6.3,6.3,0,0,1,7.5,2H2A2,2,0,0,0,0,4V18a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V13Zm-1.5,5H3.5l2.75-3.53,2,2.36L11,11.29Zm2.8-9.11A4.49,4.49,0,1,0,13.49,9a4.43,4.43,0,0,0,2.39-.7L19,11.42,20.42,10ZM13.5,7A2.5,2.5,0,1,1,16,4.5,2.5,2.5,0,0,1,13.5,7Z"
        />
      </g>
    </g>
  </svg>
);

export default ChooseLandingIcon;

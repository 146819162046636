import consts from '../../utils/consts';
const { PASSWORD } = consts.REGEXES;

const validatePassword = (value) => {
  let msg;
  if (!value) {
    msg = 'Required';
  } else if (!value.match(PASSWORD)) {
    msg = 'Your password must contains at least one uppercase character, one lowercase character and a number.';
  }
  return msg;
};

export default (values) => {
  const errors = {};

  const passwordMsg = validatePassword(values.password);
  if (passwordMsg) {
    errors.password = passwordMsg;
  }

  const passwordConfirmationMsg = validatePassword(values.passwordConfirmation);
  if (passwordConfirmationMsg) {
    errors.passwordConfirmation = passwordConfirmationMsg;
  }

  return errors;
};

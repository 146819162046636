import React, { useEffect, useRef, useState } from 'react';

const SelectCustom = React.memo(
  ({
    dropdownClassName,
    id = '',
    label,
    valueDisabled,
    error,
    onChange,
    value,
    options,
    isMulti,
    isNewFlow,
    maxSelected,
    small,
    children,
    touched,
    disabled,
    styleBox,
    showSelectedFieldCheckmark = true,
    tabIndex,
    enableSearch = false,
    showMaxSelectedNumber = true,
    showId = false,
    dataCategory = '', // Needed for filter tags in stats
    dataFiltername = '', // Needed for filter tags in stats
    showSelectedAtTop = true,
    gtmEvent,
  }) => {
    const [multiSelected, setMultiSelected] = useState([]);
    const [multiSelectedValuesText, setMultiSelectedValuesText] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [valueTextShowed, setValueTextShowed] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const multiSelectedLength = multiSelected.length;
    const wrapperRef = useRef(null);
    const buttonRef = useRef(null);
    const maxCountReached = multiSelectedLength && multiSelectedLength >= maxSelected;

    const changeMulti = (value, text) => {
      if (multiSelected.length < maxSelected && !multiSelected.includes(value)) {
        setMultiSelected((oldValues) => [...oldValues, value]);
        setMultiSelectedValuesText((oldValues) => [...oldValues, text]);
      } else if (multiSelected.includes(value)) {
        setMultiSelected(multiSelected.filter((selection) => selection !== value));
        setMultiSelectedValuesText(multiSelectedValuesText.filter((selection) => selection !== text));
      }
    };

    useEffect(() => {
      const onClickOutside = (e) => {
        const selection = window.getSelection();
        if (selection && selection.type === 'Range') {
          return;
        }
        if (
          buttonRef.current &&
          wrapperRef.current &&
          isDropdownOpen &&
          !wrapperRef.current.contains(e.target) &&
          !buttonRef.current.contains(e.target)
        ) {
          setIsDropdownOpen(false);
        }
      };
      document.addEventListener('click', onClickOutside);
      return () => {
        document.removeEventListener('click', onClickOutside);
      };
    }, [isDropdownOpen]);

    useEffect(() => {
      if (isMulti) {
        onChange(multiSelected);
      }
    }, [isMulti, multiSelected, onChange]);

    useEffect(() => {
      if (isMulti) {
        if (Array.isArray(value)) {
          // Ensure the multi-selected values match the new value array
          setMultiSelected(value);
          // Update the text labels for each selected option based on the new values
          setMultiSelectedValuesText(value.map((val) => options.find((option) => option.value === val)?.text || ''));
        }
      } else {
        const selectedOption = options.find((option) => option.value === value);
        const newValueText = selectedOption ? selectedOption.text : '';

        // Update only if the new value text differs from the current state
        if (newValueText !== valueTextShowed) {
          setValueTextShowed(newValueText);
        }
      }
    }, [value, isMulti, options]);

    const showValue = (isMulti) => {
      if (isMulti) {
        return multiSelectedValuesText.length > 0 ? multiSelectedValuesText.join(', ') : valueDisabled;
      }
      return valueTextShowed ? valueTextShowed : valueDisabled;
    };

    const filteredOptions = enableSearch
      ? options
          .filter((option) => option.text.toString().toLowerCase().includes(searchQuery.toLowerCase()))
          .sort((a, b) => a.text.localeCompare(b.text))
      : options;

    const selectedOptions = options.filter((option) => multiSelected.includes(option.value));
    const unselectedOptions = filteredOptions.filter((option) => !multiSelected.includes(option.value));

    const sortedOptions = isMulti && showSelectedAtTop ? [...selectedOptions, ...unselectedOptions] : filteredOptions;

    return (
      <div className="field" data-category={dataCategory} data-filtername={dataFiltername}>
        {label ? (
          <div className="labelBox">
            <label>{label}</label>
            {maxSelected && showMaxSelectedNumber ? (
              <div
                className={`maxSelected ${maxCountReached ? 'maxCountReached' : ''} ${
                  isNewFlow ? 'new-flow-upper-max-selected-mobile' : ''
                }`}
              >
                {multiSelectedLength}/{maxSelected} Selected
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="inputRow">
          <div className={`inputBox ${small ? 'small' : ''}`}>
            <button
              className={`inputBoxBtn ${styleBox ? '' : 'underlined'}`}
              onClick={(e) => {
                if (disabled) {
                  return;
                }
                setIsDropdownOpen(!isDropdownOpen);
              }}
              ref={buttonRef}
              tabIndex={tabIndex}
              type="button"
            >
              <input
                className={disabled ? 'disabled' : ''}
                id={id}
                onChange={onChange}
                readOnly
                tabIndex={tabIndex}
                value={showValue(isMulti)}
              />
              {error && touched ? (
                <div className="field-error" key="error div">
                  {error}
                </div>
              ) : null}
              <div className={`svgsRight ${disabled ? 'disabled' : ''}`}>
                {value && showSelectedFieldCheckmark ? (
                  <svg className="approvedCheck" height="24" viewBox="0 0 24 24" width="24">
                    <g fill="#07B500" fillRule="evenodd">
                      <path d="M12 21.6c-5.292 0-9.6-4.308-9.6-9.6S6.708 2.4 12 2.4s9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0" />
                      <path d="M16.656 7.548L9.6 14.604l-2.256-2.256a1.195 1.195 0 0 0-1.692 0 1.195 1.195 0 0 0 0 1.692l3.108 3.108a1.195 1.195 0 0 0 1.692 0L18.36 9.24a1.195 1.195 0 0 0 0-1.692 1.21 1.21 0 0 0-1.704 0" />
                    </g>
                  </svg>
                ) : null}
                <svg className="arrowDown" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10l5 5 5-5z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
            </button>
            <ul
              className={`dropdown ${isDropdownOpen ? 'open' : ''} ${dropdownClassName} ${isNewFlow ? 'new-flow-dropdown' : ''}`}
              ref={wrapperRef}
            >
              {enableSearch ? (
                <li className="search-bar">
                  <svg viewBox="0 0 14.207 14.509" xmlns="http://www.w3.org/2000/svg">
                    <g id="Search" transform="translate(0.75 0.75)">
                      <circle
                        cx="5.938"
                        cy="5.938"
                        fill="none"
                        id="Ellipse_739"
                        r="5.938"
                        stroke="#60647c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(0 0)"
                      />
                      <line
                        fill="none"
                        id="Line_181"
                        stroke="#60647c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(10.068 10.377)"
                        x2="2.328"
                        y2="2.322"
                      />
                    </g>
                  </svg>
                  <input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    type="text"
                    value={searchQuery}
                  />
                </li>
              ) : null}
              {sortedOptions.map(({ text, value }, i) => (
                <li
                  className={`${isMulti ? 'multi-select' : ''} ${
                    isMulti && maxCountReached && !multiSelected.includes(value) ? 'disable' : ''
                  } ${multiSelected.includes(value) ? 'selected' : ''}`}
                  key={i}
                  onClick={() => {
                    if (gtmEvent) {
                      gtmEvent();
                    }
                    setValueTextShowed(text);
                    if (!isMulti) {
                      onChange(value);
                      setIsDropdownOpen(false);
                    } else {
                      changeMulti(value, text);
                    }
                  }}
                >
                  <span className="text">{text}</span>
                  {showId ? <span>ID: {value}</span> : null}
                </li>
              ))}
            </ul>
          </div>
          {children}
          {dataCategory !== '' && dataFiltername !== '' && (
            <input
              data-category={dataCategory}
              data-filtername={dataFiltername}
              data-text={
                isMulti ? (Array.isArray(multiSelectedValuesText) ? multiSelectedValuesText?.join(',') : '') : valueTextShowed
              }
              id={id}
              type="hidden"
              value={isMulti ? (Array.isArray(multiSelected) ? multiSelected?.join(',') : '') : value}
            />
          )}
        </div>
        {isNewFlow && maxSelected && showMaxSelectedNumber ? (
          <div className={`maxSelected new-flow-max-selected-mobile ${maxCountReached ? 'maxCountReached' : ''}`}>
            {multiSelectedLength}/{maxSelected} Selected
          </div>
        ) : null}
      </div>
    );
  }
);

export default SelectCustom;

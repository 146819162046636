import { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Checkbox, LoadingPage } from '../../components/reusables';

class EmailPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const { fields, handleSubmit, emailLists } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="container-fluid app-container" id="profile-emailpreferences">
        {isLoading ? <LoadingPage /> : null}
        <div className="row">
          <div className="col-md-12">
            <h1>
              <i className="material-icons primary-color">settings</i>
              <FormattedMessage {...messages.profileEmailPreferencesTitle} />
            </h1>
          </div>
        </div>
        <div className="row">
          <form action="#" className="form-container col-md-12" method="post" onSubmit={handleSubmit}>
            <div className="notice">
              <FormattedMessage {...messages.profileEmailPreferencesIntro} />
            </div>
            <div className="email-lists">
              {emailLists
                ? emailLists.map((list) => (
                    <Checkbox
                      field={fields.subscribedEmailLists}
                      id={list.listId}
                      key={list.listId}
                      label={list.listName.defaultMessage}
                      value={list.listId}
                    />
                  ))
                : null}
            </div>
            <div className="row">
              <div className="col-md-12 form-buttons text-right">
                <button className="waves-effect waves-light btn bg-primary-color" id="btn-save-settings">
                  <FormattedMessage {...messages.genericTextSaveChanges} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

EmailPreferences.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  route: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm(
    {
      form: 'emailPreferences',
      touchOnChange: false,
      touchOnBlur: false,
      fields: ['subscribedEmailLists'],
    },
    (state) => ({
      initialValues: {
        subscribedEmailLists: state.profile.data.sendgridLists.filter((list) => list.isSubscribed).map((list) => list.listId),
      },
    })
  )(EmailPreferences)
);

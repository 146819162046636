import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { InputText } from '../../../../components/reusables/index.js';

import provincesList from '../../../../utils/list-options/provinces.js';
import statesList from '../../../../utils/list-options/states.js';

import messages from '../../../../i18n/base-en.js';
import { initialFetching } from '../../../../utils/initialFetching.js';

import ExcludedCountriesHelper from '../../../../utils/ExcludedCountriesHelper.js';

import 'materialize-css';
import { Select } from 'react-materialize';
import SelectCustom from '../../../../components/reusables/form/SelectCustom.js';
const AddressSection = ({
  countries,
  fields: { address, address2, city, country, province, state, region, zipcode },
  intl,
  invalid,
  isAutocomplete,
  onChangeCountry,
  values,
  dispatch,
}) => {
  useEffect(() => {
    initialFetching(dispatch);
  }, [dispatch]);

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getProfileExcludeCountries()
  );

  return (
    <div className="address_section">
      <div className="customSelects">
        <div className="fields" data-cy="select-wrapper-country">
          <SelectCustom
            enableSearch
            id="country"
            disabled={isAutocomplete}
            label={messages.genericTextCountry.description}
            onChange={onChangeCountry ?? country.onChange}
            tabIndex={130}
            value={country.value || ''}
            valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            showSelectedFieldCheckmark={false}
            options={filteredCountries.map((country) => ({
              value: country.code,
              text: country.name,
            }))}
          />
        </div>
      </div>

      <div className="row">
        <InputText
          className="col-md-12"
          disabled={isAutocomplete}
          displayErrorInstantly
          field={address}
          id="address"
          label={messages.genericTextStreetAddress}
          tabIndex={110}
          type="text"
        />
      </div>
      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={address2}
          id="address2"
          label={{
            ...messages.genericTextAddressAptSuite,
          }}
          tabIndex={115}
          type="text"
        />
      </div>
      <div className="row">
        <InputText
          className="col-md-12"
          disabled={isAutocomplete}
          displayErrorInstantly
          field={city}
          id="city"
          label={messages.genericTextCity}
          tabIndex={120}
          type="text"
        />
      </div>

      {values.country === 'CA' && (
        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              id="province"
              disabled={isAutocomplete}
              label={messages.genericTextProvince.description}
              onChange={province.onChange}
              tabIndex={140}
              value={province.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              showSelectedFieldCheckmark={false}
              options={provincesList.map((province, index) => ({
                value: province.value,
                text: intl.formatMessage(messages[province.label.id]),
              }))}
            />
            {province.error ? (
              <div className="field-error" key="error div">
                {province.error}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}

      {values.country === 'US' && (
        <div className="customSelects">
          <div className="fields" data-cy="select-wrapper-state">
            <SelectCustom
              id="state"
              disabled={isAutocomplete}
              label={messages.genericTextState.description}
              onChange={state.onChange}
              tabIndex={150}
              value={state.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              showSelectedFieldCheckmark={false}
              options={statesList.map((state, index) => ({
                value: state.value,
                text: intl.formatMessage(messages[state.label.id]),
              }))}
            />
            {state.error ? (
              <div className="field-error" key="error div">
                {state.error}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}

      {values.country !== 'CA' && values.country !== 'US' && (
        <div className="row">
          <InputText
            className="col-md-12"
            disabled={isAutocomplete}
            displayErrorInstantly
            field={region}
            id="region"
            label={messages.genericTextRegion}
            tabIndex={160}
            type="text"
          />
        </div>
      )}

      <div className="row">
        <InputText
          className="col-md-12"
          disabled={isAutocomplete}
          displayErrorInstantly
          field={zipcode}
          id="zipcode"
          label={messages.genericTextZipCode}
          tabIndex={170}
          type="text"
        />
      </div>
    </div>
  );
};

AddressSection.propTypes = {
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  invalid: PropTypes.bool,
  isAutocomplete: PropTypes.bool.isRequired,
  onChangeCountry: PropTypes.func,
  values: PropTypes.object.isRequired,
};

export default injectIntl(AddressSection);

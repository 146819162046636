const IdentityIcon = () => (
  <svg fill="none" height="33" viewBox="0 0 43 33" width="43" xmlns="http://www.w3.org/2000/svg">
    <rect
      height="29"
      rx="4"
      stroke="#2C2C2C"
      strokeDasharray="11 11"
      strokeMiterlimit="6.07152"
      strokeWidth="2.5"
      width="39"
      x="2"
      y="2"
    />
    <path
      d="M8.53571 5C6.59696 5 5 6.55825 5 8.45V24.55C5 26.4417 6.59696 28 8.53571 28H34.4643C36.403 28 38 26.4417 38 24.55V8.45C38 6.55825 36.403 5 34.4643 5H8.53571ZM8.53571 7.3H34.4643C35.1314 7.3 35.6429 7.7991 35.6429 8.45V24.55C35.6429 25.2009 35.1314 25.7 34.4643 25.7H8.53571C7.86864 25.7 7.35714 25.2009 7.35714 24.55V8.45C7.35714 7.7991 7.86864 7.3 8.53571 7.3ZM15.6071 9.6C13.0143 9.6 10.8929 11.67 10.8929 14.2C10.8929 15.4799 11.455 16.6345 12.3295 17.4706C11.5273 17.9988 10.8696 18.7105 10.4138 19.5435C9.95798 20.3765 9.71783 21.3057 9.71429 22.25H12.0714C12.0714 20.3318 13.6413 18.8 15.6071 18.8C17.573 18.8 19.1429 20.3318 19.1429 22.25H21.5C21.4965 21.3057 21.2563 20.3765 20.8005 19.5435C20.3447 18.7105 19.687 17.9988 18.8848 17.4706C19.7593 16.6345 20.3214 15.4811 20.3214 14.2C20.3214 11.67 18.2 9.6 15.6071 9.6ZM23.8571 10.75V13.05H33.2857V10.75H23.8571ZM15.6071 11.9C16.9236 11.9 17.9643 12.9154 17.9643 14.2C17.9643 15.4845 16.9236 16.5 15.6071 16.5C14.2907 16.5 13.25 15.4845 13.25 14.2C13.25 12.9154 14.2907 11.9 15.6071 11.9ZM23.8571 15.35V17.65H33.2857V15.35H23.8571ZM23.8571 19.95V22.25H29.75V19.95H23.8571Z"
      fill="#2C2C2C"
    />
  </svg>
);

export default IdentityIcon;

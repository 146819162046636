const AISmartlinkIcon = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_148_1571)">
      <path
        d="M24.9985 49.0942C38.3062 49.0942 49.0942 38.3062 49.0942 24.9985C49.0942 11.6908 38.3062 0.902802 24.9985 0.902802C11.6908 0.902802 0.902802 11.6908 0.902802 24.9985C0.902802 38.3062 11.6908 49.0942 24.9985 49.0942Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M20.5778 14.4568C20.5778 20.2287 15.8983 24.9052 10.1294 24.9052C15.9013 24.9052 20.5778 29.5847 20.5778 35.3536C20.5778 29.5817 25.2573 24.9052 31.0262 24.9052C25.2543 24.9052 20.5778 20.2257 20.5778 14.4568Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M32.5339 26.5002C32.5339 29.8405 29.8255 32.5489 26.4851 32.5489C29.8255 32.5489 32.5339 35.2573 32.5339 38.5977C32.5339 35.2573 35.2423 32.5489 38.5826 32.5489C35.2423 32.5489 32.5339 29.8405 32.5339 26.5002Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M30.0752 11.3993C30.0752 13.9121 28.0379 15.9525 25.5221 15.9525C28.0349 15.9525 30.0752 17.9898 30.0752 20.5056C30.0752 17.9928 32.1125 15.9525 34.6283 15.9525C32.1155 15.9525 30.0752 13.9151 30.0752 11.3993Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_148_1571">
        <rect fill="white" height="50" width="50" />
      </clipPath>
    </defs>
  </svg>
);

export default AISmartlinkIcon;

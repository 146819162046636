/**
 * @file Whale
 * @author Pierre-Luc Gagné
 * @version 0.0.1
 */

function RankAffiliate() {
  return (
    <svg height="17px" viewBox="0 0 20 17" width="20px">
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g id="active-aff" transform="translate(-7.000000, -11.000000)">
          <g id="Group-10">
            <g id="Group-3" transform="translate(0.000000, 3.000000)">
              <g id="Approved" transform="translate(2.000000, 2.000000)">
                <g id="Fichier-35">
                  <g id="Calque_2">
                    <g id="Calque_25">
                      <rect height="27.5555556" id="Rectangle-path" width="27.5555556" x="0.423343343" y="0.423343343" />
                      <polygon
                        fill="#FFFFFF"
                        id="Shape"
                        points="24.9465465 9.21505506 21.97998 6.24848849 12.0053654 16.2231031 7.95395395 12.1716917 4.98738739 15.1357758 12.0053654 22.1537538 12.0103303 22.1487888 12.0103303 22.1487888"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RankAffiliate;

const Whale = () => (
  <svg height="24px" viewBox="280 0 188 130" width="24px">
    <path
      d="M368.759569,44.0992313 C342.975317,54.4830582 304.785367,57.6688727 309.27547,26.5348646 C314.047993,23.8764599 341.875567,38.0608736 337.624286,12.4339305 C332.518584,15.2282322 314.108512,9.80464669 309.505831,18.8948633 C306.174696,9.7483465 286.428005,6.52952842 280,0 C280.298039,16.0280833 291.225907,23.3451673 302.273512,24.520353 C266.54531,58.1373939 300.297867,104.6763 340.37301,114.458298 C342.922607,116.954273 339.269356,124.767705 332.271303,129.749301 C351.173334,131.934655 365.814322,119.246403 374.301267,114.439531 C380.253581,117.550278 390.838511,120.98006 403.360691,120.657143 C400.773351,117.391732 398.66951,112.114397 397.984932,106.868125 C409.346844,103.080481 419.65651,99.0365738 423.153584,97.1611949 C456.029596,79.5411751 483.796651,56.1895365 457.636922,16.9968349 C455.807694,17.1864436 416.79651,24.7474951 368.759569,44.0992313 Z"
      fill="#ffffff"
      fillRule="evenodd"
      stroke="none"
    />
  </svg>
);

export default Whale;

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { arrayToReadableString } from '../../../utils/helpers';

import { openModal } from '../../../components/Modal/modal-actions/modal-actions.js';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';
const { genericTextClearAll, LiveCamWidgetPage } = messages;

const TypeSettingsOffers = ({
  animated,
  campaigns,
  campaignsNeedApproval,
  clearAllSelected,
  fields: { offers },
  intl,
  offersApprovalPending,
  offersNeedApproval,
  removeSelected,
  dispatch,
  campaignChanged,
}) => {
  const handleOpenModal = () => {
    dispatch(
      openModal({
        name: 'OfferRequestModal',
        modalProps: {
          dispatch,
        },
      })
    );
  };

  return (
    <div className="settings-group" id="offers-section">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.title.text} />
      </h2>

      <div className="offers-block customSelects">
        <div className="select-field fields">
          <SelectCustom
            id="widget-offers"
            valueDisabled={intl.formatMessage(LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.selectOffer.text)}
            value=""
            onChange={campaignChanged}
            options={campaigns
              .filter((camp) => !offers.some((offer) => offer.value === camp.name))
              .sort((campA, campB) => campA.name.localeCompare(campB.name))
              .map((camp) => ({ value: camp.name, text: camp.name }))}
            showSelectedFieldCheckmark={false}
          />
        </div>

        {campaignsNeedApproval && offersApprovalPending.length > 0 ? (
          <div className="offer-alert">
            Your approval
            {offersApprovalPending.length > 1 && <span>s</span>} for &nbsp;
            <b>{arrayToReadableString(offersApprovalPending.map((offer) => offer.offer_name))}</b>{' '}
            <span>{offersApprovalPending.length > 1 ? 'are' : 'is'}</span> pending.
          </div>
        ) : null}

        {campaignsNeedApproval && offersNeedApproval.length > 0 ? (
          <div className="offer-alert">
            To use <b>{campaignsNeedApproval}</b> you need to{' '}
            <button className="btn-link approval-required" onClick={handleOpenModal}>
              request approval
            </button>{' '}
            for the offer
            {offersNeedApproval.length > 1 && <span>s</span>}&nbsp;
            {arrayToReadableString(offersNeedApproval.map((offer) => offer.offer_name))}.
          </div>
        ) : null}

        {offers.length > 0 && (
          <div className="selected-elements">
            <FormattedMessage {...LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.selectedOffers.title.text} />:
            {offers.map((tag, index) => (
              <div className="selected-element bg-primary-color" key={tag.value}>
                <span>{tag.value}</span>
                <i className="material-icons" onClick={() => removeSelected('offers', index)}>
                  close
                </i>
              </div>
            ))}
            {offers.length > 1 && (
              <div className="clear-all" onClick={() => clearAllSelected('offers')}>
                <i className="material-icons">delete</i>
                <FormattedMessage {...genericTextClearAll} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TypeSettingsOffers.propTypes = {
  animated: PropTypes.bool,
  campaigns: PropTypes.array,
  campaignsNeedApproval: PropTypes.string,
  clearAllSelected: PropTypes.func,
  campaignChanged: PropTypes.func,
  fields: PropTypes.shape({
    offers: PropTypes.array,
  }),
  intl: intlShape.isRequired,
  offersApprovalPending: PropTypes.array,
  offersNeedApproval: PropTypes.array,
  removeSelected: PropTypes.func,
};

export default injectIntl(TypeSettingsOffers);

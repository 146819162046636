import { useState } from 'react';
import PropTypes from 'prop-types';

import { Scroller } from '..';
import { Gtm } from '../../../utils/gtm';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../colorpicker/useClickOutside';

const VideoModal = ({ modalInner, dispatch, videoUrl }) => {
  const [activeTab, setActiveTab] = useState(0);

  const videoUrlIsArray = videoUrl instanceof Array;

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div className="videoModalIcon-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      {videoUrlIsArray ? (
        <div>
          <Scroller classNames={['tabs', 'modalScroller']}>
            <ul>
              {videoUrl.map((video, index) => (
                <li
                  className={activeTab === index ? 'active' : ''}
                  data-index={index}
                  key={`videotab-${index}`}
                  onClick={() => {
                    Gtm.event('statistics', 'Click', `${video.label}`);
                    setActiveTab(index);
                  }}
                >
                  <span>{video.label}</span>
                </li>
              ))}
            </ul>
          </Scroller>
        </div>
      ) : null}
      <iframe
        allowFullScreen
        className="videoModalIcon-video"
        height="360"
        src={videoUrlIsArray ? videoUrl[activeTab].url : videoUrl}
        width="640"
      />
    </div>
  );
};

VideoModal.propTypes = {
  videoUrl: PropTypes.any.isRequired,
};

export default VideoModal;

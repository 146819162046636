const CamSmartlinkIcon = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_148_1545)">
      <path
        d="M24.9985 49.0942C38.3062 49.0942 49.0942 38.3062 49.0942 24.9985C49.0942 11.6908 38.3062 0.902802 24.9985 0.902802C11.6908 0.902802 0.9028 11.6908 0.9028 24.9985C0.9028 38.3062 11.6908 49.0942 24.9985 49.0942Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M37.0418 23.9844C37.0418 28.5977 34.4478 32.6061 30.635 34.6253C30.1083 34.9082 29.5546 35.149 28.9828 35.3476C27.734 35.7869 26.3948 36.0247 24.9985 36.0247C23.6022 36.0247 22.26 35.7869 21.0141 35.3476C16.3226 33.7045 12.9612 29.2356 12.9612 23.9813C12.9612 22.3322 13.2922 20.7614 13.8881 19.3349C15.7057 14.9925 19.997 11.944 24.9985 11.944C31.6491 11.944 37.0418 17.3337 37.0418 23.9844Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M30.3431 23.9844C30.3431 21.0322 27.9506 18.6398 24.9985 18.6398C22.4045 18.6398 20.2407 20.4905 19.7593 22.9401C19.69 23.2772 19.6569 23.6262 19.6569 23.9844C19.6569 26.9365 22.0494 29.3289 25.0015 29.3289C25.5281 29.3289 26.0397 29.2537 26.5182 29.1092C28.7301 28.4562 30.3461 26.4099 30.3461 23.9844H30.3431Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="1.25"
      />
      <path
        d="M30.3431 23.9844C30.3431 26.4069 28.7301 28.4562 26.5152 29.1092C26.0367 29.2537 25.5251 29.3289 24.9985 29.3289C22.0463 29.3289 19.6539 26.9365 19.6539 23.9844C19.6539 23.6262 19.687 23.2772 19.7562 22.9401C20.2377 20.4905 22.4014 18.6398 24.9955 18.6398C27.9476 18.6398 30.3401 21.0322 30.3401 23.9844H30.3431Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="1.25"
      />
      <path
        d="M27.9687 23.9843C27.9687 22.3443 26.6386 21.0172 24.9985 21.0172C23.3584 21.0172 22.0313 22.3443 22.0313 23.9843C22.0313 24.2763 22.0734 24.5651 22.1577 24.833C22.4646 25.8772 23.3343 26.6837 24.4147 26.8974C24.6043 26.9365 24.7999 26.9546 24.9985 26.9546C26.6386 26.9546 27.9687 25.6244 27.9687 23.9843Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M24.9985 21.0202C23.3584 21.0202 22.0313 22.3473 22.0313 23.9874C22.0313 24.2793 22.0734 24.5682 22.1577 24.836C22.4646 25.8802 23.3343 26.6867 24.4147 26.9004C24.6043 26.9395 24.7999 26.9576 24.9985 26.9576C26.6386 26.9576 27.9687 25.6275 27.9687 23.9874C27.9687 22.3473 26.6386 21.0202 24.9985 21.0202Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M21.0141 35.3506L19.4192 39.5125H30.5778L28.9828 35.3506" stroke="black" strokeMiterlimit="10" strokeWidth="1.5" />
    </g>
    <defs>
      <clipPath id="clip0_148_1545">
        <rect fill="white" height="50" width="50" />
      </clipPath>
    </defs>
  </svg>
);

export default CamSmartlinkIcon;

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { categoryList } from '../LiveCamWidgetConsts.js';

import { Gtm } from '../../../utils/gtm.js';
import { Checkbox } from '../../../components/reusables';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';

const { genericTextClearAll, LiveCamWidgetPage } = messages;

const TypeSettingsModels = ({
  fields: { spokenLanguages, categories, category, gender },
  clearAllSelected,
  intl,
  maxReached,
  removeSelected,
  availableGenders,
  catygoryChanged,
  languageChanged,
}) => {
  const clearAllCategories = () => clearAllSelected('categories');
  const clearAllSpokenLanguages = () => clearAllSelected('spokenLanguages');
  const customizedCategories = category.value === 'custom';
  const categoriesLength = categories.length;
  const spokenLanguagesLength = spokenLanguages.length;

  if (!customizedCategories && categoriesLength > 0) {
    clearAllCategories();
  }
  return (
    <div className="settings-group" id="categories-section">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.typeSettings.modelsSection.title.text} />
      </h2>
      <div>
        <FormattedMessage {...LiveCamWidgetPage.fields.gender.label} />
        <div className="checkboxes grouped">
          <Checkbox
            disabled={!availableGenders.f}
            field={gender}
            id="widget-gender-female"
            label={LiveCamWidgetPage.fields.gender.choices.female.defaultMessage}
            onClick={() => {
              Gtm.event('live cam widget', 'Click', `gender ${LiveCamWidgetPage.fields.gender.choices.female.defaultMessage}`);
            }}
            value="f"
          />
          <Checkbox
            disabled={!availableGenders.m}
            field={gender}
            id="widget-gender-male"
            label={LiveCamWidgetPage.fields.gender.choices.male.defaultMessage}
            onClick={() => {
              Gtm.event('live cam widget', 'Click', `gender ${LiveCamWidgetPage.fields.gender.choices.male.defaultMessage}`);
            }}
            value="m"
          />
          <Checkbox
            disabled={!availableGenders.t}
            field={gender}
            id="widget-gender-trans"
            label={LiveCamWidgetPage.fields.gender.choices.trans.defaultMessage}
            onClick={() => {
              Gtm.event('live cam widget', 'Click', `gender ${LiveCamWidgetPage.fields.gender.choices.trans.defaultMessage}`);
            }}
            value="t"
          />
          <Checkbox
            disabled={!availableGenders.c}
            field={gender}
            id="widget-gender-couples"
            label={LiveCamWidgetPage.fields.gender.choices.couples.defaultMessage}
            onClick={() => {
              Gtm.event('live cam widget', 'Click', `gender ${LiveCamWidgetPage.fields.gender.choices.couples.defaultMessage}`);
            }}
            value="c"
          />
        </div>
      </div>
      <div>
        <FormattedMessage {...LiveCamWidgetPage.fields.modelsSettings.label} />
        <div className="categories-wrapper customSelects">
          <div className="select-field fields">
            <SelectCustom
              id="widget-categories"
              valueDisabled={intl.formatMessage(LiveCamWidgetPage.typeSettings.modelsSection.tagsTitle.text)}
              value=""
              onChange={catygoryChanged}
              options={categoryList
                .filter((currentCategory) => !categories.some((selectedCategory) => selectedCategory.value === currentCategory))
                .map((currentCategory) => ({ value: currentCategory, text: currentCategory }))}
              showSelectedFieldCheckmark={false}
            />
            <div className="error-msg">
              {maxReached ? <FormattedMessage {...LiveCamWidgetPage.typeSettings.modelsSection.warnings.maxCategories} /> : null}
            </div>
          </div>

          {categoriesLength > 0 && (
            <div className="selected-elements">
              <FormattedMessage {...LiveCamWidgetPage.typeSettings.modelsSection.tags.text} />:
              {categories.map((tag, index) => (
                <div className="selected-element bg-primary-color" key={tag.value}>
                  <span>{tag.value}</span>
                  <i className="material-icons" onClick={() => removeSelected('categories', index, 2)}>
                    close
                  </i>
                </div>
              ))}
              {categoriesLength > 1 && (
                <div className="clear-all" onClick={clearAllCategories}>
                  <i className="material-icons">delete</i>
                  <FormattedMessage {...genericTextClearAll} />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="spoken-languages-wrapper customSelects">
          <div className="select-field fields">
            <SelectCustom
              id="widget-spoken-languages"
              valueDisabled={intl.formatMessage(LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.title.text)}
              value=""
              onChange={languageChanged}
              options={Object.keys(LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options)
                .filter(
                  (currentLanguage) => !spokenLanguages.some((selectedLanguage) => selectedLanguage.value === currentLanguage)
                )
                .map((value) => ({
                  value: value,
                  text: intl.formatMessage(LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options[value].text),
                }))}
              showSelectedFieldCheckmark={false}
            />
          </div>

          {spokenLanguagesLength > 0 && (
            <div className="selected-elements">
              <FormattedMessage {...LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.languages.text} />:
              {spokenLanguages.map((tag, index) => (
                <div className="selected-element bg-primary-color" key={tag.value}>
                  <span>
                    {intl.formatMessage(LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options[tag.value].text)}
                  </span>
                  <i className="material-icons" onClick={() => removeSelected('spokenLanguages', index, 2)}>
                    close
                  </i>
                </div>
              ))}
              {spokenLanguagesLength > 1 && (
                <div className="clear-all" onClick={clearAllSpokenLanguages}>
                  <i className="material-icons">delete</i>
                  <FormattedMessage {...genericTextClearAll} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
TypeSettingsModels.propTypes = {
  clearAllSelected: PropTypes.func,
  languageChanged: PropTypes.func,
  catygoryChanged: PropTypes.func,
  availableGenders: PropTypes.shape({
    f: PropTypes.bool.isRequired,
    m: PropTypes.bool.isRequired,
    t: PropTypes.bool.isRequired,
    c: PropTypes.bool.isRequired,
  }).isRequired,
  fields: PropTypes.shape({
    gender: PropTypes.object,
    categories: PropTypes.array,
    category: PropTypes.object,
    spokenLanguages: PropTypes.array,
  }),
  intl: intlShape.isRequired,
  maxReached: PropTypes.bool,
  removeSelected: PropTypes.func,
};

export default injectIntl(TypeSettingsModels);

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Gtm } from '../../utils/gtm';

class Filters extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { handleSearchChange, values } = this.props;

    if (!!handleSearchChange && !_.isEqual(values, nextProps.values)) {
      handleSearchChange(nextProps.values);
    }
  }

  render() {
    const { children, className, disabled, handleSubmit, id, handleGtmEvent } = this.props;

    return (
      <div className={`${className} filters`} id={id}>
        <form className="clearfix filter-form">
          <div className="row">
            {children}
            {handleSubmit ? (
              <button
                className="pull-right waves-effect waves-light btn bg-primary-color"
                data-cy="offers-button-apply-filter"
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (id === 'offer-creatives-banner-filters') {
                    Gtm.event(
                      `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
                      'Click',
                      'banner apply filter'
                    );
                  } else if (id === 'offer-creatives-html-filters') {
                    Gtm.event(
                      `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
                      'Click',
                      'html ads apply filter'
                    );
                  } else {
                    Gtm.event('offers', 'Click', 'apply filter');
                  }

                  handleSubmit();
                  // handleGtmEvent();
                }}
                type="submit"
              >
                <FormattedMessage {...messages.genericTextFilter} />
              </button>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

Filters.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleSearchChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default injectIntl(Filters);

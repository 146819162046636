const LiveCamWidgetSkin2Icon = () => (
  <svg viewBox="0 0 806.39 604.79" xmlns="http://www.w3.org/2000/svg">
    <title>Skin 2</title>
    <rect fill="#757575" height="586.79" width="788.39" x="9" y="9" />
    <path d="M788.39,18V586.79H18V18H788.39m18-18H0V604.79H806.39V0Z" fill="#d4d4d4" />
    <path
      d="M566.82,460.91l-81-20.25L481.19,422c55.86-9.41,84.6-26.14,85.87-26.88a10.59,10.59,0,0,0,5.11-8.41,10.75,10.75,0,0,0-4-9.05c-.38-.29-38.14-31.49-38.14-139.56,0-91.15-21.32-137.36-63.4-137.36h-6.23C445.18,86,433.79,79.56,403.19,79.56c-39.71,0-126.79,39.92-126.79,158.5,0,108.07-37.77,139.27-38,139.48a10.54,10.54,0,0,0,.88,17.49c1.27.77,29.74,17.59,85.93,27l-4.67,18.66-81,20.25a118.65,118.65,0,0,0-89.48,125.84H656.32A118.64,118.64,0,0,0,566.82,460.91Z"
      fill="#e7eced"
    />
    <rect height="94.73" opacity=".5" width="768.19" x="18.93" y="492.02" />
    <rect fill="#22ad01" height="45.43" rx="5.67" ry="5.67" width="110.55" x="42.73" y="39.65" />
    <path
      d="M45.86,507.6l6.21,17.53,6.17-17.53h6.54v24.17h-5v-6.61l.5-11.4-6.53,18H50.34l-6.51-18,.5,11.38v6.61h-5V507.6Z"
      fill="#fff"
    />
    <path
      d="M68.07,522.62a10.57,10.57,0,0,1,1-4.76,7.62,7.62,0,0,1,3-3.24,8.7,8.7,0,0,1,4.5-1.14,8.18,8.18,0,0,1,5.93,2.22,9,9,0,0,1,2.57,6l0,1.23a9.36,9.36,0,0,1-2.31,6.63,8.94,8.94,0,0,1-12.39,0,9.51,9.51,0,0,1-2.32-6.77Zm4.8.34a6.73,6.73,0,0,0,1,3.92,3.46,3.46,0,0,0,5.48,0,7.39,7.39,0,0,0,1-4.28,6.69,6.69,0,0,0-1-3.9,3.18,3.18,0,0,0-2.75-1.38,3.13,3.13,0,0,0-2.73,1.38A7.47,7.47,0,0,0,72.87,523Z"
      fill="#fff"
    />
    <path
      d="M87.3,522.66A10.8,10.8,0,0,1,89.18,516a6.13,6.13,0,0,1,5.16-2.49,5.5,5.5,0,0,1,4.33,2v-9.17h4.81v25.5H99.15l-.23-1.91a5.65,5.65,0,0,1-4.62,2.24,6.1,6.1,0,0,1-5.09-2.5A11.2,11.2,0,0,1,87.3,522.66Zm4.79.35a7.16,7.16,0,0,0,.88,3.88,2.87,2.87,0,0,0,2.56,1.35,3.22,3.22,0,0,0,3.14-1.88v-7.1a3.16,3.16,0,0,0-3.11-1.88Q92.1,517.38,92.09,523Z"
      fill="#fff"
    />
    <path
      d="M115.47,532.1a8.79,8.79,0,0,1-6.43-2.42,8.61,8.61,0,0,1-2.49-6.46v-.46a10.79,10.79,0,0,1,1-4.84,7.76,7.76,0,0,1,3-3.29,8.33,8.33,0,0,1,4.38-1.15,7.44,7.44,0,0,1,5.8,2.32,9.46,9.46,0,0,1,2.12,6.59v2H111.42a4.44,4.44,0,0,0,1.4,2.82,4.24,4.24,0,0,0,3,1.07,5.19,5.19,0,0,0,4.34-2l2.36,2.64a7.28,7.28,0,0,1-2.93,2.38A9.58,9.58,0,0,1,115.47,532.1Zm-.55-14.76a3,3,0,0,0-2.31,1,4.94,4.94,0,0,0-1.14,2.77h6.67v-.38a3.57,3.57,0,0,0-.86-2.48A3.11,3.11,0,0,0,114.92,517.34Z"
      fill="#fff"
    />
    <path d="M130.64,531.77h-4.81v-25.5h4.81Z" fill="#fff" />
    <path d="M154.57,531.77h-5l-9.7-15.9v15.9h-5V507.6h5l9.71,15.94V507.6h5Z" fill="#fff" />
    <path
      d="M169,531.77a5.38,5.38,0,0,1-.48-1.61A5.78,5.78,0,0,1,164,532.1a6.43,6.43,0,0,1-4.38-1.52,4.92,4.92,0,0,1-1.73-3.86,5.08,5.08,0,0,1,2.11-4.38,10.42,10.42,0,0,1,6.12-1.54h2.21v-1a2.92,2.92,0,0,0-.64-2,2.52,2.52,0,0,0-2-.75,2.84,2.84,0,0,0-1.9.58,2,2,0,0,0-.69,1.59h-4.8a4.8,4.8,0,0,1,1-2.88,6.39,6.39,0,0,1,2.72-2.09,10,10,0,0,1,3.95-.75,7.83,7.83,0,0,1,5.27,1.66,5.85,5.85,0,0,1,1.95,4.69v7.79a8.47,8.47,0,0,0,.72,3.87v.28Zm-4-3.34a4.08,4.08,0,0,0,2-.47,3.09,3.09,0,0,0,1.33-1.27V523.6h-1.79c-2.41,0-3.68.83-3.84,2.49l0,.29a1.88,1.88,0,0,0,.64,1.47A2.42,2.42,0,0,0,165,528.43Z"
      fill="#fff"
    />
    <path
      d="M181.33,513.81l.15,2a6.3,6.3,0,0,1,5.16-2.34,4.8,4.8,0,0,1,4.77,2.74,6.15,6.15,0,0,1,5.39-2.74,5.34,5.34,0,0,1,4.35,1.7,7.82,7.82,0,0,1,1.43,5.12v11.47h-4.82V520.32a3.49,3.49,0,0,0-.59-2.24,2.67,2.67,0,0,0-2.11-.7,3,3,0,0,0-3,2.06l0,12.33h-4.8V520.33a3.34,3.34,0,0,0-.62-2.25,2.65,2.65,0,0,0-2.09-.7,3.1,3.1,0,0,0-3,1.69v12.7h-4.8v-18Z"
      fill="#fff"
    />
    <path
      d="M214.53,532.1a8.79,8.79,0,0,1-6.43-2.42,8.6,8.6,0,0,1-2.48-6.46v-.46a10.79,10.79,0,0,1,1-4.84,7.72,7.72,0,0,1,3-3.29,8.27,8.27,0,0,1,4.37-1.15,7.44,7.44,0,0,1,5.8,2.32,9.46,9.46,0,0,1,2.12,6.59v2H210.48a4.49,4.49,0,0,0,1.4,2.82,4.25,4.25,0,0,0,3,1.07,5.18,5.18,0,0,0,4.33-2l2.36,2.64a7.32,7.32,0,0,1-2.92,2.38A9.6,9.6,0,0,1,214.53,532.1ZM214,517.34a3,3,0,0,0-2.31,1,5,5,0,0,0-1.14,2.77h6.67v-.38a3.57,3.57,0,0,0-.86-2.48A3.11,3.11,0,0,0,214,517.34Z"
      fill="#fff"
    />
    <path
      d="M599.39,522.19q0,4.29-1.61,6.47a6.58,6.58,0,0,1-9.74.05q-1.63-2.12-1.68-6.29V519q0-4.29,1.61-6.44a6.7,6.7,0,0,1,9.77,0q1.6,2.09,1.65,6.26Zm-3.32-3.72a8.42,8.42,0,0,0-.77-4.11,2.92,2.92,0,0,0-4.82-.07,7.82,7.82,0,0,0-.81,3.86v4.51a8.64,8.64,0,0,0,.78,4.16,2.62,2.62,0,0,0,2.45,1.37,2.56,2.56,0,0,0,2.36-1.27,8.15,8.15,0,0,0,.81-4Z"
      fill="#fff"
    />
    <path
      d="M615.3,522.19q0,4.29-1.6,6.47a5.68,5.68,0,0,1-4.89,2.18,5.75,5.75,0,0,1-4.86-2.13q-1.63-2.12-1.67-6.29V519q0-4.29,1.6-6.44a6.71,6.71,0,0,1,9.78,0q1.61,2.09,1.64,6.26ZM612,518.47a8.44,8.44,0,0,0-.76-4.11,2.93,2.93,0,0,0-4.83-.07,7.82,7.82,0,0,0-.81,3.86v4.51a8.64,8.64,0,0,0,.78,4.16,2.63,2.63,0,0,0,2.45,1.37,2.58,2.58,0,0,0,2.37-1.27,8.16,8.16,0,0,0,.8-4Z"
      fill="#fff"
    />
    <path
      d="M629.23,525.81l3-10h3.54l-5.88,17q-1.35,3.74-4.59,3.73a5.69,5.69,0,0,1-1.6-.25v-2.58l.63,0a3.19,3.19,0,0,0,1.89-.46,3,3,0,0,0,1-1.53l.48-1.27-5.19-14.72h3.58Z"
      fill="#fff"
    />
    <path
      d="M636.86,523a8.74,8.74,0,0,1,.86-3.91,6.25,6.25,0,0,1,2.42-2.68,6.76,6.76,0,0,1,3.58-.94,6.43,6.43,0,0,1,4.86,1.93,7.54,7.54,0,0,1,2,5.11v.78a8.81,8.81,0,0,1-.84,3.91,6.23,6.23,0,0,1-2.4,2.67,6.88,6.88,0,0,1-3.62.94,6.41,6.41,0,0,1-5-2.09,8,8,0,0,1-1.88-5.55Zm3.32.29a6,6,0,0,0,1,3.58,3.28,3.28,0,0,0,5.24,0,6.59,6.59,0,0,0,.93-3.84,5.89,5.89,0,0,0-1-3.55,3.25,3.25,0,0,0-5.2,0A6.44,6.44,0,0,0,640.18,523.32Z"
      fill="#fff"
    />
    <path
      d="M689.92,528a6.69,6.69,0,0,1-3,2.14,12.32,12.32,0,0,1-4.35.72,8.61,8.61,0,0,1-4.43-1.13,7.49,7.49,0,0,1-3-3.25,11.36,11.36,0,0,1-1.09-4.93V520a10.54,10.54,0,0,1,2.16-7.05,7.49,7.49,0,0,1,6-2.53,8,8,0,0,1,5.27,1.64,7.11,7.11,0,0,1,2.36,4.74h-3.39a3.82,3.82,0,0,0-4.17-3.59,4.09,4.09,0,0,0-3.54,1.67,8.58,8.58,0,0,0-1.25,4.91v1.53a8.09,8.09,0,0,0,1.36,5,4.51,4.51,0,0,0,3.78,1.78,4.93,4.93,0,0,0,3.77-1.21V523h-4.1v-2.63h7.55Z"
      fill="#fff"
    />
    <path
      d="M699.91,530.84a6.87,6.87,0,0,1-5.12-2,7.25,7.25,0,0,1-2-5.3v-.41a8.93,8.93,0,0,1,.86-4,6.53,6.53,0,0,1,2.4-2.71,6.29,6.29,0,0,1,3.44-1,5.79,5.79,0,0,1,4.67,1.93,8.12,8.12,0,0,1,1.65,5.45v1.34h-9.67a4.43,4.43,0,0,0,1.23,2.9,3.66,3.66,0,0,0,2.7,1.07,4.48,4.48,0,0,0,3.71-1.85l1.8,1.71a6.11,6.11,0,0,1-2.38,2.06A7.4,7.4,0,0,1,699.91,530.84Zm-.39-12.67a2.82,2.82,0,0,0-2.21,1,4.94,4.94,0,0,0-1.07,2.67h6.33v-.25a4.08,4.08,0,0,0-.89-2.52A2.77,2.77,0,0,0,699.52,518.17Z"
      fill="#fff"
    />
    <path
      d="M711.51,515.77l.09,1.71a5.33,5.33,0,0,1,4.31-2q4.62,0,4.7,5.29v9.78h-3.32V521a3,3,0,0,0-.61-2.08,2.56,2.56,0,0,0-2-.68,3.2,3.2,0,0,0-3,1.82v10.53h-3.32v-14.8Z"
      fill="#fff"
    />
    <path
      d="M723.38,523.06a8.77,8.77,0,0,1,1.58-5.49,5.09,5.09,0,0,1,4.25-2.07,4.83,4.83,0,0,1,3.8,1.64v-7.57h3.33v21h-3l-.17-1.53a4.89,4.89,0,0,1-4,1.8,5.06,5.06,0,0,1-4.21-2.09A9.13,9.13,0,0,1,723.38,523.06Zm3.32.29a6.15,6.15,0,0,0,.87,3.52,2.8,2.8,0,0,0,2.46,1.26,3.12,3.12,0,0,0,3-1.82V520a3.08,3.08,0,0,0-3-1.78,2.86,2.86,0,0,0-2.49,1.28A6.87,6.87,0,0,0,726.7,523.35Z"
      fill="#fff"
    />
    <path
      d="M746.32,530.84a6.84,6.84,0,0,1-5.12-2,7.22,7.22,0,0,1-2-5.3v-.41a8.93,8.93,0,0,1,.86-4,6.53,6.53,0,0,1,2.4-2.71,6.32,6.32,0,0,1,3.44-1,5.79,5.79,0,0,1,4.67,1.93,8.12,8.12,0,0,1,1.65,5.45v1.34h-9.67a4.43,4.43,0,0,0,1.23,2.9,3.66,3.66,0,0,0,2.7,1.07,4.51,4.51,0,0,0,3.72-1.85l1.79,1.71a6.08,6.08,0,0,1-2.37,2.06A7.4,7.4,0,0,1,746.32,530.84Zm-.4-12.67a2.82,2.82,0,0,0-2.21,1,4.94,4.94,0,0,0-1.07,2.67H749v-.25a4,4,0,0,0-.89-2.52A2.77,2.77,0,0,0,745.92,518.17Z"
      fill="#fff"
    />
    <path
      d="M762.57,518.81a8.48,8.48,0,0,0-1.35-.11,3.05,3.05,0,0,0-3.08,1.75v10.12h-3.32v-14.8H758l.08,1.66a3.75,3.75,0,0,1,3.34-1.93,3.1,3.1,0,0,1,1.18.19Z"
      fill="#fff"
    />
    <path d="M57.53,70.45H68.11v4H52.55V50.28h5Z" fill="#fff" />
    <path d="M76.26,74.45h-5V50.28h5Z" fill="#fff" />
    <path d="M89.81,68.45l5.47-18.17h5.55L92.41,74.45H87.22L78.83,50.28h5.53Z" fill="#fff" />
    <path d="M117.65,64h-9.57v6.48h11.23v4H103.1V50.28h16.17v4H108.08v5.76h9.57Z" fill="#fff" />
    <path
      d="M43.53,559.92a11.86,11.86,0,0,1-4.74-2.3,4.39,4.39,0,0,1-1.48-3.37,4.64,4.64,0,0,1,1.81-3.75A7.26,7.26,0,0,1,43.84,549a7.85,7.85,0,0,1,3.52.77,5.86,5.86,0,0,1,2.4,2.1,5.38,5.38,0,0,1,.85,2.94H48.07A3.51,3.51,0,0,0,47,552.09a4.48,4.48,0,0,0-3.12-1,4.64,4.64,0,0,0-2.93.83,2.76,2.76,0,0,0-1,2.29,2.5,2.5,0,0,0,1,2,9.94,9.94,0,0,0,3.39,1.48A15.62,15.62,0,0,1,48,559.17a5.67,5.67,0,0,1,2,1.89,4.87,4.87,0,0,1,.65,2.54,4.5,4.5,0,0,1-1.82,3.74,7.71,7.71,0,0,1-4.86,1.4,9.08,9.08,0,0,1-3.69-.75,6.22,6.22,0,0,1-2.65-2.08,5.07,5.07,0,0,1-.93-3h2.55a3.33,3.33,0,0,0,1.28,2.75,5.43,5.43,0,0,0,3.44,1,5,5,0,0,0,3.07-.81,2.67,2.67,0,0,0,1.07-2.23,2.6,2.6,0,0,0-1-2.18A11.27,11.27,0,0,0,43.53,559.92Z"
      fill="#fff"
    />
    <path
      d="M62.56,567.07a5.16,5.16,0,0,1-4.18,1.67,4.43,4.43,0,0,1-3.47-1.32,5.71,5.71,0,0,1-1.21-3.92v-9.28h2.44v9.21q0,3.24,2.64,3.24c1.86,0,3.1-.69,3.72-2.08V554.22h2.43v14.26H62.61Z"
      fill="#fff"
    />
    <path
      d="M77.19,564.7a1.8,1.8,0,0,0-.75-1.54,7.14,7.14,0,0,0-2.59-.94,12.37,12.37,0,0,1-2.94-1A4.11,4.11,0,0,1,69.3,560a3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3A5.84,5.84,0,0,1,74.05,554,6,6,0,0,1,78,555.2a4,4,0,0,1,1.5,3.21H77a2.19,2.19,0,0,0-.85-1.73,3.18,3.18,0,0,0-2.14-.72,3.3,3.3,0,0,0-2.08.58,1.81,1.81,0,0,0-.75,1.51,1.48,1.48,0,0,0,.69,1.33,8.54,8.54,0,0,0,2.53.86,12.94,12.94,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.41,6.41,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,5,5,0,0,1-2.07-1.7,4.11,4.11,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.81,3.81,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,77.19,564.7Z"
      fill="#fff"
    />
    <path
      d="M95.1,561.51a8.53,8.53,0,0,1-1.49,5.24,4.78,4.78,0,0,1-4,2,5.24,5.24,0,0,1-4.08-1.64V574H83.05V554.22h2.23l.11,1.58A5,5,0,0,1,89.53,554a4.87,4.87,0,0,1,4.07,1.94,8.61,8.61,0,0,1,1.5,5.39Zm-2.44-.28a6.27,6.27,0,0,0-1-3.81,3.33,3.33,0,0,0-2.82-1.4,3.57,3.57,0,0,0-3.32,2v6.81a3.58,3.58,0,0,0,3.34,2,3.29,3.29,0,0,0,2.79-1.39A6.87,6.87,0,0,0,92.66,561.23Z"
      fill="#fff"
    />
    <path
      d="M104.36,568.74a6.22,6.22,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.36,6.36,0,0,1,2.27-2.61,5.75,5.75,0,0,1,3.15-.94,5.35,5.35,0,0,1,4.33,1.83,7.94,7.94,0,0,1,1.54,5.25v1h-9.66a5,5,0,0,0,1.23,3.4,3.87,3.87,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.64,5.64,0,0,0,1.57-1.4l1.49,1.16A6,6,0,0,1,104.36,568.74ZM104.05,556a3.21,3.21,0,0,0-2.47,1.07,5.16,5.16,0,0,0-1.24,3h7.14v-.18a4.62,4.62,0,0,0-1-2.88A3.08,3.08,0,0,0,104.05,556Z"
      fill="#fff"
    />
    <path
      d="M115.29,554.22l.08,1.79a5.21,5.21,0,0,1,4.27-2.06q4.53,0,4.56,5.1v9.43h-2.43V559a3.29,3.29,0,0,0-.71-2.28,2.84,2.84,0,0,0-2.16-.74,3.53,3.53,0,0,0-2.08.63,4.39,4.39,0,0,0-1.4,1.67v10.16H113V554.22Z"
      fill="#fff"
    />
    <path
      d="M127.53,561.23a8.33,8.33,0,0,1,1.56-5.28,5.28,5.28,0,0,1,8-.28v-7.44h2.44v20.25h-2.24l-.12-1.53a5,5,0,0,1-4.07,1.79,4.87,4.87,0,0,1-4-2,8.45,8.45,0,0,1-1.56-5.3Zm2.44.28a6.37,6.37,0,0,0,1,3.79,3.23,3.23,0,0,0,2.77,1.37,3.55,3.55,0,0,0,3.39-2.08V558a3.58,3.58,0,0,0-3.36-2,3.25,3.25,0,0,0-2.8,1.39A7,7,0,0,0,130,561.51Z"
      fill="#fff"
    />
    <path
      d="M143.61,550.43a1.46,1.46,0,0,1,.36-1,1.37,1.37,0,0,1,1.08-.41,1.4,1.4,0,0,1,1.08.41,1.47,1.47,0,0,1,.37,1,1.41,1.41,0,0,1-.37,1,1.43,1.43,0,0,1-1.08.4,1.31,1.31,0,0,1-1.44-1.39Zm2.64,18.05h-2.44V554.22h2.44Z"
      fill="#fff"
    />
    <path
      d="M158.7,564.7a1.8,1.8,0,0,0-.75-1.54,7.2,7.2,0,0,0-2.6-.94,12.68,12.68,0,0,1-2.94-1,4.14,4.14,0,0,1-1.6-1.32,3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.93,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.2,3.2,0,0,0-2.14-.72,3.3,3.3,0,0,0-2.08.58,1.8,1.8,0,0,0-.76,1.51,1.49,1.49,0,0,0,.7,1.33,8.54,8.54,0,0,0,2.53.86,13.11,13.11,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.43,6.43,0,0,1-4,1.15,7.22,7.22,0,0,1-3-.6,5,5,0,0,1-2.07-1.7,4.11,4.11,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.81,3.81,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,158.7,564.7Z"
      fill="#fff"
    />
    <path
      d="M172.86,564.7a1.82,1.82,0,0,0-.74-1.54,7.28,7.28,0,0,0-2.6-.94,12.37,12.37,0,0,1-2.94-1A4.18,4.18,0,0,1,165,560a3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3,5.86,5.86,0,0,1,3.79-1.21,6,6,0,0,1,3.94,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.22,2.22,0,0,0-.85-1.73,3.21,3.21,0,0,0-2.15-.72,3.32,3.32,0,0,0-2.08.58,1.81,1.81,0,0,0-.75,1.51,1.47,1.47,0,0,0,.7,1.33,8.45,8.45,0,0,0,2.52.86,12.77,12.77,0,0,1,3,1,4.19,4.19,0,0,1,1.68,1.36,3.31,3.31,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.41,6.41,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,4.89,4.89,0,0,1-2.06-1.7,4.05,4.05,0,0,1-.75-2.35h2.44a2.52,2.52,0,0,0,1,1.94,4.54,4.54,0,0,0,4.64.16A1.71,1.71,0,0,0,172.86,564.7Z"
      fill="#fff"
    />
    <path
      d="M184.64,568.74a6.23,6.23,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.36,6.36,0,0,1,2.27-2.61,5.76,5.76,0,0,1,3.16-.94,5.35,5.35,0,0,1,4.32,1.83A7.94,7.94,0,0,1,190.2,561v1h-9.66a5.11,5.11,0,0,0,1.23,3.4,3.87,3.87,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.64,5.64,0,0,0,1.57-1.4L190,566A6,6,0,0,1,184.64,568.74Zm-.3-12.78a3.23,3.23,0,0,0-2.48,1.07,5.23,5.23,0,0,0-1.24,3h7.14v-.18a4.62,4.62,0,0,0-1-2.88A3,3,0,0,0,184.34,556Z"
      fill="#fff"
    />
    <path
      d="M207.09,556.4a7.51,7.51,0,0,0-1.2-.09,3.23,3.23,0,0,0-3.26,2v10.13H200.2V554.22h2.37l0,1.64A3.8,3.8,0,0,1,206,554a2.4,2.4,0,0,1,1.08.19Z"
      fill="#fff"
    />
    <path
      d="M209.59,550.43a1.46,1.46,0,0,1,.36-1A1.36,1.36,0,0,1,211,549a1.41,1.41,0,0,1,1.09.41,1.46,1.46,0,0,1,.36,1,1.4,1.4,0,0,1-.36,1,1.44,1.44,0,0,1-1.09.4,1.31,1.31,0,0,1-1.43-1.39Zm2.63,18.05h-2.43V554.22h2.43Z"
      fill="#fff"
    />
    <path
      d="M224.68,564.7a1.8,1.8,0,0,0-.75-1.54,7.2,7.2,0,0,0-2.6-.94,12.68,12.68,0,0,1-2.94-1,4.26,4.26,0,0,1-1.61-1.32,3.22,3.22,0,0,1-.52-1.82,3.71,3.71,0,0,1,1.49-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.93,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.2,3.2,0,0,0-2.14-.72,3.35,3.35,0,0,0-2.09.58,1.81,1.81,0,0,0-.75,1.51,1.49,1.49,0,0,0,.7,1.33,8.54,8.54,0,0,0,2.53.86,13.11,13.11,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.54,1.94,3.65,3.65,0,0,1-1.53,3.07,6.38,6.38,0,0,1-4,1.15,7.27,7.27,0,0,1-3-.6,5,5,0,0,1-2.06-1.7,4,4,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.79,3.79,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,224.68,564.7Z"
      fill="#fff"
    />
    <path
      d="M239.34,567.07a5.16,5.16,0,0,1-4.18,1.67,4.43,4.43,0,0,1-3.47-1.32,5.71,5.71,0,0,1-1.21-3.92v-9.28h2.44v9.21q0,3.24,2.64,3.24c1.86,0,3.1-.69,3.72-2.08V554.22h2.43v14.26h-2.32Z"
      fill="#fff"
    />
    <path
      d="M254,564.7a1.8,1.8,0,0,0-.75-1.54,7.14,7.14,0,0,0-2.59-.94,12.37,12.37,0,0,1-2.94-1,4.11,4.11,0,0,1-1.61-1.32,3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.94,1.25,4,4,0,0,1,1.5,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.18,3.18,0,0,0-2.14-.72,3.3,3.3,0,0,0-2.08.58,1.81,1.81,0,0,0-.75,1.51,1.48,1.48,0,0,0,.69,1.33,8.54,8.54,0,0,0,2.53.86,12.94,12.94,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.43,6.43,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,5,5,0,0,1-2.07-1.7,4.11,4.11,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.81,3.81,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,254,564.7Z"
      fill="#fff"
    />
    <path d="M269.4,568.48H267V548.23h2.44Z" fill="#fff" />
    <path
      d="M279.47,568.74a6.22,6.22,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.36,6.36,0,0,1,2.27-2.61,5.76,5.76,0,0,1,3.16-.94,5.35,5.35,0,0,1,4.32,1.83A7.94,7.94,0,0,1,285,561v1h-9.66a5,5,0,0,0,1.23,3.4,3.87,3.87,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.64,5.64,0,0,0,1.57-1.4l1.49,1.16A6,6,0,0,1,279.47,568.74Zm-.3-12.78a3.22,3.22,0,0,0-2.48,1.07,5.23,5.23,0,0,0-1.24,3h7.14v-.18a4.62,4.62,0,0,0-1-2.88A3.07,3.07,0,0,0,279.17,556Z"
      fill="#fff"
    />
    <path
      d="M293.82,566.75a3.5,3.5,0,0,0,2.28-.79,2.77,2.77,0,0,0,1.08-2h2.31a4.49,4.49,0,0,1-.85,2.34,5.57,5.57,0,0,1-2.07,1.76,6,6,0,0,1-2.75.66,5.88,5.88,0,0,1-4.64-1.94,7.79,7.79,0,0,1-1.72-5.32v-.41a8.56,8.56,0,0,1,.77-3.7,5.86,5.86,0,0,1,2.19-2.52,6.23,6.23,0,0,1,3.38-.9,5.72,5.72,0,0,1,4,1.44,5.14,5.14,0,0,1,1.7,3.73h-2.31a3.34,3.34,0,0,0-1.05-2.27,3.23,3.23,0,0,0-2.33-.89,3.42,3.42,0,0,0-2.88,1.33,6.33,6.33,0,0,0-1,3.87v.46a6.14,6.14,0,0,0,1,3.8A3.41,3.41,0,0,0,293.82,566.75Z"
      fill="#fff"
    />
    <path
      d="M305.78,550.76v3.46h2.66v1.88h-2.66V565a2,2,0,0,0,.36,1.29,1.5,1.5,0,0,0,1.21.42,6.2,6.2,0,0,0,1.16-.15v2a6.9,6.9,0,0,1-1.87.26,3.05,3.05,0,0,1-2.47-1,4.23,4.23,0,0,1-.83-2.8V556.1h-2.6v-1.88h2.6v-3.46Z"
      fill="#fff"
    />
    <path
      d="M320.34,567.07a5.16,5.16,0,0,1-4.18,1.67,4.43,4.43,0,0,1-3.47-1.32,5.71,5.71,0,0,1-1.21-3.92v-9.28h2.44v9.21q0,3.24,2.64,3.24c1.86,0,3.1-.69,3.72-2.08V554.22h2.43v14.26h-2.32Z"
      fill="#fff"
    />
    <path
      d="M335,564.7a1.8,1.8,0,0,0-.75-1.54,7.14,7.14,0,0,0-2.59-.94,12.37,12.37,0,0,1-2.94-1,4.11,4.11,0,0,1-1.61-1.32,3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.94,1.25,4,4,0,0,1,1.5,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.18,3.18,0,0,0-2.14-.72,3.3,3.3,0,0,0-2.08.58,1.81,1.81,0,0,0-.75,1.51,1.48,1.48,0,0,0,.69,1.33,8.54,8.54,0,0,0,2.53.86,12.94,12.94,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.43,6.43,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,5,5,0,0,1-2.07-1.7,4.11,4.11,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.81,3.81,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,335,564.7Z"
      fill="#fff"
    />
    <path
      d="M340.75,572.3l-1.39-.95a6.36,6.36,0,0,0,1.3-3.56v-2.2H343v1.91a6.1,6.1,0,0,1-.65,2.67A6.18,6.18,0,0,1,340.75,572.3Z"
      fill="#fff"
    />
    <path
      d="M361.6,564.7a1.82,1.82,0,0,0-.74-1.54,7.28,7.28,0,0,0-2.6-.94,12.68,12.68,0,0,1-2.94-1,4.26,4.26,0,0,1-1.61-1.32,3.14,3.14,0,0,1-.52-1.82,3.73,3.73,0,0,1,1.48-3,5.86,5.86,0,0,1,3.79-1.21,6,6,0,0,1,3.94,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.22,2.22,0,0,0-.85-1.73,3.21,3.21,0,0,0-2.15-.72,3.32,3.32,0,0,0-2.08.58,1.81,1.81,0,0,0-.75,1.51,1.47,1.47,0,0,0,.7,1.33,8.45,8.45,0,0,0,2.52.86,12.77,12.77,0,0,1,3,1,4.19,4.19,0,0,1,1.68,1.36,3.31,3.31,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.41,6.41,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,4.89,4.89,0,0,1-2.06-1.7,4.05,4.05,0,0,1-.75-2.35h2.44a2.52,2.52,0,0,0,1,1.94,3.83,3.83,0,0,0,2.42.72,3.9,3.9,0,0,0,2.23-.56A1.72,1.72,0,0,0,361.6,564.7Z"
      fill="#fff"
    />
    <path
      d="M376.27,567.07a5.16,5.16,0,0,1-4.18,1.67,4.43,4.43,0,0,1-3.47-1.32,5.71,5.71,0,0,1-1.21-3.92v-9.28h2.44v9.21c0,2.16.88,3.24,2.63,3.24s3.11-.69,3.72-2.08V554.22h2.44v14.26h-2.32Z"
      fill="#fff"
    />
    <path
      d="M390.89,564.7a1.8,1.8,0,0,0-.74-1.54,7.2,7.2,0,0,0-2.6-.94,12.68,12.68,0,0,1-2.94-1A4.26,4.26,0,0,1,383,560a3.22,3.22,0,0,1-.52-1.82,3.71,3.71,0,0,1,1.49-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.93,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.2,3.2,0,0,0-2.14-.72,3.35,3.35,0,0,0-2.09.58,1.81,1.81,0,0,0-.75,1.51,1.49,1.49,0,0,0,.7,1.33,8.54,8.54,0,0,0,2.53.86,13.11,13.11,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.54,1.94,3.65,3.65,0,0,1-1.53,3.07,6.4,6.4,0,0,1-4,1.15,7.27,7.27,0,0,1-3-.6,5,5,0,0,1-2.06-1.7,4,4,0,0,1-.74-2.35h2.43a2.53,2.53,0,0,0,1,1.94,3.79,3.79,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.7,1.7,0,0,0,390.89,564.7Z"
      fill="#fff"
    />
    <path
      d="M402.48,566.75a3.5,3.5,0,0,0,2.28-.79,2.77,2.77,0,0,0,1.08-2h2.3a4.39,4.39,0,0,1-.84,2.34,5.52,5.52,0,0,1-2.08,1.76,6,6,0,0,1-2.74.66,5.87,5.87,0,0,1-4.64-1.94,7.74,7.74,0,0,1-1.72-5.32v-.41a8.56,8.56,0,0,1,.77-3.7,5.86,5.86,0,0,1,2.19-2.52,6.2,6.2,0,0,1,3.38-.9,5.72,5.72,0,0,1,4,1.44,5.13,5.13,0,0,1,1.69,3.73h-2.3a3.34,3.34,0,0,0-1-2.27,3.23,3.23,0,0,0-2.33-.89,3.41,3.41,0,0,0-2.88,1.33,6.33,6.33,0,0,0-1,3.87v.46a6.21,6.21,0,0,0,1,3.8A3.44,3.44,0,0,0,402.48,566.75Z"
      fill="#fff"
    />
    <path
      d="M411.14,550.43a1.42,1.42,0,0,1,.37-1,1.36,1.36,0,0,1,1.07-.41,1.4,1.4,0,0,1,1.08.41,1.42,1.42,0,0,1,.37,1,1.37,1.37,0,0,1-.37,1,1.43,1.43,0,0,1-1.08.4,1.39,1.39,0,0,1-1.07-.4A1.37,1.37,0,0,1,411.14,550.43Zm2.64,18.05h-2.44V554.22h2.44Z"
      fill="#fff"
    />
    <path
      d="M430,561.51a8.53,8.53,0,0,1-1.48,5.24,4.78,4.78,0,0,1-4,2,5.27,5.27,0,0,1-4.09-1.64V574h-2.43V554.22h2.22l.12,1.58a5,5,0,0,1,4.14-1.85,4.87,4.87,0,0,1,4.07,1.94,8.61,8.61,0,0,1,1.49,5.39Zm-2.43-.28a6.33,6.33,0,0,0-1-3.81,3.33,3.33,0,0,0-2.82-1.4,3.6,3.6,0,0,0-3.33,2v6.81a3.6,3.6,0,0,0,3.35,2,3.29,3.29,0,0,0,2.79-1.39A6.87,6.87,0,0,0,427.54,561.23Z"
      fill="#fff"
    />
    <path
      d="M433.33,550.43a1.46,1.46,0,0,1,.36-1,1.37,1.37,0,0,1,1.08-.41,1.4,1.4,0,0,1,1.08.41,1.47,1.47,0,0,1,.37,1,1.41,1.41,0,0,1-.37,1,1.43,1.43,0,0,1-1.08.4,1.31,1.31,0,0,1-1.44-1.39ZM436,568.48h-2.44V554.22H436Z"
      fill="#fff"
    />
    <path
      d="M443.42,550.76v3.46h2.66v1.88h-2.66V565a2,2,0,0,0,.36,1.29,1.5,1.5,0,0,0,1.21.42,6.2,6.2,0,0,0,1.16-.15v2a6.9,6.9,0,0,1-1.87.26,3.05,3.05,0,0,1-2.47-1A4.23,4.23,0,0,1,441,565V556.1h-2.6v-1.88H441v-3.46Z"
      fill="#fff"
    />
    <path
      d="M462,568.74a6.23,6.23,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.36,6.36,0,0,1,2.27-2.61,5.76,5.76,0,0,1,3.16-.94,5.35,5.35,0,0,1,4.32,1.83,7.94,7.94,0,0,1,1.54,5.25v1h-9.66a5.11,5.11,0,0,0,1.23,3.4,3.87,3.87,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.64,5.64,0,0,0,1.57-1.4L467.4,566A6,6,0,0,1,462,568.74Zm-.3-12.78a3.23,3.23,0,0,0-2.48,1.07,5.23,5.23,0,0,0-1.24,3h7.14v-.18a4.62,4.62,0,0,0-1-2.88A3,3,0,0,0,461.72,556Z"
      fill="#fff"
    />
    <path
      d="M470.07,561.23a8.4,8.4,0,0,1,1.54-5.31,5.31,5.31,0,0,1,8.16-.12l.12-1.58h2.23v13.92a5.81,5.81,0,0,1-1.64,4.36,6.09,6.09,0,0,1-4.41,1.6,7.31,7.31,0,0,1-3-.66,5.16,5.16,0,0,1-2.26-1.81l1.27-1.46a4.76,4.76,0,0,0,3.84,1.94,3.73,3.73,0,0,0,2.77-1,3.82,3.82,0,0,0,1-2.82v-1.22a5,5,0,0,1-4,1.68,4.87,4.87,0,0,1-4.05-2A8.79,8.79,0,0,1,470.07,561.23Zm2.45.28a6.41,6.41,0,0,0,1,3.79,3.23,3.23,0,0,0,2.77,1.37,3.56,3.56,0,0,0,3.39-2.09v-6.52a3.62,3.62,0,0,0-3.37-2,3.24,3.24,0,0,0-2.78,1.39A7,7,0,0,0,472.52,561.51Z"
      fill="#fff"
    />
    <path
      d="M492,568.74a6.23,6.23,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.36,6.36,0,0,1,2.27-2.61,5.76,5.76,0,0,1,3.16-.94,5.35,5.35,0,0,1,4.32,1.83,7.94,7.94,0,0,1,1.54,5.25v1h-9.66a5,5,0,0,0,1.23,3.4,3.87,3.87,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.64,5.64,0,0,0,1.57-1.4l1.49,1.16A6,6,0,0,1,492,568.74Zm-.3-12.78a3.23,3.23,0,0,0-2.48,1.07,5.23,5.23,0,0,0-1.24,3h7.14v-.18a4.62,4.62,0,0,0-1-2.88A3.06,3.06,0,0,0,491.66,556Z"
      fill="#fff"
    />
    <path
      d="M503.9,550.76v3.46h2.66v1.88H503.9V565a2,2,0,0,0,.35,1.29,1.51,1.51,0,0,0,1.21.42,6.1,6.1,0,0,0,1.16-.15v2a6.85,6.85,0,0,1-1.87.26,3,3,0,0,1-2.46-1,4.18,4.18,0,0,1-.83-2.8V556.1h-2.6v-1.88h2.6v-3.46Z"
      fill="#fff"
    />
    <path
      d="M522.5,568.74a6.22,6.22,0,0,1-4.72-1.9,7,7,0,0,1-1.82-5.1v-.45a8.61,8.61,0,0,1,.81-3.79,6.28,6.28,0,0,1,2.27-2.61,5.75,5.75,0,0,1,3.15-.94,5.35,5.35,0,0,1,4.33,1.83,7.94,7.94,0,0,1,1.54,5.25v1H518.4a5,5,0,0,0,1.23,3.4,3.84,3.84,0,0,0,3,1.3,4.28,4.28,0,0,0,2.19-.52,5.78,5.78,0,0,0,1.57-1.4l1.48,1.16A6,6,0,0,1,522.5,568.74ZM522.19,556a3.25,3.25,0,0,0-2.48,1.07,5.3,5.3,0,0,0-1.24,3h7.15v-.18a4.62,4.62,0,0,0-1-2.88A3.08,3.08,0,0,0,522.19,556Z"
      fill="#fff"
    />
    <path
      d="M530.54,561.23a8.4,8.4,0,0,1,1.55-5.31,5.31,5.31,0,0,1,8.16-.12l.12-1.58h2.22v13.92A5.78,5.78,0,0,1,541,572.5a6.06,6.06,0,0,1-4.41,1.6,7.35,7.35,0,0,1-3-.66,5.19,5.19,0,0,1-2.25-1.81l1.26-1.46a4.79,4.79,0,0,0,3.84,1.94,3.75,3.75,0,0,0,2.78-1,3.81,3.81,0,0,0,1-2.82v-1.22a5,5,0,0,1-4,1.68,4.89,4.89,0,0,1-4.06-2A8.85,8.85,0,0,1,530.54,561.23Zm2.46.28a6.41,6.41,0,0,0,1,3.79,3.24,3.24,0,0,0,2.77,1.37,3.56,3.56,0,0,0,3.39-2.09v-6.52a3.62,3.62,0,0,0-3.36-2,3.22,3.22,0,0,0-2.78,1.39A7,7,0,0,0,533,561.51Z"
      fill="#fff"
    />
    <path
      d="M552.43,568.74a6.25,6.25,0,0,1-4.72-1.9,7.09,7.09,0,0,1-1.82-5.1v-.45a8.47,8.47,0,0,1,.82-3.79,6.33,6.33,0,0,1,2.26-2.61,5.78,5.78,0,0,1,3.16-.94,5.32,5.32,0,0,1,4.32,1.83A7.94,7.94,0,0,1,558,561v1h-9.67a5.06,5.06,0,0,0,1.24,3.4,3.84,3.84,0,0,0,3,1.3,4.21,4.21,0,0,0,2.18-.52,5.64,5.64,0,0,0,1.57-1.4l1.49,1.16A6,6,0,0,1,552.43,568.74Zm-.3-12.78a3.25,3.25,0,0,0-2.48,1.07,5.23,5.23,0,0,0-1.24,3h7.15v-.18a4.68,4.68,0,0,0-1-2.88A3.08,3.08,0,0,0,552.13,556Z"
      fill="#fff"
    />
    <path
      d="M569.37,564.7a1.8,1.8,0,0,0-.75-1.54,7.14,7.14,0,0,0-2.59-.94,12.62,12.62,0,0,1-2.95-1,4.14,4.14,0,0,1-1.6-1.32,3.14,3.14,0,0,1-.52-1.82,3.7,3.7,0,0,1,1.48-3,5.84,5.84,0,0,1,3.79-1.21,6,6,0,0,1,3.93,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.2,3.2,0,0,0-2.14-.72,3.3,3.3,0,0,0-2.08.58,1.82,1.82,0,0,0-.76,1.51,1.49,1.49,0,0,0,.7,1.33,8.54,8.54,0,0,0,2.53.86,12.94,12.94,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.55,1.94,3.65,3.65,0,0,1-1.53,3.07,6.43,6.43,0,0,1-4,1.15,7.25,7.25,0,0,1-3-.6,5,5,0,0,1-2.07-1.7,4.11,4.11,0,0,1-.74-2.35h2.44a2.49,2.49,0,0,0,1,1.94,3.81,3.81,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.71,1.71,0,0,0,569.37,564.7Z"
      fill="#fff"
    />
    <path
      d="M578.54,550.76v3.46h2.66v1.88h-2.66V565a2,2,0,0,0,.35,1.29,1.51,1.51,0,0,0,1.21.42,6.1,6.1,0,0,0,1.16-.15v2a6.85,6.85,0,0,1-1.87.26,3,3,0,0,1-2.46-1,4.18,4.18,0,0,1-.83-2.8V556.1h-2.6v-1.88h2.6v-3.46Z"
      fill="#fff"
    />
    <path
      d="M593.1,568.48a5.13,5.13,0,0,1-.35-1.5,5.45,5.45,0,0,1-4.06,1.76,5,5,0,0,1-3.46-1.19,3.85,3.85,0,0,1-1.35-3,4.07,4.07,0,0,1,1.69-3.46,8,8,0,0,1,4.77-1.23h2.37v-1.12a2.75,2.75,0,0,0-.76-2,3.1,3.1,0,0,0-2.26-.75,3.53,3.53,0,0,0-2.18.66,1.93,1.93,0,0,0-.89,1.59h-2.45a3.33,3.33,0,0,1,.76-2.06,5.1,5.1,0,0,1,2.06-1.58,6.82,6.82,0,0,1,2.85-.58,5.64,5.64,0,0,1,3.86,1.23,4.46,4.46,0,0,1,1.45,3.4v6.56a8.12,8.12,0,0,0,.5,3.13v.21Zm-4.05-1.86a4.32,4.32,0,0,0,2.17-.59,3.55,3.55,0,0,0,1.49-1.54v-2.93H590.8q-4.49,0-4.48,2.62a2.24,2.24,0,0,0,.76,1.8A3,3,0,0,0,589.05,566.62Z"
      fill="#fff"
    />
    <path
      d="M607.52,564.7a1.8,1.8,0,0,0-.74-1.54,7.2,7.2,0,0,0-2.6-.94,12.68,12.68,0,0,1-2.94-1,4.26,4.26,0,0,1-1.61-1.32,3.22,3.22,0,0,1-.52-1.82,3.71,3.71,0,0,1,1.49-3,5.82,5.82,0,0,1,3.79-1.21,6,6,0,0,1,3.93,1.25,4,4,0,0,1,1.51,3.21h-2.45a2.19,2.19,0,0,0-.85-1.73,3.2,3.2,0,0,0-2.14-.72,3.33,3.33,0,0,0-2.09.58,1.81,1.81,0,0,0-.75,1.51,1.47,1.47,0,0,0,.7,1.33,8.54,8.54,0,0,0,2.53.86,13.11,13.11,0,0,1,3,1,4.33,4.33,0,0,1,1.68,1.36,3.38,3.38,0,0,1,.54,1.94,3.65,3.65,0,0,1-1.53,3.07,6.4,6.4,0,0,1-4,1.15,7.27,7.27,0,0,1-3-.6,5,5,0,0,1-2.06-1.7,4,4,0,0,1-.74-2.35h2.43a2.53,2.53,0,0,0,1,1.94,3.79,3.79,0,0,0,2.42.72,3.89,3.89,0,0,0,2.22-.56A1.72,1.72,0,0,0,607.52,564.7Z"
      fill="#fff"
    />
    <path
      d="M613.3,572.3l-1.38-.95a6.27,6.27,0,0,0,1.29-3.56v-2.2h2.39v1.91a6.1,6.1,0,0,1-.65,2.67A6.2,6.2,0,0,1,613.3,572.3Z"
      fill="#fff"
    />
    <path d="M628.5,568.48h-2.44V548.23h2.44Z" fill="#fff" />
    <path
      d="M641.46,568.48a4.82,4.82,0,0,1-.35-1.5,5.45,5.45,0,0,1-4.06,1.76,5,5,0,0,1-3.46-1.19,3.85,3.85,0,0,1-1.35-3,4,4,0,0,1,1.69-3.46,8,8,0,0,1,4.77-1.23h2.37v-1.12a2.75,2.75,0,0,0-.76-2,3.1,3.1,0,0,0-2.26-.75,3.51,3.51,0,0,0-2.18.66,1.93,1.93,0,0,0-.89,1.59h-2.45a3.33,3.33,0,0,1,.76-2.06,5.1,5.1,0,0,1,2.06-1.58,6.82,6.82,0,0,1,2.85-.58,5.64,5.64,0,0,1,3.86,1.23,4.46,4.46,0,0,1,1.45,3.4v6.56a8.12,8.12,0,0,0,.5,3.13v.21Zm-4.05-1.86a4.32,4.32,0,0,0,2.17-.59,3.55,3.55,0,0,0,1.49-1.54v-2.93h-1.91q-4.49,0-4.48,2.62a2.24,2.24,0,0,0,.76,1.8A3,3,0,0,0,637.41,566.62Z"
      fill="#fff"
    />
    <path
      d="M655.71,568.48V556.1h-2.26v-1.88h2.26v-1.47a4.82,4.82,0,0,1,1.22-3.54A4.62,4.62,0,0,1,660.4,548a6.34,6.34,0,0,1,1.67.23l-.13,2a6.89,6.89,0,0,0-1.32-.12,2.35,2.35,0,0,0-1.83.7,2.8,2.8,0,0,0-.65,2v1.51h3.05v1.88h-3.05v12.38Z"
      fill="#fff"
    />
    <path
      d="M671,556.4a7.51,7.51,0,0,0-1.2-.09,3.22,3.22,0,0,0-3.26,2v10.13h-2.44V554.22h2.37l0,1.64a3.82,3.82,0,0,1,3.41-1.91,2.4,2.4,0,0,1,1.08.19Z"
      fill="#fff"
    />
    <path
      d="M673.51,550.43a1.47,1.47,0,0,1,.37-1,1.61,1.61,0,0,1,2.15,0,1.42,1.42,0,0,1,.37,1,1.37,1.37,0,0,1-.37,1,1.45,1.45,0,0,1-2.52-1Zm2.64,18.05h-2.44V554.22h2.44Z"
      fill="#fff"
    />
    <path
      d="M682.6,554.22l.08,1.79A5.21,5.21,0,0,1,687,554q4.53,0,4.57,5.1v9.43h-2.44V559a3.29,3.29,0,0,0-.71-2.28,2.83,2.83,0,0,0-2.15-.74,3.56,3.56,0,0,0-2.09.63,4.37,4.37,0,0,0-1.39,1.67v10.16H680.3V554.22Z"
      fill="#fff"
    />
    <path
      d="M694.86,561.23a8.4,8.4,0,0,1,1.54-5.31,5.31,5.31,0,0,1,8.16-.12l.12-1.58h2.23v13.92a5.78,5.78,0,0,1-1.65,4.36,6.05,6.05,0,0,1-4.4,1.6,7.31,7.31,0,0,1-3-.66,5.16,5.16,0,0,1-2.26-1.81l1.27-1.46a4.76,4.76,0,0,0,3.83,1.94,3.73,3.73,0,0,0,2.78-1,3.81,3.81,0,0,0,1-2.82v-1.22a5,5,0,0,1-4,1.68,4.87,4.87,0,0,1-4.05-2A8.79,8.79,0,0,1,694.86,561.23Zm2.45.28a6.41,6.41,0,0,0,1,3.79,3.23,3.23,0,0,0,2.77,1.37,3.56,3.56,0,0,0,3.38-2.09v-6.52a3.6,3.6,0,0,0-3.36-2,3.24,3.24,0,0,0-2.78,1.39A7,7,0,0,0,697.31,561.51Z"
      fill="#fff"
    />
    <path
      d="M710.84,550.43a1.46,1.46,0,0,1,.36-1,1.39,1.39,0,0,1,1.08-.41,1.4,1.4,0,0,1,1.08.41,1.42,1.42,0,0,1,.37,1,1.37,1.37,0,0,1-.37,1,1.43,1.43,0,0,1-1.08.4,1.42,1.42,0,0,1-1.08-.4A1.4,1.4,0,0,1,710.84,550.43Zm2.64,18.05H711V554.22h2.44Z"
      fill="#fff"
    />
    <path d="M720.27,568.48h-2.44V548.23h2.44Z" fill="#fff" />
    <path d="M727.07,568.48h-2.44V548.23h2.44Z" fill="#fff" />
    <path
      d="M740,568.48a5.21,5.21,0,0,1-.34-1.5,5.48,5.48,0,0,1-4.06,1.76,5,5,0,0,1-3.46-1.19,3.86,3.86,0,0,1-1.36-3,4.05,4.05,0,0,1,1.7-3.46,8,8,0,0,1,4.76-1.23h2.38v-1.12a2.76,2.76,0,0,0-.77-2,3.09,3.09,0,0,0-2.25-.75,3.56,3.56,0,0,0-2.19.66,1.92,1.92,0,0,0-.88,1.59H731.1a3.38,3.38,0,0,1,.75-2.06,5.17,5.17,0,0,1,2.06-1.58,6.88,6.88,0,0,1,2.85-.58,5.65,5.65,0,0,1,3.87,1.23,4.5,4.5,0,0,1,1.45,3.4v6.56a7.93,7.93,0,0,0,.5,3.13v.21Zm-4-1.86a4.35,4.35,0,0,0,2.18-.59,3.67,3.67,0,0,0,1.49-1.54v-2.93h-1.91q-4.49,0-4.49,2.62A2.24,2.24,0,0,0,734,566,2.93,2.93,0,0,0,736,566.62Z"
      fill="#fff"
    />
    <path
      d="M746.2,567.2a1.51,1.51,0,0,1,.37-1.05,1.45,1.45,0,0,1,1.13-.43,1.41,1.41,0,0,1,1.53,1.48,1.43,1.43,0,0,1-.39,1,1.51,1.51,0,0,1-1.14.4,1.34,1.34,0,0,1-1.5-1.42Z"
      fill="#fff"
    />
    <path
      d="M753.54,567.2a1.52,1.52,0,0,1,.38-1.05,1.45,1.45,0,0,1,1.13-.43,1.41,1.41,0,0,1,1.53,1.48,1.43,1.43,0,0,1-.39,1,1.51,1.51,0,0,1-1.14.4,1.35,1.35,0,0,1-1.51-1.42Z"
      fill="#fff"
    />
    <path
      d="M760.89,567.2a1.52,1.52,0,0,1,.38-1.05,1.45,1.45,0,0,1,1.13-.43,1.41,1.41,0,0,1,1.53,1.48,1.43,1.43,0,0,1-.39,1,1.51,1.51,0,0,1-1.14.4,1.35,1.35,0,0,1-1.51-1.42Z"
      fill="#fff"
    />
    <circle cx="135.97" cy="62.36" fill="#fff" r="7.67" />
    <rect fill="none" height="569.36" stroke="#fff" strokeMiterlimit="10" strokeWidth="2px" width="768.19" x="18.93" y="17.39" />
  </svg>
);

export default LiveCamWidgetSkin2Icon;

export default {
  'paymentForm.bankInfosTitle': 'Bank Informations',
  'paymentForm.beneficiaryInfosTitle': 'Beneficiary Informations',
  'paymentForm.intermediaryInfosTitle': 'Intermediary Bank Informations',
  'paymentFormLabel.intermediaryBankStatus': 'I have an intermediary bank',
  'paymentFormLabel.checkPayableTo': 'Payable to',
  'paymentFormLabel.paxumEmail': 'Paxum email',
  'paymentFormLabel.paymentMethodFees': 'Payment method (fees)',
  'paymentFormLabel.referenceInformation': 'Reference information',
  'paymentFormLabel.wireABArouting': 'ABA, Routing or Branch Number (for American Banks)',
  'paymentFormLabel.wireBankInstitutionNumber': 'Bank Institution Number',
  'paymentFormLabel.wireBicSwift': 'BIC / SWIFT',
  'paymentFormLabel.bankInformationName': "Bank's name",
  'paymentFormLabel.bankInformationAddress': "Bank's address",
  'paymentFormLabel.bankInformationCity': "Bank's city",
  'paymentFormLabel.bankInformationCountry': "Bank's country",
  'paymentFormLabel.bankInformationProvince': "Bank's province",
  'paymentFormLabel.bankInformationState': "Bank's state",
  'paymentFormLabel.bankInformationRegion': "Bank's region",
  'paymentFormLabel.bankInformationZipcode': "Bank's zip code",
  'paymentFormLabel.wireIntermediaryBankInformationName': "Intermediary bank's name",
  'paymentFormLabel.wireIntermediaryBankInformationAddress': "Intermediary bank's address",
  'paymentFormLabel.wireIntermediaryBankInformationCity': "Intermediary bank's city",
  'paymentFormLabel.wireIntermediaryBankInformationCountry': "Intermediary bank's country",
  'paymentFormLabel.wireIntermediaryBankInformationProvince': "Intermediary bank's province",
  'paymentFormLabel.wireIntermediaryBankInformationState': "Intermediary bank's state",
  'paymentFormLabel.wireIntermediaryBankInformationRegion': "Intermediary bank's region",
  'paymentFormLabel.wireIntermediaryBankInformationZipcode': "Intermediary bank's zip code",
  'paymentFormLabel.firstNameEPayUser': 'First Name',
  'paymentFormLabel.lastNameEPayUser': 'Last Name',
  'paymentFormLabel.eWalletNumber': 'E-Wallet Number',
};

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { TemplateSelectBox } from '../../components/reusables';

import { premadeTemplates } from './LiveCamWidgetConsts';
import { getNewToken } from '../../utils/helpers';
import { Gtm } from '../../utils/gtm.js';

const { LiveCamWidgetPage } = messages;

const LiveCamWidgetTemplateSettings = ({ animated, fields: { skin }, onTemplateChange }) => {
  const token = getNewToken();

  return (
    <div id="template-settings">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.templateSettings.chooseTemplate} />
      </h2>

      <div className="templates">
        {premadeTemplates.map((template, index) => {
          const templateSkin = template.settings.templateSettings.skin;

          return (
            <TemplateSelectBox
              Icon={template.svg}
              animated={animated}
              className={skin.value === templateSkin ? 'active' : undefined}
              field={skin}
              id={`templateOption-${index}`}
              key={`${token}-templateOption-${index}`}
              onClick={() => {
                onTemplateChange(template);
                Gtm.event('live cam widget', 'Click', `template -  ${templateSkin}`);
              }}
              title={template.name}
              value={templateSkin}
            />
          );
        })}
      </div>
    </div>
  );
};

LiveCamWidgetTemplateSettings.propTypes = {
  animated: PropTypes.bool,
  fields: PropTypes.shape({
    skin: PropTypes.object,
  }),
  onTemplateChange: PropTypes.func.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'templateOptionsLC',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['skin'],
    destroyOnUnmount: false,
  })(LiveCamWidgetTemplateSettings)
);

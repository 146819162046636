import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

export const requestPromoTools = () => ({
  type: ACTIONS.PROMOTOOLS.REQUEST_PROMO_TOOLS,
});

export const receivePromoTools = (data) => ({
  type: ACTIONS.PROMOTOOLS.RECEIVE_PROMO_TOOLS,
  data,
});

export const failedToReceivePromoTools = () => ({
  type: ACTIONS.PROMOTOOLS.FAILED_TO_RECEIVE_PROMO_TOOLS,
});

export const fetchPromoTools = () => (dispatch) => {
  dispatch(requestPromoTools());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/promotools`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePromoTools(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceivePromoTools());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getJumioUrl } from '../../../actions/affiliate-validation-actions';
import { Gtm } from '../../../utils/gtm';
import { Checkbox } from '../../../components/reusables';
import { getAffiliateValidationStatusName } from '../Identity.react';
import { reduxForm } from 'redux-form';
import { closeModalAnimation } from '../../../components/Modal/modal-actions/modal-actions';
import useClickOutside from '../../../components/reusables/colorpicker/useClickOutside';
import { updateProfile } from '../../../actions/profile-actions';

const JumioRedirectModal = ({
  jumioUrl,
  isFetchingJumio,
  dispatch,
  getAffiliateTypeId,
  affiliateType,
  affiliateValidationStatus,
  listValidationStatus,
  fields,
  modalInner,
}) => {
  useEffect(() => {}, [jumioUrl]);

  const currentAffiliateValidationStatusName = getAffiliateValidationStatusName(
    affiliateValidationStatus?.validation_status_id,
    listValidationStatus
  )?.toLowerCase();

  const isOngoingValidation =
    currentAffiliateValidationStatusName === 'PENDING'.toLowerCase() ||
    currentAffiliateValidationStatusName === 'PROCESSING'.toLowerCase();

  const onClickText = isOngoingValidation
    ? 'Please wait for the end of the current Jumio validation process'
    : 'CONFIRM YOUR IDENTITY';

  const handleClick = () => {
    if (affiliateType?.type !== 'Person') {
      dispatch(
        updateProfile({
          affiliate_type_id: getAffiliateTypeId('Person'),
        })
      );
    }
    if (!jumioUrl && !isOngoingValidation) {
      dispatch(getJumioUrl(jumioUrl, currentAffiliateValidationStatusName));
    }
    Gtm.event('profile - identity', 'Click', `Confirm your identity - Individual`);
  };

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <div className="jumio-modal">
      <h2 className="title primary-color">IMPORTANT NOTICE</h2>
      <p>
        You will have <b>30 minutes</b> to complete the process once you click on the button.
      </p>
      <p>
        Our service provider will ask you to attach an official document confirming your identity and scan your face for
        validation.
      </p>
      <p>
        Doing so will ensure you get paid on time and help us maintain compliant practices. You can wait until you have generated
        revenue before completing the process.
      </p>
      <p>
        <b>Before you begin:</b>
      </p>
      <div className="modalPreparationSection">
        <ul className="modalPreparationList">
          <li>
            <div>
              Have a government issued document on hand (
              <a
                className="primary-color"
                href="https://support.crakrevenue.com/knowledge-base/category/identity-validation/"
                rel="noreferrer"
                target="_blank"
              >
                <b>Accepted documents</b>
              </a>
              ).
            </div>
          </li>
          <li>
            <div>Make sure the lighting is good in your room for optimal picture quality.</div>
          </li>
          <li>
            <div>
              Use a high resolution camera or webcam. We recommend using your mobile phone
              <br />
              (You can use the “Continue on Mobile” option).
            </div>
          </li>
          <li>
            <div>
              <p>
                For security purposes, please <b>do not</b> send images of your ID documents directly to CrakRevenue. <br />
                This will not speed up the process.
              </p>
            </div>
          </li>
          <li>
            <div>
              You can find useful information in our{' '}
              <a
                className="primary-color"
                href="https://support.crakrevenue.com/knowledge-base/how-do-i-validate-my-identity/"
                rel="noreferrer"
                target="_blank"
              >
                FAQ
              </a>
            </div>
          </li>
        </ul>
      </div>
      <b className="modalCheckbox">
        <Checkbox field={fields.isReadyToValidate} id="isReadyToValidate" label="I am ready to begin the process" />
      </b>
      <button
        className="waves-effect waves-light btn bg-primary-color"
        disabled={!fields.isReadyToValidate.checked || isOngoingValidation || isFetchingJumio}
        onClick={handleClick}
      >
        {`${onClickText}`}
      </button>

      <p className="privacy-policy-text">
        Please refer to our{' '}
        <a className="primary-color" href="https://www.crakrevenue.com/privacy/" rel="noreferrer" target="_blank">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className="primary-color"
          href="https://www.jumio.com/legal-information/privacy-policy/jumio-corp-privacy-policy-for-online-services/)"
          rel="noreferrer"
          target="_blank"
        >
          Jumio’s Privacy Policy
        </a>{' '}
        to learn more about how the information you submit is collected, used, and disclosed.
      </p>
    </div>
  );
};

export default connect((state) => ({
  isFetchingJumio: state.affiliateValidation.isFetchingJumio,
  jumioUrl: state.affiliateValidation.url,
  affiliateType: state.profile.data.customProfile.affiliate.affiliate_type,
  affiliateValidationStatus: state.profile.data.affValidationStatus,
  listValidationStatus: state.application.lists.validationStatus,
}))(
  reduxForm(
    {
      form: 'isReadyForValidation',
      touchOnBlur: false,
      touchOnChange: false,
      fields: ['isReadyToValidate'],
    },
    () => ({
      initialValues: {
        isReadyToValidate: false,
      },
    })
  )(JumioRedirectModal)
);

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { LayoutSettingsBackground, LayoutSettingsLayout, LayoutSettingsWidgetSize } from './layout-settings';
import { default as validate } from './validateLiveCamWidgetOptions';

const LiveCamWidgetLayoutSettings = ({
  fields: { backgroundColor, backgroundType, columns, iframeHeight, iframeSizes, iframeWidth, layoutType, rows },
}) => (
  <div className="settings-groups" id="layout-settings">
    <LayoutSettingsWidgetSize fields={{ iframeHeight, iframeSizes, iframeWidth }} />

    <LayoutSettingsLayout fields={{ columns, layoutType, rows }} />

    <LayoutSettingsBackground fields={{ backgroundColor, backgroundType }} />
  </div>
);

LiveCamWidgetLayoutSettings.propTypes = {
  fields: PropTypes.shape({
    backgroundColor: PropTypes.object,
    backgroundType: PropTypes.object,
    columns: PropTypes.object,
    iframeHeight: PropTypes.object,
    iframeSizes: PropTypes.object,
    iframeWidth: PropTypes.object,
    layoutType: PropTypes.object,
    rows: PropTypes.object,
  }),
};

export default injectIntl(
  reduxForm({
    form: 'layoutSettingsLC',
    fields: ['backgroundColor', 'backgroundType', 'columns', 'iframeHeight', 'iframeSizes', 'iframeWidth', 'layoutType', 'rows'],
    destroyOnUnmount: false,
    validate,
  })(LiveCamWidgetLayoutSettings)
);

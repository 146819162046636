import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';
import { CodesTypes } from '../LiveCamWidgetConsts';

import { Radio, Checkbox } from '../../../components/reusables';
import Tooltip from '../../../components/reusables/ReactTooltip';
import { Gtm } from '../../../utils/gtm';

import { v4 as uuidv4 } from 'uuid';

const { LiveCamWidgetPage } = messages;

const TypeSettingsType = ({ availableExclusiveWidgetTypes, fields: { widgetType, canHide }, intl, onWidgetTypeChange }) => {
  // for types in a group, if both are disabled we display only one
  // else we display only the not disabled one
  const typesToDisplay = CodesTypes.filter((type) => !type.group);

  const groups = CodesTypes.filter((type) => type.group).reduce((groups, type) => {
    if (groups[type.group]) {
      groups[type.group].push(type);
    } else {
      groups[type.group] = [type];
    }
    return groups;
  }, {});

  Object.entries(groups).forEach(([groupName, types]) => {
    const availableTypes = types.filter((type) => type.isOfferExclusive && availableExclusiveWidgetTypes.includes(type.id));
    if (availableTypes.length === 0) {
      typesToDisplay.push(types[0]);
    } else {
      typesToDisplay.push(...availableTypes);
    }
  });

  return (
    <div className="settings-group">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.typeSettings.widgetTypeSection.title.text} />
      </h2>
      <div className="radio-buttons vertical">
        {typesToDisplay.map((type) => {
          const isDisabled = type.isOfferExclusive && !availableExclusiveWidgetTypes.includes(type.id);
          if (isDisabled) {
            return (
              <Tooltip
                key={uuidv4()}
                tooltip={isDisabled ? intl.formatMessage(LiveCamWidgetPage.warnings.disabledWidgetType) : ''}
              >
                <Radio
                  disabled={isDisabled}
                  field={widgetType}
                  id={`widget-type-${type.id}`}
                  image={`/img/live-cam-widget/widget-type-${type.image}.gif`}
                  label={LiveCamWidgetPage.fields.widgetType.choices[type.labelId]}
                  onClick={() => onWidgetTypeChange(type.id)}
                  value={type.id}
                />
              </Tooltip>
            );
          } else {
            return (
              <>
                <Radio
                  disabled={isDisabled}
                  field={widgetType}
                  id={`widget-type-${type.id}`}
                  image={`/img/live-cam-widget/widget-type-${type.image}.gif`}
                  label={LiveCamWidgetPage.fields.widgetType.choices[type.labelId]}
                  onClick={() => {
                    onWidgetTypeChange(type.id);
                    Gtm.event('live cam widget', 'Click', `select widget type -  ${type.title}`);
                  }}
                  value={type.id}
                />
                {type.isOfferExclusive && widgetType.value.includes('im_') ? (
                  <Checkbox
                    className="canhide-checkbox"
                    disabled={isDisabled}
                    escapeHtml
                    field={canHide}
                    id="widget-canHide"
                    label={LiveCamWidgetPage.fields.canHide}
                    value={!isDisabled ? '1' : ''}
                  />
                ) : (
                  ''
                )}
              </>
            );
          }
        })}
      </div>
    </div>
  );
};

TypeSettingsType.propTypes = {
  availableExclusiveWidgetTypes: PropTypes.array,
  fields: PropTypes.shape({
    widgetType: PropTypes.object,
    canHide: PropTypes.object,
  }),
  intl: intlShape.isRequired,
  onWidgetTypeChange: PropTypes.func,
};

export default injectIntl(TypeSettingsType);

import { InputText } from '../../../components/reusables';
import messages from '../../../i18n/base-en';

const BitcoinSection = ({ bitcoinWalletAddress }) => (
  <div className="row">
    <InputText
      className="col-md-12"
      displayErrorInstantly
      field={bitcoinWalletAddress}
      id="bitcoinWalletAddress"
      label={messages.paymentInfosBitcoinEWalletAdress}
      tabIndex={50}
      type="text"
    />
  </div>
);

export default BitcoinSection;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';

const Styles = ({ skinConfigurations }) => {
  const COLORS = SkinConfigurationsUtils.getColors(skinConfigurations);
  return (
    <style>{`
      #page-wrap {
        background-color: ${COLORS.BACKGROUND_COLOR};
      }

      .bg-primary-color,
      .standard-bg-colors,
      .basic-bg-colors:focus,
      .basic-bg-colors:hover,
      a.btn,
      button.btn,
      input[type=checkbox]:checked:not(:disabled) + .lever,
      input[type=radio]:checked:not(:disabled) + label:after,
      ul.list li:before,
      .checkbox-field input[type=checkbox]:checked:not(:disabled) + label:after,
      .datepicker__day--in-range,
      .dropdown-content li:hover,
      .menu-link:hover .icon span,
      .loading-section-bar:nth-child(-n+4),
      #saveReport button.btn:not(:disabled),
      #billing-change .modal-content .btn,
      #billing-change-error .modal-content .btn,
      #billing-change-confirmed .modal-content .btn,
      #login-page .btn,
      #password-reset-form .btn,
      #modal-add-variables .btn,
      #newsbox,
      #home-account-manager .image-container .image .image-tag {
        background-color: ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      .copy-value-widget .your-link,
      .code-input .title {
        background-color: ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} !important;
      }

      .primary-border-color {
        border: 1px solid ${COLORS.PRIMARY_COLOR.DEFAULT};
      }

      .btn-open-modal{
        text-decoration: underline ${COLORS.PRIMARY_COLOR.DEFAULT};
      }

      .LinesEllipsis div span{
        color: ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      .landing-notif {
         background: linear-gradient(${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR}, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR}),
           linear-gradient(to bottom, #f5e985, #b8b033);
       }

      .landing-notif::after {
            border: 4px solid ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR};
        }
      .primary-color,
      .standard-colors,
      .basic-colors:focus,
      .basic-colors:hover,
      .hov-primary-color:focus,
      .hov-primary-color:hover,
      input[type=email]:focus:not([readonly]) + label,
      input[type=number]:focus:not([readonly]) + label,
      input[type=password]:focus:not([readonly]) + label,
      input[type=text]:focus:not([readonly]) + label,
      .black-modal input + label,
      .datepicker__duration-selector a:hover,
      .div-container-footer .link:hover,
      .clickable:hover .div-container-footer .link,
      .input-field i:not(.notice-icon):not(.tooltipped),
      .menu-link:hover,
      #home-account-manager:not(.menu-left) a:hover,
      #home-account-manager:not(.menu-left) i,
      #main-navigation a:not(.active):hover i,
      #profile-notifications .table tr td a,
      #home-account-manager .your-aff span {
        color: ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      .fill-primary-color,
      .fill-primary-color path,
      .fill-primary-color polygon,
      .fill-primary-darker-color {
        fill: ${COLORS.PRIMARY_COLOR.DARKER} !important;
      }

      .payment-history-graph .caption {
        color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.CAPTION};
      }
      .payment-history-graph .minimum-payout {
        color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.MINIMUM_PAYOUT_COLOR};
      }
      .payment-history-graph .minimum-payout.completed {
        color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.MINIMUM_PAYOUT_COMPLETED_COLOR};
      }
      .payment-history-graph .minimum-payout svg .fill {
        fill: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.MINIMUM_PAYOUT_COLOR};
      }
      .payment-history-graph .minimum-payout.completed svg .fill {
        fill: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.RANK_STATUS_COMPLETED_COLOR};
      }
      .payment-history-graph .gauge .gauge-bkg {
        background-color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.GAUGE.BACKGROUND_COLOR_UNFILLED};
        outline-color: ${COLORS.HEADER.BACKGROUND_COLOR};
      }
      .payment-history-graph .gauge .gauge-ticks span {
        border-color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.GAUGE.COLOR_TICKS};
      }
      .payment-history-graph .days-left {
        color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.DAYS_LEFT.COLOR};
      }
      .payment-history-graph .days-left.hurry-up {
        color: ${COLORS.HEADER.PAYMENT_HISTORY_GRAPH.DAYS_LEFT.HURRY_UP_COLOR};
      }

      .br-primary-color,
      .scroller li.active,
      #search-input,
      #statistics-reports li.active,
      #statistics-sub-reports li.active,
      input[type=radio].with-gap:checked + label:before,
      .checkbox-field input[type=checkbox]:checked:not(:disabled) + label:after,
      .select-wrapper li:not(:hover) input[type=checkbox]:checked + label:before,
      .search-component input.multiple-select-dropdown span:not(:hover) input[type=checkbox]:checked + label:before {
        border-color: ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      .black-modal input,
      input:not(.select-dropdown):not([id^=tellUsMore]):focus,
      select:not(#select-RECENT_OFFERS):not(#select-TOP_OFFERS):not([id^=tellUsMore]) ~ input.select-dropdown:focus,
      input[type=text]:focus:not([readonly]):not([id^=tellUsMore]),
      input[type=password]:focus:not([readonly]),
      input[type=email]:focus:not([readonly]),
      input[type=url]:focus:not([readonly]),
      input[type=time]:focus:not([readonly]),
      input[type=date]:focus:not([readonly]),
      input[type=datetime-local]:focus:not([readonly]),
      input[type=tel]:focus:not([readonly]),
      input[type=number]:focus:not([readonly]),
      input[type=search]:focus:not([readonly]),
      textarea.materialize-textarea:focus:not([readonly]),
      input-field:not(.error) textarea {
        border-color: ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
        box-shadow: 0 1px 0 0 ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      .more-stats:hover {
        box-shadow: 0 0 3px 0 ${COLORS.PRIMARY_COLOR.DEFAULT} !important;
      }

      input[type=radio]:checked:not(:disabled) + label:after,
      .scroller li:not(.active):not(.disabled):hover,
      #statistics-sub-reports li:not(.active):hover,
      .demoIframe .device-icon:not(.br-primary-color):hover,
      .checkbox-field input[type=checkbox]:focus + label:after {
        border-color: ${COLORS.PRIMARY_COLOR.LIGHTER} !important;
      }

      .btn:hover,
      input:hover,
      input[type=checkbox] + label:hover:before,
      input[type=checkbox] + label:hover:after,
      button:hover,
      .driller-button:hover,
      .driller-button.active {
        opacity: .75;
      }

      .bg-primary-darker-color,
      .basic-bg-colors:active,
      .standard-bg-colors:hover,
      .datepicker__day--in-range:hover {
        background-color: ${COLORS.PRIMARY_COLOR.DARKER} !important;
      }

      .primary-darker-color,
      .standard-colors:focus,
      .standard-colors:hover,
      .basic-colors:active {
        color: ${COLORS.PRIMARY_COLOR.DARKER} !important;
      }

      .primary-lighter-color,
      .standard-colors:active,
      #NewsBox a:hover,
      #offer-header .description-container .details-container a,
      #site-header .notifications-list a,
      #site-footer a:hover,
      #home-account-manager.menu-left a:hover,
      #home-account-manager.menu-left i {
        color: ${COLORS.PRIMARY_COLOR.LIGHTER} !important;
      }

      #home-account-manager.menu-left .infos > div,
      #home-account-manager.menu-left .infos > div.phone a, #home-account-manager.menu-left .infos > div.skype a, #home-account-manager.menu-left .infos > div.email a{
        color: ${COLORS.MAIN_MENU.COLOR};
      }

      .bg-primary-lighter-color,
      .standard-bg-colors:active,
      .standard-bg-colors:focus {
        background-color: ${COLORS.PRIMARY_COLOR.LIGHTER} !important;
      }

      .payment-history-widget.light-color .notice.config-color {
        color: ${COLORS.PAYMENT_HISTORY.NOTICE.COLOR} !important;
      }

      .div-container .div-container-title h2 i,
      .div-container .div-container-title h2 svg {
        color: ${COLORS.CONTAINER.HEADER.ICON_COLOR};
        fill: ${COLORS.CONTAINER.HEADER.ICON_COLOR};
       }

      .div-container.black-title .div-container-title,
      .black-header .table tr th,
      .paymenthistorygraph-mobile {
        background-color: ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR};
      }

      .div-container.primary-color-title .div-container-title,
      .primary-color-header .table tr th {
        background-color: ${COLORS.PRIMARY_COLOR.DEFAULT};
      }

      .div-container.black-title.gradiant .div-container-title {
        background: ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR};
        background: -moz-linear-gradient(-45deg, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 44%, ${
      COLORS.PRIMARY_COLOR.DEFAULT
    } 81.9%, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 82%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 44%, ${
      COLORS.PRIMARY_COLOR.DEFAULT
    } 81.9%, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 82%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 44%, ${
      COLORS.PRIMARY_COLOR.DEFAULT
    } 81.9%, ${COLORS.CONTAINER.HEADER.BACKGROUND_COLOR} 82%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${
          COLORS.CONTAINER.HEADER.BACKGROUND_COLOR
        }', endColorstr='${
      COLORS.CONTAINER.HEADER.BACKGROUND_COLOR
    }', GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
      .div-container .div-container-title h2 .preview-icon,
      .code-input .title > i {
        color: ${COLORS.CONTAINER.HEADER.ICON_COLOR};
      }
      .div-container .div-container-title h2 .icon-svg * {
        fill: ${COLORS.CONTAINER.HEADER.ICON_COLOR};
      }

      #site-header,
      #site-header .utils .notifications .notifications-list {
        background-color: ${COLORS.HEADER.BACKGROUND_COLOR};
        color: ${COLORS.HEADER.COLOR};
      }
      #site-footer {
        background-color: ${COLORS.FOOTER.BACKGROUND_COLOR};
      }

      #site-header .menu-link .icon>span {
        background: ${COLORS.HEADER.BURGER_MENU};
      }


      #site-header .utils .user-img {
        background-color: ${COLORS.HEADER.RANK_STATUS.BACKGROUND_COLOR};
      }
      #site-header .utils .user-img svg .fill {
        fill: ${COLORS.HEADER.RANK_STATUS.FILL};
      }
      #site-header .utils .user-status {
        color: ${COLORS.HEADER.RANK_STATUS.COLOR};
      }
      #site-header .logout:hover {
        color: ${COLORS.HEADER.LINKS_HOVER};
      }

      #profile-header,
      .alert-header,
      .alert-header.alert-sitewide {
        background-color: ${COLORS.HEADER.PAGE_HEADER.BACKGROUND_COLOR};
      }

      #main-navigation nav,
      #offer-tags-bar .offer-tag {
        background-color: ${COLORS.MAIN_MENU.BACKGROUND_COLOR} !important;
      }
      #main-navigation nav ul a:not(.active):hover {
        color: ${COLORS.MAIN_MENU.COLOR_HOVER};
      }
      #main-navigation nav #bot-ul li:not(#toggleMenu) {
        background-color: ${COLORS.MAIN_MENU.BACKGROUND_COLOR_EXTERNAL} !important;
      }
      #main-navigation nav #bot-ul{
        border-color: ${COLORS.MAIN_MENU.BORDER_COLOR_EXTERNAL} !important;
      }      
      #main-navigation nav ul.external-links-ul a {
        color: ${COLORS.MAIN_MENU.COLOR_EXTERNAL};
      }
      #main-navigation nav ul a,
      #main-navigation nav ul.external-links-ul #toggleMenu a i,
      #offer-tags-bar .container-fluid.app-container .offer-tag {
        color: ${COLORS.MAIN_MENU.COLOR};
       }
      #home-account-manager.menu-left {
        background-color: ${COLORS.MAIN_MENU.BACKGROUND_COLOR_ACCOUNT_MANAGER};
      }

      #intercom-container .intercom-launcher-frame,
      #intercom-positioner-tree .intercom-launcher-frame{
        background: ${process.env.NODE_ENV === 'production' ? COLORS.PRIMARY_COLOR.DEFAULT : '#333333'} !important;
      }
    `}</style>
  );
};

Styles.propTypes = {
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
}))(Styles);

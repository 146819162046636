const PersonnalInfo = () => (
  <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2513 35L16.8053 31.554H13.3756C9.02644 31.554 6.31509 28.4908 6.31509 24.1432V12.4124C6.31509 8.06325 9.04104 5 13.3756 5H27.127C31.4762 5 34.1858 8.06325 34.1858 12.4124V24.1432C34.1858 28.4908 31.4762 31.554 27.127 31.554H23.6973L20.2513 35Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M12.5163 31.434V30.0783C12.5163 27.5552 14.5141 24.4075 20.2433 24.4075C25.9872 24.4075 27.985 27.5275 27.985 30.0492V31.434"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      d="M25.1865 15.8558C25.1865 18.5833 22.9763 20.7937 20.2487 20.7937C17.5212 20.7937 15.3093 18.5833 15.3093 15.8558C15.3093 13.1282 17.5212 10.918 20.2487 10.918C22.9763 10.918 25.1865 13.1282 25.1865 15.8558Z"
      fillRule="evenodd"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default PersonnalInfo;

const RankElite = () => (
  <svg height="17px" viewBox="0 0 23 17" width="23px">
    <g fill="none">
      <path
        className="fill"
        d="M18.6734 14.4932l-.4504 1.5924a.4054.4054 0 01-.3897.295H5.1126a.4054.4054 0 01-.3896-.295l-.4505-1.5924h14.401zM20.813 6.8176l-1.9279 6.9189H4.0676l-1.928-6.919a1.2703 1.2703 0 00.5563-.6756l4.6104 2.0676a.4077.4077 0 00.5113-.1554l3.2365-5.2636c.1354.0504.2788.0764.4234.0766.1538.0027.3067-.024.4504-.0788.7838 1.2162 2.4257 3.7658 3.3491 5.2162a.3986.3986 0 00.5045.151l4.4753-2.0114c.1023.2819.2988.5198.5563.6735z"
        fill="#FFF"
      />
      <path
        className="fill"
        d="M18.6734 14.4932l-.4504 1.5924a.4054.4054 0 01-.3897.295H5.1126a.4054.4054 0 01-.3896-.295l-.4505-1.5924h14.401zM2.4009 5.696a.928.928 0 01-.0946.4099.9842.9842 0 01-.2387.304.9144.9144 0 01-.5879.2117.9234.9234 0 11.9212-.9234v-.0023zM12.4032 1.563a.9324.9324 0 01-.7906.9167.3446.3446 0 00-.2455 0 .9234.9234 0 111.0428-.9166h-.0067zM22.4009 5.696a.9212.9212 0 01-1.7455.4121.9009.9009 0 01-.099-.4122.9234.9234 0 011.8445 0zM20.813 6.8176l-1.9279 6.9189H4.0676l-1.928-6.919a1.2703 1.2703 0 00.5563-.6756l4.6104 2.0676a.4077.4077 0 00.5113-.1554l3.2365-5.2636c.1354.0504.2788.0764.4234.0766.1538.0027.3067-.024.4504-.0788.7838 1.2162 2.4257 3.7658 3.3491 5.2162a.3986.3986 0 00.5045.151l4.4753-2.0114c.1023.2819.2988.5198.5563.6735z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default RankElite;

export default {
  'stateList.AL': 'Alabama',
  'stateList.AK': 'Alaska',
  'stateList.AZ': 'Arizona',
  'stateList.AR': 'Arkansas',
  'stateList.CA': 'California',
  'stateList.NC': 'North Carolina',
  'stateList.CT': 'Connecticut',
  'stateList.DE': 'Delaware',
  'stateList.DC': 'District of Columbia',
  'stateList.FL': 'Florida',
  'stateList.GA': 'Georgia',
  'stateList.HI': 'Hawaii',
  'stateList.ID': 'Idaho',
  'stateList.IL': 'Illinois',
  'stateList.IN': 'Indiana',
  'stateList.IA': 'Iowa',
  'stateList.KS': 'Kansas',
  'stateList.KY': 'Kentucky',
  'stateList.LA': 'Louisiana',
  'stateList.ME': 'Maine',
  'stateList.MD': 'Maryland',
  'stateList.MA': 'Massachusetts',
  'stateList.MI': 'Michigan',
  'stateList.Mn': 'Minnesota',
  'stateList.MS': 'Mississippi',
  'stateList.MO': 'Missouri',
  'stateList.MT': 'Montana',
  'stateList.NE': 'Nebraska',
  'stateList.NV': 'Nevada',
  'stateList.NH': 'New Hampshire',
  'stateList.NJ': 'New Jersey',
  'stateList.NM': 'New Mexico',
  'stateList.NY': 'New York',
  'stateList.ND': 'North Dakota',
  'stateList.OH': 'Ohio',
  'stateList.OK': 'Oklahoma',
  'stateList.OR': 'Oregon',
  'stateList.PA': 'Pennsylvania',
  'stateList.RI': 'Rhode Island',
  'stateList.SC': 'South Carolina',
  'stateList.SD': 'South Dakota',
  'stateList.TN': 'Tennessee',
  'stateList.TX': 'Texas',
  'stateList.UT': 'Utah',
  'stateList.VT': 'Vermont',
  'stateList.VA': 'Virginia',
  'stateList.WA': 'Washington',
  'stateList.WV': 'West Virginia',
  'stateList.WI': 'Wisconsin',
  'stateList.WY': 'Wyoming',
};

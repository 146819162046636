export default {
  decemberChallengePage: {
    title: {
      id: 'decemberChallengePage.title',
      description: 'Page title',
      defaultMessage: 'December challenge',
    },
    subscriptionConfirmation: {
      title: {
        id: 'decemberChallengePage.subTitle',
        description: 'Page subtitle',
        defaultMessage: 'Congrats, you are subscribed!',
      },
      description: {
        line1: {
          id: 'decemberChallengePage.',
          description: 'Page description',
          defaultMessage:
            '{fullName}, congratulations you are officially signed up for the December Challenge 2020 and eligible to receive a cash bonus of up to $7,500.',
        },
      },
    },
    challengeLevels: {
      title: {
        id: 'challengeLevels.subTitle',
        description: 'Page subtitle',
        defaultMessage: 'December Challenge Levels',
      },
    },
    termsAndConditions: {
      title: {
        id: 'decemberChallengePage.termsAndConditions.title',
        description: 'Terms and conditions',
        defaultMessage: 'Terms and Conditions',
      },
      points: [
        {
          id: 'decemberChallengePage.termsAndConditions.points.1',
          description: 'First element of terms and conditions',
          defaultMessage:
            "Bonuses will be determined and based upon the affiliate's $1,000+ increase in commissions from the aforementioned reference period (Nov 1 – Nov 30, 2020) and the designated challenge period (Dec 1 – Dec 31, 2020) in CrakRevenue.",
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.2',
          description: 'Second element of terms and conditions',
          defaultMessage: 'Bonuses are predefined by each commission increase level and are NOT cumulative.',
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.3',
          description: 'Third element of terms and conditions',
          defaultMessage:
            'Affiliates who qualify to receive this bonus will see their December cash bonus directly credited to their CrakRevenue account in January 2021.',
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.4',
          description: 'Fourth element of terms and conditions',
          defaultMessage:
            "Participation in this challenge is granted to affiliates & other networks at CrakRevenue's sole discretion and can be revoked at any time, and without any prior notice.",
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.5',
          description: 'Fifth element of terms and conditions',
          defaultMessage: 'Affiliate networks are NOT eligible to participate in the December Challenge.',
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.6',
          description: 'Sixth element of terms and conditions',
          defaultMessage: 'Ad networks are NOT eligible to participate in the December Challenge.',
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.7',
          description: 'Seventh element of terms and conditions',
          defaultMessage:
            'Switching traffic from one account to another for the sole purpose of generating an increase will NOT be tolerated for the December Challenge.',
        },
        {
          id: 'decemberChallengePage.termsAndConditions.points.8',
          description: 'Eight element of terms and conditions',
          defaultMessage:
            'Affiliates found sending traffic generated from spam, content locking and other deceitful tactics will see their account banned immediately.',
        },
      ],
    },
    ready: {
      title: {
        id: 'decemberChallengePage.ready.title',
        description: 'Ready title',
        defaultMessage: 'Ready to win?',
      },
    },
  },
};

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { defaultSettings } from './LiveCamWidgetConsts';

const { WidgetSection } = messages;

const LiveCamWidgetCustomCssSettings = ({ fields: { customcss } }) => (
  <div className="container-fluid config-section" id="css-settings">
    <div>
      <h2 className="primary-color">
        <FormattedMessage {...WidgetSection.cssCustomization.customCss.header.text} />
      </h2>

      <textarea
        className="textarea-css"
        id="custom-css"
        name={customcss.name || 'customcss'}
        onBlur={customcss.onBlur}
        onChange={customcss.onChange}
        onDragStart={customcss.onDragStart}
        onDrop={customcss.onDrop}
        onFocus={customcss.onFocus}
        value={customcss.value}
      />
    </div>

    <div>
      <h2 className="primary-color">
        <FormattedMessage {...WidgetSection.cssCustomization.cssExample.header.text} />
      </h2>

      <textarea
        className="textarea-css example"
        id="custom-css-example"
        name="customcssEx"
        readOnly
        value={defaultSettings.cssSettings.exampleCss}
      />
    </div>
  </div>
);

LiveCamWidgetCustomCssSettings.propTypes = {
  fields: PropTypes.shape({
    customcss: PropTypes.object,
  }),
};

export default injectIntl(
  reduxForm({
    form: 'cssSettingsLC',
    fields: ['customcss'],
    destroyOnUnmount: false,
  })(LiveCamWidgetCustomCssSettings)
);

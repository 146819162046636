export default {
  createAccountContinueGoogle: {
    id: 'createAccount.continueWithGoogleLabel',
    description: 'Sign in with Google',
    defaultMessage: 'Sign in with Google',
  },
  createAccountFirstName: {
    id: 'createAccount.firstNameLabel',
    description: 'First Name',
    defaultMessage: 'First Name',
  },
  createAccountLastName: {
    id: 'createAccount.lastNameLabel',
    description: 'Last Name',
    defaultMessage: 'Last Name',
  },
  createAccountCompany: {
    id: 'createAccount.companyLabel',
    description: "Company's Name",
    defaultMessage: "Company's Name",
  },
  createAccountEmail: {
    id: 'createAccount.emailLabel',
    description: 'Email',
    defaultMessage: 'Email',
  },
  createAccountPassword: {
    id: 'createAccount.passwordLabel',
    description: 'Password',
    defaultMessage: 'Password',
  },
  createAccountImIn: {
    id: 'createAccount.imInLabel',
    description: 'Create Affiliate Account',
    defaultMessage: 'Create Affiliate Account',
  },
  createAccountToS: {
    id: 'createAccount.ToS',
    description: "By signing up, you agree to CrakRevenue's Terms of Use and Privacy Policy",
    defaultMessage: "By signing up, you agree to CrakRevenue's Terms of Use and Privacy Policy",
  },

  createAccountToSLink: {
    id: 'createAccount.ToSLink',
    description: "By signing up, you agree to CrakRevenue's Terms Of Service and Privacy Policy",
    defaultMessage:
      "By signing up, you agree to CrakRevenue's <a href='https://www.crakrevenue.com/tos/' target='_blank'>Terms Of Service</a> and <a href='http://www.crakrevenue.com/privacy/' target='_blank'>Privacy Policy</a>",
  },
  createAccountToSLinkToU: {
    id: 'createAccount.ToSLink',
    description: 'Terms Of Service',
    defaultMessage: 'Terms Of Service',
  },
  createAccountToSLinkPP: {
    id: 'createAccount.ToSLink',
    description: 'Privacy Policy',
    defaultMessage: 'Privacy Policy',
  },

  createAccountAlreadyHaveOneSingInLink: {
    id: 'createAccount.AlreadyHaveOneSingIn',
    description: 'Already have an account? sing in here!',
    defaultMessage: 'Already have an account? {link}',
  },
  createAccountAlreadyHaveOneSingInText: {
    id: 'createAccount.AlreadyHaveOneSingInText',
    description: 'Log in',
    defaultMessage: 'Log in',
  },

  createAccountAdvertiserLink: {
    id: 'createAccount.AdvertiserLink',
    description: 'Want to sign up as an Advertiser? {link}',
    defaultMessage: 'Want to sign up as an Advertiser? {link}',
  },
  createAccountAdvertiserLinkText: {
    id: 'createAccount.AdvertiserLinkText',
    description: 'Sign up',
    defaultMessage: 'Sign up',
  },
  createAccountVpnTitle: {
    id: 'createAccount.VpnTitle',
    description: "We've noticed the use of a VPN or proxy.",
    defaultMessage: "We've noticed the use of a VPN or proxy.",
  },
  createAccountVpnDescription: {
    id: 'createAccount.VpnDescription',
    description: 'We suggest deactivating it before sending in your registration form.',
    defaultMessage: 'We suggest deactivating it before sending in your registration form.',
  },
};

import consts from '../utils/consts';

const {
  ACTIONS: { PROFILE },
} = consts;

const initialState = {
  data: {
    isOverrided: false,
    affUserInfos: {
      first_name: '',
      last_name: '',
      email: '',
      affiliate_id: '0',
    },
    isFetchingUserInfos: false,
    isFetchingFullname: false,
    affInfos: {},
    affStatus: {},
    customProfile: {
      user: {},
      affiliate: {
        payable_to_whom: {
          payable_to: '',
        },
        minimum_payout: {},
        payment_term: '',
      },
    },
    affRank: {},
    signupAnswers: {},
    paymentHistory: {
      next: {
        amount: 0,
        date: '',
      },
      last: {
        amount: 0,
        date: '',
      },
      asReachedMinimumPayout: false,
      asReceivedPayment: true,
    },
    paymentHistoryTimeStamp: 0,
    userProfileTimeStamp: 0,
    isFetchingPaymentHistory: false,
    returnMessage: null,
    settings: {},
    sendgridLists: [],
  },
  isFetching: false,
};

export const profileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PROFILE.REQUEST_PROFILE:
      return {
        ...state,
        isFetching: true,
      };

    case PROFILE.RECEIVE_PROFILE:
      action.data.affiliateManager.email = action.data.affiliateManager.email.replace('@crakmedia.com', '@crakrevenue.com');
      return {
        data: action.data,
        isFetching: false,
      };

    case PROFILE.FAILED_TO_RECEIVE_PROFILE:
      return {
        ...state,
        isFetching: false,
      };

    case PROFILE.REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        isFetching: true,
      };

    case PROFILE.RECEIVE_UPDATE_PROFILE:
      return {
        ...state,
        isFetching: false,
      };

    case PROFILE.FAILED_TO_UPDATE_PROFILE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return {
        ...state,
      };
  }
};

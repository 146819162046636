export const getPaymentMethodIds = (paymentMethods) => {
  const paymentMethodIds = {};
  if (paymentMethods) {
    paymentMethods.forEach((element) => {
      if (element.name.toLowerCase().includes('bitcoin')) {
        paymentMethodIds.paymentMethodBitcoinId = element.id;
      } else if (element.name.toLowerCase().includes('check')) {
        paymentMethodIds.paymentMethodCheckId = element.id;
      } else if (element.name.toLowerCase().includes('wire')) {
        paymentMethodIds.paymentMethodWireId = element.id;
      } else if (element.name.toLowerCase().includes('paxum')) {
        paymentMethodIds.paymentMethodPaxumId = element.id;
      } else if (element.name.toLowerCase().includes('paypal')) {
        paymentMethodIds.paymentMethodPaypalId = element.id;
      } else if (element.name.toLowerCase().includes('ach')) {
        paymentMethodIds.paymentMethodAchId = element.id;
      } else if (element.name.toLowerCase().includes('epayservice')) {
        paymentMethodIds.paymentMethodEpayServiceId = element.id;
      } else if (element.name.toLowerCase().includes('masspay')) {
        paymentMethodIds.paymentMethodMasspayId = element.id;
      }
    });
  }
  return paymentMethodIds;
};

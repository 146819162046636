export default {
  marchChallengePage: {
    title: {
      id: 'marchChallengePage.title',
      description: 'Page title',
      defaultMessage: 'Mad March Contest',
    },
    subscriptionConfirmation: {
      title: {
        id: 'marchChallengePage.subTitle',
        description: 'Page subtitle',
        defaultMessage: 'Congrats, you are signed up!',
      },
      description: {
        line1: {
          id: 'marchChallengePage.',
          description: 'Page description',
          defaultMessage: '{fullName}, congratulations, you are officially signed up for our Mad March Contest.',
        },
      },
    },
    challengeRules: {
      title: {
        id: 'challengeRules.rules',
        description: 'Page rules',
        defaultMessage: 'To play the game, you need to know the rules.',
      },
    },
    termsAndConditions: {
      title: {
        id: 'marchChallengePage.termsAndConditions.title',
        description: 'Terms and conditions',
        defaultMessage: 'Terms and Conditions',
      },
      points: [
        {
          id: 'marchChallengePage.termsAndConditions.points.1',
          description: 'First element of terms and conditions',
          defaultMessage:
            'To be eligible for the draw, affiliates must reach a minimum payout of $100 on either MyFreeCams, Jerkmate, Dating Smartlink, or SexEmulator.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.2',
          description: 'Second element of terms and conditions',
          defaultMessage: 'Reaching the $100 threshold will automatically enter the affiliate into the draw.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.3',
          description: 'Third element of terms and conditions',
          defaultMessage: 'Affiliate networks are NOT eligible to participate in the Mad March Contest.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.4',
          description: 'Fourth element of terms and conditions',
          defaultMessage: 'Ad networks are NOT eligible to participate in the Mad March Contest.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.5',
          description: 'Fifth element of terms and conditions',
          defaultMessage:
            'Switching traffic from one account to another for the sole purpose of generating an increase will NOT be tolerated for the Mad March Contest.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.6',
          description: 'Sixth element of terms and conditions',
          defaultMessage:
            'Affiliates found sending traffic generated from spam, content locking, and other deceitful tactics or otherwise violating Crakrevenue’s terms of service will see their account immediately banned.',
        },
        {
          id: 'marchChallengePage.termsAndConditions.points.7',
          description: 'Seventh element of terms and conditions',
          defaultMessage: 'The official draw will be performed and recorded live on social media on April 3, 2021.',
        },
      ],
    },
    ready: {
      title: {
        id: 'marchChallengePage.ready.title',
        description: 'Ready title',
        defaultMessage: 'Ready to win?',
      },
    },
  },
};

import consts from './consts';

const { EWALLETLEGACY } = consts.REGEXES;

export default class EpayWalletConverter {
  static convertLegacyWallet(walletNumber) {
    if (walletNumber?.match(EWALLETLEGACY)) {
      return walletNumber.match(/.{1,4}/g).join('-');
    }
    return walletNumber;
  }
}

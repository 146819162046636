const PaymentGroupIcon = () => (
  <svg fill="none" height="23" viewBox="0 0 21 23" width="21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3636 5.31824H9.63636C8.73262 5.31824 8 6.05086 8 6.9546V20.0455C8 20.9492 8.73262 21.6819 9.63636 21.6819H18.3636C19.2674 21.6819 20 20.9492 20 20.0455V6.9546C20 6.05086 19.2674 5.31824 18.3636 5.31824Z"
      stroke="#252525"
    />
    <path
      d="M13 5V2.9546C13 2.05086 12.2674 1.31824 11.3636 1.31824H2.63636C1.73263 1.31824 1 2.05086 1 2.9546V16.0455C1 16.9492 1.73263 17.6819 2.63636 17.6819H8"
      stroke="#252525"
    />
    <path d="M11.8182 13.5L16.1818 9.13635" stroke="#252525" strokeLinecap="round" />
    <path
      d="M12.3636 10.2273C12.6649 10.2273 12.9091 9.98305 12.9091 9.68181C12.9091 9.38056 12.6649 9.13635 12.3636 9.13635C12.0624 9.13635 11.8182 9.38056 11.8182 9.68181C11.8182 9.98305 12.0624 10.2273 12.3636 10.2273Z"
      stroke="#252525"
    />
    <path
      d="M15.6363 13.5001C15.9376 13.5001 16.1818 13.2559 16.1818 12.9546C16.1818 12.6534 15.9376 12.4092 15.6363 12.4092C15.3351 12.4092 15.0909 12.6534 15.0909 12.9546C15.0909 13.2559 15.3351 13.5001 15.6363 13.5001Z"
      stroke="#252525"
    />
    <path d="M10.7273 16.7728H17.2727M10.7273 18.9546H17.2727" stroke="#252525" strokeLinecap="round" />
    <path d="M3.72729 11.7728H7.27275M3.72729 13.9546H7.27275" stroke="#252525" strokeLinecap="round" />
    <path d="M3.72729 7.77283H7.27275M3.72729 9.95464H7.27275" stroke="#252525" strokeLinecap="round" />
  </svg>
);

export default PaymentGroupIcon;

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText, LoadingPage } from '../../components/reusables';

import validate from './validatePasswordResetForm';

const PasswordResetForm = (props) => {
  const { fields, handleSubmit, isDisabled } = props;

  return (
    <div id="password-reset-form">
      <div className={isDisabled ? 'greyed-out row' : 'row'}>
        <form action="#" className="col-md-12" method="post" onSubmit={handleSubmit}>
          <div className="row">
            <InputText
              autoFocus
              className="col-md-12"
              disabled={isDisabled}
              field={fields.password}
              id="password"
              label={messages.userAndCompanyNewPasswordLabel}
              tabIndex={10}
              type="password"
            />
          </div>
          <div className="row">
            <InputText
              className="col-md-12"
              disabled={isDisabled}
              field={fields.passwordConfirmation}
              id="passwordConfirmation"
              label={messages.userAndCompanyNewPasswordConfirmationLabel}
              tabIndex={20}
              type="password"
            />
          </div>

          <div className="row">
            <div className="col-md-12 form-buttons text-right">
              <button className="waves-effect waves-light btn" disabled={isDisabled}>
                <FormattedMessage {...messages.passwordResetChangeButton} />
              </button>
            </div>
          </div>
        </form>
      </div>
      {isDisabled ? (
        <div className="is-loading">
          <LoadingPage fullScreen={false} />
        </div>
      ) : null}
    </div>
  );
};

PasswordResetForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(
  {
    form: 'passwordResetForm',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['password', 'passwordConfirmation'],
    validate,
  },
  () => ({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
  })
)(PasswordResetForm);

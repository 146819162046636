const JerkmateIcon = () => (
  <svg fill="none" height="14" viewBox="0 0 20 14" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.43 4.21997C0.62 4.34997 0 5.04997 0 5.89997C0 6.74997 0.62 7.44997 1.43 7.57997V4.21997Z" fill="white" />
    <path
      d="M17.9502 4.21997V7.57997C18.7602 7.44997 19.3802 6.74997 19.3802 5.89997C19.3802 5.04997 18.7602 4.34997 17.9502 4.21997Z"
      fill="white"
    />
    <path
      d="M9.68994 1C9.68994 1 9.72994 1 9.74994 1C9.96994 1.02 10.1599 1.14 10.2799 1.32L10.5799 1.79H11.1299H13.1099C14.9499 1.79 16.4499 3.29 16.4499 5.13V6.54C16.4499 7.43 16.1099 8.27 15.4799 8.9C14.8499 9.54 14.0099 9.89 13.1199 9.89H9.68994H8.85994H8.60994H7.62994H6.25994C5.36994 9.89 4.52994 9.54 3.89994 8.9C3.26994 8.26 2.91994 7.42 2.91994 6.54V5.13C2.91994 3.29 4.42994 1.79 6.26994 1.79H8.23994H8.78994L9.08994 1.32C9.20994 1.13 9.39994 1.01 9.61994 1H9.67994M9.68994 0C9.68994 0 9.58994 0 9.54994 0C9.00994 0.05 8.53994 0.35 8.25994 0.78H6.28994C6.28994 0.78 6.28994 0.78 6.27994 0.78C3.89994 0.78 1.93994 2.73 1.93994 5.11V6.52C1.93994 8.91 3.87994 10.87 6.26994 10.87H7.63994H8.86994H9.69994H13.1299C15.5199 10.87 17.4699 8.9 17.4599 6.52V5.11C17.4599 2.72 15.4999 0.78 13.1099 0.78C13.1099 0.78 13.1099 0.78 13.0999 0.78H11.1299C10.8499 0.35 10.3799 0.05 9.83994 0C9.78994 0 9.73994 0 9.69994 0L9.68994 0Z"
      fill="white"
    />
    <path
      d="M6.87988 2.52002V3.52002L12.4999 3.54002C13.5999 3.54002 14.4999 4.44002 14.4999 5.55002V6.18002C14.4999 7.28002 13.5999 8.18002 12.4899 8.18002L6.87988 8.16002C5.77988 8.16002 4.87988 7.26002 4.87988 6.15002V5.52002C4.87988 4.42002 5.77988 3.53002 6.87988 3.53002V2.53002M6.87988 2.52002C5.22988 2.52002 3.87988 3.86002 3.87988 5.51002V6.14002C3.87988 7.79002 5.21988 9.14002 6.86988 9.15002L12.4799 9.17002C14.1299 9.17002 15.4799 7.82002 15.4799 6.18002V5.55002C15.4799 3.90002 14.1399 2.55002 12.4899 2.54002L6.87988 2.52002Z"
      fill="white"
    />
    <path
      d="M6.79004 13.41C6.79004 13.41 7.68004 12.35 9.69004 12.35C11.52 12.35 12.56 13.41 12.56 13.41"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default JerkmateIcon;

export default {
  statisticsTitle: {
    id: 'statistics.title',
    description: 'Statistics',
    defaultMessage: 'Statistics',
  },
  statisticsDateRange: {
    id: 'statistics.dateRange',
    description: 'Date Range',
    defaultMessage: 'Date Range',
  },
  statisticsLandingPage: {
    id: 'statistics.landingPage',
    description: 'Landing Page',
    defaultMessage: 'Landing Page',
  },
  statisticsDatePresets: {
    id: 'statistics.datePresets',
    description: 'Date presets',
    defaultMessage: 'Date presets',
  },
  statisticsColumns: {
    'AdCampaign.name': {
      id: 'statistics.statisticsColumnsAdCampaignName',
      description: 'Campaign Name',
      defaultMessage: 'Campaign Name',
    },
    'Browser.display_name': {
      id: 'statistics.statisticsColumnsBrowserDisplayName',
      description: 'Browser / Device',
      defaultMessage: 'Browser / Device',
    },
    'Browser.id': {
      id: 'statistics.statisticsColumnsBrowserId',
      description: 'Browser / Device ID',
      defaultMessage: 'Browser / Device ID',
    },
    'Category.id': {
      id: 'statistics.statisticsColumnsCategoryId',
      description: 'Category Id',
      defaultMessage: 'Category Id',
    },
    'Category.name': {
      id: 'statistics.statisticsColumnsCategoryName',
      description: 'Vertical',
      defaultMessage: 'Vertical',
    },
    'Country.name': {
      id: 'statistics.statisticsColumnsCountryName',
      description: 'Country',
      defaultMessage: 'Country',
    },
    'Offer.name': {
      id: 'statistics.statisticsColumnsOfferName',
      description: 'Offer',
      defaultMessage: 'Offer',
    },
    'OfferFile.display': {
      id: 'statistics.statisticsColumnsOfferFileDisplay',
      description: 'Creative ID',
      defaultMessage: 'Creative ID',
    },
    'OfferUrl.name': {
      id: 'statistics.statisticsColumnsOfferUrlName',
      description: 'Landing Page',
      defaultMessage: 'Landing Page',
    },
    'OfferUrl.preview_url': {
      id: 'statistics.statisticsColumnsPreviewUrl',
      description: 'Preview Url',
      defaultMessage: 'Preview Url',
    },
    'Stat.ad_campaign_creative_id': {
      id: 'statistics.statisticsColumnsAdCampaignCreativeId',
      description: 'Ad campaign creative Id',
      defaultMessage: 'Ad campaign creative Id',
    },
    'Stat.ad_campaign_id': {
      id: 'statistics.statisticsColumnsAdCampaignId',
      description: 'Campaign ID',
      defaultMessage: 'Campaign ID',
    },
    'Stat.affiliate_info': {
      id: 'statistics.statisticsColumnsAffiliateInfo',
      description: 'Sub ID',
      defaultMessage: 'Sub ID',
    },
    'Stat.affiliate_info1': {
      id: 'statistics.statisticsColumnsAffiliateInfo1',
      description: 'Sub ID 1',
      defaultMessage: 'Sub ID 1',
    },
    'Stat.affiliate_info2': {
      id: 'statistics.statisticsColumnsAffiliateInfo2',
      description: 'Sub ID 2',
      defaultMessage: 'Sub ID 2',
    },
    'Stat.affiliate_info3': {
      id: 'statistics.statisticsColumnsAffiliateInfo3',
      description: 'Sub ID 3',
      defaultMessage: 'Sub ID 3',
    },
    'Stat.affiliate_info4': {
      id: 'statistics.statisticsColumnsAffiliateInfo4',
      description: 'Sub ID 4',
      defaultMessage: 'Sub ID 4',
    },
    'Stat.affiliate_info5': {
      id: 'statistics.statisticsColumnsAffiliateInfo5',
      description: 'Sub ID 5',
      defaultMessage: 'Sub ID 5',
    },
    'Stat.clicks': {
      id: 'statistics.statisticsColumnsStatClicks',
      description: 'Clicks',
      defaultMessage: 'Clicks',
    },
    'Stat.conversions': {
      id: 'statistics.statisticsColumnsStatConversion',
      description: 'All Conversions',
      defaultMessage: 'All Conversions',
    },
    'Stat.paidConversions': {
      id: 'statistics.statisticsColumnsStatPaidConversions',
      description: 'Paid Conversions',
      defaultMessage: 'Paid Conversions',
    },
    'Stat.cpa': {
      id: 'statistics.statisticsColumnsStatCPA',
      description: 'CPA',
      defaultMessage: 'CPA',
    },
    'Stat.cpc': {
      id: 'statistics.statisticsColumnsStatCPC',
      description: 'CPC',
      defaultMessage: 'CPC',
    },
    'Stat.cpm': {
      id: 'statistics.statisticsColumnsStatCPM',
      description: 'CPM',
      defaultMessage: 'CPM',
    },
    'Stat.ctr': {
      id: 'statistics.statisticsColumnsStatCTR',
      description: 'CTR',
      defaultMessage: 'CTR',
    },
    'Stat.date': {
      id: 'statistics.statisticsColumnsStatDate',
      description: 'Date',
      defaultMessage: 'Date',
    },
    'Stat.erpc': {
      id: 'statistics.statisticsColumnsStatERPC',
      description: 'EPC',
      defaultMessage: 'EPC',
    },
    'Stat.hour': {
      id: 'statistics.statisticsColumnsStatHour',
      description: 'Hour',
      defaultMessage: 'Hour',
    },
    'Stat.impressions': {
      id: 'statistics.statisticsColumnsStatImpression',
      description: 'Impressions',
      defaultMessage: 'Impressions',
    },
    'Stat.ltr': {
      id: 'statistics.statisticsColumnsStatLTR',
      description: 'CR',
      defaultMessage: 'CR',
    },
    'Stat.gross_clicks': {
      id: 'statistics.statisticsColumnsStatGrossClicks',
      description: 'Gross Clicks',
      defaultMessage: 'Gross Clicks',
    },
    'Stat.month': {
      id: 'statistics.statisticsColumnsStatMonth',
      description: 'Month',
      defaultMessage: 'Month',
    },
    'Stat.offer_file_id': {
      id: 'statistics.statisticsColumnsStatOfferFileId',
      description: 'Offer file id',
      defaultMessage: 'Offer file id',
    },
    'Stat.offer_id': {
      id: 'statistics.statisticsColumnsStatOfferId',
      description: 'Offer ID',
      defaultMessage: 'Offer ID',
    },
    'Stat.offer_url_id': {
      id: 'statistics.statisticsColumnsStatOfferUrlId',
      description: 'Landing page id',
      defaultMessage: 'Landing page id',
    },
    'Stat.payout': {
      id: 'statistics.statisticsColumnsStatPayout',
      description: 'Payout',
      defaultMessage: 'Payout',
    },
    'Stat.payout_type': {
      id: 'statistics.statisticsColumnsStatPayoutType',
      description: 'Payout Type',
      defaultMessage: 'Payout Type',
    },
    'Stat.week': {
      id: 'statistics.statisticsColumnsStatWeek',
      description: 'Week',
      defaultMessage: 'Week',
    },
    'Stat.year': {
      id: 'statistics.statisticsColumnsStatYear',
      description: 'Year',
      defaultMessage: 'Year',
    },
    'Offer.Brand': {
      id: 'statistics.statisticsColumnsBrand',
      description: 'Brand',
      defaultMessage: 'Brand',
    },
    'Offer.Vertical': {
      id: 'statistics.statisticsColumnsVertical',
      description: 'Vertical',
      defaultMessage: 'Vertical',
    },
    'Offer.Payout': {
      id: 'statistics.statisticsColumnsPayout',
      description: 'Payout',
      defaultMessage: 'Payout',
    },
    'Offer.Niche': {
      id: 'statistics.statisticsColumnsNiche',
      description: 'Niche',
      defaultMessage: 'Niche',
    },
    'Goal.name': {
      id: 'statistics.statisticsColumnsGoalName',
      description: 'Goal Name',
      defaultMessage: 'Goal Name',
    },
    'Stat.source': {
      id: 'statistics.statisticsColumnsStatSource',
      description: 'Source',
      defaultMessage: 'Source',
    },
  },
  statisticsFilters: {
    filterGoals: {
      id: 'statistics.statisticsFiltersGoals',
      description: 'Goals',
      defaultMessage: 'Goals',
    },
    filterCountry: {
      id: 'statistics.statisticsFiltersCountry',
      description: 'Country',
      defaultMessage: 'Country',
    },
    filterNiche: {
      id: 'statistics.statisticsFiltersNiche',
      description: 'Niche',
      defaultMessage: 'Niche',
    },
    filterBrowser: {
      id: 'statistics.statisticsFiltersBrowser',
      description: 'Browser / Device',
      defaultMessage: 'Browser / Device',
    },
    filterSubId1: {
      id: 'statistics.statisticsFiltersAffiliateInfo1',
      description: 'Sub ID 1',
      defaultMessage: 'Sub ID 1',
    },
    filterSubId2: {
      id: 'statistics.statisticsFiltersAffiliateInfo2',
      description: 'Sub ID 2',
      defaultMessage: 'Sub ID 2',
    },
    filterSubId3: {
      id: 'statistics.statisticsFiltersAffiliateInfo3',
      description: 'Sub ID 3',
      defaultMessage: 'Sub ID 3',
    },
    filterSubId4: {
      id: 'statistics.statisticsFiltersAffiliateInfo4',
      description: 'Sub ID 4',
      defaultMessage: 'Sub ID 4',
    },
    filterSubId5: {
      id: 'statistics.statisticsFiltersAffiliateInfo5',
      description: 'Sub ID 5',
      defaultMessage: 'Sub ID 5',
    },
    filterSource: {
      id: 'statistics.statisticsFiltersSource',
      description: 'Source',
      defaultMessage: 'Source',
    },
    filterErpc: {
      id: 'statistics.statisticsFiltersERPC',
      description: 'EPC',
      defaultMessage: 'EPC',
    },
    filterConversions: {
      id: 'statistics.statisticsFiltersConversions',
      description: 'Conversions',
      defaultMessage: 'Conversions',
    },
    filterCtr: {
      id: 'statistics.statisticsFiltersCTR',
      description: 'CTR',
      defaultMessage: 'CTR',
    },
    filterPayout: {
      id: 'statistics.statisticsFiltersPayout',
      description: 'Payout',
      defaultMessage: 'Payout',
    },
    filterClicks: {
      id: 'statistics.statisticsFiltersClicks',
      description: 'Clicks',
      defaultMessage: 'Clicks',
    },
    filterImpressions: {
      id: 'statistics.statisticsFiltersImpressions',
      description: 'Impressions',
      defaultMessage: 'Impressions',
    },
    filterPayoutType: {
      id: 'statistics.statisticsFiltersPayoutType',
      description: 'Payout Type',
      defaultMessage: 'Payout Type',
    },
  },
  statisticsExportCSV: {
    id: 'statistics.exportCSV',
    description: 'Export CSV',
    defaultMessage: 'Export CSV',
  },
  statisticsGraph: {
    id: 'statistics.graph',
    description: 'Graph',
    defaultMessage: 'Graph',
  },
  statisticsSaveReport: {
    id: 'statistics.saveReport',
    description: 'Save report',
    defaultMessage: 'Save report',
  },
  statisticsSavedReportDeleted: {
    id: 'statistics.savedReportDeleted',
    description: 'Saved report deleted',
    defaultMessage: 'Saved report deleted',
  },
  statisticsErrorDeletingSavedReport: {
    id: 'statistics.errorDeletingSavedReport',
    description: 'Error deleting Saved reports',
    defaultMessage: 'Error deleting Saved reports',
  },
  statisticsAreYouSureDeleteModal: {
    id: 'statistics.areYouSureDeleteModal',
    description: 'Are you sure you want to delete this saved report?',
    defaultMessage: 'Are you sure you want to delete this saved report?',
  },
  statisticsMoreInfosStatistics: {
    id: 'statistics.moreInfosStatistics',
    description: 'Click here for more informations on the statistics',
    defaultMessage: 'Click here for more informations on the statistics',
  },
  statisticsModalTabStatisticsOverview: {
    id: 'statistics.modalTabStatisticsOverview',
    description: 'Statistics Overview',
    defaultMessage: 'Statistics Overview',
  },
  statisticsModalTabExtraEarnings: {
    id: 'statistics.modalTabExtraEarnings',
    description: 'Extra Earnings',
    defaultMessage: 'Extra Earnings',
  },
  statisticsModalTabStatsDrillDown: {
    id: 'statistics.modalTabStatsDrillDown',
    description: 'Stats Drill Down',
    defaultMessage: 'Stats Drill Down',
  },
  statisticsStartDate: {
    id: 'statistics.startDate',
    description: 'Start date',
    defaultMessage: 'Start date',
  },
  statisticsEndDate: {
    id: 'statistics.dndDate',
    description: 'End date',
    defaultMessage: 'End date',
  },
  statisticsSlowFieldsWarning: {
    id: 'statistics.slowFieldsWarning',
    description:
      'Due to their high quantity of unique values, please note that fields like {fields} will slow down statistics queries.',
    defaultMessage:
      'Due to their high quantity of unique values, please note that fields like {fields} will slow down statistics queries.',
  },
};

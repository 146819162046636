import { getNewToken } from '../../../utils/helpers';

const token = `c-${getNewToken()}`;

const LiveCamWidgetSkin1Icon = () => (
  <svg viewBox="0 0 806.46 604.79" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id={`lg-${token}-1`} x1="403.27" x2="403.27" y1="583.11" y2="492.4">
        <stop offset="0" stopColor="#1e8202" />
        <stop offset="1" stopColor="#22ad01" />
      </linearGradient>
    </defs>
    <title>Skin 1</title>
    <rect fill="#757575" height="604.79" rx="22.68" ry="22.68" width="806.39" x="0.07" />
    <path
      d="M563.86,481.28,484.4,461.41l-4.58-18.35c54.81-9.23,83-25.66,84.27-26.38a10.38,10.38,0,0,0,5-8.26,10.53,10.53,0,0,0-4-8.88c-.37-.28-37.43-30.9-37.43-137,0-89.46-20.93-134.81-62.22-134.81h-6.12C444.48,113.37,433.3,107,403.27,107c-39,0-124.44,39.17-124.44,155.55,0,106.06-37.07,136.68-37.34,136.88a10.37,10.37,0,0,0,.87,17.18c1.25.74,29.19,17.25,84.33,26.46l-4.58,18.31-79.45,19.87a116.48,116.48,0,0,0-87.82,123.51H651.69A116.45,116.45,0,0,0,563.86,481.28Z"
      fill="#e7eced"
    />
    <path d="M22.65,0H783.35A22.65,22.65,0,0,1,806,22.65V132.5a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V22.65A22.65,22.65,0,0,1,22.65,0Z" />
    <rect height="90.71" rx="8.5" ry="8.5" style={{ fill: `url(#lg-${token}-1)` }} width="575.43" x="115.55" y="492.4" />
    <path
      d="M55.17,22.67,61.38,40.2l6.17-17.53h6.54V46.84h-5V40.23l.5-11.4-6.53,18H59.65l-6.51-18,.5,11.38v6.61h-5V22.67Z"
      fill="#fff"
    />
    <path
      d="M77.38,37.69a10.57,10.57,0,0,1,1-4.76,7.62,7.62,0,0,1,3-3.24,8.7,8.7,0,0,1,4.5-1.14,8.18,8.18,0,0,1,5.93,2.22,9,9,0,0,1,2.57,6l0,1.23a9.36,9.36,0,0,1-2.31,6.63,8.94,8.94,0,0,1-12.39,0,9.51,9.51,0,0,1-2.32-6.77Zm4.8.34a6.73,6.73,0,0,0,1,3.92,3.46,3.46,0,0,0,5.48,0,7.4,7.4,0,0,0,1-4.29,6.68,6.68,0,0,0-1-3.89,3.18,3.18,0,0,0-2.75-1.38,3.13,3.13,0,0,0-2.73,1.38A7.47,7.47,0,0,0,82.18,38Z"
      fill="#fff"
    />
    <path
      d="M96.61,37.73A10.8,10.8,0,0,1,98.49,31a6.13,6.13,0,0,1,5.16-2.49,5.5,5.5,0,0,1,4.33,2V21.34h4.81v25.5h-4.33l-.23-1.91a5.65,5.65,0,0,1-4.62,2.24,6.1,6.1,0,0,1-5.09-2.5A11.2,11.2,0,0,1,96.61,37.73Zm4.79.35a7.16,7.16,0,0,0,.88,3.88,2.87,2.87,0,0,0,2.56,1.35A3.22,3.22,0,0,0,108,41.43v-7.1a3.16,3.16,0,0,0-3.11-1.88Q101.41,32.45,101.4,38.08Z"
      fill="#fff"
    />
    <path
      d="M124.78,47.17a8.79,8.79,0,0,1-6.43-2.42,8.61,8.61,0,0,1-2.49-6.46v-.46a10.79,10.79,0,0,1,1-4.84,7.76,7.76,0,0,1,3-3.29,8.33,8.33,0,0,1,4.38-1.15,7.44,7.44,0,0,1,5.8,2.32,9.46,9.46,0,0,1,2.12,6.59v2H120.73a4.44,4.44,0,0,0,1.4,2.82,4.24,4.24,0,0,0,3,1.07,5.19,5.19,0,0,0,4.34-2l2.36,2.64a7.28,7.28,0,0,1-2.93,2.38A9.55,9.55,0,0,1,124.78,47.17Zm-.55-14.76a3,3,0,0,0-2.31,1,4.94,4.94,0,0,0-1.14,2.77h6.67v-.38a3.57,3.57,0,0,0-.86-2.48A3.11,3.11,0,0,0,124.23,32.41Z"
      fill="#fff"
    />
    <path d="M140,46.84h-4.81V21.34H140Z" fill="#fff" />
    <path d="M163.88,46.84h-5l-9.7-15.9v15.9h-5V22.67h5l9.71,15.94V22.67h5Z" fill="#fff" />
    <path
      d="M178.32,46.84a5.38,5.38,0,0,1-.48-1.61,5.78,5.78,0,0,1-4.53,1.94,6.43,6.43,0,0,1-4.38-1.52,4.92,4.92,0,0,1-1.73-3.86,5.08,5.08,0,0,1,2.11-4.38,10.42,10.42,0,0,1,6.12-1.54h2.21v-1a2.92,2.92,0,0,0-.64-2,2.52,2.52,0,0,0-2-.75,2.84,2.84,0,0,0-1.9.58,2,2,0,0,0-.69,1.59h-4.8a4.8,4.8,0,0,1,1-2.88,6.39,6.39,0,0,1,2.72-2.09,10,10,0,0,1,3.95-.75,7.83,7.83,0,0,1,5.27,1.66,5.85,5.85,0,0,1,1.95,4.69v7.79a8.5,8.5,0,0,0,.72,3.87v.28Zm-4-3.34a4.08,4.08,0,0,0,2-.47,3.09,3.09,0,0,0,1.33-1.27V38.67h-1.79c-2.41,0-3.68.83-3.84,2.49l0,.29a1.88,1.88,0,0,0,.64,1.47A2.42,2.42,0,0,0,174.35,43.5Z"
      fill="#fff"
    />
    <path
      d="M190.64,28.88l.15,2A6.3,6.3,0,0,1,196,28.55a4.8,4.8,0,0,1,4.77,2.74,6.15,6.15,0,0,1,5.39-2.74,5.34,5.34,0,0,1,4.35,1.7,7.82,7.82,0,0,1,1.43,5.12V46.84h-4.82V35.39a3.49,3.49,0,0,0-.59-2.24,2.67,2.67,0,0,0-2.11-.7,3,3,0,0,0-3,2.06l0,12.33h-4.8V35.4a3.39,3.39,0,0,0-.62-2.26,2.68,2.68,0,0,0-2.09-.69,3.1,3.1,0,0,0-2.95,1.69v12.7h-4.8v-18Z"
      fill="#fff"
    />
    <path
      d="M223.84,47.17a8.79,8.79,0,0,1-6.43-2.42,8.6,8.6,0,0,1-2.48-6.46v-.46A10.79,10.79,0,0,1,216,33a7.72,7.72,0,0,1,3-3.29,8.27,8.27,0,0,1,4.37-1.15,7.44,7.44,0,0,1,5.8,2.32,9.46,9.46,0,0,1,2.12,6.59v2H219.79a4.49,4.49,0,0,0,1.4,2.82,4.25,4.25,0,0,0,3,1.07,5.18,5.18,0,0,0,4.33-2l2.36,2.64a7.32,7.32,0,0,1-2.92,2.38A9.6,9.6,0,0,1,223.84,47.17Zm-.55-14.76a3,3,0,0,0-2.31,1,5.08,5.08,0,0,0-1.14,2.77h6.67v-.38a3.57,3.57,0,0,0-.86-2.48A3.11,3.11,0,0,0,223.29,32.41Z"
      fill="#fff"
    />
    <path
      d="M659.46,38.46q0,4.29-1.6,6.47A5.66,5.66,0,0,1,653,47.11,5.74,5.74,0,0,1,648.11,45q-1.64-2.13-1.68-6.3V35.25q0-4.29,1.61-6.44a5.72,5.72,0,0,1,4.9-2.15,5.79,5.79,0,0,1,4.88,2.09c1.07,1.4,1.62,3.48,1.64,6.26Zm-3.32-3.72a8.44,8.44,0,0,0-.76-4.11,2.94,2.94,0,0,0-4.83-.07,7.86,7.86,0,0,0-.81,3.87v4.51a8.6,8.6,0,0,0,.78,4.15A2.61,2.61,0,0,0,653,44.46a2.56,2.56,0,0,0,2.36-1.26,8.18,8.18,0,0,0,.81-4Z"
      fill="#fff"
    />
    <path
      d="M675.38,38.46q0,4.29-1.61,6.47a5.66,5.66,0,0,1-4.89,2.18A5.74,5.74,0,0,1,664,45c-1.09-1.42-1.64-3.52-1.67-6.3V35.25q0-4.29,1.6-6.44a6.7,6.7,0,0,1,9.78-.06c1.07,1.4,1.62,3.48,1.65,6.26Zm-3.33-3.72a8.44,8.44,0,0,0-.76-4.11,2.94,2.94,0,0,0-4.83-.07,7.76,7.76,0,0,0-.8,3.87v4.51a8.6,8.6,0,0,0,.78,4.15,2.86,2.86,0,0,0,4.81.11,8.08,8.08,0,0,0,.8-4Z"
      fill="#fff"
    />
    <path
      d="M708.05,44.26a6.6,6.6,0,0,1-3,2.13,12.32,12.32,0,0,1-4.35.72A8.54,8.54,0,0,1,696.28,46a7.62,7.62,0,0,1-3-3.25,11.36,11.36,0,0,1-1.09-4.93V36.25a10.54,10.54,0,0,1,2.16-7,7.5,7.5,0,0,1,6-2.54,7.93,7.93,0,0,1,5.28,1.65A7.15,7.15,0,0,1,708,33.05h-3.39a3.81,3.81,0,0,0-4.17-3.6,4.11,4.11,0,0,0-3.54,1.67A8.62,8.62,0,0,0,695.69,36v1.53a8.15,8.15,0,0,0,1.36,5,4.49,4.49,0,0,0,3.78,1.78,5,5,0,0,0,3.78-1.2v-3.9h-4.1V36.63h7.54Z"
      fill="#fff"
    />
    <path
      d="M718.05,47.11a6.84,6.84,0,0,1-5.12-2,7.19,7.19,0,0,1-2-5.29v-.41a8.86,8.86,0,0,1,.85-4,6.47,6.47,0,0,1,2.4-2.71,6.33,6.33,0,0,1,3.44-1,5.82,5.82,0,0,1,4.67,1.93A8.17,8.17,0,0,1,724,39.16V40.5h-9.67a4.45,4.45,0,0,0,1.23,2.9,3.65,3.65,0,0,0,2.7,1.06A4.5,4.5,0,0,0,722,42.62l1.79,1.71a5.91,5.91,0,0,1-2.37,2.05A7.4,7.4,0,0,1,718.05,47.11Zm-.4-12.67a2.8,2.8,0,0,0-2.21,1,4.91,4.91,0,0,0-1.07,2.66h6.33v-.24a4,4,0,0,0-.89-2.53A2.81,2.81,0,0,0,717.65,34.44Z"
      fill="#fff"
    />
    <path
      d="M729.64,32.05l.1,1.71a5.32,5.32,0,0,1,4.3-2q4.62,0,4.71,5.3v9.77h-3.33V37.26a3.09,3.09,0,0,0-.6-2.09,2.57,2.57,0,0,0-2-.67,3.21,3.21,0,0,0-3,1.81V46.84h-3.32V32.05Z"
      fill="#fff"
    />
    <path
      d="M741.51,39.33a8.83,8.83,0,0,1,1.58-5.48,5.08,5.08,0,0,1,4.26-2.08,4.85,4.85,0,0,1,3.8,1.64V25.84h3.32v21h-3l-.16-1.53a4.91,4.91,0,0,1-4,1.8A5.06,5.06,0,0,1,743.11,45,9.12,9.12,0,0,1,741.51,39.33Zm3.32.29a6.13,6.13,0,0,0,.87,3.52,2.81,2.81,0,0,0,2.47,1.27,3.15,3.15,0,0,0,3-1.82V36.27a3.1,3.1,0,0,0-3-1.77,2.82,2.82,0,0,0-2.48,1.27A6.83,6.83,0,0,0,744.83,39.62Z"
      fill="#fff"
    />
    <path
      d="M764.45,47.11a6.86,6.86,0,0,1-5.12-2,7.19,7.19,0,0,1-2-5.29v-.41a8.86,8.86,0,0,1,.85-4,6.47,6.47,0,0,1,2.4-2.71,6.34,6.34,0,0,1,3.45-1,5.83,5.83,0,0,1,4.67,1.93,8.22,8.22,0,0,1,1.64,5.46V40.5h-9.66a4.44,4.44,0,0,0,1.22,2.9,3.69,3.69,0,0,0,2.7,1.06,4.5,4.5,0,0,0,3.72-1.84l1.79,1.71a5.91,5.91,0,0,1-2.37,2.05A7.4,7.4,0,0,1,764.45,47.11Zm-.4-12.67a2.81,2.81,0,0,0-2.21,1,5,5,0,0,0-1.07,2.66h6.33v-.24a4,4,0,0,0-.89-2.53A2.81,2.81,0,0,0,764.05,34.44Z"
      fill="#fff"
    />
    <path
      d="M780.71,35.08a8.61,8.61,0,0,0-1.36-.11,3,3,0,0,0-3.07,1.75V46.84H773V32.05h3.18l.08,1.65a3.75,3.75,0,0,1,3.33-1.93,3.05,3.05,0,0,1,1.18.2Z"
      fill="#fff"
    />
    <path d="M229.81,552.27V523.19h19.54v5H235.47v7.49H247v4.59H235.47v12Z" fill="#fff" />
    <path
      d="M253.32,552.27V523.19h12.86a8.28,8.28,0,0,1,3.71.84,9.68,9.68,0,0,1,2.92,2.21,10.66,10.66,0,0,1,1.93,3.12,9.4,9.4,0,0,1,.69,3.5A9.71,9.71,0,0,1,274,538a8.09,8.09,0,0,1-3.83,3.33l6.64,10.94h-6.35l-5.94-9.75H259v9.75Zm5.65-14.7h7.09a3.06,3.06,0,0,0,1.45-.37,3.75,3.75,0,0,0,1.17-1,5.13,5.13,0,0,0,.78-1.49,5.81,5.81,0,0,0,.28-1.84,5.29,5.29,0,0,0-.33-1.89,4.7,4.7,0,0,0-.88-1.49,3.88,3.88,0,0,0-1.27-1,3.16,3.16,0,0,0-1.45-.35H259Z"
      fill="#fff"
    />
    <path d="M300.74,547.31v5H280.55V523.19h19.82v5H286.21v7h12.24v4.59H286.21v7.57Z" fill="#fff" />
    <path d="M325.36,547.31v5H305.17V523.19H325v5H310.82v7h12.24v4.59H310.82v7.57Z" fill="#fff" />
    <path d="M341.2,523.19l7.87,22,7.78-22h5.94l-11.35,29.08h-4.75l-11.42-29.08Z" fill="#fff" />
    <path d="M365.9,552.27V523.19h5.65v29.08Z" fill="#fff" />
    <path
      d="M377.61,552.27V523.19h10.57a15.86,15.86,0,0,1,6.24,1.15,12.51,12.51,0,0,1,7.19,7.72,18.28,18.28,0,0,1-.1,11.63,12.59,12.59,0,0,1-7.42,7.53,15.87,15.87,0,0,1-5.91,1Zm19.21-14.58a12.14,12.14,0,0,0-.58-3.85,8.6,8.6,0,0,0-1.68-3,7.42,7.42,0,0,0-2.72-2,9.26,9.26,0,0,0-3.66-.69h-4.92v19.16h4.92a8.85,8.85,0,0,0,3.7-.73,7.77,7.77,0,0,0,2.71-2,9.05,9.05,0,0,0,1.65-3A12,12,0,0,0,396.82,537.69Z"
      fill="#fff"
    />
    <path d="M427.12,547.31v5H406.93V523.19h19.82v5H412.58v7h12.25v4.59H412.58v7.57Z" fill="#fff" />
    <path
      d="M443.34,552.47a13.15,13.15,0,0,1-5.8-1.25,14.15,14.15,0,0,1-4.46-3.31,15.34,15.34,0,0,1-3.91-10.18,14.69,14.69,0,0,1,1.08-5.57,15.6,15.6,0,0,1,3-4.71,14.24,14.24,0,0,1,4.53-3.25,13.35,13.35,0,0,1,5.69-1.21,12.82,12.82,0,0,1,5.76,1.29,14.31,14.31,0,0,1,4.44,3.38,15.59,15.59,0,0,1,2.87,4.75,15,15,0,0,1,1,5.4,14.7,14.7,0,0,1-1.07,5.55,15.15,15.15,0,0,1-2.94,4.67,14.32,14.32,0,0,1-4.51,3.24A13.5,13.5,0,0,1,443.34,552.47Zm-8.44-14.74a11.52,11.52,0,0,0,.58,3.65,9.76,9.76,0,0,0,1.65,3.11,8.42,8.42,0,0,0,2.67,2.17,7.71,7.71,0,0,0,3.58.82,7.58,7.58,0,0,0,3.62-.84,8.47,8.47,0,0,0,2.67-2.23,9.88,9.88,0,0,0,1.63-3.14,11.59,11.59,0,0,0,.56-3.54,11.2,11.2,0,0,0-.6-3.64,9.76,9.76,0,0,0-1.68-3.12,8.09,8.09,0,0,0-2.66-2.15,7.75,7.75,0,0,0-3.54-.8,7.86,7.86,0,0,0-3.65.84,8.09,8.09,0,0,0-2.66,2.22,9.68,9.68,0,0,0-1.61,3.11A11.59,11.59,0,0,0,434.9,537.73Z"
      fill="#fff"
    />
    <path
      d="M470,537.53a15.14,15.14,0,0,1,1-5.27,14.36,14.36,0,0,1,2.8-4.64,14,14,0,0,1,4.51-3.32,13.83,13.83,0,0,1,6.06-1.27,13.23,13.23,0,0,1,6.94,1.76,11.17,11.17,0,0,1,4.36,4.59l-4.34,3a7.29,7.29,0,0,0-1.39-2.07A6.81,6.81,0,0,0,488.1,529a7.77,7.77,0,0,0-2-.72,9.3,9.3,0,0,0-2-.23,7.67,7.67,0,0,0-3.71.86,8.24,8.24,0,0,0-2.62,2.24,9.82,9.82,0,0,0-1.58,3.11,11.92,11.92,0,0,0-.53,3.46,11.59,11.59,0,0,0,.61,3.71,9.78,9.78,0,0,0,1.75,3.13,8.77,8.77,0,0,0,2.7,2.17,7.33,7.33,0,0,0,3.46.82,8.83,8.83,0,0,0,2.05-.25,7.42,7.42,0,0,0,2-.78,7.69,7.69,0,0,0,1.79-1.37,6.46,6.46,0,0,0,1.31-2l4.62,2.66a8.63,8.63,0,0,1-2,2.88,12.45,12.45,0,0,1-2.91,2.11,14.54,14.54,0,0,1-3.46,1.31,15.18,15.18,0,0,1-3.61.45,12.31,12.31,0,0,1-5.69-1.31,14.66,14.66,0,0,1-4.44-3.42A15.89,15.89,0,0,1,471,543,15.07,15.07,0,0,1,470,537.53Z"
      fill="#fff"
    />
    <path d="M524.26,523.19v29.08h-5.61V539.86H505.46v12.41h-5.65V523.19h5.65V535h13.19V523.19Z" fill="#fff" />
    <path d="M538.59,523.19h5l11.06,29.08h-5.82l-2.7-7.25H536l-2.66,7.25H527.5Zm6.51,17.86-4-11.43-4.18,11.43Z" fill="#fff" />
    <path d="M576.72,528.15h-9.3v24.12h-5.61V528.15h-9.29v-5h24.2Z" fill="#fff" />
    <path
      d="M29.42,64.86V76.22a5.3,5.3,0,0,1-1.48,3.86,6,6,0,0,1-4,1.69l-.59,0A6.24,6.24,0,0,1,19,80.31a5.24,5.24,0,0,1-1.65-4.07V64.86h2.18V76.17A3.48,3.48,0,0,0,23.35,80a3.87,3.87,0,0,0,2.87-1,3.81,3.81,0,0,0,1-2.81V64.86Z"
      fill="#fff"
    />
    <path
      d="M36.29,66.14v3h2.32v1.64H36.29V78.5a1.76,1.76,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.64,2.64,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7H31.91V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M53.58,80.06a3,3,0,0,0,2-.69,2.45,2.45,0,0,0,.95-1.72h2a3.86,3.86,0,0,1-.73,2A4.79,4.79,0,0,1,56,81.22a5.23,5.23,0,0,1-2.39.57,5.09,5.09,0,0,1-4-1.69,6.73,6.73,0,0,1-1.5-4.63v-.35a7.55,7.55,0,0,1,.66-3.23,5.11,5.11,0,0,1,1.91-2.19,5.46,5.46,0,0,1,3-.78A5,5,0,0,1,57,70.17a4.47,4.47,0,0,1,1.47,3.25h-2a2.93,2.93,0,0,0-.92-2,2.81,2.81,0,0,0-2-.78,3,3,0,0,0-2.51,1.17,5.48,5.48,0,0,0-.89,3.37v.4a5.4,5.4,0,0,0,.88,3.3A3,3,0,0,0,53.58,80.06Z"
      fill="#fff"
    />
    <path
      d="M61.18,75.24A7.29,7.29,0,0,1,61.9,72a5.28,5.28,0,0,1,4.92-3,5.22,5.22,0,0,1,4.1,1.75,6.8,6.8,0,0,1,1.57,4.67v.15a7.46,7.46,0,0,1-.7,3.26,5.21,5.21,0,0,1-2,2.24,5.46,5.46,0,0,1-3,.8A5.22,5.22,0,0,1,62.75,80a6.74,6.74,0,0,1-1.57-4.65Zm2.14.25a5.3,5.3,0,0,0,1,3.32,3.24,3.24,0,0,0,5.13,0,5.76,5.76,0,0,0,1-3.55,5.29,5.29,0,0,0-1-3.31,3.22,3.22,0,0,0-5.11,0A5.8,5.8,0,0,0,63.32,75.49Z"
      fill="#fff"
    />
    <path
      d="M78,69.15l.07,1.56a4.54,4.54,0,0,1,3.72-1.79c2.62,0,3.95,1.48,4,4.44v8.2H83.64V73.35a2.89,2.89,0,0,0-.62-2,2.43,2.43,0,0,0-1.87-.64,3.06,3.06,0,0,0-1.82.55,3.73,3.73,0,0,0-1.21,1.45v8.84H76V69.15Z"
      fill="#fff"
    />
    <path
      d="M97,78.27a1.58,1.58,0,0,0-.65-1.34,6.41,6.41,0,0,0-2.26-.82,10.71,10.71,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,91,70a5,5,0,0,1,3.29-1A5.19,5.19,0,0,1,97.73,70,3.46,3.46,0,0,1,99,72.8H96.91a1.94,1.94,0,0,0-.74-1.51,2.78,2.78,0,0,0-1.87-.63,2.88,2.88,0,0,0-1.81.51,1.58,1.58,0,0,0-.65,1.32,1.28,1.28,0,0,0,.61,1.16,7.18,7.18,0,0,0,2.19.74,11.61,11.61,0,0,1,2.58.85,3.71,3.71,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.48,1.69,3.17,3.17,0,0,1-1.33,2.67,5.59,5.59,0,0,1-3.46,1,6.32,6.32,0,0,1-2.64-.52,4.37,4.37,0,0,1-1.79-1.48,3.56,3.56,0,0,1-.65-2.05h2.12a2.24,2.24,0,0,0,.86,1.7,3.34,3.34,0,0,0,2.1.62,3.4,3.4,0,0,0,1.94-.49A1.51,1.51,0,0,0,97,78.27Z"
      fill="#fff"
    />
    <path
      d="M107.92,81.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.46,5.46,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.34,4.57V76h-8.41a4.38,4.38,0,0,0,1.08,3A3.37,3.37,0,0,0,108,80.06a3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,107.92,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,107.66,70.66Z"
      fill="#fff"
    />
    <path
      d="M121.05,80.06a3.06,3.06,0,0,0,2-.69,2.4,2.4,0,0,0,.94-1.72h2a3.86,3.86,0,0,1-.73,2,5,5,0,0,1-1.81,1.54,5.26,5.26,0,0,1-2.39.57,5.11,5.11,0,0,1-4-1.69,6.73,6.73,0,0,1-1.5-4.63v-.35a7.55,7.55,0,0,1,.67-3.23,5,5,0,0,1,1.91-2.19,5.42,5.42,0,0,1,2.94-.78,5,5,0,0,1,3.48,1.25A4.47,4.47,0,0,1,126,73.42h-2a2.88,2.88,0,0,0-.91-2,2.83,2.83,0,0,0-2-.78,3,3,0,0,0-2.5,1.17,5.41,5.41,0,0,0-.89,3.37v.4a5.34,5.34,0,0,0,.88,3.3A3,3,0,0,0,121.05,80.06Z"
      fill="#fff"
    />
    <path
      d="M132.09,66.14v3h2.32v1.64h-2.32V78.5a1.76,1.76,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V69.15H130v-3Z"
      fill="#fff"
    />
    <path
      d="M142.89,81.79a5.42,5.42,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.91,4.91,0,0,1,2.74-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V76h-8.41a4.42,4.42,0,0,0,1.07,3A3.4,3.4,0,0,0,143,80.06a3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,142.89,81.79Zm-.27-11.13a2.84,2.84,0,0,0-2.15.94,4.5,4.5,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.88-2.51A2.67,2.67,0,0,0,142.62,70.66Z"
      fill="#fff"
    />
    <path
      d="M153.91,66.14v3h2.32v1.64h-2.32V78.5a1.7,1.7,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.32,6.32,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M167.22,80.34a4.52,4.52,0,0,1-3.64,1.45,3.85,3.85,0,0,1-3-1.15,5,5,0,0,1-1.05-3.41V69.15h2.12v8c0,1.88.77,2.82,2.3,2.82a3.16,3.16,0,0,0,3.23-1.81v-9h2.12V81.56h-2Z"
      fill="#fff"
    />
    <path
      d="M179.36,71.05a6.89,6.89,0,0,0-1.05-.08,2.8,2.8,0,0,0-2.83,1.78v8.81h-2.13V69.15h2.07l0,1.43a3.31,3.31,0,0,1,3-1.66,2.17,2.17,0,0,1,1,.16Z"
      fill="#fff"
    />
    <path
      d="M196.05,78.27a1.58,1.58,0,0,0-.65-1.34,6.49,6.49,0,0,0-2.26-.82,10.71,10.71,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.75,2.75,0,0,1-.46-1.58A3.22,3.22,0,0,1,190,70a5,5,0,0,1,3.29-1A5.19,5.19,0,0,1,196.74,70a3.46,3.46,0,0,1,1.31,2.79h-2.13a1.94,1.94,0,0,0-.74-1.51,2.78,2.78,0,0,0-1.87-.63,2.88,2.88,0,0,0-1.81.51,1.58,1.58,0,0,0-.65,1.32,1.28,1.28,0,0,0,.61,1.16,7.18,7.18,0,0,0,2.19.74,11.61,11.61,0,0,1,2.58.85,3.79,3.79,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.48,1.69,3.2,3.2,0,0,1-1.33,2.67,5.59,5.59,0,0,1-3.46,1,6.32,6.32,0,0,1-2.64-.52A4.37,4.37,0,0,1,189,79.79a3.56,3.56,0,0,1-.65-2.05h2.12a2.24,2.24,0,0,0,.86,1.7,3.34,3.34,0,0,0,2.1.62,3.4,3.4,0,0,0,1.94-.49A1.51,1.51,0,0,0,196.05,78.27Z"
      fill="#fff"
    />
    <path
      d="M209.44,81.56a4.34,4.34,0,0,1-.29-1.3,4.76,4.76,0,0,1-3.54,1.53,4.34,4.34,0,0,1-3-1,3.37,3.37,0,0,1-1.18-2.63,3.49,3.49,0,0,1,1.48-3A6.9,6.9,0,0,1,207.05,74h2.06v-1a2.39,2.39,0,0,0-.66-1.77,2.7,2.7,0,0,0-2-.66,3.1,3.1,0,0,0-1.9.57,1.7,1.7,0,0,0-.77,1.39h-2.13a2.94,2.94,0,0,1,.66-1.8,4.56,4.56,0,0,1,1.79-1.37,6.1,6.1,0,0,1,2.48-.5A4.93,4.93,0,0,1,210,70a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM205.92,80a3.73,3.73,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34V75.54h-1.66q-3.9,0-3.9,2.28a1.93,1.93,0,0,0,.66,1.56A2.56,2.56,0,0,0,205.92,80Z"
      fill="#fff"
    />
    <path
      d="M225.9,75.49a7.46,7.46,0,0,1-1.3,4.57,4.15,4.15,0,0,1-3.51,1.73,4.55,4.55,0,0,1-3.56-1.43v6h-2.12V69.15h1.94l.1,1.38a4.35,4.35,0,0,1,3.6-1.61,4.21,4.21,0,0,1,3.54,1.69,7.47,7.47,0,0,1,1.31,4.69Zm-2.13-.24a5.51,5.51,0,0,0-.89-3.31,2.9,2.9,0,0,0-2.46-1.22,3.12,3.12,0,0,0-2.89,1.71v5.93a3.14,3.14,0,0,0,2.92,1.7,2.86,2.86,0,0,0,2.42-1.21A6,6,0,0,0,223.77,75.25Z"
      fill="#fff"
    />
    <path
      d="M229.45,65.85a1.23,1.23,0,0,1,.32-.87,1.2,1.2,0,0,1,.93-.35A1.16,1.16,0,0,1,232,65.85a1.2,1.2,0,0,1-.32.87,1.22,1.22,0,0,1-.94.34,1.14,1.14,0,0,1-1.25-1.21Zm2.29,15.71h-2.12V69.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M241.14,81.79A5.45,5.45,0,0,1,237,80.14a6.2,6.2,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.92,4.92,0,0,1,2.75-.82,4.66,4.66,0,0,1,3.76,1.59A7,7,0,0,1,246,75.08V76h-8.41a4.42,4.42,0,0,0,1.07,3,3.4,3.4,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,241.14,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.68,2.68,0,0,0,240.88,70.66Z"
      fill="#fff"
    />
    <path
      d="M251.29,69.15l.07,1.56a4.54,4.54,0,0,1,3.72-1.79c2.62,0,3.95,1.48,4,4.44v8.2h-2.12V73.35a2.89,2.89,0,0,0-.62-2,2.45,2.45,0,0,0-1.87-.64,3.06,3.06,0,0,0-1.82.55,3.73,3.73,0,0,0-1.21,1.45v8.84h-2.13V69.15Z"
      fill="#fff"
    />
    <path
      d="M274.91,81.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.34,7.34,0,0,1,.71-3.3,5.46,5.46,0,0,1,2-2.27,4.92,4.92,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.34,4.57V76h-8.41a4.38,4.38,0,0,0,1.08,3A3.37,3.37,0,0,0,275,80.06a3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,274.91,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.68,2.68,0,0,0,274.65,70.66Z"
      fill="#fff"
    />
    <path d="M285.36,81.56h-2.12V63.94h2.12Z" fill="#fff" />
    <path
      d="M294.76,81.79a5.42,5.42,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.59-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V76h-8.41a4.42,4.42,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,294.76,81.79Zm-.27-11.13a2.88,2.88,0,0,0-2.16.94,4.5,4.5,0,0,0-1.07,2.62h6.22v-.16a4.12,4.12,0,0,0-.88-2.51A2.67,2.67,0,0,0,294.49,70.66Z"
      fill="#fff"
    />
    <path
      d="M304.9,69.15l.05,1.38a4.57,4.57,0,0,1,3.69-1.61,3.53,3.53,0,0,1,3.54,2,4.6,4.6,0,0,1,4-2q4.08,0,4.16,4.32v8.32h-2.13V73.37a2.82,2.82,0,0,0-.61-2,2.66,2.66,0,0,0-2-.66,2.81,2.81,0,0,0-2,.71,2.88,2.88,0,0,0-.91,1.9v8.23h-2.13V73.43c0-1.81-.88-2.71-2.65-2.71A2.84,2.84,0,0,0,305,72.5v9.06h-2.12V69.15Z"
      fill="#fff"
    />
    <path
      d="M329.49,81.79a5.42,5.42,0,0,1-4.1-1.65,6.16,6.16,0,0,1-1.59-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82A4.65,4.65,0,0,1,333,70.51a6.9,6.9,0,0,1,1.35,4.57V76h-8.41a4.37,4.37,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.3,1A5.22,5.22,0,0,1,329.49,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94A4.57,4.57,0,0,0,326,74.22h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,329.23,70.66Z"
      fill="#fff"
    />
    <path
      d="M339.65,69.15l.07,1.56a4.51,4.51,0,0,1,3.71-1.79q3.94,0,4,4.44v8.2h-2.12V73.35a2.89,2.89,0,0,0-.61-2,2.47,2.47,0,0,0-1.88-.64,3,3,0,0,0-1.81.55,3.75,3.75,0,0,0-1.22,1.45v8.84h-2.12V69.15Z"
      fill="#fff"
    />
    <path
      d="M354.33,66.14v3h2.32v1.64h-2.32V78.5a1.7,1.7,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.32,6.32,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7H350V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M367.64,80.34A4.52,4.52,0,0,1,364,81.79a3.85,3.85,0,0,1-3-1.15,4.92,4.92,0,0,1-1.05-3.41V69.15h2.12v8c0,1.88.77,2.82,2.3,2.82a3.16,3.16,0,0,0,3.23-1.81v-9h2.12V81.56h-2Z"
      fill="#fff"
    />
    <path
      d="M375.77,69.15l.06,1.38a4.56,4.56,0,0,1,3.68-1.61,3.54,3.54,0,0,1,3.55,2,4.6,4.6,0,0,1,4-2q4.08,0,4.15,4.32v8.32h-2.12V73.37a2.87,2.87,0,0,0-.61-2,2.69,2.69,0,0,0-2-.66,2.75,2.75,0,0,0-2.87,2.61v8.23h-2.14V73.43c0-1.81-.88-2.71-2.65-2.71a2.83,2.83,0,0,0-2.85,1.78v9.06h-2.13V69.15Z"
      fill="#fff"
    />
    <path
      d="M395.15,84.89l-1.21-.82a5.57,5.57,0,0,0,1.13-3.1V79.05h2.07v1.66a5.17,5.17,0,0,1-.56,2.32A5.46,5.46,0,0,1,395.15,84.89Z"
      fill="#fff"
    />
    <path
      d="M412.32,80.06a3,3,0,0,0,2-.69,2.45,2.45,0,0,0,.95-1.72h2a3.78,3.78,0,0,1-.73,2,4.79,4.79,0,0,1-1.81,1.54,5.23,5.23,0,0,1-2.39.57,5.09,5.09,0,0,1-4-1.69,6.73,6.73,0,0,1-1.5-4.63v-.35a7.55,7.55,0,0,1,.66-3.23,5.07,5.07,0,0,1,1.92-2.19,5.4,5.4,0,0,1,2.94-.78,5,5,0,0,1,3.47,1.25,4.47,4.47,0,0,1,1.47,3.25h-2a2.93,2.93,0,0,0-.92-2,2.81,2.81,0,0,0-2-.78,3,3,0,0,0-2.51,1.17,5.48,5.48,0,0,0-.89,3.37v.4a5.33,5.33,0,0,0,.89,3.3A3,3,0,0,0,412.32,80.06Z"
      fill="#fff"
    />
    <path
      d="M419.92,75.24a7.29,7.29,0,0,1,.72-3.28,5.28,5.28,0,0,1,4.92-3,5.22,5.22,0,0,1,4.1,1.75,6.8,6.8,0,0,1,1.57,4.67v.15a7.33,7.33,0,0,1-.7,3.26,5.21,5.21,0,0,1-2,2.24,5.46,5.46,0,0,1-3,.8A5.22,5.22,0,0,1,421.49,80a6.74,6.74,0,0,1-1.57-4.65Zm2.14.25a5.3,5.3,0,0,0,1,3.32,3.07,3.07,0,0,0,2.56,1.25,3,3,0,0,0,2.57-1.27,5.76,5.76,0,0,0,1-3.55,5.29,5.29,0,0,0-1-3.31,3.22,3.22,0,0,0-5.11,0A5.8,5.8,0,0,0,422.06,75.49Z"
      fill="#fff"
    />
    <path
      d="M436.74,69.15l.07,1.56a4.54,4.54,0,0,1,3.72-1.79c2.62,0,4,1.48,4,4.44v8.2h-2.12V73.35a2.89,2.89,0,0,0-.62-2,2.43,2.43,0,0,0-1.87-.64,3.06,3.06,0,0,0-1.82.55,3.73,3.73,0,0,0-1.21,1.45v8.84h-2.13V69.15Z"
      fill="#fff"
    />
    <path
      d="M455.78,78.27a1.58,1.58,0,0,0-.65-1.34,6.41,6.41,0,0,0-2.26-.82,10.71,10.71,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,449.75,70a5.06,5.06,0,0,1,3.3-1A5.18,5.18,0,0,1,456.47,70a3.46,3.46,0,0,1,1.31,2.79h-2.13a1.94,1.94,0,0,0-.74-1.51,2.78,2.78,0,0,0-1.86-.63,2.9,2.9,0,0,0-1.82.51,1.58,1.58,0,0,0-.65,1.32,1.28,1.28,0,0,0,.61,1.16,7.18,7.18,0,0,0,2.19.74,11.61,11.61,0,0,1,2.58.85,3.71,3.71,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.48,1.69,3.17,3.17,0,0,1-1.33,2.67,5.59,5.59,0,0,1-3.46,1,6.32,6.32,0,0,1-2.64-.52,4.37,4.37,0,0,1-1.79-1.48,3.56,3.56,0,0,1-.65-2.05h2.12a2.24,2.24,0,0,0,.86,1.7,3.34,3.34,0,0,0,2.1.62,3.4,3.4,0,0,0,1.94-.49A1.51,1.51,0,0,0,455.78,78.27Z"
      fill="#fff"
    />
    <path
      d="M466.66,81.79a5.45,5.45,0,0,1-4.11-1.65A6.2,6.2,0,0,1,461,75.7v-.39a7.47,7.47,0,0,1,.71-3.3,5.46,5.46,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.34,4.57V76h-8.41a4.38,4.38,0,0,0,1.08,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,466.66,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,466.4,70.66Z"
      fill="#fff"
    />
    <path
      d="M479.79,80.06a3,3,0,0,0,2-.69,2.4,2.4,0,0,0,.94-1.72h2a3.86,3.86,0,0,1-.73,2,5,5,0,0,1-1.81,1.54,5.23,5.23,0,0,1-2.39.57,5.12,5.12,0,0,1-4-1.69,6.73,6.73,0,0,1-1.49-4.63v-.35a7.55,7.55,0,0,1,.66-3.23,5,5,0,0,1,1.91-2.19,5.43,5.43,0,0,1,2.95-.78,5,5,0,0,1,3.47,1.25,4.47,4.47,0,0,1,1.47,3.25h-2a2.88,2.88,0,0,0-.91-2,2.82,2.82,0,0,0-2-.78,3,3,0,0,0-2.51,1.17,5.41,5.41,0,0,0-.89,3.37v.4a5.34,5.34,0,0,0,.88,3.3A3,3,0,0,0,479.79,80.06Z"
      fill="#fff"
    />
    <path
      d="M490.83,66.14v3h2.32v1.64h-2.32V78.5a1.76,1.76,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M501.63,81.79a5.42,5.42,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.91,4.91,0,0,1,2.74-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V76h-8.41a4.42,4.42,0,0,0,1.07,3,3.4,3.4,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46A4.73,4.73,0,0,0,505,78.39l1.3,1A5.21,5.21,0,0,1,501.63,81.79Zm-.27-11.13a2.84,2.84,0,0,0-2.15.94,4.5,4.5,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.88-2.51A2.67,2.67,0,0,0,501.36,70.66Z"
      fill="#fff"
    />
    <path
      d="M512.65,66.14v3H515v1.64h-2.32V78.5a1.7,1.7,0,0,0,.31,1.12A1.31,1.31,0,0,0,514,80a4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M526,80.34a4.52,4.52,0,0,1-3.64,1.45,3.85,3.85,0,0,1-3-1.15,5,5,0,0,1-1-3.41V69.15h2.12v8c0,1.88.77,2.82,2.3,2.82a3.16,3.16,0,0,0,3.23-1.81v-9H528V81.56h-2Z"
      fill="#fff"
    />
    <path
      d="M538.1,71.05a6.89,6.89,0,0,0-1.05-.08,2.8,2.8,0,0,0-2.83,1.78v8.81h-2.13V69.15h2.07l0,1.43a3.32,3.32,0,0,1,3-1.66,2.15,2.15,0,0,1,.94.16Z"
      fill="#fff"
    />
    <path
      d="M547,75.25a7.28,7.28,0,0,1,1.35-4.59,4.61,4.61,0,0,1,7-.25V63.94h2.13V81.56h-1.95l-.11-1.33a4.32,4.32,0,0,1-3.54,1.56A4.26,4.26,0,0,1,548.4,80,7.35,7.35,0,0,1,547,75.41Zm2.12.24A5.54,5.54,0,0,0,550,78.8,2.85,2.85,0,0,0,552.44,80a3.09,3.09,0,0,0,2.95-1.81v-5.7a3.12,3.12,0,0,0-2.92-1.76A2.84,2.84,0,0,0,550,71.93,6.05,6.05,0,0,0,549.16,75.49Z"
      fill="#fff"
    />
    <path
      d="M569.32,80.34a4.52,4.52,0,0,1-3.64,1.45,3.85,3.85,0,0,1-3-1.15,5,5,0,0,1-1-3.41V69.15h2.12v8c0,1.88.77,2.82,2.3,2.82a3.16,3.16,0,0,0,3.23-1.81v-9h2.13V81.56h-2Z"
      fill="#fff"
    />
    <path
      d="M575.47,65.85a1.27,1.27,0,0,1,.31-.87,1.44,1.44,0,0,1,1.88,0,1.28,1.28,0,0,1,.32.87,1.24,1.24,0,0,1-.32.87,1.47,1.47,0,0,1-1.88,0A1.24,1.24,0,0,1,575.47,65.85Zm2.29,15.71h-2.12V69.15h2.12Z"
      fill="#fff"
    />
    <path d="M592.77,78.68l3.07-9.53H598l-4.45,12.41h-1.62l-4.5-12.41h2.17Z" fill="#fff" />
    <path
      d="M600.91,65.85a1.27,1.27,0,0,1,.31-.87,1.44,1.44,0,0,1,1.88,0,1.28,1.28,0,0,1,.32.87,1.24,1.24,0,0,1-.32.87,1.47,1.47,0,0,1-1.88,0A1.24,1.24,0,0,1,600.91,65.85Zm2.29,15.71h-2.12V69.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M610.33,66.14v3h2.31v1.64h-2.31V78.5a1.7,1.7,0,0,0,.31,1.12,1.27,1.27,0,0,0,1.05.37,4.58,4.58,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.61,2.61,0,0,1-2.14-.86,3.65,3.65,0,0,1-.73-2.44v-7.7h-2.26V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M623.63,81.56a4.34,4.34,0,0,1-.29-1.3,4.76,4.76,0,0,1-3.54,1.53,4.34,4.34,0,0,1-3-1,3.37,3.37,0,0,1-1.18-2.63,3.49,3.49,0,0,1,1.48-3A6.9,6.9,0,0,1,621.24,74h2.06v-1a2.39,2.39,0,0,0-.66-1.77,2.7,2.7,0,0,0-2-.66,3.1,3.1,0,0,0-1.9.57,1.7,1.7,0,0,0-.77,1.39h-2.13a2.94,2.94,0,0,1,.66-1.8,4.56,4.56,0,0,1,1.79-1.37,6.1,6.1,0,0,1,2.48-.5A4.93,4.93,0,0,1,624.16,70a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM620.11,80a3.73,3.73,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34V75.54h-1.66q-3.9,0-3.9,2.28a1.93,1.93,0,0,0,.66,1.56A2.54,2.54,0,0,0,620.11,80Z"
      fill="#fff"
    />
    <path
      d="M634.75,81.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.92,4.92,0,0,1,2.75-.82,4.66,4.66,0,0,1,3.76,1.59,7,7,0,0,1,1.34,4.57V76h-8.41a4.42,4.42,0,0,0,1.07,3,3.4,3.4,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.9,4.9,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,634.75,81.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.68,2.68,0,0,0,634.49,70.66Z"
      fill="#fff"
    />
    <path
      d="M642.82,84.89l-1.2-.82a5.55,5.55,0,0,0,1.12-3.1V79.05h2.08v1.66a5.19,5.19,0,0,1-.57,2.32A5.34,5.34,0,0,1,642.82,84.89Z"
      fill="#fff"
    />
    <path
      d="M654.5,75.25a7.28,7.28,0,0,1,1.35-4.59,4.61,4.61,0,0,1,7-.25V63.94H665V81.56h-2l-.11-1.33a4.32,4.32,0,0,1-3.54,1.56A4.26,4.26,0,0,1,655.86,80a7.35,7.35,0,0,1-1.36-4.62Zm2.12.24a5.54,5.54,0,0,0,.87,3.31A2.85,2.85,0,0,0,659.9,80a3.09,3.09,0,0,0,3-1.81v-5.7a3.12,3.12,0,0,0-2.92-1.76,2.84,2.84,0,0,0-2.44,1.21A6.05,6.05,0,0,0,656.62,75.49Z"
      fill="#fff"
    />
    <path
      d="M669.13,65.85a1.27,1.27,0,0,1,.31-.87,1.21,1.21,0,0,1,.94-.35,1.24,1.24,0,0,1,.94.35,1.28,1.28,0,0,1,.32.87,1.14,1.14,0,0,1-1.26,1.21,1.2,1.2,0,0,1-.94-.34A1.24,1.24,0,0,1,669.13,65.85Zm2.29,15.71H669.3V69.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M680.64,80.06a3.07,3.07,0,0,0,2-.69,2.4,2.4,0,0,0,.94-1.72h2a3.79,3.79,0,0,1-.74,2,4.84,4.84,0,0,1-1.8,1.54,5.27,5.27,0,0,1-2.4.57,5.1,5.1,0,0,1-4-1.69,6.73,6.73,0,0,1-1.5-4.63v-.35a7.55,7.55,0,0,1,.67-3.23,5,5,0,0,1,1.91-2.19,5.42,5.42,0,0,1,2.94-.78,5,5,0,0,1,3.47,1.25,4.44,4.44,0,0,1,1.48,3.25h-2a2.92,2.92,0,0,0-.91-2,2.83,2.83,0,0,0-2-.78,3,3,0,0,0-2.5,1.17,5.41,5.41,0,0,0-.89,3.37v.4a5.34,5.34,0,0,0,.88,3.3A3,3,0,0,0,680.64,80.06Z"
      fill="#fff"
    />
    <path
      d="M691.69,66.14v3H694v1.64h-2.32V78.5a1.7,1.7,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.32,6.32,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V69.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M705,80.34a4.52,4.52,0,0,1-3.64,1.45,3.85,3.85,0,0,1-3-1.15,5,5,0,0,1-1.05-3.41V69.15h2.12v8c0,1.88.77,2.82,2.3,2.82a3.16,3.16,0,0,0,3.23-1.81v-9h2.12V81.56h-2Z"
      fill="#fff"
    />
    <path
      d="M713.13,69.15l.06,1.38a4.56,4.56,0,0,1,3.68-1.61,3.54,3.54,0,0,1,3.55,2,4.6,4.6,0,0,1,4-2q4.08,0,4.15,4.32v8.32h-2.12V73.37a2.87,2.87,0,0,0-.61-2,2.69,2.69,0,0,0-2-.66,2.75,2.75,0,0,0-2.87,2.61v8.23h-2.14V73.43c0-1.81-.88-2.71-2.65-2.71a2.83,2.83,0,0,0-2.85,1.78v9.06h-2.13V69.15Z"
      fill="#fff"
    />
    <path
      d="M746.47,78.27a1.58,1.58,0,0,0-.65-1.34,6.33,6.33,0,0,0-2.26-.82,10.86,10.86,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,740.44,70a5.06,5.06,0,0,1,3.3-1A5.14,5.14,0,0,1,747.16,70a3.47,3.47,0,0,1,1.32,2.79h-2.14a1.91,1.91,0,0,0-.74-1.51,2.76,2.76,0,0,0-1.86-.63,2.87,2.87,0,0,0-1.81.51,1.57,1.57,0,0,0-.66,1.32,1.28,1.28,0,0,0,.61,1.16,7.38,7.38,0,0,0,2.2.74,11.33,11.33,0,0,1,2.57.85,3.83,3.83,0,0,1,1.47,1.19,3,3,0,0,1,.47,1.69,3.17,3.17,0,0,1-1.33,2.67,5.58,5.58,0,0,1-3.45,1,6.25,6.25,0,0,1-2.64-.52,4.34,4.34,0,0,1-1.8-1.48,3.56,3.56,0,0,1-.65-2.05h2.13a2.23,2.23,0,0,0,.85,1.7,4,4,0,0,0,4,.13A1.48,1.48,0,0,0,746.47,78.27Z"
      fill="#fff"
    />
    <path
      d="M759.87,81.56a4.6,4.6,0,0,1-.3-1.3A4.74,4.74,0,0,1,756,81.79a4.37,4.37,0,0,1-3-1,3.36,3.36,0,0,1-1.17-2.63,3.51,3.51,0,0,1,1.47-3A6.94,6.94,0,0,1,757.47,74h2.07v-1a2.4,2.4,0,0,0-.67-1.77,2.67,2.67,0,0,0-2-.66,3.14,3.14,0,0,0-1.91.57,1.69,1.69,0,0,0-.76,1.39H752.1a2.94,2.94,0,0,1,.66-1.8,4.63,4.63,0,0,1,1.79-1.37,6.1,6.1,0,0,1,2.48-.5A4.94,4.94,0,0,1,760.4,70a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.43,2.72v.18ZM756.35,80a3.79,3.79,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34V75.54h-1.67q-3.9,0-3.9,2.28a1.9,1.9,0,0,0,.67,1.56A2.52,2.52,0,0,0,756.35,80Z"
      fill="#fff"
    />
    <path
      d="M776.32,75.49a7.4,7.4,0,0,1-1.3,4.57,4.13,4.13,0,0,1-3.51,1.73A4.53,4.53,0,0,1,768,80.36v6h-2.13V69.15h1.94l.11,1.38a4.34,4.34,0,0,1,3.6-1.61A4.23,4.23,0,0,1,775,70.61a7.47,7.47,0,0,1,1.3,4.69Zm-2.12-.24a5.44,5.44,0,0,0-.9-3.31,2.87,2.87,0,0,0-2.45-1.22A3.11,3.11,0,0,0,768,72.43v5.93a3.12,3.12,0,0,0,2.91,1.7,2.86,2.86,0,0,0,2.43-1.21A6,6,0,0,0,774.2,75.25Z"
      fill="#fff"
    />
    <path
      d="M779.87,65.85a1.28,1.28,0,0,1,.32-.87,1.21,1.21,0,0,1,.94-.35,1.24,1.24,0,0,1,.94.35,1.28,1.28,0,0,1,.32.87,1.14,1.14,0,0,1-1.26,1.21,1.2,1.2,0,0,1-.94-.34A1.24,1.24,0,0,1,779.87,65.85Zm2.3,15.71h-2.12V69.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M22.48,110.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.34,7.34,0,0,1,.71-3.3,5.46,5.46,0,0,1,2-2.27,4.92,4.92,0,0,1,2.75-.82A4.65,4.65,0,0,1,26,99.51a6.9,6.9,0,0,1,1.34,4.57V105H18.91a4.38,4.38,0,0,0,1.08,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,22.48,110.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94A4.57,4.57,0,0,0,19,103.22H25.2v-.16a4.06,4.06,0,0,0-.87-2.51A2.68,2.68,0,0,0,22.22,99.66Z"
      fill="#fff"
    />
    <path
      d="M32.56,98.15l.07,1.56a4.54,4.54,0,0,1,3.72-1.79c2.62,0,3.95,1.48,4,4.44v8.2H38.2v-8.21a2.89,2.89,0,0,0-.62-2,2.43,2.43,0,0,0-1.87-.64,3.06,3.06,0,0,0-1.82.55,3.73,3.73,0,0,0-1.21,1.45v8.84H30.55V98.15Z"
      fill="#fff"
    />
    <path
      d="M44.34,109.45a1.33,1.33,0,0,1,.33-.92,1.25,1.25,0,0,1,1-.36,1.28,1.28,0,0,1,1,.36,1.29,1.29,0,0,1,.34.92,1.2,1.2,0,0,1-.34.88,1.28,1.28,0,0,1-1,.36,1.25,1.25,0,0,1-1-.36A1.23,1.23,0,0,1,44.34,109.45Z"
      fill="#fff"
    />
    <path d="M71,110.56H68.81L60.4,97.69v12.87H58.18V93.86H60.4l8.43,12.93V93.86H71Z" fill="#fff" />
    <path
      d="M83,109.34a4.5,4.5,0,0,1-3.63,1.45,3.86,3.86,0,0,1-3-1.15,5,5,0,0,1-1-3.41V98.15h2.13v8c0,1.88.76,2.82,2.29,2.82A3.19,3.19,0,0,0,83,107.18v-9h2.12v12.41h-2Z"
      fill="#fff"
    />
    <path d="M91.41,110.56H89.29V92.94h2.12Z" fill="#fff" />
    <path d="M97.89,110.56H95.77V92.94h2.12Z" fill="#fff" />
    <path
      d="M109.73,110.56a4.6,4.6,0,0,1-.3-1.3,4.73,4.73,0,0,1-3.53,1.53,4.32,4.32,0,0,1-3-1,3.34,3.34,0,0,1-1.18-2.63,3.51,3.51,0,0,1,1.47-3,6.94,6.94,0,0,1,4.15-1.07h2.07v-1a2.36,2.36,0,0,0-.67-1.77,2.67,2.67,0,0,0-2-.66,3.08,3.08,0,0,0-1.9.57,1.68,1.68,0,0,0-.77,1.39H102a2.94,2.94,0,0,1,.66-1.8,4.63,4.63,0,0,1,1.79-1.37,6.15,6.15,0,0,1,2.49-.5A4.93,4.93,0,0,1,110.26,99a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM106.21,109a3.79,3.79,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34v-2.55h-1.67q-3.9,0-3.9,2.28a1.9,1.9,0,0,0,.67,1.56A2.52,2.52,0,0,0,106.21,109Z"
      fill="#fff"
    />
    <path
      d="M127.37,110.79a5.45,5.45,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.91,4.91,0,0,1,2.74-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V105H123.8a4.42,4.42,0,0,0,1.07,3,3.4,3.4,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,127.37,110.79Zm-.27-11.13a2.84,2.84,0,0,0-2.15.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.69,2.69,0,0,0,127.1,99.66Z"
      fill="#fff"
    />
    <path
      d="M134.94,104.25a7.29,7.29,0,0,1,1.34-4.62,4.29,4.29,0,0,1,3.56-1.71,4.25,4.25,0,0,1,3.54,1.61l.11-1.38h1.93v12.12a5,5,0,0,1-1.42,3.79,5.28,5.28,0,0,1-3.84,1.39,6.43,6.43,0,0,1-2.63-.57,4.51,4.51,0,0,1-2-1.57l1.1-1.28a4.13,4.13,0,0,0,3.34,1.69,3.24,3.24,0,0,0,2.41-.87,3.33,3.33,0,0,0,.87-2.46v-1.07a4.37,4.37,0,0,1-3.48,1.47,4.25,4.25,0,0,1-3.53-1.75A7.74,7.74,0,0,1,134.94,104.25Zm2.13.24a5.6,5.6,0,0,0,.86,3.3,2.81,2.81,0,0,0,2.41,1.2,3.12,3.12,0,0,0,2.95-1.82V101.5a3.15,3.15,0,0,0-2.93-1.78,2.81,2.81,0,0,0-2.42,1.21A6.05,6.05,0,0,0,137.07,104.49Z"
      fill="#fff"
    />
    <path
      d="M154.55,110.79a5.42,5.42,0,0,1-4.1-1.65,6.16,6.16,0,0,1-1.59-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.35,4.57V105H151a4.37,4.37,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.3,1A5.23,5.23,0,0,1,154.55,110.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,154.29,99.66Z"
      fill="#fff"
    />
    <path
      d="M169.86,107.27a1.58,1.58,0,0,0-.65-1.34,6.41,6.41,0,0,0-2.26-.82,10.71,10.71,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,163.83,99a5.06,5.06,0,0,1,3.3-1A5.18,5.18,0,0,1,170.55,99a3.46,3.46,0,0,1,1.31,2.79h-2.13a1.94,1.94,0,0,0-.74-1.51,2.78,2.78,0,0,0-1.86-.63,2.9,2.9,0,0,0-1.82.51,1.58,1.58,0,0,0-.65,1.32,1.28,1.28,0,0,0,.61,1.16,7.18,7.18,0,0,0,2.19.74,11.61,11.61,0,0,1,2.58.85,3.71,3.71,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.48,1.69,3.17,3.17,0,0,1-1.33,2.67,5.59,5.59,0,0,1-3.46,1,6.32,6.32,0,0,1-2.64-.52,4.37,4.37,0,0,1-1.79-1.48,3.56,3.56,0,0,1-.65-2.05h2.12a2.24,2.24,0,0,0,.86,1.7,3.34,3.34,0,0,0,2.1.62,3.4,3.4,0,0,0,1.94-.49A1.51,1.51,0,0,0,169.86,107.27Z"
      fill="#fff"
    />
    <path
      d="M178.4,95.14v3h2.32v1.64H178.4v7.71a1.7,1.7,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.32,6.32,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7H174V98.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M191.64,110.56a4,4,0,0,1-.3-1.3,4.76,4.76,0,0,1-3.54,1.53,4.36,4.36,0,0,1-3-1,3.36,3.36,0,0,1-1.17-2.63,3.51,3.51,0,0,1,1.47-3,6.92,6.92,0,0,1,4.15-1.07h2.06v-1a2.39,2.39,0,0,0-.66-1.77,2.67,2.67,0,0,0-2-.66,3.11,3.11,0,0,0-1.91.57,1.7,1.7,0,0,0-.77,1.39h-2.13a2.94,2.94,0,0,1,.66-1.8,4.56,4.56,0,0,1,1.79-1.37,6.1,6.1,0,0,1,2.48-.5A4.93,4.93,0,0,1,192.16,99a3.85,3.85,0,0,1,1.27,3v5.71a7,7,0,0,0,.43,2.72v.18ZM188.11,109a3.8,3.8,0,0,0,1.9-.52,3.08,3.08,0,0,0,1.29-1.34v-2.55h-1.66q-3.9,0-3.9,2.28a1.93,1.93,0,0,0,.66,1.56A2.56,2.56,0,0,0,188.11,109Z"
      fill="#fff"
    />
    <path
      d="M204.76,107.27a1.58,1.58,0,0,0-.65-1.34,6.33,6.33,0,0,0-2.26-.82,10.86,10.86,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,198.73,99a5.06,5.06,0,0,1,3.3-1A5.14,5.14,0,0,1,205.45,99a3.47,3.47,0,0,1,1.32,2.79h-2.14a1.91,1.91,0,0,0-.74-1.51,2.76,2.76,0,0,0-1.86-.63,2.87,2.87,0,0,0-1.81.51,1.57,1.57,0,0,0-.66,1.32,1.28,1.28,0,0,0,.61,1.16,7.27,7.27,0,0,0,2.2.74,11.5,11.5,0,0,1,2.57.85,3.83,3.83,0,0,1,1.47,1.19,3,3,0,0,1,.47,1.69,3.17,3.17,0,0,1-1.33,2.67,5.58,5.58,0,0,1-3.45,1,6.25,6.25,0,0,1-2.64-.52,4.34,4.34,0,0,1-1.8-1.48,3.56,3.56,0,0,1-.65-2.05h2.13a2.2,2.2,0,0,0,.85,1.7,3.35,3.35,0,0,0,2.11.62,3.39,3.39,0,0,0,1.93-.49A1.48,1.48,0,0,0,204.76,107.27Z"
      fill="#fff"
    />
    <path d="M219.32,110.56H217.2V92.94h2.12Z" fill="#fff" />
    <path
      d="M223.51,94.85a1.27,1.27,0,0,1,.31-.87,1.21,1.21,0,0,1,.94-.35,1.24,1.24,0,0,1,.94.35,1.28,1.28,0,0,1,.32.87,1.14,1.14,0,0,1-1.26,1.21,1.2,1.2,0,0,1-.94-.34A1.24,1.24,0,0,1,223.51,94.85Zm2.29,15.71h-2.12V98.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M240.48,104.49a7.41,7.41,0,0,1-1.3,4.58,4.2,4.2,0,0,1-3.52,1.72,4.31,4.31,0,0,1-3.63-1.66l-.11,1.43H230V92.94h2.13v6.57a4.29,4.29,0,0,1,3.54-1.59,4.2,4.2,0,0,1,3.55,1.71,7.61,7.61,0,0,1,1.29,4.68Zm-2.12-.24a5.74,5.74,0,0,0-.84-3.35,2.77,2.77,0,0,0-2.41-1.18,3.1,3.1,0,0,0-3,2V107a3.2,3.2,0,0,0,3,1.95,2.74,2.74,0,0,0,2.37-1.18A6.07,6.07,0,0,0,238.36,104.25Z"
      fill="#fff"
    />
    <path
      d="M249.08,110.79a5.42,5.42,0,0,1-4.1-1.65,6.16,6.16,0,0,1-1.59-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.35,4.57V105h-8.41a4.37,4.37,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.3,1A5.22,5.22,0,0,1,249.08,110.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,248.82,99.66Z"
      fill="#fff"
    />
    <path
      d="M263.16,100.05a6.76,6.76,0,0,0-1-.08,2.81,2.81,0,0,0-2.84,1.78v8.81h-2.12V98.15h2.06l0,1.43a3.29,3.29,0,0,1,3-1.66,2.12,2.12,0,0,1,.94.16Z"
      fill="#fff"
    />
    <path
      d="M265.09,104.24a7.29,7.29,0,0,1,.72-3.28,5.28,5.28,0,0,1,4.92-3,5.22,5.22,0,0,1,4.1,1.75,6.8,6.8,0,0,1,1.57,4.67v.15a7.46,7.46,0,0,1-.7,3.26,5.21,5.21,0,0,1-2,2.24,5.46,5.46,0,0,1-3,.8,5.22,5.22,0,0,1-4.09-1.75,6.74,6.74,0,0,1-1.57-4.65Zm2.14.25a5.3,5.3,0,0,0,1,3.32,3.24,3.24,0,0,0,5.13,0,5.76,5.76,0,0,0,.95-3.55,5.29,5.29,0,0,0-1-3.31,3.22,3.22,0,0,0-5.11,0A5.8,5.8,0,0,0,267.23,104.49Z"
      fill="#fff"
    />
    <path
      d="M288.43,98.15l.07,1.56a4.54,4.54,0,0,1,3.72-1.79q3.94,0,4,4.44v8.2h-2.12v-8.21a2.89,2.89,0,0,0-.61-2,2.47,2.47,0,0,0-1.88-.64,3,3,0,0,0-1.81.55,3.75,3.75,0,0,0-1.22,1.45v8.84h-2.12V98.15Z"
      fill="#fff"
    />
    <path
      d="M300.18,94.85a1.28,1.28,0,0,1,.32-.87,1.2,1.2,0,0,1,.93-.35,1.16,1.16,0,0,1,1.26,1.22,1.2,1.2,0,0,1-.32.87,1.22,1.22,0,0,1-.94.34,1.19,1.19,0,0,1-.93-.34A1.24,1.24,0,0,1,300.18,94.85Zm2.3,15.71h-2.13V98.15h2.13Z"
      fill="#fff"
    />
    <path
      d="M317.16,104.49a7.41,7.41,0,0,1-1.31,4.58,4.17,4.17,0,0,1-3.51,1.72,4.33,4.33,0,0,1-3.64-1.66l-.1,1.43h-2V92.94h2.12v6.57a4.32,4.32,0,0,1,3.55-1.59,4.21,4.21,0,0,1,3.55,1.71,7.61,7.61,0,0,1,1.29,4.68Zm-2.13-.24a5.74,5.74,0,0,0-.83-3.35,2.77,2.77,0,0,0-2.41-1.18,3.12,3.12,0,0,0-3,2V107a3.2,3.2,0,0,0,3,1.95,2.76,2.76,0,0,0,2.38-1.18A6.15,6.15,0,0,0,315,104.25Z"
      fill="#fff"
    />
    <path
      d="M322.73,99.65a4.52,4.52,0,0,1,3.67-1.73q3.94,0,4,4.44v8.2h-2.12v-8.21a2.89,2.89,0,0,0-.61-2,2.47,2.47,0,0,0-1.88-.64,3,3,0,0,0-1.81.55,3.75,3.75,0,0,0-1.22,1.45v8.84h-2.12V92.94h2.12Z"
      fill="#fff"
    />
    <path
      d="M334.26,113.89l-1.21-.82a5.57,5.57,0,0,0,1.13-3.1v-1.92h2.07v1.66a5.31,5.31,0,0,1-.56,2.32A5.46,5.46,0,0,1,334.26,113.89Z"
      fill="#fff"
    />
    <path
      d="M354,110.56a4.6,4.6,0,0,1-.3-1.3,4.73,4.73,0,0,1-3.53,1.53,4.34,4.34,0,0,1-3-1,3.34,3.34,0,0,1-1.18-2.63,3.49,3.49,0,0,1,1.48-3,6.89,6.89,0,0,1,4.14-1.07h2.07v-1a2.36,2.36,0,0,0-.67-1.77,2.65,2.65,0,0,0-2-.66,3.08,3.08,0,0,0-1.9.57,1.68,1.68,0,0,0-.77,1.39H346.2a2.94,2.94,0,0,1,.66-1.8,4.63,4.63,0,0,1,1.79-1.37,6.19,6.19,0,0,1,2.49-.5A4.93,4.93,0,0,1,354.5,99a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM350.45,109a3.76,3.76,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34v-2.55H352q-3.92,0-3.91,2.28a1.93,1.93,0,0,0,.67,1.56A2.52,2.52,0,0,0,350.45,109Z"
      fill="#fff"
    />
    <path
      d="M364.85,109.06a3.06,3.06,0,0,0,2-.69,2.4,2.4,0,0,0,.94-1.72h2a3.86,3.86,0,0,1-.73,2,5,5,0,0,1-1.81,1.54,5.26,5.26,0,0,1-2.39.57,5.11,5.11,0,0,1-4-1.69,6.73,6.73,0,0,1-1.5-4.63v-.35a7.55,7.55,0,0,1,.67-3.23,5,5,0,0,1,1.91-2.19,5.42,5.42,0,0,1,2.94-.78,5,5,0,0,1,3.47,1.25,4.44,4.44,0,0,1,1.48,3.25h-2a2.88,2.88,0,0,0-.91-2,2.83,2.83,0,0,0-2-.78,3,3,0,0,0-2.5,1.17,5.41,5.41,0,0,0-.89,3.37v.4a5.34,5.34,0,0,0,.88,3.3A3,3,0,0,0,364.85,109.06Z"
      fill="#fff"
    />
    <path d="M383.63,107.68l3.08-9.53h2.17l-4.46,12.41h-1.61l-4.5-12.41h2.17Z" fill="#fff" />
    <path
      d="M396.7,110.79a5.42,5.42,0,0,1-4.11-1.65A6.16,6.16,0,0,1,391,104.7v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V105h-8.41a4.42,4.42,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,396.7,110.79Zm-.27-11.13a2.86,2.86,0,0,0-2.16.94,4.5,4.5,0,0,0-1.07,2.62h6.22v-.16a4.12,4.12,0,0,0-.88-2.51A2.67,2.67,0,0,0,396.43,99.66Z"
      fill="#fff"
    />
    <path
      d="M412,107.27a1.58,1.58,0,0,0-.65-1.34,6.41,6.41,0,0,0-2.26-.82,10.71,10.71,0,0,1-2.56-.82,3.66,3.66,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,406,99a5.06,5.06,0,0,1,3.3-1A5.16,5.16,0,0,1,412.69,99,3.47,3.47,0,0,1,414,101.8h-2.14a1.91,1.91,0,0,0-.74-1.51,2.76,2.76,0,0,0-1.86-.63,2.9,2.9,0,0,0-1.82.51,1.58,1.58,0,0,0-.65,1.32,1.28,1.28,0,0,0,.61,1.16,7.27,7.27,0,0,0,2.2.74,11.5,11.5,0,0,1,2.57.85,3.71,3.71,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.48,1.69,3.17,3.17,0,0,1-1.33,2.67,5.58,5.58,0,0,1-3.45,1,6.25,6.25,0,0,1-2.64-.52,4.34,4.34,0,0,1-1.8-1.48,3.56,3.56,0,0,1-.65-2.05h2.13a2.2,2.2,0,0,0,.85,1.7,3.35,3.35,0,0,0,2.11.62,3.39,3.39,0,0,0,1.93-.49A1.48,1.48,0,0,0,412,107.27Z"
      fill="#fff"
    />
    <path
      d="M420.54,95.14v3h2.32v1.64h-2.32v7.71a1.76,1.76,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V98.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M426.13,94.85a1.27,1.27,0,0,1,.31-.87,1.21,1.21,0,0,1,.94-.35,1.24,1.24,0,0,1,.94.35,1.28,1.28,0,0,1,.32.87,1.14,1.14,0,0,1-1.26,1.21,1.2,1.2,0,0,1-.94-.34A1.24,1.24,0,0,1,426.13,94.85Zm2.29,15.71H426.3V98.15h2.12Z"
      fill="#fff"
    />
    <path
      d="M443.1,104.49a7.41,7.41,0,0,1-1.3,4.58,4.2,4.2,0,0,1-3.52,1.72,4.31,4.31,0,0,1-3.63-1.66l-.11,1.43h-2V92.94h2.13v6.57a4.29,4.29,0,0,1,3.54-1.59,4.2,4.2,0,0,1,3.55,1.71,7.61,7.61,0,0,1,1.29,4.68Zm-2.12-.24a5.74,5.74,0,0,0-.84-3.35,2.77,2.77,0,0,0-2.41-1.18,3.1,3.1,0,0,0-3,2V107a3.2,3.2,0,0,0,3,1.95,2.74,2.74,0,0,0,2.37-1.18A6.07,6.07,0,0,0,441,104.25Z"
      fill="#fff"
    />
    <path
      d="M454.22,109.34a4.52,4.52,0,0,1-3.64,1.45,3.85,3.85,0,0,1-3-1.15,4.92,4.92,0,0,1-1.05-3.41V98.15h2.12v8c0,1.88.76,2.82,2.29,2.82a3.18,3.18,0,0,0,3.24-1.81v-9h2.12v12.41h-2Z"
      fill="#fff"
    />
    <path d="M462.59,110.56h-2.12V92.94h2.12Z" fill="#fff" />
    <path
      d="M474.43,109.34a4.52,4.52,0,0,1-3.64,1.45,3.88,3.88,0,0,1-3-1.15,5,5,0,0,1-1-3.41V98.15h2.12v8c0,1.88.76,2.82,2.29,2.82a3.19,3.19,0,0,0,3.24-1.81v-9h2.12v12.41h-2Z"
      fill="#fff"
    />
    <path
      d="M482.49,98.15l.06,1.38a4.55,4.55,0,0,1,3.68-1.61,3.52,3.52,0,0,1,3.54,2,4.63,4.63,0,0,1,4-2c2.73,0,4.11,1.44,4.16,4.32v8.32h-2.13v-8.19a2.87,2.87,0,0,0-.6-2,2.7,2.7,0,0,0-2.05-.66,2.83,2.83,0,0,0-2,.71,2.88,2.88,0,0,0-.91,1.9v8.23h-2.13v-8.13c0-1.81-.88-2.71-2.65-2.71a2.85,2.85,0,0,0-2.86,1.78v9.06h-2.12V98.15Z"
      fill="#fff"
    />
    <path
      d="M511.34,95.14v3h2.32v1.64h-2.32v7.71a1.7,1.7,0,0,0,.31,1.12,1.27,1.27,0,0,0,1.05.37,4.58,4.58,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.61,2.61,0,0,1-2.14-.86,3.65,3.65,0,0,1-.73-2.44v-7.7H507V98.15h2.26v-3Z"
      fill="#fff"
    />
    <path
      d="M522.06,110.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.34,7.34,0,0,1,.71-3.3,5.46,5.46,0,0,1,2-2.27,4.92,4.92,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.34,4.57V105h-8.41a4.38,4.38,0,0,0,1.08,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,522.06,110.79Zm-.26-11.13a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.68,2.68,0,0,0,521.8,99.66Z"
      fill="#fff"
    />
    <path d="M532.44,110.56h-2.12V92.94h2.12Z" fill="#fff" />
    <path d="M538.92,110.56H536.8V92.94h2.12Z" fill="#fff" />
    <path
      d="M550.76,109.34a4.52,4.52,0,0,1-3.64,1.45,3.88,3.88,0,0,1-3-1.15,5,5,0,0,1-1-3.41V98.15h2.12v8c0,1.88.76,2.82,2.29,2.82a3.19,3.19,0,0,0,3.24-1.81v-9h2.12v12.41h-2Z"
      fill="#fff"
    />
    <path
      d="M564.05,107.27a1.58,1.58,0,0,0-.65-1.34,6.33,6.33,0,0,0-2.26-.82,10.86,10.86,0,0,1-2.56-.82,3.74,3.74,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,558,99a5.06,5.06,0,0,1,3.3-1A5.14,5.14,0,0,1,564.74,99a3.47,3.47,0,0,1,1.32,2.79h-2.14a1.91,1.91,0,0,0-.74-1.51,2.76,2.76,0,0,0-1.86-.63,2.87,2.87,0,0,0-1.81.51,1.57,1.57,0,0,0-.66,1.32,1.28,1.28,0,0,0,.61,1.16,7.38,7.38,0,0,0,2.2.74,11.33,11.33,0,0,1,2.57.85,3.83,3.83,0,0,1,1.47,1.19,3,3,0,0,1,.47,1.69,3.17,3.17,0,0,1-1.33,2.67,5.58,5.58,0,0,1-3.45,1,6.25,6.25,0,0,1-2.64-.52,4.4,4.4,0,0,1-1.8-1.48,3.56,3.56,0,0,1-.65-2.05h2.13a2.23,2.23,0,0,0,.85,1.7,4,4,0,0,0,4,.13A1.48,1.48,0,0,0,564.05,107.27Z"
      fill="#fff"
    />
    <path
      d="M586.8,104.49a7.46,7.46,0,0,1-1.3,4.57,4.15,4.15,0,0,1-3.51,1.73,4.53,4.53,0,0,1-3.56-1.43v6h-2.12V98.15h1.94l.1,1.38a4.35,4.35,0,0,1,3.6-1.61,4.23,4.23,0,0,1,3.54,1.69,7.47,7.47,0,0,1,1.31,4.69Zm-2.13-.24a5.51,5.51,0,0,0-.89-3.31,2.9,2.9,0,0,0-2.46-1.22,3.12,3.12,0,0,0-2.89,1.71v5.93a3.14,3.14,0,0,0,2.91,1.7,2.86,2.86,0,0,0,2.43-1.21A6,6,0,0,0,584.67,104.25Z"
      fill="#fff"
    />
    <path
      d="M592.39,99.65a4.52,4.52,0,0,1,3.67-1.73q3.95,0,4,4.44v8.2h-2.12v-8.21a2.89,2.89,0,0,0-.61-2,2.47,2.47,0,0,0-1.88-.64,3,3,0,0,0-1.81.55,3.75,3.75,0,0,0-1.22,1.45v8.84h-2.12V92.94h2.12Z"
      fill="#fff"
    />
    <path
      d="M611.65,110.56a4.6,4.6,0,0,1-.3-1.3,4.73,4.73,0,0,1-3.53,1.53,4.32,4.32,0,0,1-3-1,3.34,3.34,0,0,1-1.18-2.63,3.51,3.51,0,0,1,1.47-3,6.94,6.94,0,0,1,4.15-1.07h2.07v-1a2.36,2.36,0,0,0-.67-1.77,2.67,2.67,0,0,0-2-.66,3.08,3.08,0,0,0-1.9.57,1.68,1.68,0,0,0-.77,1.39h-2.14a2.94,2.94,0,0,1,.66-1.8,4.63,4.63,0,0,1,1.79-1.37,6.15,6.15,0,0,1,2.49-.5A4.93,4.93,0,0,1,612.18,99a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM608.13,109a3.76,3.76,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34v-2.55h-1.67q-3.9,0-3.9,2.28a1.9,1.9,0,0,0,.67,1.56A2.52,2.52,0,0,0,608.13,109Z"
      fill="#fff"
    />
    <path
      d="M623.55,100.05a6.89,6.89,0,0,0-1-.08,2.8,2.8,0,0,0-2.83,1.78v8.81h-2.13V98.15h2.07l0,1.43a3.31,3.31,0,0,1,3-1.66,2.17,2.17,0,0,1,.95.16Z"
      fill="#fff"
    />
    <path
      d="M631.21,110.79a5.42,5.42,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.59-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.67,4.67,0,0,1,3.77,1.59,7,7,0,0,1,1.34,4.57V105h-8.41a4.42,4.42,0,0,0,1.07,3,3.37,3.37,0,0,0,2.61,1.13,3.7,3.7,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,631.21,110.79Zm-.27-11.13a2.86,2.86,0,0,0-2.16.94,4.5,4.5,0,0,0-1.07,2.62h6.22v-.16a4.12,4.12,0,0,0-.88-2.51A2.67,2.67,0,0,0,630.94,99.66Z"
      fill="#fff"
    />
    <path
      d="M642.16,95.14v3h2.32v1.64h-2.32v7.71a1.7,1.7,0,0,0,.31,1.12,1.3,1.3,0,0,0,1,.37,4.51,4.51,0,0,0,1-.14v1.72a6.19,6.19,0,0,1-1.62.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V98.15H640v-3Z"
      fill="#fff"
    />
    <path
      d="M653.73,100.05a6.76,6.76,0,0,0-1-.08,2.82,2.82,0,0,0-2.84,1.78v8.81h-2.12V98.15h2.07l0,1.43a3.3,3.3,0,0,1,3-1.66,2.12,2.12,0,0,1,.94.16Z"
      fill="#fff"
    />
    <path
      d="M663.66,110.56a4.34,4.34,0,0,1-.29-1.3,4.76,4.76,0,0,1-3.54,1.53,4.34,4.34,0,0,1-3-1,3.37,3.37,0,0,1-1.18-2.63,3.51,3.51,0,0,1,1.48-3,6.9,6.9,0,0,1,4.15-1.07h2.06v-1a2.39,2.39,0,0,0-.66-1.77,2.7,2.7,0,0,0-2-.66,3.1,3.1,0,0,0-1.9.57,1.7,1.7,0,0,0-.77,1.39H655.9a2.94,2.94,0,0,1,.66-1.8,4.56,4.56,0,0,1,1.79-1.37,6.1,6.1,0,0,1,2.48-.5A4.93,4.93,0,0,1,664.19,99a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM660.14,109a3.73,3.73,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34v-2.55h-1.66q-3.9,0-3.9,2.28a1.93,1.93,0,0,0,.66,1.56A2.56,2.56,0,0,0,660.14,109Z"
      fill="#fff"
    />
    <path
      d="M681.3,110.79a5.45,5.45,0,0,1-4.11-1.65,6.2,6.2,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.71-3.3,5.53,5.53,0,0,1,2-2.27,5,5,0,0,1,2.75-.82,4.65,4.65,0,0,1,3.76,1.59,6.9,6.9,0,0,1,1.34,4.57V105h-8.41a4.38,4.38,0,0,0,1.08,3,3.37,3.37,0,0,0,2.61,1.13,3.64,3.64,0,0,0,1.9-.46,4.76,4.76,0,0,0,1.37-1.21l1.29,1A5.21,5.21,0,0,1,681.3,110.79ZM681,99.66a2.85,2.85,0,0,0-2.16.94,4.57,4.57,0,0,0-1.08,2.62H684v-.16a4.06,4.06,0,0,0-.87-2.51A2.67,2.67,0,0,0,681,99.66Z"
      fill="#fff"
    />
    <path
      d="M688.87,104.25a7.35,7.35,0,0,1,1.34-4.62,4.31,4.31,0,0,1,3.56-1.71,4.25,4.25,0,0,1,3.55,1.61l.1-1.38h1.94v12.12a5,5,0,0,1-1.43,3.79,5.25,5.25,0,0,1-3.84,1.39,6.46,6.46,0,0,1-2.63-.57,4.57,4.57,0,0,1-2-1.57l1.1-1.28a4.15,4.15,0,0,0,3.34,1.69,3.26,3.26,0,0,0,2.42-.87,3.32,3.32,0,0,0,.86-2.46v-1.07a4.33,4.33,0,0,1-3.47,1.47,4.23,4.23,0,0,1-3.53-1.75A7.68,7.68,0,0,1,688.87,104.25Zm2.14.24a5.53,5.53,0,0,0,.86,3.3,2.78,2.78,0,0,0,2.41,1.2,3.1,3.1,0,0,0,2.94-1.82V101.5a3.13,3.13,0,0,0-2.92-1.78,2.81,2.81,0,0,0-2.42,1.21A6.05,6.05,0,0,0,691,104.49Z"
      fill="#fff"
    />
    <path
      d="M708.49,110.79a5.45,5.45,0,0,1-4.11-1.65,6.16,6.16,0,0,1-1.58-4.44v-.39a7.47,7.47,0,0,1,.7-3.3,5.48,5.48,0,0,1,2-2.27,4.91,4.91,0,0,1,2.74-.82A4.67,4.67,0,0,1,712,99.51a7,7,0,0,1,1.34,4.57V105h-8.41a4.42,4.42,0,0,0,1.07,3,3.4,3.4,0,0,0,2.61,1.13,3.68,3.68,0,0,0,1.91-.46,4.73,4.73,0,0,0,1.36-1.21l1.3,1A5.21,5.21,0,0,1,708.49,110.79Zm-.27-11.13a2.84,2.84,0,0,0-2.15.94,4.57,4.57,0,0,0-1.08,2.62h6.22v-.16a4.06,4.06,0,0,0-.87-2.51A2.69,2.69,0,0,0,708.22,99.66Z"
      fill="#fff"
    />
    <path
      d="M719.44,95.14v3h2.32v1.64h-2.32v7.71a1.7,1.7,0,0,0,.31,1.12,1.31,1.31,0,0,0,1.06.37,4.66,4.66,0,0,0,1-.14v1.72a6.26,6.26,0,0,1-1.63.22,2.63,2.63,0,0,1-2.15-.86,3.7,3.7,0,0,1-.72-2.44v-7.7h-2.26V98.15h2.26v-3Z"
      fill="#fff"
    />
    <path d="M733.91,110.56h-2.12V92.94h2.12Z" fill="#fff" />
    <path
      d="M745.75,110.56a4.6,4.6,0,0,1-.3-1.3,4.73,4.73,0,0,1-3.53,1.53,4.32,4.32,0,0,1-3-1,3.34,3.34,0,0,1-1.18-2.63,3.51,3.51,0,0,1,1.47-3,6.94,6.94,0,0,1,4.15-1.07h2.07v-1a2.36,2.36,0,0,0-.67-1.77,2.67,2.67,0,0,0-2-.66,3.08,3.08,0,0,0-1.9.57,1.68,1.68,0,0,0-.77,1.39H738a2.94,2.94,0,0,1,.66-1.8,4.63,4.63,0,0,1,1.79-1.37,6.15,6.15,0,0,1,2.49-.5A4.93,4.93,0,0,1,746.28,99a3.88,3.88,0,0,1,1.26,3v5.71a7,7,0,0,0,.44,2.72v.18ZM742.23,109a3.79,3.79,0,0,0,1.89-.52,3.1,3.1,0,0,0,1.3-1.34v-2.55h-1.67q-3.9,0-3.9,2.28a1.9,1.9,0,0,0,.67,1.56A2.52,2.52,0,0,0,742.23,109Z"
      fill="#fff"
    />
    <path
      d="M758.87,107.27a1.56,1.56,0,0,0-.65-1.34,6.33,6.33,0,0,0-2.26-.82,11,11,0,0,1-2.56-.82,3.74,3.74,0,0,1-1.4-1.15,2.74,2.74,0,0,1-.45-1.58A3.24,3.24,0,0,1,752.84,99a5.06,5.06,0,0,1,3.3-1A5.19,5.19,0,0,1,759.57,99a3.49,3.49,0,0,1,1.31,2.79h-2.13a1.94,1.94,0,0,0-.74-1.51,2.8,2.8,0,0,0-1.87-.63,2.87,2.87,0,0,0-1.81.51,1.57,1.57,0,0,0-.66,1.32,1.3,1.3,0,0,0,.61,1.16,7.38,7.38,0,0,0,2.2.74,11.61,11.61,0,0,1,2.58.85,3.86,3.86,0,0,1,1.46,1.19,2.9,2.9,0,0,1,.47,1.69,3.17,3.17,0,0,1-1.33,2.67,5.54,5.54,0,0,1-3.45,1,6.32,6.32,0,0,1-2.64-.52,4.37,4.37,0,0,1-1.79-1.48,3.49,3.49,0,0,1-.65-2.05h2.12a2.23,2.23,0,0,0,.85,1.7,4,4,0,0,0,4,.13A1.48,1.48,0,0,0,758.87,107.27Z"
      fill="#fff"
    />
    <path
      d="M764.58,109.45a1.33,1.33,0,0,1,.33-.92,1.25,1.25,0,0,1,1-.36,1.28,1.28,0,0,1,1,.36,1.29,1.29,0,0,1,.34.92,1.2,1.2,0,0,1-.34.88,1.28,1.28,0,0,1-1,.36,1.25,1.25,0,0,1-1-.36A1.23,1.23,0,0,1,764.58,109.45Z"
      fill="#fff"
    />
    <path
      d="M771.54,109.45a1.33,1.33,0,0,1,.33-.92,1.25,1.25,0,0,1,1-.36,1.28,1.28,0,0,1,1,.36,1.29,1.29,0,0,1,.34.92,1.2,1.2,0,0,1-.34.88,1.28,1.28,0,0,1-1,.36,1.25,1.25,0,0,1-1-.36A1.23,1.23,0,0,1,771.54,109.45Z"
      fill="#fff"
    />
    <path
      d="M778.5,109.45a1.33,1.33,0,0,1,.33-.92,1.25,1.25,0,0,1,1-.36,1.28,1.28,0,0,1,1,.36,1.29,1.29,0,0,1,.34.92,1.2,1.2,0,0,1-.34.88,1.28,1.28,0,0,1-1,.36,1.25,1.25,0,0,1-1-.36A1.23,1.23,0,0,1,778.5,109.45Z"
      fill="#fff"
    />
    <circle cx="25.55" cy="36.36" fill="#22ad01" r="7.67" />
  </svg>
);

export default LiveCamWidgetSkin1Icon;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { CopyValueWidget, Switch } from '../../../components/reusables';
import BankIcon from '../../../components/reusables/svg/BankIcon.react';
import { Gtm } from '../../../utils/gtm';
import intercom from '../../../utils/intercom';
import { connect } from 'react-redux';
import { openModal } from '../../Modal/modal-actions/modal-actions.js';
import { explodeDescription } from '../../../utils/helpers';

const OfferTrackingLinkDisplay = ({
  currentUrlId,
  campaignLink,
  creativeLink,
  offerScreenshots,
  dispatch,
  fields,
  offer,
  offerUrl,
  profileData,
  trackingLink,
  generateCustomLink,
  trakingLinkFormValues,
  isAutoOptimized,
  isReferrals,
  skinConfigurations,
  changeCurrentOffer,
  performerLandingIds,
  trendingPerformers,
}) => {
  const [viewMode, setViewMode] = useState('desktop');

  const activeLandingPages = offer.landing_pages?.filter((landingPage) => landingPage.status === 'active');

  const hasManyLandingPage = activeLandingPages?.length > 1;

  const currentOfferUrl =
    isAutoOptimized && currentUrlId === undefined
      ? offerUrl.find((o) => o.id === 'optimized')
      : currentUrlId
      ? offerUrl.find((o) => o.id === currentUrlId)
      : offerUrl.find((o) => o.id === '0');

  const handleCopy = () => {
    Gtm.event('UI Component', 'Click', 'Console-Offer-Copy-Link');
    intercom.update(profileData, {
      company: { latest_copied_link_offer: offer.name },
    });
    intercom.trackEvent(offer.verticals ? `Offer-link-${offer.verticals.map((ver) => ver.name).join(', ')}` : 'Offer-link', {
      Offer_id: offer.id,
      Offer_name: offer.name,
    });
  };

  const handleViewModeChange = (e) => {
    e.preventDefault();

    const newViewMode =
      /* $("span").click() */ e.target.getAttribute('data-viewmode') ||
      /* $("i").click() */ e.target.parentNode.getAttribute('data-viewmode');

    if (newViewMode !== viewMode) {
      setViewMode(newViewMode);
    }
  };

  const handlePopUnder = (isActivating = false) => {
    Gtm.event('offers', 'Click', `pop-unders ${isActivating ? 'on' : 'off'}`);

    trakingLinkFormValues.popUnder = !fields.popUnder.value;

    generateCustomLink();
  };

  const handleBackOffers = (isActivating = false, generateLink) => {
    Gtm.event('offers', 'Click', `back-offers ${isActivating ? 'on' : 'off'}`);

    trakingLinkFormValues.bo = isActivating === false ? undefined : offer.backOffersList;

    if (generateLink) {
      generateCustomLink();
    }
  };

  const devices = [
    { name: 'desktop', icon: 'desktop_windows' },
    { name: 'mobile', icon: 'phone_iphone' },
  ];

  const getAvailableDevices = () => devices.filter((device) => offerScreenshots?.[device.name]);

  const availableDevices = getAvailableDevices();

  const handleShowModal = () => {
    dispatch(
      openModal({
        name: 'OfferLandingPagesModal',
        modalProps: {
          dispatch,
          offer,
          offerUrl,
          offerScreenshots,
          currentOfferUrl,
          availableDevices,
          viewMode,
          handleViewModeChange,
          isReferrals,
          isDirectToModel: performerLandingIds?.length > 0,
          skinConfigurations,
          closeButton: false,
          isAutoOptimized,
          changeCurrentOffer,
          model: fields.model,
          className: 'offer-landing-page-modal',
          trendingPerformers,
          performerLandingIds,
        },
      })
    );
    Gtm.event('offers', 'Click', 'best offers select');
  };

  const showTrackingLinkType =
    fields.url_id.value === 'optimized'
      ? campaignLink || trackingLink
      : offer.creativeId
      ? creativeLink
      : trackingLink || creativeLink || campaignLink;

  useEffect(() => {
    handleBackOffers(true);
  }, [offer.backOffersList]);

  return (
    <div id="offer-tracking-link-display">
      <CopyValueWidget
        dispatch={dispatch}
        id="smart-link-trackinglink"
        label={messages.offerYourLink}
        onCopy={handleCopy}
        value={showTrackingLinkType}
      />
      {hasManyLandingPage ? (
        <div className="landing-notif">
          <p>
            <BankIcon />
            <span className="gold">Note: </span> This offer contains {activeLandingPages.length} landing pages options. For
            optimal conversion rates, select the best option according to your audience.
          </p>
          <span className="btn-open-modal primary-color" id="screenshot" onClick={handleShowModal}>
            Select
          </span>
        </div>
      ) : null}
      {!creativeLink && (!offer.noPop || offer.backOffersList) ? (
        <div id="extra-earnings">
          <div className="label">
            <strong>
              <FormattedMessage {...messages.genericTextExtraEarnings} />
            </strong>
            &nbsp;
            <FormattedMessage {...messages.genericTextExtraEarningsDescription} />
          </div>
          <div className="switches">
            {!offer.noPop && (
              <Switch
                field={fields.popUnder}
                label={messages.genericTextPopUnders}
                onChange={() => handlePopUnder(fields.popUnder.value)}
                value
              />
            )}
            {offer.backOffersList ? (
              <Switch
                field={fields.bo}
                label={messages.genericTextBackOffers}
                onChange={() => handleBackOffers(!fields.bo.value, true)}
                value={offer.backOffersList}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

OfferTrackingLinkDisplay.propTypes = {
  campaignLink: PropTypes.string,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  profileData: PropTypes.any.isRequired,
  trackingLink: PropTypes.string.isRequired,
};

export default connect((state) => ({
  profileData: state.profile.data,
  offer: state.offer.offer,
  creativeLink: state.offer.creativeLink,
}))(injectIntl(OfferTrackingLinkDisplay));

const InformationIcon = ({ fillColor = '#707070', className }) => (
  <svg className={className} fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M21.1831 23.1139C22.5688 21.7282 23.227 19.4099 23.227 15.5271C23.227 11.6444 22.5688 9.32609 21.1831 7.94044C19.7975 6.5548 17.4791 5.89654 13.5964 5.89654C9.7137 5.89654 7.39537 6.5548 6.00973 7.94044C4.62408 9.32609 3.96582 11.6444 3.96582 15.5271C3.96582 19.4099 4.62408 21.7282 6.00973 23.1139C7.39537 24.4995 9.7137 25.1577 13.5964 25.1577C17.4791 25.1577 19.7975 24.4995 21.1831 23.1139ZM22.3848 24.3156C20.4953 26.2052 17.5734 26.8573 13.5964 26.8573C9.61945 26.8573 6.6976 26.2052 4.80798 24.3156C2.91837 22.4259 2.2663 19.5041 2.2663 15.5271C2.2663 11.5502 2.91837 8.62831 4.80798 6.7387C6.6976 4.84909 9.61945 4.19702 13.5964 4.19702C17.5734 4.19702 20.4953 4.84909 22.3848 6.7387C24.2745 8.62831 24.9265 11.5502 24.9265 15.5271C24.9265 19.5041 24.2745 22.4259 22.3848 24.3156Z"
      fill={fillColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.5964 20.7901C13.1271 20.7901 12.7466 20.4096 12.7466 19.9403V15.5272C12.7466 15.058 13.1271 14.6775 13.5964 14.6775C14.0658 14.6775 14.4461 15.058 14.4461 15.5272V19.9403C14.4461 20.4096 14.0658 20.7901 13.5964 20.7901Z"
      fill={fillColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.4511 11.5617C14.4511 12.031 14.0708 12.4115 13.6014 12.4115H13.5912C13.1219 12.4115 12.7414 12.031 12.7414 11.5617C12.7414 11.0924 13.1219 10.7119 13.5912 10.7119H13.6014C14.0708 10.7119 14.4511 11.0924 14.4511 11.5617Z"
      fill={fillColor}
      fillRule="evenodd"
    />
  </svg>
);

export default InformationIcon;

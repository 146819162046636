import PropTypes from 'prop-types';

import { CopyValueWidget } from '../../../reusables';

const OfferPostbacksLists = ({ dispatch, handleDeletePostback, postbacks }) => (
  <div id="postbacks-list">
    {postbacks.map((postback) => (
      <CopyValueWidget
        classNames={['postback']}
        dispatch={dispatch}
        id={`postback-${postback.id}`}
        key={`postback-${postback.id}`}
        labelTxt={postback.goalName}
        onDelete={() => handleDeletePostback(postback.id)}
        value={postback.code}
      />
    ))}
  </div>
);

OfferPostbacksLists.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleDeletePostback: PropTypes.func.isRequired,
  postbacks: PropTypes.array.isRequired,
};

export default OfferPostbacksLists;

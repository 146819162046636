import {
  LiveCamWidgetSkin0Icon,
  LiveCamWidgetSkin1Icon,
  LiveCamWidgetSkin2Icon,
  LiveCamWidgetSkin3Icon,
} from '../../components/reusables/svg';

export const categoryList = [
  'Anal',
  'Arabic',
  'Asian',
  'Athletic',
  'BBW',
  'Bigass',
  'Bigtits',
  'Blonde',
  'Blowjob',
  'Booty',
  'Brunette',
  'Caucasian',
  'Curvaceous',
  'Dance',
  'Dark',
  'Dyed',
  'Ebony',
  'Feet',
  'Ginger',
  'Hairy',
  'Hispanic',
  'Latina',
  'Lovense',
  'Masturbation',
  'Middle East',
  'MILF',
  'Muscular',
  'Native American',
  'Oral',
  'Oriental',
  'Petite',
  'Redhead',
  'Romantic',
  'Slim',
  'Tattoo',
  'Teen',
  'Toys',
];

export const LanguagesList = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Spanish',
    value: 'es',
  },
  {
    text: 'German',
    value: 'de',
  },
  {
    text: 'French',
    value: 'fr',
  },
  {
    text: 'Italian',
    value: 'it',
  },
  {
    text: 'Portuguese',
    value: 'pt',
  },
  {
    text: 'Swedish',
    value: 'sv',
  },
  {
    text: 'Dutch',
    value: 'nl',
  },
];

export const CodesTypes = [
  {
    title: 'Display',
    id: 'script',
    image: 'script',
    labelId: 'script',
    code: '<script src="{src}"></script>',
    remote: '',
    eventLabel: 'Livecam-Generator-Widget-Script',
    isOfferExclusive: false,
    settings: {
      templateSettings: {},
      layoutSettings: {
        layoutType: 'custom',
        columns: '4',
        rows: '1',
      },
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
    blockedTabs: [],
  },
  {
    title: 'IM Ads Code',
    id: 'im',
    image: 'im',
    labelId: 'imAds',
    code: '<script src="{src}"></script>',
    remote: '{src}',
    eventLabel: 'Livecam-Generator-Widget-ImAds',
    isOfferExclusive: false,
    settings: {
      templateSettings: {},
      layoutSettings: {
        layoutType: 'custom',
        columns: '1',
        rows: '1',
      },
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
    blockedTabs: [],
  },
  {
    title: 'Chat Head Code',
    id: 'im_jerky',
    image: 'im_jerky',
    labelId: 'chatHead',
    code: '<script defer src="{src}"></script>',
    remote: '{src}',
    eventLabel: 'Livecam-Generator-Widget-imJerkyChatHead',
    isOfferExclusive: true,
    group: 'im',
    settings: {
      templateSettings: {},
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
    blockedTabs: ['templateSettings', 'layoutSettings', 'thumbnailsSettings', 'infobarSettings', 'cssSettings'],
  },
  {
    title: 'Chat Head Code',
    id: 'im_freecams',
    image: 'im_jerky',
    labelId: 'chatHead',
    code: '<script defer src="{src}"></script>',
    remote: '{src}',
    eventLabel: 'Livecam-Generator-Widget-imFreeCamsChatHead',
    isOfferExclusive: true,
    group: 'im',
    settings: {
      templateSettings: {},
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
    blockedTabs: ['templateSettings', 'layoutSettings', 'thumbnailsSettings', 'infobarSettings', 'cssSettings'],
  },
];

export const defaultSettings = {
  typeSettings: {},
  templateSettings: {
    skin: '1',
  },
  layoutSettings: {
    iframeSizes: 'auto', // custom
    iframeWidth: '300',
    iframeHeight: '300',
    layoutType: 'custom',
    columns: '4',
    rows: '1',
    backgroundType: 'transparent', // color
    backgroundColor: '#ffffff',
  },
  thumbnailsSettings: {
    feed: 'live',
    ratio: '1',
    spacing: '10',
    colorFilterFlag: '0',
    colorFilter: '#bb4466',
    colorFilterOpacity: '12',
    borderFlag: '0',
    borderColor: '#000000',
    borderWidth: '1',
    cornerFlag: '0',
    cornerRadius: '10',
  },
  infobarSettings: {
    infobarIsCustomizable: false,
    infobarDisplay: 'default',
    infoFields: ['name', 'age', 'gender', 'live'],
    fontFamily: 'Arial',
    fontSize: '12',
    fontColor: '#ffffff',
    infobarPositionIO: 'inside',
    infobarPositionTB: 'bottom',
    backgroundType: 'color',
    infobarBackground: '#666666',
  },
  cssSettings: {
    customcss: '',
    exampleCss:
      '.cams-widget {  } // main container\n\n.cw-card {  } // card container\n.cw-card-border {  } // card link\n\n.cw-thumbnail {  } // picture\n.cw-thumbnail-border {  } // picture-border\n.cw-thumbnail-ctn {  } // picture container\n\n.cw-info-ctn {  } // infobar container\n\n.cw-name {  } // info name\n.cw-age {  } // info age\n.cw-gender {  } // info gender\n.cw-gender-f {  } // info gender female\n.cw-gender-m {  } // info gender male',
  },
};

const templates = [
  {
    name: '',
    svg: LiveCamWidgetSkin1Icon,
    settings: {
      typeSettings: {},
      templateSettings: {
        skin: '1',
      },
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
  },
  {
    name: '',
    svg: LiveCamWidgetSkin2Icon,
    settings: {
      typeSettings: {},
      templateSettings: {
        skin: '2',
      },
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
  },
  {
    name: '',
    svg: LiveCamWidgetSkin3Icon,
    settings: {
      typeSettings: {},
      templateSettings: {
        skin: '3',
      },
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {},
      cssSettings: {},
    },
  },
  {
    name: '',
    svg: LiveCamWidgetSkin0Icon,
    settings: {
      typeSettings: {},
      templateSettings: {
        skin: '0',
      },
      layoutSettings: {},
      thumbnailsSettings: {},
      infobarSettings: {
        infobarIsCustomizable: true,
      },
      cssSettings: {},
    },
  },
];

export const premadeTemplates = templates.map((temp) => ({
  ...temp,
  settings: {
    typeSettings: {
      ...defaultSettings.typeSettings,
      ...temp.settings.typeSettings,
    },
    templateSettings: {
      ...defaultSettings.templateSettings,
      ...temp.settings.templateSettings,
    },
    layoutSettings: {
      ...defaultSettings.layoutSettings,
      ...temp.settings.layoutSettings,
    },
    thumbnailsSettings: {
      ...defaultSettings.thumbnailsSettings,
      ...temp.settings.thumbnailsSettings,
    },
    infobarSettings: {
      ...defaultSettings.infobarSettings,
      ...temp.settings.infobarSettings,
    },
    cssSettings: {
      ...defaultSettings.cssSettings,
      ...temp.settings.cssSettings,
    },
  },
}));

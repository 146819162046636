const BadgeBg = () => (
  <svg className="badge-bg" fill="none" viewBox="0 0 1543 256" width="1543" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1536 0V255.38H1050.6L1030.85 217.25H505.15L485.4 255.38H0V0H485.4L505.15 38.13H1030.85L1050.6 0H1536Z"
      fill="url(#paint0_linear_355_1782)"
    />
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <mask
        height="256"
        id="mask0_355_1782"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="1537"
        x="6"
        y="0"
      >
        <path
          d="M1542.56 0V255.38H1057.16L1037.41 217.25H511.71L491.96 255.38H6.56006V0H491.96L511.71 38.13H1037.41L1057.16 0H1542.56Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_355_1782)">
        <path
          d="M747.89 28.96C703.78 56.03 653.51 26.87 655.75 -26.29C657.81 -75.04 702.98 -116.66 738.51 -130.64C769.82 -142.96 820.39 -131.98 829.34 -87.44C840.39 -32.44 787.36 4.73003 747.89 28.95V28.96Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.29"
        />
        <path
          d="M798.66 100.23C722.14 151.72 631.24 67.41 629.62 -11.32C628.13 -83.5 698.7 -135.53 753.27 -152.39C803.81 -168.01 854.84 -133.28 870.04 -76.97C876.38 -53.48 881 -27.47 878 -3.01999C872.23 44.02 835.61 75.37 798.66 100.23Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.35"
        />
        <path
          d="M817.24 129.45C784.94 148 716.58 173.33 681.63 141.53C641.42 104.94 610.48 54.1399 606.27 -0.950058C598.93 -97.0301 691.29 -156.28 767.74 -172.9C838.26 -188.22 885.6 -125.37 904.37 -59.3901C909.02 -43.0501 913.84 -26.7901 919.7 -10.8301C924.93 3.41994 935.09 19.7499 936.95 34.8999C938.36 46.3699 935.95 46.8399 927.55 54.1199C918.78 61.7099 909.31 68.4399 899.8 75.0499C872.76 93.8599 844.01 114.05 817.24 129.43V129.45Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.41"
        />
        <path
          d="M821.64 156.79C777.56 179.94 723.1 194.34 674.17 163.68C623.6 131.99 593.63 63.44 585.48 6.04999C569.08 -109.41 677.71 -178.96 779.19 -193.37C869.77 -206.23 915.97 -109.5 934.04 -37.15C938.77 -18.22 944.46 0.430017 953.42 17.83C957.22 25.21 972.61 41.79 972.2 49.77C971.96 54.46 966.14 61.69 963.63 65.15C951.85 81.43 933.34 92.2 916.31 102.05C884.72 120.32 851.53 141.09 821.64 156.79Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.48"
        />
        <path
          d="M818.29 186.34C767.95 210.79 711.43 239.56 662.79 190.32C615.44 142.38 580.2 76.96 566.88 11.05C554.37 -50.87 566.3 -108.46 616.97 -149.36C661.78 -185.53 726.78 -213.69 784.92 -215.05C833.26 -216.18 883.17 -178.88 911.77 -142.7C941.63 -104.94 952.26 -59.21 960.96 -12.93C965.78 12.71 974.63 33.17 984.75 56.79C990.38 69.93 992.46 84.28 984.97 98.46C975.72 115.97 953.73 124.79 936.57 132.37C896.72 149.99 855.54 168.23 818.28 186.33L818.29 186.34Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.54"
        />
        <path
          d="M809.75 217.47C779.08 231.32 748.78 258.36 715.85 266.26C687.87 272.97 667.75 253.18 652.28 232.28C628.11 199.62 612.29 161.5 593.91 125.52C575.77 90.03 560.05 54.1 549.95 15.57C532.05 -52.71 535.29 -119.6 593.72 -166.65C647.01 -209.56 719.88 -237.22 786.33 -235.89C813.48 -235.34 839.09 -221.99 861.37 -205.87C887.31 -187.1 913.03 -167.83 933.06 -142.56C967.8 -98.75 981.06 -43.97 987.32 10.53C990.42 37.54 996.32 61.62 1003.9 87.46C1007.91 101.14 1018.49 121.43 1013.64 135.73C1005.5 159.72 964.54 167.21 943.91 173.15C898.35 186.27 853.02 197.94 809.76 217.48L809.75 217.47Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.6"
        />
        <path
          d="M798.56 249.54C763.24 264.22 742.32 300.29 709.79 322.18C680.05 342.2 658.63 314.68 643.5 290.33C613.51 242.02 594.33 186.38 574.62 133.32C560.74 95.94 545.36 59.55 533.93 21.31C511.66 -53.18 501.11 -129.62 568.07 -183.56C629.76 -233.24 715.57 -251.2 790.87 -249.88C855.36 -248.75 913.28 -191.94 953.3 -143.72C992.97 -95.92 1012.32 -30.99 1015.4 30.3C1016.77 57.5 1011.64 90.02 1022.63 115.66C1030.16 133.22 1056.72 154.35 1053.18 174.89C1048.17 203.94 986.05 209.09 964.51 213.18C908.71 223.77 848.12 228.93 798.55 249.54H798.56Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.66"
        />
        <path
          d="M787.27 281.93C746.49 296.89 731.26 349.29 704.02 386.27C677.02 422.91 653.58 393.08 636.73 363.24C579.13 261.17 550.21 141.63 517.6 29.86C494.4 -49.66 465.3 -139.77 541.16 -199.56C608.87 -252.93 717.77 -259.81 798.14 -258.34C872.06 -256.99 926.39 -201.48 972.95 -147.08C1015.98 -96.81 1047.48 -23.48 1047.63 43.27C1047.71 77.7 1030.15 111.08 1050.56 142.13C1063.05 161.14 1106.11 188.85 1104.52 214.26C1102.41 248.11 1016.18 251.54 995.19 254.3C925.99 263.42 845.52 260.57 787.26 281.94L787.27 281.93Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.72"
        />
        <path
          d="M778.43 314.02C732.1 324.27 717.62 413.06 700.5 453.82C695.36 466.05 687.25 489.71 670.71 490.05C652.32 490.43 638.29 459.4 631.58 447C587.55 365.6 559.42 272.21 532.69 183.96C517.7 134.46 504.95 84.09 491.4 34.26C470.5 -42.64 433.9 -158.99 514.74 -213.62C554.02 -240.16 605.55 -247.55 651.54 -253.23C703.11 -259.6 756.56 -263.67 807.31 -262.89C884.83 -261.7 943.85 -213.83 992.65 -154.54C1015.83 -126.38 1039.79 -94.64 1051.36 -59.61C1062.46 -25.99 1070.06 11.61 1073.01 46.8C1074.79 67.95 1070.49 85.2 1067.69 105.71C1065.15 124.32 1073.49 140.85 1083.49 156.22C1097.24 177.34 1116.24 193.24 1134.39 210.41C1144.45 219.94 1165.23 236.4 1165.41 252.22C1165.61 269.31 1143.99 276.02 1130.78 280.03C1097.16 290.24 1061.11 293.28 1026.29 296.54C943.92 304.25 852.43 297.62 778.41 314L778.43 314.02Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.78"
        />
        <path
          d="M774.58 345.17C745.08 346.05 732.18 393.71 725.32 416.26C714.91 450.48 708.2 485.68 698.78 520.17C695.02 533.95 687.9 572.04 670.93 578.1C650.38 585.44 633.72 547.76 627.05 535.32C576.73 441.47 546.51 334.53 516.16 232.93C497.07 169.03 481.65 103.95 465.95 39.33C453.28 -12.83 442.17 -67.8 441.74 -121.81C441.39 -165.09 451.64 -202.92 491.57 -224.96C536.62 -249.82 593.31 -253.82 643.56 -258.11C701.38 -263.04 760.03 -265.38 817.63 -265.12C851.22 -264.97 885.04 -261.25 915.57 -245.65C952.82 -226.62 984.32 -195.49 1011.94 -164.66C1035.97 -137.84 1053.62 -115.03 1059.96 -79.52C1067.66 -36.37 1084.39 4.72001 1093.25 47.2C1098.03 70.12 1092.26 89.81 1092.79 112.21C1093.37 136.61 1114.66 160.81 1129.93 177.57C1149.58 199.13 1171.59 218.48 1192.72 238.55C1205.24 250.44 1230.6 268.17 1231.82 287.73C1233.19 309.8 1201.98 316.9 1186.05 320.97C1148.53 330.56 1109.33 333.7 1070.85 336.87C972.23 345 871.26 342.31 774.59 345.18L774.58 345.17Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.84"
        />
        <path
          d="M775.85 379.55C748.32 382.22 733.23 428.6 725.31 453.65C712.5 494.17 705.93 536.2 697.55 577.76C693.24 599.14 689.27 647.94 667.77 660.9C646.69 673.61 628.76 632.99 621.48 619.48C591.59 564.03 572.13 502.46 551.94 443.02C530.66 380.37 510.34 317.36 491.59 253.91C471.21 184.92 454.53 114.77 440.18 44.59C429.32 -8.48 422.32 -63.72 421.3 -118.12C420.42 -165.14 424.53 -212.92 472.34 -234.21C523.3 -256.9 585.02 -258.46 639.8 -261.73C702.52 -265.48 766.11 -266.18 828.28 -266.67C862.26 -266.94 897.36 -268.87 929.42 -254.61C968.12 -237.41 1000.96 -206.23 1030.53 -176.65C1040.19 -166.99 1059.32 -152.25 1060.64 -137.54C1061.46 -128.37 1056.06 -118.85 1056.08 -109.4C1056.13 -89.75 1064.45 -69.92 1071.54 -52C1084.51 -19.24 1100.37 13.05 1110.57 45.98C1114.67 59.23 1118.53 73.65 1118.56 87.99C1118.59 99.97 1115.78 110.33 1118.49 122.32C1124.58 149.23 1146.37 172.2 1165.13 191.08C1189.9 216.01 1217.62 237.73 1244.42 260.38C1260.48 273.95 1294.86 296.21 1296.64 319.79C1298.49 344.26 1266.71 351.88 1248.18 356.68C1195.04 370.45 1137.81 374.65 1083.16 375.54C980.8 377.2 866.61 370.77 775.85 379.56V379.55Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.9"
        />
        <path
          d="M779.57 421.55C752.63 424.5 734.29 465.63 724.52 491.2C708.4 533.37 699.7 578.2 690.91 622.34C686.63 643.82 682.68 665.41 677.5 686.7C674.71 698.14 671.87 721.07 661.7 729.08C639.42 746.62 619.42 700.51 612.39 686.46C583.81 629.37 563.73 567.32 542.93 507.11C518.22 435.55 494.76 363.53 472.38 291.2C447.84 211.91 425.47 131.74 413.1 49.65C404.7 -6.08004 402.68 -63.55 403.16 -119.9C403.57 -168.55 406.2 -220.22 457.34 -241.55C510.17 -263.59 577.2 -262.02 633.37 -264.81C701.65 -268.2 771.95 -267.88 838.49 -269.12C871.96 -269.74 908.6 -278.43 941.79 -265.93C980.22 -251.46 1018.93 -220.6 1047.03 -191.27C1052.37 -185.7 1064.53 -174.59 1059.68 -165.44C1058.37 -162.96 1050.06 -161.59 1047.72 -159.29C1043.48 -155.12 1042.22 -149.9 1041.87 -144.07C1040.47 -120.66 1056.35 -95.23 1066.43 -75.27C1086.62 -35.28 1110.36 3.87996 1126.97 44.46C1133.81 61.16 1139.94 79.18 1140.26 97.85C1140.46 109.53 1139.35 119.64 1142.75 131.16C1151.37 160.39 1173.69 184.96 1194.85 205.95C1222.48 233.36 1253.43 257.13 1283.72 281.47C1303.56 297.41 1341.89 321.24 1349.66 347.79C1358.37 377.56 1306.04 387.71 1286.94 390.99C1227.48 401.2 1165.04 401.24 1104.94 404.6C996.97 410.65 874.12 411.21 779.58 421.56L779.57 421.55Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="0.96"
        />
        <path
          d="M784.94 468.69C755.65 470.36 733.02 504.63 720.09 529.83C701.02 567.01 690.22 608.14 680.36 648.54C671.84 683.47 668.25 728.76 649.51 760.34C628.78 795.27 605.7 741.45 597.06 723.88C563.75 656.15 540.29 582.8 515.78 511.59C490.94 439.41 466.75 366.99 443.18 294.38C417.72 215.97 391.32 136.43 383.73 54.14C378.43 -3.34999 383.88 -62.42 386.76 -120.09C389.3 -170.87 392.43 -224 445.92 -246.27C499.83 -268.72 569.57 -265.69 626.86 -268.74C700.27 -272.65 776.57 -272.36 847.45 -274.14C879.09 -274.93 914.6 -289.68 947.25 -281.51C967.67 -276.4 986.43 -264.65 1003.74 -253.07C1018.77 -243.02 1048.55 -228.2 1055.56 -211.23C1059.91 -200.68 1058.77 -202.37 1048.17 -201.43C1034.48 -200.21 1022.4 -204.96 1020.32 -186.24C1017.3 -158.99 1047.06 -119.42 1059.99 -98.02C1088.42 -50.95 1120.5 -4.77998 1144.06 43.64C1152.3 60.58 1161.02 79.74 1162.11 99.32C1162.89 113.4 1161.14 125.72 1164.95 139.74C1173.32 170.51 1194.61 196.89 1216.05 219.65C1244.03 249.35 1276.2 274.77 1307.81 300.43C1330.91 319.19 1368.7 342.5 1379.37 371.87C1391.61 405.55 1337.2 411.98 1315.35 415.84C1248.36 427.7 1179.72 431.15 1112.04 437.19C1046.89 443 981.85 449.74 916.87 457.12C873.2 462.08 826.27 466.31 784.94 468.67V468.69Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.03"
        />
        <path
          d="M791.15 518.46C720.48 518.08 684.47 601.43 665.81 656.94C653.89 692.4 648.2 734.76 628.86 767.18C610.29 798.3 583.74 749.77 574.72 733.16C539.54 668.42 517.98 594 493.69 524.72C467.43 449.83 441.52 374.81 415.2 299.94C387.78 221.94 353.12 141.3 351.12 57.8399C349.69 -1.88007 365.35 -62.1901 371.57 -121.71C376.95 -173.2 382.15 -227.27 436.25 -249.94C489.97 -272.45 559.29 -270.08 616.38 -274.24C695.5 -280.01 777.75 -281.45 854.37 -283.26C887.6 -284.05 923.91 -305.42 958.3 -297.8C979.51 -293.1 999.17 -280.9 1017.25 -269.47C1025.52 -264.24 1060.44 -245.78 1059.69 -234.36C1058.97 -223.48 998.76 -267.62 994.24 -232.27C992.78 -220.83 1001.63 -206.3 1006.67 -196.36C1020.31 -169.45 1037.83 -144.37 1054.83 -119.53C1091.87 -65.4 1132.67 -12.05 1163.25 44.39C1174.15 64.5 1184.57 85.6999 1184.01 109.46C1183.7 122.73 1182.64 134.86 1185.27 148.1C1191.54 179.62 1208.99 207.68 1229.07 232.25C1254.88 263.83 1285.93 290.63 1316.61 317.31C1336.98 335.02 1382.08 363.96 1386.44 393.07C1392.05 430.43 1341.29 442.42 1315.16 447.22C1246.04 459.91 1175.29 463.96 1105.54 471.96C1044.67 478.95 984.08 487.8 923.77 498.62C880.43 506.39 835.28 518.71 791.13 518.47L791.15 518.46Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.09"
        />
        <path
          d="M797.4 568.37C732.85 564.87 677.51 592.02 646.55 652.33C638.12 668.75 631.39 685.96 624.5 703.06C619.38 715.76 614.5 737.61 604.47 747.45C581.39 770.09 555.13 726.49 545.61 710.6C508.76 649.09 488.02 578.16 464.07 511.05C439.04 440.91 413.83 370.85 386.78 301.47C357.67 226.79 309.52 143.35 314.37 60.45C317.93 -0.349987 345.48 -58.2 355.96 -118.08C365.31 -171.5 371.02 -229.52 427.42 -253.84C451.97 -264.43 479.19 -266.47 505.4 -269.91C540.77 -274.54 576.09 -279.3 611.56 -283.12C693.44 -291.95 778.88 -295.97 858.48 -298.15C880.15 -298.74 900.15 -304.7 921.16 -312.01C936.42 -317.32 950.19 -320.84 966.41 -317.42C987.99 -312.87 1009.01 -301.07 1027.31 -289.26C1028.23 -288.66 1067.58 -261.9 1063.26 -258.16C1057.22 -252.93 1022.07 -273.23 1017.94 -275.21C1004.24 -281.76 973.9 -307.08 968.3 -280.67C964.82 -264.28 984.75 -237.64 992.34 -225.33C1006.32 -202.65 1022.11 -181.1 1037.81 -159.59C1087.8 -91.1 1144.3 -24.7 1185.6 47.17C1196.94 66.91 1208.76 88.24 1206.6 112.02C1205.22 127.25 1203.43 141.91 1204.72 157.27C1207.27 187.58 1218.28 216.23 1234.21 241.96C1255.88 276.98 1285.5 306.14 1314.45 335.07C1336.46 357.08 1368.4 380.89 1367.35 415.33C1366.21 452.55 1328.15 476.8 1295.75 484.69C1265.87 491.97 1233.02 492.65 1202.45 495.56C1165.71 499.06 1128.96 502.5 1092.34 507.12C1031.65 514.77 971.71 526.15 912.62 541.98C875.64 551.88 833.68 570.35 797.41 568.38L797.4 568.37Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.15"
        />
        <path
          d="M802.9 615.93C750.05 611.64 674.05 582.32 627.18 632.1C607.19 653.34 598.23 689.39 575.36 706.76C549.41 726.48 525.2 690.48 512.88 671.46C475.83 614.23 454.09 547.94 430.98 484.35C406.98 418.32 382.15 352.56 353.41 288.42C333.15 243.2 309.41 199.52 289.79 154.04C277.35 125.19 268.56 93.32 272.61 61.84C280.64 -0.570004 323.93 -56.08 339.8 -117.44C353.92 -172.04 360.62 -232.99 418.43 -259.17C443.29 -270.43 471.25 -272.9 497.94 -276.79C532.71 -281.86 567.07 -289.82 601.74 -295.58C686.88 -309.73 773.3 -318.23 859 -319.6C882.6 -319.98 903.43 -324.96 925.48 -333.46C938.63 -338.53 950.92 -342.65 965.32 -340.98C985.61 -338.63 1006.44 -327.9 1023.73 -317.58C1036.81 -309.77 1064.81 -296.14 1070.55 -281.23C1052.2 -280.43 1028.07 -297.46 1012.44 -305.37C997.71 -312.82 983.33 -320.85 968.77 -328.6C957.18 -334.77 949.84 -340.46 946.75 -323.95C943.47 -306.43 966.31 -277.71 974.69 -264.88C996.71 -231.17 1021.91 -199.47 1046.79 -167.87C1103.53 -95.79 1166.79 -24.89 1211.87 52.29C1224.63 74.14 1233.98 93.09 1229.94 118.96C1227.43 134.99 1225.41 151.16 1224.67 167.39C1223.27 198.06 1227.15 228.33 1238.8 256.88C1252.34 290.07 1274.44 318.62 1296.48 346.53C1317.79 373.52 1339.34 401.53 1335.05 438.08C1330.45 477.32 1296.95 506.18 1262.86 521.4C1234.59 534.02 1205.56 533.2 1175.14 534.76C1138.96 536.61 1102.78 538.81 1066.8 543.08C1012.23 549.55 958.9 561.3 907.49 580.88C874.54 593.43 834.97 618.55 802.9 615.95V615.93Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.21"
        />
        <path
          d="M806.85 658.63C771.22 655.92 732.44 633.11 695.79 618.93C667.37 607.93 635.18 597.72 605.39 609.63C578.17 620.52 568.29 648.21 544.57 661.78C516.88 677.62 493.14 647.34 478.95 626.99C406.15 522.64 381.25 391.04 321.72 279.35C299.83 238.29 273.05 200.38 249.86 160.13C232.63 130.22 218.26 96.89 225.06 61.89C231.78 27.31 254.86 -2.99001 274.37 -31.63C293.45 -59.64 311.48 -88.19 322.6 -120.41C341.54 -175.3 350.31 -238.74 408.64 -266.66C433.57 -278.59 461.61 -281.66 488.5 -286.38C522.03 -292.27 554.08 -303.61 587 -312.07C675.61 -334.85 767.1 -340.29 855.97 -340.09C879.52 -340.04 901.44 -346.13 923.24 -355.6C938.4 -362.19 952.28 -368.21 969.17 -365.83C994.32 -362.28 1018.22 -347.57 1039.2 -334.29C1050.92 -326.87 1076.55 -315.07 1081.43 -301.35C1061.16 -300.7 1033.88 -320.02 1016.5 -328.76C998.34 -337.9 980.28 -347.32 962.53 -357.24C952.09 -363.07 939.23 -377.27 935.12 -362.1C930.51 -345.08 953.22 -316.87 961.59 -304.25C987.43 -265.29 1017.19 -228.98 1046.75 -192.81C1114.02 -110.5 1191.85 -29.83 1242.57 59.8C1255.39 82.45 1259.48 100.68 1256.18 126.74C1254.28 141.74 1251.5 156.61 1248.72 171.47C1242.89 202.73 1237.1 234.21 1240.94 266.11C1249.11 334 1312.36 389.64 1298.14 459.94C1289.66 501.85 1258.4 536.07 1222.18 556.99C1192.53 574.11 1166.87 578.67 1133.07 577.53C1102.91 576.52 1072.66 576.22 1042.54 578.29C992.08 581.76 943.24 592.89 898.48 616.93C870.08 632.18 837.13 660.96 806.83 658.65L806.85 658.63Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.27"
        />
        <path
          d="M808.44 693.97C723.52 693.27 668.091 597.96 584.211 590.85C570.551 589.69 556.55 591.66 545.07 599.01C533.94 606.13 526.471 616.78 513.401 621.24C484.451 631.12 461.38 606.09 445.59 585.11C376.76 493.67 356.38 376.01 294.61 280.63C253.44 217.06 185.49 147.04 200.26 64.38C213.23 -8.21999 272.73 -58.41 301.98 -123.48C327.04 -179.22 338.01 -245.81 397.56 -276.68C422.79 -289.76 451.261 -293.63 478.671 -299.68C512.09 -307.05 541.14 -323.35 573.56 -333.52C663.09 -361.6 759.98 -358.16 850.06 -357.94C888.37 -357.85 914.85 -380.57 950.61 -389.58C988.17 -399.04 1028.06 -369.16 1057.61 -349.96C1063.8 -345.94 1095.52 -329.19 1095.44 -321.66C1095.35 -313.27 1074.16 -325.1 1068.43 -327.54C1043.94 -337.97 1020.12 -350.08 996.391 -362.11C979.921 -370.46 956.211 -388.47 938.331 -391.68C934.301 -369.82 954.091 -344.59 965.651 -327.82C997.341 -281.85 1034.03 -238.89 1069.55 -195.89C1139.21 -111.57 1219.66 -27.62 1270.82 65.23C1282.93 87.2 1286.01 105.49 1283.37 130.67C1281.52 148.32 1278.74 165.9 1275.04 183.26C1268.34 214.69 1255.94 244.63 1249.82 276.13C1243.22 310.08 1245.65 341.59 1256.54 374.05C1268.17 408.71 1273.26 444.18 1263.49 480.01C1250.86 526.34 1218.08 566.6 1178.86 593.37C1159.08 606.88 1133.98 620.61 1109.49 622.83C1079.75 625.52 1046 613.99 1015.78 613.2C973.091 612.08 932.59 620 897.16 644.7C869.63 663.89 844.45 694.26 808.44 693.97Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.33"
        />
        <path
          d="M809.281 719.03C715.231 718.78 648.98 620.71 564.18 585.35C550.77 579.76 538.85 576.08 524.6 580.06C511.94 583.6 499.62 589.06 486.46 590.66C455.56 594.41 432.63 574.51 414.18 551.93C351.01 474.64 327.11 373.42 271.2 291.58C228.28 228.76 166.57 149.27 179 68.08C190.37 -6.17995 244.36 -62.67 277.09 -127.65C305.84 -184.71 325.7 -255.77 384.92 -289.39C410.7 -304.03 439.85 -309.18 468.02 -317.22C501.17 -326.68 530.29 -345.31 562.98 -355.86C653.55 -385.09 750.72 -374.04 842.07 -373.85C885.59 -373.76 913.71 -403.47 953.82 -413.73C993.22 -423.8 1036.44 -392.02 1067.39 -371.88C1078.83 -364.44 1107.1 -351.59 1113.02 -338.01C1118.06 -326.47 1101.22 -335.36 1094.29 -337.9C1047.67 -354.98 1004.95 -381.58 958.6 -399.02C962.44 -393.32 963.811 -379.85 966.201 -373.94C971.521 -360.78 979.381 -348.36 986.781 -336.32C1017.48 -286.32 1055.88 -240.44 1092.78 -194.99C1163.41 -107.98 1247.77 -22.78 1299.81 72.48C1319.45 108.42 1310 151.07 1301.52 191.41C1294.31 225.66 1283.73 259.18 1269.98 291.36C1256.1 323.85 1244.74 349.57 1246.26 385.46C1247.9 424.16 1248.35 460.75 1235.63 498C1219.11 546.38 1187.38 590.28 1148.66 623.43C1129.65 639.7 1106.81 655.71 1082.14 661.86C1055.07 668.61 1028.81 651.74 1002.18 646.43C960.42 638.11 934.18 648.25 901.01 673.08C873.24 693.87 844.03 719.14 809.29 719.04L809.281 719.03Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.39"
        />
        <path
          d="M811.63 734.4C720.4 734.19 633.6 655.41 555.27 607.38C524.06 588.25 496.33 581.61 460.95 575.9C429.73 570.87 406.14 554.66 385.52 531.09C327.29 464.53 294.09 376.97 247.68 302.2C206.26 235.46 150.89 155.57 160.37 72.7601C169.4 -6.09993 222.1 -68.86 254.59 -138.65C281.97 -197.46 313.47 -268.76 370.47 -304.67C395.73 -320.59 424.63 -327.92 452.42 -338C485.41 -349.97 517.13 -365.62 550.15 -377.18C641.59 -409.22 740.17 -386.23 832.68 -388.47C866.75 -389.29 893.43 -404.95 922.69 -421.78C946.69 -435.59 969.21 -444.43 996.86 -435.91C1013.98 -430.63 1148.75 -354.24 1136.94 -343.01C1127.87 -334.39 1058.13 -370.17 1048.71 -373.96C1034.65 -379.61 991.39 -406.4 994.9 -378.5C999.01 -345.85 1024.22 -313.47 1042.27 -287.26C1072.77 -243 1106.67 -201.18 1140.86 -159.75C1205.11 -81.89 1282.33 -5.55994 1329.18 80.0601C1347.72 113.93 1335.88 161.42 1327.98 199.47C1320.31 236.42 1308.85 272.62 1293.88 307.26C1280.09 339.17 1258.36 368.62 1248.46 401.91C1237.36 439.25 1232.83 477.47 1217.91 513.85C1196.79 565.36 1164.52 613.22 1126.09 653.39C1111.14 669.01 1094.44 685.75 1074.76 695.28C1053.37 705.64 1041.64 693.57 1020.97 686.55C941.44 659.52 883.34 734.56 811.64 734.39L811.63 734.4Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.45"
        />
        <path
          d="M814.99 743.26C717.78 743.11 627.911 678.27 542.271 632.79C509.051 615.15 475.72 595.96 441.75 579.93C410.67 565.27 383.68 549.81 360.01 524.28C304.46 464.38 266.34 389.55 227.21 318.74C186.83 245.68 137.11 165.12 143.48 78.13C149.44 -3.20996 197.23 -70.81 228.98 -143.42C257.09 -207.7 295.34 -280.86 354.32 -322.11C407.29 -359.16 476.261 -374.39 535.891 -397.48C628.91 -433.49 729.97 -400.16 822.69 -402.54C859.92 -403.5 889.37 -423.6 921.16 -443.01C948.43 -459.66 974.31 -469.02 1005.76 -458.16C1040.85 -446.04 1075.03 -424.6 1105.63 -403.93C1114.55 -397.91 1170.9 -364.37 1165.64 -351.22C1156.42 -328.17 1023.47 -423.57 1036.93 -347.04C1043.09 -311.99 1068.67 -277.92 1088.89 -249.8C1118.12 -209.13 1150.08 -170.52 1182.37 -132.28C1242.3 -61.31 1312.18 7.15003 1358.69 86.94C1376.94 118.24 1361.37 174.17 1354.25 208.49C1346.21 247.19 1334.21 285.11 1318.53 321.4C1303.61 355.92 1285.31 388.6 1264.41 419.83C1241.97 453.37 1229.19 492.03 1211.61 528.16C1186.34 580.11 1155.41 629.21 1121.56 675.97C1100.65 704.86 1077.89 743.31 1038.11 727.88C962.49 698.55 888.48 743.35 815 743.24L814.99 743.26Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.52"
        />
        <path
          d="M818.88 748.81C715.63 748.73 623.2 702.46 531.68 660.18C461.48 627.75 391.98 586.91 337.54 531.11C279.98 472.12 238.43 402.66 202.44 329.2C164.44 251.64 124.43 172.5 127.34 83.91C130.07 0.740005 171.41 -73.23 203.32 -148.13C233.36 -218.65 275.5 -292.79 336.73 -340.79C392.57 -384.57 462.78 -394.24 527.26 -419.53C621.51 -456.5 720.83 -414.27 812.8 -416.71C882.26 -418.55 938 -508.57 1013.09 -480.75C1049.64 -467.2 1084.95 -446.45 1117.3 -424.9C1135.1 -413.04 1152.35 -400.28 1168.8 -386.6C1174.5 -381.86 1196.22 -367.54 1195.89 -358.53C1195.29 -342.05 1144.29 -359.84 1135.5 -360.34C1098.85 -362.45 1080.5 -340.1 1087 -304.03C1093.12 -270.11 1116.98 -239.43 1137.21 -212.51C1166.63 -173.35 1198.7 -136.27 1231.03 -99.52C1284.96 -38.22 1346.22 24.4901 1388.05 92.58C1398.1 108.95 1392.12 136.51 1390.21 155.03C1388.04 176.02 1384.78 196.89 1380.49 217.55C1372.09 258 1359.55 297.65 1343.15 335.57C1310.5 411.11 1257.23 471.42 1216.43 541.94C1187.79 591.45 1161.62 642.52 1132.04 691.46C1116.24 717.6 1089.45 766.55 1052.96 766.22C1039.44 766.1 1023.2 757.05 1009.58 754.54C1000.2 752.82 990.72 751.69 981.21 750.96C962.95 749.56 944.6 750 926.31 750.06C890.49 750.18 854.61 748.83 818.88 748.81Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.58"
        />
        <path
          d="M822.79 754.23C642.99 754.12 448.27 678.19 317.5 548.83C286.73 518.4 263.19 485.33 239.74 449.11C218.39 416.13 196.43 383.5 180.38 347.53C142.95 263.65 111.42 181.87 110.9 89.8C110.42 3.83002 146.35 -78.66 179.52 -157.24C210.91 -231.61 256.77 -306.37 318.06 -359.81C375.92 -410.26 449.4 -416.8 518.98 -441.86C565.02 -458.44 608.65 -460.79 657.17 -454.2C706.02 -447.57 756.97 -430.42 803.8 -431.68C848.2 -432.88 883.85 -464.47 922.01 -488.53C956.88 -510.52 987.28 -517.01 1025.94 -500.89C1063.54 -485.21 1099.61 -465.51 1133.45 -442.85C1151.02 -431.09 1168.01 -418.46 1184.34 -405.04C1197.99 -393.82 1216.06 -381.83 1225.43 -366.58C1232.09 -355.74 1232.13 -353.58 1220.46 -351.49C1207.92 -349.25 1195.59 -351.01 1182.96 -347.38C1145.06 -336.49 1136.04 -300.32 1142.18 -264.67C1148.43 -228.4 1169.44 -201.25 1192.01 -173.19C1221.02 -137.11 1252.57 -103.12 1283.78 -68.96C1329.74 -18.66 1386.12 38.72 1417.13 96.71C1425.3 111.99 1418.73 143.79 1416.91 161.35C1414.65 183.25 1411.25 205.02 1406.77 226.58C1398 268.79 1384.92 310.15 1367.82 349.72C1351.59 387.28 1331.81 423.25 1308.79 457.07C1284.89 492.18 1254.7 521.95 1230.39 556.58C1197.92 602.85 1173.9 654.98 1145.34 703.68C1127.47 734.15 1100.58 787.87 1062.04 796.94C1041.35 801.81 1014.6 789.65 993.4 786.13C974.13 782.93 954.82 780.17 935.73 775.99C898.07 767.74 860.5 754.25 822.79 754.23Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.64"
        />
        <path
          d="M826.211 762.72C723.941 762.63 608.56 758.74 508.66 715.7C429.91 681.77 359.46 632.91 298.78 572.55C265.56 539.5 244.07 497.49 218.92 458.36C198.41 426.45 174 396.45 156.8 362.55C114.93 280.03 94.8205 186.91 93.0405 95.47C91.2905 5.45 120.54 -84.14 155.76 -166.4C189.05 -244.15 237.39 -319.18 298.96 -377.63C358.33 -433.99 431.92 -439.91 506.36 -463.19C552.31 -477.56 591.451 -484.17 639.841 -476.76C692.101 -468.76 746.301 -446.76 796.401 -448.15C843.351 -449.45 881.55 -485.4 921.74 -511.17C962.39 -537.24 994.76 -539.91 1038.83 -521.06C1116.23 -487.96 1191.78 -439.62 1249.85 -378.16C1275.59 -350.92 1247.09 -349.21 1227.03 -332.33C1194.49 -304.94 1194.49 -260.44 1203.8 -222.1C1220.85 -151.8 1285.89 -102.6 1333.78 -51.66C1374.75 -8.08003 1428.63 42.65 1445.7 99.62C1451.62 119.38 1445.78 146.69 1443.61 167.68C1441.25 190.49 1437.71 213.17 1433.05 235.62C1423.92 279.58 1410.29 322.66 1392.48 363.87C1357.6 444.57 1306.9 508.54 1249.7 574.04C1212.93 616.14 1186.76 667.8 1158.65 715.75C1137.02 752.65 1111.71 804.31 1071.51 824.65C1050.35 835.36 1027.2 826.69 1005.22 822.15C984.64 817.89 964.581 812.46 945.081 804.59C905.881 788.76 867.44 762.77 826.24 762.73L826.211 762.72Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M828.66 777.47C723.18 777.35 604.36 789.25 500.24 744.81C420.95 710.97 337.48 660.64 280.13 595.15C246.32 556.55 226.73 506.52 199.07 463.62C177.84 430.69 149.44 402.35 129.94 368.41C108.19 330.56 96.7301 282.81 87.1201 240.52C76.6401 194.38 73.0201 147.37 72.5801 100.58C70.9001 -79.14 152.75 -266.13 279.99 -393.36C308.72 -422.09 340.62 -444.51 379.15 -458.04C417.07 -471.36 457.29 -475.64 496.02 -485.87C517.94 -491.66 538.7 -500.7 561.41 -503.2C586.5 -505.97 612.05 -503.06 636.72 -498.4C688.13 -488.68 741.35 -465.31 791.34 -466.77C840.96 -468.22 880.65 -507.85 922.68 -534.96C942.29 -547.61 964.16 -559.2 988.07 -559.68C1008.83 -560.1 1028.08 -551.18 1046.83 -543.27C1129.62 -508.33 1205.73 -457.04 1269.16 -393.37C1278.67 -383.82 1293.97 -371.84 1293.06 -356.86C1292.29 -344.24 1279.06 -331.55 1273.11 -321.24C1249 -279.54 1254.47 -231.43 1269.8 -187.53C1280.99 -155.5 1295.67 -133.57 1319.94 -110.52C1347.28 -84.56 1375.07 -58.97 1400.31 -30.92C1433.99 6.51002 1468.47 52.98 1473.64 101.95C1482.75 188.2 1454.08 290.54 1419.07 373.48C1384.04 456.47 1332.67 531.99 1269.17 595.81C1230.61 634.57 1201.71 678.76 1173.59 725.48C1147.57 768.7 1121.47 824.66 1077.33 852.49C1040.99 875.4 986.55 854.69 952.01 835.7C912.48 813.97 873.76 777.53 828.67 777.48L828.66 777.47Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.76"
        />
        <path
          d="M1500.85 104.75C1501.11 296.06 1423.59 482.85 1288.12 618.31C1251.37 655.06 1218.65 689.56 1190.27 733.74C1159.66 781.4 1132.24 840.32 1087.03 876.4C993.03 951.41 918.24 801.75 829.62 801.67C715.21 801.56 600.86 820.06 491.86 773.96C447.31 755.12 404.67 731.78 364.76 704.46C327.4 678.88 288.68 650.62 262.06 613.56C230.82 570.08 213.07 518.71 184.99 473.29C163.08 437.84 131.92 412.12 107.39 379.15C82.0903 345.14 72.7403 296.27 63.9103 255.65C53.1403 206.13 48.2803 155.36 48.2803 104.75C48.2803 -86.49 125.68 -273.48 261.01 -408.81C291.71 -439.51 324.33 -466.73 365.46 -482.07C403.47 -496.25 444.02 -500.11 483.45 -508.56C505.41 -513.27 526.38 -522.05 548.46 -525.92C572.89 -530.21 598.11 -527.9 622.35 -523.56C678.05 -513.58 732.45 -488.24 789.4 -488.27C842.34 -488.29 884.21 -534.18 926.19 -560.71C945.68 -573.03 967.17 -583.81 990.7 -584.17C1013.67 -584.53 1036.51 -573.25 1057.28 -564.46C1143.33 -528.06 1222.08 -474.87 1288.14 -408.81C1301.61 -395.34 1320.73 -380.1 1324.15 -360.34C1326.85 -344.77 1320.04 -328.84 1316.47 -314.02C1304.27 -263.43 1314.33 -215.02 1337.71 -169.06C1344.11 -156.48 1351.34 -144.26 1359.04 -132.44C1369.67 -116.1 1383.63 -106.71 1398.69 -94.75C1421.9 -76.31 1444.65 -56.56 1462.76 -32.93C1493.84 7.63001 1500.81 54.99 1500.88 104.76L1500.85 104.75Z"
          stroke="#54AE32"
          strokeMiterlimit="10"
          strokeWidth="1.82"
        />
      </g>
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_355_1782" x1="0" x2="1536" y1="127.69" y2="127.69">
        <stop stopColor="#1D1D1B" stopOpacity="0.04" />
        <stop offset="0.06" stopColor="#173D15" stopOpacity="0.0529" />
        <stop offset="0.13" stopColor="#10640F" stopOpacity="0.2209" />
        <stop offset="0.21" stopColor="#0A8409" stopOpacity="0.4356" />
        <stop offset="0.28" stopColor="#059D05" stopOpacity="0.6561" />
        <stop offset="0.36" stopColor="#02AE02" stopOpacity="0.8281" />
        <stop offset="0.43" stopColor="#00B900" stopOpacity="0.9604" />
        <stop offset="0.5" stopColor="#00BD00" />
        <stop offset="0.56" stopColor="#01B501" stopOpacity="0.9025" />
        <stop offset="0.64" stopColor="#059F04" stopOpacity="0.6724" />
        <stop offset="0.75" stopColor="#0B7D0A" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#144D12" stopOpacity="0.09" />
        <stop offset="1" stopColor="#1D1D1B" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default BadgeBg;

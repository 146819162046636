export default {
  surveyGeneratorPageTitle: {
    id: 'surveyGeneratorPage.title',
    description: 'Survey Machine',
    defaultMessage: 'Survey Machine',
  },
  surveyGeneratorColorTemplate: {
    id: 'surveyGeneratorPage.colorTemplate',
    description: 'Color Template',
    defaultMessage: 'Color Template',
  },
  surveyGeneratorVideoModel: {
    id: 'surveyGeneratorPage.videoModel',
    description: 'Model',
    defaultMessage: 'Model',
  },
  surveyGeneratorTemplate: {
    id: 'surveyGeneratorPage.template',
    description: 'Template',
    defaultMessage: 'Template',
  },
  surveyGeneratorTemplates: {
    id: 'surveyGeneratorPage.templates',
    description: 'Templates',
    defaultMessage: 'Templates',
  },
  surveyGeneratorBasicConfiguration: {
    id: 'surveyGeneratorPage.basicConfiguration',
    description: 'Basic Configuration',
    defaultMessage: 'Basic Configuration',
  },
  surveyGeneratorRewardOptions: {
    id: 'surveyGeneratorPage.rewardOptions',
    description: 'Reward Options',
    defaultMessage: 'Reward Options',
  },
  surveyGeneratorCustomColors: {
    id: 'surveyGeneratorPage.infobarSettings',
    description: 'Custom Colors',
    defaultMessage: 'Custom Colors',
  },
  surveyGeneratorOfferDisplay: {
    id: 'surveyGeneratorPage.offerDisplay',
    description: 'Offer Display',
    defaultMessage: 'Offer Display',
  },
  surveyGeneratorPaletteDark: {
    id: 'surveyGeneratorPage.paletteDark',
    description: 'Dark',
    defaultMessage: 'Dark',
  },
  surveyGeneratorPaletteLight: {
    id: 'surveyGeneratorPage.paletteLight',
    description: 'Light',
    defaultMessage: 'Light',
  },
  surveyGeneratorColor1: {
    id: 'surveyGeneratorPage.color1',
    description: 'Color 1',
    defaultMessage: 'Color 1',
  },
  surveyGeneratorColor2: {
    id: 'surveyGeneratorPage.color2',
    description: 'Color 2',
    defaultMessage: 'Color 2',
  },
  surveyGeneratorPaletteSocial1: {
    id: 'surveyGeneratorPage.paletteSocial1',
    description: 'Social 1',
    defaultMessage: 'Social 1',
  },
  surveyGeneratorPaletteSocial2: {
    id: 'surveyGeneratorPage.paletteSocial2',
    description: 'Social 2',
    defaultMessage: 'Social 2',
  },
  surveyGeneratorPaletteDating: {
    id: 'surveyGeneratorPage.paletteDating',
    description: 'Dating',
    defaultMessage: 'Dating',
  },
  surveyGeneratorFormTrafficType: {
    id: 'surveyGeneratorPage.formTrafficType',
    description: 'Traffic Type',
    defaultMessage: 'Traffic Type',
  },
  surveyGeneratorFormWidgetWidth: {
    id: 'surveyGeneratorPage.formWidgetWidth',
    description: 'Widget Width',
    defaultMessage: 'Widget Width',
  },
  surveyGeneratorFormDefaultLanguage: {
    id: 'surveyGeneratorPage.formDefaultLanguage',
    description: 'Default Language',
    defaultMessage: 'Default Language',
  },
  surveyGeneratorFormDisplayFooter: {
    id: 'surveyGeneratorPage.formDisplayFooter',
    description: 'Display Footer',
    defaultMessage: 'Display Footer',
  },
  surveyGeneratorFormFooterBackgroundColor: {
    id: 'surveyGeneratorPage.formFooterBackgroundColor',
    description: 'Footer Background Color',
    defaultMessage: 'Footer Background Color',
  },
  surveyGeneratorFormLogo: {
    id: 'surveyGeneratorPage.formLogo',
    description: 'Logo',
    defaultMessage: 'Logo',
  },
  surveyGeneratorFormBoxBorder: {
    id: 'surveyGeneratorPage.formBoxBorder',
    description: 'Box border',
    defaultMessage: 'Box border',
  },
  surveyGeneratorFormIntroductionPicture: {
    id: 'surveyGeneratorPage.introPicture',
    description: 'Introduction picture',
    defaultMessage: 'Introduction picture',
  },
  surveyGeneratorFormIntroductionText: {
    id: 'surveyGeneratorPage.introText',
    description: 'Introduction text',
    defaultMessage: 'Introduction text',
  },
  surveyGeneratorFormBranding: {
    id: 'surveyGeneratorPage.branding',
    description: 'Branding',
    defaultMessage: 'Branding',
  },
  surveyGeneratorFormCustomTitle: {
    id: 'surveyGeneratorPage.customTitle',
    description: 'Custom title',
    defaultMessage: 'Custom title',
  },
  surveyGeneratorFormCustomBackgroundUrl: {
    id: 'surveyGeneratorPage.customBackgroundUrl',
    description: 'Custom background URL',
    defaultMessage: 'Custom background URL',
  },
  surveyGeneratorFormCustomLogoUrl: {
    id: 'surveyGeneratorPage.customLogoUrl',
    description: 'Custom logo URL',
    defaultMessage: 'Custom logo URL',
  },
  surveyGeneratorFormUrlPlaceholder: {
    id: 'surveyGeneratorPage.urlPlaceholder',
    description: 'https://',
    defaultMessage: 'https://',
  },
  surveyGeneratorFormDisplayLogo: {
    id: 'surveyGeneratorPage.formDisplayLogo',
    description: 'Display Logo',
    defaultMessage: 'Display Logo',
  },
  surveyGeneratorFormLogoColor1: {
    id: 'surveyGeneratorPage.formLogoColor1',
    description: 'Logo Color 1',
    defaultMessage: 'Logo Color 1',
  },
  surveyGeneratorFormLogoColor2: {
    id: 'surveyGeneratorPage.formLogoColor2',
    description: 'Logo Color 2',
    defaultMessage: 'Logo Color 2',
  },
  surveyGeneratorFormHeaderTextColor: {
    id: 'surveyGeneratorPage.formHeaderTextColor',
    description: 'Header Text Color',
    defaultMessage: 'Header Text Color',
  },
  surveyGeneratorFormOverrideDefaultLogo: {
    id: 'surveyGeneratorPage.formOverrideDefaultLogo',
    description: 'Override Default Logo',
    defaultMessage: 'Override Default Logo',
  },
  surveyGeneratorFormLogoBackgroundColor: {
    id: 'surveyGeneratorPage.formLogoBackgroundColor',
    description: 'Logo Background Color',
    defaultMessage: 'Logo Background Color',
  },
  surveyGeneratorFormSurveyTagLine: {
    id: 'surveyGeneratorPage.formSurveyTagLine',
    description: 'Survey Tag Line',
    defaultMessage: 'Survey Tag Line',
  },
  surveyGeneratorFormSurveyTagLineColor: {
    id: 'surveyGeneratorPage.formSurveyTagLineColor',
    description: 'Survey Tag Line Color',
    defaultMessage: 'Survey Tag Line Color',
  },
  surveyGeneratorFormQuestionTheme: {
    id: 'surveyGeneratorPage.formQuestionTheme',
    description: 'Questionnaire Theme',
    defaultMessage: 'Questionnaire Theme',
  },
  surveyGeneratorFormNumberOfQuestions: {
    id: 'surveyGeneratorPage.numberOfQuestions',
    description: 'Number of questions',
    defaultMessage: 'Number of questions',
  },
  surveyGeneratorFormGeneralBackgroundColor: {
    id: 'surveyGeneratorPage.formGeneralBackgroundColor',
    description: 'General Background Color',
    defaultMessage: 'General Background Color',
  },
  surveyGeneratorFormGeneralBackgroundImage: {
    id: 'surveyGeneratorPage.formGeneralBackgroundImage',
    description: 'General Background Image',
    defaultMessage: 'General Background Image',
  },
  surveyGeneratorFormQuestionBoxBackgroundColor: {
    id: 'surveyGeneratorPage.formQuestionBoxBackgroundColor',
    description: 'Question Box Background Color',
    defaultMessage: 'Question Box Background Color',
  },
  surveyGeneratorFormQuestionBoxBorder: {
    id: 'surveyGeneratorPage.formQuestionBoxBorder',
    description: 'Question Box Border',
    defaultMessage: 'Question Box Border',
  },
  surveyGeneratorFormQuestionBoxBorderColor: {
    id: 'surveyGeneratorPage.formQuestionBoxBorderColor',
    description: 'Question Box Border Color',
    defaultMessage: 'Question Box Border Color',
  },
  surveyGeneratorFormQuestionTextColor: {
    id: 'surveyGeneratorPage.formQuestionTextColor',
    description: 'Question Text Color',
    defaultMessage: 'Question Text Color',
  },
  surveyGeneratorFormAnswerTextColor: {
    id: 'surveyGeneratorPage.formAnswerTextColor',
    description: 'Answer Text Color',
    defaultMessage: 'Answer Text Color',
  },
  surveyGeneratorFormOfferBackgroundColor: {
    id: 'surveyGeneratorPage.formOfferBackgroundColor',
    description: 'Offer Background Color',
    defaultMessage: 'Offer Background Color',
  },
  surveyGeneratorFormOfferTextColor: {
    id: 'surveyGeneratorPage.formOfferTextColor',
    description: 'Offer Text Color',
    defaultMessage: 'Offer Text Color',
  },
  surveyGeneratorFormButtonBackgroundColor: {
    id: 'surveyGeneratorPage.formButtonBackgroundColor',
    description: 'Button Background Color',
    defaultMessage: 'Button Background Color',
  },
  surveyGeneratorFormButtonTextColor: {
    id: 'surveyGeneratorPage.formButtonTextColor',
    description: 'Button Text Color',
    defaultMessage: 'Button Text Color',
  },
  surveyGeneratorFormDisplayComments: {
    id: 'surveyGeneratorPage.formDisplayComments',
    description: 'Display Comments',
    defaultMessage: 'Display Comments',
  },
  surveyGeneratorFormCommentsUsernameColor: {
    id: 'surveyGeneratorPage.formCommentsUsernameColor',
    description: 'Comments Username Color',
    defaultMessage: 'Comments Username Color',
  },
  surveyGeneratorSurveyCampaignName: {
    id: 'surveyGeneratorPage.surveyCampaignName',
    description: 'Survey Campaign Name',
    defaultMessage: 'Survey Campaign Name',
  },
  surveyGeneratorSubId1: {
    id: 'surveyGeneratorPage.subId1',
    description: 'Sub ID 1',
    defaultMessage: 'Sub ID 1',
  },
  surveyGeneratorSubId2: {
    id: 'surveyGeneratorPage.subId2',
    description: 'Sub ID 2',
    defaultMessage: 'Sub ID 2',
  },
  surveyGeneratorSubId3: {
    id: 'surveyGeneratorPage.subId3',
    description: 'Sub ID 3',
    defaultMessage: 'Sub ID 3',
  },
  surveyGeneratorSubId4: {
    id: 'surveyGeneratorPage.subId4',
    description: 'Sub ID 4',
    defaultMessage: 'Sub ID 4',
  },
  surveyGeneratorSubId5: {
    id: 'surveyGeneratorPage.subId5',
    description: 'Sub ID 5',
    defaultMessage: 'Sub ID 5',
  },
  surveyGeneratorSource: {
    id: 'surveyGeneratorPage.Source',
    description: 'Source',
    defaultMessage: 'Source',
  },
  surveyGeneratorWidgetPreviewText: {
    id: 'surveyGeneratorPage.widgetPreviewText',
    description:
      'This section displays your customized survey based on the corresponding screen resolution of the device being used. Note:  The ‘Offer Rewards’ shown in the preview may differ from the ones that actually appear in your survey.',
    defaultMessage:
      'This section displays your customized survey based on the corresponding screen resolution of the device being used. Note:  The ‘Offer Rewards’ shown in the preview may differ from the ones that actually appear in your survey.',
  },
  surveyGeneratorWelcome: {
    id: 'surveyGeneratorPage.welcome',
    description: 'Welcome to Survey Machine',
    defaultMessage: 'Welcome to Survey Machine',
  },
  surveyGeneratorChooseColorTemplateToEnable: {
    id: 'surveyGeneratorPage.chooseColorTemplateToEnable',
    description: 'Choose a color template to enable',
    defaultMessage: 'Choose a color template to enable',
  },
  surveyGeneratorPleaseNameYourSurvey: {
    id: 'surveyGeneratorPage.pleaseNameYourSurvey',
    description: "Please name your survey to generate the survey's code",
    defaultMessage: "Please name your survey to generate the survey's code",
  },
  surveyGeneratorIfYouAreReady: {
    id: 'surveyGeneratorPage.ifYouAreReady',
    description: 'If you are ready to create your custom survey, you can start the customization by selecting a color palette.',
    defaultMessage:
      'If you are ready to create your custom survey, you can start the customization by selecting a color palette.',
  },
  surveyGeneratorMoreInfosSurvey: {
    id: 'surveyGeneratorPage.moreInfosSurvey',
    description: 'Click here for more informations on Survey Machine',
    defaultMessage: 'Click here for more informations on Survey Machine',
  },
  surveyGeneratorQuestionBox: {
    id: 'surveyGeneratorPage.questionBox',
    description: 'Question Box',
    defaultMessage: 'Question Box',
  },
  surveyGeneratorQuestionText: {
    id: 'surveyGeneratorPage.questionText',
    description: 'Question Text',
    defaultMessage: 'Question Text',
  },
  surveyGeneratorAnswerText: {
    id: 'surveyGeneratorPage.answerText',
    description: 'Answer Text',
    defaultMessage: 'Answer Text',
  },
  surveyGeneratorButtonText: {
    id: 'surveyGeneratorPage.buttonText',
    description: 'Button Text',
    defaultMessage: 'Button Text',
  },
  surveyGeneratorButtonBackground: {
    id: 'surveyGeneratorPage.buttonBackground',
    description: 'Button Background',
    defaultMessage: 'Button Background',
  },
  surveyGeneratorCommentsUsername: {
    id: 'surveyGeneratorPage.commentsUsername',
    description: 'Comments Username',
    defaultMessage: 'Comments Username',
  },
  surveyGeneratorPayoutsRewards: {
    id: 'surveyGeneratorPage.payoutsRewards',
    description:
      'For top paying geos, you can expect a payout between $1.95 - $6.50 for CPL rewards, and between $15 - $90 for CPA rewards.',
    defaultMessage:
      'For top paying geos, you can expect a payout between $1.95 - $6.50 for CPL rewards, and between $15 - $90 for CPA rewards.',
  },
  surveyGeneratorPrimaryColor: {
    id: 'surveyGeneratorPage.primaryColor',
    description: 'Primary color',
    defaultMessage: 'Primary color',
  },
  surveyGeneratorSecondaryColor: {
    id: 'surveyGeneratorPage.secondaryColor',
    description: 'Secondary color',
    defaultMessage: 'Secondary color',
  },
};

import consts from '../utils/consts';

const { ACTIONS } = consts;
const { CREATIVES } = ACTIONS;

const creativesInitialState = {
  data: {
    creatives: [],
    count: 1,
    current: 0,
    page: '1',
    pageCount: 1,
  },
  isFetching: false,
  isError: false,
  timestampReceived: null,
};

const creativesSizesInitialState = {
  data: [],
  isFetching: false,
  isError: false,
  timestampReceived: null,
};

const creativeCodeInitialState = {
  data: {},
  isFetching: false,
  isError: false,
  timestampReceived: null,
};

const creativesTrendingInitialState = {
  banners: [],
  countries: [],
  days: [],
  formats: [],
  sizes: [],
};

export const creatives = (state = creativesInitialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case CREATIVES.REQUEST_CREATIVES:
      return {
        ...state,
        isFetching: true,
      };

    case CREATIVES.REQUEST_TRENDING:
      return {
        ...state,
        isFetching: true,
      };

    case CREATIVES.RECEIVE_CREATIVES:
      return {
        ...state,
        data,
        isFetching: false,
        isError: false,
        timestampReceived: new Date().getTime(),
      };

    case CREATIVES.FAILED_TO_RECEIVE_CREATIVES:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case CREATIVES.RESET_CREATIVES:
      return creativesInitialState;

    default:
      return state;
  }
};

export const creativesSizes = (state = creativesSizesInitialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case CREATIVES.REQUEST_CREATIVES_SIZES:
      return {
        ...state,
        isFetching: true,
      };

    case CREATIVES.RECEIVE_CREATIVES_SIZES:
      return {
        ...state,
        data: data.sizes,
        isFetching: false,
        isError: false,
        timestampReceived: new Date().getTime(),
      };

    case CREATIVES.FAILED_TO_RECEIVE_CREATIVES_SIZES:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case CREATIVES.RESET_CREATIVES_SIZES:
      return creativesSizesInitialState;

    default:
      return state;
  }
};

export const creativeCode = (state = creativeCodeInitialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case CREATIVES.REQUEST_CREATIVE_CODE:
      return {
        ...state,
        isFetching: true,
      };

    case CREATIVES.RECEIVE_CREATIVE_CODE:
      return {
        ...state,
        data: data.creativeCode,
        isFetching: false,
        isError: false,
        timestampReceived: new Date().getTime(),
      };

    case CREATIVES.FAILED_TO_RECEIVE_CREATIVE_CODE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    default:
      return state;
  }
};

export const creativesTrending = (state = creativesTrendingInitialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case CREATIVES.RECEIVE_CREATIVES_TRENDING:
      return data;

    case CREATIVES.RESET_CREATIVES_TRENDING:
      return creativesTrendingInitialState;

    default:
      return state;
  }
};

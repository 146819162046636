import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestOffersSlides = () => ({
  type: ACTIONS.REQUEST_OFFERS_SLIDES,
});

const receiveOffersSlides = (data) => ({
  type: ACTIONS.RECEIVE_OFFERS_SLIDES,
  data,
});

const failedToReceiveOffersSlides = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFERS_SLIDES,
});

export const fetchOffersSlides = () => (dispatch) => {
  dispatch(requestOffersSlides());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/content/offers-slides`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffersSlides(res.body.data));
          fulfill(res.body.data);
        } else {
          fulfill([]);
          dispatch(receiveOffersSlides([]));
        }
      })
  );
};

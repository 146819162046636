import { hasFlag } from 'country-flag-icons';

// Already a switch case to need to refactor if another expection occurs
export const isFlagException = (country) => {
  switch (country) {
    case 'UK':
      return 'GB';
    default:
      return country;
  }
};

export const getFlagUrl = (country) => {
  const actualCountryCode = isFlagException(country);
  if (hasFlag(actualCountryCode)) {
    return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${isFlagException(actualCountryCode)}.svg`;
  } else {
    return '/img/miscellaneous/no-flag.png';
  }
};

export const getCircleFlagUrl = (country) => {
  const actualCountryCode = isFlagException(country);
  if (hasFlag(actualCountryCode)) {
    return `https://purecatamphetamine.github.io/country-flag-icons/1x1//${isFlagException(actualCountryCode)}.svg`;
  } else {
    return '/img/miscellaneous/no-flag.png';
  }
};

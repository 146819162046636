const CrakRevenueLogo = ({ className }) => (
  <svg className={className} data-name="Calque 2" id="Calque_2" viewBox="0 0 524.45 69.06" xmlns="http://www.w3.org/2000/svg">
    <g data-name="Calque 1" id="Calque_1-2">
      <g>
        <path
          className="cls-12"
          d="M88.27,8.49c-.91.09-20.29,3.87-44.16,13.54-12.81,5.19-31.79,6.78-29.56-8.78,2.37-1.33,16.2,5.76,14.09-7.05-2.54,1.4-11.68-1.31-13.97,3.23C13.01,4.87,3.19,3.26,0,0c.15,8.01,5.58,11.67,11.07,12.25-17.75,16.8-.98,40.05,18.93,44.94,1.27,1.25-.55,5.15-4.03,7.64,9.39,1.09,16.67-5.25,20.88-7.65,2.96,1.55,8.22,3.27,14.44,3.11-1.29-1.63-2.33-4.27-2.67-6.89,5.65-1.89,10.77-3.91,12.51-4.85,16.34-8.8,30.13-20.47,17.13-40.06"
        />
        <g>
          <text className="cls-1" fontFamily="Montserrat-Black, Montserrat" fontWeight="800" transform="translate(101.99 54.32)">
            <tspan className="cls-7" x="0" y="0">
              C
            </tspan>
            <tspan className="cls-8" x="41.18" y="0">
              R
            </tspan>
            <tspan x="82.95" y="0">
              A
            </tspan>
            <tspan className="cls-9" x="126.97" y="0">
              K
            </tspan>
          </text>
          <text className="cls-2" transform="translate(267.24 54.32)">
            <tspan className="cls-5" x="0" y="0">
              R
            </tspan>
            <tspan className="cls-11" x="36.04" y="0">
              E
            </tspan>
            <tspan className="cls-10" x="72.85" y="0">
              V
            </tspan>
            <tspan className="cls-5" x="108.62" y="0">
              E
            </tspan>
            <tspan className="cls-3" x="141.88" y="0">
              N
            </tspan>
            <tspan className="cls-6" x="181.96" y="0">
              U
            </tspan>
            <tspan className="cls-4" x="220.68" y="0">
              E
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default CrakRevenueLogo;

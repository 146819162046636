import consts from '../utils/consts';

const { ACTIONS } = consts;

export const initialState = {
  payoutTotal: null,
  isFetchingPayoutTotal: false,
};

export const payoutTotalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PAYOUT_TOTAL:
      return {
        ...state,
        isFetchingPayoutTotal: true,
      };

    case ACTIONS.RECEIVE_PAYOUT_TOTAL:
      return {
        payoutTotal: action?.data?.Stat?.payout || null,
        isFetchingPayoutTotal: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_PAYOUT_TOTAL: {
      const fallbakcPayoutTotal = state.payoutTotal ? state.payoutTotal : 0;
      return {
        payoutTotal: fallbakcPayoutTotal,
        isFetchingPayoutTotal: false,
      };
    }

    default:
      return state;
  }
};

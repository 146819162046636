import consts from '../utils/consts';
import moment from 'moment/moment';

const {
  ACTIONS: { LIVECAM },
} = consts;

const initialState = {
  animated: false,
  campaigns: [{ id: 0, name: 'Fetching data...', payout: { name: '' } }],
  campaignsFetched: false,
  campaignsIsFetching: false,
  creatives: {},
  iframeUrl: '',
  widgetUrls: [],
  expireDays: moment.duration(60, 'minutes').asDays().toString(),
};

export const livecamGenerator = (state = initialState, action = {}) => {
  const currentCreatedCreatives = state.creatives;

  switch (action.type) {
    case LIVECAM.REQUEST_CREATIVE:
      Object.keys(currentCreatedCreatives).forEach((creativeId) => {
        currentCreatedCreatives[creativeId].code = '';
      });
      return {
        ...state,
        creatives: currentCreatedCreatives,
      };

    case LIVECAM.RESET_CREATIVES:
      return {
        ...state,
        creatives: {},
      };

    case LIVECAM.RECEIVE_CREATED_CREATIVE:
      currentCreatedCreatives[action.creativeName] = {
        id: action.creativeId,
        code: action.creativeCode,
        name: action.creativeName,
      };
      return {
        ...state,
        creatives: currentCreatedCreatives,
      };

    case LIVECAM.SET_ANIMATED_TEMPLATE:
      return {
        ...state,
        animated: action.animated,
      };

    case LIVECAM.SET_EXPIRE_DAYS:
      return {
        ...state,
        expireDays: action.expireDays,
      };

    case LIVECAM.REQUEST_CAMPAIGNS:
      return {
        ...state,
        campaignsIsFetching: true,
      };

    case LIVECAM.FAILED_TO_RECEIVE_CAMPAIGNS:
      return {
        ...state,
        campaignsIsFetching: false,
      };

    case LIVECAM.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        campaignsFetched: true,
        campaignsIsFetching: false,
        campaigns: action.campaigns,
      };

    case LIVECAM.CHANGE_LIVECAM_URL:
      return {
        ...state,
        iframeUrl: action.iframeUrl,
        widgetUrls: action.widgetUrls,
      };

    default:
      return state;
  }
};

const verifySkinName = (skinName) => {
  if (skinName === 'crakrevenue') {
    return 'affiliates';
  } else {
    return skinName;
  }
};

const NOVEMBER_MONTH_NUMBER = 10;
const NOVEMBER_DAY_NUMBER = 15;
const DECEMBER_MONTH_NUMBER = 11;
const JANUARY_MONTH_NUMBER = 0;
const JANUARY_DAY_NUMBER = 7;
const NEXT_LEVEL_JANUARY_DAY_NUMBER = 1;
const OCTOBER_MONTH_NUMBER = 8;

export const shouldDisplayChristmasLogo = () => {
  const todayUTC = new Date();
  return (
    (todayUTC.getDate() >= NOVEMBER_DAY_NUMBER && todayUTC.getMonth() === NOVEMBER_MONTH_NUMBER) ||
    todayUTC.getMonth() === DECEMBER_MONTH_NUMBER ||
    (todayUTC.getDate() <= JANUARY_DAY_NUMBER && todayUTC.getMonth() === JANUARY_MONTH_NUMBER)
  );
};

export const shouldDisplayNextLevelLogo = () => {
  const todayUTC = new Date();
  return (
    todayUTC.getMonth() >= OCTOBER_MONTH_NUMBER ||
    (todayUTC.getMonth() === JANUARY_MONTH_NUMBER && todayUTC.getDay() === NEXT_LEVEL_JANUARY_DAY_NUMBER)
  );
};

const JUNE_MONTH_NUMBER = 5;

export const shouldDisplayPrideLogo = () => {
  const todayUTC = new Date();
  return todayUTC.getMonth() === JUNE_MONTH_NUMBER;
};

export const getWhiteLogoFromSkinName = (skinName) => {
  let logoPath = '';

  if (!skinName) {
    throw `Unable to find logo from skin name ${skinName}`;
  }

  if (shouldDisplayChristmasLogo()) {
    logoPath = `/img/${verifySkinName(skinName)}/follow-the-whale-white-christmas.png`;
  }

  if (shouldDisplayPrideLogo()) {
    logoPath = `/img/${verifySkinName(skinName)}/follow-the-whale-pride.svg`;
  }

  if (skinName === 'bluechew') {
    logoPath = `/img/${skinName}/logo-white-bg.svg`;
  }

  if (logoPath === '') {
    logoPath = `/img/${verifySkinName(skinName)}/follow-the-whale-white.svg`;
  }

  return logoPath;
};

export const getBlackLogoFromSkinName = (skinName) => {
  let logoPath = '';

  if (!skinName) {
    throw `Unable to find logo from skin name ${skinName}`;
  }

  if (shouldDisplayChristmasLogo()) {
    logoPath = `/img/${verifySkinName(skinName)}/follow-the-whale-black-christmas.png`;
  }

  if (logoPath === '') {
    logoPath = `/img/${verifySkinName(skinName)}/follow-the-whale-black.svg`;
  }

  return logoPath;
};

export const getNiceToSeeYouFromSkinName = (skinName) => {
  if (!skinName) {
    throw `Unable to find logo from skin name ${skinName}`;
  }

  return `/img/${verifySkinName(skinName)}/nice-to-see-you.jpg`;
};

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';

import config from '../config';
import consts from '../utils/consts';
import { createTrendingBannerData } from '../utils/createTrendingBannerData';

const { API_URL } = config;
const { ACTIONS } = consts;
const { CREATIVES } = ACTIONS;

const requestCreatives = () => ({
  type: CREATIVES.REQUEST_CREATIVES,
});

const receiveCreatives = (data) => ({
  type: CREATIVES.RECEIVE_CREATIVES,
  data,
});

export const resetCreatives = () => ({
  type: CREATIVES.RESET_CREATIVES,
});

const failedToReceiveCreatives = () => ({
  type: CREATIVES.FAILED_TO_RECEIVE_CREATIVES,
});

const receiveCreativesTrending = (data) => ({
  type: CREATIVES.RECEIVE_CREATIVES_TRENDING,
  data,
});

export const resetCreativesTrending = () => ({
  type: CREATIVES.RESET_CREATIVES_TRENDING,
});

export const resetCreativesSizes = () => ({
  type: CREATIVES.RESET_CREATIVES_SIZES,
});

export const fetchCreatives = (query) => async (dispatch) => {
  const { fetchTrendingBanners, offerId } = query;

  const promises = [];

  const searchParams = new URLSearchParams(window.location.search);
  const useCDNDomainReplacement =
    process.env.REACT_APP_USE_SYSTEM42_CDN_REPLACEMENT === 'true' || searchParams.get('useCDNDomainReplacement');

  if (useCDNDomainReplacement) {
    query.useCDNDomainReplacement = true;
  }

  promises.push(
    new Promise((fulfill, reject) =>
      request
        .get(`${API_URL}/creatives/findAllByOfferId`)
        .withCredentials()
        .query(query)
        .end((err, res) => {
          if (!err) {
            fulfill(res.body.data);
          } else {
            if (dispatch) {
              dispatch(failedToReceiveCreatives());
            }
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    )
  );

  if (fetchTrendingBanners) {
    dispatch(requestCreatives());

    let url = new URL(process.env.REACT_APP_API_URL_PROVEN_FLOW);
    url.searchParams.append('offerId', offerId);
    url = url.href;

    promises.push(fetch(url));
  }

  try {
    let [creatives, trending] = await Promise.all(promises);

    if (fetchTrendingBanners) {
      trending = await trending.json();

      const trendingData = createTrendingBannerData({
        creatives: creatives.creatives,
        trending: trending.banners,
      });

      dispatch(receiveCreativesTrending(trendingData));
    } else {
      if (dispatch) {
        dispatch(receiveCreatives(creatives));
      } else {
        return creatives;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const requestCreativesSizes = () => ({
  type: CREATIVES.REQUEST_CREATIVES_SIZES,
});

const receiveCreativesSizes = (data) => ({
  type: CREATIVES.RECEIVE_CREATIVES_SIZES,
  data,
});

const failedToReceiveCreativesSizes = () => ({
  type: CREATIVES.FAILED_TO_RECEIVE_CREATIVES_SIZES,
});

export const fetchCreativesSizes = (query) => (dispatch) => {
  dispatch(requestCreativesSizes());
  dispatch(requestCreatives());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offer/creatives-sizes`)
      .withCredentials()
      .query(query)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveCreativesSizes(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveCreativesSizes());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestCreativeCode = () => ({
  type: CREATIVES.REQUEST_CREATIVE_CODE,
});

const receiveCreativeCode = (data) => ({
  type: CREATIVES.RECEIVE_CREATIVE_CODE,
  data,
});

const failedToReceiveCreativeCode = () => ({
  type: CREATIVES.FAILED_TO_RECEIVE_CREATIVE_CODE,
});

export const fetchCreativeCode = (query) => (dispatch) => {
  dispatch(requestCreativeCode());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offer/generate-creative-code`)
      .withCredentials()
      .query(query)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveCreativeCode(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveCreativeCode());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import notifications from './profile/notifications';
import paymentForm from './profile/paymentForm';
import paymentHistory from './profile/paymentHistory';
import referrals from './profile/referrals';
import userAndCompany from './profile/userAndCompany';

export default {
  'profile.title': 'Profile',
  'profile.profilePaymentHistoryTitle': 'Payment History',
  'profile.profileBalanceTitle': 'Balance',
  'profile.profileHistoryTitle': 'History',
  'profile.profileUploadPopupTitle': 'Choose your profile avatar',
  'profile.profileUploadPopupDragAndDrop': 'Drag & drop your image',
  'profile.profileUploadPopupUploadFromComputer': 'Upload from computer',
  'profile.profileUploadPopupOr': 'or',
  'profile.profileMenuUserInfos': 'User Details',
  'profile.profileMenuBillingInfosTitle': 'Billing Informations',
  'profile.profileMenuReferralTitle': 'Referral',
  'profile.profileMenuReferralListTitle': 'List',
  'profile.profileMenuReferralsDescription':
    'Refer new affiliates to CrakRevenue and earn 5% of their income in commissions, for life!*',
  'profile.profileMenuReferralsAsterisk': '*See terms and conditions for more details',
  'profile.profileMenuNotifications': 'Notifications',
  'profile.profileMenuSettings': 'Settings',
  'profile.profileBillingInfosTitle': 'Billing Informations',
  'profile.updateBillingInfosCompleted': 'Your billing informations has been updated successfully',
  'profile.updateSettingsCompleted': 'Your settings has been updated successfully',
  'profile.billingInfoNotice':
    'Please note that any payment method must be updated AT LEAST 5 business days before the payment date',
  'profile.paymentHistoryInfo':
    'Please note that it may take up to 5 business days following the end of a period for the payment history section to be updated',
  'profile.settingsUnsavedChecked': 'You have unsaved information, are you sure you want to leave this page?',
  'profile.notReachedMinPayout': 'Since you did not reach your minimum payout (${amount}), your balance has been carried over.',
  'profile.yourCurrentBalance': 'Carried over earnings:',
  'profile.backToReferralsTable': 'Back To Referrals Table',
  'profile.referringCampaignsTitle': 'Referring Campaigns',
  'profile.promoteCrakrevenue': 'Promote CrakRevenue and earn some commissions',
  'profile.showAdultContent': 'Show adult content',
  'profile.byChecking': "By unchecking this box, adult (18+) offers won't be shown by default in your offer list.",
  'profile.bestTimeToCall': 'Best time to call you',
  'profile.timeZone': 'Time Zone',
  'profile.salutations': 'Title',
  'profile.mainPhone': 'Mobile phone',
  ...notifications,
  ...paymentForm,
  ...paymentHistory,
  ...referrals,
  ...userAndCompany,
};

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import apiqlFactory from '../../../utils/apiqlFactory';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS, JERKMATE_OFFERS_SEARCH } = consts;

export const requestJerkmateOffers = (req) => ({
  type: ACTIONS.JERKMATE_OFFERS.REQUEST_OFFERS,
  request: req,
});
export const receiveJerkmateOffers = (offers) => ({
  type: ACTIONS.JERKMATE_OFFERS.RECEIVE_OFFERS,
  offers,
});
export const failedToReceiveJerkmateOffers = () => ({
  type: ACTIONS.JERKMATE_OFFERS.FAILED_TO_RECEIVE_OFFERS,
});
export const fetchJerkmateOffers = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    const offerRequest = request
      .post(`${API_URL}/apiql`)
      .query({
        query: apiqlFactory({ Offers: JERKMATE_OFFERS_SEARCH }),
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          if (res.body.data.Offers?.Data) {
            dispatch(receiveJerkmateOffers(res.body.data.Offers.Data));

            fulfill();
          } else {
            dispatch(failedToReceiveJerkmateOffers());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject(err);
          }
        }
      });
    dispatch(requestJerkmateOffers(offerRequest));
  });

import '../../../../assets/sass/components/pages/offer/OfferInfoTop.react.scss';
import OfferListingBottomDetails from '../../../OfferListingTable/OfferListingBottomDetails.react';
import OfferListingTags from '../../../OfferListingTable/OfferListingTags.react';
import { Gtm } from '../../../../utils/gtm';
import OfferListingApprovalStatus from '../../../OfferListingTable/OfferListingApprovalStatus.react';
import { useEffect, useState } from 'react';

const OfferInfoTop = ({ thumbnail, name, tags, verticals, id, openShareModal, approval_status }) => {
  thumbnail = thumbnail?.thumbnail;

  const [windowWidthSize, setWindowWidthSize] = useState(undefined);

  const shareClick = () => {
    openShareModal();
    Gtm.event('offers', 'Click', 'share icon');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tooltipPosition = windowWidthSize < 768 ? 'right' : 'bottom';

  return (
    <div className="offerInfoTop">
      <div className="thumnnailImg">
        {thumbnail ? <img src={thumbnail} alt={name} /> : <div className="preview">Preview</div>}
      </div>
      <div className="offerInfoDetails">
        <div className="offerInfoTitle">{name}</div>
        <OfferListingTags tags={tags} tooltipPosition={tooltipPosition} />
        <OfferListingBottomDetails verticals={verticals} id={id} className="offerInfoTopDetails" />
      </div>
      <div className="offerInfoShare">
        <OfferListingApprovalStatus approval_status={approval_status} />
        <div className="shareBtn" onClick={shareClick}>
          <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
              <path
                id="Vector"
                d="M6.875 3.0625L3.75 5.25M6.875 9.9375L3.75 7.75M3.75 6.5C3.75 6.9144 3.58538 7.31183 3.29235 7.60485C2.99933 7.89788 2.6019 8.0625 2.1875 8.0625C1.7731 8.0625 1.37567 7.89788 1.08265 7.60485C0.78962 7.31183 0.625 6.9144 0.625 6.5C0.625 6.0856 0.78962 5.68817 1.08265 5.39515C1.37567 5.10212 1.7731 4.9375 2.1875 4.9375C2.6019 4.9375 2.99933 5.10212 3.29235 5.39515C3.58538 5.68817 3.75 6.0856 3.75 6.5ZM10 10.5625C10 10.9769 9.83538 11.3743 9.54235 11.6674C9.24933 11.9604 8.8519 12.125 8.4375 12.125C8.0231 12.125 7.62567 11.9604 7.33265 11.6674C7.03962 11.3743 6.875 10.9769 6.875 10.5625C6.875 10.1481 7.03962 9.75067 7.33265 9.45765C7.62567 9.16462 8.0231 9 8.4375 9C8.8519 9 9.24933 9.16462 9.54235 9.45765C9.83538 9.75067 10 10.1481 10 10.5625ZM10 2.4375C10 2.8519 9.83538 3.24933 9.54235 3.54235C9.24933 3.83538 8.8519 4 8.4375 4C8.0231 4 7.62567 3.83538 7.33265 3.54235C7.03962 3.24933 6.875 2.8519 6.875 2.4375C6.875 2.0231 7.03962 1.62567 7.33265 1.33265C7.62567 1.03962 8.0231 0.875 8.4375 0.875C8.8519 0.875 9.24933 1.03962 9.54235 1.33265C9.83538 1.62567 10 2.0231 10 2.4375Z"
                stroke="#3D3D3D"
                stroke-width="0.9375"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <span>Share</span>
        </div>
      </div>
    </div>
  );
};

export default OfferInfoTop;

const BrokenModalFooter = () => (
  <svg fill="none" viewBox="0 0 1525 89">
    <path
      d="M1524.82 0V39.72C1524.82 66.64 1502.99 88.47 1476.07 88.47H48.75C21.83 88.47 0 66.64 0 39.72V0H477.4L497.15 38.1299H1029.41L1049.16 0H1524.82Z"
      fill="url(#paint0_linear_458_1611)"
    />
    <g style={{ mixBlendMode: 'color-dodge' }}>
      <mask height="89" id="mask0_458_1611" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }} width="1525" x="0" y="0">
        <path
          d="M1524.82 0V39.72C1524.82 66.64 1502.99 88.47 1476.07 88.47H48.75C21.83 88.47 0 66.64 0 39.72V0H477.4L497.15 38.1299H1029.41L1049.16 0H1524.82Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_458_1611)">
        <path
          d="M739.89 28.96C695.78 56.03 645.51 26.87 647.75 -26.29C649.81 -75.04 694.98 -116.66 730.51 -130.64C761.82 -142.96 812.39 -131.98 821.34 -87.44C832.39 -32.44 779.36 4.72998 739.89 28.95V28.96Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.29"
        />
        <path
          d="M790.66 100.23C714.14 151.72 623.24 67.41 621.62 -11.32C620.13 -83.5 690.7 -135.52 745.27 -152.39C795.81 -168.01 846.84 -133.28 862.04 -76.97C868.38 -53.48 873 -27.47 870 -3.01999C864.23 44.02 827.61 75.37 790.66 100.23Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.35"
        />
        <path
          d="M809.24 129.45C776.94 148 708.58 173.33 673.63 141.53C633.42 104.94 602.48 54.14 598.27 -0.949982C590.93 -97.03 683.29 -156.28 759.74 -172.9C830.26 -188.23 877.6 -125.37 896.38 -59.39C901.03 -43.05 905.85 -26.79 911.71 -10.83C916.94 3.43001 927.1 19.75 928.96 34.9C930.37 46.37 927.96 46.84 919.56 54.12C910.79 61.71 901.32 68.44 891.81 75.05C864.77 93.86 836.02 114.05 809.24 129.43V129.45Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.41"
        />
        <path
          d="M813.64 156.79C769.56 179.94 715.1 194.34 666.18 163.68C615.61 131.99 585.64 63.44 577.49 6.05002C561.09 -109.41 669.72 -178.96 771.2 -193.37C861.78 -206.23 907.98 -109.5 926.05 -37.15C930.78 -18.22 936.47 0.429987 945.43 17.83C949.23 25.21 964.62 41.79 964.21 49.77C963.97 54.46 958.15 61.69 955.64 65.15C943.86 81.43 925.35 92.2 908.32 102.05C876.73 120.32 843.54 141.09 813.65 156.79H813.64Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.48"
        />
        <path
          d="M810.29 186.34C759.95 210.79 703.43 239.56 654.79 190.32C607.44 142.38 572.2 76.96 558.88 11.05C546.37 -50.87 558.3 -108.46 608.97 -149.36C653.78 -185.53 718.78 -213.69 776.92 -215.05C825.26 -216.18 875.17 -178.88 903.77 -142.7C933.63 -104.94 944.26 -59.21 952.96 -12.93C957.78 12.71 966.63 33.17 976.75 56.79C982.38 69.93 984.46 84.28 976.97 98.46C967.72 115.97 945.73 124.79 928.57 132.37C888.72 149.99 847.54 168.23 810.28 186.33L810.29 186.34Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.54"
        />
        <path
          d="M801.75 217.46C771.08 231.31 740.78 258.35 707.85 266.25C679.87 272.96 659.75 253.17 644.28 232.27C620.11 199.61 604.29 161.49 585.91 125.51C567.77 90.02 552.05 54.09 541.95 15.56C524.05 -52.72 527.29 -119.61 585.72 -166.66C639.01 -209.57 711.88 -237.23 778.32 -235.9C805.47 -235.35 831.08 -222 853.36 -205.88C879.3 -187.11 905.02 -167.84 925.05 -142.57C959.79 -98.76 973.05 -43.98 979.3 10.52C982.4 37.53 988.3 61.61 995.89 87.45C999.9 101.13 1010.48 121.42 1005.63 135.72C997.49 159.71 956.53 167.2 935.9 173.14C890.34 186.26 845.01 197.93 801.75 217.47V217.46Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.6"
        />
        <path
          d="M790.56 249.54C755.24 264.22 734.32 300.29 701.79 322.18C672.05 342.2 650.63 314.68 635.5 290.33C605.51 242.02 586.33 186.38 566.62 133.32C552.74 95.94 537.36 59.55 525.93 21.31C503.66 -53.18 493.11 -129.62 560.07 -183.56C621.76 -233.24 707.57 -251.2 782.87 -249.88C847.36 -248.75 905.28 -191.94 945.3 -143.72C984.97 -95.92 1004.32 -30.99 1007.4 30.3C1008.77 57.5 1003.63 90.02 1014.63 115.66C1022.16 133.22 1048.72 154.35 1045.18 174.89C1040.17 203.94 978.05 209.09 956.51 213.18C900.71 223.77 840.12 228.93 790.55 249.54H790.56Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.66"
        />
        <path
          d="M779.27 281.93C738.49 296.89 723.26 349.29 696.02 386.27C669.02 422.91 645.58 393.08 628.73 363.24C571.13 261.17 542.21 141.63 509.6 29.86C486.4 -49.66 457.3 -139.77 533.16 -199.56C600.87 -252.93 709.77 -259.81 790.14 -258.34C864.06 -256.99 918.39 -201.48 964.95 -147.08C1007.98 -96.81 1039.48 -23.48 1039.63 43.27C1039.71 77.7 1022.15 111.08 1042.56 142.13C1055.05 161.14 1098.11 188.85 1096.52 214.26C1094.41 248.11 1008.18 251.54 987.19 254.3C917.99 263.42 837.52 260.57 779.26 281.94L779.27 281.93Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.72"
        />
        <path
          d="M770.43 314.02C724.1 324.27 709.62 413.06 692.5 453.82C687.36 466.05 679.25 489.71 662.71 490.05C644.32 490.43 630.29 459.4 623.58 447C579.55 365.6 551.42 272.21 524.69 183.96C509.7 134.46 496.95 84.09 483.4 34.26C462.5 -42.64 425.9 -158.99 506.74 -213.62C546.02 -240.16 597.55 -247.55 643.54 -253.23C695.11 -259.6 748.56 -263.67 799.31 -262.89C876.83 -261.7 935.85 -213.83 984.65 -154.54C1007.83 -126.38 1031.79 -94.64 1043.36 -59.61C1054.46 -25.99 1062.06 11.61 1065.01 46.8C1066.79 67.95 1062.49 85.2 1059.69 105.71C1057.15 124.32 1065.49 140.85 1075.49 156.22C1089.24 177.34 1108.24 193.24 1126.39 210.41C1136.45 219.94 1157.23 236.4 1157.41 252.22C1157.61 269.31 1135.99 276.02 1122.78 280.03C1089.16 290.24 1053.11 293.28 1018.29 296.54C935.92 304.25 844.43 297.62 770.41 314L770.43 314.02Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.78"
        />
        <path
          d="M766.58 345.17C737.08 346.05 724.18 393.71 717.32 416.26C706.91 450.48 700.2 485.68 690.78 520.17C687.02 533.95 679.9 572.04 662.93 578.1C642.38 585.44 625.72 547.76 619.05 535.32C568.73 441.47 538.51 334.53 508.16 232.93C489.07 169.03 473.65 103.95 457.95 39.33C445.28 -12.83 434.17 -67.8 433.74 -121.81C433.39 -165.09 443.64 -202.92 483.57 -224.96C528.62 -249.82 585.31 -253.82 635.56 -258.11C693.38 -263.04 752.03 -265.38 809.63 -265.12C843.22 -264.97 877.04 -261.25 907.57 -245.65C944.82 -226.62 976.32 -195.49 1003.94 -164.66C1027.97 -137.84 1045.62 -115.03 1051.96 -79.52C1059.66 -36.37 1076.39 4.71998 1085.25 47.2C1090.03 70.12 1084.26 89.81 1084.79 112.21C1085.37 136.61 1106.66 160.81 1121.93 177.57C1141.58 199.13 1163.59 218.48 1184.72 238.55C1197.24 250.44 1222.6 268.17 1223.82 287.73C1225.19 309.8 1193.98 316.9 1178.05 320.97C1140.53 330.56 1101.33 333.7 1062.85 336.87C964.23 345 863.25 342.31 766.59 345.18L766.58 345.17Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.84"
        />
        <path
          d="M767.85 379.55C740.33 382.22 725.23 428.6 717.31 453.65C704.5 494.17 697.93 536.2 689.55 577.76C685.24 599.14 681.27 647.94 659.77 660.9C638.69 673.61 620.76 632.99 613.48 619.48C583.59 564.03 564.13 502.46 543.94 443.02C522.66 380.37 502.34 317.36 483.59 253.91C463.21 184.92 446.53 114.77 432.18 44.59C421.32 -8.48 414.32 -63.72 413.3 -118.11C412.42 -165.13 416.53 -212.91 464.34 -234.2C515.3 -256.89 577.02 -258.45 631.8 -261.72C694.52 -265.47 758.11 -266.17 820.28 -266.66C854.26 -266.93 889.36 -268.86 921.42 -254.6C960.12 -237.4 992.96 -206.22 1022.53 -176.64C1032.19 -166.98 1051.32 -152.24 1052.64 -137.53C1053.46 -128.36 1048.06 -118.84 1048.08 -109.39C1048.13 -89.74 1056.45 -69.91 1063.54 -51.99C1076.51 -19.23 1092.37 13.06 1102.57 45.99C1106.67 59.24 1110.53 73.66 1110.56 88C1110.59 99.98 1107.78 110.34 1110.49 122.33C1116.58 149.24 1138.37 172.21 1157.13 191.09C1181.9 216.02 1209.62 237.74 1236.42 260.39C1252.48 273.96 1286.86 296.22 1288.64 319.8C1290.49 344.27 1258.71 351.89 1240.18 356.69C1187.04 370.46 1129.81 374.66 1075.16 375.55C972.8 377.21 858.61 370.78 767.85 379.57V379.55Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.9"
        />
        <path
          d="M771.57 421.55C744.63 424.5 726.29 465.63 716.52 491.2C700.4 533.37 691.7 578.2 682.91 622.34C678.63 643.82 674.68 665.41 669.5 686.7C666.71 698.14 663.87 721.07 653.7 729.08C631.42 746.62 611.42 700.5 604.39 686.46C575.81 629.37 555.73 567.32 534.93 507.11C510.22 435.55 486.76 363.53 464.38 291.2C439.84 211.91 417.47 131.74 405.1 49.65C396.7 -6.08001 394.68 -63.55 395.16 -119.9C395.57 -168.55 398.2 -220.22 449.34 -241.55C502.17 -263.59 569.2 -262.02 625.37 -264.81C693.65 -268.2 763.95 -267.88 830.49 -269.12C863.96 -269.74 900.6 -278.43 933.79 -265.93C972.22 -251.46 1010.94 -220.6 1039.03 -191.27C1044.37 -185.7 1056.53 -174.59 1051.68 -165.44C1050.37 -162.96 1042.06 -161.59 1039.72 -159.29C1035.48 -155.12 1034.22 -149.9 1033.87 -144.07C1032.47 -120.66 1048.35 -95.23 1058.43 -75.27C1078.62 -35.28 1102.36 3.87999 1118.97 44.46C1125.81 61.16 1131.94 79.18 1132.26 97.85C1132.46 109.53 1131.35 119.64 1134.75 131.16C1143.37 160.39 1165.69 184.96 1186.85 205.95C1214.48 233.36 1245.43 257.13 1275.72 281.47C1295.56 297.41 1333.89 321.24 1341.66 347.79C1350.37 377.57 1298.04 387.71 1278.94 390.99C1219.48 401.2 1157.04 401.24 1096.94 404.6C988.96 410.65 866.12 411.21 771.58 421.56L771.57 421.55Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="0.96"
        />
        <path
          d="M776.94 468.68C747.65 470.35 725.02 504.62 712.09 529.82C693.02 567 682.22 608.13 672.36 648.53C663.84 683.46 660.25 728.75 641.51 760.33C620.78 795.26 597.7 741.44 589.06 723.87C555.75 656.14 532.29 582.79 507.78 511.58C482.94 439.4 458.75 366.98 435.18 294.37C409.72 215.96 383.32 136.42 375.73 54.13C370.43 -3.36003 375.88 -62.43 378.76 -120.09C381.3 -170.87 384.44 -224 437.92 -246.27C491.83 -268.72 561.57 -265.69 618.86 -268.74C692.27 -272.65 768.58 -272.36 839.45 -274.14C871.09 -274.93 906.6 -289.68 939.25 -281.51C959.67 -276.4 978.43 -264.65 995.74 -253.07C1010.77 -243.02 1040.55 -228.2 1047.56 -211.23C1051.91 -200.68 1050.77 -202.37 1040.17 -201.43C1026.48 -200.21 1014.4 -204.96 1012.32 -186.24C1009.3 -158.99 1039.06 -119.42 1051.99 -98.02C1080.42 -50.95 1112.49 -4.78001 1136.06 43.64C1144.3 60.58 1153.02 79.74 1154.11 99.32C1154.89 113.4 1153.14 125.72 1156.95 139.74C1165.32 170.51 1186.61 196.89 1208.05 219.65C1236.03 249.35 1268.2 274.77 1299.81 300.43C1322.91 319.19 1360.7 342.5 1371.37 371.87C1383.62 405.55 1329.2 411.98 1307.35 415.84C1240.36 427.7 1171.72 431.15 1104.04 437.19C1038.89 443 973.85 449.74 908.87 457.12C865.2 462.08 818.27 466.31 776.94 468.67V468.68Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.03"
        />
        <path
          d="M783.15 518.46C712.48 518.08 676.47 601.43 657.81 656.94C645.89 692.4 640.2 734.76 620.86 767.18C602.29 798.3 575.74 749.77 566.72 733.16C531.54 668.42 509.98 594 485.69 524.72C459.43 449.83 433.52 374.81 407.2 299.94C379.78 221.94 345.12 141.3 343.12 57.84C341.69 -1.88 357.35 -62.19 363.57 -121.71C368.95 -173.2 374.15 -227.27 428.25 -249.94C481.97 -272.45 551.29 -270.08 608.38 -274.24C687.5 -280.01 769.75 -281.45 846.37 -283.26C879.6 -284.05 915.91 -305.42 950.3 -297.8C971.51 -293.1 991.17 -280.9 1009.25 -269.47C1017.52 -264.24 1052.44 -245.78 1051.69 -234.36C1050.97 -223.48 990.76 -267.62 986.24 -232.27C984.78 -220.83 993.63 -206.3 998.67 -196.36C1012.31 -169.45 1029.83 -144.37 1046.83 -119.53C1083.87 -65.4 1124.67 -12.05 1155.25 44.39C1166.15 64.5 1176.57 85.7 1176.01 109.46C1175.7 122.73 1174.64 134.86 1177.27 148.1C1183.54 179.62 1200.99 207.68 1221.07 232.25C1246.88 263.83 1277.93 290.63 1308.61 317.31C1328.98 335.02 1374.08 363.96 1378.44 393.07C1384.05 430.43 1333.29 442.42 1307.16 447.22C1238.04 459.91 1167.29 463.96 1097.54 471.96C1036.67 478.95 976.08 487.8 915.77 498.62C872.43 506.39 827.28 518.71 783.13 518.47L783.15 518.46Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.09"
        />
        <path
          d="M789.4 568.37C724.85 564.87 669.51 592.02 638.55 652.33C630.12 668.75 623.39 685.96 616.5 703.06C611.38 715.76 606.5 737.61 596.47 747.45C573.39 770.09 547.13 726.49 537.61 710.6C500.76 649.09 480.02 578.16 456.07 511.05C431.04 440.92 405.83 370.86 378.78 301.47C349.67 226.79 301.52 143.35 306.37 60.45C309.93 -0.350018 337.48 -58.2 347.96 -118.08C357.31 -171.5 363.02 -229.52 419.42 -253.84C443.97 -264.43 471.19 -266.47 497.4 -269.91C532.77 -274.54 568.09 -279.3 603.56 -283.12C685.44 -291.95 770.88 -295.97 850.48 -298.15C872.15 -298.74 892.15 -304.7 913.16 -312.01C928.42 -317.32 942.19 -320.84 958.41 -317.42C979.99 -312.87 1001.01 -301.07 1019.31 -289.26C1020.23 -288.66 1059.58 -261.9 1055.26 -258.16C1049.22 -252.93 1014.07 -273.23 1009.94 -275.21C996.24 -281.76 965.9 -307.08 960.3 -280.67C956.82 -264.28 976.75 -237.64 984.34 -225.33C998.32 -202.65 1014.11 -181.1 1029.81 -159.59C1079.8 -91.1 1136.3 -24.7 1177.6 47.17C1188.94 66.91 1200.76 88.24 1198.6 112.02C1197.22 127.25 1195.43 141.91 1196.72 157.27C1199.27 187.58 1210.28 216.23 1226.21 241.96C1247.88 276.98 1277.5 306.14 1306.45 335.07C1328.46 357.08 1360.4 380.9 1359.35 415.34C1358.21 452.56 1320.15 476.81 1287.75 484.7C1257.87 491.98 1225.02 492.65 1194.45 495.57C1157.71 499.07 1120.96 502.51 1084.34 507.13C1023.65 514.78 963.71 526.16 904.62 541.99C867.64 551.89 825.68 570.36 789.41 568.39L789.4 568.37Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.15"
        />
        <path
          d="M794.9 615.93C742.05 611.64 666.05 582.32 619.18 632.1C599.19 653.34 590.23 689.39 567.36 706.76C541.41 726.48 517.2 690.47 504.88 671.46C467.83 614.23 446.09 547.94 422.98 484.35C398.98 418.32 374.15 352.56 345.41 288.42C325.15 243.2 301.41 199.52 281.79 154.04C269.35 125.19 260.56 93.32 264.61 61.84C272.64 -0.570004 315.93 -56.08 331.8 -117.44C345.92 -172.04 352.62 -232.99 410.43 -259.17C435.29 -270.43 463.25 -272.9 489.94 -276.79C524.71 -281.86 559.07 -289.82 593.74 -295.58C678.88 -309.73 765.3 -318.23 851 -319.6C874.6 -319.98 895.43 -324.96 917.48 -333.46C930.63 -338.53 942.92 -342.65 957.31 -340.98C977.6 -338.64 998.43 -327.9 1015.72 -317.58C1028.8 -309.77 1056.8 -296.14 1062.54 -281.23C1044.19 -280.43 1020.06 -297.46 1004.43 -305.37C989.7 -312.82 975.32 -320.85 960.76 -328.6C949.17 -334.77 941.83 -340.46 938.74 -323.95C935.46 -306.43 958.3 -277.71 966.68 -264.88C988.7 -231.17 1013.9 -199.47 1038.78 -167.87C1095.52 -95.79 1158.78 -24.88 1203.86 52.29C1216.62 74.14 1225.97 93.09 1221.93 118.96C1219.42 134.99 1217.4 151.16 1216.66 167.39C1215.26 198.06 1219.14 228.33 1230.79 256.88C1244.33 290.07 1266.43 318.62 1288.47 346.53C1309.78 373.52 1331.33 401.53 1327.04 438.08C1322.44 477.32 1288.94 506.18 1254.85 521.4C1226.58 534.02 1197.55 533.2 1167.13 534.76C1130.95 536.61 1094.77 538.81 1058.79 543.08C1004.22 549.55 950.89 561.3 899.48 580.88C866.53 593.43 826.96 618.55 794.9 615.95V615.93Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.21"
        />
        <path
          d="M798.85 658.63C763.22 655.92 724.44 633.11 687.79 618.93C659.37 607.93 627.18 597.72 597.39 609.63C570.17 620.52 560.29 648.21 536.57 661.78C508.88 677.62 485.14 647.34 470.95 626.99C398.15 522.64 373.25 391.04 313.72 279.35C291.83 238.29 265.05 200.38 241.86 160.13C224.63 130.22 210.26 96.9 217.06 61.89C223.78 27.31 246.86 -2.98997 266.37 -31.63C285.45 -59.64 303.48 -88.19 314.6 -120.41C333.54 -175.3 342.31 -238.74 400.64 -266.66C425.57 -278.59 453.61 -281.66 480.5 -286.38C514.03 -292.27 546.08 -303.61 579 -312.07C667.61 -334.85 759.1 -340.29 847.97 -340.09C871.52 -340.04 893.44 -346.13 915.24 -355.6C930.4 -362.19 944.28 -368.21 961.18 -365.83C986.33 -362.28 1010.23 -347.57 1031.21 -334.29C1042.93 -326.87 1068.56 -315.07 1073.44 -301.35C1053.17 -300.7 1025.89 -320.02 1008.51 -328.76C990.35 -337.9 972.29 -347.32 954.54 -357.24C944.1 -363.07 931.24 -377.27 927.13 -362.1C922.52 -345.08 945.23 -316.87 953.6 -304.25C979.44 -265.29 1009.2 -228.98 1038.76 -192.81C1106.03 -110.5 1183.86 -29.83 1234.58 59.8C1247.4 82.45 1251.49 100.68 1248.19 126.74C1246.29 141.74 1243.51 156.61 1240.73 171.47C1234.9 202.73 1229.11 234.21 1232.95 266.11C1241.12 334 1304.37 389.64 1290.15 459.94C1281.67 501.85 1250.41 536.07 1214.19 556.99C1184.54 574.11 1158.88 578.67 1125.08 577.53C1094.92 576.52 1064.67 576.22 1034.55 578.29C984.09 581.76 935.25 592.89 890.49 616.93C862.09 632.18 829.14 660.96 798.84 658.65L798.85 658.63Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.27"
        />
        <path
          d="M800.44 693.97C715.52 693.27 660.09 597.96 576.21 590.85C562.55 589.69 548.55 591.66 537.07 599.01C525.94 606.13 518.47 616.78 505.4 621.24C476.45 631.12 453.38 606.09 437.59 585.11C368.76 493.67 348.39 376.01 286.61 280.63C245.44 217.06 177.49 147.04 192.26 64.38C205.23 -8.22002 264.73 -58.41 293.98 -123.48C319.04 -179.22 330.01 -245.81 389.56 -276.68C414.79 -289.76 443.26 -293.63 470.67 -299.68C504.09 -307.05 533.14 -323.35 565.56 -333.52C655.09 -361.6 751.98 -358.16 842.06 -357.94C880.37 -357.85 906.85 -380.57 942.61 -389.58C980.17 -399.04 1020.06 -369.16 1049.61 -349.96C1055.8 -345.94 1087.52 -329.19 1087.44 -321.66C1087.35 -313.27 1066.16 -325.1 1060.43 -327.54C1035.94 -337.97 1012.12 -350.08 988.39 -362.11C971.92 -370.46 948.21 -388.47 930.33 -391.68C926.3 -369.82 946.09 -344.59 957.65 -327.82C989.34 -281.85 1026.03 -238.89 1061.55 -195.89C1131.21 -111.57 1211.66 -27.62 1262.82 65.23C1274.93 87.2 1278.01 105.49 1275.37 130.67C1273.52 148.32 1270.74 165.9 1267.04 183.26C1260.34 214.69 1247.94 244.63 1241.82 276.13C1235.22 310.08 1237.65 341.59 1248.54 374.05C1260.17 408.71 1265.26 444.18 1255.49 480.01C1242.86 526.34 1210.08 566.6 1170.86 593.37C1151.08 606.88 1125.98 620.61 1101.49 622.83C1071.75 625.52 1038 613.99 1007.78 613.2C965.09 612.08 924.59 620 889.16 644.7C861.63 663.89 836.45 694.26 800.44 693.97Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.33"
        />
        <path
          d="M801.28 719.03C707.22 718.78 640.98 620.71 556.18 585.35C542.77 579.76 530.85 576.08 516.6 580.06C503.94 583.6 491.62 589.06 478.46 590.66C447.56 594.41 424.63 574.51 406.18 551.93C343.01 474.64 319.11 373.42 263.2 291.58C220.28 228.76 158.57 149.27 171 68.08C182.37 -6.18002 236.36 -62.67 269.09 -127.65C297.84 -184.72 317.7 -255.77 376.92 -289.39C402.7 -304.03 431.85 -309.18 460.02 -317.22C493.17 -326.68 522.29 -345.31 554.98 -355.86C645.55 -385.09 742.72 -374.04 834.07 -373.85C877.59 -373.76 905.71 -403.47 945.82 -413.73C985.22 -423.8 1028.44 -392.02 1059.39 -371.88C1070.83 -364.44 1099.1 -351.59 1105.02 -338.01C1110.06 -326.47 1093.22 -335.36 1086.29 -337.9C1039.67 -354.98 996.95 -381.58 950.6 -399.02C954.44 -393.32 955.81 -379.85 958.2 -373.94C963.52 -360.78 971.38 -348.36 978.78 -336.32C1009.48 -286.32 1047.88 -240.44 1084.78 -194.99C1155.41 -107.98 1239.77 -22.78 1291.81 72.48C1311.45 108.42 1302 151.07 1293.52 191.41C1286.31 225.66 1275.73 259.18 1261.98 291.36C1248.1 323.85 1236.74 349.57 1238.26 385.46C1239.9 424.16 1240.35 460.75 1227.63 498C1211.11 546.38 1179.37 590.28 1140.66 623.43C1121.65 639.7 1098.81 655.71 1074.14 661.86C1047.07 668.61 1020.81 651.74 994.18 646.43C952.42 638.11 926.18 648.25 893.01 673.08C865.24 693.87 836.03 719.14 801.29 719.04L801.28 719.03Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.39"
        />
        <path
          d="M803.63 734.4C712.4 734.19 625.6 655.41 547.27 607.38C516.06 588.25 488.33 581.61 452.95 575.9C421.73 570.87 398.14 554.66 377.52 531.09C319.29 464.53 286.09 376.97 239.68 302.2C198.26 235.46 142.89 155.57 152.37 72.76C161.4 -6.10001 214.1 -68.86 246.59 -138.65C273.97 -197.46 305.47 -268.76 362.47 -304.67C387.73 -320.59 416.63 -327.92 444.42 -338C477.41 -349.97 509.13 -365.62 542.15 -377.18C633.59 -409.22 732.17 -386.23 824.68 -388.47C858.75 -389.29 885.43 -404.95 914.69 -421.78C938.69 -435.59 961.21 -444.44 988.86 -435.91C1005.98 -430.63 1140.75 -354.24 1128.94 -343.01C1119.87 -334.39 1050.13 -370.17 1040.71 -373.96C1026.65 -379.61 983.39 -406.4 986.9 -378.5C991.01 -345.85 1016.22 -313.47 1034.27 -287.26C1064.77 -243 1098.67 -201.18 1132.86 -159.75C1197.11 -81.89 1274.33 -5.55996 1321.18 80.06C1339.72 113.93 1327.88 161.42 1319.98 199.47C1312.31 236.42 1300.85 272.62 1285.88 307.26C1272.09 339.17 1250.36 368.62 1240.46 401.91C1229.36 439.25 1224.83 477.47 1209.91 513.85C1188.79 565.36 1156.52 613.22 1118.09 653.39C1103.14 669.01 1086.44 685.75 1066.76 695.28C1045.37 705.64 1033.64 693.57 1012.97 686.55C933.44 659.52 875.34 734.56 803.64 734.39L803.63 734.4Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.45"
        />
        <path
          d="M806.99 743.26C709.78 743.11 619.91 678.27 534.27 632.79C501.05 615.15 467.72 595.96 433.75 579.93C402.67 565.27 375.68 549.81 352.01 524.28C296.46 464.38 258.34 389.55 219.21 318.74C178.83 245.68 129.11 165.12 135.48 78.13C141.44 -3.21002 189.23 -70.81 220.98 -143.42C249.09 -207.7 287.35 -280.86 346.32 -322.11C399.29 -359.16 468.26 -374.39 527.89 -397.48C620.91 -433.49 721.97 -400.16 814.69 -402.54C851.92 -403.5 881.37 -423.6 913.16 -443.01C940.43 -459.66 966.31 -469.02 997.76 -458.16C1032.85 -446.04 1067.03 -424.6 1097.63 -403.93C1106.55 -397.91 1162.9 -364.37 1157.64 -351.22C1148.42 -328.17 1015.47 -423.57 1028.93 -347.04C1035.09 -311.99 1060.67 -277.92 1080.89 -249.8C1110.12 -209.13 1142.08 -170.52 1174.37 -132.28C1234.29 -61.31 1304.18 7.15003 1350.69 86.94C1368.94 118.24 1353.37 174.17 1346.25 208.49C1338.21 247.19 1326.22 285.11 1310.53 321.4C1295.61 355.92 1277.31 388.6 1256.41 419.83C1233.97 453.37 1221.19 492.03 1203.61 528.16C1178.34 580.11 1147.41 629.21 1113.56 675.97C1092.65 704.86 1069.89 743.31 1030.11 727.88C954.49 698.55 880.48 743.35 807 743.24L806.99 743.26Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.52"
        />
        <path
          d="M810.88 748.81C707.63 748.73 615.2 702.46 523.68 660.18C453.48 627.75 383.98 586.91 329.54 531.11C271.98 472.12 230.43 402.66 194.44 329.2C156.44 251.64 116.43 172.5 119.34 83.91C122.07 0.739998 163.41 -73.23 195.32 -148.13C225.36 -218.65 267.5 -292.79 328.73 -340.79C384.57 -384.57 454.78 -394.24 519.26 -419.53C613.51 -456.5 712.83 -414.27 804.8 -416.71C874.26 -418.55 930 -508.57 1005.09 -480.75C1041.64 -467.2 1076.95 -446.45 1109.3 -424.9C1127.1 -413.04 1144.35 -400.28 1160.8 -386.6C1166.5 -381.86 1188.22 -367.54 1187.89 -358.53C1187.29 -342.05 1136.29 -359.84 1127.5 -360.34C1090.85 -362.45 1072.5 -340.1 1079 -304.03C1085.12 -270.11 1108.98 -239.43 1129.21 -212.51C1158.63 -173.35 1190.7 -136.27 1223.03 -99.52C1276.96 -38.22 1338.22 24.48 1380.05 92.58C1390.1 108.95 1384.12 136.51 1382.21 155.03C1380.04 176.02 1376.78 196.89 1372.49 217.55C1364.09 258 1351.55 297.65 1335.16 335.57C1302.51 411.11 1249.24 471.42 1208.43 541.94C1179.79 591.45 1153.62 642.52 1124.04 691.46C1108.24 717.6 1081.45 766.56 1044.96 766.22C1031.44 766.1 1015.2 757.05 1001.58 754.54C992.2 752.82 982.72 751.69 973.21 750.96C954.95 749.56 936.6 750 918.31 750.06C882.49 750.18 846.6 748.83 810.88 748.81Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.58"
        />
        <path
          d="M814.79 754.23C634.99 754.12 440.27 678.19 309.5 548.83C278.73 518.4 255.19 485.33 231.74 449.11C210.39 416.13 188.43 383.5 172.38 347.53C134.95 263.65 103.42 181.87 102.9 89.8C102.42 3.83002 138.35 -78.66 171.52 -157.24C202.91 -231.61 248.77 -306.37 310.06 -359.81C367.92 -410.26 441.4 -416.8 510.98 -441.86C557.02 -458.44 600.65 -460.79 649.17 -454.2C698.02 -447.57 748.97 -430.42 795.8 -431.68C840.2 -432.88 875.85 -464.46 914.01 -488.53C948.88 -510.52 979.28 -517.01 1017.94 -500.89C1055.54 -485.21 1091.61 -465.51 1125.45 -442.84C1143.01 -431.08 1160.01 -418.45 1176.34 -405.03C1189.99 -393.81 1208.06 -381.82 1217.43 -366.57C1224.09 -355.73 1224.13 -353.57 1212.46 -351.48C1199.92 -349.24 1187.59 -351 1174.96 -347.37C1137.06 -336.48 1128.04 -300.31 1134.18 -264.66C1140.43 -228.39 1161.44 -201.24 1184.01 -173.18C1213.02 -137.1 1244.57 -103.11 1275.78 -68.95C1321.74 -18.65 1378.12 38.73 1409.13 96.72C1417.3 112 1410.73 143.8 1408.91 161.36C1406.64 183.26 1403.25 205.03 1398.77 226.59C1390 268.8 1376.92 310.16 1359.82 349.73C1343.59 387.29 1323.81 423.26 1300.79 457.08C1276.89 492.19 1246.7 521.96 1222.39 556.59C1189.92 602.86 1165.9 654.99 1137.34 703.69C1119.47 734.16 1092.58 787.88 1054.04 796.95C1033.35 801.82 1006.6 789.66 985.4 786.14C966.13 782.94 946.82 780.18 927.73 776C890.07 767.75 852.5 754.26 814.79 754.24V754.23Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.64"
        />
        <path
          d="M818.21 762.72C715.94 762.63 600.56 758.74 500.66 715.7C421.91 681.77 351.46 632.91 290.78 572.55C257.56 539.5 236.07 497.49 210.92 458.36C190.41 426.45 166 396.45 148.8 362.55C106.93 280.03 86.82 186.91 85.04 95.47C83.29 5.44997 112.54 -84.14 147.76 -166.4C181.05 -244.15 229.39 -319.18 290.96 -377.63C350.33 -433.99 423.92 -439.91 498.36 -463.19C544.31 -477.56 583.45 -484.17 631.84 -476.76C684.1 -468.76 738.3 -446.76 788.4 -448.15C835.35 -449.45 873.55 -485.4 913.74 -511.17C954.39 -537.24 986.76 -539.91 1030.83 -521.07C1108.23 -487.97 1183.78 -439.63 1241.85 -378.17C1267.59 -350.93 1239.09 -349.22 1219.03 -332.34C1186.49 -304.95 1186.49 -260.45 1195.8 -222.11C1212.85 -151.81 1277.89 -102.61 1325.78 -51.67C1366.75 -8.09001 1420.63 42.64 1437.7 99.61C1443.62 119.37 1437.78 146.68 1435.6 167.67C1433.24 190.48 1429.7 213.16 1425.04 235.61C1415.91 279.57 1402.28 322.65 1384.47 363.86C1349.59 444.56 1298.89 508.53 1241.69 574.03C1204.92 616.13 1178.75 667.79 1150.64 715.74C1129.01 752.64 1103.7 804.3 1063.5 824.64C1042.34 835.35 1019.19 826.68 997.21 822.14C976.63 817.88 956.57 812.45 937.07 804.58C897.87 788.75 859.43 762.76 818.23 762.72H818.21Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M820.66 777.47C715.19 777.35 596.36 789.25 492.24 744.81C412.95 710.97 329.48 660.64 272.13 595.15C238.32 556.55 218.73 506.52 191.07 463.62C169.84 430.69 141.44 402.35 121.94 368.41C100.19 330.56 88.7296 282.81 79.1196 240.52C68.6396 194.38 65.0196 147.37 64.5796 100.58C62.8996 -79.14 144.75 -266.13 271.99 -393.36C300.72 -422.09 332.62 -444.51 371.15 -458.04C409.07 -471.36 449.29 -475.64 488.02 -485.87C509.94 -491.66 530.7 -500.7 553.41 -503.2C578.5 -505.97 604.05 -503.06 628.72 -498.4C680.13 -488.68 733.35 -465.31 783.34 -466.77C832.96 -468.22 872.65 -507.85 914.68 -534.96C934.29 -547.61 956.16 -559.2 980.07 -559.68C1000.83 -560.1 1020.08 -551.18 1038.83 -543.27C1121.62 -508.33 1197.73 -457.04 1261.16 -393.37C1270.67 -383.82 1285.97 -371.84 1285.06 -356.86C1284.29 -344.25 1271.06 -331.55 1265.11 -321.24C1241 -279.54 1246.47 -231.43 1261.8 -187.53C1272.99 -155.5 1287.67 -133.57 1311.94 -110.52C1339.28 -84.56 1367.07 -58.97 1392.31 -30.92C1425.99 6.51 1460.47 52.98 1465.64 101.95C1474.75 188.2 1446.08 290.54 1411.07 373.48C1376.04 456.47 1324.67 531.99 1261.17 595.81C1222.61 634.57 1193.71 678.76 1165.59 725.48C1139.57 768.7 1113.47 824.66 1069.33 852.49C1032.99 875.4 978.55 854.69 944.01 835.7C904.48 813.97 865.76 777.53 820.67 777.48L820.66 777.47Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.76"
        />
        <path
          d="M1492.85 104.75C1493.11 296.06 1415.59 482.85 1280.13 618.31C1243.38 655.06 1210.66 689.56 1182.28 733.74C1151.67 781.4 1124.25 840.32 1079.04 876.4C985.04 951.41 910.25 801.75 821.63 801.67C707.22 801.56 592.87 820.06 483.87 773.96C439.32 755.12 396.68 731.78 356.77 704.46C319.41 678.88 280.69 650.62 254.06 613.56C222.82 570.08 205.07 518.71 176.99 473.29C155.08 437.84 123.92 412.12 99.3898 379.15C74.0898 345.14 64.7398 296.27 55.9098 255.65C45.1398 206.13 40.2798 155.36 40.2798 104.75C40.2798 -86.49 117.68 -273.48 253.01 -408.81C283.71 -439.51 316.33 -466.73 357.46 -482.07C395.47 -496.25 436.02 -500.11 475.45 -508.56C497.41 -513.27 518.38 -522.05 540.46 -525.92C564.89 -530.21 590.11 -527.9 614.35 -523.56C670.05 -513.58 724.45 -488.24 781.4 -488.27C834.34 -488.29 876.21 -534.18 918.19 -560.71C937.68 -573.03 959.17 -583.81 982.7 -584.17C1005.67 -584.53 1028.51 -573.25 1049.28 -564.46C1135.33 -528.06 1214.08 -474.87 1280.14 -408.81C1293.61 -395.34 1312.73 -380.1 1316.15 -360.34C1318.85 -344.77 1312.04 -328.84 1308.47 -314.03C1296.27 -263.44 1306.33 -215.03 1329.71 -169.07C1336.11 -156.49 1343.34 -144.27 1351.04 -132.45C1361.67 -116.11 1375.63 -106.72 1390.69 -94.76C1413.9 -76.32 1436.65 -56.57 1454.76 -32.94C1485.84 7.62 1492.81 54.98 1492.88 104.75H1492.85Z"
          stroke="#9094A0"
          strokeMiterlimit="10"
          strokeWidth="1.82"
        />
      </g>
    </g>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_458_1611" x1="0" x2="1524.82" y1="44.23" y2="44.23">
        <stop stopColor="#5E6368" stopOpacity="0.9604" />
        <stop offset="0.04" stopColor="#5E6368" stopOpacity="0.5776" />
        <stop offset="0.09" stopColor="#5E6368" stopOpacity="0.3249" />
        <stop offset="0.13" stopColor="#4B4F53" stopOpacity="0.16" />
        <stop offset="0.18" stopColor="#2D2F31" stopOpacity="0.0729" />
        <stop offset="0.24" stopColor="#2D2F31" stopOpacity="0.0289" />
        <stop offset="0.3" stopColor="#1B2619" stopOpacity="0.01" />
        <stop offset="0.37" stopColor="#1C1F1A" stopOpacity="0.01" />
        <stop offset="0.5" stopColor="#1D1D1B" stopOpacity="0.04" />
        <stop offset="0.57" stopColor="#2D2F31" stopOpacity="0.01" />
        <stop offset="0.64" stopColor="#1A2B18" stopOpacity="0.0169" />
        <stop offset="0.72" stopColor="#2D2F31" stopOpacity="0.0576" />
        <stop offset="0.8" stopColor="#4B4F53" stopOpacity="0.16" />
        <stop offset="0.88" stopColor="#5E6368" stopOpacity="0.36" />
        <stop offset="0.96" stopColor="#5E6368" stopOpacity="0.7056" />
        <stop offset="1" stopColor="#5E6368" stopOpacity="0.99" />
      </linearGradient>
    </defs>
  </svg>
);
export default BrokenModalFooter;

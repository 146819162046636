/**
 * @file Cards de la page Offers
 * @author Mikael Boutin
 * @version 0.0.1
 */

import { Component } from 'react';
import PropTypes from 'prop-types';

/** Cards de la page Offers */
class NotificationsCard extends Component {
  /**
   * Render le component
   * @return {JSX} Rend la page Home
   */
  render() {
    const { title, description, datetime } = this.props;

    return (
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" id="notifications-card">
        <div className="card">
          <div className="card-content">
            <div className="clearfix card-title-container">
              <div className="title">{title}</div>
              <div className="date">{datetime}</div>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Propriétés du component
 * @property {string} thumbnail - Adresse url du thumbnail
 * @property {string} payout - Montant du payout de l'offre
 * @property {array} categories - Array contenant les catégories pour cette offres
 * @property {string} previewUrl - Adresse url du tour
 * @property {string} name - Nom de l'offre
 * @property {string} colClassName - Classes css
 * @type {{thumbnail: (module.exports.isRequired|Function|Validator<T>|*), payout: (module.exports.isRequired|Function|Validator<T>|*), categories: (module.exports.isRequired|Function|Validator<T>|*), previewUrl: (module.exports.isRequired|Function|Validator<T>|*), name: (module.exports.isRequired|Function|Validator<T>|*), country: (module.exports.isRequired|Function|Validator<T>|*), colClassName: (module.exports.isRequired|Function|Validator<T>|*)}}
 */
NotificationsCard.propTypes = {
  datetime: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
};

export default NotificationsCard;

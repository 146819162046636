import React from 'react';
import './styles/OfferListingDetails.scss';
import OfferListingTags from './OfferListingTags.react';
import OfferListingBottomDetails from './OfferListingBottomDetails.react';

const OfferListingDetails = ({ thumbnail, name, verticals, id, tags }) => {
  return (
    <div className="offerListingDetails">
      <div className="thumnnailImg">
        {thumbnail ? <img src={thumbnail} alt={name} /> : <div className="preview">Preview</div>}
      </div>
      <div className="listingDetails">
        <div className="listingName">{name}</div>
        <OfferListingTags tags={tags} />
        <OfferListingBottomDetails verticals={verticals} id={id} />
      </div>
    </div>
  );
};

export default OfferListingDetails;

/**
 * @file Formulaire d'inscription - Connection infos
 * @author Mikael Boutin
 * @version 0.0.1
 */

import UserInfos from './UserInfos.react.js';

/**
 * Conteneur du formulaire d'inscription Connection infos - Sert à gérer les transition avec ReactTransitionGroup
 */
function UserInfosContainer(props) {
  return <UserInfos {...props} />;
}

UserInfosContainer.propTypes = {};

export default UserInfosContainer;

const GeolocationPinIcon = () => (
  <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_96_4960)" id="eva:pin-outline">
      <path
        d="M9 0.00024415C7.15555 0.00014929 5.3856 0.748825 4.07484 2.08355C2.76408 3.41827 2.01844 5.23117 2 7.12822C2 12.0602 8.16875 17.5502 8.43125 17.7842C8.58974 17.9236 8.79144 18.0002 9 18.0002C9.20856 18.0002 9.41026 17.9236 9.56875 17.7842C9.875 17.5502 16 12.0602 16 7.12822C15.9816 5.23117 15.2359 3.41827 13.9252 2.08355C12.6144 0.748825 10.8445 0.00014929 9 0.00024415ZM9 15.8852C7.53875 14.4542 3.75 10.4852 3.75 7.12822C3.75 5.69606 4.30312 4.32255 5.28769 3.30986C6.27226 2.29716 7.60761 1.72824 9 1.72824C10.3924 1.72824 11.7277 2.29716 12.7123 3.30986C13.6969 4.32255 14.25 5.69606 14.25 7.12822C14.25 10.4582 10.4613 14.4542 9 15.8852Z"
        fill="#1E1E1E"
        id="Vector"
      />
      <path
        d="M9 4.00024C8.40666 4.00024 7.82664 4.17619 7.33329 4.50583C6.83994 4.83548 6.45543 5.30402 6.22836 5.85219C6.0013 6.40037 5.94189 7.00357 6.05765 7.58551C6.1734 8.16746 6.45912 8.70201 6.87868 9.12156C7.29824 9.54112 7.83279 9.82684 8.41473 9.9426C8.99667 10.0584 9.59987 9.99894 10.1481 9.77188C10.6962 9.54482 11.1648 9.1603 11.4944 8.66695C11.8241 8.17361 12 7.59359 12 7.00024C12 6.20459 11.6839 5.44153 11.1213 4.87892C10.5587 4.31631 9.79565 4.00024 9 4.00024ZM9 8.28596C8.74571 8.28596 8.49713 8.21055 8.2857 8.06928C8.07426 7.928 7.90947 7.7272 7.81216 7.49226C7.71484 7.25733 7.68938 6.99882 7.73899 6.74941C7.7886 6.50001 7.91105 6.27092 8.09086 6.09111C8.27067 5.9113 8.49977 5.78884 8.74917 5.73923C8.99857 5.68962 9.25709 5.71509 9.49202 5.8124C9.72696 5.90971 9.92776 6.0745 10.069 6.28594C10.2103 6.49737 10.2857 6.74595 10.2857 7.00024C10.2857 7.34124 10.1503 7.66826 9.90914 7.90938C9.66802 8.1505 9.34099 8.28596 9 8.28596Z"
        fill="#1E1E1E"
        id="Vector_2"
      />
    </g>
    <defs>
      <clipPath id="clip0_96_4960">
        <rect fill="white" height="18" transform="translate(0 0.000244141)" width="18" />
      </clipPath>
    </defs>
  </svg>
);

export default GeolocationPinIcon;

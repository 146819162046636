import PaymentGroupIcon from './PaymentGroupIcon';
import { FormattedNumber } from 'react-intl';
import ArrowIcon from './ArrowIcon';
import RoundArrow from './RoundArrow';
import InvoiceIcon from './InvoiceIcon';
import PaidIcon from './PaidIcon';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

const PaymentHistoryTable = ({ handlePaymentRequest, handleInvoiceRequest, paymentsList, paginatedList }) => {
  const [visibleInvoices, setVisibleInvoices] = useState({});

  const toggleInvoices = (paymentId) => {
    setVisibleInvoices((prevState) => ({
      ...prevState,
      [paymentId]: !prevState[paymentId],
    }));
  };

  useEffect(() => {
    const handleScroll = () => window.scrollTo({ top: 0, behavior: 'auto' });

    if (document.readyState === 'complete') {
      handleScroll();
    } else {
      window.addEventListener('load', handleScroll);
    }

    return () => window.removeEventListener('load', handleScroll);
  }, []);

  return paginatedList.length > 0 ? (
    paginatedList.map((payment, rowIndex) => {
      const isVisible = visibleInvoices[payment.id] || false;
      const invoices = paymentsList.filter((invoice) => invoice.receipt_id === payment.id);

      return (
        <div className="payment-row payment-history-row" key={`row-${rowIndex}`}>
          <div
            className={`tr payment ${invoices.length > 0 && 'has-invoices'}`}
            onClick={() => invoices.length > 0 && toggleInvoices(payment.id)}
            style={invoices.length ? { cursor: 'pointer' } : {}}
          >
            <div className="td">
              <div className="payment-box" key={payment.id}>
                <PaymentGroupIcon />
                <div className="payment-info">
                  <span className="payment-date">Payment Date: {moment(payment.date).format('YYYY/MM/DD')}</span>
                  <span className="payment-date mobile">
                    Date <br />
                    {moment(payment.date).format('YYYY/MM/DD')}
                  </span>
                  <span className="payment-id">ID: {payment.id}</span>
                </div>
                {payment.date >= '2024-06-01' && (
                  <span className="details-box" onClick={(e) => handlePaymentRequest(e, payment.id)}>
                    <i className="pdf-invoice material-icons primary-color">remove_red_eye</i>
                    <span className="details-button">details</span>
                  </span>
                )}
              </div>
            </div>
            <div className="td">
              <div className="amount-box">
                <FormattedNumber currency={payment.currency} style="currency" value={parseFloat(payment.amount).toFixed(2)} />
                {invoices.length > 0 && <ArrowIcon isVisible={isVisible} />}
              </div>
            </div>
          </div>
          {isVisible ? (
            <div className="invoices">
              {invoices.map((invoice, index) => (
                <div className="tr" key={index}>
                  <div className="td">
                    <div className="payment-box">
                      <RoundArrow />
                      <InvoiceIcon />
                      {moment(invoice.start_date).format('YYYY/MM/DD')} - {moment(invoice.end_date).format('YYYY/MM/DD')}
                    </div>
                  </div>
                  {invoice.status === 'Paid' && (
                    <div className="td paid-status">
                      <PaidIcon /> {invoice.status}
                    </div>
                  )}
                  <div className="td">
                    <div className="amount-box">
                      <FormattedNumber
                        currency={invoice.currency}
                        style="currency"
                        value={parseFloat(invoice.amount).toFixed(2)}
                      />
                      {payment.date <= '2024-06-01' && (
                        <span className="details-box" onClick={(e) => handleInvoiceRequest(e, invoice.id)}>
                          <i className="pdf-invoice material-icons primary-color">remove_red_eye</i>
                          <span className="details-button">details</span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    })
  ) : (
    <div className="payment-row">
      <div className="next-payments-empty-state">
        <p>
          No past payments yet. <br /> Here you’ll find all past payments and invoices.
        </p>
      </div>
    </div>
  );
};

export default PaymentHistoryTable;

import consts from '../utils/consts';

const {
  ACTIONS: { GLOBAL_SKIN_CONFIGURATIONS, SKIN_CONFIGURATIONS },
} = consts;

const initialState = {
  isFetching: false,
  request: null,
  data: {
    name: null,
    components: [],
  },
  receivedAt: null,
};

export const skinConfigurations = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_SKIN_CONFIGURATIONS.REQUEST:
    case SKIN_CONFIGURATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
        request: action.request,
      };

    case GLOBAL_SKIN_CONFIGURATIONS.RECEIVE:
    case SKIN_CONFIGURATIONS.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        receivedAt: new Date().getTime(),
      };

    case GLOBAL_SKIN_CONFIGURATIONS.FAILED_TO_RECEIVE:
    case SKIN_CONFIGURATIONS.FAILED_TO_RECEIVE:
      return {
        ...state,
        isFetching: false,
        receivedAt: new Date().getTime(),
      };

    default:
      return state;
  }
};

const DatingSmartlinkIcon = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_148_2732)">
      <path
        d="M24.9985 49.0942C38.3062 49.0942 49.0942 38.3062 49.0942 24.9985C49.0942 11.6908 38.3062 0.902802 24.9985 0.902802C11.6908 0.902802 0.902802 11.6908 0.902802 24.9985C0.902802 38.3062 11.6908 49.0942 24.9985 49.0942Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M30.656 15.7749C28.4231 15.7749 26.4069 16.7289 24.9985 18.2516C23.5901 16.7289 21.5739 15.7749 19.341 15.7749C16.9335 15.7749 14.7818 16.8823 13.3674 18.6187C12.2841 19.9458 11.6341 21.6401 11.6341 23.4818C11.6341 24.7728 11.9591 26.0488 12.579 27.1772C15.3686 32.9943 23.7165 37.2615 24.0746 37.4391L24.9955 37.9055L25.9163 37.4391C26.0969 37.3488 28.3689 36.1872 30.9178 34.3003C33.3554 32.4947 36.0488 30.0211 37.412 27.1772C38.0319 26.0488 38.3569 24.7728 38.3569 23.4818C38.3569 19.2326 34.9052 15.7749 30.656 15.7749Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_148_2732">
        <rect fill="white" height="50" width="50" />
      </clipPath>
    </defs>
  </svg>
);

export default DatingSmartlinkIcon;

const StatIconBg = () => (
  <svg className="stat-bg" fill="none" viewBox="0 0 509 198" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M508.94 0.790039V197.87H386.98L371.74 168.45H133.61L118.37 197.87H0.800293V0.790039H118.37L133.61 30.22H371.74L386.98 0.790039H508.94Z"
      fill="url(#paint0_linear_355_1792)"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_355_1792" x1="0.800293" x2="508.94" y1="99.33" y2="99.33">
        <stop stopColor="#1D1D1B" stopOpacity="0.04" />
        <stop offset="0.06" stopColor="#173D15" stopOpacity="0.0529" />
        <stop offset="0.13" stopColor="#10640F" stopOpacity="0.2209" />
        <stop offset="0.21" stopColor="#0A8409" stopOpacity="0.4356" />
        <stop offset="0.28" stopColor="#059D05" stopOpacity="0.6561" />
        <stop offset="0.36" stopColor="#02AE02" stopOpacity="0.8281" />
        <stop offset="0.43" stopColor="#00B900" stopOpacity="0.9604" />
        <stop offset="0.5" stopColor="#00BD00" />
        <stop offset="0.56" stopColor="#01B501" stopOpacity="0.9025" />
        <stop offset="0.64" stopColor="#059F04" stopOpacity="0.6724" />
        <stop offset="0.75" stopColor="#0B7D0A" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#144D12" stopOpacity="0.09" />
        <stop offset="1" stopColor="#1D1D1B" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default StatIconBg;

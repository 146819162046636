import React from 'react';
import OfferListingTag from './OfferListingTag.react';
import './styles/OfferListingTags.scss';
import { createOfferTags } from './createOfferTags';

const OfferListingTags = ({ tags, tooltipPosition }) => {
  if (!tags?.length) {
    return null;
  }

  const offerTags = createOfferTags({ tags })?.offerTags;

  return (
    <div className="offerListingTags">
      {offerTags?.map((tag) => (
        <OfferListingTag key={tag} tag={tag} tooltipPosition={tooltipPosition} />
      ))}
    </div>
  );
};

export default OfferListingTags;

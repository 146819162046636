const Onlyfans = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.10822 9.11779C6.58024 9.11779 6.15234 9.54571 6.15234 10.0737C6.15234 10.6016 6.58024 11.0295 7.10822 11.0295C7.63619 11.0295 8.06409 10.6016 8.06409 10.0737C8.06409 9.54571 7.63619 9.11779 7.10822 9.11779ZM4.90234 10.0737C4.90234 8.85537 5.88988 7.8678 7.10822 7.8678C8.32655 7.8678 9.31409 8.85537 9.31409 10.0737C9.31409 11.292 8.32655 12.2795 7.10822 12.2795C5.88988 12.2795 4.90234 11.292 4.90234 10.0737Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.623 5.12736C11.5365 4.32801 12.7273 3.85742 14.0018 3.85742H18.3332C18.5264 3.85742 18.7088 3.9468 18.8272 4.09954C18.9455 4.25226 18.9866 4.45117 18.9384 4.6383C18.5337 6.20971 17.4771 7.30631 16.4332 8.01881C16.6231 8.00096 16.813 8.07098 16.9463 8.21228C17.101 8.37641 17.1553 8.61125 17.0883 8.82666C16.9051 9.41625 16.5878 10.415 15.8555 11.2597C15.1568 12.0658 14.1141 12.6957 12.5689 12.7891C11.5627 14.7637 9.47225 16.1406 7.10856 16.1406C3.7576 16.1406 1.0415 13.4244 1.0415 10.0735C1.0415 6.72253 3.7576 4.00643 7.10856 4.00643C8.41792 4.00643 9.63142 4.42173 10.623 5.12736ZM9.784 6.06695C9.01884 5.5548 8.09916 5.25643 7.10856 5.25643C4.44796 5.25643 2.2915 7.41289 2.2915 10.0735C2.2915 12.6187 4.26493 14.7025 6.76531 14.8785L9.10975 7.44421C9.26742 6.94439 9.49667 6.48201 9.784 6.06695ZM8.10519 14.7859C9.67042 14.4537 10.9823 13.36 11.5909 11.9311C11.6891 11.7006 11.9153 11.551 12.1659 11.551C13.5749 11.551 14.392 11.0397 14.9109 10.4411C15.1816 10.1288 15.381 9.78183 15.5357 9.4395C14.786 9.50483 14.1069 9.41625 13.3422 9.18191C13.0564 9.09433 12.8726 8.81675 12.9037 8.51941C12.9347 8.22219 13.1718 7.98849 13.4695 7.96182C13.7617 7.93565 14.6821 7.6727 15.6234 7.05657C16.3418 6.58641 17.0199 5.94467 17.4363 5.10742H14.0018C12.3087 5.10742 10.8111 6.20542 10.3019 7.82015L8.10519 14.7859Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Onlyfans;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

function BankIcon({ skinConfigurations }) {
  const COLORS = SkinConfigurationsUtils.getColors(skinConfigurations);
  return (
    <svg viewBox="0 0 21.78 25.36" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style>
          {`.cls-1,.cls-2{fill - rule:evenodd }
                .cls-1{fill:url(#Dégradé_sans_nom_19);}
                .cls-2{fill:url(#Dégradé_sans_nom_13);}`}
        </style>
        <linearGradient gradientUnits="userSpaceOnUse" id="Dégradé_sans_nom_19" x1="6.46" x2="6.46" y2="13.09">
          <stop offset="0.19" stopColor="#f3e882" />
          <stop offset="1" stopColor="#b0a937" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="Dégradé_sans_nom_13" x1="11.3" x2="11.3" y1="5.73" y2="25.36">
          <stop offset="0.48" stopColor={COLORS.PRIMARY_COLOR.DEFAULT} />
          <stop offset="1" stopColor={COLORS.PRIMARY_COLOR.DEFAULT} />
        </linearGradient>
      </defs>
      <g data-name="Calque 2" id="Calque_2">
        <g data-name="Calque 1" id="Calque_1-2">
          <path
            className="cls-1"
            d="M6.46,0A6.51,6.51,0,0,0,0,6.54a6.51,6.51,0,0,0,6.46,6.55A6.55,6.55,0,0,0,6.46,0ZM8.32,9.12a2.1,2.1,0,0,1-1.08.7.31.31,0,0,0-.27.35c0,.19,0,.38,0,.58a.24.24,0,0,1-.25.27H6.1a.26.26,0,0,1-.27-.29v-.42c0-.31,0-.33-.31-.38a3.56,3.56,0,0,1-1.1-.32c-.26-.12-.29-.19-.21-.48s.11-.43.18-.64.14-.28.37-.16A3.68,3.68,0,0,0,6,8.7a1.58,1.58,0,0,0,.8-.11.63.63,0,0,0,.14-1.1,1.62,1.62,0,0,0-.45-.28,10,10,0,0,1-1.25-.56,1.79,1.79,0,0,1-1-1.71A1.86,1.86,0,0,1,5.58,3.21c.33-.13.33-.12.34-.47V2.38c0-.27,0-.31.31-.32h.24c.56,0,.56,0,.56.56,0,.4,0,.4.39.46a3.71,3.71,0,0,1,.86.26.26.26,0,0,1,.16.34c-.06.25-.13.49-.21.73s-.14.26-.36.16A2.6,2.6,0,0,0,6.5,4.29a.9.9,0,0,0-.36.08A.53.53,0,0,0,6,5.3a2.29,2.29,0,0,0,.59.33,10.73,10.73,0,0,1,1.09.5A2,2,0,0,1,8.32,9.12Z"
          />
          <path
            className="cls-2"
            d="M20.74,13.19H20a8.46,8.46,0,0,0-2.76-3.92.8.8,0,0,0,0-.15,3.05,3.05,0,0,1,.46-2.45.61.61,0,0,0-.6-.93,4.46,4.46,0,0,0-2.48,1A2.36,2.36,0,0,0,13.81,8,7.78,7.78,0,0,1,1.52,12.37a7.58,7.58,0,0,0-.7,3.18A7.46,7.46,0,0,0,1,17.32v0a8.35,8.35,0,0,0,.64,1.71,9,9,0,0,0,1.65,2.47,4.77,4.77,0,0,1,1.27,3v.28h0a.56.56,0,0,0,.55.51H8.53a.55.55,0,0,0,.56-.55V24a12.32,12.32,0,0,0,1.5.1A9.23,9.23,0,0,0,11.77,24v.8a.56.56,0,0,0,.56.55h3.39a.55.55,0,0,0,.55-.55V24a3.38,3.38,0,0,1,1.15-2.33l.12-.1,0,0A8.48,8.48,0,0,0,20,17.76h.71a1,1,0,0,0,1-1V14.21a1.06,1.06,0,0,0-1-1m-4.23.18a.84.84,0,1,1,.84-.84.84.84,0,0,1-.84.84"
          />
        </g>
      </g>
    </svg>
  );
}

BankIcon.propTypes = {
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
}))(BankIcon);

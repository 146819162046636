import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import BankInfos from './wire-section/BankInfos.react';
import BeneficiaryInfos from './wire-section/BeneficiaryInfos.react';
import IntermediaryBankInfos from './wire-section/IntermediaryBankInfos.react';

import { Checkbox } from '../../../components/reusables';
import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper.js';

function WireSection({ fields, values, willRender, countries, accountType, intl }) {
  useEffect(() => {}, [willRender]);

  let wireSection = false;

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getBillingWireExcludedCountries()
  );

  if (willRender) {
    wireSection = (
      <div className="wire_section">
        {accountType === 'Legacy' && <BeneficiaryInfos countries={filteredCountries} fields={fields} values={values} />}
        <BankInfos countries={filteredCountries} fields={fields} values={values} />

        <div className="row">
          <div className="col-md-12">
            <h2>
              <FormattedMessage {...messages.paymentInfosIntermediaryBankInfosTitle} />
            </h2>
          </div>
        </div>
        <div className="row">
          <Checkbox
            className="col-md-12"
            field={fields.wireIntermediaryBankStatus}
            id="wireIntermediaryBankStatus"
            label={messages.paymentInfosIntermediaryBankStatusLabel.defaultMessage}
            tabIndex={190}
          />
        </div>

        <IntermediaryBankInfos
          countries={filteredCountries}
          fields={fields}
          values={values}
          willRender={values.wireIntermediaryBankStatus === true}
        />
      </div>
    );
  }

  return wireSection;
}

WireSection.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default WireSection;

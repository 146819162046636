const Smartlink = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M5.53226 7.95382C5.76658 8.18811 5.76658 8.56803 5.53226 8.80235L4.61466 9.71995C2.99537 11.3392 2.99537 13.9809 4.61466 15.6002C6.22807 17.2136 8.87611 17.219 10.4949 15.6002L11.4125 14.6826C11.6468 14.4483 12.0268 14.4483 12.2611 14.6826C12.4954 14.9169 12.4954 15.2968 12.2611 15.5312L11.3435 16.4488C9.25499 18.5372 5.84712 18.5297 3.76613 16.4488C1.67822 14.3608 1.67822 10.9593 3.76613 8.87139L4.68373 7.95382C4.91805 7.7195 5.29795 7.7195 5.53226 7.95382Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.468 12.4774C14.2337 12.2431 14.2337 11.8632 14.468 11.6289L15.3856 10.7113C17.0049 9.09203 17.0049 6.4503 15.3856 4.83101C13.7722 3.2176 11.1241 3.21225 9.50535 4.83101L8.58774 5.74862C8.35342 5.98293 7.97353 5.98293 7.73922 5.74862C7.5049 5.5143 7.5049 5.1344 7.73922 4.90009L8.65678 3.98248C10.7453 1.89404 14.1531 1.9015 16.2341 3.98248C18.3221 6.0704 18.3221 9.47187 16.2341 11.5598L15.3165 12.4774C15.0822 12.7117 14.7023 12.7117 14.468 12.4774Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.5335 7.75387C12.7677 7.98818 12.7677 8.36808 12.5334 8.6024L8.34749 12.7883C8.11317 13.0226 7.73329 13.0226 7.49897 12.7882C7.26466 12.5539 7.26466 12.1741 7.49898 11.9398L11.6849 7.75386C11.9192 7.51955 12.2992 7.51955 12.5335 7.75387Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Smartlink;

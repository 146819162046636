import React from 'react';
import './styles/OfferListingPreviewChip.scss';
import { Gtm } from '../../utils/gtm';

const OfferListingPreviewChip = ({ preview_url }) => {
  const onClick = (e) => {
    e.stopPropagation();
    Gtm.event('offers', 'Click', 'view site');
  };

  return (
    <a className="offerListingPreviewChip" href={preview_url} onClick={onClick} target="_blank">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="iconoir:eye-solid">
          <g id="Group">
            <path
              id="Vector"
              d="M3.1189 13.3655C6.7189 5.36548 17.5189 5.36548 21.1189 13.3655"
              stroke="#00BD00"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.1189 17.3655C11.7249 17.3655 11.3348 17.2879 10.9708 17.1371C10.6069 16.9864 10.2762 16.7654 9.99758 16.4868C9.719 16.2082 9.49802 15.8775 9.34726 15.5135C9.19649 15.1496 9.1189 14.7594 9.1189 14.3655C9.1189 13.9715 9.19649 13.5814 9.34726 13.2174C9.49802 12.8535 9.719 12.5227 9.99758 12.2442C10.2762 11.9656 10.6069 11.7446 10.9708 11.5938C11.3348 11.4431 11.7249 11.3655 12.1189 11.3655C12.9145 11.3655 13.6776 11.6815 14.2402 12.2442C14.8028 12.8068 15.1189 13.5698 15.1189 14.3655C15.1189 15.1611 14.8028 15.9242 14.2402 16.4868C13.6776 17.0494 12.9145 17.3655 12.1189 17.3655Z"
              fill="#00BD00"
              stroke="#00BD00"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>

      <span>Preview</span>
    </a>
  );
};

export default OfferListingPreviewChip;

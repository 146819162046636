import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { LiveCamWidgetPage } = messages;

const LayoutSettingsLayout = ({ disabled, fields: { infobarPositionIO, infobarPositionTB } }) => (
  <div className={`settings-group ${disabled ? 'disabled' : ''}`}>
    <h2 className="primary-color">
      <FormattedMessage {...LiveCamWidgetPage.infobarSettings.positionSection.title.text} />
    </h2>

    <div className="widget-positions radio-buttons horizontal">
      <Radio
        disabled={disabled}
        field={infobarPositionIO}
        id="widget-infobar-position-outside"
        label={LiveCamWidgetPage.infobarSettings.positionSection.choices.outside}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `position - outside`);
        }}
        value="outside"
      />
      <Radio
        disabled={disabled}
        field={infobarPositionIO}
        id="widget-infobar-position-inside"
        label={LiveCamWidgetPage.infobarSettings.positionSection.choices.inside}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `position - inside`);
        }}
        value="inside"
      />
    </div>

    <div className="widget-positions radio-buttons horizontal">
      <Radio
        disabled={disabled}
        field={infobarPositionTB}
        id="widget-infobar-position-bottom"
        label={LiveCamWidgetPage.infobarSettings.positionSection.choices.bottom}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `position - bottom`);
        }}
        value="bottom"
      />
      <Radio
        disabled={disabled}
        field={infobarPositionTB}
        id="widget-infobar-position-top"
        label={LiveCamWidgetPage.infobarSettings.positionSection.choices.top}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `position - top`);
        }}
        value="top"
      />
    </div>
  </div>
);

LayoutSettingsLayout.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    infobarPositionIO: PropTypes.object,
    infobarPositionTB: PropTypes.object,
  }),
};

export default injectIntl(LayoutSettingsLayout);

import React from 'react';
import JerkmateHeader from '../../components/Jerkmate2/JerkmateHeader';
import JerkmateOfferSlider from '../../components/Jerkmate2/JerkmateOfferSlider';
import JerkmatePromoTools from '../../components/Jerkmate2/JerkmatePromoTools';
import JerkmateContributors from '../../components/Jerkmate2/JerkmateContributors';
import JerkmateGetInTouch from '../../components/Jerkmate2/JerkmateGetInTouch';
import JerkyPinkSection from '../../components/Jerkmate2/JerkyPinkSection';
import JerkmateReferral from '../../components/Jerkmate2/JerkmateReferral';
import JerkmateModal from '../../components/Jerkmate2/JerkmateModal';
import JerkmateOffersProvider from './JerkmateOfferProvider';

const JerkmatePageWrap = React.memo(({ children, section }) => <div className={`wrap ${section || ''}`}>{children}</div>);

const Jerkmate2 = React.memo(() => (
  <div className="jerkmate-new-page">
    <JerkmateOffersProvider>
      <JerkmatePageWrap section="headerSection">
        <JerkmateHeader />
      </JerkmatePageWrap>
      <JerkmatePageWrap section="jerkyPinkSection">
        <JerkyPinkSection />
      </JerkmatePageWrap>
      <JerkmatePageWrap section="sliderSection">
        <JerkmateOfferSlider />
      </JerkmatePageWrap>
      <JerkmatePageWrap section="promoToolsSection">
        <JerkmatePromoTools />
      </JerkmatePageWrap>
      <JerkmatePageWrap section="getIntouchSection">
        <JerkmateGetInTouch />
        <JerkmateReferral />
      </JerkmatePageWrap>
      <JerkmateModal />
    </JerkmateOffersProvider>
  </div>
));

export default Jerkmate2;

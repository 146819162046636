import PropTypes from 'prop-types';

import { LoadingBar } from '../reusables';

const CodeInput = ({ codeId, disabled, icon, loading, text, title, onCopy }) => (
  <div className="code-input">
    <div className="title">
      <i className="material-icons">{icon}</i>
      {title}
    </div>
    {loading ? <LoadingBar /> : null}
    <textarea disabled={disabled} id={codeId} onCopy={onCopy} readOnly value={text} />
  </div>
);

CodeInput.defaultProps = {
  disabled: false,
  icon: 'code',
  loading: false,
};

CodeInput.propTypes = {
  codeId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onCopy: PropTypes.func,
  text: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

export default CodeInput;

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

const OfferAdditionalSingleInfo = ({ labelKey, values }) => (
  <div className="offer-additionnal-single-info">
    <div className="label">
      <FormattedMessage {...messages[labelKey]} />
    </div>
    <div className="value">{values.join(', ')}</div>
  </div>
);

OfferAdditionalSingleInfo.propTypes = {
  labelKey: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
};

export default OfferAdditionalSingleInfo;

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  creativeScript: null,
  creativeIsFetching: false,
  questionIsFetching: false,
  questions: [],
  themes: [],
  creativeId: null,
  creativeCode: null,
  colorTemplate: 0,
  animated: false,
  templateUrlIsFetching: false,
  templateUrls: [],
};

export const surveyGenerator = (state = initialState, action = {}) => {
  const questionsObj = {};

  switch (action.type) {
    case ACTIONS.REQUEST_SURVEY_QUESTIONS:
      return {
        ...state,
        questionIsFetching: true,
      };

    case ACTIONS.RECEIVE_SURVEY_QUESTIONS:
      action.questions.questions.map((question) => (questionsObj[question.id] = question));

      return {
        ...state,
        themes: action.questions.questions.map((question) => ({
          id: question.id,
          title: question.title,
        })),
        questions: questionsObj,
        questionIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_SURVEY_QUESTIONS:
      return {
        ...state,
        questionIsFetching: false,
      };

    case ACTIONS.REQUEST_CREATED_CREATIVE:
      return {
        ...state,
        creativeIsFetching: true,
      };

    case ACTIONS.RECEIVE_CREATED_CREATIVE:
      return {
        ...state,
        creativeId: action.creativeId,
        creativeCode: action.creativeCode,
        creativeIsFetching: false,
      };

    case ACTIONS.FAILED_TO_CREATE_CREATIVE:
      return {
        ...state,
        creativeIsFetching: false,
      };

    case ACTIONS.SET_COLOR_TEMPLATE:
      return {
        ...state,
        colorTemplate: action.colorTemplate,
      };

    case ACTIONS.SET_ANIMATED_TEMPLATE_SURVEY:
      return {
        ...state,
        animated: action.animated,
      };

    case ACTIONS.REQUEST_SURVEY_CREATIVE_SCRIPT:
      return {
        ...state,
        creativeIsFetching: true,
      };

    case ACTIONS.RECEIVE_SURVEY_CREATIVE_SCRIPT:
      return {
        ...state,
        creativeScript: action.code,
        creativeIsFetching: false,
      };

    case ACTIONS.FAILED_SURVEY_CREATIVE_SCRIPT:
      return {
        ...state,
        creativeIsFetching: false,
      };

    case ACTIONS.REQUEST_TEMPLATE_URL:
      return {
        ...state,
        templateUrlIsFetching: true,
      };

    case ACTIONS.RECEIVE_TEMPLATE_URL:
      return {
        ...state,
        templateUrls: action.templateUrls,
        templateUrlIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_TEMPLATE_URL:
      return {
        ...state,
        templateUrlIsFetching: false,
      };

    default:
      return state;
  }
};

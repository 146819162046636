import React from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import { MAIN_DROPDOWN_NAME, largeCtaTypes } from './values';
import OfferListingSort from '../../OfferListingTable/OfferListingSort.react';

const LargeCta = React.memo(({ text, largeCtaTpe }) => {
  const { activateOuterBox, activeFilters } = useOffersFilterContext();

  let totalFilters = 0;

  const mainFilterCta = largeCtaTpe === largeCtaTypes.mainFilter;

  for (const key in activeFilters) {
    const filterCount = activeFilters[key]?.length;
    totalFilters = totalFilters + filterCount;
  }

  const isSortCta = largeCtaTpe === largeCtaTypes.sort;

  const onClick = (e) => {
    e.stopPropagation();
    if (!isSortCta) {
      const field = mainFilterCta ? MAIN_DROPDOWN_NAME : '';
      activateOuterBox({ field });
    } else {
    }
  };

  return (
    <div className={`largeCta largeCta-${largeCtaTpe}`} onClick={onClick}>
      {isSortCta ? (
        <OfferListingSort isSortCta={true} />
      ) : (
        <>
          {text}
          {mainFilterCta && totalFilters ? ` (${totalFilters})` : ''}
        </>
      )}
    </div>
  );
});

export default LargeCta;

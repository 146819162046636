import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputNotice } from '../../../components/reusables';
import { getPaymentMethodIds } from './paymentMethodHelper.js';
import { useMemo } from 'react';

import SelectCustom from '../../../components/reusables/form/SelectCustom.js';

function PaymentMethodSection({ accountPaymentMethods, paymentMethodId, fields, intl, isDisabled }) {
  const { paymentMethodCheckId, paymentMethodWireId, paymentMethodAchId, paymentMethodPaypalId } = useMemo(
    () => getPaymentMethodIds(accountPaymentMethods),
    [accountPaymentMethods]
  );

  return (
    <div className="customSelects">
      <div className="fields" data-cy="select-wrapper-paymentMethod ">
        <SelectCustom
          disabled={isDisabled}
          id="paymentMethod"
          label={messages.paymentInfosPaymentMethodFeesLabel.description}
          onChange={fields.paymentMethod.onChange}
          options={accountPaymentMethods.map((paymentMethod) => ({ value: paymentMethod.id, text: paymentMethod.name }))}
          showSelectedFieldCheckmark={false}
          tabIndex={10}
          value={fields.paymentMethod.value || ''}
          valueDisabled={intl.formatMessage(messages.genericTextChoose)}
        />
        {paymentMethodId && fields.paymentMethod.error ? (
          <div className="field-error" key="error div">
            {fields.paymentMethod.error}
          </div>
        ) : (
          ''
        )}
      </div>
      {paymentMethodId != 0 && ![paymentMethodCheckId, paymentMethodWireId, paymentMethodAchId].includes(paymentMethodId) && (
        <InputNotice className="col-md-12" dataCyLabel="paymentMethodAlternativeMethodDisclaimer">
          <FormattedHTMLMessage {...messages.paymentInfosPaymentMethodAlternativeMethodDisclaimer} />
        </InputNotice>
      )}
      {paymentMethodId === paymentMethodPaypalId && (
        <InputNotice className="col-md-12" dataCyLabel="paymentMethodFeesLabelPaypalDisclaimer">
          <FormattedMessage {...messages.paymentInfosPaymentMethodFeesLabelPaypalDisclaimer} />
        </InputNotice>
      )}
    </div>
  );
}

PaymentMethodSection.propTypes = {
  accountPaymentMethods: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  parentRoute: PropTypes.object.isRequired,
  paymentMethodId: PropTypes.number.isRequired,
};

export default injectIntl(PaymentMethodSection);

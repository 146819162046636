import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Container, InputText } from '../../../components/reusables';
import BorderedPlusIcon from '../../../components/reusables/svg/BorderedPlusIcon.react';

import { Gtm } from '../../../utils/gtm';

class OfferTrackingLinkCustomization extends Component {
  constructor(props) {
    super(props);

    this.extendSubIdsSection = this.extendSubIdsSection.bind(this);
    this.getFieldProps = this.getFieldProps.bind(this);

    this.maxSubIds = 5;
    this.state = {
      extendedSubIds: false,
      numberOfSubIds: 1,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { canApplyCustomization, fields, overlay } = this.props;
    const { extendedSubIds, numberOfSubIds } = this.state;

    return (
      canApplyCustomization !== nextProps.canApplyCustomization ||
      !_.isEqual(overlay, nextProps.overlay) ||
      !_.isEqual(fields, nextProps.fields) ||
      extendedSubIds !== nextState.extendedSubIds ||
      numberOfSubIds !== nextState.numberOfSubIds
    );
  }

  getFieldProps(field) {
    const { handleOnChange } = this.props;

    return {
      ...field,
      onChange: (e) => {
        field.onChange(e);
        handleOnChange();
      },
    };
  }

  extendSubIdsSection() {
    this.setState({
      extendedSubIds: true,
      numberOfSubIds: this.maxSubIds,
    });
  }

  render() {
    const { canApplyCustomization, fields, overlay, generateCustomLink, isMoreInfoIconVisible, errors } = this.props;
    const { extendedSubIds, numberOfSubIds } = this.state;

    return (
      <div id="offer-tracking-link-customization-container">
        <Container
          className="z-depth-2 black-title"
          collapsable
          icon="link"
          id=""
          info={
            isMoreInfoIconVisible
              ? {
                  url: 'https://support.crakrevenue.com/knowledge-base/generating-tracking-link-sub-idindex-html-161/',
                  title: '',
                }
              : ''
          }
          isOpen
          onToggle={() => {
            Gtm.event('UI Component', 'Click', 'Console-Offer-Link-Dropdown');
          }}
          overlay={overlay}
          title={<FormattedMessage {...messages.offerLinkCustomization} />}
        >
          <div className="tracking-link">
            <InputText
              displayErrorInstantly
              field={this.getFieldProps(fields.source)}
              id="source"
              label={messages.offerAddYourTrackingLink}
              type="text"
            />
          </div>
          <div className="sub-ids">
            {[...Array(numberOfSubIds)]
              .map((e, i) => i + 1)
              .map((idx) => (
                <div className="sub-id" key={idx}>
                  <InputText
                    field={this.getFieldProps(fields[`aff_sub${idx === 1 ? '' : idx}`])}
                    id={`sub-id-${idx}`}
                    label={messages[`offerAddYourSubId${idx}`]}
                    type="text"
                  />
                  {!extendedSubIds && idx === 1 && (
                    <div className="sub-ids-extender pointer" onClick={() => this.extendSubIdsSection()}>
                      <BorderedPlusIcon />
                    </div>
                  )}
                </div>
              ))}
            <button
              className={`btn ${errors.source === undefined && canApplyCustomization ? '' : 'disabled'}`}
              disabled={errors.source !== undefined && fields.source.value === null}
              onClick={() => {
                if (canApplyCustomization) {
                  generateCustomLink();
                }
              }}
            >
              <FormattedMessage {...messages.genericTextApplyChanges} />
            </button>
          </div>
        </Container>
      </div>
    );
  }
}

OfferTrackingLinkCustomization.defaultProps = {
  canApplyCustomization: false,
};

OfferTrackingLinkCustomization.propTypes = {
  canApplyCustomization: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  handleApplyChanges: PropTypes.func,
  handleOnChange: PropTypes.func,
  overlay: PropTypes.object,
};

export default OfferTrackingLinkCustomization;

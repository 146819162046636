const ActiveIcon = () => (
  <svg fill="none" height="13" viewBox="0 0 12 13" width="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0622 7.39254L11.0496 3.70077C11.0471 3.63593 11.0194 3.57332 10.9723 3.52629C10.9253 3.47927 10.8627 3.45154 10.7979 3.44904L7.10536 3.43981C7.07514 3.43862 7.0453 3.4435 7.0176 3.45415C6.98991 3.4648 6.96491 3.48101 6.9441 3.50182C6.92329 3.52264 6.90708 3.54763 6.89643 3.57532C6.88578 3.60302 6.8809 3.63286 6.88208 3.66308C6.88442 3.72516 6.91093 3.78512 6.95595 3.83014C7.00097 3.87516 7.06092 3.90167 7.123 3.904L10.5946 3.904L10.5983 7.37527C10.6008 7.43722 10.6273 7.49704 10.6722 7.54197C10.7171 7.5869 10.7769 7.6134 10.8389 7.61582C10.8691 7.61701 10.899 7.61213 10.9267 7.60148C10.9543 7.59083 10.9793 7.57462 11.0002 7.5538C11.021 7.53299 11.0372 7.508 11.0478 7.4803C11.0585 7.45261 11.0634 7.42277 11.0622 7.39254Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M10.5144 4.03711L4.17145 10.38L1.97583 8.1844L1 9.16023"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default ActiveIcon;

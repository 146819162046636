import { Component } from 'react';
import PropTypes from 'prop-types';

import ProfileUserBlock from './ProfileUserBlock.react';
import { PaymentHistoryWidgetContainer } from '../../components/reusables';

class ProfileHeader extends Component {
  render() {
    const {
      userInfos,
      company,
      payableTo,
      statusRankName,
      paymentHistory,
      minimumPayout,
      paymentTerm,
      profileCompleted,
      skinConfigurations,
    } = this.props;

    return (
      <header className="container-fluid" id="profile-header">
        <div className="row app-container header-container">
          <div className="col-sm-7 col-md-8 col-xlg-9 profile-section">
            <ProfileUserBlock
              company={company}
              payableTo={payableTo}
              skinConfigurations={skinConfigurations}
              statusRankName={statusRankName}
              userInfos={userInfos}
            />
          </div>
          <div className="col-sm-5 col-md-4 col-xlg-3 bg-primary-color">
            <PaymentHistoryWidgetContainer
              minimumPayout={minimumPayout}
              paymentHistory={paymentHistory}
              paymentTerm={paymentTerm}
              profileCommunicationStatus={profileCompleted.communicationStatus}
            />
          </div>
        </div>
      </header>
    );
  }
}

ProfileHeader.propTypes = {
  company: PropTypes.string.isRequired,
  minimumPayout: PropTypes.any.isRequired,
  payableTo: PropTypes.any.isRequired,
  paymentHistory: PropTypes.object.isRequired,
  paymentTerm: PropTypes.string.isRequired,
  profileCompleted: PropTypes.object,
  skinConfigurations: PropTypes.object.isRequired,
  userInfos: PropTypes.object.isRequired,
};

export default ProfileHeader;

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

export const requestGoals = () => ({ type: ACTIONS.REQUEST_GOALS });
export const receiveGoals = (data) => ({
  type: ACTIONS.RECEIVE_GOALS,
  goalsReceivedAt: Date.now(),
  goals: data,
});
export const failedToReceiveGoals = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_GOALS,
});
export const fetchGoals = (offerId) => (dispatch) => {
  dispatch(requestGoals());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/goals/findAllByOfferIdAffiliateSpecific`)
      .withCredentials()
      .query({ offerId })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveGoals(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveGoals());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const resetGoals = () => ({ type: ACTIONS.RESET_GOALS });

const VerifiedPerformer = () => (
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32_10293)">
      <path
        d="M4.3 11.25L3.35 9.65L1.55 9.25L1.725 7.4L0.5 6L1.725 4.6L1.55 2.75L3.35 2.35L4.3 0.75L6 1.475L7.7 0.75L8.65 2.35L10.45 2.75L10.275 4.6L11.5 6L10.275 7.4L10.45 9.25L8.65 9.65L7.7 11.25L6 10.525L4.3 11.25ZM5.475 7.775L8.3 4.95L7.6 4.225L5.475 6.35L4.4 5.3L3.7 6L5.475 7.775Z"
        fill="#00AEEF"
      />
    </g>
    <defs>
      <clipPath id="clip0_32_10293">
        <rect fill="white" height="12" width="12" />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedPerformer;

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { useEffect, useState } from 'react';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';

function MinimumPayoutSection({ hasToCompleteInformation, minimumPayoutList, paymentMethodId, minimumPayoutField, intl }) {
  const [realMinimumPayoutsList, setRealMinimumPayoutsList] = useState([]);

  useEffect(() => {
    const array = [];
    minimumPayoutList.map((minimumPayout) => {
      if (paymentMethodId === parseInt(minimumPayout.payment_method_id, 10)) {
        array.push(minimumPayout);
      }
    });

    setRealMinimumPayoutsList(array);
  }, [paymentMethodId, minimumPayoutList]);

  return (
    <div className="customSelects">
      <div className="fields" data-cy="select-wrapper-minimumPayouts">
        <SelectCustom
          disabled={hasToCompleteInformation}
          id="minimumPayouts"
          label={messages.genericTextMinimumPayout.description}
          onChange={minimumPayoutField.onChange}
          options={realMinimumPayoutsList.map((minimumPayout) => ({
            value: minimumPayout.id,
            text: `$${intl.formatNumber(parseFloat(minimumPayout.amount).toFixed(2))}`,
          }))}
          showSelectedFieldCheckmark={false}
          touched={minimumPayoutField.touched}
          value={minimumPayoutField.value || ''}
          valueDisabled={intl.formatMessage(messages.genericTextChoose)}
        />
        {minimumPayoutField.error ? (
          <div className="field-error" key="error div">
            {minimumPayoutField.error}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

MinimumPayoutSection.propTypes = {
  hasToCompleteInformation: PropTypes.bool,
  intl: intlShape.isRequired,
  minimumPayoutField: PropTypes.object.isRequired,
  minimumPayoutList: PropTypes.array.isRequired,
  paymentMethodId: PropTypes.number.isRequired,
};

export default injectIntl(MinimumPayoutSection);

const MobileLandingPreviewType = ({ offerScreenshots, availableDevices, modalViewMode, handleViewModeChange }) => (
  <div className="preview-type mobile-preview">
    {offerScreenshots
      ? availableDevices.map((device) => (
          <span
            className={`pointer device-icon ${device.name}-icon ${modalViewMode === device.name ? 'primary-color' : ''}`}
            data-viewmode={device.name}
            key={device.name}
            onClick={() => handleViewModeChange(device.name)}
          >
            <i className="material-icons" data-cy={`preview-type-device-${device.name}`}>
              {device.icon}
            </i>
          </span>
        ))
      : null}
  </div>
);

export default MobileLandingPreviewType;

const ExpertTipsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
    <g clip-path="url(#clip0_327_33128)">
      <circle cx="39.5" cy="38.5" r="38.5" fill="white" />
      <g clip-path="url(#clip1_327_33128)">
        <path
          d="M50.8376 41.4861C52.8504 35.2911 49.41 28.6778 43.1943 27.1354C37.8124 25.8029 32.1425 28.9485 30.184 34.3422C29.3057 36.7639 29.2733 39.2676 29.9301 41.5215C30.6847 44.1185 30.5509 46.9242 29.7056 49.5259L29.7026 49.5351L42.4153 53.6657L42.5019 53.3994C43.3362 50.8314 44.9646 48.6263 47.0552 46.9606C48.7573 45.6051 50.1037 43.7348 50.8356 41.4821L50.8376 41.4861Z"
          stroke="#00BC00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.0101 54.0184L29.1519 49.5156L27.2757 55.2899L41.1339 59.7927L43.0101 54.0184Z"
          stroke="#00BC00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M40 23V19" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M27 38H23" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M55 38H57" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M50 27L53 23" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M30 27L27 23" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M35 38.9089L37.6325 41L46 34" stroke="#00BC00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_327_33128">
        <rect width="77" height="77" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_327_33128">
        <rect width="77" height="77" fill="white" transform="translate(22 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExpertTipsIcon;

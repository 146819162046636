const KnowledgeBase = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M3.06916 3.76721C3.82645 2.95475 4.90492 2.5 6.17836 2.5H12.6666C13.943 2.5 15.0219 2.95449 15.7789 3.76742C16.5315 4.5757 16.9229 5.69145 16.9229 6.94337V13.0566C16.9229 14.3085 16.5315 15.4243 15.7788 16.2326C15.0217 17.0455 13.9425 17.5 12.6659 17.5H6.17836C4.90193 17.5 3.82314 17.0455 3.06637 16.2325C2.31394 15.4242 1.92285 14.3085 1.92285 13.0566V6.94337C1.92285 5.69082 2.31606 4.57516 3.06916 3.76721ZM3.9132 4.55394C3.39227 5.11282 3.0767 5.93039 3.0767 6.94337V13.0566C3.0767 14.0703 3.39101 14.8878 3.91094 15.4463C4.42651 16.0002 5.18701 16.3462 6.17836 16.3462H12.6659C13.6576 16.3462 14.4185 16.0002 14.9344 15.4462C15.4545 14.8877 15.769 14.0702 15.769 13.0566V6.94337C15.769 5.92975 15.4545 5.11228 14.9345 4.55372C14.4187 3.99981 13.6579 3.65385 12.6666 3.65385H6.17836C5.19076 3.65385 4.42994 3.99956 3.9132 4.55394Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.39014 12.5421C9.39014 12.2235 9.64844 11.9652 9.96706 11.9652H13.2827C13.6013 11.9652 13.8596 12.2235 13.8596 12.5421C13.8596 12.8607 13.6013 13.1191 13.2827 13.1191H9.96706C9.64844 13.1191 9.39014 12.8607 9.39014 12.5421Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.21051 10.9924C8.43574 11.2178 8.43558 11.5831 8.21028 11.8084L6.97681 13.041C6.75149 13.2662 6.3863 13.2662 6.16104 13.0409L5.52787 12.4077C5.30256 12.1824 5.30256 11.8171 5.52787 11.5918C5.75317 11.3665 6.11845 11.3665 6.34376 11.5918L6.56911 11.8172L7.3946 10.9922C7.61997 10.767 7.98528 10.7671 8.21051 10.9924Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.21051 6.96C8.43574 7.18537 8.43558 7.55065 8.21028 7.77589L6.97681 9.00857C6.75149 9.23372 6.3863 9.23372 6.16104 9.00841L5.52787 8.37527C5.30256 8.14996 5.30256 7.78468 5.52787 7.55938C5.75317 7.33407 6.11845 7.33407 6.34376 7.55938L6.56911 7.78473L7.3946 6.95974C7.61997 6.73451 7.98528 6.73462 8.21051 6.96Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.39014 8.51258C9.39014 8.19396 9.64844 7.93567 9.96706 7.93567H13.2827C13.6013 7.93567 13.8596 8.19396 13.8596 8.51258C13.8596 8.8312 13.6013 9.08951 13.2827 9.08951H9.96706C9.64844 9.08951 9.39014 8.8312 9.39014 8.51258Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default KnowledgeBase;

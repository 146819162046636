import _ from 'lodash';

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  postbacksIsFetching: false,
  postbacks: [],
  postbacksReceivedAt: null,
  postbacksVariables: [],
};

export const postbacks = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.POSTBACKS_VARIABLES:
      return {
        ...state,
        postbacksVariables: action.postbacksVariables,
      };

    case ACTIONS.REMOVE_POSTBACKS_VARIABLES:
      return {
        ...state,
        postbacksVariables: state.postbacksVariables.filter((e) => e !== action.postbacksVariable),
      };

    case ACTIONS.REQUEST_POSTBACKS:
      return {
        ...state,
        postbacksIsFetching: true,
      };

    case ACTIONS.RECEIVE_POSTBACKS:
      return {
        ...state,
        postbacks: action.postbacks,
        postbacksReceivedAt: action.postbacksReceivedAt,
        postbacksIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_POSTBACKS:
      return {
        ...state,
        postbacksIsFetching: false,
      };

    case ACTIONS.ADDED_POSTBACK:
      if (_.isEmpty(action.postback)) {
        return {
          ...state,
          postbackIsFetching: false,
        };
      }

      return {
        ...state,
        postbacks: [...state.postbacks, action.postback],
        postbacksIsFetching: false,
      };

    default:
      return state;
  }
};

import { Component } from 'react';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const { errorText, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <h2 className="stats-error">
          <span>{errorText}</span>
          <button onClick={this.refreshPage}>Refresh</button>
        </h2>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

import consts from '../utils/consts';

const {
  ACTIONS: { UPDATE_BILLING_INFOS },
} = consts;

const initialState = {
  isFetching: false,
};

export const billingInfosReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_BILLING_INFOS.REQUEST_UPDATE_BILLING_INFOS:
      return {
        isFetching: true,
      };

    case UPDATE_BILLING_INFOS.RECEIVE_UPDATE_BILLING_INFOS:
      return {
        isFetching: false,
      };

    case UPDATE_BILLING_INFOS.FAILED_TO_UPDATE_BILLING_INFOS:
      return {
        isFetching: false,
      };

    default:
      return {
        ...state,
      };
  }
};

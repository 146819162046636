import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage, FormattedNumber } from 'react-intl';
import messages from '../../../i18n/base-en.js';
import { LoadingPage } from '../../../components/reusables';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';
import { Gtm } from '../../../utils/gtm';
import { openModal } from '../../Modal/modal-actions/modal-actions.js';
import EpcTooltipIcon from '../../Offers/EpcTooltipIcon';

const getActiveLandingPages = (landingPages) => {
  if (landingPages) {
    return landingPages.filter((landingPage) => landingPage.status === 'active');
  }
};

const OfferLandingPages = ({
  currentUrlId,
  fields,
  offerUrl,
  viewMode,
  offerScreenshots,
  availableDevices,
  handleViewModeChange,
  isDirectToModel,
  isReferrals,
  skinConfigurations,
  isAutoOptimized,
  dispatch,
  trendingPerformers,
  performerLandingIds,
  setIsLoaded,
  setHasError,
  isLoaded,
  hasError,
  offerCampaignLinkIsFetching,
}) => {
  const skin_name = SkinConfigurationsUtils.getSkinName(skinConfigurations).toLowerCase();

  const getFullResolutionScreenshot = (screenshot, viewMode) => {
    if (viewMode.toLowerCase() === 'desktop'.toLocaleLowerCase()) {
      return screenshot?.desktop;
    }
    return screenshot?.mobile;
  };

  const handleScreenshotLoading = () => {
    setIsLoaded(true);
    setHasError(false);
  };

  const handleScreenshotError = () => {
    setIsLoaded(false);
    setHasError(true);
  };

  const changeCurrentOffer = (newId) => {
    if (fields.url_id && fields.url_id.onChange) {
      fields.url_id.onChange(newId);
    }
  };

  const currentOfferUrl =
    isAutoOptimized && currentUrlId === undefined
      ? offerUrl?.find((o) => o.id === 'optimized')
      : currentUrlId
      ? offerUrl?.find((o) => o.id === currentUrlId)
      : offerUrl?.find((o) => o.id === '0');
  const handleOpenModal = () => {
    dispatch(
      openModal({
        name: 'OfferLandingPagesModal',
        modalProps: {
          dispatch,
          offerUrl,
          offerScreenshots,
          currentOfferUrl,
          availableDevices,
          viewMode,
          handleViewModeChange,
          isReferrals,
          skinConfigurations,
          isAutoOptimized,
          isDirectToModel,
          performerLandingIds,
          trendingPerformers,
          changeCurrentOffer,
          model: fields.model,
          className: 'offer-landing-page-modal',
        },
      })
    );
    Gtm.event('UI Component', 'Click', 'Console-Offer-Landing-Button');
  };

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
      setHasError(false);
    }
  }, [currentUrlId, viewMode]);

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const getOfferItemsVisibility = () => ({
    moreInfo: SKIN.isItemVisible('offersList', 'MoreInfo'),
  });

  const IS_VISIBLE = getOfferItemsVisibility();

  return (
    <div id="offer-landing-pages">
      <p>
        <FormattedMessage {...messages.offerLandingPageDescription} />
      </p>
      {currentOfferUrl ? (
        <div className="screenshot-section off-modal">
          {!isLoaded && !hasError ? (
            <div id="screenshot-loading">
              {skin_name === 'crakrevenue' ? (
                <img
                  alt="Loading..."
                  id="cr-screenshot-loading"
                  src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
                />
              ) : (
                <LoadingPage fullScreen={false} />
              )}
            </div>
          ) : null}
          {hasError ? (
            <div className="screenshot-error">
              <FormattedMessage {...messages.genericTextErrorWhileLoadingImage} />
            </div>
          ) : null}
          <div className="landing-box">
            <div
              className={`off-modal ${viewMode} ${currentOfferUrl.id === 'optimized' ? 'optimized' : ''}`}
              id="screenshot-wrapper"
            >
              {!offerCampaignLinkIsFetching && (
                <img
                  alt="Landing Page Preview"
                  className={!isLoaded ? 'hidden' : ''}
                  id="screenshot-picture"
                  onError={() => handleScreenshotError()}
                  onLoad={() => handleScreenshotLoading()}
                  src={getFullResolutionScreenshot(currentOfferUrl?.screenshot, viewMode)}
                />
              )}
            </div>
            <div className="landing-details">
              <p className="landing-name">{currentOfferUrl.name}</p>
              {!isReferrals && (
                <span className="epc">
                  <FormattedMessage {...messages.offerEPC} />
                  {IS_VISIBLE.moreInfo ? <EpcTooltipIcon id="tooltip-epc-landing-description" /> : ' '}
                  <span>
                    {currentOfferUrl.epc_affiliation ? (
                      currentOfferUrl.id === 'optimized' ? (
                        currentOfferUrl.epc_affiliation
                      ) : (
                        <FormattedNumber
                          currency="USD"
                          minimumFractionDigits={4}
                          style="currency"
                          value={parseFloat(currentOfferUrl.epc_affiliation || 0)}
                        />
                      )
                    ) : (
                      <FormattedMessage {...messages.notEnoughData} />
                    )}
                  </span>
                </span>
              )}
              <div className="preview-landing-page ">
                {currentOfferUrl.id === 'optimized' ? (
                  <p>{currentOfferUrl.description}</p>
                ) : (
                  <a className="primary-color" href={currentOfferUrl.preview_url} rel="noopener noreferrer" target="_blank">
                    <i className="material-icons">remove_red_eye</i>

                    <FormattedMessage {...messages.genericTextPreviewModal} />
                  </a>
                )}
              </div>
              {offerUrl?.length > 1 ? (
                <button
                  className="waves-effect waves-light btn btn-select-landing bg-primary-color"
                  data-cy="select-landing-page-btn"
                  id="screenshot"
                  onClick={() => {
                    handleOpenModal();
                    Gtm.event(
                      'offersList',
                      'Click',
                      isDirectToModel ? 'select landing page or performer page' : 'select landing page'
                    );
                  }}
                  type="button"
                >
                  {isDirectToModel ? <>SELECT PAGE</> : 'Select landing Page'}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
OfferLandingPages.propTypes = {
  currentUrlId: PropTypes.string,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isAutoOptimized: PropTypes.bool.isRequired,
  offerPreviewUrl: PropTypes.string.isRequired,
  offerScreenshots: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  viewMode: PropTypes.string.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
  offerUrl: getActiveLandingPages(state.offer.offer.landing_pages),
  offerCampaignLinkIsFetching: state.offer.offerCampaignLinkIsFetching,
}))(injectIntl(OfferLandingPages));

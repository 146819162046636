import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Info } from '../reusables';

const isMultiCpa = (categories) => !!categories && categories.some((category) => category === 'Multi-CPA');

const PayoutType = ({ categories, intl, showInfoMulti }) => (
  <span className="payout-type">
    {categories.join(', ')}
    {isMultiCpa(categories) && showInfoMulti ? (
      <Info
        title={intl.formatMessage(messages.offerMoreInfoAboutMultiCPA)}
        url="https://support.crakrevenue.com/knowledge-base/multi-cpa-definition/"
      />
    ) : null}
  </span>
);

PayoutType.defaultProps = {
  categories: [],
  showInfoMulti: false,
};

PayoutType.propTypes = {
  categories: PropTypes.array,
  intl: PropTypes.object.isRequired,
  showInfoMulti: PropTypes.bool,
};

export default injectIntl(PayoutType);

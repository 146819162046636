import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  goalsIsFetching: false,
  goals: [],
  goalsReceivedAt: null,
};

export const goals = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.REQUEST_GOALS:
      return {
        ...state,
        goalsIsFetching: true,
      };

    case ACTIONS.RECEIVE_GOALS:
      return {
        ...state,
        goals: action.goals,
        goalsReceivedAt: action.goalsReceivedAt,
        goalsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_GOALS:
      return {
        ...state,
        goalsIsFetching: false,
      };
    case ACTIONS.RESET_GOALS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

const JerkyPinkSection = React.memo(() => {
  const jerkyPinkRef = useRef(null);
  const interval = useRef(null);

  const [active, setActive] = useState(false);

  const [mobileInScreen, setMobileInScreen] = useState(false);

  const [scaleAmount, setScaleAmount] = useState('100%');

  useEffect(() => {
    if (mobileInScreen) {
      setActive((active) => !active);

      interval.current = setInterval(() => {
        setActive((active) => !active);
      }, 1300);
    }

    return () => clearInterval(interval.current);
  }, [mobileInScreen]);

  useEffect(() => {
    const removeListener = () => window.removeEventListener('scroll', onScroll);

    const onScroll = () => {
      if (jerkyPinkRef.current && window.innerWidth <= 1024) {
        const bottom = jerkyPinkRef.current.getBoundingClientRect().bottom - window.innerHeight;
        const divInScreen = bottom < 0;
        if (divInScreen) {
          removeListener();
          setMobileInScreen(true);
        }
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();
    return () => removeListener();
  }, []);

  useLayoutEffect(() => {
    const removeListener = () => window.removeEventListener('resize', resize);

    const resize = () => {
      if (jerkyPinkRef.current) {
        const [originalWidth] = getComputedStyle(jerkyPinkRef.current).getPropertyValue('aspect-ratio').split('/');

        const width = jerkyPinkRef.current.offsetWidth;
        const scale = Math.round((width / originalWidth) * 100);
        setScaleAmount(`${scale}%`);
      }
    };

    window.addEventListener('resize', resize, { passive: true });
    resize();

    return () => removeListener();
  }, []);

  return (
    <>
      <div className="jerky-pink-box">
        <div className="jerky-pink-scaler" ref={jerkyPinkRef}></div>
        <div
          className={`jerky-pink-section ${active ? 'active' : ''}`}
          onMouseEnter={mobileInScreen ? null : () => setActive(true)}
          onMouseLeave={mobileInScreen ? null : () => setActive(false)}
          style={{ transform: `scale(${scaleAmount})` }}
        >
          <div className="title">
            <span>Jerk</span>
            <span>mate</span>
          </div>
          <div className="jerky-anim">
            <img src="/img/jerky-head.png" className="jerky-head" alt="Jerkmate" />
            <div className="jerky-body-box">
              <img src="/img/jerky-arm-left.png" className="jerky-arm-left" alt="Jerkmate" />
              <img src="/img/jerky-body.png" className="jerky-body" alt="Jerkmate" />
              <img src="/img/jerky-arm-right.png" className="jerky-arm-right" alt="Jerkmate" />
            </div>
          </div>
          <div className="jerky-coins-left">
            <img src="/img/jerky-coin-left-top.png" className="jerky-coin-left-top" alt="Jerkmate" />
            <img src="/img/jerky-coin-left-center.png" className="jerky-coin-left-center" alt="Jerkmate" />
            <img src="/img/jerky-coin-left-bottom.png" className="jerky-coin-left-bottom" alt="Jerkmate" />
          </div>
          <div className="jerky-coins-right">
            <img src="/img/jerky-coin-right-top.png" className="jerky-coin-right-top" alt="Jerkmate" />
            <img src="/img/jerky-coin-right-center.png" className="jerky-coin-right-center" alt="Jerkmate" />
            <img src="/img/jerky-coin-right-bottom.png" className="jerky-coin-right-bottom" alt="Jerkmate" />
          </div>
        </div>
      </div>
    </>
  );
});

export default JerkyPinkSection;

import PropTypes from 'prop-types';

import OfferStatusBar from '../OfferHeader/OfferStatusBar/OfferStatusBar.react';

const OfferUnapprovedOverlay = (props) => {
  const { approvalStatus } = props;

  return (
    <div className="offer-unapproved-overlay">
      <OfferStatusBar approvalStatus={approvalStatus} isButtonStyled={false} />
    </div>
  );
};

OfferUnapprovedOverlay.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
};

export default OfferUnapprovedOverlay;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreateAccount from '../CreateAccount.react';
import { getCookie } from '../../../../utils/helpers';
import { getGoogleUrl, createLead } from '../../../../components/Login/actions/signup-actions';

const CreateAccountContainer = (props) => {
  const { dispatch } = props;
  const [googleIsLoading, setGoogleIsLoading] = useState(false);

  const handleFormSubmit = (data) => {
    const sendingData = {
      ...data,
      firstname: data.firstName,
      lastname: data.lastName,
      promoCode: getCookie('PromoCode'),
      recaptcha: data.recaptcha && data.recaptcha !== '' ? data.recaptcha : 'NOT-CHECKED',
      referralId: getCookie('referralId'),
      utm_campaign: getCookie('cr_utm_campaign'),
      utm_medium: getCookie('cr_utm_medium'),
      utm_source: getCookie('cr_utm_source'),
      utm_term: getCookie('cr_utm_term'),
      utm_content: getCookie('cr_utm_content'),
    };

    dispatch(createLead(sendingData));
  };

  const handleGoogleSignup = () => {
    setGoogleIsLoading(true);

    dispatch(getGoogleUrl())
      .then((data) => (window.location = data.body.url))
      .catch(() => setGoogleIsLoading(false));
  };

  return (
    <CreateAccount
      {...props}
      googleIsLoading={googleIsLoading}
      handleGoogleSignup={handleGoogleSignup}
      onSubmit={handleFormSubmit}
    />
  );
};

CreateAccountContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  signupUser: PropTypes.any.isRequired,
};

export default connect((state) => ({
  signupUser: state.signup,
}))(CreateAccountContainer);

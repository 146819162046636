export default {
  'surveyGeneratorPage.title': 'Survey Machine',
  'surveyGeneratorPage.colorTemplate': 'Color Template',
  'surveyGeneratorPage.basicConfiguration': 'Basic Configuration',
  'surveyGeneratorPage.rewardOptions': 'Reward Options',
  'surveyGeneratorPage.customColors': 'Custom Colors',
  'surveyGeneratorPage.offerDisplay': 'Offer Display',
  'surveyGeneratorPage.paletteDark': 'Dark',
  'surveyGeneratorPage.paletteLight': 'Light',
  'surveyGeneratorPage.paletteSocial1': 'Social 1',
  'surveyGeneratorPage.paletteSocial2': 'Social 2',
  'surveyGeneratorPage.paletteDating': 'Dating',
  'surveyGeneratorPage.formTrafficType': 'Traffic Type',
  'surveyGeneratorPage.formWidgetWidth': 'Widget Width',
  'surveyGeneratorPage.formDefaultLanguage': 'Default Language',
  'surveyGeneratorPage.formDisplayFooter': 'Display Footer',
  'surveyGeneratorPage.formFooterBackgroundColor': 'Footer Background Color',
  'surveyGeneratorPage.formLogo': 'Logo',
  'surveyGeneratorPage.formBoxBorder': 'Box border',
  'surveyGeneratorPage.introPicture': 'Introduction picture',
  'surveyGeneratorPage.introText': 'Introduction text',
  'surveyGeneratorPage.branding': 'Branding',
  'surveyGeneratorPage.customTitle': 'Custom title',
  'surveyGeneratorPage.customBackgroundUrl': 'Custom background URL',
  'surveyGeneratorPage.customLogoUrl': 'Custom logo URL',
  'surveyGeneratorPage.urlPlaceholder': 'Http://',
  'surveyGeneratorPage.formDisplayLogo': 'Display Logo',
  'surveyGeneratorPage.formLogoColor1': 'Logo Color 1',
  'surveyGeneratorPage.formLogoColor2': 'Logo Color 2',
  'surveyGeneratorPage.formHeaderTextColor': 'Header Text Color',
  'surveyGeneratorPage.formOverrideDefaultLogo': 'Override Default Logo',
  'surveyGeneratorPage.formLogoBackgroundColor': 'Logo Background Color',
  'surveyGeneratorPage.formQuestionTheme': "Question's Theme",
  'surveyGeneratorPage.numberOfQuestions': 'Number of questions',
  'surveyGeneratorPage.formGeneralBackgroundColor': 'General Background Color',
  'surveyGeneratorPage.formGeneralBackgroundImage': 'General Background Image',
  'surveyGeneratorPage.formQuestionBoxBackgroundColor': 'Question Box Background Color',
  'surveyGeneratorPage.formQuestionBoxBorder': 'Question Box Border',
  'surveyGeneratorPage.formQuestionBoxBorderColor': 'Question Box Border Color',
  'surveyGeneratorPage.formQuestionTextColor': 'Question Text Color',
  'surveyGeneratorPage.formAnswerTextColor': 'Answer Text Color',
  'surveyGeneratorPage.iframeWidgetPreview': 'Widget preview',
  'surveyGeneratorPage.surveyCampaignName': 'Survey Campaign Name',
  'surveyGeneratorPage.subId1': 'Sub ID 1',
  'surveyGeneratorPage.subId2': 'Sub ID 2',
  'surveyGeneratorPage.subId3': 'Sub ID 3',
  'surveyGeneratorPage.subId4': 'Sub ID 4',
  'surveyGeneratorPage.subId5': 'Sub ID 5',
  'surveyGeneratorPage.subId6': 'Sub ID 6',
  'surveyGeneratorPage.widgetPreview': 'Widget Preview',
  'surveyGeneratorPage.chooseColorTemplateToEnable': 'Choose a color palette to enable',
  'surveyGeneratorPage.pleaseNameYourSurvey': "Please name your survey to generate the survey's code",
  'surveyGeneratorPage.welcome': 'Welcome to Survey Machine',
  'surveyGeneratorPage.selectColorTemplate':
    'Please select a color palette in the tab above and start customizing your new survey.',
  'surveyGeneratorPage.quickPresentation':
    'For a quick presentation of this tool, please visit our <a href="https://support.crakrevenue.com/knowledge-base/crakrevenue-survey-machine/" traget="_blank">knowledge base</a> and watch the video.',
  'surveyGeneratorPage.ifYouAreReady':
    'If you are ready to create your custom survey, you can start the customization by selecting a color palette.',
  'surveyGeneratorPage.moreInfosSurvey': 'Click here for more informations on Survey Machine',
  'surveyGeneratorPage.questionBox': 'Question Box',
  'surveyGeneratorPage.questionText': 'Question Text',
  'surveyGeneratorPage.answerText': 'Answer Text',
  'surveyGeneratorPage.buttonText': 'Button Text',
  'surveyGeneratorPage.buttonBackground': 'Button Background',
  'surveyGeneratorPage.commentsUsername': 'Comments Username',
  'surveyGeneratorPage.payoutsRewards':
    'For top paying geos, you can expect a payout between $1.95 - $6.50 for CPL rewards, and between $15 - $90 for CPA rewards.',
};

export default {
  'home.paymentHistoryTitle': 'Payment History',
  'home.promoteThisOffer': 'Promote this offer',
  'home.latestBlogPosts': 'Latest Blog Posts',
  'home.recentStatistics': 'Recent statistics',
  'home.featuredOffers': 'Top Offers',
  'home.yourAffManager': 'Your aff manager',
  'home.yourAffiliateManager': 'Your Affiliate Manager',
  'home.yourDailyStats':
    'Your daily stats will appear in this section when you have some. Wanna see what it looks like to be rich? Start promoting now.',
  'home.startPromoting': 'Start promoting now',
  'home.getInTouch': 'Get In Touch',
  'home.recentStatBy': 'Recent {stat} statistics by {field}',
  'home.dailyStatByHour': 'Total daily {stat} by hours',
  'home.affiliateRanking': 'Affiliate Ranking',
  'home.totalPayout': 'Total Payout Year to Date',
  'home.activeAffiliate': 'Active Affiliate',
  'home.VIPAffiliate': 'VIP Affiliate',
  'home.eliteClub': 'Elite Club',
};

import { FormattedMessage } from 'react-intl';
import { closeModalAnimation } from '../../../components/Modal/modal-actions/modal-actions';
import useClickOutside from '../../../components/reusables/colorpicker/useClickOutside';

export default function ProfileInfosModal({ info, modalInner, dispatch }) {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <div className="profile-info-wrapper">
      <p>Why this information?</p>
      <FormattedMessage {...info} />
      <button className="modal-close-profile" onClick={closeOpenModal}>
        Close
      </button>
    </div>
  );
}

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { setLoginError } from './actions/login-actions';

class LoginErrors extends Component {
  componentDidMount() {
    this.resetErrorMessage();
  }

  shouldComponentUpdate(nextProps) {
    const { error } = this.props;
    return error !== nextProps.error;
  }

  componentDidUpdate() {
    this.resetErrorMessage();
  }

  resetErrorMessage() {
    const { dispatch, error, showErrorTime } = this.props;

    if (error !== null) {
      setTimeout(() => {
        dispatch(setLoginError(null));
      }, showErrorTime);
    }
  }

  render() {
    const { error } = this.props;

    return error ? (
      <div className="row login-errors">
        <div className="col-md-12">
          {typeof error === 'object' && <FormattedMessage {...error} />}
          {typeof error === 'string' && error}
        </div>
      </div>
    ) : null;
  }
}

LoginErrors.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.any,
  showErrorTime: PropTypes.number.isRequired,
};

export default LoginErrors;

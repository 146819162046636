import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../i18n/base-en.js';

import { AlertHeader, Container, Info } from '../components/reusables';

import { fetchPaymentHistory, fetchAffiliateInvoice } from '../actions/payment-history-actions';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { Gtm } from '../utils/gtm';
import request from '../utils/Req';
import config from '../config';
import { getCountryLabelDefaultMessage } from '../utils/list-options/countries.js';
import { getCookie, getCurrentPeriod } from '../utils/helpers.js';
import Tooltip from '../components/reusables/Tooltip.react';
import PaymentTable from './payment-history/PaymentsTable.react';
import Pagination from '../components/reusables/Pagination.react';
import PaymentDueInfo from './payment-history/PaymentDueInfo';

const { API_URL } = config;

const PaymentHistory = (props) => {
  const {
    dispatch,
    isFetchingPaymentsList,
    paymentsList,
    currentPeriodPayout,
    minimumPayoutAmount,
    nextPaymentAmount,
    paymentHistory,
    paymentTerm,
    lastPeriodPayout,
    isCurrentPeriodPayout,
    skinConfigurations,
    userInfos,
    affiliateBeneficiary,
    affType,
    affCompany,
  } = props;
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [paymentTab, setPaymentTab] = useState('current');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    const filtersObj = {
      start_date: moment('2015-01-01'),
      end_date: moment().add(1, 'days'),
    };

    dispatch(fetchPaymentHistory(filtersObj));
  }, []);

  const handleInvoiceRequest = (e, paymentId) => {
    e.stopPropagation();
    const SKIN_NAME = SkinConfigurationsUtils.getSkinName(skinConfigurations).toLowerCase();
    const filters = {
      id: paymentId,
    };
    const newWin = window.open(
      '',
      'Statement Details',
      'Toolbar=0,Location=0-,Directories=0,Status=0,Menubar=0,Scrollbars=0,Resizable=0,width=850px,height=980px,left=550'
    );

    dispatch(fetchAffiliateInvoice(filters))
      .then((resp) => {
        if (typeof resp.body.history.data[paymentId].AffiliateInvoiceItem.length !== 'undefined') {
          request
            .post(`${API_URL}/invoices/print/${paymentId}`)
            .set({
              'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
            })
            .withCredentials()
            .send({
              affiliateInvoiceInfos: resp.body.history.data[paymentId].AffiliateInvoice,
              affiliateInvoiceItemsInfos: resp.body.history.data[paymentId].AffiliateInvoiceItem,
              logoPath: `${window.location.origin}/img/${
                SKIN_NAME === 'crakrevenue' ? 'affiliates' : SKIN_NAME
              }/logo-white-bg.svg`,
              userInfos,
              affiliateBeneficiary: {
                ...affiliateBeneficiary,
                country: getCountryLabelDefaultMessage(affiliateBeneficiary?.country_code),
              },
            })
            .end((err, res) => {
              newWin.document.body.innerHTML = res.text;
              newWin.document.body.onload = () => newWin.print();
              newWin.document.title = messages.paymentHistoryStatementDetails.defaultMessage;
            });
        } else {
          newWin.document.body.innerHTML = `<p>${messages.paymentHistoryNoInvoice.defaultMessage}</p>`;
        }
      })
      .catch(() => (newWin.document.body.innerHTML = `<p>${messages.genericTextSomethingWrong.defaultMessage}</p>`));
  };

  const handlePaymentRequest = (e, paymentId) => {
    e.stopPropagation();
    const newWin = window.open(
      '',
      'Statement Details',
      'Toolbar=0,Location=0-,Directories=0,Status=0,Menubar=0,Scrollbars=0,Resizable=0,width=850px,height=980px,left=550'
    );

    request
      .post(`${API_URL}/payments/print/${paymentId}`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        logoPath: `${window.location.origin}/img/affiliates/follow-the-whale-black.svg`,
        referralIconPath: `${window.location.origin}/img/referral-icon.svg`,
        userInfos,
        affiliateBeneficiary: {
          ...affiliateBeneficiary,
          country: getCountryLabelDefaultMessage(affiliateBeneficiary?.country_code),
        },
        affCompany: affType?.type?.toLowerCase() === 'Company'.toLowerCase() ? affCompany : undefined,
      })
      .end((err, res) => {
        newWin.document.body.innerHTML = res.text;
        newWin.document.body.onload = () => newWin.print();
        newWin.document.title = messages.paymentHistoryStatementDetails.defaultMessage;
      });
  };

  const daysLeftToPeriod = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');

  const computeEarnings = (
    currentPeriodPayout,
    lastPeriodPayout,
    nextPaymentAmount,
    hasReachedMinimumPayout,
    minimumPayoutAmount,
    lastPeriodHasInvoice
  ) => {
    const futureCarriedOverEarnings = lastPeriodHasInvoice ? 0 : lastPeriodPayout;
    return hasReachedMinimumPayout || futureCarriedOverEarnings + nextPaymentAmount >= minimumPayoutAmount
      ? currentPeriodPayout
      : currentPeriodPayout + futureCarriedOverEarnings + nextPaymentAmount;
  };

  let currentEarnings = 0;

  if (isCurrentPeriodPayout) {
    currentEarnings = computeEarnings(
      currentPeriodPayout,
      lastPeriodPayout,
      nextPaymentAmount,
      paymentHistory.asReachedMinimumPayout,
      minimumPayoutAmount,
      paymentHistory.lastPeriodHasInvoice
    );
  }

  const paymentTermsFallback = paymentTerm ? paymentTerm.match(/\d+/g)[0] : 30; //

  const nextDatePayment = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd)
    .add(paymentTermsFallback, 'days')
    .format('MMMM Do YYYY');

  const totalResults = paymentsList.filter((payment) => payment.type === 'Payment').length;
  const pageCount = Math.ceil(totalResults / rowsPerPage);

  const paginatedList = paymentsList
    .filter((payment) => payment.type === 'Payment')
    .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dueDateTransform = (payment) => {
    const formatDate = (date) => date.format('MMMM Do YYYY');
    const nearEndOfMonth = (date) => moment(date).endOf('month').diff(date, 'days') <= 3;
    const getEndOfMonthDate = (date, daysToAdd) => {
      const newDate = moment(date).add(daysToAdd, 'days');
      return nearEndOfMonth(newDate) ? newDate.endOf('month') : newDate;
    };
    const isEndOfMonth = moment(payment.end_date).isSame(moment(payment.end_date).endOf('month'), 'day');

    switch (+paymentTermsFallback) {
      case 30:
        return formatDate(
          isEndOfMonth ? moment(payment.end_date).add(1, 'month').endOf('month') : moment(payment.end_date).add(1, 'month')
        );
      case 15:
        return formatDate(getEndOfMonthDate(payment.end_date, 15));
      case 7:
        return formatDate(getEndOfMonthDate(payment.end_date, 7));
      default:
        return null;
    }
  };

  const invoicesPendingArray = [];
  const invoicesCarriedOverList = [];
  let nextPaymentDueDate = '';
  let pendingFound = false;

  for (const invoice of paymentsList) {
    if (invoice.status === 'Pending') {
      pendingFound = true;
      nextPaymentDueDate = dueDateTransform(invoice);
      invoicesPendingArray.push(invoice); // Add Pending to grouped array (invoicesPendingArray)
    } else if (invoice.status === 'Carried Over') {
      if (pendingFound) {
        // If "Pending" was found earlier, add this to invoicesPendingArray
        invoice.due_date = nextPaymentDueDate;
        invoicesPendingArray.push(invoice);
      } else {
        // If "Carried Over" appears first, store it in carriedOverFirst array
        invoicesCarriedOverList.push(invoice);
      }
    }
  }

  const paymentDue = () => {
    let paymentDue = currentPeriodPayout;
    invoicesCarriedOverList.forEach((payment) => {
      paymentDue += parseFloat(payment.amount);
    });
    return +parseFloat(paymentDue).toFixed(2);
  };

  return (
    <div id="payment-history">
      <AlertHeader
        className="container-fluid app-container no-close"
        iconIsVisible
        localStorageString="paymenthistory-alert-noclose"
      >
        <span>
          <FormattedMessage {...messages.profilePaymentHistoryInfo} />
        </span>
      </AlertHeader>
      <div className="container-fluid app-container">
        <h1 className="primary-color">
          <FormattedMessage {...messages.profilePaymentHistoryTitle} />
          <Info
            onClick={() => Gtm.event('payment history', 'click', 'info icon')}
            title="Click here for more informations on Payment History"
            url="https://support.crakrevenue.com/knowledge-base/category/payments/"
          />
        </h1>
        <div className="offer-tabs payment-tabs">
          <div className={`menuTab ${paymentTab === 'current' ? 'selected' : ''}`} onClick={() => setPaymentTab('current')}>
            Current Period
          </div>
          <div className={`menuTab ${paymentTab === 'next' ? 'selected' : ''}`} onClick={() => setPaymentTab('next')}>
            Next Payment
          </div>
          <div className={`menuTab ${paymentTab === 'history' ? 'selected' : ''}`} onClick={() => setPaymentTab('history')}>
            Payment History
          </div>
        </div>

        {paymentTab === 'current' && (
          <div className="current-period-title-box">
            <h2 className="current-period-title">Current Period</h2>
            <Tooltip
              className="current-period-tooltip"
              closeIcon
              content={
                <p>
                  This section shows the amount you have earned so far in the ongoing period and where you stand regarding the
                  minimum payout threshold. Carried-over earnings from previous periods are included.
                </p>
              }
              position={windowWidthSize >= 576 ? 'right' : 'bottom'}
            >
              <Info className="more-info" />
            </Tooltip>
          </div>
        )}
        <Container className={`payments-list z-depth-2 ${isFetchingPaymentsList ? 'table-loading-state' : ''}`}>
          <PaymentTable
            currentEarnings={currentEarnings}
            currentPeriodPayout={currentPeriodPayout}
            dueDateTransform={dueDateTransform}
            handleInvoiceRequest={handleInvoiceRequest}
            handlePaymentRequest={handlePaymentRequest}
            hasReachedMinimumPayout={paymentDue() >= minimumPayoutAmount}
            invoicesCarriedOverList={invoicesCarriedOverList}
            invoicesPendingArray={invoicesPendingArray}
            isFetchingPaymentsList={isFetchingPaymentsList}
            isLoadingPayments={isFetchingPaymentsList}
            minimumPayoutAmount={minimumPayoutAmount}
            paginatedList={paginatedList}
            paymentTab={paymentTab}
            paymentTerm={paymentTerm}
            paymentsList={paymentsList}
            setPaymentTab={setPaymentTab}
            windowWidthSize={windowWidthSize}
          />
        </Container>
        <PaymentDueInfo
          currentEarnings={currentEarnings}
          currentPeriodPayout={currentPeriodPayout}
          daysLeftToPeriod={daysLeftToPeriod}
          minimumPayoutAmount={minimumPayoutAmount}
          nextDatePayment={nextDatePayment}
          paymentDue={paymentDue()}
          paymentHistory={paymentHistory}
          paymentTab={paymentTab}
          paymentTerm={paymentTerm}
        />
        {paymentTab !== 'history' && (
          <button
            className="past-payment-button"
            onClick={() => {
              setPaymentTab('history');
            }}
            type="button"
          >
            Past Payments
          </button>
        )}
        {paymentTab === 'history' && (
          <Pagination
            choices={[10, 20, 50, 100]}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleRowsChange={handleRowsChange}
            pageCount={pageCount}
            rowsPerPage={rowsPerPage}
            selectId="rowsPerPage"
            totalResults={totalResults}
          />
        )}
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  isFetchingPaymentsList: PropTypes.bool,
  paymentHistoryViewMode: PropTypes.string.isRequired,
  paymentsList: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  userInfos: PropTypes.object,
  values: PropTypes.object.isRequired,
};

const hooks = {
  defer: ({ dispatch, state }) =>
    new Promise((fulfill) => {
      const promises = [];

      if (!state.paymentHistory.isFetchingPaymentsList) {
        promises.push(dispatch(fetchPaymentHistory()));
      }

      Promise.all(promises).then(fulfill).catch(fulfill);
    }),
};

export default provideHooks(hooks)(
  connect((state) => ({
    isFetchingPaymentsList: state.paymentHistory.isFetchingPaymentsList,
    paymentHistoryViewMode: state.application.ui.paymentHistoryViewMode,
    paymentsList: state.paymentHistory.paymentsList,
    skinConfigurations: state.skinConfigurations.data,
    userInfos: state.profile.data.affInfos,
    affiliateBeneficiary: state.profile.data.affiliateBeneficiary,
    affCompany: state.profile.data.affCompany,
    affType: state.profile.data.customProfile.affiliate.affiliate_type,
    profileCompleted: state.profileCompleted,
    minimumPayout: state.profile.data.customProfile.affiliate.minimum_payout
      ? state.profile.data.customProfile.affiliate.minimum_payout.amount || ''
      : '',
    isFetchingUserInfos: state.profile.isFetching,
    isCurrentPeriodPayout: state.home.currentPeriodPayouts.totals || null,
    currentPeriodPayout:
      (state.home.currentPeriodPayouts.totals &&
        state.home.currentPeriodPayouts.totals.Stat &&
        +parseFloat(state.home.currentPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
      0,
    minimumPayoutAmount: +parseFloat(state.profile.data.customProfile.affiliate.minimum_payout.amount).toFixed(2) || 0,
    nextPaymentAmount: +parseFloat(state.profile.data.paymentHistory.next.amount).toFixed(2) || 0,
    paymentHistory: state.profile.data.paymentHistory,
    paymentTerm: state.profile.data.customProfile.affiliate.payment_term || '',
    lastPeriodPayout:
      (state.home.lastPeriodPayouts.totals &&
        state.home.lastPeriodPayouts.totals.Stat &&
        +parseFloat(state.home.lastPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
      0,
  }))(
    injectIntl(
      reduxForm({
        form: 'paymentHistoryFilters',
        fields: ['endDateFilter', 'presets', 'startDateFilter'],
        initialValues: {
          endDateFilter: getCurrentPeriod().dateEnd,
          presets: 'This Period',
          startDateFilter: getCurrentPeriod().dateStart,
        },
      })(PaymentHistory)
    )
  )
);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import messages from '../../../../i18n/base-en.js';
import countryList from '../../../../utils/list-options/countries';

import { Payout, PayoutType } from '../../../reusables';
import PayoutIcon from '../../../reusables/svg/PayoutIcon.react';
import { openModal } from '../../../Modal/modal-actions/modal-actions.js';
import EpcTooltipIcon from '../../../Offers/EpcTooltipIcon';
import { useCallback } from 'react';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const OfferPayoutInfo = ({
  goals,
  offerAcceptedCountries,
  offerExcludedCountries,
  offerConversionCap,
  offerDefaultPayout,
  offerIsFetching,
  offerPayoutType,
  offerPayoutTypes,
  offerPercentPayout,
  offerEPC,
  offerTopCountries,
  isReferrals,
  isMoreInfoIconVisible,
  dispatch,
  tiersTitleTagContainer,
  tiersTags,
}) => {
  const [payouts, setPayouts] = useState([]);
  const [colCount, setColCount] = useState(0);
  const [isFound, setIsFound] = useState(false);

  const areCountriesExcluded = !!offerExcludedCountries.length;
  const countries = areCountriesExcluded ? offerExcludedCountries : offerAcceptedCountries;

  const buildPayouts = useCallback(() => {
    const formattedPayouts = [];
    const maxItems = 10;
    let colCount = 0;
    let isFound = false;

    tiersTitleTagContainer.map((tierTitleTagContainer, index) => {
      formattedPayouts[index] = tierTitleTagContainer;
      formattedPayouts[index].countries = [];
      if (tiersTags[index]) {
        let i = 0;
        tiersTags[index].map((country) => {
          if (!(formattedPayouts[index].countries[Math.floor(i / maxItems)] instanceof Array)) {
            formattedPayouts[index].countries[Math.floor(i / maxItems)] = [];
            colCount++;
          }

          formattedPayouts[index].countries[Math.floor(i / maxItems)].push(country);
          i++;
          isFound = true;
        });
      }
    });

    setIsFound(isFound);
    setColCount(colCount);
    setPayouts(formattedPayouts);
  }, [tiersTags, tiersTitleTagContainer]);

  useEffect(() => {
    buildPayouts();
  }, [buildPayouts]);

  const formattedTopCountries = (offerTopCountries || []).map((countryCode) => {
    const currentCountry = countryList.find((country) => country.value === countryCode);
    return currentCountry ? currentCountry.label.description : countryCode;
  });

  const handleOpenModal = () => {
    dispatch(
      openModal({
        name: 'OfferPayoutTiersModal',
        modalProps: {
          dispatch,
          colCount,
          payouts,
          goals,
        },
      })
    );
  };

  const handleOpenModalCountries = () => {
    dispatch(
      openModal({
        name: 'OfferCountriesModal',
        modalProps: {
          dispatch,
          countries,
          searchClass: 'countries-search',
        },
      })
    );
  };

  return (
    <div id="offer-payout">
      {!isReferrals && (
        <h4>
          <div className="title">
            <PayoutIcon />
            <FormattedMessage {...messages.offerPayout} />
          </div>
          <div className="payout-info">
            <div className="global-payout-info">
              <Payout
                className="primary-color"
                conversionTypes={offerPayoutTypes}
                defaultPayout={offerDefaultPayout}
                payoutType={offerPayoutType}
                percentPayout={offerPercentPayout}
              />
              <PayoutType categories={offerPayoutTypes} showInfoMulti={isMoreInfoIconVisible} />
            </div>
            {isFound ? (
              <div>
                <div
                  className="hyperlink"
                  id="btn-offer-payout-tiers"
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  <FormattedMessage {...messages.offerPayoutTiers} />
                </div>
              </div>
            ) : null}
          </div>
        </h4>
      )}
      {offerConversionCap && offerConversionCap !== '0' ? (
        <div className="offer-info">
          <div className="label">
            <FormattedMessage {...messages.offerConversionCap} />
          </div>
          <div>
            <span className="conversion-cap">{offerConversionCap}</span>
            <FormattedMessage {...messages.genericTextByDay} />
          </div>
        </div>
      ) : null}
      {!isReferrals && (
        <div className="offer-info">
          <div className="label">
            <FormattedMessage {...messages.offerEPC} />
            {isMoreInfoIconVisible ? <EpcTooltipIcon id="tooltip-epc" /> : null}
          </div>
          <div>
            {offerEPC ? (
              <FormattedNumber currency="USD" minimumFractionDigits={4} style="currency" value={parseFloat(offerEPC || 0)} />
            ) : (
              <FormattedMessage {...messages.notEnoughData} />
            )}
          </div>
        </div>
      )}

      {formattedTopCountries.length > 0 && (
        <div className="offer-info">
          <div className="label">
            <FormattedMessage {...messages.offerTopCountries} />
          </div>
          {!offerIsFetching && <div>{formattedTopCountries.join(', ')}</div>}
        </div>
      )}
      <div className="offer-info">
        <div className="label">
          <FormattedMessage {...(areCountriesExcluded ? messages.offerExcludedCountries : messages.offerAcceptedCountries)} />
        </div>
        {!offerIsFetching && (
          <div>
            {!countries.length ? (
              <FormattedMessage {...messages.offerAllCountriesAccepted} />
            ) : (
              <ResponsiveEllipsis
                basedOn="words"
                ellipsis={
                  <span className="ellipsis-element" onClick={handleOpenModalCountries}>
                    ({countries.length})<i className="material-icons">public</i>
                  </span>
                }
                maxLine={2}
                style={{ whiteSpace: 'pre-wrap' }}
                text={(countries || []).map((country) => country.name).join(', ')}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OfferPayoutInfo.propTypes = {
  goals: PropTypes.array.isRequired,
  offerAcceptedCountries: PropTypes.array,
  offerConversionCap: PropTypes.string,
  offerDefaultPayout: PropTypes.string,
  offerEPC: PropTypes.number,
  offerExcludedCountries: PropTypes.array,
  offerIsFetching: PropTypes.bool.isRequired,
  offerPayoutType: PropTypes.string,
  offerPayoutTypes: PropTypes.array.isRequired,
  offerPercentPayout: PropTypes.number,
  offerTopCountries: PropTypes.array,
  tiersTags: PropTypes.array.isRequired,
  tiersTitleTagContainer: PropTypes.array.isRequired,
};

export default OfferPayoutInfo;

import { Component } from 'react';
import PropTypes from 'prop-types';

import { InputText } from '../reusables';

import '../../assets/js/vendors/jquery.colorpicker.js';

// TODO: remove jQuery from project
import $ from 'jquery';

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domId: undefined,
    };
  }

  UNSAFE_componentWillMount() {
    const { id } = this.props;

    this.setState({ domId: `cp-${id}` });
  }

  componentDidMount() {
    const { id, field, disabled, onClose, onChange } = this.props;
    const { domId } = this.state;
    const $domElem = $(`#${domId}`);
    $domElem.ColorPicker({
      color: field.value,
      onShow: (colpkr) => {
        $(colpkr).fadeIn(500);
        const $elem = $(`#${id}`);
        if ($elem.val().length === 0) {
          $elem.val('#000000');
          $elem.trigger('focus');
        }
        return false;
      },

      onHide: (colpkr) => {
        $(colpkr).fadeOut(500);
        const $elem = $(`#${id}`);
        const bgColor = $elem.val();
        // le focus et le blur sont là pour triguer un submit des valeurs, par contre au focus les valeurs sont effacées par défaut donc faut les rétablir avant le submit
        $elem.focus();
        field.value = bgColor;
        $elem.val(bgColor);
        $elem.trigger('blur');
        if (onClose) {
          onClose(bgColor);
        }
        return false;
      },
      onChange: (hsb, hex) => {
        const $elem = $(`#${id}`);
        $(`#${domId} span`).css('backgroundColor', `#${hex}`);
        $elem.val(`#${hex}`);
        $elem.trigger('blur');
        if (onChange) {
          onChange(`#${hex}`);
        }
      },
    });
    $(`#${domId} span`).css('backgroundColor', field.value);

    if (disabled) {
      $domElem.css('pointerEvents', 'none');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      field: { value },
      id,
    } = this.props;

    if (!!nextProps.id && id !== nextProps.id) {
      const domId = `cp-${nextProps.id}`;

      this.setState({ domId });
      $(`#${domId} span`).css('backgroundColor', value);
    }
    if (value !== nextProps.field.value) {
      const { domId } = this.state;
      $(`#${domId} span`).css('backgroundColor', nextProps.field.value);
    }
  }

  componentDidUpdate(previousProps) {
    const { disabled } = this.props;
    const { domId } = this.state;
    if (previousProps.disabled !== disabled) {
      $(`#${domId}`).css('pointerEvents', disabled ? 'none' : 'auto');
    }
  }

  render() {
    const { className, disabled } = this.props;
    const { domId } = this.state;

    return (
      <div className={[className, 'row', 'colorPicker-field', disabled ? 'disabled' : undefined].join(' ')}>
        <div className="col-md-11">
          <InputText type="text" {...this.props} disabled={disabled} maxLength={7} />
        </div>
        <span className="colorSelector col-md-1" id={domId}>
          <span className="inner" />
        </span>
      </div>
    );
  }
}

ColorPicker.defaultProps = {
  className: '',
};

ColorPicker.propTypes = {
  autoFocus: PropTypes.bool,
  category: PropTypes.string,
  className: PropTypes.string,
  compare: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  filtername: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.object,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  optional: PropTypes.bool,
  preLabel: PropTypes.bool,
  prefixIcon: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default ColorPicker;

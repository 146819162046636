import ReactDOM from 'react-dom';
import { trigger } from 'redial';
import { Router, match, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import routes from './routes';
import { setBodyClassForMobileDetection } from './utils/helpers';
import store from './create-store';
import intercom from './utils/intercom';
import createAppCookies from './utils/createAppCookies';

import './assets/sass/style.scss';
import './assets/js/vendors/materialize/tooltip';
import './assets/js/scripts/bootstrap-typeahead-fork';
// TODO: remove jQuery from project
import './services/functions';

const history = browserHistory;
const { dispatch } = store;
const route = routes(store);

createAppCookies();
intercom.boot();
setBodyClassForMobileDetection();

let lastRoutePoked = null;

/**
 * Écoute de l'historique
 */

history.listenBefore((historyRoute) => {
  window.dataLayer = window.dataLayer || [];
  if (historyRoute.pathname !== '/profile') {
    setTimeout(() => {
      window.dataLayer.push({
        event: 'optimize.activate',
      });
    }, 0);
  }

  const nonRedirectNeeded =
    historyRoute.pathname.indexOf('create-account') === -1 &&
    historyRoute.pathname.indexOf('signup') === -1 &&
    historyRoute.pathname.indexOf('password') === -1;

  if (historyRoute.action === 'POP' && lastRoutePoked === '/login' && nonRedirectNeeded) {
    history.replace('login');
  }

  // Match routes based on location object:
  match({ history, routes: route }, (routerError, redirectLocation, renderProps) => {
    // Check si renderProps est true sinon c'est un redirect
    if (renderProps) {
      const state = store.getState();
      const components = renderProps.routes.map((renderRoutes) => renderRoutes.component);
      const urlPath = renderProps.location.pathname;

      // Define locals to be provided to all lifecycle hooks:
      const locals = {
        path: urlPath,
        query: renderProps.location.query,
        params: renderProps.params,
        state,
        // Allow lifecycle hooks to dispatch Redux actions:
        dispatch,
      };

      intercom.update(state.profile.data);

      // Fetch deferred, client-only data dependencies
      trigger('defer', components, {
        ...locals,
        state: store.getState(),
      })
        // Finally, trigger 'done' lifecycle hooks:
        .then(() => {
          trigger('done', components, {
            ...locals,
            state: store.getState(),
          });
        });
      lastRoutePoked = urlPath;
    }

    if (module.hot) {
      module.hot.accept();
    }
  });
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} routes={route} />
  </Provider>,
  document.getElementById('root')
);

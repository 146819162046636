export default function NewIcon() {
  return (
    <svg className="new-icon" height="20" viewBox="0 0 77 37" width="37" xmlns="http://www.w3.org/2000/svg">
      <g id="NEW_composant" transform="translate(-133 -171)">
        <rect
          data-name="Rectangle 1"
          fill="#00bd00"
          height="37"
          id="Rectangle_1"
          rx="7"
          transform="translate(133 171)"
          width="77"
        />
        <text
          fill="#fff"
          fontFamily="Montserrat-Bold, Montserrat"
          fontSize="20"
          fontWeight="700"
          id="NEW"
          transform="translate(172 197)"
        >
          <tspan x="-26.32" y="0">
            NEW
          </tspan>
        </text>
      </g>
    </svg>
  );
}

import PropTypes from 'prop-types';
import { useSlider } from './SliderContext.react';
import { Gtm } from '../../../utils/gtm';

const SignupFormSlide = ({ children, index, isSkippable, ctaText = 'Next', isValid, nextFunction, title, trackingEvents }) => {
  const { nextSlide, previousSlide, totalSlides } = useSlider();

  return (
    <div className="field-container col-md-4">
      {title ? <h2>{title}</h2> : null}
      {children}
      <div className="control-container">
        {index !== 0 && (
          <button className="prev-button" onClick={previousSlide} tabIndex={-1}>
            Back
          </button>
        )}
        <button
          className={`${index === 0 ? 'full-width' : ''} next-button btn`}
          disabled={isValid !== undefined ? !isValid : false}
          onClick={() => {
            if (trackingEvents) {
              for (const t of trackingEvents) {
                Gtm.ga4Event(t.eventName, t.conversion, t.eventParameters);
              }
            }
            if (nextFunction) {
              nextFunction();
            } else {
              nextSlide();
            }
          }}
          tabIndex={-1}
        >
          {ctaText}
        </button>
        {isSkippable ? (
          <button className="prev-button" onClick={nextFunction ? nextFunction : nextSlide}>
            Skip
          </button>
        ) : null}
      </div>
      <div className="pagination">{Math.ceil(((index + 1) / totalSlides) * 100)}%</div>
      <progress className="pagination-progress" max={totalSlides} value={index + 1} />
    </div>
  );
};

SignupFormSlide.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string,
  index: PropTypes.number.isRequired,
  isSkippable: PropTypes.bool,
  isValid: PropTypes.bool,
  nextFunction: PropTypes.func,
  title: PropTypes.string,
  trackingEvents: PropTypes.array,
};

export default SignupFormSlide;

const InfoIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.105 17H13.105V11H11.105V17ZM12.105 9C12.3883 9 12.626 8.904 12.818 8.712C13.01 8.52 13.1056 8.28267 13.105 8C13.1043 7.71733 13.0083 7.48 12.817 7.288C12.6256 7.096 12.3883 7 12.105 7C11.8216 7 11.5843 7.096 11.393 7.288C11.2016 7.48 11.1056 7.71733 11.105 8C11.1043 8.28267 11.2003 8.52033 11.393 8.713C11.5856 8.90567 11.823 9.00133 12.105 9ZM12.105 22C10.7216 22 9.42165 21.7373 8.20498 21.212C6.98832 20.6867 5.92998 19.9743 5.02998 19.075C4.12998 18.1757 3.41765 17.1173 2.89298 15.9C2.36832 14.6827 2.10565 13.3827 2.10498 12C2.10432 10.6173 2.36698 9.31733 2.89298 8.1C3.41898 6.88267 4.13132 5.82433 5.02998 4.925C5.92865 4.02567 6.98698 3.31333 8.20498 2.788C9.42298 2.26267 10.723 2 12.105 2C13.487 2 14.787 2.26267 16.005 2.788C17.223 3.31333 18.2813 4.02567 19.18 4.925C20.0786 5.82433 20.7913 6.88267 21.318 8.1C21.8446 9.31733 22.107 10.6173 22.105 12C22.103 13.3827 21.8403 14.6827 21.317 15.9C20.7936 17.1173 20.0813 18.1757 19.18 19.075C18.2787 19.9743 17.2203 20.687 16.005 21.213C14.7896 21.739 13.4896 22.0013 12.105 22ZM12.105 20C14.3383 20 16.23 19.225 17.78 17.675C19.33 16.125 20.105 14.2333 20.105 12C20.105 9.76667 19.33 7.875 17.78 6.325C16.23 4.775 14.3383 4 12.105 4C9.87165 4 7.97998 4.775 6.42998 6.325C4.87998 7.875 4.10498 9.76667 4.10498 12C4.10498 14.2333 4.87998 16.125 6.42998 17.675C7.97998 19.225 9.87165 20 12.105 20Z"
      fill="#3D3D3D"
    />
  </svg>
);

export default InfoIcon;

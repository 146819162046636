import PropTypes from 'prop-types';
import messages from '../../../i18n/base-en.js';

import { InputNotice, InputText } from '../../../components/reusables';
import { FormattedMessage } from 'react-intl';

const MassPaySection = ({ masspayEmail, masspayFirstname, masspayLastname }) => (
  <div className="masspay_section">
    <div className="row">
      <InputText
        className="col-md-12"
        displayErrorInstantly
        field={masspayEmail}
        id="masspayEmail"
        label={messages.paymentInfosMassPayEmailLabel}
        tabIndex={3}
        type="email"
      />
      <InputNotice className="col-md-12" dataCyLabel="paymentInfosMassPayEmailDisclaimer">
        <FormattedMessage {...messages.paymentInfosMassPayEmailDisclaimer} />
      </InputNotice>
    </div>
    <div className="row">
      <InputText
        className="col-md-12"
        displayErrorInstantly
        field={masspayFirstname}
        id="masspayFirstname"
        label={messages.paymentInfosMassPayFirstnameLabel}
        tabIndex={1}
        type="text"
      />
      <InputNotice className="col-md-12" dataCyLabel="paymentInfosMassPayFirstnameDisclaimer">
        <FormattedMessage {...messages.paymentInfosMassPayFirstnameDisclaimer} />
      </InputNotice>
    </div>
    <div className="row">
      <InputText
        className="col-md-12"
        displayErrorInstantly
        field={masspayLastname}
        id="masspayLastname"
        label={messages.paymentInfosMassPayLastnameLabel}
        tabIndex={2}
        type="text"
      />
      <InputNotice className="col-md-12" dataCyLabel="paymentInfosMassPayLastnameDisclaimer">
        <FormattedMessage {...messages.paymentInfosMassPayLastnameDisclaimer} />
      </InputNotice>
    </div>
  </div>
);

MassPaySection.propTypes = {
  masspayEmail: PropTypes.object.isRequired,
  masspayFirstname: PropTypes.object.isRequired,
  masspayLastname: PropTypes.object.isRequired,
};

export default MassPaySection;

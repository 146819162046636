import LandingPageModalIcon from '../../../reusables/svg/offer/LandingPageModalIcon.react';
import PerformerPageModalIconReact from '../../../reusables/svg/offer/PerformerPageModalIcon.react';
import { Gtm } from '../../../../utils/gtm';

const OfferModalTabs = ({ selectedTab, setSelectedTab }) => (
  <div className="offer-tabs">
    <div
      className={`menuTab ${selectedTab === 'landing' ? 'selected' : ''}`}
      onClick={() => {
        setSelectedTab('landing');
        Gtm.event('offersList', 'Click', 'tab landing');
      }}
    >
      <LandingPageModalIcon />
      Landing Pages
    </div>
    <div
      className={`menuTab ${selectedTab === 'performer' ? 'selected' : ''}`}
      onClick={() => {
        setSelectedTab('performer');
        Gtm.event('offersList', 'Click', 'tab performer');
      }}
    >
      <PerformerPageModalIconReact />
      Performer Page
    </div>
  </div>
);

export default OfferModalTabs;

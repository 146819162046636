import React, { useEffect, useRef } from 'react';
import PromoVideo from '../PromoTools/promoToolVideo/promoVideo.react';
import ValidatedRound from '../reusables/svg/ValidatedRound.react';
import WarningIcon from '../reusables/svg/WarningIcon.react';
import { Link } from 'react-router';
import { Gtm } from '../../utils/gtm';
import { getCTALink, getCTAText } from '../PromoTools/helpers';
import { connect } from 'react-redux';
import JerkmateOfferCard from './JerkmateOfferCard';
import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';

const JerkmateModal = React.memo(() => {
  const modalRef = useRef(null);

  const { jerkmateOffers, promotool, setPromotool } = useJerkmateOffersContext();

  const showIntercom = () => {
    window.Intercom('showNewMessage');
    Gtm.event(`jerkmate page`, 'Click', 'intercom');
  };

  useEffect(() => {
    if (promotool) {
      modalRef.current.scrollTo(0, 0);
    }
  }, [promotool]);

  const closePromoModal = () => {
    setPromotool(null);
  };

  return (
    <div className={`jerkmate-modal-box ${promotool ? 'active' : ''}`}>
      <div className={`jerkmate-modal ${promotool?.jerkmate_modal_type !== '2' ? 'not-offer' : ''}`} ref={modalRef}>
        <div className="closeIconBox" onClick={closePromoModal}>
          <div className="closeIconCircle">
            <svg className="closeIcon" fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <g id="carbon:close-filled">
                <path
                  d="M16 2C8.2 2 2 8.2 2 16C2 23.8 8.2 30 16 30C23.8 30 30 23.8 30 16C30 8.2 23.8 2 16 2ZM21.4 23L16 17.6L10.6 23L9 21.4L14.4 16L9 10.6L10.6 9L16 14.4L21.4 9L23 10.6L17.6 16L23 21.4L21.4 23Z"
                  fill="#555555"
                  id="Vector"
                />
              </g>
            </svg>
          </div>
        </div>

        {promotool && (
          <>
            <div className="inner-modal">
              <h2 className="jerkmate-page-title">{promotool.title}</h2>
              <p className="intro">{promotool.header_text}</p>

              <div className="inner-content">
                {promotool.jerkmate_modal_type === '2' && (
                  <div className="offers-list">
                    {jerkmateOffers.map((offer, index) => (
                      <JerkmateOfferCard key={index} {...offer} modal={true} />
                    ))}
                  </div>
                )}

                {promotool.jerkmate_modal_type !== '2' && (
                  <>
                    {promotool.jerkmate_modal_type === '1' && promotool.video_url ? (
                      <div className="video-box">
                        <PromoVideo height="50%" src={promotool.video_url} width="100%" />
                      </div>
                    ) : null}
                    <div className="advantages">
                      <h2>Advantages</h2>
                      <ul>
                        {promotool.advantages.map((advantage, key) => (
                          <li key={key}>
                            {' '}
                            <ValidatedRound />
                            {advantage}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="description">
                      <h2>Description</h2>
                      <p>{promotool.description}</p>
                    </div>
                    {promotool.jerkmate_modal_type === '3' && (
                      <div className="warning">
                        <div className="icon-warning">
                          <WarningIcon color="#f2c157" />
                        </div>
                        <p>
                          You need to get approved to access this tool. To do so, please contact your Affiliate Manager or our
                          Support Team using the button below. We will get back to you shortly.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {promotool.jerkmate_modal_type !== '2' && (
              <div className="control-box">
                {promotool.jerkmate_modal_type !== '3' ? (
                  <Link
                    onClick={() => {
                      Gtm.event('jerkmate page', 'Click', `${promotool.title} CTA`);
                    }}
                    to={getCTALink(promotool)}
                  >
                    {getCTAText(promotool)}
                  </Link>
                ) : (
                  <a
                    onClick={() => {
                      showIntercom();
                      closePromoModal();
                    }}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {getCTAText(promotool)}
                  </a>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <div className="jerkmate-modal-background" onClick={closePromoModal} />
    </div>
  );
});

export default connect((state) => ({
  affiliateManager: state.profile.data.affiliateManager,
  affUserInfos: state.profile.data.affUserInfos,
}))(JerkmateModal);

const Profile = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M9.53858 3.65385C6.03342 3.65385 3.19242 6.49484 3.19242 10C3.19242 13.5045 6.03346 16.3462 9.53858 16.3462C13.0437 16.3462 15.8847 13.5045 15.8847 10C15.8847 6.49484 13.0437 3.65385 9.53858 3.65385ZM2.03857 10C2.03857 5.85759 5.39617 2.5 9.53858 2.5C13.681 2.5 17.0386 5.85759 17.0386 10C17.0386 14.1416 13.681 17.5 9.53858 17.5C5.39613 17.5 2.03857 14.1416 2.03857 10Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.9509 14.3822C6.44921 14.7747 6.21095 15.2782 6.15065 15.7463C6.10993 16.0623 5.82075 16.2855 5.50473 16.2448C5.18872 16.2041 4.96555 15.9149 5.00626 15.5988C5.10313 14.847 5.48608 14.0632 6.23989 13.4734C6.99234 12.8847 8.07212 12.521 9.51527 12.521C10.9724 12.521 12.059 12.8861 12.8135 13.4801C13.57 14.0755 13.9478 14.8669 14.0397 15.6256C14.0781 15.9419 13.8527 16.2295 13.5364 16.2678C13.2201 16.3062 12.9327 16.0808 12.8943 15.7645C12.8366 15.2882 12.6007 14.7811 12.0997 14.3867C11.5969 13.9908 10.7824 13.6748 9.51527 13.6748C8.26396 13.6748 7.45396 13.9885 6.9509 14.3822Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.51742 6.7548C8.4355 6.7548 7.55863 7.63136 7.55863 8.71284C7.55863 9.7943 8.4355 10.6709 9.51742 10.6709C10.5991 10.6709 11.4755 9.79453 11.4755 8.71284C11.4755 7.63114 10.5991 6.7548 9.51742 6.7548ZM6.40479 8.71284C6.40479 6.99367 7.79865 5.60095 9.51742 5.60095C11.2364 5.60095 12.6293 6.99388 12.6293 8.71284C12.6293 10.4318 11.2364 11.8248 9.51742 11.8248C7.79865 11.8248 6.40479 10.432 6.40479 8.71284Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Profile;

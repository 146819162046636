import { Component } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from './svg/WarningIcon.react';

import { getLocalStorageValue, setLocalStorageValue, getSessionStorageValue, setSessionStorageValue } from '../../utils/helpers';

class AlertHeader extends Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.getStorageValue = this.getStorageValue.bind(this);
    this.setStorageValue = this.setStorageValue.bind(this);

    this.state = {
      show: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      show: !(this.getStorageValue() === 'false'),
    });
  }

  getStorageValue() {
    const { localStorageString, useSession } = this.props;

    if (useSession) {
      return getSessionStorageValue(localStorageString);
    }
    return getLocalStorageValue(localStorageString);
  }

  setStorageValue(value) {
    const { localStorageString, useSession } = this.props;
    if (useSession) {
      return setSessionStorageValue(localStorageString, value);
    }
    return setLocalStorageValue(localStorageString, value);
  }

  handleCloseClick(e) {
    e.preventDefault();

    this.setStorageValue(false);

    this.setState({
      show: false,
    });
  }

  render() {
    const { children, className, CustomIcon, iconColor, iconIsVisible } = this.props;
    const { show } = this.state;

    return show ? (
      <div className={`alert-header ${className} ${iconIsVisible && 'iconVisible'}`}>
        <div className="row title-block">
          <div className="col-md-12">
            {iconIsVisible ? <WarningIcon color={iconColor} /> : null}
            {CustomIcon ? <CustomIcon color={iconColor} /> : null}
            <p>
              {children}
              <span className="x-circle" onClick={this.handleCloseClick}>
                <i className="material-icons">close</i>
              </span>
            </p>
          </div>
        </div>
      </div>
    ) : null;
  }
}

AlertHeader.defaultProps = {
  className: '',
  CustomIcon: false,
  iconColor: '#f7cb2c',
  iconIsVisible: false,
  useSession: false,
};

AlertHeader.propTypes = {
  CustomIcon: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  iconIsVisible: PropTypes.bool,
  localStorageString: PropTypes.string,
  useSession: PropTypes.bool,
};

export default AlertHeader;

import { FormattedMessage } from 'react-intl';
import messages from '../../../i18n/base-en.js';
import { closeModalAnimation } from '../../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../../components/reusables/colorpicker/useClickOutside.js';
import { connect } from 'react-redux';

const SurveyPayoutsModal = ({ modalInner, dispatch }) => {
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="survey-gen-payouts">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div>
        <FormattedMessage {...messages.surveyGeneratorPayoutsRewards} />
      </div>
    </div>
  );
};
export default connect(() => ({}))(SurveyPayoutsModal);

const Badge3 = () => (
  <svg className="badge-3" fill="none" height="136" viewBox="0 0 126 136" width="126" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_587)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M123.92 74.79C123.65 76.15 123.34 77.49 122.98 78.8C114.53 110.35 82.89 130.34 50.39 123.86C21.26 118.05 0.920001 92.83 0.140001 64.33C0.020001 59.74 0.400001 55.05 1.34 50.35C8.08 16.49 41 -5.48001 74.84 1.25999C108.69 8.00999 130.67 40.93 123.92 74.79Z"
          fill="url(#paint0_linear_792_587)"
        />
        <path
          d="M124.5 55.3799C74.85 64.6399 32.76 45.8799 8.51 30.8199C21.61 8.52994 47.83 -4.12006 74.61 1.21994C101.39 6.55994 121.48 29.1399 124.5 55.3799Z"
          fill="url(#paint1_linear_792_587)"
        />
        <path
          d="M124.5 55.3799C74.85 64.6399 32.76 45.8799 8.51 30.8199C21.61 8.52994 47.83 -4.12006 74.61 1.21994C101.39 6.55994 121.48 29.1399 124.5 55.3799Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M123.12 78.83C114.67 110.38 83.03 130.37 50.53 123.89C21.39 118.07 1.05 92.86 0.27 64.36C11.09 72.61 27.5 82.23 49 86.51C75.88 91.87 105.6 84.59 123.12 78.83Z"
          fill="url(#paint2_linear_792_587)"
        />
        <g opacity="0.5">
          <path
            d="M62.56 2.75993C66.48 2.75993 70.43 3.14993 74.31 3.92993C106.64 10.3799 127.7 41.9299 121.26 74.2599C120.99 75.6299 120.69 76.8799 120.36 78.0999C113.38 104.16 89.66 122.36 62.69 122.36C58.77 122.36 54.81 121.97 50.93 121.19C37.57 118.53 25.42 111.31 16.72 100.86C8.14 90.5599 3.22 77.5599 2.85 64.2499C2.73 59.7799 3.12 55.2799 3.99 50.8699C9.55 22.9999 34.19 2.75993 62.56 2.75993ZM62.56 0.0499268C33.37 0.0499268 7.26 20.6199 1.33 50.3499C0.39 55.0499 0.01 59.7299 0.14 64.3299C0.92 92.8299 21.26 118.05 50.4 123.86C54.53 124.68 58.64 125.08 62.69 125.08C90.56 125.08 115.61 106.35 122.99 78.7999C123.35 77.4899 123.66 76.1499 123.93 74.7899C130.68 40.9399 108.71 8.00993 74.85 1.25993C70.72 0.439927 66.61 0.0499268 62.56 0.0499268Z"
            fill="#527956"
          />
        </g>
        <path
          d="M106.47 71.3099C106.28 72.2799 106.06 73.2399 105.8 74.1799C99.76 96.7499 77.13 111.04 53.88 106.41C33.04 102.25 18.49 84.2199 17.93 63.8299C17.84 60.5399 18.11 57.1899 18.79 53.8299C23.62 29.6199 47.17 13.8999 71.37 18.7199C95.58 23.5499 111.3 47.0999 106.47 71.3099Z"
          fill="url(#paint3_linear_792_587)"
        />
        <path
          d="M106.47 71.3099C106.28 72.2799 106.06 73.2399 105.8 74.1799C99.76 96.7499 77.13 111.04 53.88 106.41C33.04 102.25 18.49 84.2199 17.93 63.8299C17.84 60.5399 18.11 57.1899 18.79 53.8299C23.62 29.6199 47.17 13.8999 71.37 18.7199C95.58 23.5499 111.3 47.0999 106.47 71.3099Z"
          fill="url(#paint4_linear_792_587)"
        />
        <g opacity="0.5">
          <path
            d="M62.58 17.8499C65.48 17.8499 68.42 18.1299 71.36 18.7199C95.57 23.5499 111.29 47.0999 106.46 71.3099C106.27 72.2799 106.05 73.2399 105.79 74.1799C100.51 93.8799 82.59 107.28 62.66 107.28C59.76 107.28 56.82 107 53.87 106.41C33.03 102.25 18.48 84.2199 17.92 63.8299C17.83 60.5399 18.1 57.1899 18.78 53.8299C23.02 32.5699 41.7 17.8499 62.58 17.8499ZM62.58 15.1299C40.07 15.1299 20.53 31.1799 16.12 53.2899C15.42 56.7799 15.12 60.3499 15.21 63.8999C15.5 74.4599 19.41 84.7799 26.21 92.9399C33.11 101.23 42.75 106.95 53.35 109.07C56.43 109.68 59.56 109.99 62.67 109.99C84.07 109.99 102.88 95.5499 108.42 74.8799C108.68 73.9299 108.91 72.9299 109.13 71.8399C114.24 46.1899 97.54 21.1699 71.89 16.0499C68.82 15.4399 65.68 15.1299 62.58 15.1299Z"
            fill="#527956"
          />
        </g>
        <path
          d="M107.33 61.9699C101.17 55.4199 92.99 50.6599 83.51 48.7699C59.31 43.9399 35.77 59.6599 30.94 83.8699C30.32 86.9999 30.03 90.1099 30.07 93.1799C22.69 85.3399 18.23 74.9199 17.92 63.8099C17.84 60.5299 18.11 57.1699 18.78 53.8199C23.61 29.6099 47.16 13.8799 71.37 18.7099C92.44 22.9099 107.08 41.2999 107.33 61.9699Z"
          fill="url(#paint5_linear_792_587)"
        />
        <path
          d="M124.06 74.82C113.7 124.79 52.33 142.59 17.09 105.62C6.64 94.53 0.39 79.57 0 64.33H0.27C0.53 66.21 0.73 68.1 1.05 69.95C3.29 82.75 9.47 94.99 18.62 104.17C50.56 136.28 104.37 124.7 121.05 82.81C121.57 81.31 122.56 79.02 122.97 77.49L123.79 74.77L124.06 74.82Z"
          fill="#527956"
        />
        <path
          d="M56.69 18.03C85.96 13.99 111.41 39.25 107.15 68.57C106.35 74.87 104.11 80.98 100.73 86.35L100.38 86.13C103.56 78.44 104.89 70.46 104.44 62.25C103.3 46.58 94.06 32.01 80.14 24.64C73.22 20.84 65.22 18.75 56.74 18.42L56.69 18.02V18.03Z"
          fill="#031014"
        />
        <g opacity="0.5">
          <path
            d="M15.65 40.9999C15.77 40.6499 15.89 40.2899 16.02 39.9399C16.1 39.7199 16.19 39.4899 16.28 39.2699C16.32 39.1799 16.55 38.6099 16.39 38.9999C16.23 39.3899 16.47 38.8199 16.51 38.7299C16.59 38.5499 16.67 38.3699 16.76 38.1899C16.97 37.7299 17.2 37.2799 17.43 36.8299C18.27 35.2099 19.22 33.6599 20.25 32.1599C20.54 31.7499 20.83 31.3299 21.13 30.9299C21.27 30.7499 21.4 30.5699 21.54 30.3899C21.45 30.5099 21.21 30.7899 21.65 30.2599C22.32 29.4399 23 28.6299 23.71 27.8499C25.11 26.3099 26.61 24.8499 28.19 23.4899C35.07 17.5399 44.27 13.2699 53.48 11.4899C55.81 11.0399 58.11 10.7999 60.66 10.7899C63.33 10.7799 65.51 10.9999 67.89 11.4899C69.68 11.8599 71.56 10.9699 72.07 9.1199C72.53 7.4399 71.5 5.3099 69.7 4.9399C58.77 2.6899 47.98 5.0199 37.9 9.4399C29.04 13.3299 21.3 19.5099 15.49 27.2199C12.76 30.8499 10.57 34.8799 9.12 39.1799C8.53 40.9199 9.79 42.8899 11.49 43.3599C13.35 43.8699 15.08 42.7299 15.67 40.9899L15.65 40.9999Z"
            fill="white"
          />
        </g>
        <path
          d="M72.18 114.1C71.75 114.06 62.51 114.02 50.67 116.27C44.32 117.48 35.46 116.47 37.91 109.54C39.12 109.15 44.82 113.67 45.02 107.6C43.73 108.01 39.78 105.93 38.31 107.8C37.97 105.55 33.61 103.92 32.45 102.13C31.78 105.82 33.94 107.99 36.41 108.77C31.57 111.81 30.54 116.38 31.81 120.7C33.08 125.02 36.64 129.09 40.99 131.12C41.46 131.81 40.27 133.43 38.44 134.26C42.65 135.62 46.57 133.38 48.72 132.66C49.94 133.64 52.19 134.91 55.06 135.41C54.62 134.54 54.38 133.24 54.46 132C57.22 131.65 59.76 131.19 60.64 130.92C68.94 128.37 76.34 124.28 72.17 114.11"
          fill="url(#paint6_linear_792_587)"
        />
        <path
          d="M72.18 114.1C71.75 114.06 62.51 114.02 50.67 116.27C44.32 117.48 35.46 116.47 37.91 109.54C37.91 109.54 35.6 113.96 38.58 116.47C39.96 117.63 43.71 118.98 47.1 118.18C62.3 114.6 72.18 114.09 72.18 114.09V114.1Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M32.45 102.13C33.53 103.91 37.97 105.56 38.31 107.8C39.78 105.92 43.73 108.01 45.02 107.6C45.02 107.6 39.12 106.81 38.04 108.35C38.04 108.35 37 106.45 35.54 105.57C34.08 104.69 32.6 103.96 32.45 102.13Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M40.99 131.12C37.14 129.61 33.08 125.02 31.81 120.7C30.54 116.38 31.57 111.81 36.41 108.77C36.41 108.77 24.22 118.57 40.99 131.12Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M72.18 114.1C76.35 124.28 68.95 128.37 60.65 130.91C59.77 131.18 57.23 131.64 54.47 131.99C54.47 131.99 76.68 128.21 72.18 114.1Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M38.44 134.26C38.44 134.26 41.73 136.04 48.72 132.66L51.01 131.31C51.01 131.31 42.16 135.22 38.43 134.26H38.44Z"
          fill="#527956"
          opacity="0.5"
        />
        <path
          d="M100.7 26.25C106.852 26.25 111.84 21.2624 111.84 15.11C111.84 8.95752 106.852 3.96997 100.7 3.96997C94.5475 3.96997 89.56 8.95752 89.56 15.11C89.56 21.2624 94.5475 26.25 100.7 26.25Z"
          fill="url(#paint7_radial_792_587)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M107.589 17.5896C108.954 13.7899 106.979 9.60371 103.18 8.23947C99.3798 6.87523 95.1936 8.84959 93.8294 12.6493C92.4651 16.449 94.4395 20.6352 98.2392 21.9995C102.039 23.3637 106.225 21.3894 107.589 17.5896Z"
          fill="url(#paint8_radial_792_587)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M91.87 20.94C92.64 22.1 97.15 20.44 101.95 17.25C106.75 14.06 110 10.52 109.23 9.35996C108.46 8.19996 103.95 9.85996 99.15 13.05C94.36 16.25 91.1 19.78 91.87 20.94Z"
          fill="url(#paint9_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M97.86 16.95C98.1 17.31 99.5 16.8 100.98 15.81C102.46 14.82 103.47 13.73 103.23 13.37C102.99 13.01 101.59 13.52 100.11 14.51C98.63 15.5 97.62 16.59 97.86 16.95Z"
          fill="url(#paint10_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M96.89 17.6C97.22 18.09 99.12 17.39 101.14 16.04C103.16 14.69 104.54 13.2 104.21 12.71C103.88 12.22 101.98 12.92 99.96 14.27C97.94 15.62 96.56 17.11 96.89 17.6Z"
          fill="url(#paint11_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M87.06 24.2999C86.89 24.1599 92.58 20.0799 99.78 15.1999C106.98 10.3199 112.95 6.47992 113.12 6.61992C113.29 6.75992 107.6 10.8399 100.4 15.7199C93.2 20.5999 87.23 24.4399 87.06 24.2999Z"
          fill="url(#paint12_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M94.6 7.23996C93.44 8.00996 95.1 12.52 98.29 17.32C101.49 22.11 105.02 25.37 106.18 24.6C107.34 23.83 105.68 19.32 102.49 14.52C99.3 9.71996 95.76 6.46996 94.6 7.23996Z"
          fill="url(#paint13_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M98.6 13.23C98.24 13.47 98.75 14.87 99.74 16.35C100.73 17.83 101.82 18.84 102.18 18.6C102.54 18.36 102.03 16.96 101.04 15.48C100.05 14 98.96 12.99 98.6 13.23Z"
          fill="url(#paint14_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M97.95 12.26C97.46 12.59 98.16 14.49 99.51 16.51C100.86 18.53 102.35 19.91 102.84 19.58C103.33 19.25 102.63 17.35 101.28 15.33C99.93 13.31 98.44 11.93 97.95 12.26Z"
          fill="url(#paint15_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M91.25 2.42998C91.39 2.25998 95.47 7.94998 100.35 15.15C105.23 22.35 109.07 28.32 108.93 28.49C108.79 28.66 104.71 22.97 99.83 15.77C94.95 8.56998 91.11 2.59998 91.25 2.42998Z"
          fill="url(#paint16_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M102.1 14.2499C101.41 13.2199 100.02 12.9399 98.99 13.6299C97.96 14.3199 97.68 15.7099 98.37 16.7399C99.06 17.7699 100.45 18.0499 101.48 17.3599C102.51 16.6699 102.79 15.2799 102.1 14.2499Z"
          fill="url(#paint17_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M100.24 19.5599C102.488 19.5599 104.31 17.7377 104.31 15.4899C104.31 13.2421 102.488 11.4199 100.24 11.4199C97.9922 11.4199 96.17 13.2421 96.17 15.4899C96.17 17.7377 97.9922 19.5599 100.24 19.5599Z"
          fill="url(#paint18_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M31.77 121.52C34.0509 121.52 35.9 119.671 35.9 117.39C35.9 115.109 34.0509 113.26 31.77 113.26C29.4891 113.26 27.64 115.109 27.64 117.39C27.64 119.671 29.4891 121.52 31.77 121.52Z"
          fill="url(#paint19_radial_792_587)"
          opacity="0.65"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M34.3209 118.313C34.8266 116.904 34.0947 115.352 32.686 114.846C31.2774 114.341 29.7254 115.073 29.2197 116.481C28.7139 117.89 29.4459 119.442 30.8545 119.947C32.2632 120.453 33.8151 119.721 34.3209 118.313Z"
          fill="url(#paint20_radial_792_587)"
          opacity="0.67"
          style={{ mixBlendMode: 'lighten' }}
        />
        <path
          d="M28.49 119.55C28.78 119.98 30.45 119.37 32.23 118.18C34.01 116.99 35.22 115.68 34.93 115.25C34.64 114.82 32.97 115.43 31.19 116.62C29.41 117.81 28.2 119.12 28.49 119.55Z"
          fill="url(#paint21_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.71 118.07C30.8 118.2 31.32 118.01 31.87 117.65C32.42 117.28 32.79 116.88 32.71 116.74C32.63 116.6 32.1 116.8 31.55 117.16C31 117.53 30.63 117.93 30.71 118.07Z"
          fill="url(#paint22_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.35 118.31C30.47 118.49 31.18 118.23 31.93 117.73C32.68 117.23 33.19 116.68 33.07 116.49C32.95 116.31 32.24 116.57 31.49 117.07C30.74 117.57 30.23 118.12 30.35 118.31Z"
          fill="url(#paint23_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M26.7 120.8C26.64 120.75 28.75 119.23 31.42 117.42C34.09 115.61 36.31 114.18 36.37 114.24C36.43 114.29 34.32 115.81 31.65 117.62C28.98 119.43 26.76 120.86 26.7 120.8Z"
          fill="url(#paint24_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M29.5 114.47C29.07 114.76 29.68 116.43 30.87 118.21C32.06 119.99 33.37 121.2 33.8 120.91C34.23 120.62 33.62 118.95 32.43 117.17C31.24 115.39 29.93 114.18 29.5 114.47Z"
          fill="url(#paint25_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.99 116.69C30.86 116.78 31.05 117.3 31.41 117.85C31.78 118.4 32.18 118.77 32.32 118.69C32.45 118.6 32.26 118.08 31.9 117.53C31.53 116.98 31.13 116.61 30.99 116.69Z"
          fill="url(#paint26_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M30.75 116.33C30.57 116.45 30.83 117.16 31.33 117.91C31.83 118.66 32.38 119.17 32.57 119.05C32.75 118.93 32.49 118.22 31.99 117.47C31.49 116.72 30.94 116.21 30.75 116.33Z"
          fill="url(#paint27_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M28.26 112.68C28.31 112.62 29.83 114.73 31.64 117.4C33.45 120.07 34.88 122.29 34.82 122.35C34.77 122.41 33.25 120.3 31.44 117.63C29.63 114.96 28.2 112.74 28.26 112.68Z"
          fill="url(#paint28_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
        <path
          d="M32.29 117.07C32.04 116.69 31.52 116.59 31.14 116.84C30.76 117.09 30.66 117.61 30.91 117.99C31.16 118.37 31.68 118.47 32.06 118.22C32.44 117.97 32.54 117.45 32.29 117.07Z"
          fill="url(#paint29_radial_792_587)"
          style={{ mixBlendMode: 'screen' }}
        />
        <path
          d="M31.59 119.04C32.424 119.04 33.1 118.364 33.1 117.53C33.1 116.696 32.424 116.02 31.59 116.02C30.7561 116.02 30.08 116.696 30.08 117.53C30.08 118.364 30.7561 119.04 31.59 119.04Z"
          fill="url(#paint30_radial_792_587)"
          style={{ mixBlendMode: 'color-dodge' }}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_587"
        x1="49.4233"
        x2="79.1003"
        y1="128.748"
        y2="-20.041"
      >
        <stop stopColor="#1B1D1E" />
        <stop offset="0.48" stopColor="#222A2B" />
        <stop offset="1" stopColor="#33483A" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_792_587"
        x1="105.895"
        x2="119.666"
        y1="64.5847"
        y2="0.653484"
      >
        <stop stopColor="#1B1D1E" />
        <stop offset="0.48" stopColor="#222A2B" />
        <stop offset="1" stopColor="#33483A" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_587"
        x1="51.2329"
        x2="62.6523"
        y1="124.021"
        y2="66.7686"
      >
        <stop stopColor="#031014" />
        <stop offset="1" stopColor="#1B1D1E" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_587"
        x1="53.1828"
        x2="74.4097"
        y1="109.899"
        y2="3.47569"
      >
        <stop stopColor="#33483A" />
        <stop offset="0.52" stopColor="#222A2B" />
        <stop offset="1" stopColor="#1B1D1E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_792_587"
        x1="53.1828"
        x2="74.4097"
        y1="109.899"
        y2="3.47569"
      >
        <stop stopColor="#1B1D1E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#031014" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_792_587" x1="19.592" x2="106.324" y1="49.7367" y2="67.036">
        <stop stopColor="#031014" />
        <stop offset="1" stopColor="#1B1D1E" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_792_587"
        x1="29.4702"
        x2="72.3457"
        y1="117.029"
        y2="125.581"
      >
        <stop stopColor="#1B1D1E" />
        <stop offset="0.48" stopColor="#222A2B" />
        <stop offset="1" stopColor="#33483A" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(100.681 15.0801) rotate(56.28) scale(11.14)"
        gradientUnits="userSpaceOnUse"
        id="paint7_radial_792_587"
        r="1"
      >
        <stop stopColor="#031014" />
        <stop offset="0.45" stopColor="#031014" stopOpacity="0.5476" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(100.724 15.1394) rotate(56.28) scale(7.31)"
        gradientUnits="userSpaceOnUse"
        id="paint8_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.16" stopColor="#4E7453" stopOpacity="0.9216" />
        <stop offset="0.35" stopColor="#44674B" stopOpacity="0.6889" />
        <stop offset="0.56" stopColor="#34523D" stopOpacity="0.3969" />
        <stop offset="0.78" stopColor="#1E342B" stopOpacity="0.1225" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(106.77 24.3195) rotate(-123.72) scale(2.5032 10.43)"
        gradientUnits="userSpaceOnUse"
        id="paint9_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.2" stopColor="#354A39" stopOpacity="0.8649" />
        <stop offset="0.45" stopColor="#2B3F31" stopOpacity="0.5625" />
        <stop offset="0.71" stopColor="#1A2B25" stopOpacity="0.1936" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(106.77 24.3195) rotate(-123.72) scale(0.7752 3.23)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(106.77 24.3195) rotate(-123.72) scale(1.056 4.4)"
        gradientUnits="userSpaceOnUse"
        id="paint11_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-139.288 -184.39) rotate(39.76) scale(0.428 15.1405)"
        gradientUnits="userSpaceOnUse"
        id="paint12_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.22" stopColor="#47684C" />
        <stop offset="0.57" stopColor="#33483A" />
        <stop offset="0.68" stopColor="#2A3E33" stopOpacity="0.6889" />
        <stop offset="0.87" stopColor="#142422" stopOpacity="0.1369" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(99.3047 16.6392) rotate(-33.72) scale(2.5032 10.43)"
        gradientUnits="userSpaceOnUse"
        id="paint13_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.16" stopColor="#4E7453" stopOpacity="0.9216" />
        <stop offset="0.35" stopColor="#44674B" stopOpacity="0.6889" />
        <stop offset="0.56" stopColor="#34523D" stopOpacity="0.3969" />
        <stop offset="0.78" stopColor="#1E342B" stopOpacity="0.1225" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(99.3047 16.6392) rotate(-33.72) scale(0.7752 3.23)"
        gradientUnits="userSpaceOnUse"
        id="paint14_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(99.3047 16.6392) rotate(-33.72) scale(1.056 4.4)"
        gradientUnits="userSpaceOnUse"
        id="paint15_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(53.6487 71.1205) rotate(129.76) scale(0.428 15.1405)"
        gradientUnits="userSpaceOnUse"
        id="paint16_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.22" stopColor="#47684C" />
        <stop offset="0.57" stopColor="#33483A" />
        <stop offset="0.68" stopColor="#2A3E33" stopOpacity="0.6889" />
        <stop offset="0.87" stopColor="#142422" stopOpacity="0.1369" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(100.21 15.4547) rotate(56.28) scale(2.24)"
        gradientUnits="userSpaceOnUse"
        id="paint17_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(100.21 15.4547) rotate(56.28) scale(4.07)"
        gradientUnits="userSpaceOnUse"
        id="paint18_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.7353 117.35) rotate(56.28) scale(4.13 4.13)"
        gradientUnits="userSpaceOnUse"
        id="paint19_radial_792_587"
        r="1"
      >
        <stop stopColor="#031014" />
        <stop offset="0.45" stopColor="#031014" stopOpacity="0.5476" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.7703 117.424) rotate(56.28) scale(2.71)"
        gradientUnits="userSpaceOnUse"
        id="paint20_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.16" stopColor="#4E7453" stopOpacity="0.9216" />
        <stop offset="0.35" stopColor="#44674B" stopOpacity="0.6889" />
        <stop offset="0.56" stopColor="#34523D" stopOpacity="0.3969" />
        <stop offset="0.78" stopColor="#1E342B" stopOpacity="0.1225" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(37.754 126.322) rotate(-123.72) scale(0.9288 3.87)"
        gradientUnits="userSpaceOnUse"
        id="paint21_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.2" stopColor="#354A39" stopOpacity="0.8649" />
        <stop offset="0.45" stopColor="#2B3F31" stopOpacity="0.5625" />
        <stop offset="0.71" stopColor="#1A2B25" stopOpacity="0.1936" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(37.754 126.322) rotate(-123.72) scale(0.288 1.2)"
        gradientUnits="userSpaceOnUse"
        id="paint22_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(37.754 126.322) rotate(-123.72) scale(0.3912 1.63)"
        gradientUnits="userSpaceOnUse"
        id="paint23_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-178.996 -58.2799) rotate(39.76) scale(0.1584 5.6034)"
        gradientUnits="userSpaceOnUse"
        id="paint24_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.22" stopColor="#47684C" />
        <stop offset="0.57" stopColor="#33483A" />
        <stop offset="0.68" stopColor="#2A3E33" stopOpacity="0.6889" />
        <stop offset="0.87" stopColor="#142422" stopOpacity="0.1369" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.161 118.001) rotate(-33.72) scale(0.9288 3.87)"
        gradientUnits="userSpaceOnUse"
        id="paint25_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.16" stopColor="#4E7453" stopOpacity="0.9216" />
        <stop offset="0.35" stopColor="#44674B" stopOpacity="0.6889" />
        <stop offset="0.56" stopColor="#34523D" stopOpacity="0.3969" />
        <stop offset="0.78" stopColor="#1E342B" stopOpacity="0.1225" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.161 118.001) rotate(-33.72) scale(0.288 1.2)"
        gradientUnits="userSpaceOnUse"
        id="paint26_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.161 118.001) rotate(-33.72) scale(0.3912 1.63)"
        gradientUnits="userSpaceOnUse"
        id="paint27_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(-16.235 174.763) rotate(129.76) scale(0.1584 5.6034)"
        gradientUnits="userSpaceOnUse"
        id="paint28_radial_792_587"
        r="1"
      >
        <stop stopColor="#527956" />
        <stop offset="0.22" stopColor="#47684C" />
        <stop offset="0.57" stopColor="#33483A" />
        <stop offset="0.68" stopColor="#2A3E33" stopOpacity="0.6889" />
        <stop offset="0.87" stopColor="#142422" stopOpacity="0.1369" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.5634 117.489) rotate(56.28) scale(0.830001)"
        gradientUnits="userSpaceOnUse"
        id="paint29_radial_792_587"
        r="1"
      >
        <stop stopColor="white" />
        <stop offset="0.1" stopColor="#F9FAFA" stopOpacity="0.9604" />
        <stop offset="0.23" stopColor="#EBECEC" stopOpacity="0.8464" />
        <stop offset="0.36" stopColor="#D2D5D5" stopOpacity="0.6889" />
        <stop offset="0.5" stopColor="#B0B4B6" stopOpacity="0.4761" />
        <stop offset="0.65" stopColor="#858B8D" stopOpacity="0.2704" />
        <stop offset="0.81" stopColor="#4F585B" stopOpacity="0.09" />
        <stop offset="0.96" stopColor="#111E21" stopOpacity="0.01" />
        <stop offset="1" stopColor="#031014" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(31.5634 117.489) rotate(56.2801) scale(1.51 1.51)"
        gradientUnits="userSpaceOnUse"
        id="paint30_radial_792_587"
        r="1"
      >
        <stop stopColor="#394F3C" />
        <stop offset="0.35" stopColor="#3B543E" stopOpacity="0.4096" />
        <stop offset="0.88" stopColor="#405D43" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_792_587">
        <rect fill="white" height="135.41" width="125.14" />
      </clipPath>
    </defs>
  </svg>
);

export default Badge3;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';
class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      className,
      disabled,
      field: { dirty, error, touched, value },
    } = this.props;

    return (
      className !== nextProps.className ||
      disabled !== nextProps.disabled ||
      (!!nextProps.field &&
        (dirty !== nextProps.field.dirty ||
          error !== nextProps.field.error ||
          touched !== nextProps.field.touched ||
          value !== nextProps.field.value))
    );
  }

  handleChange() {
    const { field, value } = this.props;

    if (typeof value !== 'undefined') {
      if (field.value instanceof Array) {
        if (field.value.indexOf(value) >= 0) {
          const valueIndex = field.value.indexOf(value);
          const values = [...field.value.splice(0, valueIndex), ...field.value.splice(1)];
          field.onChange(values);
        } else {
          field.onChange(field.value.concat(value));
        }
      } else {
        field.onChange([value]);
      }
    } else {
      field.onChange(!field.checked);
    }
  }

  renderLabel() {
    const { id, label, preLabel, optional, escapeHtml, tabIndex } = this.props;

    const labelClasses = [preLabel ? 'pre-label' : undefined];
    const formattedLabelMessage =
      label instanceof Object && escapeHtml ? label?.defaultMessage?.replace(/<a /g, `<a tabindex='${tabIndex}' `) : undefined;

    const messageElement = label instanceof Object && escapeHtml && (
      <FormattedHTMLMessage
        {...label}
        className="checkbox-label"
        defaultMessage={formattedLabelMessage ?? label.defaultMessage}
      />
    );

    return (
      <label className={labelClasses.join(' ')} htmlFor={id}>
        {label instanceof Object ? messageElement : <div className="checkbox-label">{label}</div>}
        {optional ? <FormattedHTMLMessage {...messages.genericTextOptional} /> : null}
      </label>
    );
  }

  render() {
    const {
      autoFocus,
      category,
      className,
      disabled,
      field,
      filtername,
      id,
      label,
      preLabel,
      tabIndex,
      value,
      dataCy,
      onClick,
      isAdded,
    } = this.props;
    let fieldClasses = 'checkbox-field ' + className;

    if (field && field.error && field.touched) {
      fieldClasses += ' error';
    }

    let checked;

    if (isAdded?.includes(`{${field.name}}`) || (!!value && field.value instanceof Array && field.value.indexOf(value) >= 0)) {
      checked = true;
    } else {
      checked = field.checked ? field.checked : false;
    }

    return (
      <div className={fieldClasses}>
        {label && preLabel ? this.renderLabel() : null}
        <input
          autoFocus={autoFocus}
          checked={checked}
          className="filled-in"
          data-category={category}
          data-cy={dataCy}
          data-filtername={filtername}
          disabled={disabled}
          id={id}
          name={field.name}
          onChange={this.handleChange || field.onChange}
          onClick={() => {
            if (isAdded?.includes(`{${field.name}}`) && onClick && checked === true) {
              onClick();
              return;
            }
            if (!isAdded && onClick && !checked) {
              onClick();
            }
          }}
          onDragStart={field.onDragStart}
          onDrop={field.onDrop}
          onFocus={field.onFocus}
          tabIndex={tabIndex}
          type="checkbox"
          value={typeof value === 'undefined' ? 'off' : value}
        />
        {label && !preLabel ? this.renderLabel() : null}
      </div>
    );
  }
}

Checkbox.defaultProps = {
  autoFocus: false,
  disabled: false,
};

Checkbox.propTypes = {
  autoFocus: PropTypes.bool,
  category: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  escapeHtml: PropTypes.bool,
  field: PropTypes.object,
  filtername: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  optional: PropTypes.bool,
  preLabel: PropTypes.bool,
  tabIndex: PropTypes.any,
  value: PropTypes.any,
};

export default injectIntl(Checkbox);

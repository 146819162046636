import consts from '../../../utils/consts';

const { ACTIONS } = consts;

export const openModal = (payload) => ({
  type: ACTIONS.MODAL.OPEN,
  payload,
});

export const closeModal = () => ({
  type: ACTIONS.MODAL.CLOSE,
});

export const animateModal = () => ({
  type: ACTIONS.MODAL.ANIMATE,
});

export const closeModalAnimation = () => (dispatch) => {
  dispatch(animateModal());
  setTimeout(() => dispatch(closeModal()), 450);
};

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  isConnected: false,
  isFetchingAuth: false,
  error: null,
  isFetchingTfaValidation: false,
};

export const login = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.REQUEST_LOGIN_AUTH:
      return {
        ...state,
        isFetchingAuth: true,
      };

    case ACTIONS.RECEIVE_LOGIN_AUTH:
      return {
        ...state,
        isFetchingAuth: false,
      };

    case ACTIONS.REQUEST_TFA_VALIDATION:
      return {
        ...state,
        isFetchingTfaValidation: true,
      };

    case ACTIONS.RECEIVE_TFA_VALIDATION:
      return {
        ...state,
        isFetchingTfaValidation: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_LOGIN_AUTH:
      return {
        ...state,
        isFetchingAuth: false,
      };

    case ACTIONS.SET_LOGIN_ERROR:
      return {
        ...state,
        isFetchingAuth: false,
        error: action.error,
      };

    case ACTIONS.LOGIN:
      return {
        ...state,
        isConnected: true,
      };

    default:
      return state;
  }
};

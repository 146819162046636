/**
 * @file Agrégateur des Reducers pour les changements d'états du store
 * @author Mikael Boutin
 * @version 0.0.1
 */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import { application } from './application-reducers';
import { profileReducer } from './profile-reducers';
import { billingInfosReducer } from './billing-infos-reducers';
import { login } from './login-reducers';
import { offers } from './offers-reducers';
import { offersSlides } from './offers-slides-reducers';
import { creatives, creativesSizes, creativesTrending, creativeCode } from './creatives-reducers';
import { topoffers } from './topoffers-reducers';
import { achConfigurations } from './ach-configurations-reducers';
import { gamification } from './gamification-reducers';
import { offerReducer } from '../pages/offer/Offer-Reducers/Offer-Reducers';
import { postbacks } from './postbacks-reducers';
import { goals } from './goals-reducers';
import { referrals } from './referrals-reducers';
import { affiliateValidation } from './affiliate-validation-reducers';
import { signup } from './signup-reducers';
import { statistics } from './statistics-reducers';
import { home } from './home-reducers';
import { blog } from './blog-reducers';
import { promo } from './promo-reducers';
import { news } from './news-reducers';
import { surveyGenerator } from './survey-generator-reducers';
import { livecamGenerator } from './livecam-generator-reducers';
import { alerts } from './alerts-reducers';
import { profileCompleted } from './profile-completed-reducers';
import { paymentHistory } from './payment-history-reducers';
import { config } from './config-reducers';
import { skinConfigurations } from './skin-configurations-reducers';
import { modal } from '../components/Modal/modal-reducers/modal-reducers';
import { promoToolsReducer } from '../pages/PromoTools/PromoTools-Reducers/PromoTools-Reducers';
import { connectionSettingsReducer } from './connection-settings-reducers';
import { payoutTotalReducer } from './payout-total-reducers';
import colorNormalizer from './normalize/colorNormalizer';

import consts from '../utils/consts';
import { jerkmateReducers } from '../pages/jerkmate/Jerkmate-reducers/Jerkmate-reducers';
const { ACTIONS } = consts;

export const reducers = {
  // la méthode normalize s'effectue après le dispatch
  form: formReducer.normalize({
    customColors: {
      generalBackgroundColor: colorNormalizer,
      logoColor1: colorNormalizer,
      logoColor2: colorNormalizer,
      logoBackgroundColor: colorNormalizer,
      introductionTextColor: colorNormalizer,
      questionTextColor: colorNormalizer,
      answerTextColor: colorNormalizer,
      questionBoxBackgroundColor: colorNormalizer,
      questionBoxQuestionTextColor: colorNormalizer,
      questionBoxBorderColor: colorNormalizer,
      questionBoxAnswerTextColor: colorNormalizer,
      rewardsBackgroundColor: colorNormalizer,
      rewardsButtonTextColor: colorNormalizer,
      rewardsTextColor: colorNormalizer,
      rewardsButtonBackgroundColor: colorNormalizer,
      rewardsCommentsUsernameColor: colorNormalizer,
    },
  }),
  routing: routerReducer,
  application,
  login,
  offers,
  topoffers,
  achConfigurations,
  gamification: gamification,
  offer: offerReducer,
  postbacks,
  goals,
  referrals,
  signup,
  statistics,
  home,
  blog,
  promo,
  news,
  offersSlides,
  creatives,
  creativesSizes,
  creativesTrending,
  creativeCode,
  surveyGenerator,
  livecamGenerator,
  alerts,
  paymentHistory,
  modal,
  profile: profileReducer,
  billingInfos: billingInfosReducer,
  promoTools: promoToolsReducer,
  connectionSettings: connectionSettingsReducer,
  config,
  profileCompleted,
  skinConfigurations,
  affiliateValidation,
  jerkmate: jerkmateReducers,
  payoutTotal: payoutTotalReducer,
};

export function createRootReducer(combReducers) {
  return (state, action) => {
    let newState = state;
    if (action.type === ACTIONS.LOGOUT) {
      newState = typeof window === 'undefined' ? undefined : window.__INITIAL_STATE__;
    }

    return combReducers(newState, action);
  };
}

const combinedReducers = combineReducers(reducers);
export default createRootReducer(combinedReducers);

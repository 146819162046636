const spokenLanguageStem = 'Live Cam Widget Page - Content Settings - Model Section - Spoken Languages';
export default {
  LiveCamWidgetPage: {
    title: {
      text: {
        id: 'LiveCamWidgetPage.title.text',
        description: 'Live Cam Widget Page - Title - Text',
        defaultMessage: 'Live Cam Widget',
      },
    },
    fields: {
      background: {
        label: {
          id: 'LiveCamWidgetPage.fields.background.label',
          description: 'Live Cam Widget Page - Fields - Background - Label',
          defaultMessage: 'Background',
        },
        alternativeLabel: {
          id: 'LiveCamWidgetPage.fields.background.alternativeLabel',
          description: 'Live Cam Widget Page - Fields - Background - Alternative Label',
          defaultMessage: 'Background Color',
        },
        choices: {
          color: {
            id: 'LiveCamWidgetPage.fields.background.choices.color',
            description: 'Live Cam Widget Page - Fields - Background - Choices - Color',
            defaultMessage: 'Color',
          },
          transparent: {
            id: 'LiveCamWidgetPage.fields.background.choices.transparent',
            description: 'Live Cam Widget Page - Fields - Background - Choices - Transparent',
            defaultMessage: 'Transparent',
          },
        },
      },
      borderWidth: {
        label: {
          id: 'LiveCamWidgetPage.fields.borderWidth.label',
          description: 'Live Cam Widget Page - Fields - Border Width - Label',
          defaultMessage: 'Width',
        },
      },
      color: {
        label: {
          id: 'LiveCamWidgetPage.fields.color.label',
          description: 'Live Cam Widget Page - Fields - Color - Label',
          defaultMessage: 'Color',
        },
      },
      colorFilterOpacity: {
        label: {
          id: 'LiveCamWidgetPage.fields.colorFilterOpacity.label',
          description: 'Live Cam Widget Page - Fields - Color Filter Opacity - Label',
          defaultMessage: 'Opacity',
        },
      },
      cornerRadius: {
        label: {
          id: 'LiveCamWidgetPage.fields.cornerRadius.label',
          description: 'Live Cam Widget Page - Fields - Corner Radius - Label',
          defaultMessage: 'Radius',
        },
      },
      feed: {
        choices: {
          liveFeed: {
            id: 'LiveCamWidgetPage.fields.feed.choices.liveFeed',
            description: 'Live Cam Widget Page - Fields - Feed - Choices - Live Feed',
            defaultMessage: 'Live feed',
          },
          profilePicture: {
            id: 'LiveCamWidgetPage.fields.feed.choices.profilePicture',
            description: 'Live Cam Widget Page - Fields - Feed - Choices - Profile Picture',
            defaultMessage: 'Profile picture',
          },
        },
      },
      gender: {
        label: {
          id: 'LiveCamWidgetPage.fields.gender.label',
          description: 'Live Cam Widget Page - Fields - Gender - Label',
          defaultMessage: 'Gender',
        },
        choices: {
          female: {
            id: 'LiveCamWidgetPage.fields.gender.choices.female',
            description: 'Live Cam Widget Page - Fields - Gender - Choices - female',
            defaultMessage: 'Female',
          },
          male: {
            id: 'LiveCamWidgetPage.fields.gender.choices.male',
            description: 'Live Cam Widget Page - Fields - Gender - Choices - male',
            defaultMessage: 'Male',
          },
          trans: {
            id: 'LiveCamWidgetPage.fields.gender.choices.trans',
            description: 'Live Cam Widget Page - Fields - Gender - Choices - Trans',
            defaultMessage: 'Trans',
          },
          couples: {
            id: 'LiveCamWidgetPage.fields.gender.choices.couples',
            description: 'Live Cam Widget Page - Fields - Gender - Choices - Couples',
            defaultMessage: 'Couples',
          },
        },
      },
      canHide: {
        id: 'LiveCamWidgetPage.fields.canHide.',
        description: 'Hide when closing </br> Chat Head',
        defaultMessage: 'Hide when closing </br> Chat Head',
      },
      ratio: {
        label: {
          id: 'LiveCamWidgetPage.fields.ratio.label',
          description: 'Live Cam Widget Page - Fields - Ratio - Label',
          defaultMessage: 'Ratio',
        },
        choices: {
          square: {
            id: 'LiveCamWidgetPage.fields.ratio.choices.square',
            description: 'Live Cam Widget Page - Fields - Ratio - Choices - Square',
            defaultMessage: 'Square 1:1',
          },
          portrait: {
            id: 'LiveCamWidgetPage.fields.ratio.choices.portrait',
            description: 'Live Cam Widget Page - Fields - Ratio - Choices - Portrait',
            defaultMessage: 'Portrait 3:4',
          },
          landscape: {
            id: 'LiveCamWidgetPage.fields.ratio.choices.landscape',
            description: 'Live Cam Widget Page - Fields - Ratio - Choices - Landscape',
            defaultMessage: 'Landscape 4:3',
          },
          panorama: {
            id: 'LiveCamWidgetPage.fields.ratio.choices.panorama',
            description: 'Live Cam Widget Page - Fields - Ratio - Choices - Panorama',
            defaultMessage: 'Panorama 16:9',
          },
        },
      },
      spacing: {
        label: {
          id: 'LiveCamWidgetPage.fields.spacing.label',
          description: 'Live Cam Widget Page - Fields - Spacing - Label',
          defaultMessage: 'Spacing',
        },
      },
      widgetType: {
        choices: {
          script: {
            id: 'LiveCamWidgetPage.fields.widgetType.choices.script',
            description: 'Live Cam Widget Page - Fields - Widget Type - Choices - Display',
            defaultMessage: 'Display',
          },
          imAds: {
            id: 'LiveCamWidgetPage.fields.widgetType.choices.imAds',
            description: 'Live Cam Widget Page - Fields - Widget Type - Choices - IM Ads',
            defaultMessage: 'IM Ads',
          },
          chatHead: {
            id: 'LiveCamWidgetPage.fields.widgetType.choices.chatHead',
            description: 'Live Cam Widget Page - Fields - Widget Type - Choices - IM Jerky Chat Head',
            defaultMessage: 'Chat Head',
          },
        },
      },
      genericChoices: {
        auto: {
          id: 'LiveCamWidgetPage.fields.genericChoices.auto',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Auto',
          defaultMessage: 'Auto',
        },
        custom: {
          id: 'LiveCamWidgetPage.fields.genericChoices.custom',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Custom',
          defaultMessage: 'Custom',
        },
        default: {
          id: 'LiveCamWidgetPage.fields.genericChoices.default',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Default',
          defaultMessage: 'Default',
        },
        hide: {
          id: 'LiveCamWidgetPage.fields.genericChoices.hide',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Hide',
          defaultMessage: 'Hide',
        },
        off: {
          id: 'LiveCamWidgetPage.fields.genericChoices.off',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Off',
          defaultMessage: 'Off',
        },
        on: {
          id: 'LiveCamWidgetPage.fields.genericChoices.on',
          description: 'Live Cam Widget Page - Fields - Generic Choices - On',
          defaultMessage: 'On',
        },
        responsive: {
          id: 'LiveCamWidgetPage.fields.genericChoices.responsive',
          description: 'Live Cam Widget Page - Fields - Generic Choices - Responsive',
          defaultMessage: 'Responsive',
        },
      },
      modelsSettings: {
        label: {
          id: 'LiveCamWidgetPage.fields.modelsSettings.label',
          description: 'Live Cam Widget Page - Fields - Models Settings - Label',
          defaultMessage: 'Models settings',
        },
      },
    },
    templateSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.templateSettings.title.text',
          description: 'Live Cam Widget Page - Template Settings - Title - Text',
          defaultMessage: 'Template',
        },
      },
      chooseTemplate: {
        id: 'LiveCamWidgetPage.templateSettings.chooseTemplate',
        description: 'Live Cam Widget Page - Template Settings - Choose Template',
        defaultMessage: 'Select a template',
      },
    },
    thumbnailsSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.thumbnailsSettings.title.text',
          description: 'Live Cam Widget Page - Thumbnails Settings - Title - Text',
          defaultMessage: 'Thumbnails',
        },
      },
      thumbnailsSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.thumbnailsSettings.thumbnailsSection.title.text',
            description: 'Live Cam Widget Page - Thumbnails Settings - Thumbnails Section - Title - Text',
            defaultMessage: 'Thumbnails',
          },
        },
      },
      colorFilterSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.thumbnailsSettings.colorFilterSection.title.text',
            description: 'Live Cam Widget Page - Thumbnails Settings - Color Filter Section - Title - Text',
            defaultMessage: 'Filter',
          },
        },
      },
      borderSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.thumbnailsSettings.borderSection.title.text',
            description: 'Live Cam Widget Page - Thumbnails Settings - Border Section - Title - Text',
            defaultMessage: 'Border',
          },
        },
      },
      cornerSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.thumbnailsSettings.cornerSection.title.text',
            description: 'Live Cam Widget Page - Thumbnails Settings - Corner Section - Title - Text',
            defaultMessage: 'Corner',
          },
        },
      },
    },
    typeSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.typeSettings.title.text',
          description: 'Live Cam Widget Page - Content Settings - Title - Text',
          defaultMessage: 'Type',
        },
      },
      widgetTypeSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.widgetTypeSection.title.text',
            description: 'Live Cam Widget Page - Content Settings - Widget Type Section - Title - Text',
            defaultMessage: 'Select Widget Type',
          },
        },
      },
      offerAndPayoutTypesSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.title.text',
            description: 'Live Cam Widget Page - Content Settings - Offer And Payout Section - Title - Text',
            defaultMessage: 'Offers And Payout Types',
          },
        },
        selectOffer: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.selectOffer.text',
            description: 'Live Cam Widget Page - Content Settings - Offer And Payout Section - Select Offer - Text',
            defaultMessage: 'Select offers',
          },
        },
        selectedOffers: {
          title: {
            text: {
              id: 'LiveCamWidgetPage.typeSettings.offerAndPayoutTypesSection.selectedOffers.title.text',
              description: 'Live Cam Widget Page - Content Settings - Offer And Payout Section - Selected Offers - Title - Text',
              defaultMessage: 'Offers',
            },
          },
        },
      },
      modelsSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.modelsSection.title.text',
            description: 'Live Cam Widget Page - Content Settings - Model Section - Title - Text',
            defaultMessage: 'Models',
          },
        },
        tagsTitle: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.modelsSection.tagsTitle.text',
            description: 'Live Cam Widget Page - Content Settings - Model Section - Tags Title - Text',
            defaultMessage: 'Model tags',
          },
        },
        tags: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.modelsSection.tags.text',
            description: 'Live Cam Widget Page - Content Settings - Model Section - Tags - Text',
            defaultMessage: 'Tags',
          },
        },
        spokenLanguages: {
          title: {
            text: {
              id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.title.text',
              description: `${spokenLanguageStem} - Title - Text`,
              defaultMessage: 'Model spoken languages',
            },
          },
          languages: {
            text: {
              id: 'LiveCamWidgetPage.typeSettings.modelsSection.languages.text',
              description: 'Live Cam Widget Page - Content Settings - Model Section - Languages - Text',
              defaultMessage: 'Languages',
            },
          },
          options: {
            en: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.en.text',
                description: `${spokenLanguageStem} - Choices - EN`,
                defaultMessage: 'English',
              },
            },
            es: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.es.text',
                description: `${spokenLanguageStem} - Choices - ES`,
                defaultMessage: 'Spanish',
              },
            },
            de: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.de.text',
                description: `${spokenLanguageStem} - Choices - DE`,
                defaultMessage: 'German',
              },
            },
            fr: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.fr.text',
                description: `${spokenLanguageStem} - Choices - FR`,
                defaultMessage: 'French',
              },
            },
            it: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.it.text',
                description: `${spokenLanguageStem} - Choices - IT`,
                defaultMessage: 'Italian',
              },
            },
            pt: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.pt.text',
                description: `${spokenLanguageStem} - Choices - PT`,
                defaultMessage: 'Portuguese',
              },
            },
            sv: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.sv.text',
                description: `${spokenLanguageStem} - Choices - SV`,
                defaultMessage: 'Swedish',
              },
            },
            nl: {
              text: {
                id: 'LiveCamWidgetPage.typeSettings.modelsSection.spokenLanguages.options.nl.text',
                description: `${spokenLanguageStem} - Choices - NL`,
                defaultMessage: 'Dutch',
              },
            },
          },
        },
        warnings: {
          maxCategories: {
            id: 'LiveCamWidgetPage.typeSettings.modelsSection.warnings.maxCategories',
            description: 'Live Cam Widget Page - Content Settings - Categories Section - Warnings - Max Categories',
            defaultMessage: 'You can choose a maximum of two categories.',
          },
        },
      },
      settingsSection: {
        chatTextLanguage: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.settingsSection.chatTextLanguage.text',
            description: 'Live Cam Widget Page - Content Settings - Settings Section - Chat Text Language - Text',
            defaultMessage: 'Chat Text Language',
          },
        },
        expireDays: {
          label: {
            id: 'LiveCamWidgetPage.typeSettings.settingsSection.expireDays.text',
            description: 'Live Cam Widget Page - Content Settings - Settings Section - Delay before showing again - Text',
            defaultMessage: 'Delay before showing again',
          },
        },
        minutes: {
          text: {
            id: 'LiveCamWidgetPage.typeSettings.settingsSection.minutes.text',
            description: 'Live Cam Widget Page - Content Settings - Settings Section - minutes - Text',
            defaultMessage: 'minutes',
          },
        },
      },
    },
    layoutSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.layoutSettings.title.text',
          description: 'Live Cam Widget Page - Layout Settings - Title - Text',
          defaultMessage: 'Layout Settings',
        },
      },
      backgroundSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.layoutSettings.backgroundSection.title.text',
            description: 'Live Cam Widget Page - Layout Settings - Background Section - Title - Text',
            defaultMessage: 'Background',
          },
        },
      },
      layoutSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.layoutSettings.layoutSection.title.text',
            description: 'Live Cam Widget Page - Layout Settings - Layout Section - Title - Text',
            defaultMessage: 'Layout',
          },
        },
        columnsField: {
          label: {
            id: 'LiveCamWidgetPage.layoutSettings.layoutSection.columnsField.label',
            description: 'Live Cam Widget Page - Layout Settings - Layout Section - Columns Field - Label',
            defaultMessage: 'Number of Columns',
          },
        },
        rowsField: {
          label: {
            id: 'LiveCamWidgetPage.layoutSettings.layoutSection.rowsField.label',
            description: 'Live Cam Widget Page - Layout Settings - Layout Section - Rows Field - Label',
            defaultMessage: 'Number of Rows',
          },
        },
      },
      widgetSizeSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.layoutSettings.widgetSizeSection.title.text',
            description: 'Live Cam Widget Page - Layout Settings - Widget Size Section - Title - Text',
            defaultMessage: 'Widget size',
          },
        },
        maxWidthField: {
          label: {
            id: 'LiveCamWidgetPage.layoutSettings.widgetSizeSection.maxWidthField.label',
            description: 'Live Cam Widget Page - Layout Settings - Widget Size Section - Max Width Field - Label',
            defaultMessage: 'Max-width',
          },
        },
        widthField: {
          label: {
            id: 'LiveCamWidgetPage.layoutSettings.widgetSizeSection.widthField.label',
            description: 'Live Cam Widget Page - Layout Settings - Widget Size Section - Width Field - Label',
            defaultMessage: 'Width',
          },
        },
        heightField: {
          label: {
            id: 'LiveCamWidgetPage.layoutSettings.widgetSizeSection.heightField.label',
            description: 'Live Cam Widget Page - Layout Settings - Widget Size Section - Height Field - Label',
            defaultMessage: 'Height',
          },
        },
      },
      thumbnailsSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.layoutSettings.thumbnailsSection.title.text',
            description: 'Live Cam Widget Page - Layout Settings - Thumbnails Section - Title - Text',
            defaultMessage: 'Thumbnails',
          },
        },
      },
    },
    infobarSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.infobarSettings.title.text',
          description: 'Live Cam Widget Page - Infobar Settings - Title - Text',
          defaultMessage: 'Infobar Settings',
        },
      },
      displaySection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.infobarSettings.displaySection.title.text',
            description: 'Live Cam Widget Page - Infobar Settings - Display Section - Title - Text',
            defaultMessage: 'Infobar',
          },
        },
        infos: {
          label: {
            id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.label',
            description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Label',
            defaultMessage: 'Infos',
          },
          choices: {
            age: {
              id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.choices.age',
              description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Choices - Age',
              defaultMessage: 'Age',
            },
            gender: {
              id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.choices.gender',
              description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Choices - Gender',
              defaultMessage: 'Gender',
            },
            live: {
              id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.choices.live',
              description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Choices - Live',
              defaultMessage: 'Live icon',
            },
            name: {
              id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.choices.name',
              description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Choices - Name',
              defaultMessage: 'Name',
            },
            room_topic: {
              id: 'LiveCamWidgetPage.infobarSettings.displaySection.infos.choices.room_topic',
              description: 'Live Cam Widget Page - Infobar Settings - DisplaySection - Infos - Choices - Room Topic',
              defaultMessage: 'Room topic',
            },
          },
        },
      },
      fontSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.infobarSettings.fontSection.title.text',
            description: 'Live Cam Widget Page - Infobar Settings - Font Section - Title - Text',
            defaultMessage: 'Font',
          },
        },
        fontColor: {
          label: {
            id: 'LiveCamWidgetPage.infobarSettings.fontSection.fontColor.label',
            description: 'Live Cam Widget Page - Infobar Settings - Font Section - Font Color - Label',
            defaultMessage: 'Font Color',
          },
        },
        fontSize: {
          label: {
            id: 'LiveCamWidgetPage.infobarSettings.fontSection.fontSize.label',
            description: 'Live Cam Widget Page - Infobar Settings - Font Section - Font Size - Label',
            defaultMessage: 'Font Size',
          },
        },
      },
      positionSection: {
        title: {
          text: {
            id: 'LiveCamWidgetPage.infobarSettings.positionSection.title.text',
            description: 'Live Cam Widget Page - Infobar Settings - Position Section - Title - Text',
            defaultMessage: 'Position',
          },
        },
        choices: {
          bottom: {
            id: 'LiveCamWidgetPage.infobarSettings.positionSection.choices.bottom',
            description: 'Live Cam Widget Page - Infobar Settings - Position Section - Choices - Bottom',
            defaultMessage: 'Bottom',
          },
          top: {
            id: 'LiveCamWidgetPage.infobarSettings.positionSection.choices.top',
            description: 'Live Cam Widget Page - Infobar Settings - Position Section - Choices - Top',
            defaultMessage: 'Top',
          },
          inside: {
            id: 'LiveCamWidgetPage.infobarSettings.positionSection.choices.inside',
            description: 'Live Cam Widget Page - Infobar Settings - Position Section - Choices - Inside',
            defaultMessage: 'Inside',
          },
          outside: {
            id: 'LiveCamWidgetPage.infobarSettings.positionSection.choices.outside',
            description: 'Live Cam Widget Page - Infobar Settings - Position Section - Choices - Outside',
            defaultMessage: 'Outside',
          },
        },
      },
    },
    customCssSettings: {
      title: {
        text: {
          id: 'LiveCamWidgetPage.customCssSettings.title.text',
          description: 'Live Cam Widget Page - Custom CSS Settings - Title - Text',
          defaultMessage: 'Custom CSS',
        },
      },
    },
    widgetPreview: {
      emptyIFrame: {
        header: {
          id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.header',
          description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Header',
          defaultMessage: 'Promote only the top earning models&excl;',
        },
        contents: [
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.contents1',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Content1',
            defaultMessage: 'Building the perfect conversion funnel has never been easier&excl;',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.contents2',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Content1',
            defaultMessage:
              'Say goodbye to mundane&comma; manual optimization&period; With the Live Cam Widget&comma; your campaign is always optimized&period;',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.contents3',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Content2',
            defaultMessage: 'Your user clicks on the performer of his or her choice and lands directly in her live show&period;',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.contents4',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Content3',
            defaultMessage:
              'CrakRevenue&apos;s Live Cam widget&apos;s exclusive algorithm continuously updates the performers based on model popularity&comma; number of viewers&comma; trending top cams and overall conversion rate&period; Unleash this powerful revenue stream today&excl;',
          },
        ],
        mfcPros: [
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros1',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - MFC Pros1',
            defaultMessage: 'Funnel auto&dash;updated',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros2',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - MFC Pros2',
            defaultMessage: 'Live models only',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros3',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - MFC Pros3',
            defaultMessage: 'Exclusive &amp; powerful algorithm',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros4',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - MFC Pros4',
            defaultMessage: 'User friendly',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.mfcPros5',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - MFC Pros5',
            defaultMessage: '100&percnt; customizable',
          },
        ],
        infos: [
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.infos1',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Infos5',
            defaultMessage:
              'To get started&comma; please take a look at our complete tutorial by pressing play on the adjacent video&period;',
          },
          {
            id: 'LiveCamWidgetPage.widgetPreview.emptyIFrame.infos2',
            description: 'Live Cam Widget Page - Widget Preview - Empty iFrame - Infos2',
            defaultMessage:
              'For more information about the Live Cam Widget or for a custom request&comma; please contact your affiliate manager&period;',
          },
        ],
      },
    },
    warnings: {
      incompatibleTemplate: {
        id: 'LiveCamWidgetPage.warnings.incompatibleTemplate',
        description: 'Live Cam Widget Page - Warnings - Incompatible Template',
        defaultMessage: 'The template you have selected is not compatible with this feature.',
      },
      screenResolution: {
        id: 'LiveCamWidgetPage.warnings.screenResolution',
        description: 'Live Cam Widget Page - Warnings - Screen Resolution',
        defaultMessage:
          'This section displays your customized widget based on the corresponding screen resolution of the device being used.',
      },
      selectTemplateFirst: {
        id: 'LiveCamWidgetPage.warnings.selectTemplateFirst',
        description: 'Live Cam Widget Page - Warnings - Select Template First',
        defaultMessage: 'Choose a layout template to enable {tab}',
      },
      selectTypeFirst: {
        id: 'LiveCamWidgetPage.warnings.selectTypeFirst',
        description: 'Live Cam Widget Page - Warnings - Select Type First',
        defaultMessage: 'Select an offer to enable {tab}',
      },
      disabledWidgetType: {
        id: 'LiveCamWidgetPage.warnings.disabledWidgetType',
        description: 'Live Cam Widget Page - Warnings - Disabled Tooltip',
        defaultMessage: 'This widget type is avalaible only when selecting either Jerkmate or MyFreeCams offers.',
      },
      blockedTab: {
        id: 'LiveCamWidgetPage.warnings.blockedTab',
        description: 'Live Cam Widget Page - Warnings - Blocked Tab',
        defaultMessage: 'Settings in this tab do not apply to the currently selected Widget Type.',
      },
    },
  },
};

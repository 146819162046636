import { Payout } from '../reusables';
import config from '../../config';

const { JERKMATE_NEW_OFFER_TAG_ID } = config;

export const getConversionTypeMessage = (offer) => {
  const payout = (
    <Payout
      conversionTypes={offer.conversion_types.map((ct) => ct.name)}
      defaultPayout={offer.default_payout}
      payoutType={offer.payout_type}
      percentPayout={offer.percent_payout}
    />
  );
  const conversionTypes = offer.conversion_types.map((ct) => ct.name);
  if (offer.id === '8563' || offer.id === '511') {
    return (
      <p>
        Starting at <span className="payout">{payout}</span>, earn up to $20 for each new premium subscription! 2-step
        registration. No purchase is required. (DOI)
      </p>
    );
  }
  switch (conversionTypes[0]) {
    case 'PPS':
      return (
        <p>
          Earn up to <span className="payout">{payout}</span> for each new premium subscription or purchase! (PPS)
        </p>
      );
    case 'DOI':
      return (
        <p>
          Earn up to <span className="payout">{payout}</span> for each new premium subscription! 2-step registration. No purchase
          is required. (DOI)
        </p>
      );
    case 'SOI':
      return (
        <p>
          Earn up to <span className="payout">{payout}</span> or each new premium subscription! 1-step registration. No purchase
          is required. (SOI)
        </p>
      );
    case 'CPI':
      return (
        <p>
          Earn up to <span className="payout">{payout}</span> for each new installation! (CPI)
        </p>
      );
    case 'CPM':
      return (
        <p>
          Earn up to <span className="payout">{payout}</span> for every thousand impressions! (CPM)
        </p>
      );
    case 'Revshare':
      return (
        <p>
          Earn <span className="payout">{payout}</span> of all spending made by your traffic! (RevShare)
        </p>
      );
    case 'Revshare Lifetime':
      return (
        <p>
          Earn <span className="payout">{payout}</span> of all spending made by your traffic forever! (RevShare Lifetime)
        </p>
      );
    default:
      return <p>Earn maximum payout according to your traffic quality for each new conversion!</p>;
  }
};

export const offerHasNewTag = (offer) => !!offer.tags.find((tag) => tag.id === JERKMATE_NEW_OFFER_TAG_ID.id);

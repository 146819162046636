import InvoiceIcon from './InvoiceIcon';
import moment from 'moment/moment';
import { FormattedNumber } from 'react-intl';
import { Gtm } from '../../utils/gtm';
import ArrowBtnIcon from './ArrowBtnIcon';
import React from 'react';

const NextPaymentsTable = ({ pendingPaymentList, dueDateTransform, handleInvoiceRequest, setPaymentTab }) => (
  <div className="payment-row next-payment">
    <div className="invoices ">
      {pendingPaymentList.length > 0 ? (
        pendingPaymentList.map((payment, index) => {
          const startDate = moment(payment.start_date).format('YYYY/MM/DD');
          const endDate = moment(payment.end_date).format('YYYY/MM/DD');
          const dueDate = payment.due_date ?? dueDateTransform(payment);
          const amountDue = parseFloat(payment.amount).toFixed(2);
          return (
            <div className="tr" key={index}>
              <div className="td">
                <div className="payment-box">
                  <InvoiceIcon />
                  {startDate} - {endDate}
                </div>
              </div>
              <div className="td due-date">{dueDate}</div>
              <div className="td">
                <div className="amount-box">
                  <FormattedNumber currency={payment.currency} style="currency" value={amountDue} />
                  <div className="pdf-invoice details-box" onClick={(e) => handleInvoiceRequest(e, payment.id)}>
                    <span className="details-box">
                      <i
                        className="material-icons primary-color "
                        onClick={() => Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - invoice')}
                      >
                        remove_red_eye
                      </i>
                      <span className="details-button">details</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="next-payments-empty-state">
          <p>
            No upcoming payments yet. <br /> Here you'll find all the payments you’re due.
          </p>
          <div className="btn-box">
            <span className="current-btn" onClick={() => setPaymentTab('current')}>
              <ArrowBtnIcon /> Current period
            </span>
            <span className="history-btn" onClick={() => setPaymentTab('history')}>
              Payment History <ArrowBtnIcon />
            </span>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default NextPaymentsTable;

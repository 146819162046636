const PlusIcon = () => (
  <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
    <title>Plus</title>
    <g>
      <circle cx="26.5" cy="26.5" r="26.5" />
      <path d="M39.75,24.29h-11v-11a2.21,2.21,0,0,0-4.42,0v11h-11a2.21,2.21,0,0,0,0,4.42h11v11a2.21,2.21,0,0,0,4.42,0v-11h11a2.21,2.21,0,0,0,0-4.42Z" />
    </g>
  </svg>
);

export default PlusIcon;

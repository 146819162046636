import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import messages from '../i18n/base-en.js';
import { setActionMessage } from '../components/Application/actions/application-actions';
import { getCookie } from '../utils/helpers';

import consts from '../utils/consts';
import config from '../config';

const { ACTIONS } = consts;
const { API_URL } = config;

const requestPostbacks = () => ({ type: ACTIONS.REQUEST_POSTBACKS });
const receivePostbacks = (data) => ({
  type: ACTIONS.RECEIVE_POSTBACKS,
  postbacksReceivedAt: Date.now(),
  postbacks: data,
});
const failedToReceivePostbacks = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_POSTBACKS,
});
export const fetchPostbacks = (offerId) => (dispatch) => {
  dispatch(requestPostbacks());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/postbacks/findAllByOfferId`)
      .withCredentials()
      .query({ offerId })
      .end((err, res) => {
        if (!err) {
          dispatch(receivePostbacks(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceivePostbacks());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const deletePostback = (postbackId, offerId, intl) => (dispatch) =>
  new Promise((fulfill, reject) =>
    dispatch(
      setActionMessage(
        'confirm',
        {
          text: intl.formatMessage(messages.offerAreYouSureToDeletedPostback),
        },
        9000000,
        () => {
          dispatch(requestPostbacks());
          return request
            .delete(`${API_URL}/postbacks/delete`)
            .withCredentials()
            .query({ postbackId })
            .end((err, res) => {
              if (!err) {
                dispatch(
                  setActionMessage('success', {
                    text: intl.formatMessage(messages.offerDeletedPostback),
                  })
                );
                dispatch(fetchPostbacks(offerId));
                fulfill();
              } else {
                dispatch(failedToReceivePostbacks());
                const APIErrorhandler = new HandleAPIError(res, dispatch);
                APIErrorhandler.redirectIfNotLoggedIn();
                APIErrorhandler.showErrorMessage();
                reject();
              }
            });
        }
      )
    )
  );

const addedPostback = (data) => ({
  type: ACTIONS.ADDED_POSTBACK,
  postback: data.postback,
});

export const addPostBackVariables = (data) => ({
  type: ACTIONS.POSTBACKS_VARIABLES,
  postbacksVariables: data,
});

export const removePostBackVariable = (name) => ({
  type: ACTIONS.REMOVE_POSTBACKS_VARIABLES,
  postbacksVariable: name,
});

export const addPostback = (offerId, goalId, code, intl) => (dispatch) => {
  dispatch(requestPostbacks());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/postbacks/create`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .query({ offerId, goalId, code })
      .end((err, res) => {
        if (!err) {
          dispatch(addedPostback(res.body.data));
          dispatch(
            setActionMessage('success', {
              text: intl.formatMessage(messages.offerPostbackAdded),
            })
          );
          fulfill();
        } else {
          dispatch(failedToReceivePostbacks());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import CloseModalIcon from '../../reusables/svg/Gamification/CloseModalIcon.react';
import { connect } from 'react-redux';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import React, { useEffect } from 'react';
import moment from 'moment';
import BrokenModalFooter from '../../reusables/svg/Gamification/BrokenModalFooter.react';
import LeftBrokenBar from './svg/leftBrokenBar.react';
import RightBrokenBar from './svg/rightBrokenBar.react';
import { Gtm } from '../../../utils/gtm';

const BrokenStreakModal = React.memo(
  ({ isImpression, gamificationBadges, gamificationAffProgress, dispatch, modalInner, streakWeek }) => {
    const today = new Date();
    const sunday = new Date(moment().startOf('week').toDate());
    const diffTime = Math.abs(sunday - today);
    const daysStreakThisWeek = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;

    const whaleClassName = (img) => {
      if (img.includes('2')) {
        return 'lvl-2';
      } else if (img.includes('3')) {
        return 'lvl-3';
      } else if (img.includes('4')) {
        return 'lvl-4';
      }
      return 'lvl-1';
    };

    const currentLevel = streakWeek || gamificationAffProgress?.progressData.streak;
    const logoLevel = currentLevel >= 16 ? 16 : currentLevel;
    const logoLowerLevel = Math.max(0, logoLevel - 1);
    const closeOpenModal = (isNotStartAgainBtn = true) => {
      dispatch(closeModalAnimation());
      if (isNotStartAgainBtn) {
        Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', `close_${currentLevel}`);
      } else {
        Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', 'start_again');
      }
    };

    useClickOutside(modalInner, closeOpenModal);

    useEffect(() => {
      if (isImpression) {
        Gtm.newEvent('gamified_ranking_modal', false, 'impression');
      }
    }, []);

    return (
      <>
        <div className="header-modal">
          <button onClick={() => closeOpenModal()} type="button">
            <CloseModalIcon />
          </button>
          <div className="badge-section">
            <img
              alt="badge"
              className={`badge ${gamificationBadges[logoLowerLevel]?.badgeData?.subtype}`}
              src={`/img/gamification/brokenThumbnail/${gamificationBadges[logoLowerLevel]?.badgeData?.subtype}-broken-thumbnail.svg`}
            />
            <div className="shadow" />
          </div>
        </div>
        <div className="week-streak-section">
          <div className="rotate-title">
            <span className="first-title">Ohhh no!</span>
            <span className="second-title">You’ve lost your Streak</span>
          </div>
          <div className="progress-bar">
            <div className="left-side">
              {logoLevel < 16 && (
                <div className="whale-lvl">
                  <img
                    className={`${whaleClassName(gamificationBadges[logoLowerLevel]?.badgeData?.whaleUrl)} ${
                      whaleClassName(gamificationBadges[logoLowerLevel]?.badgeData?.whaleUrl) === 'lvl-4' ? 'left-lvl-4' : ''
                    }`}
                    src={gamificationBadges[logoLowerLevel]?.badgeData?.whaleUrl}
                  />
                  <span className="lvl-chip">{`Lvl ${currentLevel}`}</span>
                </div>
              )}
              {[...Array(4).keys()].map((bar, key) => {
                if (bar === 3) {
                  return <LeftBrokenBar className={bar < daysStreakThisWeek && `active`} key={key} />;
                }
                return <div className={`bar ${bar < daysStreakThisWeek && 'active'}`} key={key} />;
              })}
            </div>

            <div className="right-side">
              {[...Array(4).keys()].map((bar, key) => {
                if (bar === 0) {
                  return <RightBrokenBar className={bar + 3 < daysStreakThisWeek && `active`} key={key} />;
                }
                return <div className={`bar ${bar + 3 < daysStreakThisWeek && 'active'}`} key={key} />;
              })}
              <div className="whale-lvl right">
                <img
                  className={whaleClassName(
                    gamificationBadges[logoLevel >= 16 ? logoLowerLevel : logoLevel]?.badgeData?.whaleUrl
                  )}
                  src={gamificationBadges[logoLevel >= 16 ? logoLowerLevel : logoLevel]?.badgeData?.whaleUrl}
                />
                <span className="lvl-chip">{`Lvl ${currentLevel < 16 ? currentLevel + 1 : currentLevel}`}</span>
              </div>
            </div>
          </div>
          <p>
            Unfortunately, you've lost your streak. To keep a streak alive and reach your next badge, make sure to log in at least
            once a week. Come back next week to start a new streak.
          </p>
        </div>
        <button className="btn-large btn gamification-btn" onClick={() => closeOpenModal(false)}>
          Start Again
        </button>
        <BrokenModalFooter />
      </>
    );
  }
);

BrokenStreakModal.displayName = 'BrokenStreakModal';

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affInfos: state.profile.data.affInfos,
  gamificationEventsStats: state.gamification.eventsData.notification.stats,
  gamificationEventsNotif: state.gamification.eventsData.notification,
  gamificationAffProgress: state.gamification.eventsData.notification.affiliateProgress,
  gamificationBadges: state.gamification.loginStreakBadges,
}))(BrokenStreakModal);

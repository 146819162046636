import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

import { StandardButton } from '../../../../components/reusables';
import ApprovedIcon from '../../../../components/reusables/svg/ApprovedIcon.react';
import InterrogationIcon from '../../../../components/reusables/svg/InterrogationIcon.react';
import PlusIcon from '../../../../components/reusables/svg/PlusIcon.react';
import XIcon from '../../../../components/reusables/svg/XIcon.react';
import { connect } from 'react-redux';
import { openModal } from '../../../Modal/modal-actions/modal-actions.js';
import { Gtm } from '../../../../utils/gtm';

const OfferStatusBar = ({ approvalStatus, isButtonStyled, offerIsFetching, dispatch }) => {
  if (offerIsFetching) {
    return <span>...</span>;
  }

  const getStatusClassNames = ({ approvalStatus, isButtonStyled }) => {
    if (isButtonStyled) {
      switch (approvalStatus) {
        case 'approval-required':
          return ['bg-primary-color', 'active'];
        case 'approved':
          return ['grey'];
        case 'pending':
          return ['highlight'];
        case 'rejected':
          return ['warning'];
        default:
          return [];
      }
    }
    if (approvalStatus === 'approval-required') {
      return ['active'];
    }
    return [];
  };

  const getStatusIcon = (approvalStatus) => {
    switch (approvalStatus) {
      case 'approval-required':
        return <PlusIcon />;
      case 'approved':
        return <ApprovedIcon />;
      case 'pending':
        return <InterrogationIcon />;
      case 'rejected':
        return <XIcon />;
      default:
        return null;
    }
  };

  const getStatusLabel = (approvalStatus) => <FormattedMessage {...messages.offerStatus.values[approvalStatus]} />;

  const handleStatusButtonClick = (approvalStatus) => {
    if (approvalStatus === 'approval-required') {
      Gtm.event('UI Component', 'Click', 'Console-Request-Approval');
      dispatch(
        openModal({
          name: 'OfferRequestModal',
          modalProps: {
            dispatch,
          },
        })
      );
    }
    if (approvalStatus === 'rejected') {
      return '';
    }
  };

  return approvalStatus ? (
    <div className={`offer-status-bar ${approvalStatus}`}>
      <StandardButton
        classNames={getStatusClassNames({
          approvalStatus,
          isButtonStyled,
          offerIsFetching,
        })}
        icon={getStatusIcon(approvalStatus)}
        isButtonStyled={isButtonStyled}
        label={getStatusLabel(approvalStatus)}
        onClick={() => handleStatusButtonClick(approvalStatus)}
      />
    </div>
  ) : null;
};

OfferStatusBar.defaultProps = {
  isButtonStyled: true,
};

OfferStatusBar.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
  isButtonStyled: PropTypes.bool,
  offerIsFetching: PropTypes.bool,
};

export default connect()(OfferStatusBar);

import React from 'react';

const RadioButtonCustom = React.memo(({ field, label, onChange, options, tabindex, containerClass }) => {
  return (
    <div className={`field ${containerClass}`}>
      {label ? (
        <div className="labelBox">
          <label>{label}</label>
        </div>
      ) : null}
      <div className="radio-btn-wrapper">
        {options.map((option, index) => (
          <label className="radio-btn-container" htmlFor={option} key={index}>
            <input
              id={option}
              name={field.name}
              onClick={(e) => {
                onChange(e.target.value);
              }}
              tabIndex={tabindex}
              type="radio"
              value={option}
            />
            <label htmlFor={option}>{option}</label>
          </label>
        ))}
      </div>
    </div>
  );
});

export default RadioButtonCustom;

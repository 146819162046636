import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { MODALS } from './modals/modal.modals';

const ModalComponent = ({ modal, modalProps, shouldModalAnimate }) => {
  const Component = MODALS[modal];

  useEffect(() => {
    const body = document.body;
    if (!shouldModalAnimate) {
      body.style.top = `-${window.scrollY}px`;
      body.style.overflow = 'hidden';
    } else {
      const scrollY = body.style.top;
      body.style.overflow = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [shouldModalAnimate]);
  const modalInner = useRef();
  return (
    <>
      <div className={`${shouldModalAnimate ? 'hide-modal' : ''} dimmer`} />
      <div
        className={`${modalProps.className ? modalProps.className : ''} modal-component ${
          modalProps.isLarge ? 'largeModal' : ''
        } ${shouldModalAnimate ? 'hide-modal' : ''} ${modal || ''}`}
      >
        <div className="modal-inner" ref={modalInner}>
          <Component {...modalProps} modalInner={modalInner} />
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  modal: state.modal.modal,
  modalProps: state.modal.modalProps,
  shouldModalAnimate: state.modal.shouldModalAnimate,
}))(ModalComponent);

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';

import { transformTableValue } from '../../utils/helpers';

class LineChart extends Component {
  constructor(props) {
    super(props);

    this.drawChart = this.drawChart.bind(this);
    this.buildGraphData = this.buildGraphData.bind(this);

    this.initRenderChart = this.initRenderChart.bind(this);

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.chart = {};
    this.state = {
      hasNoStats: true,
    };
  }

  componentDidMount() {
    window.google.charts.load('current', {
      packages: ['corechart', 'line'],
    });
    window.google.charts.setOnLoadCallback(this.drawChart);

    const { statisticsGraphData } = this.props;
    if (window) {
      if (!window.google.visualization) {
        this.initRenderChart();
      } else if (
        Object.prototype.hasOwnProperty.call(window.google.visualization, 'DataTable') &&
        Object.prototype.hasOwnProperty.call(statisticsGraphData, 'graphValues')
      ) {
        this.drawChart();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { statisticsGraphData } = this.props;
    const { hasNoStats } = this.state;

    return !_.isEqual(statisticsGraphData, nextProps.statisticsGraphData) || !_.isEqual(hasNoStats, nextState.hasNoStats);
  }

  componentDidUpdate() {
    const { statisticsGraphData } = this.props;

    if (!!window && !!window.google.visualization && !!statisticsGraphData.graphValues) {
      this.drawChart();
    }
  }

  initRenderChart() {
    window.google.charts.load('current', {
      packages: ['corechart', 'line'],
    });
    window.google.charts.setOnLoadCallback(this.drawChart);
  }

  buildGraphData(data) {
    const { intl } = this.props;

    const graphColData = [];
    const minMaxValues = {};

    const chartRows = (data.graphValues || []).map((rows, j) =>
      rows.map((row, index) => {
        let objType = 'number';
        let value = row;

        if (index === 0 && value !== 'Stat.hour') {
          objType = 'date';
        }
        if (index === 0 && value === 'Stat.hour') {
          objType = 'timeofday';
        }

        if (j === 0) {
          const field = data.titlesArray[index].split(' | ');
          const textTitle = [intl.formatMessage(messages.statisticsColumns[field.shift()]), ...field].join(' | ');

          graphColData.push([objType, textTitle, data.titlesArray[index]]);
        } else {
          if (index === 0) {
            if (value.length > 2) {
              const dateArray = row.split('-');
              dateArray[1] = parseInt(dateArray[1], 10) - 1;

              value = new Date(...dateArray);
            } else if (graphColData[index][0] === 'timeofday') {
              value = [parseInt(value, 10), 0, 0, 0];
            }
          } else {
            if (typeof graphColData[index] !== 'undefined') {
              const thisField = graphColData[index][2].split(' | ')[0];

              if (!Object.prototype.hasOwnProperty.call(minMaxValues, thisField)) {
                minMaxValues[thisField] = {
                  min: value,
                  max: value,
                  haveDecimals: false,
                };
              } else {
                if (minMaxValues[thisField].min > value) {
                  minMaxValues[thisField].min = value;
                }
                if (minMaxValues[thisField].max < value) {
                  minMaxValues[thisField].max = value;
                }
              }

              if (value % 1 !== 0) {
                minMaxValues[thisField].haveDecimals = true;
              }
            }
          }

          if (typeof graphColData[index] !== 'undefined' && graphColData[index][0] === 'number' && value !== null) {
            value = parseFloat(value, 10);
          }

          return transformTableValue(graphColData[index][2], value, true);
        }
      })
    );

    chartRows.shift();

    return {
      cols: graphColData,
      rows: chartRows,
      minMaxValues,
    };
  }

  drawChart() {
    const chartData = new window.google.visualization.DataTable();
    const { graphFields, resize, skinConfigurations, statisticsGraphData, withLegend } = this.props;
    let { graphWidth } = this.props;
    const COLORS = SkinConfigurationsUtils.getColors(skinConfigurations);
    const that = this;

    const { cols, rows, minMaxValues } = this.buildGraphData(statisticsGraphData);
    const series = {};
    const vAxes = {};
    let hAxisTitle = typeof cols[0] !== 'undefined' ? cols[0][1] : '';
    let colors = [];
    let legend;

    if (hAxisTitle === 'Hour') {
      hAxisTitle = 'Hour (EDT – Eastern Daylight Time)';
    }

    if (cols.length > 0) {
      this.setState({
        hasNoStats: false,
      });
      if (!withLegend) {
        legend = { position: 'none' };
      } else {
        legend = {
          position: 'top',
          color: '#333',
          textStyle: {
            color: '#333',
            fontSize: 13,
          },
        };
      }

      cols.forEach((col, index) => {
        let dimension = col[1];
        if (dimension === 'Payout') {
          dimension = 'Payout ($)';
        }

        chartData.addColumn(col[0], dimension);

        if (index !== 0) {
          if (index <= graphFields.length) {
            const currentField = col[2].split(' | ')[0];
            const title = dimension.split(' - ')[0];

            const minMaxDiff = minMaxValues[currentField].max - minMaxValues[currentField].min;
            let format =
              minMaxValues[currentField].min > 1000 ||
              (minMaxValues[currentField].min.toString().split('.')[1] || []).length > 3 ||
              (minMaxValues[currentField].max.toString().split('.')[1] || []).length > 3
                ? 'short'
                : 0;

            if (
              minMaxValues[currentField].haveDecimals === true &&
              (minMaxValues[currentField].min.toString().split('.')[1] || []).length <= 3 &&
              (minMaxValues[currentField].max.toString().split('.')[1] || []).length < 3
            ) {
              format = null;
            }

            vAxes[index - 1] = {
              title,
              format,
              gridlines: {
                count: minMaxDiff < 8 ? minMaxDiff + 1 : 8,
              },
              maxValue: minMaxValues[currentField].max,
              minValue: minMaxValues[currentField].min,
            };
          }

          if (graphFields.length < 2) {
            series[index - 1] = {
              targetAxisIndex: 0,
              lineDashStyle: null,
            };

            if (colors.length === 0) {
              colors = [COLORS.PRIMARY_COLOR.DEFAULT, '#3484BE', '#FF9F00', '#884CA2', '#D03C04'];
            }
          } else {
            if (index % 2 === 0) {
              series[index - 1] = {
                targetAxisIndex: 1,
                lineDashStyle: [4, 4],
              };
            } else {
              series[index - 1] = {
                targetAxisIndex: 0,
                lineDashStyle: null,
              };
            }

            if (colors.length === 0) {
              colors = [
                COLORS.PRIMARY_COLOR.DEFAULT,
                COLORS.PRIMARY_COLOR.DEFAULT,
                '#3484BE',
                '#3484BE',
                '#FF9F00',
                '#FF9F00',
                '#884CA2',
                '#884CA2',
                '#D03C04',
                '#D03C04',
              ];
            }
          }
        }
      });

      chartData.addRows(rows);

      if (typeof graphWidth === 'undefined') {
        if (Object.keys(vAxes).length === 2) {
          graphWidth = 85;
        } else {
          graphWidth = 90;
        }
      }

      const options = {
        legend,
        chartArea: {
          left: (98 - graphWidth) / Object.keys(vAxes).length + '%',
          width: graphWidth + '%',
          backgroundColor: {
            stroke: '#dddddd',
            strokeWidth: 1,
          },
        },
        colors: colors.length === 0 ? [COLORS.PRIMARY_COLOR.DEFAULT] : colors, // couleur des lignes,
        pointsVisible: 1,
        pointSize: 10,
        pointShape: {
          type: 'circle',
          stroke: {
            color: '#ffffff',
            width: 1,
            opacity: 1,
          },
        },
        hAxis: {
          // Ligne axis X
          title: hAxisTitle,
          textPosition: 'out',
          format: cols[0][0] === 'timeofday' ? 'h a' : null,
          titleTextStyle: {
            italic: false,
          },
          textStyle: {
            color: '#656565',
            fontSize: 12,
            textAlign: 'left',
          },
          gridlines: {
            color: '#dddddd',
            count: -1,
          },
          baselineColor: '#dddddd',
        },
        explorer: {
          axis: 'horizontal',
          actions: ['dragToZoom', 'rightClickToReset'],
          maxZoomIn: 0.05,
        },
        vAxes,
        vAxis: {
          // Ligne axis Y
          textStyle: {
            color: '#656565',
          },
          titleTextStyle: {
            italic: false,
          },
          gridlines: {
            color: '#dddddd',
          },
          baselineColor: '#dddddd',
          viewWindowMode: 'explicit',
          viewWindow: {
            min: 0,
          },
        },
        height: 300,
        width: '100%',
        series,
      };

      if (cols.length > 2) {
        that.chart = new window.google.visualization.LineChart(document.getElementById('statistics-line-chart'));
      } else {
        that.chart = new window.google.visualization.AreaChart(document.getElementById('statistics-line-chart'));
      }

      that.chart.draw(chartData, options);
      if (typeof resize === 'function') {
        resize();
      }

      window.crakrevenue.resizingFnStack.graphresize = () => {
        setTimeout(() => {
          that.chart.draw(chartData, options);

          if (typeof resize === 'function') {
            resize();
          }
        }, 200);
      };
    } else if ('container' in that.chart) {
      that.chart.clearChart();
      this.setState({
        hasNoStats: true,
      });
    }
  }

  render() {
    const { className } = this.props;
    const { hasNoStats } = this.state;

    return (
      <div className={className} id="statistics-line-chart-wrapper">
        <div className={hasNoStats ? undefined : 'hidden'} id="no-stats">
          <FormattedMessage {...messages.genericTextNoResults} />
        </div>
        <div className={className} id="statistics-line-chart" />
      </div>
    );
  }
}

LineChart.propTypes = {
  className: PropTypes.string,
  graphFields: PropTypes.array.isRequired,
  graphWidth: PropTypes.number,
  intl: intlShape.isRequired,
  resize: PropTypes.func,
  skinConfigurations: PropTypes.object.isRequired,
  statisticsGraphData: PropTypes.object,
  withLegend: PropTypes.bool,
};

export default injectIntl(
  connect((state) => ({
    skinConfigurations: state.skinConfigurations.data,
  }))(LineChart)
);

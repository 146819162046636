import config from '../config';

const { BILLING_COUNTRIES, PROFILE_COUNTRIES, SIGNUP_COUNTRIES } = config;

export default class ExcludedCountriesHelper {
  static getBillingAchExcludedCountries() {
    return BILLING_COUNTRIES.payment_methods.ach.excluded_countries;
  }

  static getBillingWireExcludedCountries() {
    return BILLING_COUNTRIES.payment_methods.wire.excluded_countries;
  }

  static getBillingCheckExcludedCountries() {
    return BILLING_COUNTRIES.payment_methods.check.excluded_countries;
  }

  static getSignUpExcludeCountries() {
    return SIGNUP_COUNTRIES.excluded_countries;
  }

  static getProfileExcludeCountries() {
    return PROFILE_COUNTRIES.excluded_countries;
  }

  static getFilteredCountries(countries, excludeCountries) {
    if (!excludeCountries) {
      return null;
    }

    let filteredCountries = [];
    filteredCountries = countries.filter(
      (country) => !excludeCountries.find((exlude) => exlude.code.toUpperCase() === country.code.toUpperCase())
    );
    return filteredCountries;
  }

  static isFilteredCountry(country, countries, excludeCountries) {
    let isFilteredCountry = false;

    if (!country || !countries || !excludeCountries) {
      return isFilteredCountry;
    }

    isFilteredCountry = excludeCountries.some((c) => c.code.toUpperCase() === country.toUpperCase());

    return isFilteredCountry ? null : country;
  }
}

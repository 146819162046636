import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFlagUrl } from '../../../components/reusables/reusablesHelpers/helpers';
import _ from 'lodash';

import { InputText, Scroller } from '../../../components/reusables';
import { reduxForm } from 'redux-form';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import { useCallback } from 'react';

const OfferCountriesModal = (props) => {
  const { dispatch, values, fields, modalInner, areCountriesExcluded } = props;

  const search = values.modalSearch;
  const [countries, setCountries] = useState({});
  const [colCount, setColCount] = useState(0);
  const [found, setFound] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);

  const buildCountries = useCallback(
    (props) => {
      const { countries } = props;
      const formattedCountries = {};
      const maxItems = 10;
      let colCount = 0;
      let i = 0;
      let found = false;

      _.sortBy(countries, 'name').map((country) => {
        if (typeof country.name[0] === 'undefined') {
          return;
        }
        if (!!search && !new RegExp(search, 'i').test(country.name)) {
          return;
        }
        if (!!selectedLetter && !country.name.toLowerCase().startsWith(selectedLetter)) {
          return;
        }

        const currentLetter = country.name[0];

        if (!(formattedCountries[currentLetter] instanceof Array)) {
          formattedCountries[currentLetter] = [];
          i = 0;
        }

        if (!(formattedCountries[currentLetter][Math.floor(i / maxItems)] instanceof Array)) {
          formattedCountries[currentLetter][Math.floor(i / maxItems)] = [];
          colCount++;
        }

        formattedCountries[currentLetter][Math.floor(i / maxItems)].push(country);
        i++;
        formattedCountries[currentLetter].count = i;
        found = true;
      });

      setCountries(formattedCountries);
      setColCount(colCount);
      setFound(found);
    },
    [search, selectedLetter]
  );

  useEffect(() => {
    buildCountries(props);
  }, [buildCountries, props]);

  useEffect(() => {
    buildCountries(props);
  }, [props, selectedLetter, buildCountries]);

  const handleLetterSelect = (letter) => {
    if (selectedLetter === letter) {
      setSelectedLetter(null);
    } else {
      setSelectedLetter(letter);
    }
  };

  const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const countriesFirstLetters = _.uniq(Object.keys(countries).map((country) => country[0].toLowerCase()));
  const colWidth = 250;
  const padding = 20;
  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="offer-countries-modal">
      <div className="modal-content">
        <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
          <i className="material-icons">close</i>
        </span>
        <div className="row search-container">
          <InputText
            className="col-md-6"
            field={fields.modalSearch}
            id="filter-search"
            placeholder="Search"
            prefixIcon="&#xE8B6;"
            type="text"
          />
        </div>
      </div>
      <Scroller>
        <ul className="list-letter clearfix">
          {alphabet.map((listLetter) => {
            const isAvailable = countriesFirstLetters.indexOf(listLetter) > -1;
            return (
              <li
                className={`letter ${isAvailable ? 'basic-colors' : 'unavailable'}`}
                key={listLetter}
                onClick={() => {
                  if (isAvailable) {
                    handleLetterSelect(listLetter);
                  }
                }}
              >
                {listLetter}
              </li>
            );
          })}
        </ul>
      </Scroller>

      <Scroller classNames={['list-countries-by-letter-container']}>
        <div
          style={{
            width: colWidth * colCount + padding * colCount,
          }}
        >
          <ul className="list-countries-by-letter clearfix">
            {Object.keys(countries).map((letter) => (
              <li
                key={letter}
                style={{
                  width: colWidth * countries[letter].length + padding * countries[letter].length,
                }}
              >
                <div className="letter">
                  {letter} ({countries[letter].count})
                </div>
                {countries[letter].map((countriesList, index) => (
                  <ul
                    className="list-countries"
                    key={index}
                    style={{
                      width: colWidth,
                      paddingRight: padding,
                    }}
                  >
                    {countriesList.map((country) => (
                      <li key={country.code}>
                        <img alt={country.name} height="24" src={getFlagUrl(country.code)} width="24" />
                        {country.name}
                      </li>
                    ))}
                  </ul>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </Scroller>

      {!found && <div>No countries</div>}
    </div>
  );
};

OfferCountriesModal.propTypes = {
  areCountriesExcluded: PropTypes.bool,
  countries: PropTypes.array.isRequired,
};

export default reduxForm({
  form: 'modalSearch',
  fields: ['modalSearch'],
})(OfferCountriesModal);

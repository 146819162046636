import frLocaleData from 'react-intl/locale-data/fr';
import { defineMessages, addLocaleData } from 'react-intl';

import genericTexts from './en/genericTexts';
import loginPage from './en/loginPage';
import MainNavigation from './en/mainNavigation';
import profilePage from './en/profilePage';
import surveyGeneratorPage from './en/surveyGeneratorPage';
import LiveCamWidgetPage from './en/LiveCamWidgetPage';
import offerPage from './en/offerPage';
import statisticsPage from './en/statisticsPage';
import passwordResetPage from './en/passwordResetPage';
import homePage from './en/homePage';
import signupQuestionsModal from './en/signupQuestionsModal';
import simplifiedRegistration from './en/simplifiedRegistration';
import smartLinkPage from './en/smartLinkPage';
import WidgetSection from './en/WidgetSection';
import decemberChallengePage from './en/decemberChallengePage';
import marchChallengePage from './en/marchChallengePage';

import { labelMessagesObj as countries } from '../utils/list-options/countries';
import { labelMessagesObj as provinces } from '../utils/list-options/provinces';
import { labelMessagesObj as states } from '../utils/list-options/states';
import { labelMessagesObj as timezones } from '../utils/list-options/timezones';

addLocaleData(frLocaleData);

/**
 * Défini tous les messages de l'application pour la traduction multi-langue avec React-intl
 */
const messages = defineMessages({
  ...genericTexts,
  ...loginPage,
  ...MainNavigation,
  ...profilePage,
  ...offerPage,
  ...statisticsPage,
  ...passwordResetPage,
  ...countries,
  ...provinces,
  ...states,
  ...timezones,
  ...homePage,
  ...surveyGeneratorPage,
  ...LiveCamWidgetPage,
  ...WidgetSection,
  ...signupQuestionsModal,
  ...simplifiedRegistration,
  ...smartLinkPage,
  ...decemberChallengePage,
  ...marchChallengePage,
});

export default messages;

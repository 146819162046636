import PropTypes from 'prop-types';

import Video from '../svg/Video.react';
import { openModal } from '../../Modal/modal-actions/modal-actions';

const VideoModalIcon = ({ className, videoUrl, gtmEvent, dispatch }) => {
  const handleModalClick = () => {
    dispatch(
      openModal({
        name: 'VideoModal',
        modalProps: {
          dispatch,
          videoUrl,
          className: 'video-modal',
        },
      })
    );
  };
  return (
    <span
      className={`videoModalIcon ${className}`}
      onClick={() => {
        if (gtmEvent) {
          gtmEvent();
        }
        handleModalClick();
      }}
    >
      <Video />
    </span>
  );
};

VideoModalIcon.propTypes = {
  className: PropTypes.string,
  videoUrl: PropTypes.any.isRequired,
};

export default VideoModalIcon;

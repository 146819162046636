import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputMask from 'react-input-mask';

import messages from '../../i18n/base-en.js';
import { LoadingRing } from './LoadingRing.react.js';
class InputText extends Component {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      disabled,
      field: { dirty, error, touched, value },
      label,
      labelString,
      isLoading,
    } = this.props;

    return (
      disabled !== nextProps.disabled ||
      label !== nextProps.label ||
      labelString !== nextProps.labelString ||
      (!!nextProps.field &&
        (dirty !== nextProps.field.dirty ||
          error !== nextProps.field.error ||
          touched !== nextProps.field.touched ||
          value !== nextProps.field.value)) ||
      isLoading !== nextProps.isLoading
    );
  }

  renderLabel(hasValue) {
    const { id, isMandatory, label, labelClasses, optional, placeholder, preLabel, labelString, dataCy } = this.props;

    const classes = [labelClasses, preLabel ? 'pre-label' : undefined, !!placeholder || hasValue ? 'active' : undefined];

    return (
      <label className={classes.join(' ')} data-cy={`${dataCy ? dataCy : `inputTextLabel-${id}`}`} htmlFor={id}>
        {!label && labelString ? <span>{labelString}</span> : <FormattedMessage {...label} />}
        {isMandatory ? (
          <FormattedMessage {...messages.genericTextMandatoryInput} />
        ) : (
          optional && <FormattedMessage {...messages.genericTextOptionalInput} />
        )}
      </label>
    );
  }

  handleBlur() {
    const { dontBlurIfPristine, field, onBlur, gtmEvent } = this.props;

    const blurHandler = onBlur || field.onBlur;

    if (gtmEvent) {
      gtmEvent();
    }

    if (!dontBlurIfPristine || !field.pristine) {
      blurHandler();
    }
  }

  render() {
    const {
      autoComplete,
      autoFocus,
      category,
      className,
      compare,
      dataCy,
      disabled,
      displayErrorInstantly,
      field,
      filtername,
      hideError = false,
      id,
      isLoading,
      label,
      max,
      maxLength,
      min,
      onFocus,
      onKeyUp,
      pattern,
      placeholder,
      prefixIcon,
      preLabel,
      tabIndex,
      type,
      labelString,
      mask,
      onChange,
    } = this.props;

    // field.value peut être un integer donc le length ne fonctionnera pas
    // l'integer peut être 0 donc vérifier seulement field.value ne passera pas le test
    const fieldValueExists = field.value !== undefined && field.value !== null;
    const hasValue = fieldValueExists && !!field.value && field.value.toString().length >= 1;
    const dataCyLabel = dataCy ? dataCy : `inputText-${id}`;

    const value = fieldValueExists ? field.value : '';

    const fieldClasses = [
      'input-field',
      className ? className : undefined,
      !!field && field.error && (field.touched || displayErrorInstantly) && !hideError ? '' : 'valid',
      type === 'hidden' ? 'hidden' : undefined,
    ];

    const InputToUse = mask ? (
      <InputMask
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        checked={field.checked}
        className={hasValue ? 'hasValue' : undefined}
        data-category={category}
        data-compare={compare}
        data-cy={dataCyLabel}
        data-filtername={filtername}
        disabled={disabled}
        id={id}
        mask={mask}
        max={max}
        min={min}
        name={field.name}
        onBlur={this.handleBlur}
        onChange={onChange || field.onChange}
        onDragStart={field.onDragStart}
        onDrop={field.onDrop}
        onFocus={onFocus || field.onFocus}
        onKeyUp={onKeyUp}
        pattern={pattern}
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
    ) : (
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        checked={field.checked}
        className={hasValue ? 'hasValue' : undefined}
        data-category={category}
        data-compare={compare}
        data-cy={dataCyLabel}
        data-filtername={filtername}
        disabled={disabled}
        id={id}
        max={max}
        maxLength={maxLength}
        min={min}
        name={field.name}
        onBlur={this.handleBlur}
        onChange={onChange || field.onChange}
        onDragStart={field.onDragStart}
        onDrop={field.onDrop}
        onFocus={onFocus || field.onFocus}
        onKeyUp={onKeyUp}
        pattern={pattern}
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
    );

    return (
      <div className={fieldClasses.join(' ')}>
        {!!prefixIcon && <i className="material-icons prefix">{prefixIcon}</i>}
        {(label || labelString) && preLabel ? this.renderLabel(hasValue) : null}
        <div className={isLoading ? 'input-loader-container' : ''}>
          {InputToUse}
          {isLoading ? <LoadingRing /> : null}
        </div>
        {(label || labelString) && !preLabel ? this.renderLabel(hasValue) : null}
        {field && (field.touched || displayErrorInstantly) && field.error && !hideError ? (
          <div className="field-error" data-cy={`error-inputText-${id}`}>
            {field.error}
          </div>
        ) : null}
      </div>
    );
  }
}

InputText.defaultProps = {
  autoFocus: false,
  disabled: false,
  dontBlurIfPristine: true,
  isMandatory: false,
  mask: undefined,
};

InputText.propTypes = {
  autoComplete: PropTypes.any,
  autoFocus: PropTypes.bool,
  category: PropTypes.string,
  className: PropTypes.string,
  compare: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  displayErrorInstantly: PropTypes.bool,
  dontBlurIfPristine: PropTypes.bool,
  field: PropTypes.object,
  filtername: PropTypes.string,
  hideError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isMandatory: PropTypes.bool,
  label: PropTypes.object,
  labelClasses: PropTypes.string,
  labelString: PropTypes.string,
  mask: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  optional: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  preLabel: PropTypes.bool,
  prefixIcon: PropTypes.string,
  tabIndex: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default injectIntl(InputText);

export default {
  passwordResetTitle: {
    id: 'passwordReset.title',
    description: 'Password reset',
    defaultMessage: 'Password reset',
  },
  passwordResetChangeButton: {
    id: 'passwordReset.changeButton',
    description: 'Change password',
    defaultMessage: 'Change password',
  },
  passwordResetSendLink: {
    id: 'passwordReset.sendLink',
    description: 'Send link',
    defaultMessage: 'Send link',
  },
  passwordResetConfirmation: {
    id: 'passwordReset.confirmation',
    description: 'If this email is associated with an active account, you will receive an email within the next 5 minutes.',
    defaultMessage: 'If this email is associated with an active account, you will receive an email within the next 5 minutes',
  },
};

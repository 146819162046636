import PropTypes from 'prop-types';

const WarningIcon = ({ color }) => (
  <svg
    height="30px"
    style={{ left: '0' }}
    viewBox="0 0 32 30"
    width="32px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Warning Icon</title>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={color} transform="translate(-253.000000, -709.000000)">
        <g transform="translate(216.000000, 194.000000)">
          <path d="M55.3154096,522.859723 L54.1952364,532.810615 C54.1201732,533.52733 53.652472,533.973772 53,533.973772 C52.347528,533.973772 51.8798268,533.521456 51.8047636,532.810615 L50.6845904,522.853849 C50.6095272,522.084325 51.0195453,521.502776 51.7239264,521.502776 L54.2702418,521.502776 C54.9747384,521.50865 55.3904728,522.090199 55.3154096,522.859723 L55.3154096,522.859723 Z M55.2634428,538.026933 C55.2634428,539.301583 54.2991122,540.276764 53,540.276764 C51.70083,540.276764 50.7365572,539.301583 50.7365572,538.026933 L50.7365572,537.974124 C50.7365572,536.705231 51.70083,535.724234 53,535.724234 C54.2991122,535.724234 55.2634428,536.699416 55.2634428,537.974124 L55.2634428,538.026933 Z M68.0934681,536.223544 L57.5846265,517.696273 C56.6376182,516.027991 54.888127,515 52.9941682,515 C51.1002671,515 49.3507182,516.027991 48.4037676,517.696273 L37.7102129,536.540752 C36.7632624,538.209093 36.7632624,540.265075 37.7102129,541.933357 C38.6571635,543.601639 40.4067124,544.62963 42.3006135,544.62963 L63.6878383,544.62963 L63.6993865,544.62963 C66.6267918,544.62963 69,542.215261 69,539.237084 C69,538.12098 68.6651029,537.081241 68.0934681,536.223544 L68.0934681,536.223544 Z" />
        </g>
      </g>
    </g>
  </svg>
);

WarningIcon.defaultProps = {
  color: '#F40000',
};

WarningIcon.propTypes = {
  color: PropTypes.string,
};

export default WarningIcon;

import { useState, useEffect, useCallback, useRef } from 'react';
import HomePromotionSlide from './HomePromotionSlide.react';
import { HomePromotionArrow } from './HomePromotionArrow.react';
import gtm, { Gtm } from '../../utils/gtm';
const SLIDE_DIRECTIONS = {
  LEFT: 'slideLeft',
  RIGHT: 'slideRight',
};

const HomePromotion = ({ content }) => {
  const touchXStartRef = useRef(0);
  const startSliderTimeoutRef = useRef(null);
  const startSliderHoverTimeoutRef = useRef(null);

  const [slider, setSlides] = useState({
    active: 0,
    next: null,
    transition: false,
    direction: SLIDE_DIRECTIONS.LEFT,
  });

  const { active, next, transition } = slider;

  const totalSlides = content?.length || 0;
  const totalSlideIndexes = totalSlides - 1;

  const clearSliderTimeout = () => {
    if (startSliderTimeoutRef.current) {
      clearTimeout(startSliderTimeoutRef.current);
    }
    if (startSliderHoverTimeoutRef.current) {
      clearTimeout(startSliderHoverTimeoutRef.current);
    }
  };

  const startSlide = useCallback(
    (previous) => {
      if (transition) {
        return;
      }

      clearSliderTimeout();

      let next = active + 1;

      if (next > totalSlideIndexes) {
        next = 0;
      }

      if (previous) {
        next = active - 1;

        if (next < 0) {
          next = totalSlideIndexes;
        }
      }

      setSlides((slides) => ({
        ...slides,
        next,
        direction: previous ? SLIDE_DIRECTIONS.RIGHT : SLIDE_DIRECTIONS.LEFT,
      }));
    },
    [transition, active, setSlides, totalSlideIndexes, clearSliderTimeout]
  );

  useEffect(() => {
    let timeout;

    if (next === 0 || next) {
      timeout = setTimeout(() => {
        setSlides((slides) => ({
          ...slides,
          transition: true,
        }));
      }, 10);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [next]);

  useEffect(() => {
    let timeout;

    if (transition) {
      timeout = setTimeout(() => {
        setSlides((slides) => {
          const { next } = slides;

          return {
            ...slides,
            active: next,
            next: null,
            transition: false,
          };
        });
      }, 301);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [transition]);

  const onHover = (event) => {
    const touchXStart = event.changedTouches?.[0]?.pageX;

    if (touchXStart) {
      touchXStartRef.current = touchXStart;
    }

    clearSliderTimeout();
  };

  const onHoverOut = (event) => {
    if (!totalSlides) {
      return;
    }

    if (event.changedTouches?.length) {
      const swipeThreshold = 30;
      const touchXEnd = event.changedTouches?.[0]?.pageX;
      const touchDistance = touchXStartRef.current - touchXEnd;

      if (touchDistance > swipeThreshold) {
        startSlide(false);
      } else if (touchDistance < swipeThreshold * -1) {
        startSlide(true);
      }
    } else {
      startSliderHoverTimeoutRef.current = setTimeout(() => {
        startSlide();
      }, 7000);
    }
  };

  useEffect(() => {
    if (!transition) {
      startSliderTimeoutRef.current = setTimeout(() => {
        startSlide(false);
      }, 10000);
    }

    return () => {
      clearSliderTimeout();
    };
  }, [transition, startSlide]);

  return (
    <div id="home-promotion">
      <div
        className="home-promotion-slides"
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
        onTouchEnd={onHoverOut}
        onTouchStart={onHover}
      >
        {totalSlides ? (
          <>
            <HomePromotionArrow
              onClick={() => {
                Gtm.newEvent('dashboard_carrousel_arrows', false, 'click', 'string', 'left');
                startSlide(true);
              }}
              totalSlides={totalSlides}
            />
            {content?.map((slide, index) => (
              <HomePromotionSlide content={slide} key={index} {...slider} index={index} />
            ))}
            <HomePromotionArrow
              onClick={() => {
                startSlide(false);
                Gtm.newEvent('dashboard_carrousel_arrows', false, 'click', 'string', 'right');
              }}
              right
              totalSlides={totalSlides}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {totalSlides > 1 ? (
        <div className="dots">
          {[...Array(totalSlides).keys()].map((index) => (
            <div className={`dot ${active === index ? 'active' : ''}`} key={index} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HomePromotion;

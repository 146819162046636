export default (values) => {
  const errors = {};

  // max cam numbers
  if (values.rows && values.columns && parseInt(values.rows, 10) * parseInt(values.columns, 10) > 50) {
    errors.rows = true;
    errors.columns = 'Only a maximum of 50 cams can be shown.';
  }

  return errors;
};

import PropTypes from 'prop-types';

import { Payout, Scroller } from '../../../components/reusables';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';

const OfferPayoutTiersModal = (props) => {
  const { colCount, goals, payouts, modalInner, dispatch } = props;

  const getPayoutTitle = (goals, tiersTitleTagContainer) => {
    const title = {};
    title.title = tiersTitleTagContainer.tiersTitleTag;

    const theGoal = goals.find((goal) => tiersTitleTagContainer.tiersTitleTag === goal.name);
    if (theGoal) {
      title.payout = (
        <Payout defaultPayout={theGoal.default_payout} payoutType={theGoal.payout_type} percentPayout={theGoal.percent_payout} />
      );
    } else {
      title.payout = tiersTitleTagContainer.tiersTitlePayout;
    }

    if (tiersTitleTagContainer.tiersTitleDevice) {
      title.title += ` - ${tiersTitleTagContainer.tiersTitleDevice}`;
    }

    return title;
  };

  const colWidth = 250;
  const padding = 20;

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="offer-payout-tiers-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <Scroller classNames={['list-countries-by-letter-container']}>
        <div style={{ width: colWidth * colCount + colCount * padding }}>
          <ul className="list-countries-by-letter clearfix">
            {payouts.map((payout, payoutIndex) => {
              const title = getPayoutTitle(goals, payout);

              return (
                <li
                  key={`payout-${payoutIndex}`}
                  style={{
                    width: colWidth * payout.countries.length + payout.countries.length * padding,
                  }}
                >
                  {payout.countries.length > 0 && (
                    <div className="letter">
                      {title.title}
                      {title.payout ? [' - ', title.payout] : ''}
                    </div>
                  )}
                  {payout.countries.map((countriesList, countriesListIndex) => (
                    <ul
                      className="list-countries"
                      key={`payout-${payoutIndex}-${countriesListIndex}`}
                      style={{
                        width: colWidth,
                        paddingRight: padding,
                      }}
                    >
                      {countriesList.map((country, countryIndex) => (
                        <li key={`payout-${payoutIndex}-${countriesListIndex}-${countryIndex}`}>{country}</li>
                      ))}
                    </ul>
                  ))}
                </li>
              );
            })}
          </ul>
        </div>
      </Scroller>
    </div>
  );
};

OfferPayoutTiersModal.propTypes = {
  colCount: PropTypes.number.isRequired,
  goals: PropTypes.array.isRequired,
  payouts: PropTypes.array.isRequired,
};

export default OfferPayoutTiersModal;

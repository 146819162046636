import ValidatedRound from '../../reusables/svg/ValidatedRound.react';
import { connect } from 'react-redux';
import OffersImage from '../../Offers/OffersImage.react';
import { CountryList, Payout, PayoutType } from '../../reusables';
import messages from '../../../i18n/base-en';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import WarningIcon from '../../reusables/svg/WarningIcon.react';
import PromoVideo from '../promoToolVideo/promoVideo.react';
import { Gtm } from '../../../utils/gtm';
import { getCTALink, getCTAText } from '../helpers';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import { USE_VAST_PREROLL } from '../../../pages/vast-pre-roll/values';

const PromoToolModal = ({ promoTool, allOffers, affUserInfos, modalInner, dispatch }) => {
  const offersPromote = [];

  if (promoTool.offers) {
    allOffers.map((offer) => {
      promoTool.offers.map((id) => {
        id.toString() === offer.id && offersPromote.push(offer);
      });
    });
  }

  const openAndPopulateIntercomMessage = () =>
    window.Intercom(
      'showNewMessage',
      `Subject: ${affUserInfos.first_name} ${affUserInfos.last_name} (${affUserInfos.affiliate_id}) request for ${promoTool.title}\nAffiliate: ${affUserInfos.first_name} ${affUserInfos.last_name} (${affUserInfos.affiliate_id})\nTool: ${promoTool.title}\nPlease provide as much information as possible on how you will use this Promo Tool:`
    );
  let ctaLink = getCTALink(promoTool);
  let ctaTarget = '_blank';

  if (promoTool.title === 'Pre-Roll Ads' && USE_VAST_PREROLL) {
    ctaLink = '/vast-pre-roll';
    ctaTarget = '_self';
  }

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <>
      <div className="promo-tool-modal">
        <div className="header-box">
          <div className="icon-box">
            <img alt={promoTool.title} src={`${promoTool.icon_url}`} />
          </div>
          <div className="header">
            <h2 className="header-title">{promoTool.title}</h2>
            <p>{promoTool.header_text}</p>
          </div>
        </div>

        {promoTool.modal_type === '1' && promoTool.video_title && promoTool.video_url ? (
          <div className="video-box">
            <h2 className="video-title">{promoTool.video_title}</h2>
            <PromoVideo height="50%" src={promoTool.video_url} width="100%" />
          </div>
        ) : null}

        <div className="advantages">
          <h2>Advantages</h2>
          <ul>
            {promoTool.advantages.map((advantage, key) => (
              <li key={key}>
                {' '}
                <ValidatedRound />
                {advantage}
              </li>
            ))}
          </ul>
        </div>

        <div className="description">
          <h2>Description</h2>
          <p>{promoTool.description}</p>
        </div>

        {promoTool.modal_type === '2' && (
          <div className="offers-box">
            <h2>{promoTool.offers_title}</h2>
            {offersPromote.map((offer) => {
              const ct = offer.conversion_types.map((ct) => ct.name === 'Multi-CPA');

              return (
                <Link
                  key={offer.id}
                  onClick={() => {
                    Gtm.event('promotools', 'Click', `${promoTool.title} select ${offer.id}`);
                    closeOpenModal();
                  }}
                  to={`/offers/${offer.id}`}
                >
                  <div className="offer">
                    <OffersImage alt={offer.name} height="50px" thumbnail={offer.thumbnail} width="50px" />
                    <div className="offer-details">
                      <span className="offer-name">{offer.name}</span>
                      {offer.top_countries ? (
                        <CountryList list={offer.top_countries} />
                      ) : (
                        <span className="country-list">
                          <FormattedMessage {...messages.notAvailable} />
                        </span>
                      )}
                      <p className="offer-info">
                        <PayoutType categories={offer.conversion_types.map((ct) => ct.name)} />
                        {offer.epc_affiliation ? (
                          <>
                            <span> · EPC: </span>
                            <FormattedNumber
                              currency="USD"
                              minimumFractionDigits={4}
                              style="currency"
                              value={parseFloat(offer.epc_affiliation || 0)}
                            />
                          </>
                        ) : (
                          <span className="no-epc">
                            <span> · </span>
                            <FormattedMessage {...messages.notAvailable} />
                          </span>
                        )}
                        <span className="separation">|</span>
                        Vertical :{' '}
                        {offer.verticals.length > 0 ? (
                          offer.verticals.map((ver) => ver.name).join(', ')
                        ) : (
                          <FormattedMessage {...messages.notAvailable} />
                        )}
                        <span className="separation">|</span>
                        ID : {offer.id}
                      </p>
                    </div>
                    {ct[0] ? (
                      <span className="payout">
                        <span>Multi</span>
                      </span>
                    ) : (
                      <Payout
                        conversionTypes={offer.conversion_types.map((ct) => ct.name)}
                        defaultPayout={offer.default_payout}
                        payoutType={offer.payout_type}
                        percentPayout={offer.percent_payout}
                      />
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        {promoTool.modal_type === '3' && (
          <div className="warning">
            <div className="icon-warning">
              <WarningIcon color="#f2c157" />
            </div>
            <p>
              You need to get approved to access this tool. To do so, please contact your Affiliate Manager or our Support Team
              using the button below. We will get back to you shortly.
            </p>
          </div>
        )}
      </div>

      <div className="control-box">
        <button className="waves-effect waves-light btn-flat btn-large primary-border-color" onClick={() => closeOpenModal()}>
          cancel
        </button>
        {promoTool.modal_type !== '3' ? (
          <Link
            className="waves-effect waves-light btn-large"
            onClick={() => {
              Gtm.event('promotools', 'Click', `${promoTool.title} CTA`);
              closeOpenModal();
            }}
            to={getCTALink(promoTool)}
          >
            {getCTAText(promoTool)}
          </Link>
        ) : promoTool.title === 'Pre-Roll Ads' && USE_VAST_PREROLL ? (
          <a
            className="waves-effect waves-light btn-large"
            href={ctaLink}
            onClick={() => {
              Gtm.event('promotools', 'Click', `${promoTool.title} CTA`);
            }}
            rel="noreferrer"
            target={ctaTarget}
          >
            {getCTAText(promoTool)}
          </a>
        ) : (
          <button
            className="waves-effect waves-light btn-large"
            onClick={() => {
              openAndPopulateIntercomMessage();
              Gtm.event('promotools', 'Click', `${promoTool.title} CTA`);
            }}
            type="button"
          >
            {getCTAText(promoTool)}
          </button>
        )}
      </div>
    </>
  );
};

export default connect((state) => ({
  allOffers: state.application.lists.allOffers,
}))(PromoToolModal);

import config from '../../../config';

export default class HasEarnedRevenuesService {
  static hasEarnedRevenues(referrals, payoutTotal, accountType) {
    let referralAmount = 0;
    referrals.map((referral) => {
      referralAmount += parseInt(referral?.Stat?.amount);
    });
    const totalAmount = referralAmount + (payoutTotal ? parseInt(payoutTotal) : 0);
    if (totalAmount >= config.MINIMUM_AMOUNT_FOR_IDENTITY_VALIDATION || !!accountType) {
      return true;
    }
    return false;
  }
}

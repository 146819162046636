import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';
import { CustomPicker } from '../../../components/reusables/colorpicker/CustomColorPicker.react.js';
import consts from '../../../utils/consts';
import { Gtm } from '../../../utils/gtm.js';
import { connect } from 'react-redux';

import 'materialize-css';
import { Select } from 'react-materialize';
const {
  LIVECAM: { googleFonts },
} = consts;
const { genericTextPx, LiveCamWidgetPage } = messages;

const LayoutSettingsLayout = ({ disabled, infobarSettings, fields: { fontColor, fontFamily, fontSize } }) => {
  const [color, setColor] = useState('#aabbcc');
  const concurrentCalls = (e) => {
    fontColor.onChange(e);
    setColor(e);
  };
  const handleGtmEvent = (e) => {
    fontFamily.onChange(e.target.value);
    Gtm.event('live cam widget', 'Click', `font dropdown - ${e.target.value}`);
  };

  return (
    <div className={`settings-group ${disabled ? 'disabled' : ''}`}>
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.infobarSettings.fontSection.title.text} />
      </h2>

      <Select
        disabled={disabled}
        id="widget-font-size"
        onChange={(e) => {
          handleGtmEvent(e);
        }}
        value={fontFamily.value}
      >
        <optgroup label="Common Fonts">
          <option value="Arial">Arial</option>
          <option value="Courier">Courier</option>
          <option value="Georgia">Georgia</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Verdana">Verdana</option>
        </optgroup>
        <optgroup label="Google Fonts">
          {googleFonts.map((font) => (
            <option key={font} value={font}>
              {font}
            </option>
          ))}
        </optgroup>
      </Select>

      <div>
        <div className="with-units">
          <InputText
            disabled={disabled}
            field={fontSize}
            id="widget-font-size"
            label={LiveCamWidgetPage.infobarSettings.fontSection.fontSize.label}
            labelClasses="active"
            max={9999}
            min={0}
            type="number"
          />

          <span className="unit-label">
            <FormattedMessage {...genericTextPx} />
          </span>
        </div>
      </div>

      <CustomPicker
        color={color}
        disabled={disabled}
        field={fontColor}
        handleChange={setColor}
        id="widget-font-color"
        input
        inputOnChange={(e) => concurrentCalls(e.target.value)}
        label={LiveCamWidgetPage.infobarSettings.fontSection.fontColor.label}
        labelClasses="active"
        onChange={(e) => concurrentCalls(e)}
      />
    </div>
  );
};

LayoutSettingsLayout.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    fontColor: PropTypes.object,
    fontFamily: PropTypes.object,
    fontSize: PropTypes.object,
  }),
};

export default connect((state) => ({
  infobarSettings: state.form.infobarSettingsLC,
}))(injectIntl(LayoutSettingsLayout));

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import consts from '../../../utils/consts';
const {
  DRILLER_CATEGORIES: { VERTICAL },
} = consts;

const getFlag = (groupNames, groupNameToSearch) => groupNames.some((groupName) => groupName === groupNameToSearch);
const getFlags = (groupNames) => ({
  categoryExist: getFlag(groupNames, 'Category.name'),
  countryExist: getFlag(groupNames, 'Country.name'),
  offerExist: getFlag(groupNames, 'Offer.name'),
  offerUrlExist: getFlag(groupNames, 'OfferUrl.name'),
});

const StatisticsDriller = ({
  handleClick,
  id,
  intl,
  statisticsDrillDownFilters,
  statisticsDrillDownGroups,
  statisticsField,
  statisticsValue,
}) => {
  const { categoryExist, countryExist, offerExist, offerUrlExist } = getFlags(statisticsDrillDownGroups);

  return [!categoryExist, !countryExist, !offerExist, !offerUrlExist].some((flag) => flag) ? (
    <div className="driller">
      <i className="material-icons driller-button primary-color" data-activates={id} data-beloworigin="true">
        &#xE619;
      </i>
      <ul className="dropdown-content" id={id}>
        {(!categoryExist ||
          !Object.prototype.hasOwnProperty.call(statisticsDrillDownFilters, 'Category.name') ||
          !Object.prototype.hasOwnProperty.call(statisticsDrillDownFilters['Category.name'], 'values')) && (
          <li>
            <a
              data-field={statisticsField}
              data-filter={VERTICAL}
              data-group="Category.name"
              data-value={statisticsValue}
              href="#"
              onClick={handleClick}
            >
              {intl.formatMessage(messages.genericTextVertical)}
            </a>
          </li>
        )}
        {!countryExist && (
          <li>
            <a data-field={statisticsField} data-group="Country.name" data-value={statisticsValue} href="#" onClick={handleClick}>
              {intl.formatMessage(messages.genericTextCountry)}
            </a>
          </li>
        )}
        {!offerExist && (
          <li>
            <a data-field={statisticsField} data-group="Offer.name" data-value={statisticsValue} href="#" onClick={handleClick}>
              {intl.formatMessage(messages.genericTextOffers)}
            </a>
          </li>
        )}
        {!offerUrlExist && (
          <li>
            <a
              data-field={statisticsField}
              data-group="OfferUrl.name"
              data-value={statisticsValue}
              href="#"
              onClick={handleClick}
            >
              {intl.formatMessage(messages.statisticsLandingPage)}
            </a>
          </li>
        )}
      </ul>
    </div>
  ) : null;
};

StatisticsDriller.defaultProps = {
  statisticsDrillDownGroups: [],
};

StatisticsDriller.propTypes = {
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  statisticsDrillDownFilters: PropTypes.object.isRequired,
  statisticsDrillDownGroups: PropTypes.array,
  statisticsField: PropTypes.string.isRequired,
  statisticsValue: PropTypes.string.isRequired,
};

export default injectIntl(StatisticsDriller);

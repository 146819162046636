import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Select } from 'react-materialize';
import 'materialize-css';
import { useRef } from 'react';

const SelectMulti = ({
  children,
  field,
  id,
  label,
  tabIndex,
  value,
  className,
  canSearch,
  afterLabelComp,
  icon,
  maxSelected,
  handleGtmEvent,
  disabled,
  filterName,
  filterCategory,
}) => {
  const totalCheckedRef = useRef(null);
  const maxCountRef = useRef(null);

  field = field || {};
  return (
    <Select
      className={className}
      data-category={filterCategory}
      data-filtername={filterName}
      disabled={disabled}
      browserDefault={false}
      noLayout={true}
      icon={icon}
      id={id}
      label={
        <>
          <span className="labelText">
            {label}
            {afterLabelComp}
          </span>
          {maxSelected && (
            <div className="maxCount" ref={maxCountRef}>
              <span ref={totalCheckedRef}>0</span>/{maxSelected} Selected
            </div>
          )}
        </>
      }
      maxSelected={maxSelected}
      multiple
      options={{
        dropdownOptions: {
          onOpenStart: (element) => {
            const select = element.parentElement;
            const ul = select.querySelector('ul');
            let searchInput = ul.querySelector('.search-select');
            const focusSearch = () =>
              setTimeout(() => {
                searchInput.focus();
              }, 300);
            if (canSearch) {
              //apply extra functionality if select element has search ability
              const searchDiv = document.createElement('div');
              //create search input
              searchDiv.innerHTML = `<div class="search-container" style="display:block;">
                  <i class="material-icons prefix">&#xE8B6;</i>
                  <input type="text" class="search-select" value="" placeholder="Search">
                </div>`;
              searchInput = searchDiv.querySelector('input');
              ul.insertBefore(searchDiv.childNodes[0], ul.childNodes[0]); //insert search input to select element
              const onSearchHandler = (e) => {
                let key = e.key;
                if (e.keyCode === 8 && searchInput.value?.length !== 0) {
                  //backspace is triggered and searchbar is not empty
                  searchInput.value = searchInput.value.substring(0, searchInput.value.length - 1);
                  key = '';
                } else if (key.length !== 1) {
                  //other than backspace or character key is pressed
                  return;
                }
                searchInput.value += key;
                const srcString = searchInput.value?.toLowerCase();
                ul.querySelectorAll('li').forEach((li) => {
                  if (!srcString || srcString === '') {
                    li.style.display = ''; //show all list items if search string is empty again
                  } else {
                    li.style.display = li.innerText?.toLowerCase()?.includes(srcString) ? '' : 'none';
                  }
                });
                ul.style.height = 'auto'; //change ul height after list items are filtered
              };
              ul.querySelectorAll('li').forEach((li) => {
                li.removeEventListener('click', (e) => focusSearch);
                li.addEventListener('click', (e) => focusSearch); //refocus search input after selection
              });

              ul.addEventListener('keydown', (e) => {
                e.stopImmediatePropagation();
                e.preventDefault();
              }); //remove default materialize search highliting for li options because it brokes searchbar functionality
              searchInput.addEventListener('keyup', (e) => {
                onSearchHandler(e);
              });
              focusSearch(); //autofocus search input on dropdown open
            }
            if (maxSelected) {
              const lis = ul.querySelectorAll('li');
              const checked = ul.querySelectorAll("input[type='checkbox']:checked");
              const checks = ul.querySelectorAll("input[type='checkbox']");
              //ADD INACTIVE BOXES IF THEY ARE STILL ACTIVE
              if (checked?.length >= maxSelected) {
                checks.forEach((check) => {
                  if (!check.checked) {
                    check.closest('li').classList.add('inactive');
                  }
                });
              }
              lis.forEach((li) => {
                li.addEventListener('click', () => {
                  const checkedUl = ul.querySelectorAll("input[type='checkbox']:checked");
                  if (checkedUl.length > maxSelected) {
                    li.click(); //reclick to unselect item
                  }

                  const totalChecks = ul.querySelectorAll("input[type='checkbox']:checked")?.length;
                  const checks = ul.querySelectorAll("input[type='checkbox']");

                  if (totalChecks === maxSelected) {
                    maxCountRef.current.classList.add('max');
                    checks.forEach((check) => {
                      if (!check.checked) {
                        check.closest('li').classList.add('inactive');
                      } else {
                        check.closest('li').classList.remove('inactive');
                      }
                    });
                  } else {
                    maxCountRef.current.classList.remove('max');
                    checks.forEach((check) => {
                      check.closest('li').classList.remove('inactive');
                    });
                  }
                  totalCheckedRef.current.textContent = totalChecks;
                });
              });
            }
          },
          onCloseStart: (e) => {
            const values = [];
            const checkedNod = document.getElementById(id).querySelectorAll('option:checked');
            if (canSearch) {
              //remove extra functionality for search input
              const searchDiv = e?.parentElement?.querySelector('.search-container');
              searchDiv?.remove();
            }
            if (!maxSelected || checkedNod?.length <= maxSelected) {
              checkedNod.forEach(function (x) {
                values.push(x.value);
              });
              field.onBlur(values);
              document.body.click(); //loose focus away from component on select close;
              if (handleGtmEvent) {
                handleGtmEvent(values);
              }
            }
          },
        },
      }}
      tabIndex={tabIndex}
      value={value}
    >
      {children}
    </Select>
  );
};

SelectMulti.propTypes = {
  afterLabelComp: PropTypes.any,
  canSearch: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  filterCategory: PropTypes.string,
  filterName: PropTypes.string,
  handleGtmEvent: PropTypes.any,
  icon: PropTypes.any,
  id: PropTypes.string.isRequired,
  label: PropTypes.object,
  maxSelected: PropTypes.number,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
};

export default injectIntl(SelectMulti);

import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import messages from '../../i18n/base-en.js';

const SignupSelection = ({ skinName }) => (
  <div id="signup-selection">
    <div className="row">
      <div className="col-sm-6 col-xs-12">
        {skinName === 'bluechew' ? (
          <a data-cy="link-signup-bluerevenue" href="http://bluerevenue.com/form/">
            <div className="signup-container">
              <h4>
                <FormattedMessage {...messages.genericTextImAn} /> <br />
                <span className="green-text">
                  <FormattedMessage {...messages.genericTextAffiliate} />
                </span>
              </h4>
              <div className="tagline">
                <FormattedMessage {...messages.loginPageIWantToBuyTraffic} />
              </div>
            </div>
          </a>
        ) : (
          <Link data-cy="link-signup-crakrevenue" onlyActiveOnIndex={false} to="/access/create-account">
            <div className="signup-container">
              <h4>
                <FormattedMessage {...messages.genericTextImAn} /> <br />
                <span className="green-text">
                  <FormattedMessage {...messages.genericTextAffiliate} />
                </span>
              </h4>
              <div className="tagline">
                <FormattedMessage {...messages.loginPageIWantToBuyTraffic} />
              </div>
            </div>
          </Link>
        )}
      </div>
      <div className="col-sm-6 col-xs-12">
        <a data-cy="link-signup-advertiser" href="http://portal.crakrevenue.com/advertiser_signup">
          <div className="signup-container">
            <h4>
              <FormattedMessage {...messages.genericTextImAn} /> <br />
              <span className="green-text">
                <FormattedMessage {...messages.genericTextAdvertiser} />
              </span>
            </h4>
            <div className="tagline">
              <FormattedMessage {...messages.loginPageIWantToSellTraffic} />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
);

SignupSelection.defaultProps = {
  skinName: 'crakrevenue',
};

SignupSelection.propTypes = {
  skinName: PropTypes.string,
};

export default SignupSelection;

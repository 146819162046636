import PropTypes from 'prop-types';

import OffersImage from '../../../components/Offers/OffersImage.react';
import { Payout, PayoutType } from '../../../components/reusables';

const HomeFeaturedOffers = (props) => {
  const { offer } = props;

  const conversionTypes = offer.conversion_types ? offer.conversion_types.map((ct) => ct.name) : [];
  const payoutString =
    offer.payout_type !== 'cpa_percentage'
      ? `$${parseFloat(offer.default_payout).toFixed(2)}`
      : `${(parseFloat(offer.percent_payout) || 0).toFixed(2)}%`;

  return (
    <div className="home-featured-offer clearfix">
      <div className="image-container">
        <OffersImage alt={offer.name} height="35px" thumbnail={offer.thumbnail} width="35px" borderRadius="10px" />
      </div>
      <div className="offer-infos">
        <div className="title-container" title={offer.name}>
          <span>{offer.name}</span>
        </div>
        <div className="payout-container" title={`${payoutString} ${conversionTypes.join(', ')}`}>
          <Payout
            conversionTypes={conversionTypes}
            defaultPayout={offer.default_payout}
            payoutType={offer.payout_type}
            percentPayout={offer.percent_payout}
          />
          <PayoutType categories={conversionTypes} />
        </div>
      </div>
    </div>
  );
};

HomeFeaturedOffers.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default HomeFeaturedOffers;

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { CopyValueWidget } from '../../reusables';
import useClickOutside from '../../reusables/colorpicker/useClickOutside.js';
import EmailInstructionsIcon from '../../reusables/svg/EmailInstructionsIcon.react';
import WarningIcon from '../../reusables/svg/WarningIcon.react';

const OfferMailingModal = ({ code, dispatch, downloadLink, hideEmailModal, unsubscribeLink, modalInner }) => {
  let stepCounter = 0;

  useClickOutside(modalInner, hideEmailModal);
  return (
    <div id="email-popup">
      <div className="modal-content">
        <span className="modal-action modal-close hov-primary-color" onClick={hideEmailModal}>
          <i className="material-icons">close</i>
        </span>
      </div>
      <div className="email-popup-content">
        <div className="general-section">
          <div className="mailing-svg">
            <EmailInstructionsIcon />
          </div>
          <h2 className="title">
            <FormattedMessage {...messages.offerEmailInstructions} />
          </h2>
          <p>
            <FormattedMessage {...messages.offerEmailInstructionsText1} />
          </p>
          <p>
            <FormattedMessage {...messages.offerEmailInstructionsText2} />
          </p>
          <h3 className="legal-title">
            <WarningIcon />
            <FormattedMessage {...messages.offerLegalNotice} />
          </h3>
          <p>
            <FormattedHTMLMessage {...messages.offerLegalNoticeText} />
          </p>
        </div>
        <div className="steps-section">
          <h1 className="title primary-color">
            <FormattedMessage {...messages.offerMandatoryProcess} />
          </h1>
          <p>
            <FormattedHTMLMessage {...messages.offerBeforeSendingYourEmail} />
          </p>
          {code ? (
            <section>
              <h2 className="step">
                <FormattedMessage {...messages.genericTextStep} /> {++stepCounter}
              </h2>
              <p>
                <FormattedMessage {...messages.offerCopyTheHtmlCode} />
              </p>
              <CopyValueWidget
                dispatch={dispatch}
                id="modal-email-popup-code"
                label={messages.genericTextYourCode}
                value={code}
              />
            </section>
          ) : null}
          <section>
            <h2 className="step">
              <FormattedMessage {...messages.genericTextStep} /> {++stepCounter}
            </h2>
            <p>
              <FormattedHTMLMessage {...messages.offerCopyAndPasteUnsubscribeLink} />
            </p>
            <CopyValueWidget
              dispatch={dispatch}
              id="modal-email-popup-link"
              label={messages.genericTextYourLink}
              value={unsubscribeLink}
            />
          </section>
          <section>
            <h2 className="step">
              <FormattedMessage {...messages.genericTextStep} /> {++stepCounter}
            </h2>
            <p>
              <FormattedHTMLMessage {...messages.offerUseOurSupression} />
            </p>
            <div>
              <a className="waves-effect waves-light btn btn-large" href={downloadLink} rel="noreferrer" target="_blank">
                <FormattedMessage {...messages.offerUseSupressionList} />
              </a>
            </div>
          </section>
          <div>
            <a
              className="im-done-btn standard-colors"
              href=""
              onClick={(e) => {
                e.preventDefault();
                hideEmailModal();
              }}
            >
              <FormattedMessage {...messages.offerImDone} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

OfferMailingModal.propTypes = {
  code: PropTypes.any,
  dispatch: PropTypes.func.isRequired,
  downloadLink: PropTypes.string,
  hideEmailModal: PropTypes.func.isRequired,
  modalInner: PropTypes.any.isRequired,
  unsubscribeLink: PropTypes.string,
};

export default OfferMailingModal;

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  data: [],
  isFetching: false,
  isError: false,
  timestampReceived: null,
};

export const offersSlides = (state = initialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case ACTIONS.REQUEST_OFFERS_SLIDES:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.RECEIVE_OFFERS_SLIDES:
      return {
        ...state,
        data,
        isFetching: false,
        isError: false,
        timestampReceived: new Date().getTime(),
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFERS_SLIDES:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    default:
      return state;
  }
};

const ContactInfo = () => (
  <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.06216 28.4945V14.2995C7.06216 9.36063 9.80463 6.29975 14.8462 6.31517H28.2845C33.3262 6.31517 36.084 9.37603 36.084 14.3149V28.4945C36.084 33.4197 33.2782 36.4942 28.1578 36.4942H14.8462C9.80463 36.4942 7.06216 33.4197 7.06216 28.4945Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M4.41733 14.6289H9.93478M4.41733 28.1745H9.93478"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M22.801 22.7538C25.3197 22.7538 27.3614 20.712 27.3614 18.1933C27.3614 15.6747 25.3197 13.6329 22.801 13.6329C20.2823 13.6329 18.2405 15.6747 18.2405 18.1933C18.2405 20.712 20.2823 22.7538 22.801 22.7538Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16.5729 29.181C16.4653 28.5618 16.5097 27.9203 16.5097 27.2948C16.5097 24.938 18.4203 23.0273 20.7773 23.0273H24.9328C27.2897 23.0273 29.2003 24.938 29.2003 27.2948C29.2003 27.9203 29.2447 28.5618 29.1372 29.181"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default ContactInfo;

const LandingPageModalIcon = () => (
  <svg fill="none" height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
    <rect height="18.6842" rx="1.5" stroke="#333333" width="21" x="7.5" y="8.5" />
    <line stroke="#333333" x1="21.3948" x2="21.3948" y1="13.7895" y2="26.5263" />
    <line stroke="#333333" x1="20.8948" x2="7.00004" y1="18.9211" y2="18.9211" />
    <line stroke="#333333" x1="27.842" x2="6.99994" y1="14.2895" y2="14.2895" />
  </svg>
);

export default LandingPageModalIcon;

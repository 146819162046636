import consts from '../../utils/consts';
const { TRACKING_SOURCE_PARAM } = consts.REGEXES;

export default (values) => {
  const errors = {};
  if (values?.source) {
    if (!values?.source?.match(TRACKING_SOURCE_PARAM)) {
      errors.source =
        'Special characters such as: ~ ! @ # $ ^ & * ( ) + { } | " < > " are not allowed in this field. Please remove them.';
    }
    if (values?.source?.length > 30) {
      errors.source = 'Source cannot exceed 30 characters';
    }
  }
  return errors;
};

import consts from '../../utils/consts';

const {
  REGEXES: { HEX_COLOR },
} = consts;

export default (value, previous) => {
  if (!HEX_COLOR.test(value)) {
    return previous;
  }
  return value;
};

import { SimplifiedInput } from '../../../../../components/reusables';
import socialsList from '../../../../../utils/list-options/socials';
import consts from '../../../../../utils/consts';
import 'materialize-css';
import CircleAdd from '../../../../reusables/svg/CircleAdd';
import SelectCustom from '../../../../reusables/form/SelectCustom';

export const MESSENGER_NAMES = {
  SKYPE: 'Skype',
  TELEGRAM: 'Telegram',
  WHATSAPP: 'WhatsApp',
};

export const MESSENGERS = [MESSENGER_NAMES.SKYPE, MESSENGER_NAMES.TELEGRAM, MESSENGER_NAMES.WHATSAPP];

export const MESSENGER_PLACEHOLDERS = {
  [MESSENGER_NAMES.SKYPE]: 'Skype ID',
  [MESSENGER_NAMES.TELEGRAM]: 'Telegram username',
  [MESSENGER_NAMES.WHATSAPP]: 'WhatsApp phone number',
};

const TellUsAboutYouRoleDropdownInputNew = (props) => {
  const { label, fieldInput, fieldSelect, inputDisabled, inputOptions, tabIndex, showAdd, addField, hidden } = props;
  const { REFERENCES } = consts;

  const getOptions = () => {
    switch (inputOptions) {
      case 'socials':
        return socialsList;
      case 'references':
        return REFERENCES;
      case 'messaging_platform_name':
        return MESSENGERS;
      default:
        return [];
    }
  };

  const getPlaceHolder = () => {
    switch (inputOptions) {
      case 'socials':
        return 'Your username';
      case 'messaging_platform_name':
        return MESSENGER_PLACEHOLDERS[fieldSelect.value] || 'Choose a messenger';
      case 'references':
        return 'Specify';
      default:
        return '';
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <SelectCustom
      styleBox={true}
      error={fieldSelect.error}
      label={label}
      onChange={fieldSelect.onChange}
      options={getOptions().map((option) => ({
        value: option.value ? option.value : option,
        text: option.value ? option.value : option,
      }))}
      small
      tabIndex={tabIndex}
      touched={fieldSelect.touched}
      value={fieldSelect.value || ''}
      valueDisabled="Choose"
    >
      <SimplifiedInput
        autofocus
        field={fieldInput}
        id="tellUsMore-simplified_role"
        inputDisabled={inputDisabled}
        key={`input-${fieldInput.name}`}
        placeholder={getPlaceHolder(fieldInput === 'messaging_platform_name')}
        tabIndex={tabIndex}
        type="text"
      />
      {showAdd ? (
        <div className="custom-svg" onClick={() => addField()}>
          <CircleAdd onClick={() => addField()} />
        </div>
      ) : null}
    </SelectCustom>
  );
};

export default TellUsAboutYouRoleDropdownInputNew;

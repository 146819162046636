import React, { useCallback } from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import { OFFER_SEARCH_FIELD } from './values';
import { Gtm } from '../../../utils/gtm';

const SearchBar = React.memo(({ searchFilterRef, options, field }) => {
  const { setSearchOptions, searchRef, distpachSearchFormChange, search } = useOffersFilterContext();

  const isFilterSearch = options ? true : false;

  const filterOnChange = useCallback(
    (e) => {
      const value = e?.target?.value?.toLowerCase();
      const filteredOptions = options.filter(({ name }) => {
        name = name.toLowerCase();
        if (name.includes(value)) {
          return true;
        }
      });

      setSearchOptions((options) => {
        const currentOptions = { ...options };

        currentOptions[field] = filteredOptions;

        return currentOptions;
      });
    },
    [options, field]
  );

  const submitSearch = (event) => {
    const value = event.target.value;
    if (event.keyCode === 13) {
      const field = OFFER_SEARCH_FIELD;
      distpachSearchFormChange({ field, filters: value, searchEvent: true });
    }
  };
  return (
    <div className="searchBar">
      <svg viewBox="0 0 14.207 14.509" xmlns="http://www.w3.org/2000/svg">
        <g id="Search" transform="translate(0.75 0.75)">
          <circle
            cx="5.938"
            cy="5.938"
            fill="none"
            id="Ellipse_739"
            r="5.938"
            stroke="#60647c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(0 0)"
          />
          <line
            fill="none"
            id="Line_181"
            stroke="#60647c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(10.068 10.377)"
            x2="2.328"
            y2="2.322"
          />
        </g>
      </svg>
      <input
        placeholder="Search"
        type="text"
        onKeyDown={isFilterSearch ? null : submitSearch}
        onChange={isFilterSearch ? filterOnChange : null}
        defaultValue={isFilterSearch ? filterOnChange : search.searchString}
        ref={isFilterSearch ? searchFilterRef : searchRef}
        onClick={() => {
          if (!isFilterSearch) {
            Gtm.event('offers', 'Click', 'browse offers search bar');
          } else {
            Gtm.event('offers', 'Click', `filter search bar offers ${field}`);
          }
        }}
      />
    </div>
  );
});

export default SearchBar;

/**
 * @file CheckCircle
 * @author Vero Lopez
 * @version 0.0.1
 */

function CheckCircle() {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24">
      <g fill="#07B500" fillRule="evenodd">
        <path d="M12 21.6c-5.292 0-9.6-4.308-9.6-9.6S6.708 2.4 12 2.4s9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0" />
        <path d="M16.656 7.548L9.6 14.604l-2.256-2.256a1.195 1.195 0 0 0-1.692 0 1.195 1.195 0 0 0 0 1.692l3.108 3.108a1.195 1.195 0 0 0 1.692 0L18.36 9.24a1.195 1.195 0 0 0 0-1.692 1.21 1.21 0 0 0-1.704 0" />
      </g>
    </svg>
  );
}

export default CheckCircle;

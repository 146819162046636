import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Copy } from '../reusables';

import { setActionMessage } from '../Application/actions/application-actions';

class ReturnMessage extends Component {
  constructor(props) {
    super(props);

    this.deleteActionMessage = this.deleteActionMessage.bind(this);

    this.timeout = null;
    this.state = {
      show: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { status } = this.props;

    if (status !== nextProps.status) {
      this.setState({
        show: nextProps.status !== null,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { status, textObj, time } = this.props;

    return status !== nextProps.status || !_.isEqual(textObj, nextProps.textObj) || time !== nextProps.time;
  }

  componentDidUpdate() {
    const { status, time } = this.props;

    if (status !== null && status !== 'confirmation') {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.deleteActionMessage();
      }, time);
    }
  }

  deleteActionMessage() {
    const { dispatch } = this.props;

    this.setState({
      show: false,
    });
    dispatch(setActionMessage(null, {}));
  }

  render() {
    const { callback, dispatch, intl, status, textObj } = this.props;
    const { show } = this.state;

    textObj.closeButton = textObj.closeButton !== 'undefined' ? true : textObj.closeButton;
    const mainContainerClassName = [show ? 'show' : undefined, textObj.modal ? 'isModal' : undefined].join(' ');

    return (
      <div className={mainContainerClassName} id="return-message">
        {textObj.modal ? <div className="overlay">&nbsp;</div> : null}
        <div className="message z-depth-4 ">
          {textObj.title ? <div className="title">{textObj.title}</div> : null}
          <span className="text">
            {status !== 'copy' && textObj.text}
            {status === 'confirm' && (
              <div className="confirm-buttons">
                <span
                  className="btn"
                  onClick={() => {
                    callback();
                    this.deleteActionMessage();
                  }}
                >
                  {intl.formatMessage(messages.genericTextYes)}
                </span>
                <span className="btn" disabled onClick={this.deleteActionMessage}>
                  {intl.formatMessage(messages.genericTextNo)}
                </span>
              </div>
            )}
            {status === 'copy' && (
              <div>
                <input id="action-message-copy" readOnly type="text" value={textObj.text} />
                <Copy clipboardTarget="#action-message-copy" dispatch={dispatch} />
              </div>
            )}
          </span>
          {textObj.closeButton ? (
            <span className="close" onClick={this.deleteActionMessage}>
              <i className="material-icons">close</i>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

ReturnMessage.propTypes = {
  callback: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  status: PropTypes.string,
  textObj: PropTypes.object.isRequired,
  time: PropTypes.number,
};

export default injectIntl(ReturnMessage);

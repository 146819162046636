import { AffiliateTypeEnum } from '../../../enum/AffiliateTypeEnum';
import { ValidationStatusEnum } from '../../../enum/ValidationStatusEnum';
import ValidationStatusMapper from '../../../utils/ValidationStatusMapper';

export default class CompleteIdentityService {
  static hasToCompleteIdentity({ hasToCompleteProfile, validationStatusList, validationStatusId, affiliateType }) {
    const validationStatus = ValidationStatusMapper.map({
      validationStatusList,
      validationStatusId,
    });

    const alreadyCompletedProfileInfo = hasToCompleteProfile === false;

    const isVerifiedStatus = validationStatus?.toUpperCase() === ValidationStatusEnum.VERIFIED;

    const isAffiliateTypePerson = affiliateType?.toUpperCase() === AffiliateTypeEnum.PERSON;

    const isAffiliateTypeLegacy = affiliateType?.toUpperCase() === AffiliateTypeEnum.LEGACY;

    const isAffiliateTypeNull = !affiliateType;

    return (
      alreadyCompletedProfileInfo &&
      ((isAffiliateTypePerson && !isVerifiedStatus) || (isAffiliateTypeLegacy && !isVerifiedStatus) || isAffiliateTypeNull)
    );
  }
}

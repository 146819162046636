const EmailIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8325 16.808H6.01402C5.49447 16.8153 4.97874 16.7181 4.4974 16.5224C4.01604 16.3268 3.57886 16.0365 3.21172 15.6688C2.79007 15.2326 2.45914 14.717 2.2381 14.152C2.01707 13.587 1.91031 12.9838 1.92402 12.3773V7.6257C1.89021 7.06251 1.97069 6.4983 2.16061 5.96701C2.35052 5.43573 2.64594 4.94835 3.02911 4.53421C3.41228 4.12007 3.87527 3.78773 4.39022 3.55717C4.90517 3.32661 5.46144 3.20261 6.02556 3.19263H12.8225C13.3866 3.20261 13.9429 3.32661 14.4578 3.55717C14.9728 3.78773 15.4357 4.12007 15.819 4.53421C16.2021 4.94835 16.4975 5.43573 16.6874 5.96701C16.8773 6.4983 16.9578 7.06251 16.924 7.6257V12.3773C16.9373 12.9833 16.8303 13.5859 16.6093 14.1504C16.3883 14.7148 16.0576 15.2299 15.6363 15.6657C15.2693 16.0341 14.8319 16.325 14.3503 16.5212C13.8687 16.7175 13.3525 16.815 12.8325 16.808ZM6.02402 15.6542H12.831C13.1995 15.6606 13.5656 15.5929 13.9074 15.455C14.2493 15.3173 14.5599 15.1121 14.821 14.8519C15.1348 14.5226 15.3803 14.1343 15.5433 13.7096C15.7062 13.2848 15.7833 12.832 15.7702 12.3773V7.6257C15.7702 5.7257 14.531 4.34647 12.8225 4.34647H6.02556C4.3171 4.34647 3.07787 5.7257 3.07787 7.6257V12.3773C3.06513 12.8315 3.14244 13.2837 3.30537 13.7079C3.46828 14.1321 3.71358 14.5199 4.0271 14.8488C4.28792 15.1089 4.59833 15.3139 4.93991 15.4517C5.28149 15.5896 5.64728 15.6573 6.01556 15.6511L6.02402 15.6542Z"
      fill="#FFF"
    />
    <path
      d="M9.44331 11.2457C8.95377 11.2454 8.47884 11.0789 8.09638 10.7734L4.99021 8.26952C4.87107 8.17344 4.79497 8.03395 4.77867 7.88176C4.76237 7.72957 4.8072 7.57713 4.90329 7.45798C4.99938 7.33884 5.13887 7.26275 5.29106 7.24645C5.44325 7.23014 5.59568 7.27497 5.71483 7.37106L8.81715 9.87261C8.99577 10.0154 9.21769 10.0932 9.44638 10.0932C9.67508 10.0932 9.897 10.0154 10.0756 9.87261L13.1464 7.37567C13.2651 7.27907 13.4174 7.2336 13.5696 7.24925C13.7218 7.2649 13.8617 7.3404 13.9583 7.45914C14.0549 7.57787 14.1004 7.73012 14.0847 7.88238C14.0691 8.03465 13.9935 8.17446 13.8748 8.27106L10.7979 10.7726C10.4128 11.0789 9.93531 11.2457 9.44331 11.2457Z"
      fill="#FFF"
    />
  </svg>
);

export default EmailIcon;

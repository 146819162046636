const PromotionMethodArrowIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.25 11.7257L5.25 11.7257" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path
      d="M14.2002 5.70129L20.2502 11.7253L14.2002 17.7503"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default PromotionMethodArrowIcon;

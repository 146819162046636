import { createContext, useContext, useState, useRef, useEffect } from 'react';

const SliderContext = createContext();

export const SliderProvider = ({ children, setSelectedOffer }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const isSlidingRef = useRef(false);
  const [showSliderCreative, setShowSliderCreatives] = useState(false);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
    }

    const timeout = setTimeout(function () {
      isSlidingRef.current = false;
      if (currentIndex !== 2) {
        setShowSliderCreatives(false);
      }
    }, 501);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentIndex]);

  const nextSlide = (promoMethodClick) => {
    if (isSlidingRef.current) {
      return;
    }
    isSlidingRef.current = true;
    setCurrentIndex((prevIndex) => prevIndex + 1);
    if (promoMethodClick) {
      setShowSliderCreatives(true);
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const previousSlide = () => {
    if (isSlidingRef.current) {
      return;
    }
    isSlidingRef.current = true;
    setCurrentIndex((prevIndex) => prevIndex - 1);
    setSelectedOffer('');
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  return (
    <SliderContext.Provider value={{ currentIndex, nextSlide, previousSlide, sliderRef, showSliderCreative }}>
      {children}
    </SliderContext.Provider>
  );
};

export const useSlider = () => useContext(SliderContext);

import PropTypes from 'prop-types';

function AlignRight({ isActive }) {
  let classes = 'align-icon align-right';

  if (isActive) {
    classes += ' active';
  }

  return (
    <svg className={classes} height="16px" viewBox="0 0 20 16" width="20px">
      <g fill="none" fillRule="evenodd" id="Survey-Generator" stroke="none" strokeWidth="1">
        <g id="03_Survey_BasicConfiguration" transform="translate(-1311.000000, -399.000000)">
          <g id="Settings" transform="translate(81.000000, 233.000000)">
            <g id="Custom-Logo-URL" transform="translate(654.000000, 138.000000)">
              <g id="Alignement-logo" transform="translate(518.000000, 28.000000)">
                <g id="Group-6" transform="translate(58.000000, 0.000000)">
                  <path
                    className="lighten-color"
                    d="M16.6168,7.33333333 L4.9832,7.33333333 C4.4404,7.33333333 4,7.03973333 4,6.67786667 L4,2.65546667 C4,2.2936 4.4404,2 4.9832,2 L16.6164,2 C17.1596,2 17.6,2.2936 17.6,2.65546667 L17.6,6.6776 C17.6,7.03973333 17.1596,7.33333333 16.6168,7.33333333"
                    fill="#D4D4D4"
                    id="Fill-1"
                  />
                  <path
                    className="darken-color"
                    d="M17.0168,14.4 L1.3832,14.4 C0.8404,14.4 0.4,14.1064 0.4,13.7445333 L0.4,9.72213333 C0.4,9.36026667 0.8404,9.06666667 1.3832,9.06666667 L17.0164,9.06666667 C17.5596,9.06666667 18,9.36026667 18,9.72213333 L18,13.7442667 C18,14.1064 17.5596,14.4 17.0168,14.4"
                    fill="#888888"
                    id="Fill-3"
                  />
                  <path
                    d="M19.4,16 C19.1792,16 19,15.8805333 19,15.7333333 L19,0.266666667 C19,0.119466667 19.1792,0 19.4,0 C19.6208,0 19.8,0.119466667 19.8,0.266666667 L19.8,15.7333333 C19.8,15.8805333 19.6208,16 19.4,16"
                    fill="#888888"
                    id="Fill-5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

AlignRight.propTypes = {
  isActive: PropTypes.bool,
};

export default AlignRight;

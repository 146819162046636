const PayoutIcon = () => (
  <svg viewBox="0 0 25.36 21.62" xmlns="http://www.w3.org/2000/svg">
    <title>Payout</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Calque 1" id="Calque_1-2">
        <path
          className="fill-primary-color"
          d="M15.11,16.6l0-.06a1.36,1.36,0,0,1,.11-1.64l.2-.23a1.53,1.53,0,0,1,2.18-.19l1.54,1.18.3.23.22-.26c.52-.59,1-1.19,1.55-1.79A10.8,10.8,0,1,0,10.8,21.62a10.69,10.69,0,0,0,6.46-2.15l-1.73-2.25a6.79,6.79,0,0,1-.42-.62m-1.2-1.53a3.55,3.55,0,0,1-1.8,1.16.49.49,0,0,0-.44.57c0,.32,0,.64,0,1a.4.4,0,0,1-.42.45h-1a.41.41,0,0,1-.44-.47c0-.23,0-.47,0-.7,0-.52,0-.54-.52-.61a6.51,6.51,0,0,1-1.83-.53c-.46-.22-.51-.33-.37-.81s.19-.71.3-1.07.24-.46.61-.26a5.81,5.81,0,0,0,2,.61,2.52,2.52,0,0,0,1.33-.17,1,1,0,0,0,.25-1.82,3.24,3.24,0,0,0-.76-.45A17.59,17.59,0,0,1,8.73,11,3,3,0,0,1,7.06,8.18,3.11,3.11,0,0,1,9.33,5.31c.55-.2.55-.19.56-.78V4c0-.44.08-.52.52-.53h.4c.93,0,.93,0,.93.93,0,.66,0,.66.66.76a6,6,0,0,1,1.44.43.42.42,0,0,1,.27.56c-.11.4-.22.8-.35,1.2s-.24.43-.6.26a4.51,4.51,0,0,0-2.29-.45,1.73,1.73,0,0,0-.6.12.86.86,0,0,0-.22,1.54,4.21,4.21,0,0,0,1,.56,17.23,17.23,0,0,1,1.81.81,3.24,3.24,0,0,1,1.06,4.93"
        />
        <path
          className="fill-primary-color"
          d="M25.07,12a1.1,1.1,0,0,0-.6-.3c-.18,0-.35.13-.59.4L20.13,16.2l-.85.93-.41-.31c-.74-.53-1.47-1.08-2.21-1.6a.79.79,0,0,0-.44-.18.72.72,0,0,0-.49.27,1.14,1.14,0,0,0-.15.16.37.37,0,0,0,0,.48c.13.2.27.4.42.59q1.47,1.82,3,3.61a.53.53,0,0,0,.4.23.55.55,0,0,0,.43-.27l5.43-7.32c.27-.37.25-.49-.11-.77"
        />
      </g>
    </g>
  </svg>
);

export default PayoutIcon;

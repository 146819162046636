const AI = () => (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_45_354)">
      <path
        d="M7 13.5C7 9.91 4.09 7 0.5 7C4.09 7 7 4.09 7 0.5C7 4.09 9.91 7 13.5 7C9.91 7 7 9.91 7 13.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0102 12.5901C11.0102 11.7201 10.3002 11.0101 9.43018 11.0101C10.3002 11.0101 11.0102 10.3001 11.0102 9.43005C11.0102 10.3001 11.7202 11.0101 12.5902 11.0101C11.7202 11.0101 11.0102 11.7201 11.0102 12.5901Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_45_354">
        <rect fill="white" height="14" width="14" />
      </clipPath>
    </defs>
  </svg>
);

export default AI;

/**
 * @file Whale
 * @author Pierre-Luc Gagné
 * @version 0.0.1
 */

function RankVIP() {
  return (
    <svg height="16.723" viewBox="0 0 18.759 16.723" width="18.759" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.073,11.211,13.617,6h.824l2.545,5.211Zm2.351,11.512-8.58-10.3h8.58Zm1.212,0v-10.3h8.58Zm3.708-11.512L15.8,6h4.12a1.421,1.421,0,0,1,.763.218,1.509,1.509,0,0,1,.545.582l2.181,4.411Zm-13.694,0L6.831,6.8a1.509,1.509,0,0,1,.545-.582A1.421,1.421,0,0,1,8.14,6h4.12L9.715,11.211Z"
        fill="#454545"
        id="vip-aff"
        transform="translate(-4.65 -6)"
      />
    </svg>
  );
}

export default RankVIP;

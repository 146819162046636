import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reset, getValues } from 'redux-form';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Container } from '../../../components/reusables';
import OfferAddPostback from '../OfferPostbacks/OfferAddPostback/OfferAddPostback.react';
import OfferPostbacksList from '../OfferPostbacks/OfferPostbacksList/OfferPostbacksList.react';

import { addPostback, deletePostback, fetchPostbacks } from '../../../actions/postbacks-actions';
import { Gtm } from '../../../utils/gtm';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions.js';

const OfferPostbacks = ({ dispatch, addPostbackForm, intl, offer, goals, overlay, postbacks, isMoreInfoIconVisible }) => {
  useEffect(() => {
    if (offer.id) {
      dispatch(fetchPostbacks(offer.id));
    }
  }, [offer.id, postbacks.length]);

  const handleAddVariables = (data) => {
    const link = typeof addPostbackForm.link !== 'undefined' ? addPostbackForm.link.value : '';
    const keys = Object.keys(data).filter((key) => key !== 'filtersSearch' && data[key] && link.indexOf(data[key]) === -1);
    dispatch({
      type: 'redux-form/CHANGE',
      form: 'addPostback',
      field: 'link',
      value: `${link}${keys.map((key) => data[key].join('')).join('')}`,
    });
    dispatch(closeModalAnimation());
  };
  const handleCreatePostback = () => {
    const values = getValues(addPostbackForm);
    const goal = values.goal !== '0' ? values.goal : undefined;

    dispatch(addPostback(offer.id, goal, values.link, intl)).then(() => {
      dispatch(reset('addPostback'));
    });
  };

  const handleDeletePostback = (postbackId) => {
    dispatch(deletePostback(postbackId, offer.id, intl));
  };

  const getGoalName = (postback) => {
    try {
      return postback.goalId ? goals.find((goal) => goal.id === postback.goalId).name : offer.default_goal_name;
    } catch (e) {
      return offer.default_goal_name;
    }
  };
  const newPostbacks = postbacks.map((postback) => ({
    ...postback,
    goalName: getGoalName(postback),
  }));
  return (
    <div id="offer-postbacks-container">
      <Container
        className="z-depth-2 black-title"
        collapsable
        icon="settings_input_composite"
        info={
          isMoreInfoIconVisible
            ? {
                url: 'https://support.crakrevenue.com/knowledge-base/setting-postback/',
                title: '',
              }
            : ''
        }
        onToggle={() => Gtm.event('UI Component', 'Click', 'Console-Offer-Postbacks-Dropdown')}
        overlay={overlay}
        title={<FormattedMessage {...messages.offerPostbackOptions} />}
      >
        <OfferAddPostback goals={goals} handleAddVariables={handleAddVariables} handleCreatePostback={handleCreatePostback} />
        <OfferPostbacksList dispatch={dispatch} handleDeletePostback={handleDeletePostback} postbacks={newPostbacks} />
      </Container>{' '}
    </div>
  );
};

OfferPostbacks.propTypes = {
  addPostbackForm: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  goals: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  offer: PropTypes.object.isRequired,
  overlay: PropTypes.object,
  postbacks: PropTypes.array.isRequired,
};

export default connect((state) => ({
  addPostbackForm: state.form.addPostback || {},
  goals: state.goals.goals,
  offer: state.offer.offer,
  postbacks: state.postbacks.postbacks,
}))(injectIntl(OfferPostbacks));

import React from 'react';
import { PREVIEW_TABS } from '../adTools/values';
import { useAdToolsContext } from '../CreativesAdTools';

const CodeInput = React.memo(() => {
  const { previewTab, creativeCode } = useAdToolsContext();

  const active = previewTab === PREVIEW_TABS.SHOW_CODE;

  return (
    <div className={`code-input ${active ? 'active' : ''}`}>
      <textarea readOnly spellCheck="false" value={creativeCode} />
    </div>
  );
});

export default CodeInput;

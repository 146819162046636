import { SimplifiedInput } from '../../../../../components/reusables';
import CircleAdd from '../../../../../components/reusables/svg/CircleAdd';

const TellUsAboutYouRoleInput = (props) => {
  const { field, hidden, addField, showAdd, placeholder, label, normalizeBudget, tabIndex, type = 'text', inputmode } = props;
  return (
    <div className={`row ${hidden ? 'hidden' : ''} custom-add`}>
      <SimplifiedInput
        autofocus
        className="simple-signup__input"
        field={field}
        id={`tellUsMore-simplified_${field.name}`}
        inputmode={inputmode}
        key={`${field.name}`}
        label={label}
        normalizeBudget={normalizeBudget}
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={type}
      />
      {showAdd ? (
        <div className="custom-svg" onClick={() => addField()}>
          <CircleAdd onClick={() => addField()} />
        </div>
      ) : null}
    </div>
  );
};

export default TellUsAboutYouRoleInput;

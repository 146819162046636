import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { Radio } from '../../components/reusables';

import { Gtm } from '../../utils/gtm';
import { initialFetching } from '../../utils/initialFetching';

import 'materialize-css';
import PromotionMethodArrowIcon from './PromotionmethodArrowIcon';
import Tooltip from '../../components/reusables/Tooltip.react';
import { useSlider } from './SliderContext.react';

const SmartLinkCustomize = ({ fields, formChangedHandler, listMediaPlacements, dispatch, windowWidthSize }) => {
  const { nextSlide, previousSlide } = useSlider();
  useEffect(() => {
    initialFetching(dispatch);
  }, []);

  const handleChangePromotionMediumUrlId = (e) => {
    fields.target.onChange(e.target.value);
    Gtm.event('dating smartlink', 'click', `promotion method - ${e.target.value}`);
    Gtm.newEvent('smartlink_vertical', false, 'click', 'string', e.target.value);
    formChangedHandler();
    nextSlide(true);
  };

  return (
    <div className="smart-link-container">
      <span className="back-btn" onClick={() => previousSlide()}>
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.05 3.951L7.00005 9.975L13.05 16"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
        Back
      </span>
      <div className="header-container">
        <div className="header-box">
          <h1 className="primary-color">Promotion Method</h1>
        </div>
        <p className="smartlink-description smartlink-description-customize">
          <span>
            Define the method you will use <br />
            with this Smartlink.
          </span>
          <Tooltip
            closeIcon
            content={<p>Our Smartlink uses this information to deliver optimal results.</p>}
            id="smartlink-promotion-method"
            isFixed
            isHoverable
            position={`${windowWidthSize >= 1151 ? 'right' : 'left'}`}
          >
            <svg
              fill="none"
              height="20"
              style={{ cursor: 'pointer' }}
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 19C4.75315 19 0.5 14.7469 0.5 9.5C0.5 4.25315 4.75315 0 10 0C15.2469 0 19.5 4.25315 19.5 9.5C19.5 14.7469 15.2469 19 10 19ZM10 17.1C12.0156 17.1 13.9487 16.2993 15.374 14.874C16.7993 13.4487 17.6 11.5156 17.6 9.5C17.6 7.48435 16.7993 5.55126 15.374 4.12599C13.9487 2.70071 12.0156 1.9 10 1.9C7.98435 1.9 6.05126 2.70071 4.62599 4.12599C3.20071 5.55126 2.4 7.48435 2.4 9.5C2.4 11.5156 3.20071 13.4487 4.62599 14.874C6.05126 16.2993 7.98435 17.1 10 17.1ZM9.05 12.35H10.95V14.25H9.05V12.35ZM10.95 10.7872V11.4H9.05V9.975C9.05 9.72304 9.15009 9.48141 9.32825 9.30325C9.50641 9.12509 9.74804 9.025 10 9.025C10.2699 9.02498 10.5342 8.94833 10.7622 8.80396C10.9902 8.65959 11.1726 8.45345 11.288 8.2095C11.4034 7.96556 11.4472 7.69386 11.4142 7.426C11.3813 7.15815 11.273 6.90516 11.1018 6.69647C10.9307 6.48778 10.7039 6.33197 10.4477 6.24717C10.1915 6.16237 9.91643 6.15207 9.6546 6.21747C9.39277 6.28287 9.15489 6.42127 8.96865 6.61658C8.7824 6.81188 8.65545 7.05606 8.60255 7.3207L6.73865 6.94735C6.8542 6.36983 7.12103 5.83335 7.51187 5.39276C7.90271 4.95216 8.40354 4.62325 8.96316 4.43965C9.52278 4.25605 10.1211 4.22435 10.697 4.34778C11.2729 4.47122 11.8057 4.74536 12.2409 5.14218C12.6761 5.53901 12.9981 6.04429 13.1741 6.60636C13.35 7.16843 13.3735 7.76715 13.2422 8.34129C13.1109 8.91544 12.8295 9.44442 12.4268 9.87417C12.0241 10.3039 11.5144 10.619 10.95 10.7872Z"
                fill="#4E4E4E"
              />
            </svg>
          </Tooltip>
        </p>
      </div>
      <div id="smart-link-customize">
        {listMediaPlacements ? (
          <div className="row promotionMethods-list">
            {listMediaPlacements.map((promotionMethod) => (
              <label
                data-cy={promotionMethod.name.toLowerCase().replace('-', '').replace(/\s/g, '')}
                htmlFor={promotionMethod.id}
                key={promotionMethod.id}
              >
                <Radio
                  field={fields?.target}
                  icon={<PromotionMethodArrowIcon />}
                  id={promotionMethod.id}
                  label={promotionMethod.name}
                  onClick={(e) => {
                    handleChangePromotionMediumUrlId(e);
                  }}
                  value={promotionMethod.name.toLowerCase().replace('-', '').replace(/\s/g, '')}
                />
              </label>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

SmartLinkCustomize.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  payoutType: PropTypes.array,
  promotionMedium: PropTypes.array,
  smartLinks: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(SmartLinkCustomize);

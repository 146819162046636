import { createMessagesFromLabels } from '../helpers';

const provincesList = [
  {
    value: 'AB',
    label: {
      id: 'provinceList.AB',
      description: 'Alberta',
      defaultMessage: 'Alberta',
    },
    name: 'provinceList.AB',
  },
  {
    value: 'BC',
    label: {
      id: 'provinceList.BC',
      description: 'British Columbia',
      defaultMessage: 'British Columbia',
    },
    name: 'provinceList.BC',
  },
  {
    value: 'MB',
    label: {
      id: 'provinceList.MB',
      description: 'Manitoba',
      defaultMessage: 'Manitoba',
    },
    name: 'provinceList.MB',
  },
  {
    value: 'NB',
    label: {
      id: 'provinceList.NB',
      description: 'New Brunswick',
      defaultMessage: 'New Brunswick',
    },
    name: 'provinceList.NB',
  },
  {
    value: 'NL',
    label: {
      id: 'provinceList.NL',
      description: 'Newfoundland',
      defaultMessage: 'Newfoundland',
    },
    name: 'provinceList.NL',
  },
  {
    value: 'NT',
    label: {
      id: 'provinceList.NT',
      description: 'Northwest Territories',
      defaultMessage: 'Northwest Territories',
    },
    name: 'provinceList.NT',
  },
  {
    value: 'NS',
    label: {
      id: 'provinceList.NS',
      description: 'Nova Scotia',
      defaultMessage: 'Nova Scotia',
    },
    name: 'provinceList.NS',
  },
  {
    value: 'NU',
    label: {
      id: 'provinceList.NU',
      description: 'Nunavut',
      defaultMessage: 'Nunavut',
    },
    name: 'provinceList.NU',
  },
  {
    value: 'ON',
    label: {
      id: 'provinceList.ON',
      description: 'Ontario',
      defaultMessage: 'Ontario',
    },
    name: 'provinceList.ON',
  },
  {
    value: 'PE',
    label: {
      id: 'provinceList.PE',
      description: 'Prince Edward Island',
      defaultMessage: 'Prince Edward Island',
    },
    name: 'provinceList.PE',
  },
  {
    value: 'QC',
    label: {
      id: 'provinceList.QC',
      description: 'Quebec',
      defaultMessage: 'Quebec',
    },
    name: 'provinceList.QC',
  },
  {
    value: 'SK',
    label: {
      id: 'provinceList.SK',
      description: 'Saskatchewan',
      defaultMessage: 'Saskatchewan',
    },
    name: 'provinceList.SK',
  },
  {
    value: 'YT',
    label: {
      id: 'provinceList.YT',
      description: 'Yukon Territory',
      defaultMessage: 'Yukon Territory',
    },
    name: 'provinceList.YT',
  },
];

export default provincesList;
export const labelMessagesObj = createMessagesFromLabels(provincesList);

export const getProvinceLabelDefaultMessage = (provinceCode) => {
  const province = provincesList.find((current) => current.value === provinceCode);
  if (province) {
    return province.label.defaultMessage;
  }
  return undefined;
};

export const getProvinceCode = (provinceLabelDefaultMessage) => {
  const province = provincesList.find((current) => current.label.defaultMessage === provinceLabelDefaultMessage);
  if (province) {
    return province.value;
  }
  return undefined;
};

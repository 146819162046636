import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { LiveCamWidgetPage } = messages;

const LayoutSettingsLayout = ({ fields: { columns, layoutType, rows } }) => {
  const disabled = layoutType.value !== 'custom';

  return (
    <div className="settings-group">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.layoutSettings.layoutSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={layoutType}
          id="widget-layout-type-auto"
          label={LiveCamWidgetPage.fields.genericChoices.responsive}
          value="responsive"
        />
        <Radio
          field={layoutType}
          id="widget-layout-type-custom"
          label={LiveCamWidgetPage.fields.genericChoices.custom}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `layout custom`);
          }}
          value="custom"
        />
      </div>

      <InputText
        disabled={disabled}
        displayErrorInstantly
        field={columns}
        id="widget-layout-columns"
        label={LiveCamWidgetPage.layoutSettings.layoutSection.columnsField.label}
        labelClasses="active"
        max={50}
        min={1}
        type="number"
      />

      <InputText
        disabled={disabled}
        displayErrorInstantly
        field={rows}
        id="widget-layout-rows"
        label={LiveCamWidgetPage.layoutSettings.layoutSection.rowsField.label}
        labelClasses="active"
        max={50}
        min={1}
        type="number"
      />
    </div>
  );
};

LayoutSettingsLayout.propTypes = {
  fields: PropTypes.shape({
    columns: PropTypes.object,
    layoutType: PropTypes.object,
    rows: PropTypes.object,
  }),
};

export default injectIntl(LayoutSettingsLayout);

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { fetchSurveyQuestions } from '../../../actions/survey-generator-actions';
import { Gtm } from '../../../utils/gtm.js';

import 'materialize-css';
import { Select } from 'react-materialize';
class Questions extends Component {
  constructor(props) {
    super(props);

    this.changeFieldValue = this.changeFieldValue.bind(this);
  }

  componentDidMount() {
    const { dispatch, questionId, questionIsFetching, themes, values } = this.props;

    themes.forEach((theme) => {
      if (theme.id === values.questionTheme) {
        const themeTitle = theme.title.trim().split(' - ');

        this.changeFieldValue('questionTopic', themeTitle[0]);
        this.changeFieldValue('questionNumber', themeTitle[1]);
      }
    });

    if (!questionIsFetching && questionId) {
      dispatch(fetchSurveyQuestions(questionId));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, themes, values, questionId } = this.props;

    if (!!nextProps.questionId && !_.isEqual(questionId, nextProps.questionId)) {
      dispatch(fetchSurveyQuestions(nextProps.questionId));
    }

    if (
      !!nextProps.values.questionTopic &&
      !!nextProps.values.questionNumber &&
      (!_.isEqual(values.questionTopic, nextProps.values.questionTopic) ||
        !_.isEqual(values.questionNumber, nextProps.values.questionNumber))
    ) {
      let questionValue;

      themes.forEach((theme) => {
        if (theme.title.trim() === [nextProps.values.questionTopic, nextProps.values.questionNumber].join(' - ')) {
          questionValue = theme.id;
        }
      });

      if (!!questionValue && nextProps.values.questionTheme !== questionValue) {
        this.changeFieldValue('questionTheme', questionValue);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      fields: { questionNumber, questionTheme, questionTopic },
      themes,
      questionIsFetching,
    } = this.props;

    return (
      !_.isEqual(themes, nextProps.themes) ||
      !_.isEqual(questionTheme, nextProps.fields.questionTheme) ||
      !_.isEqual(questionTopic, nextProps.fields.questionTopic) ||
      !_.isEqual(questionNumber, nextProps.fields.questionNumber) ||
      !_.isEqual(questionIsFetching, nextProps.questionIsFetching)
    );
  }

  changeFieldValue(field, value) {
    const { dispatch } = this.props;

    dispatch({
      type: 'redux-form/CHANGE',
      form: 'basicConfiguration',
      field,
      value,
    });
  }

  handleGtmEventQuestion(e) {
    Gtm.event('survey machine', 'Click', `questionnaire theme dropdown - ${e.target.value}`);
  }

  handleGtmEventNbQuestions(e) {
    Gtm.event('survey machine', 'Click', `number of questions dropdown - ${e.target.value}`);
  }

  render() {
    const { fields, values, themes, intl } = this.props;
    let numberArray = [];

    const topicArray = _.uniq(
      themes.map((theme) => {
        const themeExploded = theme.title.split(' - ');
        const qTheme = themeExploded[0].trim();
        const qNumber = themeExploded[1] ? themeExploded[1].trim() : themeExploded[1];

        if (!!values.questionTopic && qNumber && values.questionTopic.indexOf(qTheme) >= 0) {
          numberArray.push(qNumber);
        }

        return qTheme;
      })
    );

    numberArray = _.uniq(numberArray);

    return (
      <div className="col-md-3 config-section">
        <div className="row">
          <div className="col-md-12">
            <h2>Questions</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Select
              id="questionTopic"
              label={messages.surveyGeneratorFormQuestionTheme.description}
              onChange={(e) => {
                fields.questionTopic.onChange(e.target.value);
                this.handleGtmEventQuestion(e);
              }}
              tabIndex={10}
              value={fields.questionTopic.value}
            >
              <option disabled value="">
                {intl.formatMessage(messages.genericTextChoose)}
              </option>
              {topicArray
                .filter((topic) => topic.match(/.{1,}\(.{1,}\)/))
                .map((topic, index) => (
                  <option key={`questionTopic-${index}`} value={topic}>
                    {topic}
                  </option>
                ))}
            </Select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Select
              disabled={numberArray.length === 0}
              id="questionNumber"
              label={messages.surveyGeneratorFormNumberOfQuestions.description}
              onChange={(e) => {
                fields.questionNumber.onChange(e.target.value);
                this.handleGtmEventNbQuestions(e);
              }}
              tabIndex={15}
              value={fields.questionNumber.value}
            >
              <option disabled value="">
                {intl.formatMessage(messages.genericTextChoose)}
              </option>
              {numberArray.map((number, index) => (
                <option key={`questionNumber-${index}`} value={number}>
                  {number}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

Questions.propTypes = {
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  themes: PropTypes.array,
  values: PropTypes.object.isRequired,
};

export default injectIntl(Questions);

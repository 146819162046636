import { basicLengthAndCharValidation } from '../../../utils/helpers';
import consts from '../../../utils/consts';
const { WEBSITE_WITHOUT_PROTOCOL } = consts.REGEXES;

export default (values) => {
  const errors = {};

  if (!values.website || values.website === '') {
    errors.website = 'Please enter the website URL.';
  } else if (!values.website.match(WEBSITE_WITHOUT_PROTOCOL)) {
    errors.website = 'You must provide a valid URL.';
  }

  if (values.website && !values.options) {
    errors.options = 'Please select an option.';
  }

  if (values.additionalInfo) {
    errors.additionalInfo = basicLengthAndCharValidation(values.additionalInfo, 2, 255);
  }
  return errors;
};

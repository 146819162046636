import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const {
  ACTIONS: { GAMIFICATION },
} = consts;

const requestGamificationEvents = () => ({
  type: GAMIFICATION.REQUEST_EVENTS,
});
const receiveGamificationEvents = (payload) => ({
  type: GAMIFICATION.RECEIVE_EVENTS,
  payload,
});

const requestGamificationBadge = () => ({
  type: GAMIFICATION.REQUEST_BADGES,
});
const receiveGamificationBadge = (payload) => ({
  type: GAMIFICATION.RECEIVE_BADGES,
  payload,
});
export const fetchGamificationEvents = (isOverride) => (dispatch) =>
  new Promise((fulfill, reject) => {
    request
      .post(`${API_URL}/gamification/events`)
      .send({
        type: 'login',
        isOverride: !!isOverride,
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body;
          dispatch(receiveGamificationEvents(data));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestGamificationEvents());
  });

export const fetchGamificationBadge = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    request
      .get(`${API_URL}/gamification/badges`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body;
          dispatch(receiveGamificationBadge(data));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestGamificationBadge());
  });

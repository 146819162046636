const RestrictedIcon = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <title>Restricted Icon</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Calque 1" id="Calque_1-2">
        <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0ZM2,10a8,8,0,0,1,8-8,7.9,7.9,0,0,1,4.9,1.69L3.69,14.9A7.9,7.9,0,0,1,2,10Zm8,8a7.9,7.9,0,0,1-4.9-1.69L16.31,5.1A7.9,7.9,0,0,1,18,10,8,8,0,0,1,10,18Z" />
      </g>
    </g>
  </svg>
);

export default RestrictedIcon;

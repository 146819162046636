import { useEffect } from 'react';
import { connect } from 'react-redux';
import PersonForm from '../../../components/Profile/PersonForm.react';
import CompanyForm from '../../../components/Profile/CompanyForm.react';
import { AffiliateTypeEnum } from '../../../enum/AffiliateTypeEnum';

const BeneficarySection = ({
  accountType,
  dispatch,
  affCompany,
  validationStatus,
  listValidationStatus,
  parentRoute,
  personBeneficiarySubmitRef,
  companyBeneficiarySubmitRef,
}) => {
  useEffect(() => {
    listValidationStatus.filter((status) => status.id === validationStatus?.validation_status_id);
  }, [validationStatus?.validation_status_id]);

  return (
    <div>
      <h1>Beneficiary information</h1>

      {accountType?.toUpperCase() !== AffiliateTypeEnum.COMPANY && (
        <PersonForm dispatch={dispatch} parentRoute={parentRoute} submitRef={personBeneficiarySubmitRef} />
      )}

      {accountType?.toUpperCase() === AffiliateTypeEnum.COMPANY && (
        <CompanyForm
          affCompany={affCompany}
          dispatch={dispatch}
          parentRoute={parentRoute}
          submitRef={companyBeneficiarySubmitRef}
        />
      )}
    </div>
  );
};

export default connect((state) => ({
  listValidationStatus: state?.application?.lists?.validationStatus,
  validationStatus: state?.profile?.data?.affValidationStatus,
  accountType: state?.profile?.data?.customProfile?.affiliate?.affiliate_type?.type,
  affCompany: state?.profile?.data?.affCompany,
}))(BeneficarySection);

import { OFFER_TAG_TYPES } from './data';

export const OfferListingTagTooltipContent = ({ tag }) => {
  switch (tag) {
    case OFFER_TAG_TYPES.NEW:
      return 'Offer added less than a month';
    case OFFER_TAG_TYPES.EXCLUSIVE:
      return 'Exclusive offers are those offers that are only found on CrakRevenue';
    case OFFER_TAG_TYPES.TOP_OFFER:
      return 'The most performant Offers in the CrakRevenue Platform';
    case OFFER_TAG_TYPES.HOT_PICK:
      return 'Are those offers with great potential selected by our experts';
    default:
      return '';
  }
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPromoTools } from './PromoTools-Actions/PromoTools-Actions.js';

import PromoToolsCard from '../../components/PromoTools/PromoToolsCard/PromoToolsCard.js';
import { closeModal } from '../../components/Modal/modal-actions/modal-actions.js';

const PromoTools = ({ promoTools, dispatch, affUserInfos, affiliateManager }) => {
  useEffect(() => {
    dispatch(fetchPromoTools());

    return () => {
      dispatch(closeModal());
    };
  }, []);

  return (
    <div className="promoTools container-fluid app-container">
      <div className="promoTools__header">
        <h1 className="primary-color title">Promo Tools</h1>
        <p className="subtitle">Click on a promo tool to learn more</p>
      </div>
      <div className="promoTools__list">
        {promoTools
          ? promoTools.map((promoTool) => (
              <PromoToolsCard
                affUserInfos={affUserInfos}
                affiliateManager={affiliateManager}
                dispatch={dispatch}
                key={promoTool.title}
                promoTool={promoTool}
              />
            ))
          : null}
      </div>
    </div>
  );
};

PromoTools.propTypes = {
  affUserInfos: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  promoTools: PropTypes.arrayOf(PropTypes.object),
};

export default connect((state) => ({
  affiliateManager: state.profile.data.affiliateManager,
  promoTools: state.promoTools.promoTools,
  affUserInfos: state.profile.data.affUserInfos,
}))(PromoTools);

const Cam = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M9.42292 3.65385C6.85184 3.65385 4.7681 5.73812 4.7681 8.30938C4.7681 10.8807 6.85184 12.9649 9.42292 12.9649C11.9941 12.9649 14.0785 10.8805 14.0785 8.30938C14.0785 5.73822 11.9941 3.65385 9.42292 3.65385ZM3.61426 8.30938C3.61426 5.10108 6.21437 2.5 9.42292 2.5C12.6313 2.5 15.2323 5.10097 15.2323 8.30938C15.2323 11.5178 12.6313 14.1188 9.42292 14.1188C6.21437 14.1188 3.61426 11.5177 3.61426 8.30938Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.7138 12.3493C11.9905 12.1912 12.3429 12.2874 12.5009 12.5641L14.0304 15.2418C14.6007 16.2491 13.8728 17.4995 12.7125 17.4995H6.13656C4.9689 17.4995 4.23926 16.2481 4.81994 15.2328L4.82088 15.2311L6.35523 12.5689C6.51433 12.2928 6.8671 12.198 7.14315 12.3571C7.41921 12.5162 7.51403 12.869 7.35493 13.1451L5.82117 15.8063C5.6825 16.0498 5.85246 16.3457 6.13656 16.3457H12.7125C12.9889 16.3457 13.1607 16.0488 13.0267 15.8109L13.0262 15.8101L11.499 13.1364C11.341 12.8597 11.4371 12.5073 11.7138 12.3493Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.42416 7.19657C8.80947 7.19657 8.31108 7.69496 8.31108 8.30966C8.31108 8.92467 8.80977 9.42352 9.42416 9.42352C10.0395 9.42352 10.538 8.92452 10.538 8.30966C10.538 7.69513 10.0398 7.19657 9.42416 7.19657ZM7.15723 8.30966C7.15723 7.05771 8.17223 6.04272 9.42416 6.04272C10.6767 6.04272 11.6918 7.05755 11.6918 8.30966C11.6918 9.56144 10.677 10.5774 9.42416 10.5774C8.17185 10.5774 7.15723 9.56129 7.15723 8.30966Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Cam;

const LiveCamWidget = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88518 2C6.58066 2 3.90869 4.68288 3.90869 7.9874C3.90869 8.72901 4.03956 9.4488 4.30131 10.1032C4.62849 9.9941 4.97748 9.93958 5.33738 9.93958H5.40281C5.14107 9.33974 4.99929 8.67448 4.99929 7.9874C4.99929 5.29361 7.1914 3.0906 9.88518 3.0906C12.579 3.0906 14.782 5.29361 14.782 7.9874C14.782 8.67448 14.6402 9.33974 14.3785 9.93958H14.433C14.7929 9.93958 15.1528 9.99411 15.48 10.1141C15.7308 9.4488 15.8726 8.72901 15.8726 7.9874C15.8726 4.68288 13.1897 2 9.88518 2Z"
      fill="white"
    />
    <path
      d="M9.88515 5.68616C8.62006 5.68616 7.58398 6.71132 7.58398 7.98732C7.58398 8.80528 8.02022 9.53598 8.67459 9.9395H11.0957C11.761 9.53598 12.1972 8.80528 12.1972 7.98732C12.1972 6.71132 11.1612 5.68616 9.88515 5.68616ZM9.88515 9.19789C9.21989 9.19789 8.67459 8.65259 8.67459 7.98732C8.67459 7.32206 9.21989 6.77676 9.88515 6.77676C10.5504 6.77676 11.1066 7.31115 11.1066 7.98732C11.1066 8.6635 10.5613 9.19789 9.88515 9.19789Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M12.4485 4.82458C12.1213 4.82458 11.8486 5.09724 11.8486 5.42442C11.8486 5.7516 12.1213 6.02425 12.4485 6.02425C12.7756 6.02425 13.0483 5.7516 13.0483 5.42442C13.0483 5.09724 12.7756 4.82458 12.4485 4.82458Z"
      fill="white"
      fillRule="evenodd"
    />
    <path d="M5.70774 14.9563V12.6879H5.0752V15.4907H6.83106V14.9563H5.70774Z" fill="white" />
    <path
      d="M15.4798 10.1141C15.1526 9.99411 14.7927 9.93958 14.4329 9.93958H5.33724C4.97734 9.93958 4.62835 9.9941 4.30117 10.1032C2.95973 10.5394 2 11.7936 2 13.2659V14.9127C2 16.7558 3.49412 18.25 5.33724 18.25H14.4329C16.276 18.25 17.7701 16.7558 17.7701 14.9127V13.2659C17.7701 11.7936 16.8104 10.5503 15.4798 10.1141ZM16.7122 14.9127C16.7122 16.1669 15.687 17.1921 14.4329 17.1921H5.33724C4.08305 17.1921 3.05788 16.1669 3.05788 14.9127V13.2659C3.05788 12.208 3.77768 11.3246 4.75922 11.0738C4.94462 11.0193 5.14093 10.9975 5.33724 10.9975H14.4329C14.6292 10.9975 14.8255 11.0193 15.0109 11.0738C15.9924 11.3246 16.7122 12.208 16.7122 13.2659V14.9127Z"
      fill="white"
    />
    <path d="M8.4667 12.6879H7.82324V15.4907H8.4667V12.6879Z" fill="white" />
    <path
      d="M11.9788 12.6879L11.6407 13.713L11.0409 15.4907H10.2992L9.80847 13.9748L9.44857 12.8624L9.39404 12.6879H10.092L10.1466 12.8733L10.4301 13.8766C10.4301 13.8766 10.4301 13.9202 10.4519 13.9421C10.5392 14.2474 10.6155 14.5528 10.681 14.88H10.7028C10.7682 14.5419 10.8554 14.2038 10.9536 13.8875L11.3135 12.6879H11.9788Z"
      fill="white"
    />
    <path
      d="M14.6943 14.9781V15.4907H12.9058V12.6879H14.6398V13.2114H13.5492V13.7894H14.5744V14.3129H13.5492V14.9781H14.6943Z"
      fill="white"
    />
  </svg>
);

export default LiveCamWidget;

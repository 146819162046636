import React, { useCallback, useEffect, useState } from 'react';
import 'materialize-css';
import { Select } from 'react-materialize';
import WarningIcon from '../../../reusables/svg/WarningIcon.react';
import Tab from './Tab.js';
import InputBox from './InputBox.js';
import { animationTabs, prestitialPositions, verticalPositionTabs } from './values.js';
import Tooltip from '../../ReactTooltip.js';
import { useAdToolsContext } from '../CreativesAdTools';
import Position from './Position';
import { connect } from 'react-redux';
import { openModal } from '../../../Modal/modal-actions/modal-actions';
import PlusIcon2 from '../../svg/plusIcon2.react';

const desinationSelectBoxClass = 'destinationSelectBox';

const Config = React.memo(({ dispatch, offer }) => {
  const {
    adToolType,
    text,
    landingPages,
    postitialState,
    setPostitialState,
    offerState,
    setOfferState,
    dataAvailable,
    copy,
    gtmEvent,
    isReferrals,
    isAutoOptimized,
    skinConfigurations,
    fields,
  } = useAdToolsContext();

  const [viewMode, setViewMode] = useState('desktop');

  const devices = [
    { name: 'desktop', icon: 'desktop_windows' },
    { name: 'mobile', icon: 'phone_iphone' },
  ];

  const selectNewLanding = useCallback(
    (e) => {
      const id = e.target.value;
      const newLanding = landingPages.find((landing) => landing.id === id);
      setOfferState((state) => ({
        ...state,
        selectedOffer: newLanding,
      }));
      gtmEvent(`destination - ${id}`);
    },
    [landingPages]
  );

  useEffect(() => {
    if (adToolType === 'postitial') {
      const newLanding = landingPages.find((landing) => landing.id === fields.url_id.value);
      setOfferState((state) => ({
        ...state,
        selectedOffer: newLanding,
      }));
    }
  }, [fields?.url_id?.value]);

  const selectedOffer = offerState?.selectedOffer;

  const destinationClick = useCallback(() => {
    const destinationSelectBox = document.querySelector(`.${desinationSelectBoxClass}`);
    const trigger = destinationSelectBox.querySelector('input');
    trigger.click();
  }, []);

  const getAvailableDevices = () => {
    return devices.filter((device) => offer?.screenshot[device.name]);
  };

  const handleViewModeChange = (e) => {
    e.preventDefault();

    const newViewMode = e.target.getAttribute('data-viewmode') || e.target.parentNode.getAttribute('data-viewmode');

    if (viewMode !== newViewMode) {
      setViewMode(newViewMode);
    }
  };

  const currentOfferUrl =
    isAutoOptimized && selectedOffer?.id === undefined
      ? landingPages?.find((o) => o.id === 'optimized')
      : selectedOffer?.id
      ? landingPages?.find((o) => o.id === selectedOffer?.id)
      : landingPages?.find((o) => o.id === '0');

  const changeCurrentOffer = (newId) => {
    const newLanding = landingPages.find((landing) => landing.id === newId);
    setOfferState((state) => ({
      ...state,
      selectedOffer: newLanding,
    }));
    gtmEvent(`destination - ${newId}`);
    if (fields.url_id && fields.url_id.onChange) {
      fields.url_id.onChange(newId);
    }
  };

  const handleOpenModal = () => {
    dispatch(
      openModal({
        name: 'OfferLandingPagesModal',
        modalProps: {
          dispatch,
          offerUrl: landingPages,
          offerScreenshots: offer?.screenshot,
          currentOfferUrl,
          availableDevices: getAvailableDevices(),
          viewMode,
          handleViewModeChange,
          isReferrals,
          skinConfigurations,
          isAutoOptimized,
          changeCurrentOffer,
          className: 'offer-landing-page-modal',
        },
      })
    );
  };

  return (
    <div className={`config ${!dataAvailable ? 'noDataAvailable' : ''}`}>
      <div className="titleBox">
        <div className="title">Configuration:</div>
        <Tooltip id="postitialTooltip" tooltip={text.tooltip}>
          <i className="material-icons icon-info">info</i>
        </Tooltip>
      </div>
      <div className="fields">
        {adToolType === 'postitial' ? (
          <>
            <div className="field">
              <label>Destination</label>
              <div className={`selectOuterBox ${desinationSelectBoxClass} ${adToolType === 'postitial' && 'buttonInputCover'}`}>
                <div
                  className="selectInputCover"
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  <span>{selectedOffer?.name}</span>
                  <PlusIcon2 />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="field">
              <label>Destination</label>
              <div className="selectOuterBox">
                <div
                  className="selectInputCover"
                  onClick={() => {
                    destinationClick();
                  }}
                >
                  <span>{selectedOffer?.name}</span>
                </div>
                <Select
                  onChange={selectNewLanding}
                  options={{
                    classes: desinationSelectBoxClass,
                  }}
                >
                  {landingPages?.map(({ name, id }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </>
        )}

        <div className="field">
          <label>Width</label>
          <InputBox value={postitialState.width} stateKey={'width'} setPostitialState={setPostitialState} />
        </div>
        <div className="field">
          <label>Height</label>
          <InputBox value={postitialState.height} stateKey={'height'} setPostitialState={setPostitialState} />
        </div>
        <div className="field">
          <label>Animation</label>
          <div className="tabsBox">
            {animationTabs.map((tab, i) => (
              <Tab
                key={i}
                parentText={'Animation'}
                {...tab}
                postitialState={postitialState}
                setPostitialState={setPostitialState}
              />
            ))}
          </div>
        </div>
        <div className="field">
          {adToolType === 'postitial' ? (
            <>
              <label>Position (Y)</label>
              <div className="tabsBox">
                {verticalPositionTabs.map((tab, i) => (
                  <Tab
                    key={i}
                    parentText={'Position (Y)'}
                    {...tab}
                    postitialState={postitialState}
                    setPostitialState={setPostitialState}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <label>Position</label>
              <div className="positionsBox">
                {prestitialPositions.map((square, i) => (
                  <Position
                    key={i}
                    parentText={'Position'}
                    {...square}
                    postitialState={postitialState}
                    setPostitialState={setPostitialState}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="btn" onClick={copy}>
        {text['copy']}
      </div>
      <div className="warning">
        <WarningIcon color={'#c99b05'} />
        <div className="textRight">
          <div className="warningTitle">{text['warning.title']}</div>
          <p>{text['warning.paragraph']}</p>
        </div>
      </div>
    </div>
  );
});

export default connect((state) => ({
  offer: state.offer.offer,
}))(Config);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import config from '../../config';
import { useEffect } from 'react';

const HYPERLINK_KEYS = ['a.support', 'a.privacy-policy', 'a.terms-and-conditions'];

const Footer = (props) => {
  const { skinConfigurations, affStatus } = props;
  const footerConfigs = SkinConfigurationsUtils.getHyperlinksConfig(skinConfigurations, 'Footer', HYPERLINK_KEYS);

  useEffect(() => {
    if (!affStatus) {
      return;
    }

    if (window.CE2 && window.CE2.identify) {
      CE2.identify(affStatus);
    } else {
      (window.CE_API || (window.CE_API = [])).push(function () {
        if (window.CE2 && window.CE2.identify) {
          CE2.identify(affStatus);
        }
      });
    }
  }, [affStatus]);

  return (
    <div className="container-fluid z-depth-1" id="site-footer">
      <div className="col-md-5 col-sm-6 copyrights">
        CrakRevenue © 2010 - {new Date().getFullYear()} All Rights Reserved&nbsp;v.{config.VERSION}
      </div>
      <nav className="col-md-5 col-md-offset-1 col-sm-5 col-sm-offset-1">
        <ul className="menu">
          {footerConfigs['a.support'].visible ? (
            <li>
              <a href={footerConfigs['a.support'].url || 'https://support.crakrevenue.com/'} rel="noreferrer" target="_blank">
                <FormattedMessage {...messages.genericTextSupport} />
              </a>
            </li>
          ) : null}
          {footerConfigs['a.privacy-policy'].visible ? (
            <li>
              <a
                href={footerConfigs['a.privacy-policy'].url || 'https://www.crakrevenue.com/privacy/'}
                rel="noreferrer"
                target="_blank"
              >
                <FormattedMessage {...messages.genericTextPrivacyPolicy} />
              </a>
            </li>
          ) : null}
          {footerConfigs['a.terms-and-conditions'].visible ? (
            <li>
              <a
                href={footerConfigs['a.terms-and-conditions'].url || 'https://www.crakrevenue.com/tos/'}
                rel="noreferrer"
                target="_blank"
              >
                <FormattedMessage {...messages.genericTextTermsAndConditions} />
              </a>
            </li>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};

Footer.propTypes = {
  skinConfigurations: PropTypes.object.isRequired,
  affStatus: PropTypes.string,
};

export default connect((state) => {
  return {
    skinConfigurations: state.skinConfigurations.data,
    affStatus: state?.profile?.data?.affStatus?.rank_status_name,
  };
})(Footer);

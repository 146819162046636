/**
 * @file Whale
 * @author Pierre-Luc Gagné
 * @version 0.0.1
 */

function ColorTemplate() {
  return (
    <svg height="24px" viewBox="0 0 24 24" width="24px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#00BD00" transform="translate(-100.000000, -261.000000)">
          <g transform="translate(85.000000, 241.000000)">
            <g>
              <g transform="translate(15.000000, 20.000000)">
                <path d="M0,18 L22,18 L22,0 L0,0 L0,18 Z M1.375,16.6153846 L20.625,16.6153846 L20.625,2.76923077 L1.375,2.76923077 L1.375,16.6153846 Z" />
                <polygon points="12 7 19 7 19 6 12 6" />
                <polygon points="12 9 19 9 19 8 12 8" />
                <polygon points="12 11 16 11 16 10 12 10" />
                <polygon points="3 13 10 13 10 6 3 6" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ColorTemplate;

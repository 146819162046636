import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import ReferralsCard from './ReferralsCard.react';
import { LoadingBar, Pagination } from '../../components/reusables';

const getFilteredCommissions = (referrals) => referrals.filter((referral) => parseFloat(referral.Stat.amount) > 0);

class ReferralsCards extends Component {
  constructor(props) {
    super(props);

    this.getCards = this.getCards.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsChange = this.handleRowsChange.bind(this);
    this.setStateForTab = this.setStateForTab.bind(this);

    this.state = {
      currentPage: 1,
      limit: 25,
      pageCount: 1,
      total: 0,
    };
  }

  componentDidMount() {
    const { activeTab, referrals } = this.props;

    this.setStateForTab(referrals, activeTab);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeTab, referrals } = this.props;

    if (activeTab !== nextProps.activeTab || !_.isEqual(referrals, nextProps.referrals)) {
      this.setStateForTab(nextProps.referrals, nextProps.activeTab);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { activeTab, referrals, referralsIsFetching } = this.props;
    const { currentPage, limit, pageCount, total } = this.state;

    return (
      activeTab !== nextProps.activeTab ||
      currentPage !== nextState.currentPage ||
      limit !== nextState.limit ||
      pageCount !== nextState.pageCount ||
      total !== nextState.total ||
      referralsIsFetching !== nextProps.referralsIsFetching ||
      !_.isEqual(referrals, nextProps.referrals)
    );
  }

  setStateForTab(referrals, activeTab) {
    const { limit } = this.state;

    if (activeTab === 'affiliates') {
      const refAffiliates = {};

      referrals.forEach((referral) => {
        if (Object.keys(refAffiliates).indexOf(referral.ReferredAffiliate.company) === -1) {
          refAffiliates[referral.ReferredAffiliate.company] = referral.ReferredAffiliate;
        }
      });

      const totalLength = Object.keys(refAffiliates).length;

      this.setState({
        total: totalLength,
        pageCount: Math.ceil(totalLength / limit),
      });
    } else {
      const referralsConcat = {};
      const referralsArray = [];
      referrals
        .filter((referral) => parseFloat(referral.Stat.amount) > 0)
        .forEach((ref) => {
          if (Object.keys(referralsConcat).indexOf(ref.ReferredAffiliate.company) === -1) {
            referralsConcat[ref.ReferredAffiliate.company] = ref;
          } else {
            referralsConcat[ref.ReferredAffiliate.company].Stat.amount =
              parseFloat(referralsConcat[ref.ReferredAffiliate.company].Stat.amount) + parseFloat(ref.Stat.amount);
          }
        });

      Object.keys(referralsConcat).map((company) => {
        referralsArray.push(referralsConcat[company]);
      });

      const totalLength = referralsArray.length;

      this.setState({
        total: totalLength,
        pageCount: Math.ceil(totalLength / limit),
      });
    }
  }

  getCards(referrals) {
    const { activeTab } = this.props;
    const { currentPage, limit } = this.state;

    return referrals
      .filter((referral, index) => index >= (currentPage - 1) * limit && index < currentPage * limit)
      .map((affiliate, index) => {
        const {
          ReferredAffiliate: { company, date_added },
          Stat: { amount },
        } = affiliate;

        return (
          <ReferralsCard
            activeTab={activeTab}
            commissionAmount={amount}
            companyName={company}
            dateAdded={date_added}
            key={`${activeTab}-${company}-${index}`}
          />
        );
      });
  }

  handlePageChange(page) {
    this.setState({ currentPage: page });
  }

  handleRowsChange(value) {
    const { total } = this.state;

    this.setState({
      currentPage: 1,
      pageCount: Math.ceil(total / parseInt(value, 10)),
      limit: parseInt(value, 10),
    });
  }

  render() {
    const { activeTab, referrals, referralsIsFetching } = this.props;
    const { currentPage, limit, pageCount, total } = this.state;

    const refsComp = this.getCards(activeTab === 'affiliates' ? referrals : getFilteredCommissions(referrals));

    return (
      <div id="referrals-cards">
        {referralsIsFetching ? (
          <div className="loading-container">
            <LoadingBar />
          </div>
        ) : (
          <div>
            {refsComp.length < 1 ? (
              <p className="noResults">
                {activeTab === 'affiliates' ? (
                  <FormattedHTMLMessage {...messages.referralsNoReferrals} />
                ) : (
                  <FormattedHTMLMessage {...messages.referralsNoCommissions} />
                )}
              </p>
            ) : (
              <div style={{ display: 'grid' }}>
                <Pagination
                  choices={[25, 50, 100, 200]}
                  className="pull-right"
                  currentPage={currentPage}
                  handlePageChange={this.handlePageChange}
                  handleRowsChange={this.handleRowsChange}
                  pageCount={pageCount}
                  rowsPerPage={limit}
                  selectId="rowsPerPageTop"
                  totalResults={total}
                />
                <div className="cards">{refsComp}</div>
                <Pagination
                  choices={[25, 50, 100, 200]}
                  className="pull-right"
                  currentPage={currentPage}
                  handlePageChange={this.handlePageChange}
                  handleRowsChange={this.handleRowsChange}
                  pageCount={pageCount}
                  rowsPerPage={limit}
                  selectId="rowsPerPageBottom"
                  totalResults={total}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ReferralsCards.propTypes = {
  activeTab: PropTypes.string.isRequired,
  referrals: PropTypes.array.isRequired,
  referralsIsFetching: PropTypes.bool.isRequired,
};

export default ReferralsCards;

import React from 'react';
import { MAIN_DROPDOWN_NAME } from './values';
import { useOffersFilterContext } from './OfferFilterProvider';

const ArrowMobile = React.memo(({ field, backArrow }) => {
  const { activateOuterBox } = useOffersFilterContext();

  const goBack = () => {
    const goBackTo = field === MAIN_DROPDOWN_NAME ? '' : MAIN_DROPDOWN_NAME;

    activateOuterBox({ field: goBackTo });
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`arrowMobile ${backArrow ? 'backPanelArrow' : ''}`}
      viewBox="0 0 12.711 7.128"
      onClick={backArrow ? goBack : null}
    >
      <g id="chacked" transform="translate(1.061 1.061)">
        <g id="Arrow---Down-Circle">
          <path
            id="Stroke-3"
            d="M6.529,8.558l5.295,5.318,5.295-5.318"
            transform="translate(-6.529 -8.558)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
});

export default ArrowMobile;

import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { getCurrentPeriod } from '../../utils/helpers';
import { Info } from '../reusables';
import Check from './svg/Check.react';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { connect } from 'react-redux';

import messages from '../../i18n/base-en.js';
import React from 'react';
import Tooltip from './Tooltip.react';

const computeEarnings = (
  currentPeriodPayout,
  lastPeriodPayout,
  nextPaymentAmount,
  hasReachedMinimumPayout,
  minimumPayoutAmount,
  lastPeriodHasInvoice
) => {
  const futureCarriedOverEarnings = lastPeriodHasInvoice ? 0 : lastPeriodPayout;
  return hasReachedMinimumPayout || futureCarriedOverEarnings + nextPaymentAmount >= minimumPayoutAmount
    ? currentPeriodPayout
    : currentPeriodPayout + futureCarriedOverEarnings + nextPaymentAmount;
};

const PaymentHistoryGraph = (props) => {
  const {
    className = '',
    currentPeriodPayout,
    intl,
    minimumPayoutAmount,
    nextPaymentAmount,
    hasReachedMinimumPayout,
    paymentTerm,
    lastPeriodPayout,
    lastPeriodHasInvoice,
    isCurrentPeriodPayout,
    skinConfigurations,
  } = props;
  const skin = new SkinConfigurationsUtils(skinConfigurations);

  let showThermometerInfoIcon = true;

  if (skin.getComponentParameters('Header').find((p) => p.key === 'ThermometreInfoIcon')) {
    showThermometerInfoIcon = skin.getPropertyValue('Header', 'ThermometreInfoIcon', 'visible') === 'true' ? true : false;
  }

  let currentEarnings = 0;

  if (isCurrentPeriodPayout) {
    currentEarnings = computeEarnings(
      currentPeriodPayout,
      lastPeriodPayout,
      nextPaymentAmount,
      hasReachedMinimumPayout,
      minimumPayoutAmount,
      lastPeriodHasInvoice
    );
  }

  const completedPercentage = (currentEarnings / minimumPayoutAmount) * 100;

  const daysLeft = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd).startOf('day').diff(moment().startOf('day'), 'days');

  const getFinalColor = (completionPercent) => {
    const colorGradient = [
      '#cb0a1c',
      '#cd3712',
      '#cd6300',
      '#c68800',
      '#c39100',
      '#b9ab00',
      '#b4b300',
      '#aac21d',
      '#9ed138',
      '#87e764',
      '#00BD00',
    ];
    return colorGradient[Math.floor(completionPercent / 10)];
  };

  return (
    <div className={`payment-history-graph ${className}`}>
      <div className="summary">
        <div className="caption">
          <FormattedMessage {...messages.paymentHistoryGraphCaption} />
        </div>
        <div className="gauge">
          <div className="gauge-ticks">
            <span />
            <span />
            <span />
            <span />
          </div>
          <div className="gauge-bkg" />
          <div
            className="gauge-fill"
            style={{
              width: `${completedPercentage}%`,
              '--finalColor': isCurrentPeriodPayout ? getFinalColor(completedPercentage) : '',
            }}
          />
        </div>
      </div>
      <div className="details">
        <div className={`days-left ${completedPercentage < 100 && daysLeft <= 5 ? 'hurry-up' : ''}`}>
          <FormattedMessage {...messages.paymentHistoryGraphDaysLeft} values={{ number: daysLeft }} />
          {showThermometerInfoIcon ? (
            <Tooltip
              closeIcon
              content={
                <p>
                  {intl.formatMessage(messages.paymentHistoryGraphTooltip)}
                  {paymentTerm ? <strong> {paymentTerm}</strong> : null}.
                  <br />
                  <a href="https://support.crakrevenue.com/knowledge-base/minimum-payout-terms/" rel="noreferrer" target="_blank">
                    Learn more
                  </a>
                </p>
              }
              position="bottom"
            >
              <Info className="more-info" />
            </Tooltip>
          ) : null}
        </div>
        <div className={`minimum-payout ${completedPercentage >= 100 ? 'completed' : ''}`}>
          <Check />
          {minimumPayoutAmount ? (
            <span>
              <FormattedNumber currency="USD" style="currency" value={currentEarnings} />
              <span> / </span>
              <FormattedNumber currency="USD" style="currency" value={minimumPayoutAmount} />
            </span>
          ) : (
            <FormattedMessage {...messages.paymentHistoryGraphMissingBillingInfo} />
          )}
        </div>
      </div>
    </div>
  );
};

PaymentHistoryGraph.propTypes = {
  className: PropTypes.string,
  currentPeriodPayout: PropTypes.number,
  hasReachedMinimumPayout: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  isCurrentPeriodPayout: PropTypes.any,
  lastPeriodHasInvoice: PropTypes.bool,
  lastPeriodPayout: PropTypes.number,
  minimumPayoutAmount: PropTypes.number,
  nextPaymentAmount: PropTypes.number,
  paymentTerm: PropTypes.string,
};

export default connect((state) => {
  const { skinConfigurations } = state;

  return {
    skinConfigurations: skinConfigurations.data,
  };
})(injectIntl(PaymentHistoryGraph));

import React from 'react';
import { useCreativesBanners } from '../CreativesBanners';
import { CLEAR_ALL_TEXT, createFieldDisplayValue, createFieldTitle, isTrendingCreativesTab } from './values';

const BannerFilter = React.memo(
  ({ dropdowns, field, openDropdown, clearAll, setFilter, filterOptions, trendingFilter, displayValue, clearAllText }) => {
    let { activeTab, openFilter } = useCreativesBanners();

    if (isTrendingCreativesTab(activeTab) && field === 'language') {
      return null;
    }

    return (
      <div
        className="selectableTab"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="trigger" onClick={() => openDropdown({ field })}>
          <div className="text">{createFieldDisplayValue({ field, value: displayValue })}</div>
          <svg xmlns="http://www.w3.org/2000/svg" class="arrowDown" viewBox="0 0 7.547 6.289">
            <g id="arrow-down" transform="translate(-6 -7)">
              <g id="Arrow---Down-2" transform="translate(6 7)">
                <path d="M3.062,6.057c-.036-.036-.192-.17-.321-.295A13.64,13.64,0,0,1,.211,2.117,2.9,2.9,0,0,1,0,1.376,1.216,1.216,0,0,1,.137.813a1.178,1.178,0,0,1,.569-.5A6.188,6.188,0,0,1,1.375.152,15.08,15.08,0,0,1,3.768,0a17.435,17.435,0,0,1,2.32.134,5.342,5.342,0,0,1,.835.214,1.123,1.123,0,0,1,.624.992v.036a3.068,3.068,0,0,1-.257.831A13.641,13.641,0,0,1,4.8,5.771a3.559,3.559,0,0,1-.348.3,1.122,1.122,0,0,1-.669.214,1.179,1.179,0,0,1-.716-.232"></path>
              </g>
            </g>
          </svg>
          <div class={`activeCircle ${!trendingFilter && filterOptions?.[field] ? 'active' : ''}`}></div>
        </div>
        <div className={`dropdownOuterBox ${field} ${openFilter === field ? 'active' : ''}`}>
          <div className={`dropdownTitle`}>
            <div className="text">{createFieldTitle({ field })}</div>
            <div className="clear" onClick={() => clearAll({ field })}>
              {clearAllText || CLEAR_ALL_TEXT[field]}
            </div>
          </div>

          <div className="scrollable">
            {!dropdowns[field]
              ? ''
              : dropdowns[field].map(({ key, value }) => (
                  <div
                    class={`selectableTab ${filterOptions?.[field] === key ? 'active' : ''}`}
                    onClick={() => setFilter({ field, key })}
                  >
                    <div class="text">{value}</div>
                    <svg
                      class="check"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"></path>
                    </svg>
                  </div>
                ))}
          </div>
        </div>
      </div>
    );
  }
);

export default BannerFilter;

import offer from './apiql-query-builder/Offer';
import offers from './apiql-query-builder/Offers';
import topOffers from './apiql-query-builder/TopOffers';
import searchFields from './apiql-query-builder/SearchFields';
import achConfigurations from './apiql-query-builder/AchConfigurations';
import { skinConfigurations, globalSkinConfigurations } from './apiql-query-builder/SkinConfigurations';

// Used to merge multiple query in one action, accept an object {QueryName:params, QueryName2:params}

export default (queries) =>
  `{${Object.keys(queries)
    .map((key) => {
      switch (key) {
        case 'Offer':
          return offer(queries[key]);
        case 'Offers':
          return offers(queries[key]);
        case 'TopOffers':
          return topOffers(queries[key]);
        case 'SearchFields':
          return searchFields();
        case 'AchConfigurations':
          return achConfigurations(queries[key]);
        case 'GlobalSkinConfigurations':
          return globalSkinConfigurations();
        case 'SkinConfigurations':
          return skinConfigurations();
        default:
          return;
      }
    })
    .join(' ')}}`;

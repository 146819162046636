import { Component } from 'react';
import PropTypes from 'prop-types';

import messages from '../../../i18n/base-en.js';

import { CodeInput } from '../../../components/reusables';
import Tooltip from '../../../components/reusables/ReactTooltip';
import { Gtm } from '../../../utils/gtm';

// TODO: remove jQuery from project
import $ from 'jquery';

const { WidgetSection } = messages;

const getSubstitutionCode = (code) => code.replace('{tracking_link}', '{src}').replace('{etracking_link}', '{esrc}');
const getCode = (subsitutionCode, source) =>
  subsitutionCode.replace('{src}', source).replace('{esrc}', encodeURIComponent(source));

class SurveyGenerationModalTabs extends Component {
  constructor(props) {
    super(props);

    this.handleTabChange = this.handleTabChange.bind(this);

    this.tabs = [
      {
        title: 'Embed code',
        code: '<iframe src="{src}" width="100%" height="100%"></iframe>',
        eventLabel: 'Console-Survey-Widget-Embed',
      },
      {
        title: 'Pop-under Script',
        code: getSubstitutionCode(props.creativeScript || ''),
        eventLabel: 'Console-Survey-Widget-Pop',
      },
      {
        title: 'Direct Link',
        code: '{src}',
        eventLabel: 'Console-Survey-Widget-Direct-Link',
      },
    ];
    this.state = {
      tabIndex: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { creativeId, creativeScript, src } = this.props;
    const { tabIndex } = this.state;

    if (!!nextProps.creativeScript && creativeScript !== nextProps.creativeScript) {
      this.tabs[1].code = getSubstitutionCode(nextProps.creativeScript);
    }
    if (nextProps.src && creativeId && src !== nextProps.src) {
      $('#codeInput').val(getCode(this.tabs[tabIndex].code, nextProps.src));
    }
  }

  handleTabChange(e) {
    e.preventDefault();
    const { creativeId, src } = this.props;

    const tabIndex = parseInt(e.target.getAttribute('data-index'), 10);
    this.setState({ tabIndex });

    const { code, eventLabel } = this.tabs[tabIndex];
    Gtm.event('UI Component', 'Click', eventLabel);

    if (!!src && !!creativeId) {
      $('#codeInput').val(getCode(code, src));
    } else {
      $('#codeInput').val('You must generate before');
    }
  }

  render() {
    const { disabled, intl, loading } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className="sub-cont row">
        <div className="col-lg-12">
          {this.tabs.length > 1 && (
            <ul className="widgets-tabs">
              {this.tabs.map((tab, index) => (
                <li
                  className={tabIndex === index ? 'active' : undefined}
                  data-index={index}
                  key={`modal-tab-${index}`}
                  onClick={this.handleTabChange}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          )}
          <Tooltip
            position="top"
            tooltip={disabled ? intl.formatMessage(WidgetSection.generateCode.warnings.applyChangesBefore) : undefined}
          >
            <CodeInput
              codeId="codeInput"
              disabled={disabled}
              loading={loading}
              title={this.tabs.filter((tab, index) => index === tabIndex).map((tab) => tab.title)}
            />
          </Tooltip>
        </div>
      </div>
    );
  }
}

SurveyGenerationModalTabs.defaultProps = {
  creativeScript: '',
};

SurveyGenerationModalTabs.propTypes = {
  creativeId: PropTypes.string.isRequired,
  creativeScript: PropTypes.string,
  disabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  src: PropTypes.string.isRequired,
};

export default SurveyGenerationModalTabs;

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  paymentsList: [],
  isFetchingPaymentsList: false,
  paymentHistoryTimestamp: null,
};

export const paymentHistory = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PAYMENT_HISTORY:
      return {
        ...state,
        isFetchingPaymentsList: true,
      };

    case ACTIONS.RECEIVE_PAYMENT_HISTORY:
      return {
        ...state,
        paymentsList: action.payments,
        isFetchingPaymentsList: false,
        paymentHistoryTimestamp: Date.now(),
      };

    case ACTIONS.FAILED_TO_RECEIVE_PAYMENT_HISTORY:
      return {
        ...state,
        isFetchingPaymentsList: false,
        paymentHistoryTimestamp: Date.now(),
      };

    case ACTIONS.REQUEST_AFFILIATE_INVOICE:
      return {
        ...state,
        isFetchingInvoice: true,
      };

    case ACTIONS.RECEIVE_AFFILIATE_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
        isFetchingInvoice: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_AFFILIATE_INVOICE:
      return {
        ...state,
        isFetchingInvoice: false,
      };

    default:
      return state;
  }
};

const CustomCreativesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" fill="none">
    <g clip-path="url(#clip0_327_33039)">
      <circle cx="38.5" cy="38.5" r="38.5" fill="white" />
      <path
        d="M53.5347 50.419C57.2691 50.419 60.2964 47.3916 60.2964 43.6572C60.2964 39.9228 57.2691 36.8955 53.5347 36.8955C49.8003 36.8955 46.7729 39.9228 46.7729 43.6572C46.7729 47.3916 49.8003 50.419 53.5347 50.419Z"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.6585 47.4313C55.7432 47.4313 57.4332 45.7413 57.4332 43.6566C57.4332 41.5718 55.7432 39.8818 53.6585 39.8818C51.5738 39.8818 49.8838 41.5718 49.8838 43.6566C49.8838 45.7413 51.5738 47.4313 53.6585 47.4313Z"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M53.6733 33.8154V36.8949" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M53.6733 50.5791V53.6585" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M63.4998 43.6709H60.4204" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M46.7352 43.6709H43.6558" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M60.5904 36.6191L58.4121 38.7974" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M48.7348 48.4746L46.5586 50.6508" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M46.6221 36.7246L48.8004 38.9029" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M58.4775 48.5801L60.6537 50.7563" stroke="#00BC00" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M52.1274 55.2331C51.6884 55.4768 51.1842 55.6175 50.6465 55.6175H16.5458C14.8633 55.6175 13.5 54.2542 13.5 52.5717V25.0458C13.5 23.3633 14.8633 22 16.5458 22H50.6444C52.3269 22 53.6902 23.3633 53.6902 25.0458V30.6522"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.0538 28.6416H19.974C18.2919 28.6416 16.9282 30.0053 16.9282 31.6874V46.7506C16.9282 48.4328 18.2919 49.7964 19.974 49.7964H37.0538C38.7359 49.7964 40.0996 48.4328 40.0996 46.7506V31.6874C40.0996 30.0053 38.7359 28.6416 37.0538 28.6416Z"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.6235 28.6416H44.162C43.3139 28.6416 42.6265 29.3291 42.6265 30.1771V33.2986C42.6265 34.1466 43.3139 34.8341 44.162 34.8341H49.6235C50.4715 34.8341 51.159 34.1466 51.159 33.2986V30.1771C51.159 29.3291 50.4715 28.6416 49.6235 28.6416Z"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.1138 35.3828V43.0541L31.9133 39.4957L25.1138 35.3828Z"
        stroke="#00BC00"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_327_33039">
        <rect width="77" height="77" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CustomCreativesIcon;

import React from 'react';
import { PREVIEW_TABS, previewTabs } from './values';
import { useAdToolsContext } from '../CreativesAdTools';

const PreviewTabs = React.memo(() => {
  const { text, previewTab, setPreviewTab, copy, gtmEvent, isChathead } = useAdToolsContext();

  let className = previewTab.replace(/\s/g, '').toLowerCase();
  className += 'Hidden';

  const copyActive = previewTab === PREVIEW_TABS.SHOW_CODE || isChathead;

  return (
    <div className={`selectTabs ${isChathead ? 'chatHead' : ''}`}>
      <div className={`tab copyCode ${copyActive ? 'active' : ''}`} onClick={copyActive ? copy : null}>
        <svg height="19.009" viewBox="0 0 18.989 19.009" width="18.989" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.565,6.686a.26.26,0,0,0,.26-.26,3.645,3.645,0,0,0-3.77-3.93H6.285a3.652,3.652,0,0,0-3.78,3.93v5.44a3.655,3.655,0,0,0,3.78,3.94h.09a.3.3,0,0,0,.3-.3v-3.38a5.185,5.185,0,0,1,5.27-5.44Z"
            data-name="Tracé 26"
            fillRule="evenodd"
            id="Tracé_26"
            transform="translate(-2.505 -2.496)"
          />
          <path
            d="M17.722,8.186H11.948a3.654,3.654,0,0,0-3.773,3.94v5.439a3.654,3.654,0,0,0,3.773,3.94h5.772a3.654,3.654,0,0,0,3.774-3.94V12.126A3.654,3.654,0,0,0,17.722,8.186Z"
            data-name="Tracé 27"
            fillRule="evenodd"
            id="Tracé_27"
            transform="translate(-2.505 -2.496)"
          />
        </svg>
        {text.copyCode}
      </div>
      <div className={`tab ${className}`}>
        {previewTabs.map((tab, i) => (
          <span
            className={`${previewTab === tab ? 'selected' : ''}`}
            key={i}
            onClick={() => {
              setPreviewTab(tab);
              gtmEvent(`preview - ${tab}`);
            }}
          >
            {tab}
          </span>
        ))}
      </div>
    </div>
  );
});

export default PreviewTabs;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import Questions from './basic-configuration/Questions.react';
import { InputText, Radio } from '../../components/reusables';
import AlignCenter from '../../components/reusables/svg/AlignCenter.react';
import AlignLeft from '../../components/reusables/svg/AlignLeft.react';
import AlignRight from '../../components/reusables/svg/AlignRight.react';
import { Gtm } from '../../utils/gtm';
import Tooltip from '../../components/reusables/ReactTooltip';

import consts from '../../utils/consts';
const { TRAFFICTYPE_ZONES } = consts;

class BasicConfiguration extends Component {
  constructor(props) {
    super(props);

    this.changeLogoAlignement = this.changeLogoAlignement.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      dispatch,
      values: { questionTopic },
    } = this.props;

    if (questionTopic !== nextProps.values.questionTopic) {
      let key = nextProps.values.questionTopic.split(' (')[0].toUpperCase();

      if (key === 'PAYSITE') {
        key = 'PAYSITES';
      } else if (Object.keys(TRAFFICTYPE_ZONES).indexOf(key) === -1) {
        key = 'MIXED';
      }
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'rewardOptions',
        field: 'rewardType',
        value: JSON.stringify(TRAFFICTYPE_ZONES[key]),
      });
    }
  }

  changeLogoAlignement(value) {
    const { dispatch } = this.props;

    dispatch({
      type: 'redux-form/CHANGE',
      form: 'basicConfiguration',
      field: 'customLogoUrlAlignement',
      value,
    });
  }

  render() {
    const { dispatch, fields, fieldsSettings, questionId, intl, questionIsFetching, themes, values } = this.props;

    const isDisabled = (field) => typeof field !== 'undefined' && field === 0;

    return (
      <div className="container-fluid">
        <div className="row">
          <Questions
            dispatch={dispatch}
            fields={fields}
            fieldsSettings={fieldsSettings}
            questionId={questionId}
            questionIsFetching={questionIsFetching}
            themes={themes}
            values={values}
          />
          <div className="col-md-9 separator-left config-section">
            <h2>
              <FormattedMessage {...messages.surveyGeneratorFormBranding} />
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <label
                      className={(fields.displayLogo.error && fields.displayLogo.touched && 'error groupLabel') || 'groupLabel'}
                    >
                      <FormattedMessage {...messages.surveyGeneratorFormLogo} />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <Radio
                    autofocus
                    className="col-md-6 col-lg-4 radio-field"
                    disabled={isDisabled(fieldsSettings['has-logo'])}
                    field={fields.displayLogo}
                    id="displayLogo-yes"
                    label={messages.genericTextYes}
                    onClick={() => {
                      Gtm.event('survey machine', 'Click', 'logo yes');
                    }}
                    tabIndex={40}
                    value="yes"
                  />
                  <Radio
                    autofocus
                    className="col-md-6 col-lg-4 radio-field"
                    disabled={isDisabled(fieldsSettings['has-logo'])}
                    field={fields.displayLogo}
                    id="displayLogo-no"
                    label={messages.genericTextNo}
                    onClick={() => {
                      Gtm.event('survey machine', 'Click', 'logo no');
                    }}
                    tabIndex={50}
                    value="no"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <Tooltip effect="float" id="custom-logo-url-1" tooltip="Supported image format : jpg, gif & png">
                    <InputText
                      className="col-md-9"
                      disabled={values.displayLogo === 'no'}
                      field={fields.customLogoUrl}
                      id="customLogoUrl"
                      label={messages.surveyGeneratorFormCustomLogoUrl}
                      placeholder={intl.formatMessage(messages.surveyGeneratorFormUrlPlaceholder)}
                      tabIndex={120}
                      type="text"
                    />
                  </Tooltip>
                  <div
                    className={`col-md-3 alignments ${
                      values.displayLogo === 'no' || isDisabled(fieldsSettings.logo_position) ? 'disabled' : ''
                    }`}
                  >
                    <div className="row">
                      <div className="col-md-3 title">
                        <FormattedMessage {...messages.genericTextAlignment} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <span
                          onClick={() => {
                            this.changeLogoAlignement('left');
                          }}
                        >
                          <AlignLeft isActive={values.customLogoUrlAlignement === 'left'} />
                        </span>
                      </div>
                      <div className="col-md-4">
                        <span
                          onClick={() => {
                            this.changeLogoAlignement('center');
                          }}
                        >
                          <AlignCenter isActive={values.customLogoUrlAlignement === 'center'} />
                        </span>
                      </div>
                      <div className="col-md-4">
                        <span
                          onClick={() => {
                            this.changeLogoAlignement('right');
                          }}
                        >
                          <AlignRight isActive={values.customLogoUrlAlignement === 'right'} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <InputText
                className="col-md-4"
                field={fields.customTitle}
                id="customTitle"
                label={messages.surveyGeneratorFormCustomTitle}
                labelClasses="active"
                tabIndex={115}
                type="text"
              />
              <Tooltip effect="float" id="custom-logo-url-2" tooltip="Supported image format : jpg, gif & png">
                <InputText
                  className="col-md-8"
                  field={fields.customBackgroundUrl}
                  id="customBackgroundUrl"
                  label={messages.surveyGeneratorFormCustomBackgroundUrl}
                  placeholder={intl.formatMessage(messages.surveyGeneratorFormUrlPlaceholder)}
                  tabIndex={130}
                  type="text"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicConfiguration.propTypes = {
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  fieldsSettings: PropTypes.object,
  intl: PropTypes.object.isRequired,
  questionIsFetching: PropTypes.bool.isRequired,
  themes: PropTypes.array,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'basicConfiguration',
    touchOnChange: false,
    touchOnBlur: false,
    fields: [
      'customBackgroundUrl', // url param: bgImg
      'customLogoUrl', // url param: logo
      'customLogoUrlAlignement', // url param: logo_position
      'customTitle', // url param: title
      'displayBoxBorder',
      'displayIntroPicture',
      'displayIntroText',
      'displayLogo', // url param: has-logo
      'questionNumber', // url param: zones (pas directement, agis avec questionTopic)
      'questionTheme', // url param: set
      'questionTopic', // url param: zones (pas directement)
    ],
    destroyOnUnmount: false,
  })(BasicConfiguration)
);

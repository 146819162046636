import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import StatisticsBaseFilters from './statistics-filters/StatisticsBaseFilters.react';
import StatisticsSecondaryFilters from './statistics-filters/StatisticsSecondaryFilters.react';

import {
  fetchOffersList,
  fetchOffersUrlList,
  fetchOffersUrlListByCategoriesIds,
} from '../../components/Application/actions/application-actions';

const StatisticsFilters = ({
  browsersList,
  categories,
  countries,
  fields,
  filtersAreDisabled,
  goalsList,
  offersList,
  offersUrlList,
  statisticsSelectedFilters,
  values: { vertical, brand, offer },
  values,
  dispatch,
  requestReload,
}) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const previousOffer = usePrevious(offer) || [];

  const previousVertical = usePrevious(vertical) || [];
  useEffect(() => {
    if (vertical?.length > 0) {
      if (!_.isEqual(vertical || [], previousVertical)) {
        dispatch(fetchOffersList([vertical || [], []]));
      }
    }
    if (!_.isEqual(offer || [], previousOffer)) {
      // Si il y aucune offre, obligé à ne pas retourner de résultats
      if (offer === undefined) {
        offer = [999999999999999];
      }
      dispatch(fetchOffersUrlList(offer));
    }
  }, [vertical, brand, offer]);

  const handleReload = (e) => {
    e.preventDefault();
    requestReload();
  };

  return (
    <div className="row row-eq-height" id="statistics-filters">
      <div className="col-md-9">
        <StatisticsBaseFilters
          categories={categories}
          countries={countries}
          fields={fields}
          isDisabled={filtersAreDisabled}
          offersList={offersList}
          offersUrlList={offersUrlList}
          statisticsSelectedFilters={statisticsSelectedFilters}
          values={values}
        />
        <StatisticsSecondaryFilters
          browsersList={browsersList}
          categories={categories}
          fields={fields}
          goalsList={goalsList}
          isDisabled={filtersAreDisabled}
          statisticsSelectedFilters={statisticsSelectedFilters}
        />
      </div>
      <div className="col-md-3">
        <div className="text-right filters-buttons">
          <button className="refresh-btn waves-effect waves-light btn" onClick={(e) => handleReload(e)}>
            <i className="material-icons">&#xE5D5;</i>
          </button>
          <button className="filter-btn waves-effect waves-light btn bg-primary-color" type="submit">
            <FormattedMessage {...messages.genericTextApply} />
          </button>
        </div>
      </div>
    </div>
  );
};

StatisticsFilters.propTypes = {
  browsersList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  filtersAreDisabled: PropTypes.bool.isRequired,
  goalsList: PropTypes.array.isRequired,
  offersList: PropTypes.object.isRequired,
  offersUrlList: PropTypes.array.isRequired,
  requestReload: PropTypes.func.isRequired,
  statisticsSelectedFilters: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default StatisticsFilters;

import PropTypes from 'prop-types';
import SelectCustom from '../reusables/form/SelectCustom';
const SubdivisionSelect = ({ disabled, id, label, subdivisionField, subdivisionList }) => (
  <div className="customSelects">
    <div className="fields">
      <SelectCustom
        disabled={disabled}
        id={id}
        label={label}
        onChange={subdivisionField.onChange}
        tabIndex={131}
        value={subdivisionField.value || ''}
        valueDisabled={'Choose'}
        error={subdivisionField.error}
        touched={subdivisionField.touched}
        showSelectedFieldCheckmark={false}
        options={subdivisionList.map((subdivision, index) => ({
          value: subdivision.value,
          text: subdivision.label.defaultMessage,
        }))}
      />
    </div>
  </div>
);

SubdivisionSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subdivisionField: PropTypes.object.isRequired,
  subdivisionList: PropTypes.array.isRequired,
};

export default SubdivisionSelect;

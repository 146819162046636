import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';
import { Gtm } from '../../utils/gtm';

import { CodeInput, Copy } from '../reusables';

import SelectCustom from './form/SelectCustom.js';
class CodePreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creativeSelected: 0,
      scaleRatio: 1,
    };

    this.previewContainerRef = React.createRef(); // Create a ref for the preview container
  }

  componentDidMount() {
    this.updateScaleRatio(); // Compute ratio on mount
  }

  componentDidUpdate(prevProps, prevState) {
    // Recompute the ratio if creatives change or selection changes
    if (prevState.creativeSelected !== this.state.creativeSelected || prevProps.creativesData !== this.props.creativesData) {
      this.updateScaleRatio();
    }
  }

  updateScaleRatio() {
    const previewContainer = this.previewContainerRef.current;
    const { creativesData, selectedSize } = this.props;

    if (previewContainer && creativesData && creativesData.length > 0) {
      const containerWidth = previewContainer.offsetWidth;
      const em = parseFloat(getComputedStyle(previewContainer).fontSize);

      if (selectedSize) {
        const widthStr = selectedSize.split('x')[0];
        if (!isNaN(widthStr)) {
          const ratio = (containerWidth - 2 * em) / parseInt(widthStr);
          this.setState({ scaleRatio: ratio < 1 ? ratio : 1 });
        }
      }
    }
  }

  render() {
    const {
      affId,
      affSub,
      creativesData,
      creativesIsFetching,
      creativesSizes,
      dispatch,
      formatMessage,
      generateCreativeCode,
      intl,
      offerCopyIframeCode,
      offerId,
      offerIframeCode,
      onSizeChange,
      selectedSize,
      trackingLink,
    } = this.props;

    const { creativeSelected, scaleRatio } = this.state;

    const optionSelected = selectedSize || creativesSizes[0];

    const creativesOptions =
      creativesData && creativesData.filter((creativeData) => `${creativeData.width}x${creativeData.height}` === optionSelected);
    const creative = creativesOptions && creativesOptions[creativeSelected];

    const loadingEmpty = `<div class="empty">${
      creativesIsFetching ? intl.formatMessage(messages.offerLoadingPreview) : intl.formatMessage(messages.offerNoPreview)
    }</div>`;
    const msg = creativesIsFetching ? intl.formatMessage(messages.offerLoadingIframe) : formatMessage;

    const readOnly = !creative || creativesData.length < 1;

    const creativeCode =
      !!creative && !!creative.code
        ? generateCreativeCode({
            affId,
            affSub,
            creativeCode: creative.code,
            fileId: creative.id,
            offerId,
            trackingLink,
            uriTrackingLink: encodeURIComponent(trackingLink),
          })
        : '';

    const iframeStyle = `transform: scale(${scaleRatio}); transform-origin: top left;`;
    const transformedIFrameCode = creativeCode.replace(/style="([^"]*)"/, `style="$1 ${iframeStyle}"`);

    return (
      <div className="iframe-container-code">
        <div>
          <div className="code-input iframe">
            <div className="title">Preview</div>
            <div className="code-preview">
              <div className="row customSelects">
                {readOnly ? null : (
                  <div className="col-md-4 filterInput fields">
                    <SelectCustom
                      small
                      id="size"
                      label={messages.genericTextIframeSize.description}
                      onChange={(e) => {
                        e !== optionSelected ? onSizeChange(e) : null;
                        Gtm.event('offers', 'Click', `iframe ${e}`);
                      }}
                      value={optionSelected}
                      options={creativesSizes.map((creativeSize) => ({ value: creativeSize, text: creativeSize }))}
                      showSelectedFieldCheckmark={false}
                    />
                  </div>
                )}
                {!readOnly && creativesOptions !== null && creativesOptions.length > 1 && (
                  <div className="col-md-4 filterInput fields">
                    <SelectCustom
                      small
                      id="type"
                      label={messages.genericTextIframeDetails.description}
                      onChange={(e) => {
                        this.setState({
                          creativeSelected: e,
                        });
                      }}
                      showSelectedFieldCheckmark={false}
                      options={creativesOptions.map((co, i) => ({ value: i, text: co.display.split('_')[1] }))}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div
                  className="col-sm-12 preview-container"
                  dangerouslySetInnerHTML={{
                    __html: readOnly ? loadingEmpty : transformedIFrameCode,
                  }}
                  ref={this.previewContainerRef}
                />
              </div>
            </div>
          </div>
          <CodeInput
            codeId="iframe-code-copy-content"
            size={readOnly ? '300x250' : creativesSizes}
            text={readOnly ? msg : creativeCode}
            title={<FormattedMessage {...offerIframeCode} />}
          />
        </div>
        <Copy
          clipboardTarget="#iframe-code-copy-content"
          dispatch={dispatch}
          icon="code"
          onCopy={() => {
            Gtm.event(
              `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
              'Click',
              'copy iframe code'
            );
          }}
          text={<FormattedMessage {...offerCopyIframeCode} />}
        />
      </div>
    );
  }
}

CodePreview.propTypes = {
  affId: PropTypes.string,
  affSub: PropTypes.string,
  creativesData: PropTypes.array,
  creativesIsFetching: PropTypes.any,
  creativesSizes: PropTypes.any,
  dispatch: PropTypes.any,
  formatMessage: PropTypes.any,
  generateCreativeCode: PropTypes.func,
  intl: PropTypes.any,
  offerCopyIframeCode: PropTypes.any,
  offerId: PropTypes.any.isRequired,
  offerIframeCode: PropTypes.any,
  onSizeChange: PropTypes.func,
  selectedSize: PropTypes.any,
  trackingLink: PropTypes.string,
};

export default injectIntl(CodePreview);

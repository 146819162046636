import PropTypes from 'prop-types';

const getClassNames = ({ fullScreen }) => ['loading-section-container', fullScreen ? 'full-screen' : ''].join(' ');

const LoadingPage = ({ fullScreen }) => (
  <div className={getClassNames({ fullScreen })}>
    <div className="loading-section">
      <div className="loading-section-bar" />
      <div className="loading-section-bar" />
      <div className="loading-section-bar" />
      <div className="loading-section-bar" />
    </div>
  </div>
);

LoadingPage.defaultProps = {
  fullScreen: true,
};

LoadingPage.propTypes = {
  fullScreen: PropTypes.bool,
};

export default LoadingPage;

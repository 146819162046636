const PerformerPageModalIconReact = () => (
  <svg fill="none" height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17_3602)">
      <mask height="35" id="mask0_17_3602" maskUnits="userSpaceOnUse" width="35" x="0" y="0">
        <path d="M35 0H0V35H35V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_17_3602)">
        <path
          d="M17.5 26.3981C23.2462 26.3981 27.9044 21.74 27.9044 15.9938C27.9044 10.2477 23.2462 5.58948 17.5 5.58948C11.7539 5.58948 7.0957 10.2477 7.0957 15.9938C7.0957 21.74 11.7539 26.3981 17.5 26.3981Z"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 20.6115C20.0503 20.6115 22.1177 18.5441 22.1177 15.9938C22.1177 13.4436 20.0503 11.3762 17.5 11.3762C14.9497 11.3762 12.8823 13.4436 12.8823 15.9938C12.8823 18.5441 14.9497 20.6115 17.5 20.6115Z"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5001 18.5593C18.917 18.5593 20.0656 17.4107 20.0656 15.9938C20.0656 14.5769 18.917 13.4283 17.5001 13.4283C16.0832 13.4283 14.9346 14.5769 14.9346 15.9938C14.9346 17.4107 16.0832 18.5593 17.5001 18.5593Z"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9441 25.8148L22.3207 29.4105H12.6782L14.0549 25.8148"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_17_3602">
        <rect fill="white" height="35" width="35" />
      </clipPath>
    </defs>
  </svg>
);

export default PerformerPageModalIconReact;

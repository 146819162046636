import React, { useCallback } from 'react';
import { useAdToolsContext } from '../CreativesAdTools';

const InputBox = React.memo(({ value }) => {
  const { setPostitialState, gtmEvent } = useAdToolsContext();

  value = value?.replace('%', '');

  const onChange = useCallback((e) => {
    let inputValue = e.target.value.replace(/\D/g, '');

    if (!parseInt(inputValue)) {
      inputValue = '';
    }

    if (inputValue > 100) {
      inputValue = 100;
    }

    setPostitialState((state) => ({
      ...state,
      width: `${inputValue}%`,
      height: `${inputValue}%`,
    }));

    gtmEvent(`dimensions - ${inputValue}% x ${inputValue}%`);
  }, []);

  return (
    <div className="inputBox">
      <input type="text" value={value} onChange={onChange} />
      <div className="percentage">%</div>
    </div>
  );
});

export default InputBox;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import messages from '../../i18n/base-en.js';
import HandleAPIError from '../../utils/handleAPIError';
import { setActionMessage } from '../../components/Application/actions/application-actions';
import EmailPreferences from './EmailPreferences.react';
import { updateSendGrid } from '../../actions/sendgrid-actions';

class EmailPreferencesContainer extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeLeaveHook = () => {};
  }

  componentDidMount() {
    const { router, route, intl } = this.props;

    this.removeLeaveHook = router.setRouteLeaveHook(route, () => {
      let changed = false;

      const { emailPreferencesForm } = this.props;

      Object.keys(emailPreferencesForm).map((key) => {
        if (
          typeof emailPreferencesForm[key] === 'object' &&
          emailPreferencesForm[key].value !== null &&
          String(emailPreferencesForm[key].value) !== String(emailPreferencesForm[key].initial)
        ) {
          changed = true;
          return;
        }
      });

      if (changed) {
        return intl.formatMessage(messages.profileSettingsUnsavedChecked);
      }
    });
  }

  handleFormSubmit(data) {
    const { dispatch, intl, userEmail, skin } = this.props;
    const body = {
      list_ids: data.subscribedEmailLists,
      email: userEmail,
      skin_name: skin,
    };
    dispatch(updateSendGrid(body))
      .then(() => {
        dispatch(
          setActionMessage('success', {
            text: intl.formatMessage(messages.profileUpdateSettingsCompleted),
          })
        );
        this.removeLeaveHook();
      })
      .catch((res) => {
        new HandleAPIError(res, dispatch).handleAll();
      });
  }

  render() {
    return <EmailPreferences {...this.props} onSubmit={this.handleFormSubmit} />;
  }
}

EmailPreferencesContainer.propTypes = {
  dispatch: PropTypes.func,
  emailPreferencesForm: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  offers: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
  emailLists: state.profile.data.sendgridLists,
  userEmail: state.profile.data.affUserInfos.email,
  skin: state.skinConfigurations.data.name,
}))(injectIntl(withRouter(EmailPreferencesContainer)));

import consts from '../../../utils/consts';

export default class CompleteProfileService {
  static hasToCompleteProfile({ affInfos }) {
    let hasToCompleteProfile = true;
    if (affInfos) {
      const requireAddress = !consts.COUNTRIES_WITHOUT_ZIP.includes(affInfos.country) && !affInfos.zipcode;
      hasToCompleteProfile = !affInfos.address1 || !affInfos.city || !affInfos.country || requireAddress;
    }
    return hasToCompleteProfile;
  }

  static hasToCompleteBeneficiaryProfile({ beneficiaryInfos }) {
    let hasToCompleteBeneficiaryProfile = true;
    if (beneficiaryInfos) {
      const requireAddress =
        !consts.COUNTRIES_WITHOUT_ZIP.includes(beneficiaryInfos.country_code) && !beneficiaryInfos.postal_code;
      hasToCompleteBeneficiaryProfile =
        !beneficiaryInfos.address_line1 || !beneficiaryInfos.city || !beneficiaryInfos.country_code || requireAddress;
    }
    return hasToCompleteBeneficiaryProfile;
  }
}

export default {
  'countryList.us': 'United States',
  'countryList.ca': 'Canada',
  'countryList.au': 'Australia',
  'countryList.uk': 'United Kingdom',
  'countryList.ie': 'Ireland',
  'countryList.nz': 'New Zealand',
  'countryList.sg': 'Singapore',
  'countryList.fr': 'France',
  'countryList.de': 'Germany',
  'countryList.it': 'Italy',
  'countryList.za': 'South Africa',
  'countryList.es': 'Spain',
  'countryList.af': 'Afghanistan',
  'countryList.ax': 'Åland Islands',
  'countryList.al': 'Albania',
  'countryList.dz': 'Algeria',
  'countryList.as': 'American Samoa',
  'countryList.ad': 'Andorra',
  'countryList.ao': 'Angola',
  'countryList.ai': 'Anguilla',
  'countryList.aq': 'Antarctica',
  'countryList.ag': 'Antigua and Barbuda',
  'countryList.ar': 'Argentina',
  'countryList.am': 'Armenia',
  'countryList.aw': 'Aruba',
  'countryList.at': 'Austria',
  'countryList.az': 'Azerbaijan',
  'countryList.bs': 'Bahamas',
  'countryList.bh': 'Bahrain',
  'countryList.bd': 'Bangladesh',
  'countryList.bb': 'Barbados',
  'countryList.by': 'Belarus',
  'countryList.be': 'Belgium',
  'countryList.bz': 'Belize',
  'countryList.bj': 'Benin',
  'countryList.bm': 'Bermuda',
  'countryList.bt': 'Bhutan',
  'countryList.bo': 'Bolivia',
  'countryList.ba': 'Bosnia and Herzegovina',
  'countryList.bw': 'Botswana',
  'countryList.bv': 'Bouvet Island',
  'countryList.br': 'Brazil',
  'countryList.io': 'British Indian Ocean Territory',
  'countryList.bn': 'Brunei Darussalam',
  'countryList.bg': 'Bulgaria',
  'countryList.bf': 'Burkina Faso',
  'countryList.bi': 'Burundi',
  'countryList.kh': 'Cambodia',
  'countryList.cm': 'Cameroon',
  'countryList.cv': 'Cape Verde',
  'countryList.ky': 'Cayman Islands',
  'countryList.cf': 'Central African Republic',
  'countryList.td': 'Chad',
  'countryList.cl': 'Chile',
  'countryList.cn': 'China',
  'countryList.cx': 'Christmas Island',
  'countryList.cc': 'Cocos (Keeling) Islands',
  'countryList.co': 'Colombia',
  'countryList.km': 'Comoros',
  'countryList.cg': 'Congo',
  'countryList.cd': 'Congo, The Democratic Republic of the',
  'countryList.ck': 'Cook Islands',
  'countryList.cr': 'Costa Rica',
  'countryList.ci': "Cote D'ivoire",
  'countryList.hr': 'Croatia',
  'countryList.cu': 'Cuba',
  'countryList.cy': 'Cyprus',
  'countryList.cz': 'Czech Republic',
  'countryList.dk': 'Denmark',
  'countryList.dj': 'Djibouti',
  'countryList.dm': 'Dominica',
  'countryList.do': 'Dominican Republic',
  'countryList.ec': 'Ecuador',
  'countryList.eg': 'Egypt',
  'countryList.sv': 'El Salvador',
  'countryList.gq': 'Equatorial Guinea',
  'countryList.er': 'Eritrea',
  'countryList.ee': 'Estonia',
  'countryList.et': 'Ethiopia',
  'countryList.fk': 'Falkland Islands (Malvinas)',
  'countryList.fo': 'Faroe Islands',
  'countryList.fj': 'Fiji',
  'countryList.fi': 'Finland',
  'countryList.gf': 'French Guiana',
  'countryList.pf': 'French Polynesia',
  'countryList.tf': 'French Southern Territories',
  'countryList.ga': 'Gabon',
  'countryList.gm': 'Gambia',
  'countryList.ge': 'Georgia',
  'countryList.gh': 'Ghana',
  'countryList.gi': 'Gibraltar',
  'countryList.gr': 'Greece',
  'countryList.gl': 'Greenland',
  'countryList.gd': 'Grenada',
  'countryList.gp': 'Guadeloupe',
  'countryList.gu': 'Guam',
  'countryList.gt': 'Guatemala',
  'countryList.gg': 'Guernsey',
  'countryList.gn': 'Guinea',
  'countryList.gw': 'Guinea-Bissau',
  'countryList.gy': 'Guyana',
  'countryList.ht': 'Haiti',
  'countryList.hm': 'Heard Island and Mcdonald Islands',
  'countryList.va': 'Holy See (Vatican City State)',
  'countryList.hn': 'Honduras',
  'countryList.hk': 'Hong Kong',
  'countryList.hu': 'Hungary',
  'countryList.is': 'Iceland',
  'countryList.in': 'India',
  'countryList.id': 'Indonesia',
  'countryList.ir': 'Iran, Islamic Republic Of',
  'countryList.iq': 'Iraq',
  'countryList.im': 'Isle of Man',
  'countryList.il': 'Israel',
  'countryList.jm': 'Jamaica',
  'countryList.jp': 'Japan',
  'countryList.je': 'Jersey',
  'countryList.jo': 'Jordan',
  'countryList.kz': 'Kazakhstan',
  'countryList.ke': 'Kenya',
  'countryList.ki': 'Kiribati',
  'countryList.kp': "Korea, Democratic People's Republic of",
  'countryList.kr': 'Korea, Republic of',
  'countryList.kw': 'Kuwait',
  'countryList.kg': 'Kyrgyzstan',
  'countryList.la': "Lao People's Democratic Republic",
  'countryList.lv': 'Latvia',
  'countryList.lb': 'Lebanon',
  'countryList.ls': 'Lesotho',
  'countryList.lr': 'Liberia',
  'countryList.ly': 'Libyan Arab Jamahiriya',
  'countryList.li': 'Liechtenstein',
  'countryList.lt': 'Lithuania',
  'countryList.lu': 'Luxembourg',
  'countryList.mo': 'Macao',
  'countryList.mk': 'Macedonia, The Former Yugoslav Republic of',
  'countryList.mg': 'Madagascar',
  'countryList.mw': 'Malawi',
  'countryList.my': 'Malaysia',
  'countryList.mv': 'Maldives',
  'countryList.ml': 'Mali',
  'countryList.mt': 'Malta',
  'countryList.mh': 'Marshall Islands',
  'countryList.mq': 'Martinique',
  'countryList.mr': 'Mauritania',
  'countryList.mu': 'Mauritius',
  'countryList.yt': 'Mayotte',
  'countryList.mx': 'Mexico',
  'countryList.fm': 'Micronesia, Federated States of',
  'countryList.md': 'Moldova, Republic of',
  'countryList.mc': 'Monaco',
  'countryList.mn': 'Mongolia',
  'countryList.ms': 'Montserrat',
  'countryList.ma': 'Morocco',
  'countryList.mz': 'Mozambique',
  'countryList.mm': 'Myanmar',
  'countryList.na': 'Namibia',
  'countryList.nr': 'Nauru',
  'countryList.np': 'Nepal',
  'countryList.nl': 'Netherlands',
  'countryList.an': 'Netherlands Antilles',
  'countryList.nc': 'New Caledonia',
  'countryList.ni': 'Nicaragua',
  'countryList.ne': 'Niger',
  'countryList.ng': 'Nigeria',
  'countryList.nu': 'Niue',
  'countryList.nf': 'Norfolk Island',
  'countryList.mp': 'Northern Mariana Islands',
  'countryList.no': 'Norway',
  'countryList.om': 'Oman',
  'countryList.pk': 'Pakistan',
  'countryList.pw': 'Palau',
  'countryList.ps': 'Palestinian Territory, Occupied',
  'countryList.pa': 'Panama',
  'countryList.pg': 'Papua New Guinea',
  'countryList.py': 'Paraguay',
  'countryList.pe': 'Peru',
  'countryList.ph': 'Philippines',
  'countryList.pn': 'Pitcairn',
  'countryList.pl': 'Poland',
  'countryList.pt': 'Portugal',
  'countryList.pr': 'Puerto Rico',
  'countryList.qa': 'Qatar',
  'countryList.re': 'Reunion',
  'countryList.ro': 'Romania',
  'countryList.ru': 'Russian Federation',
  'countryList.rw': 'Rwanda',
  'countryList.sh': 'Saint Helena',
  'countryList.kn': 'Saint Kitts and Nevis',
  'countryList.lc': 'Saint Lucia',
  'countryList.pm': 'Saint Pierre and Miquelon',
  'countryList.vc': 'Saint Vincent and the Grenadines',
  'countryList.ws': 'Samoa',
  'countryList.sm': 'San Marino',
  'countryList.st': 'Sao Tome and Principe',
  'countryList.sa': 'Saudi Arabia',
  'countryList.sn': 'Senegal',
  'countryList.cs': 'Serbia and Montenegro',
  'countryList.sc': 'Seychelles',
  'countryList.sl': 'Sierra Leone',
  'countryList.sk': 'Slovakia',
  'countryList.si': 'Slovenia',
  'countryList.sb': 'Solomon Islands',
  'countryList.so': 'Somalia',
  'countryList.gs': 'South Georgia and the South Sandwich Islands',
  'countryList.lk': 'Sri Lanka',
  'countryList.sd': 'Sudan',
  'countryList.sr': 'Suriname',
  'countryList.sj': 'Svalbard and Jan Mayen',
  'countryList.sz': 'Swaziland',
  'countryList.se': 'Sweden',
  'countryList.ch': 'Switzerland',
  'countryList.sy': 'Syrian Arab Republic',
  'countryList.tw': 'Taiwan, Province of China',
  'countryList.tj': 'Tajikistan',
  'countryList.tz': 'Tanzania, United Republic of',
  'countryList.th': 'Thailand',
  'countryList.tl': 'Timor-Leste',
  'countryList.tg': 'Togo',
  'countryList.tk': 'Tokelau',
  'countryList.to': 'Tonga',
  'countryList.tt': 'Trinidad and Tobago',
  'countryList.tn': 'Tunisia',
  'countryList.tr': 'Turkey',
  'countryList.tm': 'Turkmenistan',
  'countryList.tc': 'Turks and Caicos Islands',
  'countryList.tv': 'Tuvalu',
  'countryList.ug': 'Uganda',
  'countryList.ua': 'Ukraine',
  'countryList.ae': 'United Arab Emirates',
  'countryList.um': 'United States Minor Outlying Islands',
  'countryList.uy': 'Uruguay',
  'countryList.uz': 'Uzbekistan',
  'countryList.vu': 'Vanuatu',
  'countryList.ve': 'Venezuela',
  'countryList.vn': 'Viet Nam',
  'countryList.vg': 'Virgin Islands, British',
  'countryList.vi': 'Virgin Islands, U.S.',
  'countryList.wf': 'Wallis and Futuna',
  'countryList.eh': 'Western Sahara',
  'countryList.ye': 'Yemen',
  'countryList.zm': 'Zambia',
  'countryList.zw': 'Zimbabwe',
};

import React, { useEffect, useState } from 'react';
import { Info } from '../../../reusables';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import messages from '../../../../i18n/base-en';
import SkinConfigurationsUtils from '../../../../utils/SkinConfigurationsUtils';
import EpcTooltipIcon from '../../../Offers/EpcTooltipIcon';
const OfferLandingPagesCard = ({
  landingPage,
  isReferrals,
  skinConfigurations,
  viewMode,
  checked,
  handleSelectLanding,
  handleCheckId,
  isAutoOptimized,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const getOfferItemsVisibility = () => ({
    moreInfo: SKIN.isItemVisible('offersList', 'MoreInfo'),
  });
  const IS_VISIBLE = getOfferItemsVisibility();
  const handleClick = () => {
    handleCheckId(landingPage.id);
    handleSelectLanding(landingPage.name);
  };

  useEffect(() => {
    setIsLoaded(false);
    setHasError(false);
  }, [viewMode]);

  return (
    <label className={`label-landing ${isAutoOptimized ? 'optimized' : ''}`} htmlFor={landingPage.id}>
      <div className={checked ? 'selected screenshot-box' : 'screenshot-box'} htmlFor={landingPage.id} key={landingPage.id}>
        <div className="screenshot-section">
          <div className="radio-field big-radio grey-radio" data-cy="landing-input-select">
            <input
              className="with-gap"
              defaultChecked={checked}
              id={landingPage.id}
              name="selected-landing"
              onClick={() => {
                handleClick();
              }}
              type="radio"
              value={landingPage.id}
            />
            <label htmlFor={landingPage.id} />
          </div>
          {isAutoOptimized ? (
            <img
              alt="Landing Page Preview"
              className="landing-screenshot"
              onError={() => setHasError(true)}
              onLoad={() => setIsLoaded(true)}
              src={landingPage.screenshot[`${viewMode}_thumbnail`]}
            />
          ) : (
            <div className={viewMode}>
              {!isLoaded && !hasError ? (
                <img
                  alt="Loading..."
                  id="cr-screenshot-loading"
                  src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
                />
              ) : null}
              {hasError ? (
                <div className="screenshot-error">
                  <FormattedMessage {...messages.genericTextErrorWhileLoadingImage} />
                </div>
              ) : null}
              <img
                alt="Landing Page Preview"
                className={`landing-screenshot ${!isLoaded ? 'hidden' : ''}`}
                onError={() => setHasError(true)}
                onLoad={() => setIsLoaded(true)}
                src={landingPage.screenshot[`${viewMode}_thumbnail`]}
              />
            </div>
          )}
        </div>
        <div className="landing-details">
          <p
            className={landingPage.id === 'optimized' ? 'landing-name featured_container' : 'landing-name'}
            key={landingPage.id}
            value={landingPage.id}
          >
            {landingPage.id === 'optimized' && <img alt="Featured" className="featured" src="/img/featured-star.svg" />}

            {landingPage.name}
          </p>
          {!isReferrals && (
            <span className="epc">
              <FormattedMessage {...messages.offerEPC} />
              {IS_VISIBLE.moreInfo ? <EpcTooltipIcon closeIcon id={`tooltip-epc-${landingPage.id}`} /> : ' '}
              <span>
                {landingPage.epc_affiliation ? (
                  landingPage.id === 'optimized' ? (
                    landingPage.epc_affiliation
                  ) : (
                    <FormattedNumber
                      currency="USD"
                      minimumFractionDigits={4}
                      style="currency"
                      value={parseFloat(landingPage.epc_affiliation || 0)}
                    />
                  )
                ) : (
                  <FormattedMessage {...messages.notEnoughData} />
                )}
              </span>
            </span>
          )}
          <div className="preview-landing-page ">
            {landingPage.id === 'optimized' ? (
              <p>{landingPage.description}</p>
            ) : (
              <a className="primary-color" href={landingPage.preview_url} rel="noopener noreferrer" target="_blank">
                <i className="material-icons">remove_red_eye</i>

                <FormattedMessage {...messages.genericTextPreviewModal} />
              </a>
            )}
          </div>
        </div>
      </div>
    </label>
  );
};
export default OfferLandingPagesCard;

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestNews = () => ({
  type: ACTIONS.REQUEST_NEWS,
});

const receiveNews = (data) => ({
  type: ACTIONS.RECEIVE_NEWS,
  data,
});

const failedToReceiveNews = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_NEWS,
});

export const fetchNews = () => (dispatch) => {
  dispatch(requestNews());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/content/news`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveNews(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveNews());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

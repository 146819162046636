import React, { useRef } from 'react';
const TrustBox = () => {
  const ref = useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div id="trustpilot-widget" style={{ pointerEvents: 'none' }}>
      <div
        className="trustpilot-widget"
        data-businessunit-id="60a2b58109cef7000135a2c2"
        data-locale="en-US"
        data-style-width="100%"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-theme="dark"
        ref={ref}
      >
        <a href="https://www.trustpilot.com/review/crakrevenue.com" rel="noopener noreferrer" target="_blank">
          Trustpilot
        </a>
      </div>
    </div>
  );
};
export default TrustBox;

const Badge2 = () => (
  <svg className="badge-2" fill="none" height="145" viewBox="0 0 163 145" width="163" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_656)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path d="M105.1 36.51L77.18 64.46L62.11 27.94L90.04 0L105.1 36.51Z" fill="#060909" />
        <path d="M105.1 36.51L77.18 64.46L83.61 32.23L90.04 0L105.1 36.51Z" fill="#1A2123" />
        <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
          <path d="M96.9 44.72L77.18 64.46L66.54 38.66L86.26 18.93L96.9 44.72Z" fill="#1A2123" />
        </g>
        <path d="M86.26 18.93L62.11 27.94L90.04 0L86.26 18.93Z" fill="#161D20" opacity="0.45" />
        <path d="M77.18 64.46L88 1.83L90.04 0L91.22 2.47L77.18 64.46Z" fill="#364745" />
        <path d="M62.11 27.94L90.04 0L89.46 2.9L62.11 27.94Z" fill="#364745" opacity="0.45" />
        <path d="M105.1 36.51L90.04 0L89.46 2.9L105.1 36.51Z" fill="#364745" opacity="0.45" />
        <path
          d="M143.71 90.69L136.75 100.86C132.35 107.31 125.15 111.13 117.55 111.34C115.77 111.4 113.95 111.25 112.16 110.89L70.12 102.51L75.2 77.03L143.71 90.69Z"
          fill="url(#paint0_linear_792_656)"
        />
        <path
          d="M162.18 63.63L160.94 65.46L160.1 66.69L146.26 86.94L76.02 72.93L81.09 47.46L162.18 63.63Z"
          fill="url(#paint1_linear_792_656)"
        />
        <g opacity="0.45">
          <path
            d="M141.52 90.25L143.71 90.69C143.71 90.69 134.47 111.55 117.56 111.35C129.65 109.88 138.04 95.97 141.52 90.25Z"
            fill="#364745"
          />
          <path
            d="M162.18 63.63L160.93 65.46L160.09 66.69L146.25 86.94L143.84 86.46C148.06 79.61 152.37 72.78 156.6 66.14L152.69 65.54C138.02 63.04 112.37 57.69 98.1 53.48C92.34 51.77 86.63 49.94 81.04 47.68L81.08 47.47L162.17 63.64L162.18 63.63Z"
            fill="#364745"
          />
        </g>
        <path
          d="M75.2 77.02L70.12 102.5L28.07 94.11C25.83 93.66 23.7 92.9 21.74 91.9C15.39 88.63 10.71 82.63 9.21 75.42L6.69 63.36L75.2 77.02Z"
          fill="url(#paint2_linear_792_656)"
        />
        <path
          d="M81.09 47.46L76.02 72.93L5.77 58.93L0.76 34.92L0.46 33.46L0 31.3L81.09 47.46Z"
          fill="url(#paint3_linear_792_656)"
        />
        <g opacity="0.45">
          <path d="M8.87 63.8L6.68 63.36C6.68 63.36 7.42 86.74 22.84 92.44C12.93 87.66 9.89 70.41 8.87 63.8Z" fill="#364745" />
          <path
            d="M0 31.29L0.45 33.46L0.75 34.92L5.76 58.93L8.17 59.41C6.9 51.47 5.54 43.51 4.18 35.75L8.02 36.69C22.53 40.01 48.27 44.9 63.06 46.49C69.04 47.12 75.01 47.62 81.04 47.68L81.08 47.47L0 31.29Z"
            fill="#364745"
          />
        </g>
        <g opacity="0.37" style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M21.91 62.15C24.55 52.92 29.46 44.86 35.91 38.46L125.2 56.26C128.69 64.65 130.14 73.97 129.04 83.51L21.91 62.15Z"
            fill="#1A2123"
          />
        </g>
        <g opacity="0.37" style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M128.34 88.03C128.1 89.22 127.83 90.4 127.52 91.54C125.53 98.94 122.09 105.63 117.56 111.34C115.78 111.4 113.96 111.25 112.17 110.89L28.08 94.12C25.84 93.67 23.71 92.91 21.75 91.91C20.58 87.7 19.91 83.32 19.79 78.85C19.68 74.82 20.02 70.71 20.84 66.59C20.87 66.46 20.89 66.32 20.93 66.2L128.42 87.63C128.41 87.76 128.38 87.9 128.35 88.03H128.34Z"
            fill="#1A2123"
          />
        </g>
        <path
          d="M133.48 106.89C123.68 119.02 110.82 124.85 98.87 127.48C82.59 131.06 68.05 128.68 65.49 128.2L65.08 128.12C85.88 124.94 104.91 110.27 114.65 101.56C118.96 97.69 121.45 94.99 121.45 94.99C121.45 94.99 121.46 94.99 121.47 95.01C122.97 95.6 124.28 96.27 125.43 96.99C125.74 97.17 126.04 97.37 126.32 97.56C126.84 97.91 127.31 98.26 127.75 98.62C132.59 102.55 133.47 106.9 133.47 106.9L133.48 106.89Z"
          fill="url(#paint4_linear_792_656)"
        />
        <path
          d="M133.48 106.89C123.68 119.02 110.82 124.85 98.87 127.48C82.59 131.06 68.05 128.68 65.49 128.2L65.08 128.12C83.53 129.78 97.8 126.34 108.21 121.72C123.03 115.16 130.01 106.2 130.01 106.2C130.07 103.54 128.73 101.22 127.1 99.39C126.75 99.01 126.39 98.63 126.02 98.29C125.7 97.99 125.38 97.72 125.07 97.46C123.3 95.98 121.69 95.11 121.46 94.99C122.96 95.58 124.27 96.25 125.42 96.97C125.73 97.15 126.03 97.35 126.31 97.54C126.83 97.89 127.3 98.24 127.74 98.6C132.58 102.53 133.46 106.88 133.46 106.88L133.48 106.89Z"
          fill="#364745"
          opacity="0.45"
        />
        <path
          d="M9.07 82.08C13.46 97.04 23.11 107.36 33.13 114.37C46.79 123.92 61.13 127.3 63.68 127.84L64.09 127.92C46.1 117 34.15 96.16 28.5 84.38C26 79.15 24.74 75.71 24.74 75.71C24.74 75.71 24.73 75.71 24.72 75.71C23.11 75.68 21.64 75.8 20.31 76.02C19.95 76.07 19.61 76.14 19.27 76.2C18.65 76.32 18.09 76.47 17.54 76.63C11.56 78.41 9.08 82.08 9.08 82.08H9.07Z"
          fill="url(#paint5_linear_792_656)"
        />
        <path
          d="M9.07 82.08C13.46 97.04 23.11 107.36 33.13 114.37C46.79 123.92 61.13 127.3 63.68 127.84L64.09 127.92C46.41 122.38 34.56 113.73 26.72 105.47C15.55 93.72 12.54 82.78 12.54 82.78C13.5 80.3 15.63 78.68 17.83 77.61C18.3 77.39 18.77 77.18 19.24 77.01C19.65 76.86 20.06 76.73 20.44 76.61C22.64 75.92 24.46 75.74 24.71 75.71C23.1 75.68 21.63 75.8 20.3 76.02C19.94 76.07 19.6 76.14 19.26 76.2C18.64 76.32 18.08 76.47 17.53 76.63C11.55 78.41 9.07 82.08 9.07 82.08Z"
          fill="#364745"
          opacity="0.45"
        />
        <path
          d="M124.02 87.1701C123.8 88.2601 123.55 89.3401 123.26 90.4001C116.45 115.83 90.95 131.93 64.76 126.71C41.28 122.03 24.88 101.71 24.25 78.7401C24.15 75.0401 24.46 71.2601 25.21 67.4701C30.65 40.1901 57.18 22.4801 84.46 27.9101C111.74 33.3501 129.45 59.8801 124.01 87.1701H124.02Z"
          fill="url(#paint6_linear_792_656)"
        />
        <path
          d="M124.49 71.53C84.48 79 50.55 63.87 31.01 51.74C41.57 33.77 62.7 23.57 84.28 27.88C106.28 32.27 122.06 50.38 124.49 71.53Z"
          fill="url(#paint7_linear_792_656)"
        />
        <path
          d="M124.49 71.53C84.48 79 50.55 63.87 31.01 51.74C41.57 33.77 62.7 23.57 84.28 27.88C106.28 32.27 122.06 50.38 124.49 71.53Z"
          fill="#364745"
          opacity="0.52"
        />
        <path
          d="M123.37 90.42C116.56 115.85 91.06 131.95 64.87 126.73C41.39 122.05 24.99 101.73 24.36 78.76C33.08 85.41 46.31 93.16 63.63 96.61C85.29 100.93 109.25 95.06 123.36 90.42H123.37Z"
          fill="url(#paint8_linear_792_656)"
        />
        <g opacity="0.5">
          <path
            d="M74.57 29.1201C77.73 29.1201 80.91 29.4401 84.04 30.0601C96.66 32.5801 107.55 39.8601 114.7 50.5701C121.85 61.2801 124.4 74.1201 121.88 86.7501C121.66 87.8601 121.42 88.8601 121.16 89.84C115.53 110.84 96.42 125.51 74.68 125.51C71.52 125.51 68.33 125.19 65.2 124.57C54.44 122.42 44.64 116.6 37.63 108.19C30.72 99.8901 26.75 89.4101 26.45 78.6901C26.35 75.0901 26.66 71.4601 27.37 67.9101C31.85 45.4401 51.7 29.1301 74.57 29.1301M74.57 26.9301C51.04 26.9301 30 43.5101 25.22 67.4701C24.46 71.2601 24.16 75.0401 24.26 78.7401C24.89 101.71 41.28 122.03 64.77 126.71C68.09 127.37 71.41 127.69 74.67 127.69C97.13 127.69 117.32 112.59 123.26 90.4001C123.55 89.3401 123.8 88.2601 124.02 87.1701C129.46 59.8901 111.75 33.3501 84.47 27.9101C81.15 27.2501 77.84 26.9301 74.57 26.9301Z"
            fill="#161D20"
          />
        </g>
        <path
          d="M109.95 84.36C109.79 85.14 109.62 85.91 109.41 86.67C104.54 104.86 86.3 116.38 67.57 112.64C50.77 109.29 39.05 94.76 38.6 78.33C38.53 75.68 38.75 72.98 39.29 70.27C43.18 50.76 62.16 38.09 81.67 41.98C101.19 45.87 113.85 64.85 109.96 84.36H109.95Z"
          fill="url(#paint9_linear_792_656)"
        />
        <path
          d="M109.95 84.36C109.79 85.14 109.62 85.91 109.41 86.67C104.54 104.86 86.3 116.38 67.57 112.64C50.77 109.29 39.05 94.76 38.6 78.33C38.53 75.68 38.75 72.98 39.29 70.27C43.18 50.76 62.16 38.09 81.67 41.98C101.19 45.87 113.85 64.85 109.96 84.36H109.95Z"
          fill="url(#paint10_linear_792_656)"
        />
        <g opacity="0.5">
          <path
            d="M74.58 41.28C76.91 41.28 79.28 41.51 81.66 41.98C101.18 45.87 113.84 64.85 109.95 84.36C109.79 85.14 109.62 85.91 109.41 86.67C105.16 102.55 90.72 113.35 74.65 113.35C72.31 113.35 69.94 113.12 67.57 112.65C50.77 109.3 39.05 94.77 38.6 78.34C38.53 75.69 38.75 72.99 39.29 70.28C42.71 53.14 57.76 41.29 74.59 41.29M74.58 39.09C56.44 39.09 40.69 52.02 37.14 69.85C36.58 72.66 36.33 75.54 36.41 78.4C36.64 86.91 39.79 95.23 45.27 101.81C50.83 108.49 58.6 113.1 67.14 114.81C69.62 115.3 72.15 115.55 74.65 115.55C91.9 115.55 107.06 103.91 111.52 87.25C111.73 86.48 111.92 85.68 112.09 84.8C114.09 74.79 112.06 64.6 106.4 56.11C100.73 47.62 92.1 41.84 82.08 39.85C79.6 39.36 77.08 39.11 74.57 39.11L74.58 39.09Z"
            fill="#161D20"
          />
        </g>
        <path
          d="M110.65 76.83C105.69 71.55 99.1 67.72 91.46 66.19C71.96 62.3 52.98 74.97 49.09 94.48C48.59 97 48.35 99.51 48.39 101.98C42.44 95.66 38.85 87.27 38.6 78.31C38.54 75.66 38.75 72.96 39.29 70.26C43.18 50.75 62.16 38.07 81.67 41.96C98.65 45.35 110.45 60.16 110.65 76.82V76.83Z"
          fill="url(#paint11_linear_792_656)"
        />
        <path
          d="M124.13 87.19C117.66 119.51 82.41 137.21 52.68 122.86C36.19 115.12 23.94 96.67 24.37 78.73C25.5 90.69 30.65 102.35 39.16 110.84C65.49 137.42 110.36 126.67 122.51 91.49C123.07 90.28 123.5 88.41 123.92 87.14L124.13 87.18V87.19Z"
          fill="#364745"
        />
        <path
          d="M69.84 41.4301C92.88 38.2001 113.16 57.7301 110.7 80.8901C110.07 86.4001 108.33 91.8001 105.33 96.4801L105.05 96.3101C107.27 90.84 108.46 85.2801 108.37 79.4601C108.39 66.0101 100.63 53.0601 88.73 46.7601C83.15 43.6901 76.71 42.0201 69.87 41.7501L69.83 41.4201L69.84 41.4301Z"
          fill="#1A2123"
        />
        <g opacity="0.15">
          <path
            d="M36.76 59.94C37.23 58.56 37.52 57.87 38.21 56.54C38.9 55.21 39.64 54.01 40.46 52.82C42.24 50.25 44.45 47.9 46.84 45.83C52.55 40.89 59.81 37.59 67.2 36.15C71.03 35.41 75.02 35.37 78.85 36.15C80.3 36.45 81.81 35.73 82.22 34.24C82.59 32.89 81.76 31.17 80.31 30.87C71.57 29.09 62.98 30.9 54.91 34.4C47.69 37.53 41.37 42.54 36.63 48.82C34.42 51.75 32.66 55 31.48 58.48C31.01 59.88 32.02 61.47 33.39 61.85C34.89 62.26 36.28 61.34 36.76 59.94Z"
            fill="white"
          />
        </g>
        <path
          d="M97.59 129.09C96.02 131.87 93.16 133.67 89.98 133.91L75.23 135.04L62.92 135.98L65.5 123.05L85.1 119.28L103.06 115.83C102.54 119.73 99.75 125.25 97.58 129.09H97.59Z"
          fill="url(#paint12_linear_792_656)"
        />
        <g opacity="0.75" style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M62.92 135.87C71.3 134.37 79.74 133.3 88.19 132.36C92.43 132.27 95.03 130.44 96.72 126.7C98.51 123.21 100.5 119.34 101.17 115.58L103.43 117.72C100.3 118.32 97.15 118.84 94.01 119.36C84.56 120.93 75.07 122.28 65.53 123.16L65.49 122.94L103.04 115.63L103.32 115.58C103.04 117.82 102.26 119.94 101.41 121.99C99.18 126.61 97.19 132.67 91.53 133.86C90.74 134.15 64.66 135.94 62.95 136.1L62.93 135.88L62.92 135.87Z"
            fill="#364745"
          />
        </g>
        <path
          d="M65.5 123.04L62.92 135.97L48.4 128.6L38.72 123.68C35.87 122.24 33.93 119.48 33.54 116.31C33.02 111.93 32.56 105.77 33.57 101.96L47.38 111.07L65.51 123.03L65.5 123.04Z"
          fill="url(#paint13_linear_792_656)"
        />
        <g opacity="0.75" style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M62.88 136.08C61.3 135.2 37.99 123.69 37.34 123.05C34.6 121.18 33.29 117.88 33.16 114.67C32.77 110.35 32.37 105.91 33.45 101.65L33.69 101.81C33.61 102.01 66.13 122.87 65.45 123.15C54.15 117.18 43.24 110.52 32.52 103.58L35.43 102.47C34.61 106.21 34.97 110.55 35.27 114.46C35.4 118.55 37.1 121.26 40.98 122.96C46.56 126.05 52.12 129.18 57.55 132.52C59.38 133.61 61.19 134.73 62.98 135.89L62.88 136.09V136.08Z"
            fill="#364745"
          />
        </g>
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M85.02 127.84C83.19 131.7 79.05 133.74 75.24 135.04L62.93 135.98L48.41 128.61C47.84 127.6 47.38 126.54 47.06 125.45C45.94 121.66 46.67 118.06 48.97 115.3C48.05 114.2 47.44 112.77 47.38 111.09L65.51 123.05L85.11 119.28C86.24 122.47 86.2 125.36 85.02 127.84Z"
            fill="#1A2123"
          />
        </g>
        <g filter="url(#filter0_d_792_656)">
          <path
            d="M82.22 119.36C81.88 119.33 74.43 119.3 64.89 121.11C59.77 122.08 52.63 121.27 54.61 115.68C55.59 115.36 60.18 119 60.34 114.11C59.3 114.44 56.12 112.76 54.93 114.27C54.65 112.46 51.14 111.14 50.2 109.7C49.66 112.67 51.4 114.43 53.39 115.05C49.49 117.5 48.66 121.19 49.68 124.67C50.7 128.15 53.57 131.43 57.08 133.07C57.46 133.62 56.5 134.93 55.03 135.6C58.42 136.7 61.58 134.89 63.32 134.31C64.3 135.1 66.12 136.12 68.43 136.52C68.07 135.82 67.88 134.77 67.95 133.77C70.18 133.49 72.22 133.12 72.93 132.9C79.62 130.85 85.58 127.55 82.22 119.35"
            fill="url(#paint14_linear_792_656)"
          />
          <path
            d="M82.22 119.36C81.88 119.33 74.43 119.3 64.89 121.11C59.77 122.08 52.63 121.27 54.61 115.68C54.61 115.68 52.75 119.24 55.15 121.27C56.26 122.21 59.29 123.29 62.02 122.65C74.27 119.77 82.23 119.36 82.23 119.36H82.22Z"
            fill="#364745"
            opacity="0.5"
          />
          <path
            d="M50.2 109.71C51.07 111.15 54.65 112.47 54.93 114.28C56.11 112.77 59.29 114.45 60.34 114.12C60.34 114.12 55.58 113.48 54.71 114.73C54.71 114.73 53.87 113.2 52.7 112.49C51.53 111.78 50.33 111.19 50.21 109.71H50.2Z"
            fill="#364745"
            opacity="0.5"
          />
          <path
            d="M57.08 133.07C53.98 131.86 50.7 128.15 49.68 124.67C48.66 121.19 49.49 117.5 53.39 115.05C53.39 115.05 43.57 122.95 57.08 133.07Z"
            fill="#364745"
            opacity="0.5"
          />
          <path
            d="M82.22 119.36C85.58 127.56 79.62 130.86 72.93 132.91C72.22 133.13 70.17 133.5 67.95 133.78C67.95 133.78 85.85 130.73 82.22 119.36Z"
            fill="#364745"
            opacity="0.5"
          />
          <path
            d="M55.03 135.6C55.03 135.6 57.69 137.04 63.32 134.31L65.17 133.22C65.17 133.22 58.04 136.37 55.03 135.59V135.6Z"
            fill="#364745"
            opacity="0.5"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="35.58"
        id="filter0_d_792_656"
        width="42.6656"
        x="45.9908"
        y="105.32"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="1.09" />
        <feGaussianBlur stdDeviation="2.19" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_792_656" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_792_656" mode="normal" result="shape" />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_656"
        x1="129.831"
        x2="98.2161"
        y1="46.1438"
        y2="104.65"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_792_656" x1="126.205" x2="94.7844" y1="47.093" y2="105.24">
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_656"
        x1="62.3355"
        x2="35.5053"
        y1="38.6365"
        y2="88.2875"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_656"
        x1="48.563"
        x2="19.2132"
        y1="32.5856"
        y2="86.8938"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint4_linear_792_656" x1="126.055" x2="87.7652" y1="97.64" y2="127.395">
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_792_656" x1="19.4757" x2="43.42" y1="76.333" y2="118.501">
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_792_656"
        x1="63.978"
        x2="87.8945"
        y1="130.655"
        y2="10.7472"
      >
        <stop stopColor="#060909" />
        <stop offset="0.48" stopColor="#121E15" />
        <stop offset="1" stopColor="#161D20" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_792_656"
        x1="75.6379"
        x2="85.9463"
        y1="72.1968"
        y2="20.5148"
      >
        <stop stopColor="#060909" />
        <stop offset="0.48" stopColor="#121E15" />
        <stop offset="1" stopColor="#161D20" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_792_656"
        x1="65.4312"
        x2="74.6344"
        y1="126.846"
        y2="80.7048"
      >
        <stop stopColor="#1A2123" />
        <stop offset="1" stopColor="#060909" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_792_656"
        x1="67.0079"
        x2="84.1154"
        y1="115.465"
        y2="29.694"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.52" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint10_linear_792_656"
        x1="67.0079"
        x2="84.1154"
        y1="115.465"
        y2="29.694"
      >
        <stop stopColor="#060909" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A2123" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint11_linear_792_656"
        x1="39.9382"
        x2="109.831"
        y1="66.9831"
        y2="80.9238"
      >
        <stop stopColor="#1A2123" />
        <stop offset="1" stopColor="#060909" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint12_linear_792_656"
        x1="102.838"
        x2="63.3359"
        y1="115.524"
        y2="136.635"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.25" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint13_linear_792_656"
        x1="59.9384"
        x2="37.3737"
        y1="112.719"
        y2="124.778"
      >
        <stop stopColor="#161D20" />
        <stop offset="0.25" stopColor="#121E15" />
        <stop offset="1" stopColor="#060909" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint14_linear_792_656"
        x1="47.8036"
        x2="82.3432"
        y1="121.719"
        y2="128.608"
      >
        <stop stopColor="#060909" />
        <stop offset="0.48" stopColor="#121E15" />
        <stop offset="1" stopColor="#161D20" />
      </linearGradient>
      <clipPath id="clip0_792_656">
        <rect fill="white" height="144.31" width="162.18" />
      </clipPath>
    </defs>
  </svg>
);

export default Badge2;

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import OfferTrackingLinkDisplay from '../OfferTrackingLinkDisplay/OfferTrackingLinkDisplay.react';
import { Container } from '../../../components/reusables';
import TrackingLinkIcon from '../../../components/reusables/svg/TrackingLinkIcon.react';

import messages from '../../../i18n/base-en.js';
import { explodeDescription } from '../../../utils/helpers';

const OfferTrackingLink = ({
  campaignLink,
  dispatch,
  fields,
  offer,
  overlay,
  showEmailPopup,
  trackingLink,
  userEmail,
  cancelModalButton,
  trakingLinkFormValues,
  generateCustomLink,
  isAutoOptimized,
  isReferrals,
  values,
}) => {
  const getActiveLandingPages = (landingPages) => {
    if (landingPages) {
      return landingPages.filter((landingPage) => landingPage.status === 'active');
    }
  };

  const changeCurrentOffer = (newId) => {
    if (fields.url_id && fields.url_id.onChange) {
      fields.url_id.onChange(newId);
    }
  };

  const explodedDescription = explodeDescription(offer.description);
  return (
    <form action="#" id="offer-tracking-link-container" method="post">
      <Container
        Svg={TrackingLinkIcon}
        className="z-depth-2 black-title offercontainer"
        collapsable
        isOpen
        overlay={overlay}
        title={<FormattedMessage {...messages.offerTrackingLink} />}
      >
        <OfferTrackingLinkDisplay
          campaignLink={campaignLink}
          cancelModalButton={cancelModalButton}
          changeCurrentOffer={changeCurrentOffer}
          currentUrlId={values.url_id}
          dispatch={dispatch}
          fields={fields}
          generateCustomLink={generateCustomLink}
          isAutoOptimized={isAutoOptimized}
          isReferrals={isReferrals}
          offerScreenshots={offer?.screenshot}
          offerUrl={getActiveLandingPages(offer.landing_pages)}
          performerLandingIds={explodedDescription.config?.performer_landing_ids}
          showEmailPopup={showEmailPopup}
          trackingLink={trackingLink}
          trakingLinkFormValues={trakingLinkFormValues}
          trendingPerformers={explodedDescription.config?.trending_performers}
          userEmail={userEmail}
        />
      </Container>
    </form>
  );
};

OfferTrackingLink.propTypes = {
  campaignLink: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  offer: PropTypes.object.isRequired,
  overlay: PropTypes.object,
  showEmailPopup: PropTypes.func,
  trackingLink: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default injectIntl(OfferTrackingLink);

import { OFFER_TABLE_HEADERS } from './data';
import './styles/OfferListingTable.scss';

const OfferListingRowHeader = () => {
  return (
    <div className="offerListingRow offerListingRowHeader">
      {OFFER_TABLE_HEADERS.map(({ keyHeader, title }) => (
        <div key={keyHeader} class={`offerListingGridColumn column-${keyHeader}`}>
          {title}
        </div>
      ))}
    </div>
  );
};

export default OfferListingRowHeader;

import Card from './Card';
import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

const JerkmatePromoTools = () => {
  const { jerkmatePromotools } = useJerkmateOffersContext();
  const sectionRef = useRef(null);
  const promotoolsRef = useRef([]);
  const inScreenArrayRef = useRef([]);
  const triggerCount = useRef(0);
  const intervalRef = useRef(null);

  const [inScreenSection, setInScreenSection] = useState(false);
  const [staggerTools, setStaggerTools] = useState([]);

  useEffect(() => {
    let triggerStagger = false;

    const removeListener = () => {
      clearInterval(intervalRef.current);
      window.removeEventListener('scroll', onScroll);
    };

    const onScroll = () => {
      const sectionHeight = sectionRef.current.offsetHeight / 2;
      const sectionPosition = sectionRef.current.getBoundingClientRect().top - window.innerHeight + sectionHeight + 150;

      if (sectionPosition < 0) {
        setInScreenSection(true);
      }

      if (promotoolsRef?.current?.length) {
        const inScreen = [];

        promotoolsRef?.current.forEach((promotool, index) => {
          const height = promotool.offsetHeight / 2;
          const distance = promotool.getBoundingClientRect().top - window.innerHeight + height;

          if (distance < 0) {
            inScreen.push(index);
          }
        });

        if (inScreen.length) {
          inScreenArrayRef.current = [...new Set([...inScreenArrayRef.current, ...inScreen])];

          if (!triggerStagger) {
            triggerStagger = true;

            intervalRef.current = setInterval(() => {
              if (inScreenArrayRef.current.includes(triggerCount.current)) {
                setStaggerTools((staggered) => [...staggered, triggerCount.current]);
                triggerCount.current++;
              }

              if (triggerCount.current === promotoolsRef?.current?.length) {
                removeListener();
              }
            }, 200);
          }
        }
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();

    return () => {
      removeListener();
    };
  }, []);

  return (
    <div className="promotools-section">
      <div className={`promotools-header ${inScreenSection ? 'active' : ''}`} ref={sectionRef}>
        <h2 className="jerkmate-page-title">Get maximum conversions rates with our exclusive tools:</h2>
        <p>Take advantage of our exclusive array of high-performing tools and in-house creatives</p>
      </div>
      <div className="tools-list">
        {jerkmatePromotools.map((promotool, index) => (
          <Card
            key={index}
            index={index}
            promotoolsRef={promotoolsRef}
            className={staggerTools.includes(index) ? 'active' : ''}
            promotool={promotool}
          />
        ))}
      </div>
    </div>
  );
};
export default connect((state) => ({
  promoTools: state.promoTools.promoTools,
}))(JerkmatePromoTools);

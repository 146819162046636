const Star = () => (
  <svg viewBox="0 0 306 306" x="0px" y="0px">
    <g>
      <polygon
        fill="#C3A157"
        points="153,230.775 247.35,299.625 211.65,187.425 306,121.125 191.25,121.125 153,6.375 114.75,121.125 0,121.125 94.35,187.425 58.65,299.625"
      />
    </g>
  </svg>
);

export default Star;

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

const getClassNames = ({ currentViewMode, viewMode }) =>
  ['btn-icon', 'btn-viewmode', 'viewMode-cards', currentViewMode === viewMode ? 'primary-color' : 'basic-colors'].join(' ');

const ViewMode = ({ classNames, currentViewMode, handleViewModeChange }) => (
  <div className={classNames.join(' ')} id="viewmode-component">
    <span
      className={getClassNames({ currentViewMode, viewMode: 'cards' })}
      onClick={() => {
        handleViewModeChange('cards');
      }}
    >
      <i className="material-icons">view_module</i>
    </span>
    <span
      className={getClassNames({ currentViewMode, viewMode: 'table' })}
      onClick={() => {
        handleViewModeChange('table');
      }}
    >
      <i className="material-icons">&#xE896;</i>
    </span>
    <span className="viewmode-text">
      <FormattedMessage {...messages.genericTextViewMode} />:
    </span>
  </div>
);

ViewMode.defaultProps = {
  classNames: [],
};

ViewMode.propTypes = {
  classNames: PropTypes.array,
  currentViewMode: PropTypes.string.isRequired,
  handleViewModeChange: PropTypes.func.isRequired,
};

export default ViewMode;

const UpgradedSmartlinkIcon = () => (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14_21049)">
      <path
        d="M13.0463 6.25017L10.4855 8.45992L11.2657 11.7646C11.3087 11.944 11.2977 12.1321 11.2338 12.3052C11.17 12.4783 11.0563 12.6286 10.9071 12.7371C10.7579 12.8456 10.5799 12.9075 10.3956 12.9149C10.2113 12.9223 10.0289 12.8749 9.87144 12.7787L6.99759 11.01L4.12202 12.7787C3.96463 12.8743 3.78245 12.9213 3.59843 12.9136C3.41441 12.9059 3.23677 12.844 3.08789 12.7356C2.939 12.6271 2.82553 12.4771 2.76175 12.3043C2.69797 12.1315 2.68674 11.9437 2.72948 11.7646L3.51253 8.45992L0.95167 6.25017C0.812415 6.12982 0.711703 5.97111 0.662112 5.79386C0.612521 5.6166 0.616248 5.42867 0.672829 5.25353C0.72941 5.07838 0.836336 4.92379 0.980255 4.80905C1.12417 4.69431 1.2987 4.62451 1.48205 4.60838L4.83963 4.33749L6.13486 1.203C6.20497 1.03217 6.32429 0.886045 6.47766 0.783207C6.63102 0.680368 6.81151 0.625458 6.99616 0.625458C7.18082 0.625458 7.3613 0.680368 7.51467 0.783207C7.66804 0.886045 7.78736 1.03217 7.85747 1.203L9.15213 4.33749L12.5097 4.60838C12.6934 4.62391 12.8685 4.69332 13.0129 4.8079C13.1573 4.92248 13.2648 5.07714 13.3217 5.25249C13.3786 5.42785 13.3825 5.61611 13.333 5.79369C13.2834 5.97126 13.1825 6.13025 13.0429 6.25074L13.0463 6.25017Z"
        fill="#F8F8F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_14_21049">
        <rect fill="white" height="13" transform="translate(0.5 0.5)" width="13" />
      </clipPath>
    </defs>
  </svg>
);

export default UpgradedSmartlinkIcon;

import PropTypes from 'prop-types';

import OfferAdditionalInfo from '../OfferAdditionalInfo/OfferAdditionalInfo.react';
import OfferConversionFlow from '../OfferConversionFlow/OfferConversionFlow.react';

const OfferAdditionalContainer = ({
  offerChannels,
  offerOS,
  offerTargetAudience,
  offerTopPromotionalMethods,
  offerVerticals,
  offerConversionFlow,
  offerConversionTitle,
}) => (
  <div id="offer-additional-container">
    <OfferAdditionalInfo
      offerChannels={offerChannels}
      offerOS={offerOS}
      offerTargetAudience={offerTargetAudience}
      offerTopPromotionalMethods={offerTopPromotionalMethods}
      offerVerticals={offerVerticals}
    />
    <OfferConversionFlow offerConversionFlow={offerConversionFlow} offerConversionTitle={offerConversionTitle} />
  </div>
);

OfferAdditionalContainer.propTypes = {
  offerChannels: PropTypes.array,
  offerConversionFlow: PropTypes.array.isRequired,
  offerConversionTitle: PropTypes.string.isRequired,
  offerOS: PropTypes.array,
  offerTargetAudience: PropTypes.array,
  offerTopPromotionalMethods: PropTypes.array,
  offerVerticals: PropTypes.array,
};

export default OfferAdditionalContainer;

export const adToolDefaultState = {
  postitial: {
    width: '85%',
    height: '85%',
    animation: 'slide',
    clickStart: false,
    closeIntent: false,
    timeout: false,
    direction: 'up',
    verticalPosition: 'center',
    postitialBehavior: true,
    url: '',
    contentUrl: '',
    expireDays: 0.01,
  },
  prestitial: {
    width: '35%',
    height: '35%',
    timeout: 1000,
    animation: 'slide',
    clickStart: false,
    direction: 'up',
    verticalPosition: 'center',
    horizontalPosition: 'right',
    url: '',
    contentUrl: '',
    expireDays: 0.05,
  },
};

export const previewCustomValues = {
  postitial: {},
  prestitial: {
    timeout: 1,
  },
};

export const formatCreativeCode = ({ creativeCode, object }) => {
  for (const key in object) {
    let value = object[key];

    const patternStart = `${key}:`;

    let regex = new RegExp(`(${patternStart}[^,\r\n]*)`, 'g');

    //if value is not boolean, wrap the replacement valye in 'value' to make it a string
    if (typeof value !== 'boolean' && isNaN(value)) {
      regex = new RegExp(`${patternStart}*.(['|"].*['|"])`, 'g');
      value = value.replaceAll('%2C', ',');
      value = `'${value}'`;
    }

    creativeCode = creativeCode?.replace(regex, `${patternStart} ${value}`);
  }

  return creativeCode;
};
export const adToolText = {
  showPreview: 'Show Preview',
  showCode: 'Show Code',
  postitial: {
    loading: 'Loading Postitial ...',
    noData: 'No Postitial available for this offer',
    copy: 'Copy code for integration',
    'intro.title': `Waste no traffic`,
    'intro.paragraph': `Improve your conversion rate with full-size, impactful digital ads that appear before a page change, so they don't disrupt the user experience.`,
    'warning.title': 'The ad appears within the page.',
    'warning.paragraph':
      'We recommend testing the Postitial code prior to any installation since some advertisers prevent their content from appearing in this specific advertising format.',
    tooltip:
      'Use the options below to determine how your Postitial ad will appear. You can edit the script to adjust even more the ad’s behaviour based on your preferences.',
    copyCode: 'Copy Code',
  },
  prestitial: {
    loading: 'Loading Prestitial ...',
    noData: 'No Prestitial available for this offer',
    copy: 'Copy code for integration',
    'intro.title': `Maximize your ads' visibility`,
    'intro.paragraph': `With Prestitial ads, large creatives are displayed over your home page before your users can engage with your content.`,
    'warning.title': 'The ad appears within the page.',
    'warning.paragraph':
      'We recommend using mid-sized or smaller prestitials with an easy-to-find and always visible dismissal option for your ads. This facilitates a better user experience on your website.',
    tooltip:
      'Use the options below to determine how your Prestitial ad will appear. You can edit the script to adjust even more the ad’s behaviour based on your preferences.',
    copyCode: 'Copy Code',
  },
  chathead: {
    loading: 'Loading Chat Head ...',
    copyCode: 'Copy code for integration',
  },
};

export const PREVIEW_TABS = Object.freeze({
  SHOW_PREVIEW: adToolText.showPreview,
  SHOW_CODE: adToolText.showCode,
});

export const previewTabs = [];

for (const key in PREVIEW_TABS) {
  const value = PREVIEW_TABS[key];
  previewTabs.push(value);
}

export const animationTabs = [
  {
    text: 'Slide up',
    animation: 'slide',
    direction: 'up',
  },
  {
    text: 'Slide down',
    animation: 'slide',
    direction: 'down',
  },
  {
    text: 'Fade',
    animation: 'fade',
  },
];

const positionsY = Object.freeze({
  top: 'top',
  center: 'center',
  bottom: 'bottom',
});

const positionsX = Object.freeze({
  top: 'left',
  center: 'center',
  bottom: 'right',
});

export const verticalPositionTabs = [
  {
    text: 'Top',
    verticalPosition: positionsY.top,
  },
  {
    text: 'Center',
    verticalPosition: positionsY.center,
  },
  {
    text: 'Bottom',
    verticalPosition: positionsY.bottom,
  },
];

export const prestitialPositions = [];

for (const keyY in positionsY) {
  const verticalPosition = positionsY[keyY];

  for (const keyX in positionsX) {
    const horizontalPosition = positionsX[keyX];

    const obj = {
      verticalPosition,
      horizontalPosition,
    };

    prestitialPositions.push(obj);
  }
}

import PropTypes from 'prop-types';

function AlignLeft({ isActive }) {
  let classes = 'align-icon align-left';

  if (isActive) {
    classes += ' active';
  }

  return (
    <svg className={classes} height="16px" viewBox="0 0 20 16" width="20px">
      <g fill="none" fillRule="evenodd" id="align-left-svg" stroke="none" strokeWidth="1">
        <g id="03_Survey_BasicConfiguration" transform="translate(-1254.000000, -399.000000)">
          <g id="Settings" transform="translate(81.000000, 233.000000)">
            <g id="Custom-Logo-URL" transform="translate(654.000000, 138.000000)">
              <g id="Alignement-logo" transform="translate(518.000000, 28.000000)">
                <g id="Page-1" transform="translate(1.000000, 0.000000)">
                  <path
                    className="darken-color"
                    d="M3.00554545,14 L18.9940455,14 C19.5495909,14 20,13.72475 20,13.3855 L20,9.6145 C20,9.27525 19.5495909,9 18.9944545,9 L3.00554545,9 C2.45040909,9 2,9.27525 2,9.6145 L2,13.38525 C2,13.72475 2.45040909,14 3.00554545,14"
                    fill="#888888"
                    id="Fill-1"
                  />
                  <path
                    d="M0.5,16 C0.224,16 0,15.8805333 0,15.7333333 L0,0.266666667 C0,0.119466667 0.224,0 0.5,0 C0.776,0 1,0.119466667 1,0.266666667 L1,15.7333333 C1,15.8805333 0.776,16 0.5,16"
                    fill="#333333"
                    id="Fill-3"
                  />
                  <path
                    className="lighten-color"
                    d="M3.01211765,7 C2.45335294,7 2,6.72475 2,6.3855 L2,2.6145 C2,2.27525 2.45335294,2 3.01211765,2 L14.9874706,2 C15.5466471,2 16,2.27525 16,2.6145 L16,6.38525 C16,6.72475 15.5466471,7 14.9878824,7 L3.01211765,7 Z"
                    fill="#D4D4D4"
                    id="Fill-5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

AlignLeft.propTypes = {
  isActive: PropTypes.bool,
};

export default AlignLeft;

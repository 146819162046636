import { useCallback, useRef, useState, useMemo } from 'react';
import { HexColorPicker, RgbaStringColorPicker } from 'react-colorful';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import useClickOutside from './useClickOutside';
import { InputText } from '..';
extend([namesPlugin]);

// wherever this component is being used, make sure to have a useState of [color, setColor]
// if it is being used to handle a field, make sure to use a concurrentCalls function
// (see InfobarSettingsFont.js as an example)

export const CustomColorPicker = ({ color, onChange, disabled, input, field, mask, inputOnChange, ...rest }) => {
  const popover = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);

  return (
    <div
      className="color-picker-wrapper"
      style={{
        pointerEvents: disabled ? 'none' : 'unset',
        filter: disabled && 'opacity(0.5)',
      }}
    >
      {input ? (
        <div className="color-picker-input-field">
          <InputText field={field} type="text" {...rest} disabled={disabled} mask={mask} maxLength={7} onChange={inputOnChange} />
        </div>
      ) : null}
      <div
        className="picker"
        style={{
          display: !input ? 'flex' : 'unset',
          flexDirection: !input ? 'column' : 'unset',
        }}
      >
        {!input && rest.label ? <span>{rest.label.defaultMessage}</span> : null}
        <div className="swatch" onClick={() => setIsOpen(true)} style={{ backgroundColor: field.value || color }}>
          {isOpen ? (
            <div className="popover" ref={popover}>
              <HexColorPicker color={field.value || color} onChange={onChange} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const CustomPicker = ({ color, disabled, input, handleChange, inputOnChange, field, ...rest }) => {
  const popover = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);
  const rgbaString = useMemo(() => (color.startsWith('rgba') ? color : colord(color).toRgbString()), [color]);

  if (typeof field === 'undefined') {
    return null;
  }

  return (
    <div
      className="color-picker-wrapper"
      style={{
        pointerEvents: disabled ? 'none' : 'unset',
        filter: disabled && 'opacity(0.5)',
      }}
    >
      {input ? (
        <div className="color-picker-input-field">
          <InputText field={field} type="text" {...rest} disabled={disabled} maxLength={7} onChange={inputOnChange} />
        </div>
      ) : null}
      <div className="picker">
        {!input && rest.label ? <span>{rest.label.defaultMessage}</span> : null}
        <div className="swatch" onClick={() => setIsOpen(true)} style={{ backgroundColor: field.value || color }}>
          {isOpen ? (
            <div className="popover" ref={popover}>
              <RgbaStringColorPicker color={rgbaString} {...rest} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const LeftBrokenBar = ({ className }) => (
  <svg className={`broken-bar ${className}`} viewBox="0 0 53 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.6159 36.3201L51.9325 47.5H0.5L0.5 0.5L51.9325 0.5L42.6159 11.6799L42.3491 12L42.6159 12.3201L52.3491 24L42.6159 35.6799L42.3491 36L42.6159 36.3201Z"
      fill="#01262E"
      stroke="#00BD00"
      strokeWidth="3px"
    />
  </svg>
);

export default LeftBrokenBar;

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';
import provincesList from '../../../utils/list-options/provinces';
import statesList from '../../../utils/list-options/states';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';

function CheckSection({ fields, values, willRender, countries, dispatch, intl }) {
  let checkSection = false;

  const usOnly = countries.filter((country) => country.code === 'US');

  const handleCheckCountryChange = () => {
    if (values.checkCountry !== 'US') {
      const checkFields = [
        'checkAddress',
        'checkCity',
        'checkCountry',
        'checkProvince',
        'checkState',
        'checkRegion',
        'checkZipcode',
      ];

      checkFields.map((field) =>
        dispatch({
          type: 'redux-form/CHANGE',
          form: 'billingInfos',
          field: field,
          value: null,
        })
      );
    }
  };

  if (willRender) {
    checkSection = (
      <div className="check_section">
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.checkPayableTo}
            id="checkPayableTo"
            label={messages.paymentInfosCheckPayableToLabel}
            maxLength={35}
            tabIndex={30}
            type="text"
          />
        </div>
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.checkAddress}
            id="checkAddress"
            label={messages.genericTextAddress}
            maxLength={60}
            tabIndex={40}
            type="text"
          />
        </div>
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.checkCity}
            id="checkCity"
            label={messages.genericTextCity}
            maxLength={20}
            tabIndex={50}
            type="text"
          />
        </div>

        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              enableSearch
              id="checkCountry"
              label={messages.genericTextCountry.description}
              onChange={fields.checkCountry.onChange}
              options={usOnly.map((country) => ({ value: country.code, text: country.name }))}
              showSelectedFieldCheckmark={false}
              tabIndex={60}
              touched={fields.checkCountry.touched}
              value={fields.checkCountry.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
        </div>
        {fields.checkCountry.value === 'CA' && (
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                id="checkProvince"
                label={messages.genericTextProvince.description}
                onChange={fields.checkProvince.onChange}
                options={provincesList.map((province) => ({
                  value: province.value,
                  text: intl.formatMessage(messages[province.label.id]),
                }))}
                showSelectedFieldCheckmark={false}
                tabIndex={70}
                touched={fields.checkProvince.touched}
                value={fields.checkProvince.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        )}

        {fields.checkCountry.value === 'US' && (
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                id="checkProvince"
                label={messages.genericTextState.description}
                onChange={fields.checkState.onChange}
                options={statesList.map((state) => ({ value: state.value, text: intl.formatMessage(messages[state.label.id]) }))}
                showSelectedFieldCheckmark={false}
                tabIndex={70}
                touched={fields.checkProvince.touched}
                value={fields.checkState.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        )}

        {values.checkCountry && values.checkCountry !== 'CA' && values.checkCountry !== 'US' ? (
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.checkRegion}
              id="checkRegion"
              label={messages.genericTextRegion}
              tabIndex={70}
              type="text"
            />
          </div>
        ) : null}

        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.checkZipcode}
            id="checkZipcode"
            label={messages.genericTextZipCode}
            tabIndex={80}
            type="text"
          />
        </div>
      </div>
    );
  }

  return checkSection;
}

CheckSection.propTypes = {
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default injectIntl(CheckSection);

const GreenCheckMark = () => (
  <svg fill="none" height="28" viewBox="0 0 26 28" width="26" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M17.1342 11.5816L12.1866 16.8139C12.0292 16.9805 11.8245 17.0632 11.6188 17.0632C11.4121 17.0632 11.2074 16.9805 11.0499 16.8139L8.57619 14.1978C8.26228 13.8658 8.26228 13.3276 8.57619 12.9956C8.89009 12.6637 9.39791 12.6637 9.71182 12.9956L11.6188 15.0113L15.9975 10.3795C16.3114 10.0475 16.8203 10.0475 17.1342 10.3795C17.4481 10.7115 17.4481 11.2497 17.1342 11.5816ZM12.8562 2.83252C7.24449 2.83252 2.67838 7.66142 2.67838 13.5961C2.67838 19.532 7.24449 24.3598 12.8562 24.3598C18.468 24.3598 23.0341 19.532 23.0341 13.5961C23.0341 7.66142 18.468 2.83252 12.8562 2.83252Z"
      fill="#00BD00"
      fillRule="evenodd"
    />
  </svg>
);

export default GreenCheckMark;

const excludedCountriesString = `
    excluded_countries {
      id
      code
      name
    }
`;

export default (searchParams) => `
  Offer(id:${searchParams.id}) {
    id
    name
    description
    terms_and_conditions
    default_payout
    default_goal_name
    payout_type
    conversion_cap
    currency
    percent_payout
    preview_url
    featured
    require_terms_and_conditions
    require_approval
    approval_status
    email_instructions
    email_instructions_from
    email_instructions_subject
    show_mail_list
    dne_download_url
    dne_unsubscribe_url
    top_countries
    epc_affiliation
    epc_global
    screenshot {
      desktop
      mobile
    }
    landing_pages {
      id
      name
      preview_url
      epc_affiliation
      status
      screenshot {
        desktop
        desktop_thumbnail
        mobile
        mobile_thumbnail
      }
    }
    operating_systems {
      id
      name
    }
    channels {
      id
      name
    }
    countries {
      id
      code
      name
    }
    ${excludedCountriesString}
    verticals {
      id
      name
    }
    categories {
      id
      name
    }
    thumbnail {
      thumbnail
    }
    conversion_types {
      id
      name
    }
    tags {
      id
      name
    },
    media_placements {
      id
      name
      active
    }                
  }
`;

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL, POPCODE_CREATIVE_FILE_ID } = config;
const { ACTIONS } = consts;

export const setColorTemplate = (int) => ({
  type: ACTIONS.SET_COLOR_TEMPLATE,
  colorTemplate: int,
});
export const setAnimatedTemplateSurvey = (animated) => ({
  type: ACTIONS.SET_ANIMATED_TEMPLATE_SURVEY,
  animated,
});

const requestSurveyCreativeScript = () => ({
  type: ACTIONS.REQUEST_SURVEY_CREATIVE_SCRIPT,
});
const receiveSurveyCreativeScript = (data) => ({
  type: ACTIONS.RECEIVE_SURVEY_CREATIVE_SCRIPT,
  code: data.code,
});
const failedToReceiveSurveyCreativeScript = () => ({
  type: ACTIONS.FAILED_SURVEY_CREATIVE_SCRIPT,
});
export const fetchSurveyCreativeScript = () => (dispatch) => {
  dispatch(requestSurveyCreativeScript());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/creative/get-creative-code`)
      .withCredentials()
      .query({ id: POPCODE_CREATIVE_FILE_ID })
      .then((res) => {
        dispatch(receiveSurveyCreativeScript(res.body));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveSurveyCreativeScript());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

const requestSurveyQuestions = () => ({
  type: ACTIONS.REQUEST_SURVEY_QUESTIONS,
});
const receiveSurveyQuestions = (data) => ({
  type: ACTIONS.RECEIVE_SURVEY_QUESTIONS,
  questions: data,
});
const failedToReceiveSurveyQuestions = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_SURVEY_QUESTIONS,
});
export const fetchSurveyQuestions = (sgId) => (dispatch) => {
  dispatch(requestSurveyQuestions());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/survey-builder/questions?sg_id=${sgId}`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveSurveyQuestions(res.body));
          fulfill();
        } else {
          dispatch(failedToReceiveSurveyQuestions());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestCreatedCreative = () => ({
  type: ACTIONS.REQUEST_CREATED_CREATIVE,
});
const receiveCreatedCreative = (id, code) => ({
  type: ACTIONS.RECEIVE_CREATED_CREATIVE,
  creativeId: id,
  creativeCode: code,
});
const failedToReceiveCreatedCreative = () => ({
  type: ACTIONS.FAILED_TO_CREATE_CREATIVE,
});
export const postCreateCreative = (type, code, display, placeholders) => (dispatch) => {
  dispatch(requestCreatedCreative());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/creative/create`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        type,
        code,
        display,
        placeholders,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveCreatedCreative(res.body.creativeId, code));
          fulfill();
        } else {
          dispatch(failedToReceiveCreatedCreative());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const fetchUnadblockCreativeCode = (id, creativeCode) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/creative/unadblock-creative`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        creativeCode: creativeCode,
        creativeId: id,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveCreatedCreative(id, res.body));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );

export const fetchCreatedCreative = (id, placeholdersParam) => (dispatch) => {
  dispatch(requestSurveyCreativeScript());
  return new Promise((fulfill, reject) => {
    const placeholders = {
      ...placeholdersParam,
      file_id: id,
    };
    return request
      .post(`${API_URL}/creative/get-creative-code`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        creativeId: id,
        placeholders,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(fetchUnadblockCreativeCode(id, res.body));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
  });
};

const requestTemplatesUrl = () => ({ type: ACTIONS.REQUEST_TEMPLATE_URL });
const receiveTemplatesUrl = (data) => ({
  type: ACTIONS.RECEIVE_TEMPLATE_URL,
  templateUrls: data.offer_url,
});
const failedToReceiveTemplatesUrl = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_TEMPLATE_URL,
});
export const fetchOfferTemplatesUrl = (id) => (dispatch) => {
  dispatch(requestTemplatesUrl());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offerUrl/findById`)
      .withCredentials()
      .query({ id })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveTemplatesUrl(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveTemplatesUrl());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

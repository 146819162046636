const Badge = () => (
  <svg className="badge-1" fill="none" height="90" viewBox="0 0 87 90" width="87" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_792_726)">
      <g opacity="0.45" style={{ mixBlendMode: 'multiply' }}>
        <path d="M8.74 7.49L0 65.3L32.68 89.5L72.14 79.69L86.25 22.94L50.53 0L8.74 7.49Z" fill="url(#paint0_linear_792_726)" />
        <g opacity="0.5">
          <path
            d="M50.43 0.51L85.7 23.17L71.75 79.3L32.79 88.99L0.52 65.09L9.16 7.9L50.43 0.51ZM50.53 0L8.74 7.49L0 65.3L32.68 89.5L72.14 79.69L86.25 22.94L50.53 0Z"
            fill="#253829"
          />
        </g>
        <path
          d="M17.05 18.45L12.9 58.76L35.48 75.48L62.74 68.7L72.95 29.6L47.8 13.65L17.05 18.45Z"
          fill="url(#paint1_linear_792_726)"
          opacity="0.45"
        />
        <path
          d="M15.18 37.6801L12.9 58.7601L35.48 75.4801L62.74 68.7001L70.6 38.9301L15.18 37.6801Z"
          fill="url(#paint2_linear_792_726)"
        />
        <g opacity="0.5" style={{ mixBlendMode: 'soft-light' }}>
          <path d="M8.74001 7.49L17.05 18.45L47.8 13.65L50.53 0L8.74001 7.49Z" fill="#253829" />
        </g>
        <path d="M12.9 58.76L0 65.3L32.68 89.5L35.48 75.48L12.9 58.76Z" fill="url(#paint3_linear_792_726)" opacity="0.45" />
        <path d="M35.48 75.48L32.68 89.5L72.14 79.69L62.74 68.7L35.48 75.48Z" fill="url(#paint4_linear_792_726)" opacity="0.45" />
        <g opacity="0.5" style={{ mixBlendMode: 'soft-light' }}>
          <path
            d="M47.8 13.65L72.95 29.59L62.73 68.7L35.47 75.48L12.9 58.76L17.05 18.44L47.8 13.64M47.91 13.15L47.73 13.18L16.98 17.98L16.61 18.04L16.57 18.41L12.42 58.73L12.39 59L12.61 59.16L35.18 75.88L35.36 76.01L35.58 75.96L62.84 69.18L63.12 69.11L63.19 68.83L73.41 29.72L73.5 29.38L73.2 29.19L48.05 13.25L47.9 13.15H47.91Z"
            fill="#253829"
          />
        </g>
        <path
          d="M15.18 37.6801L14.01 48.5301L68.06 48.5501L70.6 38.9301L15.18 37.6801Z"
          fill="url(#paint5_linear_792_726)"
          opacity="0.2"
          style={{ mixBlendMode: 'multiply' }}
        />
        <g filter="url(#filter0_d_792_726)">
          <path
            d="M44.09 51.43L48.18 65.4L44.83 66.38L43.15 66.87L42.89 65.99L39.06 52.91L28.99 58.34L30.3 38.3L31.6 18.21L46.57 38.48L55.49 50.56L44.09 51.43Z"
            fill="#355538"
          />
          <path
            d="M44.09 51.4299L48.18 65.3999L44.83 66.3799L42.89 65.9899L39.06 52.9099L28.99 58.3399L30.3 38.2999L46.57 38.4799L55.49 50.5599L44.09 51.4299Z"
            fill="url(#paint6_linear_792_726)"
            style={{ mixBlendMode: 'multiply' }}
          />
          <g opacity="0.5">
            <path
              d="M31.75 18.72L46.42 38.59L55.14 50.41L44.07 51.25L43.84 51.27L43.9 51.49L47.94 65.28L44.76 66.21L43.26 66.65L43.05 65.95L39.22 52.87L39.16 52.65L38.96 52.76L29.18 58.03L30.46 38.32L31.73 18.73M31.6 18.21L30.29 38.3L28.99 58.34L39.06 52.91L42.89 65.99L43.15 66.87L44.82 66.38L48.17 65.4L44.08 51.44L55.48 50.57L46.56 38.49L31.59 18.22L31.6 18.21Z"
              fill="#0A1A1E"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="53.81"
        id="filter0_d_792_726"
        width="26.5"
        x="28.99"
        y="18.21"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="5.15" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.282353 0 0 0 0 0.188235 0 0 0 0.75 0" />
        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_792_726" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_792_726" mode="normal" result="shape" />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_792_726"
        x1="48.6188"
        x2="30.1459"
        y1="9.57971"
        y2="102.195"
      >
        <stop stopColor="#243626" />
        <stop offset="0.52" stopColor="#191E1B" />
        <stop offset="1" stopColor="#151B1B" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_792_726"
        x1="45.7806"
        x2="33.0194"
        y1="20.1283"
        y2="84.1081"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_792_726"
        x1="42.9345"
        x2="34.2888"
        y1="38.0784"
        y2="81.4246"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_792_726"
        x1="-0.83205"
        x2="35.2473"
        y1="69.4229"
        y2="76.6192"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_792_726"
        x1="35.2473"
        x2="71.3168"
        y1="76.6192"
        y2="83.8135"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_792_726"
        x1="42.5504"
        x2="42.0459"
        y1="38.5728"
        y2="48.9241"
      >
        <stop stopColor="#0B2024" />
        <stop offset="1" stopColor="#151B1B" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_792_726"
        x1="42.5252"
        x2="41.9125"
        y1="39.1081"
        y2="71.4225"
      >
        <stop stopColor="#C1C7C8" />
        <stop offset="1" stopColor="#25373D" stopOpacity="0.2" />
      </linearGradient>
      <clipPath id="clip0_792_726">
        <rect fill="white" height="89.5" width="86.25" />
      </clipPath>
    </defs>
  </svg>
);

export default Badge;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from '../../i18n/base-en.js';
import { Gtm } from '../../utils/gtm';

import { detectMobileAndTablet } from '../../utils/helpers';
import { setStatisticsReport } from '../../actions/statistics-actions';

import consts from '../../utils/consts';
import { openModal } from '../../components/Modal/modal-actions/modal-actions.js';
const { STATS_REPORTS, MOBILE_STATS_REPORTS } = consts;

const StatisticsSubReports = ({
  reportSubMenu,
  statisticsDateEnd,
  statisticsDateStart,
  statisticsIsFetching,
  dispatch,
  statisticsSelectedRows,
  drillDownValues,
}) => {
  const handleModalClick = () => {
    dispatch(
      openModal({
        name: 'StatisticsAddColumnModal',
        modalProps: {
          className: 'stats-add-column-modal',
          dispatch,
          drillDownValues,
          id: 'addColumn',
          statisticsSelectedRows,
        },
      })
    );
  };

  useEffect(() => {}, [reportSubMenu, statisticsDateEnd, statisticsDateStart]);

  useEffect(() => {
    window.crakrevenue.initTabsScrolling();
  });

  const handleReportChange = (e) => {
    if (!statisticsIsFetching) {
      const el = e.target.tagName === 'LI' ? e.target : e.target.parentNode;

      Gtm.event('statistics', 'Click', `statistics data option - ${el.getAttribute('data-reportsubmenu')}`);

      dispatch(
        setStatisticsReport(
          el.getAttribute('data-reportmenu'),
          el.getAttribute('data-reportsubmenu'),
          JSON.parse(el.getAttribute('data-report'))
        )
      );
    }
  };

  const { DAILY, COUNTRY, OFFER, DEVICE, VERTICAL } = detectMobileAndTablet() ? MOBILE_STATS_REPORTS : STATS_REPORTS;

  return (
    <div id="statistics-sub-reports">
      <div className="row">
        <div className="col-md-12">
          <nav className="reports-sub-menu">
            <span className="by-text">By: </span>
            <ul>
              <li
                className={reportSubMenu === 'Daily' ? 'active' : ''}
                data-report={JSON.stringify({
                  ...DAILY,
                  date_start: statisticsDateStart,
                  date_end: statisticsDateEnd,
                })}
                data-reportmenu="Performance"
                data-reportsubmenu="Daily"
                onClick={handleReportChange}
              >
                <span>Day</span>
              </li>
              <li
                className={reportSubMenu === 'Country' ? 'active' : ''}
                data-report={JSON.stringify({
                  ...COUNTRY,
                  date_start: statisticsDateStart,
                  date_end: statisticsDateEnd,
                })}
                data-reportmenu="Performance"
                data-reportsubmenu="Country"
                onClick={handleReportChange}
              >
                <span>Country</span>
              </li>
              <li
                className={reportSubMenu === 'Offer' ? 'active' : ''}
                data-report={JSON.stringify({
                  ...OFFER,
                  date_start: statisticsDateStart,
                  date_end: statisticsDateEnd,
                })}
                data-reportmenu="Performance"
                data-reportsubmenu="Offer"
                onClick={handleReportChange}
              >
                <span>Offer</span>
              </li>
              <li
                className={reportSubMenu === 'Device' ? 'active' : ''}
                data-report={JSON.stringify({
                  ...DEVICE,
                  date_start: statisticsDateStart,
                  date_end: statisticsDateEnd,
                })}
                data-reportmenu="Performance"
                data-reportsubmenu="Device"
                onClick={handleReportChange}
              >
                <span>Device</span>
              </li>
              <li
                className={reportSubMenu === 'Vertical' ? 'active' : ''}
                data-report={JSON.stringify({
                  ...VERTICAL,
                  date_start: statisticsDateStart,
                  date_end: statisticsDateEnd,
                })}
                data-reportmenu="Performance"
                data-reportsubmenu="Vertical"
                onClick={handleReportChange}
              >
                <span>Vertical</span>
              </li>
            </ul>

            <a className="addColumnsModalLink primary-color" href="#" onClick={handleModalClick}>
              <i className="material-icons">add</i>
              <FormattedMessage {...messages.genericTextMoreDataOptions} />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

StatisticsSubReports.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  openAddColumnModal: PropTypes.func.isRequired,
  reportSubMenu: PropTypes.string,
  statisticsDateEnd: PropTypes.any,
  statisticsDateStart: PropTypes.any,
  statisticsIsFetching: PropTypes.any,
};

export default injectIntl(StatisticsSubReports);

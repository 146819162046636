import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import SurveyGenerationModalTabs from './survey-generation-modal/SurveyGenerationModalTabs.react';
import { Copy, Info, InputText } from '../../components/reusables';
import Tooltip from '../../components/reusables/ReactTooltip';
import { fetchCreatedCreative, postCreateCreative } from '../../actions/survey-generator-actions';
import { Gtm } from '../../utils/gtm';
import { closeModalAnimation } from '../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../components/reusables/colorpicker/useClickOutside.js';
import { delayAction } from '../../utils/helpers.js';
import { connect } from 'react-redux';

const { WidgetSection } = messages;

const SurveyGenerationModal = ({
  creativeCode,
  creativeId,
  creativeScript,
  dispatch,
  fields,
  intl,
  creativeIsFetching,
  title,
  values,
  modalInner,
  colorTemplate,
  generateIframeUrl,
}) => {
  const [locked, isLocked] = useState(true);
  const [isdisabled, setIsDisabled] = useState(true);
  const [appliedValues, setAppliedValues] = useState();

  useEffect(() => {
    if (title.length > 0 && !!colorTemplate) {
      dispatch(postCreateCreative('survey', generateIframeUrl('{affiliate_id}', false), title));
      setAppliedValues(values);
    }
  }, []);

  useEffect(() => {
    if (creativeId) {
      delayAction(() => {
        dispatch(fetchCreatedCreative(creativeId, {})).then(() => isLocked(false));
      }, 150);
    }
  }, [creativeId]);

  useEffect(() => {
    if (values) {
      if (_.isEqual(appliedValues, values)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [values]);

  const updateCreatives = () => {
    Gtm.event('UI Component', 'Click', 'Console-Survey-Generate-Widget-Apply-Changes');
    isLocked(true);
    dispatch(fetchCreatedCreative(creativeId, values)).then(() => {
      isLocked(false);
      setAppliedValues(values);
      setIsDisabled(true);
    });
  };

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  return (
    <div id="survey-generate-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div className="row">
        <div className="col-md-12">
          <small>Survey Name:</small>
          <h2>{title}</h2>
        </div>
      </div>
      <div className="row">
        <h2 className="modalH2 col-lg-12">
          <i className="material-icons link">link</i>
          <span>Link Customization</span>
          <Info
            title="Generate your tracking link based on your preferences!"
            url="https://support.crakrevenue.com/knowledge-base/generating-tracking-link-sub-idindex-html-161/"
          />
        </h2>
      </div>
      <div className="row">
        <InputText
          autofocus
          className="col-md-4"
          field={fields.aff_sub}
          id="surveyGenerationModal_aff_sub"
          label={messages.surveyGeneratorSubId1}
          tabIndex={20}
          type="text"
        />
        <InputText
          autofocus
          className="col-md-4"
          field={fields.aff_sub2}
          id="surveyGenerationModal_aff_sub2"
          label={messages.surveyGeneratorSubId2}
          tabIndex={30}
          type="text"
        />
        <InputText
          autofocus
          className="col-md-4"
          field={fields.aff_sub3}
          id="surveyGenerationModal_aff_sub3"
          label={messages.surveyGeneratorSubId3}
          tabIndex={40}
          type="text"
        />
      </div>
      <div className="row">
        <InputText
          autofocus
          className="col-md-4"
          field={fields.aff_sub4}
          id="surveyGenerationModal_aff_sub4"
          label={messages.surveyGeneratorSubId4}
          tabIndex={50}
          type="text"
        />
        <InputText
          autofocus
          className="col-md-4"
          field={fields.aff_sub5}
          id="surveyGenerationModal_aff_sub5"
          label={messages.surveyGeneratorSubId5}
          tabIndex={60}
          type="text"
        />
        <InputText
          autofocus
          className="col-md-4"
          field={fields.source}
          id="surveyGenerationModal_source"
          label={messages.surveyGeneratorSource}
          tabIndex={70}
          type="text"
        />
      </div>
      <button className="btn btn-generate left" disabled={isdisabled} onClick={updateCreatives}>
        {intl.formatMessage(messages.genericTextApplyChanges)}
      </button>
      <SurveyGenerationModalTabs
        creativeId={creativeId}
        creativeScript={creativeScript}
        disabled={locked}
        intl={intl}
        loading={creativeIsFetching}
        src={creativeCode}
      />
      <div className="footer-modal row">
        <div className="col-lg-4 col-lg-offset-8 text-right">
          <Tooltip
            position="left"
            tooltip={locked ? intl.formatMessage(WidgetSection.generateCode.warnings.applyChangesBefore) : undefined}
          >
            <Copy
              clipboardTarget="#codeInput"
              disabled={locked}
              dispatch={dispatch}
              onCopy={() => Gtm.event('UI Component', 'Click', 'Console-Survey-Widget-Code-Copy')}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

SurveyGenerationModal.propTypes = {
  creativeCode: PropTypes.any,
  creativeId: PropTypes.any,
  creativeScript: PropTypes.string,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  title: PropTypes.string,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  colorTemplate: state.surveyGenerator.colorTemplate,
  creativeCode: state.surveyGenerator.creativeCode,
  creativeId: state.surveyGenerator.creativeId,
  creativeIsFetching: state.surveyGenerator.creativeIsFetching,
  creativeScript: state.surveyGenerator.creativeScript,
}))(
  injectIntl(
    reduxForm({
      form: 'surveyGenerationModal',
      touchOnChange: false,
      touchOnBlur: false,
      initialValues: {
        aff_sub: '',
        aff_sub2: '',
        aff_sub3: '',
        aff_sub4: '',
        aff_sub5: '',
        source: '',
      },
      fields: ['aff_sub', 'aff_sub2', 'aff_sub3', 'aff_sub4', 'aff_sub5', 'source'],
    })(SurveyGenerationModal)
  )
);

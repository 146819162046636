import SmallPerformerPageModalIconReact from '../../../reusables/svg/offer/SmallPerformerPageModalIcon.react';

const PerformerPageSubHeader = () => (
  <div className="performer-page-sub-header">
    <div className="header">
      <SmallPerformerPageModalIconReact />
      <h2>Performer Page</h2>
    </div>
    <p>
      You would like to promote a specific Cam Performer? Enter their name and start promoting their profile. This targeted
      approach helps your create funnel that maximize the effectiveness of your marketing strategies, leading to higher conversion
      rates and increased engagement. Not sure who to promote? You can select one of the trending profile or explore{' '}
      <a href="https://www.jerkmate.com/cams" rel="noreferrer" target="_blank">
        Jerkmate.com/cams
      </a>{' '}
      and find the perfect performer to promote.
    </p>
  </div>
);
export default PerformerPageSubHeader;

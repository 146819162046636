import config from '../../config';
import moment from 'moment';
const { LIVECAM_WIDGET_PREVIEW_CHAPI_TOKEN } = config;

const getValue = (field, concat = '', valueToTestFor = '') =>
  !!field && field.value !== valueToTestFor ? `${field.value}${concat}` : '';

const getColorValue = (field) => encodeURI(getValue(field)).replace(/#/g, '%23');

const getFormattedTypeSettings = ({
  campaigns,
  categories,
  gender,
  offers,
  canHide,
  isPreviewUrl,
  language,
  expireDays,
  widgetType,
  spokenLanguages,
}) => {
  const isWidgetChatHead = widgetType.value.includes('im_');
  const canHideChatHead = canHide?.value?.toString() == true;
  const selectedCampaigns =
    campaigns[0].name !== 'Fetching data...' && !!offers && offers.length > 0
      ? offers
          .map((offer) => offer.value)
          .map((offerLabel) => campaigns.find((campaign) => campaign.name === offerLabel))
          .map((campaign) => ({
            id: campaign.id,
            payout: campaign.payout.name,
            provider: campaign.provider.name,
          }))
      : [];

  const providersMap = {
    mfc: 7,
    awempire: 21,
    streamate: 27,
    stripchat: 67,
    bongacash: 69,
    chaturbate: 169,
    imlive: 673,
  };

  const providers = selectedCampaigns
    .map((campaign) => (isPreviewUrl ? campaign.provider : providersMap[campaign.provider]))
    .join(',');

  const selectedGenders = typeof gender.value === 'string' ? [gender.value] : gender.value;
  const genderMap = {
    m: ['m', 'mm'],
    f: ['f', 'ff'],
    t: ['t', 'tm2f', 'tf2m', 's'],
    c: ['c', 'ff', 'mf', 'g'],
  };
  const genders = (
    selectedGenders.length ? [...new Set(selectedGenders.map((currentGender) => genderMap[currentGender]).flat())] : genderMap.f
  ).join(',');
  const data = {
    campaigns: encodeURI(JSON.stringify(selectedCampaigns)),
    genders,
    canHide: canHide.value.toString(),
    categories: categories.map((cat) => cat.value.toLowerCase()).join(','),
    categoriesMode: '',
    modelLang: spokenLanguages.map((lang) => lang.value.toLowerCase()).join(','),
  };

  if (isWidgetChatHead && canHideChatHead) {
    data.hoursHidden = isPreviewUrl ? '' : moment.duration(expireDays.value, 'minutes').asHours().toString();
    data.lang = language.value || 'en';
  }

  data[isPreviewUrl ? 'providers' : 'providersId'] = providers;

  return data;
};

const getFormattedTemplateSettings = ({ skin }) => ({
  skin: getValue(skin) || '0',
});

const getFormattedLayoutSettings = ({
  backgroundColor,
  backgroundType,
  columns,
  iframeHeight,
  iframeSizes,
  iframeWidth,
  layoutType,
  rows,
}) => {
  const isCustomizedSizes = getValue(iframeSizes) === 'custom';
  const isCustomizedLayout = getValue(layoutType) === 'custom';
  const isCustomizedBackground = getValue(backgroundType) === 'color';
  const nbCols = (isCustomizedLayout ? getValue(columns) : '') || '0';
  const nbRows = (isCustomizedLayout ? getValue(rows) : '') || '0';
  const number = parseInt(nbCols, 10) * parseInt(nbRows, 10);

  return {
    containerAlignment: 'center',
    iframeHeight: isCustomizedSizes ? getValue(iframeHeight, 'px', '') : '',
    iframeWidth: isCustomizedSizes ? getValue(iframeWidth, 'px', '') : '',
    cols: nbCols,
    rows: nbRows,
    number: number === 0 ? '50' : number,
    background: isCustomizedBackground ? getColorValue(backgroundColor) : 'transparent',
  };
};

const getFormattedThumbnailsSettings = ({
  borderColor,
  borderFlag,
  borderWidth,
  colorFilter,
  colorFilterFlag,
  colorFilterOpacity,
  cornerFlag,
  cornerRadius,
  feed,
  ratio,
  spacing,
}) => {
  const liveFeed = getValue(feed) === 'live' ? '1' : '0';
  const space = getValue(spacing, 'px', '');
  const isCustomizedBorder = getValue(borderFlag) === '1';
  const isCustomizedColor = getValue(colorFilterFlag) === '1';
  const isCustomizedCorner = getValue(cornerFlag) === '1';
  const borderSize = isCustomizedBorder ? getValue(borderWidth, 'px', '') : '';
  const colorStrength = (isCustomizedColor ? getValue(colorFilterOpacity) : '') || 0;

  return {
    useFeed: liveFeed,
    animateFeed: liveFeed,
    smoothAnimation: liveFeed,
    ratio: getValue(ratio),
    verticalSpace: space,
    horizontalSpace: space,
    colorFilter: (isCustomizedColor ? getColorValue(colorFilter) : '') || '0',
    colorFilterStrength: (colorStrength > 0 ? colorStrength / 100 : 0).toString(),
    cardsBorderTop: borderSize,
    cardsBorderRight: borderSize,
    cardsBorderBottom: borderSize,
    cardsBorderLeft: borderSize,
    cardsBorderColor: isCustomizedBorder ? getColorValue(borderColor) : '',
    cardsBorderRadius: isCustomizedCorner ? getValue(cornerRadius, 'px', '') : '',
  };
};

const getFormattedInfobarSettings = ({
  backgroundType,
  fontColor,
  fontFamily,
  fontSize,
  infobarBackground,
  infobarDisplay,
  infobarPositionIO,
  infobarPositionTB,
  infoFields,
}) => {
  if (infobarDisplay === 'default') {
    return {};
  }

  const fieldsNames = getValue(infoFields).split(',');
  const isFieldExist = (fieldName) => fieldsNames.some((currentFieldName) => currentFieldName === fieldName);
  const getInfosToDisplay = (keys) => keys.filter((key) => isFieldExist(key)).join(',') || '-';
  const infobarDisplayFlag = infobarDisplay === 'custom';
  const infoTextColor = infobarDisplayFlag ? getColorValue(fontColor) : '';
  const infoPosition = infobarDisplayFlag ? getValue(infobarPositionIO) : '';
  const infoVerticalAlign = (infobarDisplayFlag ? getValue(infobarPositionTB) : '') || '-';
  const infoLeftContent = infoVerticalAlign ? getInfosToDisplay(['name']) : '-';
  const infoRightContent = infoVerticalAlign ? getInfosToDisplay(['age', 'gender', 'room_topic']) : '-';
  const infoBackgroundColor =
    infobarDisplayFlag && getValue(backgroundType) === 'color' ? getColorValue(infobarBackground) : 'transparent';
  const isAlignTop = infoVerticalAlign === 'top';
  const isAlignBottom = infoVerticalAlign === 'bottom';

  return {
    infoHeight: '',
    showOnline: infobarDisplayFlag && isFieldExist('live') ? '1' : '0',
    fontFamily: infobarDisplayFlag ? getValue(fontFamily) : '',
    fontSize: infobarDisplayFlag ? getValue(fontSize, 'px', '') : '',
    infoTopTextColor: infoTextColor,
    infoTopPos: infoPosition,
    infoTopLeftContent: isAlignTop ? infoLeftContent : '-',
    infoTopRightContent: isAlignTop ? infoRightContent : '-',
    infoTopBackgroundColor: isAlignTop ? infoBackgroundColor : '',
    infoBottomTextColor: infoTextColor,
    infoBottomPos: infoPosition,
    infoBottomLeftContent: isAlignBottom ? infoLeftContent : '-',
    infoBottomRightContent: isAlignBottom ? infoRightContent : '-',
    infoBottomBackgroundColor: isAlignBottom ? infoBackgroundColor : '',
  };
};

const getFormattedCustomCss = ({ customcss, infobarDisplay }) => {
  const cssAppend = infobarDisplay === 'hide' ? '.cw-info-ctn{\n  display:  none;\n}' : '\n';

  return {
    MainCSS: '',
    AuxiliaryCSS: encodeURI(`${getValue(customcss)}${cssAppend}`).replace(/#/g, '%23'),
  };
};

export const livecamUrlBuilder = (
  { cssSettings, infobarSettings, layoutSettings, templateSettings, thumbnailsSettings, typeSettings },
  affId,
  baseUrl,
  campaigns,
  isPreviewUrl = false
) => {
  const infobarDisplay = getValue(infobarSettings.infobarDisplay);
  const lang = typeSettings?.language?.value || 'en';

  const params = {
    generator: 'camswidget',
    ...getFormattedTypeSettings({
      ...typeSettings,
      campaigns,
      isPreviewUrl,
    }),
    ...getFormattedTemplateSettings({ ...templateSettings }),
    ...getFormattedLayoutSettings(layoutSettings),
    ...getFormattedThumbnailsSettings(thumbnailsSettings),
    ...getFormattedInfobarSettings({ ...infobarSettings, infobarDisplay }),
    ...getFormattedCustomCss({ ...cssSettings, infobarDisplay }),
    lang,
  };

  const widgetTypeRoute = ['/', getValue(typeSettings.widgetType), isPreviewUrl ? '_demo' : '', '?'].join('');

  return [
    baseUrl.replace('{affiliate_id}', affId),
    baseUrl.indexOf('?') >= 0 ? '&' : '?',
    Object.keys(params)
      .filter((key) => params[key] !== '')
      .map((key) => `${key}=${params[key]}`)
      .join('&'),
    isPreviewUrl ? `&token=${LIVECAM_WIDGET_PREVIEW_CHAPI_TOKEN}` : '',
  ]
    .join('')
    .replace('/?', widgetTypeRoute);
};

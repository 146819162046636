import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../../utils/gtm';
import messages from '../../../i18n/base-en.js';

import { SelectMulti } from '../../../components/reusables';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';
import SelectCustom from '../../../components/reusables/form/SelectCustom';

const getBaseFiltersVisibility = (skin) => ({
  COUNTRY_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.country'),
  OFFER_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.offer'),
  OFFER_URL_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.offerUrl'),
  VERTICAL_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.vertical'),
});
const StatisticsBaseFilters = ({
  categories,
  countries,
  fields,
  intl,
  isDisabled,
  offersList,
  offersUrlList,
  skinConfigurations,
}) => {
  const BASE_FILTERS = getBaseFiltersVisibility(new SkinConfigurationsUtils(skinConfigurations));

  const sortOffers = () => {
    const sortable = [];

    Object.keys(offersList).forEach((key) => {
      sortable.push(offersList[key]);
    });

    sortable.sort((a, b) =>
      b.Offer.name ? a.Offer.name?.replace(/\s/g, '').localeCompare(b.Offer.name.replace(/\s/g, '')) : -1
    );
    return sortable;
  };

  const handleGtmEventVertical = (selectedIds) => {
    const eventLabel = categories?.filter(({ id }) => selectedIds?.includes(id));
    Gtm.event('statistics', 'Click', `vertical - ${eventLabel.map((category) => category.name.split('Vertical - ')[1])}`);
  };

  const handleGtmEventOffers = (selectedIds) => {
    if (selectedIds) {
      const eventLabel = selectedIds?.map((id) => offersList[id]);
      Gtm.event('statistics', 'Click', `offers - ${eventLabel.map((offer) => offer.Offer.name)}`);
    }
  };

  const handleGtmEventLP = (landingNames) => {
    Gtm.event('statistics', 'Click', `landing page - ${landingNames}`);
  };

  const handleGtmEventCountries = (countryName) => {
    Gtm.event('statistics', 'Click', `country - ${countryName}`);
  };

  return (
    <div className="baseFilters">
      {BASE_FILTERS.VERTICAL_IS_VISIBLE ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Vertical"
                dataFiltername="Category.id"
                disabled={isDisabled}
                dropdownClassName="stats-filters-dropdown"
                gtmEvent={handleGtmEventVertical(fields.vertical.value)}
                id="vertical"
                isMulti
                label={messages.genericTextVertical.description}
                maxSelected={categories.filter((category) => category.name.indexOf('Vertical') === 0).length}
                onChange={fields.vertical.onChange}
                options={categories
                  .filter((category) => category.name.indexOf('Vertical') === 0)
                  .map((category) => ({ value: category.id, text: category.name.split('Vertical - ')[1] }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={20}
                value={fields.vertical.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {BASE_FILTERS.OFFER_IS_VISIBLE ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Offer"
                dataFiltername="Offer.id"
                disabled={isDisabled || Object.keys(offersList).length === 0}
                dropdownClassName="stats-filters-dropdown"
                enableSearch
                gtmEvent={handleGtmEventOffers(fields.offer.value)}
                id="offer"
                isMulti
                label={messages.genericTextOffers.description}
                maxSelected={sortOffers().length}
                onChange={fields.offer.onChange}
                options={sortOffers().map((offer) => ({ value: offer.Offer.id, text: offer.Offer.name }))}
                showId
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={40}
                value={fields.offer.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {BASE_FILTERS.OFFER_IS_VISIBLE && BASE_FILTERS.OFFER_URL_IS_VISIBLE ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Landing page"
                dataFiltername="OfferUrl.name"
                disabled={isDisabled || offersUrlList.length === 0}
                dropdownClassName="stats-filters-dropdown"
                gtmEvent={handleGtmEventLP(fields.offerUrl.value)}
                id="offerUrl"
                isMulti
                label={messages.statisticsLandingPage.description}
                maxSelected={offersUrlList.length}
                onChange={fields.offerUrl.onChange}
                options={offersUrlList.map((offerUrl) => ({ value: offerUrl.id, text: offerUrl.name }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={50}
                value={fields.offerUrl.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {BASE_FILTERS.COUNTRY_IS_VISIBLE ? (
        <div className="filterInput">
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                dataCategory="Country"
                dataFiltername="Country.name"
                disabled={isDisabled}
                dropdownClassName="stats-filters-dropdown"
                enableSearch
                gtmEvent={handleGtmEventCountries(fields.country.value)}
                id="country"
                isMulti
                label={messages.genericTextCountry.description}
                maxSelected={countries.length}
                onChange={fields.country.onChange}
                options={countries.map((country) => ({ value: country.code, text: country.name }))}
                showMaxSelectedNumber={false}
                showSelectedAtTop={false}
                showSelectedFieldCheckmark={false}
                tabIndex={60}
                value={fields.country.value}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

StatisticsBaseFilters.propTypes = {
  categories: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  offersList: PropTypes.object.isRequired,
  offersUrlList: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default injectIntl(
  connect((state) => ({
    skinConfigurations: state.skinConfigurations.data,
  }))(StatisticsBaseFilters)
);

import React, { useEffect, useState } from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';

const ClearFilterCta = React.memo(({ className, onClick }) => {
  const { clearAllFiltersAndSearch, activeFilters } = useOffersFilterContext();
  const [totalFilters, setTotalFilters] = useState(0);
  const [isMobile, setIsMobile] = useState(() => screen.width <= 991);

  useEffect(() => {
    setTotalFilters(Object.values(activeFilters).reduce((acc, filter) => acc + filter?.length, 0));
  }, [activeFilters]);

  useEffect(() => {
    const resize = () => {
      setIsMobile(screen.width <= 991);
    };

    window.addEventListener('resize', resize);
    resize();

    () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div className={className} onClick={clearAllFiltersAndSearch}>
      Clear <br />
      All {!isMobile && totalFilters ? ` (${totalFilters})` : ''}
    </div>
  );
});

export default ClearFilterCta;

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';
import { v4 as uuidv4 } from 'uuid';

const PaymentHistoryWidget = ({ intl, paymentHistory, minimumPayout, className, paymentTerm, profileCommunicationStatus }) => {
  const noticeClasses = ['notice', className === 'light-color' ? 'config-color' : undefined].join(' ');

  const needBothInfos = () => (
    <div className="col-xs-12">
      <div className="payment-info">
        <h3>
          <FormattedMessage {...messages.genericTextWarning} />
        </h3>
        <div className={noticeClasses}>
          <FormattedMessage {...messages.notificationPaymentHistoryWidget} />
        </div>
      </div>
    </div>
  );
  const needPaymentOnly = () => (
    <div className="col-xs-12">
      <div className="payment-info">
        <h3>
          <FormattedMessage {...messages.genericTextWarning} />
        </h3>
        <div className={noticeClasses}>
          <FormattedMessage {...messages.notificationPaymentHistoryWidgetPaymentOnly} />
        </div>
      </div>
    </div>
  );
  const lastPayment = () => (
    <div className="payment-info balance">
      <div className="col-xs-12">
        <hr />
        <h3>
          <FormattedMessage {...messages.genericTextLastPayment} />
        </h3>
        <div className="amount">
          <FormattedNumber currency="USD" style="currency" value={parseFloat(paymentHistory.last.amount).toFixed(2)} />
        </div>
        <div className={noticeClasses}>{paymentHistory.last.date}</div>
      </div>
    </div>
  );
  const nextPayment = () => (
    <div className="col-xs-12">
      <div className="payment-info">
        <h3>
          <FormattedMessage {...messages.genericTextNextPayment} />
        </h3>
        {paymentHistory.asReachedMinimumPayout
          ? [
              <div className="amount" key={uuidv4()}>
                <FormattedNumber currency="USD" style="currency" value={parseFloat(paymentHistory.next.amount).toFixed(2)} />
              </div>,
              <div className={noticeClasses} key={uuidv4()}>
                {paymentHistory.next.date}
              </div>,
            ]
          : [
              <div className={noticeClasses} key={uuidv4()}>
                <FormattedMessage
                  {...messages.profileNotReachedMinPayout}
                  values={{
                    amount: parseFloat(!minimumPayout ? '100' : minimumPayout).toFixed(2),
                  }}
                />
              </div>,
              <div className={noticeClasses} key={uuidv4()}>
                {intl.formatMessage(messages.profileYourCurrentBalance)}
                <span className="unbreakable">
                  <FormattedNumber currency="USD" style="currency" value={parseFloat(paymentHistory.next.amount).toFixed(2)} />
                </span>
              </div>,
            ]}
      </div>
    </div>
  );

  const needCompleteIdentityVerification = () => (
    <div className="col-xs-12">
      <div className="payment-info">
        <h3>
          <FormattedMessage {...messages.genericTextNextPayment} />
        </h3>
        <div className={noticeClasses}>
          <FormattedMessage {...messages.notificationPaymentHistoryWidgetIdentityToComplete} />
        </div>
      </div>
    </div>
  );
  const rightContent = () => {
    switch (profileCommunicationStatus) {
      case 'both':
      case 'profile':
        return needBothInfos();
      case 'payment':
        return needPaymentOnly();
      case 'identity':
        if (paymentHistory.asReachedMinimumPayout) {
          return needCompleteIdentityVerification();
        }
      default:
        if (paymentTerm !== 'Net 7') {
          if (paymentHistory.asReceivedPayment) {
            return (
              <div className="last-next">
                <div className="row next-payment">{nextPayment()}</div>
                <div className="row last-payment">{lastPayment()}</div>
              </div>
            );
          }
          return nextPayment();
        } else if (paymentHistory.asReceivedPayment) {
          return <div className="row last-payment">{lastPayment()}</div>;
        }
    }
  };

  return <div className={`payment-history-widget ${className}`}>{rightContent()}</div>;
};

PaymentHistoryWidget.defaultProps = {
  paymentHistory: { next: { amount: 0 } },
};

PaymentHistoryWidget.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
  minimumPayout: PropTypes.any.isRequired,
  paymentHistory: PropTypes.object,
  paymentTerm: PropTypes.string.isRequired,
  profileCommunicationStatus: PropTypes.string,
};

export default injectIntl(PaymentHistoryWidget);

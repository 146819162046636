const Logout = () => (
  <svg fill="none" height="17" viewBox="0 0 18 17" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 12.4375V12.4375C16 14.5431 14.2931 16.25 12.1875 16.25H5.5C3.01472 16.25 1 14.2353 1 11.75V5.5C1 3.01472 3.01472 1 5.5 1H12.1875C14.2931 1 16 2.70691 16 4.8125V4.8125"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="1.25"
    />
    <path
      d="M9 8.375C8.65482 8.375 8.375 8.65482 8.375 9C8.375 9.34518 8.65482 9.625 9 9.625V8.375ZM9 9.625H17V8.375H9V9.625Z"
      fill="white"
    />
    <path d="M14 6L17.0052 9.0052L14 12.0104" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
  </svg>
);

export default Logout;

import { useEffect, useState } from 'react';

const RankingIcon = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setStep((step) => (step + 1) % 3), 200);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {step === 0 && (
        <svg fill="none" height="36" viewBox="0 0 24 36" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 27L12 21L6 27" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <g opacity="0.2">
            <path d="M18 15L12 9L6 15" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
          <g opacity="0.2">
            <path d="M18 21L12 15L6 21" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
        </svg>
      )}
      {step === 1 && (
        <svg fill="none" height="36" viewBox="0 0 24 36" width="24" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path d="M18 27L12 21L6 27" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
          <g opacity="0.2">
            <path d="M18 15L12 9L6 15" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
          <path d="M18 21L12 15L6 21" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
      )}
      {step === 2 && (
        <svg fill="none" height="36" viewBox="0 0 24 36" width="24" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path d="M18 27L12 21L6 27" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
          <path d="M18 15L12 9L6 15" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <g opacity="0.2">
            <path d="M18 21L12 15L6 21" stroke="#00BD00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          </g>
        </svg>
      )}
    </>
  );
};

export default RankingIcon;

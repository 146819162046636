import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Checkbox, Radio } from '../../../components/reusables';
import Tooltip from '../../../components/reusables/ReactTooltip';
import { connect } from 'react-redux';
import { Gtm } from '../../../utils/gtm.js';

const { LiveCamWidgetPage } = messages;

const InfobarSettingsDisplay = ({
  templateOptions,
  disabled,
  fields: { infobarDisplay, infoFields, infobarIsCustomizable },
  intl,
}) => {
  const shouldBeDisabled = templateOptions?.skin?.value === '0' ? false : disabled;
  infobarIsCustomizable.value = !shouldBeDisabled;

  return (
    <div className="settings-group">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.infobarSettings.displaySection.title.text} />
      </h2>

      <div className="radio-buttons horizontal" id="widget-infobar-display">
        <Radio
          field={infobarDisplay}
          id="widget-infobar-display-default"
          label={LiveCamWidgetPage.fields.genericChoices.default}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `infobar default`);
          }}
          value="default"
        />
        <Tooltip
          position="top"
          tooltip={infobarIsCustomizable.value ? '' : intl.formatMessage(LiveCamWidgetPage.warnings.incompatibleTemplate)}
        >
          <Radio
            disabled={shouldBeDisabled}
            field={infobarDisplay}
            id="widget-infobar-display-custom"
            label={LiveCamWidgetPage.fields.genericChoices.custom}
            onClick={() => {
              Gtm.event('live cam widget', 'Click', `infobar custom`);
            }}
            value="custom"
          />
        </Tooltip>
        <Radio
          field={infobarDisplay}
          id="widget-infobar-display-hide"
          label={LiveCamWidgetPage.fields.genericChoices.hide}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `infobar hide`);
          }}
          value="hide"
        />
      </div>

      <div>
        <FormattedMessage {...LiveCamWidgetPage.infobarSettings.displaySection.infos.label} />

        <div className="radio-buttons" id="infobar-fields">
          {['name', 'age', 'gender', 'live', 'room_topic'].map((fieldName) => (
            <Checkbox
              className="radio-field"
              disabled={disabled}
              field={infoFields}
              id={`widget-infobar-fields-${fieldName}`}
              key={fieldName}
              label={LiveCamWidgetPage.infobarSettings.displaySection.infos.choices[fieldName].defaultMessage}
              onClick={() => {
                Gtm.event('live cam widget', 'Click', `infobar checkbox - ${fieldName}`);
              }}
              value={fieldName}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

InfobarSettingsDisplay.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    infobarDisplay: PropTypes.object,
    infoFields: PropTypes.object,
    infobarIsCustomizable: PropTypes.object,
  }),
  intl: intlShape.isRequired,
};

export default connect((state) => ({
  templateOptions: state.form.templateOptionsLC || '',
}))(injectIntl(InfobarSettingsDisplay));

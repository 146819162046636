import React from 'react';
import ArrowMobile from './ArrowMobile';
import { useOffersFilterContext } from './OfferFilterProvider';

const DropdownTitle = React.memo(({ label, clearClick, className, field }) => {
  const { clearAllFilters } = useOffersFilterContext();

  return (
    <div className={`dropdownTitle ${className || ''}`}>
      <ArrowMobile field={field} backArrow={true} />
      <div className="text">{label}</div>
      <div className="clear" onClick={clearClick || clearAllFilters}>
        Clear All
      </div>
    </div>
  );
});

export default DropdownTitle;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, reset } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText } from '../reusables';

// TODO: remove jQuery from project
import $ from 'jquery';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);

    this._modal = null;
  }

  componentDidMount() {
    const { dispatch, id, onShow } = this.props;

    const selector = this.getClassOrIdButton();

    $(document).on(`click.${id}`, selector, (e) => {
      e.preventDefault();

      $(this._modal).openModal({
        // la fenêtre est fermée
        complete: () => {
          dispatch(reset('modalSearch'));
          $('.lean-overlay').remove();
          this.closeModal();
        },
        // la fenêtre est affichée
        ready: () => {
          if (onShow) {
            onShow(this);
          }
        },
      });
    });
  }

  componentWillUnmount() {
    const { id } = this.props;
    $(document).off(`click.${id}`);
  }

  getClassOrIdButton() {
    const { buttonId, buttonSelector, classButton } = this.props;

    if (buttonSelector) {
      return buttonSelector;
    } else if (classButton) {
      return `.${classButton}`;
    }
    return `#${buttonId}`;
  }

  closeModal() {
    const { onClose, cancelModalButton } = this.props;

    if (onClose) {
      onClose(this);
    }
    $(this._modal).closeModal();

    if (cancelModalButton && cancelModalButton.isModalOpen) {
      cancelModalButton.action();
    }
  }

  render() {
    const { button, children, className, fields, id, searchable, values, cancelModalButton } = this.props;

    return (
      <div className={`modal ${className || ''}`} id={id} ref={(div) => (this._modal = div)}>
        <div className="modal-content">
          <span className="modal-action modal-close hov-primary-color">
            <i className="material-icons">close</i>
          </span>
          {searchable ? (
            <div className="row search-container">
              <InputText
                className="col-md-6"
                field={fields.modalSearch}
                id="filter-search"
                placeholder="Search"
                prefixIcon="&#xE8B6;"
                type="text"
              />
            </div>
          ) : null}
          {React.cloneElement(children, searchable ? { search: values.modalSearch } : null)}
        </div>
        {button ? (
          <div className="modal-footer">
            {cancelModalButton ? (
              <button
                className="waves-effect waves-light btn-large btn-flat cancel-button primary-border-color"
                data-cy="landing-page-cancel"
                onClick={() => {
                  this.closeModal();
                  cancelModalButton.action();
                }}
              >
                <FormattedMessage {...messages.genericTextCancel} />
              </button>
            ) : null}
            <button
              className={`waves-effect waves-light btn ${button.className ? button.className : ''}`}
              data-cy="landing-page-save"
              disabled={button.disabled}
              onClick={() => {
                button.action();
                if (button.close) {
                  this.closeModal();
                }
              }}
            >
              {button.text}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

Modal.propTypes = {
  button: PropTypes.object,
  buttonId: PropTypes.string,
  buttonSelector: PropTypes.string,
  cancelButton: PropTypes.bool,
  children: PropTypes.object,
  classButton: PropTypes.string,
  className: PropTypes.string,
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  searchable: PropTypes.bool,
  values: PropTypes.object,
};

export default reduxForm({
  form: 'modalSearch',
  fields: ['modalSearch'],
})(Modal);

import request from '../../utils/Req';
import { getCookie } from '../../utils/helpers';

import consts from '../../utils/consts';
import config from '../../config';

const { EMAIL, PASSWORD } = consts.REGEXES;
const { API_URL } = config;

export default (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else {
    if (!values.email.match(EMAIL)) {
      errors.email = 'You must provide a valid email address';
    }
  }

  if (!values.password) {
    errors.password = 'Required';
  } else {
    if (values.length < 8) {
      errors.password = 'Your password must contains at least one uppercase character, one lowercase character and a number.';
    }
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else {
    if (!values.newPassword.match(PASSWORD)) {
      errors.newPassword = 'Your password must contains at least one uppercase character, one lowercase character and a number.';
    }
  }

  if (!values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = 'Required';
  } else {
    if (values.newPasswordConfirmation !== values.newPassword) {
      errors.newPasswordConfirmation = 'You must provide the same password as a confirmation';
    }
  }

  return errors;
};

export const asyncValidate = (values) =>
  new Promise((fulfill, reject) => {
    request
      .post(API_URL + '/user/checkPassword')
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        password: values.password,
      })
      .end((err, res) => {
        if (!err) {
          fulfill();
        } else {
          reject({ password: res.body.errorMessage });
        }
      });
  });

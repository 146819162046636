import OfferPostbacksAddVariablesModal from '../../../components/Offer/OfferPostbacks/OfferPostbacksAddVariablesModal/OfferPostbacksAddVariablesModal.react';
import StatisticsAddFiltersModal from '../../../pages/statistics/StatisticsAddFiltersModal.react';
import ProfileInfosModal from '../../../pages/profile/user-infos/ProfileInfosModal.react';
import JumioRedirectModal from '../../../pages/profile/user-infos/JumioRedirectModal.react';
import PromoToolModal from '../../PromoTools/modals/PromoToolModal.react';
import OfferLandingPagesModal from '../../../components/Offer/OfferLandingPages/OfferLandingPagesModal/OfferLandingPagesModal.react';
import StatisticsAddColumnModal from '../../../pages/statistics/statistics-modals/StatisticsAddColumnModal.react';
import VideoModal from '../../reusables/VideoModal/VideoModal';
import OfferPayoutTiersModal from '../../Offer/OfferPayoutTiersModal/OfferPayoutTiersModal.react';
import SurveyGenerationModal from '../../../pages/survey-generator/SurveyGenerationModal.react';
import OfferCountriesModal from '../../Offer/OfferCountriesModal/OfferCountriesModal.react';
import OfferRequestModal from '../../Offer/OfferModals/OfferRequestModal.react';
import OfferRequestSentModal from '../../Offer/OfferModals/OfferRequestSentModal.react';
import OfferShareModal from '../../Offer/OfferModals/OfferShareModal.react';
import BillingChangeErrorModal from '../../../pages/profile/billing-infos/billing-infos-modals/BillingChangeErrorModal.react';
import SurveyPayoutsModal from '../../../pages/survey-generator/survey-payouts-modal/SurveyPayoutsModal.react';
import { LiveCamWidgetModal } from '../../../pages/live-cam-widget';
import BillingChangeConfirmModal from '../../../pages/profile/billing-infos/billing-infos-modals/BillingChangeConfirmModal.react';
import BillingChangeModal from '../../../pages/profile/billing-infos/billing-infos-modals/BillingChangeModal.react';
import StatisticsSaveReportModal from '../../../pages/statistics/StatisticsSaveReportModal.react';
import OfferDescriptionModal from '../../Offer/OfferModals/OfferDescriptionModal.react';
import OfferMailingModal from '../../Offer/OfferMailingModal/OfferMailingModal.react';
import ContinuationStreakModal from '../../sections/Gamification/ContinuationStreakModal.react';
import WeekZeroModal from '../../sections/Gamification/WeekZeroModal.react';
import BrokenStreakModal from '../../sections/Gamification/BrokenStreakModal.react';

export const MODALS = {
  OfferPostbacksAddVariablesModal,
  StatisticsAddFiltersModal,
  ProfileInfosModal,
  JumioRedirectModal,
  PromoToolModal,
  OfferLandingPagesModal,
  StatisticsAddColumnModal,
  VideoModal,
  OfferPayoutTiersModal,
  SurveyGenerationModal,
  OfferCountriesModal,
  OfferRequestModal,
  OfferRequestSentModal,
  OfferShareModal,
  BillingChangeErrorModal,
  SurveyPayoutsModal,
  LiveCamWidgetModal,
  BillingChangeConfirmModal,
  BillingChangeModal,
  StatisticsSaveReportModal,
  OfferDescriptionModal,
  OfferMailingModal,
  ContinuationStreakModal,
  WeekZeroModal,
  BrokenStreakModal,
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Gtm } from '../../utils/gtm';
import intercom from '../../utils/intercom';
import messages from '../../i18n/base-en.js';

class DecemberChallenge extends Component {
  componentDidMount() {
    intercom.trackEvent('Challenge-2020');
    Gtm.event('Page View', 'View', 'Challenge-2020-Registration');
  }

  getFullName(userFirstName, userLastName) {
    return `${userFirstName} ${userLastName}`;
  }

  createListItems(items) {
    return Object.values(items).map((message) => (
      <li key={message.id}>
        <FormattedMessage {...message} />
      </li>
    ));
  }

  render() {
    const { userFirstName, userLastName } = this.props;

    return (
      <div className="app-container" id="december-challenge">
        <div className="content">
          <h1 className="header primary-color">
            <FormattedMessage {...messages.decemberChallengePage.title} />
          </h1>

          <div className="subscription">
            <h2 className="december-important">
              <FormattedMessage {...messages.decemberChallengePage.subscriptionConfirmation.title} />
            </h2>
            <p>
              <FormattedMessage
                {...messages.decemberChallengePage.subscriptionConfirmation.description.line1}
                values={{
                  fullName: this.getFullName(userFirstName, userLastName),
                }}
              />
              <br />
            </p>
          </div>

          <div className="challenge-description container-fluid">
            {['dc2020-1.jpg'].map((src) => (
              <img
                alt={messages.decemberChallengePage.title.defaultMessage}
                className="col-md-12 col-lg-6"
                key={src}
                src={`/img/${src}`}
              />
            ))}
          </div>

          <div className="terms-and-conditions">
            <h2>
              <FormattedMessage {...messages.decemberChallengePage.challengeLevels.title} />
            </h2>
            Here is a detailed breakdown of the number of levels, commissions increase and cash bonuses available this year.
            <ul>
              <li>Level 1 - $1,000 commissions increase = $100 cash bonus</li>
              <li>Level 2 - $2,000 commissions increase = $200 cash bonus</li>
              <li>Level 3 - $5,000 commissions increase = $500 cash bonus</li>
              <li>Level 4 - $10,000 commissions increase = $1,500 cash bonus</li>
              <li>Level 4 - $15,000 commissions increase = $2,250 cash bonus</li>
              <li>Level 6 - $25,000 commissions increase = $3,750 cash bonus</li>
              <li>Level 7 - $50,000 commissions increase = $7,500 cash bonus!</li>
            </ul>
          </div>

          <div className="terms-and-conditions">
            <h2>
              <FormattedMessage {...messages.decemberChallengePage.termsAndConditions.title} />
            </h2>
            <ol>
              {this.createListItems({
                ...messages.decemberChallengePage.termsAndConditions.points,
              })}
              <li>
                The payment of commissions is done at CrakRevenue&quot;s sole discretion, in compliance with{' '}
                <a href="http://www.crakrevenue.com/tos/" rel="noreferrer" target="_blank">
                  CrakRevenue&quot;s T.o.S.
                </a>{' '}
                and can be cancelled at any time, and without any prior notice.
              </li>
            </ol>
          </div>

          <div className="ready">
            <h2>
              <FormattedMessage {...messages.decemberChallengePage.ready.title} />
            </h2>
            <p>
              You are all set and ready to go, head over to our <a href="/offers#offers-list">Offers page</a> right now and make
              your way to top level asap!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

DecemberChallenge.propTypes = {
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
};

export default connect((state) => ({
  userFirstName: state.profile.data.affUserInfos.first_name,
  userLastName: state.profile.data.affUserInfos.last_name,
}))(DecemberChallenge);

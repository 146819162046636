import React from 'react';
import { useCreativesBanners } from './creatives/CreativesBanners';

const AddToolNoBanners = React.memo(() => {
  const { resetBannerFilters } = useCreativesBanners();

  return (
    <div className="fullWidth col-xl-12 adToolNoBanner">
      <h4 className="text-center bannerText col-xl-12">
        No <span className="addToolColor">Ad Tool</span> match your current selection for this offer
      </h4>
      <div className="bannerResetFilters">
        <div onClick={resetBannerFilters}>Reset Filters</div>
      </div>
      <div className="bannerWaves" id="bannerWaves">
        <img alt="bannerWave" className="bannerWave1" src="/img/bannerWave.svg" />
        <svg xmlns="http://www.w3.org/2000/svg" className="bannerWave1" viewBox="0 0 1057.494 222.213">
          <path
            id="Path-forest"
            d="M18.6,167.583c84.433,33.734,212.164,108.282,441.577-8.653,244.716-124.735,597.317,2.62,597.317,2.62V0H0V160.61"
            transform="translate(1057.494 222.213) rotate(180)"
            fill="#098709"
            fill-rule="evenodd"
          />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" className="bannerWave" viewBox="0 0 1057.494 222.213">
          <path
            id="Path-forest"
            d="M18.6,54.631C103.032,20.9,230.764-53.651,460.177,63.283c244.716,124.735,597.317-2.62,597.317-2.62v161.55H0V61.6"
            transform="translate(0 0)"
            fill="#00bd00"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
});

export default AddToolNoBanners;

import { useState } from 'react';
import PropTypes from 'prop-types';
import request from '../utils/Req';
import { getCookie } from '../utils/helpers';

import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import PasswordResetForm from './password-reset/PasswordResetForm.react';
import PasswordResetEmailForm from './password-reset/PasswordResetEmailForm.react';
import LoginErrors from '../components/Login/LoginErrors';

import { setActionMessage } from '../components/Application/actions/application-actions';
import { setLoginError } from '../components/Login/actions/login-actions';
import Offline from './Offline.react';

import messages from '../i18n/base-en.js';
import config from '../config';

const { API_URL } = config;

const PasswordReset = ({ dispatch, error, location }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handlePasswordResetEmailSubmit = (data) => {
    setIsDisabled(true);
    request
      .post(`${API_URL}/reset-password`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send({
        email: data.email,
      })
      .withCredentials()
      .end((err, res) => {
        if (res.body || err) {
          dispatch(setLoginError(messages.passwordResetConfirmation.defaultMessage));
        }
        setIsDisabled(false);
      });
  };

  const handlePasswordResetSubmit = (data) => {
    if (data.password === data.passwordConfirmation) {
      setIsDisabled(true);
      request
        .post(API_URL + '/change-password')
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .send({
          password: data.password,
          code: location.query.c,
        })
        .end((err, res) => {
          if (err) {
            dispatch(setLoginError('An error occured'));
          }

          if (res.body.success) {
            dispatch(
              setActionMessage(
                'success',
                {
                  text: 'Your password has been changed, you can log in with this new password.',
                },
                3000
              )
            );

            setTimeout(() => {
              browserHistory.push('/login');

              setIsDisabled(false);
            }, 4000);
          } else {
            dispatch(setLoginError(res.body.errorMessage));
            setIsDisabled(false);
          }
        });
    }
  };

  return (
    <Offline
      link={{
        url: '/login',
        text: <FormattedMessage {...messages.loginPageGoBackToLogin} />,
        isExternal: false,
      }}
    >
      <div className="row" id="password-reset-page">
        <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
          <div>
            <h1>
              <FormattedMessage {...messages.passwordResetTitle} />
            </h1>
          </div>
          <div className="login-link">
            <Link onlyActiveOnIndex={false} to="/login">
              <FormattedMessage {...messages.loginPageTitle} />
            </Link>
          </div>

          {typeof location.query.c !== 'undefined' && (
            <PasswordResetForm dispatch={dispatch} isDisabled={isDisabled} onSubmit={handlePasswordResetSubmit} />
          )}
          {typeof location.query.c === 'undefined' && (
            <PasswordResetEmailForm dispatch={dispatch} isDisabled={isDisabled} onSubmit={handlePasswordResetEmailSubmit} />
          )}
          <LoginErrors dispatch={dispatch} error={error} showErrorTime={6000} />
        </div>
      </div>
    </Offline>
  );
};

PasswordReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.any,
  location: PropTypes.object.isRequired,
};

export default connect((state) => ({
  error: state.login.error,
}))(PasswordReset);

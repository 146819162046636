import consts from '../../utils/consts';

const { EMAIL } = consts.REGEXES;

export default (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else {
    if (!values.email.match(EMAIL)) {
      errors.email = 'You must provide a valid email address';
    }
  }

  return errors;
};

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

import 'materialize-css';
import { Select } from 'react-materialize';
const { genericTextPx, LiveCamWidgetPage } = messages;

const ThumbnailsSettingsThumbnails = ({ fields: { feed, ratio, spacing }, iframeSizes, intl }) => (
  <div className="settings-group" id="thumbnails-section">
    <h2 className="primary-color">
      <FormattedMessage {...LiveCamWidgetPage.thumbnailsSettings.thumbnailsSection.title.text} />
    </h2>

    <div className="radio-buttons horizontal">
      <Radio
        field={feed}
        id="widget-feed-live"
        label={LiveCamWidgetPage.fields.feed.choices.liveFeed}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `thumbnails live feed`);
        }}
        value="live"
      />
      <Radio
        field={feed}
        id="widget-feed-picture"
        label={LiveCamWidgetPage.fields.feed.choices.profilePicture}
        onClick={() => {
          Gtm.event('live cam widget', 'Click', `thumbnails profile picture`);
        }}
        value="pic"
      />
    </div>

    <Select
      disabled={iframeSizes ? iframeSizes.value === 'custom' : null}
      id="widget-ratio"
      label={LiveCamWidgetPage.fields.ratio.label.defaultMessage}
      onChange={ratio.onChange}
      value={ratio.value}
    >
      <option value="1">{intl.formatMessage(LiveCamWidgetPage.fields.ratio.choices.square)}</option>
      <option value="0.75">{intl.formatMessage(LiveCamWidgetPage.fields.ratio.choices.portrait)}</option>
      <option value="1.3333">{intl.formatMessage(LiveCamWidgetPage.fields.ratio.choices.landscape)}</option>
      <option value="1.7777">{intl.formatMessage(LiveCamWidgetPage.fields.ratio.choices.panorama)}</option>
    </Select>

    <div>
      <div className="with-units">
        <InputText
          field={spacing}
          id="widget-spacing"
          label={LiveCamWidgetPage.fields.spacing.label}
          labelClasses="active"
          max={500}
          min={0}
          type="number"
        />

        <span className="unit-label">
          <FormattedMessage {...genericTextPx} />
        </span>
      </div>
    </div>
  </div>
);

ThumbnailsSettingsThumbnails.propTypes = {
  fields: PropTypes.shape({
    feed: PropTypes.object,
    ratio: PropTypes.object,
    spacing: PropTypes.object,
  }),
  iframeSizes: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(ThumbnailsSettingsThumbnails);

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Options } from '../../reusables';

class OfferCreativesCard extends Component {
  constructor(props) {
    super(props);

    this.resizeIframe = this.resizeIframe.bind(this);
  }

  componentDidMount() {
    const { creative } = this.props;

    if (!!this.iframe && this.iframe.contentDocument && this.iframe.contentDocument.body) {
      this.iframe.contentDocument.body.style.margin = 0;
    }

    if (creative.code) {
      this.resizeIframe();
      window.addEventListener('resize', this.resizeIframe);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeIframe);
  }

  resizeIframe() {
    const { creative, creativeMaxHeight } = this.props;

    const creativeRatio = parseInt(creative.width, 10) / parseInt(creative.height, 10);

    this.iframe.style.transform = undefined;
    this.iframe.style.transformOrigin = 'top left';

    if (this.iframeContainer.offsetWidth < creative.width) {
      this.iframeContainer.style.height =
        Math.floor(this.iframeContainer.offsetWidth / creativeRatio) < creativeMaxHeight
          ? `${Math.floor(this.iframeContainer.offsetWidth / creativeRatio)}px`
          : `${creativeMaxHeight}px`;
      this.iframe.style.transform = `scale(${this.iframeContainer.offsetWidth / creative.width})`;
    } else if (this.iframeContainer.offsetHeight < creative.height || creative.height > creativeMaxHeight) {
      this.iframeContainer.style.height = creative.height < creativeMaxHeight ? `${creative.height}px` : `${creativeMaxHeight}px`;
      this.iframe.style.transform = `scale(${this.iframeContainer.offsetHeight / creative.height})`;
    } else {
      this.iframeContainer.style.height = creative.height;
      this.iframe.style.transform = 'scale(1)';
    }

    if (creative.type === 'email creative') {
      this.iframe.style.maxWidth = '600px';
      this.iframe.style.width = '100%';
      this.iframe.style.height = `${(this.iframe.offsetWidth * 9) / 16}px`;
    }
  }

  render() {
    const {
      creative,
      creativeMaxHeight,
      isSelected,
      handleCreativeSelect,
      displayUrl,
      displayLink,
      generateHtmlIframe,
      offerThumbnail,
    } = this.props;

    const splitedCreative = creative.display.split('_');
    const rating = splitedCreative[2] ? `${splitedCreative[2]}+` : '';
    const creativeRatio = parseInt(creative.width, 10) / parseInt(creative.height, 10);
    let colClassName = 'col-lg-3 col-md-4 col-sm-4 col-xs-12';
    const imageStyle = {
      width: '100%',
      height: 'auto',
      maxWidth: `${creative.width}px`,
    };
    const iframeStyle = {
      width: `${creative.width}px`,
      height: `${creative.height}px`,
      margin: '0',
    };

    if (creativeRatio > 1.5) {
      colClassName = 'col-lg-6 col-md-6 col-sm-6 col-xs-12';
    } else if (creativeRatio < 0.666 && parseInt(creative.height, 10) > creativeMaxHeight) {
      colClassName = 'col-lg-3 col-md-4 col-sm-4 col-xs-12';
      imageStyle.height = `${creativeMaxHeight}px`;
      imageStyle.width = `${creativeMaxHeight * creativeRatio}px`;
    }

    if (creative.type === 'email creative') {
      colClassName = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
    }

    let menu;

    if (creative.type === 'file') {
      menu = [
        {
          label: <FormattedMessage {...messages.offerDownloadFile} />,
          link: {
            href: creative.url,
            download: true,
          },
        },
      ];
    } else {
      menu = [
        {
          action: () => displayLink(creative),
          label: <FormattedMessage {...messages.offerCopyCode} />,
        },
      ];

      if (creative.url) {
        menu.push(
          ...[
            {
              action: () => displayUrl(creative),
              label: <FormattedMessage {...messages.offerCopyUrl} />,
            },
            {
              label: <FormattedMessage {...messages.offerDownloadFile} />,
              link: {
                download: true,
                href: creative.url,
              },
            },
          ]
        );
      }
    }

    return (
      <div className={colClassName} id={`creative-card-${creative.id}`}>
        <div className="card creative-card">
          <div className={`card-image ${creative.type}`} onClick={() => handleCreativeSelect(creative, isSelected(creative.id))}>
            {isSelected(creative.id) || creative.code ? (
              <div className={['creative-card-overlay', isSelected(creative.id) ? 'selected' : undefined].join(' ')}>
                <i className="material-icons">done</i>
              </div>
            ) : null}
            <i className="material-icons code">code</i>
            <div className="text-center" ref={(el) => (this.iframeContainer = el)}>
              {creative.url ? (
                creative.type === 'file' ? (
                  <div className="content-file">
                    {offerThumbnail ? (
                      <div>
                        <img alt={creative.display} src={offerThumbnail} />
                        <i className="material-icons download">&#xE2C4;</i>
                      </div>
                    ) : (
                      <div className="no-preview-image">
                        <div className="text">Preview</div>
                        <i className="material-icons download">&#xE2C4;</i>
                      </div>
                    )}
                  </div>
                ) : (
                  <img alt={creative.display} src={creative.url} style={imageStyle} />
                )
              ) : null}
              {!!creative.code && (
                // eslint-disable-next-line
                <iframe
                  ref={(el) => (this.iframe = el)}
                  scrolling="no"
                  src={generateHtmlIframe(creative.code)}
                  style={iframeStyle}
                />
              )}
            </div>
          </div>
          <div className="card-content clearfix">
            <div className="card-inner-content">
              <div className="card-title-container clearfix">
                <span className="card-title">{creative.display}</span>
              </div>
              <div className="card-infos-container">
                {!!creative.type && <span className="type">{creative.type}</span>}
                {creative.width !== null && creative.height !== null && (
                  <span className="size">{`${creative.width}x${creative.height}`}</span>
                )}
                {!!rating && <span className="rating">{rating}</span>}
              </div>
            </div>
            <div className="card-options">
              <Options className="pull-right" menu={menu} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OfferCreativesCard.propTypes = {
  creative: PropTypes.object.isRequired,
  creativeMaxHeight: PropTypes.number.isRequired,
  displayLink: PropTypes.func.isRequired,
  displayUrl: PropTypes.func.isRequired,
  generateHtmlIframe: PropTypes.func.isRequired,
  handleCreativeSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  offerThumbnail: PropTypes.string,
};

export default OfferCreativesCard;

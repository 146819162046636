import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import messages from '../../i18n/base-en.js';

import NotificationsCards from '../../pages/profile/notifications/NotificationsCards.react';
import { Container, LoadingPage, Pagination, Table } from '../../components/reusables';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { fetchAlerts, changeAlertsListPage, changeAlertsRowsPerPage } from '../../components/Application/actions/alerts-actions';
import { filterVastUrlInNotifactions } from '../vast-pre-roll/values.js';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsChange = this.handleRowsChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { skinConfigurations } = this.props;
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'ProfileNotifications');
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
    });

    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, alertsSettings } = this.props;

    if (!_.isEqual(alertsSettings, nextProps.alertsSettings)) {
      dispatch(fetchAlerts(nextProps.alertsSettings));
    }
  }

  fetchData() {
    const { alertsSettings, alertsIsFetching, dispatch } = this.props;
    new Promise((fulfill) => {
      const promises = [];

      if (!alertsIsFetching) {
        const fetchAlertsPromise = dispatch(fetchAlerts({ ...alertsSettings }));
        promises.push(fetchAlertsPromise);
      }

      Promise.all(promises)
        .then(() => {
          fulfill();
        })
        .catch(() => {
          fulfill();
        });
    });
  }

  handlePageChange(page) {
    const { dispatch } = this.props;

    dispatch(changeAlertsListPage(page));
  }

  handleRowsChange(value, page) {
    const { dispatch } = this.props;

    dispatch(changeAlertsRowsPerPage(value, page));
  }

  render() {
    let { alerts, alertsInfos, alertsSettings, notificationsViewMode, skinConfigurationsIsFetching, alertsIsFetching } =
      this.props;

    alerts = filterVastUrlInNotifactions(alerts);

    const { isLoading } = this.state;
    const regexOfferLink = /"(.*?)"/;
    const table = {
      head: [
        {
          component: <FormattedMessage {...messages.notificationsSubject} />,
        },
        {
          component: <FormattedMessage {...messages.notificationsMessage} />,
        },
        {
          component: <FormattedMessage {...messages.notificationsSentAt} />,
        },
      ],
      body: alerts.map((alert) => [
        <div key={alert.title}>{alert.title}</div>,
        <div key={alert.title}>
          {alert.description.match(regexOfferLink) ? (
            <Link to={alert.description.match(regexOfferLink) ? alert.description.match(regexOfferLink)[1] : ''}>
              <div
                dangerouslySetInnerHTML={{
                  __html: alert.description,
                }}
              />
            </Link>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: alert.description,
              }}
            />
          )}
        </div>,

        <div key={alert.title}>{alert.datetime}</div>,
      ]),
    };

    const dataLoading = skinConfigurationsIsFetching || isLoading || alertsIsFetching;
    return (
      <div className="container-fluid app-container" id="profile-notifications">
        {dataLoading ? <LoadingPage /> : null}
        <Container className="z-depth-2 black-header" ignorePadding>
          {notificationsViewMode === 'table' && <Table animated table={table} />}
          {notificationsViewMode === 'cards' && <NotificationsCards alerts={alerts} />}
          <Pagination
            choices={[25, 50, 100, 200]}
            currentPage={alertsInfos.currentPage}
            handlePageChange={this.handlePageChange}
            handleRowsChange={this.handleRowsChange}
            pageCount={alertsInfos.pageCount}
            rowsPerPage={alertsSettings.limit}
            selectId="rowsPerPageBottom"
            totalResults={alertsInfos.total}
          />
        </Container>
      </div>
    );
  }
}

Notifications.propTypes = {
  alerts: PropTypes.array.isRequired,
  alertsInfos: PropTypes.object.isRequired,
  alertsIsFetching: PropTypes.bool,
  alertsSettings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  notificationsViewMode: PropTypes.string.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  skinConfigurationsIsFetching: PropTypes.bool,
};

export default connect((state) => ({
  alerts: state.alerts.alerts,
  alertsInfos: state.alerts.alertsInfos,
  alertsSettings: state.alerts.alertsSettings,
  alertsIsFetching: state.alerts.alertsIsFetching,
  notificationsViewMode: state.application.ui.notificationsViewMode,
  skinConfigurations: state.skinConfigurations.data,
  skinConfigurationsIsFetching: state.skinConfigurations.isFetching,
}))(Notifications);

const MixedSmartlinkIcon = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_148_975)">
      <path
        d="M24.9985 49.0942C38.3062 49.0942 49.0942 38.3062 49.0942 24.9985C49.0942 11.6908 38.3062 0.902802 24.9985 0.902802C11.6908 0.902802 0.902802 11.6908 0.902802 24.9985C0.902802 38.3062 11.6908 49.0942 24.9985 49.0942Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M30.6801 23.3705L33.6293 20.4213L30.6801 17.4752"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M30.6801 33.4306L33.6293 30.4815L30.6801 27.5323"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.3708 30.4815H21.3482L27.8243 20.4213H32.6873"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.3708 20.4213H21.2338L27.7129 30.4815H32.6873"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M37.0057 13.548H12.9973C11.9139 13.548 11.0382 14.4267 11.0382 15.5071V34.2853C11.0382 35.3656 11.9169 36.2414 12.9973 36.2414H18.6278L19.8556 41.5197L25.6545 36.2414H37.0117C38.0921 36.2414 38.9678 35.3626 38.9678 34.2853V15.5071C38.9678 14.4237 38.0891 13.548 37.0117 13.548H37.0057Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_148_975">
        <rect fill="white" height="50" width="50" />
      </clipPath>
    </defs>
  </svg>
);

export default MixedSmartlinkIcon;

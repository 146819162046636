import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { Gtm } from '../../utils/gtm';
const Card = ({ promotool, className, promotoolsRef, index }) => {
  const { setPromotool } = useJerkmateOffersContext();

  const title = promotool.title;

  return (
    <div
      className={`${className} jerkmate-card`}
      onClick={(e) => {
        setPromotool(promotool);
      }}
      ref={(div) => (promotoolsRef.current[index] = div)}
    >
      <div className="jerkmate-card-inner">
        <span>
          <img alt={title} src={promotool.icon_url_hover} />
        </span>
        <h3>{title}</h3>
        <p>{promotool.description}</p>
        <button
          type="button"
          onClick={() => {
            Gtm.event(`jerkmate page`, 'Click', `select_tool - ${title}`);
          }}
        >
          Choose this tool
        </button>
      </div>
    </div>
  );
};

export default Card;

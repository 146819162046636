const ArrowBtnIcon = () => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.7643 10.951C6.82382 11.0065 6.87156 11.0733 6.90467 11.1476C6.93778 11.222 6.95558 11.3022 6.95702 11.3835C6.95845 11.4649 6.94349 11.5457 6.91302 11.6211C6.88255 11.6965 6.8372 11.765 6.77968 11.8226C6.72215 11.8801 6.65363 11.9254 6.5782 11.9559C6.50276 11.9864 6.42197 12.0013 6.34062 11.9999C6.25928 11.9985 6.17906 11.9807 6.10475 11.9476C6.03044 11.9144 5.96356 11.8667 5.9081 11.8072L2.67717 8.57625C2.56372 8.46267 2.5 8.30869 2.5 8.14816C2.5 7.98762 2.56372 7.83365 2.67717 7.72006L5.9081 4.48912C5.96356 4.4296 6.03044 4.38187 6.10475 4.34876C6.17906 4.31564 6.25928 4.29784 6.34062 4.29641C6.42197 4.29497 6.50276 4.30993 6.57819 4.3404C6.65363 4.37087 6.72215 4.41622 6.77968 4.47375C6.8372 4.53127 6.88255 4.5998 6.91302 4.67523C6.94349 4.75066 6.95845 4.83146 6.95702 4.9128C6.95558 4.99414 6.93778 5.07436 6.90467 5.14867C6.87156 5.22298 6.82382 5.28986 6.7643 5.34532L4.56726 7.54236L12.3942 7.54236C12.5549 7.54236 12.709 7.60618 12.8226 7.71979C12.9362 7.8334 13 7.98749 13 8.14816C13 8.30882 12.9362 8.46291 12.8226 8.57652C12.709 8.69013 12.5549 8.75396 12.3942 8.75396L4.56726 8.75396L6.7643 10.951Z"
      fill="#00BD00"
    />
  </svg>
);

export default ArrowBtnIcon;

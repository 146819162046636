import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

import { detectMobileAndTablet, sortStatsColumns, transformTableValue } from '../../../utils/helpers';

import consts from '../../../utils/consts';
import { useEffect, useState } from 'react';
const { COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT } = consts;

const StatisticsTableTotals = ({ statisticsColumns, statisticsSummary }) => {
  const [newColumnsTotal, setNewColumnsTotal] = useState([...statisticsColumns]);
  useEffect(() => {
    if (!detectMobileAndTablet()) {
      setNewColumnsTotal((prevState) => [...statisticsColumns, 'Stat.paidConversions']);
    }
  }, [statisticsSummary]);

  return Object.keys(statisticsSummary).length > 0 ? (
    <tr className="totals-row">
      <td className="totals-title">
        <div>Totals</div>
      </td>
      {sortStatsColumns(newColumnsTotal).map((column, index) => {
        const arrayKeys = column.split('.');

        if (
          !!statisticsSummary.Stat &&
          !!statisticsSummary[arrayKeys[0]] &&
          Object.prototype.hasOwnProperty.call(statisticsSummary[arrayKeys[0]], arrayKeys[1])
        ) {
          const isCurrency = ['Stat.payout', 'Stat.cpa', 'Stat.erpc', 'Stat.cpm'].indexOf(column) !== -1;
          const isPercent = ['Stat.ctr', 'Stat.ltr'].indexOf(column) !== -1;
          const digits = ['Stat.cpa', 'Stat.erpc', 'Stat.cpm', 'Stat.ctr', 'Stat.ltr'].indexOf(column) !== -1 ? 3 : 2;
          const noFormat = ['Stat.date', 'Stat.year'].indexOf(column) === -1;

          let columnValue = statisticsSummary[arrayKeys[0]][arrayKeys[1]];
          if ((isCurrency || isPercent) && (columnValue === null || (columnValue && columnValue.length === 0))) {
            columnValue = 0;
          }
          columnValue = transformTableValue(column, columnValue);

          if (!isNaN(columnValue) && !isCurrency && !isPercent && noFormat && columnValue !== null && columnValue.length !== 0) {
            columnValue = <FormattedNumber value={columnValue} />;
          } else if (isCurrency && typeof columnValue !== 'undefined' && columnValue !== null && columnValue.length !== 0) {
            columnValue = (
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={digits}
                minimumFractionDigits={digits}
                style="currency"
                value={columnValue}
              />
            );
          } else if (isPercent && typeof columnValue !== 'undefined' && columnValue !== null && columnValue.length !== 0) {
            columnValue = (
              <FormattedNumber
                maximumFractionDigits={digits}
                minimumFractionDigits={digits}
                style="percent"
                value={columnValue / 100}
              />
            );
          }

          return (
            <td
              className={COLUMNS_FIELDS_NUMBER_ALIGN_RIGHT.indexOf(column) >= 0 ? 'text-right' : undefined}
              data-field={column}
              key={`stat-tdtotals-${index}`}
            >
              <div>{columnValue}</div>
            </td>
          );
        }
        return <td key={`stat-tdtotals-${index}`} />;
      })}
    </tr>
  ) : null;
};

StatisticsTableTotals.propTypes = {
  statisticsColumns: PropTypes.array.isRequired,
  statisticsSummary: PropTypes.object.isRequired,
};

export default StatisticsTableTotals;

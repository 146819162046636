function RefreshButton() {
  return (
    <svg viewBox="0 0 22.81 22.83" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Calque 2" id="Calque_2">
        <g data-name="Calque 1" id="Calque_1-2">
          <path
            className="cls-1"
            d="M19.46,3.35a11.42,11.42,0,1,0,3,10.92h-3A8.56,8.56,0,1,1,11.4,2.85a8.45,8.45,0,0,1,6,2.54L12.83,10h10V0Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default RefreshButton;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';
import { CustomColorPicker } from '../../../components/reusables/colorpicker/CustomColorPicker.react';

const { genericTextPx, LiveCamWidgetPage } = messages;

const ThumbnailsSettingsBorder = ({ fields: { borderColor, borderFlag, borderWidth } }) => {
  const disabled = borderFlag.value === '0';
  const [color, setColor] = useState('#aabbcc');
  const concurrentCalls = (e) => {
    borderColor.onChange(e);
    setColor(e);
  };
  return (
    <div className="settings-group" id="border-section">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.thumbnailsSettings.borderSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={borderFlag}
          id="widget-border-flag-on"
          label={LiveCamWidgetPage.fields.genericChoices.on}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `border on`);
          }}
          value="1"
        />
        <Radio
          field={borderFlag}
          id="widget-border-flag-off"
          label={LiveCamWidgetPage.fields.genericChoices.off}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `border off`);
          }}
          value="0"
        />
      </div>

      <CustomColorPicker
        color={color}
        disabled={disabled}
        field={borderColor}
        handleChange={setColor}
        input
        label={LiveCamWidgetPage.fields.color.label}
        labelClasses="active"
        onChange={(e) => concurrentCalls(e)}
      />

      <div>
        <div className={`with-units ${disabled ? 'disabled' : ''}`}>
          <InputText
            disabled={disabled}
            field={borderWidth}
            id="widget-border-width"
            label={LiveCamWidgetPage.fields.borderWidth.label}
            labelClasses="active"
            max={9999}
            min={0}
            type="number"
          />

          <span className="unit-label">
            <FormattedMessage {...genericTextPx} />
          </span>
        </div>
      </div>
    </div>
  );
};

ThumbnailsSettingsBorder.propTypes = {
  fields: PropTypes.shape({
    borderColor: PropTypes.object,
    borderFlag: PropTypes.object,
    borderWidth: PropTypes.object,
  }),
};

export default injectIntl(ThumbnailsSettingsBorder);

import { LanguagesList } from '../LiveCamWidgetConsts';
import { InputText } from '../../../components/reusables';
import { FormattedMessage } from 'react-intl';
import messages from '../../../i18n/base-en';
import SelectCustom from '../../../components/reusables/form/SelectCustom';
const { LiveCamWidgetPage } = messages;

const TypeSettings = ({ language, expireDays, isWidgetChatHead, canHideChatHead }) => (
  <div className="settings-group" id="settings-section">
    <h2 className="primary-color">Settings</h2>
    {true ? (
      <div className="customSelects">
        <div className="select-field language fields">
          <SelectCustom
            small
            label={LiveCamWidgetPage.typeSettings.settingsSection.chatTextLanguage.text.defaultMessage}
            id="widget-categories"
            value={language.value}
            onChange={language.onChange}
            valueDisabled="All"
            options={LanguagesList.map((language) => ({ value: language.value, text: language.text }))}
            touched={language.touched}
            showSelectedFieldCheckmark={false}
          />
        </div>
      </div>
    ) : null}
    {!isWidgetChatHead || canHideChatHead ? (
      <div className="delay">
        <InputText
          field={expireDays}
          id="delay"
          label={LiveCamWidgetPage.typeSettings.settingsSection.expireDays.label}
          labelClasses="active"
          type="number"
        />

        <span className="unit-label">
          <FormattedMessage {...LiveCamWidgetPage.typeSettings.settingsSection.minutes.text} />
        </span>
      </div>
    ) : null}
  </div>
);

export default TypeSettings;

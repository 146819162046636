import React from 'react';
import BannerFilters from './BannerFilters';
import { useCreativesBanners } from '../CreativesBanners';
import {
  BANNER_TAB_TRENDING_BANNERS,
  BANNER_TAB_SELECTED_CREATIVES,
  BANNER_TAB_ALL_CREATIVES,
  isAllCreativesTab,
  isTrendingCreativesTab,
} from './values';
import BannerList from './BannerList';
import BannerTitle from './BannerTitle';

const BannerListing = React.memo(() => {
  const { activeTab, creatives, selectedCreatives, creativesTrending, trendingBanners } = useCreativesBanners();

  const banners = {
    [BANNER_TAB_ALL_CREATIVES]: creatives,
    [BANNER_TAB_SELECTED_CREATIVES]: selectedCreatives,
    [BANNER_TAB_TRENDING_BANNERS]: trendingBanners,
  };

  const bannerList = banners[activeTab];
  const bannerCount = activeTab === BANNER_TAB_SELECTED_CREATIVES ? bannerList.length : 0;

  return (
    <div className="innerBannerContent">
      {isAllCreativesTab(activeTab) || isTrendingCreativesTab(activeTab) ? <BannerFilters /> : <></>}
      <BannerTitle bannerCount={bannerCount} />
      <BannerList bannerList={bannerList} />
    </div>
  );
});

export default BannerListing;

import { FormattedNumber } from 'react-intl';
import InfoIcon from './InfoIcon';
import moment from 'moment';
import { getCurrentPeriod } from '../../utils/helpers';
import React from 'react';

const PaymentDueInfo = ({ paymentDue, paymentTab, nextDatePayment, daysLeftToPeriod, minimumPayoutAmount, paymentTerm }) => {
  const dueDate = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd).format('MMMM Do YYYY');
  return (
    paymentTab === 'current' &&
    (paymentDue >= minimumPayoutAmount && minimumPayoutAmount > 0 ? (
      <div className="next-payment-box">
        <span className="next-payment-date">Total payment due on {nextDatePayment}</span>
        <span className="next-payment-amount">
          <FormattedNumber currency="USD" style="currency" value={paymentDue} />
        </span>
        <span className="next-payment-warning-box">
          <InfoIcon /> This amount may vary until the end of the period in {daysLeftToPeriod} days
        </span>
      </div>
    ) : paymentDue < minimumPayoutAmount && paymentDue > 0 ? (
      <div className="next-payment-box">
        <span className="next-payment-date">
          Earn at least ${(minimumPayoutAmount - paymentDue).toFixed(2)} by {dueDate}
          <br /> to get paid on {nextDatePayment}, or we’ll carry over your earnings to the next period.
        </span>
        <span className="next-payment-warning-box">
          <InfoIcon /> Current period ends in {daysLeftToPeriod} days
        </span>
      </div>
    ) : (
      <div className="next-payment-box">
        <span className="next-payment-date">You have not generated any revenue yet.</span>
        <span className="next-payment-warning-box">
          <InfoIcon /> Current period ends in {daysLeftToPeriod} days
        </span>
        <a
          className="how-to-link"
          href="https://www.crakrevenue.com/seven-figure-affiliate-cookbook/"
          rel="noreferrer"
          target="_blank"
        >
          How to make money with CrakRevenue?
        </a>
      </div>
    ))
  );
};

export default PaymentDueInfo;

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Options, Table } from '../../reusables';

const OfferCreativesTable = ({
  creatives,
  displayLink,
  displayUrl,
  handleSelectCreative,
  hidePreview,
  isSelected,
  showPreview,
}) => {
  const table = {
    head: [
      '',
      { component: <FormattedMessage {...messages.genericTextName} /> },
      {
        component: <FormattedMessage {...messages.genericTextPreview} />,
      },
      { component: <FormattedMessage {...messages.genericTextType} /> },
      { component: <FormattedMessage {...messages.genericTextSize} /> },
      {
        component: <FormattedMessage {...messages.genericTextLanguage} />,
      },
      { component: <FormattedMessage {...messages.genericTextRating} /> },
    ],
    body: creatives.map((creative) => {
      const [, rating, , language] = creative.display.split('_');
      const menu = [
        {
          action: () => displayLink(creative),
          label: <FormattedMessage {...messages.offerCopyCode} />,
        },
      ];
      if (creative.url) {
        menu.push(
          ...[
            {
              action: () => displayUrl(creative),
              label: <FormattedMessage {...messages.offerCopyUrl} />,
            },
            {
              label: <FormattedMessage {...messages.offerDownloadFile} />,
              link: {
                download: true,
                href: creative.url,
              },
            },
          ]
        );
      }

      return [
        <div className="checkbox-field" key={creative.id}>
          <input
            checked={isSelected(creative.id)}
            className="filled-in"
            id={`creative-checkbox-${creative.id}`}
            onChange={() => handleSelectCreative(creative, isSelected(creative.id))}
            type="checkbox"
          />
          <label htmlFor={`creative-checkbox-${creative.id}`}>&nbsp;</label>
        </div>,
        <div key={creative.id}>{creative.display}</div>,
        <span key={creative.id}>
          <span
            className="show-preview standard-bg-colors"
            onMouseEnter={(e) => showPreview(creative, e)}
            onMouseLeave={hidePreview}
          >
            <i className="material-icons">search</i>
          </span>
        </span>,
        <div key={creative.id}>{creative.type}</div>,
        <div key={creative.id}>
          {creative.width !== null && creative.height !== null ? `${creative.width}x${creative.height}` : 'none'}
        </div>,
        <div key={creative.id}>{language}</div>,
        <div key={creative.id}>{rating}</div>,
        <div key={creative.id}>
          <Options className="pull-right" menu={menu} />
        </div>,
      ];
    }),
  };

  return (
    <div id="offer-creatives-table">
      <div className="row">
        <Table animated table={table} />
      </div>
    </div>
  );
};

OfferCreativesTable.propTypes = {
  creatives: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  displayLink: PropTypes.func.isRequired,
  displayUrl: PropTypes.func.isRequired,
  handleSelectCreative: PropTypes.func.isRequired,
  hidePreview: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  showPreview: PropTypes.func.isRequired,
};

export default OfferCreativesTable;

export default {
  signupQuestionsModalText: {
    id: 'signupQuestionsModal.text',
    description:
      'We are upgrading our affiliate database in order to provide you with the most relevant offers & services. Please take a moment to answer the following questions (it only takes 2 minutes)!',
    defaultMessage:
      'We are upgrading our affiliate database in order to provide you with the most relevant offers & services. Please take a moment to answer the following questions (it only takes 2 minutes)!',
  },
  signupQuestionsModalCompletedTitle: {
    id: 'signupQuestionsModal.completedTitle',
    description: 'Thank you for updating your profile!',
    defaultMessage: 'Thank you for updating your profile!',
  },
  signupQuestionsModalCompletedText: {
    id: 'signupQuestionsModal.completedText',
    description: 'Have any additional info that you feel we should know about?  Let’s chat!',
    defaultMessage: 'Have any additional info that you feel we should know about?  Let’s chat!',
  },
};

import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import {
  ThumbnailsSettingsBorder,
  ThumbnailsSettingsColorFilter,
  ThumbnailsSettingsCorner,
  ThumbnailsSettingsThumbnails,
} from './thumbnails-settings';

const LiveCamWidgetThumbnailsSettings = ({
  fields: {
    borderColor,
    borderFlag,
    borderWidth,
    colorFilter,
    colorFilterFlag,
    colorFilterOpacity,
    cornerFlag,
    cornerRadius,
    feed,
    ratio,
    spacing,
  },
  iframeSizes,
}) => (
  <div id="thumbnails-settings">
    <div className="settings-groups same-width">
      <ThumbnailsSettingsThumbnails fields={{ feed, ratio, spacing }} iframeSizes={iframeSizes} />

      <ThumbnailsSettingsColorFilter fields={{ colorFilter, colorFilterFlag, colorFilterOpacity }} />

      <ThumbnailsSettingsBorder fields={{ borderColor, borderFlag, borderWidth }} />

      <ThumbnailsSettingsCorner fields={{ cornerFlag, cornerRadius }} />
    </div>
  </div>
);

LiveCamWidgetThumbnailsSettings.propTypes = {
  fields: PropTypes.shape({
    borderColor: PropTypes.object,
    borderFlag: PropTypes.object,
    borderWidth: PropTypes.object,
    colorFilter: PropTypes.object,
    colorFilterFlag: PropTypes.object,
    colorFilterOpacity: PropTypes.object,
    cornerFlag: PropTypes.object,
    cornerRadius: PropTypes.object,
    feed: PropTypes.object,
    ratio: PropTypes.object,
    spacing: PropTypes.object,
  }),
  iframeSizes: PropTypes.object,
};

export default reduxForm({
  form: 'thumbnailsSettingsLC',
  fields: [
    'borderColor',
    'borderFlag',
    'borderWidth',
    'colorFilter',
    'colorFilterFlag',
    'colorFilterOpacity',
    'cornerFlag',
    'cornerRadius',
    'feed',
    'ratio',
    'spacing',
  ],
  destroyOnUnmount: false,
})(LiveCamWidgetThumbnailsSettings);

import { Component } from 'react';
import PropTypes from 'prop-types';
import messages from '../../../../i18n/base-en.js';

import { InputText } from '../../../../components/reusables';

class CompanySection extends Component {
  shouldComponentUpdate(nextProps) {
    const { willRender, values, fields } = this.props;

    return (
      willRender !== nextProps.willRender ||
      values.company_name !== nextProps.values.company_name ||
      fields.company_name.error !== nextProps.fields.company_name.error ||
      fields.company_name.touched !== nextProps.fields.company_name.touched ||
      values.title !== nextProps.values.title ||
      fields.title.error !== nextProps.fields.title.error ||
      fields.title.touched !== nextProps.fields.title.touched ||
      values.taxId !== nextProps.values.taxId ||
      fields.taxId.error !== nextProps.fields.taxId.error ||
      fields.taxId.touched !== nextProps.fields.taxId.touched
    );
  }

  render() {
    const { fields, willRender } = this.props;
    let companySection = false;

    if (willRender) {
      companySection = (
        <div className="company_section">
          <div className="companyFields">
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.company_name}
                id="company_name"
                label={messages.genericTextCompanyName}
                maxLength={50}
                tabIndex={80}
                type="text"
              />
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.title}
                id="title"
                label={messages.genericTextTitleInOrganisation}
                tabIndex={90}
                type="text"
              />
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.taxId}
                id="taxId"
                label={messages.genericTextTaxId}
                tabIndex={100}
                type="text"
              />
            </div>
          </div>
        </div>
      );
    }

    return companySection;
  }
}

CompanySection.propTypes = {
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default CompanySection;

const LiveCamWidgetSkin0Icon = () => (
  <svg viewBox="0 0 806.46 604.79" xmlns="http://www.w3.org/2000/svg">
    <title>Skin 0</title>
    <rect fill="#757575" height="604.79" width="806.39" x="0.07" />
    <path
      d="M563.86,481.28,484.4,461.41l-4.58-18.35c54.81-9.23,83-25.66,84.27-26.38a10.38,10.38,0,0,0,5-8.26,10.53,10.53,0,0,0-4-8.88c-.37-.28-37.43-30.9-37.43-137,0-89.46-20.93-134.81-62.22-134.81h-6.12C444.48,113.37,433.3,107,403.27,107c-39,0-124.44,39.17-124.44,155.55,0,106.06-37.07,136.68-37.34,136.88a10.37,10.37,0,0,0,.87,17.18c1.25.74,29.19,17.25,84.33,26.46l-4.58,18.31-79.45,19.87a116.48,116.48,0,0,0-87.82,123.51H651.69A116.45,116.45,0,0,0,563.86,481.28Z"
      fill="#e7eced"
    />
    <rect height="48.34" opacity=".5" width="806" y="556.45" />
    <path
      d="M25.79,567.58l7,18.53,7-18.53h5.43v24.17H41v-8l.41-10.66-7.13,18.63h-3l-7.12-18.61.41,10.64v8H20.34V567.58Z"
      fill="#fff"
    />
    <path
      d="M49,582.6a10.53,10.53,0,0,1,1-4.75A7.58,7.58,0,0,1,53,574.59a8.34,8.34,0,0,1,4.35-1.13,7.85,7.85,0,0,1,5.9,2.34A9.25,9.25,0,0,1,65.65,582V583a10.69,10.69,0,0,1-1,4.75,7.59,7.59,0,0,1-2.92,3.24,8.37,8.37,0,0,1-4.39,1.14,7.8,7.8,0,0,1-6.09-2.53A9.71,9.71,0,0,1,49,582.8Zm4,.35a7.24,7.24,0,0,0,1.14,4.34,3.73,3.73,0,0,0,3.19,1.57,3.69,3.69,0,0,0,3.18-1.59,8,8,0,0,0,1.14-4.67,7.13,7.13,0,0,0-1.17-4.31,3.94,3.94,0,0,0-6.32,0A7.82,7.82,0,0,0,53,583Z"
      fill="#fff"
    />
    <path
      d="M68.32,582.64A10.72,10.72,0,0,1,70.24,576a6.18,6.18,0,0,1,5.17-2.51,5.85,5.85,0,0,1,4.61,2v-9.2h4v25.5H80.41l-.2-1.86a6,6,0,0,1-4.84,2.19,6.11,6.11,0,0,1-5.1-2.54A11,11,0,0,1,68.32,582.64Zm4,.35a7.41,7.41,0,0,0,1.06,4.27,3.42,3.42,0,0,0,3,1.54A3.81,3.81,0,0,0,80,586.59v-7.67a3.74,3.74,0,0,0-3.58-2.16,3.44,3.44,0,0,0-3,1.55A8.32,8.32,0,0,0,72.35,583Z"
      fill="#fff"
    />
    <path
      d="M96.18,592.08A8.35,8.35,0,0,1,90,589.67a8.77,8.77,0,0,1-2.38-6.44v-.49a10.66,10.66,0,0,1,1-4.81,7.93,7.93,0,0,1,2.91-3.3,7.73,7.73,0,0,1,4.18-1.17,7,7,0,0,1,5.67,2.34,9.92,9.92,0,0,1,2,6.62v1.63H91.64a5.46,5.46,0,0,0,1.49,3.52,4.47,4.47,0,0,0,3.28,1.29,5.43,5.43,0,0,0,4.51-2.24l2.18,2.08a7.26,7.26,0,0,1-2.88,2.49A8.91,8.91,0,0,1,96.18,592.08Zm-.48-15.39A3.41,3.41,0,0,0,93,577.86a6,6,0,0,0-1.3,3.23H99.4v-.3a4.9,4.9,0,0,0-1.08-3.06A3.38,3.38,0,0,0,95.7,576.69Z"
      fill="#fff"
    />
    <path d="M110.8,591.75h-4v-25.5h4Z" fill="#fff" />
    <path d="M134.76,591.75h-4.2L119.78,574.6v17.15h-4.2V567.58h4.2l10.81,17.21V567.58h4.17Z" fill="#fff" />
    <path
      d="M150.07,591.75a5.93,5.93,0,0,1-.47-1.68,6.79,6.79,0,0,1-9.13.47,4.94,4.94,0,0,1-1.71-3.82,5.12,5.12,0,0,1,2.13-4.41,10.39,10.39,0,0,1,6.1-1.53h2.48V579.6a3.12,3.12,0,0,0-.78-2.23,3.09,3.09,0,0,0-2.38-.84,3.51,3.51,0,0,0-2.25.69,2.12,2.12,0,0,0-.88,1.75h-4a4.51,4.51,0,0,1,1-2.77,6.47,6.47,0,0,1,2.67-2,9.27,9.27,0,0,1,3.76-.73,7.54,7.54,0,0,1,5,1.58,5.64,5.64,0,0,1,1.92,4.46v8.1a9.23,9.23,0,0,0,.68,3.87v.28Zm-4.44-2.9a4.56,4.56,0,0,0,2.25-.59,3.86,3.86,0,0,0,1.59-1.56v-3.38h-2.18a6,6,0,0,0-3.37.78,2.53,2.53,0,0,0-1.13,2.21,2.35,2.35,0,0,0,.78,1.85A3,3,0,0,0,145.63,588.85Z"
      fill="#fff"
    />
    <path
      d="M161.52,573.79l.12,1.87a6.45,6.45,0,0,1,5.18-2.2c2.4,0,4,.91,4.93,2.75a6.35,6.35,0,0,1,5.51-2.75,5.58,5.58,0,0,1,4.42,1.64,7.22,7.22,0,0,1,1.49,4.85v11.8h-4V580.06a3.54,3.54,0,0,0-.74-2.5,3.28,3.28,0,0,0-2.48-.8,3.39,3.39,0,0,0-2.25.74,4,4,0,0,0-1.22,1.93l0,12.32h-4V579.93q-.09-3.16-3.24-3.17a3.56,3.56,0,0,0-3.44,2v13h-4v-18Z"
      fill="#fff"
    />
    <path
      d="M195.22,592.08a8.35,8.35,0,0,1-6.22-2.41,8.81,8.81,0,0,1-2.38-6.44v-.49a10.79,10.79,0,0,1,1-4.81,7.93,7.93,0,0,1,2.91-3.3,7.76,7.76,0,0,1,4.19-1.17,7.07,7.07,0,0,1,5.67,2.34,9.92,9.92,0,0,1,2,6.62v1.63H190.69a5.41,5.41,0,0,0,1.49,3.52,4.45,4.45,0,0,0,3.27,1.29,5.45,5.45,0,0,0,4.52-2.24l2.17,2.08a7.26,7.26,0,0,1-2.88,2.49A8.88,8.88,0,0,1,195.22,592.08Zm-.48-15.39a3.38,3.38,0,0,0-2.68,1.17,5.94,5.94,0,0,0-1.3,3.23h7.68v-.3a4.9,4.9,0,0,0-1.08-3.06A3.37,3.37,0,0,0,194.74,576.69Z"
      fill="#fff"
    />
    <path
      d="M590,588.61a7.94,7.94,0,0,1-3.63,2.59,14.76,14.76,0,0,1-5.28.88,10.47,10.47,0,0,1-5.38-1.37,9.17,9.17,0,0,1-3.61-4,13.68,13.68,0,0,1-1.32-6v-1.9q0-5.48,2.62-8.55a9.08,9.08,0,0,1,7.32-3.08,9.59,9.59,0,0,1,6.41,2A8.62,8.62,0,0,1,590,575h-4.12c-.47-2.91-2.15-4.37-5.06-4.37a5,5,0,0,0-4.3,2,10.31,10.31,0,0,0-1.51,6v1.86a9.86,9.86,0,0,0,1.65,6.08,5.45,5.45,0,0,0,4.59,2.16q3.21,0,4.58-1.46v-4.73h-5v-3.19H590Z"
      fill="#fff"
    />
    <path
      d="M602.12,592.08a8.35,8.35,0,0,1-6.22-2.41,8.77,8.77,0,0,1-2.38-6.44v-.49a10.66,10.66,0,0,1,1-4.81,7.93,7.93,0,0,1,2.91-3.3,7.75,7.75,0,0,1,4.18-1.17,7,7,0,0,1,5.67,2.34,9.86,9.86,0,0,1,2,6.62v1.63H597.59a5.4,5.4,0,0,0,1.48,3.52,4.47,4.47,0,0,0,3.28,1.29,5.46,5.46,0,0,0,4.52-2.24L609,588.7a7.37,7.37,0,0,1-2.88,2.5A9,9,0,0,1,602.12,592.08Zm-.48-15.39a3.38,3.38,0,0,0-2.68,1.17,6,6,0,0,0-1.31,3.23h7.69v-.3a4.9,4.9,0,0,0-1.08-3.06A3.38,3.38,0,0,0,601.64,576.69Z"
      fill="#fff"
    />
    <path
      d="M616.2,573.79l.11,2.07a6.47,6.47,0,0,1,5.23-2.4q5.61,0,5.71,6.42v11.87h-4V580.11a3.71,3.71,0,0,0-.74-2.53,3.09,3.09,0,0,0-2.41-.82,3.9,3.9,0,0,0-3.64,2.21v12.78h-4v-18Z"
      fill="#fff"
    />
    <path
      d="M630.61,582.64a10.72,10.72,0,0,1,1.92-6.67,6.18,6.18,0,0,1,5.17-2.51,5.85,5.85,0,0,1,4.61,2v-9.2h4v25.5h-3.65l-.2-1.86a5.93,5.93,0,0,1-4.83,2.19,6.11,6.11,0,0,1-5.1-2.54A11,11,0,0,1,630.61,582.64Zm4,.35a7.41,7.41,0,0,0,1.06,4.27,3.42,3.42,0,0,0,3,1.54,3.81,3.81,0,0,0,3.62-2.21v-7.67a3.75,3.75,0,0,0-3.58-2.16,3.47,3.47,0,0,0-3,1.55A8.35,8.35,0,0,0,634.64,583Z"
      fill="#fff"
    />
    <path
      d="M658.46,592.08a8.32,8.32,0,0,1-6.21-2.41,8.77,8.77,0,0,1-2.39-6.44v-.49a10.79,10.79,0,0,1,1-4.81,8,8,0,0,1,2.92-3.3,7.73,7.73,0,0,1,4.18-1.17,7.06,7.06,0,0,1,5.67,2.34,9.92,9.92,0,0,1,2,6.62v1.63H653.93a5.41,5.41,0,0,0,1.49,3.52,4.47,4.47,0,0,0,3.28,1.29,5.44,5.44,0,0,0,4.51-2.24l2.18,2.08a7.37,7.37,0,0,1-2.88,2.5A9.05,9.05,0,0,1,658.46,592.08ZM658,576.69a3.39,3.39,0,0,0-2.68,1.17,6,6,0,0,0-1.3,3.23h7.68v-.3a4.84,4.84,0,0,0-1.07-3.06A3.39,3.39,0,0,0,658,576.69Z"
      fill="#fff"
    />
    <path
      d="M678.2,577.47a11,11,0,0,0-1.64-.13,3.71,3.71,0,0,0-3.74,2.13v12.28h-4v-18h3.85l.1,2a4.56,4.56,0,0,1,4-2.34,3.69,3.69,0,0,1,1.43.23Z"
      fill="#fff"
    />
    <path
      d="M721.65,581.57q0,5.22-1.95,7.87a6.9,6.9,0,0,1-5.94,2.64,7,7,0,0,1-5.9-2.58q-2-2.58-2-7.64v-4.19q0-5.2,1.95-7.82a8.14,8.14,0,0,1,11.87-.06c1.3,1.69,2,4.23,2,7.6Zm-4-4.51a10.24,10.24,0,0,0-.93-5,3.55,3.55,0,0,0-5.87-.08,9.52,9.52,0,0,0-1,4.69v5.48a10.48,10.48,0,0,0,.94,5,3.17,3.17,0,0,0,3,1.66,3.13,3.13,0,0,0,2.88-1.53,10,10,0,0,0,1-4.81Z"
      fill="#fff"
    />
    <path
      d="M741,581.57q0,5.22-2,7.87a6.9,6.9,0,0,1-5.93,2.64,7,7,0,0,1-5.9-2.58c-1.33-1.72-2-4.27-2-7.64v-4.19q0-5.2,2-7.82a8.14,8.14,0,0,1,11.87-.06c1.3,1.69,2,4.23,2,7.6Zm-4-4.51a10.24,10.24,0,0,0-.93-5,3.55,3.55,0,0,0-5.86-.08,9.4,9.4,0,0,0-1,4.69v5.48a10.47,10.47,0,0,0,1,5,3.17,3.17,0,0,0,3,1.66,3.13,3.13,0,0,0,2.87-1.53,9.87,9.87,0,0,0,1-4.81Z"
      fill="#fff"
    />
    <path
      d="M759.55,586l3.65-12.18h4.3l-7.14,20.68q-1.64,4.54-5.57,4.54a7.24,7.24,0,0,1-1.95-.3v-3.14l.77,0a3.94,3.94,0,0,0,2.3-.56,3.71,3.71,0,0,0,1.22-1.86l.58-1.55-6.31-17.86h4.35Z"
      fill="#fff"
    />
    <path
      d="M768.82,582.6a10.52,10.52,0,0,1,1-4.75,7.7,7.7,0,0,1,2.94-3.26,8.34,8.34,0,0,1,4.35-1.13,7.85,7.85,0,0,1,5.9,2.34A9.2,9.2,0,0,1,785.5,582l0,.94a10.56,10.56,0,0,1-1,4.75,7.59,7.59,0,0,1-2.92,3.24,8.35,8.35,0,0,1-4.39,1.14,7.78,7.78,0,0,1-6.08-2.53,9.67,9.67,0,0,1-2.28-6.75Zm4,.35a7.24,7.24,0,0,0,1.14,4.34,3.73,3.73,0,0,0,3.19,1.57,3.69,3.69,0,0,0,3.18-1.59,8,8,0,0,0,1.14-4.67,7.13,7.13,0,0,0-1.17-4.31,3.94,3.94,0,0,0-6.32,0A7.89,7.89,0,0,0,772.85,583Z"
      fill="#fff"
    />
    <circle cx="763.05" cy="42.53" fill="#22ad01" r="16.83" />
  </svg>
);

export default LiveCamWidgetSkin0Icon;

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../i18n/base-en.js';

import { AlertHeader } from '../reusables';
import { fetchPayoutTotal } from '../../actions/payout-total-actions';
const CustomIcon = () => (
  <img alt="Account Manager" className="image-tag with-btn bg-primary-color" src="/img/logo-cr_papa_whale_small.png" />
);

const BannerProfileOnly = () => (
  <AlertHeader CustomIcon={CustomIcon} className="container-fluid app-container alert-sitewide no-close">
    <span className="content-with-btn">
      <FormattedMessage {...messages.notificationBannerCompleteProfileOnly} />
    </span>
    <Link className="waves-effect waves-light btn" onlyActiveOnIndex={false} to="/profile/user-details">
      <FormattedMessage {...messages.genericTextGoToUserDetails} />
    </Link>
  </AlertHeader>
);

const BannerPaymentOnly = () => (
  <AlertHeader CustomIcon={CustomIcon} className="container-fluid app-container alert-sitewide no-close">
    <span className="content-with-btn">
      <FormattedMessage {...messages.genericTextWarning} />:{' '}
      <FormattedMessage {...messages.notificationBannerCompletePaymentOnly} />
    </span>
    <Link className="waves-effect waves-light btn" onlyActiveOnIndex={false} to="/profile/billing-infos">
      <FormattedMessage {...messages.genericTextGoToBillingInformation} />
    </Link>
  </AlertHeader>
);

const BannerIdentityOnly = () => (
  <AlertHeader CustomIcon={CustomIcon} className="container-fluid app-container alert-sitewide no-close">
    <span className="content-with-btn">
      <FormattedMessage {...messages.genericTextWarning} />:{' '}
      <FormattedMessage {...messages.notificationBannerCompleteIdentityOnly} />
    </span>
    <Link className="waves-effect waves-light btn" onlyActiveOnIndex={false} to="/profile/identity">
      <FormattedMessage {...messages.genericTextGoToIdentity} />
    </Link>
  </AlertHeader>
);

const BannerProfileCompleted = ({ dispatch, payoutTotal, profileCompleted: { communicationStatus } }) => {
  useEffect(() => {
    dispatch(fetchPayoutTotal());
  }, []);
  switch (communicationStatus) {
    case 'both':
    case 'profile':
      return <BannerProfileOnly />;

    case 'payment':
      return <BannerPaymentOnly />;

    case 'identity':
      if (payoutTotal > 0) {
        return <BannerIdentityOnly />;
      }
      return null;
    default:
      return null;
  }
};

BannerProfileCompleted.propTypes = {
  profileCompleted: PropTypes.object.isRequired,
};

export default connect((state) => ({
  payoutTotal: state.payoutTotal.payoutTotal,
}))(BannerProfileCompleted);

import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, initialize } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import { CustomColorPicker } from '../../components/reusables/colorpicker/CustomColorPicker.react';

import { setColorTemplate, setAnimatedTemplateSurvey } from '../../actions/survey-generator-actions';
import { COLOR_TEMPLATES } from './surveyGenerator-consts';

import 'materialize-css';
import { Select } from 'react-materialize';
function TemplateOptions(props) {
  const { fields, fieldsSettings, dispatch, handleChangeQuestionId, intl, templateUrls, values, changeBaseSurveyUrl } = props;
  const colorMask = '#******';

  const models = !fieldsSettings ? fieldsSettings.img : {};
  const modelSelectActive = Object.keys(models).length > 0;
  const [primaryColor, setPrimaryColor] = useState('transparent');
  const [secondaryColor, setSecondaryColor] = useState('transparent');

  const isMounted = useRef(false);

  const fillForms = (formConst) => {
    const { dispatch } = props;

    if (formConst) {
      dispatch(setColorTemplate(formConst.INDEX));

      // set default colors
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'templateOptions',
        field: 'primaryColor',
        value: formConst.TEMPLATE_OPTIONS.primaryColor,
      });
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'templateOptions',
        field: 'secondaryColor',
        value: formConst.TEMPLATE_OPTIONS.secondaryColor,
      });

      dispatch(setAnimatedTemplateSurvey(false));

      dispatch(initialize('basicConfiguration', formConst.BASIC_CONFIGURATION, Object.keys(formConst.BASIC_CONFIGURATION)));
      dispatch(initialize('rewardOptions', formConst.REWARD_OPTIONS, Object.keys(formConst.REWARD_OPTIONS)));
      dispatch(initialize('customColors', formConst.CUSTOM_COLORS, Object.keys(formConst.CUSTOM_COLORS)));
    }
  };

  const changeColor = (color, key) => {
    if (!color || !values.templateColors) {
      return;
    }

    COLOR_TEMPLATES[`COLOR_TEMPLATE${values.templateColors}`][key].forEach((colorField) => {
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'customColors',
        field: colorField,
        value: color,
      });
    });
  };

  let questionThemeId;
  useEffect(() => {
    changeColor(fields.secondaryColor.value, 'SECONDARYCOLORS_FILEDS');
  }, [fields.secondaryColor.value]);

  useEffect(() => {
    changeColor(fields.primaryColor.value, 'PRIMARYCOLORS_FILEDS');
  }, [fields.primaryColor.value]);

  const concurrentCalls = (e, field) => {
    fields[field].onChange(e);
    switch (field) {
      case 'primaryColor':
        setPrimaryColor(e);
        changeColor(e, 'PRIMARYCOLORS_FILEDS');
        break;
      case 'secondaryColor':
        setSecondaryColor(e);
        changeColor(e, 'SECONDARYCOLORS_FILEDS');
        break;
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fillForms(COLOR_TEMPLATES[`COLOR_TEMPLATE${values.templateColors}`]);
    } else {
      isMounted.current = true;
    }
  }, [values.templateColors]);

  useEffect(() => {
    const defaultTemplate = templateUrls[0];
    if (defaultTemplate) {
      handleCanvaChange();
      handleGtmEventTemplate(defaultTemplate.offer_url);
      fields.templateUrl.onChange(defaultTemplate.offer_url);
    }
  }, [templateUrls]);

  useEffect(() => {
    questionThemeId = values.templateUrl?.substring(values.templateUrl.indexOf('survey_id='), values.templateUrl.indexOf('&'));

    handleChangeQuestionId(questionThemeId?.split('survey_id=')[1]);
    changeBaseSurveyUrl(values.templateUrl);
  }, [values.templateUrl]);

  const handleGtmEventTemplate = (templateUrl) => {
    Gtm.event('survey machine', 'Click', `template dropdown - ${templateUrl.name}`);
  };

  const handleGtmEventColor = (e) => {
    const { intl } = props;
    switch (e.target.value) {
      case '1':
        Gtm.event(
          'survey machine',
          'Click',
          `color template dropdown - ${intl.formatMessage(messages.surveyGeneratorPaletteDark)}`
        );
        break;
      case '2':
        Gtm.event(
          'survey machine',
          'Click',
          `color template dropdown - ${intl.formatMessage(messages.surveyGeneratorPaletteLight)}`
        );
        break;
      case '3':
        Gtm.event(
          'survey machine',
          'Click',
          `color template dropdown - ${intl.formatMessage(messages.surveyGeneratorPaletteSocial1)}`
        );
        break;
      case '4':
        Gtm.event(
          'survey machine',
          'Click',
          `color template dropdown - ${intl.formatMessage(messages.surveyGeneratorPaletteSocial2)}`
        );
        break;
      default:
        Gtm.event(
          'survey machine',
          'Click',
          `color template dropdown - ${intl.formatMessage(messages.surveyGeneratorPaletteDating)}`
        );
    }
  };

  const handleCanvaChange = () => {
    if (values.templateColors) {
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'basicConfiguration',
        field: 'questionTheme',
        value: COLOR_TEMPLATES[`COLOR_TEMPLATE${values.templateColors}`].BASIC_CONFIGURATION.questionTheme,
      });
    }
  };

  return (
    <div className="container-fluid" id="color-templates">
      <div className="row">
        <div className="col-md-12 separator-right separator-left config-section">
          <h2>
            <FormattedMessage {...messages.surveyGeneratorCustomColors} />
          </h2>
          <div className="row">
            <div className="col-md-4">
              <Select
                disabled={!values.templateUrl}
                id="templateColors"
                label={messages.surveyGeneratorColorTemplate.description}
                onChange={(e) => {
                  handleGtmEventColor(e);
                  fields.templateColors.onChange(e.target.value);
                }}
                tabIndex={20}
                value={fields.templateColors.value}
              >
                <option disabled value="">
                  {intl.formatMessage(messages.genericTextChoose)}
                </option>
                <option value="1">{intl.formatMessage(messages.surveyGeneratorPaletteDark)}</option>
                <option value="2">{intl.formatMessage(messages.surveyGeneratorPaletteLight)}</option>
                <option value="3">{intl.formatMessage(messages.surveyGeneratorPaletteSocial1)}</option>
                <option value="4">{intl.formatMessage(messages.surveyGeneratorPaletteSocial2)}</option>
                <option value="5">{intl.formatMessage(messages.surveyGeneratorPaletteDating)}</option>
              </Select>
            </div>
            <div className="col-md-4 survey-color-picker">
              <CustomColorPicker
                color={primaryColor}
                disabled={!values.templateColors}
                field={fields.primaryColor}
                id="templatePrimaryColor"
                input
                inputOnChange={(e) => concurrentCalls(e.target.value, 'primaryColor')}
                label={messages.surveyGeneratorPrimaryColor}
                labelClasses="active"
                mask={colorMask}
                onChange={(e) => concurrentCalls(e, 'primaryColor')}
              />
            </div>
            <div className="col-md-4 survey-color-picker">
              <CustomColorPicker
                color={secondaryColor}
                disabled={!values.templateColors}
                field={fields.secondaryColor}
                handleChange={setSecondaryColor}
                id="templateSecondaryColor"
                labelClasses="active"
                input
                inputOnChange={(e) => concurrentCalls(e.target.value, 'secondaryColor')}
                label={messages.surveyGeneratorSecondaryColor}
                mask={colorMask}
                onChange={(e) => concurrentCalls(e, 'secondaryColor')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateOptions.propTypes = {
  changeBaseSurveyUrl: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  fieldsSettings: PropTypes.object,
  handleChangeQuestionId: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  models: PropTypes.object,
  templateUrls: PropTypes.array,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'templateOptions',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['modelIdentity', 'primaryColor', 'secondaryColor', 'templateColors', 'templateUrl'],
    destroyOnUnmount: false,
  })(TemplateOptions)
);

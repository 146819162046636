import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import OfferCreativesCard from './CreativesCard.react.js';

const OfferCreativesCards = ({
  creatives,
  handleSelectCreative,
  isSelected,
  generateHtmlIframe,
  displayUrl,
  displayLink,
  offerThumbnail,
}) => (
  <ReactCSSTransitionGroup
    className="animationBrandsCard row"
    component="div"
    transitionEnterTimeout={creatives.length * 50 + 500}
    transitionLeaveTimeout={creatives.length * 50 + 500}
    transitionName="card"
  >
    {creatives.map((creative) => (
      <OfferCreativesCard
        creative={creative}
        creativeMaxHeight={500}
        displayLink={displayLink}
        displayUrl={displayUrl}
        generateHtmlIframe={generateHtmlIframe}
        handleCreativeSelect={handleSelectCreative}
        isSelected={isSelected}
        key={creative.url}
        offerThumbnail={offerThumbnail}
      />
    ))}
  </ReactCSSTransitionGroup>
);

OfferCreativesCards.propTypes = {
  creatives: PropTypes.array.isRequired,
  displayLink: PropTypes.func.isRequired,
  displayUrl: PropTypes.func.isRequired,
  generateHtmlIframe: PropTypes.func.isRequired,
  handleSelectCreative: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  offerThumbnail: PropTypes.string,
};

export default OfferCreativesCards;

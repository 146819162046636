import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../i18n/base-en.js';

import ReferralsCards from './referrals/ReferralsCards.react';
import ReferralsSearch from './referrals/ReferralsSearch.react';
import ReferralsTable from './referrals/ReferralsTable.react';
import { Container, CopyValueWidget, ViewMode } from '../components/reusables';
import CommissionsIcon from '../components/reusables/svg/Commissions.react';
import RefferedAffiliatesIcon from '../components/reusables/svg/RefferedAffiliates.react';
import {
  fetchReferralCommission,
  fetchReferrals,
  changeReferralsDateFilters,
  changeReferralsViewMode,
} from '../actions/referrals-actions';
import { Gtm } from '../utils/gtm';

import consts from '../utils/consts';
import GreenCheckMark from '../components/reusables/svg/GreenCheckmark.react';
import InformationIcon from '../components/reusables/svg/InformationIcon.react';
import HelpSign from '../components/reusables/svg/HelpSign.react';
import CrakRevenueLogo from '../components/reusables/svg/CrakRevenueLogo.react';
const { REFERRAL_LINK_URL } = consts;

const Referrals = ({
  affId,
  dispatch,
  intl,
  referralCommission,
  referrals,
  referralsIsFetching,
  referralsSettings,
  referralsViewMode,
  referralsTimeStamp,
}) => {
  const [tab, setTab] = useState('commissions');

  const usePrevPropValue = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevReferralsSettings = usePrevPropValue(referralsSettings);
  const fetchData = () => {
    if (referralsTimeStamp <= Date.now() - 600000) {
      const defaultReferralsSettings = referralsSettings;
      const updatedReferralSettings = {
        ...defaultReferralsSettings,
        filters: {
          'Stat.date': {
            conditional: 'BETWEEN',
            values: [defaultReferralsSettings.filters['Stat.date'].values[0], moment().format('YYYY-MM-DD')],
          },
        },
      };
      dispatch(fetchReferrals(updatedReferralSettings));
    }

    if (!referralCommission.rate) {
      dispatch(fetchReferralCommission());
    }
  };

  const handleViewModeChange = (viewMode) => {
    dispatch(changeReferralsViewMode(viewMode));
  };

  const handleTabChange = (newTab) => {
    setTab(newTab);

    if (newTab === 'affiliates') {
      dispatch(changeReferralsDateFilters('2000-01-01,2018-01-22'));
      Gtm.event('refferals', 'click', 'refferals affiliates');
    } else {
      Gtm.event('refferals', 'click', 'refferals commissions');
    }
  };

  const containerClasses = ['z-depth-2', 'with-tabs'].join(' ');
  const rate = parseFloat(referralCommission.rate);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!_.isEqual(referralsSettings, prevReferralsSettings)) {
      dispatch(fetchReferrals(referralsSettings));
    }
  }, [referralsSettings]);

  return (
    <div className="container-fluid app-container" id="profile-referrals">
      <div className="header">
        <h1 className="primary-color">
          <FormattedMessage {...messages.profileMenuReferralsTitle} />
          <a
            className="info"
            href="https://support.crakrevenue.com/knowledge-base/crakrevenue-referral-program/"
            onClick={(e) => {
              Gtm.event('referrals', 'click', 'referrals info icon');
              e.stopPropagation();
            }}
            rel="noopener noreferrer"
            target="_blank"
            title={intl.formatMessage(messages.referralsLearnMore)}
          >
            <InformationIcon className="material-icons" />
          </a>
        </h1>
        <div className="checkmark-list">
          <span className="option-list">
            <GreenCheckMark /> <FormattedMessage {...messages.referralsNewMarketCheckmark} />
          </span>
          <span className="option-list">
            <GreenCheckMark /> <FormattedMessage {...messages.referralsCCffiliates} />
          </span>
          <span className="option-list">
            <GreenCheckMark /> <FormattedMessage {...messages.referralsLifetimeCommission} values={{ rate }} />
          </span>
        </div>
      </div>
      <div className="banners">
        <div className="banner">
          <div className="header-banner crakrevenue">
            <CrakRevenueLogo className="fr-logo" />
          </div>
          <div className="text-container">
            <div className="black-pills-box">
              <span className="black-pills">{rate}%</span>
            </div>
            <h2>
              <FormattedHTMLMessage {...messages.referralsCRtitle} />
            </h2>
            <p>
              <FormattedHTMLMessage {...messages.referralsCRDescription} values={{ rate }} />
            </p>
            <CopyValueWidget dispatch={dispatch} id="ref-link-cr" isReferrals value={`${REFERRAL_LINK_URL}?r=${affId}`} />
          </div>
        </div>
      </div>
      <div className="help-section">
        <div className="need-help-box">
          <HelpSign />
          <div className="need-help-text">
            <h2>
              <FormattedHTMLMessage {...messages.referralsHelpSectiontitle} />
            </h2>
            <p>
              <FormattedHTMLMessage {...messages.referralsHelpSectionDescription} />
            </p>
          </div>
        </div>
        <button
          className="btn"
          onClick={() => {
            window.Intercom('show', async () => {
              Gtm.event(`referrals page`, 'Click', 'need_help');
            });
          }}
          type="button"
        >
          Contact us
        </button>
      </div>
      <div id="referrals-filters">
        {tab === 'commissions' ? (
          <ReferralsSearch className="pull-left" dispatch={dispatch} filters={referralsSettings.filters} />
        ) : (
          /* visibility hidden here keeps the space used to not cause UI shift */ <div className="hidden-referrals-search">
            <ReferralsSearch className="pull-left" dispatch={dispatch} filters={referralsSettings.filters} />
          </div>
        )}
        <ViewMode
          classNames={['pull-right', 'hidden-xs', 'viewmode']}
          currentViewMode={referralsViewMode}
          handleViewModeChange={handleViewModeChange}
        />
      </div>
      <div id="referrals-infos">
        <ul className="tabs">
          <li
            className={tab === 'commissions' ? 'selected fill-primary-color' : undefined}
            onClick={() => {
              handleTabChange('commissions');
            }}
          >
            <CommissionsIcon />
            <FormattedMessage {...messages.referralsCommissions} />
          </li>
          <li
            className={tab === 'affiliates' ? 'selected fill-primary-color' : undefined}
            onClick={() => {
              handleTabChange('affiliates');
            }}
          >
            <RefferedAffiliatesIcon />
            <FormattedMessage {...messages.referralsReferredAffiliates} />
          </li>
        </ul>
        <Container className={containerClasses}>
          {referralsViewMode === 'table' ? (
            <ReferralsTable activeTab={tab} referrals={referrals} referralsIsFetching={referralsIsFetching} />
          ) : (
            <ReferralsCards activeTab={tab} referrals={referrals} referralsIsFetching={referralsIsFetching} />
          )}
        </Container>
      </div>
    </div>
  );
};

Referrals.propTypes = {
  affId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  referralCommission: PropTypes.object.isRequired,
  referrals: PropTypes.array.isRequired,
  referralsIsFetching: PropTypes.bool.isRequired,
  referralsSettings: PropTypes.object.isRequired,
  referralsViewMode: PropTypes.string.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => {
  const {
    referrals: { referralCommission, referrals, referralsIsFetching, referralsSettings, referralsTimeStamp },
    application: {
      ui: { referralsViewMode },
    },
    skinConfigurations,
    profile: {
      data: {
        affUserInfos: { affiliate_id },
      },
    },
  } = state;

  return {
    affId: affiliate_id,
    referralCommission,
    referrals,
    referralsIsFetching,
    referralsTimeStamp,
    referralsSettings: {
      ...referralsSettings,
      filters: {
        'Stat.date': {
          conditional: 'BETWEEN',
          values: [referralsSettings.filters['Stat.date'].values[0], moment().format('YYYY-MM-DD')],
        },
      },
    },
    referralsViewMode,
    skinConfigurations: skinConfigurations.data,
  };
})(injectIntl(Referrals));

import { Component } from 'react';
import PropTypes from 'prop-types';
import { reset, initialize } from 'redux-form';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Modal, Scroller } from '../../components/reusables';

import {
  setStatisticsFilters,
  setStatisticsReport,
  resetStatistics,
  deleteSavedReport,
  resetIsFetching,
} from '../../actions/statistics-actions';
import { setActionMessage } from '../../components/Application/actions/application-actions';
import { detectMobileAndTablet, reportTagsToFormFields, reportTagsToActiveFilters } from '../../utils/helpers';

import { Gtm } from '../../utils/gtm';

// TODO: remove jQuery from project
import $ from 'jquery';

import consts from '../../utils/consts';
const { STATS_REPORTS, MOBILE_STATS_REPORTS } = consts;

class StatisticsReports extends Component {
  constructor(props) {
    super(props);

    this.handleDeleteSavedReport = this.handleDeleteSavedReport.bind(this);
    this.handleReportChange = this.handleReportChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);

    this.state = {
      currentModalReportId: null,
    };
  }

  componentDidMount() {
    const { dispatch, statisticsIsFetching } = this.props;
    window.crakrevenue.initTabsScrolling();

    if (statisticsIsFetching) {
      dispatch(resetIsFetching());
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      reportMainMenu,
      reportSubMenu,
      resetable,
      savedReportsList,
      statisticsDateEnd,
      statisticsDateStart,
      statisticsIsFetching,
    } = this.props;
    return (
      reportMainMenu !== nextProps.reportMainMenu ||
      reportSubMenu !== nextProps.reportSubMenu ||
      resetable !== nextProps.resetable ||
      !_.isEqual(savedReportsList, nextProps.savedReportsList) ||
      statisticsDateEnd !== nextProps.statisticsDateEnd ||
      statisticsDateStart !== nextProps.statisticsDateStart ||
      statisticsIsFetching !== nextProps.statisticsIsFetching
    );
  }

  componentDidUpdate() {
    window.crakrevenue.initTabsScrolling();
  }

  handleReportChange(e) {
    const { dispatch, savedReportsList, statisticsIsFetching } = this.props;

    const el = e.target.tagName === 'LI' ? e.target : e.target.parentNode;
    const report = JSON.parse(el.getAttribute('data-report'));
    const mainMenu = el.getAttribute('data-reportmenu');
    const subMenu = el.getAttribute('data-reportsubmenu');

    if (!statisticsIsFetching && !(mainMenu === 'Saved' && savedReportsList.length <= 0)) {
      dispatch(setStatisticsReport(mainMenu, subMenu, report));
      dispatch(
        initialize(
          'statisticsFilters',
          {
            ...reportTagsToFormFields(report),
          },
          [
            'vertical',
            'brand',
            'offer',
            'offerUrl',
            'country',
            'browser',
            'payoutType',
            'subId1',
            'subId2',
            'subId3',
            'subId4',
            'subId5',
          ]
        )
      );

      dispatch(setStatisticsFilters(reportTagsToActiveFilters(report)));
    }
  }

  handleReset() {
    const { dispatch, resetable } = this.props;

    if (resetable) {
      dispatch(resetStatistics());
      dispatch(reset('statisticsFilters'));
      dispatch(reset('statisticsAddColumn'));
      dispatch(reset('statisticsAddFilter'));
    }
  }

  handleDeleteSavedReport() {
    const { dispatch, intl, savedReportsList } = this.props;
    const { currentModalReportId } = this.state;
    const id = currentModalReportId;

    $('#delete-report-modal').closeModal();

    dispatch(deleteSavedReport(id)).then(
      () => {
        dispatch(
          setActionMessage('success', {
            text: intl.formatMessage(messages.statisticsSavedReportDeleted),
          })
        );

        if (savedReportsList.length <= 1) {
          $('#statistics-reports .savedReports li:first-child').trigger('click');
        }
      },
      () => {
        dispatch(
          setActionMessage('error', {
            text: intl.formatMessage(messages.statisticsErrorDeletingSavedReport),
          })
        );
      }
    );
  }

  openDeleteModal(id) {
    this.setState({
      currentModalReportId: id,
    });

    $('#delete-report-modal').openModal();
  }

  render() {
    const { reportMainMenu, reportSubMenu, savedReportsList } = this.props;
    const { DAILY } = detectMobileAndTablet() ? MOBILE_STATS_REPORTS : STATS_REPORTS;

    return (
      <div id="statistics-reports">
        <div className="row">
          <div className="col-sm-9">
            <Scroller classNames={['tabs', 'savedReports']}>
              <ul>
                <li
                  className={[reportMainMenu === 'Performance' ? 'active' : undefined].join(' ')}
                  data-report={JSON.stringify(DAILY)}
                  data-reportmenu="Performance"
                  data-reportsubmenu="Daily"
                  onClick={(e) => {
                    this.handleReportChange(e);
                    Gtm.event('statistics', 'Click', 'performance report');
                  }}
                >
                  <span>Performance Report</span>
                </li>
                <li
                  className={[
                    reportMainMenu === 'Saved' ? 'active' : undefined,
                    savedReportsList.length <= 0 ? 'disabled' : undefined,
                  ].join(' ')}
                  data-reportmenu="Saved"
                  onClick={(e) => {
                    this.handleReportChange(e);
                    Gtm.event('statistics', 'Click', 'saved report');
                  }}
                >
                  <span>Saved Reports</span>
                </li>
              </ul>
            </Scroller>
          </div>
        </div>
        {reportMainMenu === 'Saved' && savedReportsList.length > 0 && (
          <div className="row">
            <div className="col-xs-12">
              <Scroller classNames={['reports-sub-menu']}>
                <ul>
                  {savedReportsList.map((savedReport) => (
                    <li
                      className={reportSubMenu === `saved-report-${savedReport.id}` ? 'active' : undefined}
                      data-report={JSON.stringify(savedReport.report)}
                      data-reportmenu="Saved"
                      data-reportsubmenu={`saved-report-${savedReport.id}`}
                      key={`saved-report-${savedReport.id}`}
                    >
                      <span onClick={this.handleReportChange}>{savedReport.name}</span>
                      <i className="material-icons delete-saved-report" onClick={() => this.openDeleteModal(savedReport.id)}>
                        &#xE5CD;
                      </i>
                    </li>
                  ))}
                </ul>
              </Scroller>
            </div>
          </div>
        )}
        <Modal
          button={{
            action: this.handleDeleteSavedReport,
            className: 'bg-primary-color',
            text: 'Yes',
          }}
          cancelButton
          className="modal black-modal"
          id="delete-report-modal"
        >
          <h2>
            <FormattedMessage {...messages.statisticsAreYouSureDeleteModal} />
          </h2>
        </Modal>
      </div>
    );
  }
}

StatisticsReports.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  reportMainMenu: PropTypes.string.isRequired,
  reportSubMenu: PropTypes.string,
  resetable: PropTypes.bool.isRequired,
  savedReportsList: PropTypes.array.isRequired,
  statisticsDateEnd: PropTypes.any,
  statisticsDateStart: PropTypes.any,
  statisticsIsFetching: PropTypes.bool.isRequired,
};

export default injectIntl(StatisticsReports);

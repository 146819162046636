import { CopyValueWidget } from '../reusables';
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

const JerkmateReferral = ({ dispatch, affId }) => {
  const jerkyRef = useRef(null);
  const interval = useRef(null);

  const [active, setActive] = useState(false);

  const [mobileInScreen, setMobileInScreen] = useState(false);

  useEffect(() => {
    if (mobileInScreen) {
      setActive((active) => !active);

      interval.current = setInterval(() => {
        setActive((active) => !active);
      }, 1300);
    }

    return () => clearInterval(interval.current);
  }, [mobileInScreen]);

  useEffect(() => {
    const removeListener = () => window.removeEventListener('scroll', onScroll);

    const onScroll = () => {
      if (jerkyRef.current && window.innerWidth <= 1024) {
        const bottom = jerkyRef.current.getBoundingClientRect().bottom - window.innerHeight;
        const divInScreen = bottom < 0;
        if (divInScreen) {
          removeListener();
          setMobileInScreen(true);
        }
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();
    return () => removeListener();
  }, []);

  return (
    <>
      <div className="referral-section">
        <div className="program">
          <h2 className="title">Referral program</h2>
          <h3 className="commission">5% Lifetime Commissions</h3>
          <p className="description">
            Get your fellows to sign up under your CrakRevenue referral link and earn <span className="dark-grey">5%</span> of all
            their earnings in referral commissions
            <span className="dark-grey"> for life!</span>
          </p>
          <CopyValueWidget
            dispatch={dispatch}
            id="ref-link"
            value={`https://www.crakrevenue.com/offers/cam/jerkmate-affiliate-program/?r=${affId}`}
          />
        </div>
        <div
          className="jerky-blue"
          onMouseEnter={mobileInScreen ? null : () => setActive(true)}
          onMouseLeave={mobileInScreen ? null : () => setActive(false)}
          ref={jerkyRef}
        >
          <div className={`jerky-anim ${active ? 'active' : ''}`}>
            <div className="jerky-body-box">
              <img alt="Jerkmate" className="jerky-head" src="/img/jerkmate2/jerky-blue/Head.png" />
              <img alt="Jerkmate" className="jerky-arm-left" src="/img/jerkmate2/jerky-blue/ArmB.png" />
              <img alt="Jerkmate" className="jerky-body" src="/img/jerkmate2/jerky-blue/Body.png" />
              <img alt="Jerkmate" className="jerky-arm-right" src="/img/jerkmate2/jerky-blue/ArmR.png" />
            </div>
            <div className="jerky-coins-left">
              <img src="/img/jerkmate2/jerky-blue/Coin4.png" className="jerky-coin-left-top" alt="Jerkmate" />
              <img src="/img/jerkmate2/jerky-blue/Coin5.png" className="jerky-coin-left-center" alt="Jerkmate" />
            </div>
            <div className="jerky-coins-right">
              <img src="/img/jerkmate2/jerky-blue/Coin3.png" className="jerky-coin-right-top" alt="Jerkmate" />
              <img src="/img/jerkmate2/jerky-blue/Coin2.png" className="jerky-coin-right-center" alt="Jerkmate" />
              <img src="/img/jerkmate2/jerky-blue/Coin1.png" className="jerky-coin-right-bottom" alt="Jerkmate" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  affId: state.profile.data.affInfos.id,
}))(JerkmateReferral);

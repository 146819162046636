import { Link } from 'react-router';

const CompleteIdentityComponent = () => (
  <div className="complete-identity-component">
    <h2>Before you can complete your Billing Information, please make sure you have finished the Identity validation process.</h2>
    <Link className="btn" onlyActiveOnIndex={false} to="/profile/identity">
      Check your status
    </Link>
  </div>
);

export default CompleteIdentityComponent;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';
class Textarea extends Component {
  constructor(props) {
    super(props);

    this.renderLabel = this.renderLabel.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      field: { dirty, error, touched, value },
    } = this.props;

    return (
      !!nextProps.field &&
      (dirty !== nextProps.field.dirty ||
        error !== nextProps.field.error ||
        touched !== nextProps.field.touched ||
        value !== nextProps.field.value)
    );
  }

  renderLabel() {
    const { field, id, label, optional, preLabel } = this.props;

    const labelClasses = [!!field && field.value ? 'active' : undefined, preLabel ? 'pre-label' : undefined];

    return (
      <label className={labelClasses.join(' ')} htmlFor={id}>
        <FormattedMessage {...label} className="textarea-label" />
        {optional ? <FormattedMessage {...messages.genericTextOptionalInput} /> : null}
      </label>
    );
  }

  render() {
    const { id, autoFocus, tabIndex, disabled, className, label, field, placeholder, maxLength, preLabel } = this.props;

    const fieldClasses = ['input-field', className, !!field && field.touched && field.error ? 'error' : undefined];

    return (
      <div className={fieldClasses.join(' ')}>
        {label && preLabel ? this.renderLabel() : null}
        <textarea
          autoFocus={autoFocus || false}
          // eslint-disable-next-line react/no-unknown-property
          checked={field.checked}
          className="materialize-textarea"
          disabled={disabled || false}
          id={id}
          maxLength={maxLength}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          onDragStart={field.onDragStart}
          onDrop={field.onDrop}
          onFocus={field.onFocus}
          placeholder={placeholder}
          tabIndex={tabIndex}
          value={field.value}
        />
        {label && !preLabel ? this.renderLabel() : null}
        {field && (field.dirty || field.touched) && field.error ? <div className="field-error">{field.error}</div> : null}
      </div>
    );
  }
}

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  label: PropTypes.object,
  maxLength: PropTypes.number,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  preLabel: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default injectIntl(Textarea);

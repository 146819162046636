export const LoadingRing = () => (
  <div className="loading-ring-container">
    <div className="loading-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

export const requestPayoutTotal = () => ({
  type: ACTIONS.REQUEST_PAYOUT_TOTAL,
});

export const receivePayoutTotal = (data) => ({
  type: ACTIONS.RECEIVE_PAYOUT_TOTAL,
  data,
});

export const failedToReceivePayoutTotal = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_PAYOUT_TOTAL,
});

export const fetchPayoutTotal = () => (dispatch) => {
  dispatch(requestPayoutTotal());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics/payoutTotal`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePayoutTotal(res.body[0]));
          fulfill();
        } else {
          dispatch(failedToReceivePayoutTotal());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

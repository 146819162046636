import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EmailTimeReact from '../../../components/reusables/svg/EmailTime.react';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

import messages from '../../../i18n/base-en.js';

const ConfirmEmail = (props) => {
  const { email, skinConfigurations } = props;
  const site = new SkinConfigurationsUtils(skinConfigurations).getPropertyValue('Global', 'Site', 'niceUrl') || 'CrakRevenue.com';

  return (
    <div className="simplified-confirm-email" id="simplified-confirm-email">
      <EmailTimeReact />
      <div className="row">
        <h1 className="simple-signup__confirm-title">
          <FormattedMessage {...messages.confirmEmailTitle} />
        </h1>
      </div>
      <div className="simple-signup__confirm-email-text">
        <div className="row">
          <p>
            <FormattedMessage {...messages.confirmEmailThankYouForRegistering} values={{ site }} />
          </p>
          <p>
            <FormattedMessage {...messages.confirmEmailOwnerOf} />
            <span className="simple-signup__confirmEmail"> {email} </span>
            <FormattedMessage {...messages.confirmEmailSentConfirmation} />
          </p>
        </div>
      </div>
    </div>
  );
};

ConfirmEmail.propTypes = {
  email: PropTypes.string.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default ConfirmEmail;

import { arrayShuffle, transformTableValue } from '../utils/helpers';

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  currentPeriodPayouts: [],
  currentPeriodPayoutsIsFetching: false,
  lastPeriodPayouts: [],
  lastPeriodPayoutsIsFetching: false,
  dailyStatistics: [],
  dailyStatisticsIsFetching: false,
  dailyStatisticsTotals: {},
  featuredOffers: [],
  featuredOffersIsFetching: false,
  QOTWIsFetching: false,
  QOTWSurveys: [],
  QOTWVisiblesQuestions: [],
  QOTWAnswersData: [],
};

export const home = (state = initialState, action = {}) => {
  let featuredOffers = {};
  const dailyStatsTotals = {
    Stat: {},
  };

  switch (action.type) {
    case ACTIONS.REQUEST_CURRENT_PERIOD_PAYOUTS:
      return {
        ...state,
        currentPeriodPayoutsIsFetching: true,
      };

    case ACTIONS.RECEIVE_CURRENT_PERIOD_PAYOUTS:
      return {
        ...state,
        currentPeriodPayouts: {
          data: action.data,
          totals: action.totals,
        },
        currentPeriodPayoutsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_CURRENT_PERIOD_PAYOUTS:
      return {
        ...state,
        currentPeriodPayoutsIsFetching: false,
      };

    case ACTIONS.REQUEST_LAST_PERIOD_PAYOUTS:
      return {
        ...state,
        lastPeriodPayoutsIsFetching: true,
      };

    case ACTIONS.RECEIVE_LAST_PERIOD_PAYOUTS:
      return {
        ...state,
        lastPeriodPayouts: { data: action.data, totals: action.totals },
        lastPeriodPayoutsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_LAST_PERIOD_PAYOUTS:
      return {
        ...state,
        lastPeriodPayoutsIsFetching: false,
      };

    case ACTIONS.REQUEST_DAILY_STATISTICS:
      return {
        ...state,
        dailyStatisticsIsFetching: true,
      };

    case ACTIONS.RECEIVE_DAILY_STATISTICS:
      Object.keys(action.totals.Stat).forEach((key) => {
        dailyStatsTotals.Stat[key] = transformTableValue(`Stat.${key}`, action.totals.Stat[key]);
      });

      return {
        ...state,
        dailyStatistics: action.data,
        dailyStatisticsTotals: dailyStatsTotals,
        dailyStatisticsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_DAILY_STATISTICS:
      return {
        ...state,
        dailyStatisticsIsFetching: false,
      };

    case ACTIONS.REQUEST_FEATURED_OFFERS:
      return {
        ...state,
        featuredOffersIsFetching: true,
      };

    case ACTIONS.RECEIVE_FEATURED_OFFERS:
      featuredOffers = action.data;
      arrayShuffle(featuredOffers);
      featuredOffers = featuredOffers.slice(0, 8);

      return {
        ...state,
        featuredOffers,
        featuredOffersIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_FEATURED_OFFERS:
      return {
        ...state,
        featuredOffersIsFetching: false,
      };

    default:
      return state;
  }
};

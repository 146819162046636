import { getNewToken } from '../../../utils/helpers';

const iconToken = getNewToken();
const EmailInstructionsIcon = () => (
  <svg
    height="108px"
    viewBox="0 0 143 108"
    width="143px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Email Instructions Icon</title>
    <defs>
      <polygon
        id={`polygon-${iconToken}`}
        points="43 0.205834211 43 42.9998868 0.206032237 42.9998868 0.206032237 0.205834211 43 0.205834211"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-294.000000, -236.000000)">
        <g transform="translate(216.000000, 194.000000)">
          <g transform="translate(78.000000, 42.000000)">
            <path
              d="M119.891374,2 L61.0002794,39.4907744 L2.10918457,2 C0.817225146,3.19307286 0,4.89530217 0,6.78349401 L0,80.4820693 C0,82.3795033 0.825053509,84.0887342 2.12791672,85.2823672 L60.9999999,47.8106372 L121.498425,87 L122,87 L122,6.78349401 C122.000559,4.89530217 121.183334,3.19307286 119.891374,2"
              fill="#198C19"
            />
            <path
              d="M6.36523882,87 L121,87 L80.060069,44 L41.3183655,44 L2,85.2971837 C3.15499615,86.3501973 4.6857005,87 6.36523882,87"
              fill="#02AD02"
            />
            <g fill="#12CC12" transform="translate(2.000000, 0.000000)">
              <path d="M4.55024218,0.187150228 C2.85629905,0.187150228 1.31307156,0.845894521 0.152085782,1.9121411 L54.5612706,58.7867027 C57.0306024,61.3676434 61.0708441,61.3676434 63.5401758,58.7867027 L117.94964,1.91186256 C116.788655,0.845615982 115.245427,0.186871689 113.551484,0.186871689 L4.55024218,0.186871689 L4.55024218,0.187150228 Z" />
            </g>
            <g transform="translate(100.000000, 65.000000)">
              <mask fill="white" id={`mask-${iconToken}`}>
                <use xlinkHref={`#polygon-${iconToken}`} />
              </mask>
              <path
                d="M21.6030586,0.205834211 C33.4201375,0.205834211 43.0000849,9.78578158 43.0000849,21.6031434 C43.0000849,33.4202224 33.4201375,42.9998868 21.6030586,42.9998868 C9.78597961,42.9998868 0.206032237,33.4202224 0.206032237,21.6031434 C0.206032237,9.78578158 9.78597961,0.205834211 21.6030586,0.205834211"
                fill="#262626"
                mask={`url(#mask-${iconToken})`}
              />
            </g>
            <path
              d="M121.599911,97 C120.274197,97 119.199821,95.9155253 119.199821,94.5770071 L119.199821,83.9391048 C118.355885,84.2395148 117.37858,84.0493292 116.702661,83.3671596 C115.76578,82.4209513 115.76578,80.8869722 116.702661,79.9407639 L119.902689,76.7098291 C120.589332,76.0168314 121.621635,75.8094319 122.518368,76.184528 C123.415377,76.5599017 124,77.4433626 124,78.4234434 L124,94.5770071 C123.999725,95.9152477 122.92535,97 121.599911,97"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EmailInstructionsIcon;

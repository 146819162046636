import genericTexts from './fr/genericTexts';
import loginPage from './fr/loginPage';
import mainNavigation from './fr/mainNavigation';
import profilePage from './fr/profilePage';
import offerPage from './fr/offerPage';
import passwordResetPage from './fr/passwordResetPage';
import homePage from './fr/homePage';
import surveyGeneratorPage from './fr/surveyGeneratorPage';
import signupQuestionsModal from './fr/signupQuestionsModal';
import smartLinkPage from './fr/smartLinkPage';

import countries from './fr/list-options/countries';
import provinces from './fr/list-options/provinces';
import states from './fr/list-options/states';
import timezones from './fr/list-options/timezones';

export default {
  ...genericTexts,
  ...loginPage,
  ...mainNavigation,
  ...profilePage,
  ...offerPage,
  ...passwordResetPage,
  ...countries,
  ...provinces,
  ...states,
  ...timezones,
  ...homePage,
  ...surveyGeneratorPage,
  ...signupQuestionsModal,
  ...smartLinkPage,
};

import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import _ from 'lodash';

import { TypeSettings, TypeSettingsModels, TypeSettingsOffers, TypeSettingsType } from './type-settings';

import { fetchOffer } from '../offer/Offer-Actions/Offer-Actions';

import { CodesTypes } from './LiveCamWidgetConsts';
import { Gtm } from '../../utils/gtm';

// TODO: remove jQuery from project
import $ from 'jquery';
import { setExpireDaysLivecam } from '../../actions/live-cam-widget-actions';

const LiveCamWidgetTypeSettings = ({
  animated,
  campaigns,
  fields,
  fields: { categories, category, gender, offers, widgetType, canHide, language, spokenLanguages, expireDays },
  dispatch,
  onWidgetTypeChange,
  onSelectOffers,
}) => {
  const [availableGenders, setAvailableGenders] = useState({
    f: false,
    m: false,
    t: false,
    c: false,
  });
  const [campaignsNeedApproval, setCampaignsNeedApproval] = useState('');
  const [errorsMax, setErrorsMax] = useState({
    categories: false,
  });
  const [offersApprovalPending, setOffersApprovalPending] = useState([]);
  const [offersNeedApproval, setOffersNeedApproval] = useState([]);
  const [availableExclusiveWidgetTypes, setAvailableExclusiveWidgetTypes] = useState([]);

  const clear = { categories: false, offers: false, spokenLanguages: false };
  const getSelectedCampaignsFromProps = () =>
    campaigns && fields.offers.length ? campaigns.filter((camp) => fields.offers.some((offer) => offer.value === camp.name)) : [];
  const handleGenders = () => {
    const selectedCampaigns = getSelectedCampaignsFromProps();

    const selectedCampaignsGenders = selectedCampaigns.length ? selectedCampaigns.map((camp) => camp.genders).flat() : [];
    const genderIsAvailable = (currentGender) =>
      selectedCampaigns.length > 0 &&
      selectedCampaignsGenders.filter((campaignGender) => campaignGender === currentGender).length === selectedCampaigns.length;
    const newAvailableGenders = {
      f: genderIsAvailable('f'),
      m: genderIsAvailable('m'),
      t: genderIsAvailable('t'),
      c: genderIsAvailable('c'),
    };

    const selectedGenders = gender.value;
    const newSelectedGenders = selectedGenders.filter((genderKey) => newAvailableGenders[genderKey]);
    if (selectedGenders.join(',') !== newSelectedGenders.join(',')) {
      dispatch(change('typeSettingsLC', 'gender', [...newSelectedGenders, 'f']));
    }
    if (!_.isEqual(availableGenders, newAvailableGenders)) {
      setAvailableGenders(newAvailableGenders);
    }
  };

  const handleWidgetTypes = () => {
    const selectedCampaigns = getSelectedCampaignsFromProps();

    const selectedWidgetType = CodesTypes.find((type) => type.id === widgetType.value);
    const selectedCampaignsExclusiveWidgetTypes = selectedCampaigns.length
      ? selectedCampaigns.map((camp) => camp.exclusive_widget_types).flat()
      : [];
    const availableExclusiveWidgetTypes = selectedCampaignsExclusiveWidgetTypes.filter((type) =>
      selectedCampaigns.every((camp) => camp.exclusive_widget_types.includes(type))
    );

    setAvailableExclusiveWidgetTypes(availableExclusiveWidgetTypes);

    if (selectedWidgetType.isOfferExclusive && !availableExclusiveWidgetTypes.includes(widgetType.value)) {
      dispatch(change('typeSettingsLC', 'widgetType', 'script'));
    }

    onSelectOffers(selectedCampaigns);
  };

  const clearAllSelected = (field) => {
    // tant que clearCategory est à true, la méthode sera appelée afin de vider complètement le tableau de catégories.
    clear[field] = fields[field].length > 1;
    fields[field].removeField(fields[field].length - 1);
  };

  const removeSelected = (field, index, max = null) => {
    fields[field].removeField(index);
    if (max && fields[field].length - 1 < max) {
      errorsMax[field] = false;
      setErrorsMax(errorsMax);
    }
  };

  const valuePresentInArrayField = (arrayField, value) => arrayField.some((field) => field.value === value);

  const validateAccessToOffers = (value) => {
    const campaignSelected = campaigns.find((camp) => camp.name === value);
    const offersNeedApproval = [];
    const offersApprovalPending = [];

    let accessToAll = true;
    let campaignsNeedApproval = '';

    if (campaignSelected) {
      campaignSelected.creatives.forEach((offer) => {
        if (offer.require_approval) {
          accessToAll = false;
          if (offer.approval_status === 'pending') {
            offersApprovalPending.push(offer);
          } else {
            offersNeedApproval.push(offer);
            dispatch(fetchOffer(offer.offer_id, false));
          }
          campaignsNeedApproval = campaignSelected.name;
        }
      });
      setCampaignsNeedApproval(campaignsNeedApproval);
      setOffersApprovalPending(offersApprovalPending);
      setOffersNeedApproval(offersNeedApproval);
    }
    return accessToAll;
  };

  const onMultiSelectChange = (e, field, max = null) => {
    const value = e;

    if (max && fields[field].length >= max) {
      errorsMax[field] = true;
      setErrorsMax(errorsMax);
      return false;
    }

    // ne devrait pas se produire, mais évite qu'une valeur vide soit ajouté à la liste de catégories sélectionnées et qu'on la mette en double
    if (value !== '' && !valuePresentInArrayField(fields[field], value)) {
      if (field === 'offers') {
        if (validateAccessToOffers(value)) {
          fields.offers.addField(value);
        }

        Gtm.event('live cam widget', 'Click', `offers/payout dropdown - ${value}`);
      } else {
        fields[field].addField(value);
        Gtm.event('live cam widget', 'Click', `${field} dropdown - ${value}`);
      }
    }
  };

  const isWidgetChatHead = widgetType.value.includes('im_');
  const isWidgetChatHeadOrIMAds = widgetType.value.includes('im');
  const canHideChatHead = canHide?.value?.toString() == true;

  useEffect(() => {
    if (widgetType !== fields.widgetType) {
      dispatch(change('typeSettingsLC', 'canHide', ''));
    }
  }, [widgetType, fields.widgetType, dispatch]);

  useEffect(() => {
    handleGenders();
    handleWidgetTypes();
  }, [campaigns, offers]);

  useEffect(() => {
    // tant que clearCategory est true, la méthode est appelée pour vider complètement le tableau
    Object.keys(clear).forEach((field) => {
      if (clear[field]) {
        errorsMax[field] = false;
        setErrorsMax(errorsMax);
        clearAllSelected(field);
      }
    });
  });

  useEffect(() => {
    if (campaigns.length > 1) {
      handleGenders();
      handleWidgetTypes();
    }
  }, [campaigns]);

  useEffect(() => {
    dispatch(setExpireDaysLivecam(fields.expireDays.value));
  }, [expireDays]);

  return (
    <div id="type-settings">
      <div className="settings-groups">
        <TypeSettingsOffers
          animated={animated}
          campaigns={campaigns}
          campaignsNeedApproval={campaignsNeedApproval}
          campaignChanged={(e) => onMultiSelectChange(e, 'offers')}
          clearAllSelected={clearAllSelected}
          dispatch={dispatch}
          fields={{ offers }}
          offersApprovalPending={offersApprovalPending}
          offersNeedApproval={offersNeedApproval}
          removeSelected={removeSelected}
        />

        <TypeSettingsType
          availableExclusiveWidgetTypes={availableExclusiveWidgetTypes}
          fields={{ widgetType, canHide }}
          onWidgetTypeChange={onWidgetTypeChange}
        />
        <TypeSettingsModels
          availableGenders={availableGenders}
          clearAllSelected={clearAllSelected}
          languageChanged={(e) => onMultiSelectChange(e, 'spokenLanguages')}
          catygoryChanged={(e) => onMultiSelectChange(e, 'categories')}
          fields={{ categories, category, gender, spokenLanguages }}
          maxReached={errorsMax.categories}
          removeSelected={removeSelected}
        />
        {isWidgetChatHeadOrIMAds ? (
          <TypeSettings
            expireDays={expireDays}
            isWidgetChatHead={isWidgetChatHead}
            canHideChatHead={canHideChatHead}
            language={language}
          />
        ) : null}
      </div>
    </div>
  );
};

LiveCamWidgetTypeSettings.propTypes = {
  animated: PropTypes.bool,
  campaigns: PropTypes.array,
  dispatch: PropTypes.func,
  fields: PropTypes.shape({
    categories: PropTypes.array,
    category: PropTypes.object,
    gender: PropTypes.object,
    offers: PropTypes.array,
    widgetType: PropTypes.object,
    canHide: PropTypes.object,
    spokenLanguages: PropTypes.array,
  }),
  onWidgetTypeChange: PropTypes.func,
};

export default connect((state) => ({
  offerSelected: state.offer.offer,
}))(
  reduxForm({
    form: 'typeSettingsLC',
    fields: [
      'categories[]',
      'category',
      'gender',
      'offer',
      'offers[]',
      'widgetType',
      'canHide',
      'language',
      'spokenLanguages[]',
      'expireDays',
    ],
    initialValues: {
      category: 'custom',
      gender: ['f'],
      widgetType: 'script',
      canHide: '',
      language: '',
      expireDays: 60,
    },
    destroyOnUnmount: false,
  })(LiveCamWidgetTypeSettings)
);

import ReactTooltip from 'react-tooltip';

// https://www.npmjs.com/package/react-toolti
const Tooltip = ({
  className,
  closeDelay,
  effect = 'solid',
  event,
  delay,
  html,
  multiline = false,
  type = 'dark',
  position = 'top',
  tooltip,
  children,
  backgroundColor,
  textColor,
  borderColor,
  button,
  border,
  id,
  li,
  ...props
}) => {
  if (button) {
    return (
      <>
        <button className={className} data-for={id} data-html={html} data-tip={tooltip} onClick={props.onClick} type="button">
          {children}
        </button>
        <ReactTooltip
          backgroundColor={backgroundColor}
          border={border}
          borderColor={borderColor}
          delayHide={closeDelay}
          delayShow={delay}
          effect={effect}
          event={event}
          html={html}
          id={id}
          multiline={multiline}
          place={position}
          textColor={textColor}
          type={type}
          {...props}
        />
      </>
    );
  } else if (li) {
    return (
      <>
        <li className={className} data-for={id} data-html={html} data-tip={tooltip}>
          {children}
        </li>
        <ReactTooltip
          backgroundColor={backgroundColor}
          border={border}
          borderColor={borderColor}
          className={!props.ignoreClasses ? className : ''}
          delayHide={closeDelay}
          delayShow={delay}
          effect={effect}
          event={event}
          html={html}
          id={id}
          multiline={multiline}
          place={position}
          textColor={textColor}
          type={type}
          {...props}
        />
      </>
    );
  } else {
    return (
      <>
        <span data-for={id} data-html={html} data-tip={tooltip}>
          {children}
        </span>
        <ReactTooltip
          backgroundColor={backgroundColor}
          border={border}
          borderColor={borderColor}
          className={className}
          delayHide={closeDelay}
          delayShow={delay}
          effect={effect}
          event={event}
          html={html}
          id={id}
          multiline={multiline}
          place={position}
          textColor={textColor}
          type={type}
          {...props}
        />
      </>
    );
  }
};

export default Tooltip;

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestAlerts = () => ({ type: ACTIONS.REQUEST_ALERTS });
const receiveAlerts = (data) => ({
  type: ACTIONS.RECEIVE_ALERTS,
  alerts: data.alerts,
  alertsCount: data.count,
  pageCount: data.pageCount,
  page: parseInt(data.page, 10),
});
const failedToReceiveAlerts = () => ({ type: ACTIONS.FAILED_TO_RECEIVE_ALERTS });
export const fetchAlerts = (search) => (dispatch) => {
  dispatch(requestAlerts());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/user/alerts`)
      .withCredentials()
      .query({ search: JSON.stringify(search) })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAlerts(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAlerts);
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestNewAlerts = () => ({ type: ACTIONS.REQUEST_NEW_ALERTS });
const receiveNewAlerts = (data) => ({
  type: ACTIONS.RECEIVE_NEW_ALERTS,
  newAlerts: data.alerts,
});
const failedToReceiveNewAlerts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_NEW_ALERTS,
});
export const fetchNewAlerts = (search) => (dispatch) => {
  dispatch(requestNewAlerts());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/user/alerts`)
      .withCredentials()
      .query({ search: JSON.stringify(search) })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveNewAlerts(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveNewAlerts);
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestDismissAlerts = () => ({ type: ACTIONS.REQUEST_DISMISS_ALERTS });
const receiveDismissAlerts = () => ({ type: ACTIONS.RECEIVE_DISMISS_ALERTS });
const failedToReceiveDismissAlerts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_DISMISS_ALERTS,
});
export const fetchDismissAlerts = (alertsIds) => (dispatch) => {
  dispatch(requestDismissAlerts());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/user/dismiss-alerts`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({ alertsIds })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveDismissAlerts());
          fulfill();
        } else {
          dispatch(failedToReceiveDismissAlerts);
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const changeAlertsListPage = (page) => ({
  type: ACTIONS.CHANGE_ALERTS_LIST_PAGE,
  page,
});
export const changeAlertsRowsPerPage = (limit, page) => ({
  type: ACTIONS.CHANGE_ALERTS_ROWS_PER_PAGE,
  limit,
  page,
});

const AllIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.3335 9.77149C3.3335 9.45508 3.56862 9.19358 3.87369 9.1522L3.9585 9.14649L14.9452 9.14699L10.976 5.19405C10.7314 4.9505 10.7306 4.55477 10.9741 4.31017C11.1955 4.0878 11.5427 4.06688 11.7878 4.2479L11.858 4.30827L16.8997 9.32827C16.9319 9.36037 16.9599 9.39511 16.9837 9.43181C16.9904 9.44285 16.9972 9.45415 17.0037 9.46568C17.0097 9.47556 17.015 9.48581 17.0201 9.49619C17.0272 9.51128 17.0341 9.52687 17.0403 9.54278C17.0454 9.55504 17.0497 9.56697 17.0535 9.579C17.0581 9.59391 17.0626 9.6098 17.0665 9.62593C17.0693 9.6372 17.0716 9.64804 17.0736 9.65892C17.0763 9.67511 17.0787 9.69187 17.0804 9.70883C17.0819 9.72176 17.0827 9.73457 17.0832 9.7474C17.0833 9.75517 17.0835 9.76331 17.0835 9.77149L17.0832 9.7957C17.0827 9.80797 17.0819 9.82023 17.0807 9.83245L17.0835 9.77149C17.0835 9.81093 17.0798 9.84952 17.0729 9.88693C17.0712 9.89587 17.0693 9.90504 17.0672 9.91418C17.0627 9.93299 17.0576 9.95111 17.0517 9.96887C17.0488 9.9777 17.0454 9.98713 17.0418 9.99649C17.0345 10.0153 17.0266 10.0332 17.0178 10.0507C17.0138 10.0589 17.0092 10.0675 17.0045 10.076C16.9967 10.0899 16.9886 10.1031 16.9801 10.1159C16.9741 10.125 16.9675 10.1345 16.9605 10.1438L16.9551 10.151C16.9383 10.173 16.92 10.1939 16.9004 10.2134L16.8997 10.214L11.858 15.2348C11.6134 15.4784 11.2177 15.4776 10.9741 15.233C10.7527 15.0107 10.7333 14.6634 10.9153 14.4191L10.976 14.3491L14.9435 10.397L3.9585 10.3965C3.61332 10.3965 3.3335 10.1167 3.3335 9.77149Z"
      fill="white"
    />
  </svg>
);

export default AllIcon;

export const getCTALink = (tool) => {
  switch (tool.modal_type) {
    case '1':
      return new URL(tool.cta_link).pathname;
    case '2':
      return '/offers';
    default:
      return '';
  }
};

export const getCTAText = (tool) => {
  switch (tool.modal_type) {
    case '1':
      return tool.cta_text;
    case '2':
      return 'See all offers';
    case '3':
      return 'Submit a request for this tool';
    default:
      return '';
  }
};

export const getClassName = (tool) => {
  switch (tool.modal_type) {
    case '1':
      return 'promo-tool to-tool-page';
    case '2':
      return 'promo-tool to-offer';
    case '3':
      return 'promo-tool to-manager';
    default:
      return '';
  }
};

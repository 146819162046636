import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { InputText } from '../../../../components/reusables';

import messages from '../../../../i18n/base-en.js';
import { initialFetching } from '../../../../utils/initialFetching';

class NameSection extends Component {
  constructor(props) {
    super(props);
    initialFetching(props.dispatch);
  }

  shouldComponentUpdate(nextProps) {
    const { values, fields } = this.props;

    return (
      values.first_name !== nextProps.values.first_name ||
      fields.first_name.error !== nextProps.fields.first_name.error ||
      fields.first_name.touched !== nextProps.fields.first_name.touched ||
      values.last_name !== nextProps.values.last_name ||
      fields.last_name.error !== nextProps.fields.last_name.error ||
      fields.last_name.touched !== nextProps.fields.last_name.touched
    );
  }

  render() {
    const { fields } = this.props;

    return (
      <div className="name_section">
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.first_name}
            id="first_name"
            label={messages.genericTextFirstName}
            maxLength={50}
            tabIndex={60}
            type="text"
          />
        </div>
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.last_name}
            id="last_name"
            label={messages.genericTextLastName}
            maxLength={50}
            tabIndex={70}
            type="text"
          />
        </div>
      </div>
    );
  }
}

NameSection.propTypes = {
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(NameSection);

import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { getBlackLogoFromSkinName } from '../../utils/logoHelper';

const Offline = ({ children, link, simplified, skinConfigurations, isLoginPage }) => {
  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const SKIN_NAME = SKIN.getSkinName().toLowerCase();
  return (
    <div id="offline">
      <div className="container-fluid">
        <div className="row offline-header">
          <div className="col-md-4">
            <a
              className="home-link logo"
              href={SKIN.getPropertyValue('Global', 'OfficialWebsite', 'url') || 'http://crakrevenue.com'}
            >
              <img
                alt={SKIN_NAME}
                height={SKIN.getPropertyValue('Offline', 'img.logo', 'height') || '35px'}
                src={getBlackLogoFromSkinName(SKIN_NAME)}
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className={simplified ? 'outer-container-simplified' : 'outer-login'}>
            <div className={`offline-container${simplified ? '-simplified' : ''}`}>
              {!simplified && (
                <div className="back-to-website">
                  {link.isExternal ? (
                    <a href={link.url}>
                      <i className="material-icons">keyboard_arrow_left</i>
                      {link.text}
                    </a>
                  ) : (
                    <Link onlyActiveOnIndex={false} to={link.url}>
                      <i className="material-icons">keyboard_arrow_left</i>
                      {link.text}
                    </Link>
                  )}
                </div>
              )}
              {!isLoginPage ? <div className="shadow-container">{children}</div> : children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Offline.propTypes = {
  children: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  simplified: PropTypes.bool,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  skinConfigurations: state.skinConfigurations.data,
}))(Offline);

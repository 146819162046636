import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';

const getLabels = (drillDownValues, statisticsDateStart, statisticsDateEnd) => [
  `From: ${moment(statisticsDateStart).format('YYYY-MM-DD')} to ${moment(statisticsDateEnd).format('YYYY-MM-DD')}`,
  ...Object.keys(drillDownValues).map((key) => drillDownValues[key].value),
];

const StatisticsDrillDownBreadcrumb = ({ drillDownValues, handleSetDrillDownLevel, statisticsDateEnd, statisticsDateStart }) => (
  <div className="drilldown-breadcrumb">
    {getLabels(drillDownValues, statisticsDateStart, statisticsDateEnd).map((drillValue, index) => (
      <span key={`${index}-${drillValue}`}>
        <span className="link" data-drill={index} onClick={handleSetDrillDownLevel}>
          {drillValue}
        </span>
      </span>
    ))}
  </div>
);

StatisticsDrillDownBreadcrumb.propTypes = {
  drillDownValues: PropTypes.object.isRequired,
  handleSetDrillDownLevel: PropTypes.func.isRequired,
  statisticsDateEnd: PropTypes.any,
  statisticsDateStart: PropTypes.any,
};

export default injectIntl(StatisticsDrillDownBreadcrumb);

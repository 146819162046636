const Arrow = ({ className }) => (
  <svg
    className={`${className} arrow-icon`}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 8.5L12 15.5L5 8.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
);

export default Arrow;

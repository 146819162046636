export default {
  notificationsSubject: {
    id: 'notifications.subject',
    description: 'Subject',
    defaultMessage: 'Subject',
  },
  notificationsMessage: {
    id: 'notifications.message',
    description: 'Message',
    defaultMessage: 'Message',
  },
  notificationsSentAt: {
    id: 'notifications.sendAt',
    description: 'Sent At',
    defaultMessage: 'Sent At',
  },
  notificationsSeeAll: {
    id: 'notifications.seeAll',
    description: 'See all notifications',
    defaultMessage: 'See all notifications',
  },
  notificationsNoNew: {
    id: 'notifications.noNew',
    description: 'No new notifications',
    defaultMessage: 'No new notifications',
  },
  notificationBannerCompleteProfileOnly: {
    id: 'notifications.BannerCompleteProfileOnly',
    description:
      'Welcome to the family! In order to do business together, we need you to complete your profile as thoroughly as possible.',
    defaultMessage:
      'Welcome to the family! In order to do business together, we need you to complete your profile as thoroughly as possible.',
  },
  notificationBannerCompletePaymentOnly: {
    id: 'notifications.BannerCompletePaymentOnly',
    description:
      "Our system has detected that you haven't filled in your Payment Details. In order to get paid, you must complete your Billing details.",
    defaultMessage:
      "Our system has detected that you haven't filled in your Payment Details. In order to get paid, you must complete your Billing details.",
  },
  notificationBannerCompleteIdentityOnly: {
    id: 'notifications.BannerCompleteIdentitytOnly',
    description:
      "It seems you haven't verified your identity yet. Please complete the process to avoid delaying your next payment.",
    defaultMessage:
      "It seems you haven't verified your identity yet. Please complete the process to avoid delaying your next payment.",
  },
  notificationBannerCompleteBoth: {
    id: 'notifications.BannerCompleteBoth',
    description:
      "Our system has detected that you haven't filled in your User and Payment Details. In order to get paid, you must complete your Billing details.",
    defaultMessage:
      "Our system has detected that you haven't filled in your User and Payment Details. In order to get paid, you must complete your Billing details.",
  },
  notificationPaymentHistoryWidget: {
    id: 'notifications.PaymentHistoryWidget',
    description:
      "Our system has detected that you haven't filled in your User Details. In order to get paid, you must complete your profile.",
    defaultMessage:
      "Our system has detected that you haven't filled in your User Details. In order to get paid, you must complete your profile.",
  },
  notificationPaymentHistoryWidgetPaymentOnly: {
    id: 'notifications.PaymentHistoryWidgetPaymentOnly',
    description:
      "Our system has detected that you haven't filled in your Payment Details. In order to get paid, you must complete your Billing details.",
    defaultMessage:
      "Our system has detected that you haven't filled in your Payment Details. In order to get paid, you must complete your Billing details.",
  },
  notificationPaymentHistoryWidgetIdentityToComplete: {
    id: 'notifications.PaymentHistoryWidgetIdentityToComplete',
    description: 'Before you can cash out your balance, you need to complete the identity validation process.',
    defaultMessage: 'Before you can cash out your balance, you need to complete the identity validation process.',
  },
};

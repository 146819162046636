export const HomePromotionArrow = ({ onClick, right, totalSlides }) => {

  if (totalSlides < 2) {
    return null
  }

  return (
    <div className={`promotion-slide-arrow ${right ? 'right' : 'left'}`} onClick={onClick}>
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrow-Btn">
          <rect width="45" height="45" transform="matrix(-1 0 0 -1 45 45)" fill="#252525" fill-opacity="0.65" />
          <path id="Vector" d="M18.2194 38.4356L32.7938 23.4843C33.0497 23.2217 33.193 22.8695 33.193 22.5028C33.193 22.1361 33.0497 21.7838 32.7938 21.5212L18.2194 6.56435C18.1001 6.44188 17.9576 6.34454 17.8001 6.27808C17.6426 6.21162 17.4734 6.17738 17.3025 6.17738C17.1316 6.17738 16.9624 6.21162 16.8049 6.27808C16.6474 6.34454 16.5049 6.44188 16.3856 6.56435C16.1403 6.81548 16.0029 7.15264 16.0029 7.50372C16.0029 7.8548 16.1403 8.19196 16.3856 8.4431L30.0909 22.5028L16.3856 36.5597C16.1411 36.8107 16.0043 37.1472 16.0043 37.4976C16.0043 37.848 16.1411 38.1846 16.3856 38.4356C16.5049 38.5581 16.6474 38.6554 16.8049 38.7219C16.9624 38.7883 17.1316 38.8226 17.3025 38.8226C17.4734 38.8226 17.6426 38.7883 17.8001 38.7219C17.9576 38.6554 18.1001 38.5581 18.2194 38.4356Z" fill="#00BD00" />
        </g>
      </svg>
    </div>
  );
};

const CircleAdd = () => (
  <svg height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
    <g data-name="vuesax/bold/add-circle" id="vuesax_bold_add-circle" transform="translate(-108 -252)">
      <g id="add-circle">
        <path
          d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm4,10.75H10.75V14a.75.75,0,0,1-1.5,0V10.75H6a.75.75,0,0,1,0-1.5H9.25V6a.75.75,0,0,1,1.5,0V9.25H14a.75.75,0,0,1,0,1.5Z"
          fill="#292d32"
          id="Vector"
          transform="translate(110 254)"
        />
        <path
          d="M0,0H24V24H0Z"
          data-name="Vector"
          fill="none"
          id="Vector-2"
          opacity="0"
          transform="translate(132 276) rotate(180)"
        />
      </g>
    </g>
  </svg>
);

export default CircleAdd;

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import apiqlFactory from '../../../utils/apiqlFactory';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestOffers = (req) => ({ type: ACTIONS.REQUEST_OFFERS, request: req });
const receiveOffers = (payload) => ({ type: ACTIONS.RECEIVE_OFFERS, payload });
const failedToReceiveOffers = () => ({ type: ACTIONS.FAILED_TO_RECEIVE_OFFERS });
export const fetchOffers = (search) => (dispatch) =>
  new Promise((fulfill, reject) => {
    const offerRequest = request
      .post(`${API_URL}/apiql`)
      .query({
        query: apiqlFactory({ Offers: search }),
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffers(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveOffers());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestOffers(offerRequest));
  });

export const changeOffersListPage = (page) => ({
  type: ACTIONS.CHANGE_OFFERS_LIST_PAGE,
  page,
});
export const changeOffersLocationQuery = (query) => ({
  type: ACTIONS.CHANGE_OFFERS_LOCATIONS_QUERY,
  query,
});
export const changeOffersRowsPerPage = (limit, page) => ({
  type: ACTIONS.CHANGE_OFFERS_ROWS_PER_PAGE,
  limit,
  page,
});
export const changeOffersViewMode = (viewMode) => ({
  type: ACTIONS.CHANGE_OFFERS_VIEW_MODE,
  viewMode,
});
export const removeSearchAllFilters = () => ({
  type: ACTIONS.REMOVE_SEARCH_ALL_FILTERS,
});
export const removeSearchCategoriesTag = (value, filter) => ({
  type: ACTIONS.REMOVE_SEARCH_CATEGORIES_TAG,
  value,
  filter,
});
export const removeSearchFiltersTag = (value, filter) => ({
  type: ACTIONS.REMOVE_SEARCH_FILTERS_TAG,
  value,
  filter,
});
export const removeSearchOthersTag = (value, filter) => ({
  type: ACTIONS.REMOVE_SEARCH_OTHERS_TAG,
  value,
  filter,
});
export const removeSearchStringTag = (value) => ({
  type: ACTIONS.REMOVE_SEARCH_STRING_TAG,
  value,
});
export const setOffersSearchFilters = (search) => ({
  type: ACTIONS.SET_OFFERS_SEARCH_FILTERS,
  search,
});
export const dispatchsetOffersFromMenuClick = (verticals) => ({
  type: ACTIONS.SET_OFFERS_FROM_MENU_CLICK,
  verticals,
});

export const dispatchsetSeeAllOffersFromMenuClick = (isSeeAllClicked) => ({
  type: ACTIONS.SET_SEE_ALL_OFFERS_FROM_MENU_CLICK,
  isSeeAllClicked,
});

import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

const Switch = ({ className, dataCy = undefined, field, label, multiple, onChange, reverse, value }) => {
  const arrValue = value instanceof Array ? value : [value];

  let checked = false;
  if (multiple) {
    if (_.difference(arrValue, field.value).length === 0) {
      checked = true;
    }
  } else {
    checked = !!field.value;
  }
  if (reverse) {
    checked = !checked;
  }

  const toggle = () => {
    let val;
    if (multiple) {
      if (_.difference(arrValue, field.value).length === 0) {
        val = _.difference(field.value, arrValue);
      } else {
        val = [...(field.value || []), ...arrValue];
      }
    } else {
      val = _.isEqual(field.value, value) ? undefined : value;
    }

    field.onChange(val);
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className={`switch ${className || ''}`}>
      <label data-cy={dataCy}>
        <span className="lowercase">
          <FormattedMessage {...label} />
        </span>
        <input checked={checked} onChange={toggle} type="checkbox" />
        <span className="lever" />
      </label>
    </div>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  reverse: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

export default Switch;

import request from '../utils/Req';
import config from '../config';

const { API_URL } = config;

export const checkIsIpVpn = () =>
  new Promise((resolve, reject) =>
    request.get(`${API_URL}/is-proxy`).end((error, res) => {
      if (error) {
        resolve(false);
      } else {
        try {
          resolve(res.body.isProxy);
        } catch (e) {
          resolve(false);
        }
      }
    })
  );

import PropTypes from 'prop-types';

import messages from '../../../i18n/base-en.js';
import { FormattedMessage } from 'react-intl';

const StatisticsSlowFieldsWarning = ({ slowFields, fieldsMessages }) => {
  const formattedSlowFields = slowFields.map((field) => fieldsMessages[field].defaultMessage).join(', ');

  return (
    <div className="modal-warning">
      <img alt="Warning icon" src="/img/exclamation.svg" />
      <FormattedMessage {...messages.statisticsSlowFieldsWarning} values={{ fields: formattedSlowFields }} />
    </div>
  );
};

StatisticsSlowFieldsWarning.propTypes = {
  fieldsMessages: PropTypes.object,
  slowFields: PropTypes.array,
};

export default StatisticsSlowFieldsWarning;

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, FormattedHTMLMessage, FormattedNumber } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { LoadingBar, Pagination, Table } from '../../components/reusables';

class ReferralsTable extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsChange = this.handleRowsChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.setStateForTab = this.setStateForTab.bind(this);
    this.tableAffiliates = this.tableAffiliates.bind(this);
    this.tableCommissions = this.tableCommissions.bind(this);

    this.state = {
      currentPage: 1,
      limit: 25,
      pageCount: 1,
      sort: {},
      total: 0,
    };
  }

  componentDidMount() {
    const { activeTab, referrals } = this.props;

    this.setStateForTab(referrals, activeTab);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeTab, referrals } = this.props;

    if (activeTab !== nextProps.activeTab || !_.isEqual(referrals, nextProps.referrals)) {
      this.setStateForTab(nextProps.referrals, nextProps.activeTab);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { activeTab, referrals, referralsIsFetching } = this.props;
    const { currentPage, limit, pageCount, sort, total } = this.state;

    return (
      activeTab !== nextProps.activeTab ||
      currentPage !== nextState.currentPage ||
      limit !== nextState.limit ||
      pageCount !== nextState.pageCount ||
      sort !== nextState.sort ||
      total !== nextState.total ||
      referralsIsFetching !== nextProps.referralsIsFetching ||
      !_.isEqual(referrals, nextProps.referrals)
    );
  }

  setStateForTab(referrals, activeTab) {
    const { limit } = this.state;

    if (activeTab === 'affiliates') {
      const refAffiliates = {};

      referrals.forEach((referral) => {
        if (Object.keys(refAffiliates).indexOf(referral.ReferredAffiliate.company) === -1) {
          refAffiliates[referral.ReferredAffiliate.company] = referral.ReferredAffiliate;
        }
      });

      const totalLength = Object.keys(refAffiliates).length;

      this.setState({
        total: totalLength,
        pageCount: Math.ceil(totalLength / limit),
      });
    } else {
      const referralsConcat = {};
      const referralsArray = [];
      referrals
        .filter((referral) => parseFloat(referral.Stat.amount) > 0)
        .forEach((ref) => {
          if (Object.keys(referralsConcat).indexOf(ref.ReferredAffiliate.company) === -1) {
            referralsConcat[ref.ReferredAffiliate.company] = ref;
          } else {
            referralsConcat[ref.ReferredAffiliate.company].Stat.amount =
              parseFloat(referralsConcat[ref.ReferredAffiliate.company].Stat.amount) + parseFloat(ref.Stat.amount);
          }
        });

      Object.keys(referralsConcat).map((company) => {
        referralsArray.push(referralsConcat[company]);
      });

      const totalLength = referralsArray.length;

      this.setState({
        total: totalLength,
        pageCount: Math.ceil(totalLength / limit),
      });
    }
  }

  handleSort(name, direction, id, n) {
    const table = document.querySelector(`#${id} table`);
    const { sort } = this.state;
    let i;
    let shouldSwitch;
    let switchcount = 0;
    let dir = direction;
    let switching = true;

    sort[name] = direction;

    this.setState({
      sort,
    });

    while (switching) {
      switching = false;
      const rows = table.getElementsByTagName('TR');
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        const x = rows[i].getElementsByTagName('TD')[n];
        const y = rows[i + 1].getElementsByTagName('TD')[n];
        const momentX = moment(x.textContent, 'DD/MM/YYYY');
        const momentY = moment(y.textContent, 'DD/MM/YYYY');
        let xContent = x.textContent;
        let yContent = y.textContent;

        if (dir === 'asc') {
          if (xContent.indexOf('/') !== -1) {
            // Sort by date
            if (momentX > momentY) {
              shouldSwitch = true;
              break;
            }
          } else if (xContent.indexOf('$') !== -1) {
            // Sort by amount
            xContent = parseInt(xContent.replace(/[^0-9_-]/g, ''), 10);
            yContent = parseInt(yContent.replace(/[^0-9_-]/g, ''), 10);
            if (xContent > yContent) {
              shouldSwitch = true;
              break;
            }
          } else {
            // Sort by alphabetical order
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        } else if (dir === 'desc') {
          if (xContent.indexOf('/') !== -1) {
            // Sort by date
            if (momentX < momentY) {
              shouldSwitch = true;
              break;
            }
          } else if (xContent.indexOf('$') !== -1) {
            // Sort by amount
            xContent = parseInt(xContent.replace(/[^0-9_-]/g, ''), 10);
            yContent = parseInt(yContent.replace(/[^0-9_-]/g, ''), 10);
            if (xContent < yContent) {
              shouldSwitch = true;
              break;
            }
          } else {
            // Sort by alphabetical order
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  }

  tableCommissions() {
    const { referrals } = this.props;
    const { currentPage, limit } = this.state;

    return {
      head: [
        {
          component: <FormattedMessage {...messages.referralsReferredAffiliate} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextAmount} />,
          sort: 'amount',
        },
      ],
      body: referrals
        .filter((referral) => parseFloat(referral.Stat.amount) > 0)
        .filter((referral, index) => index >= (currentPage - 1) * limit && index < currentPage * limit)
        .map((referral) => [
          <div className="space" key={referral.ReferredAffiliate.company}>
            Referral ID: {referral.ReferredAffiliate.company}
          </div>,
          <div className="space" key={referral.ReferredAffiliate.company}>
            <FormattedNumber currency="USD" style="currency" value={parseFloat(referral.Stat.amount).toFixed(2)} />
          </div>,
        ]),
    };
  }

  tableAffiliates() {
    const { referrals } = this.props;
    const { currentPage, limit } = this.state;

    return {
      head: [
        {
          component: <FormattedMessage {...messages.referralsReferredAffiliate} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextJoinDate} />,
          sort: 'join_date',
        },
      ],
      body: referrals
        .filter((referral, index) => index >= (currentPage - 1) * limit && index < currentPage * limit)
        .map((affiliate) => [
          <div className="space" key={affiliate.ReferredAffiliate.company}>
            Referral ID: {affiliate.ReferredAffiliate.company}
          </div>,
          <div className="space" key={affiliate.ReferredAffiliate.company}>
            {moment(affiliate.ReferredAffiliate.date_added).format('DD/MM/YYYY')}
          </div>,
        ]),
    };
  }

  handlePageChange(page) {
    this.setState({ currentPage: page });
  }

  handleRowsChange(value) {
    const { total } = this.state;

    this.setState({
      currentPage: 1,
      pageCount: Math.ceil(total / parseInt(value, 10)),
      limit: parseInt(value, 10),
    });
  }

  render() {
    const { activeTab, referralsIsFetching } = this.props;
    const { currentPage, limit, pageCount, sort, total } = this.state;

    return (
      <div id="referrals-table">
        {referralsIsFetching ? (
          <div className="loading-container">
            <LoadingBar />
          </div>
        ) : (
          <div>
            {this.tableAffiliates().body.length > 1 || this.tableCommissions().body.length > 1 ? (
              <Pagination
                choices={[25, 50, 100, 200]}
                className="pull-right"
                currentPage={currentPage}
                handlePageChange={this.handlePageChange}
                handleRowsChange={this.handleRowsChange}
                pageCount={pageCount}
                rowsPerPage={limit}
                selectId="rowsPerPageTop"
                totalResults={total}
              />
            ) : (
              ''
            )}
            <Table
              handleSort={this.handleSort}
              id={activeTab}
              noData={
                <FormattedHTMLMessage
                  {...messages[activeTab === 'affiliates' ? 'referralsNoReferrals' : 'referralsNoCommissions']}
                />
              }
              sort={sort}
              table={activeTab === 'affiliates' ? this.tableAffiliates() : this.tableCommissions()}
            />
            {this.tableAffiliates().body.length > 1 || this.tableCommissions().body.length > 1 ? (
              <Pagination
                choices={[25, 50, 100, 200]}
                className="pull-right"
                currentPage={currentPage}
                handlePageChange={this.handlePageChange}
                handleRowsChange={this.handleRowsChange}
                pageCount={pageCount}
                rowsPerPage={limit}
                selectId="rowsPerPageBottom"
                totalResults={total}
              />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    );
  }
}

ReferralsTable.propTypes = {
  activeTab: PropTypes.string.isRequired,
  referrals: PropTypes.array.isRequired,
  referralsIsFetching: PropTypes.bool.isRequired,
};

export default ReferralsTable;

import { basicLengthAndCharValidation, basicLengthValidation } from '../../utils/helpers';
import { validate } from 'bitcoin-address-validation';

import consts from '../../utils/consts';
import { getStateLabelDefaultMessage } from '../../utils/list-options/states';
import { getProvinceLabelDefaultMessage } from '../../utils/list-options/provinces';
import { getPaymentMethodIds } from './billing-infos/paymentMethodHelper';

const { ALPHANUMERIC, ALPHANUMERIC_ONLY, NUMERIC_ONLY, ZIPCODE, EMAIL, TEXTS, JPMORGAN, EWALLET } = consts.REGEXES;

export default (values, props) => {
  const {
    paymentMethodCheckId,
    paymentMethodWireId,
    paymentMethodPaxumId,
    paymentMethodAchId,
    paymentMethodPaypalId,
    paymentMethodEpayServiceId,
    paymentMethodBitcoinId,
    paymentMethodMasspayId,
  } = getPaymentMethodIds([...props.paymentMethodsV2, ...props.paymentMethods]);

  const errors = {};
  if (!values.paymentMethod) {
    errors.paymentMethod = 'Please select the method by which you want to receive your payments.';
  }

  if (!values.minimumPayouts || values.minimumPayouts === '' || values.minimumPayouts === null) {
    errors.minimumPayouts = 'Please select the minimum payout you want to set up.';
  }

  if (values.paymentMethod) {
    switch (parseInt(values.paymentMethod, 10)) {
      // Check
      case paymentMethodCheckId:
        if (props.accountType === 'Legacy') {
          if (!values.checkPayableTo) {
            errors.checkPayableTo = 'Please enter the name of the beneficiary.';
          } else {
            if (!values.checkPayableTo.match(ALPHANUMERIC) && values.checkPayableTo.length >= 2) {
              errors.checkPayableTo =
                'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
            } else {
              errors.checkPayableTo = basicLengthAndCharValidation(values.checkPayableTo, 2, 35);
            }
          }

          if (!values.checkAddress) {
            errors.checkAddress = 'Please enter the address of the beneficiary.';
          } else {
            errors.checkAddress = basicLengthAndCharValidation(values.checkAddress, 2, 60);
          }

          if (!values.checkCity) {
            errors.checkCity = 'Please enter the city of the beneficiary.';
          } else {
            errors.checkCity = basicLengthAndCharValidation(values.checkCity, 2, 20);
          }

          if (!values.checkCountry) {
            errors.checkCountry = 'Please select the country of the beneficiary.';
          }

          if (values.checkCountry && values.checkCountry === 'CA') {
            if (!values.checkProvince) {
              errors.checkProvince = 'Please select the province of the beneficiary.';
            }
          }

          if (values.checkCountry && values.checkCountry === 'US') {
            if (!values.checkState) {
              errors.checkState = 'Please select the state of the beneficiary.';
            }
          }

          if (values.checkCountry && values.checkCountry !== 'CA' && values.checkCountry !== 'US') {
            if (!values.checkRegion) {
              errors.checkRegion = 'Please enter the region of the beneficiary.';
            } else {
              errors.checkRegion = basicLengthAndCharValidation(values.checkRegion);
            }
          }

          if (!values.checkZipcode) {
            errors.checkZipcode = 'Please enter the zip code of the beneficiary.';
          } else {
            if (values.checkCountry && !values.checkZipcode.match(ZIPCODE[values.checkCountry])) {
              errors.checkZipcode = 'Please enter a valid zip code.';
            }
          }
        }
        break;

      // Epay Service
      case paymentMethodEpayServiceId:
        if (props.accountType === 'Legacy') {
          if (!values.epayserviceBeneficiaryFirstName) {
            errors.epayserviceBeneficiaryFirstName = 'Please enter your first name.';
          } else {
            errors.epayserviceBeneficiaryFirstName = basicLengthAndCharValidation(values.epayserviceBeneficiaryFirstName);
          }
          if (!values.epayserviceBeneficiaryLastName) {
            errors.epayserviceBeneficiaryLastName = 'Please enter your last name.';
          } else {
            errors.epayserviceBeneficiaryLastName = basicLengthAndCharValidation(values.epayserviceBeneficiaryLastName);
          }
        }
        if (!values.epayserviceEwalletNumber) {
          errors.epayserviceEwalletNumber = 'Please enter your ePayService Wallet number.';
        } else if (!values.epayserviceEwalletNumber.match(EWALLET)) {
          errors.epayserviceEwalletNumber = 'Please enter a valid ePayService Wallet number.';
        }
        break;

      // Paxnum
      case paymentMethodPaxumId:
        if (!values.paxumEmail) {
          errors.paxumEmail = 'Please enter your Paxum email address.';
        } else if (values.paxumEmail[0] === ' ' || values.paxumEmail[values.paxumEmail.length - 1] === ' ') {
          errors.paxumEmail = 'The field must not start or end with an empty space.';
        } else {
          if (!values.paxumEmail.match(EMAIL)) {
            errors.paxumEmail = 'Please enter a valid email address.';
          }
        }

        break;

      // Wire
      case paymentMethodWireId:
        if (props.accountType === 'Legacy') {
          if (!values.wireBeneficiarySameAsContact) {
            if (!values.wireBeneficiaryName) {
              errors.wireBeneficiaryName = 'Please enter the name of the beneficiary.';
            } else {
              if (!values.wireBeneficiaryName.match(ALPHANUMERIC) && values.wireBeneficiaryName >= 2) {
                errors.wireBeneficiaryName =
                  'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
              } else {
                errors.wireBeneficiaryName = basicLengthAndCharValidation(values.wireBeneficiaryName);
              }
            }

            if (!values.wireBeneficiaryAddress) {
              errors.wireBeneficiaryAddress = 'This field is required.';
            } else {
              if (!values.wireBeneficiaryAddress.match(TEXTS)) {
                errors.wireBeneficiaryAddress =
                  'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
              } else {
                errors.wireBeneficiaryAddress = basicLengthAndCharValidation(values.wireBeneficiaryAddress, 2, 60);
              }
            }

            if (!values.wireBeneficiaryCity) {
              errors.wireBeneficiaryCity = 'Please enter the city of the beneficiary.';
            } else {
              errors.wireBeneficiaryCity = basicLengthAndCharValidation(values.wireBeneficiaryCity, 2, 35);
            }

            if (!values.wireBeneficiaryCountry) {
              errors.wireBeneficiaryCountry = 'Please select the country of the beneficiary.';
            }

            if (values.wireBeneficiaryCountry && values.wireBeneficiaryCountry === 'CA') {
              if (!values.wireBeneficiaryProvince) {
                errors.wireBeneficiaryProvince = 'Please select the province of the beneficiary.';
              }
            }

            if (values.wireBeneficiaryCountry && values.wireBeneficiaryCountry === 'US') {
              if (!values.wireBeneficiaryState) {
                errors.wireBeneficiaryState = 'Please select the state of the beneficiary.';
              }
            }

            if (values.wireBeneficiaryCountry !== 'CA' && values.wireBeneficiaryCountry !== 'US') {
              if (!values.wireBeneficiaryRegion) {
                errors.wireBeneficiaryRegion = 'Please enter the region of the beneficiary.';
              } else {
                errors.wireBeneficiaryRegion = basicLengthAndCharValidation(values.wireBeneficiaryRegion, 2, 50);
              }
            }
          }

          if (!values.wireBeneficiaryZipcode) {
            errors.wireBeneficiaryZipcode = 'Please enter the zip code of the beneficiary.';
          } else {
            if (values.wireBeneficiaryCountry && !values.wireBeneficiaryZipcode.match(ZIPCODE[values.wireBeneficiaryCountry])) {
              errors.wireBeneficiaryZipcode = 'Please enter a valid zip code.';
            }
          }
        }

        if (!values.wireBankName) {
          errors.wireBankName = 'Please enter your bank name.';
        } else {
          if (!values.wireBankName.match(ALPHANUMERIC) && values.wireBankName >= 2) {
            errors.wireBankName =
              'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
          } else {
            errors.wireBankName = basicLengthAndCharValidation(values.wireBankName, 2, 50);
          }
        }

        if (!values.wireBankAddress) {
          errors.wireBankAddress = 'Please enter your bank address.';
        } else {
          if (!values.wireBankAddress.match(TEXTS)) {
            errors.wireBankAddress =
              'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
          } else {
            errors.wireBankAddress = basicLengthAndCharValidation(values.wireBankAddress, 2, 60);
          }
        }

        if (!values.wireBankCity) {
          errors.wireBankCity = 'Please enter your bank city.';
        } else {
          errors.wireBankCity = basicLengthAndCharValidation(values.wireBankCity, 2, 20);
        }

        if (!values.wireBankCountry) {
          errors.wireBankCountry = 'Please enter your bank country.';
        }

        if (values.wireBankCountry && values.wireBankCountry === 'CA') {
          if (!values.wireBankProvince) {
            errors.wireBankProvince = 'Please select the province of the bank.';
          } else {
            if (!getProvinceLabelDefaultMessage(values.wireBankProvince)) {
              errors.wireBankProvince = 'Please select the province of the bank.';
            }
          }
        }

        if (values.wireBankCountry && values.wireBankCountry === 'US') {
          if (!values.wireBankState) {
            errors.wireBankState = 'Please select the state of the bank.';
          } else {
            if (!getStateLabelDefaultMessage(values.wireBankState)) {
              errors.wireBankState = 'Please select the state of the bank.';
            }
          }
        }

        if (values.wireBankCountry && values.wireBankCountry !== 'US' && values.wireBankCountry !== 'CA') {
          if (!values.wireBankRegion) {
            errors.wireBankRegion = 'Please enter the region of the bank.';
          }
        }

        if (!values.wireBankZipcode) {
          if (values.wireBankCountry === 'US') {
            errors.wireBankZipcode = 'Please enter your bank zip code.';
          } else if (values.wireBankCountry === 'CA') {
            errors.wireBankZipcode = 'Please enter your bank postal code.';
          } else {
            errors.wireBankZipcode = 'Please enter your bank postal/zip code.';
          }
        } else {
          if (values.wireBankCountry && !values.wireBankZipcode.match(ZIPCODE[values.wireBankCountry])) {
            if (values.wireBankCountry === 'US') {
              errors.wireBankZipcode = 'Please enter a valid zip code.';
            } else if (values.wireBankCountry === 'CA') {
              errors.wireBankZipcode = 'Please enter a valid postal code.';
            } else {
              errors.wireBankZipcode = 'Please enter a valid zip/postal code.';
            }
          }
        }

        if (!values.wireBicSwift) {
          errors.wireBicSwift = 'Please enter your BIC/SWIFT code.';
        } else {
          const regex = /(royccat2|chasus33)/gim;
          if (regex.test(values.wireBicSwift)) {
            errors.wireBicSwift = 'Transfers to this bank are not permitted. Please use a different institution.';
          } else {
            errors.wireBicSwift = basicLengthAndCharValidation(values.wireBicSwift, 2, 12, ALPHANUMERIC_ONLY);
          }
        }

        if (!values.wireABArouting) {
          if (
            (values.wireBankCountry && values.wireBankCountry === 'CA') ||
            values.wireBankCountry === 'US' ||
            values.wireBankCountry === 'PR'
          ) {
            errors.wireABArouting = 'Please enter a valid ABA, Routing or Branch Number';
          }
        } else {
          if (values.wireBankCountry && values.wireBankCountry === 'CA') {
            const nonAllowedBank = /(003|270)-(\d{5})/gm;
            if (nonAllowedBank.test(values.wireABArouting)) {
              errors.wireABArouting = 'Transfers to this bank are not permitted. Please use a different institution.';
            }

            const transitRegex = /(\d{3})-(\d{5})/gm;
            if (!transitRegex.test(values.wireABArouting)) {
              errors.wireABArouting = 'Invalid institution and transit number.';
            }
          } else {
            if (basicLengthAndCharValidation(values.wireABArouting, 9, 9, NUMERIC_ONLY)) {
              errors.wireABArouting = 'Please enter a valid ABA, Routing or Branch Number';
            }
          }
        }

        if (!values.wireAccountNumberOrIban) {
          if (values.wireBankCountry && values.wireBankCountry === 'CA') {
            errors.wireAccountNumberOrIban = 'Please enter your Account Number.';
          } else {
            errors.wireAccountNumberOrIban = 'Please enter your Account Number / IBAN.';
          }
        } else {
          errors.wireAccountNumberOrIban = basicLengthAndCharValidation(values.wireAccountNumberOrIban, 2, 35, ALPHANUMERIC_ONLY);
        }

        if (values.wireIntermediaryBankStatus) {
          if (!values.wireIntermediaryBankName) {
            errors.wireIntermediaryBankName = 'Please enter your intermediary bank name.';
          } else {
            if (!values.wireIntermediaryBankName.match(ALPHANUMERIC) && values.wireIntermediaryBankName >= 2) {
              errors.wireIntermediaryBankName =
                'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
            } else {
              errors.wireIntermediaryBankName = basicLengthAndCharValidation(values.wireIntermediaryBankName, 2, 50);
            }
          }

          if (!values.wireIntermediaryBankAddress) {
            errors.wireIntermediaryBankAddress = 'Please enter your intermediary bank address.';
          } else {
            if (!values.wireIntermediaryBankAddress.match(TEXTS)) {
              errors.wireIntermediaryBankAddress =
                'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
            } else {
              errors.wireIntermediaryBankAddress = basicLengthAndCharValidation(values.wireIntermediaryBankAddress, 2, 35);
            }
          }

          if (!values.wireIntermediaryBankCity) {
            errors.wireIntermediaryBankCity = 'Please enter your intermediary bank city.';
          } else {
            errors.wireIntermediaryBankCity = basicLengthAndCharValidation(values.wireIntermediaryBankCity, 2, 35);
          }

          if (!values.wireIntermediaryBankCountry) {
            errors.wireIntermediaryBankCountry = 'Please enter your intermediary bank country.';
          }

          if (values.wireIntermediaryBankCountry !== 'CA' && values.wireIntermediaryBankCountry !== 'US') {
            if (!values.wireIntermediaryBankState || values.wireIntermediaryBankState === '') {
              errors.wireIntermediaryBankState = 'Please enter the region of the intermediary bank.';
            } else {
              errors.wireIntermediaryBankState = basicLengthAndCharValidation(values.wireIntermediaryBankState, 2, 35);
            }
          }

          if (!values.wireIntermediaryBankZipcode) {
            if (values.wireIntermediaryBankCountry === 'US') {
              errors.wireIntermediaryBankZipcode = 'Please enter your intermediary bank zip code.';
            } else if (values.wireIntermediaryBankCountry === 'CA') {
              errors.wireIntermediaryBankZipcode = 'Please enter your intermediary bank postal code.';
            } else {
              errors.wireIntermediaryBankZipcode = 'Please enter your intermediary bank postal/zip code.';
            }
          } else {
            if (
              values.wireIntermediaryBankCountry &&
              !values.wireIntermediaryBankZipcode.match(ZIPCODE[values.wireIntermediaryBankCountry])
            ) {
              if (values.wireIntermediaryBankCountry === 'US') {
                errors.wireIntermediaryBankZipcode = 'Please enter a valid zip code.';
              } else if (values.wireIntermediaryBankCountry === 'CA') {
                errors.wireIntermediaryBankZipcode = 'Please enter a valid postal code.';
              } else {
                errors.wireIntermediaryBankZipcode = 'Please enter a valid zip/postal code.';
              }
            }
          }

          if (!values.wireIntermediaryBicSwift) {
            errors.wireIntermediaryBicSwift = 'Please enter your intermediary bank BIC/SWIFT code.';
          } else {
            const regex = /(royccat2|chasus33)/gim;
            if (regex.test(values.wireIntermediaryBicSwift)) {
              errors.wireIntermediaryBicSwift = 'Transfers to this bank are not permitted. Please use a different institution.';
            } else {
              errors.wireIntermediaryBicSwift = basicLengthAndCharValidation(
                values.wireIntermediaryBicSwift,
                2,
                12,
                ALPHANUMERIC_ONLY
              );
            }
          }

          if (!values.wireIntermediaryABArouting) {
            if (
              (values.wireIntermediaryBankCountry && values.wireIntermediaryBankCountry === 'CA') ||
              values.wireIntermediaryBankCountry === 'US' ||
              values.wireIntermediaryBankCountry === 'PR'
            ) {
              errors.wireIntermediaryABArouting = 'Please enter a valid ABA, Routing or Branch Number';
            }
          }
          if (values.wireIntermediaryABArouting) {
            if (values.wireIntermediaryBankCountry && values.wireIntermediaryBankCountry === 'CA') {
              const nonAllowedBank = /(003|270)-(\d{5})/gm;
              if (nonAllowedBank.test(values.wireIntermediaryABArouting)) {
                errors.wireIntermediaryABArouting =
                  'Transfers to this intermediary bank are not permitted. Please use a different institution.';
              }

              const transitRegex = /(\d{3})-(\d{5})/gm;
              if (!transitRegex.test(values.wireIntermediaryABArouting)) {
                errors.wireIntermediaryABArouting = 'Invalid institution and transit number.';
              }
            } else {
              if (basicLengthAndCharValidation(values.wireIntermediaryABArouting, 9, 9, NUMERIC_ONLY)) {
                errors.wireIntermediaryABArouting = 'Please enter a valid ABA, Routing or Branch Number';
              }
            }
          }
        }
        break;

      case paymentMethodPaypalId:
        if (!values.paypalEmail) {
          errors.paypalEmail = 'Please enter your PayPal email address.';
        } else if (values.paypalEmail[0] === ' ' || values.paypalEmail[values.paypalEmail.length - 1] === ' ') {
          errors.paypalEmail = 'The field must not start or end with an empty space.';
        } else if (!values.paypalEmail.match(EMAIL)) {
          errors.paypalEmail = 'Please enter a valid email address.';
        }
        break;

      // ACH
      case paymentMethodAchId: {
        const transitRegex = /(0003|0270)/gm;
        const swiftRegex = /(royccat2|chasus33)/gim;

        // bank's country validations

        if (!values.achConfigId) {
          errors.achConfigId = 'Please enter the country of the bank';
        } else {
          const achConfiguration = props?.achConfigurations.filter((conf) => conf.id === values?.achConfigId)[0];
          const country = achConfiguration?.country_name;

          // bank name validations
          if (!values.achBeneficiaryBankName) {
            errors.achBeneficiaryBankName = 'Please enter the name of the bank.';
          } else {
            errors.achBeneficiaryBankName = basicLengthAndCharValidation(values.achBeneficiaryBankName, 2, 70);
          }

          // bank address validations
          if (!values.achBeneficiaryBankAddress) {
            errors.achBeneficiaryBankAddress = 'Please enter the address of the bank.';
          } else {
            errors.achBeneficiaryBankAddress = basicLengthAndCharValidation(values.achBeneficiaryBankAddress, 2, 100);
          }

          // bank's transit number validations
          if (!values.achTransitNumber) {
            errors.achTransitNumber = 'This field is required.';
          } else if (
            ((country === 'Canada (CAD)' || 'Canada (CAD)') && transitRegex.test(values.achTransitNumber)) ||
            (country !== 'United States (USD)' &&
              (country !== 'Canada (CAD)' || 'Canada (USD)') &&
              swiftRegex.test(values.achTransitNumber)) ||
            (country === 'United States (USD)' && JPMORGAN.test(values.achTransitNumber))
          ) {
            errors.achTransitNumber = 'Transfers to this bank are not permitted. Please use a different institution.';
          } else if (achConfiguration) {
            const achTransitNumberConfiguration = achConfiguration.transit_number.config.split(':');
            errors.achTransitNumber = basicLengthAndCharValidation(
              values.achTransitNumber,
              achTransitNumberConfiguration[0],
              achTransitNumberConfiguration[achTransitNumberConfiguration.length - 1],
              ALPHANUMERIC_ONLY
            );
          }
          // bank's account number validations
          if (!values.achAccountNumber) {
            errors.achAccountNumber = 'This field is required.';
          } else if (achConfiguration) {
            const achAccountNumberConfiguration = achConfiguration.account_number.config.split(':');
            errors.achAccountNumber = basicLengthAndCharValidation(
              values.achAccountNumber,
              achAccountNumberConfiguration[0],
              achAccountNumberConfiguration[achAccountNumberConfiguration.length - 1],
              ALPHANUMERIC_ONLY
            );
          }
          if (props.accountType === 'Legacy') {
            // beneficiary name validations
            if (!values.achBeneficiaryName) {
              errors.achBeneficiaryName = 'Please enter the name of the beneficiary.';
            } else if (!values.achBeneficiaryName.match(ALPHANUMERIC) && values.achBeneficiaryName.length >= 2) {
              errors.achBeneficiaryName =
                'Special characters such as: , ~ ! @ # $ % ^ & * ( ) _ + { } | : " < > " are not allowed in this field. Please remove them.';
            } else {
              errors.achBeneficiaryName = basicLengthAndCharValidation(values.achBeneficiaryName, 2, 70);
            }

            // beneficiary address validations
            if (!values.achBeneficiaryAddress) {
              errors.achBeneficiaryAddress = 'Please enter the address of the beneficiary.';
            } else {
              errors.achBeneficiaryAddress = basicLengthAndCharValidation(values.achBeneficiaryAddress, 2, 100);
            }

            // beneficiary city validations
            if (!values.achBeneficiaryCity) {
              errors.achBeneficiaryCity = 'Please enter the city of the beneficiary.';
            } else {
              errors.achBeneficiaryCity = basicLengthAndCharValidation(values.achBeneficiaryCity, 2, 20);
            }

            // beneficiary country validations
            if (!values.achBeneficiaryCountryCode) {
              errors.achBeneficiaryCountryCode = 'Please select the country of the beneficiary.';
            }
            // beneficiary state validations
            switch (values.achBeneficiaryCountryCode) {
              case 'CA':
                if (!values.achBeneficiaryState) {
                  errors.achBeneficiaryState = 'Please select the province of the beneficiary.';
                }
                break;
              case 'US':
                if (!values.achBeneficiaryState) {
                  errors.achBeneficiaryState = 'Please select the state of the beneficiary.';
                }
                break;
              default:
                if (!values.achBeneficiaryState) {
                  errors.achBeneficiaryState = 'Please enter the region of the beneficiary.';
                } else {
                  errors.achBeneficiaryState = basicLengthAndCharValidation(values.achBeneficiaryState);
                }
            }

            // beneficiary postal/zip code validations
            if (!values.achBeneficiaryZipCode) {
              switch (values.achBeneficiaryCountryCode) {
                case 'CA':
                  errors.achBeneficiaryZipCode = 'Please enter the postal code of the beneficiary.';
                  break;
                default:
                  errors.achBeneficiaryZipCode = 'Please enter the zip code of the beneficiary.';
                  break;
              }
            } else {
              if (!values.achBeneficiaryZipCode.match(ZIPCODE[values.achBeneficiaryCountryCode])) {
                switch (values.achBeneficiaryCountryCode) {
                  case 'CA':
                    errors.achBeneficiaryZipCode = 'Please enter a valid postal code.';
                    break;
                  default:
                    errors.achBeneficiaryZipCode = 'Please enter a valid zip code.';
                    break;
                }
              }
            }
          }
        }
        break;
      }

      case paymentMethodBitcoinId:
        if (!values.bitcoinWalletAddress) {
          errors.bitcoinWalletAddress = 'Please enter your wallet adress.';
        } else if (values.bitcoinWalletAddress) {
          if (!validate(values.bitcoinWalletAddress)) {
            errors.bitcoinWalletAddress = 'Please enter a valid bitcoin address.';
          }
        }
        break;

      case paymentMethodMasspayId:
        if (!values.masspayFirstname) {
          errors.masspayFirstname = 'Please enter your MassPay first name.';
        } else {
          errors.masspayFirstname = basicLengthValidation(values.masspayFirstname, 1, 255);
        }
        if (!values.masspayLastname) {
          errors.masspayLastname = 'Please enter your MassPay last name.';
        } else {
          errors.masspayLastname = basicLengthValidation(values.masspayLastname, 1, 255);
        }
        if (!values.masspayEmail) {
          errors.masspayEmail = 'Please enter your MassPay email.';
        } else {
          if (!values.masspayEmail.match(EMAIL)) {
            errors.masspayEmail = 'Please enter a valid email address.';
          }
        }
        break;

      default:
        break;
    }
  }

  return errors;
};

import { cloneElement, Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { initialize, reduxForm, reset } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

// TODO: remove jQuery from project
import $ from 'jquery';

import messages from '../i18n/base-en.js';

import { LiveCamWidgetIFrame, LiveCamWidgetTabs, livecamUrlBuilder } from './live-cam-widget';
import Tooltip from '../components/reusables/ReactTooltip';

import {
  changeLivecamUrls,
  createCreatives,
  fetchMultipleCreatedCreatives,
  setAnimatedTemplateLivecam,
  fetchLiveCamOffers,
  resetCreative,
} from '../actions/live-cam-widget-actions';
import { fetchOfferUrl } from './offer/Offer-Actions/Offer-Actions';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { Gtm } from '../utils/gtm';
import intercom from '../utils/intercom';

import { CodesTypes, premadeTemplates } from './live-cam-widget/LiveCamWidgetConsts';
import config from '../config';
import { openModal } from '../components/Modal/modal-actions/modal-actions.js';
const { genericTextBackToDashboard, LiveCamWidgetPage, WidgetSection } = messages;
const { LIVECAM_WIDGET_OFFER_ID } = config;

class LiveCamWidget extends Component {
  static getErrors(props) {
    const { typeSettings, templateOptions } = props;
    const errors = [];
    // no offers selected
    if (!typeSettings || !typeSettings.offers || typeSettings.offers.length === 0) {
      errors.push('offer');
    }
    // no template selected
    if (!templateOptions) {
      errors.push('template');
    }

    return errors;
  }

  constructor(props) {
    super(props);

    this.generateScriptUrl = this.generateScriptUrl.bind(this);
    this.generateWidgetCode = this.generateWidgetCode.bind(this);
    this.getToolTip = this.getToolTip.bind(this);
    this.displayError = this.displayError.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.onWidgetTypeChange = this.onWidgetTypeChange.bind(this);
    this.validateNavigation = this.validateNavigation.bind(this);
    this.onSelectOffers = this.onSelectOffers.bind(this);
    this.getAffSub4 = this.getAffSub4.bind(this);
    this.state = {
      errors: [],
      fieldsSettings: {},
      loading: false,
      templateSelected: false,
      currentOffers: {},
    };
  }

  UNSAFE_componentWillMount() {
    const { skinConfigurations } = this.props;
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'live-cam-widget');
    this.validateNavigation(this.props);
  }

  componentDidMount() {
    const { routes } = this.props;

    this.fetchData();

    intercom.trackEvent('widget-cam');
    if ('path' in routes[2]) {
      browserHistory.push('/live-cam-widget');
    }

    if (typeof window !== 'undefined' && 'onmessage' in window) {
      const self = this;
      window.onmessage = (e) => {
        if (typeof e.data === 'string' && e.data.indexOf('iframeHeight') >= 0) {
          $('#widget-iframe:not(.tablet, .phone) .widget-iframe-container').height(`${e.data.split(':')[1]}px`);
        }

        if (typeof e.data === 'string' && e.data.indexOf('params') >= 0) {
          const surveyParams = JSON.parse(e.data.split('params:')[1]);
          self.setState({
            fieldsSettings: surveyParams,
          });
        }
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      affId,
      campaigns,
      cssSettings,
      infobarSettings,
      layoutSettings,
      offerUrl,
      templateOptions,
      thumbnailsSettings,
      typeSettings,
    } = this.props;

    if (
      !!nextProps.affId &&
      !!nextProps.campaigns &&
      !!nextProps.cssSettings &&
      !!nextProps.infobarSettings &&
      !!nextProps.layoutSettings &&
      !!nextProps.offerUrl &&
      !!nextProps.templateOptions &&
      !!nextProps.thumbnailsSettings &&
      !!nextProps.typeSettings &&
      (affId !== nextProps.affId ||
        campaigns !== nextProps.campaigns ||
        cssSettings !== nextProps.cssSettings ||
        infobarSettings !== nextProps.infobarSettings ||
        layoutSettings !== nextProps.layoutSettings ||
        offerUrl !== nextProps.offerUrl ||
        templateOptions !== nextProps.templateOptions ||
        thumbnailsSettings !== nextProps.thumbnailsSettings ||
        typeSettings !== nextProps.typeSettings)
    ) {
      if (!nextProps.typeSettings.offers.length) {
        nextProps.dispatch(changeLivecamUrls({ widgetUrls: [], iframeUrl: '' }));
      } else {
        const widgetUrls = nextProps.offerUrl.map((url) => url.offer_url);
        const obj = {
          cssSettings: nextProps.cssSettings,
          infobarSettings: nextProps.infobarSettings,
          layoutSettings: nextProps.layoutSettings,
          templateSettings: nextProps.templateOptions,
          thumbnailsSettings: nextProps.thumbnailsSettings,
          typeSettings: nextProps.typeSettings,
        };
        const iframeUrl = livecamUrlBuilder(
          obj,
          nextProps.affId,
          `${widgetUrls[Math.floor(Math.random() * widgetUrls.length)]}`,
          nextProps.campaigns,
          true
        );

        nextProps.dispatch(changeLivecamUrls({ widgetUrls, iframeUrl }));
      }
    } else if (!!nextProps.typeSettings && nextProps.typeSettings.offers.length) {
      const currentWidgetType = CodesTypes.find((type) => nextProps.typeSettings.widgetType.value === type.id);
      this.onTemplateChange(premadeTemplates[0], currentWidgetType.settings);
    }

    if (typeSettings !== nextProps.typeSettings || templateOptions !== nextProps.templateOptions) {
      this.validateNavigation(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    const { templateOptions, dispatch } = this.props;
    if (templateOptions !== prevProps.templateOptions) {
      dispatch(reset('infobarSettingsLC'));
    }
    if (this.props.typeSettings !== prevProps.typeSettings) {
      dispatch(resetCreative());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reset('templateOptions'));
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(fetchLiveCamOffers());
    dispatch(fetchOfferUrl(LIVECAM_WIDGET_OFFER_ID));
  }

  onWidgetTypeChange(widgetType) {
    const { dispatch } = this.props;
    const widgetTypeSettings = CodesTypes.find((type) => type.id === widgetType).settings;

    Object.keys(widgetTypeSettings).forEach((type) => {
      dispatch(initialize(`${type}LC`, widgetTypeSettings[type], Object.keys(widgetTypeSettings[type])));
    });
  }

  onTemplateChange(template, settingsToMerge = {}) {
    const { dispatch, templateOptions } = this.props;
    const { templateSelected } = this.state;

    const newSettings = _.merge(template.settings, settingsToMerge);
    const { cssSettings, infobarSettings, layoutSettings, templateSettings, thumbnailsSettings, typeSettings } = newSettings;

    if (!templateOptions || (templateOptions.skin.value !== templateSettings.skin && !templateSelected)) {
      this.setState({ templateSelected: true });
      dispatch(initialize('cssSettingsLC', cssSettings, Object.keys(cssSettings)));
      dispatch(initialize('infobarSettingsLC', infobarSettings, Object.keys(infobarSettings)));
      dispatch(initialize('layoutSettingsLC', layoutSettings, Object.keys(layoutSettings)));
      dispatch(initialize('templateOptionsLC', templateSettings, Object.keys(templateSettings)));
      dispatch(initialize('thumbnailsSettingsLC', thumbnailsSettings, Object.keys(thumbnailsSettings)));
      dispatch(initialize('typeSettingsLC', typeSettings, Object.keys(typeSettings)));
    }
  }

  getToolTip() {
    const { intl } = this.props;
    const { errors } = this.state;

    if (errors.length > 0) {
      return intl.formatMessage(WidgetSection.generateCode.warnings.enterTemplateOfferFirst);
    }
  }

  validateNavigation(props) {
    const errors = LiveCamWidget.getErrors(props);
    const newState = { errors };
    if (errors.some((err) => err !== 'name')) {
      newState.iframeUrl = '';
    }

    this.setState(newState);
  }

  generateScriptUrl() {
    const { affId, cssSettings, infobarSettings, layoutSettings, templateOptions, thumbnailsSettings, typeSettings, widgetUrls } =
      this.props;

    const { currentOffers } = this.state;

    return livecamUrlBuilder(
      {
        cssSettings,
        infobarSettings,
        layoutSettings,
        templateSettings: templateOptions,
        thumbnailsSettings,
        typeSettings,
      },
      affId,
      widgetUrls[Math.floor(Math.random() * widgetUrls.length)],
      currentOffers,
      false
    );
  }

  getAffSub4() {
    const { typeSettings } = this.props;
    const widgetType = typeSettings ? typeSettings.widgetType : undefined;
    const isWidgetDisplay = widgetType.value === 'script';
    const isWidgetImAds = widgetType.value === 'im';
    const isWidgetChatHead = widgetType.value.includes('im_');

    if (isWidgetChatHead) {
      return 'AT_0006';
    } else if (isWidgetImAds) {
      return 'AT_0008';
    } else if (isWidgetDisplay) {
      return 'AT_0007';
    } else {
      return '';
    }
  }

  displayError(validateName = false) {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const { errors } = this.state;

    if (errors.length > 0) {
      if (validateName && errors.some((err) => err === 'name')) {
        if (document) {
          document.getElementById('widget-name').focus();
        }
      } else if (errors.some((err) => err === 'offer')) {
        if (pathname !== '/live-cam-widget') {
          browserHistory.push('/live-cam-widget');
        }
        dispatch(setAnimatedTemplateLivecam(true));
        setTimeout(() => {
          dispatch(setAnimatedTemplateLivecam(false));
        }, 1000);
      } else if (errors.some((err) => err === 'template')) {
        if (pathname !== '/live-cam-widget/template-settings') {
          browserHistory.push('/live-cam-widget/template-settings');
        }
        dispatch(setAnimatedTemplateLivecam(true));
        setTimeout(() => {
          dispatch(setAnimatedTemplateLivecam(false));
        }, 1000);
      }
      return true;
    }
    return false;
  }

  onSelectOffers(offers) {
    this.setState({
      currentOffers: offers,
    });
  }

  widgetButtonEnabled() {
    const { errors } = this.state;
    return errors.length > 0 ? 'disabled' : undefined;
  }

  handleOpenModal(creatives, dispatch, typeSettings, loading, widgetType, isImAds) {
    if (this.widgetButtonEnabled() === 'disabled') {
      return;
    }

    dispatch(
      openModal({
        name: 'LiveCamWidgetModal',
        modalProps: {
          creatives: creatives,
          dispatch: dispatch,
          loading: loading,
          widgetType: widgetType,
          affSub4: this.getAffSub4(),
          isImAds,
        },
      })
    );
  }

  generateWidgetCode() {
    const { creatives, dispatch, typeSettings, affId } = this.props;
    const widgetType = typeSettings ? typeSettings.widgetType : undefined;
    const isWidgetDisplay = widgetType.value === 'script';
    const isWidgetImAds = widgetType.value === 'im';
    const isWidgetChatHead = widgetType.value.includes('im_');
    let widgetName = '';
    const currentDate = new Date().toLocaleDateString('en-CA');
    const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1) + 1).toString();
    if (isWidgetChatHead) {
      widgetName = `${currentDate}-${affId}-chathead-${randomNumber}`;
    } else if (isWidgetImAds) {
      widgetName = `${currentDate}-${affId}-imads-${randomNumber}`;
    } else if (isWidgetDisplay) {
      widgetName = `${currentDate}-${affId}-display-${randomNumber}`;
    }
    const { loading } = this.state;
    this.setState({ loading: true });
    const widgetTypeSelected = CodesTypes.filter(({ id }) => widgetType.value === id);
    this.handleOpenModal(creatives, dispatch, typeSettings, loading, widgetType, widgetTypeSelected[0].id === 'im');
    if (!this.displayError(true)) {
      Gtm.event('live cam widget', 'Click', 'generate widget code');
      intercom.trackEvent('creative_widget-cam');
      const scriptGenerated = this.generateScriptUrl();
      const newCreatives = {
        type: 'livecam',
        code: widgetTypeSelected[0].code.replace('{src}', scriptGenerated),
        remote: widgetTypeSelected[0].remote.replace('{src}', scriptGenerated),
        display: widgetName,
        placeholders: null,
        name: widgetTypeSelected[0].id,
      };

      dispatch(createCreatives(newCreatives)).then(() => {
        dispatch(
          fetchMultipleCreatedCreatives(Object.values(creatives), {
            aff_sub4: this.getAffSub4(),
          })
        ).then(() => {
          $('#livecamGenerationModal_aff_sub').focus(); // hotfix pour bypasser le fait que "componentWillReciveProps" marche pas dans LiveCamWidgetModalTabs
          this.setState({ loading: false });
        });
      });
    }
  }

  render() {
    const {
      animated,
      campaigns,
      campaignsFetched,
      children,
      dispatch,
      iframeUrl,
      intl,
      layoutSettings,
      location: { pathname },
      templateOptions,
      typeSettings,
    } = this.props;
    const { fieldsSettings } = this.state;

    const iframeSizes = layoutSettings ? layoutSettings.iframeSizes : undefined;
    return (
      <div className="container-fluid app-container" id="live-cam-widget">
        <div className="visible-sm visible-xs visible-md visible-mobile z-depth-2" id="unavailable-feature">
          <h1 className="primary-color">
            <FormattedMessage {...WidgetSection.unavailableFeature.header.text} />
          </h1>
          <p className="visible-mobile hidden-desktop">
            <FormattedMessage {...WidgetSection.unavailableFeature.mobileContent.text} />
          </p>
          <p className="visible-desktop hidden-mobile">
            <FormattedMessage {...WidgetSection.unavailableFeature.desktopContent.text} />
          </p>
          <Link className="btn" onlyActiveOnIndex={false} to="/">
            <FormattedMessage {...genericTextBackToDashboard} />
          </Link>
        </div>

        <div className="hidden-xs hidden-sm hidden-md hidden-mobile">
          <div className="livecam-header">
            <h1 className="primary-color">
              <FormattedMessage {...LiveCamWidgetPage.title.text} />
            </h1>
            <div className="widget-code">
              <Tooltip
                button
                className={['btn', 'btn-generate', this.widgetButtonEnabled()].join(' ')}
                id="generate-widget-button-tooltip"
                onClick={this.generateWidgetCode}
                position="left"
                tooltip={this.getToolTip()}
              >
                {intl.formatMessage(WidgetSection.generateCode.button.text)}
              </Tooltip>
            </div>
          </div>
          <div className="generator">
            <LiveCamWidgetTabs pathname={pathname} templateOptions={templateOptions} typeSettings={typeSettings} />
            <div className="content z-depth-2">
              {cloneElement(children, {
                animated,
                campaigns,
                campaignsFetched,
                typeSettings,
                iframeSizes,
                dispatch,
                fieldsSettings,
                onSelectOffers: this.onSelectOffers,
                onTemplateChange: this.onTemplateChange,
                onWidgetTypeChange: this.onWidgetTypeChange,
              })}
            </div>
            <LiveCamWidgetIFrame src={iframeUrl} />
          </div>
        </div>
      </div>
    );
  }
}

LiveCamWidget.propTypes = {
  affId: PropTypes.string,
  animated: PropTypes.bool,
  campaigns: PropTypes.array,
  campaignsFetched: PropTypes.bool,
  children: PropTypes.object.isRequired,
  creatives: PropTypes.object,
  cssSettings: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  iframeUrl: PropTypes.string,
  infobarSettings: PropTypes.object,
  intl: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  location: PropTypes.object.isRequired,
  offerUrl: PropTypes.array,
  routes: PropTypes.array,
  skinConfigurations: PropTypes.object.isRequired,
  templateOptions: PropTypes.object,
  thumbnailsSettings: PropTypes.object,
  typeSettings: PropTypes.object,
  values: PropTypes.object.isRequired,
  widgetUrls: PropTypes.array,
};

export default connect((state) => ({
  affId: state.profile.data.affInfos.id,
  animated: state.livecamGenerator.animated,
  campaigns: state.livecamGenerator.campaigns,
  campaignsFetched: state.livecamGenerator.campaignsFetched,
  creatives: state.livecamGenerator.creatives,
  cssSettings: state.form.cssSettingsLC,
  infobarSettings: state.form.infobarSettingsLC,
  layoutSettings: state.form.layoutSettingsLC,
  offerUrl: state.offer.offerUrl,
  skinConfigurations: state.skinConfigurations.data,
  templateOptions: state.form.templateOptionsLC,
  thumbnailsSettings: state.form.thumbnailsSettingsLC,
  typeSettings: state.form.typeSettingsLC,
  widgetUrls: state.livecamGenerator.widgetUrls,
  iframeUrl: state.livecamGenerator.iframeUrl,
}))(
  injectIntl(
    reduxForm({
      form: 'livecamGenerator',
      touchOnChange: false,
      touchOnBlur: false,
      fields: ['display'],
      destroyOnUnmount: false,
    })(LiveCamWidget)
  )
);

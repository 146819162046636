/**
 * @file Container des Cards des offres
 * @author Mikael Boutin
 * @version 0.0.1
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import NotificationsCard from './NotificationsCard.react';

/** Container des Cards des offres */
class NotificationsCards extends Component {
  /**
   * @constructor
   * @param {object} props - La fonction super() appelle le parent pour y transmettre ses propriétés
   */
  constructor(props) {
    super(props);
  }

  /**
   * Render le component
   * @return {JSX} Rend la page Home
   */
  render() {
    const { alerts } = this.props;

    return (
      <div id="notifications-cards">
        <ReactCSSTransitionGroup
          className="animationBrandsCard row"
          component="div"
          transitionEnterTimeout={alerts.length * 50 + 500}
          transitionLeaveTimeout={alerts.length * 50 + 500}
          transitionName="card"
        >
          {alerts.map((alert) => (
            <NotificationsCard datetime={alert.datetime} description={alert.description} key={alert.id} title={alert.title} />
          ))}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

/**
 * Propriétés du component
 * @property {array} offers - Array des offres à afficher
 * @property {string} colClassName - Classes css
 * @type {{offers: (module.exports.isRequired|Function|Validator<T>|*), colClassName: (module.exports.isRequired|Function|Validator<T>|*)}}
 */
NotificationsCards.propTypes = {
  alerts: PropTypes.array.isRequired,
};

export default NotificationsCards;

import React, { useEffect, useState } from 'react';
import {
  BANNER_FILTERS,
  BANNER_TRENDING_FILTERS,
  CLEAR_ALL_TEXT,
  CREATIVE_BANNER_FORMAT_LIST,
  CREATIVE_BANNER_LANGUAGE_LIST,
  isTrendingCreativesTab,
} from './values';
import { useCreativesBanners } from '../CreativesBanners';
import { Gtm } from '../../../../utils/gtm';
import BannerFilter from './BannerFilter';

const BannerFilters = React.memo(() => {
  let {
    activeTab,
    creativesSizes,
    dispatchSearchFormChange,
    setOpenFilter,
    firstCreativeSize,
    creativesLoading,
    creativesTrending,
    setActivatedFilters,
    activatedFiltersByTab,
  } = useCreativesBanners();

  if (creativesSizes?.length) {
    creativesSizes = creativesSizes.map((size) => ({ key: size, value: size }));
  }

  const closeFilterDropdown = () => setOpenFilter('');

  const setFilter = ({ field, key }) => {
    if (creativesLoading) {
      return;
    }

    Gtm.event('offers', 'Click', `banner ${field} - ${key}`);

    if (activatedFiltersByTab[field] !== key) {
      setActivatedFilters((filters) => {
        filters[activeTab][field] = filters[activeTab]?.[field] === key ? '' : key;

        return { ...filters };
      });

      if (!isTrendingCreativesTab(activeTab)) {
        dispatchSearchFormChange({ field, key });
      }
    }

    closeFilterDropdown();
  };

  const clearAll = ({ field }) => {
    closeFilterDropdown();

    Gtm.event('offers', 'Click', `banner - ${field} - ${CLEAR_ALL_TEXT[field]}`);

    if (
      creativesLoading ||
      !activatedFiltersByTab[field] ||
      (!isTrendingCreativesTab(activeTab) && activatedFiltersByTab[field] === firstCreativeSize)
    ) {
      return;
    }

    setActivatedFilters((filters) => {
      if (isTrendingCreativesTab(activeTab)) {
        delete filters[activeTab][field];
      } else {
        filters[activeTab][field] = '';
      }

      return { ...filters };
    });

    if (!isTrendingCreativesTab(activeTab)) {
      dispatchSearchFormChange({ field, key: '' });
    }
  };

  const openDropdown = ({ field }) => {
    if (creativesLoading) {
      return;
    }
    setOpenFilter((openFilter) => (openFilter === field ? '' : field));
  };

  const dropdowns = {
    size: isTrendingCreativesTab(activeTab) ? creativesTrending.sizes : creativesSizes,
    language: CREATIVE_BANNER_LANGUAGE_LIST,
    format: isTrendingCreativesTab(activeTab) ? creativesTrending.formats : CREATIVE_BANNER_FORMAT_LIST,
    countryCode: creativesTrending.countries,
    days: creativesTrending.days,
  };

  return (
    <div className={`bannerFilters ${activeTab}`}>
      <div className="filtersGroup">
        {isTrendingCreativesTab(activeTab) && (
          <div className="trendingBannerFilters">
            <div className="trendingText">Best creatives in</div>
            <div className="trendingFilterRow">
              {BANNER_TRENDING_FILTERS.map(({ field, text }) => (
                <>
                  {text ? (
                    <div className="trendingText">{text}</div>
                  ) : (
                    <BannerFilter
                      clearAll={clearAll}
                      clearAllText="Reset"
                      displayValue={activatedFiltersByTab[field]}
                      dropdowns={dropdowns}
                      field={field}
                      filterOptions={activatedFiltersByTab}
                      openDropdown={openDropdown}
                      setFilter={setFilter}
                      trendingFilter
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="standardBannerFilters">
          {BANNER_FILTERS.map((field) => (
            <BannerFilter
              clearAll={clearAll}
              clearAllText={isTrendingCreativesTab(activeTab) ? 'Reset' : ''}
              dropdowns={dropdowns}
              field={field}
              filterOptions={activatedFiltersByTab}
              openDropdown={openDropdown}
              setFilter={setFilter}
            />
          ))}
        </div>
      </div>
      {isTrendingCreativesTab(activeTab) ? (
        <div className="bannerDescription">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C12.9993 7.71733 12.9033 7.48 12.712 7.288C12.5207 7.096 12.2833 7 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C10.9993 8.28267 11.0953 8.52033 11.288 8.713C11.4807 8.90567 11.718 9.00133 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
              fill="#333333"
            />
          </svg>
          <p>
            Trending banners include all the creatives we’ve tested and which deliver excellent click-through rates on significant
            traffic volumes. Not sure which banners to use? Follow the Whale and use these top-performing creatives!
          </p>
        </div>
      ) : null}
    </div>
  );
});

export default BannerFilters;

const PaidIcon = () => (
  <svg fill="none" height="12" viewBox="0 0 13 12" width="13" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_142_13271)">
      <path
        clipRule="evenodd"
        d="M6.20996 12C6.99789 12 7.77811 11.8448 8.50606 11.5433C9.23402 11.2417 9.89545 10.7998 10.4526 10.2426C11.0098 9.68549 11.4517 9.02405 11.7532 8.2961C12.0548 7.56815 12.21 6.78793 12.21 6C12.21 5.21207 12.0548 4.43185 11.7532 3.7039C11.4517 2.97595 11.0098 2.31451 10.4526 1.75736C9.89545 1.20021 9.23402 0.758251 8.50606 0.456723C7.77811 0.155195 6.99789 -1.17411e-08 6.20996 0C4.61866 2.37122e-08 3.09254 0.632141 1.96732 1.75736C0.842102 2.88258 0.209961 4.4087 0.209961 6C0.209961 7.5913 0.842102 9.11742 1.96732 10.2426C3.09254 11.3679 4.61866 12 6.20996 12ZM6.05529 8.42667L9.38863 4.42667L8.36463 3.57333L5.49796 7.01267L4.01463 5.52867L3.07196 6.47133L5.07196 8.47133L5.58796 8.98733L6.05529 8.42667Z"
        fill="#0E700E"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_142_13271">
        <rect fill="white" height="12" transform="translate(0.209961)" width="12" />
      </clipPath>
    </defs>
  </svg>
);

export default PaidIcon;

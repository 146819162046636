import request from 'superagent';

class BetterRequest {
  setOrigin(origin) {
    this.origin = origin;
  }

  get() {
    return this.completeRequest(request.get(...arguments));
  }

  post() {
    return this.completeRequest(request.post(...arguments));
  }

  put() {
    return this.completeRequest(request.put(...arguments));
  }

  delete() {
    return this.completeRequest(request.delete(...arguments));
  }

  completeRequest(req) {
    if (this.origin) {
      req.set('Origin', this.origin);
    }
    return req;
  }
}

const req = new BetterRequest();

export default req;

import _ from 'lodash';

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  search: consts.OFFERS_SEARCH_INITALSTATE,
  offersIsFetching: false,
  initialOffersFetched: false,
  offersCurrentRequest: null,
  offers: [],
  offersReceivedAt: null,
  offersInfos: {
    currentPage: 1,
    total: 0,
    pageCount: 0,
  },
  offersLocationQuery: {},
  offersFromMenuClick: [],
  isSeeAllClicked: false,
};

export const offers = (state = initialState, action = {}) => {
  const date = new Date();
  let offersInfos;
  let filtersState;
  let categoriesState;
  let othersState;
  let filtersAction;
  let categoriesAction;
  let searchState;

  switch (action.type) {
    case ACTIONS.SET_OFFERS_SEARCH_FILTERS:
      searchState = state.search;
      filtersState = searchState.filters;
      categoriesState = searchState.categories;
      othersState = searchState.others;
      filtersAction = action.search.filters;
      categoriesAction = action.search.categories;

      return {
        ...state,
        search: {
          ...searchState,
          filters: {
            ...filtersState,
            ...filtersAction,
          },
          sort: action.search.sort,
          categories: {
            ...categoriesState,
            ...categoriesAction,
          },
          categoriesSort: action.search.categoriesSort,
          others: action.search.others,
          othersSort: action.search.othersSort,
          searchString: action.search.searchString,
          featured: action.search.featured,
        },
      };

    case ACTIONS.SET_OFFERS_FROM_MENU_CLICK:
      return {
        ...state,
        offersFromMenuClick: action.verticals.length > 0 ? [action.verticals] : [],
      };
    case ACTIONS.SET_SEE_ALL_OFFERS_FROM_MENU_CLICK:
      return {
        ...state,
        isSeeAllClicked: action.isSeeAllClicked,
      };
    case ACTIONS.REMOVE_SEARCH_STRING_TAG:
      searchState = state.search;

      return {
        ...state,
        search: {
          ...searchState,
          searchString: state.search.searchString
            .split(' ')
            .filter((value) => value !== action.value)
            .join(' '),
        },
      };

    case ACTIONS.REMOVE_SEARCH_FILTERS_TAG:
      searchState = state.search;
      filtersState = _.cloneDeep(searchState.filters);

      filtersState[action.filter].map((id, index) => {
        if (id === action.value) {
          filtersState[action.filter] = [
            ...filtersState[action.filter].slice(0, index),
            ...filtersState[action.filter].slice(index + 1),
          ];
        }
      });

      return {
        ...state,
        search: {
          ...searchState,
          filters: filtersState,
        },
      };

    case ACTIONS.REMOVE_SEARCH_CATEGORIES_TAG:
      searchState = state.search;
      categoriesState = _.cloneDeep(searchState.categories);

      categoriesState[action.filter].map((id, index) => {
        if (id === action.value) {
          categoriesState[action.filter] = [
            ...categoriesState[action.filter].slice(0, index),
            ...categoriesState[action.filter].slice(index + 1),
          ];
        }
      });

      return {
        ...state,
        search: {
          ...searchState,
          categories: categoriesState,
        },
      };

    case ACTIONS.REMOVE_SEARCH_OTHERS_TAG:
      searchState = state.search;
      othersState = _.cloneDeep(searchState.others);

      othersState[action.filter].map((tag, index) => {
        if (tag === action.value) {
          othersState[action.filter] = [
            ...othersState[action.filter].slice(0, index),
            ...othersState[action.filter].slice(index + 1),
          ];
        }
      });

      return {
        ...state,
        search: {
          ...searchState,
          others: othersState,
        },
      };

    case ACTIONS.REMOVE_SEARCH_ALL_FILTERS:
      searchState = state.search;
      return {
        ...state,
        search: {
          ...searchState,
          filters: initialState.search.filters,
          categories: initialState.search.categories,
          others: initialState.search.others,
          searchString: '',
          featured: false,
        },
      };

    case ACTIONS.REQUEST_OFFERS:
      return {
        ...state,
        offersIsFetching: true,
        offersCurrentRequest: action.request,
      };

    case ACTIONS.RECEIVE_OFFERS:
      return {
        ...state,
        offers: action.payload.Offers ? action.payload.Offers.Data : [],
        offersReceivedAt: date.getTime(),
        offersIsFetching: false,
        initialOffersFetched: true,
        offersCurrentRequest: null,
        offersInfos: {
          ...offersInfos,
          total: action.payload.Offers ? action.payload.Offers.PageInfo.length : 0,
          pageCount: action.payload.Offers ? action.payload.Offers.PageInfo.lastPage + 1 : 0,
          currentPage: action.payload.Offers ? action.payload.Offers.PageInfo.currentPage + 1 : 0,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFERS:
      return {
        ...state,
        offersIsFetching: false,
        initialOffersFetched: true,
      };

    case ACTIONS.CHANGE_OFFERS_LIST_PAGE:
      searchState = state.search;

      return {
        ...state,
        search: {
          ...searchState,
          page: action.page,
        },
      };

    case ACTIONS.CHANGE_OFFERS_ROWS_PER_PAGE:
      searchState = state.search;

      return {
        ...state,
        search: {
          ...searchState,
          limit: action.limit,
          page: 1,
        },
      };

    case ACTIONS.CHANGE_OFFERS_LOCATIONS_QUERY:
      searchState = state.search;

      return {
        ...state,
        offersLocationQuery: action.query,
        search: {
          ...searchState,
          filters: {
            Country: action.query.country ? action.query.country.split(',') : [],
            Payout: action.query.payout ? action.query.payout.split(',') : [],
            MediaPlacement: action.query.mediaplacement ? action.query.mediaplacement.split(',') : [],
            Vertical: action.query.vertical ? action.query.vertical.split(',') : [],
            Targeting: action.query.targeting ? action.query.targeting.split(',') : [],
            Id: action.query.id ? action.query.id.split(',') : [],
          },
          searchString: action.query.search ? action.query.search : '',
          featured: !!action.query.featured,
          categories: {
            Niche: action.query.niche ? action.query.niche.split(',') : [],
            //Audience is overrided by profile settings
          },
          page: action.query.page ? action.query.page : 1,
          limit: action.query.limit ? action.query.limit : 25,
        },
      };

    default:
      return state;
  }
};

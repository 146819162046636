export default {
  'timezone.utc-12-DST': '(UTC-12:00) International Date Line West',
  'timezone.utc-11-U': '(UTC-11:00) Coordinated Universal Time-11',
  'timezone.utc-10-HST': '(UTC-10:00) Hawaii',
  'timezone.utc-8-AKDT': '(UTC-09:00) Alaska',
  'timezone.utc-7-PDT': '(UTC-08:00) Pacific Time (US & Canada)',
  'timezone.utc-7-UMST': '(UTC-07:00) Arizona',
  'timezone.utc-6-MDT-mexico': '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  'timezone.utc-6-MDT-us': '(UTC-07:00) Mountain Time (US & Canada)',
  'timezone.utc-6-CAST': '(UTC-06:00) Central America',
  'timezone.utc-5-CDT-us': '(UTC-06:00) Central Time (US & Canada)',
  'timezone.utc-5-CDT-mexico': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  'timezone.utc-6-CCST': '(UTC-06:00) Saskatchewan',
  'timezone.utc-5-SPST': '(UTC-05:00) Bogota, Lima, Quito',
  'timezone.utc-4-EDT': '(UTC-05:00) Eastern Time (US & Canada)',
  'timezone.utc-4-UEDT': '(UTC-05:00) Indiana (East)',
  'timezone.utc-4.5-VST': '(UTC-04:30) Caracas',
  'timezone.utc-4-PST': '(UTC-04:00) Asuncion',
  'timezone.utc-3-ADT': '(UTC-04:00) Atlantic Time (Canada)',
  'timezone.utc-4-CBST': '(UTC-04:00) Cuiaba',
  'timezone.utc-4-SWST': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  'timezone.utc-4-PSST': '(UTC-04:00) Santiago',
  'timezone.utc-2.5-NDT': '(UTC-03:30) Newfoundland',
  'timezone.utc-3-ESAST': '(UTC-03:00) Brasilia',
  'timezone.utc-3-AST': '(UTC-03:00) Buenos Aires',
  'timezone.utc-3-SEST': '(UTC-03:00) Cayenne, Fortaleza',
  'timezone.utc-2-GDT': '(UTC-03:00) Greenland',
  'timezone.utc-3-MST': '(UTC-03:00) Montevideo',
  'timezone.utc-3-BST': '(UTC-03:00) Salvador',
  'timezone.utc-2-U': '(UTC-02:00) Coordinated Universal Time-02',
  'timezone.utc-1-MDT': '(UTC-02:00) Mid-Atlantic - Old',
  'timezone.utc0-ADT': '(UTC-01:00) Azores',
  'timezone.utc-1-CVST': '(UTC-01:00) Cape Verde Is.',
  'timezone.utc1-MDT': '(UTC) Casablanca',
  'timezone.utc0-CUT': '(UTC) Coordinated Universal Time',
  'timezone.utc1-GDT': '(UTC) Dublin, Edinburgh, Lisbon, London',
  'timezone.utc0-GST': '(UTC) Monrovia, Reykjavik',
  'timezone.utc2-WEDT': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'timezone.utc2-CEDT': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  'timezone.utc2-RDT': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  'timezone.utc1-WCAST': '(UTC+01:00) West Central Africa',
  'timezone.utc1-NST': '(UTC+01:00) Windhoek',
  'timezone.utc3-GDT': '(UTC+02:00) Athens, Bucharest',
  'timezone.utc3-MEDT': '(UTC+02:00) Beirut',
  'timezone.utc2-EST': '(UTC+02:00) Cairo',
  'timezone.utc3-SDT': '(UTC+02:00) Damascus',
  'timezone.utc3-EEDT': '(UTC+02:00) E. Europe',
  'timezone.utc2-SAST': '(UTC+02:00) Harare, Pretoria',
  'timezone.utc3-FDT': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'timezone.utc3-TDT': '(UTC+02:00) Istanbul',
  'timezone.utc3-JDT': '(UTC+02:00) Jerusalem',
  'timezone.utc2-LST': '(UTC+02:00) Tripoli',
  'timezone.utc3-JST': '(UTC+03:00) Amman',
  'timezone.utc3-AST': '(UTC+03:00) Kuwait, Riyadh',
  'timezone.utc3-KST': '(UTC+03:00) Kaliningrad, Minsk',
  'timezone.utc3-EAST': '(UTC+03:00) Nairobi',
  'timezone.utc4.5-IDT': '(UTC+03:30) Tehran',
  'timezone.utc4-AST': '(UTC+04:00) Abu Dhabi, Muscat',
  'timezone.utc5-ADT': '(UTC+04:00) Baku',
  'timezone.utc4-RST': '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
  'timezone.utc4-MST': '(UTC+04:00) Port Louis',
  'timezone.utc4-GST': '(UTC+04:00) Tbilisi',
  'timezone.utc4-CST': '(UTC+04:00) Yerevan',
  'timezone.utc4.5-AST': '(UTC+04:30) Kabul',
  'timezone.utc5-WAST': '(UTC+05:00) Ashgabat, Tashkent',
  'timezone.utc5-PST': '(UTC+05:00) Islamabad, Karachi',
  'timezone.utc5.5-IST': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  'timezone.utc5.5-SLST': '(UTC+05:30) Sri Jayawardenepura',
  'timezone.utc5.75-NST': '(UTC+05:45) Kathmandu',
  'timezone.utc6-CAST': '(UTC+06:00) Astana',
  'timezone.utc6-BST': '(UTC+06:00) Dhaka',
  'timezone.utc6-EST': '(UTC+06:00) Ekaterinburg',
  'timezone.utc6.5-MST': '(UTC+06:30) Yangon (Rangoon)',
  'timezone.utc7-SAST': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  'timezone.utc7-NCAST': '(UTC+07:00) Novosibirsk',
  'timezone.utc8-CST': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  'timezone.utc8-NAST': '(UTC+08:00) Krasnoyarsk',
  'timezone.utc8-MPST': '(UTC+08:00) Kuala Lumpur, Singapore',
  'timezone.utc8-WAST': '(UTC+08:00) Perth',
  'timezone.utc8-TST': '(UTC+08:00) Taipei',
  'timezone.utc8-UST': '(UTC+08:00) Ulaanbaatar',
  'timezone.utc9-NAEST': '(UTC+09:00) Irkutsk',
  'timezone.utc9-TST': '(UTC+09:00) Osaka, Sapporo, Tokyo',
  'timezone.utc9-KST': '(UTC+09:00) Seoul',
  'timezone.utc9.5-CAST': '(UTC+09:30) Adelaide',
  'timezone.utc9.5-ACST': '(UTC+09:30) Darwin',
  'timezone.utc10-EAST': '(UTC+10:00) Brisbane',
  'timezone.utc10-AEST': '(UTC+10:00) Canberra, Melbourne, Sydney',
  'timezone.utc10-WPST': '(UTC+10:00) Guam, Port Moresby',
  'timezone.utc10-TST': '(UTC+10:00) Hobart',
  'timezone.utc10-YST': '(UTC+10:00) Yakutsk',
  'timezone.utc11-CPST': '(UTC+11:00) Solomon Is., New Caledonia',
  'timezone.utc11-VST': '(UTC+11:00) Vladivostok',
  'timezone.utc12-NZST': '(UTC+12:00) Auckland, Wellington',
  'timezone.utc12-U': '(UTC+12:00) Coordinated Universal Time+12',
  'timezone.utc12-FST': '(UTC+12:00) Fiji',
  'timezone.utc12-MST': '(UTC+12:00) Magadan',
  'timezone.utc13-KDT': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
  'timezone.utc13-TST': "(UTC+13:00) Nuku'alofa",
  'timezone.utc13-SST': '(UTC+13:00) Samoa',
};

import { getProvinceCode } from './list-options/provinces';
import { getStateCode } from './list-options/states';

export const buildAddressLabel = ({ address, city, region, postalCode, country }) => {
  if (!address && !city && !region && !postalCode) {
    return '';
  } else {
    return [`${address || ''}`, `${city || ''}`, `${region || ''}`, `${postalCode || ''}`, country]
      .filter((value) => !!value)
      .join(', ');
  }
};

export const formatAddress2ToSend = (address2) => {
  if (address2) {
    if (!address2.startsWith('#')) {
      return `#${address2}`;
    }
  }
  return address2;
};

export const formatAddress2Received = (address2) => {
  if (address2?.startsWith('#')) {
    return `${address2.slice(1)}`;
  }
  return address2;
};

export const sanitizeString = (value) =>
  value
    ? value
        .replace('  ', ' ')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : value;

export const getAddressPartsFromAWSPlaceData = (placeData, countryIsoCode2) => {
  if (placeData) {
    let address = `${placeData.AddressNumber ? placeData.AddressNumber + ' ' : ''}${placeData.Street || ''}`;
    let city = `${placeData.Municipality || ''}`;
    let region = `${placeData.Region || ''}`;
    const postalCode = `${placeData.PostalCode || ''}`;
    const unitNumber = `${placeData.UnitNumber || ''}`;
    if (placeData.Country === 'JPN') {
      if (!city) {
        city = `${placeData.Municipality || placeData.SubMunicipality || ''}`;
      }
      if (!placeData.Street) {
        const labelParts = placeData.Label.split(',').map((v) => v.trimStart().trim());
        const matchedStrings = Object.values(placeData).filter(
          (value) => typeof value === 'string' && labelParts.some((part) => part === value)
        );
        address = labelParts.filter((e) => !matchedStrings.includes(e)).join(' ');
      }
    }
    if (countryIsoCode2 === 'US') {
      region = getStateCode(sanitizeString(`${placeData.Region || ''}`));
    }
    if (countryIsoCode2 === 'CA') {
      region = getProvinceCode(sanitizeString(`${placeData.Region || ''}`));
    }
    return {
      address: sanitizeString(address),
      city: sanitizeString(city),
      postalCode: sanitizeString(postalCode),
      region: sanitizeString(region),
      unitNumber: sanitizeString(unitNumber),
    };
  }
  return {};
};

export const ADDRESS_MODE = Object.freeze({
  MANUAL: 'MANUAL',
  SEARCH: 'SEARCH',
});

export const addressMode = (isSearchMode) => (isSearchMode ? ADDRESS_MODE.SEARCH : ADDRESS_MODE.MANUAL);

import { cloneElement, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/pagination/pagination.min.css';

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Navigation, Autoplay } from 'swiper/core';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Navigation, Autoplay]);

export default function SwiperSlider({ children, verticalsAsString }) {
  let theChildren = null;
  let childrenCleaned = [];
  const carouselRef = useRef();
  const carousel = carouselRef?.current?.swiper;
  const currentSlideLink = useRef(null);

  const [active, setActive] = useState(false);

  if (children) {
    childrenCleaned = [];
    theChildren = children.map((child) => {
      const theChild = child;
      if (theChild.props.className) {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: theChild.props.className + ' swiper-slide',
          })
        );
      } else {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: 'swiper-slide',
          })
        );
      }

      return theChild;
    });
  }

  useEffect(() => {
    setActive(false);
  }, [verticalsAsString]);

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <Swiper
      grabCursor
      loop
      navigation={{
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      }}
      preventClicks={true}
      onTransitionEnd={(event) => {
        const threshold = 15;
        let touchLength = event?.touches?.diff;
        touchLength = Math.abs(touchLength);
        const targetLink = currentSlideLink.current;

        if (touchLength && touchLength < threshold && targetLink) {
          window.location.href = targetLink;
        }
      }}
      onTouchStart={(event, targetParams) => {
        const targetLink = targetParams?.target?.closest('a')?.href;
        currentSlideLink.current = targetLink;
      }}
      ref={carouselRef}
      slidesPerView="auto"
    >
      <div className="swiper-slider swiper-container">
        <div className="swiper-wrapper">
          {theChildren.map((child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </div>
      </div>
    </Swiper>
  );
}

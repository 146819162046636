import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestBlogPosts = () => ({
  type: ACTIONS.REQUEST_BLOG_POSTS,
});

const receiveBlogPosts = (data) => ({
  type: ACTIONS.RECEIVE_BLOG_POSTS,
  data,
});

const failedToReceiveBlogPosts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_BLOG_POSTS,
});

export const fetchBlogPosts = (query) => (dispatch) => {
  dispatch(requestBlogPosts());

  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/blog`)
      .withCredentials()
      .query(query)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveBlogPosts(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveBlogPosts());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import { Link } from 'react-router';

const CompleteProfileComponent = () => (
  <div className="complete-profile-component">
    <h2>Please start by completing your profile</h2>
    <Link className="btn" onlyActiveOnIndex={false} to="/profile/user-details">
      Go to user details
    </Link>
  </div>
);

export default CompleteProfileComponent;

import React from 'react';

export const BannerRating = React.memo(({ rating }) => {
  if (!rating || rating < 3) {
    rating = 3;
  } else if (rating > 5) {
    rating = 5;
  }

  const arrayCount = [...Array(rating).keys()];

  //TEMP TURN OFF RATING
  return null;

  return (
    <div className="bannerRating">
      {arrayCount.map((count) => (
        <svg key={count} xmlns="http://www.w3.org/2000/svg" width="16.401" height="16.401" viewBox="0 0 16.401 16.401">
          <path
            id="trending-gold"
            d="M15.619,10.566a.6.6,0,0,1-.168.022.646.646,0,0,1-.625-.478L14.5,8.925l-2.341,4.067a.648.648,0,0,1-.884.238L8.987,11.913,7.511,14.476a.647.647,0,1,1-1.122-.646l1.8-3.125a.65.65,0,0,1,.884-.238l2.292,1.318,2.018-3.507-1.19.32a.648.648,0,1,1-.335-1.252l2.7-.724a.517.517,0,0,1,.082-.005.646.646,0,0,1,.168-.011,46698.414,46698.414,0,0,1,.241.08.6.6,0,0,1,.129.113.542.542,0,0,1,.058.051.648.648,0,0,1,.117.236l.726,2.686A.646.646,0,0,1,15.619,10.566ZM14.63,2.5H7.77A4.419,4.419,0,0,0,3,7.059v7.285A4.419,4.419,0,0,0,7.77,18.9h6.86A4.419,4.419,0,0,0,19.4,14.343V7.059A4.419,4.419,0,0,0,14.63,2.5Z"
            transform="translate(-3 -2.5)"
            fill="#ffd701"
            fill-rule="evenodd"
          />
        </svg>
      ))}
    </div>
  );
});

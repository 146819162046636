import { Component } from 'react';
import PropTypes from 'prop-types';

import messages from '../../i18n/base-en.js';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';

/** Section d'informations de l'utilisateur dans le header du profil */
class ProfileUserBlock extends Component {
  render() {
    const { userInfos, payableTo, statusRankName, skinConfigurations } = this.props;
    const infosClasses = payableTo === 'Company' ? 'in#fos company' : 'infos';
    const skin = new SkinConfigurationsUtils(skinConfigurations);

    return (
      <div className="row title-block">
        <div className="col-md-12">
          <div className="circle hidden-circle">
            <a className="picture-btn" href="#photoUploadModal">
              <i className="material-icons">&#xE412;</i>
            </a>
          </div>
          <div className={infosClasses}>
            <h1>
              {userInfos.first_name} {userInfos.last_name}
            </h1>
            <div className="profile-header-aff-id">
              {skin.getPropertyValue('Profile', 'affiliateIdLabel', 'affiliateId') || messages.genericAffiliateId.description}:{' '}
              {userInfos.affiliate_id}
            </div>
            <div className="subtitle primary-lighter-color">{statusRankName}</div>
          </div>
        </div>
      </div>
    );
  }
}

ProfileUserBlock.propTypes = {
  payableTo: PropTypes.any.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  statusRankName: PropTypes.string,
  userInfos: PropTypes.object.isRequired,
};

export default ProfileUserBlock;

import consts from '../../../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  promoTools: [],
  promoToolsIsFetching: false,
};

export const promoToolsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.PROMOTOOLS.FAILED_TO_RECEIVE_PROMO_TOOLS:
      return {
        ...state,
        promoToolsIsFetching: false,
      };

    case ACTIONS.PROMOTOOLS.RECEIVE_PROMO_TOOLS:
      return {
        promoTools: action.data,
        promoToolsIsFetching: false,
      };
    case ACTIONS.PROMOTOOLS.REQUEST_PROMO_TOOLS:
      return {
        ...state,
        promoToolsIsFetching: true,
      };

    default:
      return state;
  }
};

const Gear = () => (
  <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M34.6777 12.7059L33.6403 10.9058C32.7627 9.38259 30.8178 8.85713 29.2925 9.73112C28.5665 10.1588 27.7001 10.2802 26.8845 10.0684C26.0688 9.85663 25.371 9.32912 24.9447 8.60221C24.6705 8.14017 24.5231 7.61392 24.5175 7.07666C24.5423 6.21529 24.2173 5.3806 23.6167 4.76271C23.016 4.14482 22.1909 3.79636 21.3291 3.79672H19.2391C18.3949 3.79672 17.5855 4.13312 16.99 4.7315C16.3944 5.32989 16.0619 6.14091 16.066 6.98513C16.041 8.72813 14.6208 10.1279 12.8776 10.1278C12.3403 10.1222 11.8141 9.97483 11.352 9.70061C9.82673 8.82662 7.88181 9.35208 7.00419 10.8753L5.89054 12.7059C5.01398 14.2272 5.53229 16.1709 7.04996 17.0538C8.03646 17.6233 8.64418 18.6759 8.64418 19.815C8.64418 20.9541 8.03646 22.0067 7.04996 22.5763C5.53422 23.4532 5.01534 25.3921 5.89054 26.9088L6.94317 28.7243C7.35437 29.4662 8.0443 30.0138 8.86028 30.2457C9.67626 30.4776 10.551 30.3748 11.291 29.96C12.0184 29.5355 12.8853 29.4192 13.6989 29.6369C14.5124 29.8546 15.2054 30.3883 15.6236 31.1194C15.8978 31.5814 16.0451 32.1077 16.0507 32.6449C16.0507 34.4058 17.4782 35.8333 19.2391 35.8333H21.3291C23.0841 35.8333 24.5091 34.4151 24.5175 32.6602C24.5135 31.8133 24.8481 31 25.4469 30.4011C26.0457 29.8023 26.8591 29.4677 27.7059 29.4718C28.2419 29.4861 28.766 29.6329 29.2315 29.8989C30.7528 30.7755 32.6965 30.2572 33.5793 28.7395L34.6777 26.9088C35.1029 26.1791 35.2196 25.3099 35.002 24.4939C34.7843 23.6778 34.2504 22.9822 33.5183 22.561C32.7862 22.1399 32.2523 21.4442 32.0346 20.6282C31.817 19.8121 31.9337 18.943 32.3589 18.2132C32.6354 17.7305 33.0356 17.3303 33.5183 17.0538C35.0269 16.1714 35.544 14.2391 34.6777 12.7212V12.7059Z"
      fillRule="evenodd"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M20.2918 24.2086C22.7183 24.2086 24.6854 22.2415 24.6854 19.815C24.6854 17.3885 22.7183 15.4214 20.2918 15.4214C17.8653 15.4214 15.8982 17.3885 15.8982 19.815C15.8982 22.2415 17.8653 24.2086 20.2918 24.2086Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default Gear;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Gtm } from '../../utils/gtm';
import intercom from '../../utils/intercom';
import messages from '../../i18n/base-en.js';

class MarchChallenge extends Component {
  componentDidMount() {
    intercom.trackEvent('Challenge-March-2021');
    Gtm.event('Page View', 'View', 'Challenge-March-2021-Registration');
  }

  getFullName(userFirstName, userLastName) {
    return `${userFirstName} ${userLastName}`;
  }

  createListItems(items) {
    return Object.values(items).map((message) => (
      <li key={message.id}>
        <FormattedMessage {...message} />
      </li>
    ));
  }

  render() {
    const { userFirstName, userLastName } = this.props;

    return (
      <div className="app-container" id="march-challenge">
        <div className="content">
          <h1 className="header primary-color">
            <FormattedMessage {...messages.marchChallengePage.title} />
          </h1>

          <div className="subscription">
            <h2 className="march-important">
              <FormattedMessage {...messages.marchChallengePage.subscriptionConfirmation.title} />
            </h2>
            <p>
              <FormattedMessage
                {...messages.marchChallengePage.subscriptionConfirmation.description.line1}
                values={{
                  fullName: this.getFullName(userFirstName, userLastName),
                }}
              />
              <br />
            </p>
          </div>

          <div className="challenge-description container-fluid">
            <img
              alt={messages.marchChallengePage.title.defaultMessage}
              className="col-md-12 col-lg-6"
              src="/img/marchChallenge/banner-lp-march-contest.jpg"
            />
          </div>

          <div className="rules">
            <h2>
              <FormattedMessage {...messages.marchChallengePage.challengeRules.title} />
            </h2>
            <ol>
              <li>
                <b>Join</b> the contest (which you just did!)
              </li>
              <li>
                <b>Promote</b> at least one of these top brands and generate a minimum monthly payout of{' '}
                <b>Jerkmate, MyFreeCams, Dating Smartlink, and SexEmulator.</b>
              </li>
              <p>
                Get a chance to win 1 of 4 Basketball bundles, including a high-quality jersey, a CrakRevenue game ball, and a
                custom pair of sneakers!{' '}
              </p>
            </ol>
          </div>

          <div className="terms-and-conditions">
            <h2>
              <FormattedMessage {...messages.marchChallengePage.termsAndConditions.title} />
            </h2>
            <ol>
              {this.createListItems({
                ...messages.marchChallengePage.termsAndConditions.points,
              })}
              <li>
                The payment of commissions is done at CrakRevenue&quot;s sole discretion, in compliance with{' '}
                <a href="http://www.crakrevenue.com/tos/" rel="noreferrer" target="_blank">
                  CrakRevenue&quot;s T.o.S.
                </a>{' '}
                and can be cancelled at any time, and without any prior notice.
              </li>
            </ol>
          </div>

          <div className="challenge-description container-fluid">
            <img
              alt={messages.marchChallengePage.title.defaultMessage}
              className="col-md-12 col-lg-6"
              src="/img/marchChallenge/logos-lp-march-contest.jpg"
            />
          </div>

          <div className="ready">
            <h2>
              <FormattedMessage {...messages.marchChallengePage.ready.title} />
            </h2>
            <p>
              You are all set and ready to go, head over to our <a href="/offers#offers-list">Offers page</a> right now and make
              your way to top level ASAP!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

MarchChallenge.propTypes = {
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
};

export default connect((state) => ({
  userFirstName: state.profile.data.affUserInfos.first_name,
  userLastName: state.profile.data.affUserInfos.last_name,
}))(MarchChallenge);

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import RestrictedIcon from '../../../reusables/svg/RestrictedIcon.react';
import XIcon from '../../../reusables/svg/XIcon.react';

import messages from '../../../../i18n/base-en.js';

const OfferRestrictions = ({ offerRestrictions }) => (
  <div id="offer-restrictions">
    <h4>
      <div className="title">
        <RestrictedIcon />
        <FormattedMessage {...messages.offerRestrictions} />
      </div>
    </h4>
    <div className="restrictions">
      <div className="label" />
      <div>
        <ul>
          {offerRestrictions.map((restriction) => (
            <li key={restriction}>
              <div className="ico">
                <XIcon />
              </div>
              {restriction}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

OfferRestrictions.propTypes = {
  offerRestrictions: PropTypes.array.isRequired,
};

export default OfferRestrictions;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import messages from '../../../i18n/base-en.js';

import { InputText } from '../../../components/reusables';

function PaypalSection({ fields }) {
  useEffect(() => {}, []);
  return (
    <div className="paypal_section">
      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={fields.paypalEmail}
          id="paypalEmail"
          label={messages.paymentInfosPaypalEmailLabel}
          tabIndex={30}
          type="email"
        />
      </div>
    </div>
  );
}

PaypalSection.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default PaypalSection;

const XIcon = () => (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <title>X</title>
    <g data-name="Calque 2" id="Calque_2">
      <g data-name="Calque 1" id="Calque_1-2">
        <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
      </g>
    </g>
  </svg>
);

export default XIcon;

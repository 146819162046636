import PropTypes from 'prop-types';

const getClassName = ({ classNames, isButtonStyled }) => {
  const newClassNames = ['standard-button', ...classNames];
  if (isButtonStyled) {
    newClassNames.push('btn-styled');
  }
  return [...new Set(newClassNames)].join(' ');
};

const StandardButton = ({ classNames, icon, isButtonStyled, label, onClick }) => (
  <div
    className={getClassName({ classNames, isButtonStyled })}
    onClick={() => {
      if (onClick) {
        onClick();
      }
    }}
  >
    {icon ? <span className="btn-icon">{icon}</span> : null}
    {label ? <span className="btn-label">{label}</span> : null}
  </div>
);

StandardButton.defaultProps = {
  classNames: [],
  isButtonStyled: true,
};

StandardButton.propTypes = {
  classNames: PropTypes.array,
  icon: PropTypes.any,
  isButtonStyled: PropTypes.bool,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default StandardButton;

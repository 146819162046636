import consts from '../utils/consts';
const { APP_NAME } = consts;

export default class SkinConfigurationsUtils {
  static getColors(skinConfigurations) {
    const skin = new SkinConfigurationsUtils(skinConfigurations);
    return {
      BACKGROUND_COLOR: skin.getPropertyValue('Global', 'default', 'background-color') || '#F8F8F8',
      CONTAINER: {
        HEADER: {
          BACKGROUND_COLOR: skin.getPropertyValue('Container', 'header', 'background-color') || '#333',
          ICON_COLOR: skin.getPropertyValue('Container', 'header', 'icon-color') || '#00BD00',
        },
      },
      FOOTER: {
        BACKGROUND_COLOR: skin.getPropertyValue('Footer', 'default', 'background-color') || '#333',
      },
      HEADER: {
        BURGER_MENU: skin.getPropertyValue('Header', 'burgerMenu', 'color') || '#fff',
        BACKGROUND_COLOR: skin.getPropertyValue('Header', 'default', 'background-color') || '#333',
        COLOR: skin.getPropertyValue('Header', 'default', 'color') || '#FFF',
        LINKS_HOVER: skin.getPropertyValue('Header', 'links:hover', 'color') || '#FFF',
        RANK_STATUS: {
          BACKGROUND_COLOR: skin.getPropertyValue('Header', 'RankStatus', 'background-color') || '#606060',
          FILL: skin.getPropertyValue('Header', 'RankStatus', 'fill') || '#FFF',
          COLOR: skin.getPropertyValue('Header', 'RankStatus', 'color') || '#87E764',
        },
        PAYMENT_HISTORY_GRAPH: {
          CAPTION: skin.getPropertyValue('Header', 'PaymentHistoryGraph.caption', 'color') || '#fff',
          MINIMUM_PAYOUT_COLOR: skin.getPropertyValue('Header', 'PaymentHistoryGraph.minimum-payout', 'color') || '#8c8c8c',
          MINIMUM_PAYOUT_COMPLETED_COLOR:
            skin.getPropertyValue('Header', 'PaymentHistoryGraph.minimum-payout:completed', 'color') || '#fff',
          RANK_STATUS_COMPLETED_COLOR:
            skin.getPropertyValue('Header', 'PaymentHistoryGraph.rank-status-icon:completed', 'fill') || '#87e764',
          GAUGE: {
            BACKGROUND_IMAGE_FILLED:
              skin.getPropertyValue('Header', 'PaymentHistoryGraph.gauge-fill', 'background-image') ||
              'linear-gradient(to top, #87e764, #92fc92)',
            BACKGROUND_COLOR_UNFILLED:
              skin.getPropertyValue('Header', 'PaymentHistoryGraph.gauge-bkg', 'background-color') || 'W252525',
            COLOR_TICKS: skin.getPropertyValue('Header', 'PaymentHistoryGraph.gauge-ticks', 'border-color') || '#32b91f',
          },
          DAYS_LEFT: {
            COLOR: skin.getPropertyValue('Header', 'PaymentHistoryGraph.days-left', 'color') || '#87E764',
            HURRY_UP_COLOR: skin.getPropertyValue('Header', 'PaymentHistoryGraph.days-left.hurry-up', 'color') || '#fe2f56',
          },
        },
        PAGE_HEADER: {
          BACKGROUND_COLOR: skin.getPropertyValue('Header', 'page-header', 'background-color') || '#333333',
        },
      },
      MAIN_MENU: {
        BACKGROUND_COLOR: skin.getPropertyValue('Menu', 'default', 'background-color') || '#252525',
        BACKGROUND_COLOR_ACTIVE: skin.getPropertyValue('Menu', 'links:active', 'background-color') || '#252525',
        BACKGROUND_COLOR_HOVER: skin.getPropertyValue('Menu', 'links:hover', 'background-color') || '#00BD00',
        BACKGROUND_COLOR_EXTERNAL: skin.getPropertyValue('Menu', 'external-links', 'background-color') || '#292929',
        BORDER_COLOR_EXTERNAL: skin.getPropertyValue('Menu', 'external-links', 'border-color') || '#202020',
        BACKGROUND_COLOR_ACCOUNT_MANAGER: skin.getPropertyValue('Menu', 'AccountManager', 'background-color') || 'transparent',
        COLOR: skin.getPropertyValue('Menu', 'default', 'color') || '#FFF',
        COLOR_HOVER: skin.getPropertyValue('Menu', 'links:hover', 'color') || '#FFF',
        COLOR_EXTERNAL: skin.getPropertyValue('Menu', 'external-links', 'color') || '#919090',
      },
      PAYMENT_HISTORY: {
        NOTICE: {
          COLOR: skin.getPropertyValue('Profile', 'PaymentHistoryWidget.notice', 'color') || '#FFFFFF',
        },
      },
      PRIMARY_COLOR: {
        DARKER: skin.getPropertyValue('Global', 'PrimaryColor', 'darker') || '#108210',
        DEFAULT: skin.getPropertyValue('Global', 'PrimaryColor', 'main') || '#00BD00',
        LIGHTER: skin.getPropertyValue('Global', 'PrimaryColor', 'lighter') || '#87E764',
        LIGHTEST: skin.getPropertyValue('Global', 'PrimaryColor', 'lightest') || '#92fc92',
      },
      GRAY: {
        DARK: skin.getPropertyValue('Global', 'Gray', 'dark') || '#333333',
      },
      TELL_US_ABOUT_YOU: {
        PROMOCODE_BACKGROUND: skin.getPropertyValue('TellUsAboutYou', 'promocode', 'background-color') || 'rgba(7, 181, 0, 0.1)',
      },
    };
  }

  static getComponentParameters(skinConfigurations, componentName) {
    return new SkinConfigurationsUtils(skinConfigurations).getComponentParameters(componentName);
  }

  static getHyperlinksConfig(skinConfigurations, componentName, parameterKeys) {
    const skin = new SkinConfigurationsUtils(skinConfigurations);

    const val = {};
    parameterKeys.forEach((parameterKey) => {
      val[parameterKey] = {};
      val[parameterKey].visible = skin.isItemVisible(componentName, parameterKey);
      if (val[parameterKey].visible) {
        val[parameterKey].url = skin.getPropertyValue(componentName, parameterKey, 'url');
      }
    });
    return val;
  }

  static getMenuItems(skinConfigurations) {
    const skin = new SkinConfigurationsUtils(skinConfigurations);
    return {
      AFFILIATE_MANAGER_IS_VISIBLE: skin.isMenuItemVisible('HomeAccountManager'),
      DASHBOARD_IS_VISIBLE: skin.isMenuItemVisible('dashboard'),
      STATISTICS_IS_VISIBLE: skin.isMenuItemVisible('statistics'),
      OFFERS_IS_VISIBLE: skin.isMenuItemVisible('offers'),
      MFC_IS_VISIBLE: skin.isMenuItemVisible('mfc'),
      LIVE_CAM_WIDGET_IS_VISIBLE: skin.isMenuItemVisible('live-cam-widget'),
      SMARTLINK_IS_VISIBLE: skin.isMenuItemVisible('smartlink'),
      SURVEY_MACHINE_IS_VISIBLE: skin.isMenuItemVisible('survey-machine'),
      REFERRALS_IS_VISIBLE: skin.isMenuItemVisible('referrals'),
      PAYMENT_HISTORY_IS_VISIBLE: skin.isMenuItemVisible('payment-history'),
      PROFILE_IS_VISIBLE: skin.isMenuItemVisible('profile'),
      KNOWLEDGE_BASE_IS_VISIBLE: skin.isMenuItemVisible('knowledge-base'),
      AFFILIATE_RESOURCES_IS_VISIBLE: skin.isMenuItemVisible('affiliate-resources'),
      PROMOTOOLS_IS_VISIBLE: skin.isMenuItemVisible('promotools'),
      BLOG_IS_VISIBLE: skin.isMenuItemVisible('blog'),
      LOGOUT_IS_VISIBLE: skin.isMenuItemVisible('logout'),
    };
  }

  static getSkinName(skinConfigurations) {
    return new SkinConfigurationsUtils(skinConfigurations).getSkinName();
  }

  static isItemVisible(skinConfigurations, componentName, parameterKey) {
    return new SkinConfigurationsUtils(skinConfigurations).isItemVisible(componentName, parameterKey);
  }

  static manageAccess(browserHistory, skinConfigurations, routeName) {
    const skin = new SkinConfigurationsUtils(skinConfigurations);
    skin.manageAccess(browserHistory, routeName);
  }

  constructor(skinConfigurations) {
    this.skinConfigurations = skinConfigurations;
  }

  getComponentParameters(componentName) {
    try {
      return this.skinConfigurations.components.find((comp) => comp.name === componentName).parameters;
    } catch (e) {
      return [];
    }
  }

  getDefaultPage() {
    const fallbackPage = '/';
    try {
      const defaultPage = this.getPropertyValue('Global', 'default', 'page');
      if ([null, undefined, 'undefined', ''].indexOf(defaultPage) >= 0) {
        return fallbackPage;
      }
      return defaultPage;
    } catch (e) {
      return fallbackPage;
    }
  }

  getPropertyValue(componentName, key, property) {
    try {
      return this.getComponentParameters(componentName).find((param) => param.key === key && param.property === property).value;
    } catch (e) {
      return null;
    }
  }

  getSkinName() {
    try {
      return this.skinConfigurations.name || APP_NAME;
    } catch (e) {
      return APP_NAME;
    }
  }

  isItemMinify(componentName, parameterKey) {
    try {
      return this.getPropertyValue(componentName, parameterKey, 'minify').toLowerCase() === 'true';
    } catch (e) {
      return false;
    }
  }

  isItemVisible(componentName, parameterKey) {
    try {
      return this.getPropertyValue(componentName, parameterKey, 'visible').toLowerCase() !== 'false';
    } catch (e) {
      return true;
    }
  }

  isMenuItemVisible(parameterKey) {
    return this.isItemVisible('Menu', parameterKey);
  }

  hasAccessTo(routeName) {
    try {
      return this.getPropertyValue('Routes', routeName, 'hasAccess').toLowerCase() !== 'false';
    } catch (e) {
      return true;
    }
  }

  manageAccess(browserHistory, routeName) {
    if (!this.hasAccessTo(routeName)) {
      browserHistory.push(this.getDefaultPage());
    }
  }
}

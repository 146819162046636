export default {
  'loginPage.title': 'Se connecter',
  'loginPage.button': 'Se connecter',
  'loginPage.forgottenPassword': 'Mot de passe oublié',
  'loginFormLabel.email': 'Votre adresse courriel',
  'loginFormLabel.password': 'Votre mot de passe',
  'loginFormLabel.emailOrPasswordAreNotValid': 'Email or password are not valid',
  'loginFormLabel.accountInValidation': "Your account is in validation, you will receive an email when it's ready!",
  'loginFormLabel.lackOfPermissions': 'Affiliate lacks permission to have an API Key',
  'loginFormLabel.cantOverride': 'Affiliate lacks permission to have an API Key',
  'loginFormLabel.stepVerification': '2-Step Verification',
  'loginFormLabel.stepVerificationText': 'You are trying to log into Crakrevenue account with this device for the first time.',
  'loginFormLabel.stepVerificationText2':
    "For security reasons, please enter the code you've received by email to access your account.",
  'loginFormLabel.stepVerificationTextNotReceiving': '*NOT RECEIVING YOUR CODE?',
  'loginFormLabel.stepVerificationText3':
    'Most people receive their code right away, but in some cases, it can take several minutes (or over 20 in extreme cases) to get it. Thank you for your understanding.',
  'loginPage.startSession': 'Start a session',
  'loginPage.goBackToWebsite': 'Go back to website',
  'loginPage.goBackToLogin': 'Go back to login',
  'loginPage.niceToSeeYouAgain': 'Nice to see you again!',
  'loginPage.niceToMeetYou': 'Nice to Meet You',
  'loginPage.DontHaveAnAccountYet': "Don't have an account Yet",
  'loginPage.AreYouLookingForCrakrevenueLegacy': 'Are you looking for Crakrevenue Legacy?',
  'loginPage.AccessHere': 'Access Here',
  'loginPage.IWantToBuyTraffic': 'I want to make $$$ with my traffic',
  'loginPage.IWantToSellTraffic': 'I want to add my offer / brand',
  'loginPage.AlreadyHaveAnAccount': 'You Already Have An Account ?',
};

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputText, Radio } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm.js';

const { genericTextPx, LiveCamWidgetPage } = messages;

const ThumbnailsSettingsCorner = ({ fields: { cornerFlag, cornerRadius } }) => {
  const disabled = cornerFlag.value === '0';

  return (
    <div className="settings-group" id="corner-section">
      <h2 className="primary-color">
        <FormattedMessage {...LiveCamWidgetPage.thumbnailsSettings.cornerSection.title.text} />
      </h2>

      <div className="radio-buttons horizontal">
        <Radio
          field={cornerFlag}
          id="widget-corner-flag-off"
          label={LiveCamWidgetPage.fields.genericChoices.default}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `corner default`);
          }}
          value="0"
        />
        <Radio
          field={cornerFlag}
          id="widget-corner-flag-on"
          label={LiveCamWidgetPage.fields.genericChoices.custom}
          onClick={() => {
            Gtm.event('live cam widget', 'Click', `corner custom`);
          }}
          value="1"
        />
      </div>

      <div>
        <div className={`with-units ${disabled ? 'disabled' : ''}`}>
          <InputText
            disabled={disabled}
            field={cornerRadius}
            id="widget-corner-radius"
            label={LiveCamWidgetPage.fields.cornerRadius.label}
            labelClasses="active"
            max={9999}
            min={0}
            type="number"
          />

          <span className="unit-label">
            <FormattedMessage {...genericTextPx} />
          </span>
        </div>
      </div>
    </div>
  );
};

ThumbnailsSettingsCorner.propTypes = {
  fields: PropTypes.shape({
    cornerFlag: PropTypes.object,
    cornerRadius: PropTypes.object,
  }),
};

export default injectIntl(ThumbnailsSettingsCorner);

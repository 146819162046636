import PropTypes from 'prop-types';

const OffersImage = (props) => {
  const { alt, height, thumbnail, width, fontSize, borderRadius } = props;

  return (
    <div className="offers-image">
      {thumbnail ? (
        <img alt={alt} className="offers-thumbnail" src={thumbnail.thumbnail} style={{ width, borderRadius }} width={width} />
      ) : (
        <div className="no-preview-image" style={{ width, height, lineHeight: height, fontSize, borderRadius }}>
          Preview
        </div>
      )}
    </div>
  );
};

OffersImage.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.any,
  thumbnail: PropTypes.object,
  width: PropTypes.any,
};

export default OffersImage;

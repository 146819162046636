import React, { useCallback } from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import { SELECTABLE_TAB_CSS_NAME } from './values';
import { Gtm } from '../../../utils/gtm';

const FilterTab = React.memo(({ text, value, field }) => {
  const { activeFilters, distpachSearchFormChange, offersIsFetching } = useOffersFilterContext();

  const dropdownFilters = activeFilters?.[field] || [];
  const activeFilter = dropdownFilters?.includes(value);

  const setFilter = useCallback(() => {
    if (offersIsFetching) {
      return;
    }

    const gtmField = field.toLowerCase();
    const gtmValue = text.toLowerCase();

    Gtm.event('offers', 'Click', `${gtmField} - ${gtmValue}`);

    let filters = [...dropdownFilters];

    if (!filters.includes(value)) {
      filters.push(value);
    } else {
      filters = dropdownFilters.filter((id) => id !== value);
    }

    distpachSearchFormChange({ field, filters });
  }, [field, value, text, dropdownFilters, offersIsFetching]);

  return (
    <div
      className={`${SELECTABLE_TAB_CSS_NAME} filterTab ${activeFilter ? 'active' : ''}`}
      onClick={() => setFilter({ field, value })}
    >
      <div className="text">{text}</div>
      <svg className="check" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
      </svg>
    </div>
  );
});

export default FilterTab;

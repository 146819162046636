/**
 * @file Formulaire d'inscription - Connection infos
 * @author Mikael Boutin
 * @version 0.0.1
 */

import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import validate from '../validateProfileInfos';
import provincesList from '../../../utils/list-options/provinces';
import statesList from '../../../utils/list-options/states';
import NameSection from './company-infos/NameSection.react';
import CompanySection from './company-infos/CompanySection.react';

import messages from '../../../i18n/base-en.js';
import { Gtm } from '../../../utils/gtm';
import UserInfoAutoSuggestionAddressReact from './UserInfoAutoSuggestionAddress.react.js';
import { getCountryLabelDefaultMessage } from '../../../utils/list-options/countries.js';
import { ADDRESS_MODE, addressMode, buildAddressLabel } from '../../../utils/addressHelper.js';
import _ from 'lodash';
import AddressSectionReact from './company-infos/AddressSection.react.js';
import { InputText } from '../../../components/reusables/index.js';
import PersonnalInfo from './svg/PersonnalInfo.react';
import Arrow from './svg/Arrow.react';
import Warning from './svg/Warning.react';

/**
 * Formulaire d'inscription - Connection infos
 */

function ProfileInfos({ fields, values, handleSubmit, payableTo, countries, dispatch, invalid, errors, isOpen, setIsOpen }) {
  const [currentProfileValues, setCurrentProfileValues] = useState(fields._meta.values);
  const [isFetchingAddressData, setIsFetchingAddressData] = useState(false);
  const [isAddressModeSearchTool, setIsAddressModeSearchTool] = useState(true);
  const [isSuggestedAddressComplete, setIsSuggestedAddressComplete] = useState(undefined);
  const [inviteUserToUseAddressTool, setInviteUserToUseAddressTool] = useState(true);
  const [addressAnimation, setAddressAnimation] = useState({
    fadeIn: addressMode(isAddressModeSearchTool),
    fadeOut: null,
  });

  const { fadeIn, fadeOut } = addressAnimation;

  const startSetIsAddressModeSearchTool = (isSearchMode) => {
    if (isSearchMode === isAddressModeSearchTool) {
      return;
    }

    setAddressAnimation((state) => ({
      ...state,
      fadeOut: state.fadeIn,
    }));

    setTimeout(() => {
      setIsAddressModeSearchTool(isSearchMode);
    }, 300);
  };

  const newValuesToSubmit = useMemo(
    () =>
      !_.isEqual(
        {
          ...currentProfileValues,
          address_label: undefined,
          autocompleted_address_label: undefined,
          is_affiliate_address_autocompleted: undefined,
        },
        {
          ...fields._meta.values,
          address_label: undefined,
          autocompleted_address_label: undefined,
          is_affiliate_address_autocompleted: undefined,
        }
      ),
    [currentProfileValues, fields._meta.values]
  );

  const handleGtmEvent = () => {
    if (fields.country.value !== fields.country.initialValue) {
      const eventLabel = countries.filter(({ code }) => fields.country.value.includes(code));
      eventLabel.map((country) => Gtm.event('profile - user details', 'Click', `country - ${country.name}`));
    }

    if (fields.province.value !== fields.province.initialValue) {
      const eventLabel = provincesList.filter(({ value }) => fields.province.value.includes(value));
      eventLabel.map((province) => Gtm.event('profile - user details', 'Click', `province - ${province.label.description}`));
    }

    if (fields.state.value !== fields.state.initialValue) {
      const eventLabel = statesList.filter(({ value }) => fields.state.value.includes(value));
      eventLabel.map((state) => Gtm.event('profile - user details', 'Click', `state - ${state.label.description}`));
    }
  };

  useEffect(() => {
    if (isSuggestedAddressComplete !== undefined) {
      if (!isSuggestedAddressComplete || (isSuggestedAddressComplete && invalid && !errors.address_label)) {
        if (inviteUserToUseAddressTool) {
          setInviteUserToUseAddressTool(false);
        }
        if (isAddressModeSearchTool) {
          startSetIsAddressModeSearchTool(false);
        }
      }
    }
  }, [errors.address_label, invalid, isSuggestedAddressComplete, setInviteUserToUseAddressTool]);

  useEffect(() => {
    if (!isAddressModeSearchTool) {
      Gtm.event('profile - user details', 'Click', `customize address manually - current country ${fields.country.value}`);
    } else {
      Gtm.event('profile - user details', 'Click', `use address validation tool - ${fields.country.value}`);
    }
  }, [isAddressModeSearchTool, fields.country, isSuggestedAddressComplete]);

  const resetAddressValues = (countryValue) => {
    if (countryValue === fields.country.initialValue) {
      fields.address.onChange(fields.address.initialValue);
      fields.city.onChange(fields.city.initialValue);
      fields.region.onChange(fields.region.initialValue);
      fields.province.onChange(fields.province.initialValue);
      fields.state.onChange(fields.state.initialValue);
      fields.zipcode.onChange(fields.zipcode.initialValue);
      fields.address_label.onChange(fields.address_label.initialValue);
    } else {
      fields.address.onChange('');
      fields.city.onChange('');
      fields.region.onChange('');
      fields.province.onChange('');
      fields.state.onChange('');
      fields.zipcode.onChange('');
      fields.address_label.onChange('');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAddressAnimation((state) => ({
        ...state,
        fadeIn: addressMode(isAddressModeSearchTool),
      }));
    }, 2);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAddressModeSearchTool]);

  const countryOnChange = (event) => {
    fields.country.onChange(event);
    resetAddressValues(event);
  };

  return (
    <div className="profile-infos">
      <div className="info-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="header-wrapper" />
        <PersonnalInfo />
        <div className="profile-info">
          <h1>
            <FormattedMessage {...messages.profileMenuPersonalInformation} />
          </h1>
          <span className="profile-info-span">
            Please provide the following details to complete your profile. Complete information ensures a strong partnership.
          </span>
        </div>
        <div className="icons">
          <Warning className={invalid ? 'invalid' : ''} />
          <Arrow className={isOpen ? 'open' : ''} />
        </div>
      </div>
      <form action="#" className={isOpen ? 'open' : ''} method="post" onSubmit={handleSubmit}>
        <span className="profile-info-span-mobile">
          Please provide the following details to complete your profile. Complete information ensures a strong partnership.
        </span>
        <div className="">
          <NameSection dispatch={dispatch} fields={fields} values={values} />
          <CompanySection dispatch={dispatch} fields={fields} values={values} willRender={payableTo === 'Company'} />
          {isAddressModeSearchTool ? (
            <div
              className={`valid-address-information-box ${fadeIn === ADDRESS_MODE.SEARCH ? 'fadeIn' : ''} ${
                fadeOut === ADDRESS_MODE.SEARCH ? 'fadeOut' : ''
              }`}
            >
              <UserInfoAutoSuggestionAddressReact
                countries={countries}
                fields={fields}
                onChangeCountry={countryOnChange}
                setIsAddressModeSearchTool={startSetIsAddressModeSearchTool}
                setIsFetchingAddressData={setIsFetchingAddressData}
                setIsSuggestedAddressComplete={setIsSuggestedAddressComplete}
              />
              <div className="row">
                <InputText
                  className="col-md-12"
                  displayErrorInstantly
                  field={fields.address2}
                  id="address2"
                  label={{
                    ...messages.genericTextAddressAptSuite,
                  }}
                  type="text"
                />
              </div>
            </div>
          ) : (
            <div
              className={`valid-address-information-box ${
                fadeIn === ADDRESS_MODE.MANUAL ? 'fadeIn' : fadeOut === ADDRESS_MODE.MANUAL ? 'fadeOut' : ''
              } ${fadeOut === ADDRESS_MODE.MANUAL ? 'fadeOut' : ''}`}
            >
              <AddressSectionReact
                countries={countries}
                dispatch={dispatch}
                fields={fields}
                invalid={invalid}
                isAutocomplete={false}
                onChangeCountry={countryOnChange}
                values={values}
              />
              <div className="valid-address-information">
                <p>
                  Warning: Your details must be accurate and complete for timely payments and a solid partnership. Please verify
                  before submitting.
                  {inviteUserToUseAddressTool ? (
                    <>
                      <br />
                      If you’re not sure about the validity of your address, use our{' '}
                      <span onClick={() => startSetIsAddressModeSearchTool(true)}>address search tool</span>.
                    </>
                  ) : null}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="form-buttons text-right">
          <button
            className="waves-effect waves-light btn bg-primary-color"
            disabled={invalid || !newValuesToSubmit || isFetchingAddressData}
            id="btn-save-profile-infos"
            onClick={() => {
              handleGtmEvent();
              setCurrentProfileValues(fields._meta.values);
              setIsSuggestedAddressComplete(undefined);
              handleSubmit();
            }}
          >
            <FormattedMessage {...messages.genericTextSaveChanges} />
          </button>
        </div>
      </form>
    </div>
  );
}

ProfileInfos.propTypes = {
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  payableTo: PropTypes.any.isRequired,
  values: PropTypes.any,
};

export default reduxForm(
  {
    form: 'profileCompanyInfos',
    touchOnChange: false,
    touchOnBlur: false,
    fields: [
      'payableTo',
      'first_name',
      'last_name',
      'company_name',
      'title',
      'taxId',
      'address',
      'address2',
      'city',
      'country',
      'province',
      'state',
      'region',
      'zipcode',
      'address_label',
      'autocompleted_address_label',
      'is_affiliate_address_autocompleted',
    ],
    validate,
  },
  (state) => {
    const addressLabel = buildAddressLabel({
      address: state.profile.data.affInfos.address1,
      city: state.profile.data.affInfos.city,
      region: state.profile.data.affInfos.region,
      postalCode: state.profile.data.affInfos.zipcode,
      country: getCountryLabelDefaultMessage(state.profile.data.affInfos.country),
    });
    return {
      initialValues: {
        salutation: state.profile.data.customProfile.user.salutation_id,
        payableTo:
          state.profile.data.customProfile.affiliate.payable_to_whom !== undefined
            ? state.profile.data.customProfile.affiliate.payable_to_whom.payable_to || ''
            : '',
        first_name: state.profile.data.affUserInfos.first_name,
        last_name: state.profile.data.affUserInfos.last_name,
        company_name: state.profile.data.affInfos.company,
        title: state.profile.data.affUserInfos.title,
        taxId: state.profile.data.customProfile.affiliate.tax_id,
        address: state.profile.data.affInfos.address1,
        address2: state.profile.data.affInfos.address2,
        city: state.profile.data.affInfos.city,
        country: state.profile.data.affInfos.country,
        province: state.profile.data.affInfos.region,
        state: state.profile.data.affInfos.region,
        region: state.profile.data.affInfos.region,
        zipcode: state.profile.data.affInfos.zipcode,
        is_affiliate_address_autocompleted: state.profile.data.customProfile.affiliate.is_affiliate_address_autocompleted,
        autocompleted_address_label: state.profile.data.customProfile.affiliate.is_affiliate_address_autocompleted
          ? addressLabel
          : '',
        address_label: addressLabel,
      },
    };
  }
)(ProfileInfos);

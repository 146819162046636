import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import apiqlFactory from '../../../utils/apiqlFactory';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestSalutations = () => ({ type: ACTIONS.REQUEST_SALUTATIONS });
const receiveSalutations = (data) => ({
  type: ACTIONS.RECEIVE_SALUTATIONS,
  data,
});
const failedToReceiveSalutations = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_SALUTATIONS,
});
export const fetchSalutations = () => (dispatch) => {
  dispatch(requestSalutations());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/salutations`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveSalutations(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveSalutations());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestBestTimesToCall = () => ({
  type: ACTIONS.REQUEST_BEST_TIMES_TO_CALL,
});
const receiveBestTimesToCall = (data) => ({
  type: ACTIONS.RECEIVE_BEST_TIMES_TO_CALL,
  data,
});
const failedToReceiveBestTimesToCall = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_BEST_TIMES_TO_CALL,
});
export const fetchBestTimesToCall = () => (dispatch) => {
  dispatch(requestBestTimesToCall());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/best-times-to-call`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveBestTimesToCall(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveBestTimesToCall());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestValidationStatus = () => ({
  type: ACTIONS.REQUEST_VALIDATION_STATUS,
});
const receiveValidationStatus = (data) => ({
  type: ACTIONS.RECEIVE_VALIDATION_STATUS,
  data,
});
const failedToReceiveValidationStatus = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_VALIDATION_STATUS,
});
export const fetchValidationStatus = () => (dispatch) => {
  dispatch(requestValidationStatus());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/validation-status`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveValidationStatus(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveValidationStatus());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAffiliateTypes = () => ({
  type: ACTIONS.REQUEST_AFFILIATE_TYPES,
});
const receiveAffiliateTypes = (data) => ({
  type: ACTIONS.RECEIVE_AFFILIATE_TYPES,
  data,
});
const failedToReceiveAffiliateTypes = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_AFFILIATE_TYPES,
});
export const fetchAffiliateTypes = () => (dispatch) => {
  dispatch(requestAffiliateTypes());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/affiliate-types`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAffiliateTypes(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAffiliateTypes());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestMinimumPayouts = () => ({ type: ACTIONS.REQUEST_MINIMUM_PAYOUTS });
const receiveMinimumPayouts = (data) => ({
  type: ACTIONS.RECEIVE_MINIMUM_PAYOUTS,
  data,
});
const failedToReceiveMinimumPayouts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_MINIMUM_PAYOUTS,
});
export const fetchMinimumPayouts = () => (dispatch) => {
  dispatch(requestMinimumPayouts());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/minimum-payouts`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveMinimumPayouts(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveMinimumPayouts());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestOffersList = () => ({ type: ACTIONS.REQUEST_OFFERS_LIST });
const receiveOffersList = (data) => ({
  type: ACTIONS.RECEIVE_OFFERS_LIST,
  data,
});
const failedToReceiveOffersList = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFERS_LIST,
});
export const fetchOffersList = (categories) => (dispatch) => {
  dispatch(requestOffersList());
  return new Promise((fulfill, reject) => {
    const catToSend = [];

    categories.forEach((catArray) => {
      if (catArray.length > 0) {
        catToSend.push(...catArray);
      }
    });

    return request
      .post(`${API_URL}/getOffersListByCategoryIds`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        categories: JSON.stringify(catToSend),
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffersList(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveOffersList());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
  });
};

const requestOffersUrlList = () => ({ type: ACTIONS.REQUEST_OFFERS_URL_LIST });
const receiveOffersUrlList = (data) => ({
  type: ACTIONS.RECEIVE_OFFERS_URL_LIST,
  data,
});
const failedToReceiveOffersUrlList = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFERS_URL_LIST,
});
export const fetchOffersUrlList = (offersIds) => (dispatch) => {
  dispatch(requestOffersUrlList());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offerUrl/findById`)
      .withCredentials()
      .query({
        id: JSON.stringify(offersIds),
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffersUrlList(res.body.data.offer_url));
          fulfill(res.body.data.offer_url);
        } else {
          dispatch(failedToReceiveOffersUrlList());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};
export const fetchOffersUrlListByCategoriesIds = (categoriesIds) => (dispatch) => {
  dispatch(requestOffersUrlList());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/offersIdsByCategoryIds`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        categories: categoriesIds,
      })
      .end((err, res) => {
        if (!err) {
          request
            .get(`${API_URL}/offerUrl/findById`)
            .withCredentials()
            .query({
              id: JSON.stringify(res.body.data),
            })
            .end((errUrl, resUrl) => {
              if (!errUrl) {
                dispatch(receiveOffersUrlList(resUrl.body.data.offer_url));
                fulfill();
              } else {
                dispatch(failedToReceiveOffersUrlList());
                const APIErrorhandler = new HandleAPIError(res, dispatch);
                APIErrorhandler.redirectIfNotLoggedIn();
                APIErrorhandler.showErrorMessage();
                reject();
              }
            });
        } else {
          dispatch(failedToReceiveOffersUrlList());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAllGoals = () => ({ type: ACTIONS.REQUEST_ALL_GOALS });
const receiveAllGoals = (data) => ({ type: ACTIONS.RECEIVE_ALL_GOALS, data });
const failedToReceiveAllGoals = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_GOALS,
});
export const fetchAllGoals = () => (dispatch) => {
  dispatch(requestAllGoals());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/goals/findAll`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAllGoals(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAllGoals());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAllBrowsers = () => ({ type: ACTIONS.REQUEST_ALL_BROWSERS });
const receiveAllBrowsers = (data) => ({
  type: ACTIONS.RECEIVE_ALL_BROWSERS,
  data,
});
const failedToReceiveAllBrowsers = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_BROWSERS,
});
export const fetchAllBrowsers = () => (dispatch) => {
  dispatch(requestAllBrowsers());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/findAll/browsers`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAllBrowsers(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAllBrowsers());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAllTimezones = () => ({ type: ACTIONS.REQUEST_ALL_TIMEZONES });
const receiveAllTimezones = (data) => ({
  type: ACTIONS.RECEIVE_ALL_TIMEZONES,
  data,
});
const failedToReceiveAllTimezones = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_TIMEZONES,
});
export const fetchAllTimezones = () => (dispatch) => {
  dispatch(requestAllTimezones());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/findAll/timezones`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAllTimezones(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAllTimezones());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAllCountries = () => ({ type: ACTIONS.REQUEST_ALL_COUNTRIES });
const receiveAllCountries = (data) => ({
  type: ACTIONS.RECEIVE_ALL_COUNTRIES,
  data,
});
const failedToReceiveAllCountries = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_COUNTRIES,
});
export const fetchAllCountries = () => (dispatch) => {
  dispatch(requestAllCountries());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/findAll/countries`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAllCountries(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAllCountries());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestSearchFields = (req) => ({
  type: ACTIONS.REQUEST_SEARCH_FIELDS,
  request: req,
});
const receiveSearchFields = (data) => ({
  type: ACTIONS.RECEIVE_SEARCH_FIELDS,
  data,
});
const failedToReceiveSearchFields = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_SEARCH_FIELDS,
});
export const fetchSearchFields = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    const offerRequest = request
      .post(`${API_URL}/apiql`)
      .query({
        query: apiqlFactory({ SearchFields: '' }),
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err && res.body.data) {
          const data = {
            Channels: res.body.data.Channels.Data,
            ConversionTypes: res.body.data.ConversionTypes.Data,
            MediaPlacements: res.body.data.MediaPlacements.Data,
            Verticals: res.body.data.Verticals.Data,
          };
          dispatch(receiveSearchFields(data));
          fulfill(data);
        }

        if (err) {
          dispatch(failedToReceiveSearchFields());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestSearchFields(offerRequest));
  });

const requestPaymentMethods = () => ({ type: ACTIONS.REQUEST_PAYMENT_METHODS });
const receivePaymentMethods = (data) => ({
  type: ACTIONS.RECEIVE_PAYMENT_METHODS,
  data,
});
const failedToReceivePaymentMethods = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_PAYMENT_METHODS,
});
export const fetchPaymentInfo = () => (dispatch) => {
  dispatch(requestPaymentMethods());
  return request
    .get(`${API_URL}/cr/payment-methods`)
    .withCredentials()
    .then((res) => {
      dispatch(receivePaymentMethods(res.body.data));
    })
    .catch((err) => {
      dispatch(failedToReceivePaymentMethods(err));
    });
};

export const fetchPaymentMethods = () => (dispatch) => {
  dispatch(requestPaymentMethods());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/payment-methods`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePaymentMethods(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceivePaymentMethods());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestPaymentMethodsV2 = () => ({
  type: ACTIONS.REQUEST_PAYMENT_METHODS_V2,
});
const receivePaymentMethodsV2 = (data) => ({
  type: ACTIONS.RECEIVE_PAYMENT_METHODS_V2,
  data,
});
const failedToReceivePaymentMethodsV2 = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_PAYMENT_METHODS_V2,
});

export const fetchPaymentMethodsV2 = () => (dispatch) => {
  dispatch(requestPaymentMethodsV2());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/cr/payment-methods/v2`) //call on initialFetching
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePaymentMethodsV2(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceivePaymentMethodsV2());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestOffersCategories = () => ({
  type: ACTIONS.REQUEST_OFFERS_CATEGORIES,
});
const receiveOffersCategories = (data) => ({
  type: ACTIONS.RECEIVE_OFFERS_CATEGORIES,
  data,
});
const failedToReceiveOffersCategories = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFERS_CATEGORIES,
});
export const fetchOffersCategories = () => (dispatch) => {
  dispatch(requestOffersCategories());
  return new Promise((fulfill, reject) =>
    request
      .get(API_URL + '/offers/findAllOfferCategories')
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffersCategories(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveOffersCategories());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestOffers = (req) => ({
  type: ACTIONS.REQUEST_ALL_OFFERS,
  request: req,
});
const receiveOffers = (payload) => ({
  type: ACTIONS.RECEIVE_ALL_OFFERS,
  payload,
});

const failedToReceiveAllOffers = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_OFFER,
});
export const fetchAllOffers = (search) => (dispatch) =>
  new Promise((fulfill, reject) => {
    const offerRequest = request
      .post(`${API_URL}/apiql`)
      .query({
        query: apiqlFactory({ Offers: search }),
      })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveOffers(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveAllOffers());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestOffers(offerRequest));
  });

export const addCompletedFormToApp = (data) => ({
  type: ACTIONS.ADD_COMPLETED_FORM_TO_APP,
  data,
});
export const changeAllViewModes = (viewMode, width) => ({
  type: ACTIONS.CHANGE_ALL_VIEW_MODES,
  viewMode,
  width,
});
export const changeMainMenuState = (mainMenuIsOpen) => ({
  type: ACTIONS.APP_UI_CHANGE_MAIN_MENU_STATE,
  mainMenuIsOpen,
});
export const changeOffersCreativesViewMode = (viewMode) => ({
  type: ACTIONS.CHANGE_OFFERS_CREATIVES_VIEW_MODE,
  viewMode,
});
export const changePaymentHistoryViewMode = (viewMode) => ({
  type: ACTIONS.CHANGE_PAYMENT_HISTORY_VIEW_MODE,
  viewMode,
});
export const setActionMessage = (status, textObj, time, callback = null) => ({
  type: ACTIONS.SET_ACTIONS_MESSAGE,
  status,
  textObj,
  time,
  callback,
});
export const showApplication = () => ({ type: ACTIONS.SHOW_APPLICATION });

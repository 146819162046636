const LiveCamWidgetSkin3Icon = () => (
  <svg viewBox="0 0 806.46 604.79" xmlns="http://www.w3.org/2000/svg">
    <title>Skin 3</title>
    <rect fill="#757575" height="604.79" width="806.39" x="0.07" />
    <path
      d="M553.54,426l-74.35-18.59-4.29-17.18c51.29-8.63,77.68-24,78.85-24.68a9.76,9.76,0,0,0,4.7-7.73,9.88,9.88,0,0,0-3.71-8.3c-.35-.27-35-28.92-35-128.17,0-83.7-19.58-126.14-58.22-126.14h-5.73c-13.93-13.47-24.39-19.41-52.49-19.41-36.47,0-116.45,36.66-116.45,145.55,0,99.25-34.68,127.9-34.93,128.09a9.68,9.68,0,0,0,.82,16.07c1.16.7,27.3,16.15,78.91,24.76l-4.29,17.14L253,426a108.93,108.93,0,0,0-82.6,105.87,9.71,9.71,0,0,0,9.71,9.7H626.45a9.73,9.73,0,0,0,9.7-9.74A108.92,108.92,0,0,0,553.54,426Z"
      fill="#e7eced"
    />
    <rect height="62.36" opacity=".4" width="806" />
    <rect height="62.36" opacity=".4" width="806" x="0.27" y="434.39" />
    <rect fill="#414244" height="108" width="806" y="496.75" />
    <rect fill="#ff4764" height="81.46" rx="8.5" ry="8.5" width="772.44" x="16.78" y="510.02" />
    <rect fill="#ff4764" height="42.52" rx="5.67" ry="5.67" width="90.71" x="13.7" y="9.97" />
    <path
      d="M129.67,19.67l6.21,17.53,6.17-17.53h6.54V43.84h-5V37.23l.5-11.4-6.52,18h-3.42l-6.51-18,.5,11.38v6.61h-5V19.67Z"
      fill="#fff"
    />
    <path
      d="M151.88,34.69a10.57,10.57,0,0,1,1-4.76,7.5,7.5,0,0,1,3-3.24,8.69,8.69,0,0,1,4.49-1.14,8.2,8.2,0,0,1,5.94,2.22,8.9,8.9,0,0,1,2.56,6l0,1.23a9.36,9.36,0,0,1-2.31,6.63,8,8,0,0,1-6.19,2.5,8.09,8.09,0,0,1-6.21-2.49,9.55,9.55,0,0,1-2.31-6.77Zm4.8.34a6.73,6.73,0,0,0,1,3.92,3.46,3.46,0,0,0,5.48,0,7.4,7.4,0,0,0,1-4.29,6.68,6.68,0,0,0-1-3.89,3.19,3.19,0,0,0-2.76-1.38,3.12,3.12,0,0,0-2.72,1.38A7.47,7.47,0,0,0,156.68,35Z"
      fill="#fff"
    />
    <path
      d="M171.1,34.73A10.8,10.8,0,0,1,173,28a6.1,6.1,0,0,1,5.15-2.49,5.5,5.5,0,0,1,4.34,2V18.34h4.81v25.5H183l-.24-1.91a5.61,5.61,0,0,1-4.61,2.24,6.1,6.1,0,0,1-5.09-2.5A11.14,11.14,0,0,1,171.1,34.73Zm4.8.35a7.08,7.08,0,0,0,.88,3.88,2.87,2.87,0,0,0,2.56,1.35,3.22,3.22,0,0,0,3.14-1.88v-7.1a3.17,3.17,0,0,0-3.11-1.88Q175.91,29.45,175.9,35.08Z"
      fill="#fff"
    />
    <path
      d="M199.28,44.17a8.81,8.81,0,0,1-6.44-2.42,8.64,8.64,0,0,1-2.48-6.46v-.46a10.79,10.79,0,0,1,1-4.84,7.64,7.64,0,0,1,3-3.29,8.33,8.33,0,0,1,4.38-1.15,7.45,7.45,0,0,1,5.8,2.32,9.45,9.45,0,0,1,2.11,6.59v2H195.23a4.44,4.44,0,0,0,1.4,2.82,4.24,4.24,0,0,0,3,1.07,5.17,5.17,0,0,0,4.33-2l2.36,2.64a7.25,7.25,0,0,1-2.92,2.38A9.58,9.58,0,0,1,199.28,44.17Zm-.55-14.76a3,3,0,0,0-2.32,1,5,5,0,0,0-1.13,2.77H202v-.38a3.57,3.57,0,0,0-.86-2.48A3.12,3.12,0,0,0,198.73,29.41Z"
      fill="#fff"
    />
    <path d="M214.45,43.84h-4.81V18.34h4.81Z" fill="#fff" />
    <path d="M238.37,43.84h-5l-9.69-15.9v15.9h-5V19.67h5l9.71,15.94V19.67h5Z" fill="#fff" />
    <path
      d="M252.82,43.84a5.1,5.1,0,0,1-.49-1.61,5.78,5.78,0,0,1-4.53,1.94,6.39,6.39,0,0,1-4.37-1.52,4.92,4.92,0,0,1-1.74-3.86,5.08,5.08,0,0,1,2.12-4.38,10.42,10.42,0,0,1,6.12-1.54h2.21v-1a3,3,0,0,0-.64-2,2.52,2.52,0,0,0-2-.75,2.84,2.84,0,0,0-1.9.58,2,2,0,0,0-.69,1.59h-4.8a4.79,4.79,0,0,1,1-2.88,6.42,6.42,0,0,1,2.73-2.09,10,10,0,0,1,3.95-.75A7.85,7.85,0,0,1,255,27.21,5.85,5.85,0,0,1,257,31.9v7.79a8.66,8.66,0,0,0,.71,3.87v.28Zm-4-3.34a4.08,4.08,0,0,0,2-.47,3.09,3.09,0,0,0,1.33-1.27V35.67h-1.8q-3.6,0-3.83,2.49l0,.29a1.91,1.91,0,0,0,.63,1.47A2.45,2.45,0,0,0,248.85,40.5Z"
      fill="#fff"
    />
    <path
      d="M265.13,25.88l.15,2a6.34,6.34,0,0,1,5.17-2.34,4.78,4.78,0,0,1,4.76,2.74,6.16,6.16,0,0,1,5.4-2.74,5.35,5.35,0,0,1,4.35,1.7,7.82,7.82,0,0,1,1.42,5.12V43.84h-4.81V32.39a3.43,3.43,0,0,0-.6-2.24,2.65,2.65,0,0,0-2.11-.7,3,3,0,0,0-3,2.06V43.84h-4.8V32.4a3.39,3.39,0,0,0-.61-2.26,2.69,2.69,0,0,0-2.09-.69,3.11,3.11,0,0,0-3,1.69v12.7h-4.79v-18Z"
      fill="#fff"
    />
    <path
      d="M298.34,44.17a8.81,8.81,0,0,1-6.44-2.42,8.64,8.64,0,0,1-2.48-6.46v-.46A10.79,10.79,0,0,1,290.47,30a7.7,7.7,0,0,1,3-3.29,8.33,8.33,0,0,1,4.38-1.15,7.45,7.45,0,0,1,5.8,2.32,9.46,9.46,0,0,1,2.12,6.59v2H294.29a4.44,4.44,0,0,0,1.4,2.82,4.24,4.24,0,0,0,3,1.07,5.19,5.19,0,0,0,4.34-2l2.35,2.64a7.25,7.25,0,0,1-2.92,2.38A9.58,9.58,0,0,1,298.34,44.17Zm-.55-14.76a3,3,0,0,0-2.32,1,5,5,0,0,0-1.13,2.77H301v-.38a3.57,3.57,0,0,0-.86-2.48A3.12,3.12,0,0,0,297.79,29.41Z"
      fill="#fff"
    />
    <path
      d="M613.5,33.61c0,3.07-.58,5.38-1.72,6.94a6.11,6.11,0,0,1-5.24,2.33,6.17,6.17,0,0,1-5.21-2.28q-1.74-2.26-1.79-6.74V30.17q0-4.61,1.72-6.9a7.17,7.17,0,0,1,10.47-.06q1.72,2.23,1.77,6.71Zm-3.56-4a9,9,0,0,0-.82-4.41,3.13,3.13,0,0,0-5.17-.07,8.29,8.29,0,0,0-.87,4.14v4.83a9.26,9.26,0,0,0,.84,4.46A2.8,2.8,0,0,0,606.54,40a2.75,2.75,0,0,0,2.53-1.35,8.72,8.72,0,0,0,.87-4.25Z"
      fill="#ff4764"
    />
    <path
      d="M630.55,33.61q0,4.61-1.72,6.94a6.11,6.11,0,0,1-5.24,2.33,6.17,6.17,0,0,1-5.21-2.28q-1.74-2.26-1.79-6.74V30.17q0-4.61,1.72-6.9a7.17,7.17,0,0,1,10.47-.06q1.72,2.23,1.77,6.71Zm-3.56-4a9,9,0,0,0-.82-4.41,3.13,3.13,0,0,0-5.17-.07,8.29,8.29,0,0,0-.87,4.14v4.83a9.26,9.26,0,0,0,.84,4.46A2.81,2.81,0,0,0,623.59,40a2.75,2.75,0,0,0,2.53-1.35,8.72,8.72,0,0,0,.87-4.25Z"
      fill="#ff4764"
    />
    <path
      d="M643.94,37.49l3.22-10.75H651L644.66,45q-1.45,4-4.92,4a6.66,6.66,0,0,1-1.72-.26V46l.68,0a3.48,3.48,0,0,0,2-.49,3.24,3.24,0,0,0,1.07-1.64l.51-1.37-5.56-15.76h3.84Z"
      fill="#ff4764"
    />
    <path
      d="M652.11,34.52a9.32,9.32,0,0,1,.93-4.2,6.75,6.75,0,0,1,2.59-2.87,7.28,7.28,0,0,1,3.84-1,7,7,0,0,1,5.21,2.06A8.17,8.17,0,0,1,666.84,34v.84A9.48,9.48,0,0,1,666,39a6.61,6.61,0,0,1-2.58,2.85,7.3,7.3,0,0,1-3.87,1,6.86,6.86,0,0,1-5.37-2.23,8.59,8.59,0,0,1-2-6Zm3.56.31a6.43,6.43,0,0,0,1,3.83A3.31,3.31,0,0,0,659.5,40a3.24,3.24,0,0,0,2.8-1.41,7,7,0,0,0,1-4.11,6.31,6.31,0,0,0-1-3.81,3.29,3.29,0,0,0-2.8-1.41,3.26,3.26,0,0,0-2.77,1.39A6.94,6.94,0,0,0,655.67,34.83Z"
      fill="#ff4764"
    />
    <path
      d="M709,39.82a7.09,7.09,0,0,1-3.2,2.29,13.28,13.28,0,0,1-4.66.77,9.2,9.2,0,0,1-4.75-1.21,8.08,8.08,0,0,1-3.18-3.48A12.15,12.15,0,0,1,692,32.91V31.24c0-3.23.77-5.74,2.32-7.55A8,8,0,0,1,700.78,21a8.53,8.53,0,0,1,5.65,1.76A7.59,7.59,0,0,1,709,27.81h-3.63A4.09,4.09,0,0,0,700.85,24a4.38,4.38,0,0,0-3.79,1.79A9.16,9.16,0,0,0,695.72,31v1.64A8.67,8.67,0,0,0,697.18,38a4.79,4.79,0,0,0,4.05,1.91c1.89,0,3.24-.43,4-1.29V34.46h-4.39V31.65H709Z"
      fill="#ff4764"
    />
    <path
      d="M719.67,42.88a7.36,7.36,0,0,1-5.48-2.13,7.74,7.74,0,0,1-2.11-5.68v-.43a9.55,9.55,0,0,1,.92-4.25,7,7,0,0,1,2.57-2.9,6.74,6.74,0,0,1,3.69-1,6.26,6.26,0,0,1,5,2.06A8.79,8.79,0,0,1,726,34.36v1.43H715.67A4.81,4.81,0,0,0,717,38.9a4,4,0,0,0,2.9,1.14,4.8,4.8,0,0,0,4-2l1.92,1.83a6.34,6.34,0,0,1-2.54,2.21A8,8,0,0,1,719.67,42.88Zm-.42-13.58a3,3,0,0,0-2.37,1,5.32,5.32,0,0,0-1.15,2.85h6.78v-.26a4.25,4.25,0,0,0-1-2.7A3,3,0,0,0,719.25,29.3Z"
      fill="#ff4764"
    />
    <path
      d="M732.09,26.74l.11,1.83a5.68,5.68,0,0,1,4.61-2.12q5,0,5,5.67V42.59h-3.56V32.32a3.26,3.26,0,0,0-.65-2.23,2.73,2.73,0,0,0-2.13-.73,3.42,3.42,0,0,0-3.21,1.95V42.59h-3.56V26.74Z"
      fill="#ff4764"
    />
    <path
      d="M744.81,34.55a9.44,9.44,0,0,1,1.7-5.88,5.44,5.44,0,0,1,4.55-2.22,5.2,5.2,0,0,1,4.08,1.75V20.09h3.56v22.5h-3.23L755.3,41A5.25,5.25,0,0,1,751,42.88a5.41,5.41,0,0,1-4.51-2.24A9.73,9.73,0,0,1,744.81,34.55Zm3.56.3a6.62,6.62,0,0,0,.93,3.78A3,3,0,0,0,751.94,40a3.37,3.37,0,0,0,3.2-1.95V31.27A3.32,3.32,0,0,0,752,29.36a3,3,0,0,0-2.66,1.37A7.3,7.3,0,0,0,748.37,34.85Z"
      fill="#ff4764"
    />
    <path
      d="M769.39,42.88a7.38,7.38,0,0,1-5.49-2.13,7.73,7.73,0,0,1-2.1-5.68v-.43a9.55,9.55,0,0,1,.92-4.25,6.91,6.91,0,0,1,2.57-2.9,6.72,6.72,0,0,1,3.69-1,6.22,6.22,0,0,1,5,2.06,8.74,8.74,0,0,1,1.77,5.85v1.43H765.39a4.81,4.81,0,0,0,1.31,3.11A3.94,3.94,0,0,0,769.59,40a4.82,4.82,0,0,0,4-2l1.92,1.83A6.41,6.41,0,0,1,773,42.1,8,8,0,0,1,769.39,42.88ZM769,29.3a3,3,0,0,0-2.36,1,5.32,5.32,0,0,0-1.15,2.85h6.78v-.26a4.31,4.31,0,0,0-1-2.7A3,3,0,0,0,769,29.3Z"
      fill="#ff4764"
    />
    <path
      d="M786.81,30a8.69,8.69,0,0,0-1.45-.12,3.28,3.28,0,0,0-3.3,1.88V42.59H778.5V26.74h3.4l.09,1.77a4,4,0,0,1,3.57-2.06,3.46,3.46,0,0,1,1.26.2Z"
      fill="#ff4764"
    />
    <path d="M31,39.27H41.54v4H26V19.09h5Z" fill="#fff" />
    <path d="M49.69,43.27h-5V19.09h5Z" fill="#fff" />
    <path d="M63.24,37.27l5.48-18.18h5.54L65.85,43.27h-5.2L52.27,19.09h5.52Z" fill="#fff" />
    <path d="M91.08,32.79H81.52v6.48H92.74v4H76.54V19.09H92.71v4H81.52v5.76h9.56Z" fill="#fff" />
    <path d="M229,565.32V536.15h19.6v5H234.66v7.52h11.59v4.6H234.66v12.08Z" fill="#fff" />
    <path
      d="M252.57,565.32V536.15h12.9a8.29,8.29,0,0,1,3.72.84,9.51,9.51,0,0,1,2.94,2.22,10.53,10.53,0,0,1,1.93,3.12,9.27,9.27,0,0,1,.7,3.51,9.68,9.68,0,0,1-1.42,5.16,8.11,8.11,0,0,1-3.84,3.35l6.66,11h-6.37l-6-9.78h-5.59v9.78Zm5.67-14.75h7.11a3.07,3.07,0,0,0,1.46-.37,3.88,3.88,0,0,0,1.17-1,4.84,4.84,0,0,0,.78-1.5,5.83,5.83,0,0,0,.29-1.85,5.29,5.29,0,0,0-.33-1.89,5.07,5.07,0,0,0-.88-1.5,4,4,0,0,0-1.28-1,3.2,3.2,0,0,0-1.46-.35h-6.86Z"
      fill="#fff"
    />
    <path d="M300.15,560.34v5H279.89V536.15h19.89v5H285.56v7h12.29v4.6H285.56v7.6Z" fill="#fff" />
    <path d="M324.84,560.34v5H304.58V536.15h19.89v5H310.25v7h12.29v4.6H310.25v7.6Z" fill="#fff" />
    <path d="M340.74,536.15l7.89,22.06,7.8-22.06h6L351,565.32h-4.77l-11.46-29.17Z" fill="#fff" />
    <path d="M365.51,565.32V536.15h5.67v29.17Z" fill="#fff" />
    <path
      d="M377.26,565.32V536.15h10.6a15.84,15.84,0,0,1,6.27,1.15,12.65,12.65,0,0,1,4.49,3.12,12.91,12.91,0,0,1,2.72,4.62,17.47,17.47,0,0,1,.9,5.65,17.12,17.12,0,0,1-1,6,12.82,12.82,0,0,1-2.89,4.6,13.18,13.18,0,0,1-4.54,3,16,16,0,0,1-5.94,1.05Zm19.27-14.63a12.21,12.21,0,0,0-.58-3.86,8.56,8.56,0,0,0-1.68-3,7.43,7.43,0,0,0-2.73-2,9.15,9.15,0,0,0-3.68-.7h-4.93v19.22h4.93a8.76,8.76,0,0,0,3.72-.74,7.65,7.65,0,0,0,2.71-2,8.86,8.86,0,0,0,1.66-3.06A12.09,12.09,0,0,0,396.53,550.69Z"
      fill="#fff"
    />
    <path d="M426.93,560.34v5H406.68V536.15h19.88v5H412.35v7h12.28v4.6H412.35v7.6Z" fill="#fff" />
    <path
      d="M443.2,565.52a13.3,13.3,0,0,1-5.81-1.25,14.33,14.33,0,0,1-4.48-3.33A15.46,15.46,0,0,1,429,550.73a14.8,14.8,0,0,1,1.09-5.59,15.56,15.56,0,0,1,3-4.72,14.45,14.45,0,0,1,4.54-3.27,13.45,13.45,0,0,1,5.71-1.21,12.8,12.8,0,0,1,5.77,1.29,14.61,14.61,0,0,1,4.46,3.39,15.83,15.83,0,0,1,2.88,4.77,15.1,15.1,0,0,1,1,5.42,14.8,14.8,0,0,1-1.07,5.57,15.14,15.14,0,0,1-3,4.68,14.24,14.24,0,0,1-4.52,3.25A13.45,13.45,0,0,1,443.2,565.52Zm-8.46-14.79a11.87,11.87,0,0,0,.57,3.66,9.55,9.55,0,0,0,1.67,3.12,8.24,8.24,0,0,0,2.67,2.18,8.29,8.29,0,0,0,7.23,0,8.5,8.5,0,0,0,2.67-2.24,10.06,10.06,0,0,0,1.64-3.15,11.6,11.6,0,0,0,.56-3.55,11.32,11.32,0,0,0-.6-3.66,10.21,10.21,0,0,0-1.69-3.12,8.06,8.06,0,0,0-2.67-2.16,7.76,7.76,0,0,0-3.55-.8,7.66,7.66,0,0,0-3.66.85,8.32,8.32,0,0,0-2.67,2.21,10,10,0,0,0-1.62,3.13A11.64,11.64,0,0,0,434.74,550.73Z"
      fill="#fff"
    />
    <path
      d="M469.9,550.52a14.92,14.92,0,0,1,1-5.27,14.35,14.35,0,0,1,2.81-4.67,14.17,14.17,0,0,1,4.52-3.32,14,14,0,0,1,6.08-1.28,13.25,13.25,0,0,1,7,1.77,11.34,11.34,0,0,1,4.37,4.6l-4.35,3a7.74,7.74,0,0,0-1.4-2.08,7.08,7.08,0,0,0-1.77-1.33,7.77,7.77,0,0,0-2-.72,9.32,9.32,0,0,0-2-.23,7.58,7.58,0,0,0-3.72.87,8.15,8.15,0,0,0-2.63,2.24,9.57,9.57,0,0,0-1.58,3.12,11.67,11.67,0,0,0-.54,3.47,11.34,11.34,0,0,0,.62,3.72,9.72,9.72,0,0,0,1.75,3.14,8.72,8.72,0,0,0,2.71,2.18,7.37,7.37,0,0,0,3.47.82,8.76,8.76,0,0,0,2.05-.25,7.55,7.55,0,0,0,2-.78,7.69,7.69,0,0,0,1.79-1.37,6.45,6.45,0,0,0,1.31-2l4.64,2.67a8.74,8.74,0,0,1-2,2.9,12.84,12.84,0,0,1-2.92,2.12,15.06,15.06,0,0,1-3.47,1.31,15.25,15.25,0,0,1-3.61.45,12.46,12.46,0,0,1-5.71-1.31,14.81,14.81,0,0,1-4.46-3.43A15.94,15.94,0,0,1,471,556,15,15,0,0,1,469.9,550.52Z"
      fill="#fff"
    />
    <path d="M524.38,536.15v29.17h-5.63V552.87H505.52v12.45h-5.67V536.15h5.67v11.79h13.23V536.15Z" fill="#fff" />
    <path d="M538.76,536.15h5l11.09,29.17H549L546.32,558H536.13l-2.67,7.28h-5.83Zm6.53,17.91-4-11.46-4.2,11.46Z" fill="#fff" />
    <path d="M577,541.12h-9.33v24.2h-5.63v-24.2h-9.32v-5H577Z" fill="#fff" />
    <path
      d="M22.19,470.18a12.87,12.87,0,0,1-5.09-2.47,4.74,4.74,0,0,1-1.59-3.62,5,5,0,0,1,1.94-4,7.77,7.77,0,0,1,5.07-1.59,8.4,8.4,0,0,1,3.78.82,6.19,6.19,0,0,1,2.58,2.26,5.81,5.81,0,0,1,.91,3.16H27.06a3.76,3.76,0,0,0-1.19-2.95,4.85,4.85,0,0,0-3.35-1.07,4.91,4.91,0,0,0-3.14.89A3,3,0,0,0,18.25,464a2.66,2.66,0,0,0,1.07,2.13,10.57,10.57,0,0,0,3.64,1.6,16.57,16.57,0,0,1,4,1.59,6.16,6.16,0,0,1,2.16,2,5.29,5.29,0,0,1,.7,2.74,4.79,4.79,0,0,1-2,4,8.28,8.28,0,0,1-5.22,1.51,9.75,9.75,0,0,1-4-.81,6.83,6.83,0,0,1-2.84-2.23,5.5,5.5,0,0,1-1-3.22h2.73A3.54,3.54,0,0,0,19,476.34a5.77,5.77,0,0,0,3.69,1.09,5.4,5.4,0,0,0,3.3-.88,2.86,2.86,0,0,0,1.14-2.39A2.8,2.8,0,0,0,26,471.81,12.13,12.13,0,0,0,22.19,470.18Z"
      fill="#d8d8d8"
    />
    <path
      d="M42.66,477.85a5.57,5.57,0,0,1-4.49,1.8,4.75,4.75,0,0,1-3.73-1.42,6.16,6.16,0,0,1-1.3-4.22v-10h2.62v9.89c0,2.33,1,3.49,2.83,3.49s3.34-.75,4-2.24V464.05h2.62v15.32H42.72Z"
      fill="#d8d8d8"
    />
    <path
      d="M58.4,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.64,4.64,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.6-3.19,6.26,6.26,0,0,1,4.07-1.3,6.38,6.38,0,0,1,4.22,1.35,4.27,4.27,0,0,1,1.63,3.44H58.24a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.54,9.54,0,0,0,2.72.92,14.34,14.34,0,0,1,3.17,1A4.63,4.63,0,0,1,60.43,473a3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24,7.76,7.76,0,0,1-3.25-.65,5.29,5.29,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.73,2.73,0,0,0,1,2.09,4.14,4.14,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,58.4,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M77.66,471.88a9.13,9.13,0,0,1-1.6,5.63,5.11,5.11,0,0,1-4.33,2.14,5.62,5.62,0,0,1-4.39-1.77v7.38H64.72V464.05h2.39l.13,1.69a5.41,5.41,0,0,1,4.45-2,5.23,5.23,0,0,1,4.36,2.08,9.24,9.24,0,0,1,1.61,5.8Zm-2.62-.3a6.8,6.8,0,0,0-1.1-4.09,3.57,3.57,0,0,0-3-1.5,3.84,3.84,0,0,0-3.57,2.11v7.32a3.86,3.86,0,0,0,3.6,2.09,3.55,3.55,0,0,0,3-1.49A7.42,7.42,0,0,0,75,471.58Z"
      fill="#d8d8d8"
    />
    <path
      d="M87.64,479.65a6.67,6.67,0,0,1-5.07-2,7.59,7.59,0,0,1-2-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.91,6.91,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.77,5.77,0,0,1,4.65,2,8.55,8.55,0,0,1,1.65,5.64v1.09H83.23a5.44,5.44,0,0,0,1.33,3.66,4.15,4.15,0,0,0,3.22,1.39,4.64,4.64,0,0,0,2.35-.56,5.86,5.86,0,0,0,1.68-1.51l1.61,1.25A6.45,6.45,0,0,1,87.64,479.65Zm-.33-13.74a3.49,3.49,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.33,3.23H91v-.19a5,5,0,0,0-1.07-3.1A3.3,3.3,0,0,0,87.31,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M99.41,464.05,99.5,466a5.59,5.59,0,0,1,4.58-2.21c3.24,0,4.88,1.83,4.9,5.48v10.13h-2.62V469.23a3.55,3.55,0,0,0-.75-2.45,3,3,0,0,0-2.32-.79,3.82,3.82,0,0,0-2.24.67,4.68,4.68,0,0,0-1.5,1.79v10.92H96.93V464.05Z"
      fill="#d8d8d8"
    />
    <path
      d="M112.59,471.58a8.94,8.94,0,0,1,1.67-5.67,5.28,5.28,0,0,1,4.37-2.15,5.35,5.35,0,0,1,4.27,1.84v-8h2.62v21.75h-2.41l-.13-1.65a5.32,5.32,0,0,1-4.37,1.93,5.22,5.22,0,0,1-4.34-2.18,9.06,9.06,0,0,1-1.68-5.69Zm2.62.3a6.83,6.83,0,0,0,1.07,4.07,3.47,3.47,0,0,0,3,1.48,3.83,3.83,0,0,0,3.64-2.24v-7a3.86,3.86,0,0,0-3.61-2.16,3.5,3.5,0,0,0-3,1.48A7.55,7.55,0,0,0,115.21,471.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M129.88,460a1.55,1.55,0,0,1,.39-1.07,1.75,1.75,0,0,1,2.32,0A1.55,1.55,0,0,1,133,460a1.55,1.55,0,1,1-3.1,0Zm2.84,19.39H130.1V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M146.12,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.64,4.64,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.6-3.19,6.26,6.26,0,0,1,4.07-1.3,6.38,6.38,0,0,1,4.22,1.35,4.24,4.24,0,0,1,1.63,3.44H146a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.54,9.54,0,0,0,2.72.92,14.34,14.34,0,0,1,3.17,1,4.63,4.63,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24,7.76,7.76,0,0,1-3.25-.65,5.29,5.29,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.73,2.73,0,0,0,1,2.09,4.14,4.14,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,146.12,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M161.36,475.3a1.93,1.93,0,0,0-.8-1.65,7.63,7.63,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.52,4.52,0,0,1-1.72-1.41,3.35,3.35,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.26,6.26,0,0,1,4.07-1.3,6.39,6.39,0,0,1,4.23,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.38,2.38,0,0,0-.92-1.86,3.41,3.41,0,0,0-2.3-.78,3.57,3.57,0,0,0-2.24.63,2,2,0,0,0-.8,1.63,1.59,1.59,0,0,0,.75,1.43,9.44,9.44,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.67,4.67,0,0,1,1.8,1.47,3.62,3.62,0,0,1,.59,2.09,3.93,3.93,0,0,1-1.64,3.29,6.88,6.88,0,0,1-4.26,1.24,7.77,7.77,0,0,1-3.26-.65,5.41,5.41,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.71,2.71,0,0,0,1.06,2.09,4.1,4.1,0,0,0,2.6.77,4.22,4.22,0,0,0,2.38-.6A1.85,1.85,0,0,0,161.36,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M174,479.65a6.67,6.67,0,0,1-5.07-2,7.58,7.58,0,0,1-1.95-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.81,6.81,0,0,1,2.43-2.81,6.2,6.2,0,0,1,3.4-1,5.73,5.73,0,0,1,4.64,2,8.55,8.55,0,0,1,1.66,5.64v1.09H169.64a5.43,5.43,0,0,0,1.32,3.66,4.17,4.17,0,0,0,3.22,1.39,4.62,4.62,0,0,0,2.35-.56,5.77,5.77,0,0,0,1.69-1.51l1.6,1.25A6.45,6.45,0,0,1,174,479.65Zm-.32-13.74a3.51,3.51,0,0,0-2.67,1.16,5.67,5.67,0,0,0-1.33,3.23h7.68v-.19a5,5,0,0,0-1.08-3.1A3.29,3.29,0,0,0,173.72,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M198.21,466.4a7.35,7.35,0,0,0-1.29-.1,3.45,3.45,0,0,0-3.49,2.19v10.88h-2.62V464.05h2.54l.05,1.77a4.07,4.07,0,0,1,3.65-2.06,2.69,2.69,0,0,1,1.16.2Z"
      fill="#d8d8d8"
    />
    <path
      d="M200.92,460a1.55,1.55,0,0,1,.39-1.07,1.75,1.75,0,0,1,2.32,0A1.55,1.55,0,0,1,204,460a1.55,1.55,0,1,1-3.1,0Zm2.84,19.39h-2.62V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M217.16,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.64,4.64,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.6-3.19,6.26,6.26,0,0,1,4.07-1.3,6.38,6.38,0,0,1,4.22,1.35,4.24,4.24,0,0,1,1.63,3.44H217a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.54,9.54,0,0,0,2.72.92,14.34,14.34,0,0,1,3.17,1,4.63,4.63,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24,7.76,7.76,0,0,1-3.25-.65,5.29,5.29,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.73,2.73,0,0,0,1.05,2.09,4.14,4.14,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,217.16,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M232.94,477.85a5.57,5.57,0,0,1-4.49,1.8,4.75,4.75,0,0,1-3.73-1.42,6.16,6.16,0,0,1-1.3-4.22v-10H226v9.89q0,3.49,2.84,3.49,3,0,4-2.24V464.05h2.62v15.32H233Z"
      fill="#d8d8d8"
    />
    <path
      d="M248.68,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.64,4.64,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.6-3.19,6.26,6.26,0,0,1,4.07-1.3,6.38,6.38,0,0,1,4.22,1.35,4.24,4.24,0,0,1,1.63,3.44h-2.64a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.54,9.54,0,0,0,2.72.92,14.34,14.34,0,0,1,3.17,1,4.63,4.63,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24,7.76,7.76,0,0,1-3.25-.65,5.29,5.29,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.73,2.73,0,0,0,1,2.09,4.14,4.14,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,248.68,475.3Z"
      fill="#d8d8d8"
    />
    <path d="M265.32,479.37H262.7V457.62h2.62Z" fill="#d8d8d8" />
    <path
      d="M276.15,479.65a6.66,6.66,0,0,1-5.06-2,7.54,7.54,0,0,1-2-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.76,6.76,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.73,5.73,0,0,1,4.64,2,8.49,8.49,0,0,1,1.66,5.64v1.09H271.75a5.44,5.44,0,0,0,1.33,3.66,4.14,4.14,0,0,0,3.22,1.39,4.67,4.67,0,0,0,2.35-.56,5.86,5.86,0,0,0,1.68-1.51l1.6,1.25A6.44,6.44,0,0,1,276.15,479.65Zm-.32-13.74a3.48,3.48,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.33,3.23h7.67v-.19a4.91,4.91,0,0,0-1.08-3.1A3.29,3.29,0,0,0,275.83,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M291.6,477.51a3.77,3.77,0,0,0,2.45-.85,3,3,0,0,0,1.16-2.12h2.47a4.62,4.62,0,0,1-.9,2.5,5.92,5.92,0,0,1-2.23,1.9,6.48,6.48,0,0,1-2.95.71,6.32,6.32,0,0,1-5-2.09,8.33,8.33,0,0,1-1.85-5.71v-.44a9.19,9.19,0,0,1,.82-4,6.29,6.29,0,0,1,2.36-2.7,6.61,6.61,0,0,1,3.63-1,6.16,6.16,0,0,1,4.29,1.55,5.49,5.49,0,0,1,1.81,4h-2.47a3.61,3.61,0,0,0-1.13-2.44,3.49,3.49,0,0,0-2.5-1,3.66,3.66,0,0,0-3.09,1.44,6.78,6.78,0,0,0-1.1,4.16V472a6.58,6.58,0,0,0,1.09,4.08A3.68,3.68,0,0,0,291.6,477.51Z"
      fill="#d8d8d8"
    />
    <path
      d="M304.47,460.34v3.71h2.86v2h-2.86v9.51a2.11,2.11,0,0,0,.39,1.39,1.62,1.62,0,0,0,1.3.46,6.67,6.67,0,0,0,1.25-.17v2.11a7.56,7.56,0,0,1-2,.28,3.23,3.23,0,0,1-2.64-1.06,4.55,4.55,0,0,1-.9-3v-9.5h-2.79v-2h2.79v-3.71Z"
      fill="#d8d8d8"
    />
    <path
      d="M320.14,477.85a5.56,5.56,0,0,1-4.49,1.8,4.77,4.77,0,0,1-3.73-1.42,6.21,6.21,0,0,1-1.29-4.22v-10h2.62v9.89q0,3.49,2.83,3.49,3,0,4-2.24V464.05h2.62v15.32H320.2Z"
      fill="#d8d8d8"
    />
    <path
      d="M335.88,475.3a1.93,1.93,0,0,0-.8-1.65,7.63,7.63,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.52,4.52,0,0,1-1.72-1.41,3.35,3.35,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.26,6.26,0,0,1,4.07-1.3,6.39,6.39,0,0,1,4.23,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.38,2.38,0,0,0-.92-1.86,3.41,3.41,0,0,0-2.3-.78,3.57,3.57,0,0,0-2.24.63,2,2,0,0,0-.8,1.63,1.59,1.59,0,0,0,.75,1.43,9.44,9.44,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.67,4.67,0,0,1,1.8,1.47,3.62,3.62,0,0,1,.59,2.09,3.93,3.93,0,0,1-1.64,3.29,6.88,6.88,0,0,1-4.26,1.24,7.77,7.77,0,0,1-3.26-.65,5.41,5.41,0,0,1-2.22-1.82,4.41,4.41,0,0,1-.8-2.53h2.62a2.71,2.71,0,0,0,1.06,2.09,4.1,4.1,0,0,0,2.6.77,4.22,4.22,0,0,0,2.38-.6A1.85,1.85,0,0,0,335.88,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M342.12,483.47l-1.49-1a6.81,6.81,0,0,0,1.39-3.82v-2.36h2.56v2.05a6.41,6.41,0,0,1-.7,2.86A6.66,6.66,0,0,1,342.12,483.47Z"
      fill="#d8d8d8"
    />
    <path
      d="M364.58,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.56,4.56,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.29,6.29,0,0,1,4.08-1.3,6.38,6.38,0,0,1,4.22,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.56,9.56,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.71,4.71,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24A7.81,7.81,0,0,1,358,479a5.32,5.32,0,0,1-2.21-1.82,4.34,4.34,0,0,1-.8-2.53h2.62a2.7,2.7,0,0,0,1.05,2.09,4.12,4.12,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,364.58,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M380.36,477.85a5.57,5.57,0,0,1-4.49,1.8,4.74,4.74,0,0,1-3.73-1.42,6.16,6.16,0,0,1-1.3-4.22v-10h2.62v9.89q0,3.49,2.83,3.49c2,0,3.34-.75,4-2.24V464.05h2.62v15.32h-2.5Z"
      fill="#d8d8d8"
    />
    <path
      d="M396.1,475.3a1.93,1.93,0,0,0-.8-1.65,7.78,7.78,0,0,0-2.79-1,12.88,12.88,0,0,1-3.16-1,4.56,4.56,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.29,6.29,0,0,1,4.08-1.3,6.38,6.38,0,0,1,4.22,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.36,2.36,0,0,0-.91-1.86,3.43,3.43,0,0,0-2.3-.78,3.55,3.55,0,0,0-2.24.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.56,9.56,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.71,4.71,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.59,2.09,3.91,3.91,0,0,1-1.65,3.29,6.84,6.84,0,0,1-4.26,1.24,7.81,7.81,0,0,1-3.26-.65,5.32,5.32,0,0,1-2.21-1.82,4.34,4.34,0,0,1-.8-2.53h2.62a2.7,2.7,0,0,0,1,2.09,4.12,4.12,0,0,0,2.6.77,4.25,4.25,0,0,0,2.39-.6A1.85,1.85,0,0,0,396.1,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M408.56,477.51a3.75,3.75,0,0,0,2.45-.85,2.94,2.94,0,0,0,1.16-2.12h2.48a4.79,4.79,0,0,1-.9,2.5,5.92,5.92,0,0,1-2.23,1.9,6.49,6.49,0,0,1-3,.71,6.31,6.31,0,0,1-5-2.09,8.28,8.28,0,0,1-1.85-5.71v-.44a9.19,9.19,0,0,1,.82-4,6.22,6.22,0,0,1,2.36-2.7,6.61,6.61,0,0,1,3.63-1,6.12,6.12,0,0,1,4.28,1.55,5.46,5.46,0,0,1,1.82,4h-2.48a3.6,3.6,0,0,0-1.12-2.44,3.51,3.51,0,0,0-2.5-1,3.66,3.66,0,0,0-3.09,1.44,6.72,6.72,0,0,0-1.1,4.16V472a6.58,6.58,0,0,0,1.09,4.08A3.66,3.66,0,0,0,408.56,477.51Z"
      fill="#d8d8d8"
    />
    <path
      d="M417.9,460a1.55,1.55,0,0,1,.39-1.07,1.75,1.75,0,0,1,2.32,0A1.55,1.55,0,0,1,421,460a1.55,1.55,0,1,1-3.1,0Zm2.83,19.39h-2.62V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M438.16,471.88a9.19,9.19,0,0,1-1.6,5.63,5.13,5.13,0,0,1-4.34,2.14,5.62,5.62,0,0,1-4.39-1.77v7.38h-2.61V464.05h2.39l.13,1.69a5.37,5.37,0,0,1,4.44-2,5.25,5.25,0,0,1,4.37,2.08,9.3,9.3,0,0,1,1.61,5.8Zm-2.62-.3a6.73,6.73,0,0,0-1.11-4.09,3.54,3.54,0,0,0-3-1.5,3.86,3.86,0,0,0-3.57,2.11v7.32a3.88,3.88,0,0,0,3.6,2.09,3.55,3.55,0,0,0,3-1.49A7.42,7.42,0,0,0,435.54,471.58Z"
      fill="#d8d8d8"
    />
    <path
      d="M441.79,460a1.55,1.55,0,0,1,.39-1.07,1.43,1.43,0,0,1,1.15-.44,1.45,1.45,0,0,1,1.16.44,1.51,1.51,0,0,1,.4,1.07,1.46,1.46,0,0,1-.4,1.06,1.48,1.48,0,0,1-1.16.43,1.46,1.46,0,0,1-1.15-.43A1.49,1.49,0,0,1,441.79,460Zm2.83,19.39H442V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M452.66,460.34v3.71h2.86v2h-2.86v9.51A2.12,2.12,0,0,0,453,477a1.62,1.62,0,0,0,1.3.46,6.67,6.67,0,0,0,1.25-.17v2.11a7.55,7.55,0,0,1-2,.28,3.25,3.25,0,0,1-2.65-1.06,4.55,4.55,0,0,1-.89-3v-9.5h-2.79v-2H450v-3.71Z"
      fill="#d8d8d8"
    />
    <path
      d="M472.69,479.65a6.7,6.7,0,0,1-5.07-2,7.58,7.58,0,0,1-1.95-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.76,6.76,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.73,5.73,0,0,1,4.64,2,8.49,8.49,0,0,1,1.66,5.64v1.09H468.29a5.48,5.48,0,0,0,1.32,3.66,4.17,4.17,0,0,0,3.22,1.39,4.68,4.68,0,0,0,2.36-.56,5.86,5.86,0,0,0,1.68-1.51l1.6,1.25A6.44,6.44,0,0,1,472.69,479.65Zm-.32-13.74a3.48,3.48,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.34,3.23h7.68v-.19A5,5,0,0,0,475,467,3.29,3.29,0,0,0,472.37,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M481.37,471.58a9,9,0,0,1,1.65-5.7,5.72,5.72,0,0,1,8.77-.14l.13-1.69h2.39V479a6.27,6.27,0,0,1-1.76,4.69,6.53,6.53,0,0,1-4.74,1.71,7.86,7.86,0,0,1-3.24-.71,5.61,5.61,0,0,1-2.43-1.94l1.36-1.57a5.13,5.13,0,0,0,4.12,2.08,4,4,0,0,0,3-1.08,4.06,4.06,0,0,0,1.07-3v-1.31a5.35,5.35,0,0,1-4.29,1.81,5.22,5.22,0,0,1-4.35-2.17A9.44,9.44,0,0,1,481.37,471.58Zm2.63.3a6.88,6.88,0,0,0,1.06,4.07,3.47,3.47,0,0,0,3,1.48,3.82,3.82,0,0,0,3.63-2.25v-7a3.87,3.87,0,0,0-3.61-2.19,3.46,3.46,0,0,0-3,1.48A7.47,7.47,0,0,0,484,471.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M504.91,479.65a6.67,6.67,0,0,1-5.07-2,7.54,7.54,0,0,1-2-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.83,6.83,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.77,5.77,0,0,1,4.65,2,8.55,8.55,0,0,1,1.65,5.64v1.09H500.5a5.44,5.44,0,0,0,1.33,3.66,4.15,4.15,0,0,0,3.22,1.39,4.64,4.64,0,0,0,2.35-.56,5.86,5.86,0,0,0,1.68-1.51l1.6,1.25A6.43,6.43,0,0,1,504.91,479.65Zm-.33-13.74a3.49,3.49,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.33,3.23h7.67v-.19a5,5,0,0,0-1.07-3.1A3.31,3.31,0,0,0,504.58,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M517.76,460.34v3.71h2.86v2h-2.86v9.51a2.12,2.12,0,0,0,.38,1.39,1.62,1.62,0,0,0,1.3.46,6.67,6.67,0,0,0,1.25-.17v2.11a7.55,7.55,0,0,1-2,.28,3.25,3.25,0,0,1-2.65-1.06,4.55,4.55,0,0,1-.89-3v-9.5h-2.79v-2h2.79v-3.71Z"
      fill="#d8d8d8"
    />
    <path
      d="M537.79,479.65a6.7,6.7,0,0,1-5.07-2,7.58,7.58,0,0,1-2-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.76,6.76,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.73,5.73,0,0,1,4.64,2,8.49,8.49,0,0,1,1.66,5.64v1.09H533.39a5.48,5.48,0,0,0,1.32,3.66,4.17,4.17,0,0,0,3.22,1.39,4.68,4.68,0,0,0,2.36-.56,5.86,5.86,0,0,0,1.68-1.51l1.6,1.25A6.44,6.44,0,0,1,537.79,479.65Zm-.32-13.74a3.48,3.48,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.34,3.23h7.68v-.19a5,5,0,0,0-1.08-3.1A3.29,3.29,0,0,0,537.47,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M546.47,471.58a9,9,0,0,1,1.65-5.7,5.72,5.72,0,0,1,8.77-.14l.13-1.69h2.39V479a6.27,6.27,0,0,1-1.76,4.69,6.53,6.53,0,0,1-4.74,1.71,7.86,7.86,0,0,1-3.24-.71,5.61,5.61,0,0,1-2.43-1.94l1.36-1.57a5.13,5.13,0,0,0,4.12,2.08,4,4,0,0,0,3-1.08,4.1,4.1,0,0,0,1.06-3v-1.31a5.35,5.35,0,0,1-4.29,1.81,5.22,5.22,0,0,1-4.35-2.17A9.44,9.44,0,0,1,546.47,471.58Zm2.63.3a6.88,6.88,0,0,0,1.06,4.07,3.47,3.47,0,0,0,3,1.48,3.82,3.82,0,0,0,3.63-2.25v-7a3.87,3.87,0,0,0-3.61-2.19,3.46,3.46,0,0,0-3,1.48A7.47,7.47,0,0,0,549.1,471.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M570,479.65a6.67,6.67,0,0,1-5.07-2,7.54,7.54,0,0,1-2-5.47v-.48a9.18,9.18,0,0,1,.87-4.07,6.91,6.91,0,0,1,2.44-2.81,6.14,6.14,0,0,1,3.39-1,5.77,5.77,0,0,1,4.65,2,8.55,8.55,0,0,1,1.65,5.64v1.09H565.6a5.44,5.44,0,0,0,1.33,3.66,4.15,4.15,0,0,0,3.22,1.39,4.64,4.64,0,0,0,2.35-.56,5.86,5.86,0,0,0,1.68-1.51l1.6,1.25A6.43,6.43,0,0,1,570,479.65Zm-.33-13.74a3.49,3.49,0,0,0-2.66,1.16,5.6,5.6,0,0,0-1.33,3.23h7.67v-.19a5,5,0,0,0-1.07-3.1A3.31,3.31,0,0,0,569.68,465.91Z"
      fill="#d8d8d8"
    />
    <path
      d="M588.22,475.3a1.91,1.91,0,0,0-.8-1.65,7.63,7.63,0,0,0-2.79-1,12.78,12.78,0,0,1-3.15-1,4.56,4.56,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.26,6.26,0,0,1,4.07-1.3,6.39,6.39,0,0,1,4.23,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.38,2.38,0,0,0-.92-1.86,3.39,3.39,0,0,0-2.3-.78,3.52,3.52,0,0,0-2.23.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.44,9.44,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.79,4.79,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.58,2.09,3.91,3.91,0,0,1-1.64,3.29,6.84,6.84,0,0,1-4.26,1.24,7.81,7.81,0,0,1-3.26-.65,5.32,5.32,0,0,1-2.21-1.82,4.34,4.34,0,0,1-.8-2.53h2.62a2.67,2.67,0,0,0,1.05,2.09,4.12,4.12,0,0,0,2.6.77,4.19,4.19,0,0,0,2.38-.6A1.85,1.85,0,0,0,588.22,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M598.1,460.34v3.71H601v2H598.1v9.51a2.17,2.17,0,0,0,.38,1.39,1.65,1.65,0,0,0,1.31.46,6.59,6.59,0,0,0,1.24-.17v2.11a7.55,7.55,0,0,1-2,.28,3.27,3.27,0,0,1-2.65-1.06,4.61,4.61,0,0,1-.89-3v-9.5h-2.79v-2h2.79v-3.71Z"
      fill="#d8d8d8"
    />
    <path
      d="M613.77,479.37a5.45,5.45,0,0,1-.37-1.62,5.83,5.83,0,0,1-4.36,1.9,5.44,5.44,0,0,1-3.72-1.28,4.15,4.15,0,0,1-1.45-3.25,4.34,4.34,0,0,1,1.82-3.72,8.59,8.59,0,0,1,5.12-1.32h2.55v-1.21a3,3,0,0,0-.82-2.18,3.29,3.29,0,0,0-2.43-.82,3.86,3.86,0,0,0-2.35.71,2.09,2.09,0,0,0-1,1.71h-2.63a3.57,3.57,0,0,1,.82-2.21,5.39,5.39,0,0,1,2.2-1.69,7.39,7.39,0,0,1,3.07-.63,6,6,0,0,1,4.15,1.33,4.77,4.77,0,0,1,1.56,3.64v7a8.81,8.81,0,0,0,.53,3.36v.23Zm-4.35-2a4.58,4.58,0,0,0,2.34-.64,3.85,3.85,0,0,0,1.6-1.65v-3.15H611.3q-4.81,0-4.81,2.82a2.4,2.4,0,0,0,.82,1.93A3.17,3.17,0,0,0,609.42,477.37Z"
      fill="#d8d8d8"
    />
    <path
      d="M629.29,475.3a1.91,1.91,0,0,0-.8-1.65,7.63,7.63,0,0,0-2.79-1,12.93,12.93,0,0,1-3.15-1,4.56,4.56,0,0,1-1.73-1.41,3.42,3.42,0,0,1-.56-2,4,4,0,0,1,1.59-3.19,6.28,6.28,0,0,1,4.07-1.3,6.39,6.39,0,0,1,4.23,1.35,4.26,4.26,0,0,1,1.62,3.44h-2.63a2.38,2.38,0,0,0-.92-1.86,3.39,3.39,0,0,0-2.3-.78,3.52,3.52,0,0,0-2.23.63,2,2,0,0,0-.81,1.63,1.59,1.59,0,0,0,.75,1.43,9.44,9.44,0,0,0,2.71.92,14.26,14.26,0,0,1,3.18,1,4.79,4.79,0,0,1,1.81,1.47,3.62,3.62,0,0,1,.58,2.09,3.91,3.91,0,0,1-1.64,3.29,6.84,6.84,0,0,1-4.26,1.24,7.81,7.81,0,0,1-3.26-.65,5.32,5.32,0,0,1-2.21-1.82,4.34,4.34,0,0,1-.8-2.53h2.62a2.67,2.67,0,0,0,1,2.09,4.12,4.12,0,0,0,2.6.77,4.19,4.19,0,0,0,2.38-.6A1.85,1.85,0,0,0,629.29,475.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M652.54,479.37a5.52,5.52,0,0,1-.36-1.62,5.85,5.85,0,0,1-4.37,1.9,5.41,5.41,0,0,1-3.71-1.28,4.15,4.15,0,0,1-1.45-3.25,4.31,4.31,0,0,1,1.82-3.72,8.58,8.58,0,0,1,5.11-1.32h2.55v-1.21a2.92,2.92,0,0,0-.82-2.18,3.25,3.25,0,0,0-2.42-.82,3.84,3.84,0,0,0-2.35.71,2.09,2.09,0,0,0-.95,1.71H643a3.61,3.61,0,0,1,.81-2.21,5.43,5.43,0,0,1,2.21-1.69,7.36,7.36,0,0,1,3.07-.63,6,6,0,0,1,4.15,1.33,4.73,4.73,0,0,1,1.55,3.64v7a8.6,8.6,0,0,0,.54,3.36v.23Zm-4.34-2a4.52,4.52,0,0,0,2.33-.64,3.79,3.79,0,0,0,1.6-1.65v-3.15h-2q-4.82,0-4.81,2.82a2.4,2.4,0,0,0,.82,1.93A3.15,3.15,0,0,0,648.2,477.37Z"
      fill="#d8d8d8"
    />
    <path
      d="M659.06,483.47l-1.48-1a6.81,6.81,0,0,0,1.39-3.82v-2.36h2.56v2.05a6.41,6.41,0,0,1-.7,2.86A6.68,6.68,0,0,1,659.06,483.47Z"
      fill="#d8d8d8"
    />
    <path
      d="M673.89,479.37v-13.3h-2.42v-2h2.42v-1.58a5.22,5.22,0,0,1,1.32-3.81,5,5,0,0,1,3.72-1.34,6.9,6.9,0,0,1,1.8.24l-.14,2.11a7.21,7.21,0,0,0-1.42-.13,2.56,2.56,0,0,0-2,.75,3,3,0,0,0-.69,2.14v1.62h3.27v2h-3.27v13.3Z"
      fill="#d8d8d8"
    />
    <path
      d="M690.37,466.4a7.5,7.5,0,0,0-1.29-.1,3.45,3.45,0,0,0-3.5,2.19v10.88H683V464.05h2.55l0,1.77a4.09,4.09,0,0,1,3.65-2.06,2.77,2.77,0,0,1,1.17.2Z"
      fill="#d8d8d8"
    />
    <path
      d="M693.08,460a1.55,1.55,0,0,1,.39-1.07,1.43,1.43,0,0,1,1.15-.44,1.45,1.45,0,0,1,1.16.44,1.51,1.51,0,0,1,.4,1.07,1.46,1.46,0,0,1-.4,1.06,1.48,1.48,0,0,1-1.16.43,1.46,1.46,0,0,1-1.15-.43A1.49,1.49,0,0,1,693.08,460Zm2.83,19.39h-2.62V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path
      d="M702.87,464.05,703,466a5.59,5.59,0,0,1,4.59-2.21q4.86,0,4.9,5.48v10.13h-2.62V469.23a3.5,3.5,0,0,0-.76-2.45,3,3,0,0,0-2.31-.79,3.8,3.8,0,0,0-2.24.67,4.68,4.68,0,0,0-1.5,1.79v10.92h-2.62V464.05Z"
      fill="#d8d8d8"
    />
    <path
      d="M716.06,471.58a9,9,0,0,1,1.65-5.7,5.72,5.72,0,0,1,8.77-.14l.13-1.69H729V479a6.27,6.27,0,0,1-1.76,4.69,6.53,6.53,0,0,1-4.74,1.71,7.93,7.93,0,0,1-3.24-.71,5.52,5.52,0,0,1-2.42-1.94l1.36-1.57a5.1,5.1,0,0,0,4.12,2.08,4,4,0,0,0,3-1.08,4.1,4.1,0,0,0,1.07-3v-1.31a5.38,5.38,0,0,1-4.29,1.81,5.23,5.23,0,0,1-4.36-2.17A9.44,9.44,0,0,1,716.06,471.58Zm2.63.3a7,7,0,0,0,1.06,4.07,3.47,3.47,0,0,0,3,1.48,3.85,3.85,0,0,0,3.64-2.25v-7a3.89,3.89,0,0,0-3.61-2.19,3.48,3.48,0,0,0-3,1.48A7.47,7.47,0,0,0,718.69,471.88Z"
      fill="#d8d8d8"
    />
    <path
      d="M733.26,460a1.59,1.59,0,0,1,.38-1.07,1.75,1.75,0,0,1,2.32,0,1.56,1.56,0,0,1,.4,1.07A1.5,1.5,0,0,1,736,461a1.78,1.78,0,0,1-2.32,0A1.53,1.53,0,0,1,733.26,460Zm2.83,19.39h-2.62V464.05h2.62Z"
      fill="#d8d8d8"
    />
    <path d="M743.41,479.37h-2.62V457.62h2.62Z" fill="#d8d8d8" />
    <path d="M750.74,479.37h-2.62V457.62h2.62Z" fill="#d8d8d8" />
    <path
      d="M764.68,479.37a5.79,5.79,0,0,1-.37-1.62,5.81,5.81,0,0,1-4.36,1.9,5.4,5.4,0,0,1-3.71-1.28,4.16,4.16,0,0,1-1.46-3.25,4.34,4.34,0,0,1,1.82-3.72,8.62,8.62,0,0,1,5.12-1.32h2.55v-1.21a2.92,2.92,0,0,0-.82-2.18,3.27,3.27,0,0,0-2.42-.82,3.82,3.82,0,0,0-2.35.71,2.09,2.09,0,0,0-.95,1.71H755.1a3.61,3.61,0,0,1,.81-2.21,5.43,5.43,0,0,1,2.21-1.69,7.36,7.36,0,0,1,3.07-.63,6,6,0,0,1,4.14,1.33,4.77,4.77,0,0,1,1.56,3.64v7a8.6,8.6,0,0,0,.54,3.36v.23Zm-4.34-2a4.52,4.52,0,0,0,2.33-.64,3.79,3.79,0,0,0,1.6-1.65v-3.15h-2q-4.82,0-4.82,2.82a2.4,2.4,0,0,0,.83,1.93A3.15,3.15,0,0,0,760.34,477.37Z"
      fill="#d8d8d8"
    />
    <path
      d="M771.34,478a1.64,1.64,0,0,1,.41-1.13,1.54,1.54,0,0,1,1.21-.45,1.56,1.56,0,0,1,1.22.45,1.6,1.6,0,0,1,.42,1.13,1.5,1.5,0,0,1-.42,1.09,1.56,1.56,0,0,1-1.22.44,1.47,1.47,0,0,1-1.62-1.53Z"
      fill="#d8d8d8"
    />
    <path
      d="M779.27,478a1.63,1.63,0,0,1,.4-1.13,1.54,1.54,0,0,1,1.21-.45,1.6,1.6,0,0,1,1.23.45,1.64,1.64,0,0,1,.41,1.13,1.54,1.54,0,0,1-.41,1.09,1.6,1.6,0,0,1-1.23.44,1.45,1.45,0,0,1-1.61-1.53Z"
      fill="#d8d8d8"
    />
    <path
      d="M787.19,478a1.63,1.63,0,0,1,.4-1.13,1.55,1.55,0,0,1,1.21-.45,1.5,1.5,0,0,1,1.65,1.58,1.5,1.5,0,0,1-.42,1.09,1.6,1.6,0,0,1-1.23.44,1.55,1.55,0,0,1-1.21-.44A1.53,1.53,0,0,1,787.19,478Z"
      fill="#d8d8d8"
    />
  </svg>
);

export default LiveCamWidgetSkin3Icon;

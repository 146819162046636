export default {
  accountRegisteredThanks: {
    id: 'accountRegistered.AccountRegisteredThanks',
    description: 'Thank you for registering !',
    defaultMessage: 'Thank you for registering !',
  },
  accountRegisteredBeforeAccess: {
    id: 'accountRegistered.AccountRegisteredBeforeAccess',
    description:
      'Before you gain access to our offers, we need to review your registration. This process can take up to 3 business days. You will receive a notification email once it has been completed.',
    defaultMessage:
      'Before you gain access to our offers, we need to review your registration. This process can take up to 3 business days. You will receive a notification email once it has been completed.',
  },
  accountRegisteredReadBlog: {
    id: 'accountRegistered.AccountRegisteredReadBlog',
    description: 'In the meantime, why not give {LinkBlog} a read?',
    defaultMessage: 'In the meantime, why not give {LinkBlog} a read?',
  },
  accountRegisteredLinkBlog: {
    id: 'accountRegistered.AccountRegisteredReadBlog',
    description: 'our blog',
    defaultMessage: 'our blog',
  },
};

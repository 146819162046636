import { createMessagesFromLabels } from '../helpers';

const statesList = [
  {
    value: 'AL',
    label: {
      id: 'stateList.AL',
      description: 'Alabama',
      defaultMessage: 'Alabama',
    },
    name: 'stateList.AL',
  },
  {
    value: 'AK',
    label: {
      id: 'stateList.AK',
      description: 'Alaska',
      defaultMessage: 'Alaska',
    },
    name: 'stateList.AK',
  },
  {
    value: 'AZ',
    label: {
      id: 'stateList.AZ',
      description: 'Arizona',
      defaultMessage: 'Arizona',
    },
    name: 'stateList.AZ',
  },
  {
    value: 'AR',
    label: {
      id: 'stateList.AR',
      description: 'Arkansas',
      defaultMessage: 'Arkansas',
    },
    name: 'stateList.AR',
  },
  {
    value: 'CA',
    label: {
      id: 'stateList.CA',
      description: 'California',
      defaultMessage: 'California',
    },
    name: 'stateList.CA',
  },
  {
    value: 'CO',
    label: {
      id: 'stateList.CO',
      description: 'Colorado',
      defaultMessage: 'Colorado',
    },
    name: 'stateList.CO',
  },
  {
    value: 'CT',
    label: {
      id: 'stateList.CT',
      description: 'Connecticut',
      defaultMessage: 'Connecticut',
    },
    name: 'stateList.CT',
  },
  {
    value: 'DE',
    label: {
      id: 'stateList.DE',
      description: 'Delaware',
      defaultMessage: 'Delaware',
    },
    name: 'stateList.DE',
  },
  {
    value: 'DC',
    label: {
      id: 'stateList.DC',
      description: 'District of Columbia',
      defaultMessage: 'District of Columbia',
    },
    name: 'stateList.DC',
  },
  {
    value: 'FL',
    label: {
      id: 'stateList.FL',
      description: 'Florida',
      defaultMessage: 'Florida',
    },
    name: 'stateList.FL',
  },
  {
    value: 'GA',
    label: {
      id: 'stateList.GA',
      description: 'Georgia',
      defaultMessage: 'Georgia',
    },
    name: 'stateList.GA',
  },
  {
    value: 'HI',
    label: {
      id: 'stateList.HI',
      description: 'Hawaii',
      defaultMessage: 'Hawaii',
    },
    name: 'stateList.HI',
  },
  {
    value: 'ID',
    label: {
      id: 'stateList.ID',
      description: 'Idaho',
      defaultMessage: 'Idaho',
    },
    name: 'stateList.ID',
  },
  {
    value: 'IL',
    label: {
      id: 'stateList.IL',
      description: 'Illinois',
      defaultMessage: 'Illinois',
    },
    name: 'stateList.IL',
  },
  {
    value: 'IN',
    label: {
      id: 'stateList.IN',
      description: 'Indiana',
      defaultMessage: 'Indiana',
    },
    name: 'stateList.IN',
  },
  {
    value: 'IA',
    label: {
      id: 'stateList.IA',
      description: 'Iowa',
      defaultMessage: 'Iowa',
    },
    name: 'stateList.IA',
  },
  {
    value: 'KS',
    label: {
      id: 'stateList.KS',
      description: 'Kansas',
      defaultMessage: 'Kansas',
    },
    name: 'stateList.KS',
  },
  {
    value: 'KY',
    label: {
      id: 'stateList.KY',
      description: 'Kentucky',
      defaultMessage: 'Kentucky',
    },
    name: 'stateList.KY',
  },
  {
    value: 'LA',
    label: {
      id: 'stateList.LA',
      description: 'Louisiana',
      defaultMessage: 'Louisiana',
    },
    name: 'stateList.LA',
  },
  {
    value: 'ME',
    label: {
      id: 'stateList.ME',
      description: 'Maine',
      defaultMessage: 'Maine',
    },
    name: 'stateList.ME',
  },
  {
    value: 'MD',
    label: {
      id: 'stateList.MD',
      description: 'Maryland',
      defaultMessage: 'Maryland',
    },
    name: 'stateList.MD',
  },
  {
    value: 'MA',
    label: {
      id: 'stateList.MA',
      description: 'Massachusetts',
      defaultMessage: 'Massachusetts',
    },
    name: 'stateList.MA',
  },
  {
    value: 'MI',
    label: {
      id: 'stateList.MI',
      description: 'Michigan',
      defaultMessage: 'Michigan',
    },
    name: 'stateList.MI',
  },
  {
    value: 'Mn',
    label: {
      id: 'stateList.Mn',
      description: 'Minnesota',
      defaultMessage: 'Minnesota',
    },
    name: 'stateList.Mn',
  },
  {
    value: 'MS',
    label: {
      id: 'stateList.MS',
      description: 'Mississippi',
      defaultMessage: 'Mississippi',
    },
    name: 'stateList.MS',
  },
  {
    value: 'MO',
    label: {
      id: 'stateList.MO',
      description: 'Missouri',
      defaultMessage: 'Missouri',
    },
    name: 'stateList.MO',
  },
  {
    value: 'MT',
    label: {
      id: 'stateList.MT',
      description: 'Montana',
      defaultMessage: 'Montana',
    },
    name: 'stateList.MT',
  },
  {
    value: 'NE',
    label: {
      id: 'stateList.NE',
      description: 'Nebraska',
      defaultMessage: 'Nebraska',
    },
    name: 'stateList.NE',
  },
  {
    value: 'NV',
    label: {
      id: 'stateList.NV',
      description: 'Nevada',
      defaultMessage: 'Nevada',
    },
    name: 'stateList.NV',
  },
  {
    value: 'NH',
    label: {
      id: 'stateList.NH',
      description: 'New Hampshire',
      defaultMessage: 'New Hampshire',
    },
    name: 'stateList.NH',
  },
  {
    value: 'NJ',
    label: {
      id: 'stateList.NJ',
      description: 'New Jersey',
      defaultMessage: 'New Jersey',
    },
    name: 'stateList.NJ',
  },
  {
    value: 'NM',
    label: {
      id: 'stateList.NM',
      description: 'New Mexico',
      defaultMessage: 'New Mexico',
    },
    name: 'stateList.NM',
  },
  {
    value: 'NY',
    label: {
      id: 'stateList.NY',
      description: 'New York',
      defaultMessage: 'New York',
    },
    name: 'stateList.NY',
  },
  {
    value: 'NC',
    label: {
      id: 'stateList.NC',
      description: 'North Carolina',
      defaultMessage: 'North Carolina',
    },
    name: 'stateList.NC',
  },
  {
    value: 'ND',
    label: {
      id: 'stateList.ND',
      description: 'North Dakota',
      defaultMessage: 'North Dakota',
    },
    name: 'stateList.ND',
  },
  {
    value: 'OH',
    label: {
      id: 'stateList.OH',
      description: 'Ohio',
      defaultMessage: 'Ohio',
    },
    name: 'stateList.OH',
  },
  {
    value: 'OK',
    label: {
      id: 'stateList.OK',
      description: 'Oklahoma',
      defaultMessage: 'Oklahoma',
    },
    name: 'stateList.OK',
  },
  {
    value: 'OR',
    label: {
      id: 'stateList.OR',
      description: 'Oregon',
      defaultMessage: 'Oregon',
    },
    name: 'stateList.OR',
  },
  {
    value: 'PA',
    label: {
      id: 'stateList.PA',
      description: 'Pennsylvania',
      defaultMessage: 'Pennsylvania',
    },
    name: 'stateList.PA',
  },
  {
    value: 'RI',
    label: {
      id: 'stateList.RI',
      description: 'Rhode Island',
      defaultMessage: 'Rhode Island',
    },
    name: 'stateList.RI',
  },
  {
    value: 'SC',
    label: {
      id: 'stateList.SC',
      description: 'South Carolina',
      defaultMessage: 'South Carolina',
    },
    name: 'stateList.SC',
  },
  {
    value: 'SD',
    label: {
      id: 'stateList.SD',
      description: 'South Dakota',
      defaultMessage: 'South Dakota',
    },
    name: 'stateList.SD',
  },
  {
    value: 'TN',
    label: {
      id: 'stateList.TN',
      description: 'Tennessee',
      defaultMessage: 'Tennessee',
    },
    name: 'stateList.TN',
  },
  {
    value: 'TX',
    label: {
      id: 'stateList.TX',
      description: 'Texas',
      defaultMessage: 'Texas',
    },
    name: 'stateList.TX',
  },
  {
    value: 'UT',
    label: {
      id: 'stateList.UT',
      description: 'Utah',
      defaultMessage: 'Utah',
    },
    name: 'stateList.UT',
  },
  {
    value: 'VT',
    label: {
      id: 'stateList.VT',
      description: 'Vermont',
      defaultMessage: 'Vermont',
    },
    name: 'stateList.VT',
  },
  {
    value: 'VA',
    label: {
      id: 'stateList.VA',
      description: 'Virginia',
      defaultMessage: 'Virginia',
    },
    name: 'stateList.VA',
  },
  {
    value: 'WA',
    label: {
      id: 'stateList.WA',
      description: 'Washington',
      defaultMessage: 'Washington',
    },
    name: 'stateList.WA',
  },
  {
    value: 'WV',
    label: {
      id: 'stateList.WV',
      description: 'West Virginia',
      defaultMessage: 'West Virginia',
    },
    name: 'stateList.WV',
  },
  {
    value: 'WI',
    label: {
      id: 'stateList.WI',
      description: 'Wisconsin',
      defaultMessage: 'Wisconsin',
    },
    name: 'stateList.WI',
  },
  {
    value: 'WY',
    label: {
      id: 'stateList.WY',
      description: 'Wyoming',
      defaultMessage: 'Wyoming',
    },
    name: 'stateList.WY',
  },
];

export default statesList;
export const labelMessagesObj = createMessagesFromLabels(statesList);

export const getStateLabelDefaultMessage = (stateCode) => {
  const state = statesList.find((current) => current.value === stateCode);
  if (state) {
    return state.label.defaultMessage;
  }
  return undefined;
};

export const getStateCode = (stateLabelDefaultMessage) => {
  const state = statesList.find((current) => current.label.defaultMessage === stateLabelDefaultMessage);
  if (state) {
    return state.value;
  }
  return undefined;
};

const ClickBronze = () => (
  <svg fill="none" height="83" viewBox="0 0 59 83" width="59" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1440_2950)">
      <path
        d="M58.09 52.84L0 0L10.32 77.83L24.61 66.01L33.21 82.44L48.82 74.27L40.22 57.83L58.08 52.83L58.09 52.84ZM42.08 72.16L35.33 75.69L26.19 58.22L14.1 68.22L6.76 12.9L48.05 50.45L32.94 54.68L42.08 72.16Z"
        fill="#F9AA5D"
      />
      <path
        d="M32.94 54.6799L42.08 72.1599L35.33 75.6899L26.18 58.2299L14.09 68.2299L6.76001 12.8999L48.05 50.4599L32.94 54.6799Z"
        fill="url(#paint0_linear_1440_2950)"
      />
      <path
        d="M32.5701 54.53L41.7401 72.06L37.5301 74.2601L35.4301 75.3601L34.8501 74.2601L26.2601 57.84L14.2801 67.75L10.6901 40.68L7.09009 13.55L32.4401 36.6L47.5401 50.34L32.5701 54.53Z"
        fill="url(#paint1_linear_1440_2950)"
      />
      <path
        d="M32.5699 54.5301L41.7399 72.0601L37.5299 74.2601H34.8499L26.2599 57.8401L14.2799 67.7501L10.6899 40.6801L32.4399 36.6001L47.5399 50.3401L32.5699 54.5301Z"
        fill="url(#paint2_linear_1440_2950)"
        opacity="0.2"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="M26.1801 58.23L24.6201 66.01L33.2201 82.44L35.3301 75.69L26.1801 58.23Z" fill="url(#paint3_linear_1440_2950)" />
      <g opacity="0.5" style={{ mixBlendMode: 'soft-light' }}>
        <path d="M42.08 72.1599L48.83 74.2799L33.22 82.4399L35.33 75.6899L42.08 72.1599Z" fill="#F9AA5D" />
      </g>
      <path
        d="M32.9399 54.6799L40.2299 57.8399L48.8299 74.2799L42.0799 72.1599L32.9399 54.6799Z"
        fill="url(#paint4_linear_1440_2950)"
      />
      <path d="M58.0899 52.84L48.0499 50.46L32.9399 54.68L40.2299 57.84L58.0899 52.84Z" fill="url(#paint5_linear_1440_2950)" />
      <path d="M26.1801 58.23L24.6201 66.01L10.3201 77.83L14.0901 68.23L26.1801 58.23Z" fill="url(#paint6_linear_1440_2950)" />
      <path d="M0 0L6.76 12.9L48.05 50.46L58.09 52.84L0 0Z" fill="url(#paint7_linear_1440_2950)" />
      <g opacity="0.75">
        <path
          d="M7.09008 13.5501L47.5401 50.3401L32.5701 54.5301L41.7401 72.0601L35.4301 75.3601L26.2601 57.8401L14.2801 67.7501L7.09008 13.5501ZM6.08008 11.6101L6.35008 13.6401L13.5401 67.8401L13.7201 69.1801L14.7601 68.3201L26.0301 59.0001L34.7701 75.7001L35.1201 76.3601L35.7801 76.0101L42.0901 72.7101L42.7501 72.3601L42.4001 71.7001L33.6601 54.9901L47.7401 51.0501L49.0501 50.6801L48.0501 49.7701L7.60008 12.9901L6.08008 11.6101Z"
          fill="#F9AA5D"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1440_2950"
        x1="6.76001"
        x2="48.05"
        y1="44.2999"
        y2="44.2999"
      >
        <stop stopColor="#C46700" />
        <stop offset="0.52" stopColor="#B35400" />
        <stop offset="1" stopColor="#9F4900" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_1440_2950" x1="7.09009" x2="47.5401" y1="44.45" y2="44.45">
        <stop stopColor="#6F2905" />
        <stop offset="1" stopColor="#9F4900" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1440_2950"
        x1="27.2199"
        x2="34.9999"
        y1="38.5101"
        y2="81.7501"
      >
        <stop stopColor="#6F2905" />
        <stop offset="1" stopColor="#9F4900" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint3_linear_1440_2950" x1="24.6201" x2="35.3301" y1="70.33" y2="70.33">
        <stop stopColor="#6F2905" />
        <stop offset="1" stopColor="#9F4900" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_1440_2950"
        x1="32.9399"
        x2="48.8299"
        y1="64.4799"
        y2="64.4799"
      >
        <stop stopColor="#6F2905" />
        <stop offset="1" stopColor="#9F4900" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint5_linear_1440_2950" x1="46.2499" x2="45.2599" y1="45.59" y2="56.6">
        <stop stopColor="#6F2905" />
        <stop offset="1" stopColor="#9F4900" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint6_linear_1440_2950" x1="20.2801" x2="17.7101" y1="45.45" y2="74.11">
        <stop stopColor="#C46700" />
        <stop offset="0.52" stopColor="#B35400" />
        <stop offset="1" stopColor="#9F4900" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint7_linear_1440_2950" x1="23.81" x2="36.58" y1="-2.7" y2="68.26">
        <stop stopColor="#C46700" />
        <stop offset="0.52" stopColor="#B35400" />
        <stop offset="1" stopColor="#9F4900" />
      </linearGradient>
      <clipPath id="clip0_1440_2950">
        <rect fill="white" height="82.44" width="58.09" />
      </clipPath>
    </defs>
  </svg>
);

export default ClickBronze;

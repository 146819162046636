import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  alertsIsFetching: false,
  newAlertsIsFetching: false,
  dismissAlertsIsFetching: false,
  alerts: [],
  newAlerts: [],
  alertsSettings: {
    page: 1,
    limit: 25,
  },
  alertsInfos: {
    currentPage: 1,
    total: 0,
    pageCount: 0,
  },
};

export const alerts = (state = initialState, action = {}) => {
  const { alertsInfos, alertsSettings } = state;

  switch (action.type) {
    case ACTIONS.REQUEST_ALERTS:
      return {
        ...state,
        alertsIsFetching: true,
      };

    case ACTIONS.RECEIVE_ALERTS:
      return {
        ...state,
        alerts: action.alerts,
        alertsIsFetching: false,
        alertsInfos: {
          ...alertsInfos,
          total: action.alertsCount,
          pageCount: action.pageCount,
          currentPage: action.page,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALERTS:
      return {
        ...state,
        alertsIsFetching: false,
      };

    case ACTIONS.CHANGE_ALERTS_LIST_PAGE:
      return {
        ...state,
        alertsSettings: {
          ...alertsSettings,
          page: action.page,
        },
      };

    case ACTIONS.CHANGE_ALERTS_ROWS_PER_PAGE:
      return {
        ...state,
        alertsSettings: {
          ...alertsSettings,
          limit: action.limit,
          page: action.page,
        },
      };

    case ACTIONS.REQUEST_NEW_ALERTS:
      return {
        ...state,
        newAlertsIsFetching: true,
      };

    case ACTIONS.RECEIVE_NEW_ALERTS:
      return {
        ...state,
        newAlerts: action.newAlerts,
        newAlertsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_NEW_ALERTS:
      return {
        ...state,
        newAlertsIsFetching: false,
      };

    case ACTIONS.REQUEST_DISMISS_ALERTS:
      return {
        ...state,
        dismissAlertsIsFetching: true,
      };

    case ACTIONS.RECEIVE_DISMISS_ALERTS:
      return {
        ...state,
        newAlerts: [],
        dismissAlertsIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_DISMISS_ALERTS:
      return {
        ...state,
        dismissAlertsIsFetching: false,
      };

    default:
      return state;
  }
};

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { InputNotice, InputText } from '../../../components/reusables';

import provincesList from '../../../utils/list-options/provinces';
import statesList from '../../../utils/list-options/states';

import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper.js';

import config from '../../../config';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';
const { CAN_NOTICE } = config;

function AchSection({ achConfigurations, accountType, countries, fields, values, willRender, intl, dispatch }) {
  const getAchConfiguration = (currentConfigId) => achConfigurations.filter((conf) => conf.id === currentConfigId)[0];

  const getInputTextLabel = (inputName, label) => ({
    id: `paymentFormLabel.${inputName}`,
    description: label,
    defaultMessage: label,
  });

  const getInputTextLabelForTransitNumber = (label) => getInputTextLabel('achTransitNumber', label);

  const getMaxLengthFromConfig = (currentConfig) => {
    const boundaries = currentConfig.split(':');
    return boundaries[boundaries.length - 1];
  };

  const handleAchConfigIdChange = (oldValue, newValue) => {
    if ([undefined, null, ''].indexOf(oldValue) === -1 && oldValue !== newValue) {
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'billingInfos',
        field: 'achTransitNumber',
        value: '',
      });

      dispatch({
        type: 'redux-form/CHANGE',
        form: 'billingInfos',
        field: 'achAccountNumber',
        value: '',
      });

      dispatch({
        type: 'redux-form/CHANGE',
        form: 'billingInfos',
        field: 'achCountryName',
        value: getAchConfiguration(newValue).country_name,
      });
    }
  };

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getBillingAchExcludedCountries()
  );

  if (!(willRender && values.minimumPayouts !== null)) {
    return false;
  }

  const achConfiguration = getAchConfiguration(values.achConfigId);
  let tabIndex = 30;

  return (
    <div className="ach_section">
      <div className="bankInfos_section">
        <div className="row">
          <div className="col-md-12">
            <h2>
              <FormattedMessage {...messages.paymentInfosBankInfosTitle} />
            </h2>
          </div>
        </div>
        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              enableSearch
              id="achConfigId"
              label={messages.paymentInfosBankInformationCountry.description}
              onChange={(e) => {
                handleAchConfigIdChange(values.achConfigId, e);
              }}
              options={achConfigurations.map((configuration) => ({ value: configuration.id, text: configuration.country_name }))}
              showSelectedFieldCheckmark={false}
              tabIndex={++tabIndex}
              touched={fields.achConfigId.touched}
              value={fields.achConfigId.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
          <InputNotice className="col-md-12">
            <span>
              <FormattedMessage {...messages.paymentInfosAchBankAccountCurrencyMessage} />
              <br />
              <FormattedMessage {...messages.paymentInfosAchBankNotAccepted} />
              <br />
              {achConfiguration !== undefined && [null, undefined, 'USD'].indexOf(achConfiguration.currency) === -1 && (
                <FormattedMessage
                  {...messages.paymentInfosAchExchangeRate}
                  values={{
                    currency: achConfiguration.currency,
                  }}
                />
              )}
            </span>
          </InputNotice>
        </div>
        {values.achConfigId !== null && achConfiguration !== undefined && (
          <div className="bank-account-transit">
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achBeneficiaryBankName}
                id="achBeneficiaryBankName"
                label={messages.paymentInfosBankName}
                maxLength={70}
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achBeneficiaryBankAddress}
                id="achBeneficiaryBankAddress"
                label={messages.paymentInfosBankAddress}
                maxLength={100}
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <InputText displayErrorInstantly field={fields.achCountryName} id="achCountryName" type="hidden" />
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achTransitNumber}
                id="achTransitNumber"
                label={getInputTextLabelForTransitNumber(achConfiguration.transit_number.label)}
                maxLength={parseInt(getMaxLengthFromConfig(achConfiguration.transit_number.config), 10)}
                tabIndex={++tabIndex}
                type="text"
              />
              <InputNotice className="col-md-12" detailsUrl={achConfiguration.country_name.match(/canada/i) && CAN_NOTICE}>
                {achConfiguration.transit_number.instructions}
              </InputNotice>
            </div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achAccountNumber}
                id="achAccountNumber"
                label={getInputTextLabelForTransitNumber(achConfiguration.account_number.label)}
                maxLength={parseInt(getMaxLengthFromConfig(achConfiguration.account_number.config), 10)}
                tabIndex={++tabIndex}
                type="text"
              />
              <InputNotice className="col-md-12">{achConfiguration.account_number.instructions}</InputNotice>
            </div>
          </div>
        )}
      </div>
      {values.achConfigId !== null && achConfiguration !== undefined && accountType === 'Legacy' && (
        <div className="beneficiaryInfos_section">
          <div className="row">
            <div className="col-md-12">
              <h2>
                <FormattedMessage {...messages.paymentInfosBeneficiaryInfosTitle} />
              </h2>
            </div>
          </div>
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.achBeneficiaryName}
              id="achBeneficiaryName"
              label={messages.genericTextName}
              maxLength={70}
              tabIndex={++tabIndex}
              type="text"
            />
          </div>
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.achBeneficiaryAddress}
              id="achBeneficiaryAddress"
              label={messages.genericTextAddress}
              maxLength={100}
              tabIndex={++tabIndex}
              type="text"
            />
          </div>
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.achBeneficiaryCity}
              id="achBeneficiaryCity"
              label={messages.genericTextCity}
              maxLength={20}
              tabIndex={++tabIndex}
              type="text"
            />
          </div>
          <div className="customSelects">
            <div className="fields">
              <SelectCustom
                id="achBeneficiaryCountryCode"
                label={messages.genericTextCountry.description}
                onChange={fields.achBeneficiaryCountryCode.onChange}
                tabIndex={++tabIndex}
                value={fields.achBeneficiaryCountryCode.value || ''}
                touched={fields.achBeneficiaryCountryCode.touched}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                showSelectedFieldCheckmark={false}
                options={filteredCountries.map((country) => ({ value: country.code, text: country.name }))}
              />
            </div>
          </div>
          <div className="customSelects">
            <div className="fields">
              {(() => {
                switch (values.achBeneficiaryCountryCode) {
                  case 'CA':
                    return (
                      <SelectCustom
                        id="achBeneficiaryState"
                        label={messages.genericTextProvince.description}
                        onChange={fields.achBeneficiaryState.onChange}
                        tabIndex={++tabIndex}
                        value={fields.achBeneficiaryState.value || ''}
                        touched={fields.achBeneficiaryState.touched}
                        valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                        showSelectedFieldCheckmark={false}
                        options={provincesList.map((province) => ({
                          value: province.value,
                          text: intl.formatMessage(messages[province.label.id]),
                        }))}
                      />
                    );
                  case 'US':
                    return (
                      <SelectCustom
                        id="achBeneficiaryState"
                        label={messages.genericTextState.description}
                        onChange={fields.achBeneficiaryState.onChange}
                        tabIndex={++tabIndex}
                        value={fields.achBeneficiaryState.value || ''}
                        touched={fields.achBeneficiaryState.touched}
                        valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                        showSelectedFieldCheckmark={false}
                        options={statesList.map((map) => ({
                          value: map.value,
                          text: intl.formatMessage(messages[map.label.id]),
                        }))}
                      />
                    );
                  default:
                    return (
                      <div className="row">
                        <InputText
                          className="col-md-12"
                          displayErrorInstantly
                          field={fields.achBeneficiaryState}
                          id="achBeneficiaryState"
                          label={messages.genericTextRegion}
                          tabIndex={++tabIndex}
                          type="text"
                        />
                      </div>
                    );
                }
              })()}
            </div>
          </div>
          <div className="row">
            {values.achBeneficiaryCountryCode === 'CA' ? (
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achBeneficiaryZipCode}
                id="achBeneficiaryZipCode"
                label={messages.genericTextPostalCode}
                maxLength={6}
                tabIndex={++tabIndex}
                type="text"
              />
            ) : (
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.achBeneficiaryZipCode}
                id="achBeneficiaryZipCode"
                label={messages.genericTextZipCode}
                maxLength={15}
                tabIndex={++tabIndex}
                type="text"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

AchSection.propTypes = {
  accountType: PropTypes.string.isRequired,
  achConfigurations: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default injectIntl(AchSection);

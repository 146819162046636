const Statistics = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M3.39277 3.21249C4.18055 2.36746 5.30234 1.89453 6.6267 1.89453H13.3744C14.702 1.89453 15.8239 2.36723 16.611 3.21284C17.3935 4.0536 17.8002 5.21417 17.8002 6.51642V12.8734C17.8002 14.1757 17.3935 15.3361 16.6108 16.1767C15.8236 17.0222 14.7014 17.4946 13.3737 17.4946H6.6267C5.29931 17.4946 4.1772 17.0221 3.38993 16.1767C2.60716 15.3362 2.2002 14.1758 2.2002 12.8734V6.51642C2.2002 5.21333 2.60935 4.05286 3.39277 3.21249ZM4.27052 4.03076C3.72856 4.61211 3.4002 5.46257 3.4002 6.51642V12.8734C3.4002 13.928 3.72725 14.7782 4.2681 15.359C4.80447 15.9349 5.5956 16.2946 6.6267 16.2946H13.3737C14.4052 16.2946 15.1964 15.9349 15.7325 15.359C16.2733 14.7782 16.6002 13.9281 16.6002 12.8734V6.51642C16.6002 5.46173 16.2733 4.61136 15.7326 4.03041C15.1964 3.45432 14.4055 3.09453 13.3744 3.09453H6.6267C5.59959 3.09453 4.80812 3.45408 4.27052 4.03076Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.0272 5.44458C10.3585 5.44458 10.6272 5.71321 10.6272 6.04458V13.3473C10.6272 13.6787 10.3585 13.9473 10.0272 13.9473C9.6958 13.9473 9.42716 13.6787 9.42716 13.3473V6.04458C9.42716 5.71321 9.6958 5.44458 10.0272 5.44458ZM6.66738 7.80784C6.99876 7.80784 7.26738 8.07645 7.26738 8.40781V13.3474C7.26738 13.6788 6.99876 13.9474 6.66738 13.9474C6.33601 13.9474 6.06738 13.6788 6.06738 13.3474V8.40781C6.06738 8.07645 6.33601 7.80784 6.66738 7.80784ZM13.3323 10.4181C13.6636 10.4181 13.9323 10.6868 13.9323 11.0181V13.3471C13.9323 13.6785 13.6636 13.9471 13.3323 13.9471C13.0009 13.9471 12.7323 13.6785 12.7323 13.3471V11.0181C12.7323 10.6868 13.0009 10.4181 13.3323 10.4181Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default Statistics;

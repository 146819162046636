import React, { createContext, useContext, useEffect, useState } from 'react';
import Tabs from './banners/Tabs';
import { injectIntl } from 'react-intl';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import {
  BANNER_FILTERS,
  BANNER_TAB_ALL_CREATIVES,
  BANNER_TAB_TRENDING_BANNERS,
  DEFAULT_BANNER_FILTER_STATES,
  USE_PROVEN_FLOW,
  filterTrendingBannerDuplicateIds,
  isAllCreativesTab,
  isSelectCreativesTabs,
  isTrendingCreativesTab,
} from './banners/values';
import AddToolNoBanners from '../AddToolBannerNew.react';
import BannerListing from './banners/BannerListing';

const BANNER_FILTER_FORM_NAME = 'creativesSearch';

const CreativesBannersContext = createContext(undefined);

const CreativesBanners = (props) => {
  const { creatives, creativesSizes, selectedCreatives, creativesLoading, handleBannersSubmit, dispatch, creativesTrending } =
    props;

  const [activeTab, setActiveTab] = useState(USE_PROVEN_FLOW() ? BANNER_TAB_TRENDING_BANNERS : BANNER_TAB_ALL_CREATIVES);

  let [trendingBanners, setTrendingBanners] = useState(creativesTrending.banners);

  if (creativesTrending?.banners?.length) {
    trendingBanners = filterTrendingBannerDuplicateIds(trendingBanners);
  }

  let noBannerResultsFound = !creativesLoading && !creatives?.length;

  if (isTrendingCreativesTab(activeTab)) {
    noBannerResultsFound = !creativesLoading && !trendingBanners.length;
  }

  const [openFilter, setOpenFilter] = useState('');

  const [activatedFilters, setActivatedFilters] = useState(JSON.parse(JSON.stringify(DEFAULT_BANNER_FILTER_STATES)));

  const activatedFiltersByTab = activatedFilters[activeTab];

  const firstCreativeSize = creativesSizes?.[0];

  const bannerSizeAll = activatedFilters[BANNER_TAB_ALL_CREATIVES].size;

  const resetBannerFilters = () => {
    setActivatedFilters((filters) => {
      filters[activeTab] = { ...DEFAULT_BANNER_FILTER_STATES[activeTab] };

      return { ...filters };
    });

    if (isAllCreativesTab(activeTab)) {
      BANNER_FILTERS.forEach((field) => {
        dispatch(change(BANNER_FILTER_FORM_NAME, field, field === 'size' ? firstCreativeSize : ''));
      });
      handleBannersSubmit({ size: firstCreativeSize });
    }
  };

  const closeFilter = () => {
    setOpenFilter('');
  };

  useEffect(() => {
    //IF NO TRENDING BANNERS, SET THE DEFAUL TO "ALL"
    if (!creativesTrending.banners.length) {
      setActiveTab(BANNER_TAB_ALL_CREATIVES);
    }
  }, [creativesTrending]);

  useEffect(() => {
    const removeListener = () => document.removeEventListener('click', closeFilter);

    if (openFilter) {
      document.addEventListener('click', closeFilter);
    } else {
      removeListener();
    }

    return () => {
      removeListener();
    };
  }, [openFilter]);

  useEffect(() => {
    if (!firstCreativeSize || bannerSizeAll || !isAllCreativesTab(activeTab)) {
      return;
    }

    setActivatedFilters((filters) => {
      filters[BANNER_TAB_ALL_CREATIVES].size = bannerSizeAll || firstCreativeSize;

      return { ...filters };
    });
  }, [firstCreativeSize, bannerSizeAll, activeTab]);

  const dispatchSearchFormChange = ({ field, key }) => {
    if (isAllCreativesTab(activeTab)) {
      dispatch(change(BANNER_FILTER_FORM_NAME, field, key));
    }

    const filters = {
      ...activatedFiltersByTab,
      [field]: key,
    };

    handleBannersSubmit({
      filters,
    });
  };

  useEffect(() => {
    //no selected creatives, switch tab back
    if (isSelectCreativesTabs(activeTab) && !selectedCreatives?.length) {
      setActiveTab(BANNER_TAB_ALL_CREATIVES);
    }
  }, [activeTab, selectedCreatives]);

  useEffect(() => {
    //FILTER THE TRENDING BANNERS
    if (isTrendingCreativesTab(activeTab)) {
      const trendingFilters = activatedFilters.TRENDING_BANNERS;

      setTrendingBanners(() => {
        let banners = creativesTrending.banners;

        if (!Object.keys(trendingFilters).length) {
          return banners;
        }

        banners = banners.filter((banner) => {
          const { countryCode } = banner;

          for (const key in trendingFilters) {
            if (key === 'countryCode' && countryCode === 'ALL') {
            } else if (banner[key] !== trendingFilters[key]) {
              return false;
            }
          }

          return true;
        });

        return banners;
      });
    }
  }, [activeTab, activatedFilters, creativesTrending.banners]);

  return (
    <CreativesBannersContext.Provider
      value={{
        ...props,
        activeTab,
        setActiveTab,
        dispatchSearchFormChange,
        openFilter,
        setOpenFilter,
        firstCreativeSize,
        resetBannerFilters,
        noBannerResultsFound,
        creativesTrending,
        trendingBanners,
        activatedFilters,
        setActivatedFilters,
        activatedFiltersByTab,
      }}
    >
      <div className="creativesBannersNew">
        <Tabs />
        <BannerListing activeTab={activeTab} />
        {noBannerResultsFound ? <AddToolNoBanners /> : ''}
        {creativesLoading ? <div className="loadingBannerCover" /> : null}
      </div>
    </CreativesBannersContext.Provider>
  );
};

export const useCreativesBanners = () => useContext(CreativesBannersContext);

export default provideHooks()(
  connect((state) => ({
    creativesTrending: state.creativesTrending,
  }))(
    injectIntl(
      reduxForm(
        {
          form: BANNER_FILTER_FORM_NAME,
          fields: ['format', 'language', 'rating', 'size'],
        },
        (state) => ({
          initialValues: {
            format: '',
            language: '',
            rating: '',
            size: state.creativesSizes.length > 0 ? state.creativesSizes[0] : '',
          },
        })
      )(CreativesBanners)
    )
  )
);

import { InputText } from '../../../reusables';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../i18n/base-en';
import { Select } from 'react-materialize';
import { Gtm } from '../../../../utils/gtm';
import MobileLandingPreviewType from './LandingPreviewType.react';
import SelectCustom from '../../../reusables/form/SelectCustom';

const SearchTool = ({
  searchString,
  searchResult,
  offerScreenshots,
  availableDevices,
  modalViewMode,
  handleViewModeChange,
  setSortType,
  sortType,
}) => (
  <div className="search-tool">
    <div className=" search-input" id="search-string">
      <InputText
        field={searchString}
        id="search-input"
        maxLength={40}
        placeholder="Search..."
        prefixIcon="&#xE8B6;"
        tabIndex={10}
        type="text"
      />
    </div>
    <div className="devices-icons">
      <div className="rightside-tools">
        <div className="preview-type desktop-preview">
          <FormattedMessage {...messages.genericTextPreviewType} />
          {offerScreenshots
            ? availableDevices.map((device) => (
                <span
                  className={`pointer device-icon ${device.name}-icon ${modalViewMode === device.name ? 'primary-color' : ''}`}
                  key={device.name}
                  onClick={() => handleViewModeChange(device.name)}
                >
                  <i className="material-icons" data-cy={`preview-type-device-${device.name}`}>
                    {device.icon}
                  </i>
                </span>
              ))
            : null}
        </div>

        <MobileLandingPreviewType
          availableDevices={availableDevices}
          handleViewModeChange={handleViewModeChange}
          modalViewMode={modalViewMode}
          offerScreenshots={offerScreenshots}
        />
        <div className="customSelects">
          <div className="sort-by fields" data-cy="sort-by-dropdown">
            <SelectCustom
              label={messages.genericTextSortingLandingPage.description}
              styleBox={true}
              id="sorting"
              onChange={(e) => {
                setSortType(e);
                Gtm.event('UI Component', 'Click', 'Console-Landing-Sort');
              }}
              value={sortType}
              showSelectedFieldCheckmark={false}
              options={[
                {
                  value: 'epc_highest',
                  text: 'EPC : Highest',
                },
                {
                  value: 'epc_lowest',
                  text: 'EPC : Lowest',
                },
                {
                  value: 'newest',
                  text: 'Newest',
                },
                {
                  value: 'oldest',
                  text: 'Oldest',
                },
                {
                  value: 'name_asc',
                  text: 'A-Z',
                },
                {
                  value: 'name_desc',
                  text: 'Z-A',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SearchTool;

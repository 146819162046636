import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Container } from '../../components/reusables';

const ReferralsCard = ({ activeTab, commissionAmount, companyName, dateAdded }) => (
  <Container className="referral-card z-depth-2">
    <div className="company">{companyName}</div>
    {activeTab !== 'affiliates' && (
      <div className="amount">
        <FormattedNumber currency="USD" style="currency" value={parseFloat(commissionAmount).toFixed(2)} />
      </div>
    )}
    <div className="joindate">
      <FormattedMessage {...messages.genericTextJoinDate} />
      &nbsp;{moment(dateAdded).format('DD/MM/YYYY')}
    </div>
  </Container>
);

ReferralsCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  commissionAmount: PropTypes.string,
  companyName: PropTypes.string,
  dateAdded: PropTypes.string,
};

export default ReferralsCard;

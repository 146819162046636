import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';
import Tooltip from './ReactTooltip';
import ErrorCircle from './svg/ErrorCircle.react.js';
import CheckCircle from './svg/CheckCircle.react.js';

// TODO: remove jQuery from project
import $ from 'jquery';

import '../../assets/js/scripts/forms';

class SelectInput extends Component {
  constructor(props) {
    super(props);

    this.renderLabel = this.renderLabel.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.resetSelect();
  }

  shouldComponentUpdate(nextProps) {
    const { children, disabled, field, value, sort } = this.props;
    return (
      sort !== nextProps.sort ||
      disabled !== nextProps.disabled ||
      value !== nextProps.value ||
      !_.isEqual(nextProps.children, children) ||
      (!!field &&
        (field.error !== nextProps.field.error ||
          field.touched !== nextProps.field.touched ||
          field.value !== nextProps.field.value))
    );
  }

  componentDidUpdate() {
    const { sort } = this.props;
    if (!sort) {
      this.resetSelect();
    }
  }

  componentWillUnmount() {
    const { id } = this.props;

    const $select = $(`#${id}`);
    if ($select.material_select) {
      $select.material_select('destroy');
    }
  }
  handleBlur() {
    const { field, dontBlurIfPristine } = this.props;
    if (!dontBlurIfPristine || !field.pristine) {
      field.onBlur();
    }
  }

  resetSelect() {
    const { field, handleChange, sort, handleGtmEvent, id, multiple, native, onOpen, maxSelected } = this.props;

    const $select = $(`#${id}`);
    const $container = $select.closest('.filterInput');
    const $verticalPromotion = $('#tellUsMore-VerticalPromotion');

    $container.find('.select-dropdown').remove();

    if (sort) {
      $select.on('change', sort);
    }

    if ($select.material_select) {
      $select.material_select('destroy');
      $select.off('change.value');
      $select.parent().find('.select-dropdown').off('close');
      if (!native) {
        $select.material_select();
      }
    }

    if (field && field.onChange && !multiple) {
      $select.on('change.value', field.onChange);
    }

    if (handleChange) {
      $select.on('change.value', handleChange);
    }

    if (field && field.onBlur && !multiple) {
      $select.on('blur.value', field.onChange);
      $select
        .parent()
        .find('.select-dropdown')
        .on('close', () => {
          setTimeout(() => {
            if (!$select.val()) {
              field.onBlur('');
            }
          }, 125);
        });
    }

    $select
      .parent()
      .find('.select-dropdown')
      .on('open', function () {
        const $self = $(this);

        $self.addClass('open');

        if (typeof onOpen === 'function') {
          onOpen();
        }
      });

    $verticalPromotion
      .parent()
      .find('.select-dropdown')
      .on('open', function () {
        if (id === 'tellUsMore-VerticalPromotion') {
          const selected = $select.find('option:selected:not([disabled])');
          const optionsList = $(this).parent().find('ul').find('li');
          const values = [];

          selected.each(function () {
            values.push($(this).val());
          });

          if (values.length >= maxSelected) {
            optionsList.each(function () {
              if (values.length !== 0 && !values.includes($(this).text())) {
                $(this).addClass('disabled');
              }
            });
          }
        }
      });

    $verticalPromotion.change(function () {
      if (id === 'tellUsMore-VerticalPromotion') {
        const selected = $select.find('option:selected');
        const optionsList = $(this).parent().find('ul').find('li');
        const values = [];

        selected.each(function () {
          values.push($(this).val());
        });

        if (values.length >= maxSelected) {
          optionsList.each(function () {
            if (!values.includes($(this).text())) {
              $(this).addClass('disabled');
            }
          });
        } else {
          optionsList.each(function () {
            if (!$(this).find('input')[0].disabled) {
              $(this).removeClass('disabled');
            }
          });
        }
      }
    });

    if (multiple) {
      $select
        .parent()
        .find('.select-dropdown')
        .on('close', function () {
          const $self = $(this);

          if ($self.hasClass('open')) {
            const selected = $select.find('option:selected:not([disabled])');
            const values = [];

            selected.each(function () {
              values.push($(this).val());
            });

            setTimeout(() => {
              field.onBlur(values);
            }, 1);

            if (handleGtmEvent) {
              handleGtmEvent(values);
            }
          }
        });
    }
  }

  renderLabel(id, labelClasses, label, icon, labelAfterIcon, optional, tooltip) {
    const { afterLabelComp, disabled } = this.props;

    return (
      <label className={`${labelClasses} active ${disabled ? 'disabled' : ''} ${tooltip ? 'select-tooltip' : ''}`}>
        <FormattedMessage {...label} />
        {icon ? <i className="material-icons select-wrapper__icon">info</i> : null}
        {!!labelAfterIcon && <FormattedMessage {...messages[labelAfterIcon]} />}
        {optional ? ' ' : null}
        {optional ? <FormattedMessage {...messages.genericTextOptionalInput} /> : null}
        {!!tooltip && (
          <Tooltip tooltip={tooltip}>
            <i className="material-icons tooltipped">&#xE88F;</i>
          </Tooltip>
        )}
        {!!afterLabelComp && afterLabelComp}
      </label>
    );
  }

  render() {
    let { field } = this.props;
    const { children } = this.props;

    const {
      autoFocus,
      category,
      className,
      compare,
      disabled,
      filtername,
      handleChange,
      icon,
      id,
      invalid,
      label,
      labelAfterIcon,
      labelClassName,
      maxSelected,
      multiple,
      native,
      optional,
      preLabel,
      tabIndex,
      tooltip,
      value,
      simplified,
      key,
    } = this.props;
    let fieldClasses = `${className} input-field select-field ${simplified && (field.touched || field.dirty) ? 'simplify' : ''}`;
    let labelClasses = labelClassName || '';

    if (preLabel) {
      labelClasses += ' pre-label';
    }

    if (typeof field !== 'undefined' && field.value) {
      fieldClasses += ' touched';
    }

    if (multiple && typeof field !== 'undefined' && !(field.value instanceof Array)) {
      field.value = [];
    }

    field = field || {};
    return (
      <div className={fieldClasses}>
        {label && preLabel ? this.renderLabel(id, labelClasses, label, icon, labelAfterIcon, optional, tooltip) : null}
        <select
          autoFocus={autoFocus}
          className={` ${multiple ? ' multiple' : ''} ${native ? 'native' : ''}`}
          data-category={category}
          data-compare={compare}
          data-filtername={filtername}
          data-maxselected={maxSelected}
          disabled={disabled}
          id={id}
          key={key}
          multiple={multiple}
          name={field.name}
          onBlur={this.handleBlur}
          onChange={handleChange || field.onChange}
          onDragStart={field.onDragStart}
          onDrop={field.onDrop}
          onFocus={field.onFocus}
          tabIndex={tabIndex}
          value={field.value || value}
        >
          {children}
        </select>
        {simplified && !!field && field.value !== '' && (field.touched || field.dirty) && field.valid ? (
          <i className="icon-validation" key="valid">
            <CheckCircle />
          </i>
        ) : null}
        {simplified && !!field && (field.touched || field.dirty) && field.error
          ? [
              <i className="icon-validation" key="error">
                <ErrorCircle />
              </i>,
              <div className="field-error" key="error div">
                {field.error}
              </div>,
            ]
          : null}
        {native ? <span /> : null}
        {label && !preLabel ? this.renderLabel(id, labelClasses, label, icon, labelAfterIcon, optional, tooltip) : null}
        {invalid ? (
          <div className="field-error" data-cy={`${id}-field-error`} key="error div">
            {field.error}
          </div>
        ) : (
          field &&
          field.error &&
          field.touched &&
          invalid && (
            <div className="field-error" key="error div">
              {field.error}
            </div>
          )
        )}
      </div>
    );
  }
}

SelectInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  multiple: false,
  native: false,
};

SelectInput.propTypes = {
  afterLabelComp: PropTypes.any,
  autoFocus: PropTypes.bool,
  category: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  compare: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  filtername: PropTypes.string,
  handleChange: PropTypes.func,
  icon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  label: PropTypes.object,
  labelAfterIcon: PropTypes.string,
  labelClassName: PropTypes.string,
  maxSelected: PropTypes.any,
  multiple: PropTypes.bool,
  native: PropTypes.bool,
  onOpen: PropTypes.func,
  optional: PropTypes.bool,
  preLabel: PropTypes.bool,
  simplified: PropTypes.bool,
  tabIndex: PropTypes.number,
  tooltip: PropTypes.string,
  value: PropTypes.any,
};

export default injectIntl(SelectInput);

import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import HomeFeaturedOffer from './featured-offers/HomeFeaturedOffer.react';

import { Gtm } from '../../utils/gtm';

const HomeFeaturedOffers = (props) => {
  const { featuredOffers } = props;

  return (
    <div className="row" id="home-featured-offers">
      {featuredOffers.map((offer) => (
        <Link
          className="home-featured-offers-container col-xlg-12 col-lg-12 col-md-12 col-sm-6 col-xs-12"
          key={uuidv4()}
          onClick={() => Gtm.event('dashboard', 'Click', `top offers - ${offer.name}`)}
          onlyActiveOnIndex={false}
          to={`/offers/${offer.id}`}
        >
          <HomeFeaturedOffer offer={offer} />
          <hr />
        </Link>
      ))}
    </div>
  );
};

HomeFeaturedOffers.defaultProps = {
  featuredOffers: [],
};

HomeFeaturedOffers.propTypes = {
  featuredOffers: PropTypes.array,
};

export default HomeFeaturedOffers;

import { createMessagesFromLabels } from '../helpers';

const timezonesList = [
  {
    value: 'Dateline Standard Time',
    label: {
      id: 'timezone.utc-12-DST',
      description: '(UTC-12:00) International Date Line West',
      defaultMessage: '(UTC-12:00) International Date Line West',
    },
    name: 'timezone-utc-12-DST',
  },
  {
    value: 'UTC-11',
    label: {
      id: 'timezone.utc-11-U',
      description: '(UTC-11:00) Coordinated Universal Time-11',
      defaultMessage: '(UTC-11:00) Coordinated Universal Time-11',
    },
    name: 'timezone-utc-11-U',
  },
  {
    value: 'Hawaiian Standard Time',
    label: {
      id: 'timezone.utc-10-HST',
      description: '(UTC-10:00) Hawaii',
      defaultMessage: '(UTC-10:00) Hawaii',
    },
    name: 'timezone-utc-10-HST',
  },
  {
    value: 'Alaskan Standard Time',
    label: {
      id: 'timezone.utc-8-AKDT',
      description: '(UTC-09:00) Alaska',
      defaultMessage: '(UTC-09:00) Alaska',
    },
    name: 'timezone-utc-8-AKDT',
  },
  {
    value: 'Pacific Standard Time (Mexico)',
    label: {
      id: 'timezone.utc-7-PDTM',
      description: '(UTC-08:00) Baja California',
      defaultMessage: '(UTC-08:00) Baja California',
    },
    name: 'timezone-utc-7-PDTM',
  },
  {
    value: 'Pacific Standard Time',
    label: {
      id: 'timezone.utc-7-PDT',
      description: '(UTC-08:00) Pacific Time (US & Canada)',
      defaultMessage: '(UTC-08:00) Pacific Time (US & Canada)',
    },
    name: 'timezone-utc-7-PDT',
  },
  {
    value: 'US Mountain Standard Time',
    label: {
      id: 'timezone.utc-7-UMST',
      description: '(UTC-07:00) Arizona',
      defaultMessage: '(UTC-07:00) Arizona',
    },
    name: 'timezone-utc-7-UMST',
  },
  {
    value: 'Mountain Standard Time (Mexico)',
    label: {
      id: 'timezone.utc-6-MDT-mexico',
      description: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
      defaultMessage: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    },
    name: 'timezone-utc-6-MDT-mexico',
  },
  {
    value: 'Mountain Standard Time',
    label: {
      id: 'timezone.utc-6-MDT-us',
      description: '(UTC-07:00) Mountain Time (US & Canada)',
      defaultMessage: '(UTC-07:00) Mountain Time (US & Canada)',
    },
    name: 'timezone-utc-6-MDT-us',
  },
  {
    value: 'Central America Standard Time',
    label: {
      id: 'timezone.utc-6-CAST',
      description: '(UTC-06:00) Central America',
      defaultMessage: '(UTC-06:00) Central America',
    },
    name: 'timezone-utc-6-CAST',
  },
  {
    value: 'Central Standard Time',
    label: {
      id: 'timezone.utc-5-CDT-us',
      description: '(UTC-06:00) Central Time (US & Canada)',
      defaultMessage: '(UTC-06:00) Central Time (US & Canada)',
    },
    name: 'timezone-utc-5-CDT-us',
  },
  {
    value: 'Central Standard Time (Mexico)',
    label: {
      id: 'timezone.utc-5-CDT-mexico',
      description: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
      defaultMessage: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    },
    name: 'timezone-utc-5-CDT-mexico',
  },
  {
    value: 'Canada Central Standard Time',
    label: {
      id: 'timezone.utc-6-CCST',
      description: '(UTC-06:00) Saskatchewan',
      defaultMessage: '(UTC-06:00) Saskatchewan',
    },
    name: 'timezone-utc-6-CCST',
  },
  {
    value: 'SA Pacific Standard Time',
    label: {
      id: 'timezone.utc-5-SPST',
      description: '(UTC-05:00) Bogota, Lima, Quito',
      defaultMessage: '(UTC-05:00) Bogota, Lima, Quito',
    },
    name: 'timezone-utc-5-SPST',
  },
  {
    value: 'Eastern Standard Time',
    label: {
      id: 'timezone.utc-4-EDT',
      description: '(UTC-05:00) Eastern Time (US & Canada)',
      defaultMessage: '(UTC-05:00) Eastern Time (US & Canada)',
    },
    name: 'timezone-utc-4-EDT',
  },
  {
    value: 'US Eastern Standard Time',
    label: {
      id: 'timezone.utc-4-UEDT',
      description: '(UTC-05:00) Indiana (East)',
      defaultMessage: '(UTC-05:00) Indiana (East)',
    },
    name: 'timezone-utc-4-UEDT',
  },
  {
    value: 'Venezuela Standard Time',
    label: {
      id: 'timezone.utc-4.5-VST',
      description: '(UTC-04:30) Caracas',
      defaultMessage: '(UTC-04:30) Caracas',
    },
    name: 'timezone-utc-4.5-VST',
  },
  {
    value: 'Paraguay Standard Time',
    label: {
      id: 'timezone.utc-4-PST',
      description: '(UTC-04:00) Asuncion',
      defaultMessage: '(UTC-04:00) Asuncion',
    },
    name: 'timezone-utc-4-PST',
  },
  {
    value: 'Atlantic Standard Time',
    label: {
      id: 'timezone.utc-3-ADT',
      description: '(UTC-04:00) Atlantic Time (Canada)',
      defaultMessage: '(UTC-04:00) Atlantic Time (Canada)',
    },
    name: 'timezone-utc-3-ADT',
  },
  {
    value: 'Central Brazilian Standard Time',
    label: {
      id: 'timezone.utc-4-CBST',
      description: '(UTC-04:00) Cuiaba',
      defaultMessage: '(UTC-04:00) Cuiaba',
    },
    name: 'timezone-utc-4-CBST',
  },
  {
    value: 'SA Western Standard Time',
    label: {
      id: 'timezone.utc-4-SWST',
      description: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
      defaultMessage: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    },
    name: 'timezone-utc-4-SWST',
  },
  {
    value: 'Pacific SA Standard Time',
    label: {
      id: 'timezone.utc-4-PSST',
      description: '(UTC-04:00) Santiago',
      defaultMessage: '(UTC-04:00) Santiago',
    },
    name: 'timezone-utc-4-PSST',
  },
  {
    value: 'Newfoundland Standard Time',
    label: {
      id: 'timezone.utc-2.5-NDT',
      description: '(UTC-03:30) Newfoundland',
      defaultMessage: '(UTC-03:30) Newfoundland',
    },
    name: 'timezone-utc-2.5-NDT',
  },
  {
    value: 'E. South America Standard Time',
    label: {
      id: 'timezone.utc-3-ESAST',
      description: '(UTC-03:00) Brasilia',
      defaultMessage: '(UTC-03:00) Brasilia',
    },
    name: 'timezone-utc-3-ESAST',
  },
  {
    value: 'Argentina Standard Time',
    label: {
      id: 'timezone.utc-3-AST',
      description: '(UTC-03:00) Buenos Aires',
      defaultMessage: '(UTC-03:00) Buenos Aires',
    },
    name: 'timezone-utc-3-AST',
  },
  {
    value: 'SA Eastern Standard Time',
    label: {
      id: 'timezone.utc-3-SEST',
      description: '(UTC-03:00) Cayenne, Fortaleza',
      defaultMessage: '(UTC-03:00) Cayenne, Fortaleza',
    },
    name: 'timezone-utc-3-SEST',
  },
  {
    value: 'Greenland Standard Time',
    label: {
      id: 'timezone.utc-2-GDT',
      description: '(UTC-03:00) Greenland',
      defaultMessage: '(UTC-03:00) Greenland',
    },
    name: 'timezone-utc-2-GDT',
  },
  {
    value: 'Montevideo Standard Time',
    label: {
      id: 'timezone.utc-3-MST',
      description: '(UTC-03:00) Montevideo',
      defaultMessage: '(UTC-03:00) Montevideo',
    },
    name: 'timezone-utc-3-MST',
  },
  {
    value: 'Bahia Standard Time',
    label: {
      id: 'timezone.utc-3-BST',
      description: '(UTC-03:00) Salvador',
      defaultMessage: '(UTC-03:00) Salvador',
    },
    name: 'timezone-utc-3-BST',
  },
  {
    value: 'UTC-02',
    label: {
      id: 'timezone.utc-2-U',
      description: '(UTC-02:00) Coordinated Universal Time-02',
      defaultMessage: '(UTC-02:00) Coordinated Universal Time-02',
    },
    name: 'timezone-utc-2-U',
  },
  {
    value: 'Mid-Atlantic Standard Time',
    label: {
      id: 'timezone.utc-1-MDT',
      description: '(UTC-02:00) Mid-Atlantic - Old',
      defaultMessage: '(UTC-02:00) Mid-Atlantic - Old',
    },
    name: 'timezone-utc-1-MDT',
  },
  {
    value: 'Azores Standard Time',
    label: {
      id: 'timezone.utc0-ADT',
      description: '(UTC-01:00) Azores',
      defaultMessage: '(UTC-01:00) Azores',
    },
    name: 'timezone-utc0-ADT',
  },
  {
    value: 'Cape Verde Standard Time',
    label: {
      id: 'timezone.utc-1-CVST',
      description: '(UTC-01:00) Cape Verde Is.',
      defaultMessage: '(UTC-01:00) Cape Verde Is.',
    },
    name: 'timezone-utc-1-CVST',
  },
  {
    value: 'Morocco Standard Time',
    label: {
      id: 'timezone.utc1-MDT',
      description: '(UTC+00:00) Casablanca',
      defaultMessage: '(UTC+00:00) Casablanca',
    },
    name: 'timezone-utc1-MDT',
  },
  {
    value: 'UTC',
    label: {
      id: 'timezone.utc0-CUT',
      description: '(UTC+00:00) Coordinated Universal Time',
      defaultMessage: '(UTC+00:00) Coordinated Universal Time',
    },
    name: 'timezone-utc0-CUT',
  },
  {
    value: 'GMT Standard Time',
    label: {
      id: 'timezone.utc1-GDT',
      description: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
      defaultMessage: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    },
    name: 'timezone-utc1-GDT',
  },
  {
    value: 'Greenwich Standard Time',
    label: {
      id: 'timezone.utc0-GST',
      description: '(UTC+00:00) Monrovia, Reykjavik',
      defaultMessage: '(UTC+00:00) Monrovia, Reykjavik',
    },
    name: 'timezone-utc0-GST',
  },
  {
    value: 'W. Europe Standard Time',
    label: {
      id: 'timezone.utc2-WEDT',
      description: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      defaultMessage: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    name: 'timezone-utc2-WEDT',
  },
  {
    value: 'Central Europe Standard Time',
    label: {
      id: 'timezone.utc2-CEDT',
      description: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      defaultMessage: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    },
    name: 'timezone-utc2-CEDT',
  },
  {
    value: 'Romance Standard Time',
    label: {
      id: 'timezone.utc2-RDT',
      description: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
      defaultMessage: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    },
    name: 'timezone-utc2-RDT',
  },
  {
    value: 'Central European Standard Time',
    label: {
      id: 'timezone.utc2-CEDT',
      description: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      defaultMessage: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    },
    name: 'timezone-utc2-CEDT',
  },
  {
    value: 'W. Central Africa Standard Time',
    label: {
      id: 'timezone.utc1-WCAST',
      description: '(UTC+01:00) West Central Africa',
      defaultMessage: '(UTC+01:00) West Central Africa',
    },
    name: 'timezone-utc1-WCAST',
  },
  {
    value: 'Namibia Standard Time',
    label: {
      id: 'timezone.utc1-NST',
      description: '(UTC+01:00) Windhoek',
      defaultMessage: '(UTC+01:00) Windhoek',
    },
    name: 'timezone-utc1-NST',
  },
  {
    value: 'GTB Standard Time',
    label: {
      id: 'timezone.utc3-GDT',
      description: '(UTC+02:00) Athens, Bucharest',
      defaultMessage: '(UTC+02:00) Athens, Bucharest',
    },
    name: 'timezone-utc3-GDT',
  },
  {
    value: 'Middle East Standard Time',
    label: {
      id: 'timezone.utc3-MEDT',
      description: '(UTC+02:00) Beirut',
      defaultMessage: '(UTC+02:00) Beirut',
    },
    name: 'timezone-utc3-MEDT',
  },
  {
    value: 'Egypt Standard Time',
    label: {
      id: 'timezone.utc2-EST',
      description: '(UTC+02:00) Cairo',
      defaultMessage: '(UTC+02:00) Cairo',
    },
    name: 'timezone-utc2-EST',
  },
  {
    value: 'Syria Standard Time',
    label: {
      id: 'timezone.utc3-SDT',
      description: '(UTC+02:00) Damascus',
      defaultMessage: '(UTC+02:00) Damascus',
    },
    name: 'timezone-utc3-SDT',
  },
  {
    value: 'E. Europe Standard Time',
    label: {
      id: 'timezone.utc3-EEDT',
      description: '(UTC+02:00) E. Europe',
      defaultMessage: '(UTC+02:00) E. Europe',
    },
    name: 'timezone-utc3-EEDT',
  },
  {
    value: 'South Africa Standard Time',
    label: {
      id: 'timezone.utc2-SAST',
      description: '(UTC+02:00) Harare, Pretoria',
      defaultMessage: '(UTC+02:00) Harare, Pretoria',
    },
    name: 'timezone-utc2-SAST',
  },
  {
    value: 'FLE Standard Time',
    label: {
      id: 'timezone.utc3-FDT',
      description: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      defaultMessage: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    },
    name: 'timezone-utc3-FDT',
  },
  {
    value: 'Turkey Standard Time',
    label: {
      id: 'timezone.utc3-TDT',
      description: '(UTC+02:00) Istanbul',
      defaultMessage: '(UTC+02:00) Istanbul',
    },
    name: 'timezone-utc3-TDT',
  },
  {
    value: 'Israel Standard Time',
    label: {
      id: 'timezone.utc3-JDT',
      description: '(UTC+02:00) Jerusalem',
      defaultMessage: '(UTC+02:00) Jerusalem',
    },
    name: 'timezone-utc3-JDT',
  },
  {
    value: 'Libya Standard Time',
    label: {
      id: 'timezone.utc2-LST',
      description: '(UTC+02:00) Tripoli',
      defaultMessage: '(UTC+02:00) Tripoli',
    },
    name: 'timezone-utc2-LST',
  },
  {
    value: 'Jordan Standard Time',
    label: {
      id: 'timezone.utc3-JST',
      description: '(UTC+03:00) Amman',
      defaultMessage: '(UTC+03:00) Amman',
    },
    name: 'timezone-utc3-JST',
  },
  {
    value: 'Arabic Standard Time',
    label: {
      id: 'timezone.utc3-AST',
      description: '(UTC+03:00) Baghdad',
      defaultMessage: '(UTC+03:00) Baghdad',
    },
    name: 'timezone-utc3-AST',
  },
  {
    value: 'Kaliningrad Standard Time',
    label: {
      id: 'timezone.utc3-KST',
      description: '(UTC+03:00) Kaliningrad, Minsk',
      defaultMessage: '(UTC+03:00) Kaliningrad, Minsk',
    },
    name: 'timezone-utc3-KST',
  },
  {
    value: 'Arab Standard Time',
    label: {
      id: 'timezone.utc3-AST',
      description: '(UTC+03:00) Kuwait, Riyadh',
      defaultMessage: '(UTC+03:00) Kuwait, Riyadh',
    },
    name: 'timezone-utc3-AST',
  },
  {
    value: 'E. Africa Standard Time',
    label: {
      id: 'timezone.utc3-EAST',
      description: '(UTC+03:00) Nairobi',
      defaultMessage: '(UTC+03:00) Nairobi',
    },
    name: 'timezone-utc3-EAST',
  },
  {
    value: 'Iran Standard Time',
    label: {
      id: 'timezone.utc4.5-IDT',
      description: '(UTC+03:30) Tehran',
      defaultMessage: '(UTC+03:30) Tehran',
    },
    name: 'timezone-utc4.5-IDT',
  },
  {
    value: 'Arabian Standard Time',
    label: {
      id: 'timezone.utc4-AST',
      description: '(UTC+04:00) Abu Dhabi, Muscat',
      defaultMessage: '(UTC+04:00) Abu Dhabi, Muscat',
    },
    name: 'timezone-utc4-AST',
  },
  {
    value: 'Azerbaijan Standard Time',
    label: {
      id: 'timezone.utc5-ADT',
      description: '(UTC+04:00) Baku',
      defaultMessage: '(UTC+04:00) Baku',
    },
    name: 'timezone-utc5-ADT',
  },
  {
    value: 'Russian Standard Time',
    label: {
      id: 'timezone.utc4-RST',
      description: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
      defaultMessage: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
    },
    name: 'timezone-utc4-RST',
  },
  {
    value: 'Mauritius Standard Time',
    label: {
      id: 'timezone.utc4-MST',
      description: '(UTC+04:00) Port Louis',
      defaultMessage: '(UTC+04:00) Port Louis',
    },
    name: 'timezone-utc4-MST',
  },
  {
    value: 'Georgian Standard Time',
    label: {
      id: 'timezone.utc4-GST',
      description: '(UTC+04:00) Tbilisi',
      defaultMessage: '(UTC+04:00) Tbilisi',
    },
    name: 'timezone-utc4-GST',
  },
  {
    value: 'Caucasus Standard Time',
    label: {
      id: 'timezone.utc4-CST',
      description: '(UTC+04:00) Yerevan',
      defaultMessage: '(UTC+04:00) Yerevan',
    },
    name: 'timezone-utc4-CST',
  },
  {
    value: 'Afghanistan Standard Time',
    label: {
      id: 'timezone.utc4.5-AST',
      description: '(UTC+04:30) Kabul',
      defaultMessage: '(UTC+04:30) Kabul',
    },
    name: 'timezone-utc4.5-AST',
  },
  {
    value: 'West Asia Standard Time',
    label: {
      id: 'timezone.utc5-WAST',
      description: '(UTC+05:00) Ashgabat, Tashkent',
      defaultMessage: '(UTC+05:00) Ashgabat, Tashkent',
    },
    name: 'timezone-utc5-WAST',
  },
  {
    value: 'Pakistan Standard Time',
    label: {
      id: 'timezone.utc5-PST',
      description: '(UTC+05:00) Islamabad, Karachi',
      defaultMessage: '(UTC+05:00) Islamabad, Karachi',
    },
    name: 'timezone-utc5-PST',
  },
  {
    value: 'India Standard Time',
    label: {
      id: 'timezone.utc5.5-IST',
      description: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      defaultMessage: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    },
    name: 'timezone-utc5.5-IST',
  },
  {
    value: 'Sri Lanka Standard Time',
    label: {
      id: 'timezone.utc5.5-SLST',
      description: '(UTC+05:30) Sri Jayawardenepura',
      defaultMessage: '(UTC+05:30) Sri Jayawardenepura',
    },
    name: 'timezone-utc5.5-SLST',
  },
  {
    value: 'Nepal Standard Time',
    label: {
      id: 'timezone.utc5.75-NST',
      description: '(UTC+05:45) Kathmandu',
      defaultMessage: '(UTC+05:45) Kathmandu',
    },
    name: 'timezone-utc5.75-NST',
  },
  {
    value: 'Central Asia Standard Time',
    label: {
      id: 'timezone.utc6-CAST',
      description: '(UTC+06:00) Astana',
      defaultMessage: '(UTC+06:00) Astana',
    },
    name: 'timezone-utc6-CAST',
  },
  {
    value: 'Bangladesh Standard Time',
    label: {
      id: 'timezone.utc6-BST',
      description: '(UTC+06:00) Dhaka',
      defaultMessage: '(UTC+06:00) Dhaka',
    },
    name: 'timezone-utc6-BST',
  },
  {
    value: 'Ekaterinburg Standard Time',
    label: {
      id: 'timezone.utc6-EST',
      description: '(UTC+06:00) Ekaterinburg',
      defaultMessage: '(UTC+06:00) Ekaterinburg',
    },
    name: 'timezone-utc6-EST',
  },
  {
    value: 'Myanmar Standard Time',
    label: {
      id: 'timezone.utc6.5-MST',
      description: '(UTC+06:30) Yangon (Rangoon)',
      defaultMessage: '(UTC+06:30) Yangon (Rangoon)',
    },
    name: 'timezone-utc6.5-MST',
  },
  {
    value: 'SE Asia Standard Time',
    label: {
      id: 'timezone.utc7-SAST',
      description: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
      defaultMessage: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    },
    name: 'timezone-utc7-SAST',
  },
  {
    value: 'N. Central Asia Standard Time',
    label: {
      id: 'timezone.utc7-NCAST',
      description: '(UTC+07:00) Novosibirsk',
      defaultMessage: '(UTC+07:00) Novosibirsk',
    },
    name: 'timezone-utc7-NCAST',
  },
  {
    value: 'China Standard Time',
    label: {
      id: 'timezone.utc8-CST',
      description: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      defaultMessage: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    },
    name: 'timezone-utc8-CST',
  },
  {
    value: 'North Asia Standard Time',
    label: {
      id: 'timezone.utc8-NAST',
      description: '(UTC+08:00) Krasnoyarsk',
      defaultMessage: '(UTC+08:00) Krasnoyarsk',
    },
    name: 'timezone-utc8-NAST',
  },
  {
    value: 'Singapore Standard Time',
    label: {
      id: 'timezone.utc8-MPST',
      description: '(UTC+08:00) Kuala Lumpur, Singapore',
      defaultMessage: '(UTC+08:00) Kuala Lumpur, Singapore',
    },
    name: 'timezone-utc8-MPST',
  },
  {
    value: 'W. Australia Standard Time',
    label: {
      id: 'timezone.utc8-WAST',
      description: '(UTC+08:00) Perth',
      defaultMessage: '(UTC+08:00) Perth',
    },
    name: 'timezone-utc8-WAST',
  },
  {
    value: 'Taipei Standard Time',
    label: {
      id: 'timezone.utc8-TST',
      description: '(UTC+08:00) Taipei',
      defaultMessage: '(UTC+08:00) Taipei',
    },
    name: 'timezone-utc8-TST',
  },
  {
    value: 'Ulaanbaatar Standard Time',
    label: {
      id: 'timezone.utc8-UST',
      description: '(UTC+08:00) Ulaanbaatar',
      defaultMessage: '(UTC+08:00) Ulaanbaatar',
    },
    name: 'timezone-utc8-UST',
  },
  {
    value: 'North Asia East Standard Time',
    label: {
      id: 'timezone.utc9-NAEST',
      description: '(UTC+09:00) Irkutsk',
      defaultMessage: '(UTC+09:00) Irkutsk',
    },
    name: 'timezone-utc9-NAEST',
  },
  {
    value: 'Tokyo Standard Time',
    label: {
      id: 'timezone.utc9-TST',
      description: '(UTC+09:00) Osaka, Sapporo, Tokyo',
      defaultMessage: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    },
    name: 'timezone-utc9-TST',
  },
  {
    value: 'Korea Standard Time',
    label: {
      id: 'timezone.utc9-KST',
      description: '(UTC+09:00) Seoul',
      defaultMessage: '(UTC+09:00) Seoul',
    },
    name: 'timezone-utc9-KST',
  },
  {
    value: 'Cen. Australia Standard Time',
    label: {
      id: 'timezone.utc9.5-CAST',
      description: '(UTC+09:30) Adelaide',
      defaultMessage: '(UTC+09:30) Adelaide',
    },
    name: 'timezone-utc9.5-CAST',
  },
  {
    value: 'AUS Central Standard Time',
    label: {
      id: 'timezone.utc9.5-ACST',
      description: '(UTC+09:30) Darwin',
      defaultMessage: '(UTC+09:30) Darwin',
    },
    name: 'timezone-utc9.5-ACST',
  },
  {
    value: 'E. Australia Standard Time',
    label: {
      id: 'timezone.utc10-EAST',
      description: '(UTC+10:00) Brisbane',
      defaultMessage: '(UTC+10:00) Brisbane',
    },
    name: 'timezone-utc10-EAST',
  },
  {
    value: 'AUS Eastern Standard Time',
    label: {
      id: 'timezone.utc10-AEST',
      description: '(UTC+10:00) Canberra, Melbourne, Sydney',
      defaultMessage: '(UTC+10:00) Canberra, Melbourne, Sydney',
    },
    name: 'timezone-utc10-AEST',
  },
  {
    value: 'West Pacific Standard Time',
    label: {
      id: 'timezone.utc10-WPST',
      description: '(UTC+10:00) Guam, Port Moresby',
      defaultMessage: '(UTC+10:00) Guam, Port Moresby',
    },
    name: 'timezone-utc10-WPST',
  },
  {
    value: 'Tasmania Standard Time',
    label: {
      id: 'timezone.utc10-TST',
      description: '(UTC+10:00) Hobart',
      defaultMessage: '(UTC+10:00) Hobart',
    },
    name: 'timezone-utc10-TST',
  },
  {
    value: 'Yakutsk Standard Time',
    label: {
      id: 'timezone.utc10-YST',
      description: '(UTC+10:00) Yakutsk',
      defaultMessage: '(UTC+10:00) Yakutsk',
    },
    name: 'timezone-utc10-YST',
  },
  {
    value: 'Central Pacific Standard Time',
    label: {
      id: 'timezone.utc11-CPST',
      description: '(UTC+11:00) Solomon Is., New Caledonia',
      defaultMessage: '(UTC+11:00) Solomon Is., New Caledonia',
    },
    name: 'timezone-utc11-CPST',
  },
  {
    value: 'Vladivostok Standard Time',
    label: {
      id: 'timezone.utc11-VST',
      description: '(UTC+11:00) Vladivostok',
      defaultMessage: '(UTC+11:00) Vladivostok',
    },
    name: 'timezone-utc11-VST',
  },
  {
    value: 'New Zealand Standard Time',
    label: {
      id: 'timezone.utc12-NZST',
      description: '(UTC+12:00) Auckland, Wellington',
      defaultMessage: '(UTC+12:00) Auckland, Wellington',
    },
    name: 'timezone-utc12-NZST',
  },
  {
    value: 'UTC+12',
    label: {
      id: 'timezone.utc12-U',
      description: '(UTC+12:00) Coordinated Universal Time+12',
      defaultMessage: '(UTC+12:00) Coordinated Universal Time+12',
    },
    name: 'timezone-utc12-U',
  },
  {
    value: 'Fiji Standard Time',
    label: {
      id: 'timezone.utc12-FST',
      description: '(UTC+12:00) Fiji',
      defaultMessage: '(UTC+12:00) Fiji',
    },
    name: 'timezone-utc12-FST',
  },
  {
    value: 'Magadan Standard Time',
    label: {
      id: 'timezone.utc12-MST',
      description: '(UTC+12:00) Magadan',
      defaultMessage: '(UTC+12:00) Magadan',
    },
    name: 'timezone-utc12-MST',
  },
  {
    value: 'Kamchatka Standard Time',
    label: {
      id: 'timezone.utc13-KDT',
      description: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
      defaultMessage: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    },
    name: 'timezone-utc13-KDT',
  },
  {
    value: 'Tonga Standard Time',
    label: {
      id: 'timezone.utc13-TST',
      description: "(UTC+13:00) Nuku'alofa",
      defaultMessage: "(UTC+13:00) Nuku'alofa",
    },
    name: 'timezone-utc13-TST',
  },
  {
    value: 'Samoa Standard Time',
    label: {
      id: 'timezone.utc13-SST',
      description: '(UTC+13:00) Samoa',
      defaultMessage: '(UTC+13:00) Samoa',
    },
    name: 'timezone-utc13-SST',
  },
];

export default timezonesList;
export const labelMessagesObj = createMessagesFromLabels(timezonesList);

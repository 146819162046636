import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';

import config from '../config';
import consts from '../utils/consts';

const { ACTIONS } = consts;
const { API_URL } = config;

const requestPaymentHistory = () => ({ type: ACTIONS.REQUEST_PAYMENT_HISTORY });
const receivePaymentHistory = (payments) => ({
  type: ACTIONS.RECEIVE_PAYMENT_HISTORY,
  payments,
});
const failedToReceivePaymentHistory = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_PAYMENT_HISTORY,
});
export const fetchPaymentHistory = (filters) => (dispatch) => {
  dispatch(requestPaymentHistory());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/billing`)
      .query({ filters: JSON.stringify(filters) })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receivePaymentHistory(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceivePaymentHistory());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestAffiliateInvoice = () => ({
  type: ACTIONS.REQUEST_AFFILIATE_INVOICE,
});
const receiveAffiliateInvoice = (invoice) => ({
  type: ACTIONS.RECEIVE_AFFILIATE_INVOICE,
  invoice,
});
const failedToReceiveAffiliateInvoice = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_AFFILIATE_INVOICE,
});
export const fetchAffiliateInvoice = (filters) => (dispatch) => {
  dispatch(requestAffiliateInvoice());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/billing/findAllInvoicesBrand`)
      .query({ filters: JSON.stringify(filters) })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveAffiliateInvoice(res.body.data));
          fulfill(res);
        } else {
          dispatch(failedToReceiveAffiliateInvoice());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const changePaymentHistoryViewMode = (viewMode) => ({
  type: ACTIONS.CHANGE_PAYMENT_HISTORY_VIEW_MODE,
  viewMode,
});

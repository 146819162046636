import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

const { WidgetSection } = messages;

class SurveyIframe extends Component {
  constructor(props) {
    super(props);

    this.handleViewModeChange = this.handleViewModeChange.bind(this);

    this.devices = [
      { name: 'desktop', icon: 'desktop_windows' },
      { name: 'tablet', icon: 'tablet_mac' },
      { name: 'phone', icon: 'phone_iphone' },
    ];
    this.state = {
      viewMode: 'desktop',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { src } = this.props;
    const { viewMode } = this.state;

    return src !== nextProps.src || viewMode !== nextState.viewMode;
  }

  handleViewModeChange(e) {
    e.preventDefault();
    const { src } = this.props;
    const { viewMode } = this.state;

    if (src.length > 0) {
      const newViewMode =
        /* $("span").click() */ e.target.getAttribute('data-viewmode') ||
        /* $("i").click() */ e.target.parentNode.getAttribute('data-viewmode');

      if (viewMode !== newViewMode) {
        this.setState({
          viewMode: newViewMode,
        });
      }
    }
  }

  render() {
    const { src } = this.props;
    const { viewMode } = this.state;

    const iframeSrcEmpty = src.length === 0;
    const iconClasses = iframeSrcEmpty ? ' disabled' : '';

    return (
      <div className={`demoIframe z-depth-2 ${viewMode} ${iconClasses}`} id="surveyIframe">
        {!iframeSrcEmpty && (
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <FormattedMessage {...WidgetSection.widgetPreview.text} />:
              </div>
              {this.devices.map((device) => (
                <span
                  className={`${device.name}-icon device-icon ${iconClasses} ${
                    viewMode === device.name ? 'br-primary-color' : ''
                  }`}
                  data-viewmode={device.name}
                  key={device.name}
                  onClick={this.handleViewModeChange}
                >
                  <i className="material-icons">{device.icon}</i>
                </span>
              ))}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="iframe-bg">
              <div className="iframe-container">
                {iframeSrcEmpty ? (
                  <div className="iframe-placeholder">
                    <div className="placeholder-content">
                      <div className="iframe-placeholder-text">
                        <div className="iframe-placeholder-title">
                          <FormattedMessage {...messages.surveyGeneratorWelcome} />
                        </div>
                        <p>
                          For a quick presentation of this tool, please visit our{' '}
                          <a
                            href="https://support.crakrevenue.com/knowledge-base/crakrevenue-survey-machine/"
                            onClick={() => {
                              Gtm.event('survey machine', 'Click', 'knowledge base');
                            }}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            knowledge base
                          </a>{' '}
                          and watch the video.
                          <br />
                          <FormattedMessage {...messages.surveyGeneratorIfYouAreReady} />
                        </p>
                      </div>
                      <img alt="Survey Machine" src="/img/surveygen-placeholder.jpg" />
                    </div>
                  </div>
                ) : null}
                {!iframeSrcEmpty && (
                  // eslint-disable-next-line
                  <iframe id="iframe-survey" src={this.props.src} />
                )}
              </div>
            </div>
            {!iframeSrcEmpty && (
              <div className="row">
                <div className="col-md-12">
                  <div className="survey-iframefooter">
                    <div>
                      <strong>
                        <FormattedMessage {...WidgetSection.widgetPreview.text} />
                      </strong>
                    </div>
                    <div>
                      <FormattedMessage {...messages.surveyGeneratorWidgetPreviewText} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SurveyIframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default SurveyIframe;

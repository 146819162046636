import { useEffect, useState } from 'react';

const hasChangedInitialFieldValue = (field) => {
  if (
    !(
      (field.initialValue === null && field.value === '') ||
      (field.initialValue === '' && field.value === null) ||
      (field.initialValue === 0 && field.value === null)
    )
  ) {
    return field.initialValue !== field.value;
  }
  return false;
};

const useConfirmationOnFields = ({ router, route, items, alertMessage, bypassFlag = false }) => {
  const [needConfirmation, setNeedConfirmation] = useState(false);

  const commonMsg = alertMessage;

  const onBeforeUnload = (e) => {
    if (needConfirmation) {
      e.returnValue = true;
      return commonMsg;
    }

    return null;
  };

  const routerWillLeave = () => {
    if (needConfirmation) {
      return commonMsg;
    }

    return true;
  };

  useEffect(() => {
    let hasChanged = false;
    for (const [, field] of Object.entries(items)) {
      hasChanged = hasChanged || hasChangedInitialFieldValue(field);
    }
    if (hasChanged && !bypassFlag) {
      setNeedConfirmation(() => true);
    } else {
      setNeedConfirmation(() => false);
    }
  }, [items]);

  useEffect(() => {
    if (needConfirmation) {
      window.addEventListener('beforeunload', onBeforeUnload);
      router.setRouteLeaveHook(route, routerWillLeave);
    } else {
      router.setRouteLeaveHook(route, () => {});
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [needConfirmation]);

  return [needConfirmation, setNeedConfirmation];
};

export { useConfirmationOnFields };

export default class ValidationStatusMapper {
  static map({ validationStatusList, validationStatusId }) {
    let validationStatus = undefined;
    if (validationStatusList?.length > 0 && validationStatusId) {
      validationStatus = validationStatusList.find((s) => s.id === validationStatusId).name;
    }

    return validationStatus;
  }
}

import request from '../utils/Req';
import { getCookie } from '../utils/helpers';
import config from '../config';
import consts from '../utils/consts';
import { fetchPaymentMethodsV2 } from '../components/Application/actions/application-actions';

import { fetchProfile } from './profile-actions';

const { API_URL } = config;
const { ACTIONS } = consts;

const createBeneficiary = () => ({
  type: ACTIONS.AFFILIATE.CREATE_BENEFICIARY,
});

const updateBeneficiary = () => ({
  type: ACTIONS.AFFILIATE.UPDATE_BENEFICIARY,
});

export const postAffiliateBeneficiary = (data) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/affiliate/beneficiary`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        name: data.name,
        country_code: data.country_code,
        city: data.city,
        subdivision: data.subdivision,
        postal_code: data.postal_code,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        address_line3: data.address_line3,
        address_line4: data.address_line4,
        address_line5: data.address_line5,
        apartment_suite_unit_building: data.apartment_suite_unit_building,
      })
      .end((err, res) => {
        if (err || res.body.errorMessage) {
          console.error(err);
          console.error(res.body.errorMessage);
        } else {
          dispatch(createBeneficiary(res.body));
          dispatch(fetchProfile());
          dispatch(fetchPaymentMethodsV2());
          fulfill();
        }
      })
  );

export const putAffiliateBeneficiary = (data) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .put(`${API_URL}/affiliate/beneficiary`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        name: data.name,
        country_code: data.country_code,
        city: data.city,
        subdivision: data.subdivision,
        postal_code: data.postal_code,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        address_line3: data.address_line3,
        address_line4: data.address_line4,
        address_line5: data.address_line5,
        apartment_suite_unit_building: data.apartment_suite_unit_building,
      })
      .end((err, res) => {
        if (err || res.body.errorMessage) {
          console.error(err);
          console.error(res.body.errorMessage);
        } else {
          dispatch(updateBeneficiary(res.body));
          dispatch(fetchProfile());
          dispatch(fetchPaymentMethodsV2());
          fulfill();
        }
      })
  );

/**
 * @file Middleware qui permet d'utiliser dispatch à l'intérieur d'actions
 */

/**
 * Middleware qui permet d'utiliser dispatch à l'intérieur d'actions
 * @function
 * @param {object} store Store de l'application
 * @param {function} next Pour la continuation des opérations
 * @param {function} action Nouvel action à dispatch
 * @return {function} L'action à dispatch
 */
export const thunkMiddleware = (store) => (next) => (action) => {
  if (typeof action !== 'function') {
    return next(action);
  }
  return action(store.dispatch, store.getState);
};

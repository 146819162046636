import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../i18n/base-en.js';

import { Checkbox } from '../../components/reusables';

import { setStatisticsFilters } from '../../actions/statistics-actions';
import StatisticsSlowFieldsWarning from './statistics-modals/StatisticsSlowFieldsWarning';
import { Gtm } from '../../utils/gtm';
import { closeModalAnimation } from '../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../components/reusables/colorpicker/useClickOutside.js';

const filtersFields = [
  'filterSubId1',
  'filterSubId2',
  'filterSubId3',
  'filterSubId4',
  'filterSubId5',
  'filterSource',
  'filterBrowser',
  'filterPayoutType',
];

const slowFilterFields = ['filterSubId1', 'filterSubId2', 'filterSubId3', 'filterSubId4', 'filterSubId5', 'filterSource'];

function StatisticsAddFiltersModal(props) {
  const { dispatch, id, values, fields, modalInner } = props;

  const handleCloseModal = () => {
    const eventLabel = [];

    dispatch(setStatisticsFilters(values));
    for (const [key, value] of Object.entries(values)) {
      if (value) {
        eventLabel.push(
          key
            .split('filter')[1]
            .replace(/([A-Z])/g, ' $1')
            .trim()
        );
      }
    }
    Gtm.event('statistics', 'Click', `filter - ${eventLabel}`);
    dispatch(closeModalAnimation());
  };

  const handleSelectAll = (selectAllFlag = true) => {
    filtersFields.map((column) =>
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'statisticsAddFilters',
        field: column,
        value: selectAllFlag,
      })
    );
  };

  useClickOutside(modalInner, handleCloseModal);

  const allSelected = !filtersFields.some((column) => !values[column]);
  const selectedSlowFilters = slowFilterFields.filter((column) => values[column]);
  let tabIndex = 0;

  return (
    <div className=" hasFooter" id={id} style={{ display: 'block' }}>
      <div className="modal-content">
        <div className="modal-container">
          <div className="row modal-filter-title">
            <div className="col-md-12">
              <h1 className="primary-color">
                <FormattedMessage {...messages.genericTextMoreFilters} />
              </h1>
            </div>
          </div>
          <div className="row modal-filter-title">
            <div className="col-md-12">
              <h2>Fields</h2>
            </div>
          </div>
          <div className="row columnsContainer">
            <Checkbox
              className="col-md-4 col-sm-6"
              field={{
                value: [allSelected],
                onChange: () => handleSelectAll(!allSelected),
              }}
              id="selectAllFilters"
              label={messages.genericTextSelectAll.defaultMessage}
              tabIndex={++tabIndex}
              value
            />

            {filtersFields.map((filter, index) => (
              <Checkbox
                className="addFiltersCheckbox col-md-4 col-sm-6"
                field={fields[filter]}
                id={filter}
                key={`addFilters-${index}`}
                label={messages.statisticsFilters[filter].defaultMessage}
                tabIndex={++tabIndex}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="modal-footer-multiple col-md-12" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          {!!selectedSlowFilters.length && (
            <StatisticsSlowFieldsWarning fieldsMessages={messages.statisticsFilters} slowFields={selectedSlowFilters} />
          )}
          <button
            className="modal-action waves-effect waves-green btn bg-primary-color modal-apply"
            onClick={handleCloseModal}
            tabIndex={++tabIndex}
          >
            <FormattedMessage {...messages.genericTextApply} />
          </button>
        </div>
      </div>
    </div>
  );
}

StatisticsAddFiltersModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default connect((state) => ({
  initialValues: state.statistics.selectedFilters,
}))(
  injectIntl(
    reduxForm({
      form: 'statisticsAddFilters',
      fields: ['filtersSearch', ...filtersFields],
    })(StatisticsAddFiltersModal)
  )
);

export default {
  paymentHistoryColumnDate: {
    id: 'paymentHistory.columnDate',
    description: 'Period',
    defaultMessage: 'Period',
  },
  paymentHistoryFaqPayments: {
    id: 'paymentHistory.paymentHistoryFaqPayments',
    description: 'Payment FAQ',
    defaultMessage: 'Payment FAQ',
  },
  paymentHistoryNoHistory: {
    id: 'paymentHistory.noHistory',
    description: "There's no payment history",
    defaultMessage: "There's no payment history",
  },
  paymentHistoryNoInvoice: {
    id: 'paymentHistory.noInvoice',
    description: "Couldn't fetch invoice.  Please try again later.",
    defaultMessage: "Couldn't fetch invoice.  Please try again later.",
  },
  paymentHistoryStatementDetails: {
    id: 'paymentHistory.statementDetails',
    description: 'Statement Details',
    defaultMessage: 'Statement Details',
  },
  paymentHistoryGraphCaption: {
    id: 'paymentHistory.graphCaption',
    description: 'Earnings To Minimum Payout',
    defaultMessage: 'Earnings To Minimum Payout',
  },
  paymentHistoryGraphDaysLeft: {
    id: 'paymentHistory.graphDaysLeft',
    description: 'Number of days left',
    defaultMessage: '{number} {number, plural, one {Day} other {Days}} Left',
  },
  paymentHistoryGraphMissingBillingInfo: {
    id: 'paymentHistory.graphMissingBillingInfo',
    description: 'No billing info',
    defaultMessage: 'No billing info',
  },
  paymentHistoryGraphTooltip: {
    id: 'paymentHistory.graphTooltip',
    description: 'Until end of period. Payment will be issued according to your payment terms',
    defaultMessage: 'Until end of period. Payment will be issued according to your payment terms',
  },
  paymentHistoryGraphLearnMore: {
    id: 'paymentHistory.graphLearnMore',
    description: 'Learn more',
    defaultMessage: 'Learn more',
  },
};

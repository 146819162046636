import React from 'react';
import { useCreativesBanners } from '../CreativesBanners';
import { CREATIVE_BANNER_TABS, isAllCreativesTab, isSelectCreativesTabs, isTrendingCreativesTab } from './values';
import { Gtm } from '../../../../utils/gtm';
import { Pagination } from '../..';

const BannerTitle = React.memo(({ bannerCount }) => {
  const {
    activeTab,
    getDownloadUrl,
    sendSelectedCreativesEmail,
    settings,
    handlePageChange,
    handleRowsChange,
    pageCount,
    totalResults,
    noBannerResultsFound,
  } = useCreativesBanners();

  const title = CREATIVE_BANNER_TABS.find(({ key }) => key === activeTab)?.title || '';

  if (noBannerResultsFound) {
    return null;
  }

  return (
    <div className={`bannerTitle ${!isTrendingCreativesTab(activeTab) ? 'active' : ''}`}>
      <div className="title">
        {title} {bannerCount ? `(${bannerCount})` : ''}
      </div>
      {isSelectCreativesTabs(activeTab) && bannerCount > 0 ? (
        <div className="cta-buttons">
          <div
            className="green-cta"
            onClick={() => {
              sendSelectedCreativesEmail();
              Gtm.event('offers', 'Click', 'send selection');
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.687 15.168">
              <g id="mail-white" transform="translate(-1.25 -2.25)">
                <g id="Message" transform="translate(2 3)">
                  <path
                    id="Stroke-1"
                    d="M13.066,5.851,9.693,8.595a1.744,1.744,0,0,1-2.172,0l-3.4-2.743"
                    transform="translate(-0.991 -1.408)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Stroke-3"
                    d="M11.321,13.668a3.943,3.943,0,0,0,3.866-4.223V4.229A3.952,3.952,0,0,0,11.321,0H3.866A3.952,3.952,0,0,0,0,4.229V9.445a3.943,3.943,0,0,0,3.866,4.223Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>

            <span>Send Selection</span>
          </div>
          <a
            className="green-cta"
            href={getDownloadUrl()}
            onClick={() => {
              Gtm.event('offers', 'Click', 'download');
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.871 14.894">
              <g id="download-white" transform="translate(-2.98 -2.202)">
                <path
                  id="Tracé_1559"
                  data-name="Tracé 1559"
                  d="M9.383,20.4H9.39a6.241,6.241,0,0,0,1.44.4"
                  transform="translate(-1.459 -4.56)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1560"
                  data-name="Tracé 1560"
                  d="M6.631,18.6a6.049,6.049,0,0,1-.871-.879"
                  transform="translate(-0.506 -3.855)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1561"
                  data-name="Tracé 1561"
                  d="M4.192,14.765a6.455,6.455,0,0,1-.358-1.335"
                  transform="translate(0 -2.727)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1562"
                  data-name="Tracé 1562"
                  d="M4.234,8.605a6.2,6.2,0,0,0-.4,1.447"
                  transform="translate(0 -1.459)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1563"
                  data-name="Tracé 1563"
                  d="M6.741,4.973a6.919,6.919,0,0,0-.886.871"
                  transform="translate(-0.532 -0.503)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1564"
                  data-name="Tracé 1564"
                  d="M10.87,3.059a6.3,6.3,0,0,0-1.335.358"
                  transform="translate(-1.499 0)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1565"
                  data-name="Tracé 1565"
                  d="M14.207,3.059a6.284,6.284,0,0,1,1.441.4,6.391,6.391,0,0,1,1.806,1.089,6.139,6.139,0,0,1,.879.885,6.54,6.54,0,0,1,1.054,1.82A6.207,6.207,0,0,1,19.744,8.6a6.651,6.651,0,0,1,0,2.108,6.127,6.127,0,0,1-.4,1.434v.007a6.343,6.343,0,0,1-1.089,1.8,5.826,5.826,0,0,1-.885.879,6.6,6.6,0,0,1-1.827,1.061,6.7,6.7,0,0,1-1.335.358"
                  transform="translate(-2.728 0)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_1566"
                  data-name="Tracé 1566"
                  d="M12.12,13.644V8.531m0,5.112,1.995-2m-1.995,2-1.993-2"
                  transform="translate(-1.655 -1.439)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>

            <span>Download All ({bannerCount})</span>
          </a>
        </div>
      ) : (
        ''
      )}
      <div className={`pagination ${isAllCreativesTab(activeTab) ? 'active' : ''}`}>
        <Pagination
          choices={[10, 20, 50, 100]}
          className="bannerPagination"
          currentPage={settings.page}
          handlePageChange={handlePageChange}
          handleRowsChange={handleRowsChange}
          pageCount={pageCount}
          rowsPerPage={parseInt(settings.limit, 10)}
          selectId="rowsPerPage"
          totalResults={totalResults}
        />
      </div>
    </div>
  );
});

export default BannerTitle;

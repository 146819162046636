/**
 * @file ErrorCircle
 * @author Vero Lopez
 * @version 0.0.1
 */

function ErrorCircle({ className }) {
  return (
    <svg className={className} height="24" viewBox="0 0 24 24" width="24">
      <path
        d="M12 21.6c-5.292 0-9.6-4.308-9.6-9.6S6.708 2.4 12 2.4s9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6M12 0C5.364 0 0 5.364 0 12s5.364 12 12 12 12-5.364 12-12S18.636 0 12 0m2.268 8.04L12 10.308 9.732 8.04a1.195 1.195 0 0 0-1.692 0 1.195 1.195 0 0 0 0 1.692L10.308 12 8.04 14.268a1.195 1.195 0 0 0 0 1.692 1.195 1.195 0 0 0 1.692 0L12 13.692l2.268 2.268a1.195 1.195 0 0 0 1.692 0 1.195 1.195 0 0 0 0-1.692L13.692 12l2.268-2.268a1.195 1.195 0 0 0 0-1.692c-.468-.456-1.236-.456-1.692 0"
        fill="#DD4B39"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ErrorCircle;

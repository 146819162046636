import { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../../utils/gtm';

import messages from '../../../i18n/base-en.js';

import { Filters } from '../../reusables';

import SelectCustom from '../form/SelectCustom';
class CreativesBannerFilters extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { creativesSizes } = this.props;

    if (!_.isEqual(creativesSizes, nextProps.creativesSizes)) {
      nextProps.fields.size.onChange(nextProps.creativesSizes.length > 0 ? nextProps.creativesSizes[0] : '');
    }
  }

  render() {
    const { creativesSizes, fields, handleSubmit, intl } = this.props;

    const languageList = [
      { code: 'EN', name: 'English' },
      { code: 'FR', name: 'French' },
      { code: 'ES', name: 'Spanish' },
      { code: 'DE', name: 'German' },
      { code: 'IT', name: 'Italian' },
      { code: 'NL', name: 'Dutch' },
      { code: '', name: 'All' },
    ];

    const handleGtmEvent = () => {
      const { fields } = this.props;
      Gtm.event(
        `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
        'Click',
        `banner size - ${fields.size.value}`
      );

      languageList.map(
        (language) =>
          language.code === fields.language.value &&
          Gtm.event(
            `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
            'Click',
            `banner language - ${language.name}`
          )
      );

      Gtm.event(
        `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
        'Click',
        `banner rating - ${fields.rating.value === '' ? 'All' : fields.rating.value}`
      );
      Gtm.event(
        `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
        'Click',
        `banner format - ${fields.format.value === '' ? 'All' : fields.format.value}`
      );
    };

    return (
      <Filters handleGtmEvent={handleGtmEvent} handleSubmit={handleSubmit} id="offer-creatives-banner-filters">
        <div className="customSelects">
          <div className="fields">
            <div className="col-xs-12 col-md-3 filterInput ">
              <SelectCustom
                id="size"
                label={messages.genericTextSize.description}
                onChange={fields.size.onChange}
                value=""
                touched={fields.size.touched}
                valueDisabled={intl.formatMessage(
                  creativesSizes.length < 1 ? messages.offerNoCreativesAvailable : messages.genericTextAll
                )}
                showSelectedFieldCheckmark={false}
                options={
                  creativesSizes.length < 1
                    ? [{ value: '0x0', text: intl.formatMessage(messages.offerNoCreativesAvailable) }]
                    : creativesSizes.map((creativeSize) => ({ value: creativeSize, text: creativeSize }))
                }
              />
            </div>
            <div className="col-xs-12 col-md-3 filterInput">
              <SelectCustom
                id="language"
                label={messages.genericTextLanguage.description}
                onChange={fields.language.onChange}
                value=""
                touched={fields.language.touched}
                valueDisabled={intl.formatMessage(messages.genericTextAll)}
                showSelectedFieldCheckmark={false}
                options={[
                  { value: 'EN', text: 'English' },
                  { value: 'FR', text: 'French' },
                  { value: 'ES', text: 'Spanish' },
                  { value: 'DE', text: 'German' },
                  { value: 'IT', text: 'Italian' },
                  { value: 'NL', text: 'Dutch' },
                ]}
              />
            </div>
            <div className="col-xs-12 col-md-3 filterInput ">
              <SelectCustom
                id="rating"
                label={messages.genericTextRating.description}
                onChange={fields.rating.onChange}
                value=""
                touched={fields.rating.touched}
                valueDisabled={intl.formatMessage(messages.genericTextAll)}
                showSelectedFieldCheckmark={false}
                options={[
                  { value: 'G', text: 'G' },
                  { value: 'PG', text: 'PG' },
                  { value: '13', text: '13+' },
                  { value: '18', text: '18+' },
                  { value: 'R', text: 'R' },
                ]}
              />
            </div>
            <div className="col-xs-12 col-md-3 filterInput ">
              <SelectCustom
                id="format"
                label={messages.genericTextRating.description}
                onChange={fields.format.onChange}
                value=""
                touched={fields.format.touched}
                valueDisabled={intl.formatMessage(messages.genericTextAll)}
                showSelectedFieldCheckmark={false}
                options={[
                  { value: 'jpg', text: 'jpg' },
                  { value: 'gif', text: 'gif' },
                ]}
              />
            </div>
          </div>
        </div>
      </Filters>
    );
  }
}

CreativesBannerFilters.propTypes = {
  creativesSizes: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  reduxForm(
    {
      form: 'creativesSearch',
      fields: ['format', 'language', 'rating', 'size'],
    },
    (state) => ({
      initialValues: {
        format: '',
        language: '',
        rating: '',
        size: state.creativesSizes.length > 0 ? state.creativesSizes[0] : '',
      },
    })
  )(CreativesBannerFilters)
);

import React from 'react';
import { OFFER_TAG_TYPES } from './data';

export const OfferListingTagIcon = ({ tag }) => {
  switch (tag) {
    case OFFER_TAG_TYPES.NEW:
      return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="flowbite:rocket-solid">
            <path
              id="Vector"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7106 3.15548C12.8438 3.28798 12.9319 3.45923 12.9631 3.64423C13.1469 4.71361 13.3738 6.89111 12.3969 7.86924C11.2906 8.97424 7.09375 11.3174 5.765 12.0461C5.6473 12.1101 5.51201 12.1341 5.37947 12.1146C5.24693 12.0951 5.1243 12.0331 5.03 11.938L4.47875 11.388L3.93063 10.8355C3.83533 10.7411 3.77325 10.6184 3.75375 10.4857C3.73425 10.3531 3.75838 10.2176 3.8225 10.0999C4.55063 8.77111 6.89313 4.57423 7.99875 3.46798C8.975 2.49111 11.1525 2.71923 12.2219 2.90298C12.4069 2.93423 12.5781 3.02236 12.7106 3.15548ZM3.37938 5.60048L5.87938 5.38736L4.19813 8.18736L2.4125 7.97861C2.29751 7.96274 2.18924 7.91502 2.09993 7.84086C2.01062 7.7667 1.94383 7.66904 1.9071 7.55891C1.87037 7.44879 1.86517 7.33059 1.89208 7.21766C1.91899 7.10474 1.97693 7.00159 2.05938 6.91986L3.37938 5.60048ZM10.4775 9.98798L10.265 12.488L8.94563 13.8086C8.86381 13.8903 8.76085 13.9476 8.64829 13.9741C8.53573 14.0006 8.41802 13.9952 8.30835 13.9585C8.19868 13.9219 8.10139 13.8554 8.02739 13.7665C7.95339 13.6777 7.9056 13.57 7.88938 13.4555L7.62563 11.7011L10.4775 9.98798ZM11.0025 6.10673C11.0025 6.4366 10.8715 6.75295 10.6382 6.9862C10.405 7.21945 10.0886 7.35048 9.75875 7.35048C9.42889 7.35048 9.11254 7.21945 8.87929 6.9862C8.64604 6.75295 8.515 6.4366 8.515 6.10673C8.515 5.77687 8.64604 5.46052 8.87929 5.22727C9.11254 4.99402 9.42889 4.86298 9.75875 4.86298C10.0886 4.86298 10.405 4.99402 10.6382 5.22727C10.8715 5.46052 11.0025 5.77687 11.0025 6.10673Z"
              fill="#00BD00"
            />
          </g>
        </svg>
      );
    case OFFER_TAG_TYPES.EXCLUSIVE:
      return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.6 4.49048L5.925 1.86548H6.075L7.4 4.49048H4.6ZM5.625 10.4155L1.3125 5.24048H5.625V10.4155ZM6.375 10.4155V5.24048H10.6875L6.375 10.4155ZM8.225 4.49048L6.925 1.86548H9.5L10.8125 4.49048H8.225ZM1.1875 4.49048L2.5 1.86548H5.075L3.775 4.49048H1.1875Z"
            fill="#E65300"
          />
        </svg>
      );
    case OFFER_TAG_TYPES.TOP_OFFER:
      return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="ph:star-fill">
            <path
              id="Vector"
              d="M10.9823 5.74909L8.87294 7.56924L9.5156 10.2913C9.55105 10.439 9.54192 10.594 9.48936 10.7366C9.43679 10.8792 9.34315 11.003 9.22025 11.0923C9.09736 11.1817 8.95074 11.2327 8.7989 11.2388C8.64706 11.2449 8.49682 11.2058 8.36716 11.1266L5.99997 9.66971L3.63138 11.1266C3.50173 11.2054 3.35168 11.2441 3.2001 11.2377C3.04852 11.2314 2.9022 11.1804 2.77957 11.0911C2.65693 11.0018 2.56346 10.8782 2.51093 10.7359C2.45839 10.5935 2.44914 10.4388 2.48435 10.2913L3.12935 7.56924L1.01997 5.74909C0.905267 5.64995 0.822311 5.51922 0.781463 5.37322C0.740615 5.22722 0.743685 5.07242 0.79029 4.92815C0.836896 4.78388 0.924971 4.65655 1.04352 4.56204C1.16206 4.46753 1.30582 4.41004 1.45685 4.39674L4.22247 4.17362L5.28935 1.59174C5.34709 1.45103 5.44538 1.33067 5.57171 1.24596C5.69804 1.16126 5.8467 1.11603 5.9988 1.11603C6.1509 1.11603 6.29956 1.16126 6.42589 1.24596C6.55222 1.33067 6.6505 1.45103 6.70825 1.59174L7.77466 4.17362L10.5403 4.39674C10.6916 4.40954 10.8358 4.46671 10.9548 4.56109C11.0737 4.65547 11.1622 4.78286 11.2091 4.9273C11.256 5.07174 11.2592 5.22681 11.2184 5.37308C11.1775 5.51935 11.0944 5.65031 10.9795 5.74956L10.9823 5.74909Z"
              fill="#115EA3"
            />
          </g>
        </svg>
      );
    case OFFER_TAG_TYPES.HOT_PICK:
      return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="ant-design:fire-filled">
            <path
              id="Vector"
              d="M9.77461 5.86393C9.5471 5.35188 9.21638 4.8923 8.80313 4.51393L8.46211 4.20104C8.45053 4.1907 8.43659 4.18337 8.42152 4.17968C8.40645 4.17599 8.3907 4.17606 8.37566 4.17988C8.36062 4.1837 8.34674 4.19115 8.33525 4.20158C8.32377 4.21201 8.31501 4.2251 8.30977 4.23971L8.15742 4.67682C8.0625 4.95104 7.88789 5.23111 7.64062 5.50651C7.62422 5.52408 7.60547 5.52877 7.59258 5.52994C7.57969 5.53111 7.55977 5.52877 7.54219 5.51236C7.52578 5.4983 7.51758 5.47721 7.51875 5.45611C7.56211 4.75065 7.35117 3.95494 6.88945 3.08893C6.50742 2.3694 5.97656 1.80807 5.31328 1.41666L4.8293 1.1319C4.76602 1.0944 4.68516 1.14361 4.68867 1.21744L4.71445 1.77994C4.73203 2.16432 4.6875 2.50416 4.58203 2.78658C4.45312 3.13229 4.26797 3.45338 4.03125 3.74166C3.86651 3.94201 3.6798 4.12323 3.47461 4.2819C2.98044 4.66178 2.57861 5.14854 2.29922 5.70572C2.02052 6.26777 1.87534 6.88657 1.875 7.51393C1.875 8.06705 1.98398 8.6026 2.19961 9.10768C2.40781 9.59396 2.70827 10.0353 3.08438 10.4073C3.46406 10.7823 3.90469 11.0776 4.3957 11.2827C4.9043 11.496 5.44336 11.6038 6 11.6038C6.55664 11.6038 7.0957 11.496 7.6043 11.2838C8.0941 11.08 8.53951 10.7826 8.91562 10.4085C9.29531 10.0335 9.59297 9.59518 9.80039 9.10885C10.0157 8.60515 10.1261 8.06288 10.125 7.5151C10.125 6.94322 10.0078 6.38775 9.77461 5.86393Z"
              fill="#A91A90"
            />
          </g>
        </svg>
      );
    default:
      return <></>;
  }
};

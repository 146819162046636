const Check = () => (
  <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M-4.577-5.577h27.556v27.556H-4.577z" />
      <path className="fill" d="M19.947 3.215L16.98.248l-9.975 9.975-4.051-4.051-2.967 2.964 7.018 7.018.005-.005z" />
    </g>
  </svg>
);

export default Check;

import React from 'react';
import BannerCard from './BannerCard';
import { useCreativesBanners } from '../CreativesBanners';

const BannerList = React.memo(({ bannerList }) => {
  const { displayLink, displayUrl, creativesLoading } = useCreativesBanners();

  if (!bannerList?.length) {
    return null;
  }

  return (
    <div className={`banner-cards ${creativesLoading ? 'loading' : ''}`}>
      {bannerList.map((creative, i) => (
        <BannerCard creative={creative} displayLink={displayLink} displayUrl={displayUrl} key={i} />
      ))}
    </div>
  );
});

export default BannerList;

const EditIcon = () => (
  <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
    <g id="iconamoon:edit-bold">
      <path
        d="M11.25 4.50051L13.5 6.75051M9.75 15.0005H15.75M3.75 12.0005L3 15.0005L6 14.2505L14.6895 5.56101C14.9707 5.27971 15.1287 4.89825 15.1287 4.50051C15.1287 4.10276 14.9707 3.7213 14.6895 3.44001L14.5605 3.31101C14.2792 3.0298 13.8977 2.87183 13.5 2.87183C13.1023 2.87183 12.7208 3.0298 12.4395 3.31101L3.75 12.0005Z"
        id="Vector"
        stroke="#00BD00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.875"
      />
    </g>
  </svg>
);

export default EditIcon;

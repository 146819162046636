import PropTypes from 'prop-types';

const TemplateSelectBox = ({ animated, className, field, Icon, id, onClick, title, value }) => (
  <label className={`template-select-box ${className}`} id={id} onClick={(e) => onClick(e, id, value)}>
    <input
      checked={field.value === value}
      name={field.name}
      onChange={field.onChange}
      onDragStart={field.onDragStart}
      onDrop={field.onDrop}
      onFocus={field.onFocus}
      type="radio"
      value={value}
    />
    <div className={`inside ${animated ? 'animated pulse' : ''}`}>
      <span className="top">
        <Icon />
      </span>
      {title ? <span className="bottom">{title}</span> : null}
    </div>
  </label>
);

TemplateSelectBox.defaultProps = {
  className: '',
  id: '',
  onClick: () => {},
  title: '',
  value: '',
};

TemplateSelectBox.propTypes = {
  Icon: PropTypes.any,
  animated: PropTypes.bool,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
};

export default TemplateSelectBox;

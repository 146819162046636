import React from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import ArrowMobile from './ArrowMobile';

const CategoryRightTab = React.memo(({ field }) => {
  const { activeFilters } = useOffersFilterContext();

  const totalFiltersCategory = activeFilters?.[field]?.length || 0;

  return (
    <div className="categoryRightTab">
      {totalFiltersCategory > 0 && <div className="totalCircle">{totalFiltersCategory}</div>}
      <ArrowMobile />
    </div>
  );
});

export default CategoryRightTab;

const PlusIcon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <g id="plus" transform="translate(-2.5 -2.5)">
      <path id="Tracé_34" data-name="Tracé 34" d="M2.5,12A9.5,9.5,0,1,1,12,21.5,9.5,9.5,0,0,1,2.5,12Z" opacity="0.4" />
      <path
        id="Tracé_35"
        data-name="Tracé 35"
        d="M12.75,8.7a.75.75,0,0,0-1.5,0v2.548H8.7a.75.75,0,1,0,0,1.5h2.55V15.3a.75.75,0,0,0,1.5,0V12.751H15.3a.75.75,0,1,0,0-1.5H12.75Z"
      />
    </g>
  </svg>
);

export default PlusIcon2;

const CopyDiagonalIcon = () => (
  <svg fill="none" height="26" viewBox="0 0 27 26" width="27" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M10.1915 23.8286C8.17542 23.8286 6.28392 23.0475 4.86692 21.6305C3.44992 20.2189 2.66884 18.3339 2.66667 16.3211C2.6645 14.305 3.4445 12.4102 4.86584 10.9867L6.11059 9.75067C6.53525 9.32709 7.221 9.33034 7.64242 9.755C8.06492 10.1786 8.06275 10.8654 7.63917 11.2868L6.39659 12.5207C5.38692 13.5315 4.83225 14.8813 4.83334 16.3189C4.8355 17.7521 5.39017 19.0933 6.39767 20.0976C7.40734 21.1061 8.75392 21.6619 10.1915 21.6619C11.6258 21.6619 12.9713 21.1061 13.9778 20.0986L15.2236 18.8615C15.6472 18.4379 16.3329 18.4411 16.7554 18.8658C17.1768 19.2905 17.1747 19.9762 16.7511 20.3976L15.5085 21.6326C14.0958 23.0475 12.2054 23.8286 10.1915 23.8286Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M20.1245 16.5749C19.8471 16.5749 19.5709 16.4687 19.3596 16.2586C18.935 15.8361 18.935 15.1503 19.3575 14.7267L20.599 13.482C21.6108 12.4734 22.1666 11.1301 22.1666 9.69465C22.1666 8.25923 21.6097 6.91156 20.599 5.90082C18.5146 3.81432 15.113 3.81431 13.0189 5.9019L11.7796 7.14556C11.356 7.57023 10.6702 7.56915 10.2477 7.14773C9.82308 6.72523 9.82309 6.0384 10.2456 5.6159L11.4871 4.37006C14.4283 1.43748 19.2036 1.43857 22.133 4.36898C23.5511 5.79031 24.3332 7.68073 24.3332 9.69465C24.3332 11.7096 23.5511 13.5979 22.1319 15.0138L20.8915 16.2564C20.6802 16.4687 20.4018 16.5749 20.1245 16.5749Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.6877 16.9979C10.4104 16.9979 10.1331 16.8918 9.92181 16.6805C9.49823 16.2569 9.49823 15.5723 9.92181 15.1487L15.5888 9.48289C16.0124 9.06039 16.6981 9.06039 17.1206 9.48289C17.5442 9.90647 17.5442 10.5922 17.1206 11.0158L11.4537 16.6805C11.2424 16.8918 10.9651 16.9979 10.6877 16.9979Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default CopyDiagonalIcon;
